import gql from 'graphql-tag'

export const CREATE_SHEET = gql`
  mutation createSheet(
    $input: CreateSheetInput!
    $options: CreateTemplateTreatmentOptions
  ) {
    createSheet(input: $input, options: $options) {
      id
      name
      patient_id
      attending_vet
      additional_care_team
    }
  }
`

export const GET_CONSULTATIONS = gql`
  query getConsultations($id: ID!, $organisationId: ID!) {
    getPatient(id: $id, organisation_id: $organisationId) {
      id
      active_consultations {
        items {
          id
          origin_id
          origin_reference
          site_id
          attending_vet_id
          admitted_at
        }
      }
    }
  }
`
export const UPDATE_CONSULTATION = gql`
  mutation updateConsultation($input: UpdateConsultationInput!) {
    updateConsultation(input: $input) {
      locations {
        key
        display
        enclosure
      }
      status
      color {
        id
        hex
      }
    }
  }
`
