import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color: string
  size: number
}

export const SvgArrowsRotate: React.FC<Props> = ({ color, size }) => (
  <Svg width={size} height={size} viewBox="0 0 18 18">
    <G fill={color} fillRule="nonzero">
      <Path d="M3.49911 7.06362C3.7799 6.27293 4.23573 5.52939 4.87753 4.89466C7.15665 2.62776 10.8506 2.62776 13.1298 4.89466L13.7533 5.5185H11.9191C11.2737 5.5185 10.7522 6.03717 10.7522 6.67915C10.7522 7.32114 11.2737 7.8398 11.9191 7.8398H16.5685H16.5831C17.2285 7.8398 17.75 7.32114 17.75 6.67915V2.03656C17.75 1.39457 17.2285 0.875907 16.5831 0.875907C15.9376 0.875907 15.4162 1.39457 15.4162 2.03656V3.8936L14.778 3.25524C11.5873 0.0815873 6.41639 0.0815873 3.22562 3.25524C2.33585 4.14023 1.69405 5.18119 1.30022 6.29469C1.08507 6.9004 1.40597 7.56052 2.0113 7.77452C2.61664 7.98851 3.28397 7.66933 3.49911 7.06725V7.06362ZM1.08872 10.2083C0.906387 10.2627 0.73135 10.3606 0.589133 10.5057C0.443269 10.6508 0.344811 10.8248 0.293759 11.0135C0.282819 11.057 0.27188 11.1041 0.264586 11.1513C0.253647 11.2129 0.25 11.2746 0.25 11.3363V15.9644C0.25 16.6063 0.771463 17.125 1.41691 17.125C2.06236 17.125 2.58382 16.6063 2.58382 15.9644V14.1109L3.22562 14.7457C6.41639 17.9157 11.5873 17.9157 14.7744 14.7457C15.6641 13.8607 16.3096 12.8197 16.7034 11.7098C16.9186 11.1041 16.5977 10.444 15.9923 10.23C15.387 10.016 14.7197 10.3352 14.5045 10.9373C14.2237 11.728 13.7679 12.4715 13.1261 13.1063C10.847 15.3731 7.153 15.3731 4.87388 13.1063L4.87023 13.1026L4.24667 12.4824H6.08455C6.73 12.4824 7.25146 11.9637 7.25146 11.3218C7.25146 10.6798 6.73 10.1611 6.08455 10.1611H1.4315C1.37315 10.1611 1.31481 10.1647 1.25646 10.172C1.19811 10.1792 1.14342 10.1901 1.08872 10.2083Z" />
    </G>
  </Svg>
)
