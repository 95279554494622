import React from 'react'
import Svg, { Defs, G, Path, Mask, Use } from 'react-native-svg'

type Props = {
  color?: string
  length?: number
}

export const SvgCheck: React.FC<Props> = ({ color = '#fff', length = 20 }) => (
  <Svg width={length} height={length} viewBox="0 0 20 21" testID="SvgCheck">
    <Defs>
      <Path
        id="svg-check-a"
        d="m16.3138221.23981243c.437722-.36734636 1.0866273-.30579265 1.4493705.13748407.3348399.40917851.3088059 1.00058359-.0414609 1.37807568l-.0943003.08968725-10.62310888 8.91515577c-.34413891.2888093-.82794974.3169161-1.19937868.0832893l-.10780518-.0779411-5.31813983-4.39018915c-.44063877-.36375267-.50666238-1.02037371-.14746786-1.46660414.33156417-.41190501.90954598-.50056071 1.34307866-.22566142l.10514762.07632221 4.66207518 3.84880407z"
      />
      <Mask id="svg-check-b" fill="#000">
        <Use fill="#000" fillRule="evenodd" xlinkHref="#svg-check-a" />
      </Mask>
    </Defs>
    <G fill="none" fillRule="evenodd">
      <Path d="m.5 1h19v19h-19z" />
      <G transform="translate(1 5.5)">
        <Use fill={color} fillRule="nonzero" xlinkHref="#svg-check-a" />
        <Path d="m0-4h18v18h-18z" fill={color} mask="url(#svg-check-b)" />
      </G>
    </G>
  </Svg>
)
