import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { GET_SHEET } from 'components/Sheet/graphql'
import { FormData } from 'components/TreatmentForm'
import { useTranslation } from 'react-i18next'
import { CREATE_TREATMENT } from 'src/components/AddTreatment/graphql'
import { getTreatmentInput } from 'src/components/TreatmentForm/utils/getTreatmentInput'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { getSheet, getSheetVariables } from 'src/types/getSheet'
import { useUpdateTreatments } from 'src/utils/useUpdateTreatments'
import {
  createTreatment as CreateTreatmentT,
  createTreatmentVariables,
} from 'types/createTreatment'
import { CreateTreatmentOptions, ProductType } from 'types/globalTypes'
import { convertProductToOptimisticTreatmentGroup } from './convertProductToOptimisticTreatmentGroup'
import { useConfirm } from 'src/context/confirm'
import { getHasProductDuplication } from './productDuplicates'

export type ProductInfo = {
  productGroupName: string
  productGroupId: string
  productGroupOrder: number | null
  productId: string
  productName: string
  productType: ProductType
}

const MAX_ADDING_TREATMENT_MESSAGE_NAME = 22

export const useCreateTreatment = (sheetId: string, patientId: string) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { fromToQueryDate } = useTimeResolution()
  const updateTreatments = useUpdateTreatments(sheetId)
  const client = useApolloClient()

  const [createTreatment] = useMutation<
    CreateTreatmentT,
    createTreatmentVariables
  >(CREATE_TREATMENT, {
    onCompleted: data => {
      updateTreatments(data.createTreatment)
      toast.success(t('addTreatment.success'))
    },
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
  })

  const confirm = useConfirm()

  return async (
    formData: FormData,
    product: ProductInfo,
    createTreatmentOptions?: CreateTreatmentOptions,
    isIVInfusion: boolean = false,
  ) => {
    const optimisticTreatmentGroup =
      convertProductToOptimisticTreatmentGroup(product)
    const { productId, productType, productName } = product

    const query = GET_SHEET
    const variables = {
      id: sheetId,
      organisation_id: organisationId,
      ...fromToQueryDate,
    }
    const sheetData = client.readQuery<getSheet, getSheetVariables>({
      query,
      variables,
    })

    const hasDuplication = getHasProductDuplication(sheetData, [productId])

    let ignoreDuplication = false
    if (hasDuplication) {
      try {
        await confirm({
          title: t('addTreatment:duplicationTitle'),
          text: t('addTreatment:duplicationText', {
            treatmentName: formData.treatmentName ?? productName,
          }),
          okText: 'Yes',
          cancelText: 'No',
        })
        ignoreDuplication = false
      } catch (e) {
        ignoreDuplication = true
      }
    }

    if (!ignoreDuplication) {
      const dismiss = toast.process(
        t('addTreatment:treatmentAdding', {
          treatmentName: formData.treatmentName ?? product.productName,
        }),
        null,
        MAX_ADDING_TREATMENT_MESSAGE_NAME,
      )

      createTreatment({
        variables: {
          input: {
            ...getTreatmentInput(formData, productType),
            organisation_id: organisationId,
            patient_id: patientId,
            product_id: productId,
            sheet_id: sheetId,
            is_iv_infusion: isIVInfusion,
          },
          ...fromToQueryDate,
          options: createTreatmentOptions,
        },
        fetchPolicy: 'no-cache',
        optimisticResponse: {
          createTreatment: optimisticTreatmentGroup,
        },
        update: (_, { data }) => {
          if (data) updateTreatments(data.createTreatment)
        },
      }).finally(dismiss)
    }
  }
}
