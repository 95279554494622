import React, { useMemo, useCallback } from 'react'
import { Button, TextLink } from 'components/common'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet } from 'react-native'
import { useUser } from 'src/context/user'

import { SubHeader } from '../SubHeader/SubHeader'
import { pinSwitchService, SwitchableUser } from 'src/utils/pinSwitchService'
import { PinSwitchUserList } from './PinSwitchUserList'
import { Fonts } from 'constants/Fonts'
import { useSwitchableUsers } from 'src/hooks/useSwitchableUsers'
import { useDeviceLock } from 'src/context/deviceLock/deviceLock'
import { useSignOut } from 'src/hooks/useSignOut'

export const PinSwitch: React.FC = () => {
  const { user: currentUser } = useUser()
  const { t } = useTranslation()

  const hasSkippedPin = useMemo(() => pinSwitchService.hasSkippedPin(), [])

  const switchableUsers = useSwitchableUsers()

  const { signOut, resetPinAndSignOut } = useSignOut()

  const resetCurrentPinAndSignOut = useCallback(async () => {
    await resetPinAndSignOut(currentUser!.email)
  }, [currentUser, resetPinAndSignOut])

  const { onSelectUnlockedUser, lock } = useDeviceLock()

  const onSelectPinSwitchUserListItem = useCallback(
    (user: SwitchableUser) => {
      onSelectUnlockedUser(user.email)
      lock()
    },
    [lock, onSelectUnlockedUser],
  )

  const hasNoOtherUsers = switchableUsers.length === 0

  return (
    <>
      <SubHeader
        headlineKey="account:general.switchUsers"
        subHeadline={currentUser?.full_name}
      />
      <View style={styles.container}>
        <View style={styles.listContainer}>
          {hasNoOtherUsers ? (
            <Text style={styles.noUsers}>{t('account:general.noUsers')}</Text>
          ) : (
            <PinSwitchUserList
              users={switchableUsers}
              onSelect={onSelectPinSwitchUserListItem}
            />
          )}
        </View>
        <Button
          title={t('settings:signOut')}
          onPress={signOut}
          color={'#ed6353'}
        />
        <TextLink
          text={
            hasSkippedPin
              ? t('account:general.signOutAndSetPIN')
              : t('account:general.resetCurrentPIN')
          }
          onPress={resetCurrentPinAndSignOut}
          containerStyles={styles.textLink}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 15,
  },
  listContainer: {
    flex: 1,
    marginBottom: 15,
  },
  noUsers: {
    textAlign: 'center',
    marginVertical: 60,
    fontFamily: Fonts.regular,
    fontSize: 15,
    color: '#3d3d3d',
  },
  textLink: {
    marginTop: 10,
  },
})
