import { Colors } from 'constants/Colors'
import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const SvgCollapseArrows = ({
  width = 29,
  height = 29,
  color = Colors.blue,
}: Props) => (
  <Svg width={width} height={height} viewBox="0 0 29 29" fill={color}>
    <Path
      d="M16.0624 12.9449C15.8358 12.7183 15.7085 12.411 15.7085 12.0905L15.7085 6.04883C15.7085 5.72836 15.8358 5.42101 16.0624 5.19441C16.289 4.9678 16.5964 4.84049 16.9168 4.84049C17.2373 4.84049 17.5446 4.9678 17.7712 5.19441C17.9979 5.42101 18.1252 5.72836 18.1252 6.04883L18.1252 9.16633L22.1006 5.19091C22.2129 5.07766 22.3465 4.98776 22.4938 4.92642C22.641 4.86507 22.799 4.83349 22.9585 4.83349C23.118 4.83349 23.2759 4.86507 23.4232 4.92642C23.5704 4.98776 23.7041 5.07766 23.8164 5.19091C23.9297 5.30324 24.0196 5.43688 24.0809 5.58413C24.1422 5.73138 24.1738 5.88931 24.1738 6.04883C24.1738 6.20834 24.1422 6.36628 24.0809 6.51353C24.0196 6.66077 23.9297 6.79441 23.8164 6.90674L19.8531 10.8822L22.9585 10.8822C23.279 10.8822 23.5863 11.0095 23.8129 11.2361C24.0395 11.4627 24.1668 11.77 24.1668 12.0905C24.1668 12.411 24.0395 12.7183 23.8129 12.9449C23.5863 13.1715 23.279 13.2988 22.9585 13.2988L16.9168 13.2988C16.5964 13.2988 16.289 13.1715 16.0624 12.9449Z"
      fill="white"
    />
    <Path
      d="M12.9454 16.0624C13.172 16.289 13.2993 16.5964 13.2993 16.9168V22.9585C13.2993 23.279 13.172 23.5863 12.9454 23.8129C12.7188 24.0395 12.4115 24.1668 12.091 24.1668C11.7705 24.1668 11.4632 24.0395 11.2366 23.8129C11.01 23.5863 10.8827 23.279 10.8827 22.9585V19.841L6.90724 23.8164C6.79491 23.9297 6.66127 24.0196 6.51402 24.0809C6.36677 24.1423 6.20884 24.1738 6.04932 24.1738C5.88981 24.1738 5.73187 24.1423 5.58463 24.0809C5.43738 24.0196 5.30374 23.9297 5.19141 23.8164C5.07815 23.7041 4.98826 23.5704 4.92691 23.4232C4.86557 23.2759 4.83398 23.118 4.83398 22.9585C4.83398 22.799 4.86557 22.641 4.92691 22.4938C4.98826 22.3466 5.07815 22.2129 5.19141 22.1006L9.15474 18.1252H6.04932C5.72885 18.1252 5.42151 17.9979 5.1949 17.7713C4.9683 17.5446 4.84099 17.2373 4.84099 16.9168C4.84099 16.5964 4.9683 16.289 5.1949 16.0624C5.42151 15.8358 5.72885 15.7085 6.04932 15.7085L12.091 15.7085C12.4115 15.7085 12.7188 15.8358 12.9454 16.0624Z"
      fill="white"
    />
  </Svg>
)
