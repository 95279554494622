import { gql } from '@apollo/client'
import { TREATMENT_GROUP_FIELDS } from 'components/AddTreatment/graphql'
import {
  SHEET_HEADER_FIELDS,
  SHEET_META_DATA_FIELDS,
} from 'components/SheetHeader/graphql'
import { TASK_NOTE } from 'components/Treatment/graphql'

export const SHEET_TYPE = gql`
  fragment SheetType on Sheet {
    id
    type
  }
`

export const GET_TIMEZONE_IN_MINUTES_FROM_UTC_TIME = gql`
  query getTimeZoneInMinutesFromUTCTime(
    $id: ID!
    $date: String!
    $time_zone: String!
  ) {
    getTimeZoneInMinutesFromUTCTime(id: $id, date: $date, time_zone: $time_zone)
  }
`

export const GET_SHEET_TYPE = gql`
  query getSheetType($id: ID!, $organisationId: ID!) {
    getSheet(id: $id, organisation_id: $organisationId) {
      ...SheetType
    }
  }
  ${SHEET_TYPE}
`

export const WORKFLOW_FIELDS = gql`
  fragment WorkflowFields on Consultation {
    workflow {
      id
      workflow_items {
        items {
          id
          name
          order
          completed_at
        }
      }
    }
  }
`

export const ZOOM_LEVEL_FIELD = gql`
  fragment ZoomLevel on Sheet {
    _zoom_level
  }
`

export const SHEET_CONSULTATION_DETAILS = gql`
  fragment SheetConsultationDetails on Sheet {
    consultation {
      admitted_at
      attending_vet_id
      description
      id
      origin_id
      origin_reference
      presenting_problems {
        id
        name
      }
      site_id
      status
      ...WorkflowFields
    }
  }
  ${WORKFLOW_FIELDS}
`

export const ANAESTHESIA_SHEET_FIELDS = gql`
  fragment AnaesthesiaSheetFields on Sheet {
    id
    consultation_id
    ...SheetMetaDataFields
  }
  ${SHEET_META_DATA_FIELDS}
`

const TASK_NOTES_BY_SHEET = gql`
  fragment TaskNotesBySheet on Sheet {
    tasks {
      items {
        ...TaskNotes
      }
    }
  }
  ${TASK_NOTE}
`

export const GET_SHEET_NOTES = gql`
  query getSheetNotes($id: ID!, $organisationId: ID!) {
    getSheet(id: $id, organisation_id: $organisationId) {
      ...TaskNotesBySheet
    }
  }
  ${TASK_NOTES_BY_SHEET}
`

export const GET_SHEET_DETAIL = gql`
  query getSheetDetail($id: ID!, $organisationId: ID!) {
    getSheet(id: $id, organisation_id: $organisationId) {
      id
      name
      consultation_id
      attending_vet
      additional_care_team
      sync_attending_vet_with_consultation
      attending_vet_tech
      attending_department
      sync_attending_department_with_consultation
      closed_at
      ...SheetConsultationDetails
    }
  }
  ${SHEET_CONSULTATION_DETAILS}
`

export const GET_SHEET_LOG_META_DATA = gql`
  query getSheetLogMetaData($sheet_id: ID!, $organisation_id: ID!) {
    getSheet(id: $sheet_id, organisation_id: $organisation_id) {
      name
      patient {
        name
        code
        contact {
          last_name
        }
      }
      consultation {
        origin_reference
      }
    }
  }
`

export const SUBSCRIBE_WORKFLOW = gql`
  subscription workflow($consultationId: ID!) {
    workflow(consultation_id: $consultationId) {
      mutation
      data {
        id
        workflow_items {
          items {
            id
            name
            order
            completed_at
          }
        }
      }
    }
  }
`

export const WORKFLOW_FRAGMENT = gql`
  fragment WorkflowFragment on Consultation {
    workflow {
      id
      workflow_items {
        items {
          id
          name
          order
          completed_at
        }
      }
    }
  }
`

export const GET_CONSULTATION = gql`
  query getConsultation($id: ID!, $organisationId: ID!) {
    getConsultation(id: $id, organisation_id: $organisationId) {
      id
      admitted_at
      description
      presenting_problems {
        id
        name
      }
      discharged_at
      site_id
      status
      origin_id
      origin_reference
      ...WorkflowFragment
    }
  }
  ${WORKFLOW_FRAGMENT}
`

export const GET_SHEET = gql`
  query getSheet(
    $id: ID!
    $organisation_id: ID!
    $fromISODate: String!
    $toISODate: String!
  ) {
    getSheet(id: $id, organisation_id: $organisation_id) {
      _zoom_level @client
      treatments {
        items {
          ...TreatmentGroupFields
        }
      }
      ...SheetHeaderFields
    }
  }
  ${TREATMENT_GROUP_FIELDS}
  ${SHEET_HEADER_FIELDS}
`

export const UPDATE_SHEET = gql`
  mutation updateSheet($input: UpdateSheetInput!) {
    updateSheet(input: $input) {
      id
      closed_at
      patient_id
      consultation_id
      attending_department
      additional_care_team
      ...SheetMetaDataFields
    }
  }
  ${SHEET_META_DATA_FIELDS}
`

export const RESTORE_SHEET = gql`
  mutation restoreSheet($input: RestoreSheetInput!) {
    restoreSheet(input: $input) {
      id
      closed_at
      patient_id
      consultation_id
      ...SheetMetaDataFields
    }
  }
  ${SHEET_META_DATA_FIELDS}
`

export const GET_CONDITIONAL_TREATMENT = gql`
  query getConditionalTreatment($treatment_id: ID!, $patient_id: ID!) {
    getConditionalTreatment(
      treatment_id: $treatment_id
      patient_id: $patient_id
    ) {
      id
      name
      organisation_id
      product_id
      instructions
      is_billable
      is_auto_completed
      created_at
      template_treatment_id
      product {
        is_bundle
      }
      medicine_dosage_info {
        is_cri
        calculate_base
        concentration_volume_unit
        concentration_weight_unit
        concentration
        is_diluted
        diluted_concentration_volume_unit
        diluted_concentration_weight_unit
        diluted_concentration
        is_cri
        infusion_rate
        route_of_administration
        dosage
        dosage_weight_unit
        dosage_patient_weight_unit
      }
      schedule {
        repeat
        start_at
        frequency
        repeat_until
        repeat_count
        duration
        time_window
        enable_staffed_hour
        type
        frequencies
        treatment_frequency_id
      }
      treatment_options {
        is_single
        products {
          id
          name
          code
          type
          track_vital
          sites
          is_fixed
          quantity
          row_order
          parent_product {
            id
            name
            order
          }
        }
        product_groups {
          id
          name
          order
          products {
            items {
              id
              name
              type
              code
            }
          }
        }
      }
    }
  }
`

export const GET_ALL_SHEET_LOGS_BY_ORGANISATION_ID = gql`
  query getAllSheetLogsByOrganisationId(
    $sheet_id: String!
    $organisation_id: String!
  ) {
    getAllSheetLogsByOrganisationId(
      sheet_id: $sheet_id
      organisation_id: $organisation_id
    ) {
      date
      time
      user_account
      veterinary_role
      entity
      change_type
      sheet_group
      product_name
      schedule
      changed_from
      changed_to
      notes
      created_at
    }
  }
`

export const DELETE_SHEET = gql`
  mutation deleteSheet($input: DeleteSheetInput!) {
    deleteSheet(input: $input)
  }
`

export const CREATE_WORKFLOW = gql`
  mutation createWorkflow($input: CreateWorkflowInput!) {
    createWorkflow(input: $input) {
      id
      workflow_items {
        items {
          id
          name
          order
          completed_at
        }
      }
    }
  }
`

export const UPDATE_WORKFLOW = gql`
  mutation updateWorkflow($input: UpdateWorkflowInput!) {
    updateWorkflow(input: $input) {
      id
      workflow_items {
        items {
          id
          name
          order
          completed_at
        }
      }
    }
  }
`

export const UPDATE_WORKFLOW_ITEM = gql`
  mutation updateWorkflowItem($input: UpdateWorkflowItemInput!) {
    updateWorkflowItem(input: $input) {
      id
      order
      completed_at
    }
  }
`

export const GENERATE_SHEET_REPORT = gql`
  mutation generateSheetReport($input: GenerateSheetReportInput!) {
    generateSheetReport(input: $input)
  }
`
