import React, { useCallback, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useNavigation, useRoute } from '@react-navigation/native'
import { toast } from 'components/common'
import {
  DialogStates,
  HeightValue,
} from 'components/common/Dialog/Dialog.types'
import { Drawer } from 'components/common/Drawer/Drawer'
import { DrawerProvider } from 'components/common/Drawer/DrawerContext'
import { LoadingConditionalRender } from 'components/common/LoadingOverlay'
import { SheetScreenRouteProp } from 'components/Sheet/Sheet'
import { SheetInfo } from 'components/SheetInfo'
import { Task } from 'components/Task/types'
import { getIsFluidStyleTaskDisplay } from 'components/Task/utils/getTreatmentInfo'
import {
  TreatmentChildLevel,
  TreatmentSheetFirstLevel,
} from 'components/Treatment/common/types'
import {
  UPDATE_TREATMENT_GROUP,
  UPDATE_TREATMENTS_ORDER,
} from 'components/Treatment/graphql'
import {
  BulkTasks,
  getSubTreatmentNearestFirstTasks,
} from 'components/Treatment/utils/getSubTreatmentsNearestFirstTasks'
import { TreatmentSetupDrawerContent } from 'components/TreatmentSetup/TreatmentSetupDrawerContent'
import { VitalsChart } from 'components/Vitals/VitalsChart'
import { Routes } from 'constants/Routes'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { client } from 'src/apollo/client'
import { useDischargePatient } from 'src/components/DischargePatient/useDischargePatient'
import { getTreatmentsNoSameTimeTask } from 'src/components/Treatment/utils/getSubTreatmentsNearestFirstTasks'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { getSheet_getSheet_treatments_items_treatments_items as SubTreatment } from 'src/types/getSheet'
import { UpdateTreatmentOrderInput } from 'src/types/globalTypes'
import {
  updateTreatmentGroup as UpdateTreatmentGroupT,
  updateTreatmentGroupVariables,
} from 'src/types/updateTreatmentGroup'
import {
  updateTreatmentsOrder,
  updateTreatmentsOrderVariables,
} from 'src/types/updateTreatmentsOrder'
import { getSheet_getSheet as Sheet } from 'types/getSheet'

import { SheetDialogPicker } from './Dialog/SheetDialogPicker'
import { EditTreatmentGroup } from './EditTreatmentGroup'
import { BulkTaskActionsDrawer } from './GridDrawers/BulkTaskActionsDrawer'
import { BulkTaskCreateDrawer } from './GridDrawers/BulkTaskCreateDrawer'
import { TaskActionsDrawer } from './GridDrawers/TaskActionDrawer'
import { TreatmentActionDrawer } from './GridDrawers/TreatmentActionDrawer'
import { GridFlatList } from './GridFlatList'
import { getTimeInGridToNearestSegment } from './GridTimeLine/utils/getTimeInGridToNearestSegment'
import { HeaderButton } from 'components/SubHeader/SubHeader'
import { PatientInfoDrawerPortal } from 'components/PatientPanel/DrawerPortal'
import { DrawerAction } from 'components/Sheet/ReadySheet'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'

type Props = {
  sheet?: Sheet | null
  dialogOpen?: DialogStates
  /** Is loading a different day range */
  isLoading: boolean
  patientId?: string
  showAnesthesiaChart?: boolean
  showMoreDetails?: boolean
  gridActionButton?: HeaderButton | null
  gridBackButton?: HeaderButton | null
  onPressShowMore?: () => void
  changeDialogOpen?: (dialogState: DialogStates) => void
  onPressPatientInfo?: () => void
  consultationId?: string
  selectDrawerAction: (action: DrawerAction | null) => void
}

type State = {
  dialogHeight?: HeightValue
  taskToEdit: Task | null
  treatmentToEdit: TreatmentChildLevel | null
}

const VITALS_DRAWER_HEIGHT = 340

export const GridManager: React.FC<Props> = React.memo(
  ({
    sheet,
    changeDialogOpen,
    dialogOpen = DialogStates.None,
    isLoading,
    patientId = '',
    showAnesthesiaChart = false,
    showMoreDetails = true,
    onPressShowMore,
    gridActionButton,
    gridBackButton,
    onPressPatientInfo,
    consultationId,
    selectDrawerAction,
  }) => {
    const { t } = useTranslation()
    const navigation = useNavigation()
    const { navigate } = navigation
    const route = useRoute<SheetScreenRouteProp>()
    const { params } = route
    const { initialDateInView, sheetId } = params

    const isFinalized = !!sheet?.closed_at
    const [{ organisationId }] = useOrganisation()

    const [state, setState] = useState<State>({
      dialogHeight: undefined,
      taskToEdit: null,
      treatmentToEdit: null,
    })
    const { timeSegment } = useTimeResolution()

    const { dischargePatient } = useDischargePatient(patientId)

    const [bulkTasksToComplete, setBulkTasksToComplete] =
      useState<BulkTasks | null>(null)

    const [bulkTreatmentsToCreate, setBulkTreatmentsToCreate] = useState<
      SubTreatment[]
    >([])
    const [treatmentGroupName, setTreatmentGroupName] = useState<string>('')

    const [isSheetInfoDrawerVisible, setIsSheetInfoDrawerVisible] =
      useState(false)
    const toggleSheetInfoDrawer = useCallback(
      () => setIsSheetInfoDrawerVisible(isVisible => !isVisible),
      [],
    )

    const [isBulkTaskDrawerVisible, setIsBulkTaskDrawerVisible] =
      useState(false)
    const toggleBulkTaskDrawer = useCallback(
      () => setIsBulkTaskDrawerVisible(isVisible => !isVisible),
      [],
    )

    const [isTaskActionsDrawerVisible, setIsTaskActionsDrawerVisible] =
      useState(false)

    const toggleTaskActionsDrawer = useCallback(
      () => setIsTaskActionsDrawerVisible(isVisible => !isVisible),
      [],
    )

    const [isBulkTaskCreateDrawerVisible, setIsBulkTaskCreateDrawerVisible] =
      useState(false)
    const toggleBulkTaskCreateDrawer = useCallback(
      () => setIsBulkTaskCreateDrawerVisible(isVisible => !isVisible),
      [],
    )

    const [isEditTreatmentDrawerVisible, setIsEditTreatmentDrawerVisible] =
      useState(false)

    const toggleEditTreatmentDrawer = useCallback(
      () => setIsEditTreatmentDrawerVisible(isVisible => !isVisible),
      [],
    )

    const [
      isConditionalTreatmentDrawerVisible,
      setIsConditionalTreatmentDrawerVisible,
    ] = useState(false)

    const toggleConditionalTreatmentDrawer = useCallback(
      () => setIsConditionalTreatmentDrawerVisible(isVisible => !isVisible),
      [],
    )

    const [isTreatmentGroupDrawerVisible, setIsTreatmentGroupDrawerVisible] =
      useState(false)
    const toggleTreatmentGroupDrawer = useCallback(
      () => setIsTreatmentGroupDrawerVisible(isVisible => !isVisible),
      [],
    )

    const [
      isTreatmentActionsDrawerVisible,
      setIsTreatmentActionsDrawerVisible,
    ] = useState(false)

    const toggleTreatmentActionsDrawer = useCallback(
      () => setIsTreatmentActionsDrawerVisible(isVisible => !isVisible),
      [],
    )

    const onToggleEditTreatmentDrawer = useCallback(() => {
      toggleEditTreatmentDrawer()
      toggleTreatmentActionsDrawer()
    }, [toggleEditTreatmentDrawer, toggleTreatmentActionsDrawer])

    // TODO: set init value as null. Because user does not click.
    const [timePress, setTimePress] = useState(new Date())
    const handleTaskPress = useCallback(
      (treatment: TreatmentChildLevel, task: Task, timeOfPress?: Date) => {
        setState({
          dialogHeight: treatment.tasks!.items!.length > 0 ? '80%' : 'auto',
          taskToEdit: task,
          treatmentToEdit: treatment,
        })
        if (timeOfPress) {
          setTimePress(timeOfPress)
        }
        toggleTaskActionsDrawer()
      },
      [toggleTaskActionsDrawer],
    )

    const handleTreatmentGroupPress = useCallback(
      (treatmentGroup: TreatmentSheetFirstLevel, timeOfPress: Date) => {
        const tasksToComplete = getSubTreatmentNearestFirstTasks(
          treatmentGroup,
          timeOfPress,
          timeSegment,
        ).filter(task => !task.treatment.discontinued_at)

        // Don't prompt for dialog if no tasks underneath
        if (tasksToComplete.length > 0) {
          toggleBulkTaskDrawer()
          setBulkTasksToComplete(tasksToComplete)
          return
        }

        const startTime = getTimeInGridToNearestSegment(
          timeSegment,
          timeOfPress,
        )
        const treatmentsToCreate = getTreatmentsNoSameTimeTask(
          treatmentGroup,
          startTime,
        )
        if (treatmentsToCreate.length > 0) {
          setTimePress(startTime)
          toggleBulkTaskCreateDrawer()
          setBulkTreatmentsToCreate(treatmentsToCreate)
          setTreatmentGroupName(treatmentGroup.name)
        }
      },
      [timeSegment, toggleBulkTaskCreateDrawer, toggleBulkTaskDrawer],
    )

    const handleTreatmentGroupTitle = useCallback(
      (treatment: TreatmentChildLevel) => {
        toggleTreatmentGroupDrawer()
        setState(curState => ({
          ...curState,
          treatmentToEdit: treatment,
          dialogHeight: 'auto',
        }))
      },
      [toggleTreatmentGroupDrawer],
    )

    const handleTreatmentPress = useCallback(
      (treatment: TreatmentChildLevel) => {
        const hasConditionalFields = !!treatment.conditional
        if (isFinalized && hasConditionalFields) return
        if (hasConditionalFields) {
          toggleConditionalTreatmentDrawer()
        } else {
          toggleTreatmentActionsDrawer()
        }

        setState(curState => ({
          ...curState,
          treatmentToEdit: treatment,
          dialogHeight: 'auto',
        }))
      },
      [
        toggleConditionalTreatmentDrawer,
        toggleTreatmentActionsDrawer,
        isFinalized,
      ],
    )

    const hideDialog = useCallback(() => {
      changeDialogOpen?.(DialogStates.None)
      setState(curState => ({
        ...curState,
        dialogHeight: undefined,
        taskToEdit: null,
      }))
    }, [changeDialogOpen])

    const handlePendingDischargePatient = useCallback(
      async (name: string, dischargeNotes: string) => {
        hideDialog()
        await dischargePatient(name, dischargeNotes)
        navigate(Routes.Patients, {})
      },
      [dischargePatient, hideDialog, navigate],
    )

    const [updateNewTreatmentsOrder, { loading: submitting }] = useMutation<
      updateTreatmentsOrder,
      updateTreatmentsOrderVariables
    >(UPDATE_TREATMENTS_ORDER, {
      onError: err => {
        toast.error(err.message)
      },
      onCompleted: () => {
        toast.success(t('sheet:update.success'))
      },
    })

    const [updateTreatment] = useMutation<
      UpdateTreatmentGroupT,
      updateTreatmentGroupVariables
    >(UPDATE_TREATMENT_GROUP)

    const submitGroupName = (newName: string) => {
      const treatmentId = state.treatmentToEdit?.id ?? ''
      if (newName === '' || treatmentId === '') return
      updateTreatment({
        variables: {
          input: {
            id: treatmentId,
            organisation_id: organisationId,
            patient_id: patientId,
            sheet_id: sheetId,
            name: newName,
          },
        },
      })
      client.writeFragment({
        id: `Treatment:${treatmentId}`,
        fragment: gql`
          fragment TreatmentGroupName on Treatment {
            name
          }
        `,
        data: {
          name: newName,
          __typename: 'Treatment',
        },
      })
    }

    const handleSubmit = async (
      treatmentList: SubTreatment[],
      groupName: string,
    ) => {
      if (!sheet) return
      const input: UpdateTreatmentOrderInput[] = []
      submitGroupName(groupName)

      treatmentList.forEach((newTreatment, index) => {
        const newerNewTreatment = {
          id: newTreatment.id,
          organisation_id: organisationId,
          patient_id: patientId,
          sheet_id: sheet.id,
          order: index,
        }

        input.push(newerNewTreatment)
      })

      await updateNewTreatmentsOrder({
        variables: { input },
      })
      toggleTreatmentGroupDrawer()
    }

    const isFluidStyleTaskDisplay = getIsFluidStyleTaskDisplay(
      state.treatmentToEdit,
    )

    return (
      <DrawerProvider>
        <View testID="Grid" style={styles.container}>
          <LoadingConditionalRender isLoading={isLoading}>
            <GridFlatList
              sheet={sheet}
              patientId={patientId}
              onPressTask={handleTaskPress}
              onPressTreatment={handleTreatmentPress}
              onPressTreatmentGroup={handleTreatmentGroupPress}
              onPressTreatmentGroupTitle={handleTreatmentGroupTitle}
              showAnesthesiaChart={showAnesthesiaChart}
              initialDateInView={initialDateInView}
              showMoreDetails={showMoreDetails}
              onPressShowMore={onPressShowMore}
              toggleSheetInfoDrawer={toggleSheetInfoDrawer}
              gridActionButton={gridActionButton}
              gridBackButton={gridBackButton}
              onPressPatientInfo={onPressPatientInfo}
              consultationId={consultationId}
              selectDrawerAction={selectDrawerAction}
            />
          </LoadingConditionalRender>
          <PatientInfoDrawerPortal />
        </View>
        <Drawer
          height={VITALS_DRAWER_HEIGHT}
          render={data => {
            if (!data || !patientId) return
            const { productId, productName, unit } = data

            return (
              <VitalsChart
                productName={productName}
                patientId={patientId}
                productId={productId}
                unit={unit}
              />
            )
          }}
        />
        <BulkTaskActionsDrawer
          visible={isBulkTaskDrawerVisible}
          bulkTasksToComplete={bulkTasksToComplete}
          patientId={patientId}
          sheetId={sheetId}
          toggleBulkTaskDrawer={toggleBulkTaskDrawer}
        />
        <SheetAwareSideDrawer
          visible={isSheetInfoDrawerVisible}
          onClose={toggleSheetInfoDrawer}
          title={sheet?.name ?? ' '}
        >
          <SheetInfo
            navigation={navigation}
            route={route}
            toggleDrawer={toggleSheetInfoDrawer}
          />
        </SheetAwareSideDrawer>
        <BulkTaskCreateDrawer
          sheetId={sheetId}
          patientId={patientId}
          timeSegment={timeSegment}
          timePress={timePress}
          treatmentGroupName={treatmentGroupName}
          sheetName={sheet?.name ?? ''}
          bulkTreatmentsToCreate={bulkTreatmentsToCreate}
          visible={isBulkTaskCreateDrawerVisible}
          toggleBulkTaskCreateDrawer={toggleBulkTaskCreateDrawer}
        />
        <TaskActionsDrawer
          visible={isTaskActionsDrawerVisible}
          toggleTaskActionsDrawer={toggleTaskActionsDrawer}
          title={state.treatmentToEdit?.name}
          taskToEdit={state.taskToEdit}
          timeSegment={timeSegment}
          treatmentToEdit={state.treatmentToEdit}
          isFinalized={isFinalized}
          timePress={timePress}
          patientId={patientId}
          sheetId={sheetId}
          isFluidStyleTaskDisplay={isFluidStyleTaskDisplay}
        />

        <SheetAwareSideDrawer
          visible={isTreatmentGroupDrawerVisible}
          onClose={toggleTreatmentGroupDrawer}
          title={'Edit Sheet/Treatment group'}
        >
          <EditTreatmentGroup
            submitting={submitting}
            handleSubmit={handleSubmit}
            treatmentToEdit={state.treatmentToEdit}
          />
        </SheetAwareSideDrawer>

        <SheetAwareSideDrawer
          title={t('treatment:setup')}
          visible={isConditionalTreatmentDrawerVisible}
          onClose={toggleConditionalTreatmentDrawer}
        >
          <TreatmentSetupDrawerContent
            onDone={toggleConditionalTreatmentDrawer}
            treatmentId={state.treatmentToEdit?.id!}
            patientId={patientId}
            sheetId={sheetId}
          />
        </SheetAwareSideDrawer>

        <TreatmentActionDrawer
          sheetId={sheetId}
          patientId={patientId}
          isFinalized={isFinalized}
          treatmentToEdit={state.treatmentToEdit!}
          isEditTreatmentDrawerVisible={isEditTreatmentDrawerVisible}
          isTreatmentActionsDrawerVisible={isTreatmentActionsDrawerVisible}
          toggleTreatmentActionsDrawer={toggleTreatmentActionsDrawer}
          toggleEditTreatmentDrawer={toggleEditTreatmentDrawer}
          onToggleEditTreatmentDrawer={onToggleEditTreatmentDrawer}
        />

        <SheetDialogPicker
          dialogHeight={state.dialogHeight}
          dialogOpen={dialogOpen}
          sheetId={sheetId}
          handlePendingDischargePatient={handlePendingDischargePatient}
          hideDialog={hideDialog}
          patientId={patientId}
          taskToEdit={state.taskToEdit}
          toggleEditTreatmentDrawer={toggleEditTreatmentDrawer}
        />
      </DrawerProvider>
    )
  },
)

GridManager.displayName = 'GridManager'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
})
