import React, { useCallback, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Fonts } from 'constants/Fonts'
import { noop, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  View,
} from 'react-native'

import { FormField } from '../Form/FormField'
import { PossibleSelectValues, Props } from './index.types'
import { getNextSelected } from 'components/common/FilterTreeSelect/getNextSelected'
const MENU_ITEM_MIN_LENGTH = 200

// eslint-disable-next-line
export function TreeSelect<T extends PossibleSelectValues>({
  a11yLabel,
  disabled = false,
  label,
  onChange = noop,
  options,
  placeholder = ' ',
  selected,
  status,
  style,
  required = false,
  hideChevron,
  iconLeft,
}: Props<T>) {
  const { t } = useTranslation()
  const [inputWidth, setInputWidth] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const openMenu = useCallback((event: GestureResponderEvent) => {
    const target = event.currentTarget
    requestAnimationFrame(() => setAnchorEl(target as any))
  }, [])

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const onPress = (value: T) => {
    onChange(getNextSelected(value, selected, options))
  }

  const onInputLayout = useCallback((event: LayoutChangeEvent) => {
    setInputWidth(event.nativeEvent.layout.width)
  }, [])

  const getSelectedText = () => {
    if (isEmpty(selected)) {
      return placeholder
    }

    if (selected.length === 1) {
      const selectedOption = options
        .flatMap(option => [option, ...(option?.children ?? [])])
        .find(option => option.value === selected[0])
      return selectedOption?.text ?? placeholder
    }
    return selected.length.toString().concat(` ${t('general.filters')}`)
  }

  const selectedTextToShow = getSelectedText()

  return (
    <>
      <FormField
        a11yLabel={a11yLabel ? a11yLabel : `${label} Multi Tree Select`}
        active={!!anchorEl}
        disabled={disabled}
        required={required}
        label={label}
        onLayout={onInputLayout}
        onPress={openMenu}
        status={status}
        style={[styles.formField, style]}
        value={selectedTextToShow}
        hideChevron={hideChevron}
        iconLeft={iconLeft}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        onClose={closeMenu}
        open={!!anchorEl}
        PaperProps={{
          style: {
            width: Math.max(inputWidth, MENU_ITEM_MIN_LENGTH),
          },
        }}
      >
        {options.map((option, idx) => {
          const { text, value } = option
          const pickerLabel = text || `${value}`
          const isSelected = selected.indexOf(option.value) !== -1

          if (option.children) {
            return (
              <View key={idx}>
                <MenuItem
                  selected={isSelected}
                  onClick={() => onPress(option.value)}
                >
                  <Text
                    style={{
                      fontFamily: isSelected ? Fonts.bold : Fonts.regular,
                    }}
                  >
                    {pickerLabel}
                  </Text>
                </MenuItem>
                {option.children.map((childOption, childIdx) => {
                  const isChildSelected =
                    isSelected || selected.indexOf(childOption.value) !== -1
                  return (
                    <MenuItem
                      key={`${idx}-${childIdx}`}
                      selected={isChildSelected}
                      onClick={() => onPress(childOption.value)}
                    >
                      <Text
                        style={{
                          paddingLeft: 15,
                          fontFamily: isChildSelected
                            ? Fonts.bold
                            : Fonts.regular,
                        }}
                      >
                        {childOption.text}
                      </Text>
                    </MenuItem>
                  )
                })}
              </View>
            )
          }
          return (
            <MenuItem
              key={idx}
              selected={isSelected}
              onClick={() => onPress(option.value)}
            >
              <Text
                style={{
                  fontFamily: isSelected ? Fonts.bold : Fonts.regular,
                }}
              >
                {pickerLabel}
              </Text>
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

const styles = StyleSheet.create({
  formField: {
    minWidth: 96,
  },
})
