import { differenceInMinutes } from 'date-fns'
import { Status } from 'src/types/globalTypes'

const ONE_DAY_IN_MINUTES = 60 * 24
const ONE_HOUR_IN_MINUTES = 60
export const getStatusAndTimeText = (date: Date, timeWindow: number) => {
  const now = new Date()
  let minutes = differenceInMinutes(date, now)
  let status = Status.PENDING
  if (minutes > 0) {
    status = Status.PENDING
  } else if (minutes + timeWindow >= 0) {
    status = Status.DUE
  } else {
    status = Status.MISSED
  }
  const statusString = `${status[0]}${status.substr(1).toLocaleLowerCase()} `
  let symbolString = ''
  if (minutes < 0) {
    minutes = Math.abs(minutes)
    symbolString = '-'
  }

  const days = Math.floor(minutes / ONE_DAY_IN_MINUTES)
  const dayString = days ? `${days} ${days === 1 ? 'day' : 'days'}, ` : ''
  minutes = minutes % ONE_DAY_IN_MINUTES
  const hours = Math.floor(minutes / ONE_HOUR_IN_MINUTES)
  const hourString = hours ? `${hours} ${hours === 1 ? 'hr' : 'hrs'}, ` : ''
  minutes = minutes % ONE_HOUR_IN_MINUTES
  const minutesString = `${minutes} ${minutes === 1 ? 'min' : 'mins'}`
  const text =
    statusString + symbolString + dayString + hourString + minutesString
  return { status, text }
}
