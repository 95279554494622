import React from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'

import { SvgCheck } from 'src/components/Icons'
import { shade } from 'src/utils/shade'

type Props = {
  label: string
  options: string[]
  selected: string | null
  onChange: (selected: string | null) => void
}

export const ColorPicker: React.FC<Props> = ({
  options,
  selected,
  onChange,
  label,
}) => {
  // Added to ensure that the options are unique
  const uniqueOptions = [...new Set(options)]

  return (
    <View style={styles.container}>
      <Text style={styles.labelText} accessibilityLabel={label}>
        {label}
      </Text>
      <View style={styles.optionsContainer}>
        {uniqueOptions.map(option => (
          <View style={styles.optionContainer} key={option}>
            <TouchableOpacity
              onPress={() => {
                onChange(option === selected ? null : option)
              }}
              style={[
                styles.option,
                {
                  backgroundColor: option,
                  borderColor: shade(option, -50),
                },
              ]}
            />
            {option === selected && (
              <View pointerEvents="none" style={styles.check}>
                <SvgCheck color="black" />
              </View>
            )}
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    borderLeftColor: Colors.white,
    height: 60,
    paddingLeft: 16,
    paddingTop: 10,
    paddingBottom: 10,
  },
  labelText: {
    fontFamily: Fonts.regular,
    fontSize: 13,
    marginBottom: 4,
    lineHeight: 19,
    color: Colors.contentTertiary,
  },
  optionsContainer: {
    flexDirection: 'row',
  },
  optionContainer: {
    marginRight: 8,
  },
  option: {
    width: 20,
    height: 20,
    borderWidth: 1,
  },
  check: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
})
