import { Linking } from 'react-native'
import { environment } from 'src/config'
import { contactSupportEmail } from 'constants/constants'

export const mailTo = async (email: string, subject: string, body: string) => {
  await Linking.openURL(`mailto:${email}?subject=${subject}&body=${body}`)
}

export const mailToSupport = async (instanceUrl: string, username: string) => {
  const subject = 'Vet Radar Query'

  const lineBreaker = environment.isWeb ? '%0D%0A' : '\n'

  const body = `ezyVet Site: ${instanceUrl}${lineBreaker}Username: ${username}${lineBreaker}${lineBreaker}*** Please provide at least 1 detailed example of your query. This is important for our team to provide fast and accurate assistance**`

  await mailTo(contactSupportEmail, subject, body)
}
