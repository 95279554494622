import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'

import { getSheet_getSheet_treatments_items_treatments_items_medicine_dosage_info as MedicineDosageInfo } from 'src/types/getSheet'
import { isNil } from 'lodash'

type Props = {
  medicineDosageInfo?: MedicineDosageInfo | null
}

export const DilutionHeader: React.FC<Props> = ({ medicineDosageInfo }) => {
  const { t } = useTranslation()
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isNativeStyle = isExSmallScreen || isSmallScreen

  const dilutedConcentration = medicineDosageInfo?.diluted_concentration ?? null
  const weightUnit =
    medicineDosageInfo?.diluted_concentration_weight_unit ?? null
  const volumeUnit =
    medicineDosageInfo?.diluted_concentration_volume_unit ?? null

  const dilutedRatioFactor =
    medicineDosageInfo?.diluted_concentration_ratio_factor ?? null
  const dilutedRatioVolume =
    medicineDosageInfo?.diluted_concentration_ratio_volume ?? null

  const isDiluted = medicineDosageInfo?.is_diluted ?? false

  const returnDilutedInfoLabel = () => {
    const concentrationUnit = `${weightUnit}/${volumeUnit}`
    let ratioLabel = ''

    if (!isNil(dilutedRatioFactor) && !isNil(dilutedRatioVolume)) {
      ratioLabel = `Ratio ${dilutedRatioFactor} : ${dilutedRatioVolume}`
    }
    const dilutedLabel = `${ratioLabel} New Concentration ${dilutedConcentration} ${concentrationUnit}`

    return dilutedLabel
  }

  if (
    !isDiluted ||
    isNil(dilutedConcentration) ||
    dilutedConcentration === 0 ||
    isNil(weightUnit) ||
    isNil(volumeUnit)
  ) {
    return null
  }
  return (
    <View
      style={[
        styles.treatmentDetailsContainer,
        {
          width: isNativeStyle ? '100%' : 'auto',
        },
      ]}
    >
      {!!medicineDosageInfo && (
        <View style={styles.treatmentValue}>
          <Text
            style={[
              styles.titleLabelText,
              styles.treatmentValueLabel,
              isNativeStyle && styles.labelTextMob,
            ]}
          >
            {t('task:taskAddEdit.dilutedLabel')}
          </Text>
          <Text
            style={[
              styles.labelText,
              styles.treatmentValueLabel,
              isNativeStyle && styles.labelTextMob,
            ]}
          >
            {returnDilutedInfoLabel()}
          </Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  titleLabelText: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 25,
  },
  labelText: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 25,
  },
  labelTextMob: {
    fontSize: 16,
    lineHeight: 20,
  },
  treatmentDetailsContainer: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderTopStartRadius: 8,
    minWidth: '60%',
  },
  treatmentValue: {
    paddingVertical: 5,
  },
  treatmentValueLabel: {
    paddingVertical: 2,
    textAlign: 'center',
  },
})
