import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color: string
  height?: number
  width?: number
}

export const SvgGraduationCap: React.FC<Props> = ({
  color,
  height = 18,
  width = 20,
}) => (
  <Svg
    width={width}
    height={height}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 72 72"
  >
    <G fill={color} fillRule="nonzero">
      <Path d="M36.0009 11.25C35.0896 11.25 34.1897 11.4047 33.3347 11.7029L1.77919 22.8935C0.710465 23.2801 0.00173159 24.2743 0.00173159 25.3901C0.00173159 26.5058 0.710465 27.5001 1.77919 27.8867L8.29278 30.1955C6.44783 33.0456 5.4016 36.4149 5.4016 39.961V43.0652C5.4016 46.2025 4.18663 49.4393 2.89291 51.9911C2.16168 53.4272 1.3292 54.8412 0.361723 56.1448C0.00173159 56.6198 -0.099516 57.2384 0.102979 57.8018C0.305474 58.3652 0.777963 58.785 1.36295 58.9286L8.56278 60.6961C9.03526 60.8176 9.5415 60.7293 9.95774 60.4752C10.374 60.2211 10.6665 59.8013 10.7565 59.3263C11.7239 54.5982 11.2402 50.3562 10.5202 47.3183C10.1602 45.7496 9.6765 44.1478 9.00152 42.6786V39.961C9.00152 36.6248 10.149 33.4764 12.1402 30.9577C13.5914 29.2455 15.4701 27.8646 17.6751 27.014L35.3371 20.198C36.2596 19.8445 37.3058 20.2864 37.6658 21.1922C38.0258 22.0981 37.5758 23.1255 36.6533 23.479L18.9913 30.2949C17.5963 30.8362 16.3701 31.6647 15.3689 32.6811L33.3234 39.0441C34.1784 39.3424 35.0784 39.497 35.9896 39.497C36.9008 39.497 37.8008 39.3424 38.6558 39.0441L70.2225 27.8867C71.2913 27.5111 72 26.5058 72 25.3901C72 24.2743 71.2913 23.2801 70.2225 22.8935L38.6671 11.7029C37.8121 11.4047 36.9121 11.25 36.0009 11.25ZM14.4014 52.7865C14.4014 56.6861 24.0762 60.7403 36.0009 60.7403C47.9256 60.7403 57.6003 56.6861 57.6003 52.7865L55.8791 36.7242L39.882 42.4024C38.6333 42.8443 37.3171 43.0652 36.0009 43.0652C34.6846 43.0652 33.3572 42.8443 32.1197 42.4024L16.1226 36.7242L14.4014 52.7865Z" />
    </G>
  </Svg>
)
