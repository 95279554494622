import { useQuery } from '@apollo/client'
import { addHours, endOfHour, startOfHour } from 'date-fns'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Colors } from 'src/design-system/theme'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import {
  getTaskPreview,
  getTaskPreviewVariables,
} from 'src/types/getTaskPreview'
import { AnimatedFadingHeader } from '../../common/AnimatedFadingHeader/AnimatedFadingHeader'
import { DUE_MISSED_STATUSES, UPCOMING_STATUSES } from './common'
import { GET_TASK_PREVIEW } from './graphql'
import { TaskList } from './sections/TaskList'
import { TaskPreviewTabs } from './sections/TaskPreviewTabs'
import { UpcomingTabSelectors } from './sections/UpcomingTabSelectors'
import { filterTaskPreviewTasks } from './taskPreviewUtils'
import { TaskPreviewTab, TaskPreviewTreatmentGroup } from './types'
import { PatientCard } from 'components/common/PatientCard'
import { useOrganisation } from 'src/context/organisation'

type Props = {
  patientId: string
  hasUnapprovedSheet?: boolean | null
  fromISODate: string
  dueTaskCount: number
  missedTaskCount: number
  tab?: TaskPreviewTab
}

const PATIENT_CARD_HEIGHT = 83
const DRAWER_HEADER_HEIGHT = 82

export const TaskPreviewContainer: React.FC<Props> = ({
  patientId,
  hasUnapprovedSheet,
  fromISODate,
  dueTaskCount,
  missedTaskCount,
  tab = TaskPreviewTab.UPCOMING,
}) => {
  const { height } = useBreakpoint()
  const { settingsMap } = useOrgSettings()

  const [activeTab, setActiveTab] = useState<TaskPreviewTab>(tab)
  const [startAt, setStartAt] = useState<string>(fromISODate)
  const [endAt, setEndAt] = useState<string>(fromISODate)
  const [taskCount, setTaskCount] = useState<number>(0)
  const [showCompleted, setShowCompleted] = useState(false)
  const [listScrollPosition, setListScrollPosition] = useState<number>(0)

  const [{ organisationId }] = useOrganisation()

  useEffect(() => {
    // Determine the start_at and end_at time on the initial load
    tab === TaskPreviewTab.DUE_MISSED
      ? handleDueMissed()
      : handleUpcoming(fromISODate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeTimeColumn = (date: string) => {
    setListScrollPosition(0)
    setStartAt(date)
  }

  const { data: taskPreview, loading } = useQuery<
    getTaskPreview,
    getTaskPreviewVariables
  >(GET_TASK_PREVIEW, {
    variables: {
      input: {
        patient_id: patientId,
        start_time: startAt,
        end_time: endAt,
        task_statuses:
          activeTab === TaskPreviewTab.UPCOMING
            ? UPCOMING_STATUSES
            : DUE_MISSED_STATUSES,
        limit: 1000,
        organisation_id: organisationId,
      },
    },
    skip: endAt <= startAt,
    fetchPolicy: 'cache-and-network',
  })

  const filteredTaskPreview = useMemo(() => {
    let counter = 0
    const filtered =
      taskPreview?.listTaskPreviewItemsByPatient.treatment_groups.filter(
        treatment => {
          const sheets = treatment.data.filter(sheet => {
            const tasks = filterTaskPreviewTasks(sheet.data, showCompleted)
            counter += tasks.length
            return !isEmpty(tasks) ? { ...sheet, data: tasks } : null
          })
          return !isEmpty(sheets) ? { ...treatment, data: sheets } : null
        },
      ) ?? []
    setTaskCount(counter)
    return filtered
  }, [
    showCompleted,
    taskPreview?.listTaskPreviewItemsByPatient.treatment_groups,
  ])

  const handleDueMissed = () => {
    setListScrollPosition(0)
    setStartAt(addHours(new Date(), -24).toISOString())
    setEndAt(new Date().toISOString())
    setActiveTab(TaskPreviewTab.DUE_MISSED)
  }

  const handleUpcoming = (start_at?: string) => {
    setListScrollPosition(0)
    const current = start_at ? new Date(start_at) : new Date()
    setStartAt(startOfHour(current).toISOString())
    setEndAt(endOfHour(current).toISOString())
    setActiveTab(TaskPreviewTab.UPCOMING)
  }

  useEffect(() => {
    if (activeTab === TaskPreviewTab.UPCOMING) {
      const current = new Date(startAt)
      setEndAt(endOfHour(current).toISOString())
    }
  }, [activeTab, startAt])

  const containerStyle = useMemo(
    () => ({
      ...styles.container,
      height: height - DRAWER_HEADER_HEIGHT,
    }),
    [height],
  )

  return (
    <View style={containerStyle}>
      <AnimatedFadingHeader
        scrollOffset={listScrollPosition}
        maxHeight={PATIENT_CARD_HEIGHT}
      >
        <PatientCard
          patientId={patientId}
          hasUnapprovedSheet={hasUnapprovedSheet}
        />
      </AnimatedFadingHeader>
      <TaskPreviewTabs
        activeTab={activeTab}
        dueTaskCount={dueTaskCount}
        missedTaskCount={missedTaskCount}
        handleDueMissed={handleDueMissed}
        handleUpcoming={() => {
          handleUpcoming()
        }}
      />
      {activeTab === TaskPreviewTab.UPCOMING ? (
        <UpcomingTabSelectors
          startAt={startAt}
          initialStartAt={fromISODate}
          taskCount={taskCount}
          showCompleted={showCompleted}
          setShowCompleted={setShowCompleted}
          updateStartAt={handleChangeTimeColumn}
        />
      ) : null}
      {loading || !taskPreview ? (
        <View style={styles.activityIndicator}>
          <ActivityIndicator size={'large'} />
        </View>
      ) : (
        <TaskList
          taskPreviewData={filteredTaskPreview as TaskPreviewTreatmentGroup[]}
          timeFormat={settingsMap.PREFERRED_TIME_FORMAT?.value}
          setScrollPosition={setListScrollPosition}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    backgroundColor: Colors.Backgrounds.transparent,
    borderTopWidth: 1,
    borderTopColor: Colors.Borders.primary,
  },
  activityIndicator: {
    paddingVertical: 24,
  },
})
