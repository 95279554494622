import React from 'react'
import { Fonts } from 'constants/Fonts'
import { StyleSheet, Text, View, TextStyle, ViewStyle } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { messageBoxColors } from 'components/common/Avatar/AvatarWithMessage'

export type TagTitle = keyof typeof styleMap
type Props = {
  testID?: string
  title: TagTitle | ''
  style?: ViewStyle
  textStyle?: TextStyle
}

const defaultTagStyles = StyleSheet.create({
  tag: {
    borderRadius: 9,
    height: 24,
    paddingVertical: 1,
    paddingHorizontal: 8,
    backgroundColor: Colors.contentPrimary,
  },
})

const defaultTextStyles = StyleSheet.create({
  tag: {
    fontFamily: Fonts.bold,
    fontSize: 16,
    lineHeight: 22,
    color: Colors.white,
  },
})

const sexColors = {
  male: {
    backgroundColor: Colors.tag.m,
  },
  maleNeutered: {
    backgroundColor: Colors.tag.mn,
  },
  female: {
    backgroundColor: Colors.tag.peach,
  },
  femaleSpayed: {
    backgroundColor: Colors.tag.peach,
  },
  unknown: {
    backgroundColor: Colors.darkGrey,
  },
}

const equineSexColors = {
  Colt: sexColors.male,
  Filly: sexColors.female,
  Gelding: sexColors.maleNeutered,
  Mare: sexColors.female,
  Stallion: sexColors.male,
  Unknown: sexColors.unknown,
}

const patientSexColors = {
  F: sexColors.female,
  FS: sexColors.femaleSpayed,
  M: sexColors.male,
  MN: sexColors.maleNeutered,
  U: sexColors.unknown,
}

/* eslint-disable react-native/no-unused-styles */
const styleMap = StyleSheet.create({
  // Sex Styles
  ...equineSexColors,
  ...patientSexColors,

  // Resuscitate Styles
  DNR: {
    backgroundColor: Colors.tag.dnr,
  },
  BLS: {
    backgroundColor: Colors.tag.bls,
  },
  ALS: {
    backgroundColor: Colors.tag.als,
  },
  DECEASED: {
    backgroundColor: Colors.contentPrimary,
  },

  // Attitude Styles
  CAUTION: messageBoxColors.BAD,
  FRIENDLY: messageBoxColors.GOOD,
  UNFRIENDLY: messageBoxColors.WARNING,
  UNKNOWN: messageBoxColors.UNKNOWN,

  // Status Styles
  ACTIVE: {
    backgroundColor: Colors.blue,
  },
  INACTIVE: {
    backgroundColor: Colors.contentTertiary,
  },
  Inactive: {
    // Repeat but should make this a uniform approach like lowercase everything
    backgroundColor: Colors.contentTertiary,
  },
  Template: {
    backgroundColor: Colors.tag.template,
  },
  Finalized: {
    backgroundColor: Colors.green2,
  },
  complication: {
    backgroundColor: Colors.blue,
  },
})

export const TagV2: React.FC<Props> = ({ testID, title, style, textStyle }) => {
  if (!title) return null
  return (
    <View
      testID={testID}
      style={[defaultTagStyles.tag, styleMap[title], style]}
    >
      <Text selectable={false} style={[defaultTextStyles.tag, textStyle]}>
        {title}
      </Text>
    </View>
  )
}
