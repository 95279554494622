import React, { useState } from 'react'
import { ChartText } from 'components/common/Chart/ChartText'
import { GraphData, Tick } from 'components/common/Chart/types'
import { Colors } from 'constants/Colors'
import { scaleLinear, scaleTime } from 'd3-scale'
import { timeHour } from 'd3-time'
import { extent } from 'd3-array'
import { line } from 'd3-shape'
import { format } from 'date-fns'
import { mean } from 'lodash'
import { StyleSheet, View } from 'react-native'
import Svg, { G, Line, Path } from 'react-native-svg'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'

type Props = {
  data: GraphData[]
  forceGraphLayout?: boolean
  timeEnd: Date
  timeStart: Date
}

const chartSettings = {
  marginHorizontal: 20,
  marginTop: 12,
  marginBottom: 40, // includes space for x-axis
  valueBufferFactor: 0.2,
}

const everyTwoHours = timeHour.every(2)
const everyThreeHours = timeHour.every(3)

export const VitalsGraph: React.FC<Props> = ({
  data,
  forceGraphLayout,
  timeEnd,
  timeStart,
}) => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isMobileScreen = isExSmallScreen || isSmallScreen
  const [svgLayout, setSvgLayout] = useState({
    width: 0,
    height: 0,
  })
  const pixelHeight = svgLayout.height
  const pixelWidth = svgLayout.width

  const boundedWidth = pixelWidth - chartSettings.marginHorizontal
  const boundedHeight = pixelHeight - chartSettings.marginBottom
  const viewBox = `0 0 ${pixelWidth} ${pixelHeight}`

  const xScale = scaleTime()
    // end the graph domain at 'NOW' aka timeEnd
    .domain([timeHour.floor(timeStart), timeHour.ceil(timeEnd)])
    .range([0, boundedWidth])

  const yExtent = extent(data, e => e.value) as [number, number]
  const yExtentMean = mean(yExtent)
  /* TODO: Toggle whether the component has any value buffer (ie: value more in
     the 'center'), makes sense todo for some vitals (temp, weight) only */
  const yExtentBuffer = 0.4
  const yExtentBuffered = [
    yExtent[0] - yExtentBuffer * yExtentMean,
    yExtent[1] + yExtentBuffer * yExtentMean,
  ]

  const yScale = scaleLinear()
    .nice()
    .domain([
      // Lock domain to positive values only
      yExtentBuffered[0] < 0 ? 0 : yExtentBuffered[0],
      yExtentBuffered[1],
    ])
    .range([boundedHeight, 0])

  const XAxisHours = isMobileScreen ? everyThreeHours : everyTwoHours

  const xTicks = xScale.ticks(XAxisHours!).map((value: Date) => {
    return {
      value,
      offset: xScale(value) ?? 0,
    }
  })

  const yTicks = yScale.ticks().map(value => {
    return {
      value,
      offset: yScale(value) ?? 0,
    }
  })

  const svgValuePath =
    line<GraphData>()
      .x(d => xScale(d.date) ?? 0)
      // render negative values as 0
      .y(d => yScale(d.value < 0 ? 0 : d.value) ?? 0)(data) ?? ''

  // dont render if container w|h is 0 or no value path
  const shouldRenderSVG = !!(
    (forceGraphLayout && svgValuePath.length) ||
    (pixelWidth && pixelHeight && svgValuePath.length)
  )

  return (
    <View
      style={styles.graphBorder}
      onLayout={({
        nativeEvent: {
          layout: { width, height },
        },
      }) => {
        setSvgLayout({ width, height })
      }}
    >
      {shouldRenderSVG ? (
        <Svg
          accessibilityLabel="Vitals Graph SVG Chart"
          fill="white"
          height="100%"
          width="100%"
          viewBox={viewBox}
        >
          <G
            // add some offset for the bounded area's margins
            // we can use zero values to equal == offset after this
            x={chartSettings.marginHorizontal}
            y={chartSettings.marginTop}
            stroke={Colors.lightGrey}
          >
            <XAxis ticks={xTicks} yPosition={boundedHeight + 4} />
            <YAxis ticks={yTicks} boundedWidth={boundedWidth} />
            <G fill="transparent">
              <Path stroke="#26A69A" strokeWidth={3} d={svgValuePath} />
            </G>
          </G>
        </Svg>
      ) : null}
    </View>
  )
}

type XAxisProps = {
  ticks: Tick<Date>[]
  yPosition: number
}
const formatTime = (x: Date, timeFormat: string) => format(x, timeFormat)
const XAxis = ({ ticks, yPosition }: XAxisProps) => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isMobileScreen = isExSmallScreen || isSmallScreen
  const { adminTimeFormat } = useAdminTimeFormat()
  return (
    <G y={yPosition}>
      {ticks.map(({ value, offset }, i) => {
        return (
          <G key={value.toISOString()} x={offset} y={0}>
            {i !== 0 && (
              <>
                <Line y1={-2} y2={-yPosition - 2} />
                <ChartText
                  fontSize={11}
                  fontSizeSmall={9}
                  isSmall={isMobileScreen}
                  transform={'translate(0 16)'}
                >
                  {formatTime(value, adminTimeFormat)}
                </ChartText>
              </>
            )}
          </G>
        )
      })}
    </G>
  )
}

type YAxisProps = { ticks: Tick<number>[]; boundedWidth: number }
const YAxis = ({ ticks, boundedWidth }: YAxisProps) => {
  return (
    <G>
      {ticks.map(({ value, offset }) => {
        return (
          <G key={value} y={offset} x={0}>
            <Line y1={-2} y2={-2} x1={18} x2={boundedWidth} />
            <ChartText fontSize={11} transform={'translate(-10 2)'}>
              {value}
            </ChartText>
          </G>
        )
      })}
    </G>
  )
}

const styles = StyleSheet.create({
  graphBorder: {
    paddingHorizontal: 10,
    height: 300,
    backgroundColor: Colors.white,
  },
})
