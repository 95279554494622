import * as Yup from 'yup'

/**
 * @todo 12/07/21 VR-5797
 *
 * We're currently using this name constant to determine which template is part
 * of the emergency drugs drawer. This constant will be removed in the future
 * when we switch this functionality over to using a typed template instead.
 */
export const DRUGS_TEMPLATE_NAME = 'Emergency Drugs'

/* Handles Treatment and Workflow Template name validation */

const TEMPLATE_NAME_REQUIRED = 'Template name required'
const MAX_NAME_LENGTH = 40

const nameValidationError =
  // eslint-disable-next-line no-template-curly-in-string
  'Name should be fewer than ${max} characters'

export const templateValidName = Yup.string()
  .max(MAX_NAME_LENGTH, nameValidationError)
  .required(TEMPLATE_NAME_REQUIRED)

// treatmentTemplate has a special case, shouldn't allow a user to create one named Emergency Drugs
// XXX: should the same expception apply to Anesthesia (Hidden)?
// TODO: Use origin id for special templates instead of name (resolves XXX)
export const treatmentTemplateValidName = Yup.string()
  .max(MAX_NAME_LENGTH, nameValidationError)
  .required(TEMPLATE_NAME_REQUIRED)
  .trim()
  // not allowed to call a Treatment Template Emergency Drugs
  .notOneOf(
    [DRUGS_TEMPLATE_NAME],
    'Name “Emergency Drugs” unavailable, choose another name',
  )
