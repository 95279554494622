import React from 'react'
import { SvgArrow } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { StyleSheet } from 'react-native'

import { FormField, FormFieldProps } from './index'

const SvgBlackBlueArrowIcon = (
  <SvgArrow
    orient="down"
    color={Colors.contentSecondary}
    width={15}
    height={15}
  />
)

export const DurationFormField: React.FC<FormFieldProps> = props => (
  <FormField
    {...props}
    icon={SvgBlackBlueArrowIcon}
    textStyle={styles.textStyle}
  />
)

const styles = StyleSheet.create({
  textStyle: { marginTop: 2 },
})
