import React, { useCallback, useMemo, useRef, useState } from 'react'
import {
  addSeconds,
  endOfDay,
  format as dateFnsFormat,
  startOfDay,
} from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { Modal, useWindowDimensions, View } from 'react-native'
import { noop } from 'lodash'
import { CustomKeyboardTimePicker } from './CustomKeyboardTimePicker.web'
import {
  endOfDayUTC,
  ListPosition,
  SECONDS,
  startOfDayUTC,
  TimeFormat,
} from './TimePickerUtils'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useWebOutsideClick } from 'src/hooks/useOutsideClick'
import {
  TIMEPICKER_HEIGHT,
  TIMEPICKER_HEIGHT_BUFFER,
  TimePickerOptions,
} from './TimePickerOptions'

type TimePickerProps = {
  value?: Date // Time picker can be Day agnostic, working in seconds from Date(0) if not provided a value.
  format?: TimeFormat | string // 24h or AM/PM, defaults to AM/PM
  intervalSeconds?: number // Amount of seconds between each option time. Default 1800 (30 minutes).
  minTime?: Date
  maxTime?: Date
  useUTC?: boolean // Use with caution
  allowCustomTime?: boolean // Allow times that aren't in the options list to be entered manually, e.g 8:02 PM. Defaults to false.
  disabled?: boolean
  onChange?: (value: Date) => void
  isMaxTimeInclusiveRange?: boolean
  isResetWidth?: boolean
}

export const TimePicker: React.FC<TimePickerProps> = ({
  value = new Date(0),
  format = TimeFormat.TWELVE_HOUR,
  intervalSeconds = SECONDS.HALF_HOUR,
  useUTC = false,
  minTime = useUTC ? startOfDayUTC(value) : startOfDay(value),
  maxTime = useUTC ? endOfDayUTC(value) : endOfDay(value),
  allowCustomTime = true,
  disabled = false,
  onChange = noop,
  isMaxTimeInclusiveRange = false,
  isResetWidth = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [displayOptions, setDisplayOptions] = useState<boolean>(true)

  const ref = useRef<View>(null)
  const parentRef = useRef<View>(null)
  const modalInputRef = useRef<HTMLDivElement>(null)
  const { height: wHeight } = useWindowDimensions()
  const [renderListPosition, setRenderListPosition] =
    useState<ListPosition | null>(null)
  const [inputPos, setInputPos] = useState({ x: 0, y: 0, h: 0, w: 0 })

  // this determines how close the list will be to the bottom of the browser.
  // It then decides whether to render the list above or below the TimeInput
  const setModalPosition = useCallback(() => {
    ref.current?.measure((_x, _y, width, height, px, py) => {
      setInputPos({ x: px, y: py, w: width, h: height })
      const listHeight =
        height + py + TIMEPICKER_HEIGHT + TIMEPICKER_HEIGHT_BUFFER
      const listPosition =
        listHeight > wHeight ? ListPosition.ABOVE : ListPosition.BELOW
      setRenderListPosition(listPosition)
    })
  }, [wHeight])

  useWebOutsideClick({
    ref: parentRef,
    callback: () => {
      closeModalOptions()
    },
  })

  const calculateTopPosition = useCallback(
    () =>
      renderListPosition === ListPosition.ABOVE
        ? displayOptions
          ? inputPos.y - TIMEPICKER_HEIGHT
          : inputPos.y
        : inputPos.y,
    [inputPos.y, displayOptions, renderListPosition],
  )

  const onOptionsOpen = (open: boolean) => {
    setIsOpen(open)
    setModalPosition()
  }

  const closeModalOptions = () => {
    setIsOpen(false)
    setDisplayOptions(true)
  }

  // Keyboard input currently only supports leading 0's on 12h, force to supported format if am/pm
  const formatOverride = useMemo(() => {
    return format.includes('a')
      ? TimeFormat.TWELVE_HOUR
      : TimeFormat.TWENTY_FOUR_HOUR
  }, [format])

  const formatWithOptionalTimezone = useCallback(
    (time: Date) => {
      if (useUTC) {
        return formatInTimeZone(time, 'UTC', formatOverride)
      }
      return dateFnsFormat(time, formatOverride)
    },
    [useUTC, formatOverride],
  )

  // Round entered value to nearest interval unless custom time is enabled
  const onChangeOverride = (val: Date | MaterialUiPickersDate) => {
    if (!allowCustomTime && val) {
      // This only considers the max time in the options
      const roundedIntervalInMillSecond = (intervalSeconds / 2) * 1000
      const roundedToMaxTime =
        val.getTime() >= maxTime.getTime() - roundedIntervalInMillSecond
      if (
        isMaxTimeInclusiveRange &&
        (val.getTime() === maxTime.getTime() || roundedToMaxTime)
      ) {
        return onChange(maxTime)
      }

      // This is how the default rounded value should be calculated
      let roundedVal = new Date(
        Math.round(val.getTime() / (intervalSeconds * 1000)) *
          (intervalSeconds * 1000),
      )

      // If the maxValue is less than an interval, this prevents the value rounding too high.
      while (roundedVal > maxTime) {
        roundedVal = addSeconds(roundedVal, -intervalSeconds)
      }
      // If the minValue is more than an interval, this prevents the value rounding too low.
      while (roundedVal < minTime) {
        roundedVal = addSeconds(roundedVal, intervalSeconds)
      }
      return onChange(roundedVal)
    }
    return onChange(val)
  }

  const timeOptions = useMemo(() => {
    const options = []
    const dayStart = useUTC
      ? startOfDayUTC(value) // get the start of the day in UTC
      : startOfDay(value)
    const steps = SECONDS.DAY / intervalSeconds
    for (let i = 0; i < steps; i += 1) {
      const time = addSeconds(dayStart, i * intervalSeconds)
      if (time >= minTime && time <= maxTime) {
        options.push({
          text: formatWithOptionalTimezone(time),
          value: time,
        })
      }
    }
    if (isMaxTimeInclusiveRange) {
      options.push({
        text: formatWithOptionalTimezone(maxTime),
        value: maxTime,
      })
    }
    return options
  }, [
    value,
    intervalSeconds,
    minTime,
    maxTime,
    useUTC,
    formatWithOptionalTimezone,
    isMaxTimeInclusiveRange,
  ])

  const scrollIdx = useMemo(() => {
    // Closest option after to current value, assumes sorted options
    const idx = timeOptions.findIndex(option => {
      return option.value.getTime() >= value.getTime()
    })
    // Ensure we don't return a negative and hang the system, otherwise put selected value in middle of selection
    return Math.max(idx - 2, 0)
  }, [timeOptions, value])

  const modalStyle = useMemo(() => {
    return {
      top: calculateTopPosition(),
      left: inputPos.x,
      width: inputPos.w,
    }
  }, [calculateTopPosition, inputPos.w, inputPos.x])

  const handleKeyboardModalEvent = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Closes the modal
    if (e.key === 'Enter') {
      closeModalOptions()
    }

    // Hide the options when the user has started typing
    if (displayOptions) {
      setDisplayOptions(false)
    }
  }

  return (
    <View ref={ref}>
      <View>
        <CustomKeyboardTimePicker
          defaultInputValue={formatWithOptionalTimezone(value)}
          value={value}
          is24HOUR={formatOverride === TimeFormat.TWENTY_FOUR_HOUR}
          timeFormat={formatOverride}
          useUTC={useUTC}
          setShowOptions={() => onOptionsOpen(!isOpen)}
          onChange={onChangeOverride}
          disabled={disabled || !timeOptions.length || isOpen}
          isResetWidth={isResetWidth}
        />
      </View>
      <Modal
        transparent
        visible={isOpen}
        onLayout={() => {
          if (modalInputRef.current) {
            modalInputRef.current.focus()
          }
        }}
      >
        <View style={modalStyle} ref={parentRef}>
          {renderListPosition === ListPosition.ABOVE && displayOptions ? (
            <TimePickerOptions
              data={timeOptions}
              value={value}
              setIsOpen={setIsOpen}
              onChange={onChange}
              initialIndex={scrollIdx}
            />
          ) : null}
          <CustomKeyboardTimePicker
            ref={modalInputRef}
            defaultInputValue={formatWithOptionalTimezone(value)}
            value={value}
            is24HOUR={formatOverride === TimeFormat.TWENTY_FOUR_HOUR}
            timeFormat={formatOverride}
            useUTC={useUTC}
            setShowOptions={noop}
            onChange={onChangeOverride}
            onKeyDown={handleKeyboardModalEvent}
            autofocus={true}
            onFocus={e => e.target.select()}
            isResetWidth={isResetWidth}
          />
          {renderListPosition === ListPosition.BELOW && displayOptions ? (
            <TimePickerOptions
              data={timeOptions}
              value={value}
              setIsOpen={setIsOpen}
              onChange={onChange}
              initialIndex={scrollIdx}
            />
          ) : null}
        </View>
      </Modal>
    </View>
  )
}
