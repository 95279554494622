import React, { useCallback, useEffect } from 'react'
import { toast } from 'components/common/Toast/ToastArea'
import {
  checkForUpdateAsync,
  fetchUpdateAsync,
  isEmergencyLaunch,
  reloadAsync,
} from 'expo-updates'
import { useTranslation } from 'react-i18next'
import { environment } from 'src/config'
import { useAppState } from 'src/hooks/useAppState'

const updateWhenNewOTA = async (onReadyToReload: () => void) => {
  const bundleInfo = await checkForUpdateAsync()
  if (bundleInfo.isAvailable) {
    const updatedBundle = await fetchUpdateAsync()
    if (updatedBundle.isNew) onReadyToReload()
  }
}

type Props = {
  children: React.ReactElement
}

export const ExpoUpdates: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation()
  const { appState } = useAppState()

  const shouldCheckForUpdates =
    !environment.isDev && !environment.isWeb && !isEmergencyLaunch
  const toastText = t('general.updates')

  const onReadyToReload = useCallback(() => {
    toast.reload(toastText, () => {
      reloadAsync()
    })
  }, [toastText])

  useEffect(() => {
    // check when ios appState changes and open app from bg
    if (shouldCheckForUpdates && appState === 'active') {
      updateWhenNewOTA(onReadyToReload)
    }
  }, [appState, onReadyToReload, shouldCheckForUpdates, toastText])

  return <>{children}</>
}
