import React from 'react'
import { Colors } from 'constants/Colors'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color?: string
  length?: number
}

export const SvgReschedule: React.FC<Props> = ({
  color = Colors.contentSecondary,
  length = 20,
}) => (
  <Svg height={length} width={length} viewBox="0 0 20 20">
    <G fill="none" fillRule="evenodd">
      <Path d="m0 0h20v20h-20z" />
      <Path
        d="m16 3v-2.46666667c0-.29455186-.2387815-.53333333-.5333333-.53333333h-1.9333334c-.2945518 0-.5333333.23878147-.5333333.53333333v2.46666667h-6v-2.46666667c0-.29455186-.23878147-.53333333-.53333333-.53333333h-1.93333334c-.29455186 0-.53333333.23878147-.53333333.53333333v2.46666667h-2c-1.1045695 0-2 .8954305-2 2v13c0 1.1045695.8954305 2 2 2h16c1.1045695 0 2-.8954305 2-2v-13c0-1.1045695-.8954305-2-2-2zm1.5 15.5h-15c-.55228475 0-1-.4477153-1-1v-10.5h17v10.5c0 .5522847-.4477153 1-1 1z"
        fill={color}
      />
      <Path
        d="m5 13h10m0 0-3.1578947 3m0-6 3.1578947 3"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </G>
  </Svg>
)
