import React, { useRef } from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { noop } from 'lodash'
import {
  StyleProp,
  StyleSheet,
  Text,
  TextInput as RnTextInput,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
  TextInputProps,
} from 'react-native'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { RawNumericInput } from '../RawNumericInput'

type Props = Omit<TextInputProps, 'value' | 'onChange'> & {
  autoFocus?: boolean
  disabled?: boolean
  label?: string
  value: number | null
  onChange?: (value: number | null) => void
  style?: StyleProp<ViewStyle>
  required?: boolean
  accessibilityLabel?: string
  shouldInteger?: boolean
}

export const NumericInput = React.forwardRef<RnTextInput, Props>(
  (
    {
      autoFocus = false,
      disabled = false,
      label,
      onChange = noop,
      style,
      value,
      required,
      shouldInteger = false,
      ...props
    },
    fRef,
  ) => {
    const ref: React.MutableRefObject<RnTextInput | null> = useRef(null)

    const pseudoLabel = label === 'pseudo'

    const focusInput = () =>
      requestAnimationFrame(() => {
        if (ref.current) ref.current.focus()
      })

    return (
      <View style={style}>
        <TouchableWithoutFeedback
          onPressIn={focusInput}
          accessible={false}
          // @ts-ignore
          focusable={false}
        >
          <View
            style={[
              styles.container,
              {
                height: 60,
              },
            ]}
          >
            <View style={styles.textInputWrapper}>
              <View style={{ flexDirection: 'row' }}>
                {label && !pseudoLabel ? (
                  <TextWithTooltip
                    style={styles.label}
                    accessibilityLabel={label}
                    numberOfLines={1}
                    title={label}
                  >
                    {label}
                  </TextWithTooltip>
                ) : null}
                {pseudoLabel ? <View style={styles.pseudoLabel} /> : null}
                {required ? <Text style={styles.requiredLabel}>*</Text> : null}
              </View>

              <RawNumericInput
                ref={el => {
                  // fRef can be a function or an object
                  if (typeof fRef === 'function') {
                    fRef(el)
                  } else if (!!fRef) {
                    fRef.current = el
                  }
                  ref.current = el
                }}
                autoFocus={autoFocus}
                label={label}
                style={[styles.value, disabled && styles.disabled]}
                onChange={onChange}
                value={value}
                disabled={disabled}
                shouldInteger={shouldInteger}
                {...props}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    )
  },
)

NumericInput.displayName = 'NumericInput'

const styles = StyleSheet.create({
  clearButton: {
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    width: 40,
    marginLeft: -16,
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    paddingLeft: 16,
  },
  disabled: {
    color: Colors.disabled,
  },
  label: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 13,
    lineHeight: 19,
  },
  pseudoLabel: {
    height: 13,
  },
  requiredLabel: {
    color: 'red',
    marginTop: 0,
  },
  textInputWrapper: {
    flex: 1,
  },
  value: {
    color: Colors.contentPrimary,
    fontFamily: Fonts.regular,
    fontSize: 16,
    lineHeight: 22,
    paddingHorizontal: 3,
    marginLeft: -3,
    marginRight: 16,
  },
})
