import React, { useCallback } from 'react'
import { Colors } from 'constants/Colors'
import {
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  StyleProp,
  ViewStyle,
} from 'react-native'
import { Fonts } from 'src/constants/Fonts'

type Props = {
  onChange: (num: number) => void
  value: number
  label: string
  min?: number
  max?: number
  disabled?: boolean
  style?: StyleProp<ViewStyle>
}

export const checkValue = (
  value: number | string,
  min: number,
  max: number,
) => {
  const newValue = Number(value)
  if (isNaN(newValue)) return 0
  if (newValue < min) return min
  if (newValue > max) return max
  if (newValue % 1 !== 1) return Math.floor(newValue)
  return newValue
}

export const NumberInput: React.FC<Props> = ({
  onChange,
  value,
  label,
  min = 0,
  max = 9999,
  disabled = false,
  style,
}) => {
  const handleChangeMinus = useCallback(
    () => onChange(checkValue(value - 1, min, max)),
    [max, min, onChange, value],
  )

  const handleChangePlus = useCallback(
    () => onChange(checkValue(value + 1, min, max)),
    [max, min, onChange, value],
  )

  return (
    <View style={style}>
      <Text style={styles.label}>{label}</Text>
      <View
        style={[styles.inputContainer, disabled && styles.disabledContainer]}
      >
        <TouchableOpacity
          disabled={disabled}
          testID={'-Button'}
          style={styles.buttonWrapper}
          // @ts-ignore
          focusable={false}
          accessible={false}
          onPress={handleChangeMinus}
        >
          <Text
            style={[styles.buttonSubFont, disabled && styles.disabledColor]}
          >
            -
          </Text>
        </TouchableOpacity>
        <TextInput
          editable={!disabled}
          style={[styles.textInput, disabled && styles.disabledColor]}
          onChangeText={inputValue =>
            onChange(checkValue(inputValue, min, max))
          }
          value={value.toString()}
        />
        <TouchableOpacity
          disabled={disabled}
          testID={'+Button'}
          style={styles.buttonWrapper}
          onPress={handleChangePlus}
        >
          <Text
            style={[styles.buttonAddFont, disabled && styles.disabledColor]}
          >
            +
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.shared.borderGrey,
    height: 38,
    width: 138,
    borderRadius: 19,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 9,
    backgroundColor: Colors.white,
  },
  disabledContainer: {
    opacity: 0.3,
  },
  label: { color: Colors.contentSecondary, fontSize: 12, marginLeft: 19 },
  buttonWrapper: {
    padding: 8,
  },
  // It's better change to same size svg
  buttonAddFont: {
    fontSize: 18,
    color: Colors.patient.infoText,
    lineHeight: 18,
  },
  // It's better change to same size svg
  buttonSubFont: {
    fontSize: 24,
    fontFamily: Fonts.regular,
    color: Colors.patient.infoText,
    lineHeight: 24,
    marginTop: Platform.OS === 'web' ? 0 : Platform.OS === 'ios' ? 1 : 6,
  },
  textInput: { width: 60, textAlign: 'center' },
  disabledColor: { color: Colors.disabled },
})
