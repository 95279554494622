import { Tag } from 'components/Tag/Tag'
import { resuscitateToTagTitle } from 'components/Tag/utils/patientToTagTitle'
import React from 'react'
import { View, ViewStyle, TextStyle } from 'react-native'
import { PatientFieldsFull as GetPatientsItem } from 'src/types/PatientFieldsFull'
import { PatientHeaderStyles as styles } from './PatientHeaderStyles'
import { TagV2 } from 'components/Tag/TagV2'

export const PatientTag: React.FC<{
  patient: Pick<GetPatientsItem, 'resuscitate'>
  style?: ViewStyle | TextStyle
  textStyle?: TextStyle
  size?: 'big' | 'small'
  useV2?: boolean
}> = ({ patient, size, style, textStyle, useV2 = false }) => {
  return (
    <View style={styles.tags}>
      {useV2 ? (
        <TagV2
          title={resuscitateToTagTitle(patient.resuscitate)}
          style={style}
          textStyle={textStyle}
        />
      ) : (
        <Tag
          title={resuscitateToTagTitle(patient.resuscitate)}
          style={styles.tag}
          size={size}
          textStyle={size === 'small' && styles.cprTagText}
        />
      )}
    </View>
  )
}
