import { differenceInSeconds } from 'date-fns'
import { find } from 'lodash'
import {
  AnesthesiaChartEntry,
  AnesthesiaChartValueData,
} from 'src/context/types/anesthesia'

const TIME_NEARBY_SECONDS = 30
/* Gets the chart entries for the anesthesia Pressure group and (more
   importantly) the points at which to draw vertical lines to the pressure chart
   value below (mapPointAndDapPoint). There are a couple of rules for these,
   1. the point below must be nearby in time (TIME_NEARBY_SECONDS).
   2. there must be both MAP and DAP points below (otherwise dont draw either
      line)
      - this means in practice the search for line points can only begin from
        SAP, this could be refactored */
export const getPressureRowChartPoints = (
  anesthesiaPressureGroupEntries: AnesthesiaChartEntry[],
) => {
  return anesthesiaPressureGroupEntries.map(
    /* Group and handle the pressure group entries seperately, they are
     drawn with separate joining lines */
    (
      { data, symbol_shape: symbolShape, show_text: showText, title },
      collectionIndex,
    ) => {
      return {
        symbolShape,
        showText,
        title,
        entry: data.map(currentPoint => {
          let mapPointAndDapPoint: AnesthesiaChartValueData[] | null = null

          /* Next pressure entry down "Systolic Arterial Pressure" => "Mean
             Arterial Pressure" => "Diastolic Arterial Pressure" */
          const mapField: AnesthesiaChartEntry | undefined =
            anesthesiaPressureGroupEntries[collectionIndex + 1]
          const dapField: AnesthesiaChartEntry | undefined =
            anesthesiaPressureGroupEntries[collectionIndex + 2]

          const getNextPressurePointAtSameTime = (
            nextEntry: AnesthesiaChartValueData,
          ) =>
            Math.abs(differenceInSeconds(currentPoint.time, nextEntry.time)) <
            TIME_NEARBY_SECONDS

          // Inefficent search for next point at a similar time, keep in a useMemo
          if (mapField && dapField) {
            const mapPoint = find(mapField.data, getNextPressurePointAtSameTime)
            const dapPoint = find(dapField.data, getNextPressurePointAtSameTime)
            mapPointAndDapPoint =
              mapPoint && dapPoint ? [mapPoint, dapPoint] : null
          }

          return { currentPoint, mapPointAndDapPoint }
        }),
      }
    },
  )
}
