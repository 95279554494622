import { useSubscription } from '@apollo/client'

import { useOrganisation } from 'src/context/organisation'

import { SUBSCRIBE_PATIENT_SUMMARY } from './graphql'

import { useUpdatePatientListCache } from './useUpdatePatientListCache'

export const usePatientSubscription = () => {
  const [{ organisationId }] = useOrganisation()
  const updatePatientListCache = useUpdatePatientListCache()
  useSubscription(SUBSCRIBE_PATIENT_SUMMARY, {
    variables: { organisationId },
    onData: options => {
      updatePatientListCache(
        options.data.data.patient.data,
        options.data.data.patient.mutation,
      )
    },
  })
}
