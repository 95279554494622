import React from 'react'
import Svg, { Defs, Path, Pattern, Rect } from 'react-native-svg'

type Props = {
  opacity?: number
  color?: string
}

export const SvgDiscontinuedBg: React.FC<Props> = ({
  opacity = 1,
  color = '#75818D',
}) => (
  <Svg height="100%" width="100%">
    <Defs>
      <Pattern
        id="svg-discontinued-bg-line-pattern"
        patternUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="60"
        height="60"
        viewBox="0 0 60 60"
      >
        <Path
          d="M-66 68.795L24.554-21M-46 68.795L44.554-21M-26 68.795L64.554-21M-6 68.795L84.554-21M14 68.795L104.554-21M34 68.795L124.554-21M54 68.795L144.554-21M74 68.795L164.554-21M94 68.795L184.554-21M114 68.795L204.554-21M134 68.795L224.554-21M154 68.795L244.554-21M174 68.795L264.554-21M194 68.795L284.554-21M214 68.795L304.554-21M234 68.795L324.554-21M254 68.795L344.554-21M274 68.795L364.554-21M294 68.795L384.554-21M314 68.795L404.554-21M334 68.795L424.554-21M354 68.795L444.554-21M374 68.795L464.554-21M394 68.795L484.554-21M414 68.795L504.554-21M414 68.795L504.554-21M434 68.795L524.554-21M454 68.795L544.554-21M474 68.795L564.554-21M494 68.795L584.554-21M514 68.795L604.554-21M534 68.795L624.554-21M554 68.795L644.554-21M574 68.795L664.554-21M594 68.795L684.554-21M614 68.795L704.554-21M634 68.795L724.554-21M654 68.795L744.554-21M674 68.795L764.554-21M694 68.795L784.554-21M714 68.795L804.554-21M734 68.795L824.554-21M754 68.795L844.554-21M774 68.795L864.554-21M794 68.795L884.554-21M814 68.795L904.554-21M834 68.795L924.554-21M854 68.795L944.554-21M874 68.795L964.554-21M894 68.795L984.554-21M914 68.795L1004.554-21M914 68.795L1004.554-21M934 68.795L1024.554-21M954 68.795L1044.554-21M974 68.795L1064.554-21M994 68.795L1084.554-21M1014 68.795L1104.554-21M1034 68.795L1124.554-21M1054 68.795L1144.554-21M1074 68.795L1164.554-21M1094 68.795L1184.554-21M1114 68.795L1204.554-21M1134 68.795L1224.554-21M1154 68.795L1244.554-21M1174 68.795L1264.554-21M1194 68.795L1284.554-21M1214 68.795L1304.554-21M1234 68.795L1324.554-21M1254 68.795L1344.554-21M1274 68.795L1364.554-21"
          strokeOpacity={opacity}
          stroke={color}
          strokeWidth={8}
        />
      </Pattern>
    </Defs>
    <Rect
      fill="url(#svg-discontinued-bg-line-pattern)"
      stroke="none"
      width="100%"
      height="59"
    />
  </Svg>
)
