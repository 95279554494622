import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color?: string
  width?: number
}

const defaultColor = '#26A69A'
export const SvgVitals: React.FC<Props> = ({
  color = defaultColor,
  width = 40,
}) => (
  <Svg width={width} height={40}>
    <G y={16} transform="translate(4, 2)">
      <Path
        d="M1 5l7.37-4 7.245 4 6.949-4 7.202 4L37 1"
        stroke={color}
        strokeWidth={1.125}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
)
