import { getFrequencyInputFromSchedule } from 'components/Treatment/utils/treatmentSchedule.utils'
import { RepeatScheduleValue } from 'components/TreatmentForm/data'
import { differenceInMinutes, startOfMinute } from 'date-fns'
import { getConditionalTreatment_getConditionalTreatment_schedule as ConditionalTreatmentSchedule } from 'types/getConditionalTreatment'

// If date compare in the 2 minutes range, identify the treatments' schedule as 'Now'. VR-5423
const NowCompareRange = 2

const isTreatmentWithNowSchedule = (createdAt: string, startAt: string) => {
  const treatmentCreatedAt = new Date(createdAt)
  const scheduleStartAt = new Date(startAt)
  const minDiff = Math.abs(
    differenceInMinutes(treatmentCreatedAt, scheduleStartAt),
  )
  return minDiff < NowCompareRange
}

/**
 * Check if the conditional treatments' has closed value between Created date and
 * Start date of the schedule. Compared those dates, if those are closed in less
 * than 2 minutes,identify the current treatments' schedule as 'Now', otherwise
 * returning the start date of the schedule. VR-5423
 */
const getStartAtDate = (
  schedule: ConditionalTreatmentSchedule | null,
  treatmentCreatedAt: string,
  isSingleOption: boolean,
) => {
  const scheduleStartAt = schedule?.start_at
  if (!scheduleStartAt) {
    return null
  }

  const isTreatmentStartAtNow = isTreatmentWithNowSchedule(
    treatmentCreatedAt,
    scheduleStartAt,
  )

  if (!isSingleOption && isTreatmentStartAtNow) {
    return null
  }

  return startOfMinute(new Date(scheduleStartAt))
}

export const getConditionalScheduleFormData = (
  schedule: ConditionalTreatmentSchedule | null,
  treatmentCreatedAt: string,
  isSingleOption: boolean,
) => {
  const startAtDate = getStartAtDate(
    schedule,
    treatmentCreatedAt,
    isSingleOption,
  )

  if (!startAtDate && !schedule?.repeat && isSingleOption) {
    return {
      startAtDate: null,
      isRepeating: RepeatScheduleValue.NONE,
      repeating: null,
    }
  }

  return {
    startAtDate: getStartAtDate(schedule, treatmentCreatedAt, isSingleOption),
    isRepeating: schedule?.repeat
      ? RepeatScheduleValue.REPEATING
      : RepeatScheduleValue.SINGLE,
    ...(schedule?.time_window && {
      timeWindow: schedule.time_window,
    }),
    repeating: schedule?.frequency,
    ...(schedule?.repeat_until && {
      repeatUntilDate: new Date(schedule.repeat_until),
    }),
    enableStaffedHour: schedule?.enable_staffed_hour,
    ...getFrequencyInputFromSchedule(schedule),
  }
}
