import { CallParameterItem } from 'components/CallParameterTemplate/CallParameterTemplateForm'
import { toast } from 'components/common'
import { CallParameter } from 'components/SheetHeader/ParameterEntry'

export const compareCallParamMinAndMax = (
  callParameterItems: CallParameterItem[] | CallParameter[],
) => {
  if (!callParameterItems) return
  let isValid = true
  callParameterItems.map(callParam => {
    if (
      callParam.min &&
      callParam.max !== null &&
      callParam.max !== undefined &&
      callParam.min > callParam.max
    ) {
      isValid = false
      setTimeout(() => {
        toast.notice(
          `${callParam.min} is greater than ${callParam.max} of ${callParam.name}`,
        )
      }, 200)
    }
  })
  return isValid
}
