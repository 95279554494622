import { isString, uniq } from 'lodash'
import { ViewToken } from 'react-native'

export interface VisiblePatientsToken extends ViewToken {
  item: // data can be shape of 'title' section before data arrives
  { data: []; title: string; resultCursor: string }
}

export const getPatientListVisibleItems = (
  viewableItems: VisiblePatientsToken[],
) => {
  const pageCursorsInView = uniq(
    viewableItems.map(({ item }) =>
      'resultCursor' in item ? item.resultCursor : null,
    ),
  ).filter(isString)

  const updatedIsVisibleMap: { [id: string]: boolean } = {}
  viewableItems.forEach(viewableItem => {
    if (viewableItem.key) {
      updatedIsVisibleMap[viewableItem.key] = viewableItem.isViewable
    }
  })
  return { updatedIsVisibleMap, pageCursorsInView }
}
