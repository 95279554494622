import React, { useState } from 'react'
import { SvgCross } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Animated, StyleSheet, TouchableOpacity, View } from 'react-native'

import { useDrawer } from './DrawerContext'
import { DrawerDataType } from './drawerDataType'

type Props = {
  height: number
  render: (data?: DrawerDataType) => '' | JSX.Element | undefined
}

export const Drawer: React.FC<Props> = ({ render, height }) => {
  const { isOpen, close, data } = useDrawer()
  const [animatedHeight] = useState(new Animated.Value(0))

  React.useEffect(() => {
    const toValue = isOpen ? height : 0
    Animated.timing(animatedHeight, {
      toValue,
      duration: 150,
      useNativeDriver: false, // TODO: Animate translateY instead to support native
    }).start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  if (!isOpen && (animatedHeight as any)._value === 0) {
    return null
  }
  return (
    <Animated.View style={{ height: animatedHeight }}>
      <View style={styles.container}>
        <TouchableOpacity onPress={close} style={styles.closeBtn}>
          <SvgCross />
        </TouchableOpacity>
        {render(data)}
      </View>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderTopColor: Colors.lightGrey,
    borderTopWidth: 1,
  },
  closeBtn: {
    padding: 8,
    position: 'absolute',
    right: 8,
    top: 8,
    zIndex: 1,
  },
})
