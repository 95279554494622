/* works in a similar way to setTimeout, the milliseconds value is similar to
   frequency milliseconds, it isn't guaranteed to be anything other that
   exectuded after that time, which could be long (or never happen) if you
   accidentally block the UI. I'm sure this must exist on npm somewhere */

export function rafInterval(callBack: () => void, millisecondsIsh: number) {
  return raf(callBack, millisecondsIsh, true)
}

export function rafTimeout(callBack: () => void, millisecondsIsh: number) {
  return raf(callBack, millisecondsIsh, false)
}

// ref: https://gist.github.com/jakearchibald/cb03f15670817001b1157e62a076fe95
const raf = (
  callback: () => void,
  millisecondsIsh: number,
  repeat: boolean,
) => {
  const start = performance.now()
  let isCancelled = false

  function frame(time: number) {
    if (isCancelled) return
    callback()
    if (repeat) {
      scheduleFrame(time)
    }
  }

  function scheduleFrame(time: number) {
    const elapsed = time - start
    const roundedElapsed =
      Math.round(elapsed / millisecondsIsh) * millisecondsIsh
    const targetNext = start + roundedElapsed + millisecondsIsh
    const delay = targetNext - performance.now()
    setTimeout(() => requestAnimationFrame(frame), delay)
  }
  scheduleFrame(start)
  return () => {
    isCancelled = true
  }
}
