import { gql } from '@apollo/client'
import { TASK_FIELDS_FULL } from '../Task/graphql'

export const BULK_UPDATE_TASKS = gql`
  mutation bulkUpdateTasks($input: [UpdateTaskInput!]!) {
    bulkUpdateTasks(input: $input) {
      ...TaskFieldsFull
    }
  }
  ${TASK_FIELDS_FULL}
`

export const BULK_CREATE_TASKS = gql`
  mutation bulkCreateTasks($input: [CreateTaskInput!]!) {
    bulkCreateTasks(input: $input) {
      ...TaskFieldsFull
    }
  }
  ${TASK_FIELDS_FULL}
`

export const BULK_DELETE_TASKS = gql`
  mutation bulkDeleteTasks($input: [DeleteTaskInput!]!) {
    bulkDeleteTasks(input: $input)
  }
`
