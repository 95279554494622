import React from 'react'
import { Routes } from 'constants/Routes'
import { StyleSheet, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import { SubHeader } from '../SubHeader/SubHeader'
import { CreateSheet } from './CreateSheet'
import { CreateSheetFormInputs } from './createSheetData'
import { useCreateSheet } from './useCreateSheet'
import { PatientStackParamList } from 'components/Patient/screens'
import { GET_TEMPLATE_TREATMENT_IDS } from 'src/components/TreatmentTemplate/graphql'
import { useImperativeQuery } from 'src/hooks/useImperativeQuery'
import {
  getTemplateTreatmentIds,
  getTemplateTreatmentIdsVariables,
} from 'src/types/getTemplateTreatmentIds'
import { useConfirm } from 'src/context/confirm'
import { flatten, uniq } from 'lodash'

type Props = StackScreenProps<PatientStackParamList, Routes.AddSheet> & {
  defaultFormOverrides?: Partial<CreateSheetFormInputs>
}

export const CreateSheetScreen: React.FC<Props> = ({
  navigation,
  route,
  defaultFormOverrides,
}) => {
  const { t } = useTranslation()
  const { navigate } = navigation
  const [{ organisationId }] = useOrganisation()
  const patientId = route.params.patientId

  const { isTemplateListLoading, submitCreateSheet } = useCreateSheet({
    patientId,
  })

  const getTemplateTreatmentIds = useImperativeQuery<
    getTemplateTreatmentIds,
    getTemplateTreatmentIdsVariables
  >(GET_TEMPLATE_TREATMENT_IDS)
  const confirm = useConfirm()

  const [isTemplateProductIdsLoading, setTemplateProductIdsIsLoading] =
    React.useState(false)
  const onSubmit = async (values: CreateSheetFormInputs) => {
    setTemplateProductIdsIsLoading(true)
    const promises =
      values.selectedTemplates
        ?.filter(template => !!template.id)
        .map(async ({ id }) => {
          const { data } = await getTemplateTreatmentIds({
            organisationId,
            id: id!,
          })
          return data?.getTemplate?.treatments?.items
            ?.flatMap(t => t.treatments?.items?.map(t => t.product_id))
            .filter(id => !!id)
        }) ?? []
    const allProductsIds = flatten(await Promise.all(promises))
    setTemplateProductIdsIsLoading(false)

    const allProductsIdsUniq = uniq(allProductsIds)
    const hasDuplication = allProductsIds.length !== allProductsIdsUniq.length

    let ignoreDuplication = false
    if (hasDuplication) {
      try {
        await confirm({
          title: t('addTreatment:duplicationTitle'),
          text: t('sheets.duplicationText'),
          okText: 'Yes',
          cancelText: 'No',
        })
        ignoreDuplication = false
      } catch (e) {
        ignoreDuplication = true
      }
    }

    // const ignoreDuplication = true
    submitCreateSheet({ values }, ignoreDuplication)
  }

  const backButton = {
    action: () => {
      navigate(Routes.SheetList, {
        patientId,
      })
    },
    label: 'sheets.returnHereLabel',
    title: 'sheets.title',
  }

  return (
    <View testID="NewSheetContainer" style={styles.container}>
      <SubHeader headlineKey="newSheet.title" backButton={backButton} />
      <CreateSheet
        defaultOverrides={defaultFormOverrides}
        isTemplateListLoading={isTemplateListLoading}
        isTemplateProductIdsLoading={isTemplateProductIdsLoading}
        onSave={onSubmit}
        patientId={patientId}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
