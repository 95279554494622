import { TreatmentChildLevel } from 'components/Treatment/common/types'

import { Action, Task } from '../types'
import { getProductIsHealthStatusWeight } from './getProductInfo'
import {
  getIsFluidStyleTaskDisplay,
  getTreatmentInfo,
  getTreatmentIsMedication,
} from './getTreatmentInfo'
import { getIsTaskDoneOrProgress } from './taskTransitionUtils'

type GetValueInputType = (params: {
  action?: Action
  task?: Task
  treatment?: TreatmentChildLevel | null
}) => ValueInputType

export enum ValueInputType {
  Select = 'Select',
  Text = 'Text',
  Number = 'Number',
  None = 'None',
}

export const getValueInputType: GetValueInputType = ({
  action,
  task,
  treatment,
}) => {
  const isCompleteAction = action === Action.COMPLETE

  const isEditDoneOrProgressTask =
    (action === Action.EDIT || action === Action.VIEW) &&
    getIsTaskDoneOrProgress(task?.status)
  const isValueChangeAllowed = isCompleteAction || isEditDoneOrProgressTask

  const isFluidStyleTaskDisplay = getIsFluidStyleTaskDisplay(treatment)
  const isMedication = getTreatmentIsMedication(treatment)

  const { hasCustomValues, isHealthStatusProduct, isObservation } =
    getTreatmentInfo(treatment)
  if (!isValueChangeAllowed || isMedication || isFluidStyleTaskDisplay) {
    return ValueInputType.None
  }

  const isHealthStatusWeight = getProductIsHealthStatusWeight(
    treatment?.product,
  )
  if (isHealthStatusWeight) {
    return ValueInputType.Text
  }

  if (hasCustomValues || isHealthStatusProduct) {
    return ValueInputType.Select
  }

  if (!isObservation) {
    return ValueInputType.Text
  }

  return ValueInputType.None
}
