import { SYNC_WITH_CONSULT_PLACEHOLDER } from 'constants/ClinicalRecord'
import { isEmpty, reject } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  NestedOptionLevels,
  ClipButtonOption,
  NestedListSelectOption,
} from './types'

export type HasChildrenHashTable = { [key: string]: boolean }

export interface NestedListMenuActions {
  handleSecondaryMenuSelection: (selectedItem: NestedListSelectOption) => void
  handleSelectAll: () => void
  handlePrimaryMenuSelection: (selectedItem: NestedListSelectOption) => void
  setMenuOpen: (newValue: boolean) => void
  getIsSelectAllChecked: () => boolean
  setSecondaryMenuParentId: (newValue: string | null) => void
}

export interface NestedListMenuState {
  isMenuOpen: boolean
  secondaryMenuParentId: string | null
  activeParentSelector: ClipButtonOption | null
  secondaryMenuOptions: NestedListSelectOption[]
  primaryMenuOptions: NestedListSelectOption[]
  hasOnlyDepartments: boolean
  secondaryMenuHeaderTitle: string
  mostRecentSecondaryMenuLength: number
}

export const useNestedListSelectMenu = (
  options: NestedListSelectOption[],
  selected: string[],
  onChange: (sites: string[]) => void,
  loading: boolean,
) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [secondaryMenuParentId, setSecondaryMenuParentId] = useState<
    string | null
  >(null)

  const hasOnlyDepartments: boolean = useMemo(() => {
    const businessUnitOptions = options.filter(
      option =>
        !!(
          option.nestedOptionLevel === NestedOptionLevels.PARENT &&
          option.value !== SYNC_WITH_CONSULT_PLACEHOLDER
        ),
    )
    return isEmpty(businessUnitOptions)
  }, [options])

  const hasChildrenHashTable: HasChildrenHashTable = useMemo(
    () =>
      options.reduce((acc: HasChildrenHashTable, listItem) => {
        if (!!listItem.parentId) acc[listItem.parentId] = true
        return acc
      }, {}),
    [options],
  )

  const activeParentSelector = useMemo(() => {
    if (options.length < 2 || isEmpty(selected) || hasOnlyDepartments) {
      return null
    }
    const firstSelectedOption = options.find(
      option => option.value === selected[0],
    )

    const newActiveParentSelector =
      firstSelectedOption?.nestedOptionLevel === NestedOptionLevels.CHILD
        ? {
            value: firstSelectedOption.parentId ?? '',
            text:
              options.find(
                option => option.value === firstSelectedOption.parentId,
              )?.text ?? '',
          }
        : {
            value: firstSelectedOption?.value ?? '',
            text: firstSelectedOption?.text ?? '',
          }
    return newActiveParentSelector
  }, [hasOnlyDepartments, options, selected])

  const secondaryMenuOptions = useMemo(() => {
    if (!secondaryMenuParentId) return []
    if (hasOnlyDepartments)
      return options.filter(
        option => option.nestedOptionLevel !== NestedOptionLevels.TOP,
      )
    return options
      .filter(
        listItem =>
          listItem.parentId === secondaryMenuParentId ||
          listItem.value === secondaryMenuParentId,
      )
      .sort(a => {
        if (!!a.parentId) return 1
        return -1
      })
  }, [hasOnlyDepartments, options, secondaryMenuParentId])

  const [mostRecentSecondaryMenuLength, setMostRecentSecondaryMenuLength] =
    useState(0)

  useEffect(() => {
    setMostRecentSecondaryMenuLength(prev =>
      isEmpty(secondaryMenuOptions) ? prev : secondaryMenuOptions.length,
    )
  }, [secondaryMenuOptions])

  const primaryMenuOptions: NestedListSelectOption[] = useMemo(() => {
    if (loading) return []
    if (options.length < 2) return options
    return options
      .filter(
        listItem => listItem.nestedOptionLevel === NestedOptionLevels.PARENT,
      )
      .map(option => {
        const hasChildren = hasChildrenHashTable[option.value] ? true : false
        return { ...option, hasChildren }
      })
  }, [hasChildrenHashTable, options, loading])

  const secondaryMenuHeaderTitle = useMemo(() => {
    if (isEmpty(secondaryMenuOptions)) return ''
    if (hasOnlyDepartments)
      return (
        options.find(
          option => option.nestedOptionLevel === NestedOptionLevels.TOP,
        )?.text ?? ''
      )
    return secondaryMenuOptions[0].text ?? ''
  }, [hasOnlyDepartments, options, secondaryMenuOptions])

  const getIsSelectAllChecked = useCallback(() => {
    if (isEmpty(secondaryMenuOptions)) return false
    let hasDeselectedOptions = true
    secondaryMenuOptions.forEach((option: NestedListSelectOption) => {
      if (!selected.includes(option.value)) {
        hasDeselectedOptions = false
      }
    })
    return hasDeselectedOptions
  }, [selected, secondaryMenuOptions])

  const checkShouldShowSecondaryMenuOnMenuClose = useCallback(() => {
    if (isMenuOpen) return
    if (hasOnlyDepartments) {
      setSecondaryMenuParentId(' ')
      return
    }
    if (
      !activeParentSelector?.value ||
      !hasChildrenHashTable[activeParentSelector?.value]
    ) {
      setSecondaryMenuParentId(null)
      return
    }
    setSecondaryMenuParentId(activeParentSelector?.value ?? null)
  }, [
    activeParentSelector?.value,
    hasChildrenHashTable,
    hasOnlyDepartments,
    isMenuOpen,
  ])

  // actions that will be exported for components to use
  const handleSecondaryMenuSelection = (
    selectedItem: NestedListSelectOption,
  ) => {
    const shouldClearSelections =
      !!activeParentSelector &&
      activeParentSelector.value !==
        (selectedItem.parentId || selectedItem.value) &&
      !hasOnlyDepartments

    if (shouldClearSelections) {
      onChange([selectedItem.value])
      return
    }
    if (selected.includes(selectedItem.value)) {
      const newSelectedOptions = reject(
        selected,
        option => option === selectedItem.value,
      )
      onChange(newSelectedOptions)
      return
    }
    const newSelectedOptions = selected.concat([selectedItem.value])

    onChange(newSelectedOptions)
  }

  const handleSelectAll = () => {
    const newValue = !getIsSelectAllChecked()
    const newSelectedOptions = newValue
      ? secondaryMenuOptions.map(
          (option: NestedListSelectOption) => option.value,
        )
      : []

    onChange(newSelectedOptions)
  }

  const handlePrimaryMenuSelection = (selectedItem: NestedListSelectOption) => {
    if (!selectedItem.hasChildren) {
      onChange([selectedItem.value])
      return
    }
    setSecondaryMenuParentId(selectedItem.value)
  }

  const setMenuOpen = (newValue: boolean) => {
    setIsMenuOpen(newValue)
    if (!newValue) {
      checkShouldShowSecondaryMenuOnMenuClose()
    }
  }

  useEffect(() => {
    if (loading) return
    checkShouldShowSecondaryMenuOnMenuClose()
  }, [checkShouldShowSecondaryMenuOnMenuClose, loading])

  useEffect(() => {
    if (options.length === 1) {
      onChange([options[0].value])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length])

  const state: NestedListMenuState = {
    primaryMenuOptions,
    secondaryMenuOptions,
    secondaryMenuParentId,
    isMenuOpen,
    hasOnlyDepartments,
    activeParentSelector,
    secondaryMenuHeaderTitle,
    mostRecentSecondaryMenuLength,
  }

  const actions: NestedListMenuActions = {
    handleSecondaryMenuSelection,
    handleSelectAll,
    handlePrimaryMenuSelection,
    setMenuOpen,
    getIsSelectAllChecked,
    setSecondaryMenuParentId,
  }

  return {
    state,
    actions,
  }
}
