import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

export const SvgHamburger = () => (
  <Svg width={18} height={13}>
    <G
      stroke="#979797"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <Path d="M1.348 1.704H17M1.348 6.704H17M1.348 11.704H17" />
    </G>
  </Svg>
)
