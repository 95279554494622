import React, { useCallback } from 'react'
import { Card } from './Card'
import { Drawer } from './Drawer'
import { StyleSheet, Text } from 'react-native'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import { PrimaryInfo } from './Cards/PrimaryInfo'
import {
  getConsultation,
  getConsultationVariables,
} from 'types/getConsultation'
import { GET_CONSULTATION } from 'components/Sheet/graphql'
import { getConsultationProblems } from 'components/PatientItem/utils/getPatientProblems'
import { useGetDateFormatPattern } from 'components/Task/utils/useGetStatusLabelText'
import { getWeightLastUpdateTime } from 'components/TreatmentForm/utils/getWeightUpdatedAt'
import { getSheet_getSheet } from 'types/getSheet'
import { CriticalNotesCard } from 'components/PatientPanel/Cards/CriticalNotes'
import { useGetAttendingVet } from 'components/Patient/useGetAttendingVet'
import { PatientCallParametersContent } from 'components/PatientPanel/Cards/CallParameters'
import { PatientInfo } from 'components/PatientPanel/Cards/PatientInfo'
import { useTranslation } from 'react-i18next'
import { DrawerAction } from 'components/Sheet/ReadySheet'
import { usePatientById } from 'src/hooks/usePatient'
import { Fonts } from 'constants/Fonts'
import { isEmpty } from 'lodash'
import { AnesthesiaTimers } from 'components/PatientPanel/Cards/AnesthesiaTimers'
import { getSheetIsAnesthesia } from 'components/Anesthesia/AnesthesiaChart/utils/getSheetIsAnesthesia'
import { useSheetHeader } from 'components/SheetHeader/useSheetHeader'
import { AnesthesiaNotes } from 'components/PatientPanel/Cards/AnesthesiaNotes'
import { getUser_getUser } from 'types/getUser'
import { card_status_type } from 'constants/LocalToggleOptions'
import { UPDATE_PATIENT } from 'components/Patient/graphql'
import {
  updatePatient as UpdatePatient,
  updatePatientVariables,
} from 'src/types/updatePatient'
import { EditableCard } from './EditableCard'
import { toast } from 'components/common'
import { SheetDetails } from './Cards/SheetDetails'
import { useGetAttendingDepartmentName } from 'components/Patient/useGetAttendingDepartmentName'
import {
  getBundlesBySheet,
  getBundlesBySheetVariables,
} from 'src/types/getBundlesBySheet'
import { GET_BUNDLES_BY_SHEET } from 'components/AddTreatment/Bundles/graphql'
import { SUBSCRIBE_TREATMENT } from 'components/Treatment/graphql'
import { MutationType } from 'src/types/globalTypes'

type Props = {
  children?: React.ReactElement | null
  consultationId?: string
  isExpanded: boolean
  handleCollapse: () => void
  patientId: string
  sheet?: getSheet_getSheet | null
  user: getUser_getUser | null
  onPressPatientInfo?: () => void
  selectDrawerAction: (action: DrawerAction | null) => void
  toggleSheetInfoDrawer?: () => void
}

export const PatientPanel = ({
  consultationId,
  isExpanded,
  handleCollapse,
  patientId,
  sheet,
  user,
  onPressPatientInfo,
  selectDrawerAction,
  toggleSheetInfoDrawer,
}: Props) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { dateFormatPattern } = useGetDateFormatPattern()

  const attendingVet = useGetAttendingVet(sheet?.attending_vet)
  const attendingVetTech = useGetAttendingVet(sheet?.attending_vet_tech)
  const careTeam = sheet?.additional_care_team_names || []
  const attendingDepartment = sheet?.attending_department
  const patient = usePatientById(patientId)
  const isAnesthesia = getSheetIsAnesthesia(sheet)
  const sheetPatientInfo = useSheetHeader(sheet?.id ?? '')

  const sheetBundleQuery = useQuery<
    getBundlesBySheet,
    getBundlesBySheetVariables
  >(GET_BUNDLES_BY_SHEET, {
    skip: !sheet?.id,
    variables: {
      sheet_id: sheet?.id ?? '',
      patient_id: patientId,
      organisation_id: organisationId,
    },
  })

  // This will refetch the bundles for a sheet, only when new treatments are Added or Deleted
  useSubscription(SUBSCRIBE_TREATMENT, {
    variables: { sheetId: sheet?.id ?? '' },
    skip: !sheet?.id,
    onData: ({ data }) => {
      if (data.data?.treatment?.mutation !== MutationType.UPDATE) {
        sheetBundleQuery.refetch()
      }
    },
  })

  const sheetBundles =
    sheetBundleQuery.data?.getBundlesBySheet.map(bundle => bundle.name) ?? []

  const consultationQueryResult = useQuery<
    getConsultation,
    getConsultationVariables
  >(GET_CONSULTATION, {
    variables: {
      organisationId,
      id: consultationId ?? '',
    },
    skip: !consultationId,
  })

  const consultation = consultationQueryResult.data?.getConsultation
  const presentingProblems = getConsultationProblems(consultation)

  const departmentName = useGetAttendingDepartmentName(
    organisationId,
    attendingDepartment,
  )

  const onPressOpenPatientDetails = useCallback(() => {
    selectDrawerAction(DrawerAction.DETAILS)
  }, [selectDrawerAction])

  const [updatePatient] = useMutation<UpdatePatient, updatePatientVariables>(
    UPDATE_PATIENT,
  )

  const handleUpdateCriticalNotes = (val?: string) => {
    try {
      updatePatient({
        variables: {
          input: {
            id: patientId,
            organisation_id: organisationId,
            critical_notes: val ? [val] : null,
          },
        },
      })
      toast.success(t('form.updated', { itemName: t('sheet:patientDetail') }))
    } catch (error) {
      if (error instanceof Error) toast.error(error.message)
    }
  }

  const handleUpdateNotes = (val?: string) => {
    try {
      updatePatient({
        variables: {
          input: {
            id: patientId,
            organisation_id: organisationId,
            notes: val,
          },
        },
      })
      toast.success(t('form.updated', { itemName: t('sheet:patientDetail') }))
    } catch (error) {
      if (error instanceof Error) toast.error(error.message)
    }
  }

  if (!patient || !user) return null
  return (
    <Drawer
      isExpanded={isExpanded}
      patientName={`"${patient.name}" ${patient.contact?.last_name ?? ''}`}
      handleCollapse={handleCollapse}
    >
      <PrimaryInfo patient={patient} onPress={onPressOpenPatientDetails} />
      <CriticalNotesCard
        notes={patient.critical_notes}
        onPress={handleUpdateCriticalNotes}
      />
      {isAnesthesia && sheetPatientInfo ? (
        <>
          <Card
            key={t('patientPanel:cards:anesthesiaTimer:key')}
            header={t('patientPanel:cards:anesthesiaTimer:header')}
            showHeader={false}
            isEditable={true}
            hasValue={true}
            onPress={onPressPatientInfo}
            cardStyles={{ contentStyle: styles.noHeaderContent }}
          >
            <AnesthesiaTimers sheetPatientInfo={sheetPatientInfo} />
          </Card>
          <Card
            key={t('patientPanel:cards:anesthesiaNotes:key')}
            header={t('patientPanel:cards:anesthesiaNotes:header')}
            isCollapsible={true}
            isEditable
            statusId={card_status_type.SHOULD_SHOW_ANESTHESIA_NOTES}
            hasValue={!isEmpty(sheetPatientInfo)}
            onPress={onPressPatientInfo}
          >
            <AnesthesiaNotes sheetPatientInfo={sheetPatientInfo} />
          </Card>
        </>
      ) : null}
      <Card
        key={t('patientPanel:cards:sheetDetails:key')}
        header={t('patientPanel:cards:sheetDetails:header')}
        isCollapsible
        isEditable
        statusId={card_status_type.SHOULD_SHOW_SHEET_DETAILS}
        onPress={toggleSheetInfoDrawer}
        hasValue={true}
      >
        <SheetDetails
          department={departmentName}
          doctor={attendingVet ?? ''}
          vetTech={attendingVetTech ?? ''}
          careTeam={careTeam}
          sheetBundles={sheetBundles}
          consultInfo={{
            id: consultation?.origin_id ?? '',
            reference: consultation?.origin_reference ?? '',
          }}
        />
      </Card>
      <EditableCard
        key={t('patientPanel:cards:notes:key')}
        header={t('patientPanel:cards:notes:header')}
        isCollapsible={true}
        statusId={card_status_type.SHOULD_SHOW_NOTES}
        onPress={handleUpdateNotes}
        value={patient.notes ?? undefined}
      />
      <Card
        key={t('patientPanel:cards:presentingProblem:key')}
        header={t('patientPanel:cards:presentingProblem:header')}
        isCollapsible
        isEditable={false}
        statusId={card_status_type.SHOULD_SHOW_PRESENTING_PROBLEM}
        hasValue={presentingProblems.length > 0}
      >
        {presentingProblems.map((problem, key) => (
          <Text style={styles.cardText} key={key}>
            {problem}
          </Text>
        ))}
      </Card>
      <Card
        key={t('patientPanel:cards:patientInfo:key')}
        header={t('patientPanel:cards:patientInfo:header')}
        isCollapsible
        isEditable
        statusId={card_status_type.SHOULD_SHOW_PATIENT_INFO}
        // @ts-ignore // this will be fixed once inline editing is added
        onPress={onPressOpenPatientDetails}
        hasValue={true}
      >
        <PatientInfo
          patientId={patient.code}
          weightLastUpdate={
            getWeightLastUpdateTime(
              patient?.weight_updated_at,
              dateFormatPattern,
            ) ?? ''
          }
          order={patient.order}
          location={patient?.primary_location?.location_display}
        />
      </Card>
      <Card
        key={t('patientPanel:cards:ownerDetails:key')}
        header={t('patientPanel:cards:ownerDetails:header')}
        isCollapsible
        isEditable={false}
        statusId={card_status_type.SHOULD_SHOW_OWNER_DETAILS}
        hasValue={true}
      >
        <Text
          style={styles.cardText}
        >{`${patient.contact?.first_name} ${patient.contact?.last_name}`}</Text>
        <Text style={styles.cardText}>{`${
          patient.contact?.phone_number || ''
        }`}</Text>
      </Card>
      <Card
        key={t('patientPanel:cards:callParameters:key')}
        header={t('patientPanel:cards:callParameters:header')}
        isCollapsible
        statusId={card_status_type.SHOULD_SHOW_CALL_PARAMETERS}
        isEditable
        // @ts-ignore // this will be fixed once inline editing is added
        onPress={onPressOpenPatientDetails}
        hasValue={true}
      >
        <PatientCallParametersContent
          patient={patient}
          onPress={onPressPatientInfo}
        />
      </Card>
    </Drawer>
  )
}

const styles = StyleSheet.create({
  cardText: {
    fontSize: 14,
    fontFamily: Fonts.regular,
  },
  noHeaderContent: {
    paddingTop: 8,
  },
})
