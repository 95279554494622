import { WebSocketLink } from '@apollo/client/link/ws'
import NetInfo from '@react-native-community/netinfo'
import { toast } from 'components/common/Toast/ToastArea'
import i18next from 'i18next'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import vetradarmobile from '../vetradar-exports'
import { notifyReconnection } from './reconnectSubscription'
import { getAccessToken } from './utils/getAccessToken'

const isLocalHost = vetradarmobile.graphqlEndpoint.indexOf('localhost') !== -1
const wsLinkUri = `ws${isLocalHost ? '' : 's'}://${
  vetradarmobile.graphqlEndpoint
}`

// Create a WebSocket link:
export const wsLink = new WebSocketLink({
  uri: wsLinkUri,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async () => ({
      authorization: await getAccessToken(),
    }),
  },
})

export const wsClient: SubscriptionClient = (wsLink as any).subscriptionClient

// fix WebSocket is closed before the connection is established.
// ref: https://github.com/apollographql/subscriptions-transport-ws/issues/377#issuecomment-375567665
// @ts-ignore as maxConnectTimeGenerator is private member..
wsClient.maxConnectTimeGenerator.duration = () =>
  // @ts-ignore
  wsClient.maxConnectTimeGenerator.max

const reconnectWindow = 60 * 1000 // one minute.
let disconnectedTime: number | null = null
wsClient.on('disconnected', () => {
  // Only toast error after a certain time, since the first several connect request are not stable 🤔
  if (!disconnectedTime) {
    disconnectedTime = Date.now()
    return
  }
  if (Date.now() - disconnectedTime < reconnectWindow) {
    return
  }
  console.log('websocket disconnected') // eslint-disable-line no-console
})

wsClient.on('reconnected', () => {
  // eslint-disable-next-line no-console
  console.log('websocket reconnected!')
  notifyReconnection()
  // reset disconnectedTime after reconnected
  disconnectedTime = null
})

// From undocumented code... ws client will try reconnect in close()
// https://github.com/apollographql/subscriptions-transport-ws/blob/5128c3ca41/src/client.ts#L160
export const reconnectWs = () => wsClient.close(false, false)

let lastIsConnected = true
let dismissDisconnectedToast: null | (() => void) = null
NetInfo.addEventListener(state => {
  const isDisconnected = lastIsConnected && !state.isConnected
  const isReconnected = !lastIsConnected && state.isConnected

  if (isDisconnected) {
    lastIsConnected = false
    dismissDisconnectedToast = toast.disconnected(
      i18next.t('general.disconnected'),
    )
  }
  // reconnect immediately without waiting for subscriptions-transport-ws internal exponential backoff reconnect
  if (isReconnected) {
    lastIsConnected = true
    dismissDisconnectedToast?.()
    dismissDisconnectedToast = null
    reconnectWs()
  }
})
