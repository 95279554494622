import { addMinutes, differenceInMinutes, startOfMinute } from 'date-fns'
import { filter, minBy, sortBy } from 'lodash'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskItem } from 'src/types/getSheet'
import { Status } from 'src/types/globalTypes'

export const convertClickPositionToTime = (
  position: number,
  width: number,
  startDate: Date,
  endDate: Date,
) => {
  const diffMinutes = differenceInMinutes(endDate, startDate)
  const positionInMinutes = Math.max((diffMinutes / width) * position, 1)

  return startOfMinute(addMinutes(startDate, positionInMinutes))
}

// click schedule fluid trigger the starting one.
export const findWorkingFluidTask = (tasks: TaskItem[], task: TaskItem) => {
  if (tasks.length === 1 || task.status === Status.DONE) {
    return task
  }

  const startingFluidTask = tasks.find(t => t.status === Status.IN_PROGRESS)
  if (startingFluidTask) {
    return startingFluidTask
  }

  const firstPendingFluidTask = minBy(tasks, task => {
    if (!task.given_start_at) {
      return ''
    }
    return task.given_start_at
  })
  return firstPendingFluidTask ?? task
}

export const getSortedTasksChunks = (tasks: TaskItem[]) => {
  const filteredTasks = filter(
    tasks,
    t => t.status !== Status.DELETED_ON_PURPOSE,
  )

  const sortedTasks = sortBy(filteredTasks, task => {
    if (!task.given_start_at) {
      return task.start_at ?? ''
    }
    return task.given_start_at
  })

  const tasksChunks: TaskItem[][] = []
  let currentChunk = 0
  // make fluid tasks into chunk. Each chunk is a fluid line.
  sortedTasks.forEach((task, index, allTasks) => {
    if (tasksChunks[currentChunk]) {
      tasksChunks[currentChunk].push(task)
    } else {
      tasksChunks[currentChunk] = [task]
    }
    if (task.status !== Status.DONE) {
      return
    }

    const nextTask = allTasks[index + 1]
    if (!nextTask) {
      return
    }
    const nextTaskStartTime = nextTask.given_start_at ?? nextTask.start_at
    if (task.given_stop_at !== nextTaskStartTime) {
      currentChunk += 1
    }
  })

  return tasksChunks
}
