import React, { useState, useMemo, useCallback } from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { LinearGradient } from 'expo-linear-gradient'
import { sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
} from 'react-native'
import { SearchInput } from 'src/components/common/SearchInput'
import { useFlags } from 'react-native-flagsmith/react'
import { FeatureFlagNames } from 'constants/FeatureFlags'

const backgroundTransparent = '#fff0'

export const BILLING_ITEMS = 'Billing Items'
export const BUNDLE_FILTER = 'BUNDLE_FILTER'
export type Group = {
  id: string
  name: string
  order: number | null
}

type Props = {
  groups: Group[]
  searchText: string
  groupId: string | undefined
  onChange: (text: string, groupId?: string) => void
  noTemplate?: boolean
  shouldShowGroupNames?: boolean
  isIVInfusion?: boolean
}

export const SearchBar: React.FC<Props> = ({
  groupId,
  groups,
  onChange,
  searchText,
  noTemplate = false,
  shouldShowGroupNames = true,
  isIVInfusion = false,
}) => {
  const { t } = useTranslation()
  const flags = useFlags([FeatureFlagNames.ENABLE_BUNDLE_UI])

  const filterItems = useMemo(() => {
    const items = [...groups]
    if (!noTemplate) {
      const templateItemName = t('addTreatment:template')
      items.push({
        name: templateItemName,
        id: 'Template',
        order: Number.MIN_SAFE_INTEGER,
      })
    }

    if (flags[FeatureFlagNames.ENABLE_BUNDLE_UI].enabled) {
      items.push({
        name: t('addTreatment:bundles'),
        id: BUNDLE_FILTER,
        order: Number.MIN_SAFE_INTEGER,
      })
    }

    items.push({
      name: BILLING_ITEMS,
      id: BILLING_ITEMS,
      order: Number.MAX_SAFE_INTEGER,
    })

    return [
      {
        name: isIVInfusion
          ? t('addIVInfusion.searchBarPlaceHolder')
          : t('general.all'),
        id: undefined,
      },
      ...sortBy(items, 'order'),
    ] as Group[]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, noTemplate, t, groupId, searchText, isIVInfusion]) // groupId & searchText are to make sure FlatList got rerendered on groupId change

  const showGroups = !!groups.length

  let initialScrollIndex = 0
  if (showGroups && groupId) {
    initialScrollIndex = groups.findIndex(group => group!.id === groupId)
  }

  const [selectedGroup, setSelectedGroup] = useState(filterItems[0].name)

  const handleGroupChange = useCallback(
    (group: Group, searchText: string) => {
      setSelectedGroup(group.name)
      onChange(searchText, group.id)
    },
    [onChange],
  )

  const handleSearchChange = useCallback(
    (text: string) => {
      onChange(text, groupId)
    },
    [groupId, onChange],
  )

  return (
    <View style={styles.container} testID="SearchBar">
      <SearchInput
        testID="SearchBarInput"
        style={styles.searchInput}
        autoFocus={Platform.OS === 'web'}
        placeholder={`${t('general.search')} "${selectedGroup}"`}
        onChangeText={handleSearchChange}
        wait={500}
      />

      {shouldShowGroupNames && showGroups ? (
        <FilterButtonGroups
          filterItems={filterItems}
          groupId={groupId}
          initialScrollIndex={initialScrollIndex}
          onPressFilter={handleGroupChange}
          searchText={searchText}
        />
      ) : null}
    </View>
  )
}

const filterButtonKeyExtractor: (item: Group) => string = item =>
  item.id || 'ALL'

const FilterButtonGroups: React.FC<{
  filterItems: Group[]
  groupId?: string
  initialScrollIndex: number
  onPressFilter: (group: Group, searchText: string) => void
  searchText: string
}> = ({
  filterItems,
  groupId,
  initialScrollIndex,
  onPressFilter,
  searchText,
}) => {
  const renderFilterItems = useCallback(
    ({ item }: { item: Group }) => (
      <FilterButton
        group={item}
        groupId={groupId}
        onPressFilter={onPressFilter}
        searchText={searchText}
      />
    ),
    [groupId, onPressFilter, searchText],
  )

  return (
    <View style={[styles.groups]}>
      <>
        <FlatList
          data={filterItems}
          horizontal={true}
          initialScrollIndex={initialScrollIndex}
          keyExtractor={filterButtonKeyExtractor}
          renderItem={renderFilterItems}
          showsHorizontalScrollIndicator={false}
          style={styles.flatListPadding}
        />
        <LinearGradient
          colors={[backgroundTransparent, Colors.backgroundGrey]}
          end={[0, 0]}
          start={[1, 0]}
          style={styles.gradientStyleLeft}
        />
        <LinearGradient
          colors={[Colors.backgroundGrey, backgroundTransparent]}
          end={[0, 0]}
          start={[1, 0]}
          style={styles.gradientStyleRight}
        />
      </>
    </View>
  )
}

const FilterButton: React.FC<{
  group: Group
  groupId?: string
  onPressFilter: (group: Group, searchText: string) => void
  searchText: string
}> = ({ group, groupId, onPressFilter, searchText }) => {
  const isSelected = group.id === groupId

  const handlePress = useCallback(() => {
    onPressFilter(group, searchText)
  }, [group, onPressFilter, searchText])

  return (
    <TouchableOpacity key={group.id || 'ALL'} onPress={handlePress}>
      <View
        style={[
          styles.filterButton,
          group.id === groupId && styles.selectedButton,
        ]}
      >
        {isSelected ? (
          <Text style={styles.selectedFilter} testID="SelectedFilterIndicator">
            ✔︎
          </Text>
        ) : null}
        <Text style={[styles.groupName]}>{group.name}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  filterButton: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 50,
    flexDirection: 'row',
    marginHorizontal: 8,
    marginVertical: 4,
    paddingHorizontal: 15,
    paddingVertical: 9,
  },
  flatListPadding: {
    paddingLeft: 16,
  },
  gradientStyleLeft: {
    bottom: 0,
    height: 48,
    left: 0,
    position: 'absolute',
    width: 20,
  },
  gradientStyleRight: {
    bottom: 0,
    height: 48,
    position: 'absolute',
    right: 0,
    width: 25,
  },
  groupName: {
    flexDirection: 'row',
    color: Colors.contentSecondary,
    fontFamily: Fonts.regular,
    fontSize: 15,
  },
  groups: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  searchInput: {
    marginBottom: 12,
  },
  selectedButton: {
    backgroundColor: Colors.vetRadarOrange,
  },
  selectedFilter: {
    width: 20,
  },
})
