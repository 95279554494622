import { StackScreenProps } from '@react-navigation/stack'
import { SettingsStackParamList } from 'components/Settings/screens'
import { Routes } from 'constants/Routes'
import React, { useCallback, useMemo, useState } from 'react'
import { useOrganisation } from 'src/context/organisation'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  ListRenderItem,
  SectionList,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { Colors } from 'constants/Colors'
import { TreatmentFrequencyListRow } from 'components/Settings/TreatmentFrequencies/TreatmentFrequencyListRow'
import { TreatmentFrequencyForm } from 'components/Settings/TreatmentFrequencies/TreatmentFrequencyForm'
import { partition } from 'lodash'
import {
  FrequencyType,
  TreatmentFrequencyCreateInput,
  TreatmentFrequencyUpdateInput,
} from 'types/globalTypes'
import { useTreatmentFrequencies } from 'src/hooks/useTreatmentFrequencies'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { Center } from 'components/common/Center'
import { SecondaryButton } from 'components/common/SecondaryButton'

type Props = StackScreenProps<
  SettingsStackParamList,
  Routes.TreatmentFrequenciesConfig
>

export const TreatmentFrequenciesScreen: React.FC<Props> = ({ navigation }) => {
  const { navigate } = navigation
  const [{ organisationId }] = useOrganisation()
  const { t } = useTranslation()

  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [selectedFrequencyId, setSelectedFrequencyId] = useState<string>('')

  const {
    queryResult,
    createTreatmentFrequency,
    deleteTreatmentFrequency,
    updateTreatmentFrequency,
  } = useTreatmentFrequencies()

  const { loading, data } = queryResult
  const [customFrequenciesList, intervalFrequenciesList] = useMemo(
    () =>
      partition(
        data?.getTreatmentFrequencies?.items,
        frequency => frequency.type === FrequencyType.MINS_FROM_MIDNIGHT,
      ) ?? [],
    [data?.getTreatmentFrequencies?.items],
  )

  const navigateSettings = useCallback(
    () => navigate(Routes.Settings),
    [navigate],
  )

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: navigateSettings,
  }

  const toggleDrawer = useCallback(() => {
    setIsDrawerVisible(previousVisible => !previousVisible)
  }, [])

  const handleCreate = () => {
    toggleDrawer()
  }

  const handleDelete = useCallback(
    (id: string) => {
      deleteTreatmentFrequency({
        variables: {
          id,
          organisationId,
        },
      })
    },
    [deleteTreatmentFrequency, organisationId],
  )

  const handleUpdate = (frequency: TreatmentFrequencyUpdateInput) => {
    setSelectedFrequencyId(frequency.id)
    toggleDrawer()
  }

  const onCreate = (frequencyInput: TreatmentFrequencyCreateInput) => {
    createTreatmentFrequency({
      variables: {
        input: frequencyInput,
      },
    })
    toggleDrawer()
  }

  const onUpdate = (frequencyInput: TreatmentFrequencyUpdateInput) => {
    updateTreatmentFrequency({
      variables: {
        input: frequencyInput,
      },
    })
    setSelectedFrequencyId('')
    toggleDrawer()
  }

  const onSave = (
    frequency: TreatmentFrequencyCreateInput | TreatmentFrequencyUpdateInput,
  ) => {
    if ('id' in frequency) {
      onUpdate(frequency)
    } else {
      onCreate(frequency)
    }
  }

  const getSelectedFrequency =
    customFrequenciesList.find(f => f.id === selectedFrequencyId) || null

  const sections = [
    {
      title: t('settings:treatmentFrequencies.drawer.title'),
      data: customFrequenciesList,
    },
    {
      title: t('settings:treatmentFrequencies.default.title'),
      data: intervalFrequenciesList,
    },
  ]

  const renderListHeader = () => {
    return (
      <Center style={styles.headerContainer}>
        <Text style={styles.listSeparatorText}>
          {t('settings:treatmentFrequencies.drawer.title')}
        </Text>
        <View style={styles.addButtonContainer}>
          <SecondaryButton
            onPress={handleCreate}
            title={t('settings:treatmentFrequencies.drawer.createButton')}
            style={styles.addButton}
            textStyle={styles.addButtonText}
          />
        </View>
      </Center>
    )
  }

  const renderItem: ListRenderItem<TreatmentFrequencyUpdateInput> = ({
    item,
  }) => (
    <TreatmentFrequencyListRow
      handleDelete={handleDelete}
      item={item}
      onPress={handleUpdate}
      organisationId={organisationId}
      isSelected={selectedFrequencyId === item.id}
    />
  )

  const renderListFooter = useCallback(() => {
    if (!loading) return null
    return (
      <ActivityIndicator
        accessibilityLabel="Treatment Frequency List Loading Indicator"
        size="large"
        style={styles.activityIndicator}
      />
    )
  }, [loading])

  const keyExtractor = useCallback(
    (item: TreatmentFrequencyUpdateInput) => item.id,
    [],
  )

  return (
    <>
      <SubHeader
        headline={t('settings:treatmentFrequencies.header')}
        subHeadline={t('settings:treatmentFrequencies.subHeader')}
        backButton={backButton}
      />
      <View style={styles.frequenciesList} testID={'TreatmentFrequencyList'}>
        <SectionList
          keyExtractor={keyExtractor}
          ListHeaderComponent={renderListHeader}
          ListFooterComponent={renderListFooter}
          renderItem={renderItem}
          style={styles.borderRadius}
          contentContainerStyle={styles.listContainerStyle}
          sections={sections}
        />
      </View>
      <SheetAwareSideDrawer
        title={' '}
        visible={isDrawerVisible}
        onClose={() => {
          toggleDrawer()
          setSelectedFrequencyId('')
        }}
      >
        <TreatmentFrequencyForm
          selectedTreatmentFrequency={getSelectedFrequency}
          save={onSave}
        />
      </SheetAwareSideDrawer>
    </>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: Colors.backgroundGrey,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  activityIndicator: {
    marginTop: 25,
  },
  listContainerStyle: {
    paddingBottom: 16,
  },
  frequenciesList: {
    marginTop: 50,
    flex: 1,
  },
  listSeparatorText: {
    flex: 1,
    fontSize: 20,
    fontWeight: '500',
    paddingBottom: 12,
    paddingHorizontal: 16,
  },
  addButtonContainer: {
    flex: 1,
    paddingVertical: 12,
  },
  borderRadius: {
    borderRadius: 12,
  },
  addButton: {
    alignSelf: 'flex-end',
  },
  addButtonText: {
    color: Colors.buttons.blue,
  },
})
