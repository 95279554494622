import { TreatmentChildLevel } from 'components/Treatment/common/types'

export const getIsBillable = (treatment: TreatmentChildLevel) => {
  if (!treatment.product?.is_billable) {
    return null
  }
  if (treatment.is_billable) {
    return true
  }
  return false
}
