import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { DEFAULT_FEATURE_FLAGS, FlagSmithFlags } from 'constants/FeatureFlags'
import { unset } from 'lodash'
import flagsmith from 'react-native-flagsmith'
import { IFlags, IRetrieveInfo } from 'react-native-flagsmith/types'
import { environment } from 'src/config'
import { FlagsmithProvider } from 'react-native-flagsmith/react'

const { flagsmith: FLAGSMITH_ENV_KEY = '' } = environment

export const FLAGSMITH_POLLING_INTERVAL = 20 * 60 * 1000 // 20 minutes

type FlagSmithError = { message: string }

const handleFlagsError = (error: FlagSmithError) => {
  console.error(error) // eslint-disable-line no-console
}

export type FSListener = (flags: IFlags | null, params: IRetrieveInfo) => void
const flagSmithListeners: { [name: string]: FSListener } = {}

export function registerFlagSmithListener(listener: FSListener) {
  const listenerUUID = listener.toString()
  flagSmithListeners[listenerUUID] = listener
  return listenerUUID
}

export function unsubscribeFlagSmithListener(listenerID: string) {
  unset(flagSmithListeners, listenerID)
}

export const FeatureFlagsContext = createContext(DEFAULT_FEATURE_FLAGS)
FeatureFlagsContext.displayName = 'FeatureFlagsContext'

export const initFlagSmith = async () => {
  let preventFetch = false
  // "BULLET_TRAIN_DB" is kind of magic word flagsmith team pick
  // when they using AsyncStorage, this is a protential risk
  // when they change that word in the future
  const result = await AsyncStorage.getItem('BULLET_TRAIN_DB')
  if (result) {
    preventFetch = true
  }
  flagsmith.init({
    AsyncStorage,
    cacheFlags: true,
    defaultFlags: DEFAULT_FEATURE_FLAGS as any,
    environmentID: FLAGSMITH_ENV_KEY,
    onChange: (_oldFlags, params) => {
      const newFlags = flagsmith.getAllFlags()
      if (!newFlags) return
      Object.values(flagSmithListeners).forEach(fn => {
        fn(newFlags, params)
      })
    },
    // prevent initial fetch, best performed after identify in context/user.tsx
    preventFetch,
    onError: handleFlagsError,
  })

  // polling every x mins (and initial query on launch to save on # of messages)
  flagsmith.startListening(FLAGSMITH_POLLING_INTERVAL)
}

export const FeatureFlagProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [flags, setFlags] = useState<FlagSmithFlags>(DEFAULT_FEATURE_FLAGS)
  const listenerFn: FSListener = (flagsUpdate, _params) => {
    if (flagsUpdate) {
      setFlags(flagsUpdate as FlagSmithFlags)
    }
  }

  useEffect(() => {
    const fsListener = registerFlagSmithListener(listenerFn)
    return () => {
      unsubscribeFlagSmithListener(fsListener)
    }
  }, [])

  return (
    <FeatureFlagsContext.Provider value={flags}>
      <FlagsmithProvider flagsmith={flagsmith}>
        {children as React.ReactElement[] | React.ReactElement}
      </FlagsmithProvider>
    </FeatureFlagsContext.Provider>
  )
}

export const useFlagsmith = () => useContext(FeatureFlagsContext)
