import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFormTrigger,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { TimePicker } from 'components/common/TimePicker/TimePicker.web'
import { SvgTrash } from 'components/Icons/Trash'
import { Colors } from 'constants/Colors'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import React from 'react'

type FieldProps = {
  name: string
  frequencies: { value: Date }[]
}

export const TreatmentFrequencyFormRow: ({
  control,
  trigger,
  fields,
  timeFormat,
  remove,
}: {
  control: Control<FieldProps>
  trigger: UseFormTrigger<any>
  fields: FieldArrayWithId<FieldProps, 'frequencies'>[]
  timeFormat: string
  remove: (index: number) => void
}) => JSX.Element = ({ control, trigger, fields, timeFormat, remove }) => {
  const { t } = useTranslation()
  const rows = fields.map((item, index: number) => {
    return (
      <View
        key={item.id}
        style={[styles.dateContainer, index === 0 && styles.topDate]}
      >
        <View style={styles.titleContainer}>
          <Text>
            {index === 0
              ? t('settings:treatmentFrequencies.drawer.form.at')
              : t('settings:treatmentFrequencies.drawer.form.and')}
          </Text>
        </View>
        <View
          style={styles.timePickerContainer}
          testID={`frequencies.${index}.label`}
        >
          <Controller
            name={`frequencies.${index}.value`}
            control={control}
            defaultValue={item.value}
            render={({ field: { value, onChange } }) => {
              return (
                <TimePicker
                  value={value}
                  onChange={v => {
                    trigger()
                    onChange(v)
                  }}
                  format={timeFormat}
                  useUTC={false}
                  allowCustomTime={true}
                />
              )
            }}
          />
        </View>
        <View style={styles.deleteButton}>
          <Pressable
            onPress={() => {
              trigger()
              return remove(index)
            }}
            accessibilityLabel={`deleteFrequency.${index}`}
          >
            <SvgTrash color={Colors.contentPrimary} />
          </Pressable>
        </View>
      </View>
    )
  })

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {rows}
    </MuiPickersUtilsProvider>
  )
}

const styles = StyleSheet.create({
  dateContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  topDate: {
    borderTopColor: Colors.borderGrey,
    borderTopWidth: 1,
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRightWidth: 1,
    borderRightColor: Colors.borderGrey,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    width: 64,
  },
  timePickerContainer: {
    flex: 6,
  },
  deleteButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderLeftWidth: 1,
    borderLeftColor: Colors.borderGrey,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    width: 64,
  },
})
