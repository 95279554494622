import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { InputGroup, NumericInput, Select } from 'components/common'
import { Inputs as NonFluidFormData } from 'components/TreatmentForm/types'
import { calculateTotalDosage } from 'components/TreatmentForm/utils/getCalculations'
import { GET_TEMPLATE } from 'components/TreatmentTemplate/graphql'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { PatientWeightUnitOptions } from 'constants/PatientWeightOptions'
import { DRUGS_TEMPLATE_NAME } from 'constants/Templates'
import { WeightUnit } from 'constants/Weight'
import { isNil, isNull } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { environment } from 'src/config'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { TimeSegmentMinutes } from 'src/hocs/types/time'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { usePatientById } from 'src/hooks/usePatient'
import { usePatientId } from 'src/hooks/usePatientId'
import { useRound } from 'src/hooks/useRound'
import { useSheetId } from 'src/hooks/useSheetId'
import {
  getTemplate as GetTemplate,
  getTemplate_getTemplate_treatments_items_treatments_items as TemplateVariablesItem,
  getTemplateVariables as GetTemplateVariables,
} from 'src/types/getTemplate'
import { ProductType } from 'src/types/globalTypes'
import { useAddEmergencyDrug } from 'src/utils/useAddEmergencyDrug'

import {
  ADD_BTN_SIZE,
  EmergencyDrugItem,
  EmergItemState,
} from './EmergencyDrugItem'
import { useRouteOfAdministrations } from 'components/RouteOfAdministration/useRouteOfAdministrations'
import { getActiveTemplates_getTemplates_items } from 'src/types/getActiveTemplates'

const isWeb = environment.isWeb
const emergencyDrugsListKeyExtractor = (item: TemplateVariablesItem) => item.id

type DrugInput = {
  patientWeightValue: number | null
  patientWeightUnit: string
  setPatientWeightValue: (value: React.SetStateAction<number | null>) => void
  setPatientWeightUnit: (value: React.SetStateAction<string>) => void
  missedProductVolume?: boolean
  isInSheetScreen?: boolean
  state: EmergItemState
}

export const EmergencyDrugsInput: React.FC<DrugInput> = ({
  patientWeightValue,
  patientWeightUnit,
  setPatientWeightValue,
  setPatientWeightUnit,
  missedProductVolume = false,
  isInSheetScreen = false,
  state = EmergItemState.NORMAL,
}) => {
  const hideHeader = state === EmergItemState.EMPTY
  const { t } = useTranslation()

  if (hideHeader) return null
  return (
    <View style={styles.emergencyDrugsHeader} testID={'PatientWeightInput'}>
      <View style={styles.patientWeightContainer}>
        <InputGroup>
          <NumericInput
            label={t('emergencyDrugs.weight')}
            value={patientWeightValue}
            required={true}
            style={styles.patientWeightInput}
            onChange={newVal => setPatientWeightValue(newVal ?? null)}
          />
          <Select
            dialog={false}
            options={PatientWeightUnitOptions}
            selected={patientWeightUnit}
            style={[
              styles.patientWeightUnit,
              !isWeb && styles.iosPatientWeightUnit,
            ]}
            onChange={newValue => setPatientWeightUnit(newValue)}
          />
        </InputGroup>

        {missedProductVolume ? (
          <Text style={styles.msg}>
            {t('emergencyDrugs.errors.missingVolumes')}
          </Text>
        ) : null}
      </View>
      <View style={styles.drugHeaderContainer}>
        <View
          style={[
            styles.drugHeader,
            isInSheetScreen && styles.firstDrugHeader,
            { flex: 3 },
          ]}
        >
          <Text style={styles.drugHeaderText}>{t('emergencyDrugs.drug')}</Text>
        </View>
        <View style={[styles.drugHeader, { flex: 2 }]}>
          <Text style={styles.drugHeaderText}>{t('emergencyDrugs.dose')}</Text>
        </View>
        <View style={[styles.drugHeader, { flex: 1 }]}>
          <Text style={styles.drugHeaderText}>{t('emergencyDrugs.ml')}</Text>
        </View>
      </View>
    </View>
  )
}

interface ActiveTempItem {
  templateItem: getActiveTemplates_getTemplates_items | null
  missingEmergTemplate: boolean
}

export const EmergencyDrugsDrawer: React.FC<ActiveTempItem> = ({
  templateItem,
  missingEmergTemplate,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { settingsMap } = useOrgSettings()
  const { currentPatientId } = usePatientId()
  const { currentSheetId } = useSheetId()
  const { timeSegment } = useTimeResolution()
  const round = useRound()
  const { getDefaultRouteOfAdmin } = useRouteOfAdministrations()
  const tempId = templateItem?.id ?? ''
  const patient = usePatientById(currentPatientId ?? '')
  const [patientWeightUnit, setPatientWeightUnit] = useState(
    () => settingsMap?.WEIGHT_UNIT?.value ?? 'kg',
  )
  const [patientWeightValue, setPatientWeightValue] = useState<number | null>(
    null,
  )

  const [addingItemId, setAddingItemId] = useState('')

  const { loading, addEmergencyDrug } = useAddEmergencyDrug(
    currentSheetId ?? '',
    currentPatientId ?? '',
  )

  const addNewTreatment = useCallback(
    (formData: TemplateVariablesItem, totalVolume: number) => {
      const selectedItemId = formData.id
      const product = formData.treatment_options?.products?.[0]
      if (!product) return
      // TODO We don't have func to add fluid type treatment for now.
      const medObject = formData.medicine_dosage_info
      const isBillable = formData.is_billable
      const dosageRate = medObject?.dosage
      const dosageWeightUnit = medObject?.dosage_weight_unit as WeightUnit
      const dosagePerWeightUnit =
        medObject?.dosage_patient_weight_unit as WeightUnit
      const isInvalidMedication =
        isNil(dosageRate) ||
        isNil(dosageWeightUnit) ||
        isNil(dosagePerWeightUnit)

      if (isInvalidMedication && product.type === ProductType.MEDICATION) return

      const concentrationWeightUnit = product.medicine_dosage_info
        ?.concentration_weight_unit as WeightUnit | null

      setAddingItemId(selectedItemId)
      const totalDosage =
        dosageWeightUnit === WeightUnit.ML
          ? null
          : calculateTotalDosage({
              patientWeight: patientWeightValue,
              patientWeightUnit,
              dosageRate,
              dosageWeightUnit,
              dosagePerWeightUnit,
              concentrationWeightUnit,
              round,
            })

      const productConcentrationVolumeUnit =
        product.medicine_dosage_info?.concentration_volume_unit ?? null
      const productInfoRoute =
        product.medicine_dosage_info?.route_of_administration ?? null

      const defaultValues = {
        dosagePerWeightUnit,
        dosageRate,
        dosageWeightUnit,
        isBillable,
        totalVolume,
        concentrationWeightUnit,
        patientWeightUnit,
        totalDosage,
        routesOfAdmin: getDefaultRouteOfAdmin(
          productInfoRoute,
          productConcentrationVolumeUnit,
        ),
        patientWeight: patientWeightValue,
        timeWindow: TimeSegmentMinutes[timeSegment],
        concentration: product.medicine_dosage_info?.concentration,
        concentrationVolumeUnit: productConcentrationVolumeUnit,
        unitsBilledPerTask: totalVolume,
        enableStaffedHour: !!formData.schedule?.enable_staffed_hour,
      } as NonFluidFormData

      const productInfo = {
        productId: product.id,
        productName: product.name,
        productType: product.type as any,
        productGroupId: '',
        productGroupName: '',
        productGroupOrder: '',
      }

      addEmergencyDrug(defaultValues, productInfo, {
        createTasks: true,
      })
    },
    [
      patientWeightValue,
      patientWeightUnit,
      round,
      getDefaultRouteOfAdmin,
      timeSegment,
      addEmergencyDrug,
    ],
  )

  useEffect(() => {
    if (!patient) return
    const overrideWeight = patient?.weight ?? null
    const overrideWeightUnit = patient?.weight_unit ?? 'kg'
    setPatientWeightValue(overrideWeight)
    setPatientWeightUnit(overrideWeightUnit)
  }, [patient])

  const { data: dataTemplate, loading: loadingTemplate } = useQuery<
    GetTemplate,
    GetTemplateVariables
  >(GET_TEMPLATE, {
    variables: {
      id: tempId,
      organisation_id: organisationId,
    },
  })

  /**
   * @todo 09/07/21 VR-5797
   *
   * In the future, we should make this read off of the type of the template,
   * rather than a name with specific characters so that this feature is not
   * dependant on the users having this specific knowledge.
   */
  const isDrugTemplate = dataTemplate?.getTemplate?.name === DRUGS_TEMPLATE_NAME

  const templateTreatment = dataTemplate?.getTemplate?.treatments?.items
  const treatmentList = templateTreatment?.[0].treatments?.items ?? null
  const missedVolumesProduct = treatmentList?.find(obj => {
    const productDoseInfo =
      obj.treatment_options?.products?.[0].medicine_dosage_info
    const productConcentration = productDoseInfo?.concentration
    const productConcentrationUnit = productDoseInfo?.concentration_volume_unit
    const productConcentrationWeightUnit =
      productDoseInfo?.concentration_weight_unit

    const dosage = obj.medicine_dosage_info?.dosage
    const productType = obj.treatment_options?.products?.[0].type
    const isMedicationType = productType === ProductType.MEDICATION
    return (
      (!productDoseInfo ||
        productConcentration === 0 ||
        isNull(productConcentrationUnit) ||
        isNull(productConcentrationWeightUnit) ||
        isNull(dosage)) &&
      isMedicationType
    )
  })

  const emergDrawerState = useMemo(() => {
    if (loading) return EmergItemState.LOADING
    if (!treatmentList) return EmergItemState.EMPTY
    return EmergItemState.NORMAL
  }, [loading, treatmentList])

  const renderEmergencyDrugsItem = useCallback(
    ({ item }: { item: TemplateVariablesItem }) => (
      <EmergencyDrugItem
        addNewTreatment={addNewTreatment}
        addingItemId={addingItemId}
        isInSheetScreen={!!currentSheetId}
        patientWeight={patientWeightValue ?? 0}
        round={round}
        state={emergDrawerState}
        treatment={item}
        weightUnit={patientWeightUnit}
      />
    ),
    [
      addNewTreatment,
      addingItemId,
      currentSheetId,
      emergDrawerState,
      patientWeightUnit,
      patientWeightValue,
      round,
    ],
  )

  return (
    <View
      accessibilityLabel={'Emergency Drugs Drawer'}
      style={styles.container}
    >
      {loadingTemplate ? (
        <ActivityIndicator size="large" style={styles.spinner} />
      ) : isDrugTemplate ? (
        <FlatList
          data={treatmentList}
          extraData={[patientWeightValue, patientWeightUnit]}
          keyExtractor={emergencyDrugsListKeyExtractor}
          style={styles.flatList}
          renderItem={renderEmergencyDrugsItem}
          ListHeaderComponent={
            <EmergencyDrugsInput
              isInSheetScreen={!!currentSheetId}
              missedProductVolume={!!missedVolumesProduct}
              patientWeightUnit={patientWeightUnit}
              patientWeightValue={patientWeightValue}
              setPatientWeightUnit={setPatientWeightUnit}
              setPatientWeightValue={setPatientWeightValue}
              state={emergDrawerState}
            />
          }
        />
      ) : (
        missingEmergTemplate &&
        !!templateItem && (
          <View style={styles.errorContainer}>
            <Text style={styles.errorText}>
              {t('emergencyDrugs.errors.missingTemplate')}
            </Text>
          </View>
        )
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  spinner: {
    marginTop: 25,
  },
  flatList: {
    overflow: 'scroll',
    width: '100%',
  },
  drugHeaderContainer: {
    width: '95%',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 5,
  },
  drugHeader: {
    height: 30,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  drugHeaderText: {
    fontSize: 18,
    fontWeight: '600',
  },
  emergencyDrugsHeader: {
    width: '100%',
    alignItems: 'center',
  },
  firstDrugHeader: {
    marginLeft: ADD_BTN_SIZE,
  },
  patientWeightContainer: {
    width: '95%',
  },
  patientWeightInput: {
    flex: 1,
  },
  patientWeightUnit: {
    flex: 0.6,
  },
  iosPatientWeightUnit: {
    flex: 1,
  },
  msg: {
    fontFamily: Fonts.regular,
    marginTop: 5,
    marginBottom: 9,
    fontSize: 13,
    lineHeight: 19,
    paddingHorizontal: 16,
    color: Colors.warning,
  },
  errorContainer: {
    width: '90%',
  },
  errorText: {
    textAlign: 'center',
  },
})
