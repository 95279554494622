import React, { ReactElement } from 'react'
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { Fonts } from 'constants/Fonts'
import { Colors } from 'constants/Colors'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { Avatar } from '.'

export enum AlertLevel {
  BAD = 'BAD',
  GOOD = 'GOOD',
  NEUTRAL = 'NEUTRAL',
  WARNING = 'WARNING',
}

type Props = {
  alertLevel?: AlertLevel
  message: string
  name?: string
  size?: number
  uri?: string
  radius?: number
  indicator?: ReactElement
  avatarContainer?: StyleProp<ViewStyle>
}

type MessageProps = {
  alertLevel: AlertLevel
  title: string
  isCollapsed?: boolean
}
export const Message: React.FC<MessageProps> = ({
  alertLevel,
  title,
  isCollapsed,
}) => {
  return (
    <View
      accessibilityLabel="Avatar message"
      style={[styles.messageContainer, messageBoxColorsStyle[alertLevel]]}
    >
      <TextWithTooltip
        numberOfLines={1}
        style={[styles.text, isCollapsed && styles.collapsedMessageText]}
        title={title}
      >
        {title}
      </TextWithTooltip>
    </View>
  )
}

// TODO extract the Message and Alert levels to where they make sense

/**
 * Use uri if exist. Otherwise, use name initials (first 3 characters).
 */
export const AvatarWithMessage: React.FC<Props> = ({
  alertLevel,
  message,
  name,
  uri,
  avatarContainer,
  indicator,
}) => {
  return (
    <View
      style={[styles.center, avatarContainer]}
      accessibilityLabel="avatarWithMessage"
    >
      <View>
        <Avatar name={name} uri={uri} size={52} style={styles.imageStyle} />
        {!!indicator ? indicator : null}
      </View>
      <Message alertLevel={alertLevel ?? AlertLevel.NEUTRAL} title={message} />
    </View>
  )
}

const styles = StyleSheet.create({
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyle: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    height: 45,
    width: 56,
  },
  messageContainer: {
    alignItems: 'center',
    borderRadius: 6,
    justifyContent: 'center',
    minWidth: 30,
    maxWidth: 80,
  },
  text: {
    color: '#FFF',
    fontFamily: Fonts.bold,
    fontSize: 12,
    letterSpacing: 0.3,
    textAlign: 'center',
    paddingHorizontal: 4,
  },
  collapsedMessageText: {
    fontSize: 14,
  },
})

export const messageBoxColors = {
  BAD: {
    backgroundColor: Colors.patient.caution,
  },
  GOOD: {
    backgroundColor: Colors.teal,
  },
  NEUTRAL: {
    backgroundColor: Colors.darkGrey,
  },
  WARNING: {
    backgroundColor: Colors.patient.warning,
  },
  UNKNOWN: {
    backgroundColor: Colors.darkGrey,
  },
  TEXTLABEL: {
    backgroundColor: Colors.tag.messageLabelBackground,
  },
}

const messageBoxColorsStyle = StyleSheet.create(messageBoxColors)
