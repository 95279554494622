export const CHART_INCREMENT = 20
export const DEFAULT_RANGE_MAX = 200
export const RANGE_MIN = 0

export const getAnesthesiaChartRangeAndIncrement = (
  maxValue = DEFAULT_RANGE_MAX,
) => {
  let maxValueNumber = maxValue
  if (Number.isNaN(maxValueNumber)) maxValueNumber = DEFAULT_RANGE_MAX

  const increment = maxValueNumber / CHART_INCREMENT
  const range = [RANGE_MIN, maxValueNumber]
  return { range, increment }
}
