import { useCallback, useMemo } from 'react'
import { useUser } from 'src/context/user'
import { getSheet_getSheet } from 'src/types/getSheet'
import { ApprovalRole, ApprovalStatus } from 'src/types/globalTypes'
import { useUpdateTreatmentApprovalStatus } from 'components/Treatment/utils/useUpdateTreatmentApprovalStatus'
import { useApproveSheetTreatments } from 'components/Treatment/utils/useApproveSheetTreatments'

type SheetTreatmentsForApproval = {
  items:
    | {
        approval_status: ApprovalStatus | null
        treatments?: SheetTreatmentsForApproval | null
      }[]
    | null
}

export function isTreatmentApproved(treatment: {
  approval_status: ApprovalStatus | null | undefined
}): boolean {
  return (
    !treatment.approval_status ||
    treatment.approval_status === ApprovalStatus.APPROVED
  )
}

// In the future, if the pending treatment could be determined by other factors
export function isTreatmentPending(treatment: {
  approval_status: ApprovalStatus | null | undefined
}): boolean {
  return treatment.approval_status === ApprovalStatus.PENDING
}

export const useApprovals = () => {
  const currentUser = useUser()

  // Show UI elements unique to CAN_APPROVE users
  const showApproverUI = useMemo(() => {
    return currentUser.approvalRole === ApprovalRole.CAN_APPROVE
  }, [currentUser.approvalRole])

  // Recursively check sheet treatments and their children for ApprovalStatus.PENDING
  const _recursiveTreatmentsAreUnapproved = (
    treatments?: SheetTreatmentsForApproval | null,
  ): boolean => {
    return !!treatments?.items?.filter(treatment => {
      return (
        !isTreatmentApproved(treatment) ||
        _recursiveTreatmentsAreUnapproved(treatment?.treatments)
      )
    }).length
  }

  const sheetHasUnapprovedTreatments = (sheet?: getSheet_getSheet | null) => {
    return _recursiveTreatmentsAreUnapproved(sheet?.treatments)
  }

  const shouldUnapproveTreatment = (treatment: any): boolean => {
    return (
      currentUser.approvalRole === ApprovalRole.NEEDS_APPROVAL &&
      isTreatmentApproved(treatment)
    )
  }

  const { updateTreatmentApprovalStatus, loading: isApprovingTreatment } =
    useUpdateTreatmentApprovalStatus()

  const approveTreatment = useCallback(
    async (treatmentId: string, patientId: string) => {
      if (currentUser.approvalRole === ApprovalRole.CAN_APPROVE) {
        await updateTreatmentApprovalStatus(
          treatmentId,
          patientId,
          ApprovalStatus.APPROVED,
        )
      }
    },
    [currentUser.approvalRole, updateTreatmentApprovalStatus],
  )

  const { approveSheetTreatments } = useApproveSheetTreatments()

  const approveSheet = useCallback(
    async (sheetId: string, organisationId: string) => {
      if (currentUser.approvalRole === ApprovalRole.CAN_APPROVE) {
        await approveSheetTreatments(sheetId, organisationId)
      }
    },
    [approveSheetTreatments, currentUser.approvalRole],
  )

  return {
    approveSheet,
    approveTreatment,
    isApprovingTreatment,
    isTreatmentApproved,
    isTreatmentPending,
    sheetHasUnapprovedTreatments,
    showApproverUI,
    shouldUnapproveTreatment,
    updateTreatmentApprovalStatus,
  }
}
