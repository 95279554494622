import React, { useState } from 'react'
import {
  Control,
  Controller,
  FieldArrayWithId,
  useFieldArray,
} from 'react-hook-form'
import {
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  TextInput,
} from 'react-native'
import { PatientStatusInputs } from './PatientStatus'
import { NumberInput } from 'components/shared'
import { Colors, Typography, Variables } from 'src/design-system/theme'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'
import { SecondaryButton } from 'components/common'
import { SvgPencil } from 'components/Icons'
import { SvgTrash } from 'components/Icons/Trash'

type Props = {
  control: Control<PatientStatusInputs>
}

export const EditTimersForm: React.FC<Props> = ({ control }) => {
  const { t } = useTranslation()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'timers',
  })

  const addNewTimer = () =>
    append({
      __typename: 'SheetMetaTimer',
      name: 'New Timer',
      elapsed: 0,
      start_at: null,
    })

  return (
    <View style={styles.section}>
      {fields.map((item, index) => (
        <TimerCard
          key={item.id}
          control={control}
          item={item}
          index={index}
          remove={remove}
        />
      ))}
      <SecondaryButton
        title={`+ ${t('sheetForm.addTimer')}`}
        onPress={addNewTimer}
        style={styles.addContainer}
        textStyle={styles.addLink}
      />
    </View>
  )
}

const TimerCard: React.FC<{
  control: Control<PatientStatusInputs>
  item: FieldArrayWithId<PatientStatusInputs, 'timers', 'id'>
  index: number
  remove: (index: number) => void
}> = ({ control, item, index, remove }) => {
  const [isEditingName, setIsEditingName] = useState(false)

  const onTextInputBlur = () => setIsEditingName(false)
  const onTextPress = () => setIsEditingName(true)

  return (
    <View key={item.id} style={styles.timerContainer}>
      <View style={styles.headerRow}>
        <Controller
          control={control}
          name={`timers.${index}.name`}
          render={({ field: { value, onChange } }) =>
            isEditingName ? (
              <TextInput
                value={value}
                onChangeText={onChange}
                autoFocus={true}
                editable={isEditingName}
                maxLength={15}
                onBlur={onTextInputBlur}
                style={[styles.title, styles.editingTitle]}
              />
            ) : (
              <TouchableOpacity
                onPress={onTextPress}
                style={styles.titleContainer}
              >
                <Text style={styles.title}>{value}</Text>
                <SvgPencil
                  height={Typography.FontSizes.base}
                  width={Typography.FontSizes.base}
                  color={Colors.Contents.accessory}
                />
              </TouchableOpacity>
            )
          }
        />
        {index > 1 && ( // minimum two timers
          <TouchableOpacity onPress={() => remove(index)}>
            <SvgTrash
              color={Colors.Contents.tertiary}
              size={styles.title.fontSize}
            />
          </TouchableOpacity>
        )}
      </View>
      <Controller
        control={control}
        name={`timers.${index}.elapsed`}
        render={({ field: { value, onChange } }) => (
          <TimeEditor value={value} onChange={onChange} />
        )}
      />
    </View>
  )
}

const TimeEditor: React.FC<{
  value: number
  onChange: (val: number) => unknown
}> = ({ value, onChange }) => {
  const { t } = useTranslation()
  const elapsedMins = Math.floor(value / 60) || 0
  const hours = Math.floor(elapsedMins / 60)
  const mins = elapsedMins % 60

  const handleHourChange = (newHour: number) => {
    onChange((newHour * 60 + mins) * 60)
  }

  const handleMinChange = (newMin: number) => {
    onChange((hours * 60 + newMin) * 60)
  }

  return (
    <View style={styles.timeEditorContainer}>
      <NumberInput
        label={t('general.hours')}
        value={hours}
        onChange={handleHourChange}
        max={59}
      />
      <NumberInput
        label={t('general.minutes')}
        value={mins}
        onChange={handleMinChange}
        max={59}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    gap: Variables.GutterSpacing.base,
  },
  title: {
    fontFamily: Fonts.regular,
    fontSize: Typography.FontSizes.md,
    fontWeight: Typography.FontWeights.regular,
    color: Colors.Contents.secondary,
  },
  editingTitle: {
    borderColor: Colors.Borders.accent,
    flexGrow: 1,
    borderWidth: 1,
    borderRadius: Variables.GutterSpacing.sm,
    padding: Variables.GutterSpacing.sm,
  },
  section: {
    flexDirection: 'column',
    gap: Variables.GutterSpacing.base,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: Variables.GutterSpacing.md,
  },
  timeEditorContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: Variables.GutterSpacing.base,
  },
  timerContainer: {
    backgroundColor: Colors.Backgrounds.UI,
    borderColor: Colors.Borders.primary,
    padding: Variables.GutterSpacing.md,
    gap: Variables.GutterSpacing.md,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  addContainer: {
    justifyContent: 'flex-start',
    padding: Variables.GutterSpacing.md,
  },
  addLink: {
    fontFamily: Fonts.regular,
    fontSize: Typography.FontSizes.base,
    fontWeight: Typography.FontWeights.bold,
    lineHeight: 16,
    color: Colors.Contents.accent,
  },
})
