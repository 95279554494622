import { DimensionValue } from 'react-native/types'
import { DoneLineWidthErrorMessage } from '../constants/constants'

/**
 * To calculate the width for the progress bar done line.
 *
 * @example  if we have 5 steps -  [step1, step2, step3, step4, step5]
 * and the chosen step index is 2, which is step 3.
 *
 * We first get the unit size in percentage which can be evenly separated from the line.
 * in this case, 100/(5+1) as we dont have any starting and ending step on the line.
 * Then, we calculate how many units from beginning of the line to the chosen step.
 * in this case, there are 3 units. So the total units in percentage is 100/(5+1)*3= '50%'
 */

export const getDoneLineWidth = (
  stepsTotalCount: number,
  indexOfSelectedStepName: number,
): DimensionValue => {
  if (stepsTotalCount <= indexOfSelectedStepName)
    throw new Error(DoneLineWidthErrorMessage)
  return indexOfSelectedStepName < 0
    ? '0%'
    : `${(100 / (stepsTotalCount + 1)) * (indexOfSelectedStepName + 1)}%`
}
