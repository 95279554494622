import React from 'react'
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  ViewStyle,
} from 'react-native'
import { partition } from 'lodash'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'
import { toast } from 'components/common'

type Props = {
  asaCat: string | null | undefined
  isEdit: boolean
  onPress?: (cat: string) => void
  groupStyle?: ViewStyle
}

export const ASACat = [
  'I',
  'IE',
  'II',
  'IIE',
  'III',
  'IIIE',
  'IV',
  'IVE',
  'V',
  'VE',
]

export const AsaCategory: React.FC<Props> = ({
  asaCat,
  isEdit,
  onPress,
  groupStyle,
}) => {
  const { t } = useTranslation()

  const separateEmergencyCategoriesFromRegularCategories = partition(
    ASACat,
    (cat: string) => (ASACat.indexOf(cat) + 1) % 2 === 1,
  )
  const [categories, categoriesWithEmergency] =
    separateEmergencyCategoriesFromRegularCategories
  const isEmergency = categoriesWithEmergency.includes(asaCat as string)

  const handleCategoryChange = (cat: string) => {
    if (onPress) {
      const newCat = `${cat}${isEmergency ? 'E' : ''}`
      onPress(newCat)
    }
  }

  const handleSelectEmergency = () => {
    if (onPress && asaCat) {
      if (!isEmergency) {
        onPress(`${asaCat}E`)
      } else {
        onPress(asaCat.slice(0, -1))
      }
      return
    }
    toast.notice(t('sheet:addEmergencyToAsaCatWarning'))
  }

  const returnEmergencyOption = () => {
    if (!isEmergency && !isEdit) return null
    return (
      <TouchableOpacity
        style={[
          styles.filterButton,
          styles.lastFilterButton,
          isEdit && styles.editFilterButton,
          isEmergency && styles.selectedButton,
          isEdit && isEmergency && styles.editSelectedButton,
        ]}
        disabled={!isEdit}
        onPress={handleSelectEmergency}
      >
        <Text style={[styles.groupName, isEmergency && styles.selectedName]}>
          E
        </Text>
      </TouchableOpacity>
    )
  }

  if (!asaCat && !isEdit) return null
  return (
    <View style={[styles.groups, groupStyle]}>
      {categories.map((cat, idx) => {
        const showSelected =
          cat === asaCat ||
          idx === categoriesWithEmergency.indexOf(asaCat as string)
        if (!showSelected && !isEdit) return null
        const changeCategory = () => handleCategoryChange(cat)
        return (
          <TouchableOpacity
            accessibilityLabel={`Asa category button ${idx + 1}`}
            key={idx}
            disabled={!isEdit}
            style={[
              styles.filterButton,
              isEdit && styles.editFilterButton,
              showSelected && styles.selectedButton,
              isEdit && showSelected && styles.editSelectedButton,
            ]}
            onPress={changeCategory}
          >
            <Text
              style={[styles.groupName, showSelected && styles.selectedName]}
            >
              {cat}
            </Text>
          </TouchableOpacity>
        )
      })}
      {returnEmergencyOption()}
    </View>
  )
}

const styles = StyleSheet.create({
  groups: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  groupName: {
    flexDirection: 'row',
    color: Colors.contentSecondary,
    fontFamily: Fonts.regular,
    fontSize: 15,
    textAlign: 'center',
    marginHorizontal: 'auto',
  },
  filterButton: {
    alignItems: 'center',
    backgroundColor: Colors.lightGrey,
    justifyContent: 'center',
    borderRadius: 12,
    flexDirection: 'row',
    marginHorizontal: 6,
    marginVertical: 4,
    width: 24,
    height: 24,
  },
  editFilterButton: {
    width: 32,
    height: 26,
    backgroundColor: Colors.white,
    marginHorizontal: 8,
    marginTop: 8,
  },
  lastFilterButton: {
    paddingRight: 0,
    marginRight: 0,
  },
  selectedButton: {
    backgroundColor: Colors.vetRadarOrange,
  },
  editSelectedButton: {
    backgroundColor: Colors.blue,
  },
  selectedName: {
    color: Colors.white,
  },
})
