import { Colors } from 'constants/Colors'
import { Colors as dsColors } from 'src/design-system/theme'
import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  bigFlex: {
    flex: 9,
    marginRight: 'auto',
  },
  container: {
    flex: 1,
    height: 'auto',
    paddingVertical: 12,
  },
  flex: {
    flex: 1,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    paddingHorizontal: 0,
  },
  buttonLayout: {
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  buttonLayoutSmall: {
    flexDirection: 'column',
  },
  buttonSmall: {
    marginBottom: 16,
  },
  linkBtnsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  linkBtnStyle: {
    marginHorizontal: 10,
    paddingBottom: 16,
  },
  select: {
    width: '100%',
  },
  unit: {
    width: '30%',
  },
  activityLogContainer: {
    paddingHorizontal: 16,
  },
  buttonStyle: {
    flexDirection: 'row',
    borderRadius: 4,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderColor: dsColors.Contents.accent,
    borderWidth: 1,
    backgroundColor: dsColors.Backgrounds.UI,
    height: 30,
    color: dsColors.Contents.accent,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  disabledButtonStyle: {
    opacity: 0.36,
  },
  buttonTextStyle: {
    color: dsColors.Contents.accent,
    fontWeight: '700',
    fontSize: 14,
    backgroundColor: dsColors.Backgrounds.UI,
  },
  useValueButtonContainer: {
    backgroundColor: dsColors.Backgrounds.UI,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderColor: dsColors.Borders.primary,
    height: 60,
  },
  initialsValueContainer: {
    flexDirection: 'row',
  },
  initialsValueContainerMobile: {
    flexDirection: 'column',
  },
  border: { borderWidth: 2 },
  inProgressButtonBorder: { borderColor: Colors.green },
  disabledInProgressButtonBorder: { borderColor: Colors.disabled },
  inProgressButtonText: { color: Colors.green },
  disabledInProgressButtonText: { color: Colors.white },
  leftMargin: { marginLeft: 16 },
})
