import {
  TreatmentChildLevel,
  TreatmentSheet,
} from 'components/Treatment/common/types'

// get all 2 child levels treatments no group
export function getFlattenedTreatments(
  treatmentGroups: TreatmentSheet[],
): TreatmentChildLevel[] {
  return treatmentGroups.reduce((previousTreatments, treatmentGroup) => {
    if ('treatments' in treatmentGroup) {
      const treatmentItems = treatmentGroup.treatments?.items
      if (treatmentItems) {
        return [
          ...previousTreatments,
          ...treatmentItems,
          ...getFlattenedTreatments(treatmentItems),
        ]
      }
    }
    return previousTreatments
  }, [] as TreatmentChildLevel[])
}
