import React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Colors, Fonts, Routes } from 'constants/constants'
import { SvgExclamation, SvgPendingClock } from 'components/Icons'
import { Avatar } from 'components/common/Avatar'
import { Indicator } from 'components/common/Indicator/indicator'
import * as RootNavigation from 'components/Base/navigation/navigation'
import { useKeyToUrl } from 'src/hooks/useKeyToUrl'
import { Pill } from 'components/common/Pill'

const MAX_TASK_VALUE = 9

type Props = {
  sheetId: string
  patientId: string
  patientName: string
  contactLastName?: string | null
  sheetName?: string
  patientImage: string
  dueTasks: number
  missedTasks: number
  studentApproval: boolean
  patientColor: string
  onClose: () => void
}

export const SheetHistoryModalRow = ({
  sheetId,
  patientId,
  patientName,
  contactLastName = '',
  sheetName,
  patientImage,
  dueTasks,
  missedTasks,
  studentApproval = false,
  patientColor = Colors.white,
  onClose,
}: Props) => {
  const profileColor = { backgroundColor: patientColor }

  const handleOnPress = () => {
    const params = {
      patientId,
      sheetId,
      sheetName,
      isFromWhiteboard: false,
    }
    const screen = Routes.Sheet
    RootNavigation.navigate(screen, params)
    onClose()
  }

  const showTaskValue = (value: number) => {
    return value > MAX_TASK_VALUE ? `${MAX_TASK_VALUE}+` : value.toString()
  }

  const patientImg = useKeyToUrl(patientImage, 160)

  return (
    <Pressable
      style={styles.rowContainer}
      onPress={() => handleOnPress()}
      aria-labelledby={`Sheet history - ${patientName} ${contactLastName}`}
    >
      <View style={[styles.profileIconContainer, profileColor]}>
        <View style={styles.pictureContainer}>
          <Avatar name={patientName} uri={patientImg} size={44} />
          {studentApproval ? <Indicator size={12} /> : null}
        </View>
      </View>

      <View style={styles.informationContainer}>
        <Text
          style={styles.patientName}
          numberOfLines={1}
        >{`"${patientName}" ${contactLastName}`}</Text>
        <Text style={styles.sheetName} numberOfLines={1}>
          {sheetName}
        </Text>
      </View>
      <View style={styles.tasksContainer}>
        {missedTasks > 0 ? (
          <Pill
            size={'small'}
            color={Colors.sheetHistoryRow.missedTasksBackground}
            icon={
              <SvgExclamation
                height={14}
                color={Colors.sheetHistoryRow.missedTasksPrimary}
              />
            }
            text={showTaskValue(missedTasks)}
            textStyle={styles.missedTaskText}
          />
        ) : null}
        {dueTasks > 0 ? (
          <Pill
            size={'small'}
            color={Colors.sheetHistoryRow.dueTasksBackground}
            style={{ marginLeft: 8 }}
            icon={
              <SvgPendingClock
                height={16}
                color={Colors.sheetHistoryRow.dueTasksPrimary}
              />
            }
            text={showTaskValue(dueTasks)}
            textStyle={styles.dueTaskText}
          />
        ) : null}
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    height: 60,
    borderColor: Colors.borderGrey,
    borderBottomWidth: 1,
    backgroundColor: Colors.white,
  },
  profileIconContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
  },
  informationContainer: {
    flex: 2,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: 8,
  },
  tasksContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 16,
  },
  pictureContainer: {
    width: 45,
    height: 45,
  },
  patientName: {
    fontFamily: Fonts.bold,
    fontSize: 16,
    marginBottom: 3,
  },
  sheetName: {
    fontFamily: Fonts.regular,
    color: Colors.sheetHistoryRow.sheetName,
    fontSize: 14,
  },
  missedTaskText: {
    color: Colors.sheetHistoryRow.missedTasksPrimary,
  },
  dueTaskText: {
    color: Colors.sheetHistoryRow.dueTasksPrimary,
  },
})

export default SheetHistoryModalRow
