import Constants from 'expo-constants'

// duplicates environment.ts, unable to access .ts 
const manifest = Constants.expoConfig

const manifestExtra =
  manifest?.extra?.expoClient?.extra ?? manifest?.extra ?? {}
const vetradarConfig = manifestExtra?.vetradar ?? {}

const {
  aws_cognito_identity_pool_id,
  aws_user_pools_id,
  aws_user_pools_web_client_id,
  file_bucket_url: fileBucketUrl,
  image_bucket_url: imageBucketUrl,
  s3_prefix_for_ttl: s3PrefixForTtl,
  s3_video_prefix_for_ttl: s3VideoPrefixForTtl,
  graphql_endpoint: graphqlEndpoint,
  auth_endpoint: authEndpoint,
} = vetradarConfig

export const settings = {
  s3PrefixForTtl,
  s3VideoPrefixForTtl,
  fileBucketUrl,
  imageBucketUrl
}

// For Amplify.configure
export const vetradarmobile = {
  graphqlEndpoint,
  authEndpoint,
  aws_cognito_identity_pool_id,
  aws_user_pools_id,
  aws_user_pools_web_client_id,
  Analytics: { disabled: true },
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_project_region: 'us-west-2',
  Storage: {
    AWSS3: {
      bucket: fileBucketUrl,
      region: 'us-west-2',
    }
  }
}

export default vetradarmobile
