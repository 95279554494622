import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Typography } from 'src/design-system/theme'
import { useTranslation } from 'react-i18next'
import { Colors } from 'constants/Colors'

export const SheetHistoryModalHeader: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View style={styles.headerContainer}>
      <Text style={styles.headerText}>{t('history:sheet.title')}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  headerText: {
    fontWeight: Typography.FontWeights.bold,
    lineHeight: 16,
    fontSize: Typography.FontSizes.base,
  },
  headerContainer: {
    backgroundColor: Colors.history.modal.headerBackground,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
})
