import {
  initialTemplateFormInputs,
  TemplateFormInput,
} from 'components/CreateSheet/createSheetData'
import { FrequencyInput } from 'components/FrequencySelector/FrequencySelector'
import { getFrequencyInputFromSchedule } from 'components/Treatment/utils/treatmentSchedule.utils'
import { RepeatScheduleValue } from 'components/TreatmentForm/data'
import { getScheduleOverwriteFromFrequencyInput } from 'components/TreatmentForm/utils/getSchedule'
import { getTreatmentInput } from 'components/TreatmentForm/utils/getTreatmentInput'
import { startOfMinute } from 'date-fns'
import { compact, isEmpty, isNil } from 'lodash'
import { FormData } from 'src/components/TreatmentForm'
import {
  AssociatedSites,
  checkIsProductOrTemplateAvailable,
} from 'src/hooks/useAssociatedSiteIds'
import {
  getTemplate_getTemplate_treatments_items_treatments_items as TemplateTreatmentItem,
  getTemplate_getTemplate_treatments_items_treatments_items_treatment_options_products,
} from 'src/types/getTemplate'
import { FrequencyType } from 'src/types/globalTypes'

import { TemplateDuplicationIdHash } from './useTemplateDuplication'

type SheetGroup = {
  name: string
  order: number | null
}

export type GroupNameHash = {
  [id: string]: SheetGroup
}

export type PartialFormData = Partial<FormData>
export const getInitialValuesV2 = (
  values: FormDataTemplate,
  overrides: Partial<FormDataTemplate>,
) => {
  const startTime = overrides.startTime ?? values.startTime

  const applyWorkflow = overrides.applyWorkflow ?? values.applyWorkflow
  const name = overrides.name ?? values.name

  const generalInitialValues = { startTime, applyWorkflow, name }
  if (!overrides.templateTreatmentFormInputs) {
    return {
      ...generalInitialValues,
      templateTreatmentFormInputs: values.templateTreatmentFormInputs,
    }
  }
  return {
    ...generalInitialValues,
    templateTreatmentFormInputs: overrides.templateTreatmentFormInputs.map(
      (templateTreatmentFormInput, index) => {
        return {
          ...templateTreatmentFormInput,
          // isDuplicated is a read only field, but can be changed when more templates added
          isDuplicated:
            values.templateTreatmentFormInputs?.[index]?.isDuplicated,
        }
      },
    ),
  }
}

export const getInitialValues = (
  values: FormDataTemplate,
  overrides: Partial<FormDataTemplate>,
) => {
  return { ...values, ...overrides }
}

export type CreateTreatmentFromInput = {
  defaultOverrides: Partial<FormData>
  product: getTemplate_getTemplate_treatments_items_treatments_items_treatment_options_products
} & { isSetup: boolean }

export type TemplateTreatmentFormInput = {
  shouldCreateTreatment: boolean // create or not
  frequencyInput?: FrequencyInput // normal template treatment
  createTreatmentFromInput?: CreateTreatmentFromInput //  setup condition template treatment
  templateTreatmentId: string // readonly
  name: string // readonly
  sheetGroup: SheetGroup // readonly
  isDuplicated?: boolean // readonly
  productId?: string // readonly
  initFrequencyInput?: FrequencyInput // readonly
}
export type FormDataTemplate = {
  startTime: Date
  applyWorkflow: boolean
  name?: string
  templateTreatmentFormInputs?: TemplateTreatmentFormInput[]
}

export type FormDataTemplateWithId = FormDataTemplate & {
  templateId: string
  index: number
  sites: string[]
}

export const filterTemplateFormInputs = (
  templateFormInputs: TemplateFormInput[],
  associatedSiteIds: AssociatedSites | null,
) => {
  if (!associatedSiteIds) {
    return templateFormInputs
  }
  const filteredTemplateFormInputs = templateFormInputs.filter(
    templateFormInput =>
      checkIsProductOrTemplateAvailable(
        associatedSiteIds,
        templateFormInput.selectedTemplate.sites,
      ),
  )

  if (!filteredTemplateFormInputs.length) {
    return initialTemplateFormInputs
  }
  return filteredTemplateFormInputs
}

type GetInitialTemplateTreatment = {
  templateTreatments: TemplateTreatmentItem[]
  groupNameHash: GroupNameHash
  templateDuplicationIdHash: TemplateDuplicationIdHash
  startTime?: Date
}

export const getInitialTemplateTreatment = ({
  templateTreatments,
  groupNameHash,
  startTime,
  templateDuplicationIdHash,
}: GetInitialTemplateTreatment) => {
  const currentTemplateDuplicate: { [id: string]: true | undefined } = {}
  return compact(
    templateTreatments.map(treatment => {
      const treatmentOptionProducts = treatment.treatment_options?.products
      if (!treatmentOptionProducts || isEmpty(treatmentOptionProducts)) return

      const parentSheetGroup =
        groupNameHash[treatmentOptionProducts[0].parent_product_id]

      const treatmentSheetGroup: SheetGroup = {
        name:
          treatmentOptionProducts.length > 1 || !parentSheetGroup?.name
            ? '' // no sheet group name for conditional treatment
            : parentSheetGroup?.name,
        order:
          treatmentOptionProducts.length > 1 || isNil(parentSheetGroup?.order)
            ? 9999 // if conditional treatment or no sheet group, order at the end
            : parentSheetGroup?.order,
      }

      const templateTreatmentFormInputs = {
        sheetGroup: treatmentSheetGroup,
        templateTreatmentId: treatment.id,
        name: treatment.name,
      }

      const isMultiTreatmentOptions = treatmentOptionProducts.length > 1

      if (isMultiTreatmentOptions) {
        const isMultiConditionalTreatmentDuplicated =
          !!templateDuplicationIdHash[treatment.id]
        return {
          ...templateTreatmentFormInputs,
          isDuplicated: isMultiConditionalTreatmentDuplicated,
          shouldCreateTreatment: !isMultiConditionalTreatmentDuplicated,
        }
      }

      const singleProduct = treatmentOptionProducts[0]

      const isProductDuplicated =
        templateDuplicationIdHash[singleProduct.id] ||
        currentTemplateDuplicate[singleProduct.id]

      if (!isProductDuplicated) {
        currentTemplateDuplicate[singleProduct.id] = true
      }

      const isSingleConditional = !!treatment.conditional

      if (isSingleConditional) {
        const createTreatmentFromInput = {
          defaultOverrides: getTemplateTreatmentDefault(treatment, startTime),
          product: singleProduct,
          isSetup: false,
        }
        return {
          ...templateTreatmentFormInputs,
          isDuplicated: isProductDuplicated,
          createTreatmentFromInput,
          shouldCreateTreatment: !isProductDuplicated,
          productId: singleProduct.id,
        }
      }

      const { frequencyInput } = getFrequencyInputFromSchedule(
        treatment.schedule,
      )

      const normalTemplateTreatmentFormInputs = {
        ...templateTreatmentFormInputs,
        isDuplicated: isProductDuplicated,
        shouldCreateTreatment: !isProductDuplicated,
        productId: singleProduct.id,
      }

      if (!frequencyInput) return normalTemplateTreatmentFormInputs

      return {
        ...normalTemplateTreatmentFormInputs,
        frequencyInput,
        initFrequencyInput: frequencyInput, // duplicate this field to check user change frequency
      }
    }),
  )
}

export const generateTemplateTreatmentInputs = (
  setupTemplateTreatmentIds: {
    organisation_id: string
    patient_id: string
    sheet_id: string
  },
  templateTreatmentFormInputs?: TemplateTreatmentFormInput[],
) => {
  if (!templateTreatmentFormInputs) return []

  return compact(
    templateTreatmentFormInputs.map(formInput => {
      const {
        templateTreatmentId,
        shouldCreateTreatment,
        frequencyInput,
        createTreatmentFromInput,
        initFrequencyInput,
      } = formInput

      const defaultTemplateTreatmentInput = {
        template_treatment_id: templateTreatmentId,
        should_create_treatment: shouldCreateTreatment,
      }

      if (!shouldCreateTreatment) {
        return defaultTemplateTreatmentInput
      }

      if (createTreatmentFromInput?.isSetup) {
        const { product, defaultOverrides } = createTreatmentFromInput
        const productType = product.type ?? undefined

        const input = getTreatmentInput(
          defaultOverrides as FormData,
          productType,
        )

        const shouldCreateTasks =
          defaultOverrides.isRepeating !== RepeatScheduleValue.NONE

        return {
          ...defaultTemplateTreatmentInput,
          treatment_input: {
            input: {
              ...input,
              product_id: product.id,
              ...setupTemplateTreatmentIds,
            },
            options: { createTasks: shouldCreateTasks },
          },
        }
      }

      if (!frequencyInput) {
        // conditional treatment
        return null
      }

      if (isSameFrequencyInput(frequencyInput, initFrequencyInput)) {
        // user have not changed the frequency
        return null
      }

      return {
        ...defaultTemplateTreatmentInput,
        schedule_overwrite:
          getScheduleOverwriteFromFrequencyInput(frequencyInput),
      }
    }),
  )
}

export const isSameFrequencyInput = (
  frequencyInput: FrequencyInput,
  initFrequencyInput: FrequencyInput | undefined,
) => {
  if (!initFrequencyInput) return false

  if (frequencyInput.type !== initFrequencyInput.type) {
    return false
  }

  // same type
  if (frequencyInput.type === FrequencyType.INTERVAL) {
    // compare interval number from the first frequency item
    return (
      frequencyInput.frequencies?.[0] === initFrequencyInput.frequencies?.[0]
    )
  }

  if (frequencyInput.type === FrequencyType.MINS_FROM_MIDNIGHT) {
    // compare id when type is MINS_FROM_MIDNIGHT
    return frequencyInput.id === initFrequencyInput?.id
  }

  // no task or single task
  return true
}

export const getTemplateTreatmentDefault = (
  treatment?: TemplateTreatmentItem,
  startTime?: Date,
) => {
  const schedule = treatment?.schedule

  const templateTreatmentMedicineDosageInfo = treatment?.medicine_dosage_info

  const dosageRate = templateTreatmentMedicineDosageInfo?.dosage
  const dosagePerWeightUnit =
    templateTreatmentMedicineDosageInfo?.dosage_patient_weight_unit
  const dosageWeightUnit =
    templateTreatmentMedicineDosageInfo?.dosage_weight_unit

  const dosageInfoFromTemplateTreatment = {
    ...(dosageRate && {
      dosageRate,
    }),
    ...(dosagePerWeightUnit && {
      dosagePerWeightUnit,
    }),
    ...(dosageWeightUnit && {
      dosageWeightUnit,
    }),
  }

  const defaultOverrides = {
    ...getFrequencyInputFromSchedule(schedule),
    isRepeating: schedule?.repeat
      ? RepeatScheduleValue.REPEATING
      : RepeatScheduleValue.NONE,
    isBillable: treatment?.is_billable,
    startAtDate: startTime ?? startOfMinute(new Date()),
    repeatUntilDate:
      schedule?.repeat && schedule?.repeat_until
        ? new Date(schedule.repeat_until!)
        : 'discharge',
    timeWindow: schedule?.time_window,
    enableStaffedHour: !!schedule?.enable_staffed_hour,
    repeating: schedule?.frequency,
    instructions: treatment?.instructions ?? '',
    isInstructionsImportant: false,
    isAutoCompleted: !!treatment?.is_auto_completed,
    isSetup: false,
    ...dosageInfoFromTemplateTreatment,
  } as PartialFormData
  return defaultOverrides
}
