import React, { useEffect, useMemo, useRef } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormBreak,
  FormLabel,
  InputGroup,
  InputGroupText,
  NumericInput,
  Select,
} from 'components/common'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'
import { Status } from 'components/common/Form/Status'
import { SelectOption } from 'components/common/Select/Select.types'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { TFunction } from 'i18next'
import { isNil, isNull } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { DosageFormDurationInfo } from './components/DosageFormDurationInfo'
import { getPhysiologicReq } from 'src/constants/PhysiologicReq'
import { getShockRates } from 'src/constants/ShockRates'
import { WeightUnit } from 'src/constants/Weight'
import { getOneProduct_getProduct as OneProduct } from 'src/types/getOneProduct'
import { ApprovalStatus } from 'src/types/globalTypes'
import * as Yup from 'yup'
import { useRound } from 'src/hooks/useRound'

import { RepeatScheduleValue } from './data'
import { FluidMedication, getNormedWeight } from './utils/getCalculations'
import { ConditionalTreatmentDeleteBtn } from 'components/TreatmentSetup/SelectProductsScreen'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { ScheduleTask } from 'components/common/Form/Sections'
import { useStartTimeRefresh } from 'src/hooks/useStartTimeRefresh'
import { useFlags } from 'react-native-flagsmith/react'
import { SaveTreatmentButton } from './SaveTreatmentButton'
import { PatientWeight } from './components/PatientWeight'
import { Instructions } from './components/Instructions'
import { FluidDosageFormData } from './types'
import { getDefaultFluidDosageFormValues } from './utils/getDefaultFluidDosageFormValues'

const getRequiredNumberSchema = (requiredMessage: string) => {
  return Yup.number().nullable().required(requiredMessage)
}

const getRequiredWeightUnitSchema = (requiredMessage: string) => {
  return Yup.string().nullable().required(requiredMessage)
}

const getSchema = (t: TFunction) => {
  const requiredMessage = t('form.required')
  const requiredNumberSchema = getRequiredNumberSchema(requiredMessage)

  return Yup.object().shape({
    patientWeight: requiredNumberSchema,
    totalResults: requiredNumberSchema,
    patientWeightUnit: getRequiredWeightUnitSchema(requiredMessage),
  })
}

type Props = {
  patientWeight?: number
  patientWeightUnit?: string
  patientWeightUpdatedAt?: string | null
  approvalStatus?: ApprovalStatus
  species: string
  isEdit?: boolean
  defaultOverrides?: FluidDosageFormData
  onSave: (data: FluidDosageFormData) => void
  isNewSheet?: boolean
  product: OneProduct | Partial<OneProduct>
  submitting?: boolean
  deleteTreatment?: () => void
  submitTitle?: string
  styleForWebOriOS?: boolean
}

const fluidUnitOptions: SelectOption<FluidMedication>[] = [
  {
    value: FluidMedication.ML,
    text: 'mL',
  },
  {
    value: FluidMedication.L,
    text: 'L',
  },
]

export const FluidDosageForm: React.FC<Props> = ({
  patientWeight: defaultPatientWeight,
  patientWeightUnit: defaultPatientWeightUnit,
  patientWeightUpdatedAt,
  species,
  approvalStatus,
  isEdit = false,
  onSave,
  isNewSheet = false,
  product,
  submitting = false,
  deleteTreatment,
  defaultOverrides,
  submitTitle,
  styleForWebOriOS,
}) => {
  const { t } = useTranslation()
  const flags = useFlags([FeatureFlagNames.DefaultDayFluidRate])
  const round = useRound()
  const startTime = useStartTimeRefresh()
  const validationSchema = getSchema(t)

  const defaultValues = getDefaultFluidDosageFormValues({
    defaultPatientWeight,
    defaultPatientWeightUnit,
    startTime,
    product,
    defaultOverrides,
  })

  const {
    setValue,
    getValues,
    watch,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm<FluidDosageFormData>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })
  const isHypovolemic = watch('isHypovolemic', false)
  const isDehydrated = watch('isDehydrated', false)
  const presetRateText = watch('presetRateText')
  const watchedIsContinuous = watch('isContinuous', defaultValues.isContinuous)
  const hasErrors = Object.keys(errors).length > 0

  const handleOnSave = (data: FluidDosageFormData) => {
    onSave({
      ...defaultValues,
      ...data,
    })
  }
  const isFirstRenderAndEdit = useRef(isEdit)
  // calculate shockTotalResult
  useEffect(() => {
    calculateShockTotalResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHypovolemic])

  // calculate physiologicReq
  useEffect(() => {
    calculatePhysiologicReqResult()
    if (isFirstRenderAndEdit.current) {
      isFirstRenderAndEdit.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isFirstRenderForStartAtDate = useRef(true)

  useEffect(() => {
    if (isEdit) {
      return
    }

    const defaultOverridesStartAtDate = defaultOverrides?.startAtDate
    // defaultOverridesStartAtDate should overwrite the startAtDate when first time render
    if (isFirstRenderForStartAtDate.current && defaultOverridesStartAtDate) {
      setValue('startAtDate', defaultOverridesStartAtDate)
      isFirstRenderForStartAtDate.current = false
      return
    }

    const { isDirty, isTouched } = control.getFieldState('startAtDate')
    // Only keep updating the refresh timer if the field has not been touched or modified.
    if (!isTouched && !isDirty) {
      setValue('startAtDate', startTime)
    }
  }, [startTime, control, setValue, isEdit, defaultOverrides?.startAtDate])

  const calculateShockTotalResult = () => {
    let newShockTotalResult = null
    const [patientWeight, patientWeightUnit] = getValues([
      'patientWeight',
      'patientWeightUnit',
    ])
    if (isHypovolemic && !isNull(patientWeight)) {
      const normedWeight = getNormedWeight(
        patientWeight,
        patientWeightUnit,
        WeightUnit.KG,
      )
      newShockTotalResult = round(getShockRates(species) * normedWeight * 0.25)
    }
    setValue('shockTotalResult', newShockTotalResult)
  }

  const calculatePhysiologicReqResult = () => {
    // If it ain't feline or canine, too bad no calcs
    const [physiologicReq, patientWeight, patientWeightUnit, totalUnit] =
      getValues([
        'physiologicReq',
        'patientWeight',
        'patientWeightUnit',
        'totalUnit',
      ])
    let physiologicReqResult = null

    let unitVolume = 1
    if (totalUnit === FluidMedication.L) {
      unitVolume = 1000
    }

    if (physiologicReq) {
      calculateTotalResults()
      return
    }

    if (!isNull(patientWeight)) {
      const normedWeight = getNormedWeight(
        patientWeight,
        patientWeightUnit,
        WeightUnit.KG,
      )
      if (flags.setting_default_day_fluid_rate.enabled) {
        physiologicReqResult = normedWeight * 60
      } else {
        physiologicReqResult = round(
          ((getPhysiologicReq(species) * normedWeight ** 0.75) / 24) *
            unitVolume,
        )
      }
    }
    setValue('physiologicReq', physiologicReqResult)

    calculateTotalResults()
  }

  const calculateDehydrationResult = () => {
    const [patientWeight, patientWeightUnit, dehydration, hoursToAdmin] =
      getValues([
        'patientWeight',
        'patientWeightUnit',
        'dehydration',
        'hoursToAdmin',
      ])
    let newDehydrationResult = null
    if (
      !isNull(dehydration) &&
      !!hoursToAdmin && // divisor can't be zero
      !isNull(patientWeight)
    ) {
      const normedWeight = getNormedWeight(
        patientWeight,
        patientWeightUnit,
        WeightUnit.KG,
      )
      newDehydrationResult = round(
        (normedWeight * dehydration * 10) / hoursToAdmin,
      )
    }
    setValue('dehydrationResult', newDehydrationResult)
    calculateTotalResults()
  }

  const clearResultsFields = () => {
    setValue('physiologicReq', null)
    setValue('ongoingLosses', null)
  }

  const calculateTotalResults = () => {
    // change fluid rate will change totalResults, do not overwrite when first edit
    if (isFirstRenderAndEdit.current && getValues('totalResults')) {
      return
    }

    const [
      physiologicReq,
      ongoingLosses,
      dehydrationResult,
      totalResults,
      totalUnit,
    ] = getValues([
      'physiologicReq',
      'ongoingLosses',
      'dehydrationResult',
      'totalResults',
      'totalUnit',
    ])

    let unitVolume = 1
    if (totalUnit === FluidMedication.L) {
      unitVolume = 0.001
    }

    if (!isNull(totalResults) && isNull(ongoingLosses)) {
      setValue('totalResults', totalResults, { shouldValidate: true })
      return
    }
    let initialTotalResult = null
    if (!isNull(physiologicReq) && !isNull(ongoingLosses)) {
      initialTotalResult = round(
        (physiologicReq + (dehydrationResult ?? 0) + (ongoingLosses ?? 0)) *
          unitVolume,
      )
      if (flags.setting_default_day_fluid_rate.enabled) {
        initialTotalResult = round(initialTotalResult / 24)
      }
    }
    setValue('totalResults', initialTotalResult, { shouldValidate: true })
    calculateDripRate()
  }

  const calculateDripRate = () => {
    const [IVSet, totalResults] = getValues(['IVSet', 'totalResults'])
    let dripRate = null
    if (!isNull(IVSet) && !isNull(totalResults)) {
      dripRate = Math.round((IVSet * totalResults) / 60)
    }
    setValue('dripRate', dripRate)
  }
  const presetKeyValuePair = useRef<{ [optionValue: string]: string }>({})

  const presetOptions = useMemo(
    () =>
      product.custom_values?.map(customValue => {
        const optionValue = `${customValue.key}  ${customValue.value}mL/kg/hr`
        if (customValue.value) {
          presetKeyValuePair.current[optionValue] = customValue.value
        }
        return {
          value: optionValue,
        }
      }) ?? [],
    [product.custom_values],
  )

  let physiologicReqLabel = `${t('addTreatment:physiologic')}`
  if (presetRateText) {
    physiologicReqLabel = `${physiologicReqLabel} (${presetRateText})`
  }

  return (
    <>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        extraHeight={-64} // set to tabBarBottom height const
        keyboardOpeningTime={0}
      >
        {presetOptions.length > 0 && (
          <ControlledSelect
            control={control}
            name="presetRateText"
            label={'Preset rate'}
            dialog={false}
            options={presetOptions}
            allowClear={true}
            onChangeListener={value => {
              let physiologicReq = null
              const [patientWeight, patientWeightUnit] = getValues([
                'patientWeight',
                'patientWeightUnit',
              ])
              const presetValue = presetKeyValuePair.current[value]
              if (!isNull(patientWeight) && !isNil(presetValue)) {
                const normedWeight = getNormedWeight(
                  patientWeight,
                  patientWeightUnit,
                  WeightUnit.KG,
                )
                physiologicReq = parseFloat(presetValue) * normedWeight
              }
              setValue('physiologicReq', physiologicReq)
              setValue('presetRateText', value)
              calculateTotalResults()
            }}
          />
        )}
        <FormLabel text={t('addTreatment:patientInfo')} />
        <PatientWeight
          control={control}
          patientWeightUpdatedAt={patientWeightUpdatedAt}
          handleChange={() => {
            calculateShockTotalResult()
            calculatePhysiologicReqResult()
          }}
          disabled
        />
        {isDehydrated ? (
          <>
            <InputGroup>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericInput
                    label={t('addTreatment:dehydration')}
                    style={styles.flex}
                    onChange={newValue => {
                      onChange(newValue)
                      calculateDehydrationResult()
                    }}
                    value={value}
                  />
                )}
                name="dehydration"
              />
              <InputGroupText text="%" />
            </InputGroup>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumericInput
                  label={t('addTreatment:hrsToAdmin')}
                  style={styles.flex}
                  onChange={newValue => {
                    onChange(newValue)
                    calculateDehydrationResult()
                  }}
                  value={value}
                />
              )}
              name="hoursToAdmin"
            />
          </>
        ) : null}
        <FormLabel text={t('addTreatment:result')} />
        <InputGroup>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericInput
                label={physiologicReqLabel}
                style={styles.flex}
                value={value}
                onChange={newVal => {
                  onChange(newVal)
                  setValue('presetRateText', null)
                  calculateTotalResults()
                }}
              />
            )}
            name="physiologicReq"
          />
          <InputGroupText text="ml" />
          <InputGroupText
            text={flags.setting_default_day_fluid_rate.enabled ? '/day' : '/hr'}
          />
        </InputGroup>
        {isDehydrated ? (
          <InputGroup>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <NumericInput
                  label={t('addTreatment:dehydration')}
                  style={styles.flex}
                  value={value}
                  disabled={true}
                />
              )}
              name="dehydrationResult"
            />
            <InputGroupText text="ml" />
            <InputGroupText text="/hr" />
          </InputGroup>
        ) : null}
        <InputGroup>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericInput
                label={t('addTreatment:ongoingLosses')}
                style={styles.flex}
                value={value}
                onChange={newVal => {
                  onChange(newVal)
                  calculateTotalResults()
                }}
              />
            )}
            name="ongoingLosses"
          />
          <InputGroupText text="ml" />
          <InputGroupText text="/hr" />
        </InputGroup>
        <FormBreak />
        <Status status={createErrorStatus(errors.totalResults?.message, true)}>
          <InputGroup>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumericInput
                  label={t('addTreatment:resultsTotal')}
                  style={styles.bigFlex}
                  value={value}
                  onChange={newVal => {
                    onChange(newVal)
                    setValue('presetRateText', null)
                    clearResultsFields()
                    trigger('totalResults')
                  }}
                  required={true}
                />
              )}
              name="totalResults"
              rules={{
                required: true,
              }}
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  dialog={false}
                  testID="totalValueUnit"
                  selected={value}
                  options={fluidUnitOptions}
                  onChange={newValue => {
                    onChange(newValue)
                  }}
                />
              )}
              name="totalUnit"
            />
            <InputGroupText text="/hr" />
          </InputGroup>
        </Status>
        <FormBreak />
        {isHypovolemic ? (
          <InputGroup>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumericInput
                  label={t('addTreatment:shockTotal')}
                  style={styles.flex}
                  value={value}
                  onChange={onChange}
                />
              )}
              name="shockTotalResult"
            />
            <InputGroupText text="ml" />
            <InputGroupText text="/15 mins" />
          </InputGroup>
        ) : null}

        <DosageFormDurationInfo
          control={control}
          watchedIsContinuous={watchedIsContinuous}
        />

        {!isNewSheet && !isEdit && (
          <ScheduleTask
            control={control}
            isRepeating={RepeatScheduleValue.SINGLE}
            isEdit={isEdit}
            isFluidDosage={true}
            trigger={trigger}
          />
        )}

        <Instructions control={control} />
      </KeyboardAwareScrollView>
      <View
        style={
          styleForWebOriOS ? styles.footer : styles.footerForCreatingSheetForIOS
        }
      >
        <SaveTreatmentButton
          loading={submitting}
          onPress={handleSubmit(handleOnSave)}
          disabled={hasErrors}
          isEdit={isEdit}
          approvalStatus={approvalStatus}
          submitTitle={submitTitle}
        />
        {deleteTreatment ? (
          <ConditionalTreatmentDeleteBtn deleteTreatment={deleteTreatment} />
        ) : null}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  bigFlex: {
    flex: 9,
    marginRight: 'auto',
  },
  footer: {
    paddingVertical: 15,
  },
  footerForCreatingSheetForIOS: {
    marginBottom: 35,
    paddingVertical: 15,
  },
})
