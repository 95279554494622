import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  color: string
  size?: number
}

export const SvgTrash: React.FunctionComponent<Props> = ({
  color,
  size = 20,
}) => (
  <Svg width={size} height={size} viewBox="0 0 20 20">
    <Path
      d="M13.6012 1.25H18.5119C18.8734 1.25 19.1667 1.53 19.1667 1.875V3.12499C19.1667 3.46999 18.8734 3.74999 18.5119 3.74999H1.48814C1.12671 3.74999 0.833374 3.46999 0.833374 3.12499V1.875C0.833374 1.53 1.12671 1.25 1.48814 1.25H6.39885L6.78354 0.519522C6.93044 0.232734 7.32109 0 7.65554 0H12.3367L12.4654 0.0105604C12.7677 0.0590281 13.0856 0.268583 13.2165 0.519522L13.6012 1.25ZM2.14288 5.00003L3.01045 18.2422C3.07407 19.2124 3.95227 20 4.97062 20H10H15.0294C16.0478 20 16.926 19.2124 16.9896 18.2422L17.8572 5.00003H2.14288Z"
      fill={color}
      fillRule="evenodd"
    />
  </Svg>
)
