import { useMemo } from 'react'
import { pinSwitchService, SwitchableUser } from 'src/utils/pinSwitchService'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import { useUser } from 'src/context/user'

export const useSwitchableUsers = (): SwitchableUser[] => {
  const { organisation } = useOrganisationHook()
  const { user: currentUser } = useUser()

  return useMemo(
    () =>
      Object.keys(pinSwitchService.users)
        .filter(
          email =>
            pinSwitchService.users[email].organisationId === organisation?.id,
        )
        .map(email => ({
          email,
          username: pinSwitchService.users[email].username as string,
        })),
    // Add currentUser?.id as dependency here to refetch users on switch to another user
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisation?.id, currentUser?.id, pinSwitchService.users],
  )
}
