import React, {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { View, StyleSheet } from 'react-native'
import { Layout } from '../components/Layout'
import { useNavigation } from '@react-navigation/native'
import { Auth, AUTH_STATE, useAuth, userSessionService } from 'src/context/auth'
import { useTranslation } from 'react-i18next'
import { PinInput } from '../components/PinInput'
import { ErrorMessage } from '../components/ErrorMessage'
import { AuthHeader } from '../components/Header'
import { ErrorCode, SkipAuthType } from 'src/context/auth/utils/type'
import { AuthPrimaryButton, AuthSecondaryButton } from '../components/Buttons'

const VERIFICATION_CODE_LENGTH = 6

export const VerifyEmail: React.FC<PropsWithChildren> = () => {
  const navigation = useNavigation()
  const { toggleAuthState } = useAuth()
  const session = userSessionService.getUserSession()
  const { t } = useTranslation()
  const [isCodeInValid, setIsCodeInValid] = useState(false)
  const [isCodeSent, setIsCodeSent] = useState(false)
  const [resendCounter, setResendCounter] = useState(60)

  const handleOnChange = async (value: string) => {
    setIsCodeInValid(false) // reset error message when inputting new code
    const isValid = value.length === VERIFICATION_CODE_LENGTH
    if (!isValid) return
    const result = await Auth.verifyEmailVerificationCode(value)
    if (result?.errorCode) {
      if (result.errorCode !== ErrorCode.USER_EMAIL_ALREADY_VERIFIED) {
        return setIsCodeInValid(true)
      }
    }
    const newAuthState = toggleAuthState(AUTH_STATE.VERIFY_EMAIL, true)
    return navigation.navigate(newAuthState.toLowerCase())
  }

  useLayoutEffect(() => {
    const isEmailVerified = !!session?.userAttributes?.email_verified
    const isEmailSkipped =
      new Date() <
      new Date(session?.userAttributes?.skip_verifyemail_exp || Date.now())
    if (isEmailVerified || isEmailSkipped) {
      const newAuthState = toggleAuthState(AUTH_STATE.VERIFY_EMAIL, true)
      if (newAuthState === AUTH_STATE.AUTHENTICATED) return
      return navigation.navigate(newAuthState.toLowerCase())
    }
  })

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isCodeSent) {
      timer = setInterval(() => {
        setResendCounter(prevCounter => {
          if (prevCounter === 1) {
            // When countdown finishes, reset resendCounter and enable resend button
            setIsCodeSent(false)
            clearInterval(timer)
            return 60
          }
          return prevCounter - 1
        })
      }, 1000)
    }

    return () => clearInterval(timer)
  }, [isCodeSent])

  const handleSkip = async () => {
    const result = await Auth.skipAuth(SkipAuthType.VERIFY_EMAIL)
    if (result?.errorCode) return
    const newAuthState = toggleAuthState(AUTH_STATE.VERIFY_EMAIL, true)
    return navigation.navigate(newAuthState.toLowerCase())
  }

  const handleSendCode = async () => {
    const result = await Auth.sendEmailVerification()
    if (result?.errorCode) return
    setIsCodeSent(true)
    setResendCounter(60)
  }

  return (
    <Layout>
      <View style={styles.headingContainer}>
        <AuthHeader
          title={t('login:label.verifyCode')}
          subtitle={t('login:label.emailVerificationTip', {
            email: session?.userAttributes?.email || '',
          })}
        />
      </View>
      <View style={styles.pinContainer}>
        <PinInput size={6} onChange={handleOnChange} />
        {isCodeInValid ? (
          <ErrorMessage message={t('login:pin.error.invalidVerification')} />
        ) : null}
      </View>

      <View style={styles.optionsContainer}>
        <AuthPrimaryButton
          onPress={handleSendCode}
          title={
            !isCodeSent
              ? t('login:label.sendCode')
              : t('login:label.resendCode', {
                  seconds: `${resendCounter} second${
                    resendCounter > 1 ? 's' : ''
                  }`,
                })
          }
          ariaLabel="SignInBtn"
          disabled={isCodeSent}
        />
        <AuthSecondaryButton
          onPress={handleSkip}
          title={t('login:label.skip24Hours')}
          ariaLabel={'SkipBtn'}
        />
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  headingContainer: { marginBottom: 48 },
  pinContainer: {
    height: 160,
    gap: 8,
    marginBottom: 28,
  },
  optionsContainer: { gap: 16 },
})
