import React from 'react'
import { Colors } from 'constants/Colors'
import { StyleSheet, View } from 'react-native'
import { ScaleTimeFn } from './GridTimeline'
import { TimesWithIsStaffedHour } from './utils/useGetStaffedHourTimes'

type StrongBorderProps = {
  timeBoxWidth: number
  height?: number
  position?: number
}

type GridTimeBoxBgLineProps = {
  isStaffedHour?: boolean
} & StrongBorderProps

export type TimeBoxProps = {
  date: Date
  timeFormat?: string
} & GridTimeBoxBgLineProps

type Props = {
  timesWithIsStaffedHour: TimesWithIsStaffedHour
  scrollHeight?: number
  positionInGridFn: ScaleTimeFn
  timeBoxWidth: number
}

export const GridBackgroundLines: React.FC<Props> = ({
  timesWithIsStaffedHour,
  scrollHeight,
  positionInGridFn,
  timeBoxWidth,
}) => (
  <>
    {timesWithIsStaffedHour.map(({ time, isStaffedHour }, i) => {
      const isStartOfDay = time.getHours() === 0 && time.getMinutes() === 0
      return (
        <React.Fragment key={time.toUTCString()}>
          {isStartOfDay && i !== 0 ? (
            <StrongBorder
              key={`gridBorder_${time.toUTCString()}`}
              height={scrollHeight}
              position={positionInGridFn(time)}
              timeBoxWidth={timeBoxWidth}
            />
          ) : null}
          <GridTimeBoxBgLine
            isStaffedHour={isStaffedHour}
            height={scrollHeight}
            key={`gridLine_${time.toUTCString()}`}
            position={positionInGridFn(time)}
            timeBoxWidth={timeBoxWidth}
          />
        </React.Fragment>
      )
    })}
  </>
)

const StrongBorder: React.FC<StrongBorderProps> = ({
  height = 0,
  position,
  timeBoxWidth,
}) => (
  <View
    pointerEvents="none"
    style={[
      styles.baseStyle,
      styles.strongBorder,
      {
        height,
        left: position,
        width: timeBoxWidth,
      },
    ]}
  />
)

const GridTimeBoxBgLine = ({
  height = 0,
  position,
  timeBoxWidth,
  isStaffedHour = true,
}: GridTimeBoxBgLineProps) => {
  return (
    <View
      pointerEvents="none"
      style={[
        styles.baseStyle,
        styles.dateBox,
        {
          height,
          left: position,
          width: timeBoxWidth,
          backgroundColor: isStaffedHour
            ? 'transparent'
            : Colors.grid.lightPurple,
        },
      ]}
    />
  )
}

export const styles = StyleSheet.create({
  baseStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
  },
  dateBox: {
    opacity: 1,
    borderColor: Colors.borderGrey,
    borderLeftWidth: 1,
  },
  strongBorder: {
    borderColor: Colors.lightGrey2,
    borderLeftWidth: 3,
    opacity: 0.5,
    zIndex: 2,
  },
})
