import React, { useCallback } from 'react'
import { toast, treatmentHeight, treatmentHeightLarge } from 'components/common'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { ticks } from 'd3-array'
import { startOfMinute } from 'date-fns'
import {
  GestureResponderEvent,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import Svg, { G } from 'react-native-svg'
import {
  AnesthesiaActionTypes,
  useAnesthesiaContext,
} from 'src/context/anesthesia'
import {
  AnesthesiaChartPressureTitles,
  AnesthesiaChartSymbolShape,
  AnesthesiaChartTitles,
} from 'src/context/types/anesthesia'
import { useBreakpoint } from 'src/hocs/breakpoint'

import { ChartSVGIconPicker } from './ChartSVGIconPicker'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { getAnesthesiaChartRangeAndIncrement } from 'components/Anesthesia/AnesthesiaChart/utils/getAnesthesiaChartRangeAndIncrement'

type FullTitle = AnesthesiaChartTitles | AnesthesiaChartPressureTitles
type ScrollBarRowProps = {
  fullTitle: FullTitle
  lastRow?: boolean
  latestValue?: number
  onPressSymbol?: ((fullTitle: FullTitle) => void) | undefined
  onPressValue?: ((event: GestureResponderEvent) => void) | undefined
  selected?: boolean
  symbolShape: AnesthesiaChartSymbolShape
  title: string
}

const ScrollbarRow: React.FC<ScrollBarRowProps> = React.memo(
  ({
    latestValue,
    lastRow,
    fullTitle,
    onPressSymbol,
    onPressValue,
    selected = false,
    symbolShape,
    title,
  }) => {
    const { isLargeScreen, isMediumScreen, isSmallScreen, isExSmallScreen } =
      useBreakpoint()

    const isLargeWebScreen =
      Platform.OS === 'web' && (isLargeScreen || isMediumScreen)

    const isSmallOrExSmallScreen = isSmallScreen || isExSmallScreen

    const onPressSymbolHandler = useCallback(() => {
      if (onPressSymbol) onPressSymbol(fullTitle)
    }, [fullTitle, onPressSymbol])

    return (
      <View
        accessibilityLabel={`Anesthesia Label Scrollbar Row: ${symbolShape}`}
        style={[
          styles.container,
          isLargeWebScreen && styles.containerWidthLargeWeb,
          (isSmallScreen || isExSmallScreen) && styles.containerWidthSmall,
          lastRow && styles.lastRow,
        ]}
      >
        <TouchableOpacity
          accessibilityLabel={`Anesthesia Chart Value Enter: ${symbolShape}`}
          onPress={onPressValue}
          style={[
            styles.touchableChartEntry,
            isSmallOrExSmallScreen && styles.touchableChartEntrySmall,
          ]}
        >
          <View style={styles.vitalsTextContainer}>
            <Text style={styles.vitalsTitle} testID={`Anaesthesia_${title}`}>
              {title}
            </Text>
          </View>
          <View style={styles.vitalsTextContainer}>
            <Text style={styles.vitalsValue}>{latestValue}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          accessibilityLabel={`Anesthesia Chart Text Toggle: ${symbolShape}`}
          onPress={onPressSymbolHandler}
          style={styles.touchableIcon}
        >
          <Svg width={24} height={24}>
            <G transform="scale(2) translate(6 6)">
              <ChartSVGIconPicker
                symbolShape={symbolShape}
                selected={selected}
              />
            </G>
          </Svg>
        </TouchableOpacity>
      </View>
    )
  },
)

ScrollbarRow.displayName = 'ScrollbarRow'

export const Scrollbar: React.FC<{
  onPressRow?: (update: { path: string[] }) => void
  isFinalized?: boolean
  timeInChart?: { timeInChart: (() => void) | ((time: Date) => void) }
}> = ({ onPressRow, isFinalized, timeInChart }) => {
  const [{ anesthesiaData, anesthesiaChartRange }, anesthesiaDispatch] =
    useAnesthesiaContext()

  const { settingsMap } = useOrgSettings()
  const { increment: chartValuesIncrement } =
    getAnesthesiaChartRangeAndIncrement(
      +settingsMap.ANAESTHESIA_CHART_MAX_DEFAULT.value,
    )

  const [Y_AXIS_MIN, Y_AXIS_MAX] = anesthesiaChartRange
  const Y_AXIS_NUMBER_OF_TICKS = 18 // skip beginning and end

  const yAxisTicks = ticks(
    Y_AXIS_MIN + chartValuesIncrement,
    Y_AXIS_MAX - chartValuesIncrement,
    Y_AXIS_NUMBER_OF_TICKS,
  ).reverse()

  const toggleDrawer = useCallback(() => {
    timeInChart?.timeInChart(startOfMinute(new Date()))
  }, [timeInChart])

  const onScrollBarRowPress = useCallback(
    (fullTitle: string) => {
      const update = {
        path: ['anesthesiaData', fullTitle],
      }

      anesthesiaDispatch({
        type: AnesthesiaActionTypes.toggleAnesthesiaShowText,
        name: fullTitle,
      })
      if (onPressRow) {
        onPressRow(update)
      }
    },
    [anesthesiaDispatch, onPressRow],
  )

  const onScrollBarValuePress = useCallback(() => {
    if (isFinalized) {
      toast.error('The sheet has been finalized')
      return
    }
    toggleDrawer()
  }, [isFinalized, toggleDrawer])

  if (!anesthesiaData || anesthesiaData.length === 0) return null
  return (
    <>
      <View
        accessibilityLabel="Anesthesia Chart Sidebar Controls"
        style={styles.scrollbarContainer}
      >
        <View>
          {anesthesiaData.map(
            (
              {
                title,
                full_title: fullTitle,
                latest_value: latestValue,
                show_text: showText,
                symbol_shape: symbolShape,
              },
              i,
            ) => (
              <ScrollbarRow
                key={title}
                latestValue={latestValue}
                lastRow={i === anesthesiaData.length - 1}
                onPressSymbol={onScrollBarRowPress}
                onPressValue={onScrollBarValuePress}
                selected={showText}
                symbolShape={symbolShape}
                fullTitle={fullTitle}
                title={title}
              />
            ),
          )}
        </View>
        <View style={styles.yAxisContainer}>
          {yAxisTicks.map(tickText => (
            <Text key={tickText} selectable={false} style={styles.axisText}>
              {tickText}
            </Text>
          ))}
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  axisText: {
    color: 'rgba(111, 111, 111, 0.7)',
    fontFamily: Fonts.regular,
    fontSize: 11,
  },
  container: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: Colors.borderGrey,
    flexDirection: 'row',
    height: treatmentHeightLarge,
    justifyContent: 'space-between',
    minWidth: 110,
    paddingLeft: 8,
  },
  containerWidthSmall: {
    minWidth: 80,
  },
  containerWidthLargeWeb: {
    minWidth: 160,
  },
  lastRow: {
    borderBottomWidth: 0,
  },
  scrollbarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  touchableChartEntry: {
    minWidth: '68%',
  },
  touchableChartEntrySmall: {
    minWidth: 70,
  },
  touchableIcon: {
    width: 24,
    height: 24,
  },
  vitalsTextContainer: {
    height: 20,
    paddingLeft: 8,
  },
  vitalsTitle: {
    fontFamily: Fonts.regular,
    fontSize: 13,
    color: 'rgb(111, 111, 111)',
  },
  vitalsValue: {
    fontSize: 16,
  },
  yAxisContainer: {
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
    paddingRight: 4,
    // Padding keeps the evenly spaced text items aligned
    paddingTop: treatmentHeight * 0.1,
    paddingBottom: treatmentHeight * 0.15,
  },
})
