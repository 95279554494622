import { Colors } from 'constants/Colors'
import { StyleSheet, ViewStyle, TextStyle } from 'react-native'

export enum InputStatusType {
  Error = 'Error',
  Warning = 'Warning',
  Pending = 'Condition Pending',
}

export type InputStatus = {
  type: InputStatusType
  message?: string
}

export const conditionPendingMessage =
  'Please select to finish the setup of the treatment'

export const getStatusStyle = (
  inputStatus?: InputStatus | null,
): ViewStyle | null => {
  if (!inputStatus) return null
  const { type } = inputStatus
  if (type === 'Error') return styles.withError
  if (type === 'Warning' || type === 'Condition Pending') {
    return styles.withWarning
  }
  return null
}

export const getStatusTextStyle = (
  inputStatus?: InputStatus | null,
): TextStyle | null => {
  if (!inputStatus) return null
  const { type } = inputStatus
  if (type === 'Error') return styles.textWithError
  if (type === 'Warning' || type === 'Condition Pending') {
    return styles.textWithWarning
  }
  return null
}

export const getStatusMessage = (inputStatus?: InputStatus | null) => {
  if (!inputStatus) return ''
  const { type, message = '' } = inputStatus
  if (type === 'Condition Pending') return conditionPendingMessage
  return message
}

const styles = StyleSheet.create({
  textWithError: {
    color: Colors.error,
  },
  textWithWarning: {
    color: Colors.blue,
  },
  withError: {
    backgroundColor: Colors.error,
  },
  withWarning: {
    backgroundColor: Colors.warning,
  },
})
