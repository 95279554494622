import { useQuery } from '@apollo/client'
import { GET_CUSTOM_PRODUCTS } from 'components/Settings/graphql'

import React, { useEffect, useMemo, useState } from 'react'
import {
  ActivityIndicator,
  StyleSheet,
  View,
  Text,
  FlatList,
  TouchableOpacity,
} from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import {
  getCustomProducts,
  getCustomProductsVariables,
  getCustomProducts_getOrganisation_products_items_products as CustomProductsItems,
} from 'src/types/getCustomProducts'
import { SortDirection } from 'types/globalTypes'

import { toast, Button, TextLink } from 'components/common'
import { DraggableList } from 'components/common/DraggableList'

import { SearchInput } from 'components/common/SearchInput'
import { useTranslation } from 'react-i18next'
import { Colors, Typography } from 'src/design-system/theme'
import { searchInList } from 'src/utils/searchInList'
import { SvgHamburger } from 'components/Icons'
import { SelectedProductRow } from 'components/TreatmentTemplate/ProductPicker/SelectedProductRow'
import { HospitalTaskRow } from './HospitalTaskRow'

const SELECTED_SHOW_MORE_LIMIT = 3

export interface HospitalTaskProduct {
  id: string
  name: string
  products: CustomProductsItems | null
  type: string | null
}

export const HospitalTaskPickProductScreen = () => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const [searchText, setSearchText] = useState('')
  const [selectedTasksIds, setSelectedTasksIds] = useState([] as string[])
  const [showMore, setShowMore] = useState(false)

  const hospitalTasksMap: { [key: string]: HospitalTaskProduct } =
    useMemo(() => {
      const tasks: { [key: string]: HospitalTaskProduct } = {}
      return tasks
    }, [])

  const queryResult = useQuery<getCustomProducts, getCustomProductsVariables>(
    GET_CUSTOM_PRODUCTS,
    {
      variables: {
        id: organisationId,
        productPageInput: {
          sortField: ['name'],
          sortDirection: [SortDirection.asc],
        },
      },
      onError: err => {
        toast.error(err.message)
      },
    },
  )
  const { loading, data } = queryResult

  useEffect(() => {
    if (!loading && data?.getOrganisation?.products?.items) {
      data.getOrganisation.products.items.forEach(item => {
        hospitalTasksMap[item.id] = item
      })
    }
  }, [loading, data, hospitalTasksMap])

  const products = useMemo(
    () =>
      searchInList(
        data?.getOrganisation?.products.items ?? [],
        'name',
        searchText,
      ),
    [data?.getOrganisation?.products.items, searchText],
  )

  const hasNoSearchResult = !!searchText && products.length === 0 && !loading

  const onChange = (text: string) => {
    setSearchText(text)
    searchInList(products, 'name', searchText)
  }

  const renderListHeader = () => (
    <Text style={styles.selectedTitle}>
      {t('template:treatment.selectedTreatment')}
    </Text>
  )

  const addTreatmentOption = (option: HospitalTaskProduct) => {
    setSelectedTasksIds([...selectedTasksIds, option.id])
  }

  const removeTreatmentOption = (taskId: string) => {
    setSelectedTasksIds(selectedTasksIds.filter(id => taskId !== id))
  }

  // TODO - define item type?
  const renderHospitalTask = (item: any) => {
    return item.map((product: HospitalTaskProduct) => (
      <HospitalTaskRow
        key={product.id}
        product={product}
        a11yLabel={`Select Product ${product.name}`}
        searchWords={searchText.split(/\s+/)}
        addProduct={addTreatmentOption}
        removeProduct={() => removeTreatmentOption(product.id)}
        isAdded={selectedTasksIds.includes(product.id)}
      />
    ))
  }

  const renderShowMoreView = () => {
    return (
      <>
        {renderListHeader()}
        <DraggableList
          ListFooterComponent={renderShowMoreViewListFooter()}
          items={selectedTasksIds.map(id => hospitalTasksMap[id])}
          renderItem={({ item, drag }) => (
            <View style={styles.dragItemContainer}>
              <TouchableOpacity style={styles.dragBtn} onPressIn={drag}>
                <SvgHamburger />
              </TouchableOpacity>
              <View style={{ flex: 1 }}>
                <SelectedProductRow
                  product={item}
                  key={item.id}
                  removeTreatmentOption={removeTreatmentOption}
                />
              </View>
            </View>
          )}
          onMoveEnd={currentList => {
            const updatedList: string[] = []
            currentList.map(item => {
              updatedList.push(item.id)
            })
            setSelectedTasksIds(updatedList)
          }}
        />
      </>
    )
  }

  const renderShowMoreViewListFooter = () => {
    return (
      <>
        <TextLink
          text={`${t('general.showLess')} / Add treatment`}
          onPress={() => setShowMore(!showMore)}
          containerStyles={styles.textLink}
        />
        <Button
          style={styles.doneButton}
          title={t('general.saveChanges')}
          color={Colors.Buttons.positive}
          testID={'ProductPickDoneBtn'}
          onPress={() => {}} // TODO - Add save changes functionality once details component is added
        />
      </>
    )
  }

  const renderShowLessView = () => {
    const extraTreatments = selectedTasksIds.length - SELECTED_SHOW_MORE_LIMIT
    const showMoreText = `${t(
      'general.showMore',
    )} (${extraTreatments}) / Reorder`

    return (
      <View style={styles.container}>
        {!!selectedTasksIds.length ? (
          <>
            {renderListHeader()}
            {selectedTasksIds.slice(0, SELECTED_SHOW_MORE_LIMIT).map(item => (
              <SelectedProductRow
                product={hospitalTasksMap[item]}
                key={item}
                removeTreatmentOption={removeTreatmentOption}
              />
            ))}
            {extraTreatments > 0 && (
              <TextLink
                text={showMoreText}
                onPress={() => setShowMore(!showMore)}
                containerStyles={styles.textLink}
              />
            )}
            <View
              testID="SelectedProductRowSeperator"
              style={styles.separator}
            />
          </>
        ) : null}

        <SearchInput
          testID="SearchBarInput"
          style={styles.searchInput}
          onChangeText={onChange}
          wait={500}
        />

        {loading ? (
          <ActivityIndicator style={styles.spinner} size="large" />
        ) : hasNoSearchResult ? (
          <Text style={styles.noResultText}>
            {t('createTreatment.notFoundNoFilter')}
          </Text>
        ) : (
          !!products.length && (
            <FlatList
              data={[products]}
              renderItem={customProduct =>
                renderHospitalTask(customProduct.item)
              }
            />
          )
        )}

        {!loading ? (
          <Button
            style={styles.doneButton}
            title={t('general.saveChanges')}
            color={Colors.Buttons.positive}
            testID={'ProductPickDoneBtn'}
            onPress={() => {}} // TODO - Add save changes functionality once details component is added
            loading={loading}
          />
        ) : null}
      </View>
    )
  }

  return showMore ? renderShowMoreView() : renderShowLessView()
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  noResultText: {
    ...Typography.Paragraph.L,
    color: Colors.Contents.secondary,
    textAlign: 'center',
    marginHorizontal: 60,
    marginVertical: 20,
  },
  spinner: {
    marginTop: 25,
  },
  doneButton: {
    marginVertical: 15,
  },
  searchInput: {
    marginBottom: 16,
    paddingHorizontal: 16,
  },
  selectedTitle: {
    ...Typography.Label.L,
    fontSize: Typography.FontSizes.lg,
    paddingLeft: 16,
    paddingBottom: 8,
  },
  separator: {
    backgroundColor: Colors.Borders.primary,
    height: 1,
    marginVertical: 20,
  },
  textLink: {
    alignItems: 'flex-end',
    paddingHorizontal: 16,
    paddingTop: 5,
  },
  dragItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dragBtn: {
    paddingHorizontal: 12,
  },
})
