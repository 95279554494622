import { Status } from 'src/types/globalTypes'

/**
 * Contains all completed task statuses
 */
export const COMPLETED_STATUS = [
  Status.DONE,
  Status.MISSED_ON_PURPOSE,
  Status.IN_PROGRESS,
]

/**
 * Contains all upcoming task statuses
 */
export const UPCOMING_STATUSES = [Status.PENDING, ...COMPLETED_STATUS]

/**
 * Contains due/missed task statuses
 */
export const DUE_MISSED_STATUSES = [Status.DUE, Status.MISSED]
