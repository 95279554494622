import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size: number
}

export const SvgFlag: React.FC<Props> = ({ size = 24 }) => (
  <Svg
    height={size}
    width={size}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <Path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6h-5.6z" fill="#EA850B" />
  </Svg>
)
