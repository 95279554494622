import { Colors } from 'constants/Colors'

export const Shadows = {
  cardShadow: {
    shadowColor: Colors.contentPrimary,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.39,
    shadowRadius: 8.3,
  },
  scrollButtonShadow: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 4,
    shadowColor: Colors.contentPrimary,
  },
  elevation: 13,
}
