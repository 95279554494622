import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { FormBreak, SecondaryButton, toast } from 'components/common'
import { SettingsStackParamList } from 'components/Settings/screens'
import { SuperUserEmails } from 'constants/constants'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { SCROLL_VIEW_FIRST_INDEX_STICKY } from 'constants/Layout'
import { uniqBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, FlatList, StyleSheet, View } from 'react-native'
import { useFlags } from 'react-native-flagsmith/react'
import { Center } from 'src/components/common/Center'
import { SearchInput } from 'src/components/common/SearchInput'
import { Colors } from 'src/constants/Colors'
import { Routes } from 'src/constants/Routes'
import { useOrganisation } from 'src/context/organisation'
import { useUser } from 'src/context/user'
import { useBreakpoint } from 'src/hocs/breakpoint'
import {
  getUsersList,
  getUsersList_getUsersList_items as UserInList,
  getUsersListVariables,
} from 'src/types/getUsersList'
import { searchInList } from 'src/utils/searchInList'
import { sortList } from 'src/utils/sortList'

import { SubHeader } from '../SubHeader/SubHeader'
import { GET_USERS_LIST } from './graphql'
import { UserItem } from './UserItem'

type Props = StackScreenProps<SettingsStackParamList>

const userListKeyExtractor = (item: UserInList) => item.id

export const UserListScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { isSmallishScreen } = useBreakpoint()

  const [searchText, setSearchText] = useState('')

  const { navigate } = navigation

  const flags = useFlags([FeatureFlagNames.VRAdminControl])

  const queryResult = useQuery<getUsersList, getUsersListVariables>(
    GET_USERS_LIST,
    {
      fetchPolicy: 'cache-and-network', // see comment on GET_USERS_LIST
      variables: {
        organisation_id: organisationId,
      },
      onError: err => {
        toast.error(err.message)
      },
    },
  )

  const { user } = useUser()
  const { loading, data } = queryResult
  const users = useMemo(() => {
    const sortedList = sortList(data?.getUsersList.items ?? [], 'full_name')
    const uniqList = uniqBy(sortedList, 'id')

    if (user && SuperUserEmails.includes(user.email)) {
      return searchInList(uniqList, 'full_name', searchText)
    }

    const filteredUser = uniqList.filter(
      user => !user.full_name.includes('(ezyVet)'),
    )

    return searchInList(filteredUser, 'full_name', searchText)
  }, [data?.getUsersList.items, searchText, user])

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: () => navigate(Routes.Settings),
  }

  const renderListFooter = useCallback(() => {
    if (!loading) return null
    return (
      <ActivityIndicator
        accessibilityLabel="User List Loading Indicator"
        size="large"
        style={styles.activityIndicator}
      />
    )
  }, [loading])

  const renderListHeader = useCallback(
    () => (
      <Center
        style={[
          styles.searchContainer,
          isSmallishScreen && styles.smallContainer,
        ]}
      >
        <SearchInput onChangeText={setSearchText} />
        {flags.vet_radar_admin_controls.enabled ? (
          <SecondaryButton
            onPress={() => navigate(Routes.AddUser)}
            title={t('settings:users.createUser')}
            style={styles.addButton}
            textStyle={styles.addButtonText}
          />
        ) : null}
      </Center>
    ),
    [flags.vet_radar_admin_controls.enabled, isSmallishScreen, navigate, t],
  )

  const renderItem = useCallback(
    ({ item }: { item: UserInList }) => {
      return (
        <UserItem
          name={item.full_name}
          role={item.role}
          onPress={() => navigate(Routes.EditUser, { userId: item.id })}
        />
      )
    },
    [navigate],
  )

  return (
    <>
      <SubHeader headlineKey="settings:users.title" backButton={backButton} />
      <View
        accessibilityLabel="User List Screen"
        style={styles.userList}
        testID="UserList"
      >
        <FlatList
          contentContainerStyle={styles.listContainerStyle}
          data={users}
          ItemSeparatorComponent={FormBreak}
          keyExtractor={userListKeyExtractor}
          ListFooterComponent={renderListFooter}
          ListHeaderComponent={renderListHeader}
          renderItem={renderItem}
          stickyHeaderIndices={SCROLL_VIEW_FIRST_INDEX_STICKY}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    marginTop: 25,
  },
  userList: {
    flex: 1,
    width: '100%',
  },
  listContainerStyle: {
    paddingBottom: 16,
  },
  searchContainer: {
    backgroundColor: Colors.backgroundGrey,
    paddingVertical: 16,
  },
  addButton: {
    paddingTop: 10,
    alignSelf: 'flex-end',
    marginRight: 10,
  },
  addButtonText: {
    color: Colors.buttons.blue,
  },
  smallContainer: {
    paddingHorizontal: 10,
  },
})
