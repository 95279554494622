import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  color: string
  width?: number
  height?: number
}

export const EyeOpen: React.FC<Props> = React.memo(
  ({ color, width = 24, height = 24 }) => (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill={color}>
      <Path
        d="M12 14.875C13.6569 14.875 15 13.5878 15 12C15 10.4122 13.6569 9.125 12 9.125C10.3431 9.125 9 10.4122 9 12C9 13.5878 10.3431 14.875 12 14.875Z"
        fill="#0071ED"
      />
      <Path
        d="M23.2052 11.7556C22.3231 9.56893 20.7917 7.67788 18.8003 6.31619C16.8089 4.95449 14.4448 4.18185 12.0002 4.09375C9.55556 4.18185 7.19146 4.95449 5.20007 6.31619C3.20868 7.67788 1.67729 9.56893 0.795169 11.7556C0.735595 11.9135 0.735595 12.0865 0.795169 12.2444C1.67729 14.4311 3.20868 16.3221 5.20007 17.6838C7.19146 19.0455 9.55556 19.8182 12.0002 19.9062C14.4448 19.8182 16.8089 19.0455 18.8003 17.6838C20.7917 16.3221 22.3231 14.4311 23.2052 12.2444C23.2647 12.0865 23.2647 11.9135 23.2052 11.7556ZM12.0002 16.6719C11.036 16.6719 10.0935 16.3979 9.29177 15.8845C8.49008 15.3712 7.86523 14.6415 7.49626 13.7878C7.12728 12.9342 7.03074 11.9948 7.21884 11.0886C7.40694 10.1823 7.87124 9.34986 8.55302 8.69649C9.23481 8.04311 10.1034 7.59816 11.0491 7.41789C11.9948 7.23763 12.975 7.33015 13.8658 7.68375C14.7565 8.03735 15.5179 8.63616 16.0536 9.40445C16.5893 10.1727 16.8752 11.076 16.8752 12C16.8732 13.2385 16.3589 14.4257 15.4451 15.3014C14.5313 16.1771 13.2925 16.67 12.0002 16.6719Z"
        fill="#0071ED"
      />
    </Svg>
  ),
)

EyeOpen.displayName = 'EyeOpen'
