import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View, StyleSheet, Platform, Dimensions, Animated } from 'react-native'
import { MinimisedPrimaryInfo } from '../../PatientPanel/Cards/MinimisedPrimaryInfo'
import { MinimisedCriticalNotesCard } from '../../PatientPanel/Cards/MinimisedCriticalNotes'
import { IconButton } from 'components/IconButton'
import { SvgExpandArrows } from 'components/Icons/ExpandArrows'
import { Colors } from 'constants/Colors'
import { getPatient_getPatient } from 'src/types/getPatient'
import { getWidth } from 'src/utils/getWidth'
import { useLocalToggle } from 'components/Sheet/useLocalToggle'
import {
  PatientPanelDrawerActionTypes,
  PatientPanelDrawerDispatch,
} from 'src/context/patientPanelDrawer'
import { local_toggle_type } from 'constants/LocalToggleOptions'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { DrawerAction } from 'components/Sheet/ReadySheet'

type Props = {
  patient: getPatient_getPatient
  shouldShowDrawer: boolean
  dispatch: PatientPanelDrawerDispatch
  selectDrawerAction: (action: DrawerAction | null) => void
}

const isIOS = Platform.OS !== 'web'
const HALF_INFO_HEADER_WIDTH = 0.5
const PADDING_SPACE = 24
const MAX_PATIENT_PANEL_SIZE = 0.56

export const MinimisedPatientInfo: React.FC<Props> = ({
  patient,
  shouldShowDrawer,
  dispatch,
  selectDrawerAction,
}) => {
  const { isLandscape } = useBreakpoint()
  const [, setShowDrawer] = useLocalToggle({
    prefix: null,
    type: local_toggle_type.SHOWDRAWER,
  })

  // keep the critical note's width updated whenever the critical notes are changed.
  const [isPatientHeaderRender, setIsPatientHeaderRender] = useState(false)

  const handleShowPatientDrawer = useCallback(() => {
    dispatch({
      type: PatientPanelDrawerActionTypes.setShouldShowDrawer,
      shouldShowDrawer: true,
    })
    setShowDrawer(true)
    !isLandscape && setIsPatientHeaderRender(true)
  }, [dispatch, isLandscape, setShowDrawer])

  const onPressOpenPatientDetails = useCallback(() => {
    selectDrawerAction(DrawerAction.DETAILS)
  }, [selectDrawerAction])

  const [miniPrimaryInfoWidth, setMiniPrimaryInfoWidth] = useState(0)
  const [miniCriticalNotesWidth, setMiniCriticalNotesWidth] = useState(0)
  const { width: windowWidth } = Dimensions.get('window')

  const maxPatientPanelWidth =
    (windowWidth - PADDING_SPACE) * MAX_PATIENT_PANEL_SIZE

  const halfPatientPanelWidth = maxPatientPanelWidth * HALF_INFO_HEADER_WIDTH

  const isPatientPanelOverSize =
    miniPrimaryInfoWidth + miniCriticalNotesWidth > maxPatientPanelWidth

  const isPatientPanelSplitLayout =
    isPatientPanelOverSize &&
    miniPrimaryInfoWidth > halfPatientPanelWidth &&
    miniCriticalNotesWidth > halfPatientPanelWidth

  const isMiniPrimaryInfoOverSize = miniPrimaryInfoWidth > halfPatientPanelWidth
  const isMiniCriticalNotesOverSize =
    miniCriticalNotesWidth > halfPatientPanelWidth

  const animatedValue = useRef(new Animated.Value(0)).current
  useEffect(() => {
    const toValue = shouldShowDrawer ? 0 : 1
    Animated.timing(animatedValue, {
      toValue,
      duration: 150,
      useNativeDriver: false,
    }).start()
  }, [animatedValue, shouldShowDrawer])

  return (
    <View
      style={[
        styles.headerRightContainer,
        shouldShowDrawer && styles.shouldShowDrawer,
      ]}
    >
      <View style={[styles.patientInfoContainer, isIOS && styles.iosFlex]}>
        <View
          onLayout={event => {
            if (!isPatientHeaderRender && miniPrimaryInfoWidth > 0) return
            setIsPatientHeaderRender(false)
            setMiniPrimaryInfoWidth(getWidth(event))
          }}
          style={[
            isPatientPanelSplitLayout && styles.halfMiniPrimaryInfo,
            isMiniPrimaryInfoOverSize && styles.isMiniPrimaryInfoOverSize,
            !patient.critical_notes && styles.iosFlex,
          ]}
        >
          <MinimisedPrimaryInfo
            patient={patient}
            onPress={onPressOpenPatientDetails}
            animatedValue={animatedValue}
            isPatientPanelSplitLayout={isPatientPanelSplitLayout}
          />
        </View>
        {!!patient.critical_notes && (
          <View
            onLayout={event => {
              if (!isPatientHeaderRender && miniCriticalNotesWidth > 0) return
              setIsPatientHeaderRender(false)
              setMiniCriticalNotesWidth(getWidth(event))
            }}
            style={[
              isPatientPanelSplitLayout && styles.halfMiniCriticalNotes,
              isMiniCriticalNotesOverSize && styles.isMiniCriticalNotesOverSize,
            ]}
          >
            <MinimisedCriticalNotesCard
              notes={patient.critical_notes}
              animatedValue={animatedValue}
            />
          </View>
        )}
      </View>
      <IconButton
        action={handleShowPatientDrawer}
        label={'toggle patient info'}
        iconColor={Colors.white}
        icon={SvgExpandArrows}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  headerRightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '56%',
    flex: -1,
    gap: 8,
    flexBasis: 'auto',
  },
  patientInfoContainer: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
  },
  iosFlex: {
    flex: -1,
    width: 'auto',
  },
  shouldShowDrawer: {
    width: 0,
    display: 'none',
  },
  halfMiniCriticalNotes: {
    maxWidth: '49%',
    flexShrink: 0,
    flexGrow: 1,
    flexBasis: 'auto',
  },
  halfMiniPrimaryInfo: {
    width: '51%',
    flexShrink: 0,
    flexBasis: 'auto',
  },
  isMiniCriticalNotesOverSize: {
    flexShrink: 1,
  },
  isMiniPrimaryInfoOverSize: {
    flexShrink: 1,
  },
})
