import { MutableRefObject, useRef } from 'react'
import { idleTimerService } from 'src/utils/idleTimerService'

export type IdleTimerProps = {
  timeout: number
  onIdle: () => void
  isIdle: boolean
  children?: React.ReactElement | null
}

export const useIdleTimer = ({ timeout, onIdle, isIdle }: IdleTimerProps) => {
  const timer: MutableRefObject<number | NodeJS.Timeout> = useRef(0)

  const mount = () => {
    unmount()
    if (!timeout || isIdle) {
      unsetIdleAt()
      return
    }
    timer.current = setTimeout(onIdle, timeout)
    setIdleAt(timeout)
  }

  const unmount = () => {
    if (!timer.current) return
    clearTimeout(timer.current)
  }

  const setIdleAt = (timeout: number) => {
    if (!timeout) {
      return unsetIdleAt()
    }
    const idleAt = JSON.stringify(Date.now() + timeout)
    idleTimerService.setIdleAt(idleAt)
  }

  const unsetIdleAt = () => idleTimerService.unsetIdleAt()

  const getIdleAt = (): number | null => {
    const idleAt = idleTimerService.getIdleAt()
    unsetIdleAt() // IdleAt checking is one-off
    return idleAt ? JSON.parse(idleAt) : null
  }

  const shallBeIdle = (): boolean => {
    const idleAt = getIdleAt()
    return idleAt ? Date.now() > idleAt : false
  }

  return { mount, unmount, setIdleAt, getIdleAt, shallBeIdle }
}
