import React from 'react'
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'

type Props = {
  a11yLabel?: string
  text: string
  onPress: () => void
  containerStyles?: object
  color?: string
  fontSize?: number
  disabled?: boolean
}

export const TextLink: React.FC<Props> = ({
  a11yLabel,
  text,
  onPress,
  containerStyles,
  color = Colors.buttons.text,
  fontSize = 16,
  disabled = false,
}) => {
  return (
    <View style={[styles.textLinkContainer, containerStyles]}>
      <TouchableOpacity
        accessibilityLabel={a11yLabel}
        onPress={onPress}
        disabled={disabled}
      >
        <Text
          style={[
            styles.textLink,
            { color: disabled ? Colors.disabled : color, fontSize },
          ]}
        >
          {text}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  textLinkContainer: {
    alignItems: 'center',
    width: 'auto',
  },
  textLink: {
    fontFamily: Fonts.semibold,
  },
})
