import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { MAGIC_ANESTHESIA_TEMPLATE_NAME } from 'components/CreateSheet/useGetAnesthesiaTemplates'
import {
  TreatmentSheet,
  TreatmentSheetFirstLevel,
} from 'components/Treatment/common/types'
import { cloneDeep, find, findIndex, update } from 'lodash'
import { UnreachableCaseError } from 'src/utils/unreachableCaseError'

import { createAnesthesiaState } from './anesthesia_utils/createAnesthesiaInitialState'
import { AnesthesiaChartEntry } from './types/anesthesia'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { getAnesthesiaChartRangeAndIncrement } from 'components/Anesthesia/AnesthesiaChart/utils/getAnesthesiaChartRangeAndIncrement'
import { useTimeResolution } from 'src/hocs/timeContext'

export enum AnesthesiaActionTypes {
  createChartEntry = 'createChartEntry',
  setAnesthesiaData = 'setAnesthesiaData',
  toggleAnesthesiaShowText = 'toggleAnesthesiaShowText',
  setAnesthesiaChartRange = 'setAnesthesiaChartRange',
}

export type AnesthesiaAction =
  | {
      anesthesiaData: AnesthesiaChartEntry[] | null
      type: AnesthesiaActionTypes.setAnesthesiaData
    }
  | {
      value: string
      type: AnesthesiaActionTypes.createChartEntry
    }
  | {
      name: string
      type: AnesthesiaActionTypes.toggleAnesthesiaShowText
    }
  | {
      range: number[]
      type: AnesthesiaActionTypes.setAnesthesiaChartRange
    }

export type AnesthesiaDispatch = (action: AnesthesiaAction) => void

export type AnesthesiaState = {
  anesthesiaData: AnesthesiaChartEntry[] | null
  anesthesiaChartRange: number[]
}

const AnesthesiaStateContext = createContext<AnesthesiaState | undefined>(
  undefined,
)

AnesthesiaStateContext.displayName = 'AnesthesiaStateContext'

const AnesthesiaDispatchContext = createContext<AnesthesiaDispatch | undefined>(
  undefined,
)
AnesthesiaDispatchContext.displayName = 'AnesthesiaDispatchContext'

function anesthesiaReducer(
  state: AnesthesiaState,
  action: AnesthesiaAction,
): AnesthesiaState {
  switch (action.type) {
    case AnesthesiaActionTypes.createChartEntry: {
      return state
    }
    case AnesthesiaActionTypes.setAnesthesiaData: {
      return {
        ...state,
        anesthesiaData: action.anesthesiaData,
      }
    }
    case AnesthesiaActionTypes.toggleAnesthesiaShowText: {
      const entryIndex = findIndex(state.anesthesiaData, [
        'full_title',
        action.name,
      ])
      if (entryIndex === -1) return state
      const stateClone = cloneDeep(state)
      update(stateClone, `anesthesiaData[${entryIndex}].show_text`, e => !e)
      return stateClone
    }
    case AnesthesiaActionTypes.setAnesthesiaChartRange: {
      return {
        ...state,
        anesthesiaChartRange: action.range,
      }
    }

    default: {
      throw new UnreachableCaseError(action)
    }
  }
}

function useAnesthesiaState() {
  const context = useContext(AnesthesiaStateContext)
  if (context === undefined) {
    throw new Error(
      'useAnesthesiaState must be used within a AnesthesiaProvider',
    )
  }
  return context
}

function useAnesthesiaDispatch() {
  const context = useContext(AnesthesiaDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useAnesthesiaDispatch must be used within a AnesthesiaProvider',
    )
  }
  return context
}

type ProviderProps = {
  children: React.ReactElement | (null | React.ReactElement)[]
  stateOverride?: AnesthesiaState
  treatments?: TreatmentSheet[]
}

export const AnesthesiaProvider: React.FC<ProviderProps> = ({
  children,
  stateOverride,
  treatments,
}) => {
  const anesthesiaTreatmentGroup = find(treatments, treatment =>
    MAGIC_ANESTHESIA_TEMPLATE_NAME.test(treatment.name),
  ) as TreatmentSheetFirstLevel | undefined

  const { settingsMap } = useOrgSettings()
  const { visibleDayRange } = useTimeResolution()
  const temperatureUnit = settingsMap?.TEMPERATURE_UNIT?.value ?? ''
  const { range } = getAnesthesiaChartRangeAndIncrement(
    +settingsMap.ANAESTHESIA_CHART_MAX_DEFAULT.value,
  )
  const defaultMaxChartValue = parseInt(
    settingsMap.ANAESTHESIA_CHART_MAX_DEFAULT.value,
    10,
  )
  const init = (initState?: AnesthesiaState) =>
    initState
      ? initState
      : createAnesthesiaState(
          anesthesiaTreatmentGroup,
          temperatureUnit,
          range,
          visibleDayRange,
          defaultMaxChartValue,
        )

  const [state, dispatch] = useReducer(anesthesiaReducer, stateOverride, init)

  const mapShowText = (
    anesthesiaData: AnesthesiaChartEntry[] | null,
    stateAnesthesiaData: AnesthesiaChartEntry[] | null,
  ) => {
    if (anesthesiaData) {
      anesthesiaData.forEach(entry => {
        const existingEntry = find(stateAnesthesiaData, [
          'full_title',
          entry.full_title,
        ])
        if (existingEntry) entry.show_text = existingEntry.show_text
      })
    }
    return anesthesiaData
  }

  /* Update the chart data whenever treatments change, really we care about the
  tasks but this seems to capture the updates */
  useEffect(() => {
    if (stateOverride || !temperatureUnit) return // don't allow updates if override in place
    const { anesthesiaData, anesthesiaChartRange } = createAnesthesiaState(
      anesthesiaTreatmentGroup,
      temperatureUnit,
      range,
      visibleDayRange,
      defaultMaxChartValue,
    )
    const data = mapShowText(anesthesiaData, state.anesthesiaData)
    dispatch({
      anesthesiaData: data,
      type: AnesthesiaActionTypes.setAnesthesiaData,
    })
    dispatch({
      range: anesthesiaChartRange,
      type: AnesthesiaActionTypes.setAnesthesiaChartRange,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anesthesiaTreatmentGroup, stateOverride, temperatureUnit])

  return (
    <AnesthesiaStateContext.Provider value={state}>
      <AnesthesiaDispatchContext.Provider value={dispatch}>
        {children}
      </AnesthesiaDispatchContext.Provider>
    </AnesthesiaStateContext.Provider>
  )
}

type UseAnesthesia = () => [AnesthesiaState, AnesthesiaDispatch]
export const useAnesthesiaContext: UseAnesthesia = () => {
  return [useAnesthesiaState(), useAnesthesiaDispatch()]
}
