import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { differenceInHours } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { WarningType } from './index.types'
import { Colors } from 'constants/Colors'

// If the picked date has been set more than 12 hours in the past, add warnning message to the picker.
const WarningDateRange = 12

export const isWarningDatePicker = (startAt: Date | null | undefined) => {
  if (!startAt) {
    return false
  }
  const hrsDiff = differenceInHours(new Date(), startAt)
  return hrsDiff > WarningDateRange
}

export const DatePickerWarning: React.FC<{
  warningInfo: {
    type: string
    pickedDate?: Date | null
    minDateTime?: Date
  }
}> = ({
  warningInfo: { pickedDate, type = WarningType.TASK, minDateTime },
}) => {
  const { t } = useTranslation()
  if (type === WarningType.TASK || type === WarningType.TREATMENT) {
    const hasWarningMessage = isWarningDatePicker(pickedDate)
    if (!hasWarningMessage) return null
    return (
      <Text style={styles.warningMessage}>
        {t('common.dateTimePicker.placeholder.startTimeWarning')}
      </Text>
    )
  }
  if (type === WarningType.RESCHEDULE) {
    if (!pickedDate || !minDateTime) {
      return null
    }
    if (minDateTime < pickedDate) {
      return null
    }
    return (
      <Text style={styles.warningMessage}>
        {t('task:taskAddEdit:fluids:startFluidError')}
      </Text>
    )
  }
  return null
}

const styles = StyleSheet.create({
  warningMessage: {
    fontSize: 13,
    color: Colors.warning,
    marginLeft: 14,
    marginVertical: 8,
  },
})
