import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from '../DateTimePicker/DateTimePicker'
import { Select } from '../Select/Select'
import { SelectOption } from '../Select/Select.types'
import { DialogHeights } from '../Dialog/common'
import { startOfDay, addMinutes } from 'date-fns'
type Props = {
  dialog?: boolean
  onChange: (date: number | null) => void
  // null stands for now; number stands for selectedDate.getUTCMinutes() + (selectedDate.getUTCHours() * 60)
  value: number | null
  dialogHeight?: DialogHeights
  disabled?: boolean
}

enum StartAtTypes {
  NOW = 'Now',
  TIME = 'time',
}

const options: SelectOption<StartAtTypes>[] = [
  {
    value: StartAtTypes.NOW,
    text: 'Now',
  },
  {
    value: StartAtTypes.TIME,
    text: 'Time of day',
  },
]

export const getInitDate = (value: number | null) => {
  const initDate = new Date()
  if (value === null) {
    return initDate
  }
  return addMinutes(startOfDay(initDate), value)
}

export const getDateMinitesValue = (date: Date) => {
  return date.getHours() * 60 + date.getMinutes()
}

export const SelectTimePicker = ({
  dialog = true,
  dialogHeight = DialogHeights.SMALL,
  onChange,
  value,
  disabled,
}: Props) => {
  const { t } = useTranslation()

  const [selected, setSelected] = useState(
    value === null ? StartAtTypes.NOW : StartAtTypes.TIME,
  )
  const [date, setDate] = useState(() => getInitDate(value))

  const pickerOnChange = (newDate: Date) => {
    setDate(newDate)
    onChange(getDateMinitesValue(newDate))
  }

  const onSelectChange = (selectedValue: StartAtTypes) => {
    onChange(
      selectedValue === StartAtTypes.NOW ? null : getDateMinitesValue(date),
    )
    setSelected(selectedValue)
  }

  return (
    <View style={styles.container}>
      <Select
        dialog={dialog}
        dialogHeight={dialogHeight}
        label={t('addTreatment:schedule:startAt')}
        onChange={onSelectChange}
        options={options}
        selected={selected}
        disabled={disabled}
      />
      {value !== null && (
        <DateTimePicker
          dialog={dialog}
          dialogHeight={dialogHeight}
          label={t('common.SelectTimePicker.pickerLabel')}
          onChange={pickerOnChange}
          value={date}
          mode="time"
          disabled={disabled}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
  },
})
