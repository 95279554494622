import { orderBy } from 'lodash'

import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as Tasks } from 'src/types/getSheet'
import {
  TreatmentNodeDisplayStates,
  TreatmentTreeNodeType,
} from 'src/types/globalTypes'

import { TreatmentSheet, TreatmentChildLevel } from './types'

const getSortedChildTreatments = (
  treatment: TreatmentSheet,
  isHideDiscontinuedTreatment: boolean,
): TreatmentChildLevel[] => {
  if (!('treatments' in treatment)) return []

  const childTreatmentItems = treatment.treatments?.items ?? []

  /* 
     Move the sort logic from GridFlatList to here. 
     https://github.com/vetradar/app/pull/1787
     2 benefits of this change. 
     1, when the sheet rendering, just render the changed treatment rather than all treatments. Much better performance.
     2, sort 3rd level treatments, now discontinued 3rd will be in the bottom.
   */
  return orderBy(
    childTreatmentItems.filter(
      t => !isHideDiscontinuedTreatment || !t.discontinued_at,
    ),
    ['discontinued_at', 'order'],
    ['desc', 'asc'],
  )
}
const getTreatmentIsHidden = (treatment: TreatmentSheet) => {
  return (
    treatment._hidden ||
    treatment.display_states?.includes(TreatmentNodeDisplayStates.HIDDEN)
  )
}
const getTreatmentGroupHasAllHiddenChildren = (
  treatment: TreatmentSheet,
  childTreatments: TreatmentSheet[],
) => {
  return (
    treatment.node_type === TreatmentTreeNodeType.GROUP &&
    !!childTreatments.length &&
    childTreatments.every(getTreatmentIsHidden)
  )
}

const getTreatmentGroupHasNoChildren = (
  treatment: TreatmentSheet,
  childTreatments: TreatmentSheet[],
) => {
  return (
    treatment.node_type === TreatmentTreeNodeType.GROUP &&
    !childTreatments.length
  )
}

const getShouldHideTreatment = (
  treatment: TreatmentSheet,
  childTreatments: TreatmentSheet[],
) =>
  getTreatmentIsHidden(treatment) ||
  getTreatmentGroupHasNoChildren(treatment, childTreatments) ||
  getTreatmentGroupHasAllHiddenChildren(treatment, childTreatments)

export const getTasks = (treatment: TreatmentSheet) => {
  if ('tasks' in treatment) {
    return treatment?.tasks?.items ?? []
  }
  return []
}

type GetTreatmentDetails = (
  treatment: TreatmentSheet | TreatmentChildLevel,
  isHideDiscontinuedTreatment?: boolean,
) => {
  childTreatments: TreatmentChildLevel[]
  hasChildTreatments: boolean
  shouldHideTreatment: boolean
  tasks: Tasks[]
}

export const getSortedTreatmentDetails: GetTreatmentDetails = (
  treatment,
  isHideDiscontinuedTreatment = true,
) => {
  const childTreatments = getSortedChildTreatments(
    treatment,
    isHideDiscontinuedTreatment,
  )

  return {
    childTreatments,
    hasChildTreatments: !!childTreatments.length,
    shouldHideTreatment: getShouldHideTreatment(treatment, childTreatments),
    tasks: getTasks(treatment),
  }
}
