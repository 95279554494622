import React from 'react'
import { StyleSheet } from 'react-native'
import { Control, Controller, useFormState } from 'react-hook-form'
import { InputGroup, NumericInput, Status } from 'components/common'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { PatientWeightUnitOptions } from 'constants/PatientWeightOptions'
import { getWeightUpdatedLabel } from '../utils/getWeightUpdatedAt'
import { useTranslation } from 'react-i18next'
import { WeightUnit } from 'constants/Weight'

type PatientWeightProps = {
  control: Control<any>
  patientWeightUpdatedAt?: string | null
  disabled?: boolean
  handleChange: () => void
}

export const PatientWeight: React.FC<PatientWeightProps> = ({
  control,
  patientWeightUpdatedAt,
  handleChange,
  disabled,
}) => {
  const { t } = useTranslation()
  const { dirtyFields, errors } = useFormState<{
    patientWeight: number | undefined
    patientWeightUnit: WeightUnit | undefined
  }>({ control })

  const errorStatus = createErrorStatus(
    errors.patientWeight?.message || errors.patientWeightUnit?.message,
  )

  const weightUpdatedAtMessage =
    patientWeightUpdatedAt &&
    t('patient:view.weight_at', 'None', {
      updated_at: patientWeightUpdatedAt,
    })

  const weightLabel = getWeightUpdatedLabel({
    weightUpdatedAtMessage,
    isWeightOverriden:
      dirtyFields.patientWeight || dirtyFields.patientWeightUnit,
    weightOverridenMessage: t('patient:view.weight_override'),
    weightUntouchedMessage: t('patient:view.weight'),
  })

  return (
    <Status status={errorStatus}>
      <InputGroup>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <NumericInput
              label={weightLabel}
              value={value}
              required
              disabled={disabled}
              style={styles.flex}
              onChange={newVal => {
                onChange(newVal)
                handleChange()
              }}
            />
          )}
          name="patientWeight"
        />
        <ControlledSelect
          control={control}
          dialog={false}
          disabled={disabled}
          dynamicallyHideOptions={disabled}
          a11yLabel="Weight Unit Select Input"
          onChangeListener={handleChange}
          options={PatientWeightUnitOptions}
          name="patientWeightUnit"
        />
      </InputGroup>
    </Status>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
})
