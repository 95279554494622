import React from 'react'
import { Banner } from 'components/common'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { Colors } from 'constants/Colors'
import { hexToRGB } from 'src/utils/hexToRGB'
import { useApprovals } from 'src/hooks/useApprovals'
import { TreatmentChildLevel } from 'components/Treatment/common/types'

type Props = {
  treatment: TreatmentChildLevel | null
}

export const RequireApprovalBanner = ({ treatment }: Props) => {
  const { t } = useTranslation()
  const { isTreatmentPending } = useApprovals()

  if (treatment && isTreatmentPending(treatment)) {
    return (
      <Banner
        containerStyle={styles.bannerContainerStyle}
        textStyle={styles.bannerTextStyle}
        text={t('treatment:approval:requireApproval')}
        accessibilityLabel={'Approval banner'}
      />
    )
  }

  return null
}

const styles = StyleSheet.create({
  bannerContainerStyle: {
    backgroundColor: hexToRGB(Colors.approval.primary, '0.1'),
  },
  bannerTextStyle: {
    color: Colors.approval.primary,
    fontSize: 14,
  },
})
