import { toast } from 'components/common'
import { useApolloClient, useMutation, gql } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import {
  updateTreatment as UpdateTreatmentT,
  updateTreatmentVariables,
} from 'src/types/updateTreatment'

import { TreatmentSheet } from '../common/types'
import { UPDATE_TREATMENT } from '../graphql'
import {
  UpdateTreatmentInput,
  TreatmentNodeDisplayStates,
  ApprovalStatus,
} from 'src/types/globalTypes'

export const useDiscontinueTreatment = ({
  sheetId,
  patientId,
  treatment,
}: {
  sheetId: string
  patientId: string
  treatment: TreatmentSheet | null
}) => {
  const client = useApolloClient()
  const [{ organisationId }] = useOrganisation()

  const updateTheCache = (
    discontinuedAt: string | null,
    displayState?: TreatmentNodeDisplayStates[] | null,
    approvalStatus?: ApprovalStatus | null,
  ) => {
    const treatmentId = treatment?.id
    client.writeFragment({
      fragment: gql`
        fragment DiscontinuedTreatmentFields on Treatment {
          id
          discontinued_at
          display_states
          approval_status
        }
      `,
      id: `Treatment:${treatmentId}`,

      data: {
        id: treatmentId,
        discontinued_at: discontinuedAt,
        display_states: displayState ? displayState : null,
        approval_status: approvalStatus,
        __typename: 'Treatment',
      },
    })
  }

  const [updateTreatment] = useMutation<
    UpdateTreatmentT,
    updateTreatmentVariables
  >(UPDATE_TREATMENT, {
    onCompleted: data => {
      updateTheCache(
        data.updateTreatment.discontinued_at,
        data.updateTreatment.display_states,
      )
    },
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
  })

  return (overRides?: Partial<UpdateTreatmentInput>, restart = false) => {
    const input: UpdateTreatmentInput = {
      id: treatment?.id!,
      sheet_id: sheetId,
      organisation_id: organisationId,
      name: treatment?.name!,
      schedule: treatment?.schedule!,
      conditional: treatment?.conditional!,
      product_id: treatment?.product?.id || treatment?.id!,
      patient_id: patientId,
      discontinued_at: restart ? null : new Date().toISOString(),
      ...overRides,
    }

    updateTreatment({
      variables: {
        input,
      },
      fetchPolicy: 'no-cache',
      optimisticResponse: {} as any,
      update: (_, { data }) => {
        updateTheCache(
          input.discontinued_at,
          input.display_states,
          data?.updateTreatment?.approval_status,
        )
      },
    })
  }
}
