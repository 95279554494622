import { TreatmentChildLevel } from 'components/Treatment/common/types'
import {
  getFormattedNumber,
  getNormedDosage,
  getNormedTotalDosage,
  getNormedWeight,
} from 'components/TreatmentForm/utils/getCalculations'
import { WeightUnit } from 'src/constants/Weight'
import { getPatient_getPatient as Patient } from 'src/types/getPatient'
import { getSheet_getSheet_treatments_items_treatments_items_medicine_dosage_info as MedicineDosageInfo } from 'src/types/getSheet'

type PatientWeight = { weight?: number | null; weight_unit?: string | null }
type GetTotalPrescribedDosageParams = {
  patientWeight: PatientWeight
  treatmentMedicineDosage?: MedicineDosageInfo | null
  round: (n: number) => number
}

type GetTotalPrescribedDosage = (
  params: GetTotalPrescribedDosageParams,
) => string | null

export const getTotalPrescribedDosage: GetTotalPrescribedDosage = ({
  patientWeight,
  treatmentMedicineDosage,
  round,
}) => {
  if (!treatmentMedicineDosage) return null

  if (treatmentMedicineDosage?.total_dosage) {
    return treatmentMedicineDosage?.total_dosage.toString()
  }

  const prescribedDosage = treatmentMedicineDosage.dosage || 0
  const dosageWeightUnit =
    treatmentMedicineDosage.dosage_weight_unit as WeightUnit
  const dosagePerWeightUnit =
    treatmentMedicineDosage.dosage_patient_weight_unit as WeightUnit

  const normedWeight = getNormedWeight(
    patientWeight.weight ?? 0,
    (patientWeight.weight_unit ?? WeightUnit.KG) as WeightUnit,
  )

  const normedDosage = getNormedDosage(
    prescribedDosage,
    dosageWeightUnit ?? '',
    dosagePerWeightUnit ?? '',
  )

  const totalPrescribedDosage = getFormattedNumber(
    getNormedTotalDosage(normedDosage, normedWeight, dosageWeightUnit),
    round,
  )

  return totalPrescribedDosage
}

type GetFormValueOrTotalDosage = (formOrDosageParams: {
  isMedication: boolean
  formValue: string
  patient?: Patient | null
  treatment?: TreatmentChildLevel | null
  round: (n: number) => number
}) => string | null

export const getFormValueOrTotalDosage: GetFormValueOrTotalDosage = ({
  isMedication,
  formValue,
  patient,
  treatment,
  round,
}) => {
  if (!isMedication) {
    return formValue !== '' ? formValue : null
  }

  return getTotalPrescribedDosage({
    patientWeight: {
      weight: patient?.weight,
      weight_unit: patient?.weight_unit,
    },
    treatmentMedicineDosage: treatment?.medicine_dosage_info,
    round,
  })
}
