import React from 'react'
import { ActionT } from 'components/common/MultiActions'
import { StyleSheet, View } from 'react-native'
import { withPermission } from 'src/hocs'
import { SwipeableRectButton } from './SwipeableRectButton'

type SwipeableActionsTypes = {
  actions: ActionT[]
  close: () => void
}

const PermissionSwipeableRectButton = withPermission(SwipeableRectButton)

export const PermissionSwipeableActions: React.FC<SwipeableActionsTypes> = ({
  actions,
  close,
}) => {
  return (
    <View style={styles.actionsContainer}>
      {actions.map(action => (
        <PermissionSwipeableRectButton
          key={action.name}
          permissionAction={action.permissionAction || null}
          name={action.name}
          onAction={action.onAction}
          onClose={close}
        />
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  actionsContainer: {
    flexDirection: 'row',
  },
})
