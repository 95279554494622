import { useSubscription } from '@apollo/client'
import { SUBSCRIBE_TREATMENT } from 'components/Treatment/graphql'
import { MutationType } from 'types/globalTypes'
import { treatment_treatment_data } from 'types/treatment'
import { useTreatmentSubscriptionHandlers } from 'components/Sheet/treatmentSubscription/handlers/useTreatmentSubscriptionHandlers'
export const useTreatmentSubscription = (sheetId: string) => {
  const subscriptionHandlers = useTreatmentSubscriptionHandlers()

  useSubscription(SUBSCRIBE_TREATMENT, {
    variables: { sheetId },
    onData: options => {
      const subscriptionData = options.data.data?.treatment

      const mutationType: MutationType | undefined = subscriptionData?.mutation
      const treatment: treatment_treatment_data = subscriptionData?.data

      if (!mutationType || !treatment) return

      const handler = subscriptionHandlers[mutationType]

      if (!handler) return

      handler.process(treatment)
    },
  })
}
