import { startOfDay, isSameDay, differenceInHours } from 'date-fns'
import { isNil } from 'lodash'
import React, { createContext, ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { dateFormatter } from 'src/utils/formatter'

const DEFAULT_DATE = new Date(0)
const PatientStartDateContext = createContext(DEFAULT_DATE)
PatientStartDateContext.displayName = 'PatientStartDateContext'
export const PatientStartDateProvider = ({
  children,
  patientStartDateValue,
}: {
  children: ReactNode
  patientStartDateValue: Date
}) => {
  return (
    <PatientStartDateContext.Provider value={patientStartDateValue}>
      {children}
    </PatientStartDateContext.Provider>
  )
}

export const usePatientStartDate = () => {
  const dateInContext = useContext(PatientStartDateContext)
  if (isSameDay(dateInContext, DEFAULT_DATE)) {
    return null
  }
  return dateInContext
}

export const useDayCountDisplay = (dayByIndex: Date | undefined) => {
  const patientDate = usePatientStartDate()
  const { t } = useTranslation()
  if (dayByIndex === undefined) return ''
  if (isNil(patientDate)) return ''
  const displayDiffInDays =
    Math.floor(differenceInHours(dayByIndex, startOfDay(patientDate)) / 24) || 0

  return displayDiffInDays < 0
    ? dateFormatter(dayByIndex, 'daymonth')
    : t('sheet:dayCount', { count: displayDiffInDays + 1 })
}
