import { gql } from '@apollo/client'
import { TREATMENT_GROUP_FIELDS } from 'components/AddTreatment/graphql'
import {
  CUSTOM_PRODUCT_FIELDS,
  MEDICATION_FIELDS,
} from 'components/Settings/graphql'

export const ADD_BUNDLE_TO_SHEET = gql`
  mutation addBundleToSheet(
    $input: AddBundleToSheetInput!
    $options: CreateBundleTreatmentOptions
    $fromISODate: String!
    $toISODate: String!
  ) {
    addBundleToSheet(input: $input, options: $options) {
      ...TreatmentGroupFields
    }
  }
  ${TREATMENT_GROUP_FIELDS}
`

export const BUNDLE_ITEM_CONFIGURATION_FIELDS = gql`
  fragment BundleItemConfiguration on BundleProduct {
    is_fixed
    quantity
    is_single
    row_order
  }
`

export const GET_BUNDLE_PRODUCTS = gql`
  query getValidBundleProducts($product_id: ID!, $organisation_id: ID!) {
    getValidBundleProducts(
      product_id: $product_id
      organisation_id: $organisation_id
    ) {
      product {
        ...BundleProduct
        code
        sites
      }
      bundle_items {
        ...BundleItemConfiguration
        product {
          ...BundleProduct
          ...CustomProductFields
          ...MedicationFields
          cubex_integrations {
            cubex_cabinet_id
            is_cubex_billable
          }
        }
      }
    }
  }
  fragment BundleProduct on Product {
    id
    name
    sheet_group_name
    is_bundle
    sites
    parent_product_id
    parent_product {
      name
      order
    }
  }
  ${CUSTOM_PRODUCT_FIELDS}
  ${MEDICATION_FIELDS}
  ${BUNDLE_ITEM_CONFIGURATION_FIELDS}
`

// Query for getBundlesBySheet
export const GET_BUNDLES_BY_SHEET = gql`
  query getBundlesBySheet(
    $sheet_id: ID!
    $patient_id: ID!
    $organisation_id: ID!
  ) {
    getBundlesBySheet(
      sheet_id: $sheet_id
      patient_id: $patient_id
      organisation_id: $organisation_id
    ) {
      id
      name
    }
  }
`
