import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { LoginWithEmail } from './screens/LoginWithEmail'
import { LoginWithPin } from './screens/LoginWithPin'
import { ChangePassword } from './screens/ChangePassword'
import { ResetPassword } from './screens/ResetPassword'
import { SetupPin } from './screens/SetupPin'
import { AUTH_STATE, useAuth } from 'src/context/auth'
import { VerifyEmail } from './screens/VerifyEmail'

const Stack = createStackNavigator()

export const AuthStack = () => {
  const { authState } = useAuth()
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, cardStyle: { flex: 1 } }}
      initialRouteName={authState.toLowerCase()}
    >
      <Stack.Screen
        name={AUTH_STATE.LOGIN.toLowerCase()}
        component={LoginWithEmail}
        key={AUTH_STATE.LOGIN.toLowerCase()}
      />
      <Stack.Screen
        name={AUTH_STATE.UNLOCK_WITH_PIN.toLowerCase()}
        component={LoginWithPin}
        key={AUTH_STATE.UNLOCK_WITH_PIN.toLowerCase()}
      />
      <Stack.Screen
        name={AUTH_STATE.SET_UP_PIN.toLowerCase()}
        component={SetupPin}
        key={AUTH_STATE.SET_UP_PIN.toLowerCase()}
      />
      <Stack.Screen
        name={AUTH_STATE.VERIFY_EMAIL.toLowerCase()}
        component={VerifyEmail}
        key={AUTH_STATE.VERIFY_EMAIL.toLowerCase()}
      />
      <Stack.Screen
        name={AUTH_STATE.RESET_PASSWORD.toLowerCase()}
        component={ResetPassword}
        key={AUTH_STATE.RESET_PASSWORD.toLowerCase()}
      />
      <Stack.Screen
        name={AUTH_STATE.CHANGE_PASSWORD.toLowerCase()}
        component={ChangePassword}
        key={AUTH_STATE.CHANGE_PASSWORD.toLowerCase()}
      />
    </Stack.Navigator>
  )
}
