import { GET_PATIENT } from 'components/Patient/graphql'
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import {
  getPatient,
  getPatient_getPatient,
  getPatientVariables,
} from 'src/types/getPatient'
import { useOnReconnected } from 'src/hooks/useOnReconnected'

export type PatientById = getPatient_getPatient

export const usePatientById = (
  patientId: string,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
): PatientById | null | undefined => {
  const [{ organisationId }] = useOrganisation()
  const { data, refetch } = useQuery<getPatient, getPatientVariables>(
    GET_PATIENT,
    {
      variables: {
        organisationId,
        id: patientId,
      },
      fetchPolicy,
      skip: !patientId,
    },
  )
  useOnReconnected(refetch)

  return data?.getPatient
}
