export const Primitive = {
  blue900: '#003977',
  blue800: '#004B9E',
  blue700: '#005EC6',
  blue600: '#0071ED',
  blue500: '#3294FF',
  blue400: '#54A6FF',
  blue300: '#99C9FF',
  blue200: '#CCE3FB',
  blue100: '#E5F1FD',
  blue50: '#F2F8FE',
  red900: '#B71C1C',
  red800: '#C62828',
  red700: '#D32F2F',
  red600: '#E53935',
  red500: '#F44336',
  red400: '#EF5350',
  red300: '#E57373',
  red200: '#EF9A9A',
  red100: '#FFE3E4',
  red50: '#FFF5F7',
  orange900: '#AB4510',
  orange800: '#EF6A00',
  orange700: '#F57C00',
  orange600: '#FB8B00',
  orange500: '#FF9700',
  orange400: '#FFA625',
  orange300: '#FFB64C',
  orange200: '#FFCB80',
  orange100: '#FFE0B2',
  orange50: '#FFF3E0',
  green900: '#1B5E20',
  green800: '#2E7D32',
  green700: '#388E3C',
  green600: '#43A047',
  green500: '#4CAF50',
  green400: '#66BB6A',
  green300: '#81C784',
  green200: '#A5D6A7',
  green100: '#C8E6C9',
  green50: '#E8F5E9',
  grey900: '#212121',
  grey800: '#424242',
  grey700: '#616161',
  grey600: '#757575',
  grey500: '#9E9E9E',
  grey400: '#BDBDBD',
  grey350: '#D3D3D3',
  grey300: '#E0E0E0',
  grey250: '#E3E3E3',
  grey200: '#EEEEEE',
  grey100: '#F5F5F5',
  grey50: '#FAFAFA',
  white: '#FFFFFF',
}

export const Brand = {
  navy900: '#152144',
  navy800: '#1E305C',
  navy700: '#253A68',
  navy600: '#2C4373',
  navy500: '#334A7B',
  navy400: '#51638C',
  navy300: '#6E7D9F',
  navy200: '#96A1BA',
  navy100: '#BEC6D6',
  navy50: '#E6E8EE',
  teal900: '#005538',
  teal800: '#007254',
  teal700: '#008263',
  teal600: '#009373',
  teal500: '#00A080',
  teal400: '#00B092',
  teal300: '#47BFAF',
  teal200: '#6DD2BF',
  teal100: '#A9E3D8',
  teal50: '#DDF4F0',
  pink900: '#CE0000',
  pink800: '#DF000F',
  pink700: '#EC0017',
  pink600: '#FF051D',
  pink500: '#FF2019',
  pink400: '#FF2019',
  pink300: '#FF6666',
  pink200: '#FF9492',
  pink100: '#FFCACF',
  pink50: '#FFEAED',
}

export const Record = {
  dash900: '#FD6C00',
  dash800: '#FC8D00',
  dash700: '#FC9E00',
  dash600: '#FBB100',
  dash500: '#FABF00',
  dash400: '#FBC913',
  dash300: '#FBD346',
  dash200: '#FCDF7D',
  dash100: '#FDEBB0',
  dash50: '#FEF8E0',
  contact900: '#004F82',
  contact800: '#006DA1',
  contact700: '#007EB4',
  contact600: '#0090C7',
  contact500: '#009ED4',
  contact400: '#23AAD9',
  contact300: '#4BBADD',
  contact200: '#7CCDE7',
  contact100: '#AFE1F0',
  contact50: '#E0F3F9',
  patient900: '#1F256A',
  patient800: '#303077',
  patient700: '#3A367F',
  patient600: '#453E88',
  patient500: '#4D438E',
  patient400: '#675D97',
  patient300: '#8078A9',
  patient200: '#A39EC1',
  patient100: '#C7C4DA',
  patient50: '#E9E7F0',
  clinical900: '#336D15',
  clinical800: '#578F27',
  clinical700: '#6BA330',
  clinical600: '#80B73A',
  clinical500: '#8FC742',
  clinical400: '#A0D05F',
  clinical300: '#B2D87D',
  clinical200: '#C8E3A3',
  clinical100: '#DEEEC7',
  clinical50: '#F2F9E8',
  financial900: '#EE701F',
  financial800: '#F18D23',
  financial700: '#F29E25',
  financial600: '#F3AE28',
  financial500: '#F4BB2D',
  financial400: '#F6C53C',
  financial300: '#F7D05A',
  financial200: '#F9DC87',
  financial100: '#FCEAB6',
  financial50: '#FEF7E2',
  report900: '#174938',
  report800: '#216452',
  report700: '#27745F',
  report600: '#2C846E',
  report500: '#30917A',
  report400: '#41A18C',
  report300: '#5CB29F',
  report200: '#87C8BA',
  report100: '#B5DDD5',
  report50: '#E1F1EE',
  admin900: '#C30017',
  admin800: '#D20024',
  admin700: '#E0072B',
  admin600: '#F21B31',
  admin500: '#FF2931',
  admin400: '#FD414C',
  admin300: '#F16A70',
  admin200: '#F89599',
  admin100: '#FFCBD2',
  admin50: '#FFEAEE',
  help900: '#2A4689',
  help800: '#3562A9',
  help700: '#3C73BB',
  help600: '#4484CE',
  help500: '#4B92DB',
  help400: '#5CA1E1',
  help300: '#74B1E5',
  help200: '#98C7EE',
  help100: '#BFDCF4',
  help50: '#E4F1FA',
}
