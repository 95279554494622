import React from 'react'
import { useMutation } from '@apollo/client'
import { getTemplateTreatmentSchedule } from 'components/TreatmentForm/utils/getTemplateTreatmentSchedule'
import { omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { toast } from 'src/components/common'
import { useOrganisation } from 'src/context/organisation'
import { ProductType } from 'src/types/globalTypes'
import {
  deleteTemplateTreatment as DeleteTemplateTreatment,
  deleteTemplateTreatmentVariables as DeleteTemplateTreatmentVariables,
} from 'types/deleteTemplateTreatment'
import {
  updateTemplateTreatment as UpdateTemplateTreatment,
  updateTemplateTreatmentVariables as UpdateTemplateTreatmentVariables,
} from 'types/updateTemplateTreatment'

import {
  DELETE_TEMPLATE_TREATMENT,
  GET_TEMPLATE,
  UPDATE_TEMPLATE_TREATMENT,
} from './graphql'
import { TemplateTreatmentDrawer } from './TemplateTreatmentDrawer'
import { TreatmentOption, Values } from './TemplateTreatmentForm'
import { useTemplate } from './useTemplate'

type Props = {
  toggleDrawer: () => void
  templateId: string
  templateTreatmentId: string
  templateSiteIds?: string[] | null
}

export const EditTemplateTreatmentScreen: React.FC<Props> = ({
  toggleDrawer,
  templateId,
  templateTreatmentId,
  templateSiteIds,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { templateTreatments, loading } = useTemplate({ templateId })

  const currentTemplateTreatment = templateTreatments?.find(
    treatment => treatment.id === templateTreatmentId,
  )

  const templateTreatmentName = currentTemplateTreatment?.name ?? ''
  const [deleteTemplateTreatment] = useMutation<
    DeleteTemplateTreatment,
    DeleteTemplateTreatmentVariables
  >(DELETE_TEMPLATE_TREATMENT, {
    variables: {
      input: {
        id: templateTreatmentId,
        template_id: templateId,
      },
    },

    refetchQueries: [
      {
        query: GET_TEMPLATE,
        variables: {
          id: templateId,
          organisation_id: organisationId,
        },
      },
    ],
    onError: err => {
      toast.error(err.message)
    },
    onCompleted: () => {
      toggleDrawer()
      toast.success(t('form.deleted', { itemName: templateTreatmentName }))
    },
  })

  const [updateTemplateTreatment, { loading: updateTemplateTreatmentLoading }] =
    useMutation<UpdateTemplateTreatment, UpdateTemplateTreatmentVariables>(
      UPDATE_TEMPLATE_TREATMENT,
      {
        // For VR-2066: Update template data, otherwise you still get staled data after save and enter the same template treatment again.
        // Maybe should use optimistic update...
        refetchQueries: [
          {
            query: GET_TEMPLATE,
            variables: {
              id: templateId,
              organisation_id: organisationId,
            },
          },
        ],
        onError: err => {
          toast.error(err.message)
        },
        onCompleted: () => {
          toast.success(t('treatment:editTemplateTreatment:success'))
        },
      },
    )

  const onSave = async (
    formData: Values,
    treatmentOptions: TreatmentOption[],
  ) => {
    if (!currentTemplateTreatment) {
      toast.error(t('template:update.err'))
      return
    }

    const input: UpdateTemplateTreatmentVariables['input'] = {
      ...omit(currentTemplateTreatment, [
        'created_at',
        'node_type',
        'conditional',
        'product',
      ]),
      ...omit(formData, [
        'selectedTreatmentOptions',
        'instructions',
        'isBillable',
        'dosageRate',
        'dosageWeightUnit',
        'dosagePerWeightUnit',
        'isAutoCompleted',
        'frequencyInput',
      ]),
      id: currentTemplateTreatment.id,
      organisation_id: organisationId,
      template_id: templateId,
      ...(formData.instructions !== currentTemplateTreatment.instructions && {
        instructions: formData.instructions,
      }),
      ...(formData.isBillable !== null && {
        is_billable: formData.isBillable,
      }),
      is_auto_completed: !!formData.isAutoCompleted,
      schedule: getTemplateTreatmentSchedule(
        formData.schedule,
        formData.frequencyInput,
      ),
      treatment_options: {
        products: treatmentOptions.map(treatment => treatment.id),
      },
    }
    if (formData.conditional !== currentTemplateTreatment.conditional) {
      input.conditional = formData.conditional
    }
    if (treatmentOptions.length === 1) {
      input.product_id = treatmentOptions[0].id
      const treatmentName = treatmentOptions[0].name
      // Don't change name if already in it
      if (input.name.indexOf(treatmentName) === -1) {
        input.name = `${treatmentName} (${input.name})`
      }
      if (treatmentOptions[0].type === ProductType.MEDICATION) {
        input.medicine_dosage_info = {
          dosage: formData.dosageRate,
          dosage_weight_unit: formData.dosageWeightUnit,
          dosage_patient_weight_unit: formData.dosagePerWeightUnit,
        }
      }
    } else {
      input.product_id = null
      input.medicine_dosage_info = {
        dosage: null,
        dosage_weight_unit: null,
        dosage_patient_weight_unit: null,
      }
    }

    await updateTemplateTreatment({
      variables: { input },
    })
    toggleDrawer()
  }

  return loading ? (
    <ActivityIndicator size="large" style={styles.marginTop} testID="Loading" />
  ) : (
    <TemplateTreatmentDrawer
      currentTemplateTreatment={currentTemplateTreatment}
      templateSiteIds={templateSiteIds}
      onSave={onSave}
      onDelete={deleteTemplateTreatment}
      createOrUpdateTreatmentLoading={updateTemplateTreatmentLoading}
    />
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25,
  },
})
