import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { SvgArrow } from 'src/components/Icons'
import { useTreatmentDrawerContext } from 'src/context/treatmentDrawer'

export const TemplateBackButton = () => {
  const [{ handleBack }] = useTreatmentDrawerContext()

  return (
    <>
      {!!handleBack && (
        <View style={styles.container}>
          <TouchableOpacity onPress={handleBack}>
            <SvgArrow orient="left" color="black" height={18} width={18} />
          </TouchableOpacity>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
