import { WeightUnit } from 'src/constants/Weight'

import { SelectOption } from '../../common/Select/Select.types'

export enum VolumeUnit {
  L = 'l',
  ML = 'ml',
  FL_OZ = 'fl oz',
  TABLE = 'table',
  PUFF = 'puff',
  CHEW = 'chew',
  CAP = 'cap',
  VIAL = 'vial',
}

export enum CRITimeUnit {
  min = 'min',
  hour = 'hour',
  day = 'day',
}

export const criTimeFactor: { readonly [key in CRITimeUnit]: number } = {
  min: 1,
  hour: 60,
  day: 24 * 60,
}

export const volumeFactor: { readonly [key in VolumeUnit]: number } = {
  l: 1000,
  ml: 1,
  'fl oz': 29.5735296875,
  table: 1,
  puff: 1,
  chew: 1,
  cap: 1,
  vial: 1,
}

export const timeFactor = {
  min: 1,
  hour: 60,
  day: 24 * 60,
} as any

export const standardWeightUnitOptions: SelectOption<WeightUnit>[] = [
  {
    value: WeightUnit.UG,
  },
  {
    value: WeightUnit.MCG,
  },
  {
    value: WeightUnit.MG,
  },
  {
    value: WeightUnit.G,
  },
  {
    value: WeightUnit.KG,
  },
  {
    value: WeightUnit.LB,
  },
  {
    value: WeightUnit.OZ,
  },
]

export const specialDosageWeightUnitOptions: SelectOption<WeightUnit>[] = [
  {
    value: WeightUnit.ML,
  },
]

export const allWeightUnitOptions: SelectOption<WeightUnit>[] = [
  ...standardWeightUnitOptions,
  {
    value: WeightUnit.IU,
  },
  {
    value: WeightUnit.MEQ,
  },
  {
    value: WeightUnit.MMOL,
  },
]

export const weightUnitOptions: SelectOption<WeightUnit>[] = [
  {
    value: WeightUnit.UG,
    text: 'ug', // 'microgram',
  },
  {
    value: WeightUnit.MCG,
    text: 'mcg',
  },
  {
    value: WeightUnit.MG,
    text: 'mg', // 'milligram',
  },
  {
    value: WeightUnit.G,
    text: 'g', // 'gram',
  },
  {
    value: WeightUnit.KG,
    text: 'kg',
  },
  {
    value: WeightUnit.LB,
    text: 'lb',
  },
  {
    value: WeightUnit.IU,
    text: 'IU',
  },
  {
    value: WeightUnit.MEQ,
    text: 'mEq', // 'milliequivalent',
  },
  {
    value: WeightUnit.MMOL,
    text: 'mmol',
  },
  {
    value: WeightUnit.OZ,
    text: 'oz',
  },
]

export const perTimeOptions: SelectOption<string>[] = [
  {
    value: 'hour',
    text: '/ hr',
  },
  {
    value: 'min',
    text: '/ min',
  },
]

export const criPerTimeOptions: SelectOption<CRITimeUnit>[] = [
  {
    value: CRITimeUnit.hour,
    text: '/ hr',
  },
  {
    value: CRITimeUnit.min,
    text: '/ min',
  },
  {
    value: CRITimeUnit.day,
    text: '/ day',
  },
]

export const perWeightUnitOptions: SelectOption<WeightUnit>[] = [
  {
    value: WeightUnit.KG,
    text: '/ kg',
  },
  {
    value: WeightUnit.G,
    text: '/ g',
  },
]

export const weightOptions: SelectOption<WeightUnit>[] = [
  {
    value: WeightUnit.KG,
    text: 'kg',
  },
  {
    value: WeightUnit.G,
    text: 'g',
  },
]

export enum RepeatScheduleValue {
  NONE = 'No Tasks',
  SINGLE = 'Single Task',
  REPEATING = 'Repeating Tasks',
}

export const repeatScheduleOptions: SelectOption<RepeatScheduleValue>[] = [
  {
    value: RepeatScheduleValue.NONE,
  },
  {
    value: RepeatScheduleValue.SINGLE,
  },
  {
    value: RepeatScheduleValue.REPEATING,
  },
]

const MINUTES_IN_HOUR = 60
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24

export const repeatUntilOptions: SelectOption<number>[] = [
  {
    text: 'discharge',
    value: 0,
  },
  {
    text: '5 minutes from start',
    value: 5,
  },
  {
    text: '10 minutes from start',
    value: 10,
  },
  {
    text: '15 minutes from start',
    value: 15,
  },
  {
    text: '20 minutes from start',
    value: 20,
  },
  {
    text: '30 minutes from start',
    value: 30,
  },
  {
    text: '1 hour from start',
    value: MINUTES_IN_HOUR * 1,
  },
  {
    text: '2 hours from start',
    value: MINUTES_IN_HOUR * 2,
  },
  {
    text: '4 hours from start',
    value: MINUTES_IN_HOUR * 4,
  },
  {
    text: '6 hours from start',
    value: MINUTES_IN_HOUR * 6,
  },
  {
    text: '8 hours from start',
    value: MINUTES_IN_HOUR * 8,
  },
  {
    text: '12 hours from start',
    value: MINUTES_IN_HOUR * 12,
  },
  {
    text: '24 hours from start',
    value: MINUTES_IN_HOUR * 24,
  },
  {
    text: '2 days from start',
    value: MINUTES_IN_DAY * 2,
  },
  {
    text: '3 days from start',
    value: MINUTES_IN_DAY * 3,
  },
]

export const timeWindowOptions: SelectOption<number>[] = [
  {
    text: '5m',
    value: 5,
  },
  {
    text: '10m',
    value: 10,
  },
  {
    text: '15m',
    value: 15,
  },
  {
    text: '30m',
    value: 30,
  },
  {
    text: '1 hour',
    value: 60,
  },
  {
    text: '2 hours',
    value: MINUTES_IN_HOUR * 2,
  },
  {
    text: '3 hours',
    value: MINUTES_IN_HOUR * 3,
  },
  {
    text: '4 hours',
    value: MINUTES_IN_HOUR * 4,
  },
  {
    text: '5 hours',
    value: MINUTES_IN_HOUR * 5,
  },
  {
    text: '6 hours',
    value: MINUTES_IN_HOUR * 6,
  },
  {
    text: '12 hours',
    value: MINUTES_IN_HOUR * 12,
  },
  {
    text: '24 hours',
    value: MINUTES_IN_HOUR * 24,
  },
]

export const durationOptions: SelectOption<number>[] = [
  {
    text: '1m',
    value: 1,
  },
  {
    text: '5m',
    value: 5,
  },
  {
    text: '10m',
    value: 10,
  },
  {
    text: '15m',
    value: 15,
  },
  {
    text: '20m',
    value: 20,
  },
  {
    text: '30m',
    value: 30,
  },
  {
    text: '1 hour',
    value: 60,
  },
  {
    text: '2 hours',
    value: MINUTES_IN_HOUR * 2,
  },
  {
    text: '3 hours',
    value: MINUTES_IN_HOUR * 3,
  },
  {
    text: '4 hours',
    value: MINUTES_IN_HOUR * 4,
  },
  {
    text: '6 hours',
    value: MINUTES_IN_HOUR * 6,
  },
  {
    text: '8 hours',
    value: MINUTES_IN_HOUR * 8,
  },
  {
    text: '12 hours',
    value: MINUTES_IN_HOUR * 12,
  },
  {
    text: '24 hours',
    value: MINUTES_IN_HOUR * 24,
  },
  {
    text: '48 hours',
    value: MINUTES_IN_HOUR * 48,
  },
  {
    text: '72 hours',
    value: MINUTES_IN_HOUR * 72,
  },
]

export const routesOfAdminAbbreviationOptions: SelectOption<string>[] = [
  {
    text: 'IA',
    value: 'intra-articular',
  },
  {
    text: 'IN',
    value: 'intra-nasal',
  },
  {
    text: 'IU',
    value: 'intra-uterine',
  },
  {
    text: 'IW',
    value: 'intra-wound',
  },
  {
    text: 'SL',
    value: 'sublingual',
  },
  {
    text: 'Con',
    value: 'conjunctival',
  },
  {
    text: 'P.R',
    value: 'REC',
  },
  {
    text: 'TD',
    value: 'transdermal',
  },
]

export const RouteOfAdminCRI = 'CRI'

export const routesOfAdminOptions: SelectOption<string>[] = [
  {
    text: 'PO (Oral/Per Os)',
    value: 'PO',
  },
  {
    text: 'IV (Intravenous)',
    value: 'IV',
  },
  {
    text: 'SQ (Subcutaneous)',
    value: 'SQ',
  },
  {
    text: 'IM (Intramuscular)',
    value: 'IM',
  },
  {
    text: 'IO (Intraosseus)',
    value: 'IO',
  },
  {
    text: 'PI (Peri-Incisional)',
    value: 'PI',
  },
  {
    text: 'IA (Intraarticular)',
    value: 'intra-articular',
  },
  {
    text: 'IN (Intranasal)',
    value: 'intra-nasal',
  },
  {
    text: 'IU (Intrauterine)',
    value: 'intra-uterine',
  },
  {
    text: 'IW (Intrawound)',
    value: 'intra-wound',
  },
  {
    text: 'SL (Sublingual)',
    value: 'sublingual',
  },
  {
    text: 'Con. (Conjunctival)',
    value: 'conjunctival',
  },
  {
    text: 'OS (In the left eye)',
    value: 'OS',
  },
  {
    text: 'OD (In the right eye)',
    value: 'OD',
  },
  {
    text: 'OU (In both eyes)',
    value: 'OU',
  },
  {
    text: 'AS (In left ear)',
    value: 'AS',
  },
  {
    text: 'AD (In right ear)',
    value: 'AD',
  },
  {
    text: 'AU (In both ears)',
    value: 'AU',
  },
  {
    text: 'P.R. (Rectal)',
    value: 'REC',
  },
  {
    text: 'TD (Transdermal)',
    value: 'transdermal',
  },
  {
    text: 'NG (Nasogastric)',
    value: 'NG',
  },
  {
    text: 'NE (Nasoesophageal)',
    value: 'NE',
  },
  {
    text: 'PEG (PEG feeding tube)',
    value: 'PEG',
  },
  {
    text: 'E-Tube (Esophagostomy)',
    value: 'ETUBE',
  },
  {
    text: 'Local (Locoregional)',
    value: 'LOCAL',
  },
  {
    text: 'IBur (In buretrol)',
    value: 'IBUR',
  },
  {
    text: 'IBag (In bag)',
    value: 'IBAG',
  },
  {
    text: 'EPI (Epidural)',
    value: 'EPI',
  },
  {
    text: 'SPL (Subpalpebral lavage)',
    value: 'SPL',
  },
  {
    text: 'Neb (Nebulized)',
    value: 'NEB',
  },
]

export const DEFAULT_REPEATING_INTERVAL_4_HOURS = {
  text: '4 hours',
  value: MINUTES_IN_HOUR * 4,
}
export const repeatingIntervalOptions: SelectOption<number>[] = [
  {
    text: '5m',
    value: 5,
  },
  {
    text: '10m',
    value: 10,
  },
  {
    text: '15m',
    value: 15,
  },
  {
    text: '20m',
    value: 20,
  },
  {
    text: '30m',
    value: 30,
  },
  {
    text: '1 hour',
    value: 60,
  },
  {
    text: '1.5 hours',
    value: MINUTES_IN_HOUR * 1.5,
  },
  {
    text: '2 hours',
    value: MINUTES_IN_HOUR * 2,
  },
  {
    text: '3 hours',
    value: MINUTES_IN_HOUR * 3,
  },
  DEFAULT_REPEATING_INTERVAL_4_HOURS,
  {
    text: '5 hours',
    value: MINUTES_IN_HOUR * 5,
  },
  {
    text: '6 hours',
    value: MINUTES_IN_HOUR * 6,
  },
  {
    text: '8 hours',
    value: MINUTES_IN_HOUR * 8,
  },
  {
    text: '12 hours',
    value: MINUTES_IN_HOUR * 12,
  },
  {
    text: '24 hours',
    value: MINUTES_IN_HOUR * 24,
  },
  {
    text: '36 hours',
    value: MINUTES_IN_HOUR * 36,
  },
  {
    text: '48 hours',
    value: MINUTES_IN_HOUR * 48,
  },
  {
    text: '72 hours',
    value: MINUTES_IN_HOUR * 72,
  },
]

export const calculateBaseOptions: SelectOption<string>[] = [
  {
    text: 'weight',
    value: 'weight',
  },
]

export const concentrationVolumeUnitOptions: SelectOption<VolumeUnit>[] = [
  { text: `/ ml`, value: VolumeUnit.ML },
  { text: '/ l', value: VolumeUnit.L },
  { text: '/ fl oz', value: VolumeUnit.FL_OZ },
  { text: '/ cap', value: VolumeUnit.CAP },
  { text: '/ chew', value: VolumeUnit.CHEW },
  { text: '/ puff', value: VolumeUnit.PUFF },
  { text: '/ tab', value: VolumeUnit.TABLE },
  { text: '/ vial', value: VolumeUnit.VIAL },
]

export const volumeUnitOptions: SelectOption<VolumeUnit>[] = [
  { text: `ml`, value: VolumeUnit.ML },
  { text: 'l', value: VolumeUnit.L },
  { text: 'fl oz', value: VolumeUnit.FL_OZ },
  { text: 'cap', value: VolumeUnit.CAP },
  { text: 'chew', value: VolumeUnit.CHEW },
  { text: 'puff', value: VolumeUnit.PUFF },
  { text: 'tab', value: VolumeUnit.TABLE },
  { text: '/ vial', value: VolumeUnit.VIAL },
]

export const volumeUnitOptionsV2: SelectOption<VolumeUnit>[] = [
  { text: `ml`, value: VolumeUnit.ML },
  { text: 'l', value: VolumeUnit.L },
]

export const dilutedConcentrationVolumeUnitOptions: SelectOption<VolumeUnit>[] =
  [
    { text: `/ml`, value: VolumeUnit.ML },
    { text: '/l', value: VolumeUnit.L },
    { text: '/fl oz', value: VolumeUnit.FL_OZ },
  ]

export enum AdminMethod {
  FLUID_BAG = 'Fluid Bag',
  SYRINGE_PUMP = 'Syringe pump',
}

export const administrationMethodOptions: SelectOption<AdminMethod>[] = [
  { text: 'Fluid Bag', value: AdminMethod.FLUID_BAG },
  { text: 'Syringe pump', value: AdminMethod.SYRINGE_PUMP },
]

export enum ConcentrationSetting {
  IGNORE = 'IGNORE',
  OVERRIDE = 'OVERRIDE',
}

export const concentrationSettingOptions: SelectOption<ConcentrationSetting>[] =
  [
    { text: 'Ignore concentration', value: ConcentrationSetting.IGNORE },
    { text: 'Override concentration', value: ConcentrationSetting.OVERRIDE },
  ]

export const totalVolumeUnitOptions: SelectOption<string>[] = [
  { text: 'ml', value: 'ml' },
  { text: 'oz', value: 'oz' },
  { text: 'lb', value: 'lb' },
  { text: 'kg', value: 'kg' },
  { text: 'g', value: 'g' },
  { text: 'mg', value: 'mg' },
  { text: 'mEq', value: 'mEq' },
  { text: 'scoop', value: 'scoop' },
  { text: 'tablet', value: 'tablet' },
  { text: 'tube', value: 'tube' },
  { text: 'treatment', value: 'treatment' },
  { text: 'drop', value: 'drop' },
  { text: 'vial', value: 'vial' },
  { text: 'IU', value: 'IU' },
  { text: 'ng', value: 'ng' },
]

/**
 * Use for the options list inside scheduling drop down
 * No tasks - -1
 * Single task - 0
 * Repeating tasks - Other values
 */
export const repeatOptionsV2: SelectOption<number>[] = [
  {
    text: 'No tasks',
    value: -1,
  },
  {
    text: 'Single task',
    value: 0,
  },
]
