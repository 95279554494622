import { getConsultation_getConsultation as Consultation } from 'types/getConsultation'

export const getConsultationProblems = (
  consultation: Partial<Consultation> | undefined,
) => {
  const consultProblemList =
    consultation?.presenting_problems?.map(problem => problem.name) ?? []

  const consultDescription = consultation?.description
  if (consultDescription) consultProblemList.push(consultDescription)
  return consultProblemList.filter(problem => !!problem.length)
}
