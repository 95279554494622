import React, { useCallback, useEffect, useState } from 'react'
import { EditableText, FormBreak } from 'components/common'
import { OnSave } from 'components/EditTreatment/EditTreatmentScreen'
import { FormData, TreatmentForm } from 'components/TreatmentForm'
import { useGetProductGroups } from 'components/TreatmentTemplate/ProductPicker/ProductPickerScreen'
import { TreatmentOption } from 'components/TreatmentTemplate/TemplateTreatmentForm'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, View, StyleSheet } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useOrganisation } from 'src/context/organisation'
import { useDefaultIVFluid } from 'src/hooks/useDefaultIVFluid'
import { usePatientById } from 'src/hooks/usePatient'
import { useSetShowTreatmentTabs } from 'src/hooks/useSetShowTreatmentTabs'
import { ProductType } from 'src/types/globalTypes'
import { useCreateTreatment } from 'src/utils/useCreateTreatment'
import { AddedProducts } from './AddedProducts'
import { NameType, ProductName } from 'components/AddTreatment/ProductName'

type Props = {
  defaultOverrides?: Partial<FormData>
  onEditIVInfusion?: OnSave
  treatmentOptions: TreatmentOption[]
  onRemoveTreatmentOptions: (id: string) => void
  medicationOption: TreatmentOption | null
  onRemoveMedicationOption: (id: string) => void
  onPressAddProductGroup: () => void
  onPressAddMedicationOption: () => void
  onDone: () => void
  sheetId: string
  patientId: string
  isEdit?: boolean
  handleChangeTreatmentName: (name: string, id: string) => void
}

const DefaultIVInfusionGroupName = 'Fluids'

const DefaultIVInfusionProductName = 'IV Fluids'

export const CalculatorForm = React.memo(
  ({
    treatmentOptions,
    onPressAddProductGroup,
    onRemoveTreatmentOptions,
    medicationOption,
    onPressAddMedicationOption,
    onRemoveMedicationOption,
    onDone,
    sheetId,
    patientId,
    onEditIVInfusion,
    defaultOverrides,
    isEdit,
    handleChangeTreatmentName,
  }: Props) => {
    const groups = useGetProductGroups()
    const { t } = useTranslation()
    const [{ organisationId }] = useOrganisation()

    const initialName = medicationOption?.name ?? DefaultIVInfusionProductName

    /*
      We now query on origin_id if medicationOption === null,
      we are using the skip feature of useQuery to skips each of the below
      queries depending on the value of medicationOption
    */
    const { productDetail, loading } = useDefaultIVFluid(
      medicationOption?.id,
      organisationId,
    )

    const medicationName = productDetail?.name ?? initialName

    const [treatmentName, setTreatmentName] = useState(medicationName)

    const patient = usePatientById(patientId)

    useSetShowTreatmentTabs()

    useEffect(() => {
      setTreatmentName(initialName)
    }, [initialName, medicationOption])

    const createTreatment = useCreateTreatment(sheetId, patientId)

    const onSave = useCallback(
      async (formData: FormData) => {
        if (!productDetail || treatmentName === null) {
          return
        }
        const groupName =
          medicationOption?.groupName ?? DefaultIVInfusionGroupName

        //  TODO: in VR-6935, we need to send parent_product_id to the platform
        const foundGroup = groups.find(group => group.name === groupName)

        const productInfo = {
          productId: productDetail.id,
          productName: productDetail.name,
          productType: productDetail.type as ProductType,
          productGroupId: foundGroup?.id ?? '',
          productGroupName: foundGroup?.name ?? DefaultIVInfusionGroupName,
          productGroupOrder: foundGroup?.order ?? 0,
        }

        const formDataWithName = { ...formData, treatmentName }

        if (onEditIVInfusion) {
          // filter new child treatments
          const editNewChildTreatments = treatmentOptions
            .filter(treatmentOption => !treatmentOption.isOldChild)
            .map(treatmentOption => ({
              product_id: treatmentOption.id,
              name: treatmentOption.name,
            }))

          onEditIVInfusion(formDataWithName, {
            child_treatments: editNewChildTreatments,
          })
          return
        }

        const childTreatments = treatmentOptions.map(treatmentOption => ({
          product_id: treatmentOption.id,
          name: treatmentOption.name,
        }))

        await createTreatment(
          formDataWithName,
          productInfo,
          {
            createTasks: true,
            child_treatments: childTreatments,
          },
          true,
        )

        onDone()
      },
      [
        createTreatment,
        groups,
        medicationOption,
        onDone,
        productDetail,
        treatmentName,
        treatmentOptions,
        onEditIVInfusion,
      ],
    )
    if (loading) {
      return <ActivityIndicator size="large" />
    }

    // TODO: in the VR-6865, we make sure productDetail won't be null, but once this happen, it's better to show a message.
    if (!productDetail || !patient) {
      return null
    }

    return (
      <>
        <KeyboardAwareScrollView>
          <View style={styles.header}>
            <EditableText value={treatmentName} onChange={setTreatmentName} />
            <FormBreak />
            {treatmentName !== medicationName && (
              <ProductName
                productName={medicationName}
                type={NameType.SUBTITLE}
              />
            )}
          </View>
          <AddedProducts
            title={t('addTreatment:ivInfusionAddedProducts')}
            onPressAdd={onPressAddProductGroup}
            selectedProducts={treatmentOptions}
            onRemoveProduct={onRemoveTreatmentOptions}
            setProductName={handleChangeTreatmentName}
          />
          <AddedProducts
            shouldDisabledAdd={medicationOption?.isOldChild}
            title={t('addTreatment:ivInfusionAddedMedications')}
            onPressAdd={onPressAddMedicationOption}
            selectedProducts={medicationOption ? [medicationOption] : []}
            onRemoveProduct={onRemoveMedicationOption}
          />
          <TreatmentForm
            submitTitle={
              isEdit ? t('general.saveChanges') : t('addTreatment:addToSheet')
            }
            patient={patient}
            product={productDetail}
            onSave={onSave}
            isIVInfusion={true}
            defaultOverrides={defaultOverrides}
            showEditableTreatmentName={false}
            isEdit={isEdit}
          />
        </KeyboardAwareScrollView>
      </>
    )
  },
)

CalculatorForm.displayName = 'CalculatorForm'

const styles = StyleSheet.create({
  header: {
    marginBottom: 8,
  },
})
