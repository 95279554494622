import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import {
  getTemplateWithProducts,
  getTemplateWithProductsVariables,
} from 'src/types/getTemplateWithProducts'

import { GET_TEMPLATE_WITH_PRODUCTS } from './graphql'
import { getTreatmentsFromTemplate } from './utils/templateTreatmentsUtils'

type UseTemplateParams = {
  templateId?: string
}

export const useTemplate = ({ templateId = '' }: UseTemplateParams) => {
  const [{ organisationId }] = useOrganisation()
  // skip some actions if we're in 'Add Template' mode (no templateId yet)
  const isExistingTemplate = !!templateId.length
  const { data, loading, refetch } = useQuery<
    getTemplateWithProducts,
    getTemplateWithProductsVariables
  >(GET_TEMPLATE_WITH_PRODUCTS, {
    skip: !isExistingTemplate,
    variables: {
      id: templateId,
      organisation_id: organisationId,
    },
  })

  const templateTreatments = useMemo(() => {
    const template = data?.getTemplate
    return getTreatmentsFromTemplate(template)
  }, [data?.getTemplate])

  return {
    loading,
    templateTreatments,
    template: data?.getTemplate,
    refetchTemplate: refetch,
  }
}
