import React, { forwardRef, useCallback, useMemo } from 'react'
import {
  BottomSheetBackdrop,
  BottomSheetHandleProps,
  BottomSheetModal,
} from '@gorhom/bottom-sheet'
import { Portal, PortalHost } from '@gorhom/portal'
import { HeaderHandle } from 'components/BottomSheet/HeaderHandle'
import { BottomSheetDefaultBackdropProps } from '@gorhom/bottom-sheet/lib/typescript/components/bottomSheetBackdrop/types'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Typography } from 'src/design-system/theme'
import { Primitive } from 'src/design-system/theme/colors/base'
import { SvgClose } from 'components/Icons'
import { Fonts } from 'constants/Fonts'

const BOTTOM_SHEET_PORTAL_NAME = 'BOTTOM_SHEET_PORTAL'

export const BottomSheetPortal: React.FC = () => (
  <PortalHost name={BOTTOM_SHEET_PORTAL_NAME} />
)

type VRBottomSheetProps = {
  title?: string
  children: React.ReactNode
  snapPoints?: string[]
  initialSnapIndex?: number
  handleClose?: () => void
  handleOnDismiss?: () => void
}

export const VRBottomSheet = forwardRef<BottomSheetModal, VRBottomSheetProps>(
  (
    {
      title,
      children,
      snapPoints = ['45%', '90%'],
      initialSnapIndex = 1,
      handleClose,
      handleOnDismiss,
    },
    ref,
  ) => {
    const snapPointsFn = useMemo(() => snapPoints, [snapPoints])

    // renders
    const renderBackdrop = useCallback(
      (props: BottomSheetDefaultBackdropProps) => (
        <BottomSheetBackdrop
          {...props}
          pressBehavior={'close'}
          disappearsOnIndex={-1}
        />
      ),
      [],
    )
    const renderHeaderHandle = (props: BottomSheetHandleProps) => (
      <HeaderHandle
        {...props}
        style={styles.headerContainer}
        indicatorStyle={styles.indicator}
      >
        <View style={styles.headerTextContainer}>
          <Text style={styles.headerText}>{title}</Text>
          <Pressable style={styles.dismissButton} onPress={handleClose}>
            <SvgClose color={Primitive.grey500} length={18} />
          </Pressable>
        </View>
      </HeaderHandle>
    )

    return (
      <Portal hostName={BOTTOM_SHEET_PORTAL_NAME}>
        <BottomSheetModal
          style={{ zIndex: 1000 }}
          ref={ref}
          index={initialSnapIndex}
          snapPoints={snapPointsFn}
          backdropComponent={renderBackdrop}
          handleComponent={title ? renderHeaderHandle : undefined}
          onDismiss={handleOnDismiss}
        >
          {children}
        </BottomSheetModal>
      </Portal>
    )
  },
)

const styles = StyleSheet.create({
  headerText: {
    flex: 1,
    fontFamily: Fonts.semibold,
    lineHeight: 24,
    fontSize: Typography.FontSizes.lg,
    textAlign: 'center',
    marginTop: 2,
    paddingLeft: 26,
  },
  headerContainer: {
    paddingHorizontal: 0,
    backgroundColor: Primitive.grey100,
    borderBottomColor: Primitive.grey300,
    borderBottomWidth: 1,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Primitive.grey100,
    paddingHorizontal: 16,
    paddingVertical: 4,
  },
  indicator: {
    marginTop: -3,
    backgroundColor: Primitive.grey500,
    height: 5,
    width: 38,
  },
  dismissButton: {
    marginTop: 2,
    paddingRight: 8,
  },
})
