import React from 'react'
import Svg, { G, Rect, Path } from 'react-native-svg'

type Props = {
  color?: string
  width?: number
  height?: number
}

export const SvgPendingClock: React.FC<Props> = ({
  color = '#fff',
  width = 18,
  height = 18,
}) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    testID="SvgPendingClock"
  >
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G>
        <Rect x="-0.00144775779" y="0" width="24" height="24" />
        <G transform="translate(2.000000, 2.000000)" fill={color}>
          <Path
            d="M9.9958114,1.81818231 C5.53084526,1.81971266 1.89326928,5.40113361 1.81805511,9.85890305 C1.80958497,10.3609086 1.39576263,10.7609982 0.893757034,10.752528 C0.39175144,10.7440579 -0.00833809584,10.3302356 0.0001320439,9.82822996 C0.0920740322,4.37904095 4.53787592,0.00187107521 9.99518822,5.4448473e-07 L9.99868386,1.88293825e-13 C15.5215314,1.88293825e-13 19.9986839,4.4771525 19.9986839,10 C19.9986839,15.440038 15.6498538,19.8791087 10.2207049,19.9975796 C9.71874733,20.008533 9.30295089,19.6104954 9.29199754,19.1085379 C9.28104419,18.6065803 9.67908174,18.1907839 10.1810393,18.1798305 C14.6222431,18.0829179 18.180502,14.4508231 18.180502,10 C18.180502,5.48130659 14.5173773,1.81818182 9.99868386,1.81818182 L9.9958114,1.81818231 Z"
            transform="translate(9.999342, 9.998900) rotate(180.000000) translate(-9.999342, -9.998900) "
          />
          <Path d="M17.5677981,6.892691 C17.3770545,6.43028711 17.5941948,5.89563941 18.0582816,5.70523781 C18.5215161,5.51483621 19.0571242,5.73243804 19.2478678,6.19484193 C19.4386114,6.65724582 19.2206187,7.19189352 18.7573842,7.38229512 C18.6390207,7.42734523 18.5215161,7.45454545 18.4125198,7.45454545 C18.048908,7.45454545 17.712567,7.24629397 17.5677981,6.89269753 L17.5677981,6.892691 Z" />
          <Path d="M15.7742565,4.20971059 C15.4189475,3.85560776 15.4189475,3.2742254 15.7742565,2.92012257 C16.1304183,2.56601975 16.704379,2.56601975 17.0588353,2.92012257 C17.414997,3.2742254 17.414997,3.85560776 17.0588353,4.20971059 C16.886306,4.38250623 16.6497161,4.47272727 16.4216623,4.47272727 C16.1850812,4.47272727 15.9570275,4.38164767 15.7742565,4.20971059 Z" />
          <Path d="M13.09587,2.43594345 C12.6307694,2.24464828 12.4131515,1.70749144 12.6043127,1.24291745 C12.7954739,0.778343469 13.3237341,0.559720417 13.796503,0.751015588 C14.2607515,0.942310758 14.4792214,1.4794676 14.2786661,1.94404158 C14.1421224,2.28990325 13.796503,2.5 13.4414894,2.5 C13.3228668,2.5 13.2051049,2.48206635 13.09587,2.43595 L13.09587,2.43594345 Z" />
          <Path d="M9.99868386,3.63636364 C10.4648983,3.63636364 10.8491449,3.98730926 10.9016586,4.43943534 L10.9077748,4.54545455 L10.9077748,9.64181818 L13.9977446,10.986036 C14.425247,11.1720401 14.6375723,11.6471467 14.5053423,12.0826819 L14.4686524,12.182338 C14.2826483,12.6098405 13.8075417,12.8221657 13.3720065,12.6899357 L13.2723504,12.6532458 L9.63598678,11.0710832 C9.3410527,10.942759 9.13872729,10.6698195 9.09740725,10.3566141 L9.08959295,10.2374784 L9.08959295,4.54545455 C9.08959295,4.0433775 9.49660681,3.63636364 9.99868386,3.63636364 Z" />
        </G>
      </G>
    </G>
  </Svg>
)
