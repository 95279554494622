import React from 'react'
import { SvgHamburger } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { noop } from 'lodash'

import { TextWithTooltip } from 'components/shared/TextWithTooltip'

export type Props = {
  groupTreatment: any
  // Don't need this on web. Maybe useful on native.
  onDragPress?: () => void
  displayHidden?: boolean
  isActive?: boolean
}

export const GroupTreatmentItem: React.FC<Props> = ({
  groupTreatment,
  onDragPress = noop,
  displayHidden,
  isActive,
}) => {
  const {
    name,
    _hidden: hidden,
    discontinued_at: isDiscontinued,
  } = groupTreatment

  if ((!displayHidden && isDiscontinued) || hidden) return null
  return (
    <View
      style={[
        styles.container,
        { backgroundColor: isActive ? 'lightgrey' : Colors.white },
      ]}
      testID={name}
    >
      <View style={styles.nameRow}>
        <TouchableOpacity style={styles.draggable} onPressIn={onDragPress}>
          <SvgHamburger />
        </TouchableOpacity>

        <TextWithTooltip style={styles.title} numberOfLines={1} title={name}>
          {name}
        </TextWithTooltip>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 4,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    width: '100%',
  },
  draggable: {
    padding: 12,
  },
  title: {
    fontFamily: Fonts.regular,
    fontSize: 16,
    flex: 1.5,
  },
  nameRow: {
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
