import { Colors } from 'constants/Colors'
import { StyleSheet } from 'react-native'

export const LIST_ITEM_HEIGHT: number = 40

export const primaryMenuHeightCalc = (options: number) =>
  LIST_ITEM_HEIGHT * options + 16

export const primaryMenuWithHeaderHeightCalc = (options: number) =>
  LIST_ITEM_HEIGHT * options + 48

export const secondaryMenuHeightCalc = (options: number) =>
  LIST_ITEM_HEIGHT + (options + 1) * 42

export const shadowStyle = {
  borderRadius: 4,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 5.84,
}

export const styles = StyleSheet.create({
  formFieldStyle: { alignItems: 'flex-start', paddingTop: 6 },
  menuCard: {
    overflow: 'hidden',
    // @ts-ignore
    boxSizing: 'border-box',
    zIndex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: Colors.white,
    ...shadowStyle,
    elevation: 5,
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingVertical: 8,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'absolute',
  },
  listItem: {
    height: LIST_ITEM_HEIGHT,
    border: 'none',
  },
})
