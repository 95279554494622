import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useConfirm } from 'src/context/confirm'
import { useOrganisation } from 'src/context/organisation'
import {
  restoreSheet as RestoreSheet,
  restoreSheetVariables as RestoreSheetVariables,
} from 'src/types/restoreSheet'

import { RESTORE_SHEET } from './graphql'

type Props = {
  sheetId: string
  sheetName: string
}

export const useRestoreSheet = ({ sheetId, sheetName }: Props) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()

  const [restoreSheet, { loading }] = useMutation<
    RestoreSheet,
    RestoreSheetVariables
  >(RESTORE_SHEET, {
    onCompleted: () => toast.success(t('sheet:restore.success')),
  })

  const confirm = useConfirm()

  const restoreFinalizedSheet = useCallback(
    () =>
      confirm({
        title: t('sheet:restore.title', {
          sheetName,
        }),
        text: t('sheet:restore.confirm', {
          sheetName,
        }),
        highlights: {
          content: [`${sheetName} sheet`],
        },
      })
        .then(() => {
          const input = {
            id: sheetId,
            organisation_id: organisationId,
          }
          return restoreSheet({ variables: { input } })
        })
        .catch(err => {
          if (err) {
            toast.error(err.message)
          }
          throw err
        }),
    [confirm, t, organisationId, sheetId, sheetName, restoreSheet],
  )

  return {
    restoreFinalizedSheet,
    loading,
  }
}
