import {
  getMinutes,
  addMinutes,
  startOfMinute,
  getHours,
  addHours,
  startOfHour,
} from 'date-fns'

/**
 * Interval scheduler with precision of one second. Can be running in the background.
 * @param {number} interval in minutes
 * @param {Function} fn function to run
 * @returns cancel function
 */
export const scheduler = (interval: number, cb: Function) => {
  // If the interval is more than 1 hour, we need to calculate the next point by hour
  const getTime = interval > 60 ? getHours : getMinutes
  const addTime = interval > 60 ? addHours : addMinutes
  const startOfTime = interval > 60 ? startOfHour : startOfMinute
  const divisor = interval > 60 ? interval / 60 : interval

  const now = startOfTime(new Date())
  let nextTriggerTime = addTime(now, divisor - (getTime(now) % divisor))

  let timerId: undefined | number | NodeJS.Timeout
  const checkTime = () => {
    timerId = setTimeout(() => {
      const now = startOfTime(new Date())
      if (now >= nextTriggerTime) {
        cb()
        nextTriggerTime = addTime(now, divisor - (getTime(now) % divisor))
      }
      checkTime()
    }, 1000)
  }
  checkTime()

  return () => {
    if (timerId) clearTimeout(timerId)
  }
}
