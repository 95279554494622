import { Action, FluidAction, Task } from '../types'
import { getIsTaskDoneOrProgress } from './taskTransitionUtils'

type GetShowBackdate = (params: {
  action?: Action | FluidAction
  task?: Task
}) => boolean
export const getShowBackdate: GetShowBackdate = ({ task, action }) => {
  if (!task || !action) return false
  if (
    action === Action.COMPLETE ||
    action === FluidAction.START ||
    action === FluidAction.STOP
  )
    return true
  if (action === Action.EDIT && getIsTaskDoneOrProgress(task?.status)) {
    return true
  }
  return false
}
