import { gql } from '@apollo/client'

const CALL_PARAMETER_TEMPLATE_FIELDS = gql`
  fragment callParameterTemplateFields on TemplateCallParameter {
    id
    name
    disabled
    user {
      id
      full_name
    }
    sites {
      id
      name
    }
  }
`

export const GET_CALL_PARAMETER_TEMPLATES = gql`
  query getTemplateCallParameters($organisation_id: String!) {
    getTemplateCallParameters(organisation_id: $organisation_id) {
      items {
        ...callParameterTemplateFields
      }
    }
  }
  ${CALL_PARAMETER_TEMPLATE_FIELDS}
`

export const GET_CALL_PARAMETER_TEMPLATES_WITH_DETAIL = gql`
  query getTemplateCallParametersWithDetail($organisation_id: String!) {
    getTemplateCallParameters(organisation_id: $organisation_id) {
      items {
        ...callParameterTemplateFields
        call_parameter_items {
          items {
            name
            id
            min
            max
            short_name
            product_id
          }
        }
      }
    }
  }
  ${CALL_PARAMETER_TEMPLATE_FIELDS}
`

export const CREATE_CALL_PARAMETER_TEMPLATE = gql`
  mutation createTemplateCallParameter(
    $input: CreateTemplateCallParameterInput!
  ) {
    createTemplateCallParameter(input: $input) {
      id
      name
      disabled
    }
  }
`

export const COPY_CALL_PARAMETER_TEMPLATE = gql`
  mutation copyTemplateCallParameter($input: CopyTemplateCallParameterInput!) {
    copyTemplateCallParameter(input: $input) {
      ...callParameterTemplateFields
    }
  }
  ${CALL_PARAMETER_TEMPLATE_FIELDS}
`

export const GET_CALL_PARAMETER_TEMPLATE = gql`
  query getTemplateCallParameter($id: ID!, $organisationId: String!) {
    getTemplateCallParameter(id: $id, organisation_id: $organisationId) {
      ...callParameterTemplateFields
      call_parameter_items {
        items {
          id
          name
          short_name
          order
          product_id
          min
          max
        }
      }
      deleted_at
    }
  }
  ${CALL_PARAMETER_TEMPLATE_FIELDS}
`

export const UPDATE_CALL_PARAMETER_TEMPLATE = gql`
  mutation updateTemplateCallParameter(
    $input: UpdateTemplateCallParameterInput!
  ) {
    updateTemplateCallParameter(input: $input) {
      ...callParameterTemplateFields
      call_parameter_items {
        items {
          name
          id
          min
          max
          short_name
        }
      }
    }
  }
  ${CALL_PARAMETER_TEMPLATE_FIELDS}
`

export const DELETE_CALL_PARAMETER_TEMPLATE = gql`
  mutation deleteTemplateCallParameter(
    $input: DeleteTemplateCallParameterInput!
  ) {
    deleteTemplateCallParameter(input: $input)
  }
`
