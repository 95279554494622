import { getOptimisticId } from 'src/utils/optimisticId'
import { TreatmentTreeNodeType } from 'types/globalTypes'
import { TreatmentGroupFields } from 'types/TreatmentGroupFields'

import { ProductInfo } from './useCreateTreatment'

export const convertProductToOptimisticTreatmentGroup: (
  product: ProductInfo,
) => TreatmentGroupFields = (product: ProductInfo) => {
  const {
    productGroupName,
    productGroupId,
    productGroupOrder,
    productId,
    productName,
    productType,
  } = product
  const optimisticTreatmentID = getOptimisticId()
  const optimisticTreatmentGroupID = getOptimisticId()

  return {
    __typename: 'Treatment',
    is_auto_completed: null,
    is_instructions_important: null,
    _hidden: null,
    is_iv_infusion: null,
    is_continuous: null,
    _optimistic: null,
    conditional: false,
    approval_status: null,
    approval_updated_by: null,
    created_at: new Date().toISOString(),
    updated_at: null,
    discontinued_at: null,
    display_states: null,
    id: optimisticTreatmentGroupID,
    parent_treatment_id: '',
    instructions: null,
    is_billable: null,
    medicine_dosage_info: null,
    fluid_dosage_info: null,
    name: productGroupName,
    node_type: TreatmentTreeNodeType.GROUP,
    order: productGroupOrder,
    product: {
      __typename: 'Product',
      custom_values: null,
      default_unit: null,
      id: productGroupId,
      is_billable: null,
      medicine_dosage_info: null,
      name: productGroupName,
      pims_mapping: null,
      track_vital: false,
      type: productType,
      is_value_required: false,
      is_notes_required: false,
      cubex_integrations: null,
      origin_id: null,
    },
    schedule: null,
    tasks: null,
    value: null,
    treatmentTasksStatus: {
      __typename: 'TreatmentTasksStatus',
      hasDue: false,
      hasMissed: false,
    },
    treatments: {
      __typename: 'ModelTreatmentConnection',
      items: [
        {
          __typename: 'Treatment',
          treatments: null,
          is_auto_completed: null,
          is_instructions_important: null,
          _hidden: null,
          is_iv_infusion: null,
          is_continuous: null,
          _optimistic: null,
          conditional: null,
          approval_status: null,
          approval_updated_by: null,
          created_at: new Date().toISOString(),
          updated_at: null,
          discontinued_at: null,
          display_states: null,
          id: optimisticTreatmentID,
          parent_treatment_id: '',
          instructions: null,
          is_billable: null,
          medicine_dosage_info: null,
          fluid_dosage_info: null,
          name: productName,
          node_type: TreatmentTreeNodeType.TREATMENT,
          order: 0,
          product: {
            __typename: 'Product',
            custom_values: null,
            default_unit: null,
            id: productId,
            is_billable: null,
            medicine_dosage_info: null,
            name: productName,
            pims_mapping: null,
            track_vital: false,
            type: productType,
            is_value_required: false,
            is_notes_required: false,
            cubex_integrations: null,
            origin_id: null,
          },
          schedule: null,
          tasks: { items: [], __typename: 'ModelTaskConnection' },
          treatmentTasksStatus: {
            __typename: 'TreatmentTasksStatus',
            hasDue: false,
            hasMissed: false,
          },
          value: null,
          cubex_integration: null,
        },
      ],
    },
    cubex_integration: null,
  }
}
