import { useQuery } from '@apollo/client'
import { GET_PROCEDURE } from '../graphql'
import { getProcedure, getProcedureVariables } from 'src/types/getProcedure'
import { formatProcedureData } from '../utils/ProcedureUtil'

export const useProcedure = (procedureId: string, organisationId: string) => {
  const { data, loading, error } = useQuery<
    getProcedure,
    getProcedureVariables
  >(GET_PROCEDURE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: procedureId,
      organisation_id: organisationId,
    },
    skip: false,
  })

  const procedure = formatProcedureData(data)

  return {
    procedure,
    loading,
    error,
  }
}
