import { useApolloClient, useMutation } from '@apollo/client'
import { TREATMENT_BASE_WITH_TASKS } from 'components/AddTreatment/graphql'
import { Task } from 'components/Task/types'
import { getTreatmentIsObservation } from 'components/Task/utils/getTreatmentInfo'
import { toast } from 'src/components/common/Toast/ToastArea'
import { useTimeResolution } from 'src/hocs/timeContext'
import {
  bulkUpdateTasks as BulkUpdateTasks,
  bulkUpdateTasksVariables as BulkUpdateTasksVariables,
} from 'src/types/bulkUpdateTasks'
import { UpdateTaskInput } from 'src/types/globalTypes'
import { TreatmentBaseWithTasks } from 'src/types/TreatmentBaseWithTasks'

import { BULK_UPDATE_TASKS } from '../graphql'

export type TaskAndInput = {
  task: Task
  taskInput: UpdateTaskInput
}
export const useBulkUpdateTasks = () => {
  const { fromToQueryDate } = useTimeResolution()
  const client = useApolloClient()
  const [bulkUpdateTasks] = useMutation<
    BulkUpdateTasks,
    BulkUpdateTasksVariables
  >(BULK_UPDATE_TASKS, {
    onError: err => {
      toast.error('Error Bulk Update Tasks')
      console.error(err) // eslint-disable-line no-console
    },
  })

  return (taskAndInputArray: TaskAndInput[]) => {
    const optimisticTasksResponse: Task[] = []
    const tasksInput: UpdateTaskInput[] = []

    taskAndInputArray.forEach(({ task, taskInput }) => {
      optimisticTasksResponse.push({
        ...task,
        ...(taskInput as Partial<Task>),
        _pending: true,
      })

      const cachedTreatment = client.readFragment<TreatmentBaseWithTasks>({
        id: `Treatment:${task.treatment_id}`,
        fragment: TREATMENT_BASE_WITH_TASKS,
        fragmentName: 'TreatmentBaseWithTasks',
        variables: fromToQueryDate,
      })

      if (getTreatmentIsObservation(cachedTreatment)) {
        taskInput.notes = taskInput.value
        taskInput.value = null
      }

      tasksInput.push(taskInput)
    })

    return bulkUpdateTasks({
      variables: {
        input: tasksInput,
      },
      optimisticResponse: {
        bulkUpdateTasks: optimisticTasksResponse,
      },
    })
  }
}
