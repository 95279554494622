// Temp Data
import { FrequencyType } from 'types/globalTypes'
import { FrequencyInput } from 'components/FrequencySelector/FrequencySelector'

export const NO_TASKS: FrequencyInput = {
  name: 'No Tasks',
  id: 'no_tasks',
  type: 'NO_TASKS',
}
export const SINGLE_TASK: FrequencyInput = {
  name: 'Single Task',
  id: 'single_task',
  type: 'SINGLE_TASK',
}

const MINUTES_IN_HOUR = 60

export const DEFAULT_INTERVAL_OPTION = {
  name: '4 hours',
  frequencies: [MINUTES_IN_HOUR * 4],
  type: FrequencyType.INTERVAL,
  id: 'every_four_hours',
}

export const INTERVAL_OPTIONS = [
  {
    name: '5m',
    frequencies: [5],
    type: FrequencyType.INTERVAL,
    id: 'every_five_minutes',
  },
  {
    name: '10m',
    frequencies: [10],
    type: FrequencyType.INTERVAL,
    id: 'every_ten_minutes',
  },
  {
    name: '15m',
    frequencies: [15],
    type: FrequencyType.INTERVAL,
    id: 'every_fifteen_minutes',
  },
  {
    name: '20m',
    frequencies: [20],
    type: FrequencyType.INTERVAL,
    id: 'every_twenty_minutes',
  },
  {
    name: '30m',
    frequencies: [30],
    type: FrequencyType.INTERVAL,
    id: 'every_thirty_minutes',
  },
  {
    name: '1 hour',
    frequencies: [60],
    type: FrequencyType.INTERVAL,
    id: 'every_one_hour',
  },
  {
    name: '1.5 hours',
    frequencies: [MINUTES_IN_HOUR * 1.5],
    type: FrequencyType.INTERVAL,
    id: 'every_one_point_five_hours',
  },
  {
    name: '2 hours',
    frequencies: [MINUTES_IN_HOUR * 2],
    type: FrequencyType.INTERVAL,
    id: 'every_two_hours',
  },
  {
    name: '2.5 hours',
    frequencies: [MINUTES_IN_HOUR * 2.5],
    type: FrequencyType.INTERVAL,
    id: 'every_two_point_five_hours',
  },
  {
    name: '3 hours',
    frequencies: [MINUTES_IN_HOUR * 3],
    type: FrequencyType.INTERVAL,
    id: 'every_three_hours',
  },
  DEFAULT_INTERVAL_OPTION,
  {
    name: '5 hours',
    frequencies: [MINUTES_IN_HOUR * 5],
    type: FrequencyType.INTERVAL,
    id: 'every_five_hours',
  },
  {
    name: '6 hours',
    frequencies: [MINUTES_IN_HOUR * 6],
    type: FrequencyType.INTERVAL,
    id: 'every_six_hours',
  },
  {
    name: '8 hours',
    frequencies: [MINUTES_IN_HOUR * 8],
    type: FrequencyType.INTERVAL,
    id: 'every_eight_hours',
  },
  {
    name: '12 hours',
    frequencies: [MINUTES_IN_HOUR * 12],
    type: FrequencyType.INTERVAL,
    id: 'every_twelve_hours',
  },
  {
    name: '24 hours',
    frequencies: [MINUTES_IN_HOUR * 24],
    type: FrequencyType.INTERVAL,
    id: 'every_twenty_four_hours',
  },
  {
    name: '36 hours',
    frequencies: [MINUTES_IN_HOUR * 36],
    type: FrequencyType.INTERVAL,
    id: 'every_thirty_six_hours',
  },
  {
    name: '48 hours',
    frequencies: [MINUTES_IN_HOUR * 48],
    type: FrequencyType.INTERVAL,
    id: 'every_forty_eight_hours',
  },
  {
    name: '72 hours',
    frequencies: [MINUTES_IN_HOUR * 72],
    type: FrequencyType.INTERVAL,
    id: 'every_seventy_two_hours',
  },
]
