import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { orderBy } from 'lodash'
import {
  FlatList,
  LayoutChangeEvent,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { format } from 'date-fns'

import { Fonts } from 'src/constants/Fonts'
import { Colors } from 'src/constants/Colors'
import { toast } from 'components/common'

import { UPDATE_WORKFLOW_ITEM } from 'components/Sheet/graphql'
import {
  updateWorkflowItem,
  updateWorkflowItemVariables,
} from 'types/updateWorkflowItem'

import { listWhiteboardWorkflows_listWhiteboardWorkflows_items_workflow_items as WorkflowItem } from 'src/types/listWhiteboardWorkflows'
import { PermissionCheckButton } from 'components/common/Permission'
import { PermissionAction } from 'types/globalTypes'

type PatientInfo = {
  criticalNotes?: string[] | null
  organisationId: string
  items: WorkflowItem[]
  isListLoading: boolean
  isActive: boolean
  setTimestamp: (timestamp: number) => void
}

const criticalNotesWidth = 120
const workflowItemWidth = 112
const workflowItemMargin = 2
const workflowItemContainerWidth = workflowItemWidth + workflowItemMargin * 2

export const WhiteboardListWorkflowItems: React.FC<PatientInfo> = React.memo(
  ({
    criticalNotes,
    items,
    organisationId,
    isListLoading,
    isActive,
    setTimestamp,
  }) => {
    const [updateWorkFlowItem] = useMutation<
      updateWorkflowItem,
      updateWorkflowItemVariables
    >(UPDATE_WORKFLOW_ITEM, {
      onError: err => {
        toast.error(err.message)
      },
    })

    const toggleItemComplete = useCallback(
      (item: WorkflowItem) => {
        let newOrder = item.order
        let completedAt: string | null = new Date().toISOString()
        if (item.completed_at) {
          // insert it to the head of unfinished items
          newOrder = Math.min(...items.map(({ order }) => order)) - 1
          completedAt = null
        }

        // trigger polling logic reset at this point (when user make changes to workflow items)
        setTimestamp(Date.now())

        updateWorkFlowItem({
          variables: {
            input: {
              id: item.id,
              organisation_id: organisationId,
              completed_at: completedAt,
              order: newOrder,
            },
          },
          optimisticResponse: {
            updateWorkflowItem: {
              id: item.id,
              completed_at: completedAt,
              order: newOrder,
              __typename: 'WorkflowItem',
            },
          },
          update: cache => {
            cache.modify({
              id: `WhiteboardWorkflowItem:${item.id}`,
              fields: {
                completed_at: () => completedAt,
                order: () => newOrder,
              },
            })
          },
        })
      },
      [items, organisationId, updateWorkFlowItem, setTimestamp],
    )

    const sortedItems = useMemo(() => {
      return orderBy(
        items,
        [
          item =>
            item.completed_at
              ? new Date(item.completed_at).getTime()
              : Number.POSITIVE_INFINITY,
          'order',
        ],
        ['asc', 'asc'],
      )
    }, [items])

    const renderItem = useCallback(
      ({ item, index }: { item: WorkflowItem; index: number }) => {
        return (
          <CheckboxItem
            index={index}
            isActive={isActive}
            isListLoading={isListLoading}
            item={item}
            sortedItems={sortedItems}
            toggleItemComplete={toggleItemComplete}
          />
        )
      },
      [isActive, isListLoading, sortedItems, toggleItemComplete],
    )

    const [containerWidth, setContainerWidth] = useState(0)
    const onContainerLayout = useCallback((e: LayoutChangeEvent) => {
      setContainerWidth(e.nativeEvent.layout.width)
    }, [])

    const getItemLayout = useCallback(
      (_data: unknown, index: number) => ({
        length: sortedItems.length,
        offset: workflowItemContainerWidth * index,
        index,
      }),
      [sortedItems],
    )

    const listRef = useRef<FlatList<WorkflowItem>>(null)
    const hasScrolledToInitPos = useRef<boolean>(false)

    useEffect(() => {
      // Move to the last uncompleted task
      if (
        !hasScrolledToInitPos.current &&
        sortedItems.length &&
        containerWidth
      ) {
        let index = sortedItems.findIndex(
          (item, idx) =>
            !!item.completed_at &&
            (idx === sortedItems.length - 1 ||
              !sortedItems[idx + 1]?.completed_at),
        )
        const maxDisplayItemsInView = Math.ceil(
          containerWidth / workflowItemContainerWidth,
        )
        // If already scroll to the end before we reach to the index place,
        // set the index to a smaller one to make sure they are all aligned across all rows.
        index =
          sortedItems.length - index < maxDisplayItemsInView
            ? sortedItems.length - maxDisplayItemsInView
            : index

        if (index > 0) {
          listRef.current?.scrollToIndex({ index })
        }
        hasScrolledToInitPos.current = true
      }
    }, [sortedItems, containerWidth])

    return (
      <View style={styles.itemsContainer}>
        <View style={styles.notesAndItems}>
          <View
            accessibilityLabel={'Critical Notes'}
            style={styles.notesHolder}
          >
            <TextWithTooltip
              style={styles.noteText}
              numberOfLines={7}
              title={criticalNotes?.join('\n') ?? ''}
            >
              {criticalNotes?.join('\n')}
            </TextWithTooltip>
          </View>
          <FlatList
            onLayout={onContainerLayout}
            ref={listRef}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            style={styles.items}
            data={sortedItems}
            extraData={isListLoading}
            renderItem={renderItem}
            getItemLayout={getItemLayout}
          />
        </View>
        <View style={styles.columnBorderSeparator} />
      </View>
    )
  },
)

WhiteboardListWorkflowItems.displayName = 'WhiteboardListWorkflowItems'

type CheckboxItemProps = {
  index: number
  isActive: boolean
  isListLoading: boolean
  item: WorkflowItem
  sortedItems: WorkflowItem[]
  toggleItemComplete: (item: WorkflowItem) => void
}

const CheckboxItem: React.FC<CheckboxItemProps> = React.memo(
  ({
    index,
    isActive,
    isListLoading,
    item,
    sortedItems,
    toggleItemComplete,
  }) => {
    const isCompleted = !!item.completed_at
    const isFirstItemIncomplete =
      !isCompleted && (index === 0 || !!sortedItems[index - 1]?.completed_at)

    const onChange = useCallback(() => {
      toggleItemComplete(item)
    }, [item, toggleItemComplete])

    const itemBoxStyles = useMemo(
      () => [
        styles.itemBox,
        isFirstItemIncomplete && styles.inCompleteFirstItem,
        isCompleted && styles.completedItem,
      ],
      [isCompleted, isFirstItemIncomplete],
    )

    const disabledStyle = useMemo(() => {
      if (Platform.OS === 'web') {
        return styles.noCursor
      }
      return styles.empty
    }, [])

    return (
      <View style={itemBoxStyles}>
        <Text style={styles.text} numberOfLines={6}>
          {item.name}
        </Text>
        <View style={styles.completeRow}>
          <View>
            {!!item.completed_at && (
              <Text style={styles.text}>
                {format(new Date(item.completed_at), 'kk:mm:ss')}
              </Text>
            )}
          </View>
          <PermissionCheckButton
            disabled={isListLoading || !isActive}
            disabledStyle={disabledStyle}
            onChange={onChange}
            checked={isCompleted}
            permissionAction={
              isCompleted ? PermissionAction.WORKFLOW_TASK__UNTICK : null
            }
          />
        </View>
      </View>
    )
  },
)

const styles = StyleSheet.create({
  notesAndItems: {
    flexDirection: 'row',
    marginLeft: 15,
    flex: 1,
  },
  completedItem: { opacity: 0.6 },
  inCompleteFirstItem: {
    borderColor: Colors.green,
    borderWidth: 3,
  },
  itemsContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  items: {
    height: '100%',
  },
  itemBox: {
    borderColor: Colors.lightGrey,
    width: workflowItemWidth,
    flex: 1,
    margin: workflowItemMargin,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    overflow: 'hidden',
    justifyContent: 'space-between',
    padding: 8,
  },
  notesHolder: {
    width: criticalNotesWidth,
    borderRadius: 8,
    backgroundColor: '#ff525229',
    marginVertical: 2,
    marginRight: 2,
  },
  noteText: {
    fontFamily: Fonts.regular,
    fontSize: 14,
    margin: 5,
    marginVertical: 10,
  },
  completeRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  remainingText: {
    fontFamily: Fonts.regular,
    fontSize: 20,
  },
  text: {
    fontFamily: Fonts.regular,
  },
  // @ts-ignore
  noCursor: { cursor: 'not-allowed' },
  empty: {},
  columnBorderSeparator: {
    width: '100%',
    borderBottomWidth: 1,
    borderColor: Colors.titleBorder,
    marginHorizontal: criticalNotesWidth + 15 + 8, // critical notes width + spacing + border radius
  },
})
