import React, { useMemo } from 'react'
import { Modal } from 'components/common/Modal'
import { Layout } from 'constants/Layout'
import { environment } from 'src/config'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { StyleProp, ViewStyle } from 'react-native/types'

type Props = {
  children: React.ReactElement
  visible: boolean
  onClose: () => void
}

const HELP_MODAL_CONTENT_WIDTH_BREAK_POINT = 550
const SMALL_SCREEN_HELP_MODAL_CONTENT_WIDTH = '80%'
const LARGE_SCREEN_HELP_MODAL_CONTENT_WIDTH = 500
const LARGE_WEB_SCREEN_DEFAULT_TOP_POSITION = 52
const LARGE_WEB_SCREEN_LEFT_GUTTER_SPACE = 658

export const HelpModalLayout: React.FC<Props> = ({
  children,
  visible,
  onClose,
}) => {
  const { width, isLargeScreen } = useBreakpoint()

  const contentStyle: StyleProp<ViewStyle> = useMemo(() => {
    const contentWidth =
      width < HELP_MODAL_CONTENT_WIDTH_BREAK_POINT
        ? SMALL_SCREEN_HELP_MODAL_CONTENT_WIDTH
        : LARGE_SCREEN_HELP_MODAL_CONTENT_WIDTH

    if (environment.isWeb && isLargeScreen) {
      return {
        width: contentWidth,
        top: LARGE_WEB_SCREEN_DEFAULT_TOP_POSITION,
        left: Layout.lg,
        transform: [
          {
            translateX: width - Layout.lg - LARGE_WEB_SCREEN_LEFT_GUTTER_SPACE,
          },
        ],
      }
    }
    return {
      width: contentWidth,
    }
  }, [isLargeScreen, width])

  return (
    <Modal visible={visible} onCloseModal={onClose} contentStyle={contentStyle}>
      {children}
    </Modal>
  )
}
