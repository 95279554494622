import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_USER_DISPLAY_NAME } from './graphql'
import { useOrganisation } from 'src/context/organisation'
import {
  getUserDisplayNameVariables,
  getUserDisplayName,
} from 'types/getUserDisplayName'

export const useGetAttendingVet = (
  id: string | undefined | null,
  isVisible = true,
) => {
  const [{ organisationId }] = useOrganisation()
  const { data } = useQuery<getUserDisplayName, getUserDisplayNameVariables>(
    GET_USER_DISPLAY_NAME,
    {
      skip: !id || !isVisible,
      variables: {
        organisationId,
        id: id!,
      },
    },
  )
  // When `skip` turns from true to false, data will be undefined.
  // To remove flicker effect we save the response into state.
  const [displayName, setDisplayName] = useState<string>()
  useEffect(() => {
    const newDisplayName = data?.getUserDisplayName?.display_name

    if (!displayName || newDisplayName !== displayName) {
      setDisplayName(newDisplayName)
    }
  }, [data?.getUserDisplayName?.display_name, displayName])
  return displayName
}
