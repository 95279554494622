// borrow from https://github.com/wbinnssmith/react-user-avatar/blob/master/index.js
const defaultColors = [
  '#2ecc71', // emerald
  '#3498db', // peter river
  '#8e44ad', // wisteria
  '#e67e22', // carrot
  '#e74c3c', // alizarin
  '#1abc9c', // turquoise
  '#2c3e50', // midnight blue
]

// List of colors we use in the template list
const departmentColors = [
  '#DDE4FF',
  '#FAE8BA',
  '#F4D3E4',
  '#CCEACE',
  '#FFCAC4',
  '#D7D8F2',
  '#FFEDD9',
  '#DDCEF5',
  '#C8EAFC',
  '#FFD7C0',
]

const sumChars = (str: string) => {
  let sum = 0
  for (let i = 0; i < str.length; i = i + 1) {
    sum += str.charCodeAt(i)
  }
  return sum
}

export enum ColorCategory {
  DEFAULT,
  TEMPLATE_LIST_DEPARTMENT,
}

/**
 * Convert string to one of the color in defaultColors
 * @param str
 */
export const stringToColor = (
  str: string,
  colorCategory: ColorCategory = ColorCategory.DEFAULT,
): string => {
  let colors = defaultColors
  switch (colorCategory) {
    case ColorCategory.TEMPLATE_LIST_DEPARTMENT:
      colors = departmentColors
      break
    default:
      break
  }
  return colors[sumChars(str) % colors.length]
}
