import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  LayoutChangeEvent,
  Pressable,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { getPatientSexTagTitle } from './utils/getPatientSex'
import { Attitude, Resuscitation } from 'src/types/globalTypes'
import { listWhiteboardPatients_listWhiteboardPatients_items_patient_consultation_locations as Consultation_location } from 'src/types/listWhiteboardPatients'
import { delay, isEqual, isNil } from 'lodash'
import { environment } from 'src/config'
import { useHeaderSwitch } from 'src/context/fullScreenSwitch'
import { useDischargePatient } from 'components/DischargePatient/useDischargePatient'
import { navigate } from 'components/Base/navigation/navigation'
import { Routes } from 'constants/Routes'
import { SheetActionFinalisedSheetsModal } from 'components/SheetActions/SheetActionFinalisedSheets'
import { useTranslation } from 'react-i18next'
import { useUpdatePatientImage } from 'components/Patient/useUpdatePatientImage'
import { ImageUploaderDialog } from 'components/common/ImageUploader/ImageUploaderDialog'
import { preventClickPropagation } from 'src/utils/preventClickPropagation'
import { getActionsPlacement } from './utils/getActionsPlacement'
import {
  DischargePatientDialog,
  WeightSection,
  ContactDetailSection,
  PresentingProblemSection,
  AttendingVetSection,
  PatientAvatar,
  PatientBreedSection,
  PatientName,
  MissedTasksIndicator,
  KebabButton,
  SheetActions,
} from './PatientListItemComponents'

export type Patient = {
  id: string
  name: string | null
  sex: string | null
  species: {
    name: string | null
  } | null
  breed: {
    name: string | null
  } | null
  attitude: Attitude | null
  avatar_url: string | null
  contact: {
    first_name: string | null
    last_name: string | null
    phone_number: string | null
  } | null
  weight: number | null
  weight_unit: string | null
  date_of_birth: string | null
  resuscitate: Resuscitation | null
  primary_location: {
    location_display: string | null
  } | null
  critical_notes: string[] | null
  consultation_id: string
  attending_vet_id: string | null
  attending_vet_display_name: string | null
  attending_vet_tech_id: string | null
  attending_vet_tech_display_name?: string | null
  presentingProblems: string
  isActive: boolean
  order: number | null
  latest_active_sheet_id: string | null
  consultation_locations: Consultation_location[]
  consultation_color: string | null
  color: string | null
  has_unapproved_sheet: boolean | null
}

type PatientInfo = {
  onPressName?: () => void
  patient: Patient
  missedTasksCount: number
  isSortByPatientOrder?: boolean
  onPressKebab: (patient: Patient) => void
}
const { isWeb } = environment

export const PATIENT_CARD_WIDTH = 432
export const PATIENT_CARD_WIDTH_TABLET = 365
const PATIENT_CARD_WIDTH_MOBILE = '100%'

export const PatientListItem: React.FC<PatientInfo> = React.memo(
  ({
    onPressName,
    patient,
    missedTasksCount,
    isSortByPatientOrder = false,
    onPressKebab,
  }) => {
    const { t } = useTranslation()
    const { isExSmallScreen, isSmallScreen, isMediumScreen } = useBreakpoint()
    const isMobileScreen = isExSmallScreen || isSmallScreen
    const isTablet = isMediumScreen
    const { isFullScreen } = useHeaderSwitch()

    const [showImageUploader, setShowImageUploader] = useState(false)
    const newObjectKeyRef = useRef<string | null>(null)

    const [showDischargeModal, setShowDischargeModal] = useState(false)
    const [showFinalisedSheetsModal, setShowFinalisedSheetsModal] =
      useState(false)
    const { dischargePatient } = useDischargePatient(patient.id)

    const [isKebabFocused, setIsKebabFocused] = useState(false)
    const { height: windowHeight } = useWindowDimensions()
    const kebabButtonRef = useRef<View>(null)
    const [actionsModalPosition, setActionsPosition] = useState(0)
    const [isActionsVisible, setIsActionsVisible] = useState(false)
    const [actionsModalHeight, setActionsModalHeight] = useState(0)

    const hasMissedTasks = !!missedTasksCount
    const color = patient.consultation_color ?? patient.color ?? undefined
    const patientSortByOrderText =
      isSortByPatientOrder && !isNil(patient.order) ? `${patient.order}` : ''

    const patientFullName = `"${patient.name}" ${
      patient?.contact?.last_name ?? ' '
    }`
    const contactFullName = `${patient?.contact?.first_name ?? ''} ${
      patient?.contact?.last_name ?? ''
    }`

    const colorStyle = useMemo(
      () => [
        styles.info,
        !!color && {
          backgroundColor: color,
        },
        hasMissedTasks && styles.missedTaskBorder,
      ],
      [color, hasMissedTasks],
    )

    const onActionsLayout = (e: LayoutChangeEvent) => {
      const { height } = e.nativeEvent.layout
      setActionsModalHeight(height)
    }

    const calculateModalPosition = useCallback(() => {
      kebabButtonRef.current?.measureInWindow((_x, y, _width, height) => {
        setActionsPosition(
          getActionsPlacement(y, height, actionsModalHeight, windowHeight),
        )
      })
    }, [actionsModalHeight, windowHeight])

    const displaySheetActions = useCallback(() => {
      setIsKebabFocused(true)

      if (isWeb) {
        setIsActionsVisible(true)
      } else {
        // This function controls iOS bottom sheet behavior in PatientList File
        onPressKebab(patient)
        delay(() => {
          setIsKebabFocused(false)
        }, 200)
      }
    }, [onPressKebab, patient])

    useEffect(() => {
      if (isWeb && isActionsVisible) {
        calculateModalPosition()
      }
    }, [calculateModalPosition, isActionsVisible])

    const dismissSheetActions = (isVisible: boolean) => {
      setIsKebabFocused(isVisible)
      setIsActionsVisible(isVisible)
    }

    const { handlePatientImageUpdate, objectKeyPrefix } =
      useUpdatePatientImage(patient)

    const handlePendingDischargePatient = useCallback(
      async (name: string, dischargeNotes: string) => {
        setShowDischargeModal(false)
        await dischargePatient(name, dischargeNotes)
        navigate(Routes.Patients, {})
      },
      [dischargePatient],
    )

    const PatientSheetActions = useMemo(
      () =>
        !isFullScreen ? (
          <>
            <KebabButton
              kebabButtonRef={kebabButtonRef}
              onPress={displaySheetActions}
              isKebabFocused={isKebabFocused}
            />
            {isWeb ? (
              <SheetActions
                isActionsVisible={isActionsVisible}
                onActionsClose={() => dismissSheetActions(false)}
                actionsPosition={actionsModalPosition}
                patient={patient}
                showDischargeModal={setShowDischargeModal}
                showFinalisedSheets={setShowFinalisedSheetsModal}
                showImageUploader={setShowImageUploader}
                onActionsLayout={onActionsLayout}
              />
            ) : null}
          </>
        ) : null,
      [
        displaySheetActions,
        isActionsVisible,
        isFullScreen,
        isKebabFocused,
        actionsModalPosition,
        patient,
      ],
    )

    const SheetActionsModals = () => (
      <Pressable onPress={preventClickPropagation}>
        {isWeb ? (
          <>
            <DischargePatientDialog
              patientId={patient.id}
              showDischargeModal={showDischargeModal}
              closeDischargeModal={() => setShowDischargeModal(false)}
              onDischarge={handlePendingDischargePatient}
            />
            <SheetActionFinalisedSheetsModal
              visible={showFinalisedSheetsModal}
              onClose={() => setShowFinalisedSheetsModal(false)}
              patientId={patient.id}
            />
          </>
        ) : null}

        <ImageUploaderDialog
          visible={showImageUploader}
          toggleDialog={() => setShowImageUploader(false)}
          objectKey={patient.avatar_url!}
          objectKeyPrefix={objectKeyPrefix}
          onChange={v =>
            handlePatientImageUpdate(v, () => {
              newObjectKeyRef.current = null
            })
          }
          newObjectKeyRef={newObjectKeyRef}
          noImageText={t('patient:view:noImageText')}
        />
      </Pressable>
    )

    return (
      <View
        accessibilityLabel={'Patient List Item'}
        style={[
          styles.container,
          isMobileScreen && styles.mobileContainer,
          isTablet && styles.tabletContainer,
        ]}
      >
        {hasMissedTasks ? <MissedTasksIndicator /> : null}
        <View style={colorStyle}>
          <View style={styles.topContentContainer}>
            <PatientAvatar
              attitude={patient.attitude}
              name={patient?.name}
              lastName={patient?.contact?.last_name}
              hasUnapprovedSheet={patient.has_unapproved_sheet}
              avatarUrl={patient.avatar_url}
              color={color}
            />
            <View style={styles.topContentRow}>
              <View style={styles.rowSubContainer}>
                <View style={styles.nameAndBreedSection}>
                  <PatientName
                    containerStyle={styles.flexShrinkContainer}
                    name={patientFullName}
                    onPressName={onPressName}
                    textStyle={styles.nameText}
                    orderNum={patientSortByOrderText}
                  />
                  <PatientBreedSection
                    breed={patient.breed}
                    species={patient.species}
                    resuscitate={patient.resuscitate}
                  />
                </View>
                {PatientSheetActions}
                <SheetActionsModals />
              </View>
              <WeightSection
                sexTagTitle={getPatientSexTagTitle(patient)}
                weight={patient.weight}
                weightUnit={patient.weight_unit}
                dateOfBirth={patient.date_of_birth}
                contactName={contactFullName}
              />
            </View>
          </View>
          <ContactDetailSection
            consultLocation={patient.consultation_locations[0]?.display}
            primaryLocation={patient.primary_location?.location_display}
            phoneNumber={patient.contact?.phone_number}
          />
          <PresentingProblemSection
            presentingProblems={patient.presentingProblems}
          />
          <AttendingVetSection
            attendingVet={patient.attending_vet_display_name}
            attendingVetTech={patient.attending_vet_tech_display_name}
          />
        </View>
        <View style={styles.patientBorderSeparator} />
      </View>
    )
  },
  isEqual, // Warning, risky but worthwhile. see: https://react.dev/reference/react/memo#specifying-a-custom-comparison-function
)

PatientListItem.displayName = 'PatientListItem'

const styles = StyleSheet.create({
  info: {
    flexGrow: 1,
    flexShrink: 1,
    height: 'auto',
    justifyContent: 'space-between',
    padding: 8,
    paddingLeft: 24,
    borderRadius: 8,
  },
  topContentContainer: {
    flexDirection: 'row',
  },
  container: {
    width: PATIENT_CARD_WIDTH,
    paddingTop: 2,
  },
  mobileContainer: {
    width: PATIENT_CARD_WIDTH_MOBILE,
  },
  missedTaskBorder: {
    borderLeftWidth: 16,
    borderLeftColor: Colors.Contents.negative,
    paddingLeft: 8,
  },
  nameAndBreedSection: {
    flex: 1,
    paddingRight: 2,
  },
  topContentRow: {
    flex: 1,
    paddingLeft: 12,
  },
  flexShrinkContainer: {
    flexShrink: 1,
  },
  rowSubContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 4,
  },
  nameText: {
    ...Typography.Heading.M,
    flexShrink: 1,
  },
  tabletContainer: {
    width: PATIENT_CARD_WIDTH_TABLET,
  },
  patientBorderSeparator: {
    borderBottomWidth: 1,
    borderColor: Colors.Borders.secondary,
    marginHorizontal: 8, // border Radius
    paddingBottom: 2,
  },
})
