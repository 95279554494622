import { useCallback } from 'react'
import { toast } from 'components/common'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import { DELETE_SHEET } from './graphql'
import {
  deleteSheet as DeleteSheet,
  deleteSheetVariables as DeleteSheetVariables,
} from 'types/deleteSheet'
import { GET_SHEETS } from 'components/Patient/graphql'
import { useConfirm } from 'src/context/confirm'

type Props = {
  sheetName: string
  sheetId: string
  patientId: string
}

export const useDeleteSheet = ({
  sheetId,
  patientId,
  sheetName = '', // default '' to remove i18n warning
}: Props) => {
  const confirm = useConfirm()
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()

  const [_deleteSheet, { loading }] = useMutation<
    DeleteSheet,
    DeleteSheetVariables
  >(DELETE_SHEET, {
    refetchQueries: [
      {
        query: GET_SHEETS,
        variables: {
          organisationId,
          id: patientId,
        },
      },
    ],
  })

  const deleteSheet = useCallback(
    () =>
      confirm({
        title: t('sheet:delete.title'),
        text: t('sheet:delete.confirm', { sheetName }),
      })
        .then(() =>
          _deleteSheet({
            variables: {
              input: {
                organisation_id: organisationId,
                patient_id: patientId,
                id: sheetId,
              },
            },
          }),
        )
        .then(({ data }) => {
          if (data && !data.deleteSheet) {
            throw new Error(t('sheet:delete.failed'))
          }
          toast.success(t('sheet:delete.success'))
        })
        .catch(err => {
          if (err) {
            toast.error(err.message)
          }
          throw err
        }),
    [_deleteSheet, confirm, organisationId, patientId, sheetId, sheetName, t],
  )

  return {
    deleteSheet,
    loading,
  }
}
