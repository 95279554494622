export enum WeightUnit {
  UG = 'ug',
  MCG = 'mcg',
  MG = 'mg',
  G = 'g',
  KG = 'kg',
  LB = 'lb',
  OZ = 'oz',
  // Following WeightUnits cannot convert to standard unit.
  MEQ = 'mEq',
  IU = 'IU',
  ML = 'mL',
  MMOL = 'mmol',
}

export const SpecialWeightUnits = [WeightUnit.ML]

export const StandardWeightUnits = [
  WeightUnit.UG,
  WeightUnit.MCG,
  WeightUnit.MG,
  WeightUnit.G,
  WeightUnit.KG,
  WeightUnit.LB,
  WeightUnit.OZ,
]

export const NonStandardWeightUnits = [
  WeightUnit.MEQ,
  WeightUnit.IU,
  WeightUnit.ML,
  WeightUnit.MMOL,
]

export const weightFactor: { readonly [key in WeightUnit]: number } = {
  [WeightUnit.KG]: 1000000000,
  [WeightUnit.G]: 1000000,
  [WeightUnit.MG]: 1000,
  [WeightUnit.UG]: 1,
  [WeightUnit.MCG]: 1,
  [WeightUnit.LB]: 453592000,
  [WeightUnit.OZ]: 28349500,
  // Following WeightUnits cannot convert to standard unit.
  [WeightUnit.ML]: 1,
  [WeightUnit.MEQ]: 1,
  [WeightUnit.IU]: 1,
  [WeightUnit.MMOL]: 1,
}
