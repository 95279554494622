import {
  NonStandardWeightUnits,
  SpecialWeightUnits,
  WeightUnit,
  StandardWeightUnits,
} from 'constants/Weight'

export const isNonStandardWeightUnits = (unit: WeightUnit | string): boolean =>
  NonStandardWeightUnits.includes(unit as WeightUnit)

export const isStandardWeightUnits = (unit: WeightUnit | string): boolean =>
  StandardWeightUnits.includes(unit as WeightUnit)

export const isSpecialDosageWeightUnits = (
  unit: WeightUnit | string,
): boolean => SpecialWeightUnits.includes(unit as WeightUnit)
