import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  View,
  Modal as NativeModal,
  Animated,
  StyleSheet,
  Dimensions,
  ScrollView,
} from 'react-native'
import { useWebOutsideClick } from 'src/hooks/useOutsideClick'

import { ListMeasurements, NestedListSelectOptionsMenuProps } from './types'
import { useResizeSubscription } from './useResizeSubscription'
import { LIST_ITEM_HEIGHT, shadowStyle } from './styles'

export const NestedListSelectMenu: React.FC<
  NestedListSelectOptionsMenuProps
> = ({
  actions,
  state,
  primaryMenu,
  secondaryMenu,
  multiSelectMenu = false,
}) => {
  const { setMenuOpen } = actions
  const { isMenuOpen, secondaryMenuParentId } = state
  const containerRef = useRef<View>(null)
  const menuRef = useRef<View>(null)
  const [windowHeight, setWindowHeight] = useState(0)

  const [listMeasurements, setListMeasurements] = useState<ListMeasurements>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    px: 0,
    py: 0,
  })

  const handleLayoutChange = useCallback(() => {
    const wHeight = Dimensions.get('window').height
    setWindowHeight(wHeight)
    containerRef?.current?.measure((x, y, width, height, px, py) => {
      setListMeasurements({ x, y, width, height, px, py })
    })
  }, [])

  useResizeSubscription(handleLayoutChange)

  const [fadeAnim] = useState(new Animated.Value(0))
  useEffect(() => {
    state.isMenuOpen ? fadeAnim.setValue(0) : fadeAnim.setValue(1)
    Animated.timing(fadeAnim, {
      toValue: state.isMenuOpen ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start()
  }, [fadeAnim, state.isMenuOpen])

  const [resizeAnim] = useState(new Animated.Value(0))
  useEffect(() => {
    !!state.secondaryMenuParentId
      ? resizeAnim.setValue(0)
      : resizeAnim.setValue(1)
    Animated.timing(resizeAnim, {
      toValue: !!state.secondaryMenuParentId ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start()
  }, [resizeAnim, state.secondaryMenuParentId])

  const realMenuHeight = !!state.secondaryMenuHeaderTitle
    ? LIST_ITEM_HEIGHT * (state.secondaryMenuOptions.length + 1)
    : LIST_ITEM_HEIGHT * state.secondaryMenuOptions.length ||
      LIST_ITEM_HEIGHT * state.primaryMenuOptions.length

  const isMenuOverHigh = realMenuHeight >= windowHeight - listMeasurements.py

  const complementHeight = 10
  const showPrimaryHeight = isMenuOverHigh
    ? windowHeight - listMeasurements.py - complementHeight
    : realMenuHeight + complementHeight

  const showSecondaryHeight = isMenuOverHigh
    ? // there is 'Back' button on the top and we need to leave a gap on the bottom
      windowHeight - listMeasurements.py - complementHeight
    : secondaryMenu.props.heightCalc(state.mostRecentSecondaryMenuLength)

  const shadowOnBottom = !multiSelectMenu
    ? shadowStyle
    : !state.secondaryMenuParentId
    ? shadowStyle
    : null

  const isMultiSecondary = multiSelectMenu && state.secondaryMenuParentId

  const getModalStyle = useCallback(() => {
    return {
      ...styles.centeredView,
      ...shadowOnBottom,
      width: listMeasurements.width,
      left: listMeasurements.px,
      top: listMeasurements.py,
      opacity: fadeAnim,
      height: resizeAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [showPrimaryHeight, showSecondaryHeight],
      }),
    }
    /* disabling the exhaustive-deps linter to add secondaryMenuParentId as a dep.
    secondaryMenuParentId is sometimes updated with the menu closed, so this is
    necessary to make sure the container height is updated accordingly.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listMeasurements.width,
    listMeasurements.px,
    listMeasurements.py,
    fadeAnim,
    resizeAnim,
    state.secondaryMenuParentId,
    state.mostRecentSecondaryMenuLength,
    state.primaryMenuOptions.length,
    showPrimaryHeight,
    showSecondaryHeight,
  ])

  /* this section handles dynamically generated styles and animations, 
  including menu placement on the screen */
  useWebOutsideClick({
    ref: menuRef,
    callback: () => setMenuOpen(false),
  })

  if (isMenuOpen)
    return (
      <View ref={containerRef} onLayout={handleLayoutChange}>
        <NativeModal transparent={true}>
          <Animated.View ref={menuRef} style={getModalStyle()}>
            <ScrollView
              scrollEnabled={isMenuOverHigh}
              style={[
                styles.scrollViewStyle,
                isMultiSecondary ? { paddingBottom: 10 } : null,
              ]}
            >
              {!!secondaryMenuParentId ? secondaryMenu : primaryMenu}
            </ScrollView>
          </Animated.View>
        </NativeModal>
      </View>
    )

  return null
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    position: 'absolute',
  },
  scrollViewStyle: {
    width: '100%',
  },
})
