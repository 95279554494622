import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  FormLabel,
  SelectDateTimePicker,
  SwitchInput,
  treatmentHeight,
} from 'components/common'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, View, Text } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { FormData } from 'src/components/TreatmentForm'
import { useOrganisation } from 'src/context/organisation'
import { usePatientById } from 'src/hooks/usePatient'
import { useStartTime } from 'src/hooks/useStartTime'
import {
  getProductGroups as GetProductGroups,
  getProductGroupsVariables,
} from 'src/types/getProductGroups'
import { getTemplate_getTemplate } from 'src/types/getTemplate'
import { useGetWorkflowTemplates } from 'src/utils/useGetWorkflowTemplates'
import * as Yup from 'yup'
import { GET_PRODUCT_GROUPS } from './graphql'
import { ProductName } from './ProductName'
import { SelectableTreatmentRow } from './SelectableTreatmentRow'
import { TemplateTreatmentFormContainer } from './TemplateTreatmentFormContainer'
import {
  FormDataTemplate,
  getInitialTemplateTreatment,
  getInitialValuesV2,
  GroupNameHash,
  TemplateTreatmentFormInput,
} from './templateUtilFns'
import { TemplateDuplicationIdHash } from './useTemplateDuplication'
import { environment } from 'src/config'
import { getBackgroundColor } from 'components/Treatment/Treatment'
import { TreatmentStateDisplay } from 'components/Treatment/common/types'
import { Typography } from 'src/design-system/theme'
import { orderBy } from 'lodash'

export const defaultValues = {
  startTime: 'now' as 'now' | Date,
  applyWorkflow: true,
  templateTreatmentFormInputs: [] as TemplateTreatmentFormInput[],
}

export type Props = {
  defaultOverrides?: Partial<FormDataTemplate>
  handleBackOverrides: Dispatch<SetStateAction<(() => void) | null>>
  isAddTemplateToNewSheet?: boolean
  onSave: (data: FormDataTemplate) => void
  patientId: string
  submitting?: boolean
  submitTitle: string
  template: getTemplate_getTemplate
  templateDuplicationIdHash: TemplateDuplicationIdHash
  templateWorkflowId?: string | null
}

const validationSchema = yupResolver(
  Yup.object().shape({
    templateTreatmentFormInputs: Yup.array().test(
      'NoSelect',
      '',
      (templateTreatmentFormInputs: TemplateTreatmentFormInput[]) =>
        templateTreatmentFormInputs.some(
          templateTreatmentFormInput =>
            templateTreatmentFormInput.shouldCreateTreatment,
        ),
    ),
  }),
)

export const AddTemplateToSheetForm: React.FC<Props> = ({
  defaultOverrides = {},
  handleBackOverrides,
  isAddTemplateToNewSheet = false,
  onSave,
  patientId,
  submitting = false,
  submitTitle,
  template,
  templateDuplicationIdHash,
  templateWorkflowId,
}) => {
  const [selectedTreatmentFormInput, setSelectedTreatmentFormInput] =
    useState<TemplateTreatmentFormInput | null>(null)
  const patient = usePatientById(patientId)

  const [{ organisationId }] = useOrganisation()
  const { isWeb } = environment
  const backgroundColor = getBackgroundColor(TreatmentStateDisplay.GroupDisplay)
  const templateName = template.name

  useEffect(() => {
    // when click back button clear the selected form input
    if (selectedTreatmentFormInput) {
      handleBackOverrides(() => () => setSelectedTreatmentFormInput(null))
    } else {
      handleBackOverrides(null)
    }
  }, [handleBackOverrides, selectedTreatmentFormInput])

  const { data: groupData } = useQuery<
    GetProductGroups,
    getProductGroupsVariables
  >(GET_PRODUCT_GROUPS, {
    variables: { id: organisationId },
  })

  const groupNameHash = useMemo(
    () =>
      groupData?.getOrganisation?.products?.items?.reduce(
        (groupNameHash, product) => {
          groupNameHash[product.id] = {
            name: product.name,
            order: product.order,
          }
          return groupNameHash
        },
        {} as GroupNameHash,
      ) ?? {},
    [groupData?.getOrganisation?.products?.items],
  )

  const startTime = useStartTime()

  const templateTreatmentFormInputs = useMemo(() => {
    const firstTreatmentGroup = template.treatments?.items?.find(treatment => {
      if ('treatments' in treatment) {
        return treatment.treatments
      }
      return
    })
    const templateTreatments =
      (firstTreatmentGroup &&
        'treatments' in firstTreatmentGroup &&
        firstTreatmentGroup?.treatments?.items) ||
      []
    return getInitialTemplateTreatment({
      templateTreatments,
      groupNameHash,
      startTime,
      templateDuplicationIdHash,
    })
  }, [
    template.treatments?.items,
    groupNameHash,
    startTime,
    templateDuplicationIdHash,
  ])

  const { t } = useTranslation()
  const initialValues = getInitialValuesV2(
    { ...defaultValues, startTime, templateTreatmentFormInputs },
    defaultOverrides,
  )

  const { handleSubmit, control, formState, getValues, setValue, watch } =
    useForm({
      mode: 'onChange',
      defaultValues: initialValues,
      resolver: validationSchema,
    })
  const watchedStartTime = watch('startTime', initialValues.startTime)

  const onSaveTreatment = useCallback(
    (templateTreatmentId: string) => (formData: FormData) => {
      const templateTreatmentFormInputs = getValues(
        'templateTreatmentFormInputs',
      )
      if (!templateTreatmentFormInputs) {
        return
      }
      const updatedInputIndex = templateTreatmentFormInputs.findIndex(
        input => input.templateTreatmentId === templateTreatmentId,
      )

      if (updatedInputIndex === -1) {
        return
      }

      const templateTreatmentFormInput =
        templateTreatmentFormInputs[updatedInputIndex]

      if (!templateTreatmentFormInput.createTreatmentFromInput?.product) {
        return
      }

      templateTreatmentFormInput.name = formData.treatmentName

      const newTreatmentInput = {
        ...templateTreatmentFormInput,
        createTreatmentFromInput: {
          defaultOverrides: formData,
          product: templateTreatmentFormInput.createTreatmentFromInput?.product,
          isSetup: true,
        },
      }

      // update the treatment input from the array
      templateTreatmentFormInputs.splice(
        updatedInputIndex,
        1,
        newTreatmentInput,
      )

      setValue('templateTreatmentFormInputs', templateTreatmentFormInputs)
      setSelectedTreatmentFormInput(null)
    },
    [getValues, setValue],
  )

  // Note: We're using the treatment indexes from the unsorted/raw form inputs for map values.
  // This is because we're iterating through the raw form inputs when returning a controlled component.
  const sheetGroupTreatmentMap = useMemo(() => {
    const map: { [sheetGroup: string]: number[] } = {}
    const currentTreatmentFormInputs = getValues('templateTreatmentFormInputs')
    const sortedTreatmentFormInputs = orderBy(currentTreatmentFormInputs, [
      'sheetGroup.order',
    ])

    sortedTreatmentFormInputs.forEach(treatment => {
      const groupName = treatment.sheetGroup.name || templateName
      const originalTreatmentIdx =
        currentTreatmentFormInputs!.indexOf(treatment)

      if (!map[groupName]) {
        map[groupName] = []
      }
      map[groupName].push(originalTreatmentIdx)
    })
    return map
  }, [getValues, templateName])

  const { workflowTemplatesList, isLoadingTemplates } =
    useGetWorkflowTemplates()

  const linkedWorkflowTemplate = useMemo(() => {
    return workflowTemplatesList?.find(template => {
      const selectedTemplateId = template.id
      return selectedTemplateId === templateWorkflowId
    })
  }, [workflowTemplatesList, templateWorkflowId])

  const { isValid } = formState

  const startAtDateOverrides = useMemo(() => {
    const isSetup =
      selectedTreatmentFormInput?.createTreatmentFromInput?.isSetup
    return isSetup ? {} : { startAtDate: watchedStartTime }
  }, [
    selectedTreatmentFormInput?.createTreatmentFromInput?.isSetup,
    watchedStartTime,
  ])

  if (isLoadingTemplates || !patient) {
    return <ActivityIndicator size="large" style={styles.spinner} />
  }

  if (selectedTreatmentFormInput?.createTreatmentFromInput) {
    return (
      <TemplateTreatmentFormContainer
        createTreatmentFromInput={
          selectedTreatmentFormInput.createTreatmentFromInput
        }
        defaultOverrides={startAtDateOverrides}
        patient={patient}
        onSave={onSaveTreatment(selectedTreatmentFormInput.templateTreatmentId)}
        isAddTemplateToNewSheet={isAddTemplateToNewSheet}
      />
    )
  }

  const sheetGroupHeader = (groupName: string) => (
    <View style={[{ backgroundColor }, styles.groupContainer]}>
      <Text style={Typography.SubHeading.M}>{groupName}</Text>
    </View>
  )

  const renderButton = () => (
    <Button
      a11yLabel="Add Template to Sheet Button"
      disabled={!isValid}
      loading={submitting}
      onPress={handleSubmit(onSave)}
      style={
        isAddTemplateToNewSheet
          ? styles.addButtonForCreatingSheet
          : styles.addButton
      }
      testID="SaveTreatmentButton"
      title={submitTitle}
    />
  )

  return (
    <>
      <ProductName productName={templateName} />
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        extraHeight={-64} // set to tabBarBottom height const
        keyboardOpeningTime={0}
      >
        <>
          <FormLabel text={t('addTreatment:addTemplateToSheet.startAt')} />
          <Controller
            control={control}
            name="startTime"
            render={({ field: { onChange, value } }) => (
              <SelectDateTimePicker
                onChange={onChange}
                type="now"
                value={value}
                title={t('addTreatment:schedule:startsLabel')}
              />
            )}
          />
          {!!templateWorkflowId && (
            <View style={styles.workflowTemplateHolder}>
              <FormLabel
                text={t('addTreatment:addTemplateToSheet.workflowTitle')}
              />
              <Controller
                control={control}
                name="applyWorkflow"
                render={({ field: { onChange, value } }) => (
                  <SwitchInput
                    label={linkedWorkflowTemplate?.name}
                    value={!!value}
                    onChangeValue={active => {
                      onChange(active)
                    }}
                  />
                )}
              />
            </View>
          )}
          <View>
            <FormLabel
              text={t('addTreatment:addTemplateToSheet.treatmentList')}
            />

            {Object.keys(sheetGroupTreatmentMap).map(
              (currentSheetGroup, groupIdx) => (
                <Fragment key={groupIdx}>
                  {sheetGroupHeader(currentSheetGroup)}
                  <Controller
                    control={control}
                    name="templateTreatmentFormInputs"
                    render={({
                      field: { onChange, value: formTreatments },
                    }) => (
                      <>
                        {formTreatments
                          ? sheetGroupTreatmentMap[currentSheetGroup].map(
                              treatmentIdx => (
                                <SelectableTreatmentRow
                                  key={treatmentIdx}
                                  treatment={formTreatments[treatmentIdx]}
                                  onChange={onChange}
                                  templateTreatmentFormInputs={formTreatments}
                                  index={treatmentIdx}
                                  onSetup={setSelectedTreatmentFormInput}
                                />
                              ),
                            )
                          : null}
                      </>
                    )}
                  />
                </Fragment>
              ),
            )}
          </View>
        </>
      </KeyboardAwareScrollView>
      {isWeb ? (
        renderButton()
      ) : (
        <View
          style={isAddTemplateToNewSheet ? styles.footerForCreatingSheet : null}
        >
          {renderButton()}
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  footerForCreatingSheet: {
    marginBottom: 35,
  },
  addButton: {
    marginVertical: 16,
  },
  addButtonForCreatingSheet: {
    marginTop: 15,
    marginBottom: 15,
  },
  spinner: {
    marginTop: 25,
  },
  workflowTemplateHolder: {
    paddingVertical: 16,
  },
  groupContainer: {
    height: treatmentHeight,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
  },
})
