/* istanbul ignore file */
import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { Routes } from 'src/constants/Routes'
import { Header } from 'src/components/Header/Header'
import { Account } from '../Account'
import { withDestroyOnBlur } from 'src/hocs/withDestroyOnBlur'

export const accountStackScreensPath = {
  [Routes.Account]: '',
}

const accountStackScreens = {
  [Routes.Account]: withDestroyOnBlur(Account),
}

export const AccountStack = () => {
  const MyStack = createStackNavigator()
  return (
    <>
      <Header />
      <MyStack.Navigator screenOptions={{ headerShown: false }}>
        {Object.entries(accountStackScreens).map(([name, component]) => (
          <MyStack.Screen name={name} component={component} key={name} />
        ))}
      </MyStack.Navigator>
    </>
  )
}
