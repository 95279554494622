import { KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'

import { getUniqueKeyArgsFunction } from './getUniqueKeyArgsFunction'
import { whiteboardPatientItemsMerge } from 'src/apollo/cache/patientList'

//  Cache separate results when these keyArgs change
export const listWhiteboardPatientsMergeKeyArgs: KeyArgsFunction = (
  _,
  context,
) => {
  if (!context.variables) {
    return 'listWhiteboardPatientsMergeKeyArgs'
  }

  return getUniqueKeyArgsFunction('listWhiteboardPatientsMergeKeyArgs')(
    context.variables,
  )
}

export const listWhiteboardPatients = {
  // Cache separate results based on keyArgs
  keyArgs: listWhiteboardPatientsMergeKeyArgs,
  merge: whiteboardPatientItemsMerge,
}
