import { useMutation } from '@apollo/client'
import { UPDATE_PATIENT } from 'components/Patient/graphql'
import { NumericInput, toast } from 'components/common'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import {
  updatePatient as UpdatePatient,
  updatePatientVariables,
} from 'src/types/updatePatient'
import { useUpdatePatientListCache } from 'src/components/Patient/useUpdatePatientListCache'
import {
  View,
  StyleSheet,
  Pressable,
  GestureResponderEvent,
} from 'react-native'

type Props = {
  patientId: string
  currentPatientOrder?: number | null
}

const preventClickPropagation = (e: GestureResponderEvent) => {
  e.stopPropagation()
  e.preventDefault()
}
export const SheetActionPatientOrder: React.FC<Props> = ({
  patientId,
  currentPatientOrder = null,
}) => {
  const { t } = useTranslation()
  const [selectedPatientOrder, setSelectedPatientOrder] = useState<
    number | null
  >(currentPatientOrder)
  const patientOrderRef = useRef<number | null>()
  patientOrderRef.current = selectedPatientOrder

  const [{ organisationId }] = useOrganisation()

  const [updatePatient] = useMutation<UpdatePatient, updatePatientVariables>(
    UPDATE_PATIENT,
  )
  const updatePatientListCache = useUpdatePatientListCache()

  const handleOrderUpdate = async (
    currentPatientOrder: number | null,
    updatedPatientOrder?: number | null,
  ) => {
    if (currentPatientOrder === updatedPatientOrder) return
    try {
      await updatePatient({
        variables: {
          input: {
            id: patientId,
            organisation_id: organisationId,
            order: patientOrderRef.current,
          },
        },
        update: (_, { data: updateData }) => {
          if (!updateData?.updatePatient) return
          updatePatientListCache(updateData?.updatePatient, 'UPDATE')
        },
      })

      toast.success(t('patient:action.order.toastMessage'))
    } catch (error) {
      if (error instanceof Error) toast.error(error.message)
    }
  }

  /* the new value must be saved when the menu is closed. This useEffect is
  used to trigger the update function when this component unmounts, meaning the 
  modal (on web) or bottom sheet (iOS) is closed.
  */
  useEffect(
    () => () => {
      handleOrderUpdate(currentPatientOrder, patientOrderRef.current)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <Pressable onPress={preventClickPropagation}>
      <View style={styles.container}>
        <NumericInput
          label={t('patient:view.order')}
          onChange={setSelectedPatientOrder}
          value={selectedPatientOrder}
          shouldInteger={true}
          maxLength={9}
        />
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: { width: '100%', position: 'relative' },
})
