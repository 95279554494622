import { SelectDateTimePicker } from 'components/common'
import { WeightUnit } from 'src/constants/Weight'
import { ApprovalStatus, ProductType } from 'src/types/globalTypes'
import { getOneProduct_getProduct as OneProduct } from 'src/types/getOneProduct'

import {
  AdminMethod,
  ConcentrationSetting,
  CRITimeUnit,
  RepeatScheduleValue,
  VolumeUnit,
} from './data'
import { FrequencyInput } from 'components/FrequencySelector/FrequencySelector'

type SelectDateTimePickerValueType = React.ComponentProps<
  typeof SelectDateTimePicker
>['value']

type CreateTaskOption = {
  createTask: boolean
}

type BaseInputs = {
  treatmentName: string
  productType: Exclude<ProductType, ProductType.IVFLUIDS>
  routesOfAdmin: string
  dosageRate: number | null
  dosageWeightUnit: WeightUnit
  dosagePerWeightUnit: WeightUnit
  totalVolume: number | null
  totalVolumeUnit: VolumeUnit | null
  totalDosage: number | null
  patientWeight: number
  patientWeightUnit: WeightUnit
  concentration: number | null
  concentrationWeightUnit: WeightUnit
  concentrationVolumeUnit: VolumeUnit
  isDiluted: boolean
  isContinuous: boolean
  administrationMethod: AdminMethod
  requiredRatioLHS: number | null
  requiredRatioRHS: number | null
  medicationVolume: number | null
  medicationVolumeUnit: VolumeUnit
  salineVolume: number | null
  salineVolumeUnit: VolumeUnit
  dilutedConcentration: number | null
  dilutedConcentrationWeightUnit: WeightUnit
  dilutedConcentrationVolumeUnit: VolumeUnit
  concentrationSetting: ConcentrationSetting | null
  unitsBilledPerTask: number | null
  reschedule: boolean
  isBillable: boolean
  isAutoCompleted: boolean
  instructions: string
  isInstructionsImportant: boolean
  isCRI: boolean
  fluidVolume: number | null
  fluidVolumeUnit: VolumeUnit
  infusionRate: number | null
  infusionRateVolumeUnit: VolumeUnit
  infusionRateWeightUnit: WeightUnit
  infusionRateTimeUnit: CRITimeUnit
  doseRateTimeUnit: CRITimeUnit
  infusionRateTotal: number | null
  infusionRateTotalVolumeUnit: VolumeUnit
  infusionRateTotalTimeUnit: string
  minutes: number
  hours: number
  days: number
  ivBagSize: number | null
  ivBagSizeUnit: VolumeUnit
  doseRate: number | null
  diluentUsed: string | null
  isAdditionalSaline: boolean
  additionalSaline: number | null
  option?: CreateTaskOption
} & ScheduleFields

// 🚨 Every time we change schedule related fields, the omit array in handleOnSave should be updated accordingly
export type ScheduleFields = {
  isRepeating: RepeatScheduleValue
  repeating: number
  repeatUntilDate: Exclude<SelectDateTimePickerValueType, 'now' | 'edit'>
  startAtDate: Exclude<SelectDateTimePickerValueType, 'discharge' | 'edit'>
  timeWindow: number
  enableStaffedHour: boolean | null
  frequencyInput?: FrequencyInput
}

export type CRIOffFormData = BaseInputs & {
  isCRI: false
}
export type CRIOnFormData = BaseInputs & {
  isCRI: true
}
export type Inputs = CRIOffFormData | CRIOnFormData

export type MedicationFormProps<T = Inputs> = {
  defaultOverrides?: T
  hasPIMSIntegration?: boolean
  hideTreatmentName?: boolean
  approvalStatus?: ApprovalStatus
  isEdit?: boolean
  isNewSheet?: boolean
  onSave: (data: T) => void
  patientWeight?: number
  patientWeightUnit?: WeightUnit
  patientWeightUpdatedAt?: string | null
  product: OneProduct | Partial<OneProduct>
  showTreatmentName?: boolean
  submitting?: boolean
  submitTitle: string
  deleteTreatment?: () => void
  shouldDisableGeneralAutoCompleted?: boolean
  isIVInfusion?: boolean
  isContinuous?: boolean
  handleCreateSheetModal?: (shouldBeVisible: boolean, newDosageInfo?: T) => void
  styleForWebOriOS?: boolean
  isCubexProductBillable?: boolean
}

export enum EquationTypeChain {
  DrWD = 'DrWD',
  IrDC = 'IrDC',
  C2V2CV = 'C2V2CV',
}

export type ChangedFieldName =
  | 'patientWeight'
  | 'doseRate'
  | 'infusionRateTotal'
  | 'dosageRate'
  | 'concentration'
  | 'dilutedConcentration'
  | 'ivBagSize'
  | 'medicationVolume'

export type Locks = { [key in ChangedFieldName]?: boolean }

export type CalculatorInput = Inputs & { changedFieldName: ChangedFieldName }

export type InputsWithChain = CalculatorInput & {
  chains: EquationTypeChain[]
  hasChain: boolean
  equationTypeChain: EquationTypeChain
}

export type FluidDosageFormData = {
  treatmentName: string
  presetRateText: string | null
  productType: ProductType.IVFLUIDS
  patientWeight: number
  patientWeightUnit: WeightUnit
  isHypovolemic: boolean
  isDehydrated: boolean
  dehydration: number | null
  hoursToAdmin: number | null
  dehydrationResult: number | null
  shockTotalResult: number | null
  physiologicReq: number | null
  ongoingLosses: number | null
  totalResults: number | null
  minutes: number
  hours: number
  days: number
  IVSet: number | null
  dripRate: number | null
  startAtDate: Exclude<SelectDateTimePickerValueType, 'discharge' | 'edit'>
  instructions: string
  isInstructionsImportant: boolean
  totalUnit: string
  isRepeating: RepeatScheduleValue
  isContinuous: boolean
  sheetType?: string
  frequencyInput?: FrequencyInput
}
