import { useBulkCreateTasksMutation } from 'components/BulkTask/utils/useBulkCreateTasksMutation'
import { Task } from 'components/Task/types'
import {
  generateTaskInput,
  NewSchedule,
  TaskOmitTypename,
} from 'components/Task/utils/useCreateTask'
import { useOrganisation } from 'src/context/organisation'

export const useAnesthesiaBulkCreate = () => {
  const [{ organisationId }] = useOrganisation()

  const bulkCreateTasks = useBulkCreateTasksMutation()

  return ({
    tasks,
    newSchedule,
    sheetId,
  }: {
    tasks: TaskOmitTypename[]
    newSchedule: NewSchedule
    sheetId: string
  }) => {
    const optimisticTasks: Task[] = tasks.map(task => ({
      __typename: 'Task',
      _pending: null,
      ...task,
    }))

    const createTaskInputs = tasks.map(task =>
      generateTaskInput(task, organisationId, newSchedule),
    )

    return bulkCreateTasks({ createTaskInputs, optimisticTasks, sheetId })
  }
}
