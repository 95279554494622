import { ReleaseStage } from 'src/config/environment'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { environment } from 'src/config'

let datadogLogInitialised = false
let datadogRumInitialised = false

const ddConfigCommon = {
  clientToken: environment.datadog?.clientToken ?? '',
  env: environment.releaseStage,
  // sampleRate: 30,
  site: 'datadoghq.com',
  version: environment.appVersion,
}

const DATADOG_RUM_SERVICE_NAME = 'vet-radar-web'

export const initDatadog = () => {
  // skip init dd if locally debugging
  if (environment.releaseStage === ReleaseStage.localDev) return

  if (!datadogRumInitialised) {
    datadogRum.init({
      ...ddConfigCommon,
      applicationId: environment.datadog?.rumAppWeb ?? '',
      service: DATADOG_RUM_SERVICE_NAME,
      trackFrustrations: true,
    })

    datadogRum.onReady(() => {
      datadogRumInitialised = true
      // @ts-ignore
      datadogRum.startSessionReplayRecording()
    })
  }

  if (!datadogLogInitialised) {
    datadogLogs.init({
      ...ddConfigCommon,
      forwardErrorsToLogs: true,
    })
    // ddLogs to console when dev locally, +feature flag'd w/ `initFlagsmith`
    datadogLogs.logger.setHandler(environment.isProduction ? 'http' : 'console')
    datadogLogs.onReady(() => {
      // try to only init once, but a warning for multiple init should be OK
      datadogLogInitialised = true
    })
  }
}

export const ddLogs = datadogLogs
export const ddLogger = datadogLogs.logger
export const ddRum = datadogRum
