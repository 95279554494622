import React, { useMemo } from 'react'
import { InProgressBg } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { noop } from 'lodash'
import {
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { isOptimisticId } from 'src/utils/optimisticId'
import { Status } from 'types/globalTypes'

import { treatmentHeight } from '../common'
import { getTaskBoxColor } from './TaskActionBox'
import { TaskButtonContent } from './TaskButtonContent'

export type ParameterRange = { min: number | null; max: number | null }

type Props = {
  comment?: string | null
  id?: string
  isPending?: boolean | null
  isDiscontinued?: boolean | null
  isPendingApproval?: boolean | null
  isBilled?: boolean | null
  onPress?: (event: GestureResponderEvent) => void
  status: Status | null
  value?: string
  width?: number
  disabled?: boolean
  highlightColor?: string | null
  range?: ParameterRange
}

export const getButtonState = (status: Status | null) => {
  let extraStyle: keyof TaskBoxStyle | undefined
  let a11yLabel: string | undefined
  let a11yHint: string | undefined

  switch (status) {
    case Status.PENDING:
      extraStyle = 'taskBoxPending'
      a11yLabel = 'Pending Task'
      a11yHint = 'Press to Reschedule a pending task'
      break
    case Status.DUE:
      extraStyle = 'taskBoxDue'
      a11yLabel = 'Due Task'
      a11yHint = 'Press to enter a value for a due task'
      break
    case Status.MISSED:
      extraStyle = 'taskBoxMissed'
      a11yLabel = 'Missed Task'
      a11yHint = 'Press to complete or reschedule a missed task'
      break
    case Status.MISSED_ON_PURPOSE:
      extraStyle = 'taskBoxMissedOnPurpose'
      a11yLabel = 'Skipped Task'
      a11yHint = 'Press to complete or reschedule a skipped task'
      break
    case Status.DONE: {
      a11yLabel = 'Completed Task'
      a11yHint = 'Press to amend the value for a completed task'
      extraStyle = 'taskBoxCompleted'
      break
    }
    case Status.IN_PROGRESS: {
      a11yLabel = 'In Progress Task'
      a11yHint = 'Press to amend the value for a in progress task'
      extraStyle = 'taskBoxInProgress'
      break
    }
    // We don't typically render DOP tasks unless debug feature flag on
    case Status.DELETED_ON_PURPOSE: {
      a11yLabel = 'Deleted on Purpose Task'
      a11yHint = ''
      extraStyle = 'taskBoxDeletedOnPurpose'
      break
    }
  }
  return { extraStyle, a11yHint, a11yLabel }
}

export const Task: React.FC<Props> = React.memo(
  ({
    comment,
    id = 'no-id',
    isPending,
    isDiscontinued,
    isPendingApproval,
    onPress = noop,
    status,
    width,
    value,
    isBilled,
    disabled,
    highlightColor,
    range,
  }) => {
    const breakpoint = useBreakpoint()

    const isAMatchstick = width && width < 20
    const isBigComment = (width && width < 50) || !breakpoint.isLargeScreen
    const isLoadingState = isPending || isOptimisticId(id)

    const { a11yHint, a11yLabel, extraStyle } = getButtonState(status)

    const StatusBar = useMemo(() => {
      return status === Status.IN_PROGRESS ? (
        <View style={styles.taskStatusIndicator}>
          <InProgressBg />
        </View>
      ) : (
        <View
          style={[
            // VR-4850: Use an positioned view instead of border width to
            // fix ios render issue, as large radius bars blur on ios.
            styles.taskStatusIndicator,
            { backgroundColor: getTaskBoxColor(status) },
          ]}
        />
      )
    }, [status])

    const taskHighlightStyle = useMemo(() => {
      return { backgroundColor: highlightColor ?? Colors.task.background }
    }, [highlightColor])

    return (
      <TouchableOpacity
        accessibilityHint={a11yHint}
        accessibilityLabel={a11yLabel}
        accessibilityRole="button"
        disabled={isLoadingState || disabled}
        onPress={onPress}
        style={styles.heightBuffer}
        testID={`Task - ${status} - ${id}`}
      >
        <View
          style={[
            styles.spaceAroundRow,
            styles.taskBox,
            extraStyle && taskBoxStyles[extraStyle],
            isPendingApproval && styles.pendingApprovalTask,
            taskHighlightStyle,
          ]}
        >
          <>
            {!isPendingApproval && StatusBar}

            <TaskButtonContent
              comment={comment}
              isBigComment={isBigComment}
              isLoading={!isAMatchstick && isLoadingState}
              status={status}
              value={value}
              isDiscontinued={isDiscontinued}
              isBilled={isBilled}
              range={range}
            />
          </>
        </View>
      </TouchableOpacity>
    )
  },
)

Task.displayName = 'Task'

const styles = StyleSheet.create({
  heightBuffer: {
    height: '97.5%',
    width: '97.5%',
  },
  spaceAroundRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  taskBox: {
    height: treatmentHeight,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.task.background,
    borderWidth: 2,
    borderRadius: 4,
    paddingLeft: 2, // for abs position taskStatusIndicator
    overflow: 'hidden',
    elevation: 7,
  },
  taskStatusIndicator: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0,
    width: 2,
  },
  pendingApprovalTask: {
    backgroundColor: 'transparent',
    borderStyle: 'dashed',
    borderWidth: 2,
    paddingLeft: 0,
  },
})

type TaskBoxStyle = {
  taskBoxDeletedOnPurpose: ViewStyle
  taskBoxPending: ViewStyle
  taskBoxDue: ViewStyle
  taskBoxMissed: ViewStyle
  taskBoxMissedOnPurpose: ViewStyle
  taskBoxCompleted: ViewStyle
  taskBoxInProgress: ViewStyle
}

const taskBoxStyles = StyleSheet.create<TaskBoxStyle>({
  taskBoxDeletedOnPurpose: {
    borderColor: Colors.contentTertiary,
  },
  taskBoxPending: {
    borderColor: Colors.task.pending,
  },
  taskBoxDue: {
    borderColor: Colors.task.due,
  },
  taskBoxMissed: {
    borderColor: Colors.task.missed,
  },
  taskBoxMissedOnPurpose: {
    borderColor: Colors.task.missedOnPurpose,
  },
  taskBoxCompleted: {
    borderColor: Colors.task.done,
  },
  taskBoxInProgress: {
    borderColor: Colors.task.done,
  },
})
