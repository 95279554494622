import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import {
  updateSheet as UpdateSheet,
  updateSheetVariables as UpdateSheetVariables,
} from 'src/types/updateSheet'
import {
  Button,
  FormBreak,
  FormLabel,
  Status,
  TextInput,
  toast,
} from 'components/common'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { UserSection } from 'components/CreateSheet/UsersSection'

import { DepartmentSection } from 'components/CreateSheet/DepartmentSection'

import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import {
  getSheetDetail_getSheet as SheetDetailsT,
  getSheetDetail_getSheet_consultation_presenting_problems as PresentingProblem,
} from 'types/getSheetDetail'
import * as Yup from 'yup'

import { getSheetDepartment } from '../Sheet/utils/getSheetDepartment'
import { veterinary_roles } from 'src/types/globalTypes'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import { SvgArrow } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ControlledTextInput } from 'components/common/TextInput/ControlledTextInput'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'
import { ControlledEditableText } from 'components/common/EditableText/ControlledEditableText'
import { SYNC_WITH_CONSULT_PLACEHOLDER } from 'constants/ClinicalRecord'
import { getDepartments_getSites_items } from 'src/types/getDepartments'
import { Fonts } from 'constants/Fonts'
import { omit } from 'lodash'
import { useMutation } from '@apollo/client'
import { UPDATE_SHEET } from 'components/Sheet/graphql'
import { AdditionalCareTeam } from 'components/CreateSheet/AdditionalCareTeam'
import { environment } from 'src/config/environment'
import { useSetShowTreatmentTabs } from 'src/hooks/useSetShowTreatmentTabs'

const { isWeb } = environment

const getValidationSchema = (t: TFunction) => {
  const requiredMsg = t('form.required')
  return Yup.object().shape({
    name: Yup.string()
      .nullable()
      .required(requiredMsg)
      .max(30, t('sheetForm.validationErrors.maxSheetNameValidation')),
  })
}

export type FormDataT = {
  name: string
  attending_vet: string | null
  sync_attending_vet_with_consultation?: boolean | null
  additional_care_team: string[] | null
  attending_department?: string | null
  sync_attending_department_with_consultation?: boolean | null
  attending_vet_tech: string | null
  consultation_id: string | null
}

type Props = {
  onSubmitForm: (saveForm: () => Promise<void>) => Promise<void>
  sheet: SheetDetailsT
  isSubmitting: boolean
  children?: ReactElement<any, any>[]
}

const SvgArrowRightIcon: React.FC = () => (
  <View style={styles.rightIcon}>
    <SvgArrow orient="right" color={Colors.blue} width={15} height={15} />
  </View>
)

export const navToHyperlink = (
  instanceUrl: string | null,
  originId: string | null,
) => {
  if (!instanceUrl) {
    return
  }
  let ezyVetUrl = `${instanceUrl}?recordclass=Consult&recordid=${originId}`
  const httpRegex = /^https?:\/\//
  const isCorrectUrl = httpRegex.test(ezyVetUrl)

  ezyVetUrl = isCorrectUrl ? ezyVetUrl : `https://${ezyVetUrl}`

  if (isWeb) {
    window.open(ezyVetUrl, '_blank')
    return
  }

  Linking.openURL(ezyVetUrl)
}

export const SheetDetailForm: React.FC<Props> = ({
  sheet,
  onSubmitForm,
  isSubmitting,
  children,
}) => {
  const { t } = useTranslation()

  const [{ organisationId, organisationSites, PIMSIntegration }] =
    useOrganisation()
  const { instanceUrl } = useOrganisationHook()
  // TODO: replace this with a util function once we have more than one integration
  const integration = PIMSIntegration === 'EZYVET' ? 'ezyVet' : ''
  const [updateSheet] = useMutation<UpdateSheet, UpdateSheetVariables>(
    UPDATE_SHEET,
  )
  const consult = sheet.consultation
  const presentingProblems = consult?.presenting_problems
  const consultVetId = consult?.attending_vet_id ?? null
  const consultSiteId = consult?.site_id ?? null
  const originSheetDepartmentId = sheet.attending_department
  const sheetDepartment = getSheetDepartment(consult, organisationSites)
  const originId = consult?.origin_id ?? ''
  const hasSyncWithConsultVetId = sheet.sync_attending_vet_with_consultation
  const hasSyncWithConsultSiteId =
    sheet.sync_attending_department_with_consultation
  const attending_vet = hasSyncWithConsultVetId
    ? SYNC_WITH_CONSULT_PLACEHOLDER
    : sheet.attending_vet

  const isDefaultToSyncConsultSiteId =
    hasSyncWithConsultSiteId && consultSiteId === originSheetDepartmentId

  const attending_department = isDefaultToSyncConsultSiteId
    ? SYNC_WITH_CONSULT_PLACEHOLDER
    : originSheetDepartmentId

  const initialValues = {
    attending_vet,
    attending_department,
    additional_care_team: sheet.additional_care_team,
    sync_attending_vet_with_consultation: hasSyncWithConsultVetId,
    sync_attending_department_with_consultation: hasSyncWithConsultSiteId,
    attending_vet_tech: sheet.attending_vet_tech,
    consultation_id: sheet.consultation_id,
    department: sheetDepartment,
    name: sheet.name,
    presenting_problems: presentingProblems,
  }
  const isFinalised = !!sheet.closed_at

  const { handleSubmit, control, formState, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(getValidationSchema(t)),
  })

  const selectedAttendingDepartmentId = watch('attending_department')

  useEffect(() => {
    setValue(
      'sync_attending_department_with_consultation',
      isDefaultToSyncConsultSiteId,
    )
  }, [setValue, isDefaultToSyncConsultSiteId])

  const handleSelectedUserChange = (value: string | null) => {
    setValue('attending_vet', value, { shouldDirty: true })
    setValue(
      'sync_attending_vet_with_consultation',
      value === SYNC_WITH_CONSULT_PLACEHOLDER,
    )
  }

  const [isConsultInSeparationSites, setIsConsultInSeparationSites] =
    useState(true)

  const handleConsultInSeparationSites = useMemo(() => {
    return (separationSites: getDepartments_getSites_items[]) => {
      setIsConsultInSeparationSites(
        !!separationSites.find(({ id }) => id === consultSiteId),
      )
    }
  }, [consultSiteId])

  const handleSelectedDepartmentChange = (value: string | null) => {
    if (
      !isConsultInSeparationSites &&
      value === SYNC_WITH_CONSULT_PLACEHOLDER
    ) {
      toast.error(t('sheetForm.sameAsEzyvetDeptError'))
      return
    }

    setValue('attending_department', value, { shouldDirty: true })
    setValue(
      'sync_attending_department_with_consultation',
      value === SYNC_WITH_CONSULT_PLACEHOLDER,
    )
  }

  const { touchedFields, isDirty, errors } = formState

  const onSheetFormSave = (formData: FormDataT) => {
    return updateSheet({
      variables: {
        input: {
          id: sheet.id,
          organisation_id: organisationId,
          ...omit(formData, ['department', 'presenting_problems']),
          consultation_id: formData.consultation_id ?? '',
          attending_vet: formData.sync_attending_vet_with_consultation
            ? sheet?.consultation?.attending_vet_id
            : formData.attending_vet,
          attending_department:
            formData.sync_attending_department_with_consultation
              ? sheet?.consultation?.site_id
              : formData.attending_department,
        },
      },
    })
  }

  const submitForm = async () => {
    if (isDirty) {
      return handleSubmit(onSheetFormSave)()
    }
  }

  const consultIdOptions = [
    {
      value: consult?.id ?? '',
      text:
        (consult?.origin_reference || consult?.origin_id || consult?.id) ?? '',
    },
  ]
  useSetShowTreatmentTabs()

  return (
    <>
      <ScrollView style={{ flex: 4 }}>
        <Status
          status={createErrorStatus(errors.name?.message, touchedFields.name)}
        >
          <ControlledEditableText
            control={control}
            name="name"
            label={t('sheetForm.name')}
            disabled={isFinalised}
          />
        </Status>
        <FormBreak />
        <ControlledTextInput
          control={control}
          name="department"
          accessibilityLabel={t('sheetForm.deptAccessibility', {
            department: initialValues.department,
          })}
          disabled={true}
          label={t('patient:view.consultation_dept')}
        />
        <Controller
          control={control}
          defaultValue={initialValues.attending_department}
          render={({ field: { value } }) => (
            <DepartmentSection
              onSelectedDepartmentChange={handleSelectedDepartmentChange}
              selectedDepartment={value}
              disabled={isFinalised}
              handleConsultInSeparationSites={handleConsultInSeparationSites}
              originSheetDepartmentId={originSheetDepartmentId}
            />
          )}
          name="attending_department"
        />
        <UserSection
          type={veterinary_roles.VET}
          selectedUser={consultVetId}
          disabled={true}
          isOwner={true}
          hideChevron={true}
        />
        <Controller
          control={control}
          defaultValue={initialValues.attending_vet}
          render={({ field: { value } }) => (
            <UserSection
              type={veterinary_roles.VET}
              onSelectedUserChange={handleSelectedUserChange}
              selectedUser={value}
              disabled={isFinalised}
              allowClear={false}
              hasSyncWithConsultVetId={true}
              consultVetId={consultVetId}
              sheetAttendingDepartmentId={
                hasSyncWithConsultSiteId
                  ? consultSiteId
                  : selectedAttendingDepartmentId
              }
            />
          )}
          name="attending_vet"
        />
        <Controller
          control={control}
          defaultValue={initialValues.attending_vet_tech}
          render={({ field: { onChange, value } }) => (
            <UserSection
              type={veterinary_roles.VETERINARY_TECH}
              onSelectedUserChange={onChange}
              selectedUser={value}
              disabled={isFinalised}
            />
          )}
          name="attending_vet_tech"
        />
        <AdditionalCareTeam control={control} name={'additional_care_team'} />
        <TouchableOpacity onPress={() => navToHyperlink(instanceUrl, originId)}>
          <ControlledSelect
            control={control}
            disabled={true}
            label={t(
              integration
                ? 'sheetForm.clinicalRecord'
                : 'sheetForm.clinicalRecordWithoutIntegration',
              { integration },
            )}
            options={consultIdOptions}
            name="consultation_id"
            hideChevron={true}
            iconRight={<SvgArrowRightIcon />}
            textStyle={styles.hyperLinkText}
          />
        </TouchableOpacity>

        <Controller
          control={control}
          defaultValue={initialValues.presenting_problems}
          name={'presenting_problems'}
          render={({ field: { value } }) => {
            if (!value?.length) {
              return <View />
            }

            return (
              <>
                <View>
                  <FormLabel text={t('sheetForm.presenting')} />
                </View>
                <View>
                  {value.map((problem: PresentingProblem, idx: number) => (
                    <TextInput
                      accessibilityLabel={`${problem.name} presenting problem name`}
                      disabled={true}
                      key={idx}
                      value={problem.name}
                    />
                  ))}
                </View>
              </>
            )
          }}
        />

        <FormBreak />
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>{t('editSheet.treatmentSheets')}</Text>
        </View>
        {children}
        <FormBreak />
      </ScrollView>
      <Button
        loading={isSubmitting}
        onPress={() => onSubmitForm(submitForm)}
        style={styles.submitBtn}
        testID="SaveSheetButton"
        title={t('general.saveChanges')}
        disabled={!!Object.keys(errors).length}
      />
    </>
  )
}

const styles = StyleSheet.create({
  rightIcon: { padding: 16 },
  hyperLinkText: { color: Colors.blue, textDecorationLine: 'underline' },
  submitBtn: {
    marginVertical: 20,
  },
  titleContainer: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 16,
  },
  titleText: {
    fontFamily: Fonts.regular,
    fontSize: 20,
  },
})
