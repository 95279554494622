import React, { useMemo } from 'react'
import { MaterialIcons } from '@expo/vector-icons'
import { VolumeUnit } from 'components/TreatmentForm/data'
import {
  calculateDosageML,
  getNormedConcentration,
  getNormedDosage,
  getNormedTotalVolume,
  getNormedWeight,
} from 'components/TreatmentForm/utils/getCalculations'
import { Colors } from 'constants/Colors'
import { WeightUnit } from 'constants/Weight'
import { identity, isNil } from 'lodash'
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { getTemplate_getTemplate_treatments_items_treatments_items as TreatmentItem } from 'src/types/getTemplate'

export enum EmergItemState {
  LOADING = 'loading',
  NORMAL = 'normal',
  EMPTY = 'empty',
}

type Props = {
  addNewTreatment: any
  addingItemId?: string
  isInSheetScreen?: boolean
  patientWeight: number | null
  round?: (num: number) => number
  state: EmergItemState
  treatment: TreatmentItem
  weightUnit: string
}

const EMERG_DRUG_HEIGHT = 70
export const ADD_BTN_SIZE = 33

export const EmergencyDrugItem: React.FC<Props> = ({
  addNewTreatment,
  addingItemId = '',
  isInSheetScreen = false,
  patientWeight,
  state = EmergItemState.NORMAL,
  round = identity,
  treatment,
  weightUnit,
}) => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isMobile = isExSmallScreen || isSmallScreen
  const isLoading = state === EmergItemState.LOADING

  const isAddingItem = treatment.id === addingItemId
  const shouldShowIndicator = isLoading && isAddingItem

  const drugName = treatment.name
  const drugDoseInfo = treatment.medicine_dosage_info

  const drugDose = drugDoseInfo?.dosage ?? 0

  const doseUnit = (drugDoseInfo?.dosage_weight_unit ?? '') as WeightUnit
  const patientWeightUnit = (drugDoseInfo?.dosage_patient_weight_unit ??
    '') as WeightUnit

  const drugProduct = treatment.treatment_options?.products
  const productDosageInfo = drugProduct?.[0].medicine_dosage_info ?? null

  const isDiluted = productDosageInfo?.is_diluted ?? false
  const dilutedConcentration = productDosageInfo?.diluted_concentration ?? 0
  const concentration = productDosageInfo?.concentration ?? 0

  const concentrationWeightUnit =
    productDosageInfo?.concentration_weight_unit as WeightUnit
  const concentrationVolumeUnit =
    productDosageInfo?.concentration_volume_unit as VolumeUnit

  const doseText = useMemo(() => {
    if (!drugDose || !doseUnit || !patientWeightUnit) return ''
    return `${drugDose}${doseUnit}/${patientWeightUnit}`
  }, [drugDose, doseUnit, patientWeightUnit])

  const returnTotalVolume = () => {
    if (doseUnit === WeightUnit.ML) {
      return calculateDosageML({
        patientWeight,
        patientWeightUnit: weightUnit,
        dosageRate: drugDose,
        dosageWeightUnit: doseUnit,
        dosagePerWeightUnit: patientWeightUnit,
        round,
      })
    }

    if (
      isNil(dilutedConcentration) ||
      isNil(concentration) ||
      isNil(concentrationWeightUnit) ||
      isNil(concentrationVolumeUnit)
    )
      return ''

    const hours = 1

    const normedDosage = getNormedDosage(drugDose, doseUnit, patientWeightUnit)
    const normedWeight = getNormedWeight(
      patientWeight ?? 0,
      weightUnit as WeightUnit,
    )

    const normedConcentration = getNormedConcentration(
      isDiluted ? dilutedConcentration : concentration,
      concentrationWeightUnit,
      concentrationVolumeUnit,
    )
    const normedTotalVolume = getNormedTotalVolume(
      normedDosage,
      normedWeight,
      normedConcentration,
    )
    if (normedTotalVolume === null) {
      return null
    }

    const totalVolume = round(normedTotalVolume * hours)
    return totalVolume
  }

  return (
    <View style={styles.treatmentItemContainer}>
      {isInSheetScreen ? (
        <TouchableOpacity
          disabled={shouldShowIndicator}
          style={[styles.addDrugBtn, isMobile && { marginRight: 0 }]}
          onPress={() => {
            const totalVolume = returnTotalVolume()
            addNewTreatment(treatment, totalVolume)
          }}
        >
          {shouldShowIndicator ? (
            <ActivityIndicator size={isMobile ? 'small' : 'large'} />
          ) : (
            <MaterialIcons
              name="add-circle"
              size={isMobile ? 30 : 36}
              color={Colors.green}
            />
          )}
        </TouchableOpacity>
      ) : null}
      <View
        style={[
          styles.treatmentItemInfoContainer,
          isMobile && { width: '90%' },
          !isInSheetScreen && { width: '95%' },
        ]}
      >
        <View style={[styles.drugHeader, { flex: 3 }]}>
          <Text
            style={[styles.drugHeaderText, isMobile && styles.drugMobileText]}
          >
            {drugName}
          </Text>
        </View>
        <View style={[styles.drugHeader, { flex: 2 }]}>
          <Text
            style={[styles.drugHeaderText, isMobile && styles.drugMobileText]}
          >
            {doseText}
          </Text>
        </View>
        <View style={[styles.drugHeader, { flex: 1 }]}>
          <Text
            style={[styles.drugHeaderText, isMobile && styles.drugMobileText]}
          >
            {returnTotalVolume()}
          </Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  drugHeader: {
    height: EMERG_DRUG_HEIGHT,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.lightGrey,
    justifyContent: 'center',
    alignItems: 'center',
  },
  drugHeaderText: {
    fontSize: 14,
    fontWeight: '500',
    alignItems: 'center',
    margin: 5,
  },
  drugMobileText: {
    fontSize: 12,
  },
  addDrugBtn: {
    height: ADD_BTN_SIZE,
    width: ADD_BTN_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
  },
  treatmentItemContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  treatmentItemInfoContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    height: EMERG_DRUG_HEIGHT,
    alignItems: 'center',
    width: '86%',
  },
})
