import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import { keyBy } from 'lodash'
import { filterNull } from 'src/utils/notEmpty'

import { GET_SETTINGS } from 'components/Settings/graphql'

import {
  getSettings as GetSettings,
  getSettingsVariables as GetSettingsVariables,
} from 'types/getSettings'

export const DEFAULT_ROUNDING_PRECISION = 2
export const DEFAULT_ENABLE_SYNC_WEIGHT = 'true'
export const DEFAULT_ANESTHESIA_CHART_MAX_RANGE = 200

// Copy from platform code
export enum OrgSettingKeys {
  TIMEZONE = 'TIMEZONE', // timezone of the organisation / hospital / user
  TIME_FORMAT = 'TIME_FORMAT', // timeformat displayed to the user
  TEMPERATURE_UNIT = 'TEMPERATURE_UNIT', // metric / imperial
  WEIGHT_UNIT = 'WEIGHT_UNIT', // metric / imperial
  BUFFER_DUE = 'BUFFER_DUE', // number in minutes
  BUFFER_MISSED = 'BUFFER_MISSED', // number in minutes
  TIME_WINDOW = 'TIME_WINDOW', // default time to start task
  DURATION = 'DURATION', // default task length
  DELETED_TASKS_VISIBLE = 'DELETED_TASKS_VISIBLE', // Whether we return DELETED_ON_PURPOSE tasks to front-end
  PREFERRED_TIME_FORMAT = 'PREFERRED_TIME_FORMAT', // Preferred time format
  PREFERRED_DATE_FORMAT = 'PREFERRED_DATE_FORMAT', // Preferred date format
  ROUNDING_PRECISION = 'ROUNDING_PRECISION', // Rounding precision for calculation numbers
  TREATMENT_LEAF_DOSAGE_INFO_TYPE = 'TREATMENT_LEAF_DOSAGE_INFO_TYPE', // doseRate / totalDosage / totalVolume
  ANAESTHESIA_AUDIBLE_ALERT_DEFAULT = 'ANAESTHESIA_AUDIBLE_ALERT_DEFAULT', // Anaesthesia audible alert default (off/on)
  ANAESTHESIA_CHART_MAX_DEFAULT = 'ANAESTHESIA_CHART_MAX_DEFAULT', // Default range limit of anesthesia chart
  AUTO_LOGOUT_TIMEOUT = 'AUTO_LOGOUT_TIMEOUT', // timeout for auto logout
  TASK_EDITING_TIMEOUT = 'TASK_EDITING_TIMEOUT', // timeout for editing tasks after completion
  ENABLE_OPERATING_HOURS = 'ENABLE_OPERATING_HOURS', // for controlling if staffed hours are enabled.
  ENABLE_SYNC_WEIGHT = 'ENABLE_SYNC_WEIGHT', // for sync patient weight from ezyVet on admit.
  ENABLE_CUBEX_CABINET = 'ENABLE_CUBEX_CABINET', // for cubex cabinet from Cubex.
  ENABLE_TRAINING_BANNER = 'ENABLE_TRAINING_BANNER', // If the site should display the training mode banner for users until dismissed
}

type Setting = {
  key: OrgSettingKeys
  value: string
  __typename: any
}
export type SettingsDict = {
  [key in OrgSettingKeys]: Setting
}

export const useOrgSettings = () => {
  const [{ organisationId }] = useOrganisation()

  const { loading, data } = useQuery<GetSettings, GetSettingsVariables>(
    GET_SETTINGS,
    {
      fetchPolicy: 'cache-first',
      variables: {
        id: organisationId,
      },
    },
  )

  const organisation = data?.getOrganisation
  const name = organisation?.name ?? ' '
  const permissions = organisation?.permissions ?? []
  const staffed_hours = organisation?.staffed_hours
  const routeOfAdministrations = organisation?.route_of_administrations

  const settings = filterNull(
    data?.getOrganisation?.settings ?? [],
  ) as Setting[]

  if (
    !settings.find(setting => setting.key === OrgSettingKeys.ROUNDING_PRECISION)
  ) {
    settings.push({
      key: OrgSettingKeys.ROUNDING_PRECISION,
      value: DEFAULT_ROUNDING_PRECISION.toString(),
      __typename: 'KeyValue',
    })
  }

  if (
    !settings.find(
      setting => setting.key === OrgSettingKeys.ANAESTHESIA_CHART_MAX_DEFAULT,
    )
  ) {
    settings.push({
      key: OrgSettingKeys.ANAESTHESIA_CHART_MAX_DEFAULT,
      value: DEFAULT_ANESTHESIA_CHART_MAX_RANGE.toString(),
      __typename: 'KeyValue',
    })
  }

  if (
    !settings.find(setting => setting.key === OrgSettingKeys.ENABLE_SYNC_WEIGHT)
  ) {
    settings.push({
      key: OrgSettingKeys.ENABLE_SYNC_WEIGHT,
      value: DEFAULT_ENABLE_SYNC_WEIGHT,
      __typename: 'KeyValue',
    })
  }

  const settingsMap = useMemo(
    () => keyBy(settings, 'key'),
    [settings],
  ) as SettingsDict

  return {
    loading,
    organisation,
    permissions,
    name,
    settings,
    settingsMap,
    staffed_hours,
    routeOfAdministrations,
  }
}
