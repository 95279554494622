import React, { ChangeEvent } from 'react'
import Switch from '@material-ui/core/Switch'
import { noop } from 'lodash'
import { SwitchInputBody } from './SwitchInputBody'
import { Props } from '.'

export const SwitchInput: React.FC<Props> = props => {
  const {
    autoFocus = false,
    disabled = false,
    value,
    onChangeValue = noop,
    ...restProps
  } = props

  return (
    <SwitchInputBody {...restProps}>
      <Switch
        autoFocus={autoFocus}
        disabled={disabled}
        checked={!!value}
        inputProps={{ 'aria-label': `${props.label} Switch Input` }}
        onChange={(_: ChangeEvent, checked: boolean) => onChangeValue(checked)}
      />
    </SwitchInputBody>
  )
}
