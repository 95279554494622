import { useApolloClient, useMutation } from '@apollo/client'

import { useOrganisationHook } from 'src/hooks/useOrganisation'
import {
  runEzyVetDataImportTaskFromApp,
  runEzyVetDataImportTaskFromAppVariables,
} from 'src/types/runEzyVetDataImportTaskFromApp'
import { RUN_EZYVET_DATA_IMPORT_FROM_APP } from '../graphql'
import { UpdateDataSyncLogs } from './UpdateDataSyncLogs'

interface IRunEzyvetDataImportFromApp {
  onClickDataImport: () => void
  loading: boolean
}

export const useRunEzyvetDataImportFromApp =
  (): IRunEzyvetDataImportFromApp => {
    const { organisationId, integrationId } = useOrganisationHook()

    const client = useApolloClient()

    const [runEzyVetDataImport, { loading }] = useMutation<
      runEzyVetDataImportTaskFromApp,
      runEzyVetDataImportTaskFromAppVariables
    >(RUN_EZYVET_DATA_IMPORT_FROM_APP)

    const onClickDataImport = () => {
      if (integrationId) {
        runEzyVetDataImport({
          variables: {
            input: {
              organisation_id: organisationId,
              integration_id: integrationId,
              timestamp: new Date().getTime().toString(),
            },
          },

          onCompleted: data => {
            const newDataSyncLog = data.runEzyVetDataImportTaskFromApp
            if (newDataSyncLog) {
              UpdateDataSyncLogs(client, organisationId, newDataSyncLog)
            }
          },
        })
      }
    }

    return {
      onClickDataImport,
      loading,
    }
  }
