import React from 'react'
import { View, StyleSheet } from 'react-native'
import { InvalidPasswordSvg } from 'src/design-system/components/Icon/InvalidPasswordIcon'
import { Paragraph } from 'src/design-system/components/Text'
import { Colors } from 'src/design-system/theme/colors'

export const InvalidStatus: React.FC<{ status: string }> = ({ status }) => {
  return (
    <View style={styles.container}>
      <InvalidPasswordSvg color={Colors.Contents.negative} />
      <Paragraph size="S" style={styles.text}>
        {status}
      </Paragraph>
    </View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center', gap: 8 },
  text: { color: Colors.Contents.negative },
})
