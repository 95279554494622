import React, { useMemo } from 'react'
import { AvatarWithMessage } from 'components/common/Avatar/AvatarWithMessage'
import { PhoneLinking } from 'components/common/PhoneLinking'
import { PatientTag } from 'components/PatientItem/PatientItemComponents/PatientTag'
import { getAlertLevelAndAttitude } from 'components/PatientItem/utils/getAlertLevelAndMessage'
import { getPatientSexTagTitle } from 'components/PatientItem/utils/getPatientSex'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useKeyToUrl } from 'src/hooks/useKeyToUrl'
import { getPatient_getPatient as GetPatientData } from 'types/getPatient'
import { Shadows } from 'constants/Shadows'
import { WeightAgeTextV2 } from 'components/PatientItem/PatientItemComponents/WeightAgeTextV2'
import { useTranslation } from 'react-i18next'
import { getPatientBreedAndSpecies } from 'components/PatientItem/utils/getPatientBreedAndSpecies'
import { useBreakpoint } from 'src/hocs/breakpoint'

type PatientInfoProps = {
  patient: GetPatientData
  onPress?: () => void
}
const InfoSection: React.FC<{
  patient: GetPatientData
  sexTagTitle: string
}> = ({ patient, sexTagTitle }) => {
  return (
    <View style={[styles.weight, styles.infoSection]}>
      <WeightAgeTextV2
        patientWeight={`${patient.weight ?? '-'} `}
        patientWeightUnit={patient.weight_unit ?? ''}
        dateOfBirth={patient.date_of_birth}
        sexTagTitle={sexTagTitle}
      />
      <PatientTag patient={patient} useV2={true} />
    </View>
  )
}

export const PrimaryInfo: React.FC<PatientInfoProps> = ({
  patient,
  onPress,
}: PatientInfoProps) => {
  const { t } = useTranslation()
  const patientImage = useKeyToUrl(patient?.avatar_url, 160)
  const { alertLevel, attitude } = getAlertLevelAndAttitude(patient)
  const sexTagTitle = getPatientSexTagTitle(patient)
  const patientColor =
    patient.active_consultations?.items?.[0]?.color?.hex || Colors.white
  const phoneNumber = patient?.contact?.phone_number
  const breedAndSpecies = getPatientBreedAndSpecies(patient)
  const { isLargeScreen } = useBreakpoint()

  const primaryInfoTouchableStyle = useMemo(
    () => [{ backgroundColor: patientColor }, styles.patientInfoCard],
    [patientColor],
  )

  return (
    <TouchableOpacity style={primaryInfoTouchableStyle} onPress={onPress}>
      <View style={[styles.imageAndBreed, styles.infoSection]}>
        <AvatarWithMessage
          alertLevel={alertLevel}
          message={attitude}
          name={`${patient.name} ${patient.contact?.last_name ?? ''}`}
          uri={patientImage}
          size={56}
          avatarContainer={styles.avatarContainer}
        />
        <View style={styles.breedContainer}>
          <Text style={[styles.breedText, styles.regularText]}>
            {breedAndSpecies}
          </Text>
          {!isLargeScreen && (
            <InfoSection patient={patient} sexTagTitle={sexTagTitle} />
          )}
        </View>
      </View>

      {isLargeScreen ? (
        <InfoSection patient={patient} sexTagTitle={sexTagTitle} />
      ) : null}

      <Text
        accessibilityLabel={t('patientPanel:primaryInfo.contact.label')}
        style={[styles.regularText, styles.infoSection]}
      >
        {`${patient?.contact?.first_name ?? ''} ${
          patient?.contact?.last_name ?? ''
        }`}{' '}
        {!!phoneNumber && (
          <PhoneLinking phoneNumber={phoneNumber}>
            <Text
              accessibilityLabel={t('patientPanel:primaryInfo.phone.label')}
              style={[styles.phoneNumber, styles.regularText]}
            >{`${phoneNumber}`}</Text>
          </PhoneLinking>
        )}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  patientInfoCard: {
    borderRadius: 6,
    width: '100%',
    padding: 8,
    flexDirection: 'column',
    ...Shadows.cardShadow,
    marginBottom: 4,
  },
  imageAndBreed: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  avatarContainer: {
    marginRight: 8,
  },
  breedContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  breedText: {
    color: Colors.contentPrimary,
  },
  weight: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  phoneNumber: {
    color: Colors.contentPrimary,
  },
  infoSection: {
    marginVertical: 4,
  },
  regularText: {
    fontSize: 14,
    fontFamily: Fonts.semibold,
  },
})
