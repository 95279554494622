import { useEffect } from 'react'
import { useSound } from 'src/hooks/useSound'
import { useIsAlertOn } from './useIsAlertOn'
import { scheduler } from 'src/utils/scheduler'
import { useTimeResolution } from 'src/hocs/timeContext'
import { TimeSegmentMinutes } from 'src/hocs/types/time'

export const useSheetAlert = (sheetId: string, isAnesthesia: boolean) => {
  const { timeSegment } = useTimeResolution()
  const [isAlertOn] = useIsAlertOn(sheetId)
  const sound = useSound('alert')

  useEffect(() => {
    if (!sound || !isAlertOn || !isAnesthesia) {
      return
    }
    const stop = scheduler(TimeSegmentMinutes[timeSegment], () => {
      sound.replayAsync()
    })
    return stop
  }, [sound, isAlertOn, isAnesthesia, timeSegment])
}
