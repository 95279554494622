import React, { useCallback, useMemo } from 'react'
import { useNavigation } from '@react-navigation/native'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native'

import { SubHeader } from 'components/SubHeader/SubHeader'
import { Button } from 'components/common'
import { DataSyncStatus } from 'components/common/DataSyncStatus'
import { DataTableBody } from 'components/common/DataTable/TableBody'
import { DataTableHead } from 'components/common/DataTable/TableHead'
import { Routes } from 'constants/Routes'
import { Colors } from 'src/design-system/theme/colors'
import { Primitive } from 'src/design-system/theme/colors/base'
import {
  FontFamilies,
  FontSizes,
  FontWeights,
} from 'src/design-system/theme/typography/base'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { DataSyncLogStatus } from 'src/types/globalTypes'
import { useDataImportSubscription } from './useDataImportSubscription'
import { useGetDataSyncLogs } from './useGetDataSyncLogs'
import { useRunEzyvetDataImportFromApp } from './useRunEzyvetDataImportFromApp'

export type IntegrationTableRow = {
  name: string
  id: string
  organisationId?: string
}

export type IDataSyncLog = {
  id: string
  organisation_id: string
  integration_id: string
  timestamp: number
  status: DataSyncLogStatus
  error_info: string | null
  completed_time: string | null
  sync_by: string | null
}

const TWENTYMINS = 1000 * 60 * 20

export const DataSyncScreen: React.FC = () => {
  const { navigate } = useNavigation()
  const { t } = useTranslation()
  const { dateFormatter } = useAdminTimeFormat()

  const { onClickDataImport, loading: submitting } =
    useRunEzyvetDataImportFromApp()

  const { data, loading } = useGetDataSyncLogs()

  const navigateSettings = useCallback(
    () => navigate(Routes.Settings),
    [navigate],
  )

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: navigateSettings,
  }

  // A list of the five most recent records from the data sync logs.
  const filterDataSyncLogs: IDataSyncLog[] = useMemo(() => {
    return (data?.getDataSyncLogs.items ?? [])
      .map(dataSyncLog => {
        const timestampToNumber = parseInt(dataSyncLog.timestamp, 10)
        const newObject = { ...dataSyncLog, timestamp: timestampToNumber }
        return newObject
      })
      .sort((a, b) => b.timestamp - a.timestamp)
      .slice(0, 5)
  }, [data?.getDataSyncLogs.items])

  const shouldDisableButton =
    loading ||
    submitting ||
    filterDataSyncLogs.some(item => {
      const now = Date.now()
      const isLessTwentyMins = now - item.timestamp < TWENTYMINS
      return isLessTwentyMins && item.status === DataSyncLogStatus.PENDING
    })

  useDataImportSubscription()

  const debounceOnClick = useMemo(
    () => debounce(onClickDataImport, 1000),
    [onClickDataImport],
  )

  const statusTag = useMemo(
    () => (status: string) => <DataSyncStatus status={status} />,
    [],
  )

  return (
    <>
      <SubHeader
        backButton={backButton}
        headlineKey={t('settings:dataSync.title')}
      />

      <View style={styles.buttonAndMsg}>
        <Button
          a11yLabel="Data Sync Button"
          onPress={debounceOnClick}
          style={styles.addButton}
          color={Colors.Buttons.positive}
          title={t('settings:dataSync.button')}
          disabled={shouldDisableButton}
        />
        {!!shouldDisableButton && (
          <Text style={styles.syncMessage}>
            {t('settings:dataSync.message.processing')}
          </Text>
        )}
      </View>
      {submitting || loading || !filterDataSyncLogs ? (
        <ActivityIndicator size="large" style={styles.spinner} />
      ) : (
        <View style={styles.historyTable}>
          <Text style={styles.historyText}>
            {t('settings:dataSync.history')}
          </Text>
          <View style={styles.history}>
            <View style={styles.tableCellContainer}>
              <View style={styles.tableCell}>
                <DataTableHead title={t('settings:dataSync.time')} />
                <DataTableHead title={t('settings:dataSync.user')} />
                <DataTableHead title={t('settings:dataSync.status')} />
              </View>
            </View>
            {filterDataSyncLogs.map(item => {
              return (
                <View key={item.id} style={styles.tableCellContainer}>
                  <View style={styles.tableCell}>
                    <DataTableBody
                      content={dateFormatter(
                        new Date(item.timestamp),
                        'timeweekdaymonthyear',
                      )}
                    />
                    <DataTableBody content={item.sync_by ?? 'user'} />
                    <DataTableBody content={statusTag(item.status)} />
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  spinner: {
    marginTop: 25,
  },
  buttonAndMsg: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  addButton: {
    marginTop: 24,
    marginBottom: 5,
  },
  syncMessage: {
    color: Primitive.orange700,
    paddingBottom: 8,
  },
  history: {
    alignItems: 'center',
  },
  historyText: {
    fontSize: FontSizes.lg,
    fontFamily: FontFamilies.bold,
    fontWeight: FontWeights.medium,
    marginLeft: '16%',
    paddingBottom: 10,
  },
  historyTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableCellContainer: {
    width: '68%',
    backgroundColor: Primitive.blue100,
  },
  tableCell: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
