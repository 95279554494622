import React from 'react'
import {
  treatmentGroupHeight,
  treatmentHeight,
  treatmentHeightLarge,
} from 'components/common'
import { DosageInfoType } from 'components/Treatment/TreatmentTree'
import { identity, noop } from 'lodash'
import { StyleSheet, View } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { TreatmentTreeNodeType } from 'src/types/globalTypes'
import { getPatient_getPatient } from 'types/getPatient'

import { getRowA11yLabel } from './common'
import {
  TreatmentChildLevel,
  TreatmentSheet,
  TreatmentStateDisplay,
} from './common/types'
import { TreatmentLeaf } from './TreatmentLeaf'
import { TreatmentNode } from './TreatmentNode'
import { getIsTreatmentFullHeight } from 'components/Task/utils/getTreatmentInfo'
import { StripedStatusBorder } from 'components/Icons/StripedStatusBorder'

export type NestingDepth = 0 | 1

export type TaskStateCounts = {
  hasDue: boolean
  hasMissed: boolean
}

export enum GroupTreatmentDisplayState {
  Has_Due = 'HAS_DUE',
  Has_Missed = 'HAS_MISSED',
}

export type TreatmentProps = {
  dosageInfoType?: DosageInfoType
  displayState?: TreatmentStateDisplay | null
  hasNestedTreatments?: boolean
  hasConditionalFields?: boolean | null
  isFolded?: boolean
  nestingDepth?: NestingDepth
  onDragPress?: () => void
  onPressTreatment: (treatment: TreatmentSheet) => void
  onPressTreatmentGroupTitle: (treatment: TreatmentSheet) => void
  onToggleFolding?: (id: string) => void
  patient?: getPatient_getPatient | null
  round?: (num: number) => number
  taskdisplayStateOverride?: GroupTreatmentDisplayState | null
  templateName?: string
  treatment: TreatmentSheet
  isTreatmentGroup?: boolean
  isTreatmentFullHeight?: boolean
}

export const getBackgroundColor = (
  displayState?: TreatmentStateDisplay | null,
) => {
  if (displayState === TreatmentStateDisplay.GroupDisplay)
    return Colors.treatment.groupBackground
  if (displayState === TreatmentStateDisplay.ConditionalDisplay) {
    return Colors.treatment.conditionalBackground
  }
  if (displayState === TreatmentStateDisplay.PendingApproval) {
    return Colors.approval.background
  }
  return Colors.treatment.defaultBackground
}

// For case when the treatment group needs to override the border color
const getTaskdisplayStateOverrideBorderColor = (
  taskdisplayStateOverride: GroupTreatmentDisplayState,
) => {
  switch (taskdisplayStateOverride) {
    case GroupTreatmentDisplayState.Has_Missed:
      return Colors.treatment.missedBorder
    case GroupTreatmentDisplayState.Has_Due:
      return Colors.treatment.dueBorder
  }
}

export const getTaskStateBorderColor = (
  noCountColor: string,
  taskCounts?: TaskStateCounts,
  taskdisplayStateOverride?: GroupTreatmentDisplayState | null,
) => {
  if (taskdisplayStateOverride) {
    return getTaskdisplayStateOverrideBorderColor(taskdisplayStateOverride)
  }

  if (!taskCounts) return noCountColor

  return taskCounts.hasMissed
    ? Colors.treatment.missedBorder
    : taskCounts?.hasDue
    ? Colors.treatment.dueBorder
    : noCountColor
}

// Border color is always fixed when final | conditional, changes for other
// states based on task state (missed or due)
export const getBorderColor = (
  displayState?: TreatmentStateDisplay | null,
  taskStateCounts?: TaskStateCounts,
  taskdisplayStateOverride?: GroupTreatmentDisplayState | null,
) => {
  if (displayState === TreatmentStateDisplay.FinalisedDisplay)
    return Colors.treatment.finalisedBorderColor
  if (displayState === TreatmentStateDisplay.ConditionalDisplay) {
    return Colors.treatment.conditionalBorderColor
  }
  if (displayState === TreatmentStateDisplay.PendingApproval) {
    return Colors.approval.primary
  }
  if (displayState === TreatmentStateDisplay.DiscontinuedDisplay)
    return Colors.white

  return getTaskStateBorderColor(
    displayState === TreatmentStateDisplay.GroupDisplay
      ? Colors.lightPurple
      : Colors.white,
    taskStateCounts,
    taskdisplayStateOverride,
  )
}

const TreatmentSideBar: React.FC<TreatmentProps> = ({
  displayState,
  dosageInfoType,
  hasNestedTreatments = false,
  isFolded = false,
  isTreatmentFullHeight = false,
  isTreatmentGroup = false,
  nestingDepth = 0,
  onPressTreatment,
  onPressTreatmentGroupTitle,
  onToggleFolding = noop,
  patient,
  round = identity,
  taskdisplayStateOverride,
  treatment,
}) => {
  const paddingMultiplier = 8
  const paddingStyle = {
    // TODO: should this be margin? - Will change borderLeft indicator offset
    paddingLeft: nestingDepth * paddingMultiplier,
  }

  const taskStateCounts = {
    hasDue: (treatment as TreatmentChildLevel).treatmentTasksStatus?.hasDue,
    hasMissed: (treatment as TreatmentChildLevel).treatmentTasksStatus
      ?.hasMissed,
  }

  const borderColor = getBorderColor(
    displayState,
    taskStateCounts,
    taskdisplayStateOverride,
  )
  const backgroundColor = getBackgroundColor(displayState)

  const showStripedBorder =
    displayState === TreatmentStateDisplay.PendingApproval

  return (
    <View style={styles.sideBarContainer}>
      <View
        accessibilityLabel={`${treatment.name} Task State Border`}
        style={[
          styles.container,
          isTreatmentFullHeight && styles.treatmentFullHeight,
          isTreatmentGroup && styles.groupHeight,
          !showStripedBorder && styles.solidBorder,
          { borderColor },
          { backgroundColor },
        ]}
      >
        {!!showStripedBorder && (
          <View style={styles.svgBorder}>
            <StripedStatusBorder color={borderColor} />
          </View>
        )}
        <View style={[paddingStyle, styles.paddingContainer]}>
          {isTreatmentGroup ? (
            <TreatmentNode
              treatment={treatment}
              isFolded={isFolded}
              isRootLevel={nestingDepth === 0}
              onPressTreatmentGroupTitle={onPressTreatmentGroupTitle}
              onPress={onToggleFolding}
            />
          ) : (
            <TreatmentLeaf
              hasNestedTreatments={hasNestedTreatments}
              displayState={displayState}
              dosageInfoType={dosageInfoType}
              onPressTreatment={onPressTreatment}
              patient={patient}
              round={round}
              treatment={treatment}
              onPress={onToggleFolding}
              isFolded={isFolded}
            />
          )}
        </View>
      </View>
    </View>
  )
}

export const Treatment: React.FC<TreatmentProps> = React.memo(props => {
  const a11yLabel = getRowA11yLabel('Treatment', props.treatment.name)
  const isTreatmentGroup =
    props.treatment.node_type === TreatmentTreeNodeType.GROUP
  const isTreatmentFullHeight = getIsTreatmentFullHeight(props.treatment)

  return (
    /* Runs full width */
    <View
      accessibilityLabel={a11yLabel}
      style={[
        styles.outerContainer,
        isTreatmentFullHeight && styles.treatmentFullHeight,
        isTreatmentGroup && styles.groupHeight,
      ]}
    >
      {/* Runs sidebar width */}
      <TreatmentSideBar
        {...props}
        isTreatmentGroup={isTreatmentGroup}
        isTreatmentFullHeight={isTreatmentFullHeight}
      />
    </View>
  )
})

Treatment.displayName = 'Treatment'

const STATUS_BORDER_WIDTH = 8

const styles = StyleSheet.create({
  outerContainer: {
    borderBottomWidth: 1,
    borderColor: Colors.borderGrey,
    flexDirection: 'row',
    height: treatmentHeight,
  },
  paddingContainer: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  container: {
    height: treatmentHeight,
    flexDirection: 'row',
    width: '100%',
  },
  solidBorder: {
    borderLeftWidth: STATUS_BORDER_WIDTH,
  },
  svgBorder: {
    width: STATUS_BORDER_WIDTH,
  },
  groupHeight: {
    height: treatmentGroupHeight,
  },
  treatmentFullHeight: {
    height: treatmentHeightLarge,
  },
  sideBarContainer: {
    overflow: 'hidden',
    width: '100%',
    flexDirection: 'row',
  },
})
