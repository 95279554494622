import React from 'react'
import { Input, ProcedureForm } from './ProcedureForm'
import { StackScreenProps } from '@react-navigation/stack'
import { SettingsStackParamList } from 'components/Settings/screens'
import { Routes } from 'constants/Routes'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { useMutation } from '@apollo/client'
import { UPDATE_PROCEDURE } from './graphql'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { toast } from 'components/common'
import { updateProcedure } from 'src/types/updateProcedure'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import { useProcedure } from './hooks/useProcedure'

type Props = StackScreenProps<SettingsStackParamList, Routes.EditProcedure>

export const EditProcedureScreen: React.FC<Props> = ({ navigation, route }) => {
  const { t } = useTranslation()
  const { navigate } = navigation
  const [{ organisationId }] = useOrganisation()

  const { procedure, loading } = useProcedure(
    route.params.procedureId,
    organisationId,
  )

  const backButton = {
    action: () => navigate(Routes.ProceduresList),
    label: 'procedures.returnHereLabel',
    title: 'Settings',
  }

  const onComplete = (formData: updateProcedure): void => {
    toast.success(
      t('procedure:update.success', {
        templateWorkflowId: formData.updateProcedure.template_workflow_id,
      }),
    )
    navigate(Routes.ProceduresList)
  }

  const [updateProcedure, { loading: saving }] = useMutation(UPDATE_PROCEDURE, {
    onError: () => toast.error(t('procedure:update.err')),
    onCompleted: onComplete,
  })

  const handleSave = async (data: Input) => {
    await updateProcedure({
      variables: {
        input: {
          id: procedure.id,
          organisation_id: organisationId,
          template_workflow_id: data.template_workflow_id,
          use_case: data.use_case,
        },
      },
    })
  }

  return (
    <>
      <SubHeader
        headline={procedure.name}
        backButton={backButton}
        hyperlinkHeadline={t('procedure:editProcedureHyperLink')}
      />
      {loading ? (
        <ActivityIndicator size="large" style={styles.marginTop} />
      ) : (
        <ProcedureForm
          procedure={procedure}
          onSave={handleSave}
          saving={saving}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25,
  },
})
