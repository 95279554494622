import React from 'react'
import { Circle, G, Path } from 'react-native-svg'
import { AnesthesiaChartSymbolShape } from 'src/context/types/anesthesia'

type Props = {
  selected?: boolean
  color?: string
  symbolShape: AnesthesiaChartSymbolShape
}

const deselectedColor = '#b2bed2'

export const ChartSVGIconPicker: React.FC<Props> = ({
  symbolShape,
  color,
  selected = true,
}) => {
  switch (symbolShape) {
    case AnesthesiaChartSymbolShape.Asterisk:
      return (
        <G>
          <Path
            d="m 0.7 3 l -0.3 -2.4 l 1.9 1.5 l 0.7 -1.2 l -2.3 -0.9 l 2.3 -0.9 l -0.7 -1.2 l -2 1.5 l 0.3 -2.4 h -1.4 l 0.3 2.4 l -2 -1.5 l -0.7 1.2 l 2.3 0.9 l -2.3 0.9 l 0.7 1.2 l 2 -1.5 l -0.3 2.4 z"
            fill={selected ? color || '#e766ca' : deselectedColor}
            stroke="none"
          />
        </G>
      )
    case AnesthesiaChartSymbolShape.CrossedCircle:
      return (
        <G fill="none" stroke={selected ? color || '#1565c0' : deselectedColor}>
          <Circle fill="none" r="2.125" />
          <Path d="m -2.5 2.5 l 5.1 -5.1" strokeLinecap="round" />
        </G>
      )
    case AnesthesiaChartSymbolShape.DownwardChevron:
      return (
        <G>
          <Path
            d="m 3.2 -1.6 l -3.2 3.2 l -3.2 -3.2"
            fill="none"
            stroke={selected ? color || '#f57c00' : deselectedColor}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </G>
      )
    case AnesthesiaChartSymbolShape.DownwardChevronCircle:
      return (
        <G fill="none" stroke={selected ? color || '#dc618d' : deselectedColor}>
          <Circle cx="0" cy="0" r="3" />
          <Path
            d="m 1.3 -0.6 l -1.3 1.7 l -1.3 -1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth=".75"
          />
        </G>
      )
    case AnesthesiaChartSymbolShape.EmptyCircle:
      return (
        <G>
          <Circle
            cx="0"
            cy="0"
            fill="none"
            r="2.2"
            stroke={selected ? color || '#689f38' : deselectedColor}
          />
        </G>
      )
    case AnesthesiaChartSymbolShape.EmptyTriangle:
      return (
        <G>
          <Path
            d="m 3 2 h -6 l 3 -5.1 z"
            fill="none"
            stroke={selected ? color || '#7e57c2' : deselectedColor}
          />
        </G>
      )
    case AnesthesiaChartSymbolShape.FilledCircle:
      return (
        <Circle
          cx={0}
          cy={0}
          r="2.2"
          fill={selected ? color || '#26a69a' : deselectedColor}
          stroke="none"
        />
      )

    case AnesthesiaChartSymbolShape.HorizontalLine:
      return (
        <G>
          <Path
            d="m -3 0 h 6"
            fill="none"
            stroke={selected ? color || '#ffc107' : deselectedColor}
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </G>
      )
    case AnesthesiaChartSymbolShape.Lozenge:
      return (
        <G>
          <Path
            d="m 0 -3 l -3 3 l 3 3 l 3 -3 z"
            fill="none"
            stroke={selected ? color || '#7eb602' : deselectedColor}
          />
        </G>
      )
    case AnesthesiaChartSymbolShape.UpwardChevron:
      return (
        <G>
          <Path
            d="m -3.2 1.6 l 3.2 -3.2 l 3.2 3.2"
            fill="none"
            stroke={selected ? color || '#ff9800' : deselectedColor}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </G>
      )

    default:
      return null
  }
}
