import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { PossibleSelectValues } from '../Select/Select.types'

export enum DialogStates {
  Discharge = 'Discharge',
  None = 'None',
  TreatmentGroup = 'TreatmentGroup',
  AllNotes = 'AllNotes',
}

export enum KeyboardStatus {
  Show = 'Keyboard Shown',
  Hide = 'Keyboard Hidden',
}

type UserItems = {
  value: PossibleSelectValues
  text?: string
}

export type SelectSearch = {
  dialogBackgroundStyle?: StyleProp<ViewStyle>
  userItemList?: UserItems[]
  isSelectSearch?: boolean
  selectedUser?: (value: string) => void
}

export type HeightValue = number | 'auto' | `${number}%`

export type DialogProps = {
  children?: React.ReactNode
  contentStyle?: StyleProp<ViewStyle>
  dialogHeight?: HeightValue
  disableCancel?: boolean
  onCancel?: () => void
  onSave?: () => void
  showCancel?: boolean
  showDone?: boolean
  title?: string
  toggleDialog: () => void
  visible: boolean
  isScrollable?: boolean
  dialogBackgroundColor?: string | null
  hasStickySubmitButton?: boolean
}

export type DialogHeaderProps = {
  bgColor?: string
  onCancel?: () => void | boolean
  onSave?: (val: any) => void | boolean
  showCancel?: boolean
  showDone?: boolean
  title?: string
}

export type DialogSearchUserProps = {
  onCancel: () => void
  toggleDialog: () => void
  visible: boolean
  searchInput: React.ReactElement
  userItemList: UserItems[]
  selectUser: (value: string) => void
  resetSearchBar: () => void
  currentUser: PossibleSelectValues
}
