import { MutationType } from 'types/globalTypes'
import { TreatmentSubscriptionHandlers } from 'components/Sheet/treatmentSubscription/handlers/types/treatment-handler.type'
import { useTreatmentDeletionHandler } from 'components/Sheet/treatmentSubscription/handlers/useTreatmentDeletionHandler'
import { useTreatmentCreationHandler } from 'components/Sheet/treatmentSubscription/handlers/useTreatmentCreationHandler'
import { useTreatmentUpdateHandler } from 'components/Sheet/treatmentSubscription/handlers/useTreatmentUpdateHandler'

/**
 * Handlers registration center
 */
export const useTreatmentSubscriptionHandlers: TreatmentSubscriptionHandlers =
  () => {
    const treatmentDeletionHandler = useTreatmentDeletionHandler()
    const treatmentUpdateHandler = useTreatmentUpdateHandler()
    const treatmentCreationHandler = useTreatmentCreationHandler()

    return {
      [MutationType.DELETE]: treatmentDeletionHandler,
      [MutationType.UPDATE]: treatmentUpdateHandler,
      [MutationType.CREATE]: treatmentCreationHandler,
    }
  }
