import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Image,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  SafeAreaView,
  SectionList,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'
import { TaskPreviewTreatmentGroup } from '../types'
import { TaskListSheetItem } from './TaskListSection/TaskListSheetItem'

type TaskListProps = {
  taskPreviewData: TaskPreviewTreatmentGroup[]
  timeFormat: string
  setScrollPosition: (val: number) => void
}

export const TaskList: React.FC<TaskListProps> = ({
  taskPreviewData,
  timeFormat,
  setScrollPosition,
}) => {
  const { t } = useTranslation()

  const hasTasks = useMemo(
    () => !!taskPreviewData.length,
    [taskPreviewData.length],
  )

  const isIOS = Platform.OS === 'ios'

  const listContainerStyle = useMemo(
    () => ({
      ...styles.taskListContainer,
      marginBottom: isIOS ? 32 : 0,
    }),
    [isIOS],
  )

  const renderTreatmentGroupHeader = (title: string) => (
    <View
      testID={`TaskListTreatmentGroupHeader-${title}`}
      style={styles.treatmentGroupHeader}
    >
      <Text style={styles.treatmentGroupHeaderText}>{title}</Text>
    </View>
  )

  const handleScrollEvent = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    setScrollPosition(
      e.nativeEvent.contentOffset.y >= 0 ? e.nativeEvent.contentOffset.y : 0,
    )
  }

  return (
    <SafeAreaView style={listContainerStyle}>
      {hasTasks ? (
        <SectionList
          bounces={false}
          sections={taskPreviewData}
          renderSectionHeader={({ section: { title } }) =>
            renderTreatmentGroupHeader(title)
          }
          renderItem={({ item, section }) => (
            <TaskListSheetItem
              sheetTitle={item.title}
              sectionLength={section.data.length}
              timeFormat={timeFormat}
              tasks={item.data}
            />
          )}
          stickySectionHeadersEnabled={true}
          onScroll={handleScrollEvent}
        />
      ) : (
        <View testID="TaskListNoTasks" style={styles.noTasksContainer}>
          <Image
            style={styles.emptySheetsImage}
            source={require('assets/images/task-preview-empty.png')}
            accessibilityLabel="Empty sheets image"
          />
          <Text style={styles.noTasksText}>
            {t('task:taskPreview.noTasks')}
          </Text>
        </View>
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  taskListContainer: {
    flex: 1,
    backgroundColor: Colors.Backgrounds.transparent,
    height: 'auto',
  },
  treatmentGroupHeader: {
    width: '100%',
    height: 40,
    backgroundColor: Colors.Backgrounds.accentSecondary,
    paddingHorizontal: 16,
    paddingVertical: 4,
    borderBottomWidth: 1,
    borderColor: Colors.Borders.primary,
    justifyContent: 'center',
  },
  treatmentGroupHeaderText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
  },
  emptySheetsImage: {
    height: 235,
    width: 400,
    marginBottom: 20,
    flexShrink: 0,
  },
  noTasksContainer: {
    flex: 1,
    padding: 40,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  noTasksText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
    color: Colors.Contents.secondary,
  },
})
