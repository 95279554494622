import React from 'react'
import {
  FormBreak,
  FormLabel,
  Select,
  SwitchInput,
  TextArea,
} from 'components/common'
import { ASACat } from 'components/common/AsaCategory/AsaCategory'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProductType } from 'src/types/globalTypes'

import { CatSiteSection } from '../CatSiteSection'
import { createSheetDefaults, CreateSheetFormInputs } from '../createSheetData'
import { ProductSection } from '../ProductSection'

// TODO: BM - This is confusing refactor ASACAT to something with a cleaner type(s)
/* Pick only the options that don't have E on the end (which happen to be odd
   indexes in the array) */
const ASACatOptions = ASACat.filter((_, idx) => (idx + 1) % 2 === 1).map(
  option => ({
    text: option,
    value: option,
  }),
)

type Props = {
  controller: Control<CreateSheetFormInputs>
  defaultOverrides?: Partial<CreateSheetFormInputs>
  attendingDepartment?: string | null
  patientId: string
  asaCat: string | null
}

export const AnesthesiaSection: React.FC<Props> = React.memo(
  ({
    controller,
    defaultOverrides,
    patientId,
    attendingDepartment,
    asaCat,
  }) => {
    const { t } = useTranslation()
    const defaultValues = { ...createSheetDefaults, ...defaultOverrides }

    return (
      <>
        <FormLabel text={t('sheetForm.anaestheticDetail')} />
        <Controller
          control={controller}
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              value={value}
              onChangeValue={onChange}
              label={t('sheetForm.ownerConsent')}
            />
          )}
          name="ownerConsent"
          defaultValue={defaultValues.ownerConsent}
        />
        <FormBreak />
        <Controller
          control={controller}
          defaultValue={defaultValues.asaCat}
          render={({ field: { onChange, value } }) => (
            <Select
              allowClear={true}
              options={ASACatOptions}
              selected={value}
              onChange={onChange}
              label={t('sheetForm.asaCat')}
            />
          )}
          name="asaCat"
        />
        <Controller
          control={controller}
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              value={value}
              onChangeValue={onChange}
              label={t('sheetForm.emergency')}
              disabled={!asaCat}
            />
          )}
          name="emergency"
          defaultValue={defaultValues.emergency}
        />
        <Controller
          control={controller}
          defaultValue={defaultValues.comments}
          render={({ field: { onChange, value } }) => (
            <TextArea
              label={t('sheetForm.comments')}
              onChangeText={onChange}
              value={value}
              visibleLines={6}
            />
          )}
          name="comments"
        />
        <FormBreak />
        <Controller
          control={controller}
          defaultValue={defaultValues.circuitCheck}
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              value={value}
              onChangeValue={onChange}
              label={t('sheetForm.circuit')}
            />
          )}
          name="circuitCheck"
        />

        <Controller
          control={controller}
          render={({ field: { onChange, value } }) => (
            <ProductSection
              label={t('sheetForm.premeds')}
              patientId={patientId}
              onSelectedProductsChange={onChange}
              selectedProducts={value}
              productType={ProductType.MEDICATION}
              attendingDepartment={attendingDepartment}
            />
          )}
          name="selectedPremedsProducts"
          defaultValue={defaultValues.selectedPremedsProducts}
        />

        <FormBreak />
        <Controller
          control={controller}
          render={({ field: { onChange, value } }) => (
            <CatSiteSection
              onChange={onChange}
              sites={value}
              label={t('sheetForm.catheterSite')}
            />
          )}
          name="catSite"
          defaultValue={defaultValues.catSite}
        />

        <Controller
          control={controller}
          render={({ field: { onChange, value } }) => (
            <ProductSection
              patientId={patientId}
              label={t('sheetForm.induction')}
              onSelectedProductsChange={onChange}
              selectedProducts={value}
              productType={ProductType.MEDICATION}
              attendingDepartment={attendingDepartment}
            />
          )}
          name="selectedInductionProducts"
          defaultValue={defaultValues.selectedInductionProducts}
        />

        <Controller
          control={controller}
          render={({ field: { onChange, value } }) => (
            <ProductSection
              patientId={patientId}
              label={t('sheetForm.intraopFluids')}
              onSelectedProductsChange={onChange}
              selectedProducts={value}
              productType={ProductType.IVFLUIDS}
              attendingDepartment={attendingDepartment}
            />
          )}
          name="selectedIntraoperativeFluidsProducts"
          defaultValue={defaultValues.selectedIntraoperativeFluidsProducts}
        />
      </>
    )
  },
)

AnesthesiaSection.displayName = 'AnesthesiaSection'
