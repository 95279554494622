import {
  getTaskStartAtString,
  getTaskStopAtString,
} from 'components/Treatment/utils/getTaskDate'
import { addMinutes } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { toast } from 'src/components/common/Toast/ToastArea'
import { Status, TreatmentTaskType } from 'src/types/globalTypes'
import { getOptimisticId } from 'src/utils/optimisticId'

import { Task } from '../types'
import { useCreateTask } from './useCreateTask'

type TaskInputOverwrite = {
  status: Status
  notes: string
  assigned_user: string
}

type ScheduleFluidTaskInput = {
  originalTask: Task
  scheduleAt: Date
  inputValue: string
  statusAndNotesOverwrite?: TaskInputOverwrite
  isRestart?: boolean
}
export const useScheduleFluidTask = () => {
  const createScheduleTask = useCreateTask()
  const { t } = useTranslation()

  return ({
    originalTask,
    scheduleAt,
    inputValue,
    statusAndNotesOverwrite,
    isRestart,
  }: ScheduleFluidTaskInput) => {
    // when restart(create) a fluid, the start time cannot before originalTask given_stop_at
    const taskRestrictAt = isRestart
      ? getTaskStopAtString(originalTask)
      : getTaskStartAtString(originalTask)

    if (!taskRestrictAt) {
      return
    }

    if (new Date(taskRestrictAt) >= scheduleAt) {
      toast.notice(t('task:taskAddEdit:fluids:startFluidError'))
      return
    }

    const {
      status = Status.PENDING,
      notes = '',
      assigned_user = '',
    } = statusAndNotesOverwrite ?? {}

    const newTask = {
      status,
      notes,
      assigned_user,
      _pending: null,
      value: inputValue,
      unit: null,
      id: getOptimisticId(),
      organisation_id: originalTask.organisation_id,
      sheet_id: originalTask.sheet_id,
      patient_id: originalTask.patient_id,
      treatment_id: originalTask.treatment_id,
      start_at: scheduleAt.toISOString(),
      stop_at: addMinutes(scheduleAt, 60).toISOString(),
      send_to_adaptor: null,
      buffer_due: null,
      buffer_missed: null,
      // set given_start_at for new fluid task(empty triangle)
      given_start_at: scheduleAt.toISOString(),
      given_stop_at: null,

      medicine_dosage_info: null,
      attending_vet: '',
      attending_department: '',
      updated_at: '',
      updated_by: '',
      type: TreatmentTaskType.NORMAL,
      photo_urls: null,
      billing_reference: null,
    }
    return createScheduleTask(newTask)
  }
}
