import React, { useMemo } from 'react'

import {
  ActivityIndicator,
  FlatList,
  View,
  TouchableOpacity,
  StyleSheet,
  Text,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import Highlighter from 'react-native-highlight-words'

type TemplateItem = {
  id: string
  name: string
}

type WorkflowTemplateListProps = {
  templates: TemplateItem[]
  onAddClick: (id: string) => any
  isListLoading: boolean
  currentAddingTemplateId: undefined | string
  searchText: string
}

export const WorkflowTemplateList: React.FC<WorkflowTemplateListProps> = ({
  templates,
  onAddClick,
  isListLoading,
  currentAddingTemplateId,
  searchText,
}) => {
  const searchWords = useMemo(() => searchText.split(/\s+/), [searchText])
  const renderItem = (item: TemplateItem) => {
    const isAdding = currentAddingTemplateId === item.id
    return (
      <View style={styles.container}>
        <Highlighter
          ellipsizeMode="middle"
          highlightStyle={{ fontFamily: Fonts.bold }}
          numberOfLines={1}
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={item.name}
        />

        <TouchableOpacity
          disabled={isAdding}
          style={[styles.addBtn, isAdding && { opacity: 0.6 }]}
          onPress={() => onAddClick(item.id)}
        >
          <Text style={styles.buttonText}>+ ADD</Text>
          {isAdding && (
            <ActivityIndicator
              size="small"
              color={Colors.white}
              style={{ marginLeft: 5 }}
            />
          )}
        </TouchableOpacity>
      </View>
    )
  }

  return (
    <FlatList
      data={templates}
      keyExtractor={item => item.id}
      renderItem={({ item }) => renderItem(item)}
      ListFooterComponent={() => <>{isListLoading && <ActivityIndicator />}</>}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    height: 45,
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
  },
  addBtn: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: 5,
    backgroundColor: Colors.blue,
    alignItems: 'center',
    borderRadius: 3,
  },
  buttonText: {
    color: Colors.white,
    fontFamily: Fonts.regular,
    fontSize: 12,
  },
})
