import React, { useMemo } from 'react'
import { OnPressTask } from 'components/Grid/GridFlatList'
import { ScaleTimeFn } from 'components/Grid/GridTimeLine/GridTimeline'
import { noop } from 'lodash'
import { StyleSheet, View } from 'react-native'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskItem } from 'src/types/getSheet'

import { treatmentHeight } from '../common'
import { TreatmentSheet } from './common/types'
import { FluidTaskGroup } from './FluidTaskGroup'
import { getSortedTasksChunks } from './utils/fluidLeafFns'

export type Props = {
  onPressTask?: OnPressTask
  positionInGridFn: ScaleTimeFn
  tasks: TaskItem[]
  treatment: TreatmentSheet
  isFinalized: boolean
}

export const FluidGridTreatmentLeaf: React.FC<Props> = React.memo(
  ({ onPressTask = noop, positionInGridFn, tasks, treatment }) => {
    // make fluid tasks into chunk. Each chunk is a fluid line.
    const sortedTasksChunks = useMemo(
      () => getSortedTasksChunks(tasks),
      [tasks],
    )

    return (
      <View
        accessibilityLabel={`Fluid Grid Treatment Leaf ${treatment.name}`}
        style={styles.gridTreatmentLeaf}
      >
        {sortedTasksChunks.map((sortedTasks, index) => (
          <FluidTaskGroup
            key={index}
            sortedTasks={sortedTasks}
            treatment={treatment}
            positionInGridFn={positionInGridFn}
            onPressTask={onPressTask}
          />
        ))}
      </View>
    )
  },
)

FluidGridTreatmentLeaf.displayName = 'FluidGridTreatmentLeaf'

const styles = StyleSheet.create({
  gridTreatmentLeaf: {
    height: treatmentHeight - 1, // Minus 1 for GridTreatment's border
  },
})
