import React from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { GET_PRODUCT_DETAILS } from 'src/components/AddTreatment/graphql'
import { FormData, TreatmentForm } from 'src/components/TreatmentForm'
import { useOrganisation } from 'src/context/organisation'
import { usePatientById } from 'src/hooks/usePatient'
import {
  getOneProduct as GetOneProduct,
  getOneProductVariables as GetOneProductVariables,
} from 'src/types/getOneProduct'
import { ApprovalStatus } from 'src/types/globalTypes'

type OnSave = (data: FormData) => void
type Props = {
  patientId: string
  onSave: OnSave
  productId?: string
  submitting: boolean
  defaultOverrides: Partial<FormData>
  treatmentName: string
  approvalStatus?: ApprovalStatus
}

export const EditNonIVTreatment: React.FC<Props> = ({
  patientId,
  onSave,
  productId,
  submitting,
  defaultOverrides,
  treatmentName,
  approvalStatus,
}) => {
  const [{ organisationId, hasPIMSIntegration }] = useOrganisation()
  const { t } = useTranslation()

  const patient = usePatientById(patientId)

  const { loading: loadingProduct, data: productData } = useQuery<
    GetOneProduct,
    GetOneProductVariables
  >(GET_PRODUCT_DETAILS, {
    skip: !productId,
    variables: {
      id: productId!,
      organisation_id: organisationId,
    },
  })

  const product = productData?.getProduct

  if (loadingProduct) {
    return (
      <ActivityIndicator
        accessibilityLabel="Edit Treatment Loading Indicator"
        size="large"
        style={styles.marginTop}
      />
    )
  }

  if (!patient || !product) {
    return null
  }

  return (
    <TreatmentForm
      onSave={onSave}
      defaultOverrides={defaultOverrides}
      submitTitle={t('general.saveChanges')}
      submitting={submitting}
      patient={patient}
      product={product}
      hasPIMSIntegration={!!hasPIMSIntegration}
      isEdit={true}
      treatmentName={treatmentName}
      approvalStatus={approvalStatus}
    />
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25,
  },
})
