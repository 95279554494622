import { useApolloClient } from '@apollo/client'
import {
  GET_CONDITIONAL_TREATMENT,
  GET_SHEET,
} from 'src/components/Sheet/graphql'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import {
  getConditionalTreatment,
  getConditionalTreatmentVariables,
} from 'src/types/getConditionalTreatment'
import { getSheet, getSheetVariables } from 'src/types/getSheet'

type Props = {
  sheetId: string
  patientId: string
}

export type TemplateDuplicationIdHash = { [id: string]: true | undefined }

export const useTemplateDuplication = ({ sheetId, patientId }: Props) => {
  const [{ organisationId }] = useOrganisation()

  const client = useApolloClient()
  const { fromToQueryDate } = useTimeResolution()

  const query = GET_SHEET

  const variables = {
    id: sheetId,
    organisation_id: organisationId,
    ...fromToQueryDate,
  }

  const sheetData = client.readQuery<getSheet, getSheetVariables>({
    query,
    variables,
  })

  const templateDuplicationIdHash: TemplateDuplicationIdHash = {}

  const sheetConditionalTreatmentIds = sheetData?.getSheet?.treatments?.items
    ?.flatMap(t => t.treatments?.items)
    ?.filter(treatment => !treatment?.product)
    ?.map(treatment => treatment?.id)

  sheetConditionalTreatmentIds?.forEach(id => {
    if (!id) return

    const data = client.readQuery<
      getConditionalTreatment,
      getConditionalTreatmentVariables
    >({
      query: GET_CONDITIONAL_TREATMENT,
      variables: {
        patient_id: patientId,
        treatment_id: id,
      },
    })

    const conditionalTreatment = data?.getConditionalTreatment

    if (!conditionalTreatment) return
    const productOptions = conditionalTreatment.treatment_options?.products
    const productCount = productOptions?.length

    if (productCount === 1 && productOptions?.[0]) {
      const productId = productOptions[0].id
      templateDuplicationIdHash[productId] = true
      return
    }

    const templateTreatmentId = conditionalTreatment.template_treatment_id

    if (templateTreatmentId) {
      templateDuplicationIdHash[templateTreatmentId] = true
    }
  })

  sheetData?.getSheet?.treatments?.items?.forEach(t =>
    t?.treatments?.items?.forEach(t => {
      const productId = t.product?.id
      if (productId) {
        templateDuplicationIdHash[productId] = true
      }
    }),
  )

  return templateDuplicationIdHash
}
