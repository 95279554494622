import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { NotesList } from 'components/Sheet/NotesList'
import { IVInfusionsHeader } from 'components/Task/TaskHeader/IVInfusionsHeader'
import {
  getIsCRI,
  getIsFluidStyleTaskDisplay,
} from 'components/Task/utils/getTreatmentInfo'
import {
  getIsTaskDoneOrProgressOrSkipped,
  getIsTaskSuceessOnCubex,
} from 'components/Task/utils/taskTransitionUtils'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { useBreakpoint } from 'src/hocs/breakpoint'
import {
  getTreatmentNotes,
  getTreatmentNotesVariables,
} from 'src/types/getTreatmentNotes'
import {
  PermissionAction,
  TreatmentNodeDisplayStates,
} from 'src/types/globalTypes'
import { Status } from 'types/globalTypes'
import { TreatmentChildLevel, TreatmentSheetSecondLevel } from './common/types'
import { GET_TREATMENT_NOTES } from './graphql'
import { TreatmentSchedule } from './TreatmentSchedule'
import { useDeleteTreatment } from './utils/useDeleteTreatment'
import { useDiscontinueTreatment } from './utils/useDiscontinueTreatment'
import { TaskDialogHeader } from 'components/Task/TaskHeader/TaskDialogHeader'
import { PermissionTouchableOpacity } from 'components/common/Permission'
import { usePermission } from 'src/hooks/usePermission'
import { RequireApprovalBanner } from 'components/Banners/RequireApprovalBanner'
import { Button, toast } from 'components/common'
import { useApprovals } from 'src/hooks/useApprovals'
import { GET_USER_DISPLAY_NAME } from 'components/Patient/graphql'
import { useOrganisation } from 'src/context/organisation'
import {
  getUserDisplayName,
  getUserDisplayNameVariables,
} from 'src/types/getUserDisplayName'
import { CubexTaskBanner } from 'components/Banners/CubexTaskBanner'

export type TreatmentActionsProps = {
  isFinalized: boolean
  patientId: string
  sheetId: string
  toggleTreatmentActionsDrawer: () => void
  toggleEditTreatmentDrawer: () => void
  treatment: TreatmentChildLevel
}

export const isTreatmentHasDoneOrSkipTask = (
  treatment: TreatmentChildLevel | null,
) =>
  treatment?.tasks?.items?.some(
    task =>
      getIsTaskDoneOrProgressOrSkipped(task.status) ||
      getIsTaskSuceessOnCubex(task.cubex_transaction_status),
  )

export const TreatmentActions: React.FC<TreatmentActionsProps> = ({
  isFinalized,
  patientId,
  sheetId,
  toggleTreatmentActionsDrawer,
  toggleEditTreatmentDrawer,
  treatment,
}) => {
  const { t } = useTranslation()
  const { fullAdminDateFormat } = useAdminTimeFormat()
  const { isExSmallScreen } = useBreakpoint()
  const discontinueRestartEnabled = usePermission(
    PermissionAction.TREATMENT__DISCONTINUE_RESTART,
  )

  const { data, loading } = useQuery<
    getTreatmentNotes,
    getTreatmentNotesVariables
  >(GET_TREATMENT_NOTES, {
    variables: {
      patient_id: patientId,
      treatment_id: treatment.id,
    },
    fetchPolicy: 'cache-and-network',
  })

  const notes = orderBy(
    data?.getTreatment.tasks?.items?.filter(
      task => !!task.notes && task.status !== Status.DELETED_ON_PURPOSE,
    ),
    'start_at',
  )

  const discontinueTreatment = useDiscontinueTreatment({
    patientId,
    sheetId,
    treatment,
  })

  const deleteTreatment = useDeleteTreatment({
    patientId,
    sheetId,
    id: treatment.id,
    treatmentName: treatment.name ?? 'treatment',
  })

  const isTreatmentHasCompletedTask = useMemo(() => {
    let hasDoneOrSkippedTasks = isTreatmentHasDoneOrSkipTask(treatment)

    // return result earlier when treatment itself hasDoneOrSkippedTasks
    if (hasDoneOrSkippedTasks) {
      return hasDoneOrSkippedTasks
    }

    if (treatment.is_iv_infusion) {
      const childTreatments =
        (treatment as TreatmentSheetSecondLevel)?.treatments?.items || []
      hasDoneOrSkippedTasks = childTreatments.some(isTreatmentHasDoneOrSkipTask)
    }

    return hasDoneOrSkippedTasks
  }, [treatment])

  const isFinishedFluidTreatment = useMemo(() => {
    const isFluidTreatment = getIsFluidStyleTaskDisplay(treatment)
    const hasDoneFluid = treatment?.tasks?.items?.some(task => {
      /* If the task is done, fluid task is stopped */
      return task.status === Status.DONE
    })

    const hasStartFluid = treatment?.tasks?.items?.some(task => {
      return task.status === Status.IN_PROGRESS
    })

    return isFluidTreatment && hasDoneFluid && !hasStartFluid
  }, [treatment])

  const discontinueTreatmentOption = useCallback(
    ({
      shouldHideTreatment,
      restart,
    }: {
      shouldHideTreatment?: boolean
      restart?: boolean
    }) => {
      discontinueTreatment(
        {
          display_states: shouldHideTreatment
            ? [TreatmentNodeDisplayStates.HIDDEN]
            : null,
        },
        restart,
      )
      toggleTreatmentActionsDrawer()
    },
    [discontinueTreatment, toggleTreatmentActionsDrawer],
  )

  const deleteTreatmentOption = useCallback(() => {
    deleteTreatment()
    toggleTreatmentActionsDrawer()
  }, [deleteTreatment, toggleTreatmentActionsDrawer])

  const onPressDiscontinue = useCallback(
    () =>
      discontinueTreatmentOption({
        restart: !!treatment.discontinued_at,
      }),
    [discontinueTreatmentOption, treatment.discontinued_at],
  )

  const { showApproverUI, isTreatmentApproved } = useApprovals()

  const showApproval = useMemo(
    () => showApproverUI && !isTreatmentApproved(treatment),
    [showApproverUI, isTreatmentApproved, treatment],
  )

  const isNativeStyle = Platform.OS === 'ios'
  const isCRI = getIsCRI(treatment)

  const renderHeader = () => (
    <>
      {isCRI ? (
        <IVInfusionsHeader
          treatment={treatment}
          isNativeStyle={isNativeStyle}
        />
      ) : (
        <TaskDialogHeader
          treatment={treatment}
          patientId={patientId}
          headStyleForCubex={
            treatment.cubex_integration ? styles.cubexTaskHeader : null
          }
        />
      )}

      {!!treatment && (
        <TreatmentSchedule
          treatment={treatment}
          timeFormat={fullAdminDateFormat}
        />
      )}

      {!isFinalized && (
        <View style={styles.actionsContainer}>
          {!treatment.discontinued_at && (
            <View style={styles.actionContainer}>
              <TouchableOpacity
                disabled={isTreatmentHasCompletedTask}
                onPress={deleteTreatmentOption}
              >
                <View
                  style={[
                    styles.actionButton,
                    isTreatmentHasCompletedTask && styles.darkGreyBorder,
                    isExSmallScreen && styles.buttonSmallScreen,
                  ]}
                >
                  <Text
                    style={[
                      styles.actionButtonText,
                      isTreatmentHasCompletedTask && styles.darkGreyText,
                    ]}
                  >
                    {t('treatment:delete')}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
          <View style={styles.actionContainer}>
            <PermissionTouchableOpacity
              onPress={onPressDiscontinue}
              permissionAction={PermissionAction.TREATMENT__DISCONTINUE_RESTART}
            >
              <View
                style={[
                  styles.actionButton,
                  isExSmallScreen && styles.buttonSmallScreen,
                  !discontinueRestartEnabled && styles.darkGreyBorder,
                ]}
              >
                <Text
                  style={[
                    styles.actionButtonText,
                    !discontinueRestartEnabled && styles.darkGreyText,
                  ]}
                >
                  {!!treatment.discontinued_at
                    ? t('treatment:restart')
                    : t('treatment:discontinue')}
                </Text>
              </View>
            </PermissionTouchableOpacity>
          </View>
          {!treatment.discontinued_at && (
            <View style={[styles.actionContainer, styles.actionContainerLast]}>
              <TouchableOpacity
                disabled={isFinishedFluidTreatment}
                onPress={toggleEditTreatmentDrawer}
              >
                <View
                  style={[
                    styles.actionButton,
                    isFinishedFluidTreatment && styles.darkGreyBorder,
                    isExSmallScreen && styles.smallScreenEdit,
                  ]}
                >
                  <Text
                    style={[
                      styles.actionButtonText,
                      isFinishedFluidTreatment && styles.darkGreyText,
                    ]}
                  >
                    {t('treatment:edit')}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}
      {!!showApproval && (
        <ApprovalSection
          toggleDrawer={toggleTreatmentActionsDrawer}
          treatmentId={treatment.id}
          patientId={patientId}
          approvalUserId={treatment.approval_updated_by}
        />
      )}
      <View style={styles.commentsContainer}>
        <Text style={styles.commentsTitle}>
          {t('treatment:comments.title')}
        </Text>
      </View>
    </>
  )
  return (
    <>
      <CubexTaskBanner treatment={treatment} />
      <RequireApprovalBanner treatment={treatment} />
      <NotesList notes={notes} loading={loading} renderHeader={renderHeader} />
    </>
  )
}

type ApprovalSectionProps = {
  treatmentId: string
  patientId: string
  approvalUserId?: string | null
  toggleDrawer: () => void
}

const ApprovalSection: React.FC<ApprovalSectionProps> = ({
  treatmentId,
  patientId,
  approvalUserId,
  toggleDrawer,
}) => {
  const { t } = useTranslation()
  const { approveTreatment, isApprovingTreatment } = useApprovals()

  const approve = async () => {
    try {
      await approveTreatment(treatmentId, patientId)
      toast.success(t('treatment:approval:successfulToApproveSingleTreatment'))
      toggleDrawer()
    } catch (error) {
      toast.error(t('treatment:approval:failedToApproveSingleTreatment'))
    }
  }
  const [{ organisationId }] = useOrganisation()
  const { data } = useQuery<getUserDisplayName, getUserDisplayNameVariables>(
    GET_USER_DISPLAY_NAME,
    {
      skip: !approvalUserId,
      variables: {
        organisationId,
        id: approvalUserId!,
      },
    },
  )

  const approvalUserName = data?.getUserDisplayName?.display_name

  return (
    <View style={styles.approvalContainer}>
      <Button
        a11yLabel={`${t('treatment:approval:approveSingleTreatment')} Button`}
        onPress={approve}
        color={Colors.approval.primary}
        testID="ApproveTreatmentButton"
        title={t('treatment:approval:approveSingleTreatment')}
        disabled={isApprovingTreatment}
      />
      {!!approvalUserName && (
        <Text style={approvalStyles.approvalRequestedBy}>
          {t('treatment:approval:userRequestedApproval', {
            name: approvalUserName,
          })}
        </Text>
      )}
    </View>
  )
}

const approvalStyles = StyleSheet.create({
  approvalRequestedBy: {
    marginTop: 8,
  },
})

const styles = StyleSheet.create({
  actionContainer: {
    borderColor: Colors.lightGrey,
    alignItems: 'center',
    borderRightWidth: 1,
    flex: 1,
    justifyContent: 'center',
    paddingBottom: 36,
    paddingTop: 28,
  },
  actionsContainer: {
    borderBottomColor: Colors.lightGrey,
    borderBottomWidth: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-evenly',
  },
  approvalContainer: {
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: Colors.shared.borderGrey,
    padding: 8,
  },
  actionContainerLast: {
    borderRightWidth: 0,
  },
  actionButton: {
    borderColor: Colors.blue,
    borderRadius: 8,
    borderWidth: 1,
    justifyContent: 'center',
    minWidth: 100,
    paddingVertical: 8,
  },
  actionButtonText: {
    color: Colors.blue,
    textAlign: 'center',
    paddingHorizontal: 8,
  },
  buttonSmallScreen: {
    minWidth: 70,
  },
  commentsContainer: {
    paddingTop: 10,
  },
  commentsTitle: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: Fonts.regular,
  },
  darkGreyBorder: { borderColor: Colors.darkGrey },
  darkGreyText: {
    color: Colors.darkGrey,
  },
  smallScreenEdit: { minWidth: 70 },
  cubexTaskHeader: {
    marginTop: 0,
  },
})
