import { format, parseISO } from 'date-fns'
import { compact, sortBy } from 'lodash'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as Task } from 'src/types/getSheet'
import { Status } from 'src/types/globalTypes'
import { isOptimisticId } from 'src/utils/optimisticId'

type GetChangeFluidRateNotes = {
  isChangeOrSchedule: boolean
  timeFormat: string
  tasks: Task[]
  task?: Task
}

export const getChangeFluidRateNotes = ({
  isChangeOrSchedule,
  timeFormat,
  tasks,
  task,
}: GetChangeFluidRateNotes) => {
  // from get fluid task action, isChanging means the task is started
  if (!isChangeOrSchedule || !task) {
    return ''
  }
  const sortedTasks = sortBy(tasks, 'given_start_at')

  const notStartFluidTasks = compact(
    sortedTasks.map(t => {
      if (t.status === Status.DONE) {
        return null
      }
      if (!t.value || !t.given_start_at || isOptimisticId(t.id)) {
        return null
      }
      if (t.id === task.id) {
        return null
      }
      return { value: t.value, givenAt: t.given_start_at }
    }),
  )

  if (notStartFluidTasks.length === 0) {
    return ''
  }

  return notStartFluidTasks.reduce((changeNotes, t, index, allTasks) => {
    const previousTask = index === 0 ? task : allTasks[index - 1]
    const changeRateText = `${previousTask?.value} to ${t.value} at ${format(
      parseISO(t.givenAt),
      timeFormat,
    )}`
    if (!changeNotes) {
      return changeRateText
    }
    return `${changeNotes}
${changeRateText}`
  }, '')
}
