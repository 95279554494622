import { useCallback, useEffect, useMemo, useState } from 'react'
import { useOrganisation } from 'src/context/organisation'
import { SortDirection } from 'src/types/globalTypes'
import { Storage } from 'src/utils/Storage'

import {
  SortOptionsEnum,
  SortOptionsText,
  SortOptionsTextType,
} from '../PatientListSort'

export enum sortType {
  Whiteboard = 'Whiteboard',
  Patient = 'Patient',
}

export type SortPageInput = {
  sortField: AllowedSortField[]
  sortDirection: SortDirection[]
}

export enum AllowedSortField {
  Name = 'name',
  Order = 'order',
  Color = 'color',
  StartAt = 'start_at',
}

const SortOptionsRecord: Record<SortOptionsTextType, SortPageInput> = {
  [SortOptionsText.SortByNameAsc]: {
    sortField: [AllowedSortField.Name],
    sortDirection: [SortDirection.asc],
  },
  [SortOptionsText.SortByNameDesc]: {
    sortField: [AllowedSortField.Name],
    sortDirection: [SortDirection.desc],
  },
  [SortOptionsText.SortByOrderAsc]: {
    sortField: [AllowedSortField.Order, AllowedSortField.Name],
    sortDirection: [SortDirection.asc, SortDirection.asc],
  },
  [SortOptionsText.SortByOrderDesc]: {
    sortField: [AllowedSortField.Order, AllowedSortField.Name],
    sortDirection: [SortDirection.desc, SortDirection.asc],
  },
  [SortOptionsText.SortByColorAsc]: {
    sortField: [AllowedSortField.Color, AllowedSortField.Name],
    sortDirection: [SortDirection.asc, SortDirection.asc],
  },
  [SortOptionsText.SortByStartAtAsc]: {
    sortField: [AllowedSortField.StartAt, AllowedSortField.Name],
    sortDirection: [SortDirection.asc, SortDirection.asc],
  },
  [SortOptionsText.SortByStartAtDesc]: {
    sortField: [AllowedSortField.StartAt, AllowedSortField.Name],
    sortDirection: [SortDirection.desc, SortDirection.asc],
  },
}

export const useLocalStorageChangeSort = (typeName: sortType) => {
  const [{ organisationId }] = useOrganisation()

  const storage = useMemo(() => new Storage(organisationId), [organisationId])

  const [sortPageInput, setSortPageInput] = useState<SortPageInput>(
    SortOptionsRecord[SortOptionsEnum.SortByNameAsc],
  )

  const [selectedSortOption, setSelectedSortOption] = useState<SortOptionsEnum>(
    () =>
      storage.getItem(`selected${typeName}SelectedSortOption`) ??
      SortOptionsEnum.SortByNameAsc,
  )

  // no feature flag for PatientSort, only sort by name
  useEffect(() => {
    setSortPageInput(SortOptionsRecord[selectedSortOption])
  }, [selectedSortOption])

  const handleChangeSortOption = useCallback(
    (sortOption: SortOptionsEnum) => {
      storage.setItem(`selected${typeName}SelectedSortOption`, sortOption)
      setSelectedSortOption(sortOption)
    },
    [storage, typeName],
  )

  return {
    handleChangeSortOption,
    selectedSortOption,
    sortPageInput,
  }
}
