import { FieldReadFunction, gql } from '@apollo/client'
import { COLOR_FIELDS_SHORT } from 'components/Settings/Colors/graphql'
import {
  patient_patient_data as PatientData,
  patient_patient_data_active_consultations_items_color as ConsultationColor,
} from 'types/patient'

/* Set consult color hex to match patient color. The cache id will be wrong
   but is OK. There is no consult level subscription, so patient has the most
   up-to-date color value. Delete once we merge consultations in platform or
   there are consult subscriptions. */
export const readColor: FieldReadFunction<ConsultationColor> = (
  color,
  { variables, cache, isReference },
) => {
  const patientId: string = variables?.id ?? ''

  if (!patientId || !isReference(color)) return color

  const patientLevelColorHex = cache.readFragment<PatientData>({
    id: `Patient:${patientId}`,
    fragment: gql`
      fragment color on Patient {
        color
      }
    `,
  })?.color

  const consultColor = cache.readFragment<ConsultationColor>({
    id: color.__ref,
    fragment: COLOR_FIELDS_SHORT,
  })

  if (!patientLevelColorHex || !consultColor) return color

  return {
    ...consultColor,
    hex: patientLevelColorHex,
  }
}
