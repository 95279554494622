import React, { useEffect, useMemo, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormBreak,
  FormLabel,
  InformativeMessage,
  InputGroup,
  InputGroupText,
  NumericInput,
  Select,
  SwitchInput,
  toast,
} from 'components/common'
import { ScheduleTask } from 'components/common/Form/Sections'
import { Status } from 'components/common/Form/Status'
import { InputStatusType } from 'components/common/Form/utils'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { getProductMustHaveValue } from 'components/Task/utils/getTreatmentInfo'
import { ConditionalTreatmentDeleteBtn } from 'components/TreatmentSetup/SelectProductsScreen'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { isNil, omit, toPairs } from 'lodash'
import { Controller, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import flagsmith from 'react-native-flagsmith'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { weightFactor, WeightUnit } from 'src/constants/Weight'
import { useConfirm } from 'src/context/confirm'
import { useTimeResolution } from 'src/hocs/timeContext'
import { TimeSegmentMinutes } from 'src/hocs/types/time'
import { useRound } from 'src/hooks/useRound'
import { useStartTimeRefresh } from 'src/hooks/useStartTimeRefresh'
import { PermissionAction, ProductType } from 'src/types/globalTypes'

import {
  administrationMethodOptions,
  ConcentrationSetting,
  concentrationSettingOptions,
  concentrationVolumeUnitOptions,
  DEFAULT_REPEATING_INTERVAL_4_HOURS,
  dilutedConcentrationVolumeUnitOptions,
  perTimeOptions,
  perWeightUnitOptions,
  RepeatScheduleValue,
  routesOfAdminOptions,
  specialDosageWeightUnitOptions,
  standardWeightUnitOptions,
  totalVolumeUnitOptions,
  volumeFactor,
  VolumeUnit,
  volumeUnitOptions,
  weightUnitOptions as allWeightUnitOptions,
} from './data'
import { CRIOffFormData, MedicationFormProps, ScheduleFields } from './types'
import { extractDosageInfo } from './utils/extractDosageInfo'
import {
  calculateDosageML,
  checkIsFluidMedication,
  getNormedConcentration,
  getNormedDosage,
  getNormedTotalDosage,
  getNormedTotalVolume,
  getNormedVol,
  getNormedWeight,
} from './utils/getCalculations'
import { getDefaultDosageValues } from './utils/getDefaultDosageValues'
import { getDefaultDosageWeightUnit } from './utils/getDefaultDosageWeightUnit'
import { getCRIOffFormSchema } from './utils/getTreatmentFormSchema'
import {
  isNonStandardWeightUnits,
  isSpecialDosageWeightUnits,
  isStandardWeightUnits,
} from './utils/isNonStandardWeightUnits'
import { useSafeDosageRange } from './utils/useSafeDosageRange'
import { getProductDefaultInstructions } from 'components/TreatmentForm/utils/isCustomProduct'
import { PermissionSwitchInput } from 'components/common/Permission'
import { SINGLE_TASK } from 'components/FrequencySelector/data'
import { SaveTreatmentButton } from './SaveTreatmentButton'
import { BillingSection } from 'components/Task/common/BillingSection'
import { useRouteOfAdministrations } from 'components/RouteOfAdministration/useRouteOfAdministrations'
import { PatientWeight } from './components/PatientWeight'
import { Instructions } from './components/Instructions'

type Props = MedicationFormProps<CRIOffFormData> & {
  renderCRISwitch: () => JSX.Element
}

// TODO: CRIOffForm, CRIOnForm, and FluidDosageForm contain too many duplicated types and functions, and components.
// Should do refactor and extract after we deprecate the old MedicineDosageForm
export const CRIOffForm: React.FC<Props> = ({
  defaultOverrides,
  hasPIMSIntegration = false,
  isEdit = false,
  isNewSheet = false,
  approvalStatus, // forgive me for adding even more mess to this godawful file
  onSave,
  patientWeight: defaultPatientWeight,
  patientWeightUnit: defaultPatientWeightUnit,
  patientWeightUpdatedAt,
  product,
  submitting = false,
  deleteTreatment,
  shouldDisableGeneralAutoCompleted = false,
  renderCRISwitch,
  handleCreateSheetModal,
  submitTitle,
  styleForWebOriOS,
  isCubexProductBillable,
}) => {
  const { t } = useTranslation()
  const confirm = useConfirm()
  const round = useRound()
  const startTime = useStartTimeRefresh()
  const { timeSegment } = useTimeResolution()
  const footStyle = styleForWebOriOS
    ? styles.footer
    : styles.footerForCreatingSheet

  const [isUnitsBilledPerTaskModified, setIsUnitsBilledPerTaskModified] =
    useState<boolean>(
      !!defaultOverrides?.unitsBilledPerTask &&
        defaultOverrides?.unitsBilledPerTask !== defaultOverrides?.totalVolume,
    )

  const [isDosageInfoModified, setIsDosageInfoModified] = useState<boolean>(
    !!defaultOverrides?.dosagePerWeightUnit ||
      !!defaultOverrides?.dosageWeightUnit ||
      !!defaultOverrides?.dosageRate,
  )

  const dosageInfo = product.medicine_dosage_info
  const {
    productConcentration,
    productConcentrationWeightUnit,
    productConcentrationVolumeUnit,
    productAdministrationRoute,
    dilutedRatioFactor,
    dilutedRatioVolume,
    productWeightUnit,
  } = extractDosageInfo(dosageInfo)

  const noConcentrationFromProductError =
    productConcentration &&
    productConcentrationWeightUnit &&
    productConcentrationVolumeUnit
      ? null
      : t('form.noValue')

  const isMedication = product.type === ProductType.MEDICATION

  const isFluidMedication = checkIsFluidMedication(
    productConcentrationVolumeUnit,
  )

  const {
    activeRouteOfAdministrationsSelectOptions,
    activeRouteOfAdministrations,
    getDefaultRouteOfAdmin,
    shouldUseRouteOfAdministrationSetting,
  } = useRouteOfAdministrations()

  const shouldTriggerRouteOfAdministrationValidation =
    shouldUseRouteOfAdministrationSetting && isMedication

  const validationSchema = getCRIOffFormSchema(
    t,
    activeRouteOfAdministrations,
    shouldTriggerRouteOfAdministrationValidation,
    isMedication,
  )

  const isNonStandardConcentrationWeightUnit = isNonStandardWeightUnits(
    productConcentrationWeightUnit,
  )
  const shouldConcentrationWeightUnitOverrideDefault =
    isNonStandardConcentrationWeightUnit &&
    productConcentrationWeightUnit !== productWeightUnit

  const defaultDosageValues = {
    dosageRate: null,
    dosageWeightUnit: shouldConcentrationWeightUnitOverrideDefault
      ? productConcentrationWeightUnit
      : getDefaultDosageWeightUnit(productConcentrationWeightUnit),
    dosagePerWeightUnit: perWeightUnitOptions[0].value,
  }

  const { dosageValues = defaultDosageValues, defaultDosageValuesHasError } =
    useMemo(
      () =>
        getDefaultDosageValues(
          shouldConcentrationWeightUnitOverrideDefault,
          dosageInfo,
        ),
      [shouldConcentrationWeightUnitOverrideDefault, dosageInfo],
    )

  // when treatment template set to wrong weight unit.
  const shouldReplaceOverrideWeightUnit =
    isNonStandardConcentrationWeightUnit &&
    isStandardWeightUnits(defaultOverrides?.dosageWeightUnit as WeightUnit)

  const nonStandardWeightUnitOverride = shouldReplaceOverrideWeightUnit
    ? { dosageWeightUnit: productConcentrationWeightUnit }
    : {}

  const defaultValues = {
    concentration: productConcentration,
    concentrationWeightUnit: productConcentrationWeightUnit,
    concentrationVolumeUnit: productConcentrationVolumeUnit,
    concentrationSetting: noConcentrationFromProductError
      ? ConcentrationSetting.IGNORE
      : null,
    unitsBilledPerTask: 1,
    productType: product.type,
    patientWeight: defaultPatientWeight,
    patientWeightUnit: defaultPatientWeightUnit,
    routesOfAdmin: getDefaultRouteOfAdmin(
      productAdministrationRoute,
      productConcentrationVolumeUnit,
    ),
    isDiluted: false,
    medicationVolumeUnit: productConcentrationVolumeUnit,
    salineVolumeUnit: productConcentrationVolumeUnit,
    dilutedConcentrationWeightUnit: productConcentrationWeightUnit,
    dilutedConcentrationVolumeUnit: productConcentrationVolumeUnit,
    requiredRatioLHS: dilutedRatioFactor,
    requiredRatioRHS: dilutedRatioVolume,
    startAtDate: startTime,
    isRepeating: RepeatScheduleValue.SINGLE, // TODO: Deprecated after full switch to FrequencySelector

    frequencyInput: SINGLE_TASK,
    repeating: 0,
    repeatUntilDate: 'discharge',
    reschedule: true,
    enableStaffedHour: true,
    isBillable: !!product.is_billable && !isCubexProductBillable,
    instructions: getProductDefaultInstructions(product), // Use description for custom products.
    isInstructionsImportant: false,
    administrationMethod: administrationMethodOptions[0].value,
    fluidVolume: null,
    fluidVolumeUnit: volumeUnitOptions[0].value,
    infusionRateVolumeUnit: volumeUnitOptions[0].value,
    infusionRateWeightUnit: perWeightUnitOptions[0].value,
    infusionRateTimeUnit: perTimeOptions[0].value,
    infusionRate: null,
    infusionRateTotal: null,
    infusionRateTotalVolumeUnit: volumeUnitOptions[0].value,
    infusionRateTotalTimeUnit: perTimeOptions[0].value,
    minutes: 0,
    hours: 0,
    days: 0,
    totalVolume: null,
    totalDosage: null,
    isAdditionalSaline: true,
    additionalSaline: null,
    isAutoCompleted: false,
    ivBagSize: null,
    ivBagSizeUnit: volumeUnitOptions[0].value,
    doseRate: null,
    diluentUsed: null,
    ...dosageValues,
    ...defaultOverrides,
    timeWindow: defaultOverrides?.timeWindow ?? TimeSegmentMinutes[timeSegment],

    ...nonStandardWeightUnitOverride,
    // set totalVolumeUnit a default value when it's null in edit mode.
    totalVolumeUnit:
      defaultOverrides?.totalVolumeUnit ??
      (!isEdit && noConcentrationFromProductError && isFluidMedication
        ? 'ml'
        : productConcentrationVolumeUnit),
  } as CRIOffFormData

  const {
    setValue,
    getValues,
    control,
    trigger, // trigger for validation
    handleSubmit,
    watch,
    formState: { dirtyFields, errors },
  } = useForm<CRIOffFormData>({
    defaultValues,
    criteriaMode: 'firstError',
    resolver: yupResolver(validationSchema),
  })

  const handleOnSave = (data: CRIOffFormData) => {
    onSave({
      ...defaultValues,
      ...(shouldShowRescheduleSwitch && !data.reschedule
        ? omit(data, [
            'isRepeating',
            'repeating',
            'repeatUntilDate',
            'startAtDate',
            'timeWindow',
            'enableStaffedHour',
            'frequencyInput',
          ] satisfies Array<keyof ScheduleFields>)
        : data),
    })
  }

  const handleOnError: SubmitErrorHandler<CRIOffFormData> = submitErrors => {
    toPairs(submitErrors).forEach(errorPair => {
      const errorName = errorPair[0]
      const errorMessage =
        'message' in errorPair[1] ? errorPair[1].message : 'no message'
      toast.error(`${errorName}: ${errorMessage}`)
    })
  }
  const watchedIsDiluted = watch('isDiluted', defaultValues.isDiluted)
  const watchedIsRepeating = watch('isRepeating', defaultValues.isRepeating)
  const watchedRepeating = watch('repeating', defaultValues.repeating)
  const watchedFrequencyInput = watch('frequencyInput')
  const watchedReschedule = watch('reschedule', defaultValues.reschedule)
  const watchedConcentrationSetting = watch(
    'concentrationSetting',
    defaultValues.concentrationSetting,
  )
  const watchedDosageWeightUnit = watch(
    'dosageWeightUnit',
    defaultValues.dosageWeightUnit,
  )
  const watchRoutesOfAdmin = watch('routesOfAdmin')

  const isConcentrationIgnore =
    watchedConcentrationSetting === ConcentrationSetting.IGNORE

  const shouldAutofillUnitsBilledPerTask =
    flagsmith.hasFeature(FeatureFlagNames.AutofillMedQtyBilledPerTask) &&
    !isConcentrationIgnore &&
    !isUnitsBilledPerTaskModified

  const shouldShowRescheduleSwitch = !isNewSheet && isEdit

  const hasErrors = Object.keys(errors).length > 0

  const isDosageWeightUnitML = watchedDosageWeightUnit === WeightUnit.ML

  // sync concentration and dosage weight-unit when non-standard
  const handleDosageConcentrationWeight = (
    changedFieldName: 'dosageWeightUnit' | 'concentrationWeightUnit',
    newValue: WeightUnit,
  ) => {
    setValue(changedFieldName, newValue)

    // If dosage unit is ml, no change to concentration
    if (isSpecialDosageWeightUnits(newValue)) {
      setValue('totalVolumeUnit', VolumeUnit.ML)
      return
    }

    const syncedFieldName =
      changedFieldName === 'dosageWeightUnit'
        ? 'concentrationWeightUnit'
        : 'dosageWeightUnit'

    const syncedFieldValue = getValues(syncedFieldName)

    if (
      isNonStandardWeightUnits(newValue) ||
      isNonStandardWeightUnits(syncedFieldValue)
    ) {
      setValue(syncedFieldName, newValue)
    }
  }

  // Disable dosage unit only when concentration unit is non-standard and concentration is valid
  const shouldDisableWeightUnit =
    isNonStandardConcentrationWeightUnit && isNil(watchedConcentrationSetting)

  const dosageUnitOptions = useMemo(() => {
    if (shouldDisableWeightUnit) {
      return allWeightUnitOptions
    }
    const shouldRenderAllOptions = !isNil(watchedConcentrationSetting)

    if (shouldRenderAllOptions) {
      return [...specialDosageWeightUnitOptions, ...allWeightUnitOptions]
    }

    return [...specialDosageWeightUnitOptions, ...standardWeightUnitOptions]
  }, [shouldDisableWeightUnit, watchedConcentrationSetting])

  const getCurrentNormedWeight = () => {
    const [patientWeight, patientWeightUnit] = getValues([
      'patientWeight',
      'patientWeightUnit',
    ])
    return getNormedWeight(patientWeight, patientWeightUnit)
  }

  const getCurrentNormedConcentration = () => {
    const [
      isDiluted,
      dilutedConcentration,
      concentration,
      concentrationWeightUnit,
      concentrationVolumeUnit,
    ] = getValues([
      'isDiluted',
      'dilutedConcentration',
      'concentration',
      'concentrationWeightUnit',
      'concentrationVolumeUnit',
    ])

    return getNormedConcentration(
      isDiluted ? dilutedConcentration : concentration,
      concentrationWeightUnit,
      concentrationVolumeUnit,
    )
  }

  const getCurrentNormedTotalVolumeUnit = (): number => {
    const totalVolumeUnit = getValues('totalVolumeUnit')
    if (!totalVolumeUnit) return 1
    return volumeFactor[totalVolumeUnit] ?? 1
  }

  const getCurrentNormedDosage = () => {
    const [dosageRate, dosageWeightUnit, dosagePerWeightUnit] = getValues([
      'dosageRate',
      'dosageWeightUnit',
      'dosagePerWeightUnit',
    ])
    return getNormedDosage(dosageRate!, dosageWeightUnit, dosagePerWeightUnit)
  }

  const onDosageRateChange = () => {
    !isDosageInfoModified && setIsDosageInfoModified(true)
    if (watch('dosageWeightUnit') === WeightUnit.ML) {
      calculateResult()
      return
    }
    if (isConcentrationIgnore) {
      return
    }
    calculateResult()
  }

  const calculateResult = () => {
    switch (watch('dosageWeightUnit')) {
      case WeightUnit.ML:
        return calculateMLResult()
      default:
        return calculateDefaultResult()
    }
  }

  const calculateMLResult = () => {
    const [
      patientWeight,
      patientWeightUnit,
      dosageRate,
      dosageWeightUnit,
      dosagePerWeightUnit,
      dilutedConcentration,
    ] = getValues([
      'patientWeight',
      'patientWeightUnit',
      'dosageRate',
      'dosageWeightUnit',
      'dosagePerWeightUnit',
      'dilutedConcentration',
    ])

    if (isNil(dosagePerWeightUnit) || isNil(patientWeightUnit)) return

    const totalVolume = calculateDosageML({
      patientWeight,
      patientWeightUnit,
      dosageRate,
      dosageWeightUnit,
      dosagePerWeightUnit,
      round,
    })
    setValue('totalVolume', totalVolume)
    if (shouldAutofillUnitsBilledPerTask && !dilutedConcentration) {
      setValue('unitsBilledPerTask', totalVolume)
    }
    setValue('totalDosage', null)
    trigger()
  }

  const calculateDefaultResult = () => {
    if (isConcentrationIgnore) {
      // Trigger validation for unitsBilledPerTask
      trigger()
      return
    }
    const [patientWeight, dosageRate, dosageWeightUnit, dilutedConcentration] =
      getValues([
        'patientWeight',
        'dosageRate',
        'dosageWeightUnit',
        'dilutedConcentration',
      ])

    let totalDosage = null
    let totalVolume = null

    const normedConcentration = getCurrentNormedConcentration()
    if (!isNil(dosageRate) && !!patientWeight && !!normedConcentration) {
      const normedWeight = getCurrentNormedWeight()
      const normedDosage = getCurrentNormedDosage()

      totalDosage = round(
        getNormedTotalDosage(normedDosage, normedWeight, dosageWeightUnit),
      )

      const normedTotalVolume = getNormedTotalVolume(
        normedDosage,
        normedWeight,
        normedConcentration,
        getCurrentNormedTotalVolumeUnit(),
      )
      totalVolume = normedTotalVolume === null ? null : round(normedTotalVolume)
    }

    setValue('totalDosage', totalDosage)
    setValue('totalVolume', totalVolume)
    if (
      shouldAutofillUnitsBilledPerTask &&
      !dilutedConcentration &&
      !!totalVolume
    ) {
      setValue('unitsBilledPerTask', totalVolume)
    }
    trigger()
  }

  const onTotalDosageChange = () => {
    if (isConcentrationIgnore) {
      return
    }
    const [patientWeight, dosageWeightUnit, dosagePerWeightUnit, totalDosage] =
      getValues([
        'patientWeight',
        'dosageWeightUnit',
        'dosagePerWeightUnit',
        'totalDosage',
      ])

    let dosageRate = null
    let totalVolume = null
    const normedConcentration = getCurrentNormedConcentration()
    if (!isNil(totalDosage) && !!patientWeight && !!normedConcentration) {
      const normedWeight = getCurrentNormedWeight()
      const normedTotalDosage = totalDosage * weightFactor[dosageWeightUnit]

      dosageRate = round(
        ((normedTotalDosage / normedWeight) *
          weightFactor[dosagePerWeightUnit]) /
          weightFactor[dosageWeightUnit],
      )

      totalVolume = round(
        normedTotalDosage /
          normedConcentration /
          getCurrentNormedTotalVolumeUnit(),
      )
    }
    setValue('dosageRate', dosageRate)
    setValue('totalVolume', totalVolume)
    if (shouldAutofillUnitsBilledPerTask) {
      setValue('unitsBilledPerTask', totalVolume)
    }
    trigger()
  }

  //  QTY to be billed not to be overwritten if it has already had an entry in it that is [NOT EQUAL TO]  Total Volume
  const onUnitsBilledPerTaskChange = () => {
    const [unitsBilledPerTask, totalVolume] = getValues([
      'unitsBilledPerTask',
      'totalVolume',
    ])
    if (unitsBilledPerTask === totalVolume) {
      setIsUnitsBilledPerTaskModified(false)
    } else {
      setIsUnitsBilledPerTaskModified(true)
    }
    trigger()
  }
  const onTotalVolumeChange = () => {
    const [patientWeight, dosageWeightUnit, dosagePerWeightUnit, totalVolume] =
      getValues([
        'patientWeight',
        'dosageWeightUnit',
        'dosagePerWeightUnit',
        'totalVolume',
      ])

    if (shouldAutofillUnitsBilledPerTask) {
      setValue('unitsBilledPerTask', totalVolume)
    }

    if (isConcentrationIgnore) {
      return
    }
    let dosageRate = null
    let totalDosage = null
    const normedConcentration = getCurrentNormedConcentration()
    if (!isNil(totalVolume) && !!patientWeight && !!normedConcentration) {
      const normedWeight = getCurrentNormedWeight()
      const normedTotalVolume = isFluidMedication
        ? totalVolume * volumeFactor.ml
        : totalVolume
      const normedTotalDosage = normedTotalVolume * normedConcentration
      const normedDosage = normedTotalDosage / normedWeight

      dosageRate = round(
        (normedDosage / weightFactor[dosageWeightUnit]) *
          weightFactor[dosagePerWeightUnit],
      )

      totalDosage = round(
        (normedTotalDosage / weightFactor[dosageWeightUnit]) *
          getCurrentNormedTotalVolumeUnit(),
      )
    }

    setValue('dosageRate', dosageRate)
    setValue('totalDosage', totalDosage)
    trigger()
  }

  const calculateDilutedConcentration = () => {
    const [requiredRatioLHS, requiredRatioRHS] = getValues([
      'requiredRatioLHS',
      'requiredRatioRHS',
    ])
    let dilutedConcentration = null
    if (!isNil(requiredRatioLHS) && !isNil(requiredRatioRHS)) {
      dilutedConcentration = round(
        (productConcentration! * requiredRatioLHS) /
          (requiredRatioLHS + requiredRatioRHS),
      )
    }
    setValue('dilutedConcentration', Number(dilutedConcentration))
  }

  const calculateDilutantVolume = () => {
    const [
      medicationVolume,
      medicationVolumeUnit,
      salineVolumeUnit,
      requiredRatioLHS,
      requiredRatioRHS,
    ] = getValues([
      'medicationVolume',
      'medicationVolumeUnit',
      'salineVolumeUnit',
      'requiredRatioLHS',
      'requiredRatioRHS',
    ])
    let salineVolume = null
    if (
      !isNil(medicationVolume) &&
      !isNil(requiredRatioLHS) &&
      !isNil(requiredRatioRHS)
    ) {
      salineVolume = round(
        (getNormedVol(medicationVolume, medicationVolumeUnit) *
          requiredRatioRHS) /
          requiredRatioLHS /
          volumeFactor[salineVolumeUnit],
      )
    }
    setValue('salineVolume', salineVolume)
    trigger()
  }

  const calculateMedicationVolume = () => {
    const [
      medicationVolumeUnit,
      salineVolume,
      salineVolumeUnit,
      requiredRatioLHS,
      requiredRatioRHS,
    ] = getValues([
      'medicationVolumeUnit',
      'salineVolume',
      'salineVolumeUnit',
      'requiredRatioLHS',
      'requiredRatioRHS',
    ])
    let medicationVolume = null
    if (
      !isNil(salineVolume) &&
      !isNil(requiredRatioLHS) &&
      !isNil(requiredRatioRHS)
    ) {
      medicationVolume = round(
        (getNormedVol(salineVolume, salineVolumeUnit) * requiredRatioLHS) /
          requiredRatioRHS /
          volumeFactor[medicationVolumeUnit],
      )
    }
    setValue('medicationVolume', medicationVolume)
    setValue('unitsBilledPerTask', medicationVolume)
    trigger()
  }

  const toggleConcentrationOverridden = (
    overriddenSetting: ConcentrationSetting,
  ) => {
    const isOverridden = overriddenSetting === ConcentrationSetting.OVERRIDE
    const dosageWeightUnit = getValues('dosageWeightUnit')

    requestAnimationFrame(() => {
      if (isOverridden) {
        setValue('concentration', null)
        setValue(
          'concentrationVolumeUnit',
          concentrationVolumeUnitOptions[0].value,
        )
        setValue(
          'concentrationWeightUnit',
          dosageWeightUnit === WeightUnit.MEQ ? WeightUnit.MEQ : WeightUnit.MG,
        )
      } else {
        setValue('concentration', defaultValues.concentration)
        setValue(
          'concentrationVolumeUnit',
          defaultValues.concentrationVolumeUnit,
        )
        setValue(
          'concentrationWeightUnit',
          defaultValues.concentrationWeightUnit,
        )
        setValue('totalVolumeUnit', defaultValues.totalVolumeUnit)
      }
      trigger()
    })
  }

  const isFirstRenderAndEdit = useRef(isEdit)

  // Populate calculated data on init and on certain field changes
  useEffect(() => {
    // variables in calculation have rounding problems when editing
    if (isFirstRenderAndEdit.current) {
      isFirstRenderAndEdit.current = false
      return
    }

    calculateResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shouldUseRouteOfAdministrationSetting) {
      trigger('routesOfAdmin')
    }
  }, [trigger, watchRoutesOfAdmin, shouldUseRouteOfAdministrationSetting])

  // TODO tidy up 'startAtDate' code once all customers are on new_scheduling_ui
  // TODO: 19 Jul 2023 - BM - All customers now on new schedule UI
  const isFirstRenderForStartAtDate = useRef(true)

  useEffect(() => {
    if (isEdit) {
      return
    }

    const defaultOverridesStartAtDate = defaultOverrides?.startAtDate
    // defaultOverridesStartAtDate should overwrite the startAtDate when first time render
    if (isFirstRenderForStartAtDate.current && defaultOverridesStartAtDate) {
      setValue('startAtDate', defaultOverridesStartAtDate)
      isFirstRenderForStartAtDate.current = false
      return
    }

    const { isDirty, isTouched } = control.getFieldState('startAtDate')
    // Only keep updating the refresh timer if the field has not been touched or modified.
    if (!isTouched && !isDirty) {
      setValue('startAtDate', startTime)
    }
  }, [startTime, control, setValue, isEdit, defaultOverrides?.startAtDate])

  useEffect(() => {
    switch (watchedFrequencyInput?.type) {
      case 'NO_TASKS': {
        setValue('isRepeating', RepeatScheduleValue.NONE)
        break
      }
      case 'SINGLE_TASK': {
        setValue('isRepeating', RepeatScheduleValue.SINGLE)
        break
      }
      default:
        setValue('isRepeating', RepeatScheduleValue.REPEATING)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFrequencyInput])

  useEffect(() => {
    if (watchedIsRepeating !== RepeatScheduleValue.REPEATING) {
      return
    }
    const repeating = getValues('repeating')
    if (!repeating) {
      setValue('repeating', DEFAULT_REPEATING_INTERVAL_4_HOURS.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedIsRepeating, watchedRepeating])

  const mustHaveValueProduct = getProductMustHaveValue({
    product: { ...product, origin_id: product.origin_id ?? null },
  })
  const shouldDisableAutoCompleted =
    shouldDisableGeneralAutoCompleted || mustHaveValueProduct

  useEffect(() => {
    if (shouldDisableAutoCompleted) {
      setValue('isAutoCompleted', false)
    }
  }, [shouldDisableAutoCompleted, setValue])

  const [dosageRateValue, dosageWeightUnitValue, dosagePerWeightUnitValue] =
    watch(['dosageRate', 'dosageWeightUnit', 'dosagePerWeightUnit'])

  const safeDosageRangeInput = {
    dosageInfo,
    defaultDosageValuesHasError,
    dosageRateValue,
    dosageWeightUnitValue,
    dosagePerWeightUnitValue,
    productName: product.name,
  }
  const {
    invalidSafeDoseRangeErrorMessage,
    safeDoseRangeMessage,
    dosageRateOutOfSafeRangeWarningMessage,
    safeDoseRangeHasError,
  } = useSafeDosageRange(safeDosageRangeInput)

  const handleSave = async () => {
    // VR-7773: if the user edits treatment and doesn't alter a value which is out of the safe dosage range, don't warn them again
    const isDosageFieldsDirty =
      dirtyFields.dosageRate ||
      dirtyFields.dosageWeightUnit ||
      dirtyFields.dosagePerWeightUnit
    const isValueOutOfSafeDoseRange = !!dosageRateOutOfSafeRangeWarningMessage
    const savedDataExists = !!defaultOverrides
    // VR-8028: handleCreateSheetModal will only be defined if the form is opened through create anaesthesia sheet screen, in which case the warning popup should always show
    const hasUserConfirmedUnsafeDoseRange =
      savedDataExists && !isDosageFieldsDirty && !handleCreateSheetModal
    if (!isValueOutOfSafeDoseRange || hasUserConfirmedUnsafeDoseRange) {
      handleSubmit(handleOnSave, handleOnError)()
      return
    }
    if (!!handleCreateSheetModal) handleCreateSheetModal(false)
    await confirm({
      title: t('form.confirmDosageOutsideSafeRange.title'),
      text: t('form.confirmDosageOutsideSafeRange.text', {
        dosageRate: dosageRateValue,
        weightUnit: dosageWeightUnitValue,
        perWeightUnit: dosagePerWeightUnitValue,
        dosageSafeRangeText: safeDoseRangeMessage.split(': ')[1],
      }),
      okText: t('form.confirmDosageOutsideSafeRange.ok'),
      cancelText: t('form.confirmDosageOutsideSafeRange.cancel'),
    })
      .then(handleSubmit(handleOnSave, handleOnError))
      .catch(() => {
        if (!!handleCreateSheetModal) {
          const currentFormState = getValues()
          handleCreateSheetModal(true, currentFormState)
        }
      })
  }

  const qtyBilledPermissionAction = isEdit
    ? PermissionAction.TREATMENT_QUANTITY_BILLED__CHANGE
    : PermissionAction.TREATMENT_QUANTITY_BILLED__ADD

  return (
    <>
      <KeyboardAwareScrollView>
        {isMedication ? (
          <>
            {renderCRISwitch()}
            <FormLabel text={t('addTreatment:patientInfo')} />
            <PatientWeight
              control={control}
              patientWeightUpdatedAt={patientWeightUpdatedAt}
              handleChange={calculateResult}
            />
            <FormLabel text={t('addTreatment:administration')} />

            {/* Route of administration */}
            {shouldUseRouteOfAdministrationSetting ? (
              <Status status={createErrorStatus(errors.routesOfAdmin?.message)}>
                <ControlledSelect
                  control={control}
                  dialog={false}
                  label={t('addTreatment:routeOfAdministration')}
                  options={activeRouteOfAdministrationsSelectOptions}
                  name="routesOfAdmin"
                />
              </Status>
            ) : (
              <ControlledSelect
                control={control}
                dialog={false}
                label={t('addTreatment:routeOfAdministration')}
                options={routesOfAdminOptions}
                name="routesOfAdmin"
              />
            )}

            <FormLabel text={t('addTreatment:calculation')} />
            <Status
              status={createErrorStatus(
                defaultDosageValuesHasError
                  ? t('form.invalidDefaultDosageValues')
                  : dosageRateOutOfSafeRangeWarningMessage,
                (defaultDosageValuesHasError && !isDosageInfoModified) ||
                  !!dosageRateOutOfSafeRangeWarningMessage,
                InputStatusType.Warning,
              )}
            >
              <Status
                status={createErrorStatus(
                  errors.dosageRate?.message
                    ? errors.dosageRate?.message
                    : invalidSafeDoseRangeErrorMessage,
                  !!invalidSafeDoseRangeErrorMessage ||
                    !!errors.dosageRate?.message,
                  InputStatusType.Error,
                )}
              >
                <InputGroup>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NumericInput
                        label={t('addTreatment:doseRate')}
                        value={value}
                        onChange={newValue => {
                          onChange(newValue)
                          onDosageRateChange()
                        }}
                        style={styles.flex}
                        required={!isConcentrationIgnore}
                      />
                    )}
                    name="dosageRate"
                  />
                  <ControlledSelect
                    control={control}
                    options={dosageUnitOptions}
                    dialog={false}
                    onChangeListener={newValue => {
                      handleDosageConcentrationWeight(
                        'dosageWeightUnit',
                        newValue,
                      )
                      onDosageRateChange()
                    }}
                    a11yLabel={t('addTreatment:dosageWeightUnit')}
                    disabled={shouldDisableWeightUnit}
                    name={'dosageWeightUnit'}
                  />
                  <ControlledSelect
                    control={control}
                    dialog={false}
                    options={perWeightUnitOptions}
                    onChangeListener={newValue => {
                      setValue('dosagePerWeightUnit', newValue)
                      onDosageRateChange()
                    }}
                    a11yLabel={t('addTreatment:dosagePerWeightUnit')}
                    name={'dosagePerWeightUnit'}
                  />
                </InputGroup>
              </Status>
            </Status>
            {!defaultDosageValuesHasError &&
              !safeDoseRangeHasError &&
              !!safeDoseRangeMessage && (
                <InformativeMessage messageText={safeDoseRangeMessage} />
              )}
            <FormBreak />
            {/* Total dosage */}
            <InputGroup>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericInput
                    label={t('addTreatment:totalDosagePerTask')}
                    onChange={newValue => {
                      onChange(newValue)
                      onTotalDosageChange()
                    }}
                    style={styles.flex}
                    disabled={isDosageWeightUnitML}
                    value={value}
                  />
                )}
                name="totalDosage"
              />
              <InputGroupText
                text={watchedDosageWeightUnit}
                textStyle={
                  isDosageWeightUnitML ? styles.disableLabel : styles.basicLabel
                }
              />
            </InputGroup>

            <InputGroup>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericInput
                    label={t(
                      isFluidMedication
                        ? 'addTreatment:totalVolume'
                        : 'addTreatment:totalAmount',
                    )}
                    onChange={newValue => {
                      onChange(newValue)
                      onTotalVolumeChange()
                    }}
                    style={styles.flex}
                    value={value}
                  />
                )}
                name="totalVolume"
              />
              <Controller
                control={control}
                name="totalVolumeUnit"
                render={({ field: { onChange, value } }) => {
                  if (isConcentrationIgnore) {
                    return (
                      <Select
                        dialog={false}
                        options={totalVolumeUnitOptions}
                        selected={value}
                        onChange={onChange}
                        disabled={isDosageWeightUnitML}
                      />
                    )
                  }

                  // limits 'table' to 'tab' - if/else in getTotalPrescribedVolume affects
                  // the task pane AFTER you have added a tablet. This is for the task pane BEFORE
                  // you click 'add to sheet'
                  if (value! === VolumeUnit.TABLE) {
                    return <InputGroupText text={value!.slice(0, 3)} />
                  }
                  return <InputGroupText text={value!} />
                }}
              />
            </InputGroup>
          </>
        ) : null}
        {/* Show if initally possible (ie. is_billable at product level), or if
             a conditional where we should try and setup (some) products */}
        {(product?.is_billable || defaultValues.isBillable) &&
        hasPIMSIntegration ? (
          <>
            <FormLabel text={t('addTreatment:billing.title')} />
            <BillingSection
              control={control}
              handleChangeIsBillable={(val: boolean) => {
                setValue('isBillable', val)
                // Trigger to set if unitsBilledPerTask is required
                trigger()
              }}
              handleChangeQtyBilled={(val: number | null) => {
                setValue('unitsBilledPerTask', val)
                onUnitsBilledPerTaskChange()
              }}
              qtyBilledPermissionAction={qtyBilledPermissionAction}
              isCubexBillable={!!isCubexProductBillable}
              qtyBilledFieldName="unitsBilledPerTask"
              qtyBilledErrorMessage={errors.unitsBilledPerTask?.message}
            />
          </>
        ) : null}

        {isMedication ? (
          <>
            <FormBreak />
            <FormLabel text={t('addTreatment:concentration')} />
            {isFluidMedication && !noConcentrationFromProductError ? (
              <>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PermissionSwitchInput
                      permissionAction={
                        PermissionAction.TREATMENT_IGNORE_CONCENTRATION__CHANGE
                      }
                      label={t('addTreatment:ignoreConcentration')}
                      onChangeValue={newValue => {
                        onChange(newValue ? ConcentrationSetting.IGNORE : null)
                        setValue('isDiluted', false)
                        trigger()
                      }}
                      value={value === ConcentrationSetting.IGNORE}
                    />
                  )}
                  name="concentrationSetting"
                />
                {!isConcentrationIgnore && (
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <SwitchInput
                        label={t('addTreatment:applyDiluted')}
                        onChangeValue={newValue => {
                          onChange(newValue)
                          calculateResult()
                        }}
                        value={value}
                      />
                    )}
                    name="isDiluted"
                  />
                )}
              </>
            ) : null}
            {noConcentrationFromProductError ? (
              <Status
                status={createErrorStatus(
                  noConcentrationFromProductError,
                  true,
                  InputStatusType.Warning,
                )}
              >
                <ControlledSelect
                  control={control}
                  onChangeListener={toggleConcentrationOverridden}
                  options={concentrationSettingOptions}
                  name="concentrationSetting"
                />
              </Status>
            ) : null}
            {/* TODO: show noConcentrationFromProductError somewhere as message */}
            {/* Concentration */}
            {!isConcentrationIgnore && (
              <Status status={createErrorStatus(errors.concentration?.message)}>
                <InputGroup>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NumericInput
                        disabled={!noConcentrationFromProductError}
                        label={
                          watchedIsDiluted
                            ? t('addTreatment:originalConc')
                            : t('addTreatment:concentration')
                        }
                        onChange={newValue => {
                          onChange(newValue)
                          calculateResult()
                        }}
                        value={value}
                        style={styles.bigFlex}
                      />
                    )}
                    name="concentration"
                  />

                  <Controller
                    control={control}
                    render={({ field: { value } }) => (
                      <Select
                        dialog={false}
                        disabled={
                          !noConcentrationFromProductError ||
                          shouldDisableWeightUnit
                        }
                        selected={value}
                        options={allWeightUnitOptions}
                        onChange={newValue => {
                          handleDosageConcentrationWeight(
                            'concentrationWeightUnit',
                            newValue,
                          )

                          calculateResult()
                        }}
                      />
                    )}
                    name="concentrationWeightUnit"
                  />

                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        dialog={false}
                        disabled={!noConcentrationFromProductError}
                        selected={value}
                        options={concentrationVolumeUnitOptions}
                        onChange={newValue => {
                          onChange(newValue)
                          setValue('totalVolumeUnit', newValue)
                          calculateResult()
                        }}
                      />
                    )}
                    name="concentrationVolumeUnit"
                  />
                </InputGroup>
              </Status>
            )}
            {watchedIsDiluted ? (
              <>
                <InputGroup>
                  <InputGroupText
                    text={t('addTreatment:ratio')}
                    containerStyle={styles.container}
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NumericInput
                        disabled={!watchedIsDiluted}
                        label={'pseudo'}
                        accessibilityLabel={'Required Ratio LHS Text Input'}
                        value={value}
                        onChange={newValue => {
                          onChange(newValue)
                          calculateDilutedConcentration()
                          calculateDilutantVolume()
                          calculateResult()
                        }}
                        style={styles.numInput}
                        numberOfLines={1}
                      />
                    )}
                    name="requiredRatioLHS"
                  />
                  <InputGroupText text={':'} />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NumericInput
                        label="pseudo"
                        accessibilityLabel={'Required Ratio RHS Text Input'}
                        disabled={!watchedIsDiluted}
                        value={value}
                        onChange={newValue => {
                          onChange(newValue)
                          calculateDilutedConcentration()
                          calculateDilutantVolume()
                          calculateResult()
                        }}
                        style={styles.numInput}
                      />
                    )}
                    name="requiredRatioRHS"
                  />
                </InputGroup>
                {/* Medication Volume */}
                <Status
                  status={createErrorStatus(errors.medicationVolume?.message)}
                >
                  <InputGroup>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <NumericInput
                          disabled={!watchedIsDiluted}
                          style={styles.bigFlex}
                          label={t('addTreatment:medVol')}
                          value={value}
                          required={true}
                          onChange={newValue => {
                            onChange(newValue)
                            setValue('unitsBilledPerTask', newValue)
                            calculateDilutantVolume()
                          }}
                        />
                      )}
                      name="medicationVolume"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          dialog={false}
                          disabled={!watchedIsDiluted}
                          selected={value}
                          options={volumeUnitOptions}
                          onChange={newValue => {
                            onChange(newValue)
                            calculateDilutantVolume()
                          }}
                        />
                      )}
                      name="medicationVolumeUnit"
                    />
                  </InputGroup>
                </Status>
                {/* Dilutant Volume */}

                <InputGroup>
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NumericInput
                        disabled={!watchedIsDiluted}
                        style={styles.bigFlex}
                        label={t('addTreatment:dilutant')}
                        value={value}
                        onChange={newValue => {
                          onChange(newValue)
                          calculateMedicationVolume()
                        }}
                      />
                    )}
                    name="salineVolume"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        dialog={false}
                        disabled={!watchedIsDiluted}
                        selected={value}
                        options={volumeUnitOptions}
                        onChange={newValue => {
                          onChange(newValue)
                          calculateMedicationVolume()
                        }}
                      />
                    )}
                    name="salineVolumeUnit"
                  />
                </InputGroup>
                {/* Diluted Concentration */}
                <Status
                  status={createErrorStatus(
                    errors.dilutedConcentration?.message,
                  )}
                >
                  <InputGroup>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <NumericInput
                          disabled={!watchedIsDiluted}
                          label={t('addTreatment:diluted')}
                          value={value}
                          required={true}
                          onChange={newValue => {
                            onChange(newValue)
                            calculateDilutantVolume()
                            calculateResult()
                          }}
                          style={styles.flex}
                        />
                      )}
                      name="dilutedConcentration"
                    />

                    <Select
                      disabled={true}
                      hideChevron={true}
                      options={allWeightUnitOptions}
                      selected={productConcentrationWeightUnit}
                    />

                    <Select
                      disabled={true}
                      hideChevron={true}
                      options={dilutedConcentrationVolumeUnitOptions}
                      selected={productConcentrationVolumeUnit}
                    />
                  </InputGroup>
                </Status>
              </>
            ) : null}
          </>
        ) : null}
        {shouldShowRescheduleSwitch ? (
          <>
            <FormBreak />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <SwitchInput
                  label={'Reschedule'}
                  value={value}
                  onChangeValue={onChange}
                />
              )}
              name="reschedule"
            />
          </>
        ) : null}
        {!isNewSheet && (watchedReschedule || !isEdit) ? (
          <ScheduleTask
            control={control}
            isRepeating={watchedIsRepeating}
            frequencyInput={watchedFrequencyInput}
            trigger={trigger}
            isEdit={isEdit}
          />
        ) : null}

        {!shouldDisableAutoCompleted && (
          <>
            <FormLabel text={t('addTreatment:isAutoCompleted')} />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <PermissionSwitchInput
                  permissionAction={
                    PermissionAction.TREATMENT_AUTO_COMPLETE__CHANGE
                  }
                  label={t('addTreatment:isAutoCompleted')}
                  onChangeValue={onChange}
                  value={value}
                />
              )}
              name="isAutoCompleted"
            />
          </>
        )}

        {!isNewSheet && <Instructions control={control} />}
      </KeyboardAwareScrollView>
      <View style={footStyle}>
        <SaveTreatmentButton
          disabled={hasErrors}
          loading={submitting}
          onPress={handleSave}
          isEdit={isEdit}
          approvalStatus={approvalStatus}
          submitTitle={submitTitle}
        />
        {deleteTreatment ? (
          <ConditionalTreatmentDeleteBtn deleteTreatment={deleteTreatment} />
        ) : null}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    alignItems: 'flex-start',
    flex: 2,
  },
  numInput: {
    flex: 0.7,
    minWidth: 30,
  },
  bigFlex: {
    flex: 9,
    marginRight: 'auto',
  },
  footer: {
    paddingVertical: 15,
  },
  footerForCreatingSheet: {
    marginBottom: 35,
    paddingVertical: 15,
  },
  disableLabel: {
    color: 'lightgrey',
  },
  basicLabel: {
    color: 'black',
  },
})
