import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  addDays,
  differenceInDays,
  eachDayOfInterval,
  endOfDay,
  isValid,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns'
import { isEqual } from 'lodash'
import { rafInterval } from 'src/utils/rafTimeout'

import {
  DateRangeCount,
  DEFAULT_DAYS_RENDERED,
  TimeSegment,
} from './types/time'

export type DayRange = [Date, Date]

export type FromToQueryDate = {
  fromISODate: string
  toISODate: string
}

export type TimeResolutionReactContext = {
  getVisibleDayByIndex: (idx: number) => Date | undefined
  setToCurrentRange: () => void
  setToNextRange: () => void
  setToPrevRange: () => void
  setVisibleDayRange: (newDayRange: DayRange) => void
  timeNow: Date
  reset: (date?: Date) => void
  timeSegment: TimeSegment
  toggleTimeSegment: (newTimeSegment: TimeSegment) => void
  visibleDayList: Date[]
  visibleDayRange: DayRange
  setDateRangeCount: (newCount: DateRangeCount) => void
  dateRangeCount: DateRangeCount
  fromToQueryDate: FromToQueryDate
}
type SetTime = (date: Date) => void

export const getDayRangeExtent = (
  rangeCenter = new Date(),
  dateRangeCount: DateRangeCount,
) => {
  const startOfToday = startOfDay(rangeCenter)
  const endOfToday = endOfDay(rangeCenter)
  const daysBefore =
    dateRangeCount === 1 ? 0 : Math.ceil((dateRangeCount - 1) / 2)
  const daysAfter = dateRangeCount === 1 ? 0 : dateRangeCount - 1 - daysBefore
  return [
    addDays(startOfToday, -daysBefore),
    addDays(endOfToday, daysAfter),
  ] as DayRange
}

const defaultDayRangeExtent = getDayRangeExtent(new Date(), 3)

const defaultVisibleDayList = eachDayOfInterval({
  start: defaultDayRangeExtent[0],
  end: defaultDayRangeExtent[1],
})

const getDayByIndex = (idx: number, days: Date[]): Date | undefined => {
  if (days[idx]) {
    return days[idx]
  }
  return undefined
}

// Used when there isnt a parent provider (ie testing)
const timeContextDefault: TimeResolutionReactContext = {
  getVisibleDayByIndex: (idx: number) =>
    getDayByIndex(idx, defaultVisibleDayList),
  setToCurrentRange: () => null,
  setToNextRange: () => null,
  setToPrevRange: () => null,
  setVisibleDayRange: () => null,
  timeNow: new Date(),
  reset: () => null,
  timeSegment: TimeSegment.hourly,
  toggleTimeSegment: _newTimeSegment => null,
  visibleDayList: defaultVisibleDayList,
  visibleDayRange: defaultDayRangeExtent,
  setDateRangeCount: _newCount => null,
  dateRangeCount: 3,
  fromToQueryDate: {
    fromISODate: defaultDayRangeExtent[0].toISOString(),
    toISODate: defaultDayRangeExtent[1].toISOString(),
  },
}

const SECOND = 1000
const HALF_MINUTE = 30 * SECOND

export const updateTimeLoop = (setTime: SetTime) => () => {
  const cancel = rafInterval(() => {
    setTime(new Date())
  }, HALF_MINUTE)
  return () => {
    // cancel on unmount
    cancel()
  }
}

export const TimeResolutionContext = React.createContext(timeContextDefault)
TimeResolutionContext.displayName = 'TimeResolutionContext'

type ProviderProps = {
  initialTimeSegment?: TimeSegment
  children: React.ReactElement
}

export const TimeResolutionProvider: React.FC<ProviderProps> = ({
  children,
  initialTimeSegment = TimeSegment.hourly,
}) => {
  const [dateRangeCount, _setDateRangeCount] = useState<DateRangeCount>(
    DEFAULT_DAYS_RENDERED,
  )
  const [timeSegment, _setTimeSegment] = useState(initialTimeSegment)
  const [timeNow, _setTime] = useState(new Date())
  const [visibleDayRange, _setVisibleDayRange] = useState(
    getDayRangeExtent(new Date(), dateRangeCount),
  )

  const setVisibleDayRange = useCallback((dayRange: DayRange) => {
    _setVisibleDayRange(curVisibleDayRange => {
      if (!isEqual(dayRange, curVisibleDayRange)) {
        return dayRange
      }
      return curVisibleDayRange
    })
  }, [])

  const visibleDayList = useMemo(
    () =>
      eachDayOfInterval({
        start: visibleDayRange[0],
        end: visibleDayRange[1],
      }),
    [visibleDayRange],
  )

  const getVisibleDayByIndex = useCallback(
    (idx: number): Date | undefined => getDayByIndex(idx, visibleDayList),
    [visibleDayList],
  )

  const fromToQueryDate = useMemo(() => {
    let from = visibleDayRange[0]
    const to = visibleDayRange[1]

    if (differenceInDays(to, from) <= 1) {
      from = subDays(from, 1)
    }
    return { fromISODate: from.toISOString(), toISODate: to.toISOString() }
  }, [visibleDayRange])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateTimeLoop(_setTime), [])

  const reset = useCallback(
    (viewableDate?: Date) => {
      _setTime(new Date())
      setVisibleDayRange(getDayRangeExtent(viewableDate, dateRangeCount))
    },
    [dateRangeCount, setVisibleDayRange],
  )

  const navStep = dateRangeCount === 3 ? 2 : 1

  // Day range controls
  const setToNextRange = useCallback(
    () =>
      setVisibleDayRange([
        addDays(visibleDayRange[0], navStep),
        addDays(visibleDayRange[1], navStep),
      ]),
    [navStep, setVisibleDayRange, visibleDayRange],
  )

  const setToPrevRange = useCallback(
    () =>
      setVisibleDayRange([
        addDays(visibleDayRange[0], -navStep),
        addDays(visibleDayRange[1], -navStep),
      ]),
    [navStep, setVisibleDayRange, visibleDayRange],
  )

  const setToCurrentRange = useCallback(
    () => setVisibleDayRange(getDayRangeExtent(new Date(), dateRangeCount)),
    [dateRangeCount, setVisibleDayRange],
  )

  const value: TimeResolutionReactContext = {
    fromToQueryDate,
    getVisibleDayByIndex,
    setToCurrentRange,
    setToNextRange,
    setToPrevRange,
    setVisibleDayRange,
    timeNow,
    reset,
    timeSegment,
    dateRangeCount,
    visibleDayList,
    visibleDayRange,
    toggleTimeSegment: _setTimeSegment,
    setDateRangeCount: _setDateRangeCount,
  }

  return (
    <TimeResolutionContext.Provider value={value}>
      {children}
    </TimeResolutionContext.Provider>
  )
}

// Render Prop for legacy use - only use one Provider or the other
export const withTimeResolutionProvider = <P extends object>(
  BasicComponent: React.ComponentType<P>,
) => {
  return (props: P) => {
    return (
      <TimeResolutionProvider>
        <BasicComponent {...props} />
      </TimeResolutionProvider>
    )
  }
}

export const getResetToDate = (resetToDate?: string) => {
  if (!resetToDate) return
  const resetToDateParsed = parseISO(resetToDate)
  const isValidResetToDate = isValid(resetToDateParsed)

  if (!isValidResetToDate) {
    // eslint-disable-next-line no-console
    console.error(
      `Passed an invalid initial visible date ${resetToDate}, ignoring and viewing now() instead`,
    )
    return
  }
  return resetToDateParsed
}

export const useTimeResolution = () => useContext(TimeResolutionContext)
export const useResetTimeResolution = (resetToDate?: string) => {
  const { reset } = useTimeResolution()

  useEffect(() => {
    // Seems no need to getLastState anymore?
    // if (getLastState() === Routes.SheetList || resetToDate) {
    if (resetToDate) {
      reset(getResetToDate(resetToDate))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
