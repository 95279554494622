import { compact } from 'lodash'
import { BulkTreatmentsKeyedById } from '../BulkTaskCreate'

export const getCheckedTreatments = (allItems: BulkTreatmentsKeyedById) =>
  compact(
    Object.entries(allItems).map(
      ([_, { treatment, checked, value, isBillable, notes }]) => {
        return checked
          ? {
              notes,
              value,
              isBillable,
              id: treatment.id,
              unitsBilledPerTask:
                treatment.medicine_dosage_info?.units_billed_per_task,
            }
          : null
      },
    ),
  )
