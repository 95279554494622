import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color: string
}

export const SvgViewColumn: React.FC<Props> = ({ color }) => (
  <Svg width={24} height={24}>
    <G fill={color} fillRule="nonzero">
      <Path d="M10 18h5V5h-5v13zm-6 0h5V5H4v13zM16 5v13h5V5h-5z" />
    </G>
  </Svg>
)
