import React, { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'

import { Fonts } from 'constants/Fonts'
import { CloseButton, TrainingModeButton } from './zTEMP-buttons'

import { IntroductionModal } from './IntroductionModal'
import { useTranslation } from 'react-i18next'

export const IntroductionBanner: React.FC<{
  navigateToTrainingSite: () => void
  hideCard: () => void
}> = ({ navigateToTrainingSite, hideCard }) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const closeModal = () => setShowModal(false)
  return (
    <>
      <View style={styles.container}>
        <View style={styles.titleRow}>
          <Text style={styles.title}>
            {t('training:introductionBanner:title')}
          </Text>
          <CloseButton onPress={hideCard} />
        </View>

        <Text style={styles.subtitle}>
          {t('training:introductionBanner:description')}
        </Text>

        <View style={{ marginTop: 16, alignSelf: 'flex-start' }}>
          <TrainingModeButton
            title={t('training:introductionBanner:openTrainingButton')}
            onPress={() => setShowModal(true)}
          />
        </View>
      </View>
      <IntroductionModal
        visible={showModal}
        onClose={closeModal}
        navigateToTrainingSite={navigateToTrainingSite}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: Colors.Backgrounds.warning,
  },
  titleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    // TODO combine Font definitions in Design System https://www.figma.com/file/NvI7tIEm3m9oB6UkiTmPhx/ezyVet-Design-System?type=design&node-id=4149-57166&t=bl22mdTZ7FV6tGkF-0
    color: Colors.Contents.primary,
    fontSize: Typography.FontSizes.lg,
    fontWeight: Typography.FontWeights.bold,
    fontFamily: Fonts.bold,
  },
  subtitle: {
    color: Colors.Contents.primary,
    fontSize: Typography.FontSizes.base,
    fontWeight: Typography.FontWeights.regular,
    fontFamily: Fonts.regular,
  },
})
