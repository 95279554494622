import { useRef } from 'react'
import { rafTimeout } from 'src/utils/rafTimeout'

type VoidFunction = () => void

/* 
  lodash/debounce does not work in useCRIOnForm
  Maybe the closure of lodash/debounce cannot be used inside function in a function component.
  And debounce in '@material-ui/core' is throttle, not fulfill the requirement
 */
export const useRefDebounce = () => {
  const debounceRef = useRef(() => {
    let cancel: VoidFunction

    return (callBack: VoidFunction, wait: number) => {
      function debounceFn() {
        cancel = rafTimeout(callBack, wait)
      }

      return () => {
        if (cancel) {
          cancel()
        }
        debounceFn()
      }
    }
  })
  return debounceRef.current()
}
