import { MAX_CHART_VALUE } from 'components/Anesthesia/AnesthesiaChart/utils/getHighestChartValue'

// @ts-ignore
export const calcChartValuesRange = (
  highestValue: number,
  chartDefaultMaxValue: number,
  chartValuesRange: number[],
) => {
  if (
    highestValue > chartDefaultMaxValue &&
    chartValuesRange[1] !== MAX_CHART_VALUE
  ) {
    return [chartValuesRange[0], MAX_CHART_VALUE]
  }
  return chartValuesRange
}
