import React, { useMemo, useState } from 'react'
import { SwitchInput } from 'components/common/SwitchInput'
import { SvgArrow } from 'components/Icons'
import { TickForCubexBilling } from 'components/Icons/TickForCubexBilling'
import { Colors } from 'constants/Colors'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Typography } from 'src/design-system/theme'
import { Primitive } from 'src/design-system/theme/colors/base'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { CubexTransactionStatus } from 'src/types/globalTypes'

type Props = {
  isEzyVetBillingOn: boolean
  children: React.ReactElement
  isCubexBillable: boolean
  cubexTransactionStatus?: CubexTransactionStatus | null
}

export const CubexBillToEzyVet: React.FC<Props> = ({
  isEzyVetBillingOn,
  children,
  isCubexBillable,
  cubexTransactionStatus,
}) => {
  const { t } = useTranslation()

  const [isEzyVetBillingHidden, setIsEzyVetBillingHidden] = useState(
    isCubexBillable && !isEzyVetBillingOn,
  )

  const toggleIsEzyVetBillingHidden = () => {
    setIsEzyVetBillingHidden(preValue => !preValue)
  }

  const arrowUpDown = isEzyVetBillingHidden ? 'up' : 'down'

  const showDoubleBillingWarning = useMemo(
    () => isCubexBillable && isEzyVetBillingOn,
    [isCubexBillable, isEzyVetBillingOn],
  )

  const {
    isExSmallScreen,
    isSmallScreen,
    isSmallishScreen,
    isPortrait,
    isLargeButNotThatLargeScreen,
  } = useBreakpoint()
  const isExSmallAndSmall = isExSmallScreen || isSmallScreen

  return (
    <View>
      {isCubexBillable ? (
        <View
          accessibilityLabel={t('task:taskTransition:cubexBillTestID')}
          style={styles.cubexControlBar}
        >
          <SwitchInput
            label={
              isExSmallAndSmall
                ? t('task:taskTransition:shortCubexBillingTitle')
                : t('task:taskTransition:cubexBillingTitle')
            }
            value={true}
            disabled={true}
            subTag={
              cubexTransactionStatus === CubexTransactionStatus.SUCCESS ? (
                <View
                  style={[
                    styles.billingSentComponentStyle,
                    (isSmallishScreen || isLargeButNotThatLargeScreen) &&
                      styles.portraitOrSmallistScreen,
                  ]}
                >
                  <View style={styles.checkboxContainer}>
                    <TickForCubexBilling />
                  </View>
                  {!(isSmallishScreen || isLargeButNotThatLargeScreen) && (
                    <Text style={styles.cubexTagText}>
                      {t('addTreatment:cubexBillingSent')}
                    </Text>
                  )}
                </View>
              ) : null
            }
            style={styles.cubexSwitchPosition}
            subTagStyle={isPortrait ? styles.subTagStyle : null}
          />
          <Pressable
            style={({ pressed }) => [
              pressed && { opacity: 0.5 },
              styles.cubexDropdownArrowContainer,
            ]}
            onPress={toggleIsEzyVetBillingHidden}
          >
            <SvgArrow
              orient={arrowUpDown}
              color={Colors.contentPrimary}
              width={15}
              height={15}
            />
          </Pressable>
        </View>
      ) : null}

      {!isEzyVetBillingHidden ? (
        <View style={[styles.displayFlex]}>
          {children}
          {showDoubleBillingWarning ? (
            <Text style={styles.doubleBillingContainer}>
              {t('addTreatment:doubleBilling')}
            </Text>
          ) : null}
        </View>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  displayFlex: {
    display: 'flex',
  },
  cubexControlBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Primitive.white,
  },
  cubexSwitchPosition: {
    width: '91.5%',
  },
  portraitOrSmallistScreen: {
    width: 34,
  },
  subTagStyle: {
    width: 28,
    marginLeft: 4,
  },
  cubexDropdownArrowContainer: {
    width: '8.5%',
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.borderGrey,
    paddingLeft: '1.6%',
    paddingTop: 22,
  },
  billingSentComponentStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 116,
    height: 32,
    borderRadius: 180,
    alignItems: 'center',
    marginLeft: 8,
    backgroundColor: Primitive.green100,
  },
  cubexTagText: {
    fontFamily: Typography.FontFamilies.base,
    fontWeight: Typography.FontWeights.bold,
    fontSize: 14,
    fontStyle: 'normal',
    marginRight: 10,
    color: Primitive.green900,
  },
  checkboxContainer: {
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Primitive.green100,
    borderRadius: 8,
    marginLeft: 9,
  },
  doubleBillingContainer: {
    color: Primitive.orange700,
    paddingTop: 8,
    paddingLeft: 16,
    paddingBottom: 8,
    marginBottom: -3,
  },
})
