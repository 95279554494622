import { gql } from '@apollo/client'
import { TASK_FIELDS_FULL } from '../Task/graphql'
import {
  CUSTOM_PRODUCT_FIELDS,
  MEDICATION_FIELDS,
} from 'components/Settings/graphql'

export const SCHEDULE_FIELDS = gql`
  fragment ScheduleFields on Treatment {
    schedule {
      repeat
      start_at
      frequency
      repeat_until
      repeat_count
      duration
      time_window
      enable_staffed_hour
      type
      frequencies
      treatment_frequency_id
    }
  }
`

export const MEDICINE_FIELDS = gql`
  fragment MedicineFields on Treatment {
    medicine_dosage_info {
      days
      hours
      minutes
      concentration
      diluted_concentration
      diluted_concentration_volume_unit
      diluted_concentration_weight_unit
      diluted_concentration_ratio_factor
      diluted_concentration_ratio_volume
      dosage
      dosage_patient_weight_unit
      dosage_weight_unit
      is_cri
      is_diluted
      patient_weight
      patient_weight_unit
      route_of_administration
      infusion_rate_total
      infusion_rate_volume_unit
      infusion_rate_time_unit
      total_volume
      concentration_setting
      total_volume_unit
      total_dosage
      units_billed_per_task
      dose_rate
      dose_rate_time_unit
      medication_volume
      medication_volume_unit
      diluent_used
      iv_bag_size
      iv_bag_size_unit
    }
  }
`
export const FLUID_FIELDS = gql`
  fragment FluidFields on Treatment {
    fluid_dosage_info {
      is_hypovolemic
      is_dehydrated
      dehydration
      hours_to_admin
      dehydration_result
      shock_total_result
      physiologic_req
      ongoing_losses
      total_result
      total_result_unit
      iv_set
      drip_rate
      minutes
      hours
      days
      patient_weight
      patient_weight_unit
      preset_rate_text
    }
  }
`

export const MEDICINE_FIELDS_FULL = gql`
  fragment MedicineFieldsFull on Treatment {
    medicine_dosage_info {
      is_cri
      calculate_base
      concentration_volume_unit
      concentration_weight_unit
      concentration
      is_diluted
      diluted_concentration_volume_unit
      diluted_concentration_weight_unit
      diluted_concentration
      concentration_setting
      units_billed_per_task
      is_cri
      administration_method
      administration_volume
      administration_volume_unit
      infusion_rate
      infusion_rate_volume_unit
      infusion_rate_weight_unit
      infusion_rate_time_unit
      infusion_rate_total
      route_of_administration
      dosage
      dosage_weight_unit
      dosage_patient_weight_unit
      total_dosage
      total_volume
      total_volume_unit
      medication_volume
      medication_volume_unit
      diluent_used
      iv_bag_size
      iv_bag_size_unit
      dose_rate
      dose_rate_time_unit
      saline_volume
      saline_volume_unit
      minutes
      hours
      days
      patient_weight
      patient_weight_unit
    }
  }
`

export const PRODUCT_FIELDS = gql`
  fragment ProductFields on Treatment {
    product {
      default_unit
      id
      name
      track_vital
      type
      is_billable
      is_value_required
      is_notes_required
      pims_mapping {
        dest
        field
        type
      }
      custom_values {
        key
        value
      }
      medicine_dosage_info {
        concentration_volume_unit
      }
      origin_id
    }
  }
`

const TREATMENT_TASKS_STATUS = gql`
  fragment TreatmentTasksStatus on Treatment {
    treatmentTasksStatus(fromISODate: $fromISODate, toISODate: $toISODate) {
      hasDue
      hasMissed
    }
  }
`

export const TREATMENT_FIELDS_BASE = gql`
  fragment TreatmentFieldsBase on Treatment {
    _hidden @client
    _optimistic @client
    conditional
    created_at
    discontinued_at
    display_states
    id
    instructions
    is_instructions_important
    is_billable
    name
    node_type
    order
    parent_treatment_id
    is_auto_completed
    is_iv_infusion
    is_continuous
    approval_status
    approval_updated_by
    updated_at
    value {
      value
      type
      display_unit
    }
    cubex_integration {
      cubex_cabinet_id
      is_cubex_billable
    }
    ...ScheduleFields
    ...MedicineFields
    ...ProductFields
    ...FluidFields
  }
  ${SCHEDULE_FIELDS}
  ${MEDICINE_FIELDS}
  ${PRODUCT_FIELDS}
  ${FLUID_FIELDS}
`

export const TREATMENT_WITH_TASK_STATUS = gql`
  fragment TreatmentWithTaskStatus on Treatment {
    ...TreatmentFieldsBase
    ...TreatmentTasksStatus
  }
  ${TREATMENT_FIELDS_BASE}
  ${TREATMENT_TASKS_STATUS}
`

const TASK_ITEMS = gql`
  fragment TaskItems on Treatment {
    tasks(fromISODate: $fromISODate, toISODate: $toISODate) {
      items {
        ...TaskFieldsFull
      }
    }
  }
  ${TASK_FIELDS_FULL}
`

export const TREATMENT_BASE_WITH_TASKS = gql`
  fragment TreatmentBaseWithTasks on Treatment {
    ...TaskItems
    ...TreatmentWithTaskStatus
  }
  ${TASK_ITEMS}
  ${TREATMENT_WITH_TASK_STATUS}
`

export const TREATMENT_FIELDS = gql`
  fragment TreatmentFields on Treatment {
    ...TreatmentBaseWithTasks
    treatments {
      items {
        ...TreatmentBaseWithTasks
      }
    }
  }
  ${TREATMENT_BASE_WITH_TASKS}
`

export const TREATMENT_GROUP_FIELDS = gql`
  fragment TreatmentGroupFields on Treatment {
    ...TreatmentFieldsBase
    treatments {
      items {
        ...TreatmentFields
      }
    }
  }
  ${TREATMENT_FIELDS}
  ${TREATMENT_FIELDS_BASE}
`

export const CREATE_TREATMENT = gql`
  mutation createTreatment(
    $input: CreateTreatmentInput!
    $fromISODate: String!
    $toISODate: String!
    $options: CreateTreatmentOptions
  ) {
    createTreatment(input: $input, options: $options) {
      ...TreatmentGroupFields
    }
  }
  ${TREATMENT_GROUP_FIELDS}
`

export const ADD_TEMPLATE_TO_SHEET = gql`
  mutation addTemplateToSheet(
    $input: AddTemplateToSheetInput!
    $options: CreateTemplateTreatmentOptions
    $fromISODate: String!
    $toISODate: String!
  ) {
    addTemplateToSheet(input: $input, options: $options) {
      ...TreatmentGroupFields
    }
  }
  ${TREATMENT_GROUP_FIELDS}
`

export const GET_PRODUCT_GROUPS = gql`
  query getProductGroups($id: ID!) {
    getOrganisation(id: $id) {
      id
      products(masterProductType: ALL) {
        items {
          type
          id
          name
          order
        }
      }
      frequentlyUsedProducts {
        items {
          id
          name
          parent_product_id
          type
        }
      }
    }
  }
`

export const SEARCH_PRODUCTS = gql`
  query searchProducts($input: SearchInput!) {
    searchProducts(input: $input) {
      type
      item {
        id
        is_billable
        is_bundle
        name
        code
        type
        parent_product_id
      }
    }
  }
`

export const GET_PRODUCT_DETAILS = gql`
  query getOneProduct($id: ID!, $organisation_id: ID!) {
    getProduct(id: $id, organisation_id: $organisation_id) {
      cubex_integrations {
        cubex_cabinet_id
        is_cubex_billable
      }
      ...MedicationFields
      ...CustomProductFields
    }
  }
  ${CUSTOM_PRODUCT_FIELDS}
  ${MEDICATION_FIELDS}
`

export const GET_PRODUCT_ID = gql`
  fragment ProductId on Treatment {
    product {
      id
    }
  }
`
