import React from 'react'
import { Select } from 'components/common'
import { reverse, sortBy } from 'lodash'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Platform, View } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import {
  getConsultations,
  getConsultationsVariables,
} from 'types/getConsultations'

import { GET_CONSULTATIONS } from '../graphql'

type Props = {
  a11yLabel?: string
  disabled?: boolean
  onConsultationsLoaded?: (consultations: getConsultations) => void
  onSelectedConsultIdChange: (consultId: string) => void
  selectedConsultId: string | null
  selectA11yLabel?: string
  patientId: string
}

export const reverseSortByOriginId = <
  T extends { origin_id?: string | null; id: string },
>(
  consultItems: T[],
) => reverse(sortBy<T>(consultItems, ['origin_id', 'id']))

export const ConsultIdSection: React.FC<Props> = React.memo(
  ({
    a11yLabel = 'Consultation Section',
    disabled = false,
    onConsultationsLoaded,
    onSelectedConsultIdChange,
    selectedConsultId,
    selectA11yLabel,
    patientId,
  }) => {
    const { t } = useTranslation()
    const [{ organisationId, PIMSIntegration }] = useOrganisation()
    const integration = PIMSIntegration === 'EZYVET' ? 'ezyVet' : ''

    const variables = {
      organisationId,
      id: patientId,
    }
    const { data, loading } = useQuery<
      getConsultations,
      getConsultationsVariables
    >(GET_CONSULTATIONS, {
      variables,
      fetchPolicy: 'no-cache',
      onCompleted: onConsultationsLoaded,
    })

    const consultations = data?.getPatient?.active_consultations?.items ?? []

    const consultIdOptions = reverseSortByOriginId(consultations).map(item => ({
      value: item.id,
      text: item.origin_reference || item.origin_id || item.id,
    }))

    return (
      <View accessibilityLabel={a11yLabel}>
        <Select
          dialog={false}
          a11yLabel={selectA11yLabel}
          // allowClear: hacky fix for when unable to select option on mobile device
          allowClear={!(Platform.OS === 'web')}
          disabled={disabled}
          label={t(
            integration
              ? 'sheetForm.clinicalRecord'
              : 'sheetForm.clinicalRecordWithoutIntegration',
            { integration },
          )}
          loading={loading}
          onChange={onSelectedConsultIdChange}
          options={consultIdOptions}
          selected={selectedConsultId}
        />
      </View>
    )
  },
)
ConsultIdSection.displayName = 'ConsultIdSection'
