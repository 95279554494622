import { throttle, ThrottleSettings, DebouncedFunc } from 'lodash'
import { useState, useEffect, DependencyList, useRef } from 'react'

export const useThrottleCallback = <
  T extends (...args: any[]) => ReturnType<T>,
>(
  callback: T,
  deps: DependencyList,
  delay: number,
  options?: ThrottleSettings,
) => {
  // Make sure callback won't get called after component destroyed
  const isDestroyedRef = useRef(false)
  useEffect(() => {
    return () => {
      isDestroyedRef.current = true
    }
  }, [])

  const [throttledCallback, setThrottledCallback] = useState<DebouncedFunc<T>>()
  useEffect(() => {
    const newThrottleCallback = throttle(
      (...arg) => {
        if (isDestroyedRef.current) {
          return
        }
        return callback(...arg)
      },
      delay,
      options,
    )
    setThrottledCallback(() => newThrottleCallback)
    return newThrottleCallback.cancel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay])
  return throttledCallback!
}
