import React, { useRef, forwardRef } from 'react'
import { SvgCross } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { noop } from 'lodash'
import {
  ActivityIndicator,
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextInput as RnTextInput,
  TextInputProps,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'

export type Props = TextInputProps & {
  disabled?: boolean
  inputGroupId?: string
  label?: string
  onChangeText?: (text: string) => void
  onPressCancel?: (event: GestureResponderEvent) => void
  style?: StyleProp<ViewStyle>
  loading?: boolean
  required?: boolean
  showClearButton?: boolean
}

const hitSlop = { top: 10, left: 10, bottom: 10, right: 10 }

export const TextInput = forwardRef<RnTextInput, Props>(
  (
    {
      disabled = false,
      inputGroupId,
      label,
      onChangeText = noop,
      onPressCancel,
      style,
      value,
      loading = false,
      required,
      showClearButton = false,
      ...props
    },
    fRef,
  ) => {
    const ref: React.MutableRefObject<RnTextInput | null> = useRef(null)

    const pseudoLabel = label === 'pseudo'

    const focusInput = () =>
      requestAnimationFrame(() => {
        if (ref.current) ref.current.focus()
      })

    const onClear = (event: GestureResponderEvent) => {
      onChangeText('')
      if (!!onPressCancel) {
        onPressCancel(event)
      }
      focusInput()
    }
    return (
      <View style={style}>
        <TouchableWithoutFeedback
          onPressIn={focusInput}
          accessible={false}
          // @ts-ignore
          focusable={false}
        >
          <View
            style={[
              styles.container,
              {
                height: props.multiline ? 'auto' : 60,
                paddingTop: props.multiline ? 4 : undefined,
                paddingBottom: props.multiline ? 12 : undefined,
              },
            ]}
          >
            <View style={styles.textInputWrapper}>
              <View style={{ flexDirection: 'row' }}>
                {label && !pseudoLabel && (
                  <TextWithTooltip
                    style={styles.label}
                    accessibilityLabel={label}
                    numberOfLines={1}
                    title={label}
                  >
                    {label}
                  </TextWithTooltip>
                )}
                {pseudoLabel && <View style={styles.pseudoLabel} />}
                {required && <Text style={styles.requiredLabel}>*</Text>}
              </View>

              <RnTextInput
                ref={el => {
                  // fRef can be a function or an object
                  if (typeof fRef === 'function') {
                    fRef(el)
                  } else if (!!fRef) {
                    fRef.current = el
                  }
                  ref.current = el
                }}
                accessibilityLabel={
                  props.accessibilityLabel || `${label} Text Input`
                }
                style={[styles.value, disabled && styles.disabled]}
                onChangeText={onChangeText}
                value={value ?? ''}
                editable={!disabled}
                {...props}
                {...(disabled && { tabIndex: -1 })}
              />
            </View>
            {!disabled && showClearButton && (
              <View style={styles.clearButton}>
                {!!value && !loading && (
                  <TouchableOpacity
                    style={{ paddingTop: label ? 16 : 0 }}
                    accessibilityLabel={`${label} Clear Text Input`}
                    hitSlop={hitSlop}
                    onPress={onClear}
                  >
                    <SvgCross />
                  </TouchableOpacity>
                )}
                {!!value && loading && (
                  <ActivityIndicator size="small" style={{ marginTop: 8 }} />
                )}
              </View>
            )}
          </View>
        </TouchableWithoutFeedback>
      </View>
    )
  },
)

TextInput.displayName = 'TextInput'

const styles = StyleSheet.create({
  clearButton: {
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    width: 40,
    marginLeft: -16,
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    paddingLeft: 16,
  },
  disabled: {
    color: Colors.disabled,
  },
  label: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 13,
    lineHeight: 19,
  },
  pseudoLabel: {
    height: 13,
  },
  requiredLabel: {
    color: 'red',
    marginTop: 0,
  },
  textInputWrapper: {
    flex: 1,
  },
  value: {
    color: Colors.contentPrimary,
    fontFamily: Fonts.regular,
    fontSize: 16,
    lineHeight: 22,
    paddingHorizontal: 3,
    marginLeft: -3,
    marginRight: 16,
  },
})
