import React from 'react'
import {
  Authenticator,
  Heading,
  Image,
  ThemeProvider,
  useTheme,
  View,
  WithAuthenticatorOptions,
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { theme } from 'components/common/theme/index.web'
import { Colors } from 'constants/Colors'
import { SetupPin } from '../SetupPin'
import {
  AuthProps,
  LOGIN_MECHANISM,
  PASSWORD_SETTINGS,
  vocabularies,
} from './common'
import { I18n } from 'aws-amplify'
import { AUTH_STATE, authTypeService, useAuth } from 'src/context/auth'
import { AuthStack } from 'components/Login/AuthStack'

I18n.putVocabularies(vocabularies)

export const LoginScreens: React.FC<AuthProps> = ({ children }) => {
  const isAuthV2 = authTypeService.getIsAuthV2()
  const { authState } = useAuth()

  if (isAuthV2) {
    return (
      <>
        {authState !== AUTH_STATE.AUTHENTICATED ? (
          <AuthStack />
        ) : (
          <View style={containerStyle}>
            <View style={childrenContainerStyle}>{children}</View>
          </View>
        )}
      </>
    )
  }
  return (
    <View style={containerStyle}>
      <ThemeProvider theme={theme}>
        <Authenticator
          components={components}
          hideSignUp={true}
          loginMechanisms={LOGIN_MECHANISM}
          passwordSettings={PASSWORD_SETTINGS}
        >
          <SetupPin>
            <View style={childrenContainerStyle}>{children}</View>
          </SetupPin>
        </Authenticator>
      </ThemeProvider>
    </View>
  )
}

const imageStyle = { height: 100 }

const components: WithAuthenticatorOptions['components'] = {
  Footer() {
    const { tokens } = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.small}>
        <Image
          style={imageStyle}
          alt="Vet Radar logo"
          src={require('assets/images/vetradar-logo-white.png')}
        />
      </View>
    )
  },

  VerifyUser: {
    Header() {
      return <Heading level={3}>{I18n.get('Verify password')}</Heading>
    },
  },

  ConfirmVerifyUser: {
    Header() {
      return (
        <Heading level={3}>{I18n.get('Verification code password')}</Heading>
      )
    },
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme()
      // failsafe for scenarios when the signOut method is not called
      // we need to reset the url to the root path
      window.history.pushState(null, '', '/')
      return (
        <Heading
          textAlign="left"
          padding={`${tokens.space.large} 0 0 ${tokens.space.large}`}
          level={4}
        >
          {I18n.get('Sign In')}
        </Heading>
      )
    },
  },
}

const containerStyle = {
  backgroundColor: Colors.vetRadarBlue,
  flex: 1,
  paddingTop: '4%',
}

const childrenContainerStyle = {
  display: 'flex',
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
}
