import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color?: string
}

export const SvgCross = ({ color = '#6D7278' }: Props) => (
  <Svg height={15} width={15}>
    <G
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
      stroke={color}
      strokeWidth={1}
      transform="scale(2)"
    >
      <Path d="M.5.5l6.596 6.596M.5 7.096L7.096.5" />
    </G>
  </Svg>
)
