import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, Text } from 'react-native'
import { Routes } from 'src/constants/Routes'
import { SubHeader } from '../SubHeader/SubHeader'
import { environment } from 'src/config'
import { useNavigation } from '@react-navigation/native'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import { useTranslation } from 'react-i18next'

const { appVersion, manifestExtra, manifestClientInfo } = environment
// @ts-ignore
const vetradarConfig = manifestExtra?.vetradar ?? {}

let buildTime = 'n/a'
if (manifestExtra.buildTime) {
  const pattern = /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/
  const date = manifestExtra.buildTime.replace(pattern, '$4:$5 $3/$2/$1')
  buildTime = `${date} (d/m/y)`
}

/**
 * Dump of the App.config.json for debugging
 */
export const Manifest: React.FC = () => {
  const { t } = useTranslation('settings')
  const { navigate } = useNavigation()

  const { instanceUrl, apiUrl } = useOrganisationHook()
  const [ezyVetVersion, setEzyVetVersion] = useState<string | null>(null)

  const fetchEzyVetSiteInfo = async () => {
    if (apiUrl) {
      try {
        const request = await fetch(`${apiUrl}/v3/siteInformation`)
        const response: EzyVetSiteInformationResponse = await request.json()
        setEzyVetVersion(response.data.attributes.version)
      } catch (error) {
        setEzyVetVersion(t('manifest.ezyVetVersion.fetchError'))
      }
    } else {
      setEzyVetVersion(t('manifest.ezyVetVersion.unknownSite'))
    }
  }

  useEffect(() => {
    fetchEzyVetSiteInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: () => navigate(Routes.Settings),
  }
  return (
    <>
      <SubHeader
        headlineKey="settings:support.buildInfo.title"
        backButton={backButton}
      />
      <ScrollView style={styles.padding}>
        <Text style={styles.text}>{`${t('manifest.name')}: ${
          manifestClientInfo?.name
        }`}</Text>
        <Text style={styles.text}>{`${t(
          'manifest.version',
        )}: ${appVersion}`}</Text>
        <Text style={styles.text}>{`${t(
          'manifest.build',
        )}: ${buildTime}`}</Text>
        <Text style={styles.text}>{`${t('manifest.description')}: ${
          manifestClientInfo.description
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.expoUpdateChannel')}: ${
          manifestExtra.expoUpdateChannel
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.expoSdkVersion')}: ${
          manifestClientInfo?.sdkVersion
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.gitHash')}: ${
          manifestExtra.gitHash
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.slug')}: ${
          manifestClientInfo?.slug
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.releaseStage')}: ${
          environment.releaseStage ?? 'none'
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.graphQlUrl')}: ${
          vetradarConfig?.graphql_endpoint ?? 'not found'
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.authUrl')}: ${
          vetradarConfig?.auth_endpoint ?? 'not found'
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.s3ImgPrefix')}: ${
          vetradarConfig?.s3_prefix_for_ttl ?? 'not found'
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.s3VideoPrefix')}: ${
          vetradarConfig?.s3_video_prefix_for_ttl ?? 'not found'
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.ezyVetSite.label')}: ${
          instanceUrl ?? 'not found'
        }`}</Text>
        <Text style={styles.text}>{`${t('manifest.ezyVetVersion.label')}: ${
          ezyVetVersion ?? ''
        }`}</Text>
      </ScrollView>
    </>
  )
}

export const styles = StyleSheet.create({
  padding: {
    padding: 16,
  },
  text: {
    paddingVertical: 1,
    fontSize: 17,
  },
})

type EzyVetSiteInformationResponse = {
  data: {
    id: string
    type: string
    attributes: {
      prefix: string
      practiceType: string
      version: string
    }
    relationships: {
      timezone: {
        links: {
          related: string
        }
        data: {
          type: string
          id: string
        }
      }
    }
    included: {
      id: string
      type: string
      attributes: {
        name: string
      }
    }[]
  }
}
