import React from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { toast } from 'components/common'
import { getTemplateTreatmentSchedule } from 'components/TreatmentForm/utils/getTemplateTreatmentSchedule'
import { useOrganisation } from 'src/context/organisation'
import { ProductType } from 'src/types/globalTypes'
import {
  createTemplateTreatment as CreateTemplateTreatment,
  createTemplateTreatmentVariables as CreateTemplateTreatmentVariables,
} from 'types/createTemplateTreatment'

import { CREATE_TEMPLATE_TREATMENT, GET_TEMPLATE } from './graphql'
import { TemplateTreatmentDrawer } from './TemplateTreatmentDrawer'
import { TreatmentOption, Values } from './TemplateTreatmentForm'

type Props = {
  templateId: string
  toggleDrawer: () => void
  treatmentListLength: number
  templateSiteIds?: string[] | null
}

export const AddTemplateTreatmentScreen: React.FC<Props> = ({
  templateId,
  toggleDrawer,
  treatmentListLength,
  templateSiteIds,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const [createTemplateTreatment, { loading: createTreatmentLoading }] =
    useMutation<CreateTemplateTreatment, CreateTemplateTreatmentVariables>(
      CREATE_TEMPLATE_TREATMENT,
      {
        refetchQueries: [
          {
            query: GET_TEMPLATE,
            variables: {
              id: templateId,
              organisation_id: organisationId,
            },
          },
        ],
        onError: err => {
          toast.error(err.message)
        },
        onCompleted: () => {
          toast.success(t('treatment:addTemplateTreatment:success'))
        },
      },
    )

  const onSave = async (
    {
      conditional,
      dosageRate,
      dosagePerWeightUnit,
      dosageWeightUnit,
      instructions,
      isBillable,
      name,
      schedule,
      type,
      isAutoCompleted,
      frequencyInput,
    }: Values,
    treatmentOptions: TreatmentOption[],
  ) => {
    const input: CreateTemplateTreatmentVariables['input'] = {
      conditional,
      name,
      type,
      order: treatmentListLength,
      ...(isBillable !== null && { is_billable: isBillable }),
      ...(instructions && { instructions }),
      is_auto_completed: isAutoCompleted,
      organisation_id: organisationId,
      schedule: getTemplateTreatmentSchedule(schedule, frequencyInput),
      template_id: templateId,
      treatment_options: { products: treatmentOptions.map(v => v.id) },
    }
    if (treatmentOptions.length === 1) {
      input.product_id = treatmentOptions[0].id
      if (treatmentOptions[0].type === ProductType.MEDICATION) {
        input.medicine_dosage_info = {
          dosage: dosageRate,
          dosage_weight_unit: dosageWeightUnit,
          dosage_patient_weight_unit: dosagePerWeightUnit,
        }
      }
    } else if (treatmentOptions.length > 1) {
      input.treatment_options = {
        products: treatmentOptions.map(v => v.id),
      }
      input.medicine_dosage_info = {
        dosage: null,
        dosage_weight_unit: null,
        dosage_patient_weight_unit: null,
      }
    }

    await createTemplateTreatment({
      variables: { input },
    })
    toggleDrawer()
  }

  return (
    <TemplateTreatmentDrawer
      onSave={onSave}
      createOrUpdateTreatmentLoading={createTreatmentLoading}
      templateSiteIds={templateSiteIds}
    />
  )
}
