import { PatientSpecies } from './PatientSpecies'

// ml/kg
const defaultShockRate = 90
const speciesShockRates = {
  [PatientSpecies.FEL]: 55,
  [PatientSpecies.CAT]: 55,
  [PatientSpecies.CAN]: 90,
  [PatientSpecies.DOG]: 90,
} as any

export const getShockRates = (species: string) => {
  return speciesShockRates[species] ?? defaultShockRate
}
