import { Attitude } from 'src/types/globalTypes'
import { AlertLevel } from 'components/common/Avatar/AvatarWithMessage'

export const attitudeToAlertLevel = (
  attitude?: Attitude | null,
): AlertLevel => {
  if (!attitude) return AlertLevel.NEUTRAL
  let alertLevel: AlertLevel

  switch (attitude) {
    case Attitude.CAUTION:
      alertLevel = AlertLevel.BAD
      break
    case Attitude.UNFRIENDLY:
      alertLevel = AlertLevel.WARNING
      break
    case Attitude.FRIENDLY:
      alertLevel = AlertLevel.GOOD
      break
    default:
      alertLevel = AlertLevel.NEUTRAL
      break
  }

  return alertLevel
}
