import React from 'react'
import Svg, { Circle } from 'react-native-svg'

type Props = {
  size?: number
  color?: string
}

export const DefaultPasswordSvg = ({ size = 16, color = '#5D6066' }: Props) => {
  return (
    <Svg width={size} height={(size * 17) / 16} viewBox="0 0 16 17" fill="none">
      <Circle cx="8" cy="8.5" r="3" fill={color} />
    </Svg>
  )
}
