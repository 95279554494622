import React from 'react'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { Button, SecondaryButton } from 'components/common'
import { Colors } from 'constants/Colors'
import { manipulateAsync } from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native'
import { environment } from 'src/config'

enum PickerType {
  Camera,
  Library,
}

export enum TouchType {
  Button = 'Button',
  Touchable = 'Touchable',
  SecondaryButton = 'SecondaryButton',
}

type Props = {
  children?: React.ReactElement | null
  title?: string
  onPressOverride?: TouchableOpacityProps['onPress']
  onSelected: (uri: any) => void
  testID?: string
  style?: ViewStyle
  type?: TouchType
  multiSelect?: boolean
  disabled?: boolean
}

const launchOptions: ImagePicker.ImagePickerOptions = {
  allowsEditing: true,
  mediaTypes: ImagePicker.MediaTypeOptions.Images,
  quality: 0.5,
}

export const ImagePickerButton: React.FC<Props> = ({
  children,
  onSelected,
  onPressOverride,
  style,
  testID,
  title = '',
  type = TouchType.Button,
  multiSelect = false,
  disabled = false,
}) => {
  const { t } = useTranslation()
  const { showActionSheetWithOptions } = useActionSheet()

  const pickImage = async (pickerType: PickerType) => {
    if (Platform.OS !== 'web') {
      if (pickerType === PickerType.Camera) {
        const { status } = await ImagePicker.requestCameraPermissionsAsync()
        if (status !== 'granted') {
          alert(t('permissions.failed', { permission: 'camera roll' }))
        }
      }
    }

    const launchImagePickOrCamera =
      pickerType === PickerType.Camera
        ? ImagePicker.launchCameraAsync
        : ImagePicker.launchImageLibraryAsync

    const options = multiSelect
      ? {
          ...launchOptions,
          allowsMultipleSelection: true,
        }
      : launchOptions

    const result = await launchImagePickOrCamera(options)

    if (result.canceled) {
      return
    }

    const shouldDoRotationFix = environment.isWeb

    let selectedItems: string[] = result.assets.map(({ uri }) => uri)

    if (!selectedItems) return

    if (shouldDoRotationFix) {
      // See: https://github.com/expo/expo/issues/2559#issuecomment-676709323,
      // Camera on web for android devices gets the rotation wrong unless add
      // manipulation step, TODO: remove once camera lib updated
      selectedItems = await Promise.all(
        // @ts-ignore
        selectedItems.map(uri =>
          manipulateAsync(uri, [{ rotate: 0 }]).then(
            noopRotate => noopRotate.uri,
          ),
        ),
      )
    }

    // return a string when not multi-select to retain multi compatibility
    // @ts-ignore
    if (!multiSelect) selectedItems = selectedItems[0]
    onSelected(selectedItems)
  }

  const onOpenActionSheet = () => {
    if (Platform.OS === 'web') {
      pickImage(PickerType.Library)
      return
    }
    // Same interface as https://facebook.github.io/react-native/docs/actionsheetios.html
    const options = ['Take Photo', 'Choose from Library', 'Cancel']
    const cancelButtonIndex = 2

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      buttonIndex => {
        if (buttonIndex === 0) {
          pickImage(PickerType.Camera)
          return
        }
        if (buttonIndex === 1) {
          pickImage(PickerType.Library)
          return
        }
      },
    )
  }

  switch (type) {
    case TouchType.Button:
      return (
        <Button
          onPress={onPressOverride || onOpenActionSheet}
          testID={testID}
          title={title}
          disabled={disabled}
        />
      )

    case TouchType.SecondaryButton:
      return (
        <SecondaryButton
          title={title}
          style={styles.addPhotoBtn}
          textStyle={styles.addPhotoBtnText}
          onPress={onPressOverride || onOpenActionSheet}
          testID={testID}
          disabled={disabled}
        />
      )
    default:
      return (
        <TouchableOpacity
          onPress={onPressOverride || onOpenActionSheet}
          style={style}
          testID={testID}
          disabled={disabled}
        >
          {children}
        </TouchableOpacity>
      )
  }
}

const styles = StyleSheet.create({
  addPhotoBtn: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    marginBottom: 16,
  },
  addPhotoBtnText: {
    color: Colors.buttons.text,
  },
})
