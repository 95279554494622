import { isNil, isNull } from 'lodash'
import { FormData } from 'src/components/TreatmentForm'
import { FluidDosageFormData } from '../types'
import { Inputs as NonFluidFormData } from 'src/components/TreatmentForm/types'
import { MedicineDosageInfoInput, ProductType } from 'types/globalTypes'

import {
  getFluidSchedule,
  getSchedule,
  getScheduleDeprecated,
  GetScheduleInputNew,
} from './getSchedule'

export const getIsFluidFormData = (
  // @ts-ignore
  formData: FormData,
  productType?: string,
): formData is FluidDosageFormData => productType === ProductType.IVFLUIDS

const getScheduleByFormData = (formData: FormData, productType?: string) => {
  if (getIsFluidFormData(formData, productType)) {
    return getFluidSchedule(formData)
  }
  if (formData.isCRI) {
    return getFluidSchedule(formData)
  }
  if (formData.frequencyInput) {
    return getSchedule(formData as GetScheduleInputNew)
  }
  return getScheduleDeprecated(formData)
}

export function getTreatmentInput(
  formData: FormData,
  productType?: string, // TODO: Remove productType override (should use value in formData)
) {
  // TODO: use Type guards to determine FormData type
  const isMedication = productType === ProductType.MEDICATION
  const isFluids = productType === ProductType.IVFLUIDS
  const schedule = getScheduleByFormData(formData, productType)

  const isBillable = getIsBillable({
    isFluids,
    isBillable: (formData as NonFluidFormData).isBillable,
  })
  const isAutoCompleted = getIsFluidFormData(formData, productType)
    ? false
    : !!formData.isAutoCompleted

  const { treatmentName, instructions, isInstructionsImportant, isContinuous } =
    formData
  const nameInput = treatmentName ? { name: treatmentName } : {}

  return {
    ...nameInput,
    schedule,

    ...{
      medicine_dosage_info: getMedicineDosageInfoFromFormData(
        formData as NonFluidFormData,
        isMedication,
      ),
    },
    ...(isFluids && {
      fluid_dosage_info: getFluidDosageInfoFromFormData(
        formData as FluidDosageFormData,
      ),
    }),
    ...(!isNull(isBillable) && {
      is_billable: isBillable,
    }),
    is_auto_completed: isAutoCompleted,
    ...(!isNil(formData.instructions) && {
      instructions,
    }),
    is_instructions_important: isInstructionsImportant,
    is_continuous: isContinuous,
  }
}

const getIsBillable = ({
  isBillable,
  isFluids,
}: {
  isBillable?: boolean | null
  isFluids: boolean
}) => {
  if (!isNil(isBillable) && !isFluids) {
    return isBillable
  }
  return null
}

const getFluidDosageInfoFromFormData = (formData: FluidDosageFormData) => ({
  is_hypovolemic: formData.isHypovolemic,
  is_dehydrated: formData.isDehydrated,
  dehydration: formData.dehydration,
  hours_to_admin: formData.hoursToAdmin,
  dehydration_result: formData.dehydrationResult,
  shock_total_result: formData.shockTotalResult,
  physiologic_req: formData.physiologicReq,
  ongoing_losses: formData.ongoingLosses,
  total_result: formData.totalResults,
  total_result_unit: formData.totalUnit,
  iv_set: formData.IVSet,
  drip_rate: formData.dripRate,
  minutes: formData.minutes,
  hours: formData.hours,
  days: formData.days,
  patient_weight: formData.patientWeight,
  patient_weight_unit: formData.patientWeightUnit,
  preset_rate_text: formData.presetRateText,
})

type GetMedicineDosageInfoFromFormDataFn = (
  formData: NonFluidFormData,
  isMedication: boolean,
) => MedicineDosageInfoInput

const getMedicineDosageInfoFromFormData: GetMedicineDosageInfoFromFormDataFn = (
  formData: NonFluidFormData,
  isMedication: boolean,
) => {
  if (isMedication)
    return {
      // calculate_base: formData.calculateBase,
      calculate_base: 'weight',
      is_diluted: formData.isDiluted,
      diluted_concentration_volume_unit:
        formData.concentrationVolumeUnit || null,
      diluted_concentration_weight_unit: formData.concentrationWeightUnit,
      diluted_concentration: Number(formData.dilutedConcentration?.toFixed(4)),
      diluted_concentration_ratio_factor: formData.requiredRatioLHS,
      diluted_concentration_ratio_volume: formData.requiredRatioRHS,
      concentration: Number(formData.concentration),
      concentration_weight_unit: formData.concentrationWeightUnit,
      concentration_volume_unit: formData.concentrationVolumeUnit || null,
      concentration_setting: formData.concentrationSetting,
      units_billed_per_task: formData.unitsBilledPerTask,
      is_cri: formData.isCRI,
      administration_method: formData.administrationMethod,
      administration_volume: formData.fluidVolume,
      administration_volume_unit: formData.fluidVolumeUnit,
      infusion_rate: formData.infusionRate,
      infusion_rate_volume_unit: formData.infusionRateVolumeUnit,
      infusion_rate_weight_unit: formData.infusionRateWeightUnit,
      infusion_rate_time_unit: formData.infusionRateTimeUnit,
      dose_rate_time_unit: formData.doseRateTimeUnit,
      infusion_rate_total: formData.infusionRateTotal,
      route_of_administration: formData.routesOfAdmin,
      dose_rate: formData.doseRate,
      dosage: formData.dosageRate,
      dosage_weight_unit: formData.dosageWeightUnit,
      dosage_patient_weight_unit: formData.dosagePerWeightUnit,
      total_dosage: Number(formData.totalDosage),
      total_volume: Number(formData.totalVolume),
      total_volume_unit: formData.totalVolumeUnit,
      medication_volume: Number(formData.medicationVolume),
      medication_volume_unit: formData.medicationVolumeUnit,
      saline_volume: formData.isDiluted ? Number(formData.salineVolume) : null,
      saline_volume_unit: formData.isDiluted ? formData.salineVolumeUnit : null,
      minutes: formData.minutes,
      hours: formData.hours,
      days: formData.days,
      patient_weight: formData.patientWeight ?? null,
      patient_weight_unit: formData.patientWeightUnit ?? null,
      iv_bag_size: formData.ivBagSize,
      iv_bag_size_unit: formData.ivBagSizeUnit,
      diluent_used: formData.diluentUsed,
    }
  return {
    units_billed_per_task: formData.unitsBilledPerTask ?? null,
  }
}
