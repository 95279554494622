import React, { useCallback, useMemo } from 'react'
import { SCROLL_LIST_END_REACHED_THRESHOLD } from 'constants/Layout'
import { format, startOfDay } from 'date-fns'
import { groupBy, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  SectionList,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { TaskNotes } from 'src/types/TaskNotes'
import { OrgSettingKeys, useOrgSettings } from 'src/hooks/useOrgSettings'

type NoteWithTreatmentName = {
  treatmentName?: string
} & TaskNotes

export type NotesListProps = {
  notes: NoteWithTreatmentName[]
  loading?: boolean
  renderHeader: () => JSX.Element
  noteType?: string
}

const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
const DEFAULT_TIME_FORMAT = 'HH:mm'

export const NotesList: React.FC<NotesListProps> = ({
  notes,
  loading = false,
  renderHeader,
  noteType = 'treatment',
}) => {
  const { t } = useTranslation()

  const { settingsMap } = useOrgSettings()

  const dateFormat =
    settingsMap[OrgSettingKeys.PREFERRED_DATE_FORMAT]?.value ??
    DEFAULT_DATE_FORMAT
  const timeFormat =
    settingsMap[OrgSettingKeys.PREFERRED_TIME_FORMAT]?.value ??
    DEFAULT_TIME_FORMAT

  const sectionedNotes = useMemo(
    () =>
      map(
        groupBy(notes, treatmentNote =>
          startOfDay(
            new Date(treatmentNote.given_start_at || treatmentNote.start_at!),
          ),
        ),
        (treatmentNoteGroup, key) => ({
          title: format(new Date(key), dateFormat),
          data: treatmentNoteGroup.map(treatmentNote => ({
            treatmentName: treatmentNote.treatmentName,
            key: treatmentNote.id,
            time: format(
              new Date(treatmentNote.given_start_at || treatmentNote.start_at!),
              timeFormat,
            ),
            recorded_time: treatmentNote.updated_at
              ? format(new Date(treatmentNote.updated_at), timeFormat)
              : null,
            note: treatmentNote.notes,
          })),
        }),
      ),
    [dateFormat, notes, timeFormat],
  )

  const renderListFooter = useCallback(() => {
    // Show loading footer when fetching more
    if (loading) {
      return <ActivityIndicator size="large" style={styles.loadingMargin} />
    }
    if (sectionedNotes.length === 0) {
      return (
        <Text style={styles.commentsEmpty}>
          {t('treatment:comments.empty', {
            noteType,
          })}
        </Text>
      )
    }
    return null
  }, [loading, sectionedNotes, t, noteType])

  return (
    <View style={styles.container}>
      <SectionList
        keyExtractor={(_, index) => index.toString()}
        ListFooterComponent={renderListFooter()}
        ListHeaderComponent={renderHeader()}
        onEndReachedThreshold={SCROLL_LIST_END_REACHED_THRESHOLD}
        showsVerticalScrollIndicator={false}
        renderItem={({ item }) => (
          <View style={styles.noteRow}>
            <Text style={styles.time}>{item.time}</Text>
            {!!item.treatmentName && (
              <Text style={styles.name}>{item.treatmentName}</Text>
            )}
            <Text style={styles.note}>{item.note}</Text>
            {!!item.recorded_time && (
              <Text
                style={styles.recordedTimeText}
              >{` - ${item.recorded_time}`}</Text>
            )}
          </View>
        )}
        renderSectionHeader={({ section: { title } }) => (
          <View style={styles.noteSectionHeader}>
            <Text style={styles.noteSectionHeaderText}>{title}</Text>
          </View>
        )}
        sections={sectionedNotes}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  commentsEmpty: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 16,
    paddingTop: 8,
    textAlign: 'center',
  },
  loadingMargin: {
    marginTop: 25,
  },
  noteRow: {
    borderLeftColor: Colors.titleBorder,
    borderLeftWidth: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 30,
    paddingBottom: 3,
    paddingLeft: 15,
  },
  note: {
    fontFamily: Fonts.regular,
  },
  name: { fontFamily: Fonts.regular, paddingRight: 15 },
  noteSectionHeader: {
    paddingHorizontal: 20,
    paddingBottom: 5,
  },
  noteSectionHeaderText: {
    fontSize: 18,
    fontFamily: Fonts.regular,
  },
  recordedTimeText: {
    fontFamily: Fonts.regular,
    color: Colors.contentTertiary,
  },
  time: {
    fontFamily: Fonts.regular,
    paddingRight: 15,
  },
})
