import { useApolloClient, useMutation } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import {
  deleteTask as DeleteTask,
  deleteTaskVariables as DeleteTaskVariables,
} from 'src/types/deleteTask'
import {
  ApprovalAction,
  ApprovalStatus,
  DeleteTaskInput,
} from 'src/types/globalTypes'
import { toast } from 'src/components/common/Toast/ToastArea'
import { TaskFieldsFull } from 'src/types/TaskFieldsFull'

import { DELETE_TASK, TASK_FIELDS_FULL } from '../graphql'
import { useTimeResolution } from 'src/hocs/timeContext'
import { useApprovals } from 'src/hooks/useApprovals'
import { TreatmentBaseWithTasks } from 'types/TreatmentBaseWithTasks'
import { buildTreatmentWithTasksFragmentVariable } from 'components/Treatment/utils/buildTreatmentWithTasksFragmentVariable'

export const useDeleteTask = () => {
  const [deleteTask] = useMutation<DeleteTask, DeleteTaskVariables>(DELETE_TASK)
  const client = useApolloClient()
  const [{ organisationId }] = useOrganisation()
  const { fromToQueryDate } = useTimeResolution()
  const { shouldUnapproveTreatment } = useApprovals()

  const updateCache = (taskId: string, clientData: { _pending: boolean }) => {
    const cachedTask = client.readFragment<TaskFieldsFull>({
      id: `Task:${taskId}`,
      fragment: TASK_FIELDS_FULL,
      fragmentName: 'TaskFieldsFull',
    })

    if (!cachedTask) return

    client.writeFragment({
      id: `Task:${taskId}`,
      fragment: TASK_FIELDS_FULL,
      fragmentName: 'TaskFieldsFull',
      data: {
        ...cachedTask,
        ...clientData,
      },
    })
  }

  return (
    task: DeleteTaskInput,
    isDeletable = true,
    approvalAction?: ApprovalAction,
  ) => {
    // TODO: show error toast if not correct task type
    // if (!getTaskIsDeleteable(task)) return
    if (!isDeletable) return

    return deleteTask({
      variables: {
        input: {
          id: task.id,
          organisation_id: organisationId,
          sheet_id: task.sheet_id,
          treatment_id: task.treatment_id,
          ...(approvalAction ? { approval_action: approvalAction } : {}),
        },
      },
      fetchPolicy: 'no-cache',
      optimisticResponse: {
        deleteTask: true,
      },
      update: () => updateCache(task.id, { _pending: true }),
    })
      .then(() => {
        client.cache.evict({ id: `Task:${task.id}` })

        const fragmentVariable = buildTreatmentWithTasksFragmentVariable(
          task.treatment_id,
          fromToQueryDate,
        )

        const cachedTreatment =
          client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

        if (!cachedTreatment) return

        if (
          shouldUnapproveTreatment(cachedTreatment) &&
          approvalAction !== ApprovalAction.STOP_FLUID
        ) {
          client.writeFragment({
            ...fragmentVariable,
            data: {
              ...cachedTreatment,
              approval_status: ApprovalStatus.PENDING,
            },
          })
        }
      })
      .catch(err => {
        toast.error('Error Deleting Task')
        console.error(err) // eslint-disable-line no-console
      })
  }
}
