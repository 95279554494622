import { DateTimePicker } from 'components/common'
import React, { useState } from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { WarningType } from '../DateTimePicker/index.types'
import { SwitchInput } from '.'
import { DialogHeights } from '../Dialog/common'

export enum PickerType {
  NOW = 'now',
  DISCHARGE = 'discharge',
  EDIT = 'edit',
}
type Props<T extends PickerType> = {
  onChange: (date: Props<T>['value'] | string) => void
  type: T
  value: T | Date
  defaultTimeToStart: Date
  switchLabel?: string
  pickerLabel?: string
  dialog?: boolean
  lastUsedTime?: Date
  dialogHeight?: DialogHeights
  textStyle?: StyleProp<TextStyle>
  labelStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  title?: string
}

export const SwitchInputDateTimePicker = <T extends PickerType>({
  onChange,
  type,
  value,
  defaultTimeToStart,
  switchLabel,
  pickerLabel,
  dialog = true,
  lastUsedTime,
  dialogHeight = DialogHeights.SMALL,
  textStyle,
  labelStyle,
  style,
  title,
  ...props
}: Props<T>) => {
  const [switchState, setSwitchState] = useState(
    Object.values(PickerType).includes(value as PickerType),
  )

  const [currentTime, setCurrentTime] = useState(
    typeof value === 'string' ? defaultTimeToStart : value,
  )

  const onSwitchChange = (value: boolean) => {
    setSwitchState(value)

    const updatedValue = value ? type : currentTime
    onChange(updatedValue)
  }

  const onPickerChange = (time: Date) => {
    setCurrentTime(time)
    onChange(time)
  }

  return (
    <View>
      <SwitchInput
        {...props}
        style={style}
        value={switchState}
        onChangeValue={onSwitchChange}
        label={switchLabel}
      />

      {!switchState && (
        <DateTimePicker
          dialog={dialog}
          dialogHeight={dialogHeight}
          label={pickerLabel}
          onChange={onPickerChange}
          value={currentTime}
          textStyle={textStyle}
          labelStyle={labelStyle}
          warningInfo={{ type: WarningType.TREATMENT }}
          title={title}
        />
      )}
    </View>
  )
}
