import { gql } from '@apollo/client'

export const GET_PROCEDURES = gql`
  query getProcedures($organisation_id: ID!) {
    getProcedures(organisation_id: $organisation_id) {
      items {
        id
        name
        use_case
        template_workflow_id
        sites {
          id
          name
        }
      }
    }
  }
`

const PROCEDURE_TREATMENT_BASE = gql`
  fragment ProcedureTreatmentBase on ProcedureTreatment {
    id
    source
    procedure_id
    name
    parent_id
    product {
      name
      id
      origin_id
      is_bundle
      sites
      parent_product {
        id
        name
      }
    }
  }
`

export const GET_PROCEDURE = gql`
  query getProcedure($id: ID!, $organisation_id: ID!) {
    getProcedure(id: $id, organisation_id: $organisation_id) {
      id
      name
      use_case
      template_workflow_id
      sites {
        id
        name
      }
      treatments {
        items {
          ...ProcedureTreatmentBase
          treatments {
            items {
              ...ProcedureTreatmentBase
              treatments {
                items {
                  ...ProcedureTreatmentBase
                }
              }
            }
          }
        }
      }
      __typename
    }
  }
  ${PROCEDURE_TREATMENT_BASE}
`

export const UPDATE_PROCEDURE = gql`
  mutation updateProcedure($input: UpdateProcedureInput!) {
    updateProcedure(input: $input) {
      id
      organisation_id
      template_workflow_id
      use_case
    }
  }
`
