import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { SvgFilter } from 'components/Icons/Filter'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { GET_USERS_LIST } from 'src/components/Settings/graphql'
import { GET_DEPARTMENTS } from 'src/components/Whiteboard/graphql'
import { useOrganisation } from 'src/context/organisation'
import { UnassignedOption, useLocationTree } from 'src/hooks/useLocationTree'
import {
  getDepartments as GetDepartments,
  getDepartmentsVariables as GetDepartmentsVariables,
} from 'src/types/getDepartments'
import { ApprovalStatus, OriginTypes } from 'types/globalTypes'
import { SecondaryButton } from 'components/common'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { SelectOption } from 'components/common/TreeSelect/index.types'
import { DocSiteFormat } from './utils/useLocalStorageChangeFilters'
import { approvalStatusOptions } from './data'
import { MultiSelect } from 'src/design-system/components/Selects'
import { NestedMultiSelect } from 'src/design-system/components/Selects/NestedMultiSelect/NestedMultiSelect.web'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { getUsersList, getUsersListVariables } from 'types/getUsersList'
import { getVetVetTechFromUsers } from 'components/Patient/utils/patientListFilter.utils'

type Props = {
  selectedDepartments: string[]
  onSelectDepartment: (val: string[]) => void
  selectedLocations: string[]
  onSelectLocation: (val: string[]) => void
  selectedVets: string[]
  selectedVetTechs: string[]
  onSelectVet: (val: string[]) => void
  onSelectVetTech: (val: string[]) => void
  selectedApprovalStatuses: ApprovalStatus[]
  onSelectApprovalStatus: (val: ApprovalStatus[]) => void
  clearFilters: () => void
  setDoctors: (value: DocSiteFormat[]) => void
  setVetTechs: (value: DocSiteFormat[]) => void
  setDepartments: (value: DocSiteFormat[]) => void
  setLocations: (value: SelectOption<string>[]) => void
  isWhiteboardFilter?: boolean
}

export const PatientListFilterV2: React.FC<Props> = React.memo(
  ({
    selectedDepartments,
    selectedLocations,
    selectedVets,
    selectedVetTechs,
    selectedApprovalStatuses,
    onSelectDepartment,
    onSelectLocation,
    onSelectVet,
    onSelectVetTech,
    onSelectApprovalStatus,
    clearFilters,
    isWhiteboardFilter = false,
    setDoctors,
    setVetTechs,
    setDepartments,
    setLocations,
  }) => {
    const { t } = useTranslation()
    const [isDrawerVisible, setIsDrawerVisible] = useState(false)
    const [{ organisationId }] = useOrganisation()

    const { data: dataDepartments, loading: isLoadingDepartments } = useQuery<
      GetDepartments,
      GetDepartmentsVariables
    >(GET_DEPARTMENTS, {
      skip: !isDrawerVisible,
      variables: {
        organisationId,
      },
    })

    const { data: dataVetsVetTechs, loading: isLoadingVetsVetTechs } = useQuery<
      getUsersList,
      getUsersListVariables
    >(GET_USERS_LIST, {
      fetchPolicy: 'cache-and-network', // see comment on GET_USERS_LIST
      skip: !isDrawerVisible,
      variables: {
        organisation_id: organisationId,
        origin_type: OriginTypes.EZYVET, // only getting ezyvetUsers
      },
    })

    const toggleDrawer = useCallback(
      () => setIsDrawerVisible(isVisible => !isVisible),
      [],
    )

    const departmentOptions = useMemo(() => {
      const dataDepartmentOptions =
        dataDepartments?.getSites?.items?.map(dept => ({
          value: dept.id,
          text: dept.name,
        })) ?? []
      if (isWhiteboardFilter) return dataDepartmentOptions
      return [UnassignedOption, ...dataDepartmentOptions]
    }, [dataDepartments, isWhiteboardFilter])

    const { locationOptions, loading: isLoadingLocations } = useLocationTree(
      !isWhiteboardFilter,
      !isDrawerVisible,
    )
    const locationGroups = locationOptions.map(item => {
      if (item.children) {
        return {
          value: item.value,
          label: item.text,
          options: item.children,
        }
      }
      return item
    })

    const [vetOptions, vetTechOptions] = useMemo(() => {
      const users = dataVetsVetTechs?.getUsersList?.items ?? []
      const { vets, vetTechs } = getVetVetTechFromUsers(users)
      const compareFn = (a: { text: string }, b: { text: string }) =>
        a.text.localeCompare(b.text)
      return [
        [UnassignedOption, ...vets.sort(compareFn)],
        [UnassignedOption, ...vetTechs.sort(compareFn)],
      ]
    }, [dataVetsVetTechs?.getUsersList?.items])

    useEffect(() => {
      setLocations(locationOptions)
    }, [locationOptions, setLocations])

    useEffect(() => {
      setDepartments(departmentOptions)
    }, [departmentOptions, setDepartments])

    useEffect(() => {
      setDoctors(vetOptions)
      setVetTechs(vetTechOptions)
    }, [vetTechOptions, setVetTechs, setDoctors, vetOptions])

    return (
      <>
        <FilterPatientsToggle onPress={toggleDrawer} />
        <SheetAwareSideDrawer visible={isDrawerVisible} onClose={toggleDrawer}>
          <View style={styles.sideDrawerInnerContainer}>
            <Text style={styles.filterTitle}>
              {t('patient:list.filterTitle')}
            </Text>
            <View style={styles.clearBtn}>
              <SecondaryButton onPress={clearFilters} title="Clear filters" />
            </View>
            <KeyboardAwareScrollView>
              {!isWhiteboardFilter ? (
                <>
                  <MultiSelect
                    label={t('whiteboard:filter.doctorFilterPlaceholder')}
                    onChange={onSelectVet}
                    options={vetOptions}
                    selected={selectedVets}
                    loading={isLoadingVetsVetTechs}
                  />
                  <MultiSelect
                    options={vetTechOptions}
                    selected={selectedVetTechs}
                    onChange={onSelectVetTech}
                    loading={isLoadingVetsVetTechs}
                    label={t('whiteboard:filter.vetTechFilterPlaceholder')}
                  />
                </>
              ) : null}
              <MultiSelect
                label={t('whiteboard:filter.departmentFilterPlaceholder')}
                onChange={onSelectDepartment}
                options={departmentOptions}
                selected={selectedDepartments}
                loading={isLoadingDepartments}
              />
              <NestedMultiSelect
                onChange={onSelectLocation}
                options={locationGroups}
                label={t('whiteboard:filter.locationFilterPlaceholder')}
                loading={isLoadingLocations}
                selected={selectedLocations}
              />
              <MultiSelect
                onChange={onSelectApprovalStatus}
                options={approvalStatusOptions}
                label={t('whiteboard:filter.approvalStatusFilterPlaceholder')}
                selected={selectedApprovalStatuses}
              />
            </KeyboardAwareScrollView>
          </View>
        </SheetAwareSideDrawer>
      </>
    )
  },
)

PatientListFilterV2.displayName = 'PatientListFilter'

const FilterPatientsToggle: React.FC<{ onPress: () => void }> = React.memo(
  ({ onPress }) => {
    const { t } = useTranslation()
    return (
      <View style={styles.container}>
        <TouchableOpacity
          accessibilityLabel={t('patient:list.filter')}
          onPress={onPress}
          style={mergedStyles.drawerToggle}
          testID="Filter patients toggle"
        >
          <SvgFilter />
          <Text style={styles.filterText}>{t('patient:list.filter')}</Text>
        </TouchableOpacity>
      </View>
    )
  },
)

FilterPatientsToggle.displayName = 'FilterPatientsToggle'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  selector: {
    height: 44,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Colors.buttons.listFilters,
    // use specific rules to overwrite inner style
    borderBottomWidth: 2,
    borderBottomColor: Colors.buttons.listFilters,
    borderLeftWidth: 2,
    borderLeftColor: Colors.buttons.listFilters,
    backgroundColor: Colors.backgroundGrey,
    paddingLeft: 8,
    paddingRight: 8,
  },
  filter: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterText: {
    marginLeft: 5,
    marginRight: 2,
    fontFamily: Fonts.semibold,
  },
  clearBtn: {
    marginHorizontal: 16,
    paddingBottom: 10,
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  sideDrawerInnerContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  filterTitle: {
    textAlign: 'center',
    fontFamily: Fonts.bold,
    fontSize: 24,
    paddingTop: 24,
  },
})

const mergedStyles = { drawerToggle: [styles.selector, styles.filter] }
