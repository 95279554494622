import { SvgArrow, SvgPencil } from 'components/Icons'
import { Colors } from 'constants/Colors'
import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/user'
import {
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { IconButtonWithText } from 'components/IconButtonWithText'
import { Shadows } from 'constants/Shadows'
import { Fonts } from 'constants/Fonts'
import {
  card_status,
  card_status_type,
  local_toggle_type,
} from 'constants/LocalToggleOptions'
import { useLocalToggle } from 'components/Sheet/useLocalToggle'
import { environment } from 'src/config'

const { isWeb } = environment

type StylingProps = {
  containerStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  contentTextStyle?: StyleProp<TextStyle>
  editingStyle?: StyleProp<ViewStyle>
  editingTextStyle?: StyleProp<TextStyle>
  buttonStyle?: StyleProp<ViewStyle>
  buttonTextStyle?: StyleProp<TextStyle>
  buttonIconColor?: string
}

export type Props = {
  isCollapsible?: boolean
  key: string
  header?: string
  showHeader?: boolean
  statusId?: card_status_type
  onPress?: (v?: string) => void
  value?: string
  defaultLabel?: string
  cardStyles?: StylingProps
}

export const EditableCard = ({
  header,
  showHeader = true,
  isCollapsible = false,
  statusId,
  onPress,
  value,
  defaultLabel = `No ${header?.toLowerCase()}`,
  cardStyles,
}: Props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [isExpanded, setIsExpanded] = useLocalToggle({
    prefix: user?.id ? user.id : '',
    type: local_toggle_type.SHOWPANEL,
    key: statusId,
    initialState: statusId && card_status[statusId],
  })

  const [isBeingEdited, setIsBeingEdited] = useState(false)
  const [val, setVal] = useState(value)

  useEffect(() => {
    setVal(value)
  }, [value])

  // Custom Autofocus, as using the autoFocus prop won't trigger KeyboardAware scrolling.
  const inputRef = useRef<TextInput>(null)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      if (isWeb) {
        const htmlRef: HTMLInputElement =
          inputRef.current as unknown as HTMLInputElement
        htmlRef.setSelectionRange(-1, -1)
      }
    }
  }, [isBeingEdited])

  const handleEdit = () => {
    setIsBeingEdited(true)
  }

  const handleSave = () => {
    onPress && onPress(val?.trim())
    setIsBeingEdited(false)
  }

  const handleExpand = () => {
    setIsExpanded(!isExpanded, statusId)
  }

  return (
    <View style={[styles.card, cardStyles?.containerStyle]}>
      {!!showHeader && !!(isCollapsible || header) && (
        <TouchableOpacity
          style={styles.headerContainer}
          disabled={!isCollapsible}
          onPress={handleExpand}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.headerText]}>{header}</Text>
          </View>
          {!!isCollapsible && (
            <SvgArrow
              width={9}
              height={9}
              color={Colors.contentPrimary}
              orient={isExpanded ? 'up' : 'down'}
            />
          )}
        </TouchableOpacity>
      )}
      {!!isExpanded && (
        <>
          <View style={[styles.content, cardStyles?.contentStyle]}>
            {isBeingEdited ? (
              <TextInput
                ref={inputRef}
                onBlur={handleSave}
                onChangeText={prevVal => setVal(prevVal)}
                value={val ?? ''}
                multiline
                numberOfLines={4}
                style={[
                  styles.editingStyle,
                  cardStyles?.editingStyle,
                  styles.contentText,
                  cardStyles?.contentTextStyle,
                ]}
              />
            ) : (
              <Text style={[styles.contentText, cardStyles?.contentTextStyle]}>
                {val || defaultLabel}
              </Text>
            )}
          </View>
          {!isBeingEdited ? (
            <IconButtonWithText
              action={handleEdit}
              label={t('patient:info.saveButton')}
              buttonStyle={[styles.editButton, cardStyles?.buttonStyle]}
              icon={
                <SvgPencil
                  scale={0.6}
                  color={cardStyles?.buttonIconColor || Colors.buttons.blue}
                />
              }
              textStyle={[styles.editText, cardStyles?.buttonTextStyle]}
              title={t('patient:info.editButton')}
              hideText={false}
            />
          ) : (
            <Pressable
              onPress={handleSave}
              accessibilityLabel={t('patient:info.saveButton')}
              style={[styles.saveButton, cardStyles?.buttonStyle]}
            >
              <Text style={[styles.editText, cardStyles?.buttonTextStyle]}>
                {t('patient:info.saveButton')}
              </Text>
            </Pressable>
          )}
        </>
      )}
    </View>
  )
}

export const styles = StyleSheet.create({
  card: {
    backgroundColor: Colors.white,
    borderRadius: 6,
    padding: 8,
    marginVertical: 4,
    ...Shadows.cardShadow,
  },
  headerText: {
    // fontWeight: '700',
    color: Colors.contentPrimary,
    fontSize: 14,
    fontFamily: Fonts.bold,
  },
  content: {
    padding: 8,
    paddingTop: 0,
  },
  contentText: {
    fontSize: 14,
    fontFamily: Fonts.regular,
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    paddingBottom: 8,
  },
  editButton: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  saveButton: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  editText: {
    color: Colors.buttons.blue,
    fontFamily: Fonts.extrabold,
    fontSize: 14,
  },
  editingStyle: {
    padding: 8,
  },
})
