import React, { useCallback, useEffect, useState } from 'react'

import { TrainingBanner } from './TrainingBanner'
import { IntroductionBanner } from './IntroductionBanner'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import { useFlags } from 'react-native-flagsmith/react'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { useTrainingSwitch } from './useTrainingSwitch'
import { useUser } from 'src/context/user'
import { useOrgSettings } from 'src/hooks/useOrgSettings'

const HIDE_BANNER_PREFERENCE_KEY = 'trainingBannerDismissed'

export const TrainingSection = () => {
  const { organisation } = useOrganisationHook()
  const { settingsMap } = useOrgSettings()
  const { preferences } = useUser()
  const { toggleTrainingMode, isTrainingOrganisation } = useTrainingSwitch()
  const { training_site } = useFlags([FeatureFlagNames.TRAINING_SITE])

  const [userHideTrainingBanner, setUserHideTrainingBanner] = useState(true)

  useEffect(() => {
    setUserHideTrainingBanner(
      preferences
        ? preferences.getPreference(HIDE_BANNER_PREFERENCE_KEY)
        : true,
    )
  }, [preferences])

  const dismissModal = useCallback(() => {
    setUserHideTrainingBanner(true)
    preferences?.setPreference(HIDE_BANNER_PREFERENCE_KEY, true)
  }, [preferences])

  const enableBannerOrgSetting = JSON.parse(
    settingsMap?.ENABLE_TRAINING_BANNER?.value?.toLowerCase() || 'false',
  )

  const showIntroBanner =
    training_site.enabled &&
    enableBannerOrgSetting &&
    !isTrainingOrganisation &&
    !userHideTrainingBanner &&
    organisation?.training_organisation_id

  /* Always show indication that the org is training mode, regardless of settings or flags */
  if (isTrainingOrganisation) {
    return <TrainingBanner navigateToRealSite={toggleTrainingMode} />
  }

  if (showIntroBanner) {
    return (
      <IntroductionBanner
        hideCard={dismissModal}
        navigateToTrainingSite={toggleTrainingMode}
      />
    )
  }

  return null
}
