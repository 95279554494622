import {
  TimeNavigator,
  UpdateTimeAction,
} from 'components/common/TimeNavigator'
import { addHours, startOfHour } from 'date-fns'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'
import { FontFamilies } from 'src/design-system/theme/typography/base'

type Props = {
  initialStartAt: string
  startAt: string
  updateStartAt: (startAt: string) => void
  taskCount?: number
}

export const TimeNav: React.FC<Props> = ({
  initialStartAt,
  startAt,
  updateStartAt,
  taskCount,
}) => {
  const disableIterator = (action: UpdateTimeAction) => {
    const startAtDate = new Date(startAt)
    const amount = action === UpdateTimeAction.INCREMENT ? 12 : -12
    return (
      startAtDate.getTime() ===
      addHours(startOfHour(new Date(initialStartAt)), amount).getTime()
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.timeNavContainer}>
        <TimeNavigator
          selectedTime={startAt}
          updateSelectedTime={updateStartAt}
          disableNext={disableIterator(UpdateTimeAction.INCREMENT)}
          disablePrevious={disableIterator(UpdateTimeAction.DECREMENT)}
        />
        {taskCount ? (
          <View style={styles.badgeContainer}>
            <View style={styles.badge}>
              <Text style={styles.badgeText}>
                {taskCount >= 99 ? '99+' : taskCount}
              </Text>
            </View>
          </View>
        ) : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    height: 61,
    borderTopWidth: 1,
    // @ts-ignore
    borderTopStyle: 'solid',
    borderTopColor: Colors.Borders.primary,
  },
  timeNavContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  badgeContainer: {
    justifyContent: 'space-evenly',
    marginLeft: 4,
  },
  badge: {
    backgroundColor: Colors.Backgrounds.overlay,
    padding: 8,
    borderRadius: 4,
  },
  badgeText: {
    ...Typography.Label.S,
    fontFamily: FontFamilies.bold,
  },
})
