import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputKeyPressEventData,
  View,
} from 'react-native'
import {
  PolicyRule,
  RuleStatus,
} from 'src/design-system/components/TextInput/TextInput'
import { Colors } from 'src/design-system/theme'
import { useBreakpoint } from 'src/hocs/breakpoint'
import {
  PinInputType,
  determineKeyboardType,
  isRepeatingPin,
  isSequentialPin,
  validateInput,
} from './utils'
import { DefaultStatus } from '../DefaultStatus'
import { ValidStatus } from '../ValidStatus'
import { InvalidStatus } from '../InvalidStatus'
import { Fonts } from 'constants/Fonts'

type PinInputV2Props = {
  size?: number
  type?: PinInputType
  secureInput?: boolean
  onChange: (value: string, reset: () => void) => void
  onConfirm?: () => void
  displayPinPolicies?: boolean
  onValidation?: (results: RuleStatus[] | null) => void
}

const PIN_LENGTH = 5

export const PinInput: React.FC<PinInputV2Props> = forwardRef(
  (
    {
      size = PIN_LENGTH,
      type = PinInputType.NUMERIC,
      secureInput = true,
      onChange,
      onConfirm,
      onValidation,
      displayPinPolicies = false,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const { isExSmallScreen } = useBreakpoint()
    const inputRefs = useRef<TextInput[]>(Array(size).fill(null))
    const [pin, setPin] = useState<string[]>(Array(size).fill(''))
    const [validationResults, setValidationResults] = useState<RuleStatus[]>([])
    const [invalid, setIsInvalid] = useState(false)

    // Pin policies rules, currently there are only rules for numeric pins
    const pinPolicies: PolicyRule[] = useMemo(() => {
      if (type === PinInputType.NUMERIC) {
        return [
          {
            rule: pin => !isRepeatingPin(pin),
            message: t('login:pin:error:repeating'),
          },
          {
            rule: pin => !isSequentialPin(pin),
            message: t('login:pin:error:sequential'),
          },
        ]
      }

      return []
    }, [t, type])

    const reset = useCallback(() => {
      setPin(Array(size).fill(''))
      inputRefs.current[0].focus()
      setIsInvalid(false)
    }, [size])

    useImperativeHandle(ref, () => ({
      reset() {
        setPin(Array(size).fill(''))
        inputRefs.current[0].focus()
      },
    }))

    const handleKeyPress = (
      { nativeEvent }: NativeSyntheticEvent<TextInputKeyPressEventData>,
      idx: number,
    ): void => {
      if (nativeEvent.key === 'Backspace') {
        if (pin[idx]) {
          pin[idx] = ''
        } else if (idx > 0) {
          pin[idx - 1] = ''
          inputRefs.current[idx - 1].focus()
        }
        setPin([...pin])
        onChange(pin.join(''), reset)
        validatePinPolicies(pin)
      }

      if (nativeEvent.key === 'Enter' && idx === size - 1 && onConfirm) {
        inputRefs.current[idx - 1].blur()
        onConfirm()
      }
    }

    const handleChangeText = (value: string, idx: number): void => {
      if (!validateInput(type, value)) return

      if (value.length > 1) {
        // Logic for Copy/Paste
        const endIdx = Math.min(idx + value.length, size)

        const newPin = [
          ...pin.slice(0, idx),
          ...value.split('').slice(0, endIdx - idx),
          ...pin.slice(endIdx),
        ]
        setPin(newPin)

        if (inputRefs.current[endIdx - 1]) {
          inputRefs.current[endIdx - 1].focus()
        }
        onChange(newPin.join(''), reset)
        validatePinPolicies(newPin)
        return
      }

      pin[idx] = value
      setPin([...pin])
      if (value) {
        inputRefs.current[idx + 1]?.focus()
      }
      onChange(pin.join(''), reset)
      validatePinPolicies(pin)
    }

    const validatePinPolicies = (pin: string[]) => {
      const pins = pin.join('')
      if (pins.length === size) {
        const validationResults: RuleStatus[] = pinPolicies.map(rule => ({
          isValid: rule.rule(pins),
          message: rule.message,
        }))

        setValidationResults(validationResults)
        onValidation?.(validationResults)
        setIsInvalid(validationResults.some(result => !result.isValid))
        inputRefs.current[size - 1].blur()
      } else {
        // Reset the validation and invalid state
        setValidationResults([])
        onValidation?.(null)
        setIsInvalid(false)
      }
    }

    const inputFontSize = isExSmallScreen ? 48 : 64

    const activeBorderColor = useCallback(
      (idx: number) => {
        if (invalid && displayPinPolicies) return Colors.Borders.negative

        return inputRefs.current[idx]?.isFocused()
          ? Colors.Borders.accent
          : Colors.Borders.tertiary
      },
      [invalid, displayPinPolicies],
    )

    useEffect(() => {
      inputRefs.current[0].focus()
    }, [])

    const PolicyMessages = useCallback(() => {
      if (validationResults?.length > 0) {
        return (
          <>
            {validationResults.map(policy =>
              !!policy.isValid ? (
                <ValidStatus key={policy.message} status={policy.message} />
              ) : (
                <InvalidStatus key={policy.message} status={policy.message} />
              ),
            )}
          </>
        )
      }

      return (
        <>
          {pinPolicies?.map(policy => (
            <DefaultStatus key={policy.message} status={policy.message} />
          ))}
        </>
      )
    }, [validationResults, pinPolicies])

    return (
      <>
        <View style={styles.container} aria-label="PinInput">
          {inputRefs.current.map((_, idx) => {
            return (
              <TextInput
                key={idx}
                style={[
                  styles.textInput,
                  {
                    fontSize: inputFontSize,
                    borderColor: activeBorderColor(idx),
                  },
                  secureInput
                    ? {
                        // This font is used to override the behavior on IOS where the entered number is shown briefly before being hidden,
                        // And prevent Chrome from autofilling the inputs even with autocomplete="off" (https://bugs.chromium.org/p/chromium/issues/detail?id=370363)
                        fontFamily: Fonts.textSecurityDisc,
                      }
                    : {},
                ]}
                aria-label="PinInputField"
                underlineColorAndroid="transparent"
                autoCorrect={false}
                autoCapitalize="none"
                autoComplete="off"
                keyboardType={determineKeyboardType(type)}
                value={pin[idx]?.at(0) ?? ''}
                onChangeText={value => handleChangeText(value, idx)}
                onKeyPress={e => handleKeyPress(e, idx)}
                ref={ref => {
                  if (ref) inputRefs.current[idx] = ref
                }}
              />
            )
          })}
        </View>
        <View style={styles.pinPolicy}>
          {displayPinPolicies ? <PolicyMessages /> : null}
        </View>
      </>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    gap: 8,
    maxWidth: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.Backgrounds.UI,
  },
  textInput: {
    flex: 1,
    height: 96,
    minWidth: 48,
    borderRadius: 8,
    borderWidth: 2,
    textAlign: 'center',
    backgroundColor: Colors.Backgrounds.UI,
  },
  pinPolicy: {
    marginTop: 8,
  },
})

PinInput.displayName = 'PinInput'
