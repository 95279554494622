import { Timers } from 'components/PatientPanel/Cards/Timers'
import React from 'react'
import { SheetPatientInfoFields } from 'types/SheetPatientInfoFields'
import { useTranslation } from 'react-i18next'

type Props = {
  sheetPatientInfo: SheetPatientInfoFields
}

export const AnesthesiaTimers: React.FunctionComponent<Props> = ({
  sheetPatientInfo,
}) => {
  const { t } = useTranslation()

  return (
    <Timers
      sheetPatientInfo={sheetPatientInfo}
      a11yLabel={t('patientPanel:cards.anesthesiaTimer.timer.label')}
    />
  )
}
