import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { useCallback, useEffect, useState } from 'react'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as SubTreatmentTask } from 'src/types/getSheet'
import { HandleChange } from '../BulkTaskList'
import { TabName } from 'components/BulkTask/common'
import { usePermission } from 'src/hooks/usePermission'
import { ApprovalStatus, PermissionAction, Status } from 'types/globalTypes'
import { useApprovals } from 'src/hooks/useApprovals'

export const shouldDisableDeleteCheck = (
  hasDeleteMissedTaskPermission: boolean,
  nextTaskToComplete?: SubTreatmentTask,
) => {
  return (
    nextTaskToComplete?.status === Status.MISSED &&
    !hasDeleteMissedTaskPermission
  )
}

export const areAllMissedTasks = (valueArray: BulkValue[]) => {
  return valueArray.every(subTreatmentAndFirstTask => {
    return (
      subTreatmentAndFirstTask?.nextTaskToComplete?.status === Status.MISSED
    )
  })
}

export const areAllUnapprovedTreatmentTasks = (valueArray: BulkValue[]) => {
  return valueArray.every(subTreatmentAndFirstTask => {
    return (
      subTreatmentAndFirstTask?.treatment?.approval_status ===
      ApprovalStatus.PENDING
    )
  })
}

type BulkValue = {
  checked: boolean
  treatment: TreatmentChildLevel
  completeChecked?: boolean
  nextTaskToComplete?: SubTreatmentTask
  deleteChecked?: boolean
}

export type Props = {
  valueArray: BulkValue[]
  isCompletingTask: boolean
  handleChange: HandleChange
  isBulkCreating: boolean
  isSkippingTask?: boolean
  isDeletingTask?: boolean
  currentTab?: TabName
}
export const useSelectAll = ({
  valueArray,
  isCompletingTask,
  handleChange,
  isBulkCreating,
  isSkippingTask,
  isDeletingTask,
}: Props) => {
  const [isAllSelected, setIsAllSelected] = useState(false)

  const hasDeleteMissedTaskPermission = usePermission(
    PermissionAction.TASK__DELETE_MISSED,
  )

  const { isTreatmentPending } = useApprovals()

  const clearAllSelect = useCallback(() => {
    if (isSkippingTask && isAllSelected) {
      valueArray.forEach(subTreatmentAndFirstTask => {
        const { nextTaskToComplete, treatment } = subTreatmentAndFirstTask

        if (isTreatmentPending(treatment)) return

        handleChange(`${nextTaskToComplete?.id}.completeChecked`, true)

        return
      })
    }
  }, [
    handleChange,
    isAllSelected,
    isSkippingTask,
    isTreatmentPending,
    valueArray,
  ])

  const handleAllSelect = useCallback(() => {
    valueArray.forEach(subTreatmentAndFirstTask => {
      const { nextTaskToComplete, treatment } = subTreatmentAndFirstTask

      if (
        isDeletingTask &&
        shouldDisableDeleteCheck(
          hasDeleteMissedTaskPermission,
          nextTaskToComplete,
        )
      ) {
        return
      }

      if (
        (isCompletingTask || isSkippingTask) &&
        isTreatmentPending(treatment)
      ) {
        return
      }

      if (isBulkCreating) {
        handleChange(`${treatment.id}.checked`, !isAllSelected)
        return
      }

      if (isDeletingTask) {
        handleChange(`${nextTaskToComplete?.id}.deleteChecked`, !isAllSelected)
        return
      }

      if (!isCompletingTask) {
        handleChange(`${nextTaskToComplete?.id}.checked`, !isAllSelected)
        return
      }

      if (isSkippingTask) {
        handleChange(
          `${nextTaskToComplete?.id}.completeChecked`,
          !isAllSelected,
        )
        return
      }
      handleChange(`${nextTaskToComplete?.id}.completeChecked`, !isAllSelected)
    })
  }, [
    valueArray,
    isDeletingTask,
    hasDeleteMissedTaskPermission,
    isCompletingTask,
    isSkippingTask,
    isTreatmentPending,
    isBulkCreating,
    handleChange,
    isAllSelected,
  ])

  useEffect(() => {
    // If we are on the deleted tab and all tasks are missed task
    if (
      isDeletingTask &&
      !hasDeleteMissedTaskPermission &&
      areAllMissedTasks(valueArray)
    ) {
      setIsAllSelected(false)
      return
    }

    if (
      (isCompletingTask || isSkippingTask) &&
      areAllUnapprovedTreatmentTasks(valueArray)
    ) {
      setIsAllSelected(false)
      return
    }

    const allChecked = valueArray.every(subTreatmentAndFirstTask => {
      if (isDeletingTask) {
        return (
          shouldDisableDeleteCheck(
            hasDeleteMissedTaskPermission,
            subTreatmentAndFirstTask.nextTaskToComplete,
          ) || subTreatmentAndFirstTask.deleteChecked
        )
      }

      if (!isCompletingTask) {
        return subTreatmentAndFirstTask.checked
      }

      return (
        isTreatmentPending(subTreatmentAndFirstTask.treatment) ||
        subTreatmentAndFirstTask.completeChecked
      )
    })
    setIsAllSelected(allChecked)
  }, [
    valueArray,
    isCompletingTask,
    isDeletingTask,
    hasDeleteMissedTaskPermission,
    isTreatmentPending,
    isSkippingTask,
  ])

  return { isAllSelected, handleAllSelect, clearAllSelect }
}
