import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}
export const SvgComment: React.FC<Props> = ({
  width = 23,
  height = 21,
  color = '#F5A623',
}) => (
  <Svg width={width} height={height}>
    <Path d="M.236 0H23v20.5z" fill={color} fillRule="nonzero" />
  </Svg>
)
