import { useMutation } from '@apollo/client'
import { useTimeResolution } from 'src/hocs/timeContext'
import {
  approveSheetTreatments,
  approveSheetTreatmentsVariables,
} from 'types/approveSheetTreatments'
import { APPROVE_SHEET_TREATMENTS } from 'components/Treatment/graphql'
import { GetSheet, GetSheetVariables } from 'components/Task/types'
import { GET_SHEET } from 'components/Sheet/graphql'
import { cloneDeep } from 'lodash'
import { ApprovalStatus } from 'types/globalTypes'

type SheetTreatments = {
  items:
    | {
        approval_status: ApprovalStatus | null
        treatments?: SheetTreatments | null
      }[]
    | null
}

export const useApproveSheetTreatments = () => {
  const { fromToQueryDate } = useTimeResolution()

  const [approveSheetTreatmentsMutation, { loading }] = useMutation<
    approveSheetTreatments,
    approveSheetTreatmentsVariables
  >(APPROVE_SHEET_TREATMENTS)

  const recursiveApproveTreatments = (treatments?: SheetTreatments | null) => {
    const treatmentsItems = treatments?.items

    if (!treatmentsItems) return

    treatmentsItems.forEach(treatment => {
      treatment.approval_status = ApprovalStatus.APPROVED
      recursiveApproveTreatments(treatment?.treatments)
    })
  }

  const approveSheetTreatments = (sheetId: string, organisationId: string) =>
    approveSheetTreatmentsMutation({
      variables: {
        input: {
          id: sheetId,
          organisation_id: organisationId,
        },
      },
      update: (cache, { data }) => {
        if (!data?.approveSheet) return

        const variables = {
          id: sheetId,
          organisation_id: organisationId,
          ...fromToQueryDate,
        }

        const sheet = cache.readQuery<GetSheet, GetSheetVariables>({
          query: GET_SHEET,
          variables,
        })

        const clonedSheet = cloneDeep(sheet)

        const treatments = clonedSheet?.getSheet?.treatments

        recursiveApproveTreatments(treatments)

        cache.writeQuery({
          query: GET_SHEET,
          variables,
          data: clonedSheet,
        })
      },
    })

  return {
    approveSheetTreatments,
    loading,
  }
}
