import { useCallback, useState } from 'react'
import { isArray, omit } from 'lodash'
import { UseFormReturn } from 'react-hook-form'
import { useNonZeroPrecisionRound } from 'src/hooks/useRound'

import { ConcentrationSetting } from './data'
import {
  CRIOnFormData,
  ChangedFieldName,
  EquationTypeChain,
  InputsWithChain,
  Locks,
} from './types'
import {
  calculateResult,
  generateCalculatorParams,
  getNewLocksByEquationType,
} from './utils/criCalculatorFns'
import { useRefDebounce } from './utils/getDebounce'

const CalculatorDebounceTime = 0.4 * 1000 // 0.4 second

export const useCRIOnForm = (methods: UseFormReturn<CRIOnFormData>) => {
  const round = useNonZeroPrecisionRound()

  const debounce = useRefDebounce()

  const [locks, setLocks] = useState<Locks>({})

  const { getValues, setValue, trigger } = methods

  const clearCalculator = useCallback(() => {
    setValue('doseRate', null)
    setValue('dosageRate', null)
    setValue('infusionRateTotal', null)
    setValue('dilutedConcentration', null)
    setValue('medicationVolume', null)
    setValue('ivBagSize', null)
    setLocks({})
    trigger()
  }, [setValue, trigger])

  const getLock = useCallback((key: ChangedFieldName) => locks[key], [locks])

  // keep the user entered fields unlooked throughout the full calculation with all other values calculated and locked.
  const addLocks = useCallback(
    (changedFieldName: ChangedFieldName, newLocks: Locks = {}) => {
      setLocks(persistentLocks => ({
        ...newLocks,
        ...persistentLocks,
        [changedFieldName]: persistentLocks[changedFieldName] ?? false,
      }))
      trigger()
    },
    [trigger],
  )

  // user delete the value to null, reset this field the lock
  const removeLock = useCallback(
    (changedFieldName: ChangedFieldName) => {
      setLocks(persistentLocks => omit(persistentLocks, changedFieldName))
      trigger()
    },
    [trigger],
  )

  const handleCalculate = useCallback(
    (changedFieldName: ChangedFieldName, chains?: EquationTypeChain[]) => {
      const runCalculator = () => {
        const allInputs = getValues()
        if (allInputs.concentrationSetting === ConcentrationSetting.IGNORE) {
          return
        }

        // modify the chains as params to avoid duplicated recursive call

        const oldChains = chains ?? []
        const calculatorParam = generateCalculatorParams(
          changedFieldName,
          allInputs,
          oldChains,
        )

        if (isArray(calculatorParam)) {
          calculatorParam.forEach(param => {
            calculateResultFieldValue(param)
          })
          return
        }
        calculateResultFieldValue(calculatorParam)
      }
      // when the user quickly type input, do debounce.
      const debounceCalculator = debounce(runCalculator, CalculatorDebounceTime)

      //  when the calculator called by chain(recursion), not do debounce.
      if (!chains) {
        debounceCalculator()
      } else {
        runCalculator()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [round, setValue, addLocks, removeLock, locks, debounce, trigger], // must contain resolveResult and calculateResultFieldValue dependencies
  )

  const calculateResultFieldValue = useCallback(
    (calculatorInputs: InputsWithChain) => {
      const { chains, hasChain, equationTypeChain, changedFieldName } =
        calculatorInputs
      if (hasChain) {
        return
      }

      const result = calculateResult(equationTypeChain, calculatorInputs, locks)
      // clear input
      if (result === false) {
        removeLock(changedFieldName)
        return
      }
      // we can not get result if 2 or more fields are empty in equation
      if (!result) {
        addLocks(changedFieldName)
        return
      }
      // save result and lock related fields
      const { resultField, resultValue } = result
      setValue(resultField, round(resultValue))
      const newLocks = getNewLocksByEquationType(
        equationTypeChain,
        calculatorInputs.isDiluted,
      )
      addLocks(changedFieldName, newLocks)

      // these two fields are in two equations. which should trigger another calculator
      if (
        resultField === 'dilutedConcentration' ||
        resultField === 'dosageRate'
      ) {
        handleCalculate(resultField, chains)
      }
    },
    [locks, handleCalculate, round, setValue, addLocks, removeLock],
  )

  return {
    operations: {
      clearCalculator,
      handleCalculate,
      getLock,
    },
  }
}
