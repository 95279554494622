type NonModifiableProductsType = {
  origin_id: string
  default_name: string
}

const NonModifiableProducts = <NonModifiableProductsType[]>[
  {
    origin_id: 'VR-10056',
    default_name: 'IV Fluids',
  },
]

export { NonModifiableProducts }
