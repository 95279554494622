import React, { useContext, useState, useEffect } from 'react'
import { Dimensions } from 'react-native'
import debounce from 'lodash/debounce'
import { Layout } from '../constants/Layout'

export const getBreakpoint = () => {
  const { width, height } = Dimensions.get('window')
  const isExSmallScreen = width < Layout.sm
  const isSmallScreen = width >= Layout.sm && width < Layout.md
  const isMediumScreen = width >= Layout.md && width < Layout.lg
  const isLargeScreen = width >= Layout.lg
  const isLargeButNotThatLargeScreen = width >= Layout.lg && width < Layout.xl
  const isVeryLargeScreen = width >= Layout.xl
  const isSuperLargeScreen = width >= Layout.xxl
  const isSmallishScreen = isExSmallScreen || isSmallScreen || isMediumScreen
  const isBigScreen = width >= Layout.xLarge

  const isPortrait = height >= width
  const isLandscape = width >= height

  return {
    width,
    height,
    isExSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isLargeButNotThatLargeScreen,
    isVeryLargeScreen,
    isSuperLargeScreen,
    isSmallishScreen,
    isBigScreen,
    isLandscape,
    isPortrait,
  }
}

export type GetBreakpoint = typeof getBreakpoint

const BreakpointContext = React.createContext(getBreakpoint())

type BreakpointProviderProps = {
  children: React.ReactElement
}

export const BreakpointProvider: React.FC<BreakpointProviderProps> = props => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint())

  // let subscription
  useEffect(() => {
    const onResize = debounce(() => setBreakpoint(getBreakpoint()), 200)
    const resizeSubscription = Dimensions.addEventListener('change', onResize)
    return () => {
      resizeSubscription.remove()
    }
  })

  return (
    <BreakpointContext.Provider value={breakpoint}>
      {props.children}
    </BreakpointContext.Provider>
  )
}

export const withBreakpointProvider = <P extends object>(
  BasicComponent: React.ComponentType<P>,
) => {
  return (props: P) => (
    <BreakpointProvider>
      <BasicComponent {...props} />
    </BreakpointProvider>
  )
}

export const useBreakpoint = () => useContext(BreakpointContext)
