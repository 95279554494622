import React from 'react'
import Highlighter from 'react-native-highlight-words'
import { Pressable, StyleSheet, Text, View } from 'react-native'

import { Colors } from 'src/constants/Colors'
import { Fonts } from 'constants/Fonts'
import { Modal } from '../Modal'

type HighlightedWords = String[] | RegExp

export type Highlights = {
  content?: HighlightedWords
}

type Props = {
  visible: boolean
  onCancel: () => void
  onOK: () => void
  text: string
  title: string
  highlights?: Highlights
  okText?: string
  cancelText?: string
}

export const Alert: React.FC<Props> = React.memo(
  ({
    visible,
    onCancel,
    onOK,
    text,
    title,
    highlights,
    okText = 'OK',
    cancelText = 'Cancel',
  }) => {
    return (
      <Modal
        visible={visible}
        onCloseModal={onCancel}
        style={styles.backdrop}
        contentStyle={styles.alertContainer}
      >
        <View style={styles.content}>
          <Text style={styles.titleText}>{title}</Text>
          <Text style={styles.text}>
            <Highlighter
              ellipsizeMode="middle"
              highlightStyle={styles.highlightContent}
              searchWords={highlights?.content ?? []}
              autoEscape={true}
              textToHighlight={text}
            />
          </Text>
          <View style={styles.actionLayout}>
            <Pressable
              onPress={onCancel}
              testID="AlertCancel"
              style={styles.button}
            >
              <Text style={[styles.buttonText, { color: Colors.error }]}>
                {cancelText}
              </Text>
            </Pressable>
            <Pressable onPress={onOK} testID="AlertOk" style={styles.button}>
              <Text style={[styles.buttonText, { color: Colors.tag.m }]}>
                {okText}
              </Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    )
  },
)

Alert.displayName = 'Alert'

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    padding: 16,
    gap: 16,
  },
  alertContainer: {
    maxWidth: 600,
    minWidth: 250,
  },
  actionLayout: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleText: {
    color: Colors.darkGrey,
    fontFamily: Fonts.bold,
    fontSize: 18,
  },
  text: {
    color: Colors.darkGrey,
    fontFamily: Fonts.regular,
    fontSize: 17,
  },
  button: {
    padding: 8,
    backgroundColor: Colors.backgroundGrey,
  },
  buttonText: {
    fontSize: 16,
    textTransform: 'uppercase',
    fontFamily: Fonts.bold,
    fontWeight: '500',
  },
  highlightContent: { fontFamily: Fonts.bold },
})
