import { TreatmentSubscriptionHandler } from 'components/Sheet/treatmentSubscription/handlers/types/treatment-handler.type'
import { useCallback } from 'react'
import { treatment_treatment_data } from 'types/treatment'
import { useApolloClient } from '@apollo/client'
import { buildTreatmentWithTasksFragmentVariable } from 'components/Treatment/utils/buildTreatmentWithTasksFragmentVariable'
import { useTimeResolution } from 'src/hocs/timeContext'
import { TreatmentBaseWithTasks } from 'types/TreatmentBaseWithTasks'
import { cloneDeep } from 'lodash'

export const useTreatmentUpdateHandler = (): TreatmentSubscriptionHandler => {
  const client = useApolloClient()
  const { fromToQueryDate } = useTimeResolution()

  const process = useCallback(
    (treatment: treatment_treatment_data) => {
      const fragmentVariable = buildTreatmentWithTasksFragmentVariable(
        treatment.id,
        fromToQueryDate,
      )

      const cachedTreatment =
        client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

      if (!cachedTreatment) return

      const clonedTreatment = cloneDeep(cachedTreatment)

      // TODO: VR-8795 - Consistent Treatment Update subscription behavior
      client.writeFragment({
        ...fragmentVariable,
        data: {
          ...clonedTreatment,
          approval_status: treatment.approval_status,
          approval_updated_by: treatment.approval_updated_by,
          conditional: treatment.conditional,
          product: treatment.product,
          medicine_dosage_info: treatment.medicine_dosage_info,
          name: treatment.name,
        },
      })
    },
    [client, fromToQueryDate],
  )

  return {
    process,
  }
}
