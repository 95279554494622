import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number
  color?: string
}

export const CrossedEyeSvg = ({ size = 17, color = '#31353D' }: Props) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" fill="none">
      <Path
        d="M3.6982 5.21987C3.24321 4.86753 2.5826 4.94917 2.22386 5.39603C1.86511 5.8429 1.94824 6.49171 2.40323 6.84405L28.3028 26.7811C28.7578 27.1334 29.4184 27.0518 29.7771 26.6049C30.1359 26.1581 30.0527 25.5093 29.5977 25.1569L24.9953 21.6164C26.7278 19.8719 27.9003 17.9169 28.4909 16.529C28.6353 16.1895 28.6353 15.8114 28.4909 15.472C27.839 13.938 26.4697 11.7037 24.4222 9.8389C22.366 7.95691 19.5354 6.3757 16.0005 6.3757C13.0168 6.3757 10.5318 7.50575 8.59374 8.98814L3.6982 5.21987ZM11.7612 11.4244C12.8768 10.4233 14.3686 9.81312 16.0005 9.81312C19.4786 9.81312 22.3004 12.5845 22.3004 16.0005C22.3004 17.0704 22.0248 18.0758 21.5391 18.9524L19.8504 17.6547C20.2179 16.8255 20.3142 15.8759 20.0604 14.9349C19.5748 13.1517 17.9692 11.9529 16.1842 11.8799C15.9305 11.8713 15.7817 12.142 15.8605 12.3826C15.9524 12.6576 16.0049 12.9498 16.0049 13.2548C16.0049 13.6931 15.8999 14.1056 15.7161 14.4708L11.7656 11.4287L11.7612 11.4244ZM18.3192 21.7539C17.6017 22.0332 16.8186 22.1879 16.0005 22.1879C12.5224 22.1879 9.7006 19.4164 9.7006 16.0005C9.7006 15.704 9.72247 15.4161 9.76185 15.1325L5.6363 11.94C4.63881 13.2162 3.9257 14.488 3.51008 15.472C3.36571 15.8114 3.36571 16.1895 3.51008 16.529C4.16195 18.0629 5.5313 20.2973 7.57876 22.1621C9.63498 24.0441 12.4656 25.6253 16.0005 25.6253C18.0917 25.6253 19.9335 25.071 21.5216 24.2288L18.3192 21.7539Z"
        fill={color}
      />
    </Svg>
  )
}
