import React from 'react'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { DilutionHeader } from './DilutionHeader'
import { IVInfusionsHeader } from './IVInfusionsHeader'
import { TruncatedText } from './TruncatedText'
import { getIsCRI } from '../utils/getTreatmentInfo'
import { SvgFlag } from 'components/Icons/Flag'

export const rootA11yLabel = 'Completed Medicine Task Info'

type Props = {
  amountGiven?: string | null
  instructions?: string | null
  treatment: TreatmentChildLevel | null
  isFluid?: boolean
  changeFluidRateNotes?: string
}

const InfoHeader: React.FC<Props> = ({
  amountGiven,
  instructions,
  treatment,
  isFluid = false,
  changeFluidRateNotes,
}) => {
  const { t } = useTranslation()
  const { isMediumScreen, isLargeScreen } = useBreakpoint()
  const isCRI = getIsCRI(treatment)
  const isNativeStyle = !isMediumScreen && !isLargeScreen
  const shouldShowIvInfusionHeader = isCRI && isFluid

  return (
    <View
      style={[
        styles.treatmentDetailsContainer,
        {
          padding: isNativeStyle ? 8 : 12,
          width: isNativeStyle ? '100%' : 'auto',
        },
      ]}
    >
      <DilutionHeader medicineDosageInfo={treatment?.medicine_dosage_info} />
      {shouldShowIvInfusionHeader ? (
        <IVInfusionsHeader
          treatment={treatment}
          isNativeStyle={isNativeStyle}
        />
      ) : null}
      {amountGiven && !isCRI ? (
        <View style={styles.treatmentValue}>
          <Text style={styles.subLabelText}>
            {isFluid
              ? t('task:taskAddEdit.infusionRateTotal')
              : t('task:taskAddEdit.givenTotalDosage')}
          </Text>
          <Text
            style={[
              styles.labelText,
              styles.treatmentValueLabel,
              isNativeStyle && styles.labelTextMob,
            ]}
          >
            {amountGiven}
          </Text>
          <Text style={styles.subLabelText}>{changeFluidRateNotes}</Text>
        </View>
      ) : null}
      {!!instructions && (
        <View
          style={[
            styles.instructionStyle,
            {
              padding: isNativeStyle ? 8 : 12,
              width:
                Platform.OS === 'ios'
                  ? isNativeStyle
                    ? '100%'
                    : '30%'
                  : '100%',
            },
          ]}
        >
          {!!treatment?.is_instructions_important && (
            <View style={styles.flag}>
              <SvgFlag size={13} />
            </View>
          )}
          <TruncatedText textToTruncate={instructions} />
        </View>
      )}
    </View>
  )
}

/* This Task Action header shows instead of the treatment instructions when the
   treatment is a medicine and the task has been completed. The component's
   purpose is to show again what amount was given on the task */
export const CompletedMedicineHeader: React.FC<Props> = ({
  amountGiven,
  instructions,
  treatment,
  isFluid = false,
  changeFluidRateNotes,
}) => {
  return (
    <View
      accessibilityLabel={rootA11yLabel}
      style={styles.treatmentNameContainer}
    >
      <InfoHeader
        amountGiven={amountGiven}
        instructions={instructions}
        treatment={treatment}
        isFluid={isFluid}
        changeFluidRateNotes={changeFluidRateNotes}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  labelText: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 28,
  },
  labelTextMob: {
    fontSize: 18,
    lineHeight: 20,
  },
  subLabelText: {
    color: Colors.contentTertiary,
    flexWrap: 'wrap',
    fontSize: 14,
    marginBottom: 'auto',
    textAlign: 'center',
  },
  treatmentNameContainer: {
    alignItems: 'center',
    height: 'auto',
    justifyContent: 'flex-start',
    paddingBottom: 8,
    paddingTop: Platform.OS === 'web' ? 0 : 24,
    width: 'auto',
  },
  treatmentDetailsContainer: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 8,
    marginTop: Platform.OS === 'web' ? 8 : 0,
    minWidth: '60%',
  },
  treatmentValue: {
    padding: 16,
  },
  treatmentValueLabel: {
    paddingVertical: 4,
    textAlign: 'center',
  },
  instructionStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flag: {
    marginRight: 4,
    paddingBottom: 14,
  },
})
