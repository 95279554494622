import React from 'react'
import { Text, StyleSheet } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'

type Props = {
  text: string
}

export const FormLabel: React.FC<Props> = ({ text }) => {
  return <Text style={styles.container}>{text}</Text>
}

const styles = StyleSheet.create({
  container: {
    fontFamily: Fonts.regular,
    lineHeight: 19,
    paddingLeft: 16,
    paddingTop: 20,
    paddingBottom: 8,
    fontSize: 15,
    color: Colors.contentTertiary,
  },
})
