import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { GetSheet, GetSheetVariables } from 'components/Task/types'
import { getFlattenedTreatments } from 'components/Task/utils/getFlattenedTreatments'
import { CubexTransactionStatus, Status } from 'src/types/globalTypes'

import { GET_SHEET } from './graphql'

export const useIsSheetDeletable = (getSheetVariables: GetSheetVariables) => {
  const client = useApolloClient()

  return useCallback(() => {
    const query = GET_SHEET
    const sheet = client.readQuery<GetSheet, GetSheetVariables>({
      query,
      variables: getSheetVariables,
    })

    const treatmentGroups = sheet?.getSheet?.treatments?.items
    if (!treatmentGroups) return true

    const flattenedTreatments = getFlattenedTreatments(treatmentGroups)

    const tasks = flattenedTreatments.flatMap(
      treatment => treatment.tasks?.items ?? [],
    )
    return !tasks?.some(
      task =>
        task?.status === Status.DONE ||
        task?.status === Status.MISSED_ON_PURPOSE ||
        task?.cubex_transaction_status === CubexTransactionStatus.SUCCESS,
    )
  }, [getSheetVariables, client])
}
