import { getTime, startOfDay } from 'date-fns'
import { TimeSegment, TimeSegmentMinutes } from 'src/hocs/types/time'

const oneHourMS = 60 * 1000
const fourHoursMS = 240 * oneHourMS

export const getTimeInGridToNearestSegment = (
  timeSegment: TimeSegment,
  timeInGrid: Date,
) => {
  if (timeSegment === TimeSegment.twentyFourHours) {
    return startOfDay(timeInGrid)
  }

  const segmentMS = TimeSegmentMinutes[timeSegment] * oneHourMS
  let rounded = Math.floor(getTime(timeInGrid) / segmentMS) * segmentMS

  // TODO: fix: Eight hours is weird, our calculation falls 4 hrs back
  if (timeSegment === TimeSegment.eightHours) {
    rounded = rounded + fourHoursMS
  }

  return new Date(rounded)
}
