import { format, isValid } from 'date-fns'

const formatMap = {
  daymonth: 'd MMM', // 29 Jan
  weekdaymonth: 'EEEE d, MMM', // Friday 29, Jan
  weekdaymonthyear: 'EEEE d, MMMM yyyy', // Friday 29, January 2021
  timeweekdaymonthyear: 'p EEEE d, MMMM yyyy', // 10:55 AM Friday 29, January 2021
  datetime: 'MMM do yyyy p',
  date: 'MMM do yyyy',
  time: 'p',
  monthdaytime: 'MMM do p',
}

/**
 * @deprecated doesn't respect organisation formatting preferences - see useAdminTimeFormat instead
 */
export const dateFormatter = (
  date: Date,
  mode: keyof typeof formatMap = 'datetime',
) => {
  if (isValid(date)) {
    return format(date, formatMap[mode])
  }
  return ''
}
