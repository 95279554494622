import React, { useCallback, useEffect, useState } from 'react'
import {
  Animated,
  Dimensions,
  DimensionValue,
  GestureResponderEvent,
  Modal as NativeModal,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { DialogHeights } from './common'
import { dialogStyles } from './DialogCommon'

const getDimensions = () => {
  const { height, width } = Dimensions.get('window')
  return {
    deviceHeight: height,
    deviceWidth: width,
  }
}

export type Props = {
  children?: React.ReactNode
  onClose: () => void
  visible: boolean
  dialogHeight?: DimensionValue
}

export const RawDialog: React.FC<Props> = ({
  children,
  onClose,
  visible,
  dialogHeight,
}) => {
  const { deviceHeight, deviceWidth } = getDimensions()
  const insets = useSafeAreaInsets()

  const [isDialogOpen, setDialogStatus] = useState<boolean | undefined>()
  const [showContent, setShowContent] = useState<boolean | undefined>()

  useEffect(() => {
    let timeoutID: NodeJS.Timeout | number | undefined
    if (visible) {
      setDialogStatus(true)
      timeoutID = setTimeout(() => {
        setShowContent(true)
      }, 200)
    }
    return () => {
      if (timeoutID) clearTimeout(timeoutID)
    }
  }, [visible])

  const onCloseDialog = useCallback(() => {
    setDialogStatus(false) // This kicks off the closing animation
    setTimeout(() => {
      onClose()
    }, 225)
  }, [onClose])

  const [fadeAnim] = useState(new Animated.Value(0))
  useEffect(() => {
    isDialogOpen ? fadeAnim.setValue(0) : fadeAnim.setValue(1)
    Animated.timing(fadeAnim, {
      toValue: isDialogOpen ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen])

  const [slideAnim] = useState(new Animated.Value(0))
  useEffect(() => {
    slideAnim.setValue(0)
    Animated.timing(slideAnim, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
    }).start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen])

  return (
    <NativeModal
      // @ts-ignore - update type to show supports testID
      testID="Native Dialog Modal"
      visible={visible}
      transparent={true}
    >
      <View style={[dialogStyles.container]}>
        <Animated.View
          style={[
            dialogStyles.dialogOverlay,
            {
              height: deviceHeight,
              width: deviceWidth,
              opacity: fadeAnim,
            },
          ]}
        >
          <DialogOverlay onPress={onCloseDialog} />
        </Animated.View>
        <Animated.View
          testID="Dialog Background"
          style={[
            dialogStyles.dialogBackground,
            {
              transform: [
                {
                  translateY: slideAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: isDialogOpen
                      ? [deviceHeight, 0]
                      : [0, deviceHeight],
                  }),
                },
              ],
              marginBottom: -insets.bottom,
              maxHeight: dialogHeight ? dialogHeight : DialogHeights.BIG,
            },
          ]}
        >
          {!!showContent && children}
        </Animated.View>
      </View>
    </NativeModal>
  )
}

const DialogOverlay: React.FC<{
  onPress?: (event: GestureResponderEvent) => void
}> = React.memo(({ onPress }) => {
  return (
    <TouchableWithoutFeedback
      accessibilityLabel="Dialog Background Overlay"
      onPress={onPress}
    >
      <View style={dialogStyles.flex} />
    </TouchableWithoutFeedback>
  )
})

DialogOverlay.displayName = 'DialogOverlay'
