import React from 'react'
import { TextLink } from 'components/common'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Colors } from 'constants/Colors'

type Props = {
  action: () => void
}

export const SaveNotesAndColorButton: React.FC<Props> = ({ action }) => {
  const { t } = useTranslation()
  return (
    <View style={[styles.view]}>
      <TextLink
        a11yLabel={t('task:taskTransition.saveNotes.a11y')}
        text={t('task:taskTransition.saveNotes.title')}
        onPress={action}
        fontSize={14}
        color={Colors.blue}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    paddingBottom: 10,
    paddingHorizontal: 16,
    paddingTop: 20,
  },
})
