import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color: string
}

export const SvgAuthDone: React.FunctionComponent<Props> = ({ color }) => (
  <Svg width={24} height={19}>
    <G fill={color} fillRule="evenodd">
      <Path d="M11.574 11.536c-1.085-.635-1.181-.475-2.26-1.642v-.543a5.815 5.815 0 0 0 1.135-2.016c.036-.11.071-.224.103-.338.262-.133.475-.487.524-.92a1.53 1.53 0 0 0-.008-.414 1.048 1.048 0 0 0-.227-.527c.022-.411.023-.846.004-1.304a17.301 17.301 0 0 0-.084-1.112 3.938 3.938 0 0 0-.04-.277C10.332.222 8.283.002 7.506 0h-.6c-.779.002-2.828.222-3.217 2.443a4.503 4.503 0 0 0-.04.277c-.04.387-.068.757-.084 1.112-.019.458-.018.893.004 1.304a1.04 1.04 0 0 0-.227.527 1.53 1.53 0 0 0-.008.413c.049.434.262.789.524.92.064.231.137.452.221.665a5.735 5.735 0 0 0 1.018 1.69v.544C3.175 11.975 0 12.353 0 14.08v1.884c0 .231.19.42.424.42h7.35c.301-1.671 2.987-2.337 5.177-4.056l-1.377-.792z" />
      <Path d="M18.479 11.963l-.003-.813c1.164.15 3.536-.686 3.536-.686-.804-.812-.987-1.76-1.514-5.476-.527-3.718-3.8-3.683-4.047-3.683-.246 0-3.52-.035-4.047 3.683-.528 3.717-.71 4.664-1.514 5.476 0 0 2.373.836 3.537.686l-.003.813c-2.07 2.24-5.503 2.65-5.503 4.51v2.03c0 .25.205.452.457.452h14.148a.454.454 0 0 0 .457-.452v-2.03c0-1.862-3.434-2.27-5.505-4.51z" />
    </G>
  </Svg>
)
