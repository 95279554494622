/**
 * @deprecated Use design-system/theme/typography if possible
 * Separate Bold/Italic fonts are legacy, use fontWeight/fontStyle instead
 */
export const Fonts = {
  semibold: 'nunitosans-semibold',
  regular: 'nunitosans-regular',
  bold: 'nunitosans-bold',
  extrabold: 'nunitosans-extrabold',
  italic: 'nunitosans-italic',
  textSecurityDisc: 'text-security-disc',
  // TODO: We might want to add things like font sizes here for consistency
}
