import React, { useCallback, useState } from 'react'
import { TextArea } from 'components/common'
import { GET_PATIENT } from 'components/Patient/graphql'
import { PatientInfo } from 'components/Patient/PatientInfo'
import { Colors } from 'constants/Colors'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Fonts } from 'src/constants/Fonts'
import { useOrganisation } from 'src/context/organisation'
import {
  getPatient as GetPatient,
  getPatientVariables as GetPatientVariables,
} from 'src/types/getPatient'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export type Props = {
  showTitle?: boolean
  onDischarge?: (patientName: string, dischargeNotes: string) => void
  patientId: string
}

export const DischargePatient: React.FC<Props> = ({
  showTitle = true,
  onDischarge,
  patientId,
}) => {
  const { t } = useTranslation()
  const [dischargeNotes, setDischargeNotes] = useState('')
  const [{ organisationId }] = useOrganisation()

  const { data } = useQuery<GetPatient, GetPatientVariables>(GET_PATIENT, {
    variables: { organisationId, id: patientId },
  })

  const patient = data?.getPatient ?? null
  const handleDischarge = useCallback(() => {
    if (onDischarge && patient?.name) onDischarge(patient.name, dischargeNotes)
  }, [onDischarge, patient?.name, dischargeNotes])

  if (!patient) return null

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {showTitle ? (
          <View style={[styles.dischargeSection, styles.titleSection]}>
            <Text style={styles.headerText}>Discharge patient</Text>
          </View>
        ) : null}
        <View style={[styles.dischargeSection, styles.infoSection]}>
          <PatientInfo color={Colors.contentPrimary} patient={patient} />
        </View>
        <View style={[styles.dischargeSection, styles.noteSection]}>
          <TextArea
            label={t('discharge:notes')}
            labelStyle={styles.labelStyle}
            onChangeText={setDischargeNotes}
            value={dischargeNotes}
            visibleLines={5}
          />
        </View>
        <View style={styles.actionsContainer}>
          <TouchableOpacity
            accessibilityLabel={'Discharge Patient Button'}
            onPress={handleDischarge}
            style={styles.actionButton}
          >
            <Text style={styles.actionButtonText}>{t('discharge:title')}</Text>
          </TouchableOpacity>
        </View>
      </KeyboardAwareScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  actionButton: {
    backgroundColor: Colors.blue,
    borderRadius: 4,
    justifyContent: 'center',
    minWidth: Platform.OS === 'web' ? 48 : '70%',
    paddingHorizontal: Platform.OS === 'web' ? 48 : 0,
    paddingVertical: 8,
  },
  actionButtonText: {
    fontFamily: Fonts.bold,
    textAlign: 'center',
    color: Colors.white,
    fontSize: 18,
  },
  actionsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 24,
  },
  container: {
    paddingBottom: Platform.OS === 'web' ? 0 : 24,
  },
  dischargeSection: {
    borderBottomColor: Colors.lightGrey,
    borderBottomWidth: 1,
  },
  headerText: {
    alignSelf: 'center',
    fontSize: 20,
    fontFamily: Fonts.bold,
    maxWidth: '60%',
    textAlign: 'center',
  },
  infoSection: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  labelStyle: {
    paddingBottom: 0,
    paddingTop: 10,
  },
  noteSection: {
    flex: 1,
  },
  titleSection: {
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    paddingBottom: 24,
    paddingTop: Platform.OS === 'web' ? 0 : 24,
  },
})
