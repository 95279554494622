import React from 'react'
import { useRoute } from '@react-navigation/native'
import { Dialog } from 'components/common/Dialog/Dialog'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { TemplateFormInput } from 'components/CreateSheet/createSheetData'
import { SheetScreenRouteProp } from 'components/Sheet/Sheet'
import { Colors } from 'constants/Colors'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { environment } from 'src/config'

import { AddTemplateDrawerContent } from './AddTemplateDrawerContent'
import { TemplateBackButton } from './TemplateBackButton'
import { FormDataTemplateWithId } from './templateUtilFns'

export type AddTemplateDrawerProps = {
  isVisible: boolean
  toggleDrawer: () => void
  sheetAttendingDepartment: string | null
  onDone: (data: FormDataTemplateWithId) => void
  currentTemplateIndex?: number | null
  templateFormInputs: TemplateFormInput[]
  isAnesthesia: boolean
}
const { isWeb } = environment

export const AddTemplateDrawer: React.FC<AddTemplateDrawerProps> = ({
  isVisible,
  toggleDrawer,
  sheetAttendingDepartment,
  onDone,
  currentTemplateIndex,
  templateFormInputs,
  isAnesthesia,
}) => {
  const {
    params: { patientId },
  } = useRoute<SheetScreenRouteProp>()
  const { t } = useTranslation()

  if (isNil(currentTemplateIndex)) {
    return null
  }
  const renderAddTemplateDrawerContent = () => (
    <AddTemplateDrawerContent
      patientId={patientId}
      onDone={onDone}
      sheetAttendingDepartment={sheetAttendingDepartment}
      currentTemplateIndex={currentTemplateIndex}
      templateFormInputs={templateFormInputs}
      isAnesthesia={isAnesthesia}
    />
  )
  if (!isWeb) {
    return (
      <Dialog
        onCancel={toggleDrawer}
        showDone={false}
        toggleDialog={toggleDrawer}
        visible={isVisible}
        dialogBackgroundColor={Colors.backgroundGrey}
        hasStickySubmitButton={true}
      >
        <TemplateBackButton />
        {renderAddTemplateDrawerContent()}
      </Dialog>
    )
  }

  return (
    <SheetAwareSideDrawer
      visible={isVisible}
      onClose={toggleDrawer}
      title={t('addTreatment:addTemplate')}
      back={{
        text: t('addTreatment:addTemplate'),
      }}
    >
      {renderAddTemplateDrawerContent()}
    </SheetAwareSideDrawer>
  )
}
