import React, { useMemo } from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { SHEET_DAY_FORMAT } from 'constants/Date'
import { StyleSheet, Text, View } from 'react-native'
import { addDays, format } from 'date-fns'

import { gridNavHeight } from '../GridNavV2'
import { TimeBoxProps } from './GridBackgroundLine'
import { useTimeResolution } from 'src/hocs/timeContext'
import { useDayCountDisplay } from 'src/context/patientStartDate'

type Props = {
  numberOfDays: number
  segmentsPerDay: number
  shouldShowFixedDateHeader?: boolean
  timeBoxWidth: number
  timeStart: Date
}

export const FixedDateLabel = 'Current Date Display'
export const ScrollableDatesLabel = 'Set of Scrollable Dates Display'

// still used, but won't show when we use absolute positioned <FixedCurrentDateDisplay />
export const DateArea: React.FC<Props> = React.memo(
  ({
    numberOfDays,
    segmentsPerDay,
    shouldShowFixedDateHeader,
    timeBoxWidth,
    timeStart,
  }) => {
    const days: Date[] = useMemo(
      () =>
        Array.from(Array(numberOfDays)).map((_, idx) => {
          return addDays(timeStart, idx)
        }),
      [numberOfDays, timeStart],
    )

    return (
      // show empty view when has large scrollable content area. Fixed day title to be
      // absolutely positioned over top in GridFlatList->DateWithDay
      <View
        accessibilityLabel={
          shouldShowFixedDateHeader ? FixedDateLabel : ScrollableDatesLabel
        }
        style={[
          styles.dayBoxContainer,
          {
            height: gridNavHeight,
          },
        ]}
      >
        {!shouldShowFixedDateHeader &&
          days.map((day, index) => (
            <DayBox
              date={day}
              daySegments={segmentsPerDay}
              key={day.toUTCString()}
              timeBoxWidth={timeBoxWidth}
              currentDateIdx={index}
            />
          ))}
      </View>
    )
  },
)

DateArea.displayName = 'DateArea'

export const DayBox = ({
  date,
  timeBoxWidth,
  daySegments,
  currentDateIdx,
}: TimeBoxProps & {
  daySegments: number
  currentDateIdx: number
}) => {
  const dayBoxWidth = timeBoxWidth * daySegments
  const day = format(date, SHEET_DAY_FORMAT)
  const { getVisibleDayByIndex } = useTimeResolution()
  const curVisibleDay = getVisibleDayByIndex(currentDateIdx)
  const dayCountText = useDayCountDisplay(curVisibleDay)
  return (
    <>
      <View>
        <View style={[styles.dayBox, { width: dayBoxWidth }]}>
          <Text selectable={false} style={[styles.dayText, styles.dayTextV2]}>
            {day} {`- ${dayCountText}`}
          </Text>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  dayBoxContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderColor: Colors.treatment.gridBorder,
    flexDirection: 'row',
  },
  dayBox: {
    alignItems: 'center',
    height: gridNavHeight,
    justifyContent: 'center',
  },
  dayText: {
    fontFamily: Fonts.bold,
    fontSize: 14,
  },
  dayTextV2: {
    color: Colors.task.text,
    fontSize: 12,
  },
})
