import React from 'react'
import { HelpModalBody } from 'components/Help/HelpModalBody'
import { HelpModalHeader } from 'components/Help/HelpModalHeader'
import { HelpModalFooter } from 'components/Help/HelpModalFooter'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { HelpModalLayout } from './HelpModalLayout'

type Props = {
  visible: boolean
  feedback: string
  onFeedbackChange: (_: string) => void
  onClose: () => void
  onSendFeedback: () => void
  shouldDisableSendFeedbackButton?: boolean
}

const KEYBOARD_AVOIDING_SCROLL_VIEW_EXTRA_SCROLL_HEIGHT = 130

export const HelpModal: React.FC<Props> = ({
  visible,
  onClose,
  feedback,
  onFeedbackChange,
  onSendFeedback,
  shouldDisableSendFeedbackButton,
}) => {
  return (
    <HelpModalLayout visible={visible} onClose={onClose}>
      <KeyboardAwareScrollView
        scrollEnabled={true}
        enableAutomaticScroll={true}
        viewIsInsideTabBar={true}
        extraScrollHeight={KEYBOARD_AVOIDING_SCROLL_VIEW_EXTRA_SCROLL_HEIGHT}
      >
        <HelpModalHeader onCloseModal={onClose} />
        <HelpModalBody
          onCloseModal={onClose}
          feedback={feedback}
          onFeedbackChange={onFeedbackChange}
        />
        <HelpModalFooter
          onSendFeedback={onSendFeedback}
          onCloseModal={onClose}
          sendFeedbackButtonDisabled={shouldDisableSendFeedbackButton}
        />
      </KeyboardAwareScrollView>
    </HelpModalLayout>
  )
}
