import { getOneProduct_getProduct_medicine_dosage_info as oneProduct } from 'src/types/getOneProduct'
import { getTemplate_getTemplate_treatments_items_treatments_items_treatment_options_products_medicine_dosage_info as medicationDosageInfo } from 'src/types/getTemplate'

export const getSafeDoseRange = (
  medicineDosageInfo?: oneProduct | medicationDosageInfo | null,
) => {
  // no values set is not considered an error, default behaviour persists
  if (!medicineDosageInfo)
    return {
      safeDoseRangeHasError: false,
    }
  const { lowest_dosage, highest_dosage } = medicineDosageInfo

  if (lowest_dosage && highest_dosage && highest_dosage < lowest_dosage)
    return {
      safeDoseRangeHasError: true,
    }

  return {
    lowestDosage: lowest_dosage ?? null,
    highestDosage: highest_dosage ?? null,
    safeDoseRangeHasError: false,
  }
}
