import { useCallback, useMemo, useState } from 'react'
import { useOrganisation } from 'src/context/organisation'
import { Storage } from 'src/utils/Storage'
import { SelectOption } from 'components/common/TreeSelect/index.types'
import { ApprovalStatus } from 'src/types/globalTypes'
import { approvalStatusOptions } from '../data'

export type DocSiteFormat = {
  value: string
  text: string
}

export enum filterType {
  Whiteboard = 'Whiteboard',
  Patient = 'Patient',
}

export const useLocalStorageChangeFilters = (typeName: filterType) => {
  const [{ organisationId }] = useOrganisation()

  const [doctorList, setDoctorList] = useState<DocSiteFormat[]>([])
  const [vetTechList, setVetTechList] = useState<DocSiteFormat[]>([])
  const [departmentList, setDepartmentList] = useState<DocSiteFormat[]>([])
  const [locationList, setLocationList] = useState<SelectOption<string>[]>([])

  const storage = useMemo(() => new Storage(organisationId), [organisationId])
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>(
    () => storage.getItem(`selected${typeName}ListDepartments`) ?? [],
  )
  const [selectedDepartmentName, setSelectedDepartmentName] = useState<string>(
    () => storage.getItem(`selected${typeName}ListDepartmentName`) ?? '',
  )

  const [selectedApprovalStatuses, setSelectedApprovalStatuses] = useState<
    ApprovalStatus[]
  >(storage.getItem(`selected${typeName}ListApprovalStatuses`) ?? [])
  const [selectedApprovalStatusName, setSelectedApprovalStatusName] =
    useState<string>(
      () => storage.getItem(`selected${typeName}ListApprovalStatusName`) ?? '',
    )

  const [selectedAttendingVets, setSelectedAttendingVets] = useState<string[]>(
    storage.getItem(`selected${typeName}ListAttendingVets`) ?? [],
  )
  const [selectedAttendingVetName, setSelectedAttendingVetName] =
    useState<string>(
      storage.getItem(`selected${typeName}ListAttendingVetName`) ?? '',
    )

  const [selectedAttendingVetTechs, setSelectedAttendingVetTechs] = useState<
    string[]
  >(storage.getItem(`selected${typeName}ListAttendingVetTechs`) ?? [])

  const [selectedAttendingVetTechName, setSelectedAttendingVetTechName] =
    useState<string>(
      storage.getItem(`selected${typeName}ListAttendingVetTechName`) ?? '',
    )

  const [selectedLocations, setSelectedLocations] = useState<string[]>(
    () => storage.getItem(`selected${typeName}Locations`) ?? [],
  )
  const [selectedLocationName, setSelectedLocationName] = useState<string>(
    () => storage.getItem(`selected${typeName}ListLocationName`) ?? '',
  )

  const [searchQuery, setSearchQuery] = useState<string>('')

  const handleChangeApprovalStatuses = useCallback(
    (approvalStatuses: ApprovalStatus[]) => {
      if (approvalStatuses.length === 1) {
        const approvalStatus = approvalStatusOptions.find(
          ({ value }) => value === approvalStatuses[0],
        )
        storage.setItem(
          `selected${typeName}ListApprovalStatusName`,
          approvalStatus?.text,
        )
        setSelectedApprovalStatusName(approvalStatus?.text ?? '')
      }
      storage.setItem(
        `selected${typeName}ListApprovalStatuses`,
        approvalStatuses,
      )
      setSelectedApprovalStatuses(approvalStatuses)
    },
    [storage, typeName, setSelectedApprovalStatusName],
  )

  const handleChangeAttendingVet = useCallback(
    (vets: string[]) => {
      if (vets.length === 1) {
        const doc = doctorList.find(
          (doctor: DocSiteFormat) => doctor.value === vets[0],
        )
        storage.setItem(`selected${typeName}ListAttendingVetName`, doc?.text)
        setSelectedAttendingVetName(doc?.text ?? '')
      }
      storage.setItem(`selected${typeName}ListAttendingVets`, vets)
      setSelectedAttendingVets(vets)
    },
    [storage, typeName, doctorList],
  )

  const handleChangeAttendingVetTech = useCallback(
    (vetTechs: string[]) => {
      if (vetTechs.length === 1) {
        const tech = vetTechList.find(
          (tech: DocSiteFormat) => tech.value === vetTechs[0],
        )
        storage.setItem(
          `selected${typeName}ListAttendingVetTechName`,
          tech?.text,
        )
        setSelectedAttendingVetTechName(tech?.text ?? '')
      }
      storage.setItem(`selected${typeName}ListAttendingVetTechs`, vetTechs)
      setSelectedAttendingVetTechs(vetTechs)
    },
    [storage, typeName, vetTechList],
  )

  const handleChangeDepartment = useCallback(
    (departments: string[]) => {
      if (departments.length === 1) {
        const department = departmentList.find(
          (dep: DocSiteFormat) => dep.value === departments[0],
        )
        storage.setItem(
          `selected${typeName}ListDepartmentName`,
          department?.text,
        )
        setSelectedDepartmentName(department?.text ?? '')
      }
      storage.setItem(`selected${typeName}ListDepartments`, departments)
      setSelectedDepartments(departments)
    },
    [storage, typeName, departmentList],
  )

  const handleChangeLocation = useCallback(
    (locations: string[]) => {
      if (locations.length === 1) {
        const singleLocation: SelectOption<string>[] = []
        locationList.forEach((location: SelectOption<string>) => {
          if (location.value === locations[0]) {
            singleLocation.push(location)
            return
          }
          if (!!location.children) {
            location.children.map(child => {
              if (child.value === locations[0]) {
                singleLocation.push(child)
                return
              }
            })
          }
        })
        storage.setItem(
          `selected${typeName}ListLocationName`,
          singleLocation[0]?.text,
        )
        setSelectedLocationName(singleLocation[0]?.text ?? '')
      }
      storage.setItem(`selected${typeName}Locations`, locations)
      setSelectedLocations(locations)
    },
    [storage, typeName, locationList],
  )

  const hasFilters =
    !!selectedDepartments.length ||
    !!selectedLocations.length ||
    !!selectedAttendingVets.length ||
    !!selectedAttendingVetTechs.length ||
    !!selectedApprovalStatuses.length

  const clearFilters = useCallback(() => {
    handleChangeAttendingVet([])
    handleChangeAttendingVetTech([])
    handleChangeLocation([])
    handleChangeDepartment([])
    handleChangeApprovalStatuses([])
  }, [
    handleChangeApprovalStatuses,
    handleChangeAttendingVet,
    handleChangeDepartment,
    handleChangeLocation,
    handleChangeAttendingVetTech,
  ])

  const filterState = useMemo(
    () => ({
      searchQuery,
      approvalStatuses: selectedApprovalStatuses,
      approvalStatusName: selectedApprovalStatusName,
      attendingVets: selectedAttendingVets,
      attendingVetName: selectedAttendingVetName,
      attendingVetTechs: selectedAttendingVetTechs,
      attendingVetTechName: selectedAttendingVetTechName,
      locations: selectedLocations,
      locationName: selectedLocationName,
      sites: selectedDepartments,
      siteName: selectedDepartmentName,
    }),
    [
      searchQuery,
      selectedApprovalStatuses,
      selectedApprovalStatusName,
      selectedAttendingVets,
      selectedAttendingVetName,
      selectedAttendingVetTechs,
      selectedAttendingVetTechName,
      selectedLocations,
      selectedLocationName,
      selectedDepartments,
      selectedDepartmentName,
    ],
  )

  const setFilterState = useMemo(
    () => ({
      approvalStatuses: handleChangeApprovalStatuses,
      attendingVets: handleChangeAttendingVet,
      attendingVetTechs: handleChangeAttendingVetTech,
      locations: handleChangeLocation,
      sites: handleChangeDepartment,
      searchQuery: setSearchQuery,
      doctorList: setDoctorList,
      vetTechList: setVetTechList,
      departmentList: setDepartmentList,
      locationList: setLocationList,
    }),
    [
      handleChangeApprovalStatuses,
      handleChangeAttendingVet,
      handleChangeAttendingVetTech,
      handleChangeLocation,
      handleChangeDepartment,
    ],
  )

  return {
    hasFilters,
    clearFilters,
    filterState,
    setFilterState,
  }
}
