import React, { useEffect } from 'react'
import {
  Button,
  FormBreak,
  FormLabel,
  SwitchInput,
  TextInput,
} from 'components/common'
import { Status } from 'components/common/Form/Status'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { useTranslation } from 'react-i18next'
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import * as Yup from 'yup'
import { Center } from 'src/components/common/Center'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getTemplateWorkflow_getTemplateWorkflow } from 'src/types/getTemplateWorkflow'
import { veterinary_roles } from 'src/types/globalTypes'
import { DepartmentsSection } from 'components/Template/DepartmentsSection'
import { OwnerSection } from 'components/Template/OwnerSection'
import { DraggableList } from 'components/common/DraggableList'
import { filterNull } from 'src/utils/notEmpty'
import { useConfirm } from 'src/context/confirm'

import { SecondaryButton } from 'src/components/common'
import { Input } from 'components/shared/Input'
import { SvgHamburger } from 'components/Icons'
import { Actions } from 'components/common/MultiActions'
import { getOptimisticId } from 'src/utils/optimisticId'
import { getTemplatesByTemplateWorkflow_getTemplatesByTemplateWorkflow as TreatmentTemplates } from 'src/types/getTemplatesByTemplateWorkflow'
import { noop } from 'lodash'
import { templateValidName } from 'constants/Templates'

type WorkflowItemInput = {
  id?: string | null
  name?: string | null
  order?: number | null
  deletedAt?: string
}

const defaultValues = {
  name: '',
  user_id: null as null | string | undefined,
  sites: [] as string[],
  disabled: false as null | undefined | boolean,
  // Need all fields to init useForm. Otherwise it won't save deletedAt. Same as VR-5924
  items: [
    {
      id: getOptimisticId(),
      name: '',
      deletedAt: new Date().toISOString(),
    },
  ] as WorkflowItemInput[],
}

export type Inputs = typeof defaultValues

const validationSchema = Yup.object().shape({
  name: templateValidName,
})

// Read from graphql generated type
type WorkflowItem = {
  id: string
  name: string
  deletedAt?: string
}

type TemplateFormProps = {
  handleSubmit: (data: Inputs) => void
  submitting: boolean
  template?: getTemplateWorkflow_getTemplateWorkflow
  treatmentTemplates?: TreatmentTemplates
}

const getInitialValues = (
  template?: getTemplateWorkflow_getTemplateWorkflow,
): Inputs => {
  if (template?.id) {
    return {
      ...defaultValues,
      name: template?.name!,
      user_id: template?.user?.id,
      sites: filterNull(template?.sites?.map(i => i?.id) ?? []),
      disabled: template?.disabled,
      items: [
        ...defaultValues.items,
        ...(template?.workflow_items?.items ?? []),
      ],
    }
  }
  return defaultValues
}

export const WorkflowTemplateForm: React.FC<TemplateFormProps> = ({
  handleSubmit,
  submitting,
  template,
  treatmentTemplates,
}) => {
  const isNewTemplate = !template
  const { t } = useTranslation()
  const treatmentTemplateList = treatmentTemplates?.items ?? []
  const shouldShowTreatmentTemplate = !!treatmentTemplateList.length
  const initialValues = getInitialValues(template)
  const confirm = useConfirm()
  const {
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { isValid, errors },
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  // Trigger an on-mount name validation, incase name changed outside form (eg. via duplicate)
  useEffect(() => {
    trigger('name')
  }, [trigger])

  const hasError = !isValid
  const workflowItems = watch('items')

  const setItemName = (item: Partial<WorkflowItem>, newName: string) => {
    const index = workflowItems.findIndex(i => i.id === item.id)
    return setValue(`items.${index}`, {
      ...item,
      name: newName,
      order: 0,
    })
  }

  const renderItem = ({ item, drag }: { item: WorkflowItem; drag: any }) => {
    if (item.deletedAt) {
      return null
    }
    const menuItems = [
      {
        name: Actions.DELETE,
        onClick: () => deleteItem(item.id),
      },
    ]
    return (
      <Center key={item.id}>
        <View style={styles.itemContainer} key={item.id}>
          <TouchableOpacity style={styles.dragBtn} onPressIn={drag}>
            <SvgHamburger />
          </TouchableOpacity>
          <Input
            value={item.name}
            onChange={value => setItemName(item, value)}
            menuItems={menuItems}
          />
        </View>
      </Center>
    )
  }

  const onSubmit = async () => {
    const data = getValues()
    if (data.disabled) {
      await confirm({
        title: t('template:toggleWorkflowTemplate'),
        text: t('template:setWorkflowTempInactive'),
        okText: 'Yes',
        cancelText: 'No',
      })
        .then(() => {
          handleSubmit(data)
        })
        .catch(noop)
    } else {
      handleSubmit(data)
    }
  }

  const renderListHeader = () => (
    <Center>
      <FormBreak />
      <Status status={createErrorStatus(errors.name?.message, true)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              testID="Template Form Name Text Input"
              label={t('template:form.name')}
              value={value}
              onChangeText={onChange}
              required={true}
            />
          )}
          name="name"
          rules={{ required: true }}
        />
      </Status>
      <Controller
        control={control}
        defaultValue={defaultValues.user_id}
        render={({ field: { onChange, value } }) => (
          <OwnerSection
            type={veterinary_roles.VET}
            onSelectedUserChange={onChange}
            selectedUser={value}
          />
        )}
        name="user_id"
      />
      <Controller
        control={control}
        defaultValue={defaultValues.sites}
        render={({ field: { onChange, value } }) => (
          <DepartmentsSection
            onSelectedDepartmentChange={newVal => onChange(newVal)}
            selectedDepartment={value ? value : []}
          />
        )}
        name="sites"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <SwitchInput
            label={t('template:form.active')}
            value={!value}
            onChangeValue={active => {
              onChange(!active)
            }}
          />
        )}
        name="disabled"
      />
      <FormBreak />
    </Center>
  )

  /**
   * For a new template we need to also reset the name of an item
   * when it is deleted, otherwise it will be saved on creation
   *
   * @param item
   */
  const resetNameForItemDeletion = (item: WorkflowItemInput) => {
    if (isNewTemplate) return ''
    return item.name
  }

  const addEmptyItem = () => {
    setValue('items', [
      ...(workflowItems as any[]),
      {
        id: getOptimisticId(),
        name: '',
        deletedAt: undefined,
      },
    ])
  }

  const deleteItem = (id: string) => {
    setValue(
      'items',
      workflowItems.map(item => {
        if (item.id === id) {
          return {
            ...item,
            name: resetNameForItemDeletion(item),
            deletedAt: new Date().toISOString(),
          }
        }
        return item
      }),
    )
  }

  const deleteAllItems = () => {
    setValue(
      'items',
      workflowItems.map(item => ({
        ...item,
        name: resetNameForItemDeletion(item),
        deletedAt: new Date().toISOString(),
      })),
    )
  }

  const reorderItems = (newItems: WorkflowItem[]) => {
    const updatedItems = newItems.map(item => {
      const deletedAt = item.deletedAt
      if (!deletedAt) {
        return { ...item, deletedAt: undefined }
      }
      return item
    })
    setValue('items', updatedItems as WorkflowItemInput[])
  }
  const renderListFooter = () => (
    <Center>
      <View style={styles.taskBtns}>
        <SecondaryButton
          title={`+ Task`}
          textStyle={styles.taskBtnTask}
          onPress={addEmptyItem}
        />
        <SecondaryButton
          title={`Delete all tasks`}
          textStyle={styles.taskBtnTxt}
          onPress={deleteAllItems}
        />
      </View>
      <Button
        onPress={onSubmit}
        title={
          isNewTemplate ? 'Create workflow template' : t('general.saveChanges')
        }
        loading={submitting}
        color={Colors.green}
        style={styles.button}
        disabled={hasError}
      />

      {shouldShowTreatmentTemplate ? (
        <FlatList
          data={treatmentTemplateList}
          keyExtractor={item => item.id}
          style={styles.treatmentTemplateList}
          ListHeaderComponent={
            <FormLabel text={t('settings:organisation.treatmentTemplates')} />
          }
          renderItem={({ item }) => {
            return (
              <Center key={item.template_workflow_id}>
                <View style={styles.treatmentItemContainer} key={item.id}>
                  <Text style={styles.treatmentTemplateText}>{item.name}</Text>
                </View>
              </Center>
            )
          }}
        />
      ) : null}
    </Center>
  )

  return (
    <DraggableList
      items={workflowItems as WorkflowItem[]}
      renderItem={renderItem}
      ListHeaderComponent={renderListHeader()}
      ListFooterComponent={renderListFooter()}
      onMoveEnd={newItems => {
        reorderItems(newItems)
      }}
    />
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 5,
  },
  taskBtns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dragBtn: {
    marginRight: 12,
    width: 18,
  },
  taskBtnTxt: {
    color: Colors.buttons.text,
  },
  taskBtnTask: {
    color: Colors.buttons.blue,
  },
  button: {
    marginVertical: 16,
  },
  treatmentTemplateList: {
    paddingBottom: '5%',
  },
  treatmentItemContainer: {
    width: '100%',
    paddingVertical: 10,
    marginBottom: 5,
    backgroundColor: Colors.white,
    borderColor: Colors.lightGrey,
    borderWidth: 1,
    borderRadius: 5,
  },
  treatmentTemplateText: {
    marginHorizontal: 10,
  },
})
