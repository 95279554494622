import React, { useCallback, useMemo, useState } from 'react'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { Status } from 'src/types/globalTypes'
import { isOptimisticId } from 'src/utils/optimisticId'

import { FluidTaskBoxAndText } from './FluidTaskBoxAndText'
import { FluidTaskTransition } from './FluidTaskTransition'
import { ActivityLog } from './TaskActions'
import { CompletedMedicineHeader } from './TaskHeader/CompletedMedicineHeader'
import {
  getShouldShowTaskTreatmentHeader,
  TaskDialogHeader,
} from './TaskHeader/TaskDialogHeader'
import { FluidAction, TaskActionsProps } from './types'
import { getChangeFluidRateNotes } from './utils/getChangeFluidRateNotes'
import { getFluidTaskActions } from './utils/getFluidTaskActions'
import { getNearestTaskBeforePress } from './utils/getNearestTaskBeforePress'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

type FluidActions = {
  value: FluidAction
  text: string
}[]

type ActionItemProps = {
  editTaskAction: (action: FluidAction) => void
  isNativeStyle: boolean
  selectedAction: FluidAction | null
  actions: FluidActions
}

const ActionItems: React.FC<ActionItemProps> = ({
  editTaskAction,
  isNativeStyle,
  selectedAction,
  actions,
}) => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isSmallishInitialScreen = isExSmallScreen || isSmallScreen
  const isNativeScreen = Platform.OS !== 'web'
  return (
    <>
      <View
        style={[
          styles.actionsContainer,
          isNativeScreen && styles.actionsContainerMob,
        ]}
      >
        {actions.map((item, idx) => (
          <View style={styles.actionContainer} key={idx}>
            <TouchableOpacity
              onPress={() => {
                editTaskAction(item.value)
              }}
            >
              <View
                accessibilityLabel={`Task action ${item.value}`}
                style={[
                  styles.actionButton,
                  {
                    borderBottomWidth: selectedAction === item.value ? 2 : 0,
                    borderBottomColor:
                      selectedAction === item.value
                        ? Colors.tag.aggressivePink
                        : 'none',
                  },
                  isNativeScreen && styles.nativeActionButton,
                ]}
              >
                <Text
                  style={[
                    styles.actionButtonText,
                    {
                      color:
                        !selectedAction || selectedAction === item.value
                          ? Colors.contentPrimary
                          : Colors.contentTertiary,
                    },
                    isSmallishInitialScreen && styles.smallActionButtonText,
                  ]}
                >
                  {item.text}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        ))}
      </View>
      {!isNativeStyle && <View style={styles.divider} />}
    </>
  )
}

export const FluidTaskActions: React.FC<TaskActionsProps> = ({
  patientId,
  isFinalized,
  task,
  timeInGrid = new Date(),
  toggleDialog,
  treatment,
  sheetId,
}) => {
  const { isExSmallScreen, isSmallScreen, isLargeScreen } = useBreakpoint()
  const { t } = useTranslation()
  const { adminTimeFormat } = useAdminTimeFormat()
  const { isPortrait } = useBreakpoint()
  const extraLandscapeIpad = 240
  const extraPortraitIos = 250
  const extraLandscapeIphone = 50

  const isNativeStyle =
    Platform.OS !== 'web' || isExSmallScreen || isSmallScreen

  const allTasks = useMemo(
    () =>
      treatment?.tasks?.items?.filter(
        t => t.status !== Status.DELETED_ON_PURPOSE,
      ) ?? [],
    [treatment?.tasks?.items],
  )

  const taskToEdit = useMemo(
    () => task ?? getNearestTaskBeforePress(allTasks, timeInGrid),
    [allTasks, task, timeInGrid],
  )

  const actions = useMemo(
    () => getFluidTaskActions({ t, task: taskToEdit }),
    [t, taskToEdit],
  )

  const editTaskAction = useCallback((newAction: FluidAction) => {
    setAction(newAction)
  }, [])

  const isReadOnly =
    actions.length === 0 ||
    isFinalized ||
    !!treatment?.discontinued_at ||
    isOptimisticId(task?.id)

  const [action, setAction] = useState<FluidAction>(() =>
    isReadOnly ? FluidAction.VIEW : actions[0].value,
  )

  const shouldShowDialogHeader = getShouldShowTaskTreatmentHeader(
    treatment,
    task,
  )
  const isChangeOrSchedule =
    action === FluidAction.CHANGE_FLUID_RATE ||
    action === FluidAction.SCHEDULE_FLUID

  const changeFluidRateNotes = useMemo(
    () =>
      getChangeFluidRateNotes({
        isChangeOrSchedule,
        timeFormat: adminTimeFormat,
        tasks: allTasks,
        task: taskToEdit,
      }),
    [isChangeOrSchedule, allTasks, taskToEdit, adminTimeFormat],
  )

  const calculateExtraHeight = useMemo(() => {
    if (!isPortrait && isLargeScreen) {
      return extraLandscapeIpad
    }
    if (isPortrait) {
      return extraPortraitIos
    }
    return extraLandscapeIphone
  }, [isPortrait, isLargeScreen])

  if (!taskToEdit) {
    return null
  }
  return (
    <KeyboardAwareScrollView
      extraHeight={calculateExtraHeight}
      contentContainerStyle={[styles.container]}
      accessibilityLabel={t('task:taskTransition:a11y')}
    >
      {!!task?.value ? (
        <CompletedMedicineHeader
          amountGiven={task.value}
          instructions={treatment?.instructions}
          changeFluidRateNotes={changeFluidRateNotes}
          isFluid={true}
          treatment={treatment}
        />
      ) : (
        <TaskDialogHeader
          showHeader={shouldShowDialogHeader}
          treatment={treatment}
          patientId={patientId}
        />
      )}
      <View
        style={[
          styles.updateTaskContainer,
          isNativeStyle && styles.smallUpdateTaskContainer,
        ]}
      >
        {!isReadOnly && (
          <View style={styles.editFluids}>
            <ActionItems
              actions={actions}
              editTaskAction={editTaskAction}
              isNativeStyle={isNativeStyle}
              selectedAction={action}
            />
          </View>
        )}
        <FluidTaskBoxAndText
          taskToEdit={taskToEdit}
          allTasks={allTasks}
          sheetId={sheetId}
        />
      </View>
      <FluidTaskTransition
        sheetId={sheetId}
        action={action}
        isFinalized={isFinalized}
        patientId={patientId}
        task={taskToEdit}
        toggleDialog={toggleDialog}
        treatment={treatment}
        timeInGrid={timeInGrid}
        activityLog={<ActivityLog task={taskToEdit} />}
      />
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: '100%',
  },
  divider: {
    width: '50%',
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
    marginHorizontal: 'auto',
    paddingVertical: 12,
  },
  updateTaskContainer: {
    paddingHorizontal: 25,
    alignItems: 'center',
  },
  smallUpdateTaskContainer: {
    paddingHorizontal: 15,
  },
  editFluids: {
    paddingHorizontal: 0,
    width: '100%',
  },
  actionsContainer: {
    maxWidth: '80%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 'auto',
    paddingBottom: 20,
    minWidth: '40%',
  },
  actionsContainerMob: {
    maxWidth: '100%',
    justifyContent: 'center',
  },
  actionContainer: {
    flex: 1,
    padding: 8,
    paddingHorizontal: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionButton: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingTop: 8,
    paddingBottom: 2,
  },
  nativeActionButton: {
    minWidth: 70,
  },
  actionButtonText: {
    fontSize: 18,
    paddingVertical: 2,
    textAlign: 'center',
  },
  smallActionButtonText: {
    fontSize: 14,
  },
})
