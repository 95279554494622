import React, { Fragment, useState, useRef, useEffect } from 'react'
import {
  TextInput,
  TextLink,
  SecondaryButton,
  FormLabel,
} from 'components/common'
import { clone } from 'lodash'
import {
  View,
  StyleSheet,
  TextInput as RNTextInput,
  TextStyle,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { Variables } from 'src/design-system/theme'

type Props = {
  onChange: (e: any) => void
  sites: string[]
  isFinalized?: boolean
  label?: string
  addCatSiteButtonTextStyle?: TextStyle
}
type RefObject = RNTextInput[]

export const CatSiteSection: React.FC<Props> = ({
  onChange,
  sites,
  label,
  addCatSiteButtonTextStyle,
  isFinalized = false,
}) => {
  const { t } = useTranslation()
  const [catSites, setCatSites] = useState(sites)
  const sitesRef = useRef<RefObject>([])
  const handleCatSiteChange = (newVal: string, idx: number) => {
    const newCatSiteList = clone(sites)
    newCatSiteList[idx] = newVal
    setCatSites(newCatSiteList)
  }
  const componentIsMounted = useRef<boolean>(false)

  useEffect(() => {
    if (!componentIsMounted.current) {
      componentIsMounted.current = true
      return
    }
    const idx = catSites.length - 1
    if (sitesRef.current[idx]) sitesRef.current[idx].focus()
  }, [catSites.length])

  useEffect(() => {
    onChange(catSites)
  }, [catSites, onChange])

  const handleRemoveCatSite = (idx: number) => {
    setCatSites(catSites.filter((_, index) => index !== idx))
  }

  const handleAddCatSite = () => {
    setCatSites(sites => [...sites, ''])
  }

  return (
    <View>
      {!!label ? <FormLabel text={label} /> : null}
      {catSites.map((site, idx) => (
        <Fragment key={idx}>
          <TextInput
            disabled={isFinalized}
            label={`${t('sheetForm.catSite')} #${idx + 1}`}
            value={site}
            onChangeText={newVal => handleCatSiteChange(newVal, idx)}
            ref={el => {
              if (el) sitesRef.current[idx] = el
            }}
          />
          {!isFinalized && (
            <TextLink
              text={t('general.remove')}
              onPress={() => handleRemoveCatSite(idx)}
              containerStyles={styles.removeButton}
            />
          )}
        </Fragment>
      ))}
      {!isFinalized && (
        <SecondaryButton
          title={`+ ${t('sheetForm.addCatSite')}`}
          textStyle={addCatSiteButtonTextStyle}
          style={styles.secondaryButton}
          onPress={handleAddCatSite}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  secondaryButton: {
    justifyContent: 'flex-start',
    height: 35,
    paddingHorizontal: Variables.GutterSpacing.md,
    width: 180,
  },
  removeButton: {
    alignItems: 'flex-end',
    paddingRight: Variables.GutterSpacing.md,
  },
})
