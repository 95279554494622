import { EmergencyDrugsButton } from 'components/EmergencyDrugsButton/EmergencyDrugsButton'
import { Colors } from 'constants/Colors'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, View } from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { IconButton } from 'components/IconButton'
import { HeaderButton } from 'components/SubHeader/SubHeader'
import { SvgMeatballs } from 'components/Icons'
import { DateWithDay } from './DateWithDay'
import { useApprovals } from 'src/hooks/useApprovals'
import { Indicator } from 'components/common/Indicator/indicator'
import { SvgClockRotateLeftSolid } from 'components/Icons/ClockRotateLeftSolid'
import { VRBottomSheet } from 'components/BottomSheet'
import { SheetHistoryContainer } from 'components/UserHistory/SheetHistory/SheetHistoryContainer'
import { BottomSheetModal } from '@gorhom/bottom-sheet'

type NavIconLayoutProps = {
  isMobileScreen: boolean
  formatTime: string
  dayCountText: string | null
  actionButton?: HeaderButton | null
  onClickPrev: () => void
  toggleDialog: () => void
  onClickNext: () => void
  needsApproval?: boolean
  isEmptySheet?: boolean
}

const EMERGENCY_ICON_SIZE = 48
const EMERGENCY_ICON_SIZE_MOBILE = 36
export const USER_SHEET_HISTORY_ICON_SIZE = 26

const MobileWrapper: React.FC<{
  children: React.ReactElement
  isMobileScreen: boolean
}> = ({ isMobileScreen, children }) =>
  !isMobileScreen ? (
    children
  ) : (
    <View style={styles.mobileLayout}>{children}</View>
  )

export const NavIconLayout: React.FC<NavIconLayoutProps> = ({
  isMobileScreen,
  actionButton,
  onClickPrev,
  onClickNext,
  formatTime,
  dayCountText,
  toggleDialog,
  isEmptySheet,
  needsApproval,
}) => {
  const size = isMobileScreen ? EMERGENCY_ICON_SIZE_MOBILE : EMERGENCY_ICON_SIZE
  const isIOS = Platform.OS !== 'web'
  const { t } = useTranslation()
  const { isExSmallScreen, isLargeScreen, isSmallishScreen } = useBreakpoint()
  const { showApproverUI } = useApprovals()
  const bottomSheetRef = useRef<BottomSheetModal>(null)
  const [isSheetHistoryOpen, setIsSheetHistoryOpen] = useState(false)
  const showApprovalIndicator = showApproverUI && needsApproval

  const iconButtonContainerStyles = useMemo(
    () => [
      styles.iconButtonContainerStyle,
      showApprovalIndicator
        ? { marginRight: iconContainerMargin + indicatorSize / 2 }
        : {},
    ],
    [showApprovalIndicator],
  )

  const openSheetHistory = useCallback(() => {
    if (isIOS) bottomSheetRef?.current?.present()
    setIsSheetHistoryOpen(true)
  }, [isIOS])

  const dismissSheetHistory = useCallback(() => {
    if (isIOS) bottomSheetRef?.current?.dismiss()
    setIsSheetHistoryOpen(false)
  }, [isIOS])

  return (
    <MobileWrapper isMobileScreen={isMobileScreen}>
      <>
        <DateWithDay
          isEmptySheet={isEmptySheet}
          formatTime={formatTime}
          dayCountText={dayCountText}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          placement="DAY_NAVIGATION"
          toggleDialog={toggleDialog}
        />

        {actionButton ? (
          <View
            style={[
              styles.rightButtonContainer,
              isExSmallScreen && styles.mobileRightButtonContainer,
            ]}
          >
            {isSmallishScreen || isIOS ? (
              <>
                <IconButton
                  action={openSheetHistory}
                  containerStyle={[
                    iconButtonContainerStyles,
                    styles.sheetHistoryButton,
                  ]}
                  iconColor={Colors.buttons.blue}
                  icon={SvgClockRotateLeftSolid}
                  label={t('history:buttonLabel')}
                  iconHeight={USER_SHEET_HISTORY_ICON_SIZE}
                  iconWidth={USER_SHEET_HISTORY_ICON_SIZE}
                />
                {isIOS ? (
                  <VRBottomSheet
                    title={t('history:sheet.title')}
                    ref={bottomSheetRef}
                    handleClose={dismissSheetHistory}
                  >
                    <SheetHistoryContainer
                      visible={isSheetHistoryOpen}
                      onClose={dismissSheetHistory}
                    />
                  </VRBottomSheet>
                ) : (
                  <SheetHistoryContainer
                    visible={isSheetHistoryOpen}
                    onClose={dismissSheetHistory}
                  />
                )}
              </>
            ) : null}
            {!isLargeScreen || isIOS ? (
              <EmergencyDrugsButton size={size} />
            ) : null}
            <IconButton
              testID="ActionButton"
              containerStyle={iconButtonContainerStyles}
              disabled={actionButton.disabled}
              action={actionButton.action}
              label={t(actionButton.label)}
              icon={SvgMeatballs}
              iconColor={Colors.white}
            >
              {showApprovalIndicator ? (
                <Indicator
                  size={indicatorSize}
                  accessibilityLabel={'Pending approvals indicator'}
                />
              ) : null}
            </IconButton>
          </View>
        ) : null}
      </>
    </MobileWrapper>
  )
}

// TODO - Design system should contain these values.
const indicatorSize = 16
const iconContainerMargin = 8

const styles = StyleSheet.create({
  iconButtonContainerStyle: { marginRight: iconContainerMargin },

  mobileLayout: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
  },
  rightButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileRightButtonContainer: {
    paddingBottom: 3,
    transform: [
      {
        translateY: 5,
      },
    ],
  },
  sheetHistoryButton: {
    backgroundColor: 'transparent',
    marginRight: 5,
  },
})
