import React, { useCallback, useMemo } from 'react'
import downChevron from 'assets/images/png/icons/chevron_down/downChevron.png'
import upChevronBlue from 'assets/images/png/icons/chevron_up/upChevronBlue.png'
import { SvgComment } from 'components/Icons'
import { SvgFlag } from 'components/Icons/Flag'
import { SvgVitals } from 'components/Icons/Vitals'
import { getTaskUnit } from 'components/Task/utils/getTaskUnit'
import { DosageInfoType } from 'components/Treatment/TreatmentTree'
import { routesOfAdminAbbreviationOptions } from 'components/TreatmentForm/data'
import { FluidMedication } from 'components/TreatmentForm/utils/getCalculations'
import { useConditionalTreatment } from 'components/TreatmentSetup/utils/useConditionalTreatment'
import { identity, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  Image,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native'
import { useDrawer } from 'src/components/common/Drawer/DrawerContext'
import { getTotalVolumeUnit } from 'src/components/Task/utils/getTotalPrescribedVolume'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { ProductType } from 'src/types/globalTypes'
import { isOptimisticId } from 'src/utils/optimisticId'
import { getPatient_getPatient } from 'types/getPatient'
import { treatmentWidthLarge, treatmentWidthSmall } from '../common'
import { TreatmentSheet, TreatmentStateDisplay } from './common/types'
import {
  getCRIInfusionRate,
  getCRITotalDosage,
  getTotalDosage,
  getCRIDoseRate,
} from './utils/getCRITreatmentInfo'

const foldButtonHitSlop = { top: 15, bottom: 15 }

type Props = {
  dosageInfoType?: DosageInfoType
  displayState?: TreatmentStateDisplay | null
  onPressTreatment: (treatment: TreatmentSheet) => void
  patient?: getPatient_getPatient | null
  round?: (num: number) => number
  treatment: TreatmentSheet
  onPress?: (id: string) => void
  isFolded?: boolean
  hasNestedTreatments?: boolean
}

type FinishSetupLabelProps = {
  patientId?: string
  treatmentId: string
}

const getTotalVolume = (treatment: TreatmentSheet) => {
  const totalVolume = treatment.medicine_dosage_info?.total_volume
  if (isNil(totalVolume)) {
    return null
  }
  const unit = getTotalVolumeUnit(
    totalVolume.toString(),
    treatment.product?.medicine_dosage_info,
    treatment.medicine_dosage_info,
  )
  return `${totalVolume} ${unit}`
}

const getMedicineInfo = (
  treatment: TreatmentSheet,
  dosageInfoType?: DosageInfoType,
) => {
  /**
   * We now check if the medicine has come from CRI as the
   * dosageInfoTypes are calculated differently using different
   * fields
   */
  const isCRI = treatment.medicine_dosage_info?.is_cri
  let medicineInfo: string | null
  switch (dosageInfoType) {
    case DosageInfoType.DOSAGE_RATE:
      medicineInfo = isCRI
        ? getCRIDoseRate(treatment)
        : getDosageRate(treatment)
      break
    case DosageInfoType.TOTAL_VOLUME:
      medicineInfo = isCRI
        ? getCRIInfusionRate(treatment)
        : getTotalVolume(treatment)
      break
    case DosageInfoType.TOTAL_DOSAGE:
      medicineInfo = isCRI
        ? getCRITotalDosage(treatment)
        : getTotalDosage(treatment)
      break
    default:
      medicineInfo = isCRI
        ? getCRIDoseRate(treatment)
        : getDosageRate(treatment)
  }
  if (!medicineInfo) return null

  return `${getAdministration(treatment)}${medicineInfo}`
}

const getAdministration = (treatment: TreatmentSheet) => {
  const administration =
    treatment?.medicine_dosage_info?.route_of_administration
  const displayAdministration =
    routesOfAdminAbbreviationOptions.find(
      option => option.value === administration,
    )?.text ?? administration
  const seperateComma = administration ? ',' : ''
  return `${displayAdministration}${seperateComma}`
}

const getDosageRate = (treatment: TreatmentSheet) => {
  const dosage = treatment?.medicine_dosage_info?.dosage
  const dosageWeightUnit = treatment?.medicine_dosage_info?.dosage_weight_unit
  const dosagePerWeightUnit =
    treatment?.medicine_dosage_info?.dosage_patient_weight_unit
  if (
    !(
      treatment.product?.type === ProductType.IVFLUIDS ||
      treatment.product?.type === ProductType.MEDICATION
    ) ||
    isNil(dosage) ||
    isNil(dosageWeightUnit) ||
    isNil(dosagePerWeightUnit)
  ) {
    return null
  }
  return `${dosage}${dosageWeightUnit}/${dosagePerWeightUnit}`
}

const getFluidTotal = (
  treatment: TreatmentSheet,
  round: (num: number) => number,
) => {
  const total = treatment?.fluid_dosage_info?.total_result
  if (!total) return null

  let totalUnit = treatment?.fluid_dosage_info?.total_result_unit ?? 'mL'
  if (totalUnit === FluidMedication.L) {
    totalUnit = 'L'
  } else {
    totalUnit = 'mL'
  }

  const roundedTotal = round(total)

  return `${roundedTotal}${totalUnit}/hr`
}

const FinishSetupLabel: React.FC<FinishSetupLabelProps> = ({
  patientId = '',
  treatmentId,
}) => {
  const { t } = useTranslation()
  const { loading, isSingleOption } = useConditionalTreatment({
    patientId,
    treatmentId,
  })

  if (loading) {
    return null
  }

  return (
    <View>
      <Text style={styles.finishButtonText}>
        {isSingleOption
          ? t('sheets.setup.finish')
          : t('sheets.setup.addTreatments')}
      </Text>
    </View>
  )
}

const useNameTextMaxWidth = (isVital: boolean, isInstructions: boolean) => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  if (!isExSmallScreen && !isSmallScreen) {
    return isInstructions ? treatmentWidthLarge - 60 : treatmentWidthLarge - 40
  }
  if (isVital) {
    return treatmentWidthSmall / 2
  }
  return treatmentWidthSmall - 30
}

export const TreatmentLeaf: React.FC<Props> = React.memo(
  ({
    displayState,
    onPress,
    isFolded,
    dosageInfoType,
    onPressTreatment,
    patient,
    round = identity,
    treatment,
    hasNestedTreatments = false,
  }) => {
    const { open } = useDrawer()

    const source = isFolded ? upChevronBlue : downChevron

    const handlePressFoldTitle = useCallback(() => {
      onPress?.(treatment.id)
    }, [onPress, treatment])

    const hasConditionalFields = !!treatment.conditional
    const isDiscontinued = !!treatment.discontinued_at
    const isNeedFinishSetup = hasConditionalFields && !isDiscontinued

    const nameStyle = useMemo(() => {
      if (
        displayState === TreatmentStateDisplay.DiscontinuedDisplay ||
        displayState === TreatmentStateDisplay.FinalisedDisplay
      ) {
        return { color: Colors.contentTertiary }
      }
      if (displayState === TreatmentStateDisplay.PendingApproval) {
        return { color: Colors.approval.primary, fontFamily: Fonts.bold }
      }
      return { color: Colors.contentPrimary }
    }, [displayState])

    const isVital = treatment.product?.track_vital ?? false
    const isOptimistic = treatment._optimistic || isOptimisticId(treatment.id)

    const medicineInfo = getMedicineInfo(treatment, dosageInfoType)
    const fluidTotal = getFluidTotal(treatment, round)

    const viewVitals = useCallback(() => {
      const product = treatment.product
      if (!product) return
      const { id: productId, name: productName } = product

      const vitalUnit = getTaskUnit({
        product,
        dosageInfo: treatment.medicine_dosage_info,
        patientWeightUnit: patient?.weight_unit,
      })

      open({ productId, productName, unit: vitalUnit })
    }, [open, treatment, patient])

    const handlePressTreatment = useCallback(() => {
      onPressTreatment(treatment)
    }, [treatment, onPressTreatment])

    return (
      <TouchableOpacity
        accessibilityLabel={`Press to edit - ${treatment.name}`}
        disabled={isOptimistic}
        onPress={handlePressTreatment}
        style={styles.container}
      >
        <TreatmentNameInfo
          fluidTotal={fluidTotal}
          isInstructionsImportant={!!treatment.is_instructions_important}
          isNeedFinishSetup={isNeedFinishSetup}
          isOptimistic={isOptimistic}
          isVital={isVital}
          medicineInfo={medicineInfo}
          patientId={patient?.id}
          treatmentId={treatment.id}
          treatmentName={treatment.name}
          viewVitals={viewVitals}
          hasNestedTreatments={hasNestedTreatments}
          styleOverrides={nameStyle}
        />
        {treatment.is_billable && (
          <View style={styles.billedIndicatorStyle}>
            <SvgComment height={13} width={15} color={Colors.task.billed} />
          </View>
        )}
        {hasNestedTreatments && (
          <TouchableOpacity
            hitSlop={foldButtonHitSlop}
            onPress={handlePressFoldTitle}
            style={styles.buttonContainer}
          >
            <Image source={source} style={styles.chevronStyle} />
          </TouchableOpacity>
        )}
      </TouchableOpacity>
    )
  },
)

TreatmentLeaf.displayName = 'TreatmentLeaf'

type TreatmentNameInfoProps = {
  fluidTotal: string | null
  isOptimistic: boolean
  isVital: boolean
  medicineInfo: string | null
  treatmentName: string
  viewVitals: () => void
  patientId?: string
  treatmentId: string
  isNeedFinishSetup: boolean
  isInstructionsImportant: boolean
  hasNestedTreatments?: boolean
  styleOverrides: StyleProp<TextStyle>
}
const svgVitalsWidth = 40
const smallSvgVitalsWidth = 26

const TreatmentNameInfo = React.memo(
  ({
    fluidTotal,
    isInstructionsImportant,
    isNeedFinishSetup,
    isOptimistic,
    isVital,
    medicineInfo,
    patientId,
    treatmentId,
    treatmentName,
    viewVitals,
    hasNestedTreatments,
    styleOverrides,
  }: TreatmentNameInfoProps) => {
    const { isExSmallScreen, isSmallScreen } = useBreakpoint()
    const isMobileScreen = isExSmallScreen || isSmallScreen
    const hasNestedTreatmentsWidth = hasNestedTreatments ? 50 : 0
    const maxWidth =
      useNameTextMaxWidth(isVital, isInstructionsImportant) -
      hasNestedTreatmentsWidth
    return (
      <>
        <View
          testID={`TreatmentLeaf - ${treatmentName}`}
          style={styles.textContainer}
        >
          <View style={styles.nameContainer}>
            {isOptimistic && <ActivityIndicator size="small" />}
            {isInstructionsImportant && (
              <View style={styles.flag}>
                <SvgFlag size={13} />
              </View>
            )}
            <Text
              ellipsizeMode={'tail'}
              numberOfLines={2}
              style={[styles.nameText, { maxWidth }, styleOverrides]}
            >
              {treatmentName}
            </Text>
          </View>
          {isNeedFinishSetup && (
            <FinishSetupLabel patientId={patientId} treatmentId={treatmentId} />
          )}
          {medicineInfo && (
            <Text style={styles.dosageText}>{medicineInfo}</Text>
          )}
          {fluidTotal && <Text style={styles.dosageText}>{fluidTotal}</Text>}
        </View>
        {isVital && (
          <TouchableOpacity
            onPress={viewVitals}
            style={styles.openVitalsButton}
          >
            <SvgVitals
              width={isMobileScreen ? smallSvgVitalsWidth : svgVitalsWidth}
            />
          </TouchableOpacity>
        )}
      </>
    )
  },
)

TreatmentNameInfo.displayName = 'TreatmentNameInfo'

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  openVitalsButton: {
    paddingRight: 6,
  },
  textContainer: {
    height: '100%',
    paddingTop: 10,
    paddingBottom: 6,
    paddingRight: 8,
    marginLeft: 8,
  },
  nameContainer: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  nameText: {
    flexWrap: 'wrap',
    fontFamily: Fonts.regular,
    fontSize: 13,
    fontWeight: 'normal',
    lineHeight: 15,
  },
  dosageText: {
    fontSize: 11,
    color: Colors.darkGrey,
  },
  finishButtonText: {
    color: Colors.blue,
    fontFamily: Fonts.regular,
    fontSize: 13,
  },
  flag: {
    marginRight: 4,
  },
  billedIndicatorStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  buttonContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    maxWidth: 40,
  },
  chevronStyle: {
    height: 24,
    width: 24,
    marginLeft: -5,
    paddingLeft: 5,
  },
})
