import { useQuery } from '@apollo/client'
import { GET_DEPARTMENTS } from 'components/Whiteboard/graphql'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import {
  getDepartments,
  getDepartmentsVariables,
} from 'src/types/getDepartments'

export const useGetAttendingDepartmentName = (
  organisationId: string,
  attendingDepartment?: string | null,
) => {
  const { data: departmentsData } = useQuery<
    getDepartments,
    getDepartmentsVariables
  >(GET_DEPARTMENTS, {
    variables: {
      organisationId,
    },
    skip: isNil(attendingDepartment),
  })

  const departmentName = useMemo(() => {
    const departmentItem = departmentsData?.getSites?.items?.find(
      item => item.id === attendingDepartment,
    )
    return departmentItem?.name ?? ''
  }, [departmentsData?.getSites?.items, attendingDepartment])

  return departmentName
}
