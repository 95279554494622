import React, { useCallback } from 'react'
import { Dialog } from 'components/common/Dialog/Dialog'
import {
  DialogStates,
  HeightValue,
} from 'components/common/Dialog/Dialog.types'
import { DischargePatient } from 'components/DischargePatient/DischargePatient'
import { Task } from 'components/Task/types'
import { Platform, StyleSheet } from 'react-native'

import { AllNotes } from 'components/Sheet/AllNotes'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'src/hocs/breakpoint'

type DialogPickerProps = {
  dialogHeight?: HeightValue
  dialogOpen?: DialogStates
  handlePendingDischargePatient: (name: string, dischargeNotes: string) => void
  hideDialog: () => void
  patientId?: string | null
  taskToEdit: Task | null
  sheetId: string
  toggleEditTreatmentDrawer: () => void
}
export const SheetDialogPicker: React.FC<DialogPickerProps> = ({
  dialogHeight,
  dialogOpen,
  handlePendingDischargePatient,
  hideDialog,
  patientId,
  sheetId,
}) => {
  const { t } = useTranslation()
  const { isExSmallScreen } = useBreakpoint()
  const renderDialog = () => {
    switch (dialogOpen) {
      case DialogStates.Discharge:
        return (
          !!patientId && (
            <DischargePatient
              patientId={patientId}
              onDischarge={handlePendingDischargePatient}
            />
          )
        )
      case DialogStates.AllNotes:
        return <AllNotes sheetId={sheetId} />
      default:
        return null
    }
  }

  const getTitle = useCallback(() => {
    if (dialogOpen === DialogStates.AllNotes) {
      return t('treatment:comments.title')
    }
    return ' '
  }, [dialogOpen, t])

  return (
    <Dialog
      contentStyle={styles.dialogContentStyle}
      dialogHeight={
        Platform.OS === 'web' && !isExSmallScreen ? dialogHeight : undefined
      }
      onCancel={hideDialog}
      onSave={hideDialog}
      showDone={false}
      toggleDialog={hideDialog}
      title={getTitle()}
      visible={dialogOpen !== DialogStates.None}
    >
      {renderDialog()}
    </Dialog>
  )
}

const styles = StyleSheet.create({
  dialogContentStyle: { height: '100%' },
})
