import { ApolloClient } from '@apollo/client'

import { typeDefs } from './resolvers'
import { link } from './link'
import { cache } from './cache'

export const client = new ApolloClient({
  link,
  cache,
  typeDefs,
  // Should be used along side with freezeResults. Ref: https://blog.apollographql.com/whats-new-in-apollo-client-2-6-b3acf28ecad1
  assumeImmutableResults: true,
  connectToDevTools: true,
})
