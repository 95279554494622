import { View, StyleSheet } from 'react-native'
import React, { useEffect, useMemo } from 'react'
import { useIdleTimer, IdleTimerProps } from './utils/useIdleTimer'
import { useAppState } from 'src/hooks/useAppState'

const ACTIVE_EVENTS = ['mousemove', 'wheel', 'keypress', 'click']

export const IdleTimer: React.FC<IdleTimerProps> = ({
  timeout = 0,
  onIdle,
  isIdle,
  children,
}) => {
  const idleTimer = useIdleTimer({ timeout, onIdle, isIdle })
  const { isActive } = useAppState()

  const idleTimerGroupListener = useMemo(
    () => ({
      mount: () => {
        idleTimer.mount()
        ACTIVE_EVENTS.forEach(type => {
          window.addEventListener(type, idleTimer.mount, true)
        })
      },
      unmount: () => {
        idleTimer.unmount()
        ACTIVE_EVENTS.forEach(type => {
          window.removeEventListener(type, idleTimer.mount, true)
        })
      },
    }),
    [idleTimer],
  )

  // 1. setIdleAt when web app is unloading
  // 2. made web app idle when idleAt expires
  useEffect(() => {
    if (!isActive) {
      return idleTimer.setIdleAt(timeout)
    }

    if (idleTimer.shallBeIdle()) {
      onIdle()
    }
    window.onbeforeunload = () => idleTimer.setIdleAt(timeout)
  }, [timeout, onIdle, idleTimer, isActive])

  // Start the timer when web app is loaded
  useEffect(() => {
    idleTimerGroupListener.mount()
    return () => idleTimerGroupListener.unmount()
  }, [idleTimerGroupListener])

  return <View style={styles.container}>{children}</View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
