import {
  NavigationHelpers,
  NavigationProp,
  ParamListBase,
} from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { BottomTabNavigationEventMap } from '@react-navigation/bottom-tabs/lib/typescript/src/types'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/Avatar'
import { SoftLogout } from 'components/SoftLogout'
import { environment } from 'src/config'
import { useDeviceLock } from 'src/context/deviceLock/deviceLock'
import { useUser } from 'src/context/user'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useSignOut } from 'src/hooks/useSignOut'
import { useActionSheetHandler } from 'src/utils/useActionSheetHandler'
import { useHelp } from 'src/context/help/help'
import { reloadApp } from 'src/utils/reloadApp'
import {
  Auth,
  AUTH_STATE,
  AUTH_WEIGHT,
  authTypeService,
  useAuth,
  userOrganisationService,
  userSessionService,
} from 'src/context/auth'
import { PasswordAction } from 'src/context/auth/utils/type'

export type AccountButtonProps = {
  navigation:
    | NavigationHelpers<ParamListBase, BottomTabNavigationEventMap>
    | NavigationProp<ParamListBase>
}

const { isWeb } = environment

export const AccountButton: React.FC<AccountButtonProps> = React.memo(
  ({ navigation }) => {
    const { t } = useTranslation()
    const { username, user } = useUser()
    const { isLargeScreen } = useBreakpoint()
    const { signOut } = useSignOut()
    const { onSelectUnlockedUser, lock } = useDeviceLock()
    const { toggleAuthState } = useAuth()

    const [showAccountDropdown, setShowAccountDropdown] = useState(false)
    const hideDropdownMenu = useCallback(
      () => setShowAccountDropdown(false),
      [],
    )

    const showVerifyPinDialog = useCallback(() => {
      onSelectUnlockedUser(user?.email ?? '')
      lock()
      setShowAccountDropdown(false)
    }, [lock, onSelectUnlockedUser, user?.email])

    const switchToSelectOrganisation = useCallback(async () => {
      userOrganisationService.deleteOrganisationId()
      await Auth.signOut()
      reloadApp()
    }, [])

    const switchToChangePassword = useCallback(() => {
      const expiresAt = new Date(user?.password_expired_at ?? new Date())
      const isPasswordExpired = new Date() > expiresAt
      userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
      const newAuthState = toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, false)
      userSessionService.setPwdActionInfo({
        email: userSessionService.getUserSession()?.userAttributes?.email,
        action: PasswordAction.CHANGE_PASSWORD_IN_SESSION,
        isPasswordExpired,
      })
      navigation.navigate(newAuthState.toLowerCase())
    }, [navigation, toggleAuthState, user?.password_expired_at])

    const { showActionSheetWithOptions } = useActionSheet()
    const { toggleHelpModal } = useHelp()

    const isAuthV2 = authTypeService.getIsAuthV2()
    const hasTwoOrgs = userSessionService.getUserOrgIds().length > 1

    const softLogoutOptions = useMemo(
      () => [
        {
          action: showVerifyPinDialog,
          name: t('account:general.lock'),
        },
        ...(isAuthV2 && hasTwoOrgs
          ? [
              {
                action: switchToSelectOrganisation,
                name: t('account:general.switchOrganisations'),
              },
            ]
          : []),
        ...(isAuthV2
          ? [
              {
                action: switchToChangePassword,
                name: t('account:general.changePassword'),
              },
            ]
          : []),
        {
          action: toggleHelpModal,
          name: t('account:general.help'),
        },
        {
          action: signOut,
          name: t('account:general.signOut'),
          destructive: true,
        },
      ],
      [
        showVerifyPinDialog,
        t,
        toggleHelpModal,
        signOut,
        switchToChangePassword,
        switchToSelectOrganisation,
        isAuthV2,
        hasTwoOrgs,
      ],
    )

    const softLogoutOptionsHandler = useActionSheetHandler({
      showActionSheetWithOptions,
      buttons: softLogoutOptions,
      cancelName: t('general.cancel'),
    })
    const isLargeScreenOrIOS = !isWeb || isLargeScreen

    const toggleSoftLogout = useCallback(() => {
      // using portal
      if (isLargeScreenOrIOS) {
        setShowAccountDropdown(menuState => !menuState)
        return
      }
      // using actions menu
      softLogoutOptionsHandler()
    }, [isLargeScreenOrIOS, softLogoutOptionsHandler, setShowAccountDropdown])

    return (
      <TouchableOpacity
        accessibilityLabel={'account'}
        testID={'IconButton-account'}
        onPress={toggleSoftLogout}
        style={[
          styles.avatarButton,
          // add extra marginRight when in large web header
          isWeb && isLargeScreen && styles.largeScreenMarginRight,
        ]}
      >
        <Avatar name={username} size={40} radius={20} />
        {isLargeScreenOrIOS && showAccountDropdown ? (
          <SoftLogout
            username={username}
            signOut={signOut}
            hideDropdownMenu={hideDropdownMenu}
            showVerifyPinDialog={showVerifyPinDialog}
          />
        ) : null}
      </TouchableOpacity>
    )
  },
)

AccountButton.displayName = 'AccountButton'

const styles = StyleSheet.create({
  avatarButton: {
    alignItems: 'center',
    marginRight: isWeb ? 30 : 0,
  },
  largeScreenMarginRight: {
    marginRight: 30,
  },
})
