import { FrequencyInput } from 'components/FrequencySelector/FrequencySelector'
import { isDate, startOfMinute } from 'date-fns'
import { ScheduleInput } from 'types/globalTypes'

import { RepeatScheduleValue } from '../data'
import { getRepeatUntil } from './getRepeatUntil'

type GetScheduleInputDeprecated = {
  isRepeating: RepeatScheduleValue
  repeating: number
  repeatUntilDate: number | 'discharge' | Date
  startAtDate: 'now' | 'edit' | 'discharge' | Date
  timeWindow: number
  enableStaffedHour: boolean | null
}

const buildCustomFrequencyInput = (
  frequencyInput: FrequencyInput,
): {
  treatment_frequency_id?: string | null
  frequencies?: number[] | null
  frequency?: number | null
} => {
  if (frequencyInput.type === 'INTERVAL') {
    return {
      treatment_frequency_id: null,
      frequencies: null,
      frequency: frequencyInput.frequencies?.[0],
    }
  }

  return {
    treatment_frequency_id: frequencyInput.id,
    frequencies: frequencyInput.frequencies,
    frequency: null,
  }
}

export const getStartDate = (
  startAtDate: GetScheduleInputDeprecated['startAtDate'],
) => {
  // return first minute of 'now' if not Date type supplied
  return isDate(startAtDate) ? (startAtDate as Date) : startOfMinute(new Date())
}

export function getScheduleDeprecated({
  isRepeating,
  repeating: minuteInterval,
  repeatUntilDate,
  startAtDate,
  timeWindow,
  enableStaffedHour,
}: GetScheduleInputDeprecated) {
  const selectedDate = getStartDate(startAtDate)
  const repeat = isRepeating === RepeatScheduleValue.REPEATING
  const schedule: ScheduleInput = {
    repeat,
    start_at: selectedDate.toISOString(),
    time_window: timeWindow,
    ...(repeat && {
      frequency: minuteInterval,
      repeat_until: getRepeatUntil(repeatUntilDate),
    }),
    enable_staffed_hour: enableStaffedHour,
  }
  return schedule
}

export type GetScheduleInputNew = {
  frequencyInput: FrequencyInput
  enableStaffedHour: boolean
  repeatUntilDate: number | 'discharge' | Date
  startAtDate: 'now' | 'edit' | 'discharge' | Date
  timeWindow: number
}

export const singleAndNoTaskSchedule = {
  repeat: false,
  type: null,
  treatment_frequency_id: null,
  frequencies: null,
  frequency: null,
}

export function getScheduleOverwriteFromFrequencyInput(
  frequencyInput: FrequencyInput,
) {
  if (frequencyInput.type === 'NO_TASKS') {
    return {
      ...singleAndNoTaskSchedule,
      should_create_tasks: false,
    }
  }

  if (frequencyInput.type === 'SINGLE_TASK') {
    return {
      ...singleAndNoTaskSchedule,
      should_create_tasks: true,
    }
  }

  const customFrequencyInput = buildCustomFrequencyInput(frequencyInput)

  return {
    repeat: true, // Not single/No Task
    type: frequencyInput.type,
    should_create_tasks: true,
    ...customFrequencyInput,
  }
}

export function getSchedule({
  frequencyInput,
  enableStaffedHour,
  repeatUntilDate,
  startAtDate,
  timeWindow,
}: GetScheduleInputNew): ScheduleInput {
  if (frequencyInput.type === 'NO_TASKS') {
    return {
      ...singleAndNoTaskSchedule,
      start_at: null,
      repeat_until: null,
      enable_staffed_hour: enableStaffedHour,
      time_window: timeWindow,
    }
  }

  if (frequencyInput.type === 'SINGLE_TASK') {
    return {
      ...singleAndNoTaskSchedule,
      start_at: getStartDate(startAtDate).toISOString(),
      repeat_until: null,
      enable_staffed_hour: enableStaffedHour,
      time_window: timeWindow,
    }
  }
  /* the frequencies field has backend validation to throw when values over 1439 are included.
   ensure that the value from frequency is not included in the array */
  const customFrequencyInput = buildCustomFrequencyInput(frequencyInput)

  return {
    repeat: true, // Not single/No Task
    type: frequencyInput.type,
    start_at: getStartDate(startAtDate).toISOString(),
    repeat_until: getRepeatUntil(repeatUntilDate),
    time_window: timeWindow,
    enable_staffed_hour: enableStaffedHour,
    ...customFrequencyInput,
  }
}

type GetFluidScheduleInput = {
  startAtDate: GetScheduleInputDeprecated['startAtDate']
}

export function getFluidSchedule({ startAtDate }: GetFluidScheduleInput) {
  const selectedDate = getStartDate(startAtDate)

  const schedule: ScheduleInput = {
    duration: 60 * 60 * 1000, // 1 hour
    repeat: false,
    start_at: selectedDate.toISOString(),
  }
  return schedule
}
