import React from 'react'
import { Colors } from 'constants/constants'
import Svg, { G, Path, Text } from 'react-native-svg'

type Props = {
  dateNumber: number
  color?: string
}

export const SvgCalendarWithDate: React.FC<Props> = ({
  dateNumber,
  color = Colors.contentSecondary,
}) => (
  <Svg width={18} height={19}>
    <G transform="translate(-54.000000, -184.000000)" fill={color}>
      <G transform="translate(0.000000, 103.000000)">
        <G transform="translate(0.000000, -0.000000)">
          <G transform="translate(44.000000, 73.000000)">
            <G transform="translate(8.000000, 7.000000)">
              <G>
                <Path d="M16.0875,3.45222989 L16.0875,1.63963218 L14.7125,1.63963218 L14.7125,3.45222989 L6.96666667,3.45222989 L6.96666667,1.63963218 L5.59166667,1.63963218 L5.59166667,3.45222989 L4.53149425,3.45222989 C3.52550253,3.45222989 2.72795954,4.26789885 2.72795954,5.26482759 L2.71889655,17.9530115 C2.71889655,18.9499402 3.52550253,19.7656092 4.53149425,19.7656092 L17.2196782,19.7656092 C18.2166069,19.7656092 19.0322759,18.9499402 19.0322759,17.9530115 L19.0322759,5.26482759 C19.0322759,4.26789885 18.2166069,3.45222989 17.2196782,3.45222989 L16.0875,3.45222989 Z M4.2006429,18.3333333 L4.2006429,7.98372414 L17.814799,7.98372414 L17.814799,18.3333333 L4.2006429,18.3333333 Z" />
              </G>
            </G>
          </G>
        </G>
      </G>
    </G>
    <Text
      x="9"
      y="15.5"
      textAnchor="middle"
      fontSize="10"
      fontWeight="bold"
      fill={color}
    >
      {dateNumber}
    </Text>
  </Svg>
)
