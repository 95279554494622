import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/user'
import { ApprovalStatus, ApprovalRole } from 'src/types/globalTypes'
import { Button } from 'components/common'
import { Colors } from 'constants/Colors'

export const SaveTreatmentButton: React.FC<{
  disabled: boolean
  loading: boolean
  isEdit?: boolean
  approvalStatus: ApprovalStatus | undefined
  onPress: () => void
  submitTitle?: string
}> = ({
  disabled,
  loading,
  onPress,
  isEdit = false,
  approvalStatus,
  submitTitle,
}) => {
  const { t } = useTranslation()
  const currentUser = useUser()

  const showApproverUI =
    approvalStatus === ApprovalStatus.PENDING &&
    currentUser.approvalRole === ApprovalRole.CAN_APPROVE

  const showNeedsApprovalUI =
    approvalStatus !== ApprovalStatus.PENDING &&
    currentUser.approvalRole === ApprovalRole.NEEDS_APPROVAL

  const title = useMemo(() => {
    if (!isEdit) return submitTitle ?? t('addTreatment:addToSheet')
    if (showApproverUI) return t('general.saveAndApprove')
    if (showNeedsApprovalUI) return t('general.sendForApproval')
    return t('general.saveChanges')
  }, [isEdit, showApproverUI, showNeedsApprovalUI, submitTitle, t])

  const color = useMemo(() => {
    if (showApproverUI || showNeedsApprovalUI) return Colors.approval.primary
    return undefined // Default
  }, [showApproverUI, showNeedsApprovalUI])

  return (
    <Button
      a11yLabel={`${title} Button`}
      disabled={disabled}
      loading={loading}
      onPress={onPress}
      testID="SaveTreatmentButton"
      title={title}
      color={color}
    />
  )
}
