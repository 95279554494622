import React from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import {
  AsaCategory,
  Button,
  FormBreak,
  FormLabel,
  TextArea,
  TextInput,
  toast,
} from 'components/common'
import { ControlledSwitchInput } from 'components/common/SwitchInput/ControlledSwitchInput'
import { commonButtonMinWidth } from 'components/common/Button'
import { CatSiteSection } from 'components/CreateSheet/CatSiteSection'
import { Colors } from 'constants/Colors'
import { compact } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useOrganisation } from 'src/context/organisation'
import {
  AnaesthesiaSheetFields,
  AnaesthesiaSheetFields_type_meta_data_timers as Timer,
} from 'types/AnaesthesiaSheetFields'
import {
  updateSheet as UpdateSheet,
  updateSheetVariables as UpdateSheetVariables,
} from 'types/updateSheet'

import { ANAESTHESIA_SHEET_FIELDS, UPDATE_SHEET } from './graphql'
import { EditTimersForm } from './EditTimersForm'
import { Fonts } from 'constants/Fonts'
import { Typography } from 'src/design-system/theme/typography'
import { Colors as ThemeColors } from 'src/design-system/theme/colors'

export type PatientStatusInputs = {
  ownerConsent: boolean
  circuitCheck: boolean
  asaCat: string | null
  catSite: string[]
  complications: Complications[]
  tubeSize?: string
  strTubeSize?: string
  comments: string
  timers: Timer[]
}

enum Complications {
  DIFFICULT_INTUBATION = 'DIFFICULT INTUBATION',
  APNEA_RESP = 'APNEA/RESP. DISTRESS',
  HYPOTHERMIA = 'HYPOTHERMIA',
  HYPOTENSION = 'HYPOTENSION',
  SHOCK = 'SHOCK',
  ARRHYTHMIA = 'ARRHYTHMIA',
  PROLONGED_RECOVERY = 'PROLONGED RECOVERY',
  REGURGITATION = 'REGURGITATION',
}

const complicationsMapper = {
  [Complications.DIFFICULT_INTUBATION]: 'Difficult intubation',
  [Complications.APNEA_RESP]: 'Apnea/resp. distress',
  [Complications.HYPOTHERMIA]: 'Hypothermia',
  [Complications.HYPOTENSION]: 'Hypotension',
  [Complications.SHOCK]: 'Shock',
  [Complications.ARRHYTHMIA]: 'Arrhythmia',
  [Complications.PROLONGED_RECOVERY]: 'Prolonged recovery',
  [Complications.REGURGITATION]: 'Regurgitation',
}

type Props = {
  sheetId: string
  toggleDialog: () => void
  isFinalized?: boolean
}

export const PatientStatus: React.FC<Props> = ({
  sheetId,
  toggleDialog,
  isFinalized = false,
}) => {
  const [{ organisationId }] = useOrganisation()
  const { t } = useTranslation()
  const client = useApolloClient()
  const anaesthesiaSheetFields = client.readFragment<AnaesthesiaSheetFields>({
    fragment: ANAESTHESIA_SHEET_FIELDS,
    fragmentName: 'AnaesthesiaSheetFields',
    id: `Sheet:${sheetId}`,
  })

  const [updateSheet, { loading: submitting }] = useMutation<
    UpdateSheet,
    UpdateSheetVariables
  >(UPDATE_SHEET, {
    fetchPolicy: 'no-cache',
    onError: err => toast.error(err.message),
    onCompleted: () => {
      toast.success(t('sheet:updateStatusSuccess'))
    },
  })

  const onSubmit = async (data: PatientStatusInputs) => {
    await updateSheet({
      variables: {
        input: {
          id: anaesthesiaSheetFields?.id!,
          organisation_id: organisationId,
          consultation_id: anaesthesiaSheetFields?.consultation_id!,
          type_meta_data: {
            ...(data.asaCat && { asa: data.asaCat }),
            ...(anaesthesiaTimer && { timers: data.timers }),
            anaesthesia: {
              catheter_site: compact(data.catSite),
              circuit_check: data.circuitCheck,
              owner_consent: data.ownerConsent,
              comments: data.comments,
              complications: data.complications,
              ...(data.tubeSize && { tube_size: +data.tubeSize }),
              ...(data.strTubeSize && { str_tube_size: data.strTubeSize }),
            },
          },
        },
      },
      update: (_, { data: updateData }) => {
        if (!updateData) return
        const updatedSheet = updateData.updateSheet
        client.writeFragment({
          fragment: ANAESTHESIA_SHEET_FIELDS,
          fragmentName: 'AnaesthesiaSheetFields',
          data: updatedSheet,
          id: `Sheet:${updatedSheet.id}`,
        })
      },
    })
    toggleDialog()
  }

  const anaesthesiaData = anaesthesiaSheetFields?.type_meta_data?.anaesthesia
  const anaesthesiaTimer = anaesthesiaSheetFields?.type_meta_data?.timers
  const selectTubeSize = anaesthesiaData?.tube_size?.toString() ?? ''

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<PatientStatusInputs>({
    mode: 'onChange',
    defaultValues: {
      ownerConsent: anaesthesiaData?.owner_consent ?? false,
      circuitCheck: anaesthesiaData?.circuit_check ?? false,
      asaCat: anaesthesiaSheetFields?.type_meta_data?.asa ?? '',
      catSite: anaesthesiaData?.catheter_site ?? [''],
      complications: (anaesthesiaData?.complications ?? []) as Complications[],
      tubeSize: selectTubeSize,
      strTubeSize: anaesthesiaData?.str_tube_size ?? selectTubeSize,
      comments: anaesthesiaData?.comments ?? '',
      timers: anaesthesiaTimer || [],
    },
  })

  return (
    <>
      <KeyboardAwareScrollView>
        <View style={styles.container}>
          <ControlledSwitchInput
            control={control}
            name="ownerConsent"
            label={t('sheetForm.ownerConsent')}
            disabled={isFinalized}
          />
          <ControlledSwitchInput
            control={control}
            name="circuitCheck"
            label={t('sheetForm.circuit')}
            disabled={isFinalized}
          />

          <FormLabel text={'Timers'} />
          <EditTimersForm control={control} />

          <FormLabel text={t('sheetForm.asaCat')} />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <AsaCategory
                isEdit={!isFinalized}
                asaCat={value}
                onPress={onChange}
              />
            )}
            name="asaCat"
          />
          <FormLabel text={t('sheetForm.complications')} />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <View style={styles.complicationsContainer}>
                {Object.values(Complications).map((complication, idx) => {
                  const isSelected = value?.includes(complication)
                  const onPress = () => {
                    if (!isSelected) {
                      onChange([...value, complication])
                    } else {
                      onChange(
                        value.filter(
                          (comp: Complications) => comp !== complication,
                        ),
                      )
                    }
                  }
                  return (
                    <TouchableOpacity
                      disabled={isFinalized}
                      style={[
                        styles.complicationsPill,
                        isSelected && styles.selectedComplicationsPill,
                      ]}
                      key={idx}
                      onPress={onPress}
                    >
                      <Text
                        style={[
                          styles.complicationsText,
                          isSelected && styles.selectedComplicationText,
                        ]}
                      >
                        {complicationsMapper[complication]}
                      </Text>
                    </TouchableOpacity>
                  )
                })}
              </View>
            )}
            name="complications"
          />
          <FormBreak />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                accessibilityLabel={'ET tube input'}
                label={t('sheet:tube')}
                onChangeText={value => {
                  onChange(value?.slice(0, 8))
                }}
                value={value}
              />
            )}
            name="strTubeSize"
          />
          <FormBreak />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <CatSiteSection
                onChange={onChange}
                sites={value}
                isFinalized={isFinalized}
                addCatSiteButtonTextStyle={styles.addCatSiteButtonTextStyle}
              />
            )}
            name="catSite"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextArea
                label={t('sheetForm.comments')}
                value={value}
                onChangeText={onChange}
                visibleLines={4}
                disabled={isFinalized}
              />
            )}
            name="comments"
          />
          <FormBreak />
        </View>
      </KeyboardAwareScrollView>
      {!isFinalized && (
        <View style={styles.footer}>
          <Button
            a11yLabel={t('general.submit')}
            disabled={!isValid || submitting}
            loading={submitting}
            minWidth={commonButtonMinWidth}
            onPress={handleSubmit(onSubmit)}
            testID="updatePatientStatusButton"
            title={t('general.submit')}
          />
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 24,
  },
  complicationsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 8,
  },
  complicationsPill: {
    height: 30,
    borderRadius: 14,
    backgroundColor: Colors.white,
    marginHorizontal: 8,
    marginVertical: 4,
  },
  selectedComplicationsPill: {
    backgroundColor: Colors.blue,
  },
  complicationsText: {
    fontFamily: Fonts.regular,
    paddingHorizontal: 16,
    paddingTop: 5,
    width: 'auto',
    fontSize: 16,
  },
  selectedComplicationText: {
    color: Colors.white,
  },
  footer: {
    paddingVertical: 15,
  },
  addCatSiteButtonTextStyle: {
    fontFamily: Fonts.regular,
    fontSize: Typography.FontSizes.base,
    fontWeight: Typography.FontWeights.bold,
    lineHeight: 16,
    color: ThemeColors.Contents.accent,
  },
})
