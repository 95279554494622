import { PATIENT_CARD_HEIGHT } from 'components/Patient/PatientListCard'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native'
import { useHeaderSwitch } from 'src/context/fullScreenSwitch'
import { useThrottleCallback } from 'src/hooks/useThrottleCallback'

const ON_SCROLL_THROTTLE_TIME = 300

const GET_NEXT_OFFSET_TIME = 20 * 1000 // 10s for scrolling, 10s for stopping

const SCROLL_INTERVAL_TIME = 60

const SMALL_MISTAKE = 5

export const HEADER_ERROR_HEIGHT = 12

export const useAutoScroll = (fetchMoreExhausted: boolean = true) => {
  const { isFullScreen } = useHeaderSwitch()

  const autoScrollIntervalRef = useRef<number | NodeJS.Timeout>()
  const headerHeightRef = useRef(0)
  const isInBottomRef = useRef(false)
  const sectionListHeightRef = useRef(0)
  const currentYOffsetRef = useRef(0)
  const sectionListTotalHeight = useRef(0)
  const scrollSpeed = useRef(0)
  const scrollListRef = useRef<any>(null)

  const [nextScrollY, setNextScrollY] = useState(0)
  const [isLayoutReady, setIsLayoutReady] = useState(false)

  const moveToTop = useCallback(() => {
    if (!scrollListRef.current) return
    scrollListRef.current.scrollToOffset({ offset: 0, animated: false })
    currentYOffsetRef.current = 0
    isInBottomRef.current = false
    setNextScrollY(0)
  }, [])
  const lastScrollY = useRef(0)

  const getNextOffsetOfPatientList = useCallback(() => {
    if (isInBottomRef.current) {
      moveToTop()
    } else {
      let scrollHeight =
        Math.floor(sectionListHeightRef.current / PATIENT_CARD_HEIGHT) *
        PATIENT_CARD_HEIGHT

      const errorDistance =
        (currentYOffsetRef.current - headerHeightRef.current) %
        PATIENT_CARD_HEIGHT

      // auto adjust the next Y
      if (errorDistance) {
        scrollHeight =
          scrollHeight - errorDistance > 0
            ? scrollHeight - errorDistance
            : scrollHeight - errorDistance + PATIENT_CARD_HEIGHT
      }

      // half GET_NEXT_OFFSET_TIME time to scroll and half to stop.
      scrollSpeed.current =
        scrollHeight / (GET_NEXT_OFFSET_TIME / SCROLL_INTERVAL_TIME / 2)

      let newNextScrollY = currentYOffsetRef.current + scrollHeight
      // prevent the user scrolls and stops at the same position
      if (newNextScrollY === lastScrollY.current) {
        newNextScrollY += 1
      }
      setNextScrollY(newNextScrollY)
      lastScrollY.current = newNextScrollY
    }
  }, [moveToTop])

  const movePatientList = useCallback(
    (currentYOffset: number, newOffsetY: number) => {
      //  currentYOffsetRef is updating, so using closure to calculate the offset
      let offset = currentYOffset
      return () => {
        if (!scrollListRef.current || !scrollSpeed.current) {
          return
        }
        offset += scrollSpeed.current
        if (offset > newOffsetY) {
          clearInterval(autoScrollIntervalRef.current)
          offset = newOffsetY
        }
        scrollListRef.current.scrollToOffset({
          offset,
          animated: true,
        })
      }
    },
    [],
  )

  useEffect(() => {
    if (!isFullScreen || !scrollListRef.current) {
      return
    }
    autoScrollIntervalRef.current = setInterval(
      movePatientList(currentYOffsetRef.current, nextScrollY),
      SCROLL_INTERVAL_TIME,
    )
    return () => {
      clearInterval(autoScrollIntervalRef.current)
    }
  }, [isFullScreen, nextScrollY, movePatientList])

  useEffect(() => {
    if (!isFullScreen || !isLayoutReady) {
      return
    }
    const getNextOffsetInterval = setInterval(
      getNextOffsetOfPatientList,
      GET_NEXT_OFFSET_TIME,
    )
    return () => {
      clearInterval(getNextOffsetInterval)
    }
  }, [isFullScreen, getNextOffsetOfPatientList, isLayoutReady])

  const onThrottleScroll = useThrottleCallback(
    (nativeEvent: NativeScrollEvent) => {
      const contentY = nativeEvent.contentOffset.y
      checkUserScroll(contentY)
      sectionListTotalHeight.current = nativeEvent.contentSize.height
      if (
        contentY + nativeEvent.layoutMeasurement.height >=
          nativeEvent.contentSize.height - HEADER_ERROR_HEIGHT &&
        fetchMoreExhausted
      ) {
        isInBottomRef.current = true
      }
    },
    [fetchMoreExhausted, isFullScreen],
    ON_SCROLL_THROTTLE_TIME,
  )

  const onScroll = useCallback(
    (e: NativeSyntheticEvent<NativeScrollEvent>) => {
      e.persist?.()
      if (!e.nativeEvent || !isFullScreen) {
        return
      }
      const { nativeEvent } = e
      const contentY = nativeEvent.contentOffset.y

      currentYOffsetRef.current = contentY

      onThrottleScroll(nativeEvent)
    },
    [isFullScreen, onThrottleScroll],
  )

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    sectionListTotalHeight.current =
      (e.nativeEvent as any).target?.firstChild?.clientHeight ?? 0
    sectionListHeightRef.current = e.nativeEvent.layout.height
    setIsLayoutReady(true)
  }, [])

  const lastYAndTime = useRef({ y: 0, time: 0 })
  /*
    This function will listen to the scroll speed.
    If the speed is not around the setting speed.
    Will stop the auto-scroll.
  */
  const checkUserScroll = useCallback((yOffset: number) => {
    const now = new Date().getTime()
    // calculated scroll Y
    const autoScrolledY =
      (scrollSpeed.current * ON_SCROLL_THROTTLE_TIME) / SCROLL_INTERVAL_TIME

    const realScrolledY = yOffset - lastYAndTime.current.y

    // user scrolled Y
    const absDifferenceScrolledY = Math.abs(
      realScrolledY - autoScrolledY + SMALL_MISTAKE,
    )

    // time * speed = distance, if wait time is too long the value should wrong
    const timeToScroll =
      now - lastYAndTime.current.time - ON_SCROLL_THROTTLE_TIME * 1.4

    if (absDifferenceScrolledY > autoScrolledY && timeToScroll <= 0) {
      clearInterval(autoScrollIntervalRef.current)
    }
    lastYAndTime.current = { y: yOffset, time: now }
  }, [])

  return { scrollListRef, moveToTop, onScroll, onLayout, headerHeightRef }
}
