import React, { RefObject } from 'react'
import {
  NavigationContainerRef,
  NavigationState,
  PartialState,
} from '@react-navigation/native'

export const navigationRef: RefObject<NavigationContainerRef> =
  React.createRef()

// The methods are used for the non-screen component that is wrapped by NavigationContainer
export const navigate = (name: string, params: object | undefined) => {
  navigationRef?.current?.navigate(name, params)
}

export const canGoBack = () => {
  return navigationRef?.current?.canGoBack()
}

export const goBack = () => {
  return navigationRef?.current?.goBack()
}

export const isInScreen = (name: string) => {
  return navigationRef?.current?.getCurrentRoute()?.name === name
}

export const getRootState = () => {
  return navigationRef?.current?.getRootState()
}

export const reset = (
  state: NavigationState | PartialState<NavigationState>,
) => {
  return navigationRef?.current?.reset(state)
}
