import { VitalOptions } from 'constants/VitalOptions'

export const retrieveVitalProductOptions = (
  customValueOptions:
    | {
        text: string
        value: string
      }[]
    | [],
  isHealthStatusProduct: boolean,
  originId: string | null = null,
) =>
  isHealthStatusProduct && originId && originId in VitalOptions
    ? VitalOptions[originId]
    : customValueOptions
