import { useCallback, useEffect, useMemo } from 'react'
import { Storage } from 'src/utils/Storage'
import { makeVar, useReactiveVar } from '@apollo/client'
import { useOrgSettings } from 'src/hooks/useOrgSettings'

const IS_ALERT_ON_KEY = 'IS_ALERT_ON_KEY'
const isAlertOnVar = makeVar<boolean>(false)

export const useIsAlertOn = (sheetId: string) => {
  const storage = useMemo(() => new Storage(sheetId), [sheetId])
  const { settingsMap } = useOrgSettings()
  const isAlertOnInSetting =
    settingsMap.ANAESTHESIA_AUDIBLE_ALERT_DEFAULT?.value === 'on'

  useEffect(() => {
    const isAlertOnInStorage = storage.getItem(IS_ALERT_ON_KEY)
    isAlertOnVar(isAlertOnInStorage ?? isAlertOnInSetting)
  }, [storage, isAlertOnInSetting])

  const isAlertOn = !!useReactiveVar(isAlertOnVar)

  const setIsAlertOn = useCallback(
    (v: boolean) => {
      storage.setItem(IS_ALERT_ON_KEY, v)
      isAlertOnVar(v)
    },
    [storage],
  )

  return [isAlertOn, setIsAlertOn] as const
}
