import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, StyleSheet } from 'react-native'

import { Colors } from 'constants/Colors'

export const ToggleButtonText = ({
  isHideText,
  hideTextMessage,
  viewTextMessage,
}: {
  isHideText: boolean
  hideTextMessage?: string
  viewTextMessage?: string
}) => {
  const { t } = useTranslation()

  const toggleButtonText = useMemo(() => {
    if (isHideText) return t(hideTextMessage ?? 'addTreatment:hideWorkflow')
    return t(viewTextMessage ?? 'addTreatment:viewWorkflow')
  }, [t, isHideText, hideTextMessage, viewTextMessage])

  return <Text style={styles.toggleButtonText}>{toggleButtonText}</Text>
}

const styles = StyleSheet.create({
  toggleButtonText: {
    marginLeft: 5,
    color: Colors.blue,
  },
})
