import { FormLabel } from '@material-ui/core'
import { orientToDegree, SvgArrow } from 'components/Icons'
import { BlueCheckbox } from 'components/Settings/StaffedHours/StaffedHoursScreen'
import { RowButton } from 'components/SubHeader/SubHeader'
import React, { useMemo } from 'react'
import {
  GestureResponderEvent,
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
} from 'react-native'
import { Colors } from 'constants/Colors'
import { SubMenuProps } from './types'
import { Fonts } from 'constants/Fonts'

export const SecondaryLevelMenu: React.FC<SubMenuProps> = ({
  menuOptions,
  state,
  containerStyle,
  actions,
  selected,
}) => {
  const getSvgArrowIcon = (
    orient: keyof typeof orientToDegree,
    color: string,
  ) => <SvgArrow orient={orient} color={color} width={15} height={15} />

  const backIcon = useMemo(
    () => getSvgArrowIcon('left', Colors.buttons.blue),
    [],
  )

  // MaterialUI components cause clickThrough to the view behind that closes the menu, necessary to prevent
  const preventClickPropagation = (e: GestureResponderEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <View style={containerStyle}>
      <View style={styles.secondaryMenuHeader}>
        {state.hasOnlyDepartments ? (
          <View />
        ) : (
          <RowButton
            testID="BackButton"
            title=" Back"
            label="Go back to top level menu"
            onPress={() => {
              actions.setSecondaryMenuParentId(null)
            }}
            icon={backIcon}
            colour={Colors.buttons.blue}
          />
        )}
        <Text style={styles.secondaryMenuHeaderText}>
          {state.secondaryMenuHeaderTitle}
        </Text>
        <View />
      </View>
      <View style={styles.secondaryMenuListContainer}>
        <TouchableOpacity
          style={{ width: '100%' }}
          onPress={e => {
            preventClickPropagation(e)
            actions.handleSelectAll()
          }}
        >
          <View style={styles.secondaryMenuListItem}>
            <FormLabel style={styles.blackText}>
              <BlueCheckbox checked={actions.getIsSelectAllChecked()} />
              {'Select all'}
            </FormLabel>
          </View>
        </TouchableOpacity>

        <View style={styles.separator} />

        <TouchableOpacity onPress={preventClickPropagation}>
          {menuOptions.map(listItem => (
            <View key={listItem.value} style={styles.secondaryMenuListItem}>
              <TouchableOpacity
                onPress={() => {
                  actions.handleSecondaryMenuSelection(listItem)
                }}
              >
                <FormLabel style={styles.blackText}>
                  <BlueCheckbox
                    checked={!!selected && selected.includes(listItem.value)}
                    value={listItem.value}
                  />
                  {listItem.text}
                </FormLabel>
              </TouchableOpacity>
            </View>
          ))}
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  blackText: {
    color: Colors.contentPrimary,
  },
  secondaryMenuHeader: {
    // @ts-ignore
    boxSizing: 'border-box',
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 8,
    height: 36,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  secondaryMenuHeaderText: {
    fontSize: 18,
    fontWeight: '600',
  },
  secondaryMenuListItem: {
    margin: 0,
    boxSizing: 'border-box',
    paddingHorizontal: 6,
    paddingVertical: 16,
    width: '100%',
    height: 36,
    fontSize: 16,
    justifyContent: 'center',
    fontWeight: '400',
    fontFamily: Fonts.regular,
  },
  secondaryMenuListContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    flexDirection: 'column',
    width: '100%',
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.borderGrey,
  },
})
