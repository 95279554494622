import { PermissionAction } from 'types/globalTypes'
import { useUser } from 'src/context/user'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { useMemo } from 'react'

type Permissions = { [permissionAction in PermissionAction]: boolean }

const useRolePermissions = (): Permissions => {
  const { user } = useUser()
  const { permissions } = useOrgSettings()

  return useMemo(
    () =>
      permissions
        .filter(({ role }) => user?.role === role)
        .reduce((acc: Permissions, { name, enabled }) => {
          acc[name] = enabled
          return acc
        }, {} as Permissions),
    [user, permissions],
  )
}

export const usePermission = (
  permissionAction: PermissionAction | null,
): boolean => {
  const rolePermissions = useRolePermissions()

  if (!permissionAction) return true

  return rolePermissions[permissionAction] ?? true
}
