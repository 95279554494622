import React from 'react'

import { Select, SwitchInput, toast } from 'components/common'
import { LinkProps, ListItem } from 'components/common/ListItem'
import { SelectOption } from 'components/common/Select/Select.types'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { Routes } from 'constants/Routes'
import { i18n as i18nT, TFunction } from 'i18next'
import { Dictionary } from 'lodash'
import {
  FetchResult,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  Platform,
  SectionList,
  SectionListProps,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { useUser } from 'src/context/user'
import {
  getSettings_getOrganisation,
  getSettings_getOrganisation_settings,
} from 'types/getSettings'
import { updateSetting, updateSettingVariables } from 'types/updateSetting'
import {
  OrgSettingKeys,
  SettingsDict,
  useOrgSettings,
} from 'src/hooks/useOrgSettings'
import { SettingsStackParamList } from 'components/Settings/screens'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { languageStorage } from 'src/i18n'

import { SET_SETTING } from './graphql'
import { OrganisationTimeout } from 'components/Settings/TimeoutOptions'
import { reloadApp } from 'src/utils/reloadApp'
import { useFlags } from 'react-native-flagsmith/react'
import { IFlagsmithFeature } from 'react-native-flagsmith/types'
import { useTrainingSwitch } from 'components/Training/useTrainingSwitch'
import { HelpTooltip } from 'components/common/Tooltip/HelpTooltip'

type Props = StackScreenProps<SettingsStackParamList> & {
  loadingOverride?: boolean
}
type Navigate = Props['navigation']['navigate']

type SettingsLink = {
  name: string
  disabled?: boolean
  onPress?: LinkProps['onPress']
}

type SettingsValue = {
  a11yLabel: string
  name: string
  onPress: (value: string) => void
  options: SelectOption<string>[]
  value: string
  disabled?: boolean
}

type SettingsToggle = {
  a11yLabel: string
  name: string
  onToggleChange: (value: boolean) => void
  value: boolean
}

type SettingsToggleWithTooltip = {
  a11yLabel: string
  name: string
  onToggleChange: (value: boolean) => void
  value: boolean
  withTooltip: Boolean
  tooltip: string
}

// Settings entries can be either links or selects
type SettingsEntry =
  | SettingsLink
  | SettingsValue
  | SettingsToggle
  | SettingsToggleWithTooltip
type SectionPropsInstance = SectionListProps<SettingsEntry>
type SettingsSection = { title: string; data: SettingsEntry[] }

type SettingsControls = {
  TEMPERATURE_UNIT: SelectOption<string>
  ROUNDING_PRECISION: SelectOption<string>
  ANAESTHESIA_AUDIBLE_ALERT_DEFAULT: SelectOption<string>
  ENABLE_SYNC_WEIGHT: SelectOption<string>
  ENABLE_TRAINING_BANNER: SelectOption<string>
  [OrgSettingKeys.ANAESTHESIA_CHART_MAX_DEFAULT]: SelectOption<string>
  AUTO_LOGOUT_TIMEOUT: SelectOption<string>
  TASK_EDITING_TIMEOUT: SelectOption<string>
}

type SettingsFlags = {
  enable_procedure_ui: IFlagsmithFeature
  training_site: IFlagsmithFeature
  route_of_administration_setting: IFlagsmithFeature
}

type SettingsObjOpts = {
  t: TFunction
  i18n: i18nT
  navigate: Navigate
  values: SettingsControls
  updateAction: (key: string, value: string) => void
  isAdmin: boolean
  isTrainingOrganisation: boolean
  flags: SettingsFlags
}

type OrganisationSettings =
  | 'TEMPERATURE_UNIT'
  | 'ROUNDING_PRECISION'
  | 'ANAESTHESIA_AUDIBLE_ALERT_DEFAULT'
  | OrgSettingKeys.ANAESTHESIA_CHART_MAX_DEFAULT
  | 'AUTO_LOGOUT_TIMEOUT'
  | 'TASK_EDITING_TIMEOUT'
  | 'ENABLE_SYNC_WEIGHT'
  | 'ENABLE_TRAINING_BANNER'

type UserSettings = 'LANGUAGE'
type PossibleSettingsKeys = OrganisationSettings | UserSettings
type OptionsDictEntry = Dictionary<SelectOption<string>>
type OptionsDict = Record<PossibleSettingsKeys, OptionsDictEntry>

const autoLogoutTimePickerOptions = {
  '1': {
    value: OrganisationTimeout.ONE_MIN,
    text: '1 min',
  },
  '2': {
    value: OrganisationTimeout.TWO_MIN,
    text: '2 min',
  },
  '3': {
    value: OrganisationTimeout.THREE_MIN,
    text: '3 min',
  },
  '4': {
    value: OrganisationTimeout.FOUR_MIN,
    text: '4 min',
  },
  '5': {
    value: OrganisationTimeout.FIVE_MIN,
    text: '5 min',
  },
  '6': {
    value: OrganisationTimeout.TEN_MIN,
    text: '10 min',
  },
  '7': {
    value: OrganisationTimeout.FIFTEEN_MIN,
    text: '15 min',
  },
  '8': {
    value: OrganisationTimeout.THIRTY_MIN,
    text: '30 min',
  },
  '9': {
    value: OrganisationTimeout.ONE_HOUR,
    text: '1hr',
  },
  '10': {
    value: OrganisationTimeout.NONE,
    text: 'None',
  },
}

const taskEditingTimePickerOptions = {
  '1': {
    value: OrganisationTimeout.TWO_MIN,
    text: '2 min',
  },
  '2': {
    value: OrganisationTimeout.FIVE_MIN,
    text: '5 min',
  },
  '3': {
    value: OrganisationTimeout.FIFTEEN_MIN,
    text: '15 min',
  },
  '4': {
    value: OrganisationTimeout.ONE_HOUR,
    text: '1hr',
  },
  '5': {
    value: OrganisationTimeout.TWELVE_HOURS,
    text: '12hrs',
  },
  '6': {
    value: OrganisationTimeout.ONE_DAY,
    text: '24hrs',
  },
  '7': {
    value: OrganisationTimeout.NONE,
    text: 'None',
  },
}

export const optionsTextsDict = (t: TFunction): OptionsDict => ({
  LANGUAGE: {
    'en-US': { value: 'en-US', text: t('languages.en-US') },
    cn: { value: 'cn', text: t('languages.cn') },
  },
  TEMPERATURE_UNIT: {
    celsius: {
      value: 'celsius',
      text: t('settings:organisation.temperature.cel'),
    },
    fahrenheit: {
      value: 'fahrenheit',
      text: t('settings:organisation.temperature.fah'),
    },
  },
  ROUNDING_PRECISION: {
    '1': {
      value: '1',
      text: '1dp',
    },
    '2': {
      value: '2',
      text: '2dp',
    },
    '3': {
      value: '3',
      text: '3dp',
    },
  },
  [OrgSettingKeys.ANAESTHESIA_CHART_MAX_DEFAULT]: {
    '200': {
      value: '200',
      text: '0-200',
    },
    '300': {
      value: '300',
      text: '0-300',
    },
    '400': {
      value: '400',
      text: '0-400',
    },
  },
  ANAESTHESIA_AUDIBLE_ALERT_DEFAULT: {
    on: {
      value: 'on',
      text: 'on',
    },
    off: {
      value: 'off',
      text: 'off',
    },
  },
  ENABLE_SYNC_WEIGHT: {
    on: {
      value: 'true',
      text: 'on',
    },
    off: {
      value: 'false',
      text: 'off',
    },
  },
  ENABLE_TRAINING_BANNER: {
    on: {
      value: 'true',
      text: 'on',
    },
    off: {
      value: 'false',
      text: 'off',
    },
  },
  AUTO_LOGOUT_TIMEOUT: autoLogoutTimePickerOptions,
  TASK_EDITING_TIMEOUT: taskEditingTimePickerOptions,
})

type GetSelectArgs = {
  entry: PossibleSettingsKeys
  options: OptionsDict
  settingsMap: SettingsDict
}

const getSelect = ({
  settingsMap,
  options,
  entry,
}: GetSelectArgs): SelectOption<string> => {
  const value = settingsMap[entry as OrgSettingKeys]?.value ?? ''
  const text = options[entry][value]?.text ?? ''
  return { value, text }
}

type UpdateServerArgs = {
  updateMutation: (
    options: MutationFunctionOptions<updateSetting, updateSettingVariables>,
  ) => Promise<void | FetchResult<updateSetting>>

  organisation?: getSettings_getOrganisation | null
  settings?: getSettings_getOrganisation_settings[]
}

const updateServer =
  ({ updateMutation, organisation, settings }: UpdateServerArgs) =>
  (key: string, value: string) => {
    // We brute force update all the settings here until #VR-1053 is fixed. Not
    // elegant but it works and avoids flash of missing settings
    if (organisation && settings) {
      const updatedSettings = settings.map(setting => {
        return {
          value: setting.key === key ? value : setting.value,
          key: setting.key,
        }
      })
      updateMutation({
        variables: {
          input: {
            id: organisation.id,
            name: organisation.name,
            settings: [...updatedSettings],
          },
        },
        optimisticResponse: {
          updateOrganisation: {
            __typename: 'Organisation',
            id: organisation.id,
            settings: updatedSettings.map(setting => ({
              ...setting,
              __typename: 'KeyValue',
            })),
          },
        },
      })
    }
  }

const settingsSectionsBuilder = ({
  i18n,
  isAdmin,
  isTrainingOrganisation,
  flags,
  navigate,
  t,
  updateAction,
  values,
}: SettingsObjOpts): SettingsSection[] => {
  const settingsSection = [
    {
      title: t('settings:general.sectionTitle'),
      data: [
        {
          a11yLabel: t('settings:general.language.a11yLabel'),
          name: t('settings:general.language.title'),
          onPress: (value: string) => {
            i18n.changeLanguage(value)
            languageStorage.set(value)
          },
          options: Object.values(optionsTextsDict(t).LANGUAGE),
          value: (
            optionsTextsDict(t).LANGUAGE[i18n.language] ||
            optionsTextsDict(t).LANGUAGE['en-US']
          ).value,
        },
      ],
    },
    {
      title: t('settings:support.sectionTitle'),
      data: [
        {
          name: t('settings:support.buildInfo.title'),
          onPress: () => navigate(Routes.Manifest),
        },
        {
          name: t('settings:support.refresh.title'),
          a11yLabel: t('settings:support.refresh.a11yLabel'),
          onPress: reloadApp,
        },
      ],
    },
  ]

  // When in training organisation mode, we only show the general and support sections
  if (isTrainingOrganisation) {
    return settingsSection
  }

  const anaesthesiaChartMaxDefaultSetting = {
    name: t('settings:organisation.anaesthesiaChart.title'),
    a11yLabel: t('settings:organisation.anaesthesiaChart.a11yLabel'),
    onPress: (value: string) => {
      updateAction(OrgSettingKeys.ANAESTHESIA_CHART_MAX_DEFAULT, value)
    },
    options: Object.values(optionsTextsDict(t).ANAESTHESIA_CHART_MAX_DEFAULT),
    value: values.ANAESTHESIA_CHART_MAX_DEFAULT.value,
  }

  const anaesthesiaAudibleAlertDefaultSetting = {
    name: t('settings:organisation.anaesthesiaAlert.title'),
    a11yLabel: t('settings:organisation.anaesthesiaAlert.a11yLabel'),
    onToggleChange: (value: boolean) => {
      updateAction('ANAESTHESIA_AUDIBLE_ALERT_DEFAULT', value ? 'on' : 'off')
    },
    value: values.ANAESTHESIA_AUDIBLE_ALERT_DEFAULT.value === 'on',
  }

  const syncPatientWeightSetting = {
    name: t('settings:organisation.syncPatientWeight.title'),
    a11yLabel: t('settings:organisation.syncPatientWeight.a11yLabel'),
    onToggleChange: (value: boolean) => {
      updateAction('ENABLE_SYNC_WEIGHT', value ? 'true' : 'false')
    },
    value: values.ENABLE_SYNC_WEIGHT.value === 'true',
    withTooltip: true,
    tooltip: t('settings:organisation.syncPatientWeight.tooltip'),
  }

  const trainingBannerSetting = {
    name: t('settings:organisation.enableTrainingBanner.title'),
    a11yLabel: t('settings:organisation.enableTrainingBanner.a11yLabel'),
    onToggleChange: (value: boolean) => {
      updateAction('ENABLE_TRAINING_BANNER', value ? 'true' : 'false')
    },
    value: values.ENABLE_TRAINING_BANNER.value === 'true',
  }

  const manageUsers = {
    title: t('settings:users.title'),
    data: [
      {
        name: t('settings:users.title'),
        onPress: () => navigate(Routes.UserList),
      },
    ],
  }

  // We only show Admin features at top if admin && web user
  const shouldShowAdminFeatures = isAdmin && Platform.OS === 'web'
  if (shouldShowAdminFeatures) {
    const manageCustomProducts = {
      name: t('settings:products.title'),
      onPress: () => navigate(Routes.CustomProductList),
    }
    const manageColors = {
      name: t('settings:colors.manageColorsTitle'),
      onPress: () => navigate(Routes.ColorsConfig),
    }
    const manageSheetGroupOrder = {
      name: t('settings:sheetGroup.manageOrderTitle'),
      onPress: () => navigate(Routes.SheetGroupOrderConfig),
    }
    const manageTreatmentTemplates = {
      name: t('settings:organisation.treatmentTemplates'),
      onPress: () => navigate(Routes.TreatmentTemplateList),
    }
    const manageProcedures = {
      name: t('settings:organisation.procedures'),
      onPress: () => navigate(Routes.ProceduresList),
    }
    const manageWorkflowTemplates = {
      name: t('settings:organisation.workflowTemplates'),
      onPress: () => navigate(Routes.WorkflowTemplateList),
    }
    const manageCallParameterTemplates = {
      name: t('settings:organisation.callParameterTemplates'),
      onPress: () => navigate(Routes.CallParameterTemplateList),
    }

    const manageStaffedHours = {
      name: t('settings:staffedHours.title'),
      onPress: () => navigate(Routes.StaffedHoursConfig),
    }

    const manageRouteOfAdministration = {
      name: t('settings:routeOfAdministration.title'),
      onPress: () => navigate(Routes.RouteOfAdministrationConfig),
    }

    const manageTreatmentFrequencies = {
      name: t('settings:treatmentFrequencies.title'),
      onPress: () => navigate(Routes.TreatmentFrequenciesConfig),
    }

    const dataSync = {
      name: t('settings:dataSync.title'),
      onPress: () => navigate(Routes.DataSync),
    }

    const manageSetAutoPINLock = {
      name: t('settings:organisation.PINLock.title'),
      a11yLabel: t('settings:organisation.PINLock.a11yLabel'),
      onPress: (value: string) => {
        updateAction('AUTO_LOGOUT_TIMEOUT', value)
      },
      options: Object.values(optionsTextsDict(t).AUTO_LOGOUT_TIMEOUT),
      value:
        values.AUTO_LOGOUT_TIMEOUT.value ??
        optionsTextsDict(t).AUTO_LOGOUT_TIMEOUT[10].value,
    }

    const manageSetTaskEditingTimeout = {
      name: t('settings:organisation.taskEditingTimeout.title'),
      a11yLabel: t('settings:organisation.taskEditingTimeout.a11yLabel'),
      onPress: (value: string) => {
        updateAction('TASK_EDITING_TIMEOUT', value)
      },
      options: Object.values(optionsTextsDict(t).TASK_EDITING_TIMEOUT),
      value:
        values.TASK_EDITING_TIMEOUT.value ||
        optionsTextsDict(t).TASK_EDITING_TIMEOUT[7].value,
    }

    const organisationSection = {
      title: t('settings:organisation.sectionTitle'),
      data: [
        {
          name: t('settings:organisation.temperature.title'),
          a11yLabel: t('settings:organisation.temperature.a11yLabel'),
          onPress: (value: string) => {
            updateAction('TEMPERATURE_UNIT', value)
          },
          options: Object.values(optionsTextsDict(t).TEMPERATURE_UNIT),
          value: values.TEMPERATURE_UNIT.value,
        },
        {
          name: t('settings:organisation.rounding.title'),
          a11yLabel: t('settings:organisation.rounding.a11yLabel'),
          onPress: (value: string) => {
            updateAction('ROUNDING_PRECISION', value)
          },
          options: Object.values(optionsTextsDict(t).ROUNDING_PRECISION),
          value: values.ROUNDING_PRECISION.value,
        },
      ],
    }

    settingsSection.unshift(organisationSection)

    if (flags.route_of_administration_setting.enabled) {
      settingsSection[0].data.unshift(manageRouteOfAdministration as any)
    }

    settingsSection[0].data.unshift(manageTreatmentFrequencies as any)

    settingsSection[0].data.unshift(manageStaffedHours as any)

    // TODO: Add these options back to the unshift block below when feature flag is removed
    settingsSection[0].data.unshift(manageWorkflowTemplates as any)
    if (flags.enable_procedure_ui.enabled) {
      settingsSection[0].data.unshift(manageProcedures as any)
    }

    settingsSection[0].data.unshift(
      // @ts-ignore
      manageCallParameterTemplates,
      manageColors,
      manageSheetGroupOrder,
      manageCustomProducts,
      manageTreatmentTemplates,
    )

    settingsSection[0].data.push(anaesthesiaChartMaxDefaultSetting as any)

    settingsSection[0].data.push(anaesthesiaAudibleAlertDefaultSetting as any)

    settingsSection[0].data.push(syncPatientWeightSetting as any)

    if (flags.training_site.enabled) {
      settingsSection[0].data.push(trainingBannerSetting as any)
    }

    settingsSection[0].data.push(manageSetAutoPINLock as any)

    settingsSection[0].data.push(manageSetTaskEditingTimeout as any)

    settingsSection[0].data.push(dataSync as any)

    settingsSection.unshift(manageUsers)
  }

  return settingsSection
}

const sectionHeader: SectionPropsInstance['renderSectionHeader'] = ({
  section: { title },
}) => (
  <View style={styles.sectionTitleContainer}>
    <Text style={styles.sectionTitle}>{title}</Text>
  </View>
)

const renderItem: SectionPropsInstance['renderItem'] = ({ item }) => {
  if ('options' in item) {
    return (
      <Select
        dialog={false}
        a11yLabel={item.a11yLabel}
        onChange={item.onPress}
        label={item.name}
        selected={item.value}
        options={item.options}
        disabled={item.disabled}
      />
    )
  }

  if ('withTooltip' in item) {
    return (
      <SwitchInput
        label={item.name}
        value={item.value}
        tooltip={<HelpTooltip text={item.tooltip} />}
        onChangeValue={item.onToggleChange}
      />
    )
  }

  if ('onToggleChange' in item) {
    return (
      <SwitchInput
        label={item.name}
        value={item.value}
        onChangeValue={item.onToggleChange}
      />
    )
  }

  return (
    <ListItem
      disabled={item.disabled}
      id={item.name}
      name={item.name}
      onPress={item.onPress}
    />
  )
}

const settingsListKeyExtractor = (item: SettingsEntry) => item.name

type UpdateSettingVars = updateSettingVariables
export const Settings = ({ loadingOverride, navigation }: Props) => {
  const { t, i18n } = useTranslation()
  const {
    loading,
    organisation,
    settings,
    settingsMap,
    name: orgName,
  } = useOrgSettings()

  const [updateMutation] = useMutation<updateSetting, UpdateSettingVars>(
    SET_SETTING,
    {
      onError: e => {
        console.error(e) // eslint-disable-line no-console
        toast.error(t('settings:update.err'))
      },
    },
  )

  const { navigate } = navigation

  const values = {
    TEMPERATURE_UNIT: getSelect({
      settingsMap,
      entry: 'TEMPERATURE_UNIT',
      options: optionsTextsDict(t),
    }),
    ROUNDING_PRECISION: getSelect({
      settingsMap,
      entry: 'ROUNDING_PRECISION',
      options: optionsTextsDict(t),
    }),
    ANAESTHESIA_AUDIBLE_ALERT_DEFAULT: getSelect({
      settingsMap,
      entry: 'ANAESTHESIA_AUDIBLE_ALERT_DEFAULT',
      options: optionsTextsDict(t),
    }),
    ENABLE_SYNC_WEIGHT: getSelect({
      settingsMap,
      entry: 'ENABLE_SYNC_WEIGHT',
      options: optionsTextsDict(t),
    }),
    ENABLE_TRAINING_BANNER: getSelect({
      settingsMap,
      entry: 'ENABLE_TRAINING_BANNER',
      options: optionsTextsDict(t),
    }),
    [OrgSettingKeys.ANAESTHESIA_CHART_MAX_DEFAULT]: getSelect({
      settingsMap,
      entry: OrgSettingKeys.ANAESTHESIA_CHART_MAX_DEFAULT,
      options: optionsTextsDict(t),
    }),
    AUTO_LOGOUT_TIMEOUT: getSelect({
      settingsMap,
      entry: 'AUTO_LOGOUT_TIMEOUT',
      options: optionsTextsDict(t),
    }),
    TASK_EDITING_TIMEOUT: getSelect({
      settingsMap,
      entry: 'TASK_EDITING_TIMEOUT',
      options: optionsTextsDict(t),
    }),
  }

  const updateAction = updateServer({
    organisation,
    updateMutation,
    settings,
  })

  const { isAdmin } = useUser()
  const { isTrainingOrganisation } = useTrainingSwitch()

  const flags = useFlags([
    FeatureFlagNames.ENABLE_PROCEDURE_UI,
    FeatureFlagNames.TRAINING_SITE,
    FeatureFlagNames.ROUTE_OF_ADMINISTRATION_SETTING,
  ])

  const settingsSections = settingsSectionsBuilder({
    i18n,
    isAdmin,
    isTrainingOrganisation,
    flags,
    navigate,
    t,
    updateAction,
    values,
  })

  return (
    <>
      <SubHeader headlineKey="title.settings" subHeadline={orgName} />
      {loading || loadingOverride ? (
        <ActivityIndicator
          accessibilityLabel="Settings loading indicator"
          size="large"
          style={styles.activityMargin}
        />
      ) : (
        <SectionList
          accessibilityLabel="List of settings"
          keyExtractor={settingsListKeyExtractor}
          renderItem={renderItem}
          renderSectionHeader={sectionHeader}
          sections={settingsSections}
          style={styles.container}
          testID="SettingsList"
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  activityMargin: {
    marginTop: 25,
  },
  container: {
    flex: 1,
  },
  sectionTitle: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 15,
  },
  sectionTitleContainer: {
    backgroundColor: Colors.backgroundGrey,
    height: 50,
    justifyContent: 'flex-end',
    paddingBottom: 10,
    paddingLeft: 16,
  },
})
