import { SvgArrow, SvgClose } from 'components/Icons'
import { SvgArrowsRotate } from 'components/Icons/ArrowsRotate'
import { SvgGraduationCap } from 'components/Icons/GraduationCap'
import React from 'react'
import { StyleSheet, Text, TouchableOpacity, ViewStyle } from 'react-native'

import { Colors, Typography } from 'src/design-system/theme'

// TODO These should be using design system buttons, but we don't really have them yet.
type ButtonProps = {
  style?: ViewStyle
  onPress?: () => void
  title?: string
  disabled?: boolean
}

export const LeaveTrainingModeButton: React.FC<ButtonProps> = ({
  onPress,
  title,
}) => (
  <TouchableOpacity onPress={onPress} style={buttonStyles.link}>
    <SvgArrow orient={'left'} color={Colors.Contents.accent} height={16} />
    <Text style={buttonStyles.linkText}>{title}</Text>
  </TouchableOpacity>
)

export const ResetTrainingModeButton: React.FC<ButtonProps> = ({
  disabled,
  onPress,
  title,
}) => (
  <TouchableOpacity
    onPress={onPress}
    disabled={disabled}
    style={[disabled ? buttonStyles.disabled : null, buttonStyles.link]}
  >
    <SvgArrowsRotate color={Colors.Contents.accent} size={16} />
    <Text style={buttonStyles.linkText}>{title}</Text>
  </TouchableOpacity>
)

export const CloseButton: React.FC<ButtonProps> = ({ onPress }) => (
  <TouchableOpacity onPress={onPress} style={buttonStyles.subtle}>
    <SvgClose color={Colors.Contents.primary} length={14} />
  </TouchableOpacity>
)

export const TrainingModeButton: React.FC<ButtonProps> = ({
  onPress,
  title,
}) => (
  <TouchableOpacity onPress={onPress} style={buttonStyles.default}>
    <SvgGraduationCap color={Colors.Contents.onColor} />
    <Text style={buttonStyles.defaultText}>{title}</Text>
  </TouchableOpacity>
)

export const ContinueButton: React.FC<ButtonProps> = ({
  onPress,
  title,
  style,
}) => (
  <TouchableOpacity onPress={onPress} style={[buttonStyles.default, style]}>
    <Text style={buttonStyles.defaultText}>{title}</Text>
  </TouchableOpacity>
)

export const CancelButton: React.FC<ButtonProps> = ({ onPress, title }) => (
  <TouchableOpacity onPress={onPress} style={buttonStyles.subtle}>
    <Text style={buttonStyles.subtleText}>{title}</Text>
  </TouchableOpacity>
)

const buttonDefaults = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 11,
    paddingHorizontal: 24,
    borderRadius: 4,
    gap: 10,
  },
  text: {
    fontSize: Typography.FontSizes.md,
    fontWeight: Typography.FontWeights.bold,
  },
})

const buttonStyles = StyleSheet.create({
  default: {
    ...buttonDefaults.container,
    backgroundColor: Colors.Backgrounds.accentPrimary,
  },
  subtle: {
    ...buttonDefaults.container,
  },
  link: {
    ...buttonDefaults.container,
    paddingHorizontal: 0,
  },
  defaultText: {
    ...buttonDefaults.text,
    color: Colors.Contents.onColor,
  },
  subtleText: {
    ...buttonDefaults.text,
    color: Colors.Contents.primary,
  },
  linkText: {
    ...buttonDefaults.text,
    color: Colors.Contents.accent,
  },
  disabled: {
    opacity: 0.5,
  },
})
