import React from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import {
  Dimensions,
  DimensionValue,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { getDoneLineWidth } from './utils/getDoneLineWidth'

type Props = {
  steps: Record<string, string>
  colorDone?: string
  colorPending?: string
  width?: DimensionValue
  selectedStepName?: string
  labelSize?: number
}

export const progressDoneLineTestId = 'progressdoneLine'
export const stepTestId = 'stepTestId'

const { width } = Dimensions.get('window')
const ovalHeightAndWidth = 20
const processLineHeight = 3

export const ProgressBar: React.FC<Props> = ({
  steps,
  colorDone = Colors.blue,
  colorPending = Colors.lightGrey,
  selectedStepName = '',
  width = '100%',
  labelSize = 14,
}) => {
  const stepValues = Object.values(steps)
  const indexOfSelectedStepName = stepValues.indexOf(selectedStepName)
  return (
    <View style={[styles.progressBarContainer, { width }]}>
      <View
        style={[
          styles.progressLine,
          styles.progressPendingLine,
          { backgroundColor: colorPending },
        ]}
      >
        {/* process done line, default blue */}
        {indexOfSelectedStepName >= 0 && (
          <View
            style={[
              styles.progressLine,
              styles.processDoneLine,
              {
                backgroundColor: colorDone,
                width: getDoneLineWidth(
                  stepValues.length,
                  indexOfSelectedStepName,
                ),
              },
            ]}
            testID={progressDoneLineTestId}
          />
        )}

        <View style={styles.stepsContainer}>
          {stepValues.map((step, i) => {
            return (
              <View
                key={step}
                style={[
                  styles.stepContainer,
                  {
                    // As we know the total number of steps beforehand,
                    // then we make each step container equal size,
                    flexBasis: stepValues.length / 100,
                  },
                ]}
              >
                <View
                  style={[
                    styles.oval,
                    {
                      backgroundColor:
                        i >= indexOfSelectedStepName + 1
                          ? colorPending
                          : colorDone,
                    },
                  ]}
                  testID={stepTestId}
                />
                <View style={styles.stepLabelContainer}>
                  <Text
                    style={[styles.stepLabel, { fontSize: labelSize }]}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {step}
                  </Text>
                </View>
              </View>
            )
          })}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  progressBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 80,
    padding: 16,
    position: 'relative',
    marginBottom: 16,
  },

  stepsContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    left: 0,
    // moving upwards the radius of oval, moving downwards half height of the process line.
    // this is to center the steps against process line.
    top: processLineHeight / 2 - ovalHeightAndWidth / 2,
    justifyContent: 'space-evenly',
    width: '100%',
  },

  stepContainer: {
    alignItems: 'center',
  },

  oval: {
    width: ovalHeightAndWidth,
    height: ovalHeightAndWidth,
    borderRadius: ovalHeightAndWidth * 0.6,
  },

  progressLine: {
    height: processLineHeight,
  },

  stepLabel: {
    fontFamily: Fonts.regular,
    textAlign: 'center',
  },

  stepLabelContainer: {
    marginTop: 16,
    maxWidth: 0.2 * width,
    minWidth: 0.17 * width,
  },

  progressPendingLine: {
    position: 'relative',
  },

  processDoneLine: {
    position: 'absolute',
  },
})
