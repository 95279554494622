import { useAppState } from './useAppState'
import { useIsFocused } from '@react-navigation/native'
import { useIsConnected } from './useIsConnected'

export const useShouldSkipPolling = (hasFullSubscription = false) => {
  const { isActive } = useAppState()
  const isFocused = useIsFocused()
  const isConnected = useIsConnected()

  return !isActive || !isFocused || (hasFullSubscription && isConnected)
}
