import React from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import {
  getDepartments,
  getDepartmentsVariables,
  getDepartments_getSites_items,
} from 'src/types/getDepartments'
import { GET_DEPARTMENTS } from 'src/components/Whiteboard/graphql'
import { MultiSelectNestedMenu } from 'components/common/NestedListSelect/MultiSelectNestedMenu'
import {
  NestedListSelectOption,
  NestedOptionLevels,
} from 'components/common/NestedListSelect/types'
import { SiteType } from 'src/types/globalTypes'
import { isEmpty } from 'lodash'

type Props = {
  disabled?: boolean
  onSelectedDepartmentChange: (sites: string[]) => void
  selectedDepartment: string[]
}

export const DepartmentsSection: React.FC<Props> = ({
  onSelectedDepartmentChange,
  selectedDepartment,
  disabled,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { data: DepartmentsData, loading } = useQuery<
    getDepartments,
    getDepartmentsVariables
  >(GET_DEPARTMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisationId,
    },
  })

  const getSelectableDepartments = (
    sites?: getDepartments_getSites_items[] | null,
  ) => {
    if (isEmpty(sites)) return []
    /* If the only available site is the top level ENTITY, it should go into 
    the options. Otherwise, it should not be there. */
    let selectableDepartments =
      sites?.filter(site => site.type !== SiteType.SITE) ?? []
    if (isEmpty(selectableDepartments)) {
      selectableDepartments =
        sites?.filter(site => site.type === SiteType.SITE) ?? []
    }
    const departmentLevelToNestedOptionLevelMapper: {
      [key in SiteType]: NestedOptionLevels
    } = {
      [SiteType.DIVISION]: NestedOptionLevels.CHILD,
      [SiteType.BUSINESS]: NestedOptionLevels.PARENT,
      [SiteType.SITE]: NestedOptionLevels.TOP,
      // wont be used below this comment
      [SiteType.DEPARTMENT]: NestedOptionLevels.CHILD,
      [SiteType.LOCATION]: NestedOptionLevels.CHILD,
    }
    return selectableDepartments.map(({ name, id, type, parent_site_id }) => {
      return {
        value: id,
        text: name,
        nestedOptionLevel: departmentLevelToNestedOptionLevelMapper[type],
        parentId: type === SiteType.DIVISION ? parent_site_id : null,
      }
    })
  }

  const selectableDepartmentsMultiSelectMenu: NestedListSelectOption[] =
    getSelectableDepartments(DepartmentsData?.getSites?.items)

  return (
    <View>
      <MultiSelectNestedMenu
        label={t('template:form.department')}
        options={selectableDepartmentsMultiSelectMenu}
        selected={selectedDepartment}
        onChange={onSelectedDepartmentChange}
        loading={loading}
        // If there is only one option, the user can't open the menu
        disabled={disabled || selectableDepartmentsMultiSelectMenu.length < 2}
      />
    </View>
  )
}
