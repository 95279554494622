import { attitudeToAlertLevel } from './attitudeToAlertLevel'
import { Attitude } from 'src/types/globalTypes'
import { AlertLevel } from 'components/common/Avatar/AvatarWithMessage'

type PatientAttitude = {
  attitude: Attitude | null
}

export const getAlertLevelAndAttitude = (
  patient: PatientAttitude,
  isPatientDangerousOverride?: boolean,
) => {
  if (isPatientDangerousOverride) {
    return { alertLevel: AlertLevel.BAD, attitude: Attitude.CAUTION }
  }

  return {
    alertLevel: attitudeToAlertLevel(patient.attitude),
    attitude: patient.attitude ?? Attitude.UNKNOWN,
  }
}
