import { get, orderBy } from 'lodash'

/**
 * Sort list alphabetically
 */

export const sortList = <T extends { [key: string]: any }>(
  list: T[],
  key: string = 'name',
): T[] => {
  return orderBy(
    list,
    [item => (get(item, key) as string)?.toLowerCase()],
    ['asc'],
  )
}
