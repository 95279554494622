import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SvgArrow,
  SvgCheck,
  SvgHamburger,
  SvgMeatballs,
} from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  GestureResponderEvent,
  StyleProp,
  ViewStyle,
} from 'react-native'
import { isOptimisticId } from 'src/utils/optimisticId'
import { Tag } from 'src/components/Tag/Tag'
import { Actions, ActionableListItem } from 'components/common/MultiActions'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { useConfirm } from 'src/context/confirm'

export type OnPressLink = {
  id: string
  name: string
}

type IMenuItemOption = {
  name: Actions
  onClick: () => any
  confirmationOption?: {
    title: string
    text: string
  }
}

export type LinkProps = {
  a11yLabel?: string
  disabled?: boolean
  draggable?: boolean
  id: string
  name: string
  onDragPress?: () => void
  onPress?: (onPress: OnPressLink) => void
  tag?: Parameters<typeof Tag>[0]['title'] | null
  menuItems?: IMenuItemOption[]
  extraText?: string
  displayArrow?: boolean
  displayCheck?: boolean
  style?: StyleProp<ViewStyle>
}

export const ListItem = ({
  a11yLabel,
  draggable = false,
  disabled = false,
  id,
  name,
  onDragPress,
  onPress,
  tag,
  menuItems,
  extraText,
  displayArrow,
  displayCheck,
  style,
}: LinkProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOptimistic = isOptimisticId(id)
  const showMetaBall = Platform.OS === 'web' && menuItems

  const confirm = useConfirm()

  const openMenu = useCallback((event: GestureResponderEvent) => {
    event.preventDefault()
    const target = event.currentTarget
    requestAnimationFrame(() => setAnchorEl(target as any))
  }, [])

  const renderItem = () => (
    <TouchableOpacity
      accessibilityLabel={a11yLabel || `Navigate to Sheet ${name}`}
      disabled={disabled || isOptimistic}
      onPress={() => onPress && onPress({ id, name })}
      style={[styles.container, style, disabled && { opacity: 0.5 }]}
    >
      <View style={styles.main}>
        {draggable ? (
          <TouchableOpacity style={styles.draggable} onPressIn={onDragPress}>
            <SvgHamburger />
          </TouchableOpacity>
        ) : null}
        <TextWithTooltip
          style={[
            styles.title,
            isOptimistic && { color: Colors.optimisticGrey },
          ]}
          numberOfLines={1}
          testID={name}
          title={name}
        >
          {name}
        </TextWithTooltip>
        {!!tag && (
          <Tag testID={`Tag ${tag} - ${name}`} title={tag} style={styles.tag} />
        )}
        {displayCheck ? (
          <View style={styles.iconLeft}>
            <SvgCheck color={Colors.contentPrimary} />
          </View>
        ) : null}
      </View>
      {isOptimistic ? (
        <ActivityIndicator size="small" style={styles.activitySpinner} />
      ) : (
        !disabled && (
          <View style={styles.main}>
            {!!extraText && (
              <TextWithTooltip
                numberOfLines={1}
                style={styles.extraText}
                title={extraText}
              >
                {extraText}
              </TextWithTooltip>
            )}
            {!!showMetaBall && (
              <TouchableOpacity
                accessibilityLabel={t('general.moreActions')}
                style={styles.metaBall}
                onPress={openMenu}
              >
                <SvgMeatballs color={Colors.contentTertiary} />
              </TouchableOpacity>
            )}
            {displayArrow ? (
              <View style={styles.icon}>
                <SvgArrow orient="right" color="grey" width={15} height={15} />
              </View>
            ) : null}
          </View>
        )
      )}
    </TouchableOpacity>
  )

  if (!menuItems) {
    return renderItem()
  }

  const actions = menuItems.map(menuItem => {
    const onAction = async () => {
      setAnchorEl(null)
      if (menuItem.confirmationOption) {
        try {
          await confirm(menuItem.confirmationOption)
        } catch (_) {
          return
        }
      }
      menuItem.onClick()
    }
    return {
      onAction,
      name: menuItem.name,
    }
  })
  const onCloseMenu = () => setAnchorEl(null)
  return (
    <ActionableListItem
      actions={actions}
      anchorEl={anchorEl}
      closeMenu={onCloseMenu}
      isActionable={true}
    >
      {renderItem()}
    </ActionableListItem>
  )
}

const styles = StyleSheet.create({
  activitySpinner: {
    paddingRight: 16,
  },
  container: {
    flexDirection: 'row',
    paddingLeft: 16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    width: '100%',
  },
  draggable: {
    marginRight: 12,
  },
  icon: {
    padding: 16,
    maxWidth: '12%',
  },
  iconLeft: {
    padding: 16,
    maxWidth: '12%',
    justifyContent: 'flex-start',
  },
  main: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  metaBall: {
    paddingHorizontal: 16,
  },
  tag: {
    marginHorizontal: 12,
  },
  title: {
    fontFamily: Fonts.regular,
    fontSize: 16,
  },
  extraText: {
    fontFamily: Fonts.regular,
    color: Colors.darkGrey,
    paddingRight: 8,
  },
})
