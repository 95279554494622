import { gql } from '@apollo/client'

export const GET_PATIENT_VITALS = gql`
  query getPatientVitals($patientId: ID!, $productId: ID!) {
    getPatientVitals(patient_id: $patientId, product_id: $productId) {
      items {
        date
        examination_date
        id
        value
      }
    }
  }
`
