import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  color: string
}

export const SvgMore: React.FunctionComponent<Props> = ({ color }) => (
  <Svg width={17} height={18}>
    <Path
      d="M16.017 15.785H8.655a2.772 2.772 0 0 1-2.61 1.815 2.772 2.772 0 0 1-2.611-1.815h-1.85a.778.778 0 0 1-.784-.773v-.303c0-.426.35-.772.783-.772h1.85a2.772 2.772 0 0 1 2.61-1.813c1.204 0 2.225.756 2.61 1.813h7.363c.432 0 .783.345.783.772v.303a.776.776 0 0 1-.782.773zm-9.972-1.832a.915.915 0 0 0-.922.908c0 .503.412.909.922.909.509 0 .92-.406.92-.909a.915.915 0 0 0-.92-.908zm9.972-4.23h-1.83a2.772 2.772 0 0 1-2.61 1.815 2.772 2.772 0 0 1-2.611-1.816H1.584a.778.778 0 0 1-.783-.772v-.303c0-.426.35-.772.783-.772h7.383a2.772 2.772 0 0 1 2.61-1.814c1.203 0 2.224.757 2.61 1.814h1.83c.432 0 .783.345.783.772v.303c0 .427-.35.772-.783.772zm-4.44-1.832a.915.915 0 0 0-.922.909c0 .502.412.909.921.909.51 0 .922-.407.922-.909a.915.915 0 0 0-.922-.909zm4.44-4.23H8.655a2.772 2.772 0 0 1-2.61 1.815 2.772 2.772 0 0 1-2.611-1.815h-1.85A.778.778 0 0 1 .8 2.89v-.303c0-.426.35-.773.783-.773h1.85A2.772 2.772 0 0 1 6.044 0c1.204 0 2.225.757 2.61 1.813h7.363c.432 0 .783.346.783.773v.303a.776.776 0 0 1-.782.772zM6.045 1.83a.915.915 0 0 0-.922.909c0 .502.412.908.922.908.509 0 .92-.406.92-.908a.915.915 0 0 0-.92-.909z"
      fill={color}
      fillRule="nonzero"
    />
  </Svg>
)
