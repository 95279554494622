import React from 'react'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { TextStyle, View, ViewStyle } from 'react-native'

type PatientNameTextProps = {
  name: string
  textStyle: TextStyle
  containerStyle: ViewStyle
}

export const PatientNameText = ({
  containerStyle,
  name,
  textStyle,
}: PatientNameTextProps) => {
  return (
    <View style={containerStyle}>
      <TextWithTooltip style={textStyle} numberOfLines={1} title={name}>
        {name}
      </TextWithTooltip>
    </View>
  )
}
