import { isBefore, parseISO } from 'date-fns'
import { maxBy, sortBy, nth } from 'lodash'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as Task } from 'src/types/getSheet'

export const getNearestTaskBeforePress = (
  allTasks: Task[],
  timeInGrid: Date,
) => {
  const earlierTasks = allTasks.filter(item => {
    if (!item.start_at) return false
    const startAt = parseISO(item?.start_at)
    return isBefore(startAt, timeInGrid)
  })

  // TODO: we should sort tasks in a treatment by start_at earlier. Or return
  // them sorted from platform
  return maxBy(earlierTasks, task => {
    if (!task.start_at) return
    return parseISO(task.start_at)
  })
}

export const getPrevTask = (allTasks: Task[]) => {
  const sortedTasks = sortBy(allTasks, task => task.given_stop_at)
  return nth(sortedTasks, -2)
}
