import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  FormBreak,
  FormLabel,
  InputGroup,
  InputGroupText,
  NumericInput,
  SecondaryButton,
  Select,
  SwitchInput,
  TextArea,
  TextInput,
  TextLink,
} from 'components/common'
import { Status } from 'components/common/Form/Status'
import { ControlledTextInput } from 'components/common/TextInput/ControlledTextInput'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { VitalOptions } from 'constants/VitalOptions'
import { TFunction } from 'i18next'
import { capitalize } from 'lodash'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import {
  calculateBaseOptions,
  concentrationVolumeUnitOptions,
  dilutedConcentrationVolumeUnitOptions,
  perWeightUnitOptions,
  routesOfAdminOptions,
  weightUnitOptions,
} from 'src/components/TreatmentForm/data'
import { Colors } from 'src/constants/Colors'
import { WeightUnit } from 'src/constants/Weight'
import { useOrganisation } from 'src/context/organisation'
import { anesthesiaCustomProductTitles } from 'src/context/types/anesthesia'
import {
  getCustomPimsMappingOptions as GetPimsMappingOptions,
  getCustomPimsMappingOptionsVariables as GetPimsMappingOptionsVariables,
} from 'src/types/getCustomPimsMappingOptions'
import {
  getProduct_getProduct_custom_values as CustomValue,
  getProduct_getProduct_pims_mapping as PimsMapping,
} from 'src/types/getProduct'
import {
  getSheetGroups as GetSheetGroups,
  getSheetGroupsVariables as GetSheetGroupsVariables,
} from 'types/getSheetGroups'
import { ProductType } from 'types/globalTypes'
import * as Yup from 'yup'
import { useConfirm } from 'src/context/confirm'

import { GET_CUSTOM_PIMS_MAPPING_OPTIONS, GET_SHEET_GROUPS } from './graphql'
import { isNonStandardWeightUnits } from 'components/TreatmentForm/utils/isNonStandardWeightUnits'
import { useFlags } from 'react-native-flagsmith/react'
import { useRouteOfAdministrations } from 'components/RouteOfAdministration/useRouteOfAdministrations'
import { RouteOfAdministration } from 'components/Settings/RouteOfAdministration/types'
import { getRoutesOfAdminSchema } from 'components/TreatmentForm/utils/getTreatmentFormSchema'
import { retrieveVitalProductOptions } from 'src/utils/retrieveVitalProductOptions'

export type CustomValueT = Omit<CustomValue, '__typename'>

interface Medicine {
  is_diluted: boolean
  route_of_administration: string
  calculate_base: string
  dosage: number | null
  dosage_weight_unit: string
  dosage_patient_weight_unit: string
  diluted_concentration: number | null
  diluted_concentration_weight_unit: string
  diluted_concentration_volume_unit: string
  concentration: number | null
  concentration_weight_unit: string
  concentration_volume_unit: string
}

export type Inputs = {
  name: string
  description: string
  pimsMapping: PimsMapping[] | null
  type: ProductType
  custom_values: CustomValueT[]
  medicine: Medicine
  parent_product_id: string | null
  customPimsMapping: string | null
  isValueRequired: boolean
  isNotesRequired: boolean
  origin_id: string | null
}

const typeOptions = Object.keys(ProductType).map(type => ({
  text: type === 'IVFLUIDS' ? 'IV Fluids' : capitalize(type),
  value: type,
}))

// the null option for old custom product still sync notes to ezyVet
const defaultCustomPimsMapping = [
  {
    text: 'Hospital Notes',
    value: 'hospital_notes',
  },
]

const defaultValues = {
  description: '',
  medicine: {
    is_diluted: false,
    route_of_administration: routesOfAdminOptions[1].value,
    calculate_base: calculateBaseOptions[0].value,
    dosage: 0,
    dosage_weight_unit: weightUnitOptions[2].value,
    dosage_patient_weight_unit: perWeightUnitOptions[0].value,
    // TODO: There is no dilution in treatment right now, so always default for now.
    diluted_concentration: 0,
    diluted_concentration_weight_unit: WeightUnit.MG,
    diluted_concentration_volume_unit: concentrationVolumeUnitOptions[0].value,
    concentration: 0,
    concentration_weight_unit: WeightUnit.MG,
    concentration_volume_unit: concentrationVolumeUnitOptions[0].value,
  },
  name: '',
  pimsMapping: null,
  isValueRequired: false,
  isNotesRequired: false,
  type: ProductType.STANDARD,
  custom_values: [],
  parent_product_id: '',
  customPimsMapping: null,
  origin_id: null,
} as Inputs

const getValidationSchema = (
  t: TFunction,
  routeOfAdministrations: RouteOfAdministration[],
  hasRouteOfAdministrationsSettingEnabled: boolean = false,
) => {
  const requiredMsg = t('form.required')
  return Yup.object().shape({
    name: Yup.string().required(requiredMsg),
    type: Yup.string().required(requiredMsg),
    medicine: Yup.object().when('type', {
      is: ProductType.MEDICATION,
      then: Yup.object()
        .shape({
          dosage: Yup.number().nullable(),
          ...(hasRouteOfAdministrationsSettingEnabled
            ? {
                route_of_administration: getRoutesOfAdminSchema(
                  t,
                  routeOfAdministrations,
                ),
              }
            : {}),
        })
        .nullable(true),
    }),
    isValueRequired: Yup.boolean().required(requiredMsg),
    isNotesRequired: Yup.boolean().required(requiredMsg),
    custom_values: Yup.array()
      .of(
        Yup.object().shape({
          key: Yup.string().nullable().required(requiredMsg),
          value: Yup.string().nullable().required(requiredMsg),
        }),
      )
      .nullable(true),
  })
}

export type Props = {
  isSelf?: boolean
  onDelete?: () => void
  onSubmit: (data: Inputs) => void
  product?: Inputs
  submitting?: boolean
  submitTitle: string
  isCustomProduct?: boolean
  isProductHealthStatus?: boolean
  isModifiable?: boolean
}

export const CustomProductForm: React.FC<Props> = ({
  onDelete,
  onSubmit,
  product = {} as Inputs,
  submitting = false,
  submitTitle,
  isCustomProduct = true,
  isProductHealthStatus = false,
  isModifiable = true,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const queryVariables = {
    id: organisationId,
  }
  const [isDeleting, setIsDeleting] = useState(false)

  const { data, loading: groupLoading } = useQuery<
    GetSheetGroups,
    GetSheetGroupsVariables
  >(GET_SHEET_GROUPS, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
  })

  const { data: customPims, loading: pimsLoading } = useQuery<
    GetPimsMappingOptions,
    GetPimsMappingOptionsVariables
  >(GET_CUSTOM_PIMS_MAPPING_OPTIONS, {
    variables: { organisation_id: organisationId },
  })
  const loading = groupLoading || pimsLoading

  const flags = useFlags([FeatureFlagNames.VRAdminControl])

  const {
    shouldUseRouteOfAdministrationSetting,
    activeRouteOfAdministrations,
    activeRouteOfAdministrationsSelectOptions,
  } = useRouteOfAdministrations()

  // Always allow VR admin to edit (any) custom product. Disallow editing
  // anesthesia chartable values (except sheet group) otherwise
  const isInAnesthesiaChartCustomProductsList: boolean =
    !flags.vet_radar_admin_controls.enabled &&
    !!product.name &&
    anesthesiaCustomProductTitles.includes(product.name)

  const sheetGroups = data?.getOrganisation?.products?.items
  const sheetGroupOptions =
    sheetGroups?.map(grp => ({
      text: grp.name,
      value: grp.id,
    })) ?? []

  const validationSchema = getValidationSchema(
    t,
    activeRouteOfAdministrations,
    shouldUseRouteOfAdministrationSetting,
  )

  const defaultMedicineRouteOfAdministration = useMemo(() => {
    const IVRoute = activeRouteOfAdministrations.find(
      route => route.value === 'IV',
    )?.value

    if (IVRoute) return IVRoute

    return activeRouteOfAdministrations?.[0]?.value ?? ''
  }, [activeRouteOfAdministrations])

  const initialValues: Inputs = {
    ...defaultValues,
    ...(shouldUseRouteOfAdministrationSetting
      ? {
          medicine: {
            ...defaultValues.medicine,
            route_of_administration: defaultMedicineRouteOfAdministration,
          },
        }
      : {}),
    ...product,
    ...(!product?.customPimsMapping
      ? { customPimsMapping: 'hospital_notes' }
      : {}),
  }

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, errors },
    getValues,
    setValue,
    trigger,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'custom_values',
  })
  const customName = watch('name')
  const productType = watch('type')
  const isDiluted = watch('medicine.is_diluted', false)
  const pims = watch('pimsMapping')
  const routeOfAdmin = watch('medicine.route_of_administration')

  useEffect(() => {
    if (
      shouldUseRouteOfAdministrationSetting &&
      productType === ProductType.MEDICATION
    ) {
      trigger('medicine.route_of_administration')
    }
  }, [
    routeOfAdmin,
    shouldUseRouteOfAdministrationSetting,
    trigger,
    productType,
  ])

  const [showCustomValues, setShowCustomValues] = useState(true)

  const customProductOptions = retrieveVitalProductOptions(
    [],
    !showCustomValues,
    product?.origin_id,
  )

  const hasError = !isValid
  const isEditView = !!onDelete

  const hasCustomOption = productType !== ProductType.MEDICATION
  const isFluid = productType === ProductType.IVFLUIDS

  useEffect(() => {
    if (product.origin_id && product.origin_id in VitalOptions) {
      setShowCustomValues(false)
    }
  }, [product.origin_id])

  const confirm = useConfirm()
  const handleDelete = async () => {
    try {
      await confirm({
        title: t('settings:removeProduct.label', {
          customProductName: customName,
        }),
        text: '',
      })
    } catch (_) {
      // Handle the confirm dialog closed action
      return
    }

    try {
      setIsDeleting(true)
      await onDelete!()
    } finally {
      setIsDeleting(false)
    }
  }

  const customPimsMappingOptions = useMemo(() => {
    if (!customPims?.getCustomPimsMappingOptions) {
      return defaultCustomPimsMapping
    }
    return [
      ...defaultCustomPimsMapping,
      ...customPims.getCustomPimsMappingOptions,
    ]
  }, [customPims?.getCustomPimsMappingOptions])

  const handleDosageConcentrationWeight = (
    changedFieldName:
      | 'medicine.dosage_weight_unit'
      | 'medicine.concentration_weight_unit',
    newValue: WeightUnit,
  ) => {
    const syncedFieldName =
      changedFieldName === 'medicine.dosage_weight_unit'
        ? 'medicine.concentration_weight_unit'
        : 'medicine.dosage_weight_unit'

    const syncedFieldValue = getValues(syncedFieldName) as WeightUnit

    // Keep dosage rate and concentration unit the same if the selected value is non-standard
    // If the selected value is non-standard and synced field is non-standard, reset synced field to MG
    if (isNonStandardWeightUnits(newValue)) {
      setValue(syncedFieldName, newValue)
    } else if (isNonStandardWeightUnits(syncedFieldValue)) {
      setValue(syncedFieldName, WeightUnit.MG)
    }
  }

  return !loading ? (
    <KeyboardAwareScrollView>
      <FormLabel text={t('settings:addProduct:name')} />
      <Status status={createErrorStatus(errors.name?.message)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              accessibilityLabel={'Product name input'}
              label={t('settings:products:name')}
              disabled={isInAnesthesiaChartCustomProductsList || !isModifiable}
              onChangeText={onChange}
              value={value}
            />
          )}
          name="name"
          rules={{ required: true }}
        />
      </Status>
      <Status status={createErrorStatus(errors.type?.message)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              disabled={isInAnesthesiaChartCustomProductsList || !isModifiable}
              testID={'product type'}
              label={t('settings:addProduct:type')}
              options={typeOptions}
              onChange={newValue => {
                onChange(newValue)
                setValue('custom_values', [])
              }}
              selected={value}
            />
          )}
          name="type"
          rules={{ required: true }}
        />
      </Status>
      <ControlledSelect
        control={control}
        name="parent_product_id"
        label={t('settings:addProduct:sheetGroup')}
        options={sheetGroupOptions}
      />
      {isCustomProduct || !isProductHealthStatus ? (
        <ControlledSelect
          control={control}
          name="customPimsMapping"
          label={t('settings:addProduct:pimsMapping')}
          options={customPimsMappingOptions}
        />
      ) : null}
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <SwitchInput
            label={t('settings:addProduct:isValueRequired')}
            onChangeValue={onChange}
            value={value}
          />
        )}
        name="isValueRequired"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <SwitchInput
            label={t('settings:addProduct:isNotesRequired')}
            onChangeValue={onChange}
            value={value}
          />
        )}
        name="isNotesRequired"
      />
      <FormBreak />
      {productType === ProductType.MEDICATION && (
        <>
          <FormLabel text={t('addTreatment:calculation')} />
          <FormBreak />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('addTreatment:calculateBaseOn')}
                options={calculateBaseOptions}
                selected={value}
                onChange={onChange}
              />
            )}
            name="medicine.calculate_base"
          />
          {/* Route of administration */}
          {shouldUseRouteOfAdministrationSetting ? (
            <Status
              status={createErrorStatus(
                errors?.medicine?.route_of_administration?.message,
              )}
            >
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    label={t('addTreatment:routeOfAdministration')}
                    options={activeRouteOfAdministrationsSelectOptions}
                    selected={value}
                    onChange={onChange}
                  />
                )}
                name="medicine.route_of_administration"
              />
            </Status>
          ) : (
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  label={t('addTreatment:routeOfAdministration')}
                  options={routesOfAdminOptions}
                  selected={value}
                  onChange={onChange}
                />
              )}
              name="medicine.route_of_administration"
            />
          )}

          <InputGroup>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumericInput
                  label={t('addTreatment:concentration')}
                  value={value}
                  onChange={onChange}
                  style={styles.flex}
                />
              )}
              name="medicine.concentration"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={weightUnitOptions}
                  selected={value}
                  onChange={newValue => {
                    onChange(newValue)
                    handleDosageConcentrationWeight(
                      'medicine.concentration_weight_unit',
                      newValue as WeightUnit,
                    )
                  }}
                  a11yLabel={t('addTreatment:concentrationWeightUnit')}
                />
              )}
              name="medicine.concentration_weight_unit"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={concentrationVolumeUnitOptions}
                  selected={value}
                  onChange={onChange}
                />
              )}
              name="medicine.concentration_volume_unit"
            />
          </InputGroup>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <SwitchInput
                label={t('addTreatment:applyDiluted')}
                onChangeValue={onChange}
                value={value}
              />
            )}
            name="medicine.is_diluted"
          />
          {isDiluted ? (
            <InputGroup>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericInput
                    disabled={!isDiluted}
                    label={t('addTreatment:diluted')}
                    value={value}
                    onChange={onChange}
                    style={styles.flex}
                  />
                )}
                name="medicine.diluted_concentration"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    disabled={!isDiluted}
                    onChange={onChange}
                    options={weightUnitOptions}
                    selected={value}
                  />
                )}
                name="medicine.diluted_concentration_weight_unit"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    disabled={!isDiluted}
                    onChange={onChange}
                    options={dilutedConcentrationVolumeUnitOptions}
                    selected={value}
                  />
                )}
                name="medicine.diluted_concentration_volume_unit"
              />
            </InputGroup>
          ) : null}
          <Status
            status={createErrorStatus(
              (errors?.medicine as any)?.dosage?.message,
            )}
          >
            <InputGroup>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericInput
                    label={t('addTreatment:doseRate')}
                    value={value}
                    onChange={onChange}
                    style={styles.flex}
                  />
                )}
                name="medicine.dosage"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    selected={value}
                    options={weightUnitOptions}
                    onChange={newValue => {
                      onChange(newValue)
                      handleDosageConcentrationWeight(
                        'medicine.dosage_weight_unit',
                        newValue as WeightUnit,
                      )
                    }}
                    a11yLabel={t('addTreatment:dosageWeightUnit')}
                  />
                )}
                name="medicine.dosage_weight_unit"
              />
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    selected={value}
                    options={perWeightUnitOptions}
                    onChange={onChange}
                    a11yLabel={t('addTreatment:dosagePerWeightUnit')}
                  />
                )}
                name="medicine.dosage_patient_weight_unit"
              />
            </InputGroup>
          </Status>
        </>
      )}
      {hasCustomOption
        ? fields?.map((field, idx) => (
            <View key={field.id}>
              <Status
                status={createErrorStatus(
                  (errors.custom_values as any)?.[idx]?.key?.message ||
                    (errors.custom_values as any)?.[idx]?.value?.message,
                )}
              >
                {isFluid ? (
                  <InputGroup>
                    <ControlledTextInput
                      control={control}
                      name={`custom_values.${idx}.key`}
                      label={t('settings:addProduct.optionName')}
                      required={true}
                      style={{ flex: 1 }}
                      defaultValue={field.key}
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <NumericInput
                          label={t('settings:addProduct.optionRate')}
                          value={value ? parseFloat(value) : null}
                          onChange={v => onChange(v?.toString() ?? '')}
                          required={true}
                          style={styles.flex}
                        />
                      )}
                      defaultValue={field.value!}
                      name={`custom_values.${idx}.value`}
                    />
                    <InputGroupText text={'mL/kg/hr'} />
                  </InputGroup>
                ) : (
                  <Controller
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        label={t('settings:addProduct.valueOption', {
                          number: idx + 1,
                        })}
                        value={value!}
                        onChangeText={onChange}
                        required={true}
                      />
                    )}
                    name={`custom_values.${idx}.value`}
                    rules={{ required: true }}
                    defaultValue={field.value}
                  />
                )}
              </Status>

              <TextLink
                a11yLabel={`${t('settings:addProduct.removeValue')} button`}
                onPress={() => remove(idx)}
                text={t('settings:addProduct.removeValue')}
                containerStyles={styles.removeOptionButton}
              />
            </View>
          ))
        : null}
      {hasCustomOption &&
      showCustomValues &&
      !isInAnesthesiaChartCustomProductsList ? (
        <TextLink
          onPress={() =>
            append({
              key: isFluid ? '' : `Option ${fields.length + 1}`,
              value: '',
            })
          }
          a11yLabel={`${t('settings:addProduct.customValue')} button`}
          containerStyles={{ marginVertical: 8 }}
          text={t('settings:addProduct.customValue')}
          color={Colors.buttons.blue}
        />
      ) : null}

      {customProductOptions.map((option, idx) => {
        return (
          <TextInput
            key={option.value}
            label={t('settings:addProduct.valueOption', {
              number: idx + 1,
            })}
            value={option.text}
            disabled={true}
          />
        )
      })}

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextArea
            disabled={isInAnesthesiaChartCustomProductsList}
            label={t('settings:addProduct:desc')}
            onChangeText={onChange}
            value={value}
            visibleLines={6}
          />
        )}
        name="description"
      />
      {/* use useFieldArray if need to edit */}
      {pims ? (
        <>
          <FormLabel text={t('settings:products.pimsMapping')} />
          {pims.map((e, idx) => {
            return (
              <Controller
                key={e.field ?? idx}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    disabled={true}
                    label={capitalize(`${value.type} ${value.dest}`)}
                    onChangeText={onChange}
                    value={e.field ?? ''}
                  />
                )}
                name={`pimsMapping.${idx}`}
              />
            )
          })}
        </>
      ) : null}

      <Button
        onPress={handleSubmit(_ => onSubmit(getValues()))}
        title={submitTitle}
        loading={submitting}
        color={Colors.green}
        style={styles.button}
        disabled={hasError}
      />
      {isEditView && !isInAnesthesiaChartCustomProductsList && isModifiable ? (
        <SecondaryButton
          accessibilityLabel={`${t('settings:deleteProduct.label')} button`}
          onPress={handleDelete}
          title={t('settings:deleteProduct.label')}
          loading={isDeleting}
        />
      ) : null}
    </KeyboardAwareScrollView>
  ) : (
    <ActivityIndicator
      accessibilityLabel="Custom Product Form Loading Indicator"
      size="large"
      style={styles.activityIndicator}
    />
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  button: {
    marginVertical: 16,
  },
  removeOptionButton: {
    alignItems: 'flex-end',
    paddingRight: 8,
    paddingBottom: 16,
  },
  activityIndicator: {
    marginTop: 32,
  },
})
