import React from 'react'
import { ClearIndicatorProps, components } from 'react-select'
import { Text, StyleSheet } from 'react-native' // TODO for web, maybe don't use RN
import { Typography } from 'src/design-system/theme/typography'
import { SelectOption } from '../..'

export const ClearIndicator: React.FC<
  ClearIndicatorProps<SelectOption, boolean>
> = props => {
  return (
    <components.ClearIndicator {...props}>
      <Text style={[Typography.Label.M, styles.clearLabel]}>Clear</Text>
    </components.ClearIndicator>
  )
}

const styles = StyleSheet.create({
  clearLabel: {
    color: 'inherit',
    fontWeight: 'bold',
  },
})
