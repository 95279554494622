// Borrow(Copy) from https://github.com/RangerMauve/react-native-responsive-stylesheet.
// Caveat: Need trigger a re-render after Dimension changed. Use a consumer HOC from hocs/Breakpoint.tsx works for now.
// TODO: Customize it: Ceate default style in createSized()

import { Dimensions, StyleSheet } from 'react-native'

const VALID_direction = [
  'min-width',
  'max-width',
  'min-height',
  'max-height',
  'min-direction',
  'max-direction',
]

// Pass-through
function create(styles) {
  return StyleSheet.create(styles)
}

// Create sheet based on size increments for a certain direction
function createSized(direction, map) {
  if (VALID_direction.indexOf(direction) === -1)
    throw new TypeError(`direction must equal ${VALID_direction.join(' or ')}`)

  const sizes = Object.keys(map).sort()

  const stylesheets = sizes.reduce((result, size) => {
    const stylesheet = map[size]

    result[size] = StyleSheet.create(stylesheet)

    return result
  }, {})

  const styleNames = sizes.reduce((names, size) => {
    return names.concat(Object.keys(map[size]))
  }, [])

  return styleNames.reduce((sheet, styleName) => {
    if (styleName in sheet) return sheet

    Object.defineProperty(sheet, styleName, {
      get: getStyles,
    })

    function getStyles() {
      const valid = validSizes(direction, sizes)

      return valid.reduce((styles, size) => {
        const stylesheet = stylesheets[size]
        const style = stylesheet[styleName]

        if (!style) return styles
        return styles.concat(style)
      }, [])
    }

    return sheet
  }, {})
}

// Creates sheet based on landscape or portrait orientation
function createOriented(map) {
  const landscapeStyles = map.landscape || {}
  const portraitStyles = map.portrait || {}

  const landscapeStylesheet = StyleSheet.create(landscapeStyles)
  const portraitStylesheet = StyleSheet.create(portraitStyles)

  const stylesheets = {
    landscape: landscapeStylesheet,
    portrait: portraitStylesheet,
  }

  const styleNames = Object.keys(landscapeStyles).concat(
    Object.keys(portraitStyles),
  )

  return styleNames.reduce((sheet, styleName) => {
    if (styleName in sheet) return sheet

    Object.defineProperty(sheet, styleName, {
      get: getStyle,
    })

    function getStyle() {
      const orientation = getOrientation()
      return stylesheets[orientation][styleName]
    }

    return sheet
  }, {})
}

function validSizes(direction, sizes) {
  const dimensions = Dimensions.get('window')
  const width = dimensions.width
  const height = dimensions.height

  const dimensionName = direction.slice(4)
  let dimension
  if (dimensionName === 'direction')
    dimension = width <= height ? width : height
  else dimension = dimensions[dimensionName]

  const directionName = direction.slice(0, 3)
  const greater = directionName === 'min'

  const styles = sizes.filter(size => {
    const parsed = parseInt(size, 10)
    return greater ? dimension >= parsed : dimension <= parsed
  })

  return greater ? styles : styles.reverse()
}

function getOrientation() {
  const dimensions = Dimensions.get('window')

  return dimensions.width > dimensions.height ? 'landscape' : 'portrait'
}

export const ResponsiveStylesheet = {
  create,
  createSized,
  createOriented,
}
