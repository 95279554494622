import React from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  ViewStyle,
} from 'react-native'
import { noop } from 'lodash'
import { SvgClose } from 'components/Icons/Close'
import { Typography } from 'src/design-system/theme'

type Props = {
  title: string
  onPress?: () => void
  style?: StyleProp<ViewStyle>
}

/* Secondary Button, used for add treatment, add template etc */
export const ClipButton = ({ onPress = noop, style = {}, title }: Props) => {
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <Text numberOfLines={1} style={styles.text}>
        {title}
      </Text>
      <SvgClose color="black" />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    backgroundColor: '#F3F4F6',
    height: 44,
    borderRadius: 50,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontFamily: Typography.FontFamilies.semibold,
    marginRight: 5,
  },
})
