import React, { useCallback } from 'react'
import { ScaleTimeFn } from 'components/Grid/GridTimeLine/GridTimeline'
import { Colors } from 'constants/Colors'
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
  Platform,
  GestureResponderEvent,
} from 'react-native'

import {
  treatmentHeight,
  treatmentGroupHeight,
  treatmentHeightLarge,
} from '../common'

type Props = {
  a11yLabel?: string
  children: React.ReactElement
  groupStyle: ViewStyle
  isMedication?: boolean
  isTouchable?: boolean
  isTreatmentFullHeight?: boolean
  isTreatmentGroup?: boolean
  onPress: (timeOfPress: Date) => void
  positionInGridFn: ScaleTimeFn
}

export const GridFullRow: React.FC<Props> = React.memo(
  ({
    a11yLabel,
    children,
    groupStyle,
    isTouchable,
    isTreatmentFullHeight = false,
    isTreatmentGroup = false,
    onPress,
    positionInGridFn,
  }) => {
    const touchableRowPress = useCallback(
      (event: any) => {
        let locationX: number
        if (Platform.OS === 'web') {
          locationX = (event.nativeEvent as MouseEvent).offsetX
        } else {
          locationX = (event as GestureResponderEvent).nativeEvent.locationX
        }
        if (locationX) {
          const timeOfPress: Date = positionInGridFn.invert(locationX)
          onPress(timeOfPress)
        }
      },
      [positionInGridFn, onPress],
    )

    /* Runs full width */
    return isTouchable ? (
      <TouchableOpacity
        accessibilityLabel={a11yLabel}
        style={[
          styles.outerContainer,
          groupStyle,
          styles.container,
          isTreatmentGroup && styles.groupHeight,
        ]}
        onPress={touchableRowPress}
      >
        {children}
      </TouchableOpacity>
    ) : (
      <View
        accessibilityLabel={a11yLabel}
        style={[
          styles.outerContainer,
          isTreatmentFullHeight && styles.fullHeightContainer,
          groupStyle,
          styles.container,
          isTreatmentGroup && styles.groupHeight,
        ]}
      >
        {children}
      </View>
    )
  },
)

GridFullRow.displayName = 'GridFullRow'

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  fullHeightContainer: {
    height: treatmentHeightLarge,
  },
  groupHeight: {
    height: treatmentGroupHeight,
  },
  outerContainer: {
    borderBottomWidth: 1,
    borderColor: Colors.borderGrey,
    flexDirection: 'row',
    height: treatmentHeight,
  },
})
