import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color: string
}

export const SvgAccount: React.FC<Props> = ({ color }) => (
  <Svg width={20} height={18}>
    <G fill={color} fillRule="nonzero">
      <Path d="M8.506 8.73c2.185 0 4.034-1.925 4.034-4.429C12.54 1.861 10.678 0 8.506 0c-2.171 0-4.033 1.887-4.02 4.327 0 2.478 1.836 4.403 4.02 4.403zM2.043 18h12.901C16.431 18 17 17.576 17 16.703c0-2.593-3.258-6.162-8.506-6.162C3.258 10.54 0 14.11 0 16.703 0 17.576.569 18 2.043 18z" />
    </G>
  </Svg>
)
