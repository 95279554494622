import React, { useMemo } from 'react'
import { timeBoxHeight } from 'components/common'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { format } from 'date-fns'
import { StyleSheet, Text, View } from 'react-native'
import { DEFAULT_DATEBOX_WIDTH } from 'src/hocs/types/time'

import { ScaleTimeFn } from './GridTimeline'
import { TimeBoxProps } from './GridBackgroundLine'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { TimesWithIsStaffedHour } from './utils/useGetStaffedHourTimes'

type Props = {
  positionInGridFn: ScaleTimeFn
  segmentsInDay: number
  timesWithIsStaffedHour: TimesWithIsStaffedHour
  timeBoxWidth: number
}

export const TimeArea: React.FC<Props> = ({
  positionInGridFn,
  timesWithIsStaffedHour,
  timeBoxWidth,
}) => {
  const { adminTimeFormat } = useAdminTimeFormat()
  return (
    <View style={styles.dayBoxContainer}>
      {timesWithIsStaffedHour.map(({ time, isStaffedHour }) => {
        return (
          <TimeBox
            date={time}
            isStaffedHour={isStaffedHour}
            key={time.toUTCString()}
            position={positionInGridFn(time)}
            timeBoxWidth={timeBoxWidth}
            timeFormat={adminTimeFormat}
          />
        )
      })}
    </View>
  )
}

TimeArea.displayName = 'TimeArea'

export const TimeBox = ({
  date,
  timeBoxWidth,
  position,
  timeFormat = 'HH:mm',
  isStaffedHour = true,
}: TimeBoxProps) => {
  const isLongTimeFormat = timeFormat.length > 5

  let hourAndMinute = format(date, timeFormat)
  hourAndMinute = useMemo(
    () => hourAndMinute.replace(/(am|pm| )/gi, ''),
    [hourAndMinute],
  )
  const meridiem = format(date, 'aaa')
  return (
    <View
      style={[
        styles.borderColor,
        styles.dateBox,
        styles.timeBox,
        // Align timeline box text left once higher (> 102px)
        timeBoxWidth > DEFAULT_DATEBOX_WIDTH * 1.7 && styles.dateBoxLarge,
        {
          left: (position ?? 0) + 1,
          width: timeBoxWidth,
          backgroundColor: isStaffedHour
            ? Colors.white
            : Colors.grid.lightPurple,
        },
      ]}
    >
      <Text selectable={false} style={styles.timeText}>
        {hourAndMinute}
      </Text>
      {isLongTimeFormat ? (
        <Text style={styles.subTimeText}>{meridiem}</Text>
      ) : null}
    </View>
  )
}

export const styles = StyleSheet.create({
  borderColor: {
    borderColor: Colors.treatment.gridBorder,
  },
  dateBox: {
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    height: timeBoxHeight,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
  },
  dateBoxLarge: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 8,
  },
  dayBoxContainer: {
    flexDirection: 'row',
  },
  timeBox: {
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  timeText: {
    color: Colors.contentSecondary,
    fontSize: 14,
    fontFamily: Fonts.semibold,
    lineHeight: 15,
  },
  subTimeText: {
    color: Colors.lightGrey3,
    fontSize: 10,
    fontFamily: Fonts.semibold,
    lineHeight: 10,
  },
})
