import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { SecondaryButton } from 'components/common'
import { FilterMultiSelect } from 'components/common/FilterMultiSelect'
import { FilterTreeSelect } from 'components/common/FilterTreeSelect'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { SelectOption } from 'components/common/TreeSelect/index.types'
import { SvgFilter } from 'components/Icons/Filter'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { GET_USERS_LIST } from 'src/components/Settings/graphql'
import { GET_DEPARTMENTS } from 'src/components/Whiteboard/graphql'
import { useOrganisation } from 'src/context/organisation'
import { UnassignedOption, useLocationTree } from 'src/hooks/useLocationTree'
import {
  getDepartments as GetDepartments,
  getDepartmentsVariables as GetDepartmentsVariables,
} from 'src/types/getDepartments'
import { getUsersList, getUsersListVariables } from 'src/types/getUsersList'
import { ApprovalStatus, OriginTypes } from 'types/globalTypes'
import { approvalStatusOptions } from './data'
import { PatientListFilterV2 } from './PatientListFilterV2'
import { DocSiteFormat } from './utils/useLocalStorageChangeFilters'
import { getVetVetTechFromUsers } from 'components/Patient/utils/patientListFilter.utils'

type Props = {
  selectedDepartments: string[]
  onSelectDepartment: (val: string[]) => void
  selectedLocations: string[]
  onSelectLocation: (val: string[]) => void
  selectedVets: string[]
  onSelectVet: (val: string[]) => void
  selectedVetTechs: string[]
  onSelectVetTech: (val: string[]) => void
  selectedApprovalStatuses: ApprovalStatus[]
  onSelectApprovalStatus: (val: ApprovalStatus[]) => void
  clearFilters: () => void
  setDoctors: (value: DocSiteFormat[]) => void
  setVetTechs: (value: DocSiteFormat[]) => void
  setDepartments: (value: DocSiteFormat[]) => void
  setLocations: (value: SelectOption<string>[]) => void
  isWhiteboardFilter?: boolean
}

enum TabIndex {
  ISCLOSED = '0',
  DOCTOR = '1',
  VET_TECH = '2',
  DEPT = '3',
  LOCATION = '4',
  APPROVAL_STATUS = '5',
}

export const PatientListFilter: React.FC<Props> = props => {
  if (!!PatientListFilterV2) {
    // @ts-expect-error - Only exits in web
    return <PatientListFilterV2 {...props} />
  }
  return <PatientListFilterV1 {...props} />
}

export const PatientListFilterV1: React.FC<Props> = React.memo(
  ({
    selectedDepartments,
    selectedLocations,
    selectedVets,
    selectedVetTechs,
    selectedApprovalStatuses,
    onSelectDepartment,
    onSelectLocation,
    onSelectVet,
    onSelectVetTech,
    onSelectApprovalStatus,
    clearFilters,
    isWhiteboardFilter = false,
    setDoctors,
    setVetTechs,
    setDepartments,
    setLocations,
  }) => {
    const { t } = useTranslation()
    const [isDrawerVisible, setIsDrawerVisible] = useState(false)
    const [{ organisationId }] = useOrganisation()

    const { data: dataDepartments, loading: isLoadingDepartments } = useQuery<
      GetDepartments,
      GetDepartmentsVariables
    >(GET_DEPARTMENTS, {
      skip: !isDrawerVisible,
      variables: {
        organisationId,
      },
    })

    const { data: dataVetsVetTechs, loading: isLoadingVetsVetTechs } = useQuery<
      getUsersList,
      getUsersListVariables
    >(GET_USERS_LIST, {
      fetchPolicy: 'cache-and-network', // see comment on GET_USERS_LIST
      skip: !isDrawerVisible,
      variables: {
        organisation_id: organisationId,
        origin_type: OriginTypes.EZYVET, // only getting ezyvetUsers
      },
    })

    const toggleDrawer = useCallback(
      () => setIsDrawerVisible(isVisible => !isVisible),
      [],
    )

    const departmentOptions = useMemo(() => {
      const dataDepartmentOptions =
        dataDepartments?.getSites?.items?.map(dept => ({
          value: dept.id,
          text: dept.name,
        })) ?? []
      if (isWhiteboardFilter) return dataDepartmentOptions
      return [UnassignedOption, ...dataDepartmentOptions]
    }, [dataDepartments, isWhiteboardFilter])

    useEffect(() => {
      setDepartments(departmentOptions)
    }, [departmentOptions, setDepartments])

    const { locationOptions, loading: isLoadingLocations } = useLocationTree(
      !isWhiteboardFilter,
      !isDrawerVisible,
    )

    useEffect(() => {
      setLocations(locationOptions)
    }, [locationOptions, setLocations])

    const [vetOptions, vetTechOptions] = useMemo(() => {
      const users = dataVetsVetTechs?.getUsersList?.items ?? []
      const { vets, vetTechs } = getVetVetTechFromUsers(users)
      const compareFn = (a: { text: string }, b: { text: string }) =>
        a.text.localeCompare(b.text)
      return [
        [UnassignedOption, ...vets.sort(compareFn)],
        [UnassignedOption, ...vetTechs.sort(compareFn)],
      ]
    }, [dataVetsVetTechs?.getUsersList?.items])

    useEffect(() => {
      setDoctors(vetOptions)
      setVetTechs(vetTechOptions)
    }, [setDoctors, setVetTechs, vetOptions, vetTechOptions])

    const [selectedAccordionItem, setSelectedAccordionItem] =
      useState<TabIndex>(TabIndex.ISCLOSED)

    const toggleAccordion = (idx: TabIndex): void => {
      selectedAccordionItem === idx
        ? setSelectedAccordionItem(TabIndex.ISCLOSED)
        : setSelectedAccordionItem(idx)
    }

    return (
      <>
        <FilterPatientsToggle onPress={toggleDrawer} />
        <SheetAwareSideDrawer visible={isDrawerVisible} onClose={toggleDrawer}>
          <View
            style={[
              styles.sideDrawerInnerContainer,
              { flexDirection: 'column' },
            ]}
          >
            <Text style={styles.filterTitle}>
              {t('patient:list.filterTitle')}
            </Text>
            <View style={styles.clearBtn}>
              <SecondaryButton onPress={clearFilters} title="Clear filters" />
            </View>

            {!isWhiteboardFilter ? (
              <>
                <FilterMultiSelect
                  label={t('patient:list.vet')}
                  onChange={onSelectVet}
                  options={vetOptions}
                  selected={selectedVets}
                  loading={isLoadingVetsVetTechs}
                  style={styles.selector}
                  toggle={() => toggleAccordion(TabIndex.DOCTOR)}
                  isOpen={selectedAccordionItem === TabIndex.DOCTOR}
                />
                <FilterMultiSelect
                  label={t('patient:list.vetTech')}
                  onChange={onSelectVetTech}
                  options={vetTechOptions}
                  selected={selectedVetTechs}
                  loading={isLoadingVetsVetTechs}
                  style={styles.selector}
                  toggle={() => toggleAccordion(TabIndex.VET_TECH)}
                  isOpen={selectedAccordionItem === TabIndex.VET_TECH}
                />
              </>
            ) : null}
            <FilterMultiSelect
              label={t('whiteboard:filter.departmentFilterPlaceholder')}
              onChange={onSelectDepartment}
              options={departmentOptions}
              selected={selectedDepartments}
              loading={isLoadingDepartments}
              style={styles.selector}
              toggle={() => toggleAccordion(TabIndex.DEPT)}
              isOpen={selectedAccordionItem === TabIndex.DEPT}
            />
            <FilterTreeSelect
              onChange={onSelectLocation}
              options={locationOptions}
              label={t('whiteboard:filter.locationFilterPlaceholder')}
              loading={isLoadingLocations}
              selected={selectedLocations}
              toggle={() => toggleAccordion(TabIndex.LOCATION)}
              isOpen={selectedAccordionItem === TabIndex.LOCATION}
            />
            <FilterTreeSelect
              onChange={onSelectApprovalStatus}
              options={approvalStatusOptions}
              label={t('whiteboard:filter.approvalStatusFilterPlaceholder')}
              selected={selectedApprovalStatuses}
              toggle={() => toggleAccordion(TabIndex.APPROVAL_STATUS)}
              isOpen={selectedAccordionItem === TabIndex.APPROVAL_STATUS}
            />
          </View>
        </SheetAwareSideDrawer>
      </>
    )
  },
)

PatientListFilter.displayName = 'PatientListFilter'

const FilterPatientsToggle: React.FC<{ onPress: () => void }> = React.memo(
  ({ onPress }) => {
    const { t } = useTranslation()
    return (
      <View style={styles.container}>
        <TouchableOpacity
          accessibilityLabel={t('patient:list.filter')}
          onPress={onPress}
          style={mergedStyles.drawerToggle}
          testID="Filter patients toggle"
        >
          <SvgFilter />
          <Text style={styles.filterText}>{t('patient:list.filter')}</Text>
        </TouchableOpacity>
      </View>
    )
  },
)

FilterPatientsToggle.displayName = 'FilterPatientsToggle'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  selector: {
    height: 44,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Colors.buttons.listFilters,
    // use specific rules to overwrite inner style
    borderBottomWidth: 2,
    borderBottomColor: Colors.buttons.listFilters,
    borderLeftWidth: 2,
    borderLeftColor: Colors.buttons.listFilters,
    backgroundColor: Colors.backgroundGrey,
    paddingLeft: 8,
    paddingRight: 8,
  },
  filter: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterText: {
    marginLeft: 5,
    marginRight: 2,
    fontFamily: Fonts.semibold,
  },
  clearBtn: {
    marginHorizontal: 16,
    paddingBottom: 10,
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  sideDrawerInnerContainer: {
    flex: 1,
  },
  filterTitle: {
    textAlign: 'center',
    fontFamily: Fonts.bold,
    fontSize: 24,
    paddingTop: 24,
  },
})

const mergedStyles = { drawerToggle: [styles.selector, styles.filter] }
