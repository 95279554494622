import React, { useCallback } from 'react'
import { Select } from 'components/common'
import { Colors } from 'constants/Colors'
import { StyleSheet, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Fonts } from 'constants/constants'

export enum SortOptionsEnum {
  SortByNameAsc = 'Name A-Z',
  SortByNameDesc = 'Name Z-A',
  SortByOrderAsc = 'Order 1-9',
  SortByOrderDesc = 'Order 9-1',
  SortByColorAsc = 'Color 1-9',
  SortByStartAtAsc = 'StartAt Asc',
  SortByStartAtDesc = 'StartAt Desc',
}

const sortOptionsTranslationKeys: { [key in SortOptionsEnum]: string } = {
  [SortOptionsEnum.SortByNameAsc]: 'nameAsc',
  [SortOptionsEnum.SortByNameDesc]: 'nameDesc',
  [SortOptionsEnum.SortByOrderAsc]: 'orderAsc',
  [SortOptionsEnum.SortByOrderDesc]: 'orderDesc',
  [SortOptionsEnum.SortByColorAsc]: 'colorAsc',
  [SortOptionsEnum.SortByStartAtAsc]: 'startAtAsc',
  [SortOptionsEnum.SortByStartAtDesc]: 'startAtDesc',
}

export enum SortIconOptionsEnum {
  SortByNameAsc = 'sort-alpha-down',
  SortByNameDesc = 'sort-alpha-down-alt',
  SortByOrderAsc = 'sort-numeric-down',
  SortByOrderDesc = 'sort-numeric-down-alt',
  SortByColorAsc = 'sort',
}

export const SortOptionsText = {
  ...SortOptionsEnum,
}

export type SortOptionsTextType = SortOptionsEnum

export const getPickerIconName = (pickerLabel: SortOptionsEnum) => {
  switch (pickerLabel) {
    case SortOptionsEnum.SortByNameAsc:
      return SortIconOptionsEnum.SortByNameAsc
    case SortOptionsEnum.SortByNameDesc:
      return SortIconOptionsEnum.SortByNameDesc
    case SortOptionsEnum.SortByOrderAsc:
      return SortIconOptionsEnum.SortByOrderAsc
    case SortOptionsEnum.SortByOrderDesc:
      return SortIconOptionsEnum.SortByOrderDesc
    case SortOptionsEnum.SortByColorAsc:
      return SortIconOptionsEnum.SortByColorAsc
    default:
      return null
  }
}

type Props = {
  selectedSort: SortOptionsTextType
  onSelectSort: (value: SortOptionsTextType) => void
}

// TODO: The style in iOS for Select component is not correct
export const PatientListSort: React.FC<Props> = React.memo(
  ({ selectedSort = SortOptionsText.SortByNameAsc, onSelectSort }) => {
    const { t } = useTranslation()
    const SortOptions = Object.values(SortOptionsText).map(value => {
      const displayLabelKey = sortOptionsTranslationKeys[value]
      const displayLabel = t(`patient:sorting.${displayLabelKey}`)
      return {
        text: displayLabel,
        value,
      }
    })

    // This will be used for Web App (since IOS SelectItem cannot be customised)
    const renderSelectItemAndTitle = useCallback((text: string) => {
      return (
        <View style={styles.selectItemContainer}>
          <View style={styles.selectItemWrapper}>
            <Text style={styles.selectItemText}>{text}</Text>
          </View>
        </View>
      )
    }, [])

    return (
      <Select
        onChange={onSelectSort}
        options={SortOptions}
        selected={selectedSort}
        style={styles.selector}
        hideChevron={true}
        renderSelectItem={renderSelectItemAndTitle}
        renderSelectTitle={renderSelectItemAndTitle}
        selectItemStyle={styles.selectItemStyle}
      />
    )
  },
)

PatientListSort.displayName = 'PatientListSort'

const styles = StyleSheet.create({
  selector: {
    height: 44,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Colors.buttons.listFilters,
    borderBottomWidth: 2,
    borderBottomColor: Colors.buttons.listFilters,
    borderLeftWidth: 2,
    borderLeftColor: Colors.buttons.listFilters,
    backgroundColor: Colors.backgroundGrey,
    paddingHorizontal: 8,
    marginHorizontal: 8,
    paddingRight: 12,
  },
  selectItemText: {
    fontWeight: '600',
    fontSize: 14,
  },
  selectItemStyle: {
    fontFamily: Fonts.regular,
  },
  selectItemContainer: {
    width: 60,
    alignItems: 'center',
  },
  selectItemWrapper: {
    flexDirection: 'row',
  },
  wordToStyle: {
    fontWeight: 'normal',
    fontSize: 14,
  },
})
