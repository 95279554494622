import { TextStyle } from 'react-native'
import {
  FontFamilies,
  FontSizes,
  FontWeights,
  LineHeightMultiplier,
} from './base'

/**
 * These Styles are defined using React Native CSS names
 * They can be added to a style in a component by spreading the var 
 * e.g. 
  
 Stylesheet.create({ 
    yourTextElement:{
      ...Typography.Heading.L,
      color: Colors.Contents.primary,
      otherStyles: etc, 
    }
  })
 */

/**
 * PLEASE
 * Keep in sync with vet radar design system, do not add extra styles here unless in design system.
 * Special cases should be overwritten in the special case component
 */

interface TextStyles {
  [key: string]: TextStyle
}

const Heading: TextStyles = {
  XL: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.xxl,
    lineHeight: FontSizes.xxl * LineHeightMultiplier.lg,
  },

  L: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.xl,
    lineHeight: FontSizes.xl * LineHeightMultiplier.lg,
  },

  M: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.lg,
    lineHeight: FontSizes.lg * LineHeightMultiplier.lg,
  },

  S: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.md,
    lineHeight: FontSizes.md * LineHeightMultiplier.lg,
  },
}

const SubHeading: TextStyles = {
  L: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.md,
    lineHeight: FontSizes.md * LineHeightMultiplier.lg,
  },

  M: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.base,
    lineHeight: FontSizes.base * LineHeightMultiplier.lg,
  },

  S: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.sm,
    lineHeight: FontSizes.sm * LineHeightMultiplier.lg,
  },
}

const Paragraph: TextStyles = {
  L: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.md,
    lineHeight: FontSizes.md * LineHeightMultiplier.lg,
  },

  M: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.base,
    lineHeight: FontSizes.base * LineHeightMultiplier.lg,
  },

  S: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.sm,
    lineHeight: FontSizes.sm * LineHeightMultiplier.lg,
  },
}

/**
 * For Bold versions of Labels, overwrite the font weight
 * e.g.
    yourLabel:{
      ...Typography.Label.L,
      fontWeight: FontWeights.bold,
    }
 */
const Label: TextStyles = {
  L: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.md,
  },

  M: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.base,
  },

  S: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.sm,
  },
}

export const Typography = {
  // Base Variables
  FontFamilies,
  FontSizes,
  FontWeights,
  LineHeightMultiplier,
  // Typography Styles
  Heading,
  SubHeading,
  Paragraph,
  Label,
}
