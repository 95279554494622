import { addMinutes, differenceInMinutes, startOfMinute } from 'date-fns'

import { FluidAction, Task } from '../types'

type GetTaskGivenTimesParams = {
  formTimeGiven?: Date
  isBackdate?: boolean
  originalTask: Task
}

export const getTaskGivenTimes = ({
  formTimeGiven,
  isBackdate,
  originalTask,
}: GetTaskGivenTimesParams) => {
  const {
    given_start_at: originalGivenStartAt,
    given_stop_at: originalGivenStopAt,
  } = originalTask

  const originalStartAndStop = {
    givenStartAt: originalGivenStartAt,
    givenStopAt: originalGivenStopAt,
  }

  if (isBackdate) {
    if (!originalTask.start_at || !originalTask.stop_at || !formTimeGiven) {
      return originalStartAndStop
    }

    const backdateGivenStopAt = addMinutes(
      formTimeGiven,
      differenceInMinutes(
        Date.parse(originalTask.stop_at),
        Date.parse(originalTask.start_at),
      ),
    ).toISOString()

    return {
      givenStartAt: formTimeGiven.toISOString(),
      givenStopAt: backdateGivenStopAt,
    }
  }

  return originalStartAndStop
}

type GetFluidTaskGivenTimes = (params: {
  action: FluidAction
  backdateTimeGiven?: Date | null
  originalTask: Task
}) => Pick<Task, 'given_start_at' | 'given_stop_at'>

export const getFluidTaskGivenTimes: GetFluidTaskGivenTimes = ({
  action,
  backdateTimeGiven,
  originalTask,
}) => {
  switch (action) {
    // Starting Fluid. Set start now, stop to null
    case FluidAction.START:
      return {
        given_start_at: backdateTimeGiven
          ? startOfMinute(backdateTimeGiven).toISOString()
          : originalTask.start_at,
        given_stop_at: null,
      }

    // Stop / Finishing Fluid. Set stop to now, keep start
    case FluidAction.STOP: {
      let selectedStopTime = backdateTimeGiven ?? new Date()
      const originalStartTime = originalTask.given_start_at
        ? new Date(originalTask.given_start_at)
        : startOfMinute(new Date())
      if (selectedStopTime <= originalStartTime) {
        selectedStopTime = addMinutes(originalStartTime, 1)
      }

      return {
        given_start_at: originalTask.given_start_at,
        given_stop_at: startOfMinute(selectedStopTime).toISOString(),
      }
    }
    default:
      return {
        given_start_at: originalTask.start_at,
        given_stop_at: originalTask.stop_at,
      }
  }
}
