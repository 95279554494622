import React from 'react'
import { RectButton } from 'react-native-gesture-handler'
import { StyleSheet, Text, View } from 'react-native'
import { Actions } from '../MultiActions'
import { Colors } from 'constants/Colors'

type SwipeableRectButtonType = {
  name: Actions
  onAction: () => void
  onClose: () => void
  disabled?: boolean
}

const buttonColorMap = {
  [Actions.DELETE]: Colors.errorBackground,
  [Actions.EXCLUDE]: Colors.blue,
  [Actions.CLEAR]: Colors.errorBackground,
  [Actions.DUPLICATE]: Colors.blue,
  [Actions.RENAME]: Colors.blue,
}

export const SwipeableRectButton = ({
  name,
  onAction,
  onClose,
  disabled = false,
}: SwipeableRectButtonType) => {
  return (
    <View style={styles.actionView} key={name}>
      <RectButton
        style={[
          styles.actionButton,
          {
            backgroundColor: buttonColorMap[name],
            opacity: disabled ? 0.5 : 1,
          },
        ]}
        onPress={() => {
          if (!disabled) {
            onAction()
            onClose()
          }
        }}
      >
        <Text style={styles.actionText}>{name}</Text>
      </RectButton>
    </View>
  )
}

const styles = StyleSheet.create({
  actionView: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  actionButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    paddingHorizontal: 15,
    paddingVertical: 12,
  },
  actionText: {
    color: Colors.white,
    fontWeight: '600',
  },
})
