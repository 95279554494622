import { isObject } from 'lodash'

export const omitDeep = <T extends { [key: string]: any }>(
  obj: T,
  key: string,
): any => {
  if (!isObject(obj)) {
    return obj
  }
  if (Array.isArray(obj)) {
    return obj.map(v => omitDeep(v, key))
  }
  const keys = Object.keys(obj)
  const newObj = {} as T
  keys.forEach(i => {
    if (i !== key) {
      const val = obj[i]
      ;(newObj[i] as T) = omitDeep(val, key)
    }
  })
  return newObj
}
