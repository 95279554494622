import React, { useMemo, useState } from 'react'
import { treatmentHeightLarge } from 'components/common'
import { Tick } from 'components/common/Chart/types'
import { Colors } from 'constants/Colors'
import { scaleLinear, scaleTime } from 'd3-scale'
import { groupBy } from 'lodash'
import {
  GestureResponderEvent,
  Platform,
  TouchableOpacity,
  View,
} from 'react-native'
import Svg, { G, Line } from 'react-native-svg'
import { useAnesthesiaContext } from 'src/context/anesthesia'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useTimeResolution } from 'src/hocs/timeContext'

import { PressureRowChartEntry } from './PressureRowChartEntry'
import { RowChartEntry } from './RowChartEntry'
import { getPressureRowChartPoints } from './utils/getPressureRowChartPoints'

const TICKS_PER_ROW = 2 // return two lines for every row
const chartSettings = {
  marginHorizontal: 0,
  marginVertical: 0,
}

type Props = { timeInChart: (x: number) => void }

export const Chart: React.FC<Props> = ({ timeInChart }) => {
  const { visibleDayRange } = useTimeResolution()
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const [{ anesthesiaData, anesthesiaChartRange }] = useAnesthesiaContext()
  const [pixelWidth, setPixelWidth] = useState(0)

  const isMobileScreen = isExSmallScreen || isSmallScreen

  const groupedEntries = groupBy(anesthesiaData, ({ group_name }) =>
    group_name ? group_name : 'none',
  )

  const numberOfEntries = anesthesiaData?.length ?? 0
  const anesthesiaDataEntries = groupedEntries.none ?? []
  const pressureRowChartPoints = useMemo(
    () => getPressureRowChartPoints(groupedEntries.pressure ?? []),
    [groupedEntries.pressure],
  )

  const pixelHeight = numberOfEntries * treatmentHeightLarge

  const boundedWidth = pixelWidth - chartSettings.marginHorizontal
  const boundedHeight = pixelHeight - chartSettings.marginVertical

  const viewBox = `0 0 ${pixelWidth} ${pixelHeight}`

  const xScale = scaleTime().domain(visibleDayRange).range([0, boundedWidth])

  const yScale = scaleLinear()
    /* It initially seems strange using the chart values range as the yScale
       domain, but this is correct */
    .domain(anesthesiaChartRange)
    .range([boundedHeight, 0])

  const yTicks = yScale
    .ticks(numberOfEntries * TICKS_PER_ROW)
    .map(value => {
      return {
        value,
        offset: yScale(value) ?? 0,
      }
    })
    .slice(1, -1) // skip first and last axis lines

  const getTouchCoordinates = (event: any) => {
    // evt.nativeEvent.locationX get undefined in expo sdk39 web
    let locationX: number
    if (Platform.OS === 'web') {
      locationX = (event.nativeEvent as MouseEvent).offsetX
    } else {
      locationX = (event as GestureResponderEvent).nativeEvent.locationX
    }
    if (locationX) {
      timeInChart(locationX)
    }
  }

  if (!anesthesiaData) return null
  return (
    <View
      accessibilityLabel="Anesthesia Chart"
      onLayout={({
        nativeEvent: {
          layout: { width },
        },
      }) => {
        setPixelWidth(width)
      }}
      style={{ height: pixelHeight }}
    >
      {!pixelWidth ? null : (
        <TouchableOpacity activeOpacity={1} onPress={getTouchCoordinates}>
          <Svg
            accessibilityLabel="Anesthesia Graph SVG Chart"
            fill="white"
            height={pixelHeight}
            width={pixelWidth}
            viewBox={viewBox}
          >
            <G
              // add some offset for the bounded area's margins
              // we can use zero values to equal == offset after this
              x={chartSettings.marginHorizontal}
              y={chartSettings.marginVertical}
              stroke={Colors.lightGrey}
            >
              <YAxis ticks={yTicks} boundedWidth={boundedWidth} />
              {pressureRowChartPoints.map(
                ({ entry, symbolShape, showText, title }) =>
                  entry.map(({ currentPoint, mapPointAndDapPoint }) => (
                    <PressureRowChartEntry
                      key={`${title}Text${currentPoint.time}`}
                      currentPoint={currentPoint}
                      isMobileScreen={isMobileScreen}
                      mapPointAndDapPoint={mapPointAndDapPoint}
                      showText={showText}
                      symbolShape={symbolShape}
                      title={title}
                      xScale={xScale}
                      yScale={yScale}
                    />
                  )),
              )}
              {anesthesiaDataEntries.map(
                ({
                  color,
                  data,
                  show_text: showText,
                  symbol_shape: symbolShape,
                  title,
                }) => (
                  <RowChartEntry
                    key={`${title}Fragment`}
                    color={color}
                    data={data}
                    isMobileScreen={isMobileScreen}
                    showText={showText}
                    symbolShape={symbolShape}
                    title={title}
                    xScale={xScale}
                    yScale={yScale}
                  />
                ),
              )}
            </G>
          </Svg>
        </TouchableOpacity>
      )}
    </View>
  )
}

type YAxisProps = { ticks: Tick<number>[]; boundedWidth: number }
const YAxis = ({ ticks, boundedWidth }: YAxisProps) => {
  return (
    <G>
      {ticks.map(({ value, offset }) => {
        return (
          <G key={value} y={offset} x={0}>
            <Line y1={-0.5} y2={-0.5} x1={-20} x2={boundedWidth} />
          </G>
        )
      })}
    </G>
  )
}
