import { client } from 'src/apollo/client'
import {
  GET_FILE_UPLOAD_PART_SIGNED_URL,
  GET_FILE_UPLOAD_SIGNED_URL,
  START_FILE_MULTI_PART_UPLOAD,
  COMPLETE_FILE_MULTI_PART_UPLOAD,
  GET_FILE_DOWNLOAD_SIGNED_URL,
} from 'src/utils/file/graphql'
import {
  getFileUploadSignedUrl as GetFileUploadSignedUrl,
  getFileUploadSignedUrlVariables as GetFileUploadSignedUrlVariables,
} from 'types/getFileUploadSignedUrl'
import {
  getFileUploadPartSignedUrl as GetFileUploadPartSignedUrl,
  getFileUploadPartSignedUrlVariables as GetFileUploadPartSignedUrlVariables,
} from 'types/getFileUploadPartSignedUrl'
import {
  startFileMultiPartUpload as StartFileMultiPartUpload,
  startFileMultiPartUploadVariables as StartFileMultiPartUploadVariables,
} from 'types/startFileMultiPartUpload'
import {
  completeFileMultiPartUpload as CompleteFileMultiPartUpload,
  completeFileMultiPartUploadVariables as CompleteFileMultiPartUploadVariables,
} from 'types/completeFileMultiPartUpload'
import { PartInput } from 'types/globalTypes'
import {
  getFileDownloadSignedUrl as GetFileDownloadSignedUrl,
  getFileDownloadSignedUrlVariables as GetFileDownloadSignedUrlVariables,
} from 'types/getFileDownloadSignedUrl'

export const getFileUploadSignedUrl = async (key: string): Promise<string> => {
  const {
    data: {
      getFileUploadSignedUrl: { url },
    },
  } = await client.query<
    GetFileUploadSignedUrl,
    GetFileUploadSignedUrlVariables
  >({
    query: GET_FILE_UPLOAD_SIGNED_URL,
    fetchPolicy: 'network-only',
    variables: {
      key,
    },
  })

  return url
}

export const getFileUploadPartSignedUrl = async (
  key: string,
  uploadId: string,
  partNumber: number,
): Promise<string> => {
  const {
    data: {
      getFileUploadPartSignedUrl: { url },
    },
  } = await client.query<
    GetFileUploadPartSignedUrl,
    GetFileUploadPartSignedUrlVariables
  >({
    query: GET_FILE_UPLOAD_PART_SIGNED_URL,
    fetchPolicy: 'network-only',
    variables: {
      key,
      upload_id: uploadId,
      part_number: partNumber,
    },
  })

  return url
}

export const startFileMultiPartUpload = async (
  key: string,
  contentType: string,
): Promise<string> => {
  const { data } = await client.mutate<
    StartFileMultiPartUpload,
    StartFileMultiPartUploadVariables
  >({
    mutation: START_FILE_MULTI_PART_UPLOAD,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        key,
        content_type: contentType,
      },
    },
  })

  const uploadId = data?.startFileMultiPartUpload.upload_id

  if (!uploadId) {
    throw new Error('Failed to start upload')
  }

  return uploadId
}

export const completeFileMultiPartUpload = async (
  key: string,
  uploadId: string,
  parts: PartInput[],
): Promise<void> => {
  await client.mutate<
    CompleteFileMultiPartUpload,
    CompleteFileMultiPartUploadVariables
  >({
    mutation: COMPLETE_FILE_MULTI_PART_UPLOAD,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        key,
        parts,
        upload_id: uploadId,
      },
    },
  })
}

export const getFileDownloadSignedUrl = async (
  key: string,
  fileName: string,
  isAttachment: boolean,
): Promise<string> => {
  const {
    data: {
      getFileDownloadSignedUrl: { url },
    },
  } = await client.query<
    GetFileDownloadSignedUrl,
    GetFileDownloadSignedUrlVariables
  >({
    query: GET_FILE_DOWNLOAD_SIGNED_URL,
    fetchPolicy: 'network-only',
    variables: {
      key,
      file_name: fileName,
      is_attachment: isAttachment,
    },
  })

  return url
}
