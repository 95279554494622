import {
  PossibleSelectValues,
  SelectOption,
} from 'components/common/Select/Select.types'
import { Select } from 'components/common'
import React, { useMemo } from 'react'
import { useGetWorkflowTemplates } from 'src/utils/useGetWorkflowTemplates'
import { useTranslation } from 'react-i18next'

type Props = {
  onChange: (option: PossibleSelectValues) => void
  selected?: string | null
  disabled?: boolean
}

export const WorkflowTemplateSection: React.FC<Props> = ({
  onChange,
  selected,
  disabled,
}) => {
  const { t } = useTranslation()
  const { workflowTemplatesList, isLoadingTemplates } =
    useGetWorkflowTemplates()

  const options: SelectOption<string>[] = useMemo(() => {
    return (
      workflowTemplatesList.map(({ id, name }) => ({
        value: id,
        text: name,
      })) ?? []
    )
  }, [workflowTemplatesList])

  return (
    <Select
      label={t('addTreatment:addTemplateToSheet.workflowTitle')}
      onChange={onChange}
      allowClear={true}
      options={options}
      selected={selected}
      loading={isLoadingTemplates}
      disabled={disabled}
    />
  )
}
