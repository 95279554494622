import React from 'react'
import {
  Text,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
  Pressable,
} from 'react-native'
import { Colors, Typography, Variables } from 'src/design-system/theme'

type Props = {
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  title: string
  onPress: () => void
  disabled?: boolean
}

export const HelpModalButton: React.FC<Props> = ({
  style,
  title,
  textStyle,
  onPress,
  disabled,
}) => {
  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.button,
        style,
        disabled && {
          opacity: 0.36,
        },
      ]}
    >
      <Text style={[styles.buttonText, textStyle]}>{title}</Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 4,
    paddingVertical: Variables.Size.md,
  },
  buttonText: {
    textAlign: 'center',
    color: Colors.Contents.onColor,
    fontWeight: Typography.FontWeights.bold,
    fontSize: Typography.FontSizes.base,
    lineHeight: 16,
  },
})
