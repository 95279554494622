import React from 'react'
import { Fonts } from 'constants/Fonts'
import { subHours } from 'date-fns'
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native'

import { VitalsGraph } from './VitalsGraph'
import { parseAndFilterGraphData } from './utils'
import { useTranslation } from 'react-i18next'
import { getPatientVitals_getPatientVitals_items as VitalsItem } from 'src/types/getPatientVitals'
import { Colors } from 'constants/Colors'

type TitleProps = {
  forceGraphLayout?: boolean
  hourLimit: 24 | 48
  title: string
  titleUnit?: string | null
}

type Props = TitleProps & { data: VitalsItem[]; loading?: boolean }

const Loading = () => (
  <View accessibilityLabel="Vitals Chart Loading" style={styles.emptySpace}>
    <ActivityIndicator size="large" />
  </View>
)

const NoDataFound = ({ title }: { title: string }) => {
  const { t } = useTranslation()
  return (
    <View style={styles.emptySpace}>
      <Text style={styles.textTitleUnit}>{t('vitals:noData', { title })}</Text>
    </View>
  )
}

const VitalsGraphTitle: React.FC<TitleProps> = ({
  hourLimit,
  title,
  titleUnit,
}) => {
  return (
    <View style={styles.padding} accessibilityLabel={`${title} Vitals Area`}>
      <View style={styles.titleAlign}>
        <Text style={styles.textTitle}>{title}</Text>
        {!!titleUnit && (
          <Text style={styles.textTitleUnit}>{`(${titleUnit})`}</Text>
        )}
        <Text style={styles.textHourLimit}>{`Past ${hourLimit} hours`}</Text>
      </View>
    </View>
  )
}

export const VitalsArea: React.FC<Props> = ({
  data,
  forceGraphLayout,
  hourLimit,
  loading = false,
  title,
  titleUnit,
}) => {
  const timeEnd = new Date()
  const timeStart = subHours(timeEnd, hourLimit)
  const parsedData = parseAndFilterGraphData(data)

  return (
    <>
      <VitalsGraphTitle
        hourLimit={hourLimit}
        title={title}
        titleUnit={titleUnit}
      />
      {loading ? (
        <Loading />
      ) : parsedData.length === 0 ? (
        <NoDataFound title={title} />
      ) : (
        <VitalsGraph
          data={parsedData}
          forceGraphLayout={forceGraphLayout}
          timeEnd={timeEnd}
          timeStart={timeStart}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  emptySpace: {
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 300,
    backgroundColor: Colors.white,
  },
  padding: {
    paddingTop: 8,
    paddingHorizontal: 16,
    backgroundColor: Colors.white,
  },
  textHourLimit: {
    fontFamily: Fonts.regular,
    fontSize: 11,
    letterSpacing: 0.8,
    marginBottom: 6, // bring in line with bottom of title text
    paddingLeft: 4,
  },
  textTitle: {
    fontFamily: Fonts.bold,
    fontSize: 24,
    letterSpacing: 0.8,
  },
  textTitleUnit: {
    fontFamily: Fonts.regular,
    fontSize: 14,
    letterSpacing: 1,
    marginBottom: 4, // bring in line with bottom of title text
    paddingLeft: 4,
  },
  titleAlign: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: 4,
    justifyContent: 'flex-start',
  },
})
