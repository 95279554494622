import { FeatureFlagNames } from 'constants/FeatureFlags'
import flagsmith from 'react-native-flagsmith'
import { environment } from 'src/config'
import { ReleaseStage } from 'src/config/environment'
import { getOrganisation_getOrganisation } from 'src/types/getOrganisation'
import { getUser_getUser } from 'src/types/getUser'

export const WalkMe = {
  isAllowed: () => {
    const isDev = environment.releaseStage === ReleaseStage.localDev
    const isFlagAllowed = flagsmith.hasFeature(FeatureFlagNames.DisplayWalkMe)
    return environment.isWeb && isFlagAllowed && !isDev
  },
  // @ts-ignore
  isLoaded: () => !!window._walkmeConfig,
  load: () => {
    if (!WalkMe.isAllowed() || WalkMe.isLoaded()) {
      return
    }

    const walkmeScript = document.createElement('script')
    walkmeScript.type = 'text/javascript'
    walkmeScript.async = true
    walkmeScript.src = environment.walkmeURL
    document.head.appendChild(walkmeScript)
    // @ts-ignore
    window._walkmeConfig = { smartLoad: true }
  },
  identifyUser(
    user: getUser_getUser,
    organisation: getOrganisation_getOrganisation,
  ) {
    const name = organisation.is_training
      ? organisation.name.replace('Training - ', '')
      : organisation.name
    // set window._walkmeUser.userId etc. to match walkMe settings vars
    // @ts-ignore
    window._walkmeUser = {
      userId: user.email,
      userRole: user.veterinary_role,
      userType: user.role,
      siteName: name,
      info: {
        organisationId: organisation.id,
        is_training: organisation.is_training,
      },
    }
  },
}
