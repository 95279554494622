import React, { useEffect, useState } from 'react'
import { useRoute } from '@react-navigation/native'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { Tabs } from 'components/common/Tabs/Tabs'
import { IVInfusionForm } from 'components/IVInfusionForm'
import { SheetScreenRouteProp } from 'components/Sheet/Sheet'
import { useTranslation } from 'react-i18next'
import { useTreatmentDrawerContext } from 'src/context/treatmentDrawer'

import { AddTreatmentDrawerContent } from './AddTreatmentDrawerContent'
import { QuickAddTreatmentDrawerContent } from './QuickAddTreatmentDrawerContent'

type Props = {
  isVisible: boolean
  isQuickAdd?: boolean
  toggleDrawer: () => void
}

export enum TreatmentAction {
  AddTreatment = 'AddTreatment',
  AddIVInfusion = 'AddIVInfusion',
}

export const AddTreatmentDrawer: React.FC<Props> = ({
  isVisible,
  toggleDrawer,
  isQuickAdd = false,
}) => {
  const { t } = useTranslation()
  const [currentTab, setCurrentTab] = useState<TreatmentAction>(
    TreatmentAction.AddTreatment,
  )

  useEffect(() => {
    if (isVisible) {
      setCurrentTab(TreatmentAction.AddTreatment)
    }
  }, [isVisible])

  const [{ shouldShowTabs }] = useTreatmentDrawerContext()

  const {
    params: { patientId, sheetId },
  } = useRoute<SheetScreenRouteProp>()

  const treatmentOptions = [
    {
      value: TreatmentAction.AddTreatment,
      text: t('addTreatment:treatment'),
    },
    {
      value: TreatmentAction.AddIVInfusion,
      text: t('addTreatment:ivInfusion'),
    },
  ]

  return (
    <SheetAwareSideDrawer
      visible={isVisible}
      onClose={toggleDrawer}
      title={t('addTreatment:addTreatment')}
      back={{
        text: t('addTreatment:addTreatment'),
      }}
    >
      {shouldShowTabs ? (
        <Tabs
          options={treatmentOptions}
          onPressHandler={(tab: TreatmentAction) => {
            setCurrentTab(tab)
          }}
          selected={currentTab}
        />
      ) : null}

      {currentTab === TreatmentAction.AddTreatment ? (
        <>
          {isQuickAdd ? (
            <QuickAddTreatmentDrawerContent
              sheetId={sheetId}
              patientId={patientId}
              onDone={toggleDrawer}
            />
          ) : (
            <AddTreatmentDrawerContent
              sheetId={sheetId}
              patientId={patientId}
              onDone={toggleDrawer}
            />
          )}
        </>
      ) : (
        <IVInfusionForm
          patientId={patientId}
          sheetId={sheetId}
          onDone={toggleDrawer}
        />
      )}
    </SheetAwareSideDrawer>
  )
}
