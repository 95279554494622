import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'components/common'
import { startOfMinute } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { usePatientById } from 'src/hooks/usePatient'
import { useSetShowTreatmentTabs } from 'src/hooks/useSetShowTreatmentTabs'
import {
  addBundleToSheet,
  addBundleToSheetVariables,
} from 'src/types/addBundleToSheet'
import { useUpdateTreatments } from 'src/utils/useUpdateTreatments'

import { Product } from '../ProductRow'
import { AddBundleForm, FormDataBundle } from './AddBundleForm'
import { ADD_BUNDLE_TO_SHEET, GET_BUNDLE_PRODUCTS } from './graphql'
import { formInputToTreatmentInput } from 'components/AddTreatment/Bundles/utils/formInputToTreatmentInput'

export type AddBundleScreenProps = {
  product: Product
  onDone: () => void
  sheetId: string
  patientId: string
  handleBackOverrides: Dispatch<SetStateAction<(() => void) | null>>
}

export const AddBundleScreen: React.FC<AddBundleScreenProps> = ({
  product,
  sheetId,
  onDone,
  patientId,
  handleBackOverrides,
}) => {
  useSetShowTreatmentTabs() // This hides the tabs?! Refactor if possible
  const [{ organisationId }] = useOrganisation()
  const { fromToQueryDate } = useTimeResolution()
  const patient = usePatientById(patientId)
  const updateTreatments = useUpdateTreatments(sheetId)
  const { t } = useTranslation()

  const { loading, data } = useQuery(GET_BUNDLE_PRODUCTS, {
    fetchPolicy: 'network-only',
    variables: {
      product_id: product.id,
      organisation_id: organisationId,
    },
  })

  const [addBundleToSheet, { loading: isSubmitting }] = useMutation<
    addBundleToSheet,
    addBundleToSheetVariables
  >(ADD_BUNDLE_TO_SHEET, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      data.addBundleToSheet.forEach(updateTreatments)
    },
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
  })

  useEffect(() => {
    if (isSubmitting) {
      const dismiss = toast.process(t('addTreatment:bundleAdding'))
      return () => dismiss()
    }
    return
  }, [isSubmitting, t])

  const onSave = ({ startTime, bundleTreatmentFormInputs }: FormDataBundle) => {
    if (!bundleTreatmentFormInputs) return

    return addBundleToSheet({
      variables: {
        input: {
          organisation_id: organisationId,
          sheet_id: sheetId,
          bundle_id: product.id,
          start_at:
            startTime === 'now'
              ? startOfMinute(new Date()).toISOString()
              : startTime.toISOString(),
        },
        options: {
          bundle_treatments: bundleTreatmentFormInputs.map(treatmentForm =>
            formInputToTreatmentInput(
              treatmentForm,
              organisationId,
              patientId,
              sheetId,
            ),
          ),
        },
        ...fromToQueryDate,
      },
    }).finally(onDone)
  }

  if (loading || !patient) {
    return <ActivityIndicator size="large" />
  }

  return (
    <AddBundleForm
      bundle={data.getValidBundleProducts}
      patient={patient}
      sheetId={sheetId}
      handleBackOverrides={handleBackOverrides}
      onSave={onSave}
    />
  )
}
