import React, { useCallback, useMemo } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { GetSheet, GetSheetVariables } from 'components/Task/types'
import { getFlattenedTreatments } from 'components/Task/utils/getFlattenedTreatments'
import { cloneDeep, orderBy } from 'lodash'
import { StyleSheet, View } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { getSheetNotes, getSheetNotesVariables } from 'src/types/getSheetNotes'

import { GET_SHEET, GET_SHEET_NOTES } from './graphql'
import { NotesList } from './NotesList'
import { Status } from 'src/types/globalTypes'

export type AllNotesProps = {
  sheetId: string
}
type TreatmentIdsInSheet = { [treatmentId: string]: string }

export const AllNotes: React.FC<AllNotesProps> = ({ sheetId }) => {
  const [{ organisationId }] = useOrganisation()

  const { data, loading } = useQuery<getSheetNotes, getSheetNotesVariables>(
    GET_SHEET_NOTES,
    {
      variables: {
        organisationId,
        id: sheetId,
      },
      fetchPolicy: 'cache-and-network',
    },
  )
  const client = useApolloClient()

  const noteType = 'sheet'

  const { fromToQueryDate } = useTimeResolution()

  const getTreatmentIdNamePair = useCallback(() => {
    const variables = {
      id: sheetId,
      organisation_id: organisationId,
      ...fromToQueryDate,
    }
    const query = GET_SHEET
    const sheet = client.readQuery<GetSheet, GetSheetVariables>({
      query,
      variables,
    })
    const clonedData = cloneDeep(sheet)

    const treatmentIdsInSheet: TreatmentIdsInSheet = {}
    const treatmentGroups = clonedData?.getSheet?.treatments?.items

    if (!treatmentGroups) {
      return treatmentIdsInSheet
    }

    getFlattenedTreatments(treatmentGroups).forEach(treatment => {
      treatmentIdsInSheet[treatment.id] = treatment.name
    })
    return treatmentIdsInSheet
  }, [client, fromToQueryDate, organisationId, sheetId])

  const notes = useMemo(() => {
    const treatmentIdNamePair = getTreatmentIdNamePair()

    const filterOrderedTasks = orderBy(
      data?.getSheet?.tasks?.items?.filter(
        task => !!task.notes && task.status !== Status.DELETED_ON_PURPOSE,
      ),
      'start_at',
    )

    // add treatment name to notes
    return filterOrderedTasks.map(task => ({
      ...task,
      treatmentName: treatmentIdNamePair[task.treatment_id],
    }))
  }, [getTreatmentIdNamePair, data])

  const renderHeader = () => <View style={styles.commentsContainer} />

  return (
    <NotesList
      renderHeader={renderHeader}
      notes={notes}
      loading={loading}
      noteType={noteType}
    />
  )
}

const styles = StyleSheet.create({
  commentsContainer: {
    paddingTop: 10,
  },
})
