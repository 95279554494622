import React from 'react'
import Svg, { Path, Defs, G, Rect, Mask, Polygon, Use } from 'react-native-svg'

export const SvgFilter: React.FC = () => (
  <Svg height={24} width={24} viewBox="0 0 24 24">
    <Defs>
      <Path
        d="M11.5,10 C12.0522847,10 12.5,10.4477153 12.5,11 C12.5,11.5128358 12.1139598,11.9355072 11.6166211,11.9932723 L11.5,12 L8.5,12 C7.94771525,12 7.5,11.5522847 7.5,11 C7.5,10.4871642 7.88604019,10.0644928 8.38337887,10.0067277 L8.5,10 L11.5,10 Z M15.5,5 C16.0522847,5 16.5,5.44771525 16.5,6 C16.5,6.51283584 16.1139598,6.93550716 15.6166211,6.99327227 L15.5,7 L4.5,7 C3.94771525,7 3.5,6.55228475 3.5,6 C3.5,5.48716416 3.88604019,5.06449284 4.38337887,5.00672773 L4.5,5 L15.5,5 Z M18.5,0 C19.0522847,0 19.5,0.44771525 19.5,1 C19.5,1.51283584 19.1139598,1.93550716 18.6166211,1.99327227 L18.5,2 L1.5,2 C0.94771525,2 0.5,1.55228475 0.5,1 C0.5,0.487164161 0.88604019,0.0644928393 1.38337887,0.00672773133 L1.5,0 L18.5,0 Z"
        id="svg-filter-path-1"
      />
    </Defs>
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G>
        <Rect x="0" y="0" width="24" height="24" />
        <G transform="translate(2.000000, 6.000000)">
          <Mask id="svg-filter-mask-2" fill="white">
            <Use xlinkHref="#svg-filter-path-1" />
          </Mask>
          <Use
            fill="#979797"
            fillRule="nonzero"
            xlinkHref="#svg-filter-path-1"
          />
          <Polygon
            fill="#1A274C"
            mask="url(#svg-filter-mask-2)"
            points="-2 -6 22 -6 22 18 -2 18"
          />
        </G>
      </G>
    </G>
  </Svg>
)
