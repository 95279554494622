import React from 'react'
import { useQuery } from '@apollo/client'
import { sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { Select } from 'components/common'
import { SelectOption } from 'components/common/Select/Select.types'
import { GET_USERS } from 'src/components/Settings/graphql'
import { useOrganisation } from 'src/context/organisation'
import { getUsers, getUsersVariables } from 'src/types/getUsers'
import { SortDirection, veterinary_roles } from 'src/types/globalTypes'

type UserSelectOptions = SelectOption<string>

type Props = {
  disabled?: boolean
  onSelectedUserChange: any
  selectedUser: string | null | undefined
  type: veterinary_roles
}

export const OwnerSection: React.FC<Props> = ({
  onSelectedUserChange,
  type,
  selectedUser,
  disabled,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()

  const { data: usersData, loading } = useQuery<getUsers, getUsersVariables>(
    GET_USERS,
    {
      fetchPolicy: 'cache-and-network', // see comment on GET_USERS
      variables: {
        organisation_id: organisationId,
        veterinary_role: type,
        usersPageInput: {
          sortField: ['full_name'],
          sortDirection: [SortDirection.asc],
        },
      },
    },
  )

  const users = sortBy(
    usersData?.getUsers?.items?.filter(item => item.has_login) ?? [],
    'full_name',
  )

  const selectableUsers: UserSelectOptions[] = users.map(
    ({ full_name, id }) => ({
      value: id,
      text: full_name,
    }),
  )

  return (
    <View>
      <Select
        a11yLabel={`Owner Select Input`}
        label={t('template:form.owner')}
        options={selectableUsers}
        selected={selectedUser}
        onChange={onSelectedUserChange}
        loading={loading}
        allowClear={true}
        disabled={disabled}
      />
    </View>
  )
}
