import React, { useMemo, useState } from 'react'
import { PhoneLinking } from 'components/common/PhoneLinking'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import _ from 'lodash'

import { PatientInfo } from '../PatientItem'
import { FullProfileButton } from './FullProfileButton'
import { PatientHeaderStyles as styles } from './PatientHeaderStyles'
import { ShowMoreButton } from 'components/SheetHeader/ShowMoreButton'

export const SheetListPatientInfo = ({ patient }: PatientInfo) => {
  const { t } = useTranslation()
  const [showInactiveProblems, setShowInactiveProblems] = useState(true)
  const phoneNumber = patient?.contact?.phone_number

  const nonEmptyMasterProblems = useMemo(() => {
    return (
      patient.master_problems?.filter(
        problem =>
          problem.condition ||
          problem.description ||
          problem.condition_specifics,
      ) ?? []
    )
  }, [patient.master_problems])

  const [activeMasterProblems, inactiveMasterProblems] = _.partition(
    nonEmptyMasterProblems,
    ['condition_active', true],
  )

  return (
    <>
      <View style={[styles.row, styles.rowDivider]}>
        <View style={[styles.columnLeft, styles.contact]}>
          <Text style={styles.owner}>{t('patient:view.owner')}</Text>
          <Text style={styles.contactName}>{`${
            patient?.contact?.first_name ?? ''
          } ${patient?.contact?.last_name ?? ''}`}</Text>
          {!!phoneNumber && (
            <PhoneLinking phoneNumber={phoneNumber}>
              <Text style={styles.phoneNumber}>{`📞${phoneNumber}`}</Text>
            </PhoneLinking>
          )}
        </View>
        <View style={[styles.columnRight, styles.buttonContainer]}>
          <FullProfileButton patientId={patient.id!} />
        </View>
      </View>

      {!!nonEmptyMasterProblems.length && (
        <View style={styles.problemsContainer}>
          {!!activeMasterProblems.length && (
            <Text style={styles.problemTitle}>
              {t('patient:view.activeMaster')}
            </Text>
          )}
          {activeMasterProblems.map((masterProblem, idx) => (
            <View style={styles.problemContainer} key={idx}>
              <View>
                <Text style={styles.condition}>{masterProblem.condition}</Text>
                <Text style={styles.masterProblemsText}>
                  {masterProblem.description}
                </Text>
                <Text style={styles.masterProblemsText}>
                  {masterProblem.condition_specifics}
                </Text>
              </View>
            </View>
          ))}
          {!!inactiveMasterProblems.length && (
            <View
              style={[
                styles.inactiveProblemTitle,
                activeMasterProblems.length ? styles.rowTopDivider : null,
              ]}
            >
              <Text style={styles.problemTitle}>
                {t('patient:view.inactiveMaster')}
              </Text>

              <ShowMoreButton
                onPressShowMore={() => {
                  setShowInactiveProblems(!showInactiveProblems)
                }}
                showMoreDetails={showInactiveProblems}
              />
            </View>
          )}
          {showInactiveProblems
            ? inactiveMasterProblems.map((masterProblem, idx) => (
                <View style={styles.problemContainer} key={idx}>
                  <View>
                    <Text style={styles.condition}>
                      {masterProblem.condition}
                    </Text>
                    <Text style={styles.masterProblemsText}>
                      {masterProblem.description}
                    </Text>
                    <Text style={styles.masterProblemsText}>
                      {masterProblem.condition_specifics}
                    </Text>
                  </View>
                </View>
              ))
            : null}
        </View>
      )}
    </>
  )
}
