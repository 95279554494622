import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { Layout, toast } from 'components/common'
import { SettingsStackParamList } from 'components/Settings/screens'
import { compact, trim } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { Routes } from 'src/constants/Routes'
import { useOrganisation } from 'src/context/organisation'
import { useUser } from 'src/context/user'
import { ApprovalRole, veterinary_roles } from 'src/types/globalTypes'
import { getUser, getUserVariables } from 'types/getUser'
import {
  updateUser as UpdateUser,
  updateUserVariables as UpdateUserVariables,
} from 'types/updateUser'

import { SubHeader } from '../SubHeader/SubHeader'
import { GET_USER, UPDATE_USER } from './graphql'
import { useDeleteUser } from './useDeleteUser'
import { UserForm, UserValues } from './UserForm'
import { authTypeService, userPinSessionService } from 'src/context/auth'

type Props = StackScreenProps<SettingsStackParamList, Routes.EditUser>

export const EditUserScreen: React.FC<Props> = ({ navigation, route }) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { user: currentUser, updateCurrentUser } = useUser()
  const { navigate } = navigation
  const userId = route.params.userId
  const isSelf = currentUser?.id === userId
  const isAuthV2 = authTypeService.getIsAuthV2()

  const backButton = {
    title: 'settings:users.title',
    label: 'settings:users.returnTo',
    action: () => navigate(Routes.UserList),
  }

  const queryVariables = {
    id: userId,
    organisation_id: organisationId,
  }

  const { data, loading } = useQuery<getUser, getUserVariables>(GET_USER, {
    variables: queryVariables,
    fetchPolicy: 'network-only',
    onError: err => {
      toast.error(err.message, null, err)
    },
  })

  const user = data?.getUser

  const [deleteUser] = useDeleteUser(queryVariables)

  const [updateUser, { loading: submitting }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(UPDATE_USER, {
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
    onCompleted: () => {
      toast.success(
        t('settings:editUser.success', { name: user?.display_name }),
      )
    },
  })

  const updateUserAndNavBack = async (updatedUser: UserValues) => {
    if (!user) return
    const old_email = user.email
    const shouldValidateEmail = old_email !== updatedUser.email
    const changedExistingUserEmail = shouldValidateEmail && old_email
    const input = {
      allow_login: updatedUser.has_login,
      departments: compact(updatedUser.departments.split(',').map(trim)),
      display_name: updatedUser.display_name,
      email: updatedUser.email,
      full_name: updatedUser.full_name,
      id: user.id,
      job_title: updatedUser.job_title,
      organisation_id: organisationId,
      phone_number: updatedUser.phone_number,
      // We don't need to convert the role using getUserRole since it is form data sent to server
      role: updatedUser.role,
      veterinary_role: updatedUser.veterinary_role,
      approval_role: updatedUser.approval_role,
      temporaryPassword: updatedUser.temporary_password,
      auto_logout_timeout: updatedUser.auto_logout_timeout,
      ...(changedExistingUserEmail && {
        old_email,
      }),
    }
    await updateUser({ variables: { input, options: { shouldValidateEmail } } })
    const shouldDeleteV2Pin = changedExistingUserEmail || !input.allow_login
    if (isAuthV2 && shouldDeleteV2Pin) {
      userPinSessionService.deleteUserPinSession(user.id)
    }

    if (isSelf) {
      await updateCurrentUser()
    }

    navigate(Routes.UserList)
  }

  const deleteUserAndNavBack = async () => {
    await deleteUser()
    if (isAuthV2 && user?.id) {
      userPinSessionService.deleteUserPinSession(user.id)
    }
    navigate(Routes.UserList)
  }

  useEffect(() => {
    if (!loading && !user) {
      const noUserError = new Error(`No user found: ${userId}`)
      toast.error(
        'No user found, please check manage users list',
        null,
        noUserError,
      )
    }
  }, [loading, user, userId])

  const userFormData = user
    ? {
        departments: user.departments?.join(', ') ?? '',
        display_name: user.display_name,
        email: user.email,
        full_name: user.full_name,
        has_login: user.has_login ?? false,
        job_title: user.job_title ?? '',
        phone_number: user.phone_number ?? '',
        role: user.role,
        veterinary_role: user.veterinary_role ?? veterinary_roles.VET,
        approval_role: user.approval_role ?? ApprovalRole.NOT_APPLICABLE,
        auth_provider_id: user.auth_provider_id,
        temporary_password: null,
        auto_logout_timeout: user?.auto_logout_timeout,
      }
    : null

  return (
    <>
      <SubHeader
        headlineKey="settings:editUser.title"
        backButton={backButton}
      />
      <Layout mode="single-center">
        {loading ? (
          <ActivityIndicator size="large" style={styles.marginTop} />
        ) : (
          userFormData && (
            <UserForm
              isSelf={isSelf}
              onDelete={deleteUserAndNavBack}
              onSubmit={updateUserAndNavBack}
              submitting={submitting}
              submitTitle={t('general.saveChanges')}
              user={userFormData}
            />
          )
        )}
      </Layout>
    </>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25,
  },
})
