import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Typography, Colors } from 'src/design-system/theme'

type PresentingProblemSectionProps = {
  presentingProblems: string
}

export const PresentingProblemSection: React.FC<
  PresentingProblemSectionProps
> = ({ presentingProblems }) => {
  return (
    <View style={styles.presentingRowContainer}>
      {!!presentingProblems && (
        <View style={[styles.presentingRow, styles.rowPadding]}>
          <TextWithTooltip
            style={styles.regularText}
            numberOfLines={1}
            title={presentingProblems}
          >
            {presentingProblems}
          </TextWithTooltip>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  presentingRowContainer: {
    marginTop: 4,
    height: 23,
  },
  presentingRow: {
    backgroundColor: Colors.Backgrounds.secondary,
  },
  regularText: {
    ...Typography.Paragraph.M,
  },
  rowPadding: {
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
})
