import { KeyArgsFunction } from '@apollo/client/cache/inmemory/policies'

import { getUniqueKeyArgsFunction } from './getUniqueKeyArgsFunction'
import { workflowPatientItemsMerge } from 'src/apollo/cache/patientList'

//  Cache separate results when these keyArgs change
export const listWhiteboardWorkflowsKeyArgs: KeyArgsFunction = (_, context) => {
  if (!context.variables) {
    return 'listWhiteboardWorkflowsKeyArgs'
  }

  return getUniqueKeyArgsFunction('listWhiteboardWorkflowsKeyArgs')(
    context.variables,
  )
}

export const listWhiteboardWorkflows = {
  // Cache separate results based on keyArgs
  keyArgs: listWhiteboardWorkflowsKeyArgs,
  merge: workflowPatientItemsMerge,
}
