import { RouteOfAdministrationConfig } from 'components/Settings/RouteOfAdministration/types'
import React, { useEffect } from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Colors } from 'src/design-system/theme'
import { Checkbox } from 'src/design-system/components/CheckBox/Checkbox'
import { TextInput } from 'components/common'
import { SvgTrash } from 'components/Icons/Trash'
import { useTranslation } from 'react-i18next'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { UseFormWatch } from 'react-hook-form/dist/types/form'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { Fonts } from 'constants/Fonts'

type RouteSectionProps = {
  removeRoute: (idx: number) => void
  idx: number
  control: Control<RouteOfAdministrationConfig>
  watch: UseFormWatch<RouteOfAdministrationConfig>
  setValue: UseFormSetValue<RouteOfAdministrationConfig>
  errors: FieldErrors<RouteOfAdministrationConfig>
}

export const RouteSection: React.FC<RouteSectionProps> = ({
  removeRoute,
  idx,
  control,
  watch,
  setValue,
  errors,
}) => {
  const { t } = useTranslation()

  const routes = watch(`routes`)

  const route = routes[idx]

  const isRouteUnique = (abbreviation: string, currentIndex: number) => {
    return (
      routes.findIndex(
        (route, index) =>
          (route.abbreviation === abbreviation ||
            route.value === abbreviation) &&
          index !== currentIndex,
      ) === -1
    )
  }

  useEffect(() => {
    if (!route.default) {
      setValue(`routes.${idx}.value`, route.abbreviation)
    }
  }, [route.default, route.abbreviation, setValue, idx])

  const renderFormErrorMessage = () => {
    if (errors?.routes?.[idx]?.abbreviation?.type === 'isUnique') {
      return (
        <Text style={styles.errorText}>
          {t('settings:routeOfAdministration.error.abbreviationUnique')}
        </Text>
      )
    }

    return null
  }

  return (
    <View>
      <View style={styles.routeSectionContainer}>
        <View style={styles.checkBox}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Checkbox checked={value} onPress={() => onChange(!value)} />
            )}
            name={`routes.${idx}.active`}
            control={control}
            rules={{ validate: { forceValidation: () => true } }}
          />
        </View>

        <View style={styles.abbreviation}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <TextInput
                label={t('settings:routeOfAdministration.abbreviation')}
                onChangeText={onChange}
                value={value}
                editable={!route.default}
                maxLength={6}
                required
                autoFocus={value === ''}
              />
            )}
            name={`routes.${idx}.abbreviation`}
            control={control}
            rules={{
              required: true,
              validate: {
                isUnique: value => isRouteUnique(value, idx),
              },
            }}
          />
        </View>

        <View style={styles.name}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <TextInput
                label={t('settings:routeOfAdministration.name')}
                onChangeText={onChange}
                value={value}
                editable={!route.default}
                maxLength={30}
                required
              />
            )}
            name={`routes.${idx}.name`}
            control={control}
            rules={{ required: true }}
          />
        </View>

        {!route.default ? (
          <View style={styles.remove}>
            <Pressable onPress={() => removeRoute(idx)}>
              <SvgTrash color={Colors.Contents.primary} />
            </Pressable>
          </View>
        ) : null}
      </View>

      {renderFormErrorMessage()}
    </View>
  )
}

const styles = StyleSheet.create({
  routeSectionContainer: {
    width: '100%',
    height: 61,
    marginVertical: 4,
    backgroundColor: Colors.Backgrounds.UI,
    borderBottomWidth: 1,
    borderColor: Colors.Borders.primary,
    display: 'flex',
    flexDirection: 'row',
  },
  checkBox: {
    flex: 1,
    height: 61,
    justifyContent: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  abbreviation: {
    flex: 8,
    height: 61,
    justifyContent: 'center',
    borderRightWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  name: {
    flex: 8,
    height: 61,
    justifyContent: 'center',
  },
  remove: {
    borderLeftWidth: 1,
    borderColor: Colors.Borders.primary,
    flex: 1,
    height: 61,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontFamily: Fonts.regular,
    marginTop: 5,
    marginBottom: 9,
    fontSize: 13,
    lineHeight: 19,
    paddingHorizontal: 16,
    color: Colors.Contents.negative,
  },
})
