import { ApolloClient, useApolloClient, useMutation } from '@apollo/client'
import { TREATMENT_BASE_WITH_TASKS } from 'components/AddTreatment/graphql'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { cloneDeep, find, isNumber } from 'lodash'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { rescheduleTasks_rescheduleTasks as Task } from 'src/types/rescheduleTasks'
import { TaskFieldsFull } from 'src/types/TaskFieldsFull'
import { TreatmentBaseWithTasks } from 'src/types/TreatmentBaseWithTasks'
import { getOptimisticId } from 'src/utils/optimisticId'
import { ApprovalStatus, Status } from 'types/globalTypes'

import { toast } from '../../common'
import { RESCHEDULE_TASK, TASK_FIELDS_FULL } from '../graphql'
import { RescheduleTask, RescheduleTaskVariables } from '../types'
import { useApprovals } from 'src/hooks/useApprovals'
import { buildTreatmentWithTasksFragmentVariable } from 'components/Treatment/utils/buildTreatmentWithTasksFragmentVariable'

export const updateRescheduleTask = (
  newTask: Task,
  oldTask: TaskFieldsFull,
  client: ApolloClient<object>,
  fromToQueryDate: {
    fromISODate: string
    toISODate: string
  },
) => {
  if (newTask.id === oldTask.id) {
    // optimistic response
    updateTaskCache(
      oldTask.id,
      {
        status: Status.DELETED_ON_PURPOSE,
      },
      client,
    )
    addTaskToCache(
      oldTask.treatment_id,
      {
        ...newTask,
        id: getOptimisticId(),
        status: Status.PENDING,
      },
      client,
      fromToQueryDate,
    )
    return
  }

  // real response
  // 1. update task in cache
  updateTaskCache(
    oldTask.id,
    {
      status: Status.DELETED_ON_PURPOSE,
    },
    client,
  )

  // 2. insert new task
  addTaskToCache(oldTask.treatment_id, newTask, client, fromToQueryDate)
}

const addTaskToCache = (
  treatmentId: string,
  task: Task,
  client: ApolloClient<object>,
  fromToQueryDate: {
    fromISODate: string
    toISODate: string
  },
) => {
  const fragmentVariable = {
    fragment: TREATMENT_BASE_WITH_TASKS,
    fragmentName: 'TreatmentBaseWithTasks',
    id: `Treatment:${treatmentId}`,
    variables: fromToQueryDate,
  }

  const cachedTreatment =
    client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

  if (!cachedTreatment) {
    return
  }
  const clonedTreatmentData = cloneDeep(cachedTreatment)

  if (!clonedTreatmentData?.tasks?.items) {
    return
  }
  // Task may already exist on subscription
  if (!find(clonedTreatmentData.tasks.items, ['id', task.id])) {
    clonedTreatmentData.tasks.items.push(task)
  }

  client.writeFragment({ ...fragmentVariable, data: clonedTreatmentData })
}

const updateTaskCache = (
  taskId: string,
  newTask: Partial<Task>,
  client: ApolloClient<object>,
) => {
  const cachedTask = client.readFragment({
    id: `Task:${taskId}`,
    fragment: TASK_FIELDS_FULL,
    fragmentName: 'TaskFieldsFull',
  })

  if (!cachedTask) return

  client.writeFragment({
    id: `Task:${taskId}`,
    fragment: TASK_FIELDS_FULL,
    fragmentName: 'TaskFieldsFull',
    data: {
      ...cachedTask,
      ...newTask,
    },
  })
}

export const createDateString = (
  task: TaskFieldsFull,
  newStartDateOrRescheduleMinutes: Date | number,
) => {
  let timeWindow = 60

  if (task.stop_at && task.start_at) {
    timeWindow = differenceInMinutes(
      new Date(task.stop_at),
      new Date(task.start_at),
    )
  }

  let newStartAt: Date
  if (isNumber(newStartDateOrRescheduleMinutes)) {
    newStartAt = addMinutes(
      new Date(task.start_at!),
      newStartDateOrRescheduleMinutes,
    )
  } else {
    newStartAt = newStartDateOrRescheduleMinutes
  }

  const newStopAt = new Date(newStartAt.getTime())
  newStopAt.setMinutes(newStopAt.getMinutes() + timeWindow)

  return {
    startAt: newStartAt.toISOString(),
    stopAt: newStopAt.toISOString(),
  }
}

export const useRescheduleTask = () => {
  const [{ organisationId }] = useOrganisation()
  const client = useApolloClient()
  const { fromToQueryDate } = useTimeResolution()
  const { shouldUnapproveTreatment } = useApprovals()

  const [rescheduleTask] = useMutation<RescheduleTask, RescheduleTaskVariables>(
    RESCHEDULE_TASK,
    {
      onError: err => {
        toast.error(err.message)
      },
    },
  )

  return (
    oldTask: TaskFieldsFull,
    newStartDateOrRescheduleMinutes: Date | number,
  ) => {
    const treatmentId = oldTask.treatment_id
    const { startAt, stopAt } = createDateString(
      oldTask,
      newStartDateOrRescheduleMinutes,
    )

    const input = {
      id: oldTask.id,
      notes: oldTask.notes,
      organisation_id: organisationId,
      patient_id: oldTask.patient_id,
      set_as_skipped: false,
      sheet_id: oldTask.sheet_id,
      start_at: startAt,
      treatment_id: treatmentId,
      highlight_colour: oldTask.highlight_colour,
    }

    return rescheduleTask({
      variables: {
        input,
      },
      optimisticResponse: {
        rescheduleTask: {
          ...oldTask,
          start_at: startAt,
          stop_at: stopAt,
        },
      },
      update: (_, { data }) => {
        const newTask = data?.rescheduleTask
        if (!newTask) {
          return
        }
        updateRescheduleTask(newTask, oldTask, client, fromToQueryDate)

        const fragmentVariable = buildTreatmentWithTasksFragmentVariable(
          newTask.treatment_id,
          fromToQueryDate,
        )

        const cachedTreatment =
          client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)
        if (!cachedTreatment) return

        if (shouldUnapproveTreatment(cachedTreatment)) {
          client.writeFragment({
            ...fragmentVariable,
            data: {
              ...cachedTreatment,
              approval_status: ApprovalStatus.PENDING,
            },
          })
        }
      },
    })
  }
}
