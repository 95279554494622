import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color?: string
  length?: number
}

export const SvgSkipped: React.FC<Props> = ({
  color = '#FF5252',
  length = 20,
}) => (
  <Svg width={length} height={length} viewBox="0 0 24 24" testID="SvgSkipped">
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <Path
        d="M18.1067206,19.7575973 C21.8388385,17.1443402 22.7458544,12.0003974 20.1325973,8.26827942 C17.5193402,4.53616147 12.3753974,3.62914556 8.64327942,6.24240268 C4.91116147,8.8556598 4.00414556,13.9996026 6.61740268,17.7317206 C9.2306598,21.4638385 14.3746026,22.3708544 18.1067206,19.7575973 Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="33,39.875"
      />
      <Path
        d="M8.37,14.1766227 L5.88,16.7118873 C5.49644936,17.0954379 4.81147014,17.0954379 4.4279195,16.7118873 L1.93766298,14.1766227 C1.55411234,13.7930721 1.55411234,13.1712136 1.93766298,12.787663 C2.1218505,12.6034755 2.37166238,12.5 2.63214286,12.5 L7.67,12.5 C8.21242252,12.5 8.65,12.9397203 8.65,13.4821429 C8.65,13.7426233 8.55418751,13.9924352 8.37,14.1766227 Z"
        fill={color}
      />
    </G>
  </Svg>
)
