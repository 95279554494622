import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_SHEET_HISTORY } from 'components/UserHistory/graphql'
import type {
  getSheetHistory,
  getSheetHistoryVariables,
} from 'src/types/getSheetHistory'

export const useSheetHistory = (
  visible: boolean,
  userId: string | undefined,
  organisationId: string,
) => {
  const { data: getSheetHistory, loading: getSheetHistoryLoading } = useQuery<
    getSheetHistory,
    getSheetHistoryVariables
  >(GET_SHEET_HISTORY, {
    fetchPolicy: 'cache-and-network',
    skip: !visible || !userId || !organisationId,
    variables: {
      organisation_id: organisationId,
      user_id: userId as string,
    },
  })
  return { getSheetHistory, getSheetHistoryLoading }
}

/**
 * Use to lazy load the sheet history in the background, can call `loadSheetHistory` to update the value stored
 */
export const useLazySheetHistory = (userId: string, organisationId: string) => {
  const [
    loadSheetHistory,
    { data: getSheetHistory, loading: getSheetHistoryLoading },
  ] = useLazyQuery<getSheetHistory, getSheetHistoryVariables>(
    GET_SHEET_HISTORY,
    {
      fetchPolicy: 'network-only',
      variables: {
        organisation_id: organisationId,
        user_id: userId,
      },
    },
  )
  return { loadSheetHistory, getSheetHistory, getSheetHistoryLoading }
}
