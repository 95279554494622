import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { UPDATE_CONSULTATION } from 'components/CreateSheet/graphql'
import { cloneDeep, isNil } from 'lodash'
import { useOrganisation } from 'src/context/organisation'
import { getPatient, getPatientVariables } from 'src/types/getPatient'
import { UpdateConsultationInput } from 'src/types/globalTypes'
import {
  updateConsultation as UpdateConsultation,
  updateConsultationVariables as UpdateConsultationVariables,
} from 'src/types/updateConsultation'

import { GET_PATIENT } from './graphql'

export const useUpdateConsultation = (patientId: string) => {
  const [{ organisationId }] = useOrganisation()
  const [updateConsultation] = useMutation<
    UpdateConsultation,
    UpdateConsultationVariables
  >(UPDATE_CONSULTATION, {
    update: (cache, { data }) => {
      if (!data) return
      const query = GET_PATIENT
      const variables = {
        organisationId,
        id: patientId,
      }

      // (read|write)Query is a good choice here since there are two
      // cache fragments (patient, consultation) to update
      const patient = cache.readQuery<getPatient, getPatientVariables>({
        query,
        variables,
      })

      if (patient !== null) {
        const cachePatient = cloneDeep(patient)
        const firstConsult = // object ref to mutate
          cachePatient?.getPatient?.active_consultations?.items?.[0]

        if (firstConsult) {
          firstConsult.locations = data.updateConsultation.locations
          firstConsult.color = data.updateConsultation.color
        }

        cache.writeQuery({ query, variables, data: cachePatient })
      }
    },
    onError: err => {
      toast.error(err.message)
    },
  })
  return useCallback(
    (updateConsultationInput: UpdateConsultationInput) => {
      if (isNil(updateConsultationInput.id)) return
      return updateConsultation({
        variables: {
          input: updateConsultationInput,
        },
      })
    },
    [updateConsultation],
  )
}
