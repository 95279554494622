import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  color: string
  width?: number
  height?: number
}

export const EyeClose: React.FC<Props> = React.memo(
  ({ color, width = 24, height = 24 }) => (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill={color}>
      <Path
        d="M8.073 12.186L4.212 8.48584C2.692 10.0738 2.116 11.6646 2.106 11.6972L2 12L2.105 12.3029C2.127 12.3671 4.421 18.7084 12.054 18.7084C12.983 18.7084 13.829 18.6106 14.606 18.4468L11.86 15.8152C10.8713 15.7687 9.93595 15.3715 9.23598 14.7006C8.53601 14.0298 8.12147 13.1335 8.073 12.186Z"
        fill="#0071ED"
      />
      <Path
        d="M12.054 5.29172C10.199 5.29172 8.67897 5.67888 7.41197 6.24814L3.70697 2.69751L2.29297 4.05259L20.293 21.3026L21.707 19.9475L18.409 16.7869C21.047 14.9153 21.988 12.3431 22.002 12.3029L22.107 12.0001L22.002 11.6972C21.98 11.633 19.687 5.29172 12.054 5.29172ZM13.96 12.5233C14.147 11.8745 13.988 11.1443 13.468 10.645C12.948 10.1457 12.185 9.99426 11.508 10.1735L9.99997 8.7283C10.6179 8.36412 11.3285 8.16984 12.054 8.16672C14.26 8.16672 16.054 9.88597 16.054 12.0001C16.051 12.6951 15.8479 13.376 15.467 13.9675L13.96 12.5233Z"
        fill="#0071ED"
      />
    </Svg>
  ),
)

EyeClose.displayName = 'EyeClose'
