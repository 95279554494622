import React, { Fragment, useEffect, useState } from 'react'
import { ProductGroupName } from 'components/AddTreatment/SingleProductPicker'
import { FormLabel, SecondaryButton, TextLink } from 'components/common'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { ProductType } from 'src/types/globalTypes'

import { ProductSelect, ProductSelected } from './ProductSelect'
import { Variables } from 'src/design-system/theme'

type Props = {
  label: string
  patientId: string
  selectedProducts: (ProductSelected | null)[]
  onSelectedProductsChange: (
    selectedProducts: (ProductSelected | null)[],
  ) => void
  groupName?: ProductGroupName
  productType?: ProductType
  attendingDepartment?: string | null
}

export const ProductSection: React.FC<Props> = ({
  label,
  patientId,
  selectedProducts,
  onSelectedProductsChange,
  productType,
  groupName = ProductGroupName.All,
  attendingDepartment,
}) => {
  const { t } = useTranslation()
  const [localState, setLocalState] = useState(selectedProducts)

  useEffect(() => {
    onSelectedProductsChange(localState)
  }, [localState, onSelectedProductsChange])

  const updateProductSelected = (
    productSelected: ProductSelected,
    index: number,
  ) => {
    const newProductsList = [...selectedProducts]
    newProductsList[index] = productSelected
    setLocalState(newProductsList)
  }

  const onAddProduct = () => {
    setLocalState(v => [...v, null])
  }

  const onRemoveProduct = (idx: number) => {
    const filter = localState.filter((_, index) => index !== idx)
    setLocalState(filter)
  }

  return (
    <View>
      <FormLabel text={label} />
      {localState.map((product, index) => {
        const removeProduct = () => onRemoveProduct(index)
        return (
          <Fragment key={index}>
            <ProductSelect
              patientId={patientId}
              selected={product}
              onChange={productSelected =>
                updateProductSelected(productSelected, index)
              }
              groupName={groupName}
              productType={productType}
              attendingDepartment={attendingDepartment}
            />
            <TextLink
              text={t('general.remove')}
              a11yLabel={`${t('general.remove')} treatment button`}
              onPress={removeProduct}
              containerStyles={styles.removeButton}
            />
          </Fragment>
        )
      })}
      <SecondaryButton
        title={`+ ${t('addTreatment.title')}`}
        style={styles.secondaryButton}
        onPress={onAddProduct}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  secondaryButton: {
    justifyContent: 'flex-start',
    height: 35,
    paddingHorizontal: Variables.GutterSpacing.md,
    width: 180,
  },
  removeButton: {
    alignItems: 'flex-end',
    paddingRight: 16,
  },
})
