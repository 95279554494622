import React from 'react'
import {
  TreatmentChildLevel,
  TreatmentGroupProps,
  TreatmentSheet,
  TreatmentStateDisplay,
} from 'components/Treatment/common/types'
import { identity, noop } from 'lodash'
import { View } from 'react-native'
import { TreatmentTreeNodeType } from 'src/types/globalTypes'

import { foldedTreatmentsDefault } from './common'
import { groupStyles } from './common/styles'
import {
  GroupTreatmentDisplayState,
  NestingDepth,
  Treatment,
} from './Treatment'
import { useSortedTreatmentDetails } from './useSortedTreatmentDetails'
import { isTreatmentPending } from 'src/hooks/useApprovals'

type DisplayStateParams = {
  isConditional: boolean
  isDiscontinued: boolean
  isFinalized: boolean
  isPendingApproval: boolean
  isGroup: boolean
}

export const getDisplayState = ({
  isConditional,
  isDiscontinued,
  isFinalized,
  isGroup,
  isPendingApproval,
}: DisplayStateParams) => {
  if (isGroup) return TreatmentStateDisplay.GroupDisplay
  if (isPendingApproval) return TreatmentStateDisplay.PendingApproval
  if (isFinalized) return TreatmentStateDisplay.FinalisedDisplay
  if (isDiscontinued) return TreatmentStateDisplay.DiscontinuedDisplay
  if (isConditional) return TreatmentStateDisplay.ConditionalDisplay

  return TreatmentStateDisplay.None
}

// TODO: Calculate in platform #VR-2915
export const getGroupTaskDisplayState = (
  treatments: TreatmentChildLevel[],
): GroupTreatmentDisplayState | null => {
  const hasMissedChildren = treatments.some(treatment => {
    if (treatment.discontinued_at) return false
    return treatment.treatmentTasksStatus.hasMissed
  })
  const hasDueChildren = treatments.some(
    treatment => treatment.treatmentTasksStatus.hasDue,
  )

  if (hasMissedChildren) return GroupTreatmentDisplayState.Has_Missed
  if (hasDueChildren) return GroupTreatmentDisplayState.Has_Due
  return null
}

export const TreatmentGroup: React.FC<TreatmentGroupProps> = ({
  dosageInfoType,
  foldedTreatments = foldedTreatmentsDefault,
  isFinalized = false,
  isFolded = false,
  nestingDepth = 0,
  onPressTreatment = noop,
  onPressTreatmentGroupTitle = noop,
  onToggleFolding = noop,
  patient,
  round = identity,
  templateName,
  treatment,
}) => {
  const { childTreatments, hasChildTreatments, shouldHideTreatment } =
    useSortedTreatmentDetails(treatment)

  const treatmentDisplayState = getDisplayState({
    isFinalized,
    isGroup: treatment.node_type === TreatmentTreeNodeType.GROUP,
    isConditional: treatment.conditional ?? false,
    isDiscontinued: !!treatment.discontinued_at ?? false,
    isPendingApproval: isTreatmentPending(treatment),
  })

  let groupTaskDisplayStateOverride: GroupTreatmentDisplayState | null = null
  if (treatmentDisplayState === TreatmentStateDisplay.GroupDisplay) {
    groupTaskDisplayStateOverride = getGroupTaskDisplayState(childTreatments)
  }

  return shouldHideTreatment ? null : (
    <>
      <Treatment
        displayState={treatmentDisplayState}
        dosageInfoType={dosageInfoType}
        hasNestedTreatments={hasChildTreatments}
        hasConditionalFields={treatment.conditional}
        isFolded={isFolded}
        nestingDepth={nestingDepth}
        onPressTreatment={onPressTreatment}
        onPressTreatmentGroupTitle={onPressTreatmentGroupTitle}
        onToggleFolding={onToggleFolding}
        patient={patient}
        round={round}
        taskdisplayStateOverride={groupTaskDisplayStateOverride}
        templateName={templateName}
        treatment={treatment}
      />
      <View style={[isFolded && groupStyles.hidden]}>
        {childTreatments.map((childNode: TreatmentSheet, i: number) => (
          // Forms a tree data structure
          <TreatmentGroup
            dosageInfoType={dosageInfoType}
            foldedTreatments={foldedTreatments}
            isFinalized={isFinalized}
            isFolded={foldedTreatments.includes(childNode.id)}
            key={i}
            nestingDepth={(nestingDepth + 1) as NestingDepth}
            onPressTreatment={onPressTreatment}
            onToggleFolding={onToggleFolding}
            patient={patient}
            round={round}
            templateName={templateName}
            treatment={childNode}
          />
        ))}
      </View>
    </>
  )
}
