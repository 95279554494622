import { yupResolver } from '@hookform/resolvers/yup'
import {
  getRequiredPositiveSchema,
  getUnitsBilledPerTaskSchema,
} from 'components/TreatmentForm/utils/getTreatmentFormSchema'
import { noop, toNumber } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { environment } from 'src/config'
import { useConfirm } from 'src/context/confirm'
import { useOrganisation } from 'src/context/organisation'
import { usePatientById } from 'src/hooks/usePatient'
import { useStartTime } from 'src/hooks/useStartTime'
import { Status, VideoInput } from 'src/types/globalTypes'
import * as Yup from 'yup'
import {
  Action,
  FluidAction,
  Inputs,
  PhotoAction,
  UseTaskTransitionProps,
} from '../types'
import { getShowBackdate } from '../utils/getShowBackdate'
import { getTaskIsBackdated } from '../utils/getTaskIsBackdated'
import {
  getIsCRI,
  getIsFluidStyleTaskDisplay,
  getMustCompleteTaskWithInitials,
  getTreatmentIsMedication,
  getTreatmentIsNotesRequired,
  getTreatmentIsValueRequired,
} from '../utils/getTreatmentInfo'
import { getIsTaskDoneOrProgress } from '../utils/taskTransitionUtils'
import { useResetTask } from '../utils/useResetTask'
import { getFluidTaskNumberValue } from 'components/EditTreatment/utils/fluidTaskUtils'
import { getProductIsHealthStatusWeight } from './getProductInfo'

export const useTaskTransition = ({
  action = Action.COMPLETE,
  isFinalized = false,
  patientId,
  task,
  treatment,
  sheetId,
  timeInGrid,
  toggleDialog,
  nextPendingFluidTask,
}: UseTaskTransitionProps) => {
  const { t } = useTranslation()
  const { resetTask } = useResetTask()

  const { isWeb } = environment
  const startTime = useStartTime()
  const patient = usePatientById(patientId)
  const [{ organisationId }] = useOrganisation()
  const [isUploadingMedia, setIsUploadingMedia] = useState(false)

  const isFluidStyleTaskDisplay = getIsFluidStyleTaskDisplay(treatment)
  const isCRI = getIsCRI(treatment)
  const isStartAction = action === FluidAction.START
  const isCompleteOrStartAction =
    action === Action.COMPLETE || action === FluidAction.START
  const isReadOnlyAction = action === Action.VIEW || action === FluidAction.VIEW
  const shouldShowBackDate = getShowBackdate({
    action,
    task,
  })

  const patientWeightUnit = patient?.weight_unit ?? 'kg'

  const treatmentProduct = treatment?.product ?? null

  const shouldFluidStateShowBillableToggle =
    !isFluidStyleTaskDisplay || action === FluidAction.START

  const shouldShowBillableToggle =
    treatmentProduct?.is_billable &&
    (isCompleteOrStartAction || getIsTaskDoneOrProgress(task?.status)) &&
    shouldFluidStateShowBillableToggle

  const getBillableDefaultValue = () => {
    // If is readonly or task is completed: Use the task is_billable value
    if (isReadOnlyAction || getIsTaskDoneOrProgress(task?.status)) {
      return task?.send_to_adaptor?.is_billable
    }
    // If is not readonly and the action is complete and has PIMS integration
    // or it is a skip task
    // return treatment is_billable value
    if (
      (isCompleteOrStartAction && shouldShowBillableToggle) ||
      task?.status === Status.MISSED_ON_PURPOSE
    ) {
      return treatment?.is_billable
    }
    // All other cases return false
    return false
  }

  const mustCompleteWithValue = getTreatmentIsValueRequired(treatment)
  const mustCompleteWithInitials = getMustCompleteTaskWithInitials(
    action,
    treatment,
  )

  const isNotesRequired = getTreatmentIsNotesRequired(action, treatment)
  const isMedication = getTreatmentIsMedication(treatment)

  const { backdate, datetimeGiven } = getTaskIsBackdated(
    startTime,
    task,
    action,
  )

  const medicationOverrides = useMemo(() => {
    // Populate form data for MEDICATION
    if (isMedication) {
      const {
        dosage: treatmentDosage,
        dosage_weight_unit: treatmentDosageUnit,
      } = treatment?.medicine_dosage_info ?? {}

      // If value not already set (ie. Task completed)
      if (!task?.value) {
        return {
          value: `${treatmentDosage ?? ''}`,
          unit: treatmentDosageUnit ?? '',
        }
      }
    }
    return null
  }, [isMedication, task?.value, treatment?.medicine_dosage_info])

  const getDefaultValue = () => {
    if (isFluidStyleTaskDisplay) {
      const fluidValue = getFluidTaskNumberValue(task?.value ?? null)
      return fluidValue !== null ? String(fluidValue) : ''
    }
    if (medicationOverrides) {
      return medicationOverrides.value
    }
    return task?.value ?? ''
  }

  const defaultQtyBilled = useMemo(
    () =>
      task?.qty_billed ??
      treatment?.medicine_dosage_info?.units_billed_per_task ??
      1,
    [task?.qty_billed, treatment?.medicine_dosage_info?.units_billed_per_task],
  )

  const defaultValues: Inputs = {
    // Alway default to false if is fluid. VR-6340
    backdate: isFluidStyleTaskDisplay ? false : backdate,
    datetimeGiven,
    //  add default string to Reduce test noise
    assigned_user: task?.assigned_user ?? '',
    newTaskDate: timeInGrid ?? startTime,
    isBillable: getBillableDefaultValue(),
    notes: task?.notes ?? '',
    reschedule: false,
    showValue: false,
    unit: medicationOverrides ? medicationOverrides.unit : task?.unit ?? '',
    value: getDefaultValue(),
    photo_urls: [], // task?.photo_urls is not correct, because the platform need empty array for updating without photos
    videos: [], // task?.videos is not correct, because the platform need empty array for updating without photos
    highlightColor: task?.highlight_colour ?? null,
    qtyBilled: defaultQtyBilled,
  }

  const validationSchema = Yup.object().shape({
    ...(isCompleteOrStartAction &&
      mustCompleteWithValue && {
        value: Yup.string().required('Must Complete with value').nullable(),
      }),
    ...(mustCompleteWithInitials && {
      assigned_user: Yup.string().required(t('form.required')).nullable(),
    }),
    ...(isNotesRequired && {
      notes: Yup.string().required(
        t('task:taskTransition:form:mandatoryNotes'),
      ),
    }),
    ...(isCRI && {
      value: getRequiredPositiveSchema(t),
    }),
    isBillable: Yup.boolean(),
    qtyBilled: getUnitsBilledPerTaskSchema(t),
  })

  const {
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { isValid, errors },
  } = useForm<Inputs>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const isBackdated = watch('backdate', defaultValues.backdate)
  const watchedValue = watch('value')
  const watchedQtyBilled = watch('qtyBilled')
  const isValueEqualQtyBilled = useMemo(
    () => toNumber(watchedValue) === watchedQtyBilled,
    [watchedQtyBilled, watchedValue],
  )

  const objectKeyPrefix = useMemo(() => {
    if (task && sheetId) {
      return [
        organisationId,
        'patient',
        patientId,
        'sheet',
        sheetId,
        'treatment',
        treatment?.id,
        'task',
        task.id,
      ].join('/')
    }
    return ''
  }, [organisationId, patientId, sheetId, task, treatment?.id])

  const handlePhotoChange = (
    newUrls: string[] | string,
    photoAction: PhotoAction = PhotoAction.Add,
  ) => {
    const photoUrls = getValues('photo_urls') ?? []

    if (photoAction === PhotoAction.Add) {
      setValue('photo_urls', [...photoUrls, ...newUrls])
    }

    if (photoAction === PhotoAction.Remove) {
      setValue(
        'photo_urls',
        photoUrls.filter(photoUrl => photoUrl !== newUrls),
      )
    }
  }

  const handleVideoChange = (
    videos: VideoInput | VideoInput[],
    action: PhotoAction,
  ) => {
    const currVideos = getValues('videos') ?? []
    if (action === PhotoAction.Add) {
      if (Array.isArray(videos)) {
        setValue('videos', [...currVideos, ...videos])
      } else {
        setValue('videos', [...currVideos, videos])
      }
    }
    if (action === PhotoAction.Remove) {
      if (Array.isArray(videos)) {
        setValue(
          'videos',
          currVideos.filter(vid => !videos.map(v => v.url).includes(vid.url)),
        )
      } else {
        setValue(
          'videos',
          currVideos.filter(vid => vid.url !== videos.url),
        )
      }
    }
  }

  // run validation when changing tab
  useEffect(() => {
    if (action === FluidAction.CHANGE_FLUID_RATE) {
      const value =
        getFluidTaskNumberValue(
          nextPendingFluidTask?.value ?? null,
        )?.toString() ?? ''
      if (value) {
        setValue('value', value)
      }
      if (nextPendingFluidTask?.start_at) {
        const newTaskDate = new Date(nextPendingFluidTask.start_at!)
        setValue('newTaskDate', newTaskDate)
      }
    }
    if (action === FluidAction.SCHEDULE_FLUID) {
      setValue('value', defaultValues.value)
      setValue('newTaskDate', defaultValues.newTaskDate)
    }

    trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, nextPendingFluidTask])

  useEffect(() => {
    if (isStartAction) {
      setValue('showValue', true, { shouldValidate: true })
    }
  }, [action, isStartAction, setValue])

  const handleIsBillable = (val: boolean) => {
    // If task is read only, do nothing (pseudo disable to preserve blue colour),VR-3987 Done task can change billing
    if (isReadOnlyAction) return
    setValue('isBillable', val, { shouldValidate: true })
    trigger()
  }

  const handleQtyBilled = (val: number | null) => {
    // If task is read only, do nothing (pseudo disable to preserve blue colour),VR-3987 Done task can change billing
    if (isReadOnlyAction) return
    setValue('qtyBilled', val, { shouldValidate: true })
    trigger()
  }

  const shouldDisableSubmit = !isValid || isUploadingMedia

  const shouldDisableSkip = !isValid

  const showDoneButton = !(treatment?.discontinued_at || isFinalized)

  const shouldShowUploadBtn =
    (action === Action.COMPLETE || action === FluidAction.STOP) &&
    showDoneButton &&
    !!task

  const confirm = useConfirm()

  // Weight status product has different text due to weight tasks having side effects that don't get reset
  const resetModalText = getProductIsHealthStatusWeight(treatment?.product)
    ? {
        text: t('sheet:undoTask.weightReset'),
        title: t('sheet:undoTask.title'),
      }
    : {
        text: t('sheet:undoTask.confirm'),
        title: t('sheet:undoTask.title'),
      }

  const onReset = () => {
    confirm(resetModalText)
      .then(() => {
        if (task) {
          resetTask(task)
          toggleDialog()
        }
      })
      .catch(noop)
  }

  return {
    control,
    errors,
    getValues,
    handleIsBillable,
    handlePhotoChange,
    handleVideoChange,
    handleQtyBilled,
    isBackdated,
    isMedication,
    isReadOnlyAction,
    isUploadingMedia,
    isValueEqualQtyBilled,
    isWeb,
    mustCompleteWithInitials,
    mustCompleteWithValue,
    isNotesRequired,
    objectKeyPrefix,
    onReset,
    organisationId,
    patientWeightUnit,
    setIsUploadingMedia,
    setValue,
    shouldDisableSubmit,
    shouldDisableSkip,
    shouldShowBackDate,
    shouldShowBillableToggle,
    shouldShowUploadBtn,
    watchedValue,
  }
}
