import { useSubscription } from '@apollo/client'
import { SUBSCRIBE_TRAINING_ORGANISATION_DATA_RESET } from 'components/Settings/graphql'
import { trainingOrganisationDataReset } from 'types/trainingOrganisationDataReset'
import { useOrganisation } from 'src/context/organisation'
import { useResetStatusToastSender } from './useResetStatusToastSender'

export const useTrainingOrganisationDataResetSubscription = ({
  setIsResetting,
}: {
  setIsResetting?: (isResetting: boolean) => void
}) => {
  const sendResetStatusToast = useResetStatusToastSender()
  const [{ organisationId }] = useOrganisation()

  useSubscription<trainingOrganisationDataReset>(
    SUBSCRIBE_TRAINING_ORGANISATION_DATA_RESET,
    {
      variables: { organisationId },
      onData: ({ data }) => {
        const { resetStatus, message: errorMessage } =
          data.data?.trainingOrganisationDataReset?.data ?? {}

        if (resetStatus) {
          if (setIsResetting) setIsResetting(false)
          sendResetStatusToast(resetStatus, errorMessage!)
        }
      },
    },
  )
}
