import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { scaleTime } from 'd3-scale'
import { endOfDay, isWithinInterval, startOfDay } from 'date-fns'
import { ScrollView } from 'react-native'
import { getDayRangeExtent, useTimeResolution } from 'src/hocs/timeContext'
import { getSegmentsPerOneOrThreeDays } from 'src/hocs/types/time'

import { getDateBoxWidth } from '../Grid/GridTimeLine/utils/getDateBoxWidth'
import { OnSheetScroll } from './types'

type UseScrollPositionParams = {
  fullGridWidth: number
  initialDateInView?: string
  sheetRef: React.RefObject<ScrollView>
  visibleGridWidth: number
  visibleDayListLength: number
}

export const useScrollPosition = ({
  fullGridWidth,
  initialDateInView,
  sheetRef,
  visibleGridWidth,
  visibleDayListLength,
}: UseScrollPositionParams) => {
  const [currentDateIdx, setCurrentDateIdx] = useState(0) // The selected date button index
  const [hasHadInitialScrollToNow, setHasHadInitialScrollToNow] =
    useState(false)
  const offsetRef = useRef(0)
  const middleOfVisibleGrid = visibleGridWidth * 0.5

  const {
    dateRangeCount,
    setVisibleDayRange,
    timeNow,
    timeSegment,
    visibleDayRange,
  } = useTimeResolution()

  const initTime = useMemo(
    () => (initialDateInView ? new Date(initialDateInView) : timeNow),
    [initialDateInView, timeNow],
  )

  const scrollToPosition = useCallback(
    (offset: number, animated = false) => {
      // Without requestAnimationFrame, this won't work for native. (Don't know why..)
      requestAnimationFrame(() =>
        sheetRef.current?.scrollTo({ animated, x: offset }),
      )
    },
    [sheetRef],
  )

  const scrollToNowOnInit = useCallback(() => {
    const timeStart = startOfDay(visibleDayRange[0])
    const timeEnd = endOfDay(visibleDayRange[1])
    const isNowInRange = isWithinInterval(initTime, {
      start: timeStart,
      end: timeEnd,
    })
    // scroll to the very beginning if now is not in the range
    // scroll to now if now is in the range
    let newOffset = 0

    if (isNowInRange) {
      const positionInGrid =
        scaleTime().domain([timeStart, timeEnd]).range([0, fullGridWidth])(
          initTime,
        ) ?? 0

      newOffset = positionInGrid - middleOfVisibleGrid
    }

    scrollToPosition(newOffset)
  }, [
    fullGridWidth,
    initTime,
    middleOfVisibleGrid,
    scrollToPosition,
    visibleDayRange,
  ])

  // Keep grid position between grid range changes
  const lastTimeSegmentRef = useRef(timeSegment)
  const lastDateRangeCountRef = useRef(dateRangeCount)
  useEffect(() => {
    const { current: lastTimeSegment } = lastTimeSegmentRef
    const { current: lastDateRangeCount } = lastDateRangeCountRef
    if (timeSegment !== lastTimeSegment) {
      const segmentsInDay = getSegmentsPerOneOrThreeDays(
        timeSegment,
        dateRangeCount,
      )
      const lastSegmentsInDay = getSegmentsPerOneOrThreeDays(
        lastTimeSegment,
        lastDateRangeCount,
      )

      // Extra adjustment for changing date box widths
      const dateBoxWidthRatio =
        getDateBoxWidth(timeSegment, dateRangeCount) /
        getDateBoxWidth(lastTimeSegment, lastDateRangeCount)

      const offsetInMiddle = offsetRef.current + middleOfVisibleGrid
      const newOffsetInMiddle =
        (offsetInMiddle * segmentsInDay * dateBoxWidthRatio) / lastSegmentsInDay
      const newOffset = newOffsetInMiddle - middleOfVisibleGrid

      scrollToPosition(newOffset)
    }
    lastTimeSegmentRef.current = timeSegment
    lastDateRangeCountRef.current = dateRangeCount
  }, [
    dateRangeCount,
    fullGridWidth,
    scrollToPosition,
    timeSegment,
    visibleGridWidth,
    middleOfVisibleGrid,
  ])

  // Update visible grid day/date index
  const onSheetScroll: OnSheetScroll = useCallback(
    event => {
      const scrollOffset = event.nativeEvent.contentOffset.x
      offsetRef.current = scrollOffset

      const offsetPositionInGrid =
        (scrollOffset + middleOfVisibleGrid) / fullGridWidth

      const offsetPositionInDateRange = offsetPositionInGrid * dateRangeCount
      const newIdx = Math.floor(offsetPositionInDateRange)
      if (newIdx <= visibleDayListLength && newIdx >= 0) {
        setCurrentDateIdx(newIdx)
      }
    },
    [dateRangeCount, fullGridWidth, middleOfVisibleGrid, visibleDayListLength],
  )

  const scrollToDateIdx = useCallback(
    (index: number, animated = true) => {
      scrollToPosition((fullGridWidth * index) / dateRangeCount, animated)
    },
    [fullGridWidth, dateRangeCount, scrollToPosition],
  )

  const scrollToNow = useCallback(() => {
    const newDayRange = getDayRangeExtent(timeNow, dateRangeCount)
    setVisibleDayRange(newDayRange)
    const timeStart = startOfDay(newDayRange[0])
    const timeEnd = endOfDay(newDayRange[1])
    const positionInGrid =
      scaleTime().domain([timeStart, timeEnd]).range([0, fullGridWidth])(
        timeNow,
      ) ?? 0

    const newOffset = positionInGrid - middleOfVisibleGrid
    scrollToPosition(newOffset)
  }, [
    dateRangeCount,
    fullGridWidth,
    middleOfVisibleGrid,
    timeNow,
    scrollToPosition,
    setVisibleDayRange,
  ])

  const isReadyForInitialLayoutScrollToNow =
    visibleGridWidth > 1 && fullGridWidth > 1

  useEffect(() => {
    if (isReadyForInitialLayoutScrollToNow && !hasHadInitialScrollToNow) {
      scrollToNowOnInit()
      setHasHadInitialScrollToNow(true) // only do this once
    }
  }, [
    isReadyForInitialLayoutScrollToNow,
    hasHadInitialScrollToNow,
    scrollToNowOnInit,
  ])

  return {
    sheetRef,
    onSheetScroll,
    currentDateIdx,
    scrollToDateIdx,
    scrollToNow,
  }
}
