import React from 'react'
import { View, StyleSheet } from 'react-native'
import { HelpModalButton } from 'components/Help/HelpModalButton'
import { Colors, Variables } from 'src/design-system/theme'
import { useTranslation } from 'react-i18next'

type Props = {
  onCloseModal: () => void
  onSendFeedback: () => void
  sendFeedbackButtonDisabled?: boolean
}

export const HelpModalFooter: React.FC<Props> = ({
  onCloseModal,
  onSendFeedback,
  sendFeedbackButtonDisabled,
}) => {
  const { t } = useTranslation()
  return (
    <View style={styles.footer}>
      <View style={styles.footerContainer}>
        <View style={styles.footerWrapper}>
          <HelpModalButton
            title={t('help:cancelButtonText')}
            style={styles.cancelButtonStyle}
            onPress={onCloseModal}
            textStyle={styles.cancelButtonTextStyle}
          />

          <HelpModalButton
            disabled={sendFeedbackButtonDisabled}
            title={t('help:sendFeedbackButtonText')}
            onPress={onSendFeedback}
            style={styles.sendFeedbackButtonStyle}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  footer: {
    backgroundColor: Colors.Backgrounds.primary,
    borderTopWidth: Variables.BorderWidths.base,
    borderColor: Colors.Borders.primary,
  },
  footerContainer: {
    marginLeft: 'auto',
  },
  footerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: Variables.GutterSpacing.md,
    marginVertical: Variables.GutterSpacing.md,
  },
  cancelButtonStyle: {
    paddingHorizontal: Variables.GutterSpacing.md,
    paddingVertical: Variables.GutterSpacing.base,
  },
  cancelButtonTextStyle: {
    color: Colors.Contents.accent,
  },
  sendFeedbackButtonStyle: {
    paddingHorizontal: Variables.GutterSpacing.md,
    paddingVertical: Variables.GutterSpacing.base,
    marginLeft: Variables.GutterSpacing.base,
    backgroundColor: Colors.Backgrounds.accentPrimary,
  },
})
