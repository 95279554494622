import React, { useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { capitalize } from 'lodash'
import {
  Button,
  FormBreak,
  FormLabel,
  SecondaryButton,
  TextArea,
} from 'components/common'
import { Colors } from 'src/constants/Colors'
import { ApprovalRole, Roles, veterinary_roles } from 'types/globalTypes'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Status } from 'components/common/Form/Status'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { SelectOption } from 'src/components/common/Select/Select.types'
import { useConfirm } from 'src/context/confirm'
import { useUser } from 'src/context/user'
import { ControlledTextInput } from 'components/common/TextInput/ControlledTextInput'
import { ControlledSwitchInput } from 'components/common/SwitchInput/ControlledSwitchInput'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'
import { UserTimeout } from 'components/Settings/TimeoutOptions'
import { SuperUserEmails } from 'constants/constants'

const autoLogoutTimePickerOptions = [
  {
    value: UserTimeout.ONE_MIN,
    text: '1 min',
  },
  {
    value: UserTimeout.TWO_MIN,
    text: '2 min',
  },
  {
    value: UserTimeout.THREE_MIN,
    text: '3 min',
  },
  {
    value: UserTimeout.FOUR_MIN,
    text: '4 min',
  },
  {
    value: UserTimeout.FIVE_MIN,
    text: '5 min',
  },
  {
    value: UserTimeout.TEN_MIN,
    text: '10 min',
  },
  {
    value: UserTimeout.FIFTEEN_MIN,
    text: '15 min',
  },
  {
    value: UserTimeout.THIRTY_MIN,
    text: '30 min',
  },
  {
    value: UserTimeout.ONE_HOUR,
    text: '1 hr',
  },
  {
    value: UserTimeout.NO_LIMIT,
    text: 'No Limit',
  },
  {
    value: null,
    text: 'Hospital Default Value',
  },
]

const vetRoleOptions: SelectOption<veterinary_roles>[] = [
  {
    value: veterinary_roles.VET,
    text: 'Doctor',
  },
  {
    value: veterinary_roles.VETERINARY_TECH,
    text: 'Veterinary Tech',
  },
  {
    value: veterinary_roles.USER,
    text: 'Other',
  },
]

const approvalRoleOptions: SelectOption<ApprovalRole>[] = [
  {
    value: ApprovalRole.NOT_APPLICABLE,
    text: 'Not applicable',
  },
  {
    value: ApprovalRole.CAN_APPROVE,
    text: 'Can approve',
  },
  {
    value: ApprovalRole.NEEDS_APPROVAL,
    text: 'Needs approval',
  },
]

const autoSignoutTimeOptions: SelectOption<UserTimeout | null>[] =
  autoLogoutTimePickerOptions

const defaultValues = {
  departments: '',
  display_name: '',
  email: '',
  full_name: '',
  has_login: false,
  job_title: '',
  phone_number: '',
  role: Roles.USER,
  veterinary_role: veterinary_roles.VET,
  approval_role: ApprovalRole.NOT_APPLICABLE,
  auth_provider_id: null as null | string,
  temporary_password: null as null | string,
  auto_logout_timeout: null as null | number,
}

const getValidationSchema = (t: TFunction) => {
  const requiredMsg = t('form.required')

  return Yup.object().shape({
    departments: Yup.string(),
    display_name: Yup.string(),
    email: Yup.string().email().required(requiredMsg),
    has_login: Yup.bool().required(requiredMsg),
    full_name: Yup.string().required(requiredMsg),
    phone_number: Yup.string(),
    veterinary_role: Yup.string().required(requiredMsg),
    temporary_password: Yup.string()
      .min(10, t('settings:addUser:passwordLength'))
      .nullable(),
    auto_logout_timeout: Yup.number().nullable(),
  })
}

export type UserValues = typeof defaultValues

export type Props = {
  user?: UserValues
  onSubmit: (data: UserValues) => void
  onDelete?: () => void
  submitTitle: string
  submitting?: boolean
  isSelf?: boolean
}

const roleOrders: { [role in Roles]: number } = {
  [Roles.ADMIN]: 0,
  [Roles.USER]: 1,
  [Roles.BASIC]: 2,
}

export const UserForm: React.FC<Props> = ({
  isSelf = false,
  onDelete,
  onSubmit,
  submitting = false,
  submitTitle,
  user = {} as UserValues,
}) => {
  const { t } = useTranslation()
  const validationSchema = getValidationSchema(t)
  const initialValues = { ...defaultValues, ...user }
  const confirm = useConfirm()
  const [isDeleting, setIsDeleting] = useState(false)
  const { isAdmin } = useUser()
  const isSuperUser = SuperUserEmails.includes(user.email)
  const isNormalAdmin = isAdmin && !isSuperUser

  const filteredRoles = useMemo(() => {
    const roleOptions = (Object.keys(roleOrders) as Roles[])
      .sort((a, b) => roleOrders[a] - roleOrders[b])
      .map(role => ({
        text: capitalize(role),
        value: role,
      }))
    return roleOptions
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })
  const hasLogin = watch('has_login')
  const hasError = !isValid

  const isEditView = !!onDelete
  const isEmailEditable = !isEditView || !user.auth_provider_id || isNormalAdmin

  const handleDelete = async () => {
    try {
      await confirm({
        title: t('settings:deleteUser.label'),
        text: t('settings:deleteUser.confirm'),
      })
    } catch (_) {
      return
    }

    try {
      setIsDeleting(true)
      await onDelete!()
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <KeyboardAwareScrollView>
      <FormLabel text={t('settings:users:userDetails')} />
      <Status
        status={createErrorStatus(
          errors.full_name?.message,
          touchedFields.full_name,
        )}
      >
        <ControlledTextInput
          control={control}
          name="full_name"
          label={t('settings:addUser:fullName')}
        />
      </Status>
      <Status
        status={createErrorStatus(
          errors.display_name?.message,
          touchedFields.display_name,
        )}
      >
        <ControlledTextInput
          control={control}
          name="display_name"
          label={t('settings:addUser:displayName')}
        />
      </Status>
      <Status
        status={createErrorStatus(errors.email?.message, touchedFields.email)}
      >
        <ControlledTextInput
          control={control}
          label={t('settings:addUser:email')}
          name="email"
          disabled={!isEmailEditable}
        />
      </Status>
      <Status
        status={createErrorStatus(
          errors.phone_number?.message,
          touchedFields.phone_number,
        )}
      >
        <ControlledTextInput
          control={control}
          label={t('settings:addUser:phone')}
          name="phone_number"
        />
      </Status>
      <FormBreak />
      <FormLabel text={t('settings:users:userDetails')} />
      <ControlledSwitchInput
        control={control}
        name="has_login"
        label={t('settings:addUser:login')}
      />
      {!!hasLogin && (
        <Status
          status={createErrorStatus(
            errors.temporary_password?.message,
            touchedFields.temporary_password,
          )}
        >
          <ControlledTextInput
            control={control}
            label={t('settings:addUser:setPassword')}
            name="temporary_password"
          />
        </Status>
      )}
      <Status
        status={createErrorStatus(
          errors.auto_logout_timeout?.message,
          touchedFields.auto_logout_timeout,
        )}
      >
        <ControlledSelect
          control={control}
          label={t('settings:organisation:PINLock.title')}
          options={autoSignoutTimeOptions}
          name="auto_logout_timeout"
        />
      </Status>

      <FormBreak />
      <FormLabel text={t('settings:addUser:jobDetails')} />
      <ControlledTextInput
        control={control}
        label={t('account:general.jobTitle')}
        name="job_title"
      />

      <Status
        status={createErrorStatus(
          errors.veterinary_role?.message,
          touchedFields.veterinary_role,
        )}
      >
        <ControlledSelect
          control={control}
          label={t('settings:addUser:vetRole')}
          options={vetRoleOptions}
          name="veterinary_role"
        />
      </Status>

      <ControlledSelect
        control={control}
        label={t('settings:addUser:role')}
        options={filteredRoles}
        name="role"
      />

      <ControlledSelect
        name="approval_role"
        options={approvalRoleOptions}
        control={control}
        label={t('settings:addUser:approvalRole')}
      />

      <Status
        status={createErrorStatus(
          errors.departments?.message,
          touchedFields.departments,
        )}
      >
        <Controller
          control={control}
          name="departments"
          render={({ field: { onChange, value } }) => (
            <TextArea
              label={t('settings:addUser:dept')}
              value={value}
              onChangeText={onChange}
              visibleLines={6}
            />
          )}
        />
      </Status>
      <Button
        onPress={handleSubmit(onSubmit)}
        title={submitTitle}
        loading={submitting}
        color={Colors.green}
        style={styles.button}
        disabled={hasError}
      />
      {isEditView && !isSelf && (
        <SecondaryButton
          accessibilityLabel={t('settings:deleteUser.label')}
          onPress={handleDelete}
          title={t('settings:deleteUser.label')}
          loading={isDeleting}
        />
      )}
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  button: {
    marginVertical: 16,
  },
})
