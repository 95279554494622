import { pick } from 'lodash'
import { environment } from 'src/config'
import { ReleaseStage } from 'src/config/environment'
import { Roles, veterinary_roles } from 'src/types/globalTypes'

enum UserPropertiesKeys {
  EMAIL = 'email',
  FULL_NAME = 'full_name',
  ID = 'id',
  ROLE = 'role',
  VETERINARY_ROLE = 'veterinary_role',
  ORGANISATION_ID = 'organisation_id',
}

type UserProperties = {
  [UserPropertiesKeys.EMAIL]: string
  [UserPropertiesKeys.FULL_NAME]: string
  [UserPropertiesKeys.ID]: string
  [UserPropertiesKeys.ROLE]: Roles
  [UserPropertiesKeys.VETERINARY_ROLE]: veterinary_roles
  [UserPropertiesKeys.ORGANISATION_ID]: string
}

type EventProperties = {
  [key: string]: any
}

type AppcuesType = {
  identify: (userId: string, userProperties?: UserProperties) => void // Identify the user and determine if they should see Appcues content
  page: () => void // Notify Appcues that the page has changed and it should check again for content
  track: (eventName: string, eventProperties?: EventProperties) => void // Track actions users take in your application
}

declare global {
  interface Window {
    Appcues: AppcuesType
  }
}

// Since Appcues's state is in window.Appcues, it's safe to use const to group the Appcues methods.
export const Appcues = {
  isAllowed: () => {
    const isDev = environment.releaseStage === ReleaseStage.localDev
    return environment.isWeb && !isDev
  },
  isLoaded: () => !!window.Appcues,
  load: () => {
    if (!Appcues.isAllowed() || Appcues.isLoaded()) {
      return
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = environment.appcuesURL
    document.head.appendChild(script)
  },
  identifyUser(rawUser: UserProperties) {
    const user = Appcues.filterUserProperties(rawUser)
    window.Appcues?.identify(user.email, user)
  },
  reinitializeOnPageChanged() {
    window.Appcues?.page()
  },
  trackEvent(eventName: string) {
    window.Appcues?.track(eventName)
  },
  filterUserProperties(user: UserProperties): UserProperties {
    // filter out the extra fields of user info
    const allowedKeys = Object.values(UserPropertiesKeys)
    return pick(user, allowedKeys)
  },
}
