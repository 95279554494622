import { useState, useRef, useEffect } from 'react'

export const useMouseHover = () => {
  const [isHovered, setIsHovered] = useState(false)

  // Any component could use this ref
  const hoverRef = useRef<any>(null)

  useEffect(() => {
    const element = hoverRef?.current

    element?.addEventListener('mouseenter', () => {
      setIsHovered(true)
    })
    element?.addEventListener('mouseleave', () => {
      setIsHovered(false)
    })

    return () => {
      element?.removeEventListener('mouseleave', () => {})
      element?.removeEventListener('mouseenter', () => {})
    }
  }, [hoverRef, setIsHovered])

  return { hoverRef, isHovered }
}
