import React from 'react'
import {
  Text,
  StyleSheet,
  type TextProps as ReactNativeTextProps,
} from 'react-native'
import { Colors } from 'src/design-system/theme'
import {
  FontFamilies,
  FontWeights,
  FontSizes,
  LineHeightMultiplier,
} from 'src/design-system/theme/typography/base'

type ParagraphProps = ReactNativeTextProps & { size: 'L' | 'M' | 'S' }
export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  style,
  size = 'M',
  ...rest
}) => {
  return (
    <Text style={[styles[size], style]} {...rest}>
      {children}
    </Text>
  )
}

/* eslint-disable react-native/no-unused-styles */
// Accessed Dynamically
const styles = StyleSheet.create({
  L: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.md,
    lineHeight: FontSizes.md * LineHeightMultiplier.lg,
    color: Colors.Contents.primary,
  },
  M: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.base,
    lineHeight: FontSizes.base * LineHeightMultiplier.lg,
    color: Colors.Contents.primary,
  },
  S: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.sm,
    lineHeight: FontSizes.sm * LineHeightMultiplier.lg,
    color: Colors.Contents.primary,
  },
})
