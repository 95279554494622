import { cloneDeep } from 'lodash'
import { useApolloClient } from '@apollo/client'

import { WHITEBOARD_PATIENT_FIELDS } from './graphql'
import { ConsultationStatus } from 'src/types/globalTypes'
import { PatientFieldsSummary } from 'src/types/PatientFieldsSummary'

export const useUpdatePatientListCache = () => {
  const client = useApolloClient()
  return (
    data: PatientFieldsSummary,
    mutation: 'CREATE' | 'DELETE' | 'UPDATE',
  ) => {
    if (!data) {
      return
    }

    const cacheContents = client.cache.extract()
    const cacheIds = Object.keys(cacheContents).filter(item =>
      item.startsWith(`WhiteboardPatient:{"patient_id":"${data.id}"`),
    )
    cacheIds.forEach(id => {
      const whiteboardPatient = client.readFragment({
        id,
        fragment: WHITEBOARD_PATIENT_FIELDS,
      })

      if (!whiteboardPatient) {
        return
      }
      const clonedWhiteboardPatient = cloneDeep(whiteboardPatient)
      if (mutation === 'UPDATE') {
        clonedWhiteboardPatient.patient_name = data.name
        clonedWhiteboardPatient.patient_weight = data.weight
        clonedWhiteboardPatient.patient_weight_unit = data.weight_unit
        const consultationStatus = (
          data.active_consultations?.items ?? []
        ).every(item => item.status === ConsultationStatus.discharged)
          ? ConsultationStatus.discharged
          : ConsultationStatus.admitted
        clonedWhiteboardPatient.consultation_status = consultationStatus
        clonedWhiteboardPatient.consultation_color = data.color
        clonedWhiteboardPatient.patient_resuscitate = data.resuscitate
        clonedWhiteboardPatient.patient_order = data.order
        clonedWhiteboardPatient.patient_avatar_url = data.avatar_url
      } else if (mutation === 'DELETE') {
        clonedWhiteboardPatient.consultation_status =
          ConsultationStatus.discharged
      }
      client.writeFragment({
        id,
        fragment: WHITEBOARD_PATIENT_FIELDS,
        data: clonedWhiteboardPatient,
      })
    })
    return
  }
}
