import { TFunction } from 'react-i18next'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as Task } from 'src/types/getSheet'
import { isOptimisticId } from 'src/utils/optimisticId'
import { Status } from 'types/globalTypes'

import { FluidAction } from '../types'

enum FluidTaskProcess {
  isPending = 'isPending',
  isStarted = 'isStarted',
  isReadOnly = 'isReadOnly',
}

// in FluidGridTreatmentLeaf.tsx findWorkingFluidTask, we cannot trigger other tasks when fluid is started
const getFluidTaskProcess = (task?: Task) => {
  if (!task) {
    return
  }
  const isFluidFinished = task.status === Status.DONE
  const isOptimisticTask = isOptimisticId(task.id)
  if (isFluidFinished || isOptimisticTask) {
    return FluidTaskProcess.isReadOnly
  }

  const isFluidTaskStarted = task.status === Status.IN_PROGRESS

  if (!isFluidTaskStarted) {
    return FluidTaskProcess.isPending
  }

  return FluidTaskProcess.isStarted
}

export const getFluidTaskActions = ({
  t,
  task,
}: {
  t: TFunction
  task?: Task
  isCRI?: boolean
}) => {
  const fluidTaskProcess = getFluidTaskProcess(task)

  const stopFluidAction = {
    value: FluidAction.STOP,
    text: t('task:taskAddEdit:fluids:stopFluid'),
  }

  const startFluidAction = {
    value: FluidAction.START,
    text: t('task:taskAddEdit:fluids:startFluid'),
  }

  const scheduleFluidAction = {
    value: FluidAction.SCHEDULE_FLUID,
    text: t('task:taskAddEdit:fluids:scheduleFluid'),
  }

  const changeFluidAction = {
    value: FluidAction.CHANGE_FLUID_RATE,
    text: t('task:taskAddEdit:fluids:changeRate'),
  }

  const editFluidAction = {
    value: FluidAction.EDIT_FLUID,
    text: t('task:taskAddEdit:fluids:editFluid'),
  }

  // TODO: temporary solution for CRI calculator updating, after VR-7203, we need to bring it back
  switch (fluidTaskProcess) {
    case FluidTaskProcess.isPending:
      return [startFluidAction, scheduleFluidAction, editFluidAction]
    case FluidTaskProcess.isStarted:
      return [changeFluidAction, stopFluidAction, scheduleFluidAction]
    default:
      return []
  }
}
