import { SheetTypeDisplayValueTranslationKey } from 'components/CreateSheet/createSheetData'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useDefaultSheetName = (
  isAnesthesia: boolean,
  sheetNameList: string[],
) => {
  const { t } = useTranslation()
  return useMemo(() => {
    let keyword = t(
      `sheetForm.sheetTypes.${SheetTypeDisplayValueTranslationKey.NORMAL}`,
    )
    if (isAnesthesia) {
      keyword = t(
        `sheetForm.sheetTypes.${SheetTypeDisplayValueTranslationKey.ANAESTHESIA}`,
      )
    }
    if (!sheetNameList.includes(keyword)) return keyword

    let sheetIndex = 1

    while (sheetNameList.includes(`${keyword} ${sheetIndex}`)) {
      sheetIndex = sheetIndex + 1
    }

    return `${keyword} ${sheetIndex}`
  }, [isAnesthesia, sheetNameList, t])
}
