import React from 'react'

import { Layout, toast } from 'components/common'
import { compact, trim, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { Routes } from 'src/constants/Routes'
import { useOrganisation } from 'src/context/organisation'

import { SubHeader } from '../SubHeader/SubHeader'
import { UserForm, UserValues } from './UserForm'
import { UserCreateInput } from 'src/types/globalTypes'
import { SettingsStackParamList } from 'components/Settings/screens'

import { useCreateUser } from './useCreateUser'

type Props = StackScreenProps<SettingsStackParamList>

export const AddUserScreen: React.FC<Props> = props => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { navigation } = props
  const { navigate } = navigation

  const [createUser, { loading: submitting }] = useCreateUser()

  const backButton = {
    action: () => navigate(Routes.UserList),
    label: 'settings:users.returnTo',
    title: 'settings:users.title',
  }

  const createAndNavBack = (data: UserValues) => {
    const input: UserCreateInput = {
      allow_login: data.has_login,
      departments: compact(data.departments.split(',').map(trim)),
      display_name: isEmpty(data.display_name)
        ? data.full_name
        : data.display_name,
      email: data.email,
      full_name: data.full_name,
      job_title: data.job_title,
      organisation_id: organisationId,
      phone_number: data.phone_number,
      // We don't need to convert the role using getUserRole since it is form data sent to server
      role: data.role,
      veterinary_role: data.veterinary_role,
    }

    createUser({
      variables: { input },
    })
      .then(() => {
        toast.success(
          t('settings:addUser.success', { name: input.display_name }),
        )
      })
      .then(() => navigate(Routes.UserList))
      .catch(err => toast.error(err.message))
  }

  return (
    <>
      <SubHeader headlineKey="settings:addUser.title" backButton={backButton} />
      <Layout mode="single-center">
        <UserForm
          onSubmit={createAndNavBack}
          submitting={submitting}
          submitTitle={t('settings:addUser.submitTitle')}
        />
      </Layout>
    </>
  )
}
