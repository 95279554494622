import React, { useState } from 'react'
import {
  Image,
  ImageStyle,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native'
import { AvatarInitials } from './AvatarInitials'

type Props = {
  uri?: string
  name?: string
  size?: number
  radius?: number
  style?: StyleProp<ViewStyle & ImageStyle>
}
/**
 * Use when uri exists. Otherwise, use name initials (first 3 characters).
 */
export const Avatar: React.FC<Props> = ({
  uri,
  name,
  size = 50,
  radius = 6,
  style,
}) => {
  const [isImageBroken, setIsImageBroken] = useState(false)
  const onError = () => setIsImageBroken(true)

  const avatarStyle = StyleSheet.compose<ImageStyle, ImageStyle, ImageStyle>(
    {
      height: size,
      width: size,
      borderTopRightRadius: radius,
      borderTopLeftRadius: radius,
      borderBottomRightRadius: radius,
      borderBottomLeftRadius: radius, // We destructure borderRadius so it can be individually controlled/overwritten
    },
    style,
  )

  if (uri && !isImageBroken) {
    return (
      <Image
        style={avatarStyle}
        source={{ uri }}
        accessibilityLabel={name}
        onError={onError}
      />
    )
  }
  return <AvatarInitials name={name} size={size} style={avatarStyle} />
}
