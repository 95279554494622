import React from 'react'
import { Text, StyleSheet, TextProps } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'

interface SubheadingProps extends React.PropsWithChildren<TextProps> {
  disabled?: boolean
  testID?: string
}

export const SubHeading: React.FC<SubheadingProps> = ({
  children,
  disabled,
  style,
  ...rest
}) => {
  if (!children) return null
  return (
    <Text
      style={[styles.subHeading, disabled ? styles.disabled : undefined, style]}
      {...rest}
    >
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  subHeading: {
    ...Typography.Label.M,
    color: Colors.Contents.tertiary,
  },
  disabled: {
    color: Colors.Interactive.disabledPrimary,
  },
})
