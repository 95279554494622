import React from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'

import { DraggableListProps, ListItemT, reorder } from './common'
import { noop } from 'lodash'

export const DraggableList = <T extends ListItemT>({
  items,
  renderItem,
  onMoveEnd,
  ListHeaderComponent,
  ListFooterComponent,
  isNestedDraggable,
  isDragDisabled,
}: DraggableListProps<T>) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    if (result.destination.index === result.source.index) {
      return
    }
    const reorderedTreatments = reorder(
      items,
      result.source.index,
      result.destination.index,
    )

    onMoveEnd(reorderedTreatments)
  }

  return (
    <div style={isNestedDraggable ? {} : { overflowY: 'auto' }}>
      <>
        {ListHeaderComponent}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, index) => (
                  <Draggable
                    isDragDisabled={isDragDisabled}
                    draggableId={item.id}
                    index={index}
                    key={item.id}
                  >
                    {innerProvided => (
                      <div
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        {renderItem({
                          item,
                          // @ts-ignore
                          index,
                          // just for types
                          drag: noop,
                          isActive: false,
                        })}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {ListFooterComponent}
      </>
    </div>
  )
}
