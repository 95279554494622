import { toast } from 'components/common'
import {
  getCustomProductCSV,
  getCustomProductCSV_getOrganisation_products_items,
} from 'src/types/getCustomProductCSV'
import { getCustomProductsVariables } from 'src/types/getCustomProducts'
import { GET_CUSTOM_PRODUCT_CSV, GET_SHEET_GROUPS } from './graphql'
import { FieldInfo, parseAsync } from 'json2csv'
import { isEmpty } from 'lodash'
import { ContentType, download as downloadFile } from 'src/utils/download'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useClientQueryPromise } from 'src/utils/useClientQueryPromise'
import { PageInput } from 'src/types/globalTypes'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import {
  getSheetGroups as GetSheetGroups,
  getSheetGroupsVariables as GetSheetGroupsVariables,
} from 'types/getSheetGroups'

type GetCustomMedicationCSV = {
  name: string
  type: string | null
  sheet_group: string | null
  dest: string | null
  value_required: string | null
  notes_required: string | null
  custom_values: string | null
  description: string | null
  calculate_base?: string | null
  route_of_administration?: string | null
  concentration?: number | null
  concentration_weight_unit?: string | null
  concentration_volume_unit?: string | null
  is_diluted?: string | null
  diluted_concentration?: number | null
  diluted_concentration_volume_unit?: string | null
  diluted_concentration_weight_unit?: string | null
  dosage?: number | null
  dosage_weight_unit?: string | null
  dosage_patient_weight_unit?: string | null
}

type OrganisationIdAndPageInput = {
  organisationId: string
  productPageInput: PageInput
}

const CustomProductCSVFieldMappings: FieldInfo<unknown>[] = [
  {
    label: 'Product Name',
    value: 'name',
  },
  {
    label: 'Product Type',
    value: 'type',
  },
  {
    label: 'Sheet Group',
    value: 'sheet_group',
  },
  {
    label: 'Clinical record note mapping',
    value: 'dest',
  },
  {
    label: 'Mandatory value',
    value: 'value_required',
  },
  {
    label: 'Mandatory notes',
    value: 'notes_required',
  },
  {
    label: 'Has custom value',
    value: 'custom_values',
  },
  {
    label: 'Calculate based on',
    value: 'calculate_base',
  },
  {
    label: 'Route of administration',
    value: 'route_of_administration',
  },
  {
    label: 'Concentration',
    value: 'concentration',
  },
  {
    label: 'Concentration unit 1',
    value: 'concentration_weight_unit',
  },
  {
    label: 'Concentration unit 2',
    value: 'concentration_volume_unit',
  },
  {
    label: 'Apply diluted concentration',
    value: 'is_diluted',
  },
  {
    label: 'Diluted concentration',
    value: 'diluted_concentration',
  },
  {
    label: 'Diluted concentration unit 1',
    value: 'diluted_concentration_weight_unit',
  },
  {
    label: 'Diluted concentration unit 2',
    value: 'diluted_concentration_volume_unit',
  },
  {
    label: 'Dose rate',
    value: 'dosage',
  },
  {
    label: 'Dose rate unit 1',
    value: 'dosage_weight_unit',
  },
  {
    label: 'Dose rate unit 2',
    value: 'dosage_patient_weight_unit',
  },
  {
    label: 'Product description',
    value: 'description',
  },
]

export const useCustomProductsDownloader = ({
  organisationId,
  productPageInput,
}: OrganisationIdAndPageInput) => {
  const loadingToast = useRef<null | (() => void)>(null)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const { t } = useTranslation()
  const { organisation } = useOrganisationHook()

  const getCustomProduct = useClientQueryPromise<
    getCustomProductCSV,
    getCustomProductsVariables
  >({
    query: GET_CUSTOM_PRODUCT_CSV,
    fetchPolicy: 'no-cache',
  })

  const getSheetGroups = useClientQueryPromise<
    GetSheetGroups,
    GetSheetGroupsVariables
  >({ query: GET_SHEET_GROUPS, fetchPolicy: 'network-only' })

  const getSheetGroupsMapping = useCallback(
    async (organisationId: string) => {
      const { data } = await getSheetGroups({ id: organisationId })
      const sheetGroups = data?.getOrganisation?.products?.items ?? []
      return sheetGroups.reduce(
        (sheetGroupMap: { [key: string]: string }, { id, name }) => {
          sheetGroupMap[id] = name
          return sheetGroupMap
        },
        {},
      )
    },
    [getSheetGroups],
  )

  const buildCustomerProductContentCSV = (
    customProducts: GetCustomMedicationCSV[],
  ) =>
    parseAsync(customProducts, {
      fields: CustomProductCSVFieldMappings,
      header: true,
    })

  const convertPimsToDest = (
    product: getCustomProductCSV_getOrganisation_products_items,
  ) => {
    // custom pruduct only has one `pims_mapping`
    switch (product.pims_mapping?.[0]?.dest) {
      case 'hospital_notes':
        return 'Hospital Notes'
      case 'healthstatus':
        return 'Health Status'
      case 'physical_exams':
        return 'Physical Exams'
      case 'assessments':
        return 'Assessments'
      case 'in_clinic_notes':
        return 'In Clinic Notes'
      case 'none':
        return 'None'
      default:
        return 'Hospital Notes'
    }
  }

  useEffect(() => {
    if (isDownloading) {
      loadingToast.current = toast.disconnected(
        t('settings:addProduct:customProductDownloading'),
      )
      return
    }

    loadingToast.current?.()
    loadingToast.current = null
  }, [isDownloading, t])

  const downloadCustomProductCSV = useCallback(async () => {
    if (isDownloading) return

    setIsDownloading(true)

    try {
      const [
        {
          data: { getOrganisation: customProducts },
        },
        sheetGroupsMap,
      ] = await Promise.all([
        getCustomProduct({
          id: organisationId,
          productPageInput,
        }),
        getSheetGroupsMapping(organisationId),
      ])

      const products = customProducts?.products?.items ?? []
      const productsForDownload = products?.map(product => {
        return {
          name: product.name,
          type: product.type,
          sheet_group: sheetGroupsMap[product.parent_product_id] ?? '',
          value_required: product.is_value_required ? 'Yes' : 'No',
          notes_required: product.is_notes_required ? 'Yes' : 'No',
          custom_values: isEmpty(product.custom_values) ? 'No' : 'Yes',
          description: product.description,
          dest: convertPimsToDest(product),
          calculate_base: product.medicine_dosage_info?.calculate_base,
          route_of_administration:
            product.medicine_dosage_info?.route_of_administration,
          concentration: product.medicine_dosage_info?.concentration,
          concentration_weight_unit:
            product.medicine_dosage_info?.concentration_weight_unit,
          concentration_volume_unit:
            product.medicine_dosage_info?.concentration_volume_unit,
          is_diluted: product.medicine_dosage_info?.is_diluted ? 'Yes' : 'No',
          diluted_concentration:
            product.medicine_dosage_info?.diluted_concentration,
          diluted_concentration_volume_unit: product.medicine_dosage_info
            ?.diluted_concentration
            ? product.medicine_dosage_info?.diluted_concentration_volume_unit
            : '',
          diluted_concentration_weight_unit: product.medicine_dosage_info
            ?.diluted_concentration
            ? product.medicine_dosage_info?.diluted_concentration_weight_unit
            : '',
          dosage: product.medicine_dosage_info?.dosage,
          dosage_weight_unit: product.medicine_dosage_info?.dosage_weight_unit,
          dosage_patient_weight_unit:
            product.medicine_dosage_info?.dosage_patient_weight_unit,
        }
      })

      const fileName = organisation?.name
        ? organisation?.name.concat(' Custom Products')
        : t('settings:addProduct:customProducts')

      const customerProductCSV = await buildCustomerProductContentCSV(
        productsForDownload,
      )

      downloadFile(customerProductCSV, fileName, ContentType.CSV)
    } catch (_) {
      toast.error(t('settings:addProduct:downloadSheetChangeLogFailure'))
    }
    setIsDownloading(false)
  }, [
    isDownloading,
    t,
    productPageInput,
    getCustomProduct,
    organisationId,
    organisation?.name,
    getSheetGroupsMapping,
  ])

  return downloadCustomProductCSV
}
