import React from 'react'
import { Image } from 'components/common'
import { useQuery } from '@apollo/client'
import {
  GestureResponderEvent,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { Layout } from 'src/constants/Layout'
import { useOrganisation } from 'src/context/organisation'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useKeyToUrl } from 'src/hooks/useKeyToUrl'
import {
  getPatient as GetPatient,
  getPatient_getPatient as GetPatientData,
  getPatientVariables as GetPatientVariables,
} from 'src/types/getPatient'
import { Resuscitation } from 'src/types/globalTypes'
import { ResponsiveStylesheet } from 'src/utils/responsive-stylesheet'
import { getPatientSexTagTitle } from 'components/PatientItem/utils/getPatientSex'
import { Tag } from '../Tag/Tag'
import { GET_PATIENT } from './graphql'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'

type Props = {
  color?: string
  onPress?: (event: GestureResponderEvent) => void
  patientId: string
}

export const PatientInfoWithQuery: React.FC<Props> = ({
  color = Colors.white,
  onPress,
  patientId,
}) => {
  useBreakpoint()
  const [{ organisationId }] = useOrganisation()

  const queryResult = useQuery<GetPatient, GetPatientVariables>(GET_PATIENT, {
    variables: {
      organisationId,
      id: patientId,
    },
  })

  const patient = queryResult?.data?.getPatient ?? null

  if (!patient) return null

  return <PatientInfo patient={patient} color={color} onPress={onPress} />
}

type PatientInfoProps = Omit<Props, 'patientId'> & {
  patient: GetPatientData
}

export const PatientInfo: React.FC<PatientInfoProps> = ({
  color = Colors.white,
  onPress,
  patient,
}) => {
  const { isLargeScreen } = useBreakpoint()
  const patientImage = useKeyToUrl(patient?.avatar_url, 100)

  const textStyle = { color }
  const sexTagTitle = getPatientSexTagTitle(patient)
  const content = !patient ? null : (
    <>
      {patientImage ? (
        <Image
          style={[styles.avatar, responsiveStyles.avatar]}
          source={{ uri: patientImage }}
        />
      ) : (
        <View style={[styles.avatar, responsiveStyles.avatar]} />
      )}
      <View style={[styles.info, responsiveStyles.info]}>
        <View style={styles.nameRow}>
          <TextWithTooltip
            style={[styles.name, textStyle]}
            numberOfLines={1}
            title={patient.name}
          >
            {patient.name}
          </TextWithTooltip>
          <Text style={[styles.firstName, textStyle, { paddingLeft: 4 }]}>
            {patient.contact ? patient.contact.last_name : ''}
          </Text>
          {!!patient.weight && (
            <Text style={[styles.weightText, textStyle]}>
              {patient.weight}
              {patient.weight_unit}
            </Text>
          )}
        </View>
        <View style={styles.extra}>
          {patient.sex && <Tag title={sexTagTitle} style={styles.tag} />}
          {patient.resuscitate === Resuscitation.NO && (
            <Tag title="DNR" style={styles.tag} />
          )}
          {patient.attitude && (
            <Tag title={patient.attitude as any} style={styles.tag} />
          )}
          {patient.species && isLargeScreen && (
            <TextWithTooltip
              style={[styles.regularText, textStyle]}
              numberOfLines={1}
              title={patient.species.name}
            >
              {patient.species.name}
            </TextWithTooltip>
          )}
        </View>
      </View>
    </>
  )
  if (onPress) {
    return (
      <TouchableOpacity
        accessibilityLabel={'Patient Info Button'}
        style={[styles.container, responsiveStyles.container]}
        onPress={onPress}
      >
        {content}
      </TouchableOpacity>
    )
  }
  return (
    <View
      accessibilityLabel={'Patient Info'}
      style={[styles.container, responsiveStyles.container]}
    >
      {content}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '52%',
    justifyContent: 'center',
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 6,
  },
  info: {
    flexShrink: 1,
    height: 40,
    justifyContent: 'flex-end',
    marginLeft: 8,
    // flex in native doesn't behave the same as web, move a little bit some here to get it aligned..
    bottom: Platform.OS === 'web' ? 0 : -1,
  },
  nameRow: {
    alignItems: 'center',
    marginBottom: -2,
    flexDirection: 'row',
    flexShrink: 1,
  },
  name: {
    fontFamily: Fonts.bold,
    fontSize: 16,
    flexShrink: 1,
  },
  tag: {
    marginRight: 3,
  },
  firstName: {
    fontFamily: Fonts.regular,
    fontSize: 16,
    marginRight: 'auto',
  },
  weightText: {
    fontFamily: Fonts.bold,
    fontSize: 12,
    paddingLeft: 8,
    paddingTop: 2,
  },
  regularText: {
    fontFamily: Fonts.regular,
    paddingLeft: 8,
  },
  extra: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 'auto',
  },
})

const responsiveStyles = ResponsiveStylesheet.createSized('min-width', {
  [Layout.lg]: {
    container: {
      width: 'auto',
    },
    avatar: {
      width: 50,
      height: 50,
    },
    info: {
      height: 50,
      justifyContent: 'space-between',
    },
  },
})
