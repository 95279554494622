import { gql } from '@apollo/client'
import {
  CUSTOM_PRODUCT_FIELDS,
  MEDICATION_FIELDS,
} from 'components/Settings/graphql'

export const TEMPLATE_TOP_FIELDS = gql`
  fragment TemplateTopFields on Template {
    id
    name
    description
    disabled
    use_case
    template_workflow_id
    user {
      id
      full_name
    }
    sites {
      id
      name
    }
  }
`

export const TEMPLATE_TREATMENT_FIELDS = gql`
  fragment TemplateTreatmentFields on TemplateTreatment {
    id
    name
    created_at
    product_id
    conditional
    node_type
    instructions
    is_billable
    is_auto_completed
    schedule {
      enable_staffed_hour
      frequencies
      frequency
      repeat
      repeat_until
      start_at
      time_window
      treatment_frequency_id
      type
    }
    medicine_dosage_info {
      dosage
      dosage_weight_unit
      dosage_patient_weight_unit
      is_diluted
      diluted_concentration
      concentration
      concentration_volume_unit
      concentration_weight_unit
      concentration_volume_unit
      route_of_administration
      lowest_dosage
      highest_dosage
    }
    order
    treatment_options {
      products {
        ...CustomProductFields
        ...MedicationFields
        cubex_integrations {
          cubex_cabinet_id
          is_cubex_billable
        }
        code
        sites
      }
    }
    type
  }
  ${CUSTOM_PRODUCT_FIELDS}
  ${MEDICATION_FIELDS}
`

export const TEMPLATE_TREATMENT_WITH_PRODUCT_FIELDS = gql`
  fragment TemplateTreatmentWithProductFields on TemplateTreatment {
    ...TemplateTreatmentFields
    product {
      id
      parent_product {
        name
        id
        order
      }
    }
  }
  ${TEMPLATE_TREATMENT_FIELDS}
`

export const TEMPLATE_TREATMENT_GROUP_FIELDS = gql`
  fragment TemplateGroupFields on TemplateTreatment {
    conditional
    created_at
    id
    name
    node_type
    product_id
    treatments {
      items {
        ...TemplateTreatmentFields
      }
    }
  }
  ${TEMPLATE_TREATMENT_FIELDS}
`
export const TEMPLATE_TREATMENT_GROUP_FIELDS_WITH_PRODUCT = gql`
  fragment TemplateGroupFieldsWithProduct on TemplateTreatment {
    conditional
    created_at
    id
    name
    node_type
    product_id
    treatments {
      items {
        ...TemplateTreatmentWithProductFields
      }
    }
  }
  ${TEMPLATE_TREATMENT_WITH_PRODUCT_FIELDS}
`

export const TEMPLATE_FIELDS_WITH_PRODUCT = gql`
  fragment templateFieldsWithProduct on Template {
    ...TemplateTopFields
    treatments {
      items {
        ...TemplateGroupFieldsWithProduct
      }
    }
  }
  ${TEMPLATE_TOP_FIELDS}
  ${TEMPLATE_TREATMENT_GROUP_FIELDS_WITH_PRODUCT}
`

export const TEMPLATE_FIELDS = gql`
  fragment templateFields on Template {
    ...TemplateTopFields
    treatments {
      items {
        ...TemplateGroupFields
      }
    }
  }
  ${TEMPLATE_TOP_FIELDS}
  ${TEMPLATE_TREATMENT_GROUP_FIELDS}
`

export const GET_TEMPLATE_WITH_PRODUCTS = gql`
  query getTemplateWithProducts($id: ID!, $organisation_id: ID!) {
    getTemplate(id: $id, organisation_id: $organisation_id) {
      ...templateFieldsWithProduct
    }
  }
  ${TEMPLATE_FIELDS_WITH_PRODUCT}
`

// Due to limitation of can't ignore optional arguments?!
// So we need create two getTemplates one for active, another for all.
// https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/241
export const GET_ACTIVE_TREATMENT_TEMPLATES = gql`
  query getActiveTemplates($organisation_id: ID!, $site_ids: [String!]) {
    getTemplates(
      organisation_id: $organisation_id
      disabled: false
      site_ids: $site_ids
    ) {
      items {
        ...TemplateTopFields
      }
    }
  }
  ${TEMPLATE_TOP_FIELDS}
`

export const GET_NOT_HIDDEN_ACTIVE_TREATMENT_TEMPLATES = gql`
  query getNotHiddenActiveTemplates(
    $organisation_id: ID!
    $site_ids: [String!]
  ) {
    getTemplates(
      organisation_id: $organisation_id
      disabled: false
      hideHidden: true
      site_ids: $site_ids
    ) {
      items {
        ...TemplateTopFields
      }
    }
  }
  ${TEMPLATE_TOP_FIELDS}
`

export const GET_TREATMENT_TEMPLATES = gql`
  query getTemplates($organisation_id: ID!) {
    getTemplates(organisation_id: $organisation_id) {
      items {
        ...TemplateTopFields
      }
    }
  }
  ${TEMPLATE_TOP_FIELDS}
`

export const CREATE_TEMPLATE = gql`
  mutation createTemplate($input: CreateTemplateInput!) {
    createTemplate(input: $input) {
      id
      name
      disabled
      description
      use_case
    }
  }
`

export const COPY_TREATMENT_TEMPLATE = gql`
  mutation copyTemplate($input: CopyTemplateInput!) {
    copyTemplate(input: $input) {
      ...TemplateTopFields
    }
  }
  ${TEMPLATE_TOP_FIELDS}
`

export const GET_TEMPLATE = gql`
  query getTemplate($id: ID!, $organisation_id: ID!) {
    getTemplate(id: $id, organisation_id: $organisation_id) {
      ...templateFields
    }
  }
  ${TEMPLATE_FIELDS}
`

export const GET_TEMPLATE_TREATMENT_IDS = gql`
  query getTemplateTreatmentIds($id: ID!, $organisationId: ID!) {
    getTemplate(id: $id, organisation_id: $organisationId) {
      id
      treatments {
        items {
          id
          treatments {
            items {
              id
              product_id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_TEMPLATE_TEMPLATE_WORKFLOW_ID = gql`
  query getTemplateTemplateWorkflowId($id: ID!, $organisation_id: ID!) {
    getTemplate(id: $id, organisation_id: $organisation_id) {
      template_workflow_id
      name
    }
  }
`

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($input: UpdateTemplateInput!) {
    updateTemplate(input: $input) {
      ...templateFields
    }
  }
  ${TEMPLATE_FIELDS}
`

export const CREATE_TEMPLATE_TREATMENT = gql`
  mutation createTemplateTreatment($input: CreateTemplateTreatmentInput!) {
    createTemplateTreatment(input: $input) {
      conditional
      created_at
      id
      name
      node_type
      order
      product_id
      is_auto_completed
      type
      treatments {
        items {
          id
          name
          product_id
          created_at
          conditional
          node_type
          order
        }
      }
    }
  }
`

export const UPDATE_TEMPLATE_TREATMENT = gql`
  mutation updateTemplateTreatment($input: UpdateTemplateTreatmentInput!) {
    updateTemplateTreatment(input: $input) {
      id
      name
      product_id
      created_at
      conditional
      order
      is_billable
      is_auto_completed
    }
  }
`

export const DELETE_TEMPLATE_TREATMENT = gql`
  mutation deleteTemplateTreatment($input: DeleteTemplateTreatmentInput!) {
    deleteTemplateTreatment(input: $input)
  }
`

export const DELETE_TREATMENT_TEMPLATE = gql`
  mutation deleteTemplate($input: DeleteTemplateInput!) {
    deleteTemplate(input: $input)
  }
`
