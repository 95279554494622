import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from 'src/apollo/client'

export const withApolloProvider = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return (props: P) => (
    // ApolloProvider can be removed after full on apollo hooks
    <ApolloProvider client={client}>
      <WrappedComponent {...props} />
    </ApolloProvider>
  )
}
