import { useMutation, useQuery } from '@apollo/client'
import {
  getTreatmentFrequencies,
  getTreatmentFrequenciesVariables,
} from 'types/getTreatmentFrequencies'
import {
  CREATE_TREATMENT_FREQUENCIES,
  DELETE_TREATMENT_FREQUENCY,
  GET_TREATMENT_FREQUENCIES,
  GET_TREATMENT_FREQUENCY,
  UPDATE_TREATMENT_FREQUENCY,
} from 'components/Settings/graphql'
import { toast } from 'components/common'
import {
  deleteTreatmentFrequency,
  deleteTreatmentFrequencyVariables,
} from 'types/deleteTreatmentFrequency'
import {
  createTreatmentFrequency,
  createTreatmentFrequencyVariables,
} from 'types/createTreatmentFrequency'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import {
  updateTreatmentFrequency,
  updateTreatmentFrequencyVariables,
} from 'types/updateTreatmentFrequency'
import {
  getTreatmentFrequency,
  getTreatmentFrequencyVariables,
} from 'src/types/getTreatmentFrequency'
import { useClientQueryPromise } from 'src/utils/useClientQueryPromise'

export const useTreatmentFrequencies = () => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()

  const queryResult = useQuery<
    getTreatmentFrequencies,
    getTreatmentFrequenciesVariables
  >(GET_TREATMENT_FREQUENCIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisationId,
    },
    onError: err => {
      toast.error(err.message)
    },
  })

  const singleFrequency = useClientQueryPromise<
    getTreatmentFrequency,
    getTreatmentFrequencyVariables
  >({ query: GET_TREATMENT_FREQUENCY })

  const [deleteTreatmentFrequency] = useMutation<
    deleteTreatmentFrequency,
    deleteTreatmentFrequencyVariables
  >(DELETE_TREATMENT_FREQUENCY, {
    refetchQueries: [
      {
        query: GET_TREATMENT_FREQUENCIES,
        variables: {
          organisationId,
        },
      },
    ],
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
    onCompleted: () =>
      toast.success(t('settings:treatmentFrequencies.drawer.deleted')),
  })

  const [createTreatmentFrequency] = useMutation<
    createTreatmentFrequency,
    createTreatmentFrequencyVariables
  >(CREATE_TREATMENT_FREQUENCIES, {
    refetchQueries: [
      {
        query: GET_TREATMENT_FREQUENCIES,
        variables: {
          organisationId,
        },
      },
    ],
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
    onCompleted: () =>
      toast.success(t('settings:treatmentFrequencies.drawer.created')),
  })

  const [updateTreatmentFrequency] = useMutation<
    updateTreatmentFrequency,
    updateTreatmentFrequencyVariables
  >(UPDATE_TREATMENT_FREQUENCY, {
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
    onCompleted: () =>
      toast.success(t('settings:treatmentFrequencies.drawer.updated')),
  })

  return {
    queryResult,
    singleFrequency,
    deleteTreatmentFrequency,
    createTreatmentFrequency,
    updateTreatmentFrequency,
  }
}
