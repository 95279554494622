import { accountStackScreensPath } from 'components/Account/screens'
import {
  SvgPatient,
  SvgWhiteboard,
  SvgMore,
  SvgAccount,
} from 'components/Icons'
import { patientsStackScreensPath } from 'components/Patient/screens'
import { settingsStackScreensPath } from 'components/Settings/screens'
import { whiteboardsStackScreensPath } from 'components/Whiteboard/screens'
import { Routes } from 'constants/Routes'
import { getBreakpoint } from 'src/hocs/breakpoint'

const { isExSmallScreen, isSmallScreen } = getBreakpoint()
export const isSmallishInitialScreen = isExSmallScreen || isSmallScreen

export const AppScreensConfig = {
  [Routes.PatientsStack]: {
    path: 'patients',
    screens: patientsStackScreensPath,
    icon: SvgPatient,
    rootStack: Routes.Patients,
    titleKey: 'title.patients',
  },
  ...(!isSmallishInitialScreen && {
    [Routes.WhiteboardsStack]: {
      path: 'workflows',
      screens: whiteboardsStackScreensPath,
      icon: SvgWhiteboard,
      rootStack: Routes.Whiteboard,
      titleKey: 'title.workflows',
    },
  }),
  [Routes.SettingsStack]: {
    path: 'settings',
    screens: settingsStackScreensPath,
    icon: SvgMore,
    rootStack: Routes.Settings,
    titleKey: 'title.settings',
  },
  [Routes.AccountStack]: {
    path: 'account',
    screens: accountStackScreensPath,
    icon: SvgAccount,
    rootStack: Routes.Account,
    titleKey: 'title.account',
  },
}

export type ScreenName = keyof typeof AppScreensConfig
