import { StatusIcon } from 'components/Task/common/StatusIcon'
import { getIsTaskDoneOrSkipped } from 'components/Task/utils/taskTransitionUtils'
import { Pill } from 'components/common/Pill'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import React, { memo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'
import {
  actionTypeToTextMapper,
  formatTaskPreviewFluidDosageInfo,
  formatTaskPreviewMedicineDosageInfo as formatMedicineDosageInfo,
  getRouteOfAdministration,
} from '../../taskPreviewUtils'
import { TaskPreviewTask } from '../../types'
import { FluidStatusIcon } from 'components/Task/common/FluidStatusIcon'
import { PENDING_APPROVAL_TAG_NAME, Tag } from 'components/Tag/Tag'

type Props = {
  task: TaskPreviewTask
  timeFormat: string
}

export const TaskListTaskItem: React.FC<Props> = memo(
  ({ task, timeFormat }) => {
    const startTime = format(new Date(task.start_at), timeFormat)
    const taskMedicineDosageStrings = formatMedicineDosageInfo(
      task.medicine_dosage_info,
      task.fluid_dosage_info?.fluid_action_type ?? null,
      task.is_fluid,
    )
    const taskFluidDosageStrings = formatTaskPreviewFluidDosageInfo(
      task.fluid_dosage_info,
    )
    const roa = getRouteOfAdministration(task)
    const statusStyles = getIsTaskDoneOrSkipped(task.status)
      ? Colors.Backgrounds.secondary
      : Colors.Backgrounds.UI

    return (
      <View
        testID={`TaskListTask-${task.id}`}
        accessibilityLabel={`TaskListTask`}
        style={[styles.taskPreviewItem, { backgroundColor: statusStyles }]}
      >
        <View style={styles.flexOne}>
          <Text style={styles.treatmentGroupHeaderText} numberOfLines={2}>
            {task.treatment_name}
          </Text>
          {task.is_fluid && task.fluid_dosage_info?.fluid_action_type ? (
            <Text style={styles.fluidTaskHeaderText} numberOfLines={2}>
              {actionTypeToTextMapper(task.fluid_dosage_info.fluid_action_type)}
            </Text>
          ) : null}

          <View style={styles.dosageInfoTagsContainer}>
            {!!task.treatment_is_pending_approval ? (
              <Tag
                size={'small'}
                style={styles.pendingTag}
                title={PENDING_APPROVAL_TAG_NAME}
              />
            ) : (
              <>
                {roa ? (
                  <Pill
                    key="routeOfAdministration"
                    size={'small'}
                    color={Colors.Backgrounds.overlay}
                    text={roa}
                    style={styles.dosageInfoPill}
                  />
                ) : null}
                {!isEmpty(taskFluidDosageStrings) ? (
                  <>
                    {Object.entries(taskFluidDosageStrings).map(
                      ([key, value]) =>
                        value ? (
                          <Pill
                            key={key}
                            size={'small'}
                            color={Colors.Backgrounds.overlay}
                            text={value}
                            style={styles.dosageInfoPill}
                          />
                        ) : null,
                    )}
                  </>
                ) : null}
                {!isEmpty(taskMedicineDosageStrings) ? (
                  <>
                    {Object.entries(taskMedicineDosageStrings).map(
                      ([key, value]) =>
                        value ? (
                          <Pill
                            key={key}
                            size={'small'}
                            color={Colors.Backgrounds.overlay}
                            text={value}
                            style={styles.dosageInfoPill}
                          />
                        ) : null,
                    )}
                  </>
                ) : null}
              </>
            )}
          </View>
        </View>
        <View style={styles.flexRow}>
          {task.is_fluid && task.fluid_dosage_info?.fluid_action_type ? (
            <FluidStatusIcon
              status={task.fluid_dosage_info.fluid_action_type}
            />
          ) : (
            <StatusIcon status={task.status} />
          )}
          <Text style={styles.taskPreviewTimeText}>{startTime}</Text>
        </View>
      </View>
    )
  },
)

const styles = StyleSheet.create({
  taskPreviewItem: {
    width: '100%',
    minHeight: 60,
    maxHeight: 108,
    backgroundColor: Colors.Backgrounds.UI,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderColor: Colors.Borders.primary,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexOne: { flex: 1 },
  treatmentGroupHeaderText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
  },
  fluidTaskHeaderText: {
    ...Typography.Label.S,
    color: Colors.Contents.tertiary,
  },
  dosageInfoTagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 2,
    alignItems: 'center',
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  taskPreviewTimeText: {
    width: 52,
    marginLeft: 8,
    ...Typography.SubHeading.S,
    color: Colors.Contents.tertiary,
  },
  dosageInfoPill: {
    marginRight: 8,
    marginVertical: 2,
  },
  pendingTag: {
    alignItems: 'center',
    height: 18,
    marginRight: 3,
  },
})
