import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from 'components/common'
import {
  DISCHARGE_PATIENT,
  GET_PATIENT,
  GET_SHEETS,
} from 'components/Patient/graphql'
import i18next from 'i18next'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useUpdatePatientListCache } from 'src/components/Patient/useUpdatePatientListCache'
import { useConfirm } from 'src/context/confirm'
import { useOrganisation } from 'src/context/organisation'
import { getPatient, getPatientVariables } from 'src/types/getPatient'
import { getSheets, getSheetsVariables } from 'src/types/getSheets'
import {
  dischargePatient as DischargePatient,
  dischargePatientVariables as DischargePatientVariables,
} from 'types/dischargePatient'
import { environment } from 'src/config'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { navigate } from 'components/Base/navigation/navigation'
import { Routes } from 'constants/Routes'

enum SheetActionButtonIndex {
  DISCHARGE = 0,
  CANCEL = 1,
}

export const useDischargePatient = (
  id: string,
  dischargePatientIOSCb?: () => void,
) => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [{ organisationId }] = useOrganisation()
  const updatePatientListCache = useUpdatePatientListCache()
  const confirm = useConfirm()
  const { showActionSheetWithOptions } = useActionSheet()

  const updateCacheSheets = () => {
    const nowString = new Date().toISOString()
    const preData = client.readQuery<getSheets, getSheetsVariables>({
      query: GET_SHEETS,
      variables: {
        organisationId,
        id,
      },
    })
    if (!preData) {
      return
    }
    const clonedData = cloneDeep(preData)

    clonedData.getPatient?.sheets?.items?.forEach(sheet => {
      if (!sheet.closed_at) sheet.closed_at = nowString
    })

    client.writeQuery<getSheets, getSheetsVariables>({
      query: GET_SHEETS,
      variables: {
        organisationId,
        id,
      },
      data: clonedData,
    })
  }

  const updateCacheConsultations = () => {
    const preData = client.readQuery<getPatient, getPatientVariables>({
      query: GET_PATIENT,
      variables: {
        organisationId,
        id,
      },
    })

    if (!preData) {
      return
    }

    const clonedData = cloneDeep(preData)
    if (!clonedData.getPatient?.active_consultations) {
      return
    }
    clonedData.getPatient.active_consultations.items = []

    client.writeQuery<getPatient, getPatientVariables>({
      query: GET_PATIENT,
      variables: {
        organisationId,
        id,
      },
      data: clonedData,
    })
  }

  const [dischargePatient] = useMutation<
    DischargePatient,
    DischargePatientVariables
  >(DISCHARGE_PATIENT, {
    update: (_, { data: updateData }) => {
      updateCacheSheets()
      updateCacheConsultations()
      setTimeout(() => {
        updatePatientListCache(updateData?.dischargePatient as any, 'DELETE')
        // DischargePatient is async in the backend, so we need to wait for it to finish.
      }, 1000)
    },
    onCompleted: () => {
      toast.success(t('discharge:success'))
    },
    onError: err => {
      toast.error(err.message)
      throw err
    },
  })

  const dischargePatientAction = (notes: string) => {
    return dischargePatient({
      variables: {
        id,
        organisation_id: organisationId,
        discharge_notes: notes,
        locale: i18next.language,
      },
      optimisticResponse: {
        dischargePatient: {
          id,
          __typename: 'Patient',
        },
      },
    })
  }

  const dischargePatientIOSHandler = (
    patientName: string,
    notes: string,
    callback?: () => void,
  ) => {
    const options = [t('discharge:label'), t('general.cancel')]

    const actionSheetCallback = async (selectedIndex?: number) => {
      switch (selectedIndex) {
        case SheetActionButtonIndex.DISCHARGE:
          await dischargePatientAction(notes)
          navigate(Routes.Patients, {})
          if (callback) callback()
          break

        case SheetActionButtonIndex.CANCEL:
          return
        // Canceled
      }
    }

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex: SheetActionButtonIndex.CANCEL,
        destructiveButtonIndex: SheetActionButtonIndex.DISCHARGE,
        title: t('discharge:confirm', { patientName }),
      },
      actionSheetCallback,
    )
  }

  const dischargePatientWebHandler = async (
    patientName: string,
    dischargeNotes: string,
  ) => {
    await confirm({
      text: t('discharge:confirm', { patientName }),
      title: t('discharge:label'),
    })
    navigate(Routes.Patients, {})
    return dischargePatientAction(dischargeNotes)
  }

  /**
   * Depending on the platform, we need to handle the discharge patient differently.
   * On web, we can use the confirm hook.
   * On iOS, we need to use the action sheet.
   */
  const dischargePatientConfirm = (
    name: string,
    dischargeNotes: string = '',
  ) => {
    if (environment.isWeb) {
      return dischargePatientWebHandler(name, dischargeNotes)
    }
    return dischargePatientIOSHandler(
      name,
      dischargeNotes,
      dischargePatientIOSCb,
    )
  }

  return {
    dischargePatient: dischargePatientConfirm,
  }
}
