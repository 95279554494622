import React, { useCallback } from 'react'
import { Identity } from 'components/Base/UtilityComponents'
import {
  Patient,
  PatientListItem,
} from 'components/PatientItem/PatientListItem'
import { PatientListTasksItems } from 'components/PatientItem/PatientListTasksItems'
import { Platform, Pressable, StyleSheet, Text, View } from 'react-native'
import { RectButton, Swipeable } from 'react-native-gesture-handler'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { isOptimisticId } from 'src/utils/optimisticId'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { PatientListData } from './useGetPatientList'
import { isEqual } from 'lodash'

type SwipeableProps = Swipeable['props']
const SwipeableNativeOnly = Platform.OS === 'web' ? Identity : Swipeable
export const PATIENT_CARD_HEIGHT = 165

export type OnPressPatientListCardParams = {
  isAnesthesia?: boolean
  patientId: string
  sheetId?: string
  sheetName?: string
}

type Props = {
  item: PatientListData
  onPress: (params: OnPressPatientListCardParams) => void
  onLongPress?: null | ((patientId: string) => void)
  showDelete?: boolean
  isSortByPatientOrder?: boolean
  onPressKebab: (patient: Patient) => void
}

type RenderRightActionFn = (rightArgs: {
  onDeletePress?: ((pointerInside: boolean) => void) | null
  showDelete?: boolean
}) => SwipeableProps['renderRightActions']

const renderRightActions: RenderRightActionFn =
  ({ onDeletePress, showDelete = false }) =>
  _renderArgs => {
    if (!onDeletePress) return null

    return (
      showDelete && (
        <RectButton style={styles.rectButton} onPress={onDeletePress}>
          <Text style={styles.deleteText}>Delete</Text>
        </RectButton>
      )
    )
  }

export const PatientListCard: React.FC<Props> = React.memo(
  ({
    item: { patient, tasks, missedTasksCount, dueTasks, missedTasks },
    onLongPress,
    onPress,
    showDelete = false,
    isSortByPatientOrder = false,
    onPressKebab,
  }) => {
    const patientId = patient.id
    const isOptimistic = isOptimisticId(patientId)
    const { isExSmallScreen, isSmallScreen } = useBreakpoint()

    const onDeletePress = useCallback(() => {
      if (onLongPress) onLongPress(patientId)
    }, [onLongPress, patientId])

    const onPressHandler = useCallback(async () => {
      onPress({
        patientId,
        ...(patient.latest_active_sheet_id && {
          sheetId: patient.latest_active_sheet_id,
        }),
      })
    }, [onPress, patient.latest_active_sheet_id, patientId])

    const isSmallishScreen = isExSmallScreen && isSmallScreen

    return (
      <SwipeableNativeOnly
        renderRightActions={renderRightActions({ onDeletePress, showDelete })}
      >
        <View style={styles.rowContainer}>
          <Pressable
            delayLongPress={1500}
            disabled={isOptimistic}
            onLongPress={onDeletePress}
            onPress={onPressHandler}
            testID={`PatientListCard_${patient.name!}`}
            style={[
              styles.touchableContainer,
              styles.fullWidth,
              (isExSmallScreen || isSmallScreen) && styles.flex,
            ]}
          >
            <PatientListItem
              patient={patient}
              missedTasksCount={missedTasksCount}
              isSortByPatientOrder={isSortByPatientOrder}
              onPressKebab={onPressKebab}
            />
            {!isSmallishScreen ? (
              <PatientListTasksItems
                patientId={patientId}
                hasUnapprovedSheet={patient.has_unapproved_sheet}
                tasks={tasks}
                dueTasks={dueTasks}
                criticalNotes={patient.critical_notes}
                missedTasks={missedTasks}
              />
            ) : null}
          </Pressable>
        </View>
      </SwipeableNativeOnly>
    )
  },
  isEqual, // Warning, risky but worthwhile here. see: https://react.dev/reference/react/memo#specifying-a-custom-comparison-function
)

PatientListCard.displayName = 'PatientListCard'

const styles = StyleSheet.create({
  deleteText: {
    color: Colors.white,
    fontFamily: Fonts.bold,
    fontSize: 18,
  },
  rectButton: {
    alignItems: 'center',
    backgroundColor: Colors.errorBackground,
    justifyContent: 'center',
    width: 96,
  },
  rowContainer: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    height: PATIENT_CARD_HEIGHT,
  },
  touchableContainer: {
    flexDirection: 'row',
  },
  fullWidth: { width: '100%' },
  flex: {
    flex: 1,
  },
})
