import { WeightUnit } from 'constants/Weight'
import { getOneProduct_getProduct_medicine_dosage_info as oneProduct } from 'src/types/getOneProduct'
import { getTemplate_getTemplate_treatments_items_treatments_items_treatment_options_products_medicine_dosage_info as medicationDosageInfo } from 'src/types/getTemplate'
import {
  isNonStandardWeightUnits,
  isStandardWeightUnits,
} from './isNonStandardWeightUnits'

export const getDefaultDosageValues = (
  shouldConcentrationWeightUnitOverrideDefault: boolean,
  productDosageInfo?: oneProduct | medicationDosageInfo | null,
) => {
  if (!productDosageInfo)
    return {
      defaultDosageValuesHasError: false,
    }
  const {
    dosage,
    dosage_weight_unit,
    dosage_patient_weight_unit,
    concentration_weight_unit,
  } = productDosageInfo
  if (!dosage && !dosage_weight_unit && !dosage_patient_weight_unit)
    return {
      defaultDosageValuesHasError: false,
    }
  // when medication concentration measured in a non standard unit the user must use the same unit for the dosage
  const isProductDosagePerWeightUnitInvalid =
    dosage_patient_weight_unit !== WeightUnit.G &&
    dosage_patient_weight_unit !== WeightUnit.KG
  // if user sets default dosage in a non standard unit but concentration is in a standard unit
  const isProductDosageWeightUnitInvalid =
    (isNonStandardWeightUnits(dosage_weight_unit as WeightUnit) &&
      isStandardWeightUnits(concentration_weight_unit as WeightUnit)) ||
    !dosage_patient_weight_unit
  // default dosage values would not be valid if any of these are true
  if (
    shouldConcentrationWeightUnitOverrideDefault ||
    isProductDosagePerWeightUnitInvalid ||
    isProductDosageWeightUnitInvalid
  )
    return {
      defaultDosageValuesHasError: true,
    }
  return {
    dosageValues: {
      dosageRate: dosage || null,
      dosageWeightUnit: dosage_weight_unit,
      dosagePerWeightUnit: dosage_patient_weight_unit,
    },
    defaultDosageValuesHasError: false,
  }
}
