import React from 'react'
import {
  Text,
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native'
import { Colors } from 'constants/Colors'

type Props = {
  containerStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  text: string
  subText?: React.ReactElement | null
  accessibilityLabel?: string
}

export const Banner: React.FC<Props> = ({
  containerStyle,
  textStyle,
  text,
  subText,
  accessibilityLabel,
}) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <Text
        accessibilityLabel={accessibilityLabel}
        style={[styles.text, textStyle]}
      >
        {text}
      </Text>
      {subText}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.greyBlue,
  },
  text: {
    fontWeight: 'bold',
    fontSize: 16,
    color: Colors.white,
  },
})
