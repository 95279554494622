import { format, isValid } from 'date-fns'

export const getWeightLastUpdateTime = (
  weightTime?: number | string | null,
  dateFormat = 'p PP',
) => {
  if (!weightTime) return null
  const weightLastUpdateDate = new Date(weightTime)
  if (!isValid(weightLastUpdateDate)) return null
  return format(weightLastUpdateDate, dateFormat)
}

type GetWeightUpdatedLabel = (params: {
  isWeightOverriden?: boolean
  weightOverridenMessage: string
  weightUntouchedMessage: string
  weightUpdatedAtMessage?: string | null // important can be nil, since may not always have value
}) => string

export const getWeightUpdatedLabel: GetWeightUpdatedLabel = ({
  isWeightOverriden,
  weightOverridenMessage,
  weightUntouchedMessage,
  weightUpdatedAtMessage,
}) => {
  if (isWeightOverriden) return weightOverridenMessage
  if (!weightUpdatedAtMessage) return weightUntouchedMessage
  return weightUpdatedAtMessage
}
