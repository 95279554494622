import { useCallback, useMemo, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { toast } from 'src/components/common/Toast/ToastArea'
import { useOrganisation } from 'src/context/organisation'
import { LIST_WHITEBOARD_WORKFLOWS } from '../Patient/graphql'
import {
  listWhiteboardWorkflows,
  listWhiteboardWorkflows_listWhiteboardWorkflows_items,
  listWhiteboardWorkflowsVariables,
} from 'src/types/listWhiteboardWorkflows'
import { SortPageInput } from 'components/Patient/utils/useLocalStorageChangeSort'
import {
  ApprovalStatus,
  OriginTypes,
  veterinary_roles,
} from 'src/types/globalTypes'
import { GET_USERS_LIST } from 'components/Settings/graphql'
import { getUsersList, getUsersListVariables } from 'src/types/getUsersList'
import {
  generateUserListHash,
  UserListHash,
} from 'components/Patient/utils/transformPatientData'

export const WHITEBOARD_PATIENT_LIST_QUERY_MAX_AGE = 9 * 1000
// Fetch patient list (in view) every 18 seconds
export const WHITEBOARD_PATIENT_LIST_POLL_INTERVAL = 18 * 1000
export const WHITEBOARD_PATIENT_LIST_POLL_INTERVAL_FULL_MODE = 10 * 1000
export const WHITEBOARD_PATIENT_LIST_RESULTS_LIMIT = 8

export type PatientListFilters = {
  approvalStatuses: ApprovalStatus[]
  sites: string[]
  attendingVets: string[]
  attendingVetTechs: string[]
  locations: string[]
  searchQuery: string
}

type useGetWhiteboardPatientList = {
  filters: PatientListFilters
  isFullScreen?: boolean
  sortPageInput: SortPageInput
}

/**
 * Added to override the patient type from the generated types as we do not graph the
 * attending_vet_tech_display_name field from graphql
 *
 * TODO: In the future, this should match the Patient type from /components/PatientItem/PatientListItem.tsx
 * as the PatientCard is the same between both component
 */
export type ListWhiteboardWorkflowsItem =
  listWhiteboardWorkflows_listWhiteboardWorkflows_items & {
    patient: {
      attending_vet_tech_display_name?: string | null
    }
  }

export const useGetWhiteboardPatientList = ({
  filters,
  isFullScreen = false,
  sortPageInput,
}: useGetWhiteboardPatientList) => {
  const queryTimeStampsRef = useRef<{ [cursor: string]: number }>({})

  const [{ organisationId }] = useOrganisation()
  const {
    sites,
    locations,
    attendingVets,
    attendingVetTechs,
    searchQuery,
    approvalStatuses,
  } = filters

  const queryVariables: listWhiteboardWorkflowsVariables = useMemo(
    () => ({
      input: {
        organisation_id: organisationId,
        filterOptions: {
          filters: {
            sites,
            attending_vet_user_ids: attendingVets,
            attending_vet_tech_user_ids: attendingVetTechs,
            location_ids: locations,
            approval_statuses: approvalStatuses,
          },
          ...(!!searchQuery && {
            q: searchQuery,
          }),
        },
        pageInput: {
          ...sortPageInput,
          limit: !searchQuery ? WHITEBOARD_PATIENT_LIST_RESULTS_LIMIT : null,
        },
      },
    }),
    [
      approvalStatuses,
      attendingVetTechs,
      attendingVets,
      locations,
      organisationId,
      searchQuery,
      sites,
      sortPageInput,
    ],
  )

  const { loading, data, fetchMore } = useQuery<
    listWhiteboardWorkflows,
    listWhiteboardWorkflowsVariables
  >(LIST_WHITEBOARD_WORKFLOWS, {
    variables: queryVariables,
    // no pollInterval - do the polling with a refetch
    fetchPolicy: 'cache-and-network',
    // use cache when return and network update w/ poll
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onError: err => {
      toast.error(err.message)
    },
  })

  const { data: users } = useQuery<getUsersList, getUsersListVariables>(
    GET_USERS_LIST,
    {
      fetchPolicy: 'cache-first',
      variables: {
        organisation_id: organisationId,
        veterinary_role: veterinary_roles.VETERINARY_TECH,
        origin_type: OriginTypes.EZYVET,
      },
      onError: err => {
        toast.error(err.message)
      },
    },
  )

  const usersList: UserListHash = useMemo(
    () => generateUserListHash(users?.getUsersList?.items ?? []),
    [users?.getUsersList.items],
  )

  const getFetchMoreVariables = useCallback(
    (cursor: string) => {
      return {
        variables: {
          input: {
            ...queryVariables.input,
            pageInput: {
              ...queryVariables.input.pageInput,
              cursor,
            },
          },
        },
      }
    },
    [queryVariables],
  )

  const doFetchMore = useCallback(
    (cursor: string) => {
      if (
        !fetchMore ||
        (queryTimeStampsRef.current[cursor] &&
          Date.now() - queryTimeStampsRef.current[cursor] <
            WHITEBOARD_PATIENT_LIST_QUERY_MAX_AGE)
      ) {
        return
      }
      queryTimeStampsRef.current[cursor] = Date.now()

      try {
        const fetchMoreVariables = getFetchMoreVariables(cursor)
        return fetchMore(fetchMoreVariables)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        return
      }
    },
    [fetchMore, getFetchMoreVariables],
  )

  /* Instead of using apollo poll arg, we do timeout based polling so that we
    can change based on visible cursors. We cancel the timeout if user scrolls
    to different section */
  const pollVisibleCursors = useCallback(
    (visiblePageCursors: string[]) => {
      const timeoutIds: (number | NodeJS.Timeout)[] = []
      visiblePageCursors.forEach(val => {
        const timeoutId = setInterval(
          () => doFetchMore(val),
          isFullScreen
            ? WHITEBOARD_PATIENT_LIST_POLL_INTERVAL_FULL_MODE
            : WHITEBOARD_PATIENT_LIST_POLL_INTERVAL,
        )
        timeoutIds.push(timeoutId)
      })
      return timeoutIds
    },
    [doFetchMore, isFullScreen],
  )

  const whiteboardPatients: ListWhiteboardWorkflowsItem[] =
    data?.listWhiteboardWorkflows.items.map(item => {
      const attending_vet_tech_id = item.patient.attending_vet_tech_id
      const attending_vet_tech_display_name =
        attending_vet_tech_id && users
          ? usersList[attending_vet_tech_id]?.display_name
          : ''

      return {
        ...item,
        patient: {
          ...item.patient,
          attending_vet_tech_display_name,
        },
      }
    }) ?? []

  return {
    data,
    doFetchMore,
    loading,
    pollVisibleCursors,
    queryTimeStampsRef,
    whiteboardPatients,
  }
}
