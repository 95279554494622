import React from 'react'
import { Controller, Control } from 'react-hook-form'
import { View, StyleSheet } from 'react-native'
import { DurationSelector, DurationType } from './DurationSelector'
import { useTranslation } from 'react-i18next'
import { Colors } from 'constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { noop } from 'lodash'

type Props = {
  onDurationChange?: () => void
  control: Control<any>
}

export const DaysHoursMinutesSelector: React.FC<Props> = ({
  onDurationChange = noop,
  control,
}) => {
  const { t } = useTranslation()

  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isMobileScreen = isExSmallScreen || isSmallScreen

  const smallScreenSelectStyle = isMobileScreen && styles.smallScreenSelect

  const fieldTextWrapperStyle = isMobileScreen
    ? styles.smallFieldTextWrapperStyle
    : styles.fieldTextWrapperStyle

  return (
    <View style={styles.durationContainer}>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DurationSelector
            onChange={v => {
              onChange(v)
              onDurationChange()
            }}
            value={value}
            type={DurationType.Days}
            a11yLabel={t('addTreatment:duration.days')}
            label={t('addTreatment:duration.days')}
            style={smallScreenSelectStyle}
            fieldTextWrapperStyle={fieldTextWrapperStyle}
          />
        )}
        name="days"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DurationSelector
            onChange={v => {
              onChange(v)
              onDurationChange()
            }}
            style={smallScreenSelectStyle}
            value={value}
            type={DurationType.Hours}
            a11yLabel={t('addTreatment:duration.hrs')}
            label={t('addTreatment:duration.hrs')}
            fieldTextWrapperStyle={fieldTextWrapperStyle}
          />
        )}
        name="hours"
      />

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DurationSelector
            onChange={v => {
              onChange(v)
              onDurationChange()
            }}
            style={smallScreenSelectStyle}
            value={value}
            type={DurationType.Minutes}
            a11yLabel={t('addTreatment:duration.mins')}
            label={t('addTreatment:duration.mins')}
            fieldTextWrapperStyle={fieldTextWrapperStyle}
          />
        )}
        name="minutes"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  durationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    paddingTop: 12,
  },
  smallScreenSelect: {
    width: 80,
    height: 40,
    minWidth: 80,
  },
  fieldTextWrapperStyle: {
    marginRight: -24,
    paddingLeft: 24,
  },
  smallFieldTextWrapperStyle: {
    marginRight: -12,
    paddingLeft: 12,
  },
})
