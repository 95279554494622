import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddTemplateDrawer } from 'components/AddTreatment/AddTemplateDrawer'
import {
  filterTemplateFormInputs,
  FormDataTemplateWithId,
} from 'components/AddTreatment/templateUtilFns'
import { Button, FormBreak, Select, TextInput } from 'components/common'
import { commonButtonMinWidth } from 'components/common/Button'
import { DrawerProvider } from 'components/common/Drawer/DrawerContext'
import { Status } from 'components/common/Form/Status'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { GET_PATIENT_WITH_SHEETS } from 'components/SheetList/graphql'
import { sortSheetList } from 'components/SheetList/utils/sortSheetList'
import { SYNC_WITH_CONSULT_PLACEHOLDER } from 'constants/ClinicalRecord'
import { startOfHour } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Center } from 'src/components/common/Center'
import { useOrganisation } from 'src/context/organisation'
import { getAssociatedSiteIds } from 'src/hooks/useAssociatedSiteIds'
import { useDefaultSheetName } from 'src/hooks/useDefaultSheetName'
import {
  getConsultations,
  getConsultations_getPatient_active_consultations_items,
} from 'src/types/getConsultations'
import {
  getPatientWithSheets,
  getPatientWithSheetsVariables,
} from 'src/types/getPatientWithSheets'
import { veterinary_roles } from 'src/types/globalTypes'
import * as Yup from 'yup'

import { AdditionalCareTeam } from './AdditionalCareTeam'
import { AnesthesiaSection } from './AnesthesiaSection'
import { ConsultIdSection, reverseSortByOriginId } from './ConsultationSection'
import {
  createSheetDefaults,
  CreateSheetFormInputs,
  initialTemplateFormInputs,
  SheetType,
  SheetTypeDisplayValueTranslationKey,
} from './createSheetData'
import { DepartmentSection } from './DepartmentSection'
import { TemplateSection } from './TemplateSection'
import { UserSection } from './UsersSection'
import { SheetActionTypes, useSheetContext } from 'src/context/sheet'
import { CARD_HEIGHT_MEDIUM, PatientCard } from 'components/common/PatientCard'

const NO_CONSULTATIONS_FOUND = 'NO_CONSULTATIONS_FOUND'

Yup.addMethod(
  Yup.string,
  'isDuplicated',
  // eslint-disable-next-line func-names
  function (namelist: string[], message: string) {
    // eslint-disable-next-line func-names
    return this.test('test-name', message, function (value: string) {
      const { path, createError } = this
      const checkName = namelist.includes(value.trim())
      return checkName ? createError({ path, message }) : true
    })
  },
)
declare module 'yup' {
  export interface StringSchema {
    isDuplicated(list: string[], message: string): StringSchema
  }
}

const getValidationSchema = (nameList: string[], t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string()
      .max(30, t('sheetForm.validationErrors.maxSheetNameValidation'))
      .isDuplicated(nameList, t('sheetForm.validationErrors.nameDuplicated'))
      .required(t('sheetForm.validationErrors.nameRequired')),

    selectedConsultId: Yup.string()
      .notOneOf(
        [NO_CONSULTATIONS_FOUND],
        t('sheetForm.validationErrors.noConsultationsFound'),
      )
      .required(t('sheetForm.validationErrors.consultationRequired'))
      .nullable(),
    selectedTemplates: Yup.array().of(Yup.string().nullable()),
    selectedVet: Yup.string().required(
      t('sheetForm.validationErrors.attendingDoctorRequired'),
    ),
  })
}

export type Props = {
  defaultOverrides?: Partial<CreateSheetFormInputs>
  isTemplateListLoading?: boolean
  isTemplateProductIdsLoading: boolean
  onSave: (data: CreateSheetFormInputs) => void
  patientId: string
}

export const CreateSheet: React.FC<Props> = ({
  defaultOverrides,
  isTemplateListLoading = false,
  isTemplateProductIdsLoading = false,
  onSave,
  patientId,
}) => {
  const { t } = useTranslation()
  const [{ organisationId, organisationSites }] = useOrganisation()
  const [consultations, setConsultations] = useState<
    getConsultations_getPatient_active_consultations_items[]
  >([])

  const [currentTemplateIndex, setCurrentTemplateIndex] = useState<
    number | null
  >(null)

  const { data: sheetsData } = useQuery<
    getPatientWithSheets,
    getPatientWithSheetsVariables
  >(GET_PATIENT_WITH_SHEETS, {
    variables: { id: patientId, organisation_id: organisationId },
  })

  const sheetNameList = useMemo(
    () =>
      sortSheetList(
        (sheetsData?.getPatient?.sheets?.items ?? []).filter(s => !s.closed_at),
      ).map(sheet => sheet.name),
    [sheetsData],
  )

  const defaultValues = { ...createSheetDefaults, ...defaultOverrides }
  const { setValue, getValues, control, formState, watch } =
    useForm<CreateSheetFormInputs>({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(getValidationSchema(sheetNameList, t)),
    })

  const sheetTypeOptions = Object.values(SheetType).map(key => ({
    value: key,
    text: t(`sheetForm.sheetTypes.${SheetTypeDisplayValueTranslationKey[key]}`),
  }))

  const { touchedFields, isValid, dirtyFields, errors } = formState

  const handleSubmit = async () => {
    if (isValid) {
      onSave(getValues())
    }
  }

  const sheetType = watch('sheetType', SheetType.NORMAL)
  const watchedAsaCat = watch('asaCat')
  const isAnesthesia = sheetType === SheetType.ANAESTHESIA
  const isSubmitButtonLoading =
    (isAnesthesia && isTemplateListLoading) || isTemplateProductIdsLoading

  const defaultSheetName = useDefaultSheetName(isAnesthesia, sheetNameList)

  useEffect(() => {
    // Change the default name if sheet type is changed and name is untouched
    if (dirtyFields.name) return
    setValue('name', defaultSheetName, {
      shouldValidate: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetType, defaultSheetName])

  const setInitialConsultationSelectValue = useCallback(
    (result: getConsultations) => {
      const consultations = result.getPatient?.active_consultations?.items
      if (!consultations) return

      // Set to the first consult ID when available
      const firstConsultItem = reverseSortByOriginId(consultations)[0] as
        | getConsultations_getPatient_active_consultations_items
        | undefined

      setValue(
        'selectedConsultId',
        firstConsultItem?.id ?? NO_CONSULTATIONS_FOUND,
        {
          shouldValidate: true,
        },
      )

      if (firstConsultItem?.attending_vet_id && !getValues('selectedVet')) {
        setValue('selectedVet', firstConsultItem.attending_vet_id, {
          shouldValidate: true,
        })
      }
      setConsultations(consultations)
    },
    [getValues, setValue],
  )

  const selectedAttendingDepartmentId = watch('selectedDepartment')
  const selectedConsultId = watch('selectedConsultId')
  const hasSyncWithConsultDepartment = watch(
    'syncSelectedDepartmentWithConsult',
  )

  const selectedConsultation = useMemo(
    () => consultations.find(({ id }) => id === selectedConsultId),
    [selectedConsultId, consultations],
  )

  const { consultDepartment, consultSiteId } = useMemo(() => {
    const selectedConsultSite = organisationSites?.find(
      ({ id }) => id === selectedConsultation?.site_id,
    )
    return {
      consultDepartment: selectedConsultSite?.name ?? '',
      consultSiteId: selectedConsultSite?.id ?? '',
    }
  }, [selectedConsultation?.site_id, organisationSites])

  const [, setSheetContext] = useSheetContext()

  // initialize the value of CreateSheetFormHiddenInputs when selectedConsultation changes
  useEffect(() => {
    setValue('consultVetId', selectedConsultation?.attending_vet_id ?? null)
    setValue('syncSelectedVetWithConsult', true)
    setValue('consultSiteId', selectedConsultation?.site_id ?? null)
    setValue('syncSelectedDepartmentWithConsult', true)

    if (selectedConsultation) {
      setSheetContext({
        type: SheetActionTypes.setConsultationSiteId,
        consultationSiteId: selectedConsultation.site_id,
      })
    }
  }, [selectedConsultation, setValue, setSheetContext])

  // reset the 'emergency' toggle to off when the asaCat changes to null
  useEffect(() => {
    if (!watchedAsaCat) setValue('emergency', false)
  }, [watchedAsaCat, setValue])

  const handleSelectedUserChange = useCallback(
    (value: string | null) => {
      setValue('selectedVet', value, { shouldValidate: true })
      setValue(
        'syncSelectedVetWithConsult',
        value === SYNC_WITH_CONSULT_PLACEHOLDER,
      )
    },
    [setValue],
  )

  const handleSelectedDepartmentChange = useCallback(
    (value: string | null) => {
      setValue('selectedDepartment', value, { shouldValidate: true })
      setValue(
        'syncSelectedDepartmentWithConsult',
        value === SYNC_WITH_CONSULT_PLACEHOLDER,
      )

      if (!organisationSites) {
        return
      }

      if (!value) return

      const selectedDepartment =
        value === SYNC_WITH_CONSULT_PLACEHOLDER ? consultSiteId : value
      const associatedSiteIds = getAssociatedSiteIds(organisationSites, [
        selectedDepartment,
      ])
      const templateFormInputs = getValues('templateFormInputs')

      setValue(
        'templateFormInputs',
        filterTemplateFormInputs(templateFormInputs, associatedSiteIds),
      )
    },
    [setValue, getValues, consultSiteId, organisationSites],
  )

  const [isAddTreatmentDrawerVisible, setAddTreatmentDrawerVisible] =
    useState(false)

  const toggleDrawer = useCallback(() => {
    setAddTreatmentDrawerVisible(drawerVisible => {
      if (drawerVisible) {
        setCurrentTemplateIndex(null)
      }
      return !drawerVisible
    })
  }, [])

  const onSetupTemplate = useCallback(
    ({
      startTime,
      applyWorkflow,
      templateTreatmentFormInputs,
      templateId,
      name,
      index,
      sites,
    }: FormDataTemplateWithId) => {
      const templateFormInputs = getValues('templateFormInputs')

      templateFormInputs[index] = {
        selectedTemplate: {
          startTime,
          applyWorkflow,
          name,
          sites,
          id: templateId,
        },
        templateTreatmentFormInputs: templateTreatmentFormInputs ?? [],
      }
      setValue('templateFormInputs', templateFormInputs)

      setAddTreatmentDrawerVisible(drawerVisible => !drawerVisible)
    },
    [getValues, setValue],
  )

  const addNewTemplate = useCallback(
    () =>
      setValue('templateFormInputs', [
        ...getValues('templateFormInputs'),
        {
          selectedTemplate: {
            id: null,
            startTime: startOfHour(new Date()),
          },
        },
      ]),
    [getValues, setValue],
  )

  const resetTemplateFormInputs = useCallback(
    () => setValue('templateFormInputs', initialTemplateFormInputs),
    [setValue],
  )

  const onPressTemplateSection = useCallback(
    (index: number) => {
      if (isAddTreatmentDrawerVisible) return
      setCurrentTemplateIndex(index)
      toggleDrawer()
    },
    [toggleDrawer, isAddTreatmentDrawerVisible],
  )

  return (
    <KeyboardAwareScrollView stickyHeaderIndices={[0]}>
      <View style={{ height: CARD_HEIGHT_MEDIUM }}>
        <Center>
          <PatientCard
            patientId={patientId}
            config={{
              avatarContainerMaxWidth: 90,
            }}
          />
        </Center>
      </View>
      <View>
        <DrawerProvider>
          <Center>
            <FormBreak />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  dialog={false}
                  options={sheetTypeOptions}
                  selected={value}
                  onChange={v => {
                    resetTemplateFormInputs()
                    onChange(v)
                  }}
                  label={t('sheetForm.type')}
                />
              )}
              name="sheetType"
            />
            <Status
              status={createErrorStatus(
                errors?.name?.message,
                touchedFields.name,
              )}
            >
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    label={t('sheetForm.name')}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    testID="SheetNameInput"
                    value={value}
                    required={true}
                  />
                )}
                name="name"
                defaultValue=""
                rules={{ required: true }}
              />
            </Status>
            <FormBreak />

            <Controller
              control={control}
              render={() => (
                <TextInput
                  label={t('patient:view.consultation_dept')}
                  value={consultDepartment}
                  disabled={true}
                />
              )}
              name="department"
            />
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <DepartmentSection
                  onSelectedDepartmentChange={handleSelectedDepartmentChange}
                  selectedDepartment={value}
                  disabled={
                    !selectedConsultId ||
                    selectedConsultId === NO_CONSULTATIONS_FOUND
                  }
                />
              )}
              name="selectedDepartment"
            />

            <UserSection
              type={veterinary_roles.VET}
              selectedUser={selectedConsultation?.attending_vet_id ?? ''}
              disabled={true}
              isOwner={true}
              hideChevron={true}
            />
            <Status
              status={createErrorStatus(
                t('form.required'),
                !!errors?.selectedVet?.message,
              )}
            >
              <Controller
                control={control}
                render={({ field: { value } }) => (
                  <UserSection
                    type={veterinary_roles.VET}
                    onSelectedUserChange={handleSelectedUserChange}
                    selectedUser={value}
                    allowClear={false}
                    hasSyncWithConsultVetId={true}
                    consultVetId={selectedConsultation?.attending_vet_id}
                    sheetAttendingDepartmentId={
                      hasSyncWithConsultDepartment
                        ? consultSiteId
                        : selectedAttendingDepartmentId
                    }
                  />
                )}
                name="selectedVet"
              />
            </Status>

            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <UserSection
                  type={veterinary_roles.VETERINARY_TECH}
                  onSelectedUserChange={onChange}
                  selectedUser={value}
                />
              )}
              name="selectedVetTech"
            />

            <AdditionalCareTeam control={control} name={'additionalCareTeam'} />

            <Status
              status={createErrorStatus(
                errors?.selectedConsultId?.message,
                true,
              )}
            >
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ConsultIdSection
                    patientId={patientId}
                    onConsultationsLoaded={setInitialConsultationSelectValue}
                    onSelectedConsultIdChange={onChange}
                    selectedConsultId={value}
                  />
                )}
                name="selectedConsultId"
                rules={{ required: true }}
              />
            </Status>
            <FormBreak />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <TemplateSection
                      onSelectedTemplatesChange={onChange}
                      onPress={onPressTemplateSection}
                      onAddTemplate={addNewTemplate}
                      templateFormInputs={value}
                    />
                    <AddTemplateDrawer
                      isVisible={isAddTreatmentDrawerVisible}
                      toggleDrawer={toggleDrawer}
                      sheetAttendingDepartment={
                        hasSyncWithConsultDepartment
                          ? consultSiteId
                          : selectedAttendingDepartmentId
                      }
                      onDone={onSetupTemplate}
                      currentTemplateIndex={currentTemplateIndex}
                      templateFormInputs={value}
                      isAnesthesia={isAnesthesia}
                    />
                  </>
                )
              }}
              name="templateFormInputs"
            />
            {!!isAnesthesia && (
              <AnesthesiaSection
                controller={control}
                patientId={patientId}
                attendingDepartment={selectedAttendingDepartmentId}
                asaCat={watchedAsaCat}
              />
            )}
            <View style={styles.createButtonContainer}>
              <Button
                a11yLabel={t('newSheet.actionLabel')}
                disabled={!isValid}
                loading={isSubmitButtonLoading}
                minWidth={commonButtonMinWidth}
                onPress={handleSubmit}
                testID="addSheetButton"
                title={t('newSheet.title')}
              />
            </View>
          </Center>
        </DrawerProvider>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  createButtonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    marginBottom: 20,
    zIndex: -1,
  },
})
