import React, { createContext, useContext, useReducer } from 'react'
import { UnreachableCaseError } from 'src/utils/unreachableCaseError'
import { SheetType } from 'src/types/globalTypes'

export enum SheetActionTypes {
  setLastTaskStartTime = 'setLastTaskStartTime',
  setAttendingDepartment = 'setAttendingDepartment',
  setType = 'setType',
  setSheet = 'setSheet',
  setConsultationSiteId = 'setConsultationSiteId',
}

export type SheetAction =
  | {
      type: SheetActionTypes.setLastTaskStartTime
      lastTaskScheduleStartTime: Date | null
    }
  | {
      type: SheetActionTypes.setAttendingDepartment
      sheetAttendingDepartment: string | null
    }
  | {
      type: SheetActionTypes.setType
      sheetType?: SheetType | null
    }
  | {
      type: SheetActionTypes.setSheet
      sheetType?: SheetType | null
      lastTaskScheduleStartTime: Date | null
    }
  | {
      type: SheetActionTypes.setConsultationSiteId
      consultationSiteId: string | null
    }

export type SheetDispatch = (action: SheetAction) => void

export type SheetState = {
  lastTaskStartTime: Date | null
  sheetType?: SheetType | null
  sheetAttendingDepartment?: string | null
  consultationSiteId: string | null
}

export const getInitialState = (
  overrides?: Partial<SheetState>,
): SheetState => ({
  lastTaskStartTime: null,
  sheetAttendingDepartment: null,
  sheetType: SheetType.NORMAL,
  consultationSiteId: null,
  ...overrides,
})

const SheetStateContext = createContext<SheetState | undefined>(undefined)
SheetStateContext.displayName = 'SheetStateContext'

const SheetDispatchContext = createContext<SheetDispatch | undefined>(undefined)
SheetDispatchContext.displayName = 'SheetDispatchContext'

function sheetReducer(state: SheetState, action: SheetAction): SheetState {
  switch (action.type) {
    case SheetActionTypes.setLastTaskStartTime: {
      return { ...state, lastTaskStartTime: action.lastTaskScheduleStartTime }
    }
    case SheetActionTypes.setType: {
      return { ...state, sheetType: action.sheetType }
    }
    case SheetActionTypes.setSheet: {
      return {
        ...state,
        ...(action.sheetType && { sheetType: action.sheetType }),
        lastTaskStartTime: action.lastTaskScheduleStartTime,
      }
    }
    case SheetActionTypes.setConsultationSiteId: {
      return {
        ...state,
        consultationSiteId: action.consultationSiteId,
      }
    }
    case SheetActionTypes.setAttendingDepartment: {
      return {
        ...state,
        sheetAttendingDepartment: action.sheetAttendingDepartment,
      }
    }
    default: {
      throw new UnreachableCaseError(action)
    }
  }
}

function useSheetState() {
  const context = useContext(SheetStateContext)
  if (context === undefined) {
    throw new Error('useSheetState must be used within a SheetProvider')
  }
  return context
}

function useSheetDispatch() {
  const context = useContext(SheetDispatchContext)
  if (context === undefined) {
    throw new Error('useSheetDispatch must be used within a SheetProvider')
  }
  return context
}

type ProviderProps = {
  initialState?: Partial<SheetState>
  children: React.ReactElement | React.ReactElement[]
}

export const SheetProvider: React.FC<ProviderProps> = ({
  children,
  initialState,
}) => {
  const [state, dispatch] = useReducer(
    sheetReducer,
    getInitialState(initialState),
  )

  return (
    <SheetStateContext.Provider value={state}>
      <SheetDispatchContext.Provider value={dispatch}>
        {children}
      </SheetDispatchContext.Provider>
    </SheetStateContext.Provider>
  )
}

type UseSheet = () => [SheetState, SheetDispatch]
export const useSheetContext: UseSheet = () => {
  return [useSheetState(), useSheetDispatch()]
}
