import React from 'react'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskItem } from 'src/types/getSheet'
import { Status } from 'types/globalTypes'
import { Tabs } from 'components/common/Tabs/Tabs'

import { Action } from './types'

const getActions = (t: TFunction, taskStatus: Status | null | undefined) => {
  const isSkippedTask = taskStatus === Status.MISSED_ON_PURPOSE
  const editAction = {
    value: Action.EDIT,
    text: isSkippedTask
      ? t('task:taskTransition:form:notes')
      : t('task:taskAddEdit:editTask'),
  }

  const completeInProgressAction = {
    value: Action.EDIT,
    text: t('general.complete'),
  }

  const completeAction = {
    value: Action.COMPLETE,
    text: t('general.complete'),
  }

  const rescheduleAction = {
    value: Action.RESCHEDULE,
    text: t('task:taskAddEdit:rescheduleTask'),
  }

  switch (taskStatus) {
    case Status.DONE:
      return [editAction]
    case Status.IN_PROGRESS:
      return [completeInProgressAction]
    case Status.MISSED_ON_PURPOSE:
      return [completeAction, editAction]
    default:
      return [completeAction, rescheduleAction]
  }
}

type TaskActionMenuProps = {
  action: Action | null
  onPressAction: (action: Action) => void
  taskToEdit: TaskItem
}

export const TaskActionMenu = ({
  action,
  onPressAction,
  taskToEdit,
}: TaskActionMenuProps) => {
  const { t } = useTranslation()
  const status = taskToEdit?.status
  const actions = getActions(t, status)

  return (
    <Tabs options={actions} selected={action} onPressHandler={onPressAction} />
  )
}
