import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Routes } from 'constants/Routes'
import { StackScreenProps } from '@react-navigation/stack'
import { useOrganisation } from 'src/context/organisation'

import { toast } from '../common'
import { SubHeader } from '../SubHeader/SubHeader'
import { CREATE_CALL_PARAMETER_TEMPLATE } from './graphql'
import { Inputs, CallParameterTemplateForm } from './CallParameterTemplateForm'
import { SettingsStackParamList } from 'components/Settings/screens'
import { useTranslation } from 'react-i18next'
import { createTemplateCallParameter } from 'types/createTemplateCallParameter'

type Props = StackScreenProps<SettingsStackParamList>

const useCreateCallParameterTemplateMutation = (
  onComplete: (formData: createTemplateCallParameter) => void,
) => {
  const [createCallParameterTemplate] = useMutation(
    CREATE_CALL_PARAMETER_TEMPLATE,
    {
      // TODO: optimistic update?
      onError: err => {
        toast.error(err.message)
      },
      onCompleted: onComplete,
    },
  )
  return createCallParameterTemplate
}

export const AddCallParameterTemplateScreen: React.FC<Props> = ({
  navigation,
}) => {
  const [{ organisationId }] = useOrganisation()
  const [submitLoading, setSubmitLoading] = useState(false)
  const { navigate } = navigation
  const { t } = useTranslation()

  const handleBack = () => navigate(Routes.CallParameterTemplateList)

  const backButton = {
    title: 'templates.callParameterTitle',
    label: 'templates.returnHereLabel',
    action: handleBack,
  }

  const onComplete = (formData: createTemplateCallParameter): void => {
    toast.success(
      t('template:create.success', {
        name: formData.createTemplateCallParameter.name,
      }),
    )
    handleBack()
  }

  const createCallParameterTemplate =
    useCreateCallParameterTemplateMutation(onComplete)

  const onSubmit = async (formData: Inputs) => {
    setSubmitLoading(true)
    const callParameterItems =
      formData.items
        ?.filter(item => item.name)
        .map((item, idx) => ({
          organisation_id: organisationId,
          order: idx,
          name: item.name,
          product_id: item.product_id,
          short_name: item.short_name,
          min: item.min,
          max: item.max,
        })) ?? []

    const input = {
      organisation_id: organisationId,
      name: formData.name,
      disabled: formData.disabled,
      sites: formData.sites,
      user_id: formData.user_id,
      call_parameter_items: callParameterItems,
    }

    await createCallParameterTemplate({
      variables: { input },
    })
  }

  return (
    <>
      <SubHeader
        headline="Create call parameter template"
        backButton={backButton}
      />
      <CallParameterTemplateForm
        handleSubmit={onSubmit}
        submitting={submitLoading}
      />
    </>
  )
}
