import React, { useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FormData, TreatmentForm } from 'components/TreatmentForm'
import { RepeatScheduleValue } from 'components/TreatmentForm/data'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { useSheetContext } from 'src/context/sheet'
import { useTimeResolution } from 'src/hocs/timeContext'
import { TimeSegmentMinutes } from 'src/hocs/types/time'
import { useBackButton } from 'src/hooks/useBackButton'
import { usePatientById } from 'src/hooks/usePatient'
import { useSetShowTreatmentTabs } from 'src/hooks/useSetShowTreatmentTabs'
import {
  getOneProduct as GetOneProduct,
  getOneProductVariables as GetOneProductVariables,
} from 'src/types/getOneProduct'
import { getPatient_getPatient as Patient } from 'src/types/getPatient'
import { useAddTemplateToSheet } from 'src/utils/useAddTemplateToSheet'
import { useCreateTreatment } from 'src/utils/useCreateTreatment'
import { ProductType } from 'types/globalTypes'

import { AddTemplateToSheetScreen } from './AddTemplateToSheetScreen'
import { GET_PRODUCT_DETAILS } from './graphql'
import { Product } from './ProductRow'
import { SingleProductPicker } from './SingleProductPicker'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { useFlags } from 'react-native-flagsmith/react'
import { AddBundleScreen } from './Bundles/AddBundleScreen'

type Props = {
  sheetId: string
  patientId: string
  onDone: () => void
}

export const QuickAddTreatmentDrawerContent: React.FC<Props> = ({
  sheetId,
  patientId,
  onDone,
}) => {
  const patient = usePatientById(patientId)
  const { timeSegment } = useTimeResolution()

  const bundleFlags = useFlags([FeatureFlagNames.ENABLE_BUNDLE_UI])
  const isBundleFlagEnabled = bundleFlags.enable_bundle_ui.enabled

  const createTreatment = useCreateTreatment(sheetId, patientId)

  const addTemplateToSheet = useAddTemplateToSheet({ sheetId, patientId })

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)

  const handleBackOverrides = useBackButton<Product | null>(
    selectedProduct,
    setSelectedProduct,
  )

  const [sheetContext] = useSheetContext()

  const getShouldCreateTasks = useCallback(
    (productType: string | null, isRepeating: RepeatScheduleValue) =>
      (productType === ProductType.MEDICATION ||
        productType === ProductType.IVFLUIDS) &&
      isRepeating !== RepeatScheduleValue.NONE,
    [],
  )

  const onDosageSetup = useCallback(
    async (
      product: Product,
      formData: any = {
        isRepeating: RepeatScheduleValue.SINGLE,
        startAtDate: 'now',
        timeWindow: TimeSegmentMinutes[timeSegment], // sheet zoom level
      },
    ) => {
      if (product.group.id === 'Template') {
        await addTemplateToSheet({
          templateId: product!.id,
          startTime: 'now',
        })
        onDone()
        return
      }

      const productInfo = {
        productGroupName: product.group.name,
        productGroupId: product.group.id,
        productGroupOrder: product.group.order,
        productId: product.id,
        productName: product.name,
        productType: product.type as ProductType,
      }

      const shouldCreateTasks = getShouldCreateTasks(
        product.type,
        formData.isRepeating,
      )

      // Use product billable value for quick add if there is no formData
      formData.isBillable ??= product.isBillable
      formData.unitsBilledPerTask ??= 1
      await createTreatment(formData, productInfo, {
        createTasks: shouldCreateTasks,
      })
      onDone()
    },
    [
      timeSegment,
      addTemplateToSheet,
      onDone,
      getShouldCreateTasks,
      createTreatment,
    ],
  )

  const onProductSelect = useCallback(
    (product: Product) => {
      const isMedicationOrFluids =
        product.type === ProductType.IVFLUIDS ||
        product.type === ProductType.MEDICATION

      if (
        isMedicationOrFluids ||
        product.group.id === 'Template' ||
        product.isBundle
      ) {
        setSelectedProduct(product)
        return
      }
      onDosageSetup(product)
    },
    [setSelectedProduct, onDosageSetup],
  )

  const onSaveTreatmentFormWithProduct = useCallback(
    (data: FormData) => {
      if (!!selectedProduct) {
        onDosageSetup(selectedProduct, data)
      }
    },
    [onDosageSetup, selectedProduct],
  )

  if (!patient) return null

  if (!selectedProduct) {
    return (
      <SingleProductPicker
        onSelect={onProductSelect}
        isQuickAdd={true}
        siteIds={
          sheetContext.sheetAttendingDepartment
            ? [sheetContext.sheetAttendingDepartment]
            : []
        }
        isEditingTemplateEnabled={true}
        shouldShowBundleOverride={true}
      />
    )
  }

  if (selectedProduct.group.id === 'Template') {
    return (
      <AddTemplateToSheetScreen
        sheetId={sheetId}
        templateId={selectedProduct.id}
        onDone={onDone}
        patientId={patientId}
        handleBackOverrides={handleBackOverrides}
      />
    )
  }

  if (selectedProduct.isBundle && isBundleFlagEnabled) {
    return (
      <AddBundleScreen
        product={selectedProduct}
        onDone={onDone}
        sheetId={sheetId}
        patientId={patientId}
        handleBackOverrides={handleBackOverrides}
      />
    )
  }

  return (
    <TreatmentFormWithProduct
      patient={patient}
      productId={selectedProduct.id}
      onSave={onSaveTreatmentFormWithProduct}
    />
  )
}

type TreatmentFormWithProductProps = {
  onSave: (data: FormData) => void
  patient: Patient
  productId: string
}

const TreatmentFormWithProduct: React.FC<TreatmentFormWithProductProps> = ({
  onSave,
  patient,
  productId,
}) => {
  const { t } = useTranslation()
  const [{ organisationId, hasPIMSIntegration }] = useOrganisation()
  const { data, loading } = useQuery<GetOneProduct, GetOneProductVariables>(
    GET_PRODUCT_DETAILS,
    {
      variables: {
        id: productId,
        organisation_id: organisationId,
      },
    },
  )
  const product = data?.getProduct

  useSetShowTreatmentTabs()

  if (loading) {
    return <ActivityIndicator size="large" />
  }

  if (!product) return null

  return (
    <>
      <TreatmentForm
        onSave={onSave}
        submitTitle={t('addTreatment:addToSheet')}
        patient={patient}
        product={product}
        hasPIMSIntegration={hasPIMSIntegration ?? false}
      />
    </>
  )
}
