import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { flatMap, uniqBy, orderBy } from 'lodash'
import {
  getConditionalTreatment,
  getConditionalTreatmentVariables,
} from 'src/types/getConditionalTreatment'

import { GET_CONDITIONAL_TREATMENT } from 'components/Sheet/graphql'
import { Product } from '../SelectProductsScreen'
import { useFlags } from 'react-native-flagsmith/react'
import { FeatureFlagNames } from 'constants/FeatureFlags'

export const assembleProducts = (data: getConditionalTreatment | undefined) => {
  const optionProducts =
    data?.getConditionalTreatment?.treatment_options?.products ?? []
  const optionProductGroups =
    data?.getConditionalTreatment?.treatment_options?.product_groups ?? []
  const isBundle = !!data?.getConditionalTreatment?.product?.is_bundle
  const uniqueProducts = uniqBy(
    [
      ...optionProducts.map(product => ({
        id: product.id,
        name: product.name,
        type: product.type,
        code: product.code,
        group: product.parent_product,
        is_fixed: product.is_fixed,
        row_order: product.row_order,
        quantity: product.quantity,
        sites: product.sites,
      })),
      ...flatMap(optionProductGroups, group =>
        group?.products?.items?.map(product => ({
          group,
          id: product.id,
          name: product.name,
          code: product.code,
          type: product.type,
        })),
      ),
    ] as Product[],
    'id',
  )
  const products = isBundle
    ? orderBy(uniqueProducts, ['row_order', 'name'], ['asc', 'asc'])
    : uniqueProducts

  const productGroups = uniqBy(
    products.map(product => product.group),
    'id',
  )
  return {
    products,
    productGroups,
  }
}

type useProductsOptions = {
  patientId: string
  treatmentId: string
}

export const useConditionalTreatment = ({
  patientId,
  treatmentId,
}: useProductsOptions) => {
  const { loading, data } = useQuery<
    getConditionalTreatment,
    getConditionalTreatmentVariables
  >(GET_CONDITIONAL_TREATMENT, {
    variables: {
      patient_id: patientId,
      treatment_id: treatmentId,
    },
    skip: !patientId,
  })

  const conditionalTreatment = data?.getConditionalTreatment

  const { products, productGroups } = useMemo(
    () => assembleProducts(data),
    [data],
  )

  const { enable_bundle_ui } = useFlags([FeatureFlagNames.ENABLE_BUNDLE_UI])
  const isBundleFlagEnabled = enable_bundle_ui.enabled

  const isSingleOption = useMemo(() => {
    // behind feature flag
    if (!isBundleFlagEnabled) return products.length === 1

    return conditionalTreatment?.product?.is_bundle
      ? false
      : products.length === 1
  }, [products, isBundleFlagEnabled, conditionalTreatment?.product?.is_bundle])

  const isSingleSelect = conditionalTreatment?.treatment_options?.is_single

  return {
    loading,
    conditionalTreatment,
    products,
    productGroups,
    isSingleOption,
    isSingleSelect,
  }
}
