import React, { useEffect, useMemo } from 'react'
import { SelectOption } from 'components/common/Select/Select.types'
import { useTranslation } from 'react-i18next'
import { Platform, View } from 'react-native'
import { useSeparationSites } from 'src/hooks/useSeparationSites'
import { SiteType } from 'types/globalTypes'
import { orderBy } from 'lodash'
import { SYNC_WITH_CONSULT_PLACEHOLDER } from 'constants/ClinicalRecord'

import {
  NestedListSelectOption,
  NestedOptionLevels,
} from 'components/common/NestedListSelect/types'
import { SingleSelectNestedMenu } from 'components/common/NestedListSelect/SingleSelectNestedMenu'
import { getDepartments_getSites_items } from 'src/types/getDepartments'
import { SingleSelect } from 'src/design-system/components/Selects'

type DepartmentSelectOptions = SelectOption<string>

type Props = {
  disabled?: boolean
  onSelectedDepartmentChange: (selectedDepartment: string | null) => void
  selectedDepartment: string | null
  handleConsultInSeparationSites?: (
    separationSites: getDepartments_getSites_items[],
  ) => void
  originSheetDepartmentId?: string | null
}

// The lowest number has the highest priority
const departmentSectionOrderMap: Record<SiteType, number> = {
  [SiteType.SITE]: 1,
  [SiteType.BUSINESS]: 2,
  [SiteType.DIVISION]: 3,
  // ignore the unused type below, since the code will never reach there. (typesafe)
  [SiteType.DEPARTMENT]: 4,
  [SiteType.LOCATION]: 5,
}

export const DepartmentSection: React.FC<Props> = ({
  onSelectedDepartmentChange,
  selectedDepartment,
  disabled,
  originSheetDepartmentId,
  handleConsultInSeparationSites,
}) => {
  const isCreateSheet = !originSheetDepartmentId
  const { t } = useTranslation()
  const isIOS = Platform.OS !== 'web'

  const shouldUseNewDepartmentsUI = isCreateSheet && !isIOS

  //  we need to use originSheetDepartmentId to fetch separationSites
  const siteIds = useMemo(() => {
    return !originSheetDepartmentId ? [] : [originSheetDepartmentId]
  }, [originSheetDepartmentId])

  const { separationSites, loading } = useSeparationSites(siteIds)

  useEffect(() => {
    handleConsultInSeparationSites?.(separationSites)
  }, [handleConsultInSeparationSites, separationSites])

  const selectableDepartmentsSingleMenu: DepartmentSelectOptions[] =
    useMemo(() => {
      const selectDepartments = orderBy(
        separationSites.map(({ name, id, type }) => ({
          value: id,
          text: name,
          order: departmentSectionOrderMap[type],
        })),
        ['order', 'text'],
        ['asc', 'asc'],
      )
      return [
        {
          value: SYNC_WITH_CONSULT_PLACEHOLDER,
          text: t('sheetForm.sameAsConsultDept'),
          order: 0,
        },
        ...selectDepartments,
      ]
    }, [separationSites, t])

  const handleNestedMenuChange = (value: string[]) => {
    onSelectedDepartmentChange(value[0])
  }

  const selectableDepartmentsNestedMenu: NestedListSelectOption[] =
    useMemo(() => {
      if (separationSites.length === 1) {
        const firstOption: getDepartments_getSites_items = separationSites[0]
        return [
          {
            value: firstOption.id ?? '',
            text: firstOption.name ?? '',
            nestedOptionLevel: NestedOptionLevels.TOP,
            parentId: null,
          },
        ]
      }
      const selectDepartments = separationSites
        .filter(option => option.type !== SiteType.SITE)
        .map(({ name, id, type, parent_site_id }) => ({
          value: id,
          text: name,
          nestedOptionLevel:
            type === SiteType.DIVISION
              ? NestedOptionLevels.CHILD
              : NestedOptionLevels.PARENT,
          parentId: type === SiteType.DIVISION ? parent_site_id : null,
        }))

      selectDepartments.unshift({
        value: SYNC_WITH_CONSULT_PLACEHOLDER,
        text: t('sheetForm.sameAsConsultDept'),
        parentId: null,
        nestedOptionLevel: NestedOptionLevels.PARENT,
      })
      return selectDepartments
    }, [separationSites, t])

  return (
    <View>
      {shouldUseNewDepartmentsUI ? (
        <SingleSelectNestedMenu
          label={t('sheetForm.attendDept')}
          options={selectableDepartmentsNestedMenu}
          selected={[selectedDepartment ?? '']}
          onChange={handleNestedMenuChange}
          loading={false}
          // If there is only one option, the user can't open the menu
          disabled={disabled || selectableDepartmentsNestedMenu.length < 2}
        />
      ) : (
        <SingleSelect
          label={t('sheetForm.attendDept')}
          options={selectableDepartmentsSingleMenu}
          selected={selectedDepartment}
          onChange={onSelectedDepartmentChange}
          loading={loading}
          allowClear={false}
          disabled={disabled}
        />
      )}
    </View>
  )
}
