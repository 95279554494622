import { StatusIcon } from 'components/Task/common/StatusIcon'
import { SwitchInput } from 'components/common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'
import { Status } from 'src/types/globalTypes'

type Props = {
  showCompleted: boolean
  setShowCompleted: (showCompleted: boolean) => void
}

export const ShowCompletedToggle: React.FC<Props> = ({
  showCompleted,
  setShowCompleted,
}) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View
        style={styles.secondaryContainer}
        accessibilityLabel="Show completed tasks"
      >
        <Text style={styles.label}>
          {t('task:taskPreview.showCompletedToggle')}
        </Text>
        <View style={styles.checkMark}>
          <StatusIcon status={Status.DONE} />
        </View>
      </View>
      <SwitchInput
        style={styles.switch}
        value={showCompleted}
        onChangeValue={setShowCompleted}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    backgroundColor: Colors.Backgrounds.UI,
  },
  secondaryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  label: {
    ...Typography.SubHeading.L,
  },
  checkMark: {
    marginLeft: 4,
  },
  switch: {
    borderBottomWidth: 0,
  },
})
