import {
  OnPressTask,
  OnPressTreatmentGroup,
} from 'components/Grid/GridFlatList'
import { ScaleTimeFn } from 'components/Grid/GridTimeLine/GridTimeline'
import { DosageInfoType } from 'components/Treatment/TreatmentTree'
import { LayoutChangeEvent } from 'react-native'
import { getPatient_getPatient } from 'types/getPatient'
import {
  getSheet_getSheet_treatments_items,
  getSheet_getSheet_treatments_items_treatments_items,
  getSheet_getSheet_treatments_items_treatments_items_treatments_items,
} from 'types/getSheet'

import { NestingDepth } from '../Treatment'

export type TreatmentSheetFirstLevel = getSheet_getSheet_treatments_items

export type TreatmentSheetSecondLevel =
  getSheet_getSheet_treatments_items_treatments_items
export type TreatmentSheetThirdLevel =
  getSheet_getSheet_treatments_items_treatments_items_treatments_items

export type TreatmentChildLevel =
  | TreatmentSheetSecondLevel
  | TreatmentSheetThirdLevel

export type TreatmentSheet = TreatmentSheetFirstLevel | TreatmentChildLevel

type CommonProps = {
  // only used in the case where used in Template Builder , TODO: delete this
  // once we can resolve the __typename of an 'Arrange By Template' Treatment
  // group in #VR-985 . A Treatment row with the Template name won't show in
  // 'Arrange by Product Group' mode in the Template Builder and should always
  // have children in a sheet
  templateName?: string
  foldedTreatments?: string[]
  showAnesthesiaChart?: boolean
}

type CommonGridTreeProps = {
  treatments: TreatmentSheet[]
}

type CommonGroupProps = {
  nestingDepth?: NestingDepth
  isFinalized?: boolean
  isFolded?: boolean
  onToggleFolding?: (id: string) => void
}

export type TreatmentTreeProps = CommonProps &
  CommonGridTreeProps & {
    isFinalized?: boolean
    onPressTreatment: (treatment: TreatmentChildLevel) => void
    onPressTreatmentGroupTitle: (treatment: TreatmentChildLevel) => void
    onToggleFolding: CommonGroupProps['onToggleFolding']
    onTreatmentLayout?: (event: LayoutChangeEvent) => void
    treatmentWidth?: number
    timeInChart?: { timeInChart: (() => void) | ((time: Date) => void) }
  }

export type GridTreatmentTreeProps = CommonProps & {
  onPressTask: OnPressTask
  onPressTreatmentGroup?: OnPressTreatmentGroup
  positionInGridFn: ScaleTimeFn
  treatments: TreatmentChildLevel[]
  timeInGridFn: (x: number, t: TreatmentChildLevel) => void
  isFinalized: boolean
  timeInChart: (x: number) => void
}

export type TreatmentGroupProps = CommonProps &
  CommonGroupProps & {
    dosageInfoType?: DosageInfoType
    onPressTreatment?: (treatment: TreatmentChildLevel) => void
    onPressTreatmentGroupTitle?: (treatment: TreatmentChildLevel) => void
    patient?: getPatient_getPatient | null
    round?: (num: number) => number
    treatment: TreatmentSheet
  }

export type GridTreatmentGroupProps = CommonProps &
  CommonGroupProps & {
    onPressTask: OnPressTask
    onPressTreatmentGroup?: OnPressTreatmentGroup
    positionInGridFn: ScaleTimeFn
    treatment: TreatmentChildLevel
    timeInGridFn: (x: number, t: TreatmentChildLevel) => void
    isFinalized: boolean
  }

export enum TreatmentStateDisplay {
  ConditionalDisplay = 'CONDITIONAL',
  DiscontinuedDisplay = 'DISCONTINUED',
  FinalisedDisplay = 'FINALISED',
  PendingApproval = 'PENDING_APPROVAL',
  GroupDisplay = 'GROUP',
  None = 'NONE',
}
