import { useQuery } from '@apollo/client'
import { GET_DEPARTMENTS } from 'components/Whiteboard/graphql'
import { isEmpty, isNil } from 'lodash'
import { useOrganisation } from 'src/context/organisation'
import {
  getDepartments,
  getDepartments_getSites_items,
  getDepartmentsVariables,
} from 'types/getDepartments'
import { SiteType } from 'types/globalTypes'
import { useCallback, useMemo } from 'react'
import { getOneProduct_getProduct_cubex_integrations } from 'src/types/getOneProduct'

export type AssociatedSites = { [key: string]: true }

type SitesRecord = Record<
  string,
  getDepartments_getSites_items & {
    associatedSites: AssociatedSites
  }
>

const getAssociatedSitesBySiteId = (sitesMap: SitesRecord, siteId: string) => {
  // when siteId is site level or empty =>  null means all access
  if (isEmpty(sitesMap[siteId]) || !sitesMap[siteId].type) {
    return null
  }
  switch (sitesMap[siteId].type) {
    case SiteType.BUSINESS:
      return isEmpty(sitesMap[siteId].associatedSites)
        ? null
        : sitesMap[siteId].associatedSites
    case SiteType.DIVISION: {
      const parentSiteId = sitesMap[siteId].parent_site_id
      return isEmpty(sitesMap[parentSiteId].associatedSites)
        ? null
        : sitesMap[parentSiteId].associatedSites
    }
    default:
      return null
  }
}

const buildSitesMapWithAssociatedSites = (
  sites: getDepartments_getSites_items[],
) => {
  const siteMap: SitesRecord = getAllSitesMap(sites)
  Object.values(siteMap).forEach(site => {
    if (site.type === SiteType.SITE) {
      return
    }

    if (site.type === SiteType.BUSINESS) {
      // Nest site and business level into business associatedSites.
      siteMap[site.id].associatedSites[site.parent_site_id] = true
      siteMap[site.id].associatedSites[site.id] = true
      return
    }

    if (site.type !== SiteType.DIVISION) {
      return
    }

    const isParentSiteEntity =
      siteMap[site.parent_site_id].type === SiteType.SITE

    if (isParentSiteEntity) {
      return
    }
    // Nest division level into business associatedSites.
    siteMap[site.parent_site_id].associatedSites[site.id] = true
  })

  return siteMap
}

export const getAssociatedSiteIds = (
  sites: getDepartments_getSites_items[],
  siteIds?: string[] | null,
) => {
  // when template / product does not have department
  if (!siteIds || isEmpty(siteIds)) {
    return null
  }
  return getAssociatedSites(buildSitesMapWithAssociatedSites(sites), siteIds)
}

const getAssociatedSites = (sitesMap: SitesRecord, siteIds: string[]) => {
  return siteIds.reduce((acc, siteId) => {
    // once null, mean get all access
    if (!acc) {
      return null
    }
    const result = getAssociatedSitesBySiteId(sitesMap, siteId)
    if (isNil(result)) {
      return null
    }
    return { ...acc, ...result }
  }, {} as AssociatedSites | null)
}

export const checkIsProductOrTemplateAvailable = (
  associatedSites: AssociatedSites | null,
  siteIds?: string[] | null,
) => {
  if (!siteIds || !associatedSites || isEmpty(siteIds)) {
    return true
  }
  if (siteIds.some(s => associatedSites[s])) {
    return true
  }
  return false
}

export const useAssociatedSiteIds = (siteIds?: string[] | null) => {
  const [{ organisationId }] = useOrganisation()
  const { data } = useQuery<getDepartments, getDepartmentsVariables>(
    GET_DEPARTMENTS,
    {
      variables: {
        organisationId,
      },
    },
  )

  const allSitesMap = useMemo(() => {
    const sites = data?.getSites?.items ?? []
    return getAllSitesMap(sites)
  }, [data?.getSites?.items])

  const associatedSiteIds = useMemo(() => {
    const sites = data?.getSites?.items ?? []
    return getAssociatedSiteIds(sites, siteIds)
  }, [data?.getSites?.items, siteIds])

  const getIsProductAvailable = useCallback(
    (productSiteIds?: string[] | null) => {
      return checkIsProductOrTemplateAvailable(
        associatedSiteIds,
        productSiteIds,
      )
    },
    [associatedSiteIds],
  )
  const getProductCubexIntegration = useCallback(
    (
      siteId: string | null,
      productCubexIntegrations?:
        | getOneProduct_getProduct_cubex_integrations[]
        | null,
    ) => {
      if (!siteId || !productCubexIntegrations) {
        return
      }

      const productCubex = productCubexIntegrations?.find(
        productCubexIntegration =>
          productCubexIntegration.cubex_cabinet_id ===
          allSitesMap[siteId]?.cubex_cabinet_id,
      )

      return {
        isProductInCubex: !!productCubex,
        isCubexBillable: !!productCubex?.is_cubex_billable,
      }
    },
    [allSitesMap],
  )

  return {
    getIsProductAvailable,
    associatedSiteIds,
    getProductCubexIntegration,
  }
}

const getAllSitesMap = (allSites: getDepartments_getSites_items[]) => {
  return allSites.reduce((acc, site) => {
    acc[site.id] = { ...site, associatedSites: {} }
    return acc
  }, {} as SitesRecord)
}
