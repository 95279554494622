import React, { useState } from 'react'
import {
  ProductGroupName,
  SingleProductPicker,
} from 'components/AddTreatment/SingleProductPicker'
import { Product } from 'components/AddTreatment/ProductRow'
import { FormField } from 'components/common'
import { Dialog } from 'components/common/Dialog/Dialog'
import { useTranslation } from 'react-i18next'
import { ProductType } from 'types/globalTypes'
import { Inputs as MedicineDosageInfo } from '../../TreatmentForm/types'
import { DosageInfo, DosageSetup } from './DosageSetup'
import { ProductDosageField } from './ProductDosageField'

export type ProductSelected = {
  dosageInfo?: DosageInfo
  isBillable?: boolean
  productId: string
  productName: string
}

type Props = {
  patientId: string
  onChange: (product: ProductSelected) => void
  selected: ProductSelected | null
  groupName?: ProductGroupName
  productType?: ProductType
  attendingDepartment?: string | null
}

export const ProductSelect: React.FC<Props> = ({
  patientId,
  onChange,
  selected,
  productType,
  groupName = ProductGroupName.All,
  attendingDepartment,
}) => {
  const { t } = useTranslation()

  const [dialogVisible, setDialogVisible] = useState(false)
  const [internalState, setInternalState] = useState<ProductSelected>()

  const toggleDialog = () => {
    setDialogVisible(!dialogVisible)
    if (dialogVisible) {
      setInternalState(undefined)
    }
  }

  const onSelect = (product: Product) => {
    if (
      product.type === ProductType.MEDICATION ||
      product.type === ProductType.IVFLUIDS
    ) {
      return setInternalState({
        productId: product.id,
        productName: product.name,
        isBillable: product.isBillable,
      })
    }
    onChange({
      productId: product.id,
      productName: product.name,
      isBillable: product.isBillable,
    })
    toggleDialog()
  }

  const onDosageSetup = (dosageInfo: DosageInfo) => {
    onChange({
      ...internalState!,
      dosageInfo,
    })
    toggleDialog()
  }

  const openDosageDialog = () => {
    setInternalState(selected!)
    setDialogVisible(true)
  }

  const handleCreateSheetModal = (
    shouldBeVisible: boolean,
    newDosageInfo?: MedicineDosageInfo,
  ): void => {
    if (!!internalState && !!newDosageInfo) {
      setInternalState({
        ...internalState,
        dosageInfo: newDosageInfo,
      })
    }
    setDialogVisible(shouldBeVisible)
  }

  const siteIds = attendingDepartment ? [attendingDepartment] : []

  return (
    <>
      <FormField
        onPress={toggleDialog}
        value={selected?.productName || t('addTreatment.placeholder')}
        label={t('addTreatment.label')}
        hideChevron={true}
        style={!!selected?.dosageInfo && { borderBottomWidth: 0 }}
      />
      {!!selected?.dosageInfo && (
        <ProductDosageField
          selectedDosageInfo={selected?.dosageInfo}
          onPress={openDosageDialog}
        />
      )}

      <Dialog
        onCancel={toggleDialog}
        showDone={false}
        toggleDialog={toggleDialog}
        visible={dialogVisible}
      >
        {!internalState?.productId ? (
          <SingleProductPicker
            groupName={groupName}
            onSelect={onSelect}
            noTemplate={true}
            productType={productType}
            siteIds={siteIds}
          />
        ) : (
          <DosageSetup
            patientId={patientId}
            productId={internalState.productId}
            dosageInfo={internalState.dosageInfo}
            onSetup={onDosageSetup}
            handleCreateSheetModal={handleCreateSheetModal}
          />
        )}
      </Dialog>
    </>
  )
}
