import React from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { Colors } from 'src/design-system/theme'
import { Label } from '../Text'

interface CheckboxProps {
  checked: boolean
  label?: string
  a11yLabel?: string
  disabled?: boolean
  onPress?: () => void
  size?: number
  labelStyles?: object
}

// TODO FILE - ACTUAL DESIGNS NEEDED

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  a11yLabel,
  checked,
  disabled,
  onPress,
  size = 24,
  labelStyles,
}) => {
  return (
    <TouchableOpacity
      testID={a11yLabel}
      disabled={disabled}
      onPress={onPress}
      hitSlop={16}
      accessibilityLabel={a11yLabel}
      role="checkbox"
      accessibilityRole="checkbox"
      accessibilityState={{ checked }}
    >
      <View style={styles.container}>
        <Ionicons
          name={checked ? 'checkbox' : 'square-outline'}
          size={size}
          color={
            disabled
              ? Colors.Interactive.disabledPrimary
              : checked
              ? Colors.Backgrounds.accentPrimary
              : Colors.Borders.tertiary
          }
        />
        {!!label && (
          <Label size="L" style={[styles.label, labelStyles]}>
            {label}
          </Label>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    marginLeft: 8,
  },
})
