import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useKeyToUrl } from 'src/hooks/useKeyToUrl'
import { PatientFieldsFull as GetPatientsItem } from 'src/types/PatientFieldsFull'

import { getAlertLevelAndAttitude } from './utils/getAlertLevelAndMessage'
import { AvatarWithMessage } from 'components/common/Avatar/AvatarWithMessage'
import { PatientNameText } from './PatientItemComponents/PatientNameText'
import { BreedText } from './PatientItemComponents/BreedText'
import { WeightAgeText } from './PatientItemComponents/WeightAgeText'
import { PatientTag } from './PatientItemComponents/PatientTag'
import { AlertLevelTag } from './PatientItemComponents/AlertLevelTag'
import { PatientOwnerInfo } from './PatientItemComponents/PatientOwnerInfo'
import { SheetListPatientInfo } from './PatientItemComponents/SheetListPatientInfo'
import { getPatientSexTagTitle } from './utils/getPatientSex'

type CopyWithPartial<T, K extends keyof T> = Omit<T, K> & Partial<T>

export type PatientInfo = {
  onPressName?: () => void
  patient: CopyWithPartial<
    Pick<
      GetPatientsItem,
      | 'id'
      | 'avatar_url'
      | 'name'
      | 'contact'
      | 'species'
      | 'breed'
      | 'weight'
      | 'date_of_birth'
      | 'weight_unit'
      | 'sex'
      | 'resuscitate'
      | 'attitude'
      | 'master_problems'
      | 'active_consultations'
    >,
    'active_consultations'
  >
  headerColor?: string
  sideBar?: JSX.Element | null
  criticalNotes?: JSX.Element | null
  callParameter?: JSX.Element | null
  sizeOverride?: 'Large' | 'Small'
}

type PatientInfoWithMode = PatientInfo & {
  asaCat?: React.ReactElement | null
  children?: React.ReactElement | (null | React.ReactElement)[] | null
  mode?: 'card' | 'full' | 'sheet' | 'anesthesia'
  complications?: React.ReactElement | null
  isCollapsed?: boolean
}

type PatientInfoWithImage = PatientInfoWithMode & {
  patientImage: string
}

export const PatientItem: React.FC<PatientInfoWithMode> = ({
  asaCat,
  children,
  mode = 'card',
  onPressName,
  patient,
  sideBar,
  criticalNotes,
  callParameter,
  complications,
  isCollapsed,
  headerColor,
}) => {
  const patientImage = useKeyToUrl(patient?.avatar_url, 160)
  if (!patient) {
    return null
  }

  return (
    <PatientHeaderScreen
      asaCat={asaCat}
      mode={mode}
      onPressName={onPressName}
      patient={patient}
      patientImage={patientImage}
      sideBar={sideBar}
      criticalNotes={criticalNotes}
      callParameter={callParameter}
      complications={complications}
      isCollapsed={isCollapsed}
      headerColor={headerColor}
    >
      {children}
    </PatientHeaderScreen>
  )
}

const ColumnSeparator = () => (
  <View
    style={{ width: 1, backgroundColor: 'lightgrey', marginHorizontal: 8 }}
  />
)

const PatientHeaderScreen = ({
  children,
  onPressName,
  patient,
  patientImage,
  sideBar,
  criticalNotes,
  isCollapsed,
  mode,
  headerColor,
}: PatientInfoWithImage) => {
  const { isExSmallScreen, isLargeScreen } = useBreakpoint()
  const { alertLevel, attitude } = getAlertLevelAndAttitude(patient)
  const isSheetList = mode === 'full'
  const sexTagTitle = getPatientSexTagTitle(patient)
  const patientColor =
    headerColor ?? patient.active_consultations?.items?.[0]?.color?.hex
  const returnPatientName = () => (
    <PatientNameText
      containerStyle={styles.nameTextContainer}
      name={`"${patient.name}" ${patient?.contact?.last_name ?? ''}`}
      textStyle={styles.nameText}
    />
  )

  const returnBreedHolder = () => (
    <BreedText
      speciesName={patient.species?.name}
      breedName={patient.breed?.name}
    />
  )

  const returnPatientDetail = (isSecondColumn: boolean) => (
    <View style={[styles.row, sheetDisplayStyles.topPadding]}>
      <WeightAgeText
        patientWeight={
          patient.weight
            ? `${patient.weight}${patient.weight_unit ?? ''}`
            : `-${patient.weight_unit ?? ''}`
        }
        dateOfBirth={patient.date_of_birth}
        sexTagTitle={sexTagTitle}
        textStyle={isSecondColumn ? styles.weightText : null}
      />
      <PatientTag patient={patient} size={isSecondColumn ? 'big' : 'small'} />
    </View>
  )

  const returnPatientCard = () => {
    return (
      <TouchableOpacity onPress={onPressName}>
        <View style={styles.main}>
          {!isCollapsed && (
            <AvatarWithMessage
              alertLevel={alertLevel}
              message={attitude}
              name={`${patient.name} ${patient.contact?.last_name ?? ''}`}
              uri={patientImage}
              size={56}
            />
          )}

          {isCollapsed ? (
            <View style={styles.info}>
              <View style={styles.row}>
                <View style={styles.collapsedNameHolder}>
                  <AlertLevelTag patient={patient} />
                  {returnPatientName()}
                </View>
              </View>
              {returnBreedHolder()}
              {!isLargeScreen && returnPatientDetail(false)}
            </View>
          ) : (
            <View style={styles.info}>
              <View>
                {returnPatientName()}
                {returnBreedHolder()}
                {!isLargeScreen && returnPatientDetail(false)}
              </View>
            </View>
          )}
        </View>

        {!isCollapsed && <PatientOwnerInfo patient={patient} />}
      </TouchableOpacity>
    )
  }

  const returnFirstColumn = () => {
    return (
      <View
        style={[
          sheetDisplayStyles.firstColum,
          sheetDisplayStyles.flex,
          sheetDisplayStyles.centerColumn,
          !!patientColor && {
            backgroundColor: patientColor,
            borderRadius: 8,
          },
        ]}
      >
        {returnPatientCard()}
        {children}
      </View>
    )
  }

  const returnSecondColumn = () => {
    return (
      <View style={[sheetDisplayStyles.flex, sheetDisplayStyles.centerColumn]}>
        {returnPatientDetail(true)}
        {criticalNotes}
      </View>
    )
  }

  const returnFullPatientInfo = () => {
    return (
      <View
        style={[
          sheetDisplayStyles.flex,
          sheetDisplayStyles.horizontalPadding,
          sheetDisplayStyles.verticalPadding,
        ]}
      >
        <View style={styles.main}>
          <AvatarWithMessage
            alertLevel={alertLevel}
            message={attitude}
            name={`${patient.name} ${patient.contact?.last_name ?? ''}`}
            uri={patientImage}
            size={56}
          />
          <View style={styles.info}>
            {returnPatientName()}
            {returnBreedHolder()}
            {returnPatientDetail(false)}
          </View>
        </View>
        <SheetListPatientInfo patient={patient} />
      </View>
    )
  }

  return (
    <View
      accessibilityLabel={'Patient Item'}
      style={[
        styles.container,
        { flexDirection: 'row-reverse' },
        isSheetList && !!patientColor && { backgroundColor: patientColor },
      ]}
    >
      {!!(sideBar && !isExSmallScreen) && (
        <>
          <View style={sheetDisplayStyles.flex}>{sideBar}</View>
          <ColumnSeparator />
        </>
      )}

      {isLargeScreen && !isSheetList ? (
        <>
          {returnSecondColumn()}
          <ColumnSeparator />
        </>
      ) : null}

      {isSheetList ? returnFullPatientInfo() : returnFirstColumn()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    backgroundColor: Colors.white,
    borderRadius: 6,
    width: '100%',
  },
  info: {
    paddingLeft: 8,
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
    height: 'auto',
  },
  main: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 2,
  },
  nameTextContainer: {
    flexShrink: 1,
  },
  nameText: {
    fontFamily: Fonts.bold,
    fontSize: 20,
  },
  weightText: {
    fontSize: 18,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  collapsedNameHolder: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
})

const sheetDisplayStyles = StyleSheet.create({
  firstColum: {
    paddingHorizontal: 6,
    paddingVertical: 3,
  },
  flex: {
    flex: 1,
  },
  horizontalPadding: {
    paddingHorizontal: 16,
  },
  verticalPadding: {
    paddingVertical: 8,
  },
  centerColumn: {
    justifyContent: 'center',
  },
  topPadding: {
    paddingTop: 4,
  },
})
