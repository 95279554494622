import React, { useCallback, useEffect, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { Routes } from 'constants/Routes'
import { useTranslation } from 'react-i18next'
import { Button, Layout, SwitchInput, TextLink, toast } from 'components/common'
import { Colors } from 'constants/Colors'
import { StaffedHoursConfig } from './types'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import {
  StyleSheet,
  View,
  Text,
  GestureResponderEvent,
  Pressable,
  ActivityIndicator,
} from 'react-native'
import {
  Checkbox,
  FormGroup,
  FormLabel,
  Switch,
  withStyles,
} from '@material-ui/core'
import { TimePicker } from 'components/common/TimePicker/TimePicker.web'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { addHours } from 'date-fns'
import { SvgClose } from 'components/Icons/Close'
import { OrgSettingKeys, useOrgSettings } from 'src/hooks/useOrgSettings'
import { SET_SETTING, SET_STAFFED_HOURS } from '../graphql'
import { updateSetting, updateSettingVariables } from 'src/types/updateSetting'
import { useMutation } from '@apollo/client'
import {
  deserializeStaffedHoursSettings,
  serializeStaffedHoursSettings,
} from './utils'
import {
  updateStaffedHours,
  updateStaffedHoursVariables,
} from 'src/types/updateStaffedHours'
import { StaffedHoursInput } from 'src/types/globalTypes'
import { Fonts } from 'constants/Fonts'
import { useConfirm } from 'src/context/confirm'

export const StaffedHoursScreen: React.FC = () => {
  const { navigate } = useNavigation()
  const { t } = useTranslation()
  const confirm = useConfirm()

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)

  const navigateSettings = useCallback(async () => {
    if (!isFormDirty) return navigate(Routes.Settings)
    try {
      await confirm({
        title: t('settings:staffedHours.confirm.title'),
        text: t('settings:staffedHours.confirm.text'),
      })
      navigate(Routes.Settings)
    } catch (e) {
      // do nothing
    }
  }, [confirm, isFormDirty, navigate, t])

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: navigateSettings,
  }

  const { settings, settingsMap, loading, staffed_hours, organisation } =
    useOrgSettings()

  const featureEnabled = JSON.parse(
    settingsMap?.ENABLE_OPERATING_HOURS?.value?.toLowerCase() || 'false',
  )

  // MUTATIONS
  const [updateSettingsMutation] = useMutation<
    updateSetting,
    updateSettingVariables
  >(SET_SETTING, {
    onCompleted: _data => {
      toast.success(t('settings:update.success'))
    },
    onError: e => {
      console.error(e) // eslint-disable-line no-console
      toast.error(t('settings:update.err'))
    },
  })

  const [updateStaffedHoursMutation, { loading: submitting }] = useMutation<
    updateStaffedHours,
    updateStaffedHoursVariables
  >(SET_STAFFED_HOURS, {
    onCompleted: _data => {
      toast.success(t('settings:update.success'))
    },
    onError: e => {
      console.error(e) // eslint-disable-line no-console
      toast.error(t('settings:update.err'))
    },
  })

  const setStaffedHoursToggle = (val: boolean) => {
    if (!settings || !organisation) return
    updateSettingsMutation({
      variables: {
        input: {
          id: organisation.id,
          name: organisation.name,
          settings: [
            ...settings,
            {
              key: OrgSettingKeys.ENABLE_OPERATING_HOURS,
              value: val.toString(),
            },
          ],
        },
      },
    })
  }

  const setStaffedHours = (data: StaffedHoursInput) => {
    if (!settings || !organisation) return
    updateStaffedHoursMutation({
      variables: {
        input: {
          id: organisation.id,
          name: organisation.name,
          staffed_hours: data,
        },
      },
    })
  }

  const showLoadingIndicator = loading || submitting

  // MAIN
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SubHeader
        backButton={backButton}
        headlineKey={t('settings:staffedHours.header')}
        subHeadline={t('settings:staffedHours.subHeader')}
      />
      {showLoadingIndicator ? (
        <ActivityIndicator size="large" style={styles.spinner} />
      ) : null}
      {!showLoadingIndicator && !!staffed_hours && (
        <StaffedHoursForm
          staffedHoursSettings={deserializeStaffedHoursSettings(staffed_hours)}
          timeFormat={settingsMap?.PREFERRED_TIME_FORMAT?.value}
          featureEnabled={featureEnabled}
          onSave={v => setStaffedHours(serializeStaffedHoursSettings(v))}
          toggleFeature={setStaffedHoursToggle}
          isDirtyCheck={setIsFormDirty}
        />
      )}
    </MuiPickersUtilsProvider>
  )
}

type StaffedHoursFormProps = {
  featureEnabled: boolean
  staffedHoursSettings: StaffedHoursConfig
  timeFormat: string
  onSave: (v: StaffedHoursConfig) => void
  toggleFeature: (v: boolean) => void
  isDirtyCheck: (v: boolean) => void
}

const StaffedHoursForm: React.FC<StaffedHoursFormProps> = ({
  featureEnabled,
  staffedHoursSettings,
  timeFormat,
  onSave,
  toggleFeature,
  isDirtyCheck,
}) => {
  const { t } = useTranslation()

  // FORM
  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitSuccessful },
    reset,
  } = useForm<StaffedHoursConfig>({
    defaultValues: staffedHoursSettings,
    mode: 'onChange',
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  useEffect(() => {
    isDirtyCheck(isDirty)
  }, [isDirty, isDirtyCheck])

  // DAYS
  const dayConfigurations = Object.keys(staffedHoursSettings).map(key => (
    <Day
      key={key}
      idx={key}
      control={control}
      watch={watch}
      timeFormat={timeFormat}
    />
  ))

  // MAIN
  return (
    <>
      <Layout mode="single-center">
        <SwitchInput
          style={styles.switchInputOverride}
          value={featureEnabled}
          onChangeValue={toggleFeature}
          label={t('settings:staffedHours.enableFeatureLabel')}
        />
        <View style={styles.separator} />
        {featureEnabled ? dayConfigurations : null}
      </Layout>
      <View style={styles.saveFooter}>
        <Button
          style={styles.saveButton}
          disabled={!(isDirty && isValid && featureEnabled)}
          a11yLabel="Update Sheet Group Order Button"
          onPress={handleSubmit(onSave, () =>
            toast.error(t('settings:update.err')),
          )}
          color={Colors.blue}
          title={t('general.saveChanges')}
        />
      </View>
    </>
  )
}

const Day: React.FC<any> = ({ control, idx, watch, timeFormat }) => {
  const { t } = useTranslation()

  const dayHoursNameForForm = `${idx}.hours`
  const { fields, append, remove } = useFieldArray({
    control,
    name: dayHoursNameForForm,
  })

  const DEFAULT_START_TIME = addHours(new Date(0), 8) // 8 am
  const DEFAULT_END_TIME = addHours(new Date(0), 17) // 5 pm

  const addDefaultHourBlock = () => {
    append({ start: DEFAULT_START_TIME, end: DEFAULT_END_TIME })
  }

  const enabled = watch(`${idx}.enabled`)
  const disabled = !enabled
  const is24H = watch(`${idx}.is24H`)

  if (!is24H && !fields.length) {
    // Add a Time Block by Default
    addDefaultHourBlock()
  }

  const hoursBlocks = fields.map((field, idx) => {
    return (
      <View key={idx} style={styles.hourSelector}>
        <HourSelector
          key={field.id}
          idx={idx}
          format={timeFormat}
          name={dayHoursNameForForm}
          control={control}
          watch={watch}
        />
        {fields.length > 1 && <DeleteButton onPress={() => remove(idx)} />}
        {fields.length <= 1 && <Text style={styles.addHoursSpacer} />}
        {fields.length === idx + 1 && (
          <AddHoursButton
            text={t(`settings:staffedHours.addHours`)}
            onPress={addDefaultHourBlock}
          />
        )}
      </View>
    )
  })

  const isEnabledAndNot24Hr = !!enabled && !is24H
  const shouldDisplayAddButton = isEnabledAndNot24Hr && !fields.length

  return (
    <View style={styles.zIndexReset}>
      <FormGroup row style={styles.formGroupRow}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormGroup>
              <FormLabel style={styles.label}>
                <BlueCheckbox
                  checked={value}
                  value={value}
                  onChange={onChange}
                />
                {t(`settings:staffedHours.weekdays.${idx}`)}
              </FormLabel>
            </FormGroup>
          )}
          name={`${idx}.enabled`}
          rules={{ validate: { forceValidation: () => true } }}
        />

        {!!enabled && (
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormGroup>
                <FormLabel style={styles.label}>
                  {t(`settings:staffedHours.is24HLabel`)}
                  <Switch checked={value} value={value} onChange={onChange} />
                </FormLabel>
              </FormGroup>
            )}
            name={`${idx}.is24H`}
          />
        )}
        {disabled ? (
          <Text style={styles.closedLabel}>
            {t(`settings:staffedHours.closed`)}
          </Text>
        ) : null}
      </FormGroup>

      {isEnabledAndNot24Hr ? hoursBlocks : null}
      {shouldDisplayAddButton ? (
        <AddHoursButton
          text={t(`settings:staffedHours.addHours`)}
          onPress={addDefaultHourBlock}
        />
      ) : null}

      <View style={styles.separator} />
    </View>
  )
}

const HourSelector: React.FC<any> = ({ idx, name, control, format, watch }) => {
  const startVal = watch(`${name}.${idx}.start`)
  const endVal = watch(`${name}.${idx}.end`)

  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <View style={styles.timePicker}>
            <TimePicker
              key={idx}
              format={format}
              maxTime={endVal}
              value={value}
              onChange={onChange}
              useUTC
              allowCustomTime={false}
            />
          </View>
        )}
        name={`${name}.${idx}.start`}
        rules={{
          validate: {
            beforeEnd: v => v < endVal,
          },
        }}
      />
      <Text style={styles.dash}> — </Text>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <View style={styles.timePicker}>
            <TimePicker
              key={idx}
              minTime={startVal}
              format={format}
              value={value}
              onChange={onChange}
              useUTC
              isMaxTimeInclusiveRange
              allowCustomTime={false}
            />
          </View>
        )}
        name={`${name}.${idx}.end`}
        rules={{
          validate: {
            afterStart: v => v > startVal,
          },
        }}
      />
    </>
  )
}

const DeleteButton: React.FC<{
  onPress: (event: GestureResponderEvent) => void
}> = ({ onPress }) => (
  <Pressable style={styles.deleteButton} onPress={onPress}>
    <SvgClose length={16} color={Colors.contentPrimary} />
  </Pressable>
)

const AddHoursButton: React.FC<{ text: string; onPress: () => void }> = ({
  text,
  onPress,
}) => (
  <TextLink
    containerStyles={styles.addHoursContainer}
    color={Colors.blue}
    text={text}
    onPress={onPress}
  />
)

export const BlueCheckbox = withStyles({
  root: {
    color: Colors.contentTertiary,
    '&$checked': {
      color: Colors.blue,
    },
    '&$disabled': {
      color: Colors.blue,
      backgroundColor: Colors.white,
    },
  },
  checked: {},
  disabled: {},
})(Checkbox)

/*
 We need to reset the zIndex on the View component, as it always sets itself to z-index: 0, 
 But the StyleSheet class only expects a number value for zIndex, and not 'auto'|'initial'|'reset' ... etc
 So we cast string to number here as a HACK 🥲
*/
const Z_INDEX_RESET = 'auto' as unknown as number

// Similar issue, StyleSheet doesn't recognize userSelect. This casting works to fix. HACK
const userSelectNone = {
  userSelect: 'none',
} as {}

const styles = StyleSheet.create({
  switchInputOverride: {
    borderBottomWidth: 0,
  },
  saveButton: {
    marginBottom: 16,
    marginTop: 16,
  },
  formGroupRow: {
    justifyContent: 'space-between',
  },
  saveFooter: {
    borderTopColor: Colors.borderGrey,
    borderTopWidth: 1,
  },
  separator: {
    height: 1,
    width: '100%',
    marginVertical: 16,
    backgroundColor: Colors.borderGrey,
  },
  hourSelector: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 4,
    zIndex: Z_INDEX_RESET,
  },
  zIndexReset: {
    zIndex: Z_INDEX_RESET,
  },
  timePicker: {
    // @ts-ignore
    border: `1px solid ${Colors.shared.borderGrey}`,
    marginHorizontal: 10,
    zIndex: Z_INDEX_RESET,
  },
  deleteButton: {
    marginHorizontal: 10,
  },
  addHoursSpacer: {
    width: 16, // SVG width
    marginHorizontal: 10,
  },
  addHoursContainer: {
    marginHorizontal: 10,
  },
  dash: {
    ...userSelectNone,
    fontSize: 24,
  },
  label: {
    ...userSelectNone,
    fontFamily: Fonts.regular,
    alignSelf: 'center',
    color: Colors.contentPrimary,
  },
  closedLabel: {
    ...userSelectNone,
    fontFamily: Fonts.regular,
    fontSize: '1rem' as unknown as number, // keep consistent with other formLabels
    alignSelf: 'center',
    color: Colors.contentPrimary,
    marginHorizontal: 10,
  },
  spinner: {
    marginTop: 25,
  },
})
