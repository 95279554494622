import { useCallback, useMemo, useRef } from 'react'
import { ApolloQueryResult, useQuery } from '@apollo/client'
import {
  generateUserListHash,
  transformPatientData,
} from 'components/Patient/utils/transformPatientData'
import { Patient } from 'components/PatientItem/PatientListItem'
import { toast } from 'src/components/common/Toast/ToastArea'
import { useOrganisation } from 'src/context/organisation'
import {
  listWhiteboardPatients as ListWhiteboardPatients,
  listWhiteboardPatients_listWhiteboardPatients_items_due_tasks as DueTasks,
  listWhiteboardPatients_listWhiteboardPatients_items_missed_tasks as MissedTasks,
  listWhiteboardPatients_listWhiteboardPatients_items_tasks as PatientTask,
  listWhiteboardPatientsVariables as ListWhiteboardPatientsVariables,
} from 'src/types/listWhiteboardPatients'
import { listWhiteboardWorkflows as ListWhiteboardWorkflows } from 'types/listWhiteboardWorkflows'
import {
  ApprovalStatus,
  OriginTypes,
  veterinary_roles,
} from 'types/globalTypes'

import { LIST_WHITEBOARD_PATIENT } from './graphql'
import { SortPageInput } from './utils/useLocalStorageChangeSort'
import { GET_USERS_LIST } from 'components/Settings/graphql'
import { getUsersList, getUsersListVariables } from 'src/types/getUsersList'

export type PatientListFilters = {
  sites: string[]
  attendingVets: string[]
  attendingVetTechs: string[]
  approvalStatuses: ApprovalStatus[]
  locations: string[]
  searchQuery: string
}

export type DoFetchMore = (
  cursor: string,
) =>
  | Promise<ApolloQueryResult<ListWhiteboardWorkflows | ListWhiteboardPatients>>
  | undefined

export type PatientListData = {
  patient: Patient
  missedTasksCount: number
  tasks: PatientTask[]
  dueTasks: DueTasks
  missedTasks: MissedTasks
}

type UseGetPatientListParams = {
  columns: number
  filters: PatientListFilters
  sortPageInput: SortPageInput
}

export const PATIENT_LIST_QUERY_MAX_AGE = 9 * 1000
export const PATIENT_LIST_POLL_INTERVAL = 18 * 1000
export const PATIENT_LIST_RESULTS_LIMIT = 8

export const useGetPatientList = ({
  columns,
  filters,
  sortPageInput,
}: UseGetPatientListParams) => {
  const queryTimeStampsRef = useRef<{ [cursor: string]: number }>({})

  const [{ organisationId }] = useOrganisation()
  const {
    searchQuery,
    sites,
    locations,
    attendingVets,
    attendingVetTechs,
    approvalStatuses,
  } = filters

  const queryVariables: ListWhiteboardPatientsVariables = useMemo(
    () => ({
      input: {
        organisation_id: organisationId,
        filterOptions: {
          filters: {
            sites,
            attending_vet_user_ids: attendingVets,
            attending_vet_tech_user_ids: attendingVetTechs,
            location_ids: locations,
            approval_statuses: approvalStatuses,
          },
          ...(!!searchQuery && {
            q: searchQuery,
          }),
          columns,
        },
        pageInput: {
          ...sortPageInput,
          limit: !searchQuery ? PATIENT_LIST_RESULTS_LIMIT : null,
        },
      },
    }),
    [
      approvalStatuses,
      attendingVets,
      columns,
      locations,
      organisationId,
      searchQuery,
      sites,
      sortPageInput,
      attendingVetTechs,
    ],
  )

  const { loading, data, fetchMore } = useQuery<
    ListWhiteboardPatients,
    ListWhiteboardPatientsVariables
  >(LIST_WHITEBOARD_PATIENT, {
    variables: queryVariables,
    // no pollInterval - do the polling with a refetch
    fetchPolicy: 'cache-and-network',
    // use cache when return and network update w/ poll
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onError: err => {
      toast.error(err.message)
    },
  })

  // TODO: Might need to remove the role filter so we can use this cache value for the filter as well
  const { data: users } = useQuery<getUsersList, getUsersListVariables>(
    GET_USERS_LIST,
    {
      fetchPolicy: 'cache-first',
      variables: {
        organisation_id: organisationId,
        veterinary_role: veterinary_roles.VETERINARY_TECH,
        origin_type: OriginTypes.EZYVET,
      },
      onError: err => {
        toast.error(err.message)
      },
    },
  )

  const usersList = useMemo(
    () => generateUserListHash(users?.getUsersList?.items ?? []),
    [users?.getUsersList.items],
  )

  const getFetchMoreVariables = useCallback(
    (cursor: string) => {
      return {
        variables: {
          input: {
            ...queryVariables.input,
            pageInput: {
              ...sortPageInput,
              cursor,
              limit: PATIENT_LIST_RESULTS_LIMIT,
            },
          },
        },
      }
    },
    [queryVariables, sortPageInput],
  )

  const doFetchMore: DoFetchMore = useCallback(
    // no cursor when polling a patient query
    (cursor: string) => {
      if (
        !fetchMore ||
        (queryTimeStampsRef.current[cursor] &&
          Date.now() - queryTimeStampsRef.current[cursor] <
            PATIENT_LIST_QUERY_MAX_AGE)
      ) {
        return
      }
      queryTimeStampsRef.current[cursor] = Date.now()

      try {
        const fetchMoreVariables = getFetchMoreVariables(cursor)

        return fetchMore(fetchMoreVariables)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        return
      }
    },
    [fetchMore, getFetchMoreVariables],
  )

  const onRefresh = useCallback(
    async (fetchedCursors: string[]) => {
      if (fetchedCursors.length === 0) return

      if (fetchedCursors.length === 1)
        return fetchMore(getFetchMoreVariables(fetchedCursors[0]))

      await Promise.all(
        fetchedCursors.slice(0, fetchedCursors.length - 1).map(cursor => {
          return fetchMore(getFetchMoreVariables(cursor))
        }),
      )

      return fetchMore(
        getFetchMoreVariables(fetchedCursors[fetchedCursors.length - 1]),
      )
    },
    [fetchMore, getFetchMoreVariables],
  )

  /* Instead of using apollo poll arg, we do timeout based polling so that we
     can change based on visible cursors. We cancel the timeout if user scrolls
     to different section */
  const pollVisibleCursors = (visiblePageCursors: string[]) => {
    const timeoutIds: (number | NodeJS.Timeout)[] = []
    visiblePageCursors.forEach(val => {
      const timeoutId = setInterval(
        () => doFetchMore(val),
        PATIENT_LIST_POLL_INTERVAL,
      )
      timeoutIds.push(timeoutId)
    })
    return timeoutIds
  }

  const { items, nextCursor } = useMemo(
    () => transformPatientData(data, usersList),
    [data, usersList],
  )

  return {
    doFetchMore,
    items,
    loading,
    nextCursor,
    pollVisibleCursors,
    queryTimeStampsRef,
    onRefresh,
  }
}
