import React, { useCallback, useState } from 'react'
import { View, StyleSheet, Pressable } from 'react-native'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import {
  Auth,
  AUTH_STATE,
  AUTH_WEIGHT,
  useAuth,
  userSessionService,
} from 'src/context/auth'
import { Layout } from '../components/Layout'
import { AuthHeader } from '../components/Header'
import { PasswordInput } from '../components/PasswordInput'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { Colors, Typography, Variables } from 'src/design-system/theme'
import { Label } from 'src/design-system/components/Text/Label'
import { TextInput } from 'src/design-system/components/TextInput/TextInput'
import { ErrorCode, PasswordAction } from 'src/context/auth/utils/type'
import { PasswordTextInput } from 'src/design-system/components/TextInput/PasswordTextInput'
import {
  AuthPrimaryButton,
  AuthSecondaryButton,
  IconBackButton,
} from '../components/Buttons'
import { reloadApp } from 'src/utils/reloadApp'

export const ChangePassword: React.FC = () => {
  const { toggleAuthState } = useAuth()
  const { t } = useTranslation()
  const navigation = useNavigation()
  const [title, setTitle] = useState<string>(t('login:label.changePassword'))
  const [action, setAction] = useState<PasswordAction>(
    PasswordAction.CHANGE_PASSWORD_IN_SESSION,
  )
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [session, setSession] = useState<string>('')
  const [isPasswordExpired, setIsPasswordExpired] = useState<boolean>(false)
  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const exAndSmallScreen = isExSmallScreen || isSmallScreen
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [attemptsInSession, setAttemptsInSession] = useState<number>(0)
  const [hasBackBtn, setHasBackBtn] = useState<boolean>(!exAndSmallScreen)

  useFocusEffect(
    useCallback(() => {
      const pwdAction = userSessionService.getPwdActionInfo()
      if (pwdAction) {
        setEmail(pwdAction?.email ?? '')
        setIsPasswordExpired(!!pwdAction?.isPasswordExpired)
        if (!pwdAction?.action) return
        setAction(pwdAction?.action)
        switch (pwdAction?.action) {
          case PasswordAction.FORGET_PASSWORD:
            setTitle(t('login:label.enterVerificationCode'))
            break
          case PasswordAction.NEW_PASSWORD_REQUIRED:
            setSession(pwdAction?.session ?? '')
            setHasBackBtn(false)
            break
          case PasswordAction.CHANGE_PASSWORD_IN_SESSION:
          default:
            break
        }
      }
    }, [setSession, t]),
  )

  const handleBack = async () => {
    if (action === PasswordAction.CHANGE_PASSWORD_IN_SESSION) {
      if (!userSessionService.hasV2Session()) {
        await Auth.signOut()
        return reloadApp()
      }
      userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
      return toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
    }
    if (action === PasswordAction.FORGET_PASSWORD) {
      userSessionService.resetPwdActionInfo()
      return navigation.navigate(AUTH_STATE.RESET_PASSWORD.toLowerCase())
    }
  }

  const handleChangePassword = async () => {
    setIsLoading(true)
    if (!isPasswordValid) return
    const userSession = userSessionService.getUserSession()
    if (action === PasswordAction.NEW_PASSWORD_REQUIRED) {
      const result = await Auth.respondNewPasswordChallenge(
        email,
        newPassword,
        session,
      )
      if (result?.errorCode) {
        if (result.errorCode === ErrorCode.TEMP_PASSWORD_EXPIRED_SESSION) {
          toggleAuthState(AUTH_STATE.RESET_PASSWORD, false)
          const newAuthState = toggleAuthState(AUTH_STATE.LOGIN, false)
          userSessionService.resetPwdActionInfo()
          return navigation.navigate(newAuthState.toLowerCase())
        }
        return
      }
      const newAuthState = toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
      userSessionService.resetPwdActionInfo()
      return navigation.navigate(newAuthState.toLowerCase())
    }
    if (action === PasswordAction.FORGET_PASSWORD) {
      const result = await Auth.confirmForgetPassword(email, code, newPassword)
      if (result?.errorCode) return
      toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
      userSessionService.resetPwdActionInfo()
      return navigation.navigate(AUTH_STATE.LOGIN.toLowerCase())
    }
    if (action === PasswordAction.CHANGE_PASSWORD_IN_SESSION) {
      setAttemptsInSession(attemptsInSession + 1)
      const result = await Auth.changePassword(
        oldPassword,
        newPassword,
        userSession?.userAttributes?.id || '',
      )
      if (result?.errorCode) {
        if (attemptsInSession > 2) {
          await Auth.signOut()
          userSessionService.setErrorCode(
            ErrorCode.CHANGE_PASSWORD_IN_SESSION_MAX_ATTEMPT,
          )
          return reloadApp()
        }
        return
      }
      toggleAuthState(AUTH_STATE.CHANGE_PASSWORD, true)
      userSessionService.resetPwdActionInfo()
      navigation.goBack()
    }
  }
  const handleResetPassword = async () => {
    await Auth.signOut()
    navigation.navigate(AUTH_STATE.RESET_PASSWORD.toLowerCase())
  }

  return (
    <Layout>
      <View style={styles.authContainer}>
        {!hasBackBtn && action !== PasswordAction.NEW_PASSWORD_REQUIRED ? (
          <IconBackButton onPress={handleBack} />
        ) : null}
        <AuthHeader title={title} />
        {isPasswordExpired ? (
          <Label size="M" style={styles.passwordExpiredContainer}>
            <Label size="M" style={styles.circleIcon}>
              !
            </Label>
            {t('login:label.passwordExpired')}
          </Label>
        ) : null}
        {action === PasswordAction.FORGET_PASSWORD ? (
          <>
            <Label size="L">{t('login:label.sentCodeToEmail')}</Label>
            <View style={styles.codeInputStyle}>
              <TextInput textChange={setCode} label={'Code'} />
            </View>
          </>
        ) : null}
        {action === PasswordAction.CHANGE_PASSWORD_IN_SESSION ? (
          <View style={styles.codeInputStyle}>
            <PasswordTextInput
              skipValidation
              onValidation={() => {}}
              onBlur={() => {}}
              textChange={setOldPassword}
              label={t('login:label.currentPassword')}
              autoComplete="current-password"
            />
            <View style={styles.forgotPasswordContainer}>
              <Pressable
                onPress={handleResetPassword}
                aria-label="ForgotPasswordBtn"
              >
                <Label size="M" style={styles.forgotPasswordBtn}>
                  {t('login:label.forgotPassword')}
                </Label>
              </Pressable>
            </View>
          </View>
        ) : null}
        <PasswordInput
          onVerifyPassword={setIsPasswordValid}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
        />
        <View style={[styles.btnContainer]}>
          <AuthPrimaryButton
            onPress={() =>
              handleChangePassword().then(() => setIsLoading(false))
            }
            title={
              action === PasswordAction.FORGET_PASSWORD
                ? t('login:policy.password.continue')
                : t('login:label.changePassword')
            }
            a11yLabel={'ChangepasswordBtn'}
            disabled={!isPasswordValid || isLoading}
            loading={isLoading}
          />
          {hasBackBtn ? (
            <AuthSecondaryButton
              onPress={handleBack}
              title={t('login:pin.back')}
              a11yLabel={'ConfirmpasswordBtn'}
            />
          ) : null}
        </View>
      </View>
    </Layout>
  )
}

const styles = StyleSheet.create({
  authContainer: {
    gap: 8,
  },
  passwordExpiredContainer: {
    backgroundColor: '#CEE4FC',
    color: '#0347A1',
    padding: 10,
    borderRadius: 4,
    display: 'flex',
    marginVertical: Variables.GutterSpacing.md,
  },
  circleIcon: {
    width: Variables.GutterSpacing.md,
    height: Variables.GutterSpacing.md,
    borderRadius: Variables.GutterSpacing.md,
    backgroundColor: '#0347A1',
    color: '#CEE4FC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: Typography.FontSizes.sm,
    fontWeight: Typography.FontWeights.bold,
    marginHorizontal: Variables.GutterSpacing.base,
    marginTop: 2,
  },
  forgotPasswordContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: Variables.GutterSpacing.md,
  },
  forgotPasswordBtn: { color: Colors.Contents.accent },
  codeInputStyle: {
    marginTop: 16,
  },
  btnContainer: {
    borderRadius: 4,
    gap: 16,
  },
})
