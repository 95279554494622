import React, { useCallback, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TextStyle,
  Platform,
  LayoutChangeEvent,
  NativeSyntheticEvent,
  TextLayoutEventData,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { useTranslation } from 'react-i18next'

type Props = {
  textToTruncate: string
  style?: TextStyle
}

export const NUMBER_OF_LINES = 3
export const APPROX_LINE_HEIGHT = 20 * NUMBER_OF_LINES
const isMobile = Platform.OS !== 'web'

export const TruncatedText: React.FC<Props> = ({ textToTruncate, style }) => {
  const { t } = useTranslation()
  const [isTruncated, setIsTruncated] = useState<boolean>()
  const [isCollapsible, setIsCollapsible] = useState<boolean>()

  const handleTruncateText = useCallback(
    (event: NativeSyntheticEvent<TextLayoutEventData> | LayoutChangeEvent) => {
      // Check if mobile to apply proper solution. If not, use the approx line height :)
      const shouldTruncate = isMobile
        ? (event as NativeSyntheticEvent<TextLayoutEventData>).nativeEvent.lines
            ?.length >= NUMBER_OF_LINES
        : (event as LayoutChangeEvent).nativeEvent.layout.height >=
          APPROX_LINE_HEIGHT

      if (isTruncated === undefined) {
        setIsTruncated(shouldTruncate)
      }

      if (isCollapsible === undefined) {
        setIsCollapsible(shouldTruncate)
      }
    },
    [isTruncated, isCollapsible],
  )

  return (
    <View style={styles.truncatedContainer}>
      <Text
        style={[styles.subLabelText, styles.treatmentInstructions, style]}
        numberOfLines={isTruncated ? NUMBER_OF_LINES : undefined}
        onTextLayout={handleTruncateText} // for Mobile (onTextLayout doesn't work in react-native-web)
        onLayout={handleTruncateText} // for Web
      >
        {textToTruncate}
      </Text>
      {!!isCollapsible && (
        <TouchableOpacity
          style={styles.readMoreButton}
          onPress={() => setIsTruncated(!isTruncated)}
        >
          <Text style={styles.readMoreText} testID="Read more button">
            {isTruncated
              ? t('task:taskAddEdit:readMore')
              : t('task:taskAddEdit:readLess')}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  subLabelText: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: 14,
    marginBottom: 'auto',
  },
  treatmentInstructions: {
    lineHeight: 20,
    marginBottom: 16,
    maxWidth: '95%',
  },
  readMoreButton: {
    paddingVertical: 2,
    paddingHorizontal: 4,
    width: '100%',
  },
  readMoreText: {
    color: Colors.blue,
    fontFamily: Fonts.regular,
    fontSize: 12,
    textAlign: 'right',
  },
  truncatedContainer: {
    width: '100%',
    flexDirection: 'column',
  },
})
