import { useCallback, useState } from 'react'

export const useFoldedTreatments = () => {
  const [foldedTreatments, setFoldedTreatment] = useState<string[]>([])

  const handleTreatmentFoldedToggle = useCallback((id: string) => {
    setFoldedTreatment(setTreatments =>
      setTreatments.includes(id)
        ? setTreatments.filter(e => e !== id)
        : [...setTreatments, id],
    )
  }, [])

  return {
    foldedTreatments,
    handleTreatmentFoldedToggle,
  }
}
