import React from 'react'
import { View } from 'react-native'
import { foldedTreatmentsDefault } from './common'
import { groupStyles } from './common/styles'
import { GridTreatmentGroupProps, TreatmentChildLevel } from './common/types'
import { GridTreatment } from './GridTreatment'
import { NestingDepth } from './Treatment'
import { useSortedTreatmentDetails } from './useSortedTreatmentDetails'

export const GridTreatmentGroup: React.FC<GridTreatmentGroupProps> = React.memo(
  ({
    foldedTreatments = foldedTreatmentsDefault,
    isFinalized,
    isFolded = false,
    nestingDepth = 0,
    onPressTask,
    onPressTreatmentGroup,
    positionInGridFn,
    timeInGridFn,
    treatment,
  }) => {
    const { childTreatments, hasChildTreatments, shouldHideTreatment, tasks } =
      useSortedTreatmentDetails(treatment)

    return shouldHideTreatment ? null : (
      <>
        <GridTreatment
          hasNestedTreatmentGroups={hasChildTreatments}
          id={treatment.id}
          isFinalized={isFinalized}
          isFolded={isFolded}
          name={treatment.name}
          nestingDepth={nestingDepth}
          onPressTask={onPressTask}
          onPressTreatmentGroup={onPressTreatmentGroup}
          positionInGridFn={positionInGridFn}
          tasks={tasks}
          timeInGridFn={timeInGridFn}
          treatment={treatment}
        />
        <View style={[isFolded && groupStyles.hidden]}>
          {childTreatments.map((childNode: TreatmentChildLevel) => (
            // Forms tree data structure
            <GridTreatmentGroup
              foldedTreatments={foldedTreatments}
              isFinalized={isFinalized}
              isFolded={foldedTreatments.includes(childNode!.id)}
              key={childNode.id}
              nestingDepth={(nestingDepth + 1) as NestingDepth}
              onPressTask={onPressTask}
              positionInGridFn={positionInGridFn}
              timeInGridFn={timeInGridFn}
              treatment={childNode}
            />
          ))}
        </View>
      </>
    )
  },
)

GridTreatmentGroup.displayName = 'GridTreatmentGroup'
