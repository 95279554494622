import React from 'react'

import { SingleSelect as NewSingleSelect } from './SingleSelect/SingleSelect'
import { MultiSelect as NewMultiSelect } from './MultiSelect/MultiSelect'

// Stolen from existing Select
export type PossibleSelectValues = string | number | boolean | null

export type SelectOption = {
  value: PossibleSelectValues
  text?: string
  icon?: React.ReactElement // TODO
}

export type Group = {
  label?: string
  value?: PossibleSelectValues
  options: SelectOption[]
}

export type SelectProps = {
  label?: string
  selected: PossibleSelectValues | PossibleSelectValues[]
  options: Array<SelectOption | Group>
  onChange: (value: any) => void
  disabled?: boolean
  loading?: boolean
  allowClear?: boolean
  hideChevron?: boolean
  placeholder?: string
}

/* Handle Feature Flags in one Place */
export const SingleSelect = (
  props: Omit<SelectProps, 'selected' | 'onChange'> & {
    selected: PossibleSelectValues
    onChange: (value: any) => void
  },
) => {
  return <NewSingleSelect {...props} />
}

export const MultiSelect = (
  props: Omit<SelectProps, 'selected' | 'onChange'> & {
    selected: PossibleSelectValues[]
    onChange: (value: any) => void
  },
) => {
  return <NewMultiSelect {...props} />
}
