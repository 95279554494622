import React from 'react'
import Svg, { Path } from 'react-native-svg'
import { Colors } from 'src/design-system/theme'

type Props = {
  size?: number
  color?: string
}

export const EmailSvg: React.FC<Props> = ({
  size = 28,
  color = Colors.Contents.accessory,
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4H4ZM4 18V8L11.47 12.6688C11.7943 12.8714 12.2057 12.8714 12.53 12.6688L20 8V18H4ZM4 6H20L12.53 10.6688C12.2057 10.8714 11.7943 10.8714 11.47 10.6688L4 6Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4H4ZM4 18V8L11.47 12.6688C11.7943 12.8714 12.2057 12.8714 12.53 12.6688L20 8V18H4ZM4 6H20L12.53 10.6688C12.2057 10.8714 11.7943 10.8714 11.47 10.6688L4 6Z"
      />
    </Svg>
  )
}
