import { PatientSpecies } from './PatientSpecies'

// mLs/kg, 24hours
const defaultPhysiologicReq = 60
const physiologicReqConstants = {
  [PatientSpecies.FEL]: 80,
  [PatientSpecies.CAT]: 80,
  [PatientSpecies.CAN]: 132,
  [PatientSpecies.DOG]: 132,
  [PatientSpecies.EQUI]: 60,
} as any

export const getPhysiologicReq = (species: string) => {
  return physiologicReqConstants[species] ?? defaultPhysiologicReq
}
