import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { Colors } from 'src/design-system/theme'
import { Colors as oldColors } from 'constants/Colors'
import { Status } from 'src/types/globalTypes'
import {
  SvgCheckMark,
  SvgExclamation,
  SvgInProgress,
  SvgPendingClock,
  SvgSkipped,
} from 'components/Icons'

type StatusIconProps = { status: Status }

const styleOverridesMap = {
  [Status.DUE]: { backgroundColor: oldColors.task.due, opacity: 0.36 },
  [Status.DONE]: { backgroundColor: Colors.Backgrounds.positive },
  [Status.MISSED]: { backgroundColor: Colors.Backgrounds.negative },
  [Status.MISSED_ON_PURPOSE]: {
    backgroundColor: oldColors.sheetHistoryRow.missedOnPurposeTask,
    opacity: 0.36,
  },
  [Status.IN_PROGRESS]: { backgroundColor: Colors.Backgrounds.positive },
  [Status.DELETED_ON_PURPOSE]: {},
  [Status.PENDING]: {},
} as const

export const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
  const iconBackgroundStyle = useMemo(
    () => ({ ...styles.statusIconBackground, ...styleOverridesMap[status] }),
    [status],
  )

  const SvgIcon = useMemo(() => {
    switch (status) {
      case Status.DUE:
        return (
          <SvgPendingClock
            width={16}
            height={16}
            color={oldColors.sheetHistoryRow.dueTasksPrimary}
          />
        )
      case Status.DONE:
        return (
          <SvgCheckMark
            width={16}
            height={16}
            color={Colors.Contents.positiveOnColor}
          />
        )
      case Status.MISSED:
        return (
          <SvgExclamation
            height={16}
            width={16}
            color={oldColors.sheetHistoryRow.missedTasksPrimary}
          />
        )
      case Status.MISSED_ON_PURPOSE:
        return (
          <SvgSkipped
            length={16}
            color={oldColors.sheetHistoryRow.missedOnPurposeTask}
          />
        )
      case Status.IN_PROGRESS:
        return (
          <SvgInProgress length={24} color={Colors.Contents.positiveOnColor} />
        )

      default:
        return null
    }
  }, [status])
  return (
    <View style={styles.statusIconContainer}>
      <View style={iconBackgroundStyle} />
      <View style={styles.onTopZIndex}>{SvgIcon}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  onTopZIndex: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
  },
  statusIconContainer: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusIconBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    marginHorizontal: 8,
    borderRadius: 180,
    opacity: 0.64,
  },
})
