import { Dimensions } from 'react-native'
import {
  DEFAULT_DATEBOX_WIDTH,
  SMALL_GRID_DATEBOX_MULTIPLY,
  getSegmentsPerOneOrThreeDays,
  TimeSegment,
} from 'src/hocs/types/time'

type GetDefaultGridWidthParams = {
  timeSegment: TimeSegment
  dateBoxWidth?: number
  daysRendered: number
}

export const getDefaultGridWidth = ({
  timeSegment,
  dateBoxWidth = DEFAULT_DATEBOX_WIDTH,
  daysRendered,
}: GetDefaultGridWidthParams) => {
  return (
    getSegmentsPerOneOrThreeDays(timeSegment, daysRendered) *
    dateBoxWidth *
    daysRendered
  )
}

export const getGridToScreenCoverageRatio = (
  timeSegment: TimeSegment,
  days: number,
  windowWidthOverride?: number,
) => {
  const { width: windowWidth } = windowWidthOverride
    ? { width: windowWidthOverride }
    : Dimensions.get('window')

  const defaultGridWidth = getDefaultGridWidth({
    timeSegment,
    daysRendered: days,
  })
  return +(defaultGridWidth / windowWidth).toFixed(1)
}

const isGridTooSmall = (gridScreenCoverage: number, coverage: number) => {
  return gridScreenCoverage < coverage
}

/* We make the dateBox width dynamic here so that we can increase the width when
   the grid is smaller than the viewable area. This give the impression of a
   larger grid when rendering grids at zoom levels like 24hr. */
export const getDateBoxWidth = (
  timeSegment: TimeSegment,
  days: number,
  windowWidthOverride?: number,
) => {
  const sizeRatio = getGridToScreenCoverageRatio(
    timeSegment,
    days,
    windowWidthOverride,
  )
  const isRenderedGridTooSmall = isGridTooSmall(sizeRatio, days)

  if (isRenderedGridTooSmall) {
    // Invert (and multiply by ZOOM_FACTOR) the size ratio of the grid to increase grid size relative to ratio
    const multiplier = +(SMALL_GRID_DATEBOX_MULTIPLY / sizeRatio).toFixed(2)
    return DEFAULT_DATEBOX_WIDTH * multiplier
  }
  return DEFAULT_DATEBOX_WIDTH
}
