import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  FormLabel,
  SelectDateTimePicker,
  toast,
  treatmentHeight,
} from 'components/common'
import { FixedQuantityProductRow } from 'components/common/ProductRow/FixedQuantityProductRow'
import { FrequencyInput } from 'components/FrequencySelector/FrequencySelector'
import { TreatmentStateDisplay } from 'components/Treatment/common/types'
import { getBackgroundColor } from 'components/Treatment/Treatment'
import { Typography } from 'src/design-system/theme'
import { orderBy } from 'lodash'
import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  UseFieldArrayUpdate,
  useForm,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { PatientById } from 'src/hooks/usePatient'
import { useStartTime } from 'src/hooks/useStartTime'
import { BundleProduct } from 'src/types/BundleProduct'
import { getValidBundleProducts_getValidBundleProducts_bundle_items_product } from 'types/getValidBundleProducts'
import * as Yup from 'yup'

import { ProductName } from '../ProductName'
import { TemplateTreatmentFormContainer } from '../TemplateTreatmentFormContainer'
import { CreateTreatmentFromInput } from '../templateUtilFns'
import { useTemplateDuplication } from '../useTemplateDuplication'
import { BundleProductRow } from './BundleProductRow'
import { groupBundleItems } from './utils/groupBundleItems'
import { useSheetContext } from 'src/context/sheet'
import { Checkbox } from 'src/design-system/components/CheckBox/Checkbox'

export type AddBundleFormProps = {
  onSave: (data: FormDataBundle) => void
  bundle: Bundle
  patient: PatientById
  sheetId: string
  handleBackOverrides: Dispatch<SetStateAction<(() => void) | null>>
}

export type Bundle = {
  product: getValidBundleProducts_getValidBundleProducts_bundle_items_product
  bundle_items?: BundleItem[]
}

export type BundleItem = Bundle & {
  is_fixed: boolean | null
  is_single: boolean | null
  quantity: number | null
  row_order: number | null
}

export type FormDataBundle = {
  startTime: Date | 'now'
  bundleTreatmentFormInputs?: BundleTreatmentFormInput[]
}

export type BundleTreatmentFormInput = {
  shouldCreateTreatment: boolean
  frequencyInput?: FrequencyInput
  createTreatmentFromInput: CreateTreatmentFromInput
  name: string
  isDuplicated?: boolean
  isInvalidSite?: boolean
  isFixed: boolean | null
  sites: string[] | null
  quantity?: number | null
  productId?: string
  product: BundleProduct
  initFrequencyInput?: FrequencyInput
}

const validationSchema = yupResolver(
  Yup.object().shape({
    bundleTreatmentFormInputs: Yup.array().test(
      'NoSelect',
      '',
      (bundleTreatmentFormInputs: BundleTreatmentFormInput[]) =>
        bundleTreatmentFormInputs.some(
          bundleTreatmentFormInput =>
            bundleTreatmentFormInput.shouldCreateTreatment,
        ),
    ),
  }),
)

export const AddBundleForm: React.FC<AddBundleFormProps> = ({
  onSave,
  bundle,
  patient,
  sheetId,
  handleBackOverrides,
}) => {
  const { t } = useTranslation()
  const startTime = useStartTime()
  const [{ sheetAttendingDepartment }] = useSheetContext()

  const templateDuplicationIdHash = useTemplateDuplication({
    sheetId,
    patientId: patient.id,
  })
  const backgroundColor = getBackgroundColor(TreatmentStateDisplay.GroupDisplay)

  const orderedItems = orderBy(bundle?.bundle_items ?? [], 'row_order')
  const items = orderedItems.map(item => {
    const isDuplicated = !!templateDuplicationIdHash[item.product.id]
    const isFixedItem = item.is_fixed && !item.product.is_bundle

    return {
      shouldCreateTreatment: !isDuplicated && !isFixedItem,
      name: item.product.name,
      productId: item.product.id,
      product: item.product,
      isFixed: item.is_fixed,
      quantity: item.quantity,
      sites: item.product.sites,
      isDuplicated,
      isInvalidSite: undefined,
      createTreatmentFromInput: {
        defaultOverrides: {
          startAtDate: startTime,
          isBillable: !!item.product?.is_billable,
        },
        product: item.product,
        isSetup: false,
      },
    }
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    watch,
  } = useForm<FormDataBundle>({
    mode: 'onChange',
    defaultValues: {
      startTime,
      bundleTreatmentFormInputs: items,
    },
    resolver: validationSchema,
  })
  const watchedStartTime = watch('startTime', startTime)

  const { fields, update } = useFieldArray({
    control,
    name: 'bundleTreatmentFormInputs',
  })

  const sortedBundlesGroups = useMemo(() => groupBundleItems(fields), [fields])

  const [productIndexToSetup, setProductIndexToSetup] = useState<number | null>(
    null,
  )
  const productToSetup =
    productIndexToSetup !== null ? fields[productIndexToSetup] : undefined

  useEffect(() => {
    if (productToSetup) {
      handleBackOverrides(() => () => setProductIndexToSetup(null))
    } else {
      handleBackOverrides(null)
    }
  }, [handleBackOverrides, productIndexToSetup, productToSetup])

  if (
    productIndexToSetup !== null &&
    productToSetup?.createTreatmentFromInput
  ) {
    const { createTreatmentFromInput } = productToSetup
    if (!createTreatmentFromInput.product) {
      toast.error('There are no bundle products')
      return null
    }
    const startAtDateOverrides = createTreatmentFromInput?.isSetup
      ? {}
      : { startAtDate: watchedStartTime }
    return (
      <TemplateTreatmentFormContainer
        createTreatmentFromInput={createTreatmentFromInput}
        defaultOverrides={startAtDateOverrides}
        onSave={data => {
          update(productIndexToSetup, {
            ...productToSetup,
            createTreatmentFromInput: {
              ...createTreatmentFromInput,
              defaultOverrides: data,
              isSetup: true,
            },
          })
          setProductIndexToSetup(null)
        }}
        patient={patient}
      />
    )
  }

  return (
    <View style={styles.container} testID="addBundleForm">
      <ProductName productName={bundle?.product?.name} />
      <KeyboardAwareScrollView>
        <Controller
          control={control}
          name="startTime"
          render={({ field: { onChange, value } }) => (
            <SelectDateTimePicker
              onChange={onChange}
              type="now"
              value={value}
              title={t('addTreatment:schedule:startsLabel')}
            />
          )}
        />
        <FormLabel text={t('addTreatment:addBundle:treatmentList')} />
        <View style={{ flexDirection: 'row', padding: 16, paddingTop: 8 }}>
          <ToggleSelectAllButton fields={fields} update={update} />
        </View>
        <View style={{ backgroundColor }}>
          {sortedBundlesGroups?.map(groupedBundle => (
            <Fragment key={groupedBundle.name}>
              <View style={styles.groupContainer}>
                <Text style={styles.groupText}>{groupedBundle.name}</Text>
              </View>
              {groupedBundle.items.map(bundleItem => {
                const bundleField = bundleItem.field
                const fieldProduct = bundleField.product
                if (!fieldProduct.is_bundle && bundleField.isFixed) {
                  return (
                    <FixedQuantityProductRow
                      product={bundleField}
                      key={bundleField.id}
                      searchWords={[]}
                      attendingDepartment={sheetAttendingDepartment}
                    />
                  )
                }
                return (
                  <BundleProductRow
                    product={fieldProduct}
                    isDuplicated={bundleField.isDuplicated}
                    key={bundleField.id}
                    index={bundleItem.index}
                    update={update}
                    value={bundleField}
                    setProductSetup={setProductIndexToSetup}
                  />
                )
              })}
            </Fragment>
          ))}
        </View>
      </KeyboardAwareScrollView>
      <Button
        onPress={handleSubmit(onSave)}
        testID="addBundleButton"
        title={t('addTreatment:addBundle:title')}
        loading={isSubmitting}
        disabled={!isValid}
        containerStyle={styles.containerStyle}
      />
    </View>
  )
}

interface ToggleSelectAllButtonProps {
  fields: FieldArrayWithId<FormDataBundle, 'bundleTreatmentFormInputs', 'id'>[]
  update: UseFieldArrayUpdate<FormDataBundle, 'bundleTreatmentFormInputs'>
}

const ToggleSelectAllButton: React.FC<ToggleSelectAllButtonProps> = ({
  fields,
  update,
}) => {
  const { t } = useTranslation()
  const isCreatable = (field: BundleTreatmentFormInput): boolean => {
    const { isInvalidSite, isFixed, product } = field
    return !isInvalidSite && !(isFixed && !product.is_bundle)
  }
  const someSelectable = fields.some(isCreatable)
  const allSelected =
    someSelectable &&
    fields.every(field => !isCreatable(field) || field.shouldCreateTreatment)

  const toggleAll = () => {
    fields.forEach((field, index) => {
      if (isCreatable(field)) {
        update(index, {
          ...field,
          shouldCreateTreatment: !allSelected,
        })
      }
    })
  }
  return (
    <Checkbox
      onPress={toggleAll}
      checked={allSelected}
      disabled={!someSelectable}
      label={
        allSelected
          ? t('addTreatment:selectAll:deselectAll')
          : t('addTreatment:selectAll:selectAll')
      }
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 16,
  },
  containerStyle: {
    marginTop: 16,
  },
  groupContainer: {
    height: treatmentHeight,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  groupText: {
    ...Typography.SubHeading.M,
    marginLeft: 8,
  },
})
