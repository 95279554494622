import React from 'react'
import { Colors } from 'constants/Colors'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color?: string
  width?: number
  height?: number
}

export const SvgClock: React.FunctionComponent<Props> = ({
  width = 18,
  height = 18,
  color = Colors.contentSecondary,
}) => (
  <Svg width={width} height={height} viewBox="0 0 18 18">
    <G
      transform="translate(-1316.000000, -185.000000)"
      fill={color}
      fillRule="nonzero"
    >
      <G transform="translate(1306.000000, 176.000000)">
        <G transform="translate(10.000000, 7.000000)">
          <Path d="M8.99996115,20 C13.9234722,20 18,15.9235275 18,11 C18,6.08528914 13.9146538,2 8.99114274,2 C4.07645013,2 0,6.08528914 0,11 C0,15.9235275 4.08528661,20 8.99996115,20 Z M8.99996115,18.500003 C4.83526726,18.500003 1.50881043,15.1647094 1.50881043,11.000003 C1.50881043,6.84410909 4.82644885,3.50000301 8.99114274,3.50000301 C13.1558366,3.50000301 16.4911119,6.84410909 16.499984,11.000003 C16.508821,15.1647094 13.164655,18.500003 8.99996115,18.500003 Z M4.38527526,11.9529342 L8.99114274,11.9529342 C9.33525958,11.9529342 9.60879299,11.6882363 9.60879299,11.3352997 L9.60879299,5.38823444 C9.60879299,5.04411632 9.33525958,4.77941841 8.99114274,4.77941841 C8.64702589,4.77941841 8.38232896,5.04411632 8.38232896,5.38823444 L8.38232896,10.7264656 L4.38527526,10.7264656 C4.03234,10.7264656 3.767625,10.9911816 3.767625,11.3352997 C3.767625,11.6882363 4.03234,11.9529342 4.38527526,11.9529342 Z" />
        </G>
      </G>
    </G>
  </Svg>
)
