import React from 'react'
import { Pressable } from 'react-native'
import { Tooltip as MUITooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Fonts } from 'constants/Fonts'
import { Colors } from 'constants/Colors'

const StyledMUITooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: Colors.white,
    color: Colors.contentPrimary,
    maxWidth: 250,
    padding: 16,
    fontFamily: Fonts.regular,
    fontSize: 14,
    boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.24)',
    borderRadius: 4,
    marginBottom: 4,
  },
}))(MUITooltip)

export interface ToolTipProps {
  text: string
  children: React.ReactNode
}

export const Tooltip = ({
  text,
  children,
}: React.PropsWithChildren<ToolTipProps>) => (
  <StyledMUITooltip title={text} placement={'top'}>
    <Pressable>{children}</Pressable>
  </StyledMUITooltip>
)
