import { cloneDeep, get, set } from 'lodash'
import { useSubscription } from '@apollo/client'
import { ParamListBase, RouteProp, useRoute } from '@react-navigation/native'
import { useOrganisation } from 'src/context/organisation'
import { GET_PATIENT_WITH_SHEETS, SUBSCRIBE_SHEET } from './graphql'
import { MutationType } from 'src/types/globalTypes'
import {
  getPatientWithSheets,
  getPatientWithSheetsVariables,
  getPatientWithSheets_getPatient_sheets_items,
} from 'src/types/getPatientWithSheets'
import { sheet, sheetVariables } from 'src/types/sheet'

type SheetSubscriptionRouteParams = RouteProp<ParamListBase, string> & {
  params: {
    patientId: string
  }
}

export const useSheetSubscription = () => {
  const {
    params: { patientId },
  } = useRoute<SheetSubscriptionRouteParams>()

  const [{ organisationId }] = useOrganisation()

  useSubscription<sheet, sheetVariables>(SUBSCRIBE_SHEET, {
    variables: { patientId },
    onData: options => {
      try {
        const preData = options.client.readQuery<
          getPatientWithSheets,
          getPatientWithSheetsVariables
        >({
          query: GET_PATIENT_WITH_SHEETS,
          variables: {
            id: patientId,
            organisation_id: organisationId,
          },
        })

        const cloned = cloneDeep(preData ?? { getPatient: null })
        const itemsPath = `getPatient.sheets.items`
        const preItems: getPatientWithSheets_getPatient_sheets_items[] = get(
          cloned,
          itemsPath,
          [],
        )
        const addedItem = options.data.data?.sheet?.data

        switch (options.data.data?.sheet?.mutation) {
          case MutationType.CREATE:
            set(cloned, itemsPath, [
              addedItem,
              ...preItems.filter(item => item.id !== addedItem?.id),
            ])
            break
          case MutationType.DELETE:
            set(
              cloned,
              itemsPath,
              preItems.filter(item => item.id !== addedItem?.id),
            )
            break
          default:
            return
        }

        options.client.writeQuery<
          getPatientWithSheets,
          getPatientWithSheetsVariables
        >({
          query: GET_PATIENT_WITH_SHEETS,
          variables: {
            id: patientId,
            organisation_id: organisationId,
          },
          data: cloned,
        })
      } catch {
        // ignore if can't find GET_SHEETS in cache
      }
    },
  })
}
