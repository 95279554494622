import React from 'react'
import { CheckButton, Input, Select } from 'components/shared'
import { getTaskUnit } from 'components/Task/utils/getTaskUnit'
import { getTreatmentInfo } from 'components/Task/utils/getTreatmentInfo'
import { Colors } from 'constants/Colors'
import { isNil } from 'lodash'
import { KeyboardTypeOptions, StyleSheet, View, TextInput } from 'react-native'
import { SvgCreditCard } from 'src/components/Icons'
import { SubTreatmentWithChecked } from './BulkTaskCreate'
import { HandleChange } from './BulkTaskList'
import { useTranslation } from 'react-i18next'
import { getPatient_getPatient } from 'src/types/getPatient'
import { getProductIsHealthStatusWeight } from 'components/Task/utils/getProductInfo'
import { regexToNumber } from 'components/Treatment/utils/allowNumberAndOnlyOneDot'
import { ApprovalRequiredTextMessage } from 'components/BulkTask/ApprovalRequiredTextMessage'
import { useApprovals } from 'src/hooks/useApprovals'
import { retrieveVitalProductOptions } from 'src/utils/retrieveVitalProductOptions'

type Props = {
  treatmentTask: SubTreatmentWithChecked
  idx: number
  keyboardType: KeyboardTypeOptions
  patient: getPatient_getPatient | null | undefined
  autoFocus: boolean
  taskInputRefs: React.MutableRefObject<
    Record<
      number,
      {
        input: TextInput | HTMLElement
        notes?: HTMLElement | TextInput | undefined
      }
    >
  >
  handleChange: HandleChange
}

export const BulkTaskCreateListItem: React.FC<Props> = ({
  treatmentTask,
  idx,
  keyboardType,
  patient,
  taskInputRefs,
  handleChange,
  autoFocus,
}) => {
  const { t } = useTranslation()
  const { isTreatmentPending } = useApprovals()

  const { treatment, value, checked, isBillable } = treatmentTask

  const treatmentProduct = treatment.product

  const shouldShowRequiredApproval =
    !!treatment && isTreatmentPending(treatment)

  if (!treatmentTask) return null

  const taskUnit = getTaskUnit({
    dosageInfo: treatment?.medicine_dosage_info,
    patientWeightUnit: patient?.weight_unit,
    product: treatmentProduct,
  })

  const handleNextFocus = (type: 'notes' | 'input') => () => {
    const nextNotes = taskInputRefs.current[idx]?.notes
    const nextInput = taskInputRefs.current[idx + 1]?.input

    if (nextNotes && isNotesRequired && type !== 'notes') {
      nextNotes.focus()
    } else if (nextInput) {
      nextInput.focus()
    }
  }

  const {
    customValueOptions,
    hasCustomValues,
    isHealthStatusProduct,
    isValueRequired,
    isNotesRequired,
    isObservation,
  } = getTreatmentInfo(treatment)

  const customProductOptions = retrieveVitalProductOptions(
    customValueOptions,
    isHealthStatusProduct,
    treatmentProduct?.origin_id,
  )

  const handleChangeRow = (updatedValue: string) => {
    if (getProductIsHealthStatusWeight(treatmentProduct)) {
      const revertedValue = regexToNumber(updatedValue)
      handleChange(`${treatment.id}.checked`, !!revertedValue)
      handleChange(`${treatment.id}.value`, revertedValue)
      return
    }
    handleChange(`${treatment.id}.checked`, !!updatedValue)
    handleChange(`${treatment.id}.value`, updatedValue)
  }

  const handleNotes = (note: string) => {
    handleChange(`${treatment.id}.checked`, !!note)
    handleChange(`${treatment.id}.notes`, note)
  }

  const handleChangeChecked = (checked: boolean) => {
    handleChange(`${treatment.id}.checked`, checked)
  }

  const handleBulkWeightValueChange = (val: string) => {
    if (val.endsWith('.')) {
      const splitVal = val.split('.')
      if (!splitVal[1]) handleChange(`${treatment.id}.value`, splitVal[0])
    }
  }

  const inputProps = {
    label: treatment.name,
    required: isValueRequired,
    ...(!isNil(isBillable) && {
      iconRight: (
        <SvgCreditCard
          color={
            isBillable ? Colors.contentSecondary : Colors.patient.borderBottom
          }
        />
      ),
    }),
  }

  return (
    <View
      key={treatment.id}
      style={styles.choices}
      accessibilityLabel={`Complete: ${treatment.name}`}
    >
      <CheckButton
        key={`${treatment.id} - check`}
        onChange={handleChangeChecked}
        checked={checked}
        style={styles.checkButton}
      />
      <View style={styles.completeContainer}>
        {isHealthStatusProduct || hasCustomValues ? (
          <Select
            a11yLabel={`Options: ${treatment.name}`}
            fRef={el => {
              if (el)
                taskInputRefs.current[idx] = {
                  ...taskInputRefs.current[idx],
                  input: el,
                }
            }}
            allowClear={true}
            options={customProductOptions}
            placeholder={t('task:taskTransition.form.valueLabel')}
            selected={value}
            onChange={handleChangeRow}
            {...inputProps}
            disabled={shouldShowRequiredApproval}
          />
        ) : (
          <Input
            accessibilityLabel={`Value: ${treatment.name}`}
            ref={el => {
              if (el)
                taskInputRefs.current[idx] = {
                  ...taskInputRefs.current[idx],
                  input: el,
                }
            }}
            onSubmitEditing={handleNextFocus('input')}
            returnKeyType="next"
            onChange={handleChangeRow}
            value={value}
            autoFocus={autoFocus}
            {...(taskUnit && {
              suffix: taskUnit,
            })}
            {...inputProps}
            onBlur={
              getProductIsHealthStatusWeight(treatmentProduct)
                ? () => handleBulkWeightValueChange(value ?? '')
                : undefined
            }
            textInputDisabledGreyOut={shouldShowRequiredApproval}
            textInputDisabled={shouldShowRequiredApproval}
          />
        )}
        {!!isNotesRequired && !isObservation && (
          <Input
            accessibilityLabel={`Notes: ${treatment.name}`}
            keyboardType={keyboardType}
            label={t('task:taskTransition:form:notes')}
            onChange={handleNotes}
            onSubmitEditing={handleNextFocus('notes')}
            ref={el => {
              if (el)
                taskInputRefs.current[idx] = {
                  ...taskInputRefs.current[idx],
                  notes: el,
                }
            }}
            returnKeyType="next"
            value={treatmentTask.notes ?? ''}
            numberOfLines={2}
            required
            textInputDisabledGreyOut={shouldShowRequiredApproval}
            textInputDisabled={shouldShowRequiredApproval}
          />
        )}
        {shouldShowRequiredApproval ? <ApprovalRequiredTextMessage /> : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  checkButton: {
    marginTop: 28,
    marginRight: 15,
  },
  completeContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
  },
  choices: {
    flexDirection: 'row',
    flex: 0,
    flexBasis: 'auto',
    marginVertical: 4,
    alignItems: 'flex-start',
  },
})
