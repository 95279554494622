import { getOneProduct_getProduct as OneProduct } from 'src/types/getOneProduct'
import { ProductType } from 'src/types/globalTypes'
import { RepeatScheduleValue } from '../data'
import { getProductDefaultInstructions } from 'components/TreatmentForm/utils/isCustomProduct'
import { FluidDosageFormData } from '../types'

export function getDefaultFluidDosageFormValues({
  defaultPatientWeight,
  defaultPatientWeightUnit,
  startTime,
  product,
  defaultOverrides,
}: {
  defaultPatientWeight: number | undefined
  defaultPatientWeightUnit: string | undefined
  startTime: Date
  product: OneProduct | Partial<OneProduct>
  defaultOverrides: FluidDosageFormData | undefined
}) {
  return {
    presetRateText: null,
    productType: ProductType.IVFLUIDS,
    patientWeight: defaultPatientWeight,
    patientWeightUnit: defaultPatientWeightUnit,
    isHypovolemic: false,
    isDehydrated: false,
    ongoingLosses: 0,
    IVSet: null,
    minutes: 0,
    hours: 0,
    days: 0,
    startAtDate: startTime,
    instructions: getProductDefaultInstructions(product), // Use description for custom products.
    isInstructionsImportant: false,
    physiologicReq: null,
    totalResults: null,
    dripRate: null,
    totalUnit: 'ml',
    dehydration: 0,
    hoursToAdmin: null,
    dehydrationResult: 0,
    isContinuous: true,
    ...defaultOverrides,
    isRepeating: RepeatScheduleValue.SINGLE,
  } as FluidDosageFormData
}
