import {
  ActionSheetOptions,
  useActionSheet,
} from '@expo/react-native-action-sheet'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { TimeSegment } from 'src/hocs/types/time'
import { invert, isNil } from 'lodash'
import { ViewStyle } from 'react-native'
import { useTimeResolution } from 'src/hocs/timeContext'
import { cache } from 'src/apollo/cache'
import { ZOOM_LEVEL_FIELD } from 'components/Sheet/graphql'
import { getSheet_getSheet } from 'types/getSheet'

type ActionSheetHook = (
  options: ActionSheetOptions,
  callback: (i?: number | null) => void,
) => void

type ActionSheetOnPress = (newTimeSegment: TimeSegment) => void

type TimeSegmentTranslationLookup = Record<TimeSegment, string>

type ButtonActionParams = {
  cancelName: string
  onPressLabelAction: ActionSheetOnPress
  showActionSheetHook: ActionSheetHook
  timeSegmentTranslationLookup: TimeSegmentTranslationLookup
  title: string
}

const containerStyle: ViewStyle = {
  maxHeight: '70%',
}

const buttonAction = ({
  cancelName,
  onPressLabelAction,
  showActionSheetHook,
  timeSegmentTranslationLookup,
  title,
}: ButtonActionParams) => {
  const getKeyByTranslationValue = invert(timeSegmentTranslationLookup)
  const options: string[] = [
    ...Object.values(timeSegmentTranslationLookup),
    cancelName,
  ]
  const cancelButtonIndex = options.length - 1

  showActionSheetHook(
    {
      cancelButtonIndex,
      containerStyle,
      options,
      title,
    },
    (buttonIndex?: number | null) => {
      if (isNil(buttonIndex) || buttonIndex === cancelButtonIndex) return

      const newTimeSegment = getKeyByTranslationValue[
        options[buttonIndex]
      ] as TimeSegment
      // nb: we assume the options (ask translation.json:sheets.time.resolution)
      // keys match TimeSegment enum types here. See src/hocs/types/time.ts

      return onPressLabelAction(newTimeSegment)
    },
  )
}

export const useZoomMenu = (sheet?: getSheet_getSheet | null) => {
  const { showActionSheetWithOptions } = useActionSheet()
  const { t } = useTranslation()
  const { toggleTimeSegment } = useTimeResolution()

  // persist the local sheet level zoom to the cache
  const onChangeZoom = useCallback(
    (timeSegment: TimeSegment) => {
      if (!sheet) return

      cache.writeFragment({
        id: cache.identify(sheet as any),
        data: { _zoom_level: timeSegment },
        fragment: ZOOM_LEVEL_FIELD,
      })
    },
    [sheet],
  )

  const onPressActionSheetButton = useCallback(
    (newTimeSegment: TimeSegment) => {
      toggleTimeSegment(newTimeSegment)
      onChangeZoom(newTimeSegment)
    },
    [onChangeZoom, toggleTimeSegment],
  )

  const actionSheetTimeSegmentLabels: TimeSegmentTranslationLookup = useMemo(
    () => t('sheets.time.resolution.options', { returnObjects: true }),
    [t],
  )

  const buttonActionCb = useCallback(
    () =>
      buttonAction({
        cancelName: t('general.cancel'),
        onPressLabelAction: onPressActionSheetButton,
        showActionSheetHook: showActionSheetWithOptions,
        timeSegmentTranslationLookup: actionSheetTimeSegmentLabels,
        title: t('sheets.time.resolution.actionSheetTitle'),
      }),
    [
      actionSheetTimeSegmentLabels,
      onPressActionSheetButton,
      t,
      showActionSheetWithOptions,
    ],
  )

  return {
    buttonActionCb,
  }
}
