import { toast } from 'components/common'
import { ORGANISATION_ID } from 'constants/Memory'
import { useCallback } from 'react'
import {
  OrganisationActionTypes,
  useOrganisation,
} from 'src/context/organisation'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import { SyncStorage } from 'src/utils/SyncStorage'
import { reloadApp } from 'src/utils/reloadApp'
import { environment } from 'src/config'

export const useTrainingSwitch = () => {
  const { organisation } = useOrganisationHook()
  const [_, organisationDispatch] = useOrganisation()

  const isTrainingOrganisation = !!organisation?.is_training

  const switchToTrainingOrganisation = useCallback(() => {
    if (!organisation?.training_organisation_id) {
      toast.error('Error: No training mode configured for your organisation')
      return
    }
    SyncStorage.setItem(ORGANISATION_ID, organisation?.training_organisation_id)
    organisationDispatch({
      organisationId: organisation?.training_organisation_id,
      type: OrganisationActionTypes.setOrganisationId,
    })
  }, [organisation, organisationDispatch])

  // Could be improved, but currently because the changed organisation isn't persisted, refresh takes the user back to the real organisation.
  const switchToRegularOrganisation = () => {
    if (environment.isWeb) window.history.pushState(null, '', '/')
    reloadApp()
  }

  const toggleTrainingMode = isTrainingOrganisation
    ? switchToRegularOrganisation
    : switchToTrainingOrganisation

  return { toggleTrainingMode, isTrainingOrganisation }
}
