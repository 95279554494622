import React from 'react'
import { StyleSheet } from 'react-native'
import { SwitchInput, TextArea } from 'components/common'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'src/hocs/breakpoint'

interface InstructionsProps {
  control: Control<any>
}

export const Instructions = ({ control }: InstructionsProps) => {
  const { t } = useTranslation()
  const { isExSmallScreen } = useBreakpoint()
  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextArea
            label={t('addTreatment:instructions')}
            onChangeText={onChange}
            value={value}
            visibleLines={6}
          />
        )}
        name="instructions"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <SwitchInput
            textStyle={isExSmallScreen ? styles.smallFormText : null}
            label={t('addTreatment:markInstructionsImportant')}
            value={value}
            onChangeValue={onChange}
          />
        )}
        name="isInstructionsImportant"
      />
    </>
  )
}

export const styles = StyleSheet.create({
  smallFormText: {
    fontSize: 14,
  },
})
