import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useGetAttendingVet } from 'components/Patient/useGetAttendingVet'
import { GET_SHEET_DETAIL } from 'components/Sheet/graphql'
import { returnDiscontinuedTask } from 'components/Treatment/GridTreatmentLeaf'
import { GET_DEPARTMENTS } from 'components/Whiteboard/graphql'
import { compact } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { useBreakpoint } from 'src/hocs/breakpoint'
import {
  getDepartments,
  getDepartmentsVariables,
} from 'src/types/getDepartments'
import {
  getSheetDetail as GetSheetDetail,
  getSheetDetailVariables as GetSheetDetailVariables,
} from 'types/getSheetDetail'
import { CubexTransactionStatus, Status } from 'types/globalTypes'

import { TaskActionBox } from './TaskActionBox'
import { TaskActionMenu } from './TaskActionMenu'
import {
  getShouldShowTaskTreatmentHeader,
  TaskDialogHeader,
} from './TaskHeader/TaskDialogHeader'
import { TaskTransition } from './TaskTransition'
import { Action, Task, TaskActionsProps } from './types'
import { getNearestTaskBeforePress } from './utils/getNearestTaskBeforePress'
import { getIsTaskDoneOrProgressOrSkipped } from './utils/taskTransitionUtils'
import {
  useGetCreatedLabelText,
  useGetLastEditLabelText,
  useGetStatusLabelText,
} from './utils/useGetStatusLabelText'
import { useIsTaskPastEditingTimeout } from 'src/hooks/useIsTaskPastEditingTimeout'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Colors, Typography } from 'src/design-system/theme'

export const ActivityLog: React.FC<{ task: Task | null }> = ({ task }) => {
  const { t } = useTranslation()
  const getLastEditLabelText = useGetLastEditLabelText()
  const getCreatedLabelText = useGetCreatedLabelText()
  const lastUpdateUser = task?.updated_by ?? null
  let user = useGetAttendingVet(lastUpdateUser) ?? ''
  if (!lastUpdateUser) {
    user = t('task:taskTransition.system')
  }
  return (
    <View>
      <Text style={styles.lastEditLabelText}>
        {getLastEditLabelText(task, t) || getCreatedLabelText(task, t)}
      </Text>
      <Text style={styles.lastEditLabelText}>
        {t('task:taskAddEdit.lastEditBy', { user })}
      </Text>
    </View>
  )
}

export const ActivityInfo: React.FC<{ task: Task | null; sheetId: string }> = ({
  task,
  sheetId,
}) => {
  const [{ organisationId }] = useOrganisation()
  let attendingDoc = task?.attending_vet
  let attendingDepartment = task?.attending_department
  const taskDisplayCurrentValue =
    task?.status === Status.MISSED || task?.status === Status.DUE
  const hasAttendingValues = !!attendingDoc && !!attendingDepartment
  const skip = hasAttendingValues && !taskDisplayCurrentValue

  const { data } = useQuery<GetSheetDetail, GetSheetDetailVariables>(
    GET_SHEET_DETAIL,
    {
      skip,
      variables: {
        organisationId,
        id: sheetId,
      },
    },
  )

  const { data: departmentsData } = useQuery<
    getDepartments,
    getDepartmentsVariables
  >(GET_DEPARTMENTS, {
    variables: {
      organisationId,
    },
  })

  const sheet = data?.getSheet
  if (!skip) {
    attendingDoc = sheet?.attending_vet
    attendingDepartment = sheet?.attending_department
  }

  const vet = useGetAttendingVet(attendingDoc)

  const department = useMemo(
    () =>
      departmentsData?.getSites?.items?.find(
        obj => obj.id === attendingDepartment,
      )?.name,
    [attendingDepartment, departmentsData?.getSites?.items],
  )

  const displayString = useMemo(
    () => compact([vet, department]).join(' | '),
    [vet, department],
  )

  return (
    <View style={styles.activityLogHolder}>
      <Text style={styles.logLabelText} numberOfLines={1}>
        {displayString}
      </Text>
    </View>
  )
}

export const TaskActions: React.FC<TaskActionsProps> = ({
  isFinalized,
  task,
  timeInGrid = new Date(),
  toggleDialog,
  treatment,
  patientId,
  sheetId,
}) => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isTaskPastEditingTimeout = useIsTaskPastEditingTimeout(
    task?.user_updated_at ?? '',
    task?.status,
  )
  const { t } = useTranslation()
  const getStatusLabelText = useGetStatusLabelText()

  const [action, setAction] = useState<Action | null>(null)

  const isNativeStyle = isExSmallScreen || isSmallScreen
  const allTasks = useMemo(
    () => treatment?.tasks?.items ?? [],
    [treatment?.tasks?.items],
  )

  const isDiscontinued = !!treatment?.discontinued_at

  const taskToEdit = useMemo(
    () => task ?? getNearestTaskBeforePress(allTasks, timeInGrid),
    [allTasks, task, timeInGrid],
  )

  const editTask = (newAction: Action) => {
    setAction(newAction)
  }

  const hasTaskTransitioned = getIsTaskDoneOrProgressOrSkipped(
    taskToEdit?.status,
  )

  const isReadOnly = isFinalized || !!treatment?.discontinued_at
  useEffect(() => {
    if (action) {
      return
    }
    if (hasTaskTransitioned) {
      return setAction(Action.EDIT)
    }
    setAction(Action.COMPLETE)
  }, [action, hasTaskTransitioned])

  const shouldShowDialogHeader = getShouldShowTaskTreatmentHeader(treatment)

  const discontinuedTask = useMemo(() => {
    return returnDiscontinuedTask(allTasks, isDiscontinued, treatment!)
  }, [allTasks, isDiscontinued, treatment])

  const isCurTaskDiscontinued = discontinuedTask?.id === taskToEdit?.id

  return (
    <KeyboardAwareScrollView
      style={styles.container}
      accessibilityLabel={t('task:taskTransition:a11y')}
      extraHeight={200}
    >
      <TaskDialogHeader
        showHeader={shouldShowDialogHeader}
        treatment={treatment}
        patientId={patientId}
        task={task}
        headStyleForCubex={
          task?.cubex_transaction_status === CubexTransactionStatus.SENT ||
          task?.cubex_transaction_status === CubexTransactionStatus.WAITING
            ? styles.cubexTaskHeader
            : null
        }
      />
      {taskToEdit ? (
        <>
          <View style={styles.updateTaskContainer}>
            {!isReadOnly && (
              <>
                {taskToEdit ? (
                  <TaskActionMenu
                    action={action}
                    onPressAction={editTask}
                    taskToEdit={taskToEdit}
                  />
                ) : null}
                {!isNativeStyle && <View style={styles.divider} />}
              </>
            )}
            <View style={styles.updateTaskTopHalf}>
              <TaskActionBox
                taskToEdit={taskToEdit}
                isDiscontinued={isCurTaskDiscontinued}
              />
              <View style={styles.activityInfoHolder}>
                <Text
                  style={[
                    styles.subLabelText,
                    isNativeStyle && styles.smallSubLabelText,
                  ]}
                >
                  {getStatusLabelText(taskToEdit, t)}
                </Text>
              </View>
            </View>
            {sheetId ? (
              <ActivityInfo task={taskToEdit} sheetId={sheetId} />
            ) : null}
          </View>
          {isReadOnly || isTaskPastEditingTimeout ? (
            <TaskTransition
              action={Action.VIEW}
              task={taskToEdit}
              treatment={treatment}
              isTaskPastEditingTimeout={isTaskPastEditingTimeout}
              toggleDialog={toggleDialog}
              isFinalized={isFinalized}
              patientId={patientId}
              activityLog={<ActivityLog task={taskToEdit} />}
              sheetId={sheetId}
            />
          ) : (
            <>
              {action && taskToEdit ? (
                <TaskTransition
                  action={action}
                  task={taskToEdit}
                  treatment={treatment}
                  toggleDialog={toggleDialog}
                  isFinalized={isFinalized}
                  patientId={patientId}
                  sheetId={sheetId}
                  activityLog={<ActivityLog task={taskToEdit} />}
                />
              ) : null}
            </>
          )}
        </>
      ) : null}
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: '100%',
  },
  divider: {
    width: '50%',
    borderTopWidth: 1,
    borderTopColor: Colors.Borders.primary,
    marginHorizontal: 'auto',
    paddingVertical: 12,
  },
  subLabelText: {
    color: Colors.Contents.primary,
    fontWeight: '500',
    fontSize: 16,
  },
  smallSubLabelText: {
    fontSize: 13,
  },
  logLabelText: {
    color: Colors.Contents.primary,
    fontSize: 13,
  },
  lastEditLabelText: {
    ...Typography.Label.M,
    color: Colors.Contents.tertiary,
  },
  updateTaskContainer: {
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  updateTaskTopHalf: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    width: '100%',
  },
  activityInfoHolder: {
    alignSelf: 'center',
  },
  activityLogHolder: {
    alignItems: 'center',
    paddingTop: 10,
    width: '100%',
  },
  cubexTaskHeader: {
    marginTop: 0,
  },
})
