import React, { useEffect, useMemo, useState } from 'react'
import { Dimensions, Platform, StyleSheet, Text, View } from 'react-native'
import { Image, LoadingOverlay, toast } from 'components/common'
import { ImagePickerButton } from 'components/common/ImagePickerButton'
import { Dialog } from 'components/common/Dialog/Dialog'
import { v4 as uuid } from 'uuid'
import { upload } from 'src/utils/upload'
import { keyToUrl } from 'src/utils/keyToUrl'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { settings } from 'src/vetradar-exports'
import { FontFamilies } from 'src/design-system/theme/typography/base'
import { Primitive } from 'src/design-system/theme/colors/base'
import { useTranslation } from 'react-i18next'

const S3_PREFIX = settings.s3PrefixForTtl
const isIOS = Platform.OS === 'ios'

type Props = {
  visible: boolean
  toggleDialog: () => true | void
  objectKey: string
  objectKeyPrefix: string
  onChange: (objectKey: string) => void
  newObjectKeyRef: React.MutableRefObject<string | null>
  noImageText?: string
}
export const ImageUploaderDialog: React.FC<Props> = ({
  visible,
  toggleDialog,
  objectKey,
  objectKeyPrefix,
  onChange,
  newObjectKeyRef,
  noImageText,
}) => {
  const [fullImage, setFullImage] = useState(() => keyToUrl(objectKey))
  const [uploading, setUpLoading] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isPwa = isExSmallScreen || isSmallScreen

  const { t } = useTranslation()

  // setFullImage(keyToUrl(objectKey))

  useEffect(() => {
    if (visible) setFullImage(keyToUrl(objectKey))
  }, [objectKey, visible])

  const onSelected = (dataURI: string) => {
    setFullImage(dataURI)
    setUpLoading(true)
    setButtonDisabled(true)
    const key = S3_PREFIX + [objectKeyPrefix, uuid()].join('/')
    upload({ dataURI, key })
      .then(() => {
        newObjectKeyRef.current = key
      })
      .catch(err => {
        toast.error('Upload failed', null, err)
        setFullImage(keyToUrl(objectKey))
      })
      .finally(() => {
        setButtonDisabled(false)
        return setUpLoading(false)
      })
  }

  const dialogOnSave = () => {
    if (uploading) {
      return
    }
    if (newObjectKeyRef.current) {
      onChange(newObjectKeyRef.current.substring(S3_PREFIX.length))
    }
    toggleDialog()
  }

  const insets = useSafeAreaInsets()
  const avatarStyle = useMemo(() => {
    let length = 0
    if (isIOS) {
      const { width, height } = Dimensions.get('window')
      // dialog content height: 0.6, header: 56, footer: 85, safeArea: insets.bottom
      length = Math.min(width, height * 0.6 - 56 - 85 - insets.bottom)
    } else if (isPwa) {
      length = 310
    } else {
      length = 450
    }
    return { width: length, height: length }
  }, [insets.bottom, isPwa])

  const buttonTitleText = useMemo(
    () =>
      uploading
        ? t('common.ImageUploaderButton.uploadingButtonText')
        : t('common.ImageUploaderButton.uploadPhotoButtonText'),
    [uploading, t],
  )

  return (
    <Dialog
      showDone={!uploading}
      onCancel={toggleDialog}
      onSave={dialogOnSave}
      toggleDialog={toggleDialog}
      visible={visible}
      contentStyle={styles.dialogContentStyle}
    >
      <View style={styles.content} testID="Photo Upload dialog">
        {fullImage ? (
          <LoadingOverlay isLoading={uploading}>
            <Image
              style={avatarStyle}
              source={{ uri: fullImage }}
              testID="PatientFullAvatar"
            />
          </LoadingOverlay>
        ) : (
          <View
            style={[styles.noImageContainer, avatarStyle]}
            testID="No Image Uploaded"
          >
            <Text style={styles.noImageText}>{noImageText}</Text>
          </View>
        )}

        <View style={styles.footer}>
          <ImagePickerButton
            testID="Change Photo Button"
            title={buttonTitleText}
            onSelected={onSelected}
            disabled={buttonDisabled}
          />
        </View>
      </View>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  dialogContentStyle: isIOS
    ? {
        justifyContent: 'center',
        alignItems: 'center',
        bottom: '20%',
      }
    : {
        bottom: 'auto',
        right: 'auto',
        minWidth: 'auto',
        maxWidth: 'auto',
        minHeight: 'auto',
      },
  content: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    paddingHorizontal: 20,
    paddingVertical: 30,
  },
  footer: {
    justifyContent: 'center',
    paddingTop: 20,
  },
  noImageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: Primitive.grey300,
    borderRadius: 10,
  },
  noImageText: {
    fontFamily: FontFamilies.base,
    fontSize: 20,
    textAlign: 'center',
  },
})
