import { CheckButton } from 'components/shared'
import { ResetButton } from 'components/Task/common/ResetButton'
import { TouchableOpacity } from 'react-native'
import { withPermission } from 'src/hocs'
import { NumericInput } from '../NumericInput'
import { SwitchInput } from '../SwitchInput'
import { TextArea } from '../TextArea'
import { TextLink } from '../TextLink'

export const PermissionResetButton = withPermission(ResetButton)
export const PermissionTextArea = withPermission(TextArea)
export const PermissionNumericInput = withPermission(NumericInput)
export const PermissionTextLink = withPermission(TextLink)
export const PermissionCheckButton = withPermission(CheckButton)
export const PermissionTouchableOpacity = withPermission(TouchableOpacity)
export const PermissionSwitchInput = withPermission(SwitchInput)
