import React from 'react'
import { TouchableOpacity, StyleSheet } from 'react-native'
import { Button, ButtonProps } from 'components/common/Button'
import { SvgArrow } from 'components/Icons'
import { Colors, Typography } from 'src/design-system/theme'

interface BackButtonProps {
  onPress: () => void
}

export const IconBackButton: React.FC<BackButtonProps> = ({ onPress }) => {
  return (
    <TouchableOpacity style={styles.backButtonContainer} onPress={onPress}>
      <SvgArrow
        orient="left"
        color={Colors.Contents.primary}
        width={15}
        height={15}
      />
    </TouchableOpacity>
  )
}

export const AuthPrimaryButton: React.FC<ButtonProps> = ({
  style,
  containerStyle,
  textStyle,
  ...rest
}) => {
  return (
    <Button
      style={[styles.button, style]}
      color={Colors.Backgrounds.accentPrimary}
      disabledBackgroundColor={Colors.Backgrounds.accentSecondary}
      containerStyle={[styles.buttonContainer, containerStyle]}
      textStyle={[styles.buttonText, textStyle]}
      {...rest}
    />
  )
}

export const AuthSecondaryButton: React.FC<ButtonProps> = ({
  style,
  containerStyle,
  textStyle,
  disabled,
  ...rest
}) => {
  return (
    <Button
      style={[styles.button, styles.secondaryButton, style]}
      containerStyle={[styles.buttonContainer, containerStyle]}
      disabled={disabled}
      textStyle={[
        styles.buttonText,
        {
          color: disabled
            ? Colors.Interactive.disabledPrimary
            : Colors.Contents.primary,
        },
        textStyle,
      ]}
      {...rest}
    />
  )
}

const styles = StyleSheet.create({
  button: {
    width: '100%',
    borderRadius: 4,
  },
  buttonContainer: {
    width: '100%',
    paddingHorizontal: 0,
  },
  buttonText: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
  },
  secondaryButton: {
    backgroundColor: Colors.Backgrounds.UI,
    borderWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  backButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
  },
})
