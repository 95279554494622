import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, Text } from 'react-native'
import { Routes } from 'constants/Routes'
import { PatientHeaderStyles as styles } from './PatientHeaderStyles'

export const FullProfileButton: React.FC<{ patientId: string }> = React.memo(
  ({ patientId }) => {
    const { t } = useTranslation()
    const { navigate } = useNavigation()

    return (
      <TouchableOpacity
        accessibilityLabel={t('patient:view.viewProfile')}
        onPress={() => navigate(Routes.EditPatient, { patientId })}
        style={styles.fullProfileButton}
      >
        <Text style={styles.fullProfileButtonText}>
          {t('patient:view.viewProfile')}
        </Text>
      </TouchableOpacity>
    )
  },
)

FullProfileButton.displayName = 'FullProfileButton'
