import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native'
import { Colors } from 'src/design-system/theme'

interface RowContainerProps
  extends React.PropsWithChildren<TouchableOpacityProps> {}

export const Container: React.FC<RowContainerProps> = ({
  children,
  style,
  ...rest
}) => (
  <TouchableOpacity
    style={[styles.container, style]}
    delayLongPress={1500}
    {...rest}
  >
    {children}
  </TouchableOpacity>
)

const styles = StyleSheet.create({
  container: {
    minHeight: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
    backgroundColor: Colors.Backgrounds.UI,
    borderBottomColor: Colors.Borders.primary,
    borderBottomWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
})
