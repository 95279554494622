import { useMemo } from 'react'
import { getTreatmentIsValueRequired } from 'components/Task/utils/getTreatmentInfo'
import { BulkTasksKeyedByTaskID } from '../BulkTaskActions'
import { BulkTreatmentsKeyedById } from '../BulkTaskCreate'
import { TabName } from '../common'

type Props = {
  hasTaskSelected: boolean
  hasError: boolean
  bulkTasks: BulkTasksKeyedByTaskID | BulkTreatmentsKeyedById
  currentTab?: TabName
  isBulkTaskActions?: boolean
}

export const useShouldDisableComplete = ({
  hasTaskSelected,
  hasError,
  bulkTasks,
  isBulkTaskActions = false,
  currentTab,
}: Props) => {
  return useMemo(() => {
    if (!hasTaskSelected || hasError) return true
    if (isBulkTaskActions && !(currentTab === TabName.COMPLETE)) return false
    const tasksArr = Object.values(bulkTasks)

    return tasksArr.some(task => {
      const isValueRequired = getTreatmentIsValueRequired(task.treatment)
      const isValueValid = isValueRequired
        ? !!task?.value || !!task?.nextTaskToComplete?.value
        : true

      const isNotesRequired = task.treatment.product?.is_notes_required ?? false
      const isNotesValid = isNotesRequired
        ? !!task?.notes || !!task?.nextTaskToComplete?.notes
        : true
      const completeChecked = isBulkTaskActions
        ? task.completeChecked
        : task.checked

      return (!isValueValid || !isNotesValid) && completeChecked
    })
  }, [bulkTasks, hasTaskSelected, hasError, currentTab, isBulkTaskActions])
}
