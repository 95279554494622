import { parseISO } from 'date-fns'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskItem } from 'src/types/getSheet'

export const getTaskStartAt = (
  task: Pick<TaskItem, 'start_at' | 'given_start_at'>,
) => {
  return parseISO(
    (!!task.given_start_at ? task.given_start_at : task.start_at) ?? '',
  )
}

export const getTaskStopAt = (
  task: Pick<TaskItem, 'stop_at' | 'given_stop_at'>,
) => {
  return parseISO(
    (!!task.given_stop_at ? task.given_stop_at : task.stop_at) ?? '',
  )
}

export const getTaskStartAtString = (
  task: Pick<TaskItem, 'start_at' | 'given_start_at'>,
) => task.given_start_at ?? task.start_at ?? ''

export const getTaskStopAtString = (
  task: Pick<TaskItem, 'stop_at' | 'given_stop_at'>,
) => task.given_stop_at ?? task.stop_at ?? ''
