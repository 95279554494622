/* Search the list in the field by the searchText and return a matched list. */
import { get } from 'lodash'
export const searchInList = <T extends { [key: string]: any }>(
  list: T[],
  field: string,
  searchText: string,
): T[] =>
  list.filter(item =>
    get(item, field).toUpperCase().includes(searchText.toUpperCase()),
  )
