import React from 'react'
import { Banner } from 'components/common/Banner'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text } from 'react-native'
import { Colors } from 'src/design-system/theme'
import { useBreakpoint } from 'src/hocs/breakpoint'

export enum CUBEX_BANNER_STATUS {
  WILL_SYNC = 'WILL_SYNC',
  ARRIVED = 'ARRIVED',
  NOT_SHOW = 'NOT_SHOW',
}

type SubBannerProps = {
  name: string
}

const CubexBannerSubText: React.FC<SubBannerProps> = ({ name }) => (
  <Text ellipsizeMode="tail" numberOfLines={1} style={styles.subTextStyle}>
    {`(${name})`}
  </Text>
)

export const CubexTopBanner: React.FC<{
  cubexBannerStatus: CUBEX_BANNER_STATUS
  cubexMachineName?: string
}> = ({ cubexBannerStatus, cubexMachineName }) => {
  const { t } = useTranslation()
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isSmallAndExSmallScreen = isExSmallScreen || isSmallScreen

  if (cubexBannerStatus === CUBEX_BANNER_STATUS.NOT_SHOW) return null

  const cubexMachineNameStyle = cubexMachineName
    ? styles.cubexContainerWithCubexNameStyle
    : null
  const cubexMachineNameText = cubexMachineName ? (
    <CubexBannerSubText name={cubexMachineName} />
  ) : null

  return cubexBannerStatus === CUBEX_BANNER_STATUS.ARRIVED ? (
    <Banner
      containerStyle={[
        styles.arrivedAtCubexContainerStyle,
        cubexMachineNameStyle,
      ]}
      textStyle={styles.arrivedAtCubexTextStyle}
      text={
        isSmallAndExSmallScreen
          ? t('treatment:cubex:arrivedAtCubexForShort')
          : t('treatment:cubex:arrivedAtCubex')
      }
      subText={cubexMachineNameText}
      accessibilityLabel={'Cubex banner Arrived'}
    />
  ) : (
    <Banner
      containerStyle={[
        styles.willLSyncToCubexContainerStyle,
        cubexMachineNameStyle,
      ]}
      textStyle={styles.willLSyncToCubexTextStyle}
      text={t('treatment:cubex:willSyncToCubex')}
      subText={cubexMachineNameText}
      accessibilityLabel={'Cubex banner Will Sync'}
    />
  )
}

const styles = StyleSheet.create({
  willLSyncToCubexContainerStyle: {
    backgroundColor: Colors.Backgrounds.overlay,
  },
  willLSyncToCubexTextStyle: {
    color: Colors.Contents.primary,
    fontSize: 16,
  },
  arrivedAtCubexContainerStyle: {
    backgroundColor: Colors.Backgrounds.positive,
  },
  cubexContainerWithCubexNameStyle: {
    height: 48,
  },
  arrivedAtCubexTextStyle: {
    color: Colors.Contents.positiveOnColor,
    fontSize: 16,
  },
  subTextStyle: {
    paddingTop: 2,
    height: 16,
    color: Colors.Contents.secondary,
    fontSize: 12,
  },
})
