import { Theme } from '@aws-amplify/ui-react'
import { Colors } from 'constants/Colors'
import { commonTheme } from './common'

export const theme: Theme = {
  name: 'VR Web Theme',
  tokens: {
    ...commonTheme.tokens,
    components: {
      alert: {
        backgroundColor: Colors.errorBackground,
      },
      authenticator: {
        form: {
          padding: '12px 20px 16px 24px',
        },
        router: {
          borderColor: Colors.setupBackground,
        },
      },
      button: {
        _focus: {
          borderColor: Colors.tabIconSelected,
        },
        primary: {
          backgroundColor: Colors.primaryButtonBackground,
          _focus: {
            borderColor: Colors.tabIconSelected,
          },
          _hover: {
            backgroundColor: Colors.vetRadarBlue,
          },
        },
      },
      fieldcontrol: {
        _focus: {
          borderColor: Colors.tabIconDefault,
        },
      },
      radio: {
        button: {
          borderWidth: '2px',
          width: '20px',
          height: '20px',
          padding: '2px',
        },
      },
    },
    fontSizes: {
      small: '12px',
      medium: '16px',
      large: '18px',
      xl: '24px',
    },
    fonts: {
      default: {
        variable: { value: 'nunitosans-regular, Roboto, sans-serif' },
        static: { value: 'nunitosans-regular, Roboto, sans-serif' },
      },
    },
  },
}
