import React, { forwardRef, useEffect, useState, useMemo } from 'react'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import { SvgClock } from 'components/Icons'
import { TouchableOpacity, StyleSheet, View } from 'react-native'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
  formatDateString,
  isValidRegexDate,
  processDateValue,
} from './TimePickerUtils'

type Props = {
  value: Date
  is24HOUR: boolean
  timeFormat: string
  defaultInputValue: string
  disabled?: boolean
  useUTC?: boolean
  autofocus?: boolean
  onChange: (data: MaterialUiPickersDate) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  setShowOptions: (status: boolean) => void
  isResetWidth?: boolean
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& input': {
      fontFamily: Fonts.regular,
      textTransform: 'upperCase',
    },
    '& button:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
})

const DEFAULT_TIMEPICKER_MASK = '__:__ _M'

export const CustomKeyboardTimePicker = forwardRef(
  (
    {
      value,
      defaultInputValue,
      is24HOUR,
      timeFormat,
      useUTC = false,
      disabled = false,
      autofocus = false,
      setShowOptions,
      onChange,
      onKeyDown,
      onFocus,
      isResetWidth = false,
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const keyboardTimePickerClass = useStyles()
    const [inputValue, setInputValue] = useState<string>(defaultInputValue)

    useEffect(() => {
      setInputValue(defaultInputValue)
    }, [defaultInputValue, value])

    useEffect(() => {
      const onKeyBoardBlur = () => {
        const timeList = isValidRegexDate(inputValue, is24HOUR)
        if (timeList) {
          onChange(processDateValue(value, timeList, is24HOUR, useUTC))
        }
      }

      onKeyBoardBlur()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])

    const svgClock = useMemo(
      () =>
        !isResetWidth ? (
          <View style={styles.iconContainer}>
            <SvgClock />
          </View>
        ) : null,
      [isResetWidth],
    )

    const onInputChange = (
      _date: MaterialUiPickersDate,
      value: string | null | undefined,
    ) => {
      setInputValue(formatDateString(value))
    }

    return (
      <TouchableOpacity
        onPress={() => disabled || setShowOptions(true)}
        style={styles.touchContainer}
      >
        <KeyboardTimePicker
          className={keyboardTimePickerClass.root}
          value={value}
          inputValue={inputValue}
          open={false}
          mask={is24HOUR ? undefined : DEFAULT_TIMEPICKER_MASK}
          format={timeFormat}
          onFocus={onFocus}
          invalidDateMessage={''}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          autoFocus={autofocus}
          disabled={disabled}
          InputProps={{
            'aria-label': value.toTimeString(),
            disableUnderline: true,
            ref,
          }}
          keyboardIcon={svgClock}
        />
      </TouchableOpacity>
    )
  },
)

const styles = StyleSheet.create({
  touchContainer: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    borderLeftColor: Colors.white,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    paddingLeft: 16,
  },
  iconContainer: {
    marginRight: 8,
  },
})
