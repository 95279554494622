import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color: string
}

export const SvgTask: React.FunctionComponent<Props> = ({ color }) => (
  <Svg width={17} height={18}>
    <G fill={color} fillRule="nonzero">
      <Path d="M6.263 16.149a80.724 80.724 0 0 1-3.317-.164c-.17 0-.292-.14-.317-.304-.268-4.61-.268-9.245 0-13.855 0-.164.147-.281.317-.305 3.927-.28 7.878-.28 11.805 0 .171.024.293.14.317.305.147 2.34.22 4.704.195 7.044 0 0 0 .187.122.351.366.351.561.515.927.866.342.304.293-.187.293-.187a91.26 91.26 0 0 0-.317-8.94c-.025-.305-.293-.586-.586-.609A88.623 88.623 0 0 0 1.824.374c-.292.024-.536.305-.56.609a113.94 113.94 0 0 0-.025 15.54c.024.305.293.586.585.609 2.22.234 3.317.304 5.537.351 0 0 .634.023.268-.351-.317-.304-.463-.468-.78-.772.024 0-.171-.188-.586-.211z" />
      <Path d="M6.824 14.862a.385.385 0 0 1 0-.539c.39-.374.561-.561.952-.936.146-.14.39-.14.536 0 .634.609.951.913 1.561 1.522.147.14.39.14.537 0 0 0 3.61-3.722 4.39-4.237.17-.117.415-.14.561-.023.39.351.585.515.951.866.147.14.147.351-.024.515a217.729 217.729 0 0 0-5.317 5.196c-.464.374-1.317.397-1.708.023-.95-.936-1.439-1.428-2.439-2.387zM13.678 4.283c0 .164-.122.28-.293.28-3.073-.093-6.17-.093-9.244 0-.17 0-.292-.116-.292-.28 0-.234 0-.351.024-.562 0-.164.147-.28.317-.304 3.049-.117 6.122-.117 9.171 0 .17 0 .293.14.317.304v.562zM13.727 6.974c0 .164-.122.281-.293.281-3.122-.023-6.22-.023-9.341 0-.171 0-.293-.117-.293-.28v-.562c0-.164.146-.281.293-.281 3.097-.07 6.195-.07 9.317 0 .17 0 .292.14.292.28.025.235.025.352.025.562zM13.751 9.666c0 .164-.146.28-.292.28-3.122.024-6.22.024-9.342 0a.29.29 0 0 1-.293-.28v-.562c0-.164.147-.28.293-.28h9.342c.17 0 .292.116.292.28v.562z" />
    </G>
  </Svg>
)
