import { gql } from '@apollo/client'

export const typeDefs = [
  gql`
    extend type Sheet {
      _zoom_level: String
    }
  `,
  gql`
    extend type Task {
      _pending: Boolean
    }
  `,
  gql`
    extend type Treatment {
      _hidden: Boolean
      _optimistic: Boolean
    }
  `,
  gql`
    # Using local field policy (cache.ts->PatientWithTasks )
    extend type PatientWithTasks {
      resultCursor: String
    }
  `,
  gql`
    # Using local field policy (cache.ts->PatientWithTasksV2 )
    extend type ActivePatientListV2 {
      resultCursor: String
    }
  `,
  gql`
    # Using local field policy (cache.ts->WhiteboardPatientList )
    extend type WhiteboardPatientList {
      resultCursor: String
    }
  `,
  gql`
    # Using local field policy (cache.ts->WhiteboardWorkflow )
    extend type WhiteboardWorkflow {
      resultCursor: String
    }
  `,
]
