import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import React, { useEffect, useState } from 'react'
import { Button, EditableText, FormLabel } from 'components/common'
import {
  addMinutes,
  differenceInMinutes,
  startOfDay,
  startOfHour,
} from 'date-fns'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { useMouseHover } from 'src/hooks/useMouseHover.web'
import {
  FrequencyType,
  TreatmentFrequencyCreateInput,
  TreatmentFrequencyUpdateInput,
} from 'types/globalTypes'
import {
  getDynamicName,
  getMinFromMidnightArray,
  getTimeOfDayArray,
} from 'components/Settings/TreatmentFrequencies/utils/treatmentFrequencies.utils'
import { useOrganisation } from 'src/context/organisation'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { TreatmentFrequencyFormRow } from 'components/Settings/TreatmentFrequencies/TreatmentFrequencyFormRow'

export const NEW_DEFAULT_FREQUENCY = {
  organisation_id: '',
  type: FrequencyType.MINS_FROM_MIDNIGHT,
  active: true,
  name: 'New frequency',
  frequencies: [
    differenceInMinutes(startOfHour(new Date()), startOfDay(new Date())),
  ],
}

type Props = {
  selectedTreatmentFrequency: TreatmentFrequencyUpdateInput | null
  save: (
    frequency: TreatmentFrequencyUpdateInput | TreatmentFrequencyCreateInput,
  ) => void
}

export const TreatmentFrequencyForm: React.FC<Props> = ({
  selectedTreatmentFrequency = null,
  save,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { hoverRef, isHovered } = useMouseHover()
  const { adminTimeFormat } = useAdminTimeFormat()

  const [treatmentFrequency, setTreatmentFrequency] = useState<
    TreatmentFrequencyUpdateInput | TreatmentFrequencyCreateInput
  >(selectedTreatmentFrequency || NEW_DEFAULT_FREQUENCY)

  const defaultValues = treatmentFrequency.frequencies.map(frequency => ({
    value: addMinutes(startOfDay(new Date()), frequency),
  }))

  const validationSchema = yupResolver(
    Yup.object().shape({
      name: Yup.string()
        .required(
          t(
            'settings:treatmentFrequencies.drawer.validationErrors.name.required',
          ),
        )
        .max(
          50,
          t(
            'settings:treatmentFrequencies.drawer.validationErrors.name.length',
          ),
        ),
      frequencies: Yup.array()
        .test(
          'Unique',
          t('settings:treatmentFrequencies.drawer.validationErrors.frequency'),
          frequencies => {
            const values = frequencies.map(
              (x: { value: { toString: () => string } }) => x.value.toString(),
            )
            return new Set(values).size === values.length
          },
        )
        .required(
          t('settings:treatmentFrequencies.drawer.validationErrors.frequency'),
        ),
    }),
  )

  const {
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: treatmentFrequency.name,
      frequencies: defaultValues,
    },
    mode: 'all',
    resolver: validationSchema,
  })

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'frequencies',
  })

  const updateNameDynamically = () => {
    const frequencies = getValues('frequencies').map(
      frequency => frequency.value,
    )
    const newName = getDynamicName(frequencies, adminTimeFormat)
    const updatedFrequencies = getMinFromMidnightArray(frequencies)

    setTreatmentFrequency(v => {
      const isCreate =
        v.name === NEW_DEFAULT_FREQUENCY.name && v.frequencies.length > 0
      const isEdit =
        v.name ===
        getDynamicName(getTimeOfDayArray(v.frequencies), adminTimeFormat)

      if (isCreate || isEdit) {
        return {
          ...treatmentFrequency,
          name: newName,
          frequencies: updatedFrequencies,
        }
      }
      return { ...v, frequencies: updatedFrequencies }
    })
  }

  useEffect(() => {
    setValue('name', treatmentFrequency.name)
  }, [setValue, treatmentFrequency.name])

  watch(_ => {
    updateNameDynamically()
  })

  const handleSave = () => {
    const frequencies = getMinFromMidnightArray(
      getValues('frequencies').map(v => v.value),
    )
    const updatedFrequency = {
      ...treatmentFrequency,
      name: getValues('name'),
      organisation_id: organisationId,
      frequencies,
    }
    save(updatedFrequency)
  }

  const onAppend = () => {
    append({ value: startOfHour(new Date()) })
    updateNameDynamically()
  }

  const onRemove = (row: number | number[] | undefined) => {
    remove(row)
    updateNameDynamically()
  }

  const isSaveDisabled = Object.keys(errors).length > 0

  return (
    <>
      <Controller
        control={control}
        name={'name'}
        defaultValue={treatmentFrequency.name}
        render={({ field: { value, onChange } }) => {
          return <EditableText onChange={onChange} value={value} />
        }}
      />
      <KeyboardAwareScrollView>
        <FormLabel
          text={t('settings:treatmentFrequencies.drawer.form.label')}
        />
        <TreatmentFrequencyFormRow
          control={control}
          trigger={trigger}
          fields={fields}
          timeFormat={adminTimeFormat}
          remove={onRemove}
        />
        <View style={styles.addButtonContainer}>
          <Pressable
            ref={hoverRef}
            style={styles.addButton}
            onPress={onAppend}
            accessibilityLabel={t(
              'settings:treatmentFrequencies.drawer.form.addButton',
            )}
          >
            <Text
              style={[
                styles.addButtonTextStyle,
                isHovered && styles.addButtonHover,
              ]}
            >
              {t('settings:treatmentFrequencies.drawer.form.addButton')}
            </Text>
          </Pressable>
        </View>
      </KeyboardAwareScrollView>
      <View style={styles.saveButtonContainer}>
        {isSaveDisabled ? (
          <Text style={styles.errorMessage}>
            {errors.name?.message ?? errors.frequencies?.message}
          </Text>
        ) : null}
        <Button
          textStyle={styles.saveButton}
          onPress={handleSave}
          title={t('settings:treatmentFrequencies.drawer.form.saveButton')}
          a11yLabel={t('settings:treatmentFrequencies.drawer.form.saveButton')}
          disabled={isSaveDisabled}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  addButtonContainer: {
    width: '100%',
    backgroundColor: 'white',
    height: 60,
  },
  addButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  addButtonHover: {
    opacity: 0.8,
  },
  addButtonTextStyle: {
    color: Colors.blue,
    fontFamily: Fonts.bold,
  },
  saveButtonContainer: {
    paddingVertical: 50,
  },
  saveButton: {
    fontSize: 18,
    fontFamily: Fonts.bold,
  },
  errorMessage: {
    paddingVertical: 22,
    paddingHorizontal: 20,
    alignSelf: 'center',
    textAlign: 'center',
    color: Colors.error,
    fontFamily: Fonts.regular,
    fontSize: 13,
    lineHeight: 19,
  },
})
