import { useState, useEffect } from 'react'
import { AppState, AppStateStatus } from 'react-native'

export const useAppState = () => {
  const [appState, setAppState] = useState<AppStateStatus>('unknown')

  useEffect(() => {
    const eventSubscription = AppState.addEventListener(
      'change',
      nextAppState => {
        setAppState(nextAppState)
      },
    )

    return () => eventSubscription.remove()
  }, [])

  const isActive = !appState.match(/inactive|background/)

  return {
    appState,
    isActive,
  }
}
