import {
  getSheet_getSheet_treatments_items_treatments_items_medicine_dosage_info as DosageInfo,
  getSheet_getSheet_treatments_items_treatments_items_product as TreatmentProduct,
} from 'src/types/getSheet'

import { ProductType } from 'src/types/globalTypes'
import { Inputs, Task } from '../types'
import { getProductIsHealthStatusWeight } from './getProductInfo'

type GetTaskUnitInput = {
  task?: Inputs | Task
  dosageInfo?: DosageInfo | null
  patientWeightUnit?: string | null
  product?: TreatmentProduct | null
}

/* Return the task unit in the format expected by API:
   1. Patient weight unit if product is healthStatus -> weight
   2. Unit/Metric if medication and has dosage
   3. Default Product Unit if general
   4. Task unit if not empty */
export const getTaskUnit = ({
  patientWeightUnit = 'kg',
  task,
  dosageInfo,
  product,
}: GetTaskUnitInput) => {
  const productType = product?.type ?? ProductType.STANDARD

  let unit: string | null = null
  // TODO: refactor to check task patient weight unit
  if (patientWeightUnit && getProductIsHealthStatusWeight(product)) {
    unit = patientWeightUnit
  } else if (
    productType === ProductType.MEDICATION &&
    dosageInfo &&
    dosageInfo.dosage_weight_unit
  ) {
    // Use combined unit/weight unit when available
    // const weightUnit = dosageInfo.dosage_patient_weight_unit
    unit = dosageInfo.dosage_weight_unit
  } else if (product) {
    // Use product default if general treatment
    const productDefaultUnit = product.default_unit
    unit = productDefaultUnit
  } else if (task) {
    // Dont allow empty string to be submitted, prefer null
    unit = task.unit !== '' ? task.unit : null
  }

  return unit
}
