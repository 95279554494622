import React from 'react'
import {
  FieldValues,
  FieldPath,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { SwitchInput, Props as SwitchInputProps } from '.'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> &
  Omit<SwitchInputProps, 'value' | 'onChangeValue'> & {
    onChangeListener?: (value: boolean) => void
  }

export const ControlledSwitchInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  defaultValue,
  onChangeListener,
  ...props
}: Props<TFieldValues, TName>) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
    ...(defaultValue !== undefined && { defaultValue }),
  })
  return (
    <SwitchInput
      {...props}
      value={value as boolean}
      onChangeValue={newValue => {
        onChange(newValue)
        onChangeListener?.(newValue)
      }}
    />
  )
}
