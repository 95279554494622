import { Colors } from 'constants/Colors'
import React from 'react'
import Svg, { Path, Defs, Mask, Use, Rect } from 'react-native-svg'

type Props = {
  color?: string
  length?: number
}

export const SvgPlus: React.FC<Props> = ({
  color = Colors.white,
  length = 18,
}) => (
  <Svg width={length} height={length} viewBox="0 0 24 24">
    <Defs>
      <Path
        d="M12,3 C12.5128358,3 12.9355072,3.38604019 12.9932723,3.88337887 L13,4 L13,11 L20,11 C20.5522847,11 21,11.4477153 21,12 C21,12.5128358 20.6139598,12.9355072 20.1166211,12.9932723 L20,13 L13,13 L13,20 C13,20.5522847 12.5522847,21 12,21 C11.4871642,21 11.0644928,20.6139598 11.0067277,20.1166211 L11,20 L11,13 L4,13 C3.44771525,13 3,12.5522847 3,12 C3,11.4871642 3.38604019,11.0644928 3.88337887,11.0067277 L4,11 L11,11 L11,4 C11,3.44771525 11.4477153,3 12,3 Z"
        id="svg-plus-path-1"
      />
    </Defs>
    <Mask id="svg-plus-mask-2" fill="white">
      <Use xlinkHref="#svg-plus-path-1" />
    </Mask>
    <Rect
      fill={color}
      mask="url(#svg-plus-mask-2)"
      x="0"
      y="0"
      width="24"
      height="24"
    />
  </Svg>
)
