import { ApolloError, Operation } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { Scope, SentryExpo } from 'src/utils/errorTracking/sentry/common'

export const isGraphQLError = (
  error: unknown,
): error is GraphQLError | ApolloError =>
  !!error && (error instanceof GraphQLError || error instanceof ApolloError)

export const graphQLErrorLog = (
  graphQLError: GraphQLError,
  operation: Operation,
) => {
  const { message, locations, path } = graphQLError
  // eslint-disable-next-line no-console
  console.log(
    `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
      locations,
    )}, Path: ${path}, OperationName: ${
      operation.operationName
    }, Variables:${JSON.stringify(operation.variables)}`,
  )
  // eslint-disable-next-line no-console
  console.error(graphQLError)
}

export const sentryGraphQLErrorCapture = (
  graphQLError: GraphQLError,
  operation: Operation,
) => {
  SentryExpo.withScope((scope: Scope) => {
    scope.setContext('operation', {
      ...operation,
      query: JSON.stringify(operation.query),
      variables: JSON.stringify(operation.variables),
    })
    scope.setContext('graphQLError', {
      ...graphQLError,
      extensions: JSON.stringify(graphQLError.extensions),
      locations: JSON.stringify(graphQLError.locations),
    })
    SentryExpo.captureEvent({
      message: graphQLError.message,
    })
  })
}
