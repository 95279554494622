import React from 'react'
import { ShowCompletedToggle } from './ShowCompletedToggle'
import { TimeNav } from './TimeNav'

type Props = {
  initialStartAt: string
  startAt: string
  taskCount: number | undefined
  showCompleted: boolean
  setShowCompleted: (showCompleted: boolean) => void
  updateStartAt: (startAt: string) => void
}

export const UpcomingTabSelectors: React.FC<Props> = ({
  showCompleted,
  setShowCompleted,
  initialStartAt,
  startAt,
  taskCount,
  updateStartAt,
}) => {
  return (
    <>
      <ShowCompletedToggle
        showCompleted={showCompleted}
        setShowCompleted={setShowCompleted}
      />
      <TimeNav
        startAt={startAt}
        taskCount={taskCount}
        initialStartAt={initialStartAt}
        updateStartAt={updateStartAt}
      />
    </>
  )
}
