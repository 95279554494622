import React from 'react'
import Svg, { Path } from 'react-native-svg'
import { Colors } from 'src/design-system/theme'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const LockSvg: React.FC<Props> = ({
  width = 12,
  height = 14,
  color = Colors.Contents.accessory,
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 12 14" fill="none">
      <Path
        d="M3.85714 3.9375V5.25H8.14286V3.9375C8.14286 2.72891 7.18393 1.75 6 1.75C4.81607 1.75 3.85714 2.72891 3.85714 3.9375ZM2.14286 5.25V3.9375C2.14286 1.76367 3.87054 0 6 0C8.12946 0 9.85714 1.76367 9.85714 3.9375V5.25H10.2857C11.2312 5.25 12 6.03477 12 7V12.25C12 13.2152 11.2312 14 10.2857 14H1.71429C0.76875 14 0 13.2152 0 12.25V7C0 6.03477 0.76875 5.25 1.71429 5.25H2.14286Z"
        fill={color}
      />
    </Svg>
  )
}
