import { gql } from '@apollo/client'
import {
  CUSTOM_PRODUCT_FIELDS,
  MEDICATION_FIELDS,
} from 'components/Settings/graphql'

export const GET_PRODUCT_BY_ORIGIN_ID = gql`
  query getProductByOriginId($origin_id: String!, $organisation_id: ID!) {
    getProductByOriginId(
      origin_id: $origin_id
      organisation_id: $organisation_id
    ) {
      ...MedicationFields
      ...CustomProductFields
    }
  }
  ${CUSTOM_PRODUCT_FIELDS}
  ${MEDICATION_FIELDS}
`
