import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number
  color?: string
}

export const EyeSvg = ({ size = 14, color = '#717479' }: Props) => {
  return (
    <Svg width={size} height={(size * 12) / 14} viewBox="0 0 14 12" fill="none">
      <Path
        d="M6.99879 0.5C5.03507 0.5 3.46263 1.40357 2.31794 2.47902C1.18054 3.54464 0.419842 4.82143 0.060151 5.69799C-0.0200503 5.89196 -0.0200503 6.10804 0.060151 6.30201C0.419842 7.17857 1.18054 8.45536 2.31794 9.52098C3.46263 10.5964 5.03507 11.5 6.99879 11.5C8.9625 11.5 10.5349 10.5964 11.6796 9.52098C12.817 8.4529 13.5777 7.17857 13.9398 6.30201C14.02 6.10804 14.02 5.89196 13.9398 5.69799C13.5777 4.82143 12.817 3.54464 11.6796 2.47902C10.5349 1.40357 8.9625 0.5 6.99879 0.5ZM3.49909 6C3.49909 5.06227 3.86781 4.16295 4.52413 3.49987C5.18045 2.8368 6.07061 2.46429 6.99879 2.46429C7.92696 2.46429 8.81712 2.8368 9.47344 3.49987C10.1298 4.16295 10.4985 5.06227 10.4985 6C10.4985 6.93773 10.1298 7.83705 9.47344 8.50013C8.81712 9.1632 7.92696 9.53571 6.99879 9.53571C6.07061 9.53571 5.18045 9.1632 4.52413 8.50013C3.86781 7.83705 3.49909 6.93773 3.49909 6ZM6.99879 4.42857C6.99879 5.29531 6.30128 6 5.44336 6C5.27081 6 5.10555 5.97054 4.95 5.91897C4.81634 5.87478 4.66079 5.95826 4.66565 6.10067C4.67295 6.27009 4.69725 6.43951 4.74342 6.60893C5.07638 7.86607 6.35717 8.6125 7.60151 8.27612C8.84585 7.93973 9.58467 6.64576 9.25171 5.38862C8.98195 4.36964 8.09001 3.6846 7.09843 3.64286C6.95747 3.63795 6.87484 3.79263 6.91858 3.93013C6.96962 4.08728 6.99879 4.25424 6.99879 4.42857Z"
        fill={color}
      />
    </Svg>
  )
}
