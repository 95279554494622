import { veterinary_roles } from 'types/globalTypes'
import { getUsersList_getUsersList_items } from 'types/getUsersList'

export const getVetVetTechFromUsers = (
  users: getUsersList_getUsersList_items[],
) => {
  const vets = []
  const vetTechs = []
  for (const user of users) {
    if (user.veterinary_role === veterinary_roles.VET) {
      vets.push({
        value: user.id,
        text: user.full_name,
      })
    } else if (user.veterinary_role === veterinary_roles.VETERINARY_TECH) {
      vetTechs.push({
        value: user.id,
        text: user.full_name,
      })
    }
  }
  return {
    vets,
    vetTechs,
  }
}
