import {
  PossibleSelectValues,
  SelectOption,
} from 'components/common/Select/Select.types'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Select } from 'src/components/common'
import { useOrganisation } from 'src/context/organisation'
import { useTreatmentFrequencies } from 'src/hooks/useTreatmentFrequencies'
import {
  INTERVAL_OPTIONS,
  NO_TASKS,
  SINGLE_TASK,
} from 'components/FrequencySelector/data'
import { FrequencyType } from 'types/globalTypes'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'

export type FrequencyInput = {
  name?: string
  type: FrequencyType | 'SINGLE_TASK' | 'NO_TASKS'
  id: string | null
  frequencies?: number[]
}

export type Props = {
  label?: string
  selected: FrequencyInput | undefined
  onChange: (value?: FrequencyInput) => void
  allowNoTasks?: boolean // Decide if the component allows "No Tasks" in the dropdown
  onlyScheduleTasks?: boolean // Remove "No Tasks" & "Single Tasks" from the dropdown
  showEditedText?: boolean
  textStyle?: StyleProp<TextStyle>
  labelStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  disabled?: boolean
}

// For use when choosing a treatment frequency - both on individual treatments, and on templates
export const FrequencySelector: React.FC<Props> = ({
  label,
  selected,
  onChange,
  allowNoTasks = false,
  onlyScheduleTasks: onlyAllowFrequencyTask = true,
  showEditedText = true,
  style,
  textStyle,
  labelStyle,
  disabled,
}) => {
  const { queryResult, singleFrequency } = useTreatmentFrequencies()
  const [{ organisationId }] = useOrganisation()
  const [selectedId, setSelectedId] = useState<string | undefined | null>(
    selected?.id,
  )

  const { data, loading } = queryResult

  const customFrequencies: FrequencyInput[] = useMemo(
    () => data?.getTreatmentFrequencies.items || [],
    [data],
  )

  const nonRepeatingFrequencies: FrequencyInput[] = useMemo(
    () => (allowNoTasks ? [NO_TASKS, SINGLE_TASK] : [SINGLE_TASK]),
    [allowNoTasks],
  )

  const intervalFrequencies: FrequencyInput[] = INTERVAL_OPTIONS

  const allFrequencies = useMemo(
    () => [
      ...customFrequencies,
      ...nonRepeatingFrequencies,
      ...intervalFrequencies,
    ],
    [customFrequencies, intervalFrequencies, nonRepeatingFrequencies],
  )

  useEffect(() => {
    if (!loading) {
      const equivalentInterval = allFrequencies.find(
        freq =>
          freq?.type === FrequencyType.INTERVAL &&
          selected?.frequencies?.[0] === freq.frequencies?.[0],
      )
      setSelectedId(selected?.id || equivalentInterval?.id)
    }
  }, [allFrequencies, selected, loading])

  const freqToSelectOption = useCallback(
    (frequencies: FrequencyInput[]): SelectOption<string>[] => {
      return frequencies.map(freq => {
        return {
          text: freq.name,
          value: freq.id!,
        }
      })
    },
    [],
  )

  const allOptions = useMemo(
    () => [
      freqToSelectOption(customFrequencies),
      onlyAllowFrequencyTask ? freqToSelectOption(nonRepeatingFrequencies) : [],
      freqToSelectOption(intervalFrequencies),
    ],
    [
      customFrequencies,
      freqToSelectOption,
      intervalFrequencies,
      nonRepeatingFrequencies,
      onlyAllowFrequencyTask,
    ],
  )

  const onChangeOverride = useCallback(
    (val: PossibleSelectValues): void => {
      const selectedFrequency = allFrequencies.find(freq => freq?.id === val)
      setSelectedId(selectedFrequency?.id)
      onChange(selectedFrequency)
    },
    [allFrequencies, onChange],
  )

  // Special Handling for changed/deleted frequencies
  const [placeholder, setPlaceholder] = useState<string | undefined>(undefined)

  const getPlaceholderForDeleted = useCallback(
    async (id: string) => {
      const frequency = await singleFrequency({
        organisationId,
        id,
      })
      return frequency.data.getTreatmentFrequency?.name
    },
    [organisationId, singleFrequency],
  )

  useEffect(() => {
    if (!selected?.id) return

    if (selected.type === 'NO_TASKS') {
      setPlaceholder(NO_TASKS.name)
      return
    }

    const selectedInOptions = customFrequencies.find(
      freq => freq?.id === selected.id,
    )
    if (!selectedInOptions) {
      getPlaceholderForDeleted(selected.id).then(placeholderName =>
        setPlaceholder(`${placeholderName || 'Unknown'} (Deleted)`),
      )
    } else if (
      !isEqual(selectedInOptions.frequencies, selected.frequencies) &&
      showEditedText
    ) {
      setSelectedId(null)
      setPlaceholder(`${selectedInOptions?.name} (Edited)`)
    }
  }, [customFrequencies, getPlaceholderForDeleted, selected, showEditedText])

  return (
    <Select
      label={label}
      dialog={false}
      placeholder={placeholder}
      options={allOptions}
      selected={selectedId}
      onChange={onChangeOverride}
      loading={loading}
      style={style}
      textStyle={textStyle}
      labelStyle={labelStyle}
      disabled={disabled}
    />
  )
}
