import { debounce, DebounceSettings, DebouncedFunc } from 'lodash'
import { useState, useEffect, DependencyList, useRef } from 'react'

export const useDebounceCallback = <
  T extends (...args: any[]) => ReturnType<T>,
>(
  callback: T,
  deps: DependencyList,
  delay: number,
  options?: DebounceSettings,
) => {
  // Make sure callback won't get called after component destroyed
  const isDestroyedRef = useRef(false)
  useEffect(() => {
    return () => {
      isDestroyedRef.current = true
    }
  }, [])

  const getDebounceCallback = () =>
    debounce(
      (...arg) => {
        if (isDestroyedRef.current) {
          return
        }
        return callback(...arg)
      },
      delay,
      options,
    )

  const [debouncedCallback, setDebouncedCallback] =
    useState<DebouncedFunc<T>>(getDebounceCallback)
  useEffect(() => {
    setDebouncedCallback((oldDebounceCallback: DebouncedFunc<T>) => {
      oldDebounceCallback.cancel()
      return getDebounceCallback()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay])

  return debouncedCallback!
}
