import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle,
  View,
} from 'react-native'
import { rafInterval } from 'src/utils/rafTimeout'
import { padStart } from 'lodash'
import { MAXIMUM_TIMER_ELAPSED } from 'components/PatientPanel/Cards/Timers'
import { Colors, Typography, Variables } from 'src/design-system/theme'
import { Ionicons } from '@expo/vector-icons'

export type StopClockData = {
  elapsed: number
  startAt: Date | null
}

type Props = StopClockData & {
  title: string
  onToggle?: (data: StopClockData) => Promise<any>
  style?: StyleProp<ViewStyle>
  timerStyle?: StyleProp<TextStyle>
  disabled?: boolean
}

export type Ref = {
  exportElapsedInternal: number
}

const pad = (n: number) => padStart(n.toString(), 2, '0')
const formatTime = (totalSec: number) => {
  const seconds = totalSec % 60
  const minutes = Math.floor(totalSec / 60) % 60
  const hours = Math.floor(totalSec / 3600) % 60
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
}

/*
  Warning: Need to destroy and recreate if elaspsed or startAt changes. 
  To do this, use a key joined by elapsed & start_at.
  <StopClock
    key={`${timer.elapsed}${timer.start_at}`}
    ...
  />
*/
export const StopClock = forwardRef<Ref, Props>(
  (
    { title, elapsed, startAt, onToggle, style, timerStyle, disabled = false },
    ref,
  ) => {
    const [isStopped, setIsStopped] = useState(!startAt)
    const [elapsedInternal, setElapsedInternal] = useState(() => {
      if (startAt) {
        return Math.floor((Date.now() - startAt.getTime()) / 1000) + elapsed
      }
      return elapsed
    })
    const pauseTimeRef = useRef<number>(startAt?.getTime() || 0)
    const lastElapsedRef = useRef<number>(elapsed)

    useEffect(() => {
      if (isStopped) {
        return
      }
      const cancel = rafInterval(() => {
        setElapsedInternal(
          Math.min(
            lastElapsedRef.current +
              Math.floor((Date.now() - pauseTimeRef.current) / 1000),
            MAXIMUM_TIMER_ELAPSED,
          ),
        )
      }, 200)
      return () => cancel()
    }, [isStopped])

    const toggle = () => {
      const nextIsStopped = !isStopped
      const lastLastElapsed = lastElapsedRef.current
      const lastPauseTime = pauseTimeRef.current
      setIsStopped(nextIsStopped)
      lastElapsedRef.current = elapsedInternal
      pauseTimeRef.current = Date.now()
      onToggle?.({
        startAt: nextIsStopped ? null : new Date(pauseTimeRef.current),
        elapsed: elapsedInternal,
      }).catch(() => {
        // Revert on error
        setIsStopped(isStopped)
        lastElapsedRef.current = lastLastElapsed
        pauseTimeRef.current = lastPauseTime
      })
    }

    useImperativeHandle(
      ref,
      () => ({ exportElapsedInternal: elapsedInternal }),
      [elapsedInternal],
    )
    return (
      <TouchableOpacity
        style={[styles.container, style]}
        onPress={toggle}
        accessibilityLabel={title}
        disabled={disabled}
      >
        <Text
          style={[styles.title, timerStyle, !isStopped && styles.runningTimer]}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {title}
        </Text>

        <View style={styles.container}>
          <Text
            style={[
              styles.timer,
              timerStyle,
              !isStopped && styles.runningTimer,
            ]}
          >
            {formatTime(elapsedInternal)}
          </Text>
          <Ionicons
            name={isStopped ? 'play' : 'pause'}
            size={16}
            color={
              isStopped ? Colors.Contents.tertiary : Colors.Contents.primary
            }
          />
        </View>
      </TouchableOpacity>
    )
  },
)

StopClock.displayName = 'StopClock'

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: Variables.GutterSpacing.base,
  },
  title: {
    ...Typography.Label.M,
    color: Colors.Contents.tertiary,
    flexShrink: 1,
  },
  timer: {
    ...Typography.Label.M,
    color: Colors.Contents.tertiary,
  },
  runningTimer: {
    color: Colors.Contents.primary,
    fontWeight: Typography.FontWeights.bold,
  },
})
