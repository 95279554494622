import React, { FunctionComponent } from 'react'
import { StyleSheet, Text } from 'react-native'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'

type PatientInfoProps = {
  patientId: string | null
  weightLastUpdate: string
  order?: number | null
  location?: string | null
}

export const PatientInfo: FunctionComponent<PatientInfoProps> = ({
  patientId,
  weightLastUpdate,
  order,
  location,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Text style={styles.text}>
        {t('patientPanel:cards.patientInfo.list.order', { value: order })}
      </Text>
      <Text style={styles.text}>
        {t('patientPanel:cards.patientInfo.list.weight', {
          value: weightLastUpdate,
        })}
      </Text>
      <Text style={styles.text}>
        {t('patientPanel:cards.patientInfo.list.patientId', {
          value: patientId,
        })}
      </Text>
      <Text style={styles.text}>
        {t('patientPanel:cards.patientInfo.list.location', {
          value: location || '',
        })}
      </Text>
    </>
  )
}

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    fontFamily: Fonts.regular,
  },
})
