import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { getOptimisticId } from 'src/utils/optimisticId'
import { partition } from 'lodash'
import { getConsultation_getConsultation_workflow as Workflow } from 'types/getConsultation'

export type WorkflowItem = {
  id: string
  name: string
  completedAt: string | null // date
  deletedAt: string | undefined
}

export type FormValues = {
  items: WorkflowItem[]
}
type useWorkflowItemFormArgs = {
  workflow: Workflow | null
  onSubmit: (submitValues: FormValues) => void
}

const getInitialItems = (workflow: Workflow | null) => {
  if (
    !workflow?.workflow_items?.items ||
    workflow.workflow_items.items.length === 0
  ) {
    // Need all fields to init useForm. Otherwise it won't save name. Fix VR-5924.
    return [
      {
        id: getOptimisticId(),
        name: '',
        completedAt: null,
        deletedAt: new Date().toISOString(),
      },
    ]
  }

  return workflow.workflow_items.items.map(item => {
    return {
      id: item.id,
      name: item.name,
      completedAt: item.completed_at,
      deletedAt: undefined as string | undefined,
    }
  })
}

export const useWorkflowItemForm = ({
  workflow,
  onSubmit,
}: useWorkflowItemFormArgs) => {
  const initialValues = useMemo(
    () => ({
      items: getInitialItems(workflow),
    }),
    [workflow],
  )

  const { handleSubmit, watch, setValue } = useForm({
    defaultValues: initialValues,
  })
  const submitForm = handleSubmit(onSubmit)

  const items = watch('items')
  const [finishedItems, unfinishedItems] = useMemo(
    () =>
      partition(
        items.filter(({ deletedAt }) => !deletedAt),
        item => item.completedAt,
      ),
    [items],
  )

  const itemsCount = items.filter(({ deletedAt }) => !deletedAt).length

  const addEmptyItem = () => {
    setValue('items', [
      ...items,
      {
        id: getOptimisticId(),
        name: '',
        completedAt: null,
        deletedAt: undefined,
      },
    ])
  }

  const addItems = (newItems: { name: string }[]) => {
    setValue('items', [
      ...items,
      ...newItems.map(({ name }) => ({
        name,
        id: getOptimisticId(),
        completedAt: null,
        deletedAt: undefined,
      })),
    ])
  }

  const deleteItem = (id: string) => {
    setValue(
      'items',
      items.map(item => {
        if (item.id === id) {
          return {
            ...item,
            deletedAt: new Date().toISOString(),
          }
        }
        return item
      }),
    )
  }

  const deleteAllItems = () => {
    setValue(
      'items',
      items.map(item => ({
        ...item,
        deletedAt: new Date().toISOString(),
      })),
    )
  }

  const toggleFinishItem = (item: WorkflowItem) => {
    const newItems = items.filter(i => i.id !== item.id)
    newItems.unshift({
      ...item,
      completedAt: item.completedAt ? null : new Date().toISOString(),
    })
    setValue('items', newItems)
  }

  const setUnfinishedItems = (newItems: WorkflowItem[]) => {
    // new items won't contain deletedItems, need to add it back.
    const deletedItems = items.filter(i => !!i.deletedAt)
    setValue('items', [...finishedItems, ...deletedItems, ...newItems])
  }

  const setItemName = (item: Partial<Workflow>, newName: string) => {
    const index = items.findIndex(i => i.id === item.id)
    return setValue(`items.${index}.name`, newName)
  }

  return {
    finishedItems,
    unfinishedItems,
    itemsCount,
    addEmptyItem,
    addItems,
    deleteItem,
    deleteAllItems,
    toggleFinishItem,
    setUnfinishedItems,
    submitForm,
    setItemName,
  }
}
