import React from 'react'
import { SvgArrow } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { noop } from 'lodash'
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

type Props = {
  value: string | React.ReactElement
  disabled?: boolean
  a11yLabel?: string
  label?: string
  rightLabel?: string
  icon?: React.ReactElement
  required?: boolean
  onLayout?: (e: LayoutChangeEvent) => void
  onPress?: (e: GestureResponderEvent) => void
  iconRight?: React.ReactElement
}

const SvgArrowIcon = (
  <SvgArrow orient="down" color="grey" width={15} height={15} />
)

export const Field: React.FC<Props> = ({
  a11yLabel,
  disabled = false,
  icon = SvgArrowIcon,
  label,
  rightLabel,
  onLayout = noop,
  onPress = noop,
  value,
  required = false,
  iconRight,
}) => {
  return (
    <>
      {label && (
        <View style={styles.labelContainer}>
          <Text style={styles.labelText}>
            {label}
            {required && <Text style={styles.requiredLabel}>*</Text>}
          </Text>
          <Text style={styles.labelText}>{rightLabel}</Text>
        </View>
      )}
      <TouchableOpacity
        disabled={disabled}
        style={[styles.container, disabled && styles.disabled]}
        onPress={onPress}
        onLayout={onLayout}
        accessibilityLabel={a11yLabel ? a11yLabel : `${label} Select Input`}
      >
        <Text style={styles.selectText}>{value}</Text>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.icon}>{icon}</View>
          {iconRight && <View style={styles.icon}>{iconRight}</View>}
        </View>
      </TouchableOpacity>
    </>
  )
}

const styles = StyleSheet.create({
  labelContainer: {
    flexDirection: 'row',
    paddingVertical: 2,
    paddingHorizontal: 8,
    justifyContent: 'space-between',
  },
  labelText: {
    fontFamily: Fonts.regular,
    color: Colors.contentSecondary,
    fontSize: 12,
  },
  container: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.shared.borderGrey,
    height: 40,
    paddingVertical: 5,
    paddingHorizontal: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.3,
  },
  selectText: {
    fontSize: 14,
    fontFamily: Fonts.regular,
  },
  icon: {
    paddingHorizontal: 4,
  },
  requiredLabel: {
    color: 'red',
    marginTop: 0,
  },
})
