import { useEffect, useState } from 'react'
import { wsClient } from 'src/apollo/wsLink'

let isWebsocketConnected = false
wsClient.onConnected(() => (isWebsocketConnected = true))
wsClient.onReconnected(() => (isWebsocketConnected = true))
wsClient.onDisconnected(() => (isWebsocketConnected = false))

/**
 *
 * @returns is websocket connected
 */
export const useIsConnected = () => {
  const [isConnected, setIsConnected] = useState(isWebsocketConnected)
  useEffect(() => {
    return wsClient.onConnected(() => setIsConnected(true)) as () => void
  }, [])

  useEffect(() => {
    return wsClient.onReconnected(() => setIsConnected(true)) as () => void
  }, [])

  useEffect(() => {
    return wsClient.onDisconnected(() => setIsConnected(false)) as () => void
  }, [])

  return isConnected
}
