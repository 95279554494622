import { isArray, isBoolean, minBy } from 'lodash'
import { weightFactor } from 'src/constants/Weight'

import { criTimeFactor, volumeFactor } from '../data'
import {
  CalculatorInput,
  ChangedFieldName,
  EquationTypeChain,
  Inputs,
  InputsWithChain,
  Locks,
} from '../types'

enum EquationType {
  Left = 'Left',
  Right = 'Right',
}

type EquationBase = {
  equationType: EquationType
  fieldName: ChangedFieldName
  priority: number
}

type EquationValue = EquationBase & { value: number | null }

type CalculatorValue = EquationBase & { value: number }

type CalculatorBase = { equationType: EquationType; value: number }

type EquationValuesAndUnit = {
  values: EquationValue[]
  unitQuotient: number
}

/*
   We have several rules, when one of equation are balanced, and the user keeps changing one field.
   The calculator will find the lowest priority field as result by this priority number and getLockTimes
   When the user is entering the fields the times will be 4. => 3 * 4 > 11.
   So the result won't be the user input.
   The times of pre-input value will be 2. => 3 * 2 > 5
   So normally this won't changes the pre-input. 
   And 5 * 2 < 11, so the patient weight and the concentration won't be changed
   This is the reason using 3, 4, 5 and 11
   Details: https://ezyvet.atlassian.net/browse/VR-7114
*/
const defaultFieldPriority: { readonly [key in ChangedFieldName]: number } = {
  dilutedConcentration: 4,
  dosageRate: 3,
  ivBagSize: 4,
  doseRate: 5,
  medicationVolume: 5,
  infusionRateTotal: 5,
  patientWeight: 11,
  concentration: 11,
}

enum LockTimes {
  UserInput = 4,
  PreInput = 2,
  Default = 1,
}

const isUserPreInput = (field?: boolean) => {
  return field === false
}

const getChangedFieldEquationTypeChains = (
  changedFieldName: ChangedFieldName,
  isDiluted?: boolean,
) => {
  switch (changedFieldName) {
    case 'patientWeight':
    case 'doseRate':
      return EquationTypeChain.DrWD
    case 'concentration': {
      if (isBoolean(isDiluted)) {
        return isDiluted ? EquationTypeChain.C2V2CV : EquationTypeChain.IrDC
      }
      return [EquationTypeChain.C2V2CV, EquationTypeChain.IrDC]
    }
    case 'infusionRateTotal':
      return EquationTypeChain.IrDC
    case 'dosageRate': {
      return [EquationTypeChain.DrWD, EquationTypeChain.IrDC]
    }
    case 'ivBagSize':
    case 'medicationVolume': {
      return EquationTypeChain.C2V2CV
    }
    case 'dilutedConcentration': {
      return [EquationTypeChain.C2V2CV, EquationTypeChain.IrDC]
    }
  }
}

const containsPreInput = (
  changedFieldName: ChangedFieldName,
  equationType: EquationTypeChain,
) => {
  const equationTypes = getChangedFieldEquationTypeChains(changedFieldName)
  if (isArray(equationTypes)) {
    return equationTypes.includes(equationType)
  }
  return equationTypes === equationType
}

const getLockTimes = (
  priorityFieldName: ChangedFieldName,
  locks: Locks,
  changedFieldName: ChangedFieldName,
) => {
  if (priorityFieldName === changedFieldName) {
    return LockTimes.UserInput
  }

  if (isUserPreInput(locks[priorityFieldName])) {
    return LockTimes.PreInput
  }

  // dosageRate and dilutedConcentration are not user preInput and not changedField but is calculated
  // we need to check if their equations contains preInput
  switch (priorityFieldName) {
    case 'dosageRate': {
      if (containsPreInput(changedFieldName, EquationTypeChain.DrWD)) {
        return LockTimes.PreInput
      }
      if (
        containsPreInput(changedFieldName, EquationTypeChain.IrDC) &&
        isUserPreInput(locks.doseRate)
      ) {
        return LockTimes.PreInput
      }
      break
    }
    case 'dilutedConcentration': {
      if (containsPreInput(changedFieldName, EquationTypeChain.C2V2CV)) {
        return LockTimes.PreInput
      }
      if (
        containsPreInput(changedFieldName, EquationTypeChain.IrDC) &&
        isUserPreInput(locks.medicationVolume) &&
        isUserPreInput(locks.ivBagSize)
      ) {
        return LockTimes.PreInput
      }

      break
    }
  }

  return LockTimes.Default
}

const getLowestPriorityField = (
  calculatorValues: CalculatorValue[],
  changedFieldName: ChangedFieldName,
  locks: Locks,
) => {
  const values = calculatorValues.map(calculatorValue => ({
    ...calculatorValue,
    priority:
      getLockTimes(calculatorValue.fieldName, locks, changedFieldName) *
      calculatorValue.priority,
  }))
  return minBy(values, 'priority')
}

/*
             Left                                                  Right
   DrWD   => doseRate * patientWeight                            = dosageRate
   IrDC   => concentration(dilutedConcentration) * InfusionRate  = dosageRate
   C2V2CV => dilutedConcentration * finalVolume                  = concentration * medicationVolume
   More details https://ezyvet.atlassian.net/wiki/spaces/VR/pages/3532030000/Revised+CRI+calculator
*/
const fieldEquationType: { readonly [key in ChangedFieldName]: EquationType } =
  {
    dilutedConcentration: EquationType.Left,
    dosageRate: EquationType.Right,
    doseRate: EquationType.Left,
    medicationVolume: EquationType.Right,
    ivBagSize: EquationType.Left,
    infusionRateTotal: EquationType.Left,
    patientWeight: EquationType.Left,
    concentration: EquationType.Left, // when diluted concentration is in the right side of equation, getFieldEquationType corrects this
  }

const getFieldEquationType = (
  changedFieldName: ChangedFieldName,
  isDiluted: boolean,
) => {
  if (isDiluted && changedFieldName === 'concentration') {
    return EquationType.Right
  }
  return fieldEquationType[changedFieldName]
}

const getEquationValue = (
  fieldName: ChangedFieldName,
  inputs: Inputs,
  isDiluted: boolean = false,
): EquationValue => ({
  fieldName,
  value: inputs[fieldName],
  equationType: getFieldEquationType(fieldName, isDiluted),
  priority: defaultFieldPriority[fieldName],
})

// getIrDCEquationValues, getDrWDEquationValues, getC2V2CVEquationValues prepare related values for calculator
const getIrDCEquationValues = (inputs: Inputs, isDiluted: boolean = false) => {
  const result = [
    getEquationValue('infusionRateTotal', inputs),
    getEquationValue('dosageRate', inputs),
  ]
  if (isDiluted) {
    result.push(getEquationValue('dilutedConcentration', inputs))
  } else {
    result.push(getEquationValue('concentration', inputs))
  }
  return result
}

const getDrWDEquationValues = (inputs: Inputs) => {
  return [
    getEquationValue('dosageRate', inputs),
    getEquationValue('doseRate', inputs),
    getEquationValue('patientWeight', inputs),
  ]
}

const getC2V2CVEquationValues = (inputs: Inputs) => {
  return [
    getEquationValue('concentration', inputs, true),
    getEquationValue('dilutedConcentration', inputs),
    getEquationValue('medicationVolume', inputs),
    getEquationValue('ivBagSize', inputs),
  ]
}

const getEquationValues = (
  equationTypeChain: EquationTypeChain,
  inputs: Inputs,
) => {
  switch (equationTypeChain) {
    case EquationTypeChain.C2V2CV:
      return getC2V2CVEquationValues(inputs)
    case EquationTypeChain.DrWD:
      return getDrWDEquationValues(inputs)
    case EquationTypeChain.IrDC:
      return getIrDCEquationValues(inputs, inputs.isDiluted)
  }
}
// Unit quotient fns solve all weight and volume units convert
const getDrWDUnitQuotient = (inputs: Inputs) => {
  const { patientWeightUnit, dosageWeightUnit, dosagePerWeightUnit } = inputs

  const dosageRateFormalUnit = weightFactor[dosageWeightUnit] // DR and D using same unit
  const doseFormalUnit =
    dosageRateFormalUnit / weightFactor[dosagePerWeightUnit]
  const patientWeightFormalUnit = weightFactor[patientWeightUnit]
  return (doseFormalUnit * patientWeightFormalUnit) / dosageRateFormalUnit
}

const getIrDCUnitQuotient = (inputs: Inputs) => {
  const {
    concentrationWeightUnit,
    concentrationVolumeUnit,
    dosageWeightUnit,
    infusionRateVolumeUnit,
    infusionRateTimeUnit,
    doseRateTimeUnit,
  } = inputs

  // a bit complex logic to handle time unit is optional for old data.
  const infusionRateTimeFormalUnit = !infusionRateTimeUnit
    ? 1
    : criTimeFactor[infusionRateTimeUnit]
  const doseRateTimeFormUnit = !infusionRateTimeUnit
    ? 1
    : criTimeFactor[doseRateTimeUnit ?? infusionRateTimeUnit]

  const dosageRateFormalUnit =
    weightFactor[dosageWeightUnit] / doseRateTimeFormUnit
  const infusionRateFormUnit =
    volumeFactor[infusionRateVolumeUnit] / infusionRateTimeFormalUnit
  const concentrationFormalUnit =
    weightFactor[concentrationWeightUnit] /
    volumeFactor[concentrationVolumeUnit]

  return (concentrationFormalUnit * infusionRateFormUnit) / dosageRateFormalUnit
}

const getC2V2CVUnitQuotient = (inputs: Inputs) => {
  const { medicationVolumeUnit, ivBagSizeUnit } = inputs
  const finalFormalUnit = volumeFactor[ivBagSizeUnit]
  const medicationFormalUnit = volumeFactor[medicationVolumeUnit]

  return finalFormalUnit / medicationFormalUnit
}

const getUnitQuotient = (
  equationTypeChain: EquationTypeChain,
  inputs: Inputs,
) => {
  switch (equationTypeChain) {
    case EquationTypeChain.C2V2CV:
      return getC2V2CVUnitQuotient(inputs)
    case EquationTypeChain.DrWD:
      return getDrWDUnitQuotient(inputs)
    case EquationTypeChain.IrDC:
      return getIrDCUnitQuotient(inputs)
  }
}
// prepare related fields and values for equation
const getEquationValuesAndUnit = (
  equationTypeChain: EquationTypeChain,
  inputs: Inputs,
): EquationValuesAndUnit => {
  return {
    values: getEquationValues(equationTypeChain, inputs),
    unitQuotient: getUnitQuotient(equationTypeChain, inputs),
  }
}

const getCalculatorResultByPriority = (
  calculatorValues: CalculatorValue[],
  changedFieldName: ChangedFieldName,
  locks: Locks,
  unitQuotient: number,
) => {
  const resultFieldValue = getLowestPriorityField(
    calculatorValues,
    changedFieldName,
    locks,
  )
  if (!resultFieldValue) {
    return null
  }

  const filteredCalculatorValues = calculatorValues.filter(
    e => e.fieldName !== resultFieldValue.fieldName,
  )

  return {
    resultField: resultFieldValue.fieldName,
    resultValue: getCalculatorResult(
      filteredCalculatorValues,
      unitQuotient,
      resultFieldValue.equationType,
    ),
  }
}

// Make unit to the left
const getCalculatorBase = (
  calculatorBases: CalculatorBase[],
  unitQuotient: number,
) => [
  ...calculatorBases,
  { equationType: EquationType.Left, value: unitQuotient },
]

/*
   Calculator the result by multiply or divide other values
   For example: a * b * unit = c * d
   when `a` should be the result and in the left side, the code had filtered it out before this function.
   a= (c * d / b) / unit
   The code multiply different side values `c` and `d` here and divide the same side `b` here.
*/
const getCalculatorResult = (
  calculatorValues: CalculatorBase[],
  unitQuotient: number,
  resultEquationType: EquationType,
) =>
  getCalculatorBase(calculatorValues, unitQuotient).reduce(
    (result, calculatorValue) => {
      if (calculatorValue.equationType === resultEquationType) {
        return result / calculatorValue.value
      }
      return result * calculatorValue.value
    },
    1,
  )

// filter no value fields
const getIsCalculatorValue = (
  equationValue: EquationValue,
): equationValue is CalculatorValue => !!equationValue.value

const getResult = (
  equation: EquationValuesAndUnit,
  changedFieldName: ChangedFieldName,
  locks: Locks,
) => {
  const resultFieldValues: EquationValue[] = []
  const calculatorValues: CalculatorValue[] = []
  equation.values.forEach(equationValue => {
    if (!getIsCalculatorValue(equationValue)) {
      resultFieldValues.push(equationValue)
      return
    }
    calculatorValues.push(equationValue)
  })
  // 2 or more fields are empty, won't run calculator
  if (resultFieldValues.length > 1) {
    return null
  }
  // only 1 field is empty, will calculate it
  if (resultFieldValues.length === 1) {
    return {
      resultField: resultFieldValues[0].fieldName,
      resultValue: getCalculatorResult(
        calculatorValues,
        equation.unitQuotient,
        resultFieldValues[0].equationType,
      ),
    }
  }
  // all fields have value and was balanced, we pick up the lowest priority field as result field
  return getCalculatorResultByPriority(
    calculatorValues,
    changedFieldName,
    locks,
    equation.unitQuotient,
  )
}

export const getNewLocksByEquationType = (
  equationTypeChain: EquationTypeChain,
  isDiluted: boolean,
) => {
  switch (equationTypeChain) {
    case EquationTypeChain.C2V2CV:
      return {
        dilutedConcentration: true,
        concentration: true,
        ivBagSize: true,
        medicationVolume: true,
      }
    case EquationTypeChain.DrWD:
      return {
        patientWeight: true,
        doseRate: true,
        dosageRate: true,
      }
    case EquationTypeChain.IrDC:
      return {
        ...(isDiluted
          ? { dilutedConcentration: true }
          : { concentration: true }),
        dosageRate: true,
        infusionRateTotal: true,
      }
  }
}

export const generateCalculatorParams = (
  changedFieldName: ChangedFieldName,
  inputs: Inputs,
  oldChains: EquationTypeChain[],
): InputsWithChain | InputsWithChain[] => {
  const fieldEquationTypeChain = getChangedFieldEquationTypeChains(
    changedFieldName,
    inputs.isDiluted ?? false,
  )
  if (isArray(fieldEquationTypeChain)) {
    return fieldEquationTypeChain.map(equationTypeChain => {
      const hasChain = oldChains.includes(equationTypeChain)
      return {
        ...inputs,
        hasChain,
        equationTypeChain,
        chains: [...oldChains, equationTypeChain],
        changedFieldName,
      }
    })
  }
  const hasChain = oldChains.includes(fieldEquationTypeChain)
  return {
    ...inputs,
    hasChain,
    chains: [...oldChains, fieldEquationTypeChain],
    equationTypeChain: fieldEquationTypeChain,
    changedFieldName,
  }
}

export const calculateResult = (
  equationTypeChain: EquationTypeChain,
  inputs: CalculatorInput,
  locks: Locks = {},
) => {
  const changedFieldName = inputs.changedFieldName
  const changedFieldResult = inputs[changedFieldName]
  if (!changedFieldResult) {
    return false // when user clear a field, return false
  }
  // prepare all related fields and values for particular equation
  const equationValuesAndUnit = getEquationValuesAndUnit(
    equationTypeChain,
    inputs,
  )
  return getResult(equationValuesAndUnit, changedFieldName, locks)
}
