import React, { useCallback, useState } from 'react'
import { Image, StyleSheet, View, Text, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Button } from 'src/components/common/Button'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { AddTreatmentDrawer } from 'components/AddTreatment/AddTreatmentDrawer'

type Props = {
  isFinalized: boolean
}

export const EmptyGrid: React.FC<Props> = ({ isFinalized }) => {
  const { t } = useTranslation()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const toggleDrawer = useCallback(
    () => setIsDrawerVisible(isVisible => !isVisible),
    [setIsDrawerVisible],
  )

  return (
    <ScrollView contentContainerStyle={styles.contentContainer}>
      <View style={styles.imageWrapper}>
        <Image
          style={styles.image}
          source={require('assets/images/empty-sheet.png')}
        />
      </View>
      {!isFinalized && (
        <Button
          testID="AddTreatmentButton"
          onPress={toggleDrawer}
          title={t('sheets.empty.button')}
          style={styles.addButton}
        />
      )}
      <Text style={styles.hintText}>
        {isFinalized ? t('sheets.empty.finalized') : t('sheets.empty.hint')}
      </Text>
      <AddTreatmentDrawer
        isVisible={isDrawerVisible}
        toggleDrawer={toggleDrawer}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  imageWrapper: {
    alignItems: 'center',
  },
  image: {
    width: 256,
    height: 195,
  },
  addButton: {
    marginVertical: 25,
    alignItems: 'center',
  },
  hintText: {
    alignItems: 'center',
    marginHorizontal: 32,
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 17,
    textAlign: 'center',
  },
})
