import React from 'react'
import { Button } from 'components/common'
import { Colors } from 'constants/Colors'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'

import { styles } from '../common/taskTransitionStyles'
import { Action, FluidAction, Task } from '../types'
import { buttonMinWidth, getButtonTitle } from '../utils/taskTransitionUtils'

type SubmitButtonProps = {
  onSubmit: () => void
  disabled: boolean
  loading: boolean
  action: Action | FluidAction
  task?: Task
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  task,
  action,
  disabled,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()

  const isSmallToLargeDisplay =
    breakpoint.isExSmallScreen ||
    breakpoint.isSmallScreen ||
    breakpoint.isMediumScreen ||
    breakpoint.isLargeButNotThatLargeScreen

  const buttonTitle = getButtonTitle({
    action,
    t,
    task,
  })

  return (
    <View style={[styles.flex, isSmallToLargeDisplay && styles.buttonSmall]}>
      <Button
        a11yLabel={t('task:taskTransition:submit:a11y')}
        color={Colors.green}
        disabled={disabled}
        onPress={onSubmit}
        title={buttonTitle}
        loading={loading}
        containerStyle={styles.buttonContainer}
        minWidth={buttonMinWidth}
        style={[
          styles.border,
          disabled
            ? styles.disabledInProgressButtonBorder
            : styles.inProgressButtonBorder,
        ]}
      />
    </View>
  )
}
