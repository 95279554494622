import React, { useCallback, useMemo, useState } from 'react'
import { FormBreak, SecondaryButton, toast } from 'components/common'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, FlatList, StyleSheet, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { Center } from 'components/common/Center'
import { Colors } from 'constants/Colors'
import { Routes } from 'constants/Routes'
import { SCROLL_VIEW_FIRST_INDEX_STICKY } from 'constants/Layout'
import { SearchInput } from 'components/common/SearchInput'
import { SettingsStackParamList } from 'components/Settings/screens'
import { SortDirection } from 'types/globalTypes'
import { searchInList } from 'src/utils/searchInList'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useOrganisation } from 'src/context/organisation'
import {
  getCustomProducts,
  getCustomProducts_getOrganisation_products_items as ProductItemT,
  getCustomProductsVariables,
} from 'src/types/getCustomProducts'

import { SubHeader } from '../SubHeader/SubHeader'
import { CustomProductItem } from './CustomProductItem'
import { GET_CUSTOM_PRODUCTS } from './graphql'
import { useCustomProductsDownloader } from './useCustomProductsDownloader'

type Props = StackScreenProps<SettingsStackParamList>

export const CustomProductListScreen: React.FC<Props> = props => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { navigation } = props
  const { navigate } = navigation
  const [searchText, setSearchText] = useState('')
  const { isSmallishScreen } = useBreakpoint()

  const queryResult = useQuery<getCustomProducts, getCustomProductsVariables>(
    GET_CUSTOM_PRODUCTS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: organisationId,
        productPageInput: {
          sortField: ['name'],
          sortDirection: [SortDirection.asc],
        },
      },
      onError: err => {
        toast.error(err.message)
      },
    },
  )

  const { loading, data } = queryResult
  const products = useMemo(
    () =>
      searchInList(
        data?.getOrganisation?.products.items ?? [],
        'name',
        searchText,
      ),
    [data?.getOrganisation?.products.items, searchText],
  )

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: () => navigate(Routes.Settings),
  }

  const customProductsDownloader = useCustomProductsDownloader({
    organisationId,
    productPageInput: {
      sortField: ['name'],
      sortDirection: [SortDirection.asc],
    },
  })

  const renderListHeader = () => (
    <Center
      style={[
        styles.searchContainer,
        isSmallishScreen && styles.smallContainer,
      ]}
    >
      <SearchInput onChangeText={setSearchText} />
      <View style={styles.buttonContainer}>
        <SecondaryButton
          onPress={customProductsDownloader}
          title={t('settings:products.downloadCustomProduct')}
          style={styles.downloadButton}
          textStyle={styles.downloadButtonText}
        />
        <SecondaryButton
          onPress={() => navigate(Routes.AddCustomProduct)}
          title={t('settings:products.createCustomProduct')}
          style={styles.addButton}
          textStyle={styles.addButtonText}
        />
      </View>
    </Center>
  )

  const renderItem = ({ item }: { item: ProductItemT }) => (
    <CustomProductItem
      onPress={() => navigate(Routes.EditCustomProduct, { productId: item.id })}
      product={item}
    />
  )

  const renderListFooter = useCallback(() => {
    if (!loading) return null
    return (
      <ActivityIndicator
        accessibilityLabel="Product List Loading Indicator"
        size="large"
        style={styles.activityIndicator}
      />
    )
  }, [loading])

  return (
    <>
      <SubHeader
        headlineKey="settings:products.title"
        backButton={backButton}
      />
      <View testID="CustomProductList" style={styles.productList}>
        <FlatList
          contentContainerStyle={styles.listContainerStyle}
          data={products}
          ItemSeparatorComponent={FormBreak}
          keyExtractor={item => item.id}
          ListFooterComponent={renderListFooter()}
          ListHeaderComponent={renderListHeader()}
          renderItem={renderItem}
          stickyHeaderIndices={SCROLL_VIEW_FIRST_INDEX_STICKY}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    marginTop: 32,
  },
  productList: {
    flex: 1,
    width: '100%',
  },
  listContainerStyle: {
    paddingBottom: 16,
  },
  searchContainer: {
    paddingTop: 16,
    backgroundColor: Colors.backgroundGrey,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 0,
  },
  downloadButton: {
    paddingVertical: 10,
    alignSelf: 'flex-start',
    marginLeft: 10,
  },
  downloadButtonText: {
    color: Colors.buttons.blue,
  },
  addButton: {
    paddingVertical: 10,
    alignSelf: 'flex-end',
    marginRight: 10,
  },
  addButtonText: {
    color: Colors.buttons.blue,
  },
  smallContainer: {
    paddingHorizontal: 10,
  },
})
