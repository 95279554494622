import * as SentryBrowser from '@sentry/browser'
import { environment } from 'src/config'
import { ReleaseStage } from 'src/config/environment'
import {
  initCommons,
  tracingOrigins,
} from 'src/utils/errorTracking/sentry/common'

export const routingInstrumentation = undefined

export const initSentry = () => {
  if (environment.releaseStage === ReleaseStage.localDev) return
  SentryBrowser.init({
    /* only set dist and release on web, eas plugin on iOS auto generate
       https://github.com/expo/sentry-expo#no-publish-builds */
    dist: environment.appVersion,
    release: environment.sentryReleaseChannelWeb,
    dsn: environment.sentryDsnWeb,
    integrations: [
      new SentryBrowser.BrowserTracing({
        tracingOrigins,
      }),
    ],
    ...initCommons,
  })
}
