import { gql } from '@apollo/client'

export const TASK_FIELDS = gql`
  fragment TaskFields on Task {
    id
    organisation_id
    patient_id
    sheet_id
    treatment_id
    status
    start_at
    stop_at
    value
    unit
  }
`
export const TASK_MEDICINE_FIELDS = gql`
  fragment TaskMedicineFields on Task {
    medicine_dosage_info {
      days
      hours
      minutes
      concentration
      diluted_concentration
      diluted_concentration_volume_unit
      diluted_concentration_weight_unit
      diluted_concentration_ratio_factor
      diluted_concentration_ratio_volume
      dosage
      dosage_patient_weight_unit
      dosage_weight_unit
      is_cri
      is_diluted
      patient_weight
      patient_weight_unit
      route_of_administration
      infusion_rate_total
      infusion_rate_time_unit
      infusion_rate_volume_unit
      total_volume
      concentration_setting
      total_volume_unit
      total_dosage
      units_billed_per_task
      dose_rate
      dose_rate_time_unit
      medication_volume
      medication_volume_unit
      diluent_used
      iv_bag_size
      iv_bag_size_unit
    }
  }
`

export const TASK_FIELDS_FULL = gql`
  fragment TaskFieldsFull on Task {
    id
    organisation_id
    sheet_id
    patient_id
    treatment_id
    status
    start_at
    stop_at
    buffer_due
    buffer_missed
    given_start_at
    given_stop_at
    updated_at
    user_updated_at
    updated_by
    created_at
    value
    unit
    notes
    assigned_user
    photo_urls
    videos {
      url
      thumbnail_url
    }
    attending_vet
    attending_department
    highlight_colour
    cubex_transaction_status
    send_to_adaptor {
      is_billable
    }
    billing_reference {
      remote_id
      billed_qty
    }
    qty_billed
    _pending @client
    type
    ...TaskMedicineFields
  }
  ${TASK_MEDICINE_FIELDS}
`

export const CREATE_TASK = gql`
  mutation createTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      ...TaskFieldsFull
    }
  }
  ${TASK_FIELDS_FULL}
`

export const UPDATE_TASK_FULL = gql`
  mutation updateTaskFull($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      ...TaskFieldsFull
    }
  }
  ${TASK_FIELDS_FULL}
`

export const RESCHEDULE_TASK = gql`
  mutation rescheduleTask($input: RescheduleTaskInput!) {
    rescheduleTask(input: $input) {
      ...TaskFieldsFull
    }
  }
  ${TASK_FIELDS_FULL}
`

export const RESCHEDULE_TASKS = gql`
  mutation rescheduleTasks($input: [RescheduleTaskInput!]!) {
    rescheduleTasks(input: $input) {
      ...TaskFieldsFull
    }
  }
  ${TASK_FIELDS_FULL}
`

export const DELETE_TASK = gql`
  mutation deleteTask($input: DeleteTaskInput!) {
    deleteTask(input: $input)
  }
`

export const SUBSCRIBE_TASK = gql`
  subscription task($sheetId: ID!) {
    task(sheet_id: $sheetId) {
      mutation
      data {
        ...TaskFieldsFull
      }
    }
  }
  ${TASK_FIELDS_FULL}
`

export const RESET_TASK = gql`
  mutation resetTask($input: ResetTaskInput!) {
    resetTask(input: $input) {
      ...TaskFieldsFull
    }
  }
  ${TASK_FIELDS_FULL}
`
