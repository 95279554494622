import {
  listWhiteboardPatients as ListWhiteboardPatients,
  listWhiteboardPatients_listWhiteboardPatients_items_due_tasks as DueTasks,
  listWhiteboardPatients_listWhiteboardPatients_items_missed_tasks as MissedTasks,
  listWhiteboardPatients_listWhiteboardPatients_items_tasks as PatientTask,
} from 'types/listWhiteboardPatients'
import { ConsultationStatus } from 'types/globalTypes'
import { Patient } from 'components/PatientItem/PatientListItem'
import { getUsersList_getUsersList_items as GetUser } from 'src/types/getUsersList'

export type TransformedData = {
  items: {
    patient: Patient
    resultCursor: string | null
    missedTasksCount: number
    tasks: PatientTask[]
    dueTasks: DueTasks
    missedTasks: MissedTasks
  }[]
  nextCursor: string
}

export type UserListHash = {
  [userId: string]: GetUser
}

type TransformDataFunction = (
  data?: ListWhiteboardPatients,
  users?: UserListHash,
) => TransformedData

export const transformPatientData: TransformDataFunction = (data, users) => {
  if (!data) {
    return { items: [], nextCursor: '' }
  }

  return {
    items:
      data.listWhiteboardPatients.items?.map(item => {
        const presentingProblems = [
          ...item.patient.presenting_problems,
          { name: item.patient.consultation_description },
        ]
          .filter(p => p.name)
          .map(p => p.name)
          .join(' . ')

        const attending_vet_tech_id = item.patient.attending_vet_tech_id
        const attending_vet_tech_display_name =
          attending_vet_tech_id && users
            ? users[attending_vet_tech_id]?.display_name
            : ''

        return {
          patient: {
            id: item.patient.patient_id,
            name: item.patient.patient_name,
            sex: item.patient.patient_sex,
            species: item.patient.patient_species,
            breed: item.patient.patient_breed,
            attitude: item.patient.patient_attitude,
            avatar_url: item.patient.patient_avatar_url,
            color: item.patient.patient_color,
            order: item.patient.patient_order,
            contact: {
              first_name: item.patient.contact_first_name,
              last_name: item.patient.contact_last_name,
              phone_number: item.patient.contact_phone_number,
            },
            weight: item.patient.patient_weight,
            weight_unit: item.patient.patient_weight_unit,
            date_of_birth: item.patient.patient_date_of_birth, // toIOS?
            resuscitate: item.patient.patient_resuscitate,
            primary_location: {
              location_display: item.patient.patient_location_primary_display,
            },
            critical_notes: item.patient.patient_critical_notes,
            latest_active_sheet_id: item.patient.latest_active_sheet_id,
            presentingProblems,
            attending_vet_display_name: item.patient.attending_vet_display_name,
            attending_vet_id: item.patient.attending_vet_id,
            attending_vet_tech_id,
            attending_vet_tech_display_name,
            consultation_id: item.patient.consultation_id,
            isActive:
              item.patient.consultation_status === ConsultationStatus.admitted,
            consultation_locations: item.patient.consultation_locations,
            consultation_color: item.patient.consultation_color,
            has_unapproved_sheet: item.patient.has_unapproved_sheet,
          } as Patient,
          resultCursor: item.resultCursor,
          missedTasksCount: item.patient.missed_tasks,
          tasks: item.tasks,
          dueTasks: item.due_tasks,
          missedTasks: item.missed_tasks,
        }
      }) ?? [],
    nextCursor: data.listWhiteboardPatients.pageInfo?.nextCursor ?? '',
  }
}

export const generateUserListHash = (users: GetUser[]) => {
  return users.reduce<UserListHash>((acc, user) => {
    acc[user.id] = user
    return acc
  }, {})
}
