import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color?: string
  length?: number
}

export const SvgClose: React.FC<Props> = ({
  color = '#FFFFFF',
  length = 10,
}) => (
  <Svg width={length} height={length} viewBox="0 0 10 10">
    <G transform="translate(-258.000000, -13.000000)" stroke={color}>
      <G transform="translate(258.000000, 13.000000)">
        <Path d="M0.625,0.625 L8.87054443,8.87054443" />
        <Path d="M0.625,8.87054443 L8.87054443,0.625" />
      </G>
    </G>
  </Svg>
)
