import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'

import { useTranslation } from 'react-i18next'
import { CancelButton, ContinueButton } from '../zTEMP-buttons'

export const ResetModalContent: React.FC<{
  resetTrainingSite: () => void
  onClose: () => void
}> = ({ resetTrainingSite, onClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <View style={styles.header}>
        <Text style={styles.title}>{t('training:resetModal:title')}</Text>
      </View>
      <View style={styles.content}>
        <Text>{t('training:resetModal:description')}</Text>
      </View>
      <View style={styles.footer}>
        {/* TODO temp buttons */}
        <ContinueButton
          title={t('training:resetModal:resetButton')}
          onPress={() => {
            resetTrainingSite()
            onClose()
          }}
        />
        <CancelButton
          title={t('training:resetModal:cancelButton')}
          onPress={onClose}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  header: {
    padding: 16,
    borderBottomWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  title: {
    fontSize: Typography.FontSizes.md,
    fontWeight: Typography.FontWeights.bold,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.Backgrounds.primary,
    padding: 16,
    borderTopWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  content: {
    alignItems: 'center',
    padding: 16,
  },
})
