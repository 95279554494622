import { useApolloClient, useMutation } from '@apollo/client'
import { ADD_TEMPLATE_TO_SHEET } from 'src/components/AddTreatment/graphql'
import { GET_TEMPLATE_TREATMENT_IDS } from 'src/components/TreatmentTemplate/graphql'
import { toast } from 'src/components/common'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import {
  addTemplateToSheet as AddTemplateToSheetT,
  addTemplateToSheetVariables,
} from 'src/types/addTemplateToSheet'
import { useUpdateTreatments } from 'src/utils/useUpdateTreatments'
import { useTranslation } from 'react-i18next'
import { startOfMinute } from 'date-fns'
import { evictRootQuery } from './evictRootQuery'
import { useImperativeQuery } from 'src/hooks/useImperativeQuery'
import { getSheet, getSheetVariables } from 'src/types/getSheet'
import {
  GET_SHEET,
  GET_CONDITIONAL_TREATMENT,
} from 'src/components/Sheet/graphql'
import { useConfirm } from 'src/context/confirm'
import {
  getTemplateTreatmentIds,
  getTemplateTreatmentIdsVariables,
} from 'src/types/getTemplateTreatmentIds'
import { getProductDuplicates } from './productDuplicates'
import { filterNull } from './notEmpty'
import {
  getConditionalTreatment,
  getConditionalTreatmentVariables,
} from 'src/types/getConditionalTreatment'
import _ from 'lodash'
import { TemplateTreatmentInput } from 'src/types/globalTypes'

type Props = {
  sheetId: string
  patientId: string
}

type AddTemplateToSheetParams = {
  templateId: string
  startTime: 'now' | Date
  templateTreatmentInput?: TemplateTreatmentInput[]
  shouldSkipIgnore?: boolean
  applyWorkflow?: boolean
}

export const useAddTemplateToSheet = ({ sheetId, patientId }: Props) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const updateTreatments = useUpdateTreatments(sheetId)
  const { fromToQueryDate } = useTimeResolution()

  const client = useApolloClient()

  const [addTemplateToSheet] = useMutation<
    AddTemplateToSheetT,
    addTemplateToSheetVariables
  >(ADD_TEMPLATE_TO_SHEET, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      data.addTemplateToSheet.forEach(updateTreatments)
      toast.success(t('addTreatment:templateAdded'))

      // Update workflow template items
      evictRootQuery(client.cache, /^(getConsultation)/)
    },
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
  })

  const getTemplateTreatmentIds = useImperativeQuery<
    getTemplateTreatmentIds,
    getTemplateTreatmentIdsVariables
  >(GET_TEMPLATE_TREATMENT_IDS)
  const confirm = useConfirm()

  return async ({
    templateId,
    startTime,
    applyWorkflow = false,
    templateTreatmentInput = [],
    shouldSkipIgnore = false,
  }: AddTemplateToSheetParams) => {
    let ignoreDuplication = false
    if (!shouldSkipIgnore) {
      const { data } = await getTemplateTreatmentIds({
        organisationId,
        id: templateId,
      })

      const productIds = filterNull(
        data?.getTemplate?.treatments?.items?.flatMap(t =>
          t.treatments?.items?.map(t => t.product_id),
        ),
      )

      const incommingConditionalTreatments =
        data?.getTemplate?.treatments?.items
          ?.flatMap(t => t.treatments?.items)
          .filter(treatment => !treatment?.product_id)
          .map(conditional => ({
            id: conditional?.id,
            name: conditional?.name,
          }))

      const query = GET_SHEET
      const variables = {
        id: sheetId,
        organisation_id: organisationId,
        ...fromToQueryDate,
      }
      const sheetData = client.readQuery<getSheet, getSheetVariables>({
        query,
        variables,
      })

      const sheetConditionalTreatmentIds =
        sheetData?.getSheet?.treatments?.items
          ?.flatMap(t => t.treatments?.items)
          ?.filter(treatment => !treatment?.product)
          ?.map(treatment => treatment?.id)

      const sheetConditionalTemplateTreatmentIds =
        sheetConditionalTreatmentIds &&
        sheetConditionalTreatmentIds.map(id => {
          if (!id) return null

          const data = client.readQuery<
            getConditionalTreatment,
            getConditionalTreatmentVariables
          >({
            query: GET_CONDITIONAL_TREATMENT,
            variables: {
              patient_id: patientId,
              treatment_id: id,
            },
          })

          return data?.getConditionalTreatment?.template_treatment_id
        })

      const conditionalDuplicates = incommingConditionalTreatments?.filter(
        ({ id }) => {
          if (!id) return false
          return sheetConditionalTemplateTreatmentIds?.includes(id)
        },
      )

      const duplicates = conditionalDuplicates?.concat(
        getProductDuplicates(sheetData, productIds),
      )

      if (duplicates && duplicates.length > 0) {
        try {
          await confirm({
            title: t('addTreatment:addTemplateToSheet:duplicationTitle'),
            text:
              duplicates.length > 1
                ? t('addTreatment:addTemplateToSheet:bulkDuplicationText')
                : t('addTreatment:addTemplateToSheet:duplicationText', {
                    treatmentName: duplicates[0].name,
                  }),
            okText: 'Yes',
            cancelText: 'No',
          })
          ignoreDuplication = false
        } catch (e) {
          ignoreDuplication = true
        }
      }
    }
    const dismiss = toast.process(t('addTreatment:templateAdding'))
    addTemplateToSheet({
      variables: {
        input: {
          organisation_id: organisationId,
          sheet_id: sheetId,
          start_at:
            startTime === 'now'
              ? startOfMinute(new Date()).toISOString()
              : startTime.toISOString(),
          template_id: templateId,
          apply_template_workflow: applyWorkflow,
          ignore_duplication: ignoreDuplication,
        },
        options: {
          template_treatments: templateTreatmentInput,
        },
        ...fromToQueryDate,
      },
    }).finally(dismiss)
    return
  }
}
