import { Theme } from '@aws-amplify/ui-react'
import { Colors } from 'constants/Colors'

export const commonTheme: Partial<Theme> = {
  tokens: {
    borderWidths: {
      small: '2px',
      medium: '4px',
      large: '6px',
    },
    fontWeights: {
      light: 500,
      medium: 600,
      bold: 700,
      normal: 600,
    },
    radii: {
      xs: '2px',
      small: '4px',
      large: '8px',
      xl: '12px',
    },
    colors: {
      brand: {
        primary: {
          '80': 'rgb(237, 99, 83)',
        },
        secondary: {
          '80': Colors.vetRadarGrey,
        },
      },
      border: {
        primary: Colors.lightGrey,
      },
      background: {
        primary: 'rgb(19, 22, 57)',
        secondary: Colors.vetRadarBlue,
        error: Colors.errorBackground,
      },
      font: {
        primary: Colors.white,
        secondary: Colors.white,
        tertiary: Colors.lightGrey2,
        error: Colors.errorText,
      },
    },
  },
}
