import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { CreateSheetScreen } from 'components/CreateSheet/CreateSheetScreen'
import { AddPatientScreen } from 'components/Patient/AddPatientScreen'
import { EditPatientScreen } from 'components/Patient/EditPatientScreen'
import { PatientList } from 'components/Patient/PatientList'
import { StyleSheet, View } from 'react-native'
import { Header } from 'src/components/Header/Header'
import { Sheet } from 'src/components/Sheet/Sheet'
import { SheetList } from 'src/components/SheetList/SheetList'
import { Routes } from 'src/constants/Routes'
import {
  useHeaderSwitch,
  withFullScreenSwitchProvider,
} from 'src/context/fullScreenSwitch'
import { withDestroyOnBlur } from 'src/hocs/withDestroyOnBlur'
import { OrganisationBanner } from 'components/Banners/OrganisationBanner'

export type PatientStackParamList = {
  [Routes.Patients]: undefined
  [Routes.EditPatient]: {
    patientId: string
    sheetId?: string
  }
  [Routes.SheetList]: {
    patientId: string
  }
  [Routes.AddSheet]: {
    patientId: string
  }
  [Routes.Sheet]: {
    initialDateInView?: string
    isFromWhiteboard?: string | boolean
    sheetName?: string
    patientId: string
    sheetId: string
  }
}

export const patientsStackScreensPath = {
  [Routes.Patients]: '',
  [Routes.SheetList]: ':patientId/sheets',
  [Routes.AddSheet]: ':patientId/sheets/add',
  [Routes.Sheet]: ':patientId/sheets/:sheetId',
  [Routes.AddPatient]: 'add',
  [Routes.EditPatient]: ':patientId/edit',
}

const patientStackScreens = {
  [Routes.Patients]: withDestroyOnBlur(PatientList),
  [Routes.SheetList]: withDestroyOnBlur(SheetList),
  [Routes.AddSheet]: withDestroyOnBlur(CreateSheetScreen),
  [Routes.Sheet]: withDestroyOnBlur(Sheet),
  [Routes.AddPatient]: withDestroyOnBlur(AddPatientScreen),
  [Routes.EditPatient]: withDestroyOnBlur(EditPatientScreen),
}

const PatientsStackInit = () => {
  const MyStack = createStackNavigator()
  const { isFullScreen } = useHeaderSwitch()
  return (
    <>
      {!isFullScreen ? <Header /> : <View style={styles.headerReplace} />}
      <OrganisationBanner />
      <MyStack.Navigator screenOptions={{ headerShown: false }}>
        {Object.entries(patientStackScreens).map(([name, component]) => (
          <MyStack.Screen name={name} component={component} key={name} />
        ))}
      </MyStack.Navigator>
    </>
  )
}

// without a height View, the patient list cannot scroll in full screen
const styles = StyleSheet.create({
  headerReplace: { height: 3 },
})

export const PatientsStack = withFullScreenSwitchProvider(PatientsStackInit)
