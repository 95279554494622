import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  DateTimePicker,
  FormBreak,
  FormLabel,
  ImageUploader,
  InputGroup,
  NumericInput,
  Select,
  TextArea,
  TextInput,
  TextLink,
} from 'components/common'
import { Status } from 'components/common/Form/Status'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'
import { ControlledSwitchInput } from 'components/common/SwitchInput/ControlledSwitchInput'
import { ControlledTextInput } from 'components/common/TextInput/ControlledTextInput'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { getPatientIsEquine } from 'components/PatientItem/utils/getPatientSex'
import { ColorPicker } from 'components/shared/ColorPicker'
import { useGetDateFormatPattern } from 'components/Task/utils/useGetStatusLabelText'
import { getWeightLastUpdateTime } from 'components/TreatmentForm/utils/getWeightUpdatedAt'
import { PatientWeightUnitOptions } from 'constants/PatientWeightOptions'
import { subYears } from 'date-fns'
import { TFunction } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Colors } from 'src/constants/Colors'
import { useOrganisation } from 'src/context/organisation'
import { getDepartments_getSites_items } from 'src/types/getDepartments'
import { ControlledSingleSelect } from 'src/design-system/components/Selects/Controlled'
import {
  Attitude,
  ConsultationLocationInput,
  Resuscitation,
} from 'src/types/globalTypes'
import {
  getPatient_getPatient,
  getPatient_getPatient_master_problems,
} from 'types/getPatient'
import * as Yup from 'yup'

import {
  attitudeOptions,
  equineSexOptions,
  resuscitateOptions,
  sexOptions,
} from './data'
import { GET_COLORS } from 'components/Settings/Colors/graphql'
import {
  getColors as GetColors,
  getColorsVariables as GetColorsVariables,
} from 'src/types/getColors'
import { getSpecies, getSpeciesVariables } from 'src/types/getSpecies'
import {
  getBreedsBySpeciesId,
  getBreedsBySpeciesIdVariables,
} from 'src/types/getBreedsBySpeciesId'
import { GET_BREEDS_BY_SPECIES_ID, GET_SPECIES } from './graphql'
import { LocationSelect } from './LocationSelect'
import { isEmpty } from 'lodash'

// Use this value for initial DOB when no value set
const defaultDOBWhenUnset = subYears(new Date(), 3)

const masterProblemStatusOptions = [
  {
    text: 'Active',
    value: true,
  },
  {
    text: 'Inactive',
    value: false,
  },
]

const defaultMasterProblems = Object.freeze({
  condition_active: false,
  condition_critical: false,
  condition: '',
  description: '',
  condition_specifics: '',
})

type MasterProblemsT = Omit<getPatient_getPatient_master_problems, '__typename'>

export type locationType = {
  display: string | null
  wardId: string | null
  roomId: string | null
  enclosure: string | null
}

export const formatLocationKeys = (
  locations: locationType[],
): ConsultationLocationInput[] =>
  locations
    .filter(({ wardId }) => !isEmpty(wardId))
    .map(item => {
      const roomId = item.roomId
      const seperateMark = roomId ? ':' : ''
      const locationKey = `${item.wardId}${seperateMark}${roomId ?? ''}`
      return {
        display: item.display ?? '',
        enclosure: item.enclosure ?? '',
        id: locationKey,
      }
    })

const defaultValues = {
  animal_colour: '',
  attitude: Attitude.UNKNOWN,
  avatar_url: '',
  breedName: '',
  critical_notes: null as (string | undefined)[] | null,
  code: '',
  departments: null as getDepartments_getSites_items[] | null,
  DOB: null as Date | null,
  id: '',
  is_dead: false,
  is_estimated_date_of_birth: false,
  master_problems: null as MasterProblemsT[] | null,
  microchip_number: '',
  name: '',
  notes: '',
  resuscitate: null as Resuscitation | null,
  sex: sexOptions[0].value,
  speciesName: '',
  weight_unit: 'kg',
  weight: null as number | null,
  color: null as string | null,
  order: null as number | null,
  locations: [
    { display: null, wardId: null, roomId: null, enclosure: null },
  ] as locationType[],
}

const getValidationSchema = (t: TFunction) => {
  const requiredMsg = t('form.required')
  return Yup.object().shape({
    name: Yup.string().trim().required(requiredMsg),
    weight: Yup.number().min(0).nullable(),
  })
}

export type Values = typeof defaultValues & {
  weight_updated_at?: string | null
  color_id?: string | null
  origin_species_id?: string | null
  origin_breed_id?: string | null
}

export const patientDataToInput = (values: Values) => ({
  attitude: values.attitude,
  breed: values.breedName
    ? {
        name: values.breedName,
        origin_id: values.origin_breed_id,
        origin_species_id: values.origin_species_id,
      }
    : null,
  name: values.name,
  code: values.code || null,
  critical_notes: values.critical_notes?.filter(note => !!note) as string[],
  date_of_birth: values.DOB ? values.DOB.toISOString() : null,
  is_dead: values.is_dead || false,
  is_estimated_date_of_birth: values.is_estimated_date_of_birth,
  master_problems:
    values.master_problems && values.master_problems.length > 0
      ? values.master_problems
      : null,
  notes: values.notes || null,
  resuscitate: values.resuscitate,
  sex: values.sex || null,
  species: values.speciesName
    ? {
        name: values.speciesName,
        origin_id: values.origin_species_id,
      }
    : null,
  weight: values.weight,
  weight_unit: values.weight_unit,
  ...(values.microchip_number && {
    microchip_number: values.microchip_number,
  }),
  color: values.color,
  color_id: values.color_id,
  order: values.order,
})

export type Props = {
  addMasterProblem?: () => void
  contact?: getPatient_getPatient['contact']
  onSubmit: (data: Values) => void
  onAvatarUpdate?: (avatar: string) => Promise<string | null>
  patient?: Values
  submitting?: boolean
  submitTitle: string
}

export const PatientForm: React.FC<Props> = ({
  contact,
  onSubmit,
  onAvatarUpdate,
  patient,
  submitting = false,
  submitTitle,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const validationSchema = getValidationSchema(t)

  const initialValues = {
    ...defaultValues,
    ...patient,
    weight: patient?.weight ?? 0,
  }
  const isEquine = getPatientIsEquine(patient?.speciesName)
  const { dateFormatPattern } = useGetDateFormatPattern()
  const maxDateOfBirth = new Date()

  const { data: colorsData } = useQuery<GetColors, GetColorsVariables>(
    GET_COLORS,
    {
      variables: {
        getColorsOrganisationId: organisationId,
      },
    },
  )

  const colorOptions = colorsData?.getColors?.items?.map(obj => obj.hex) ?? []

  const { data: speciesData } = useQuery<getSpecies, getSpeciesVariables>(
    GET_SPECIES,
    {
      variables: {
        organisationId,
      },
    },
  )

  const speciesList = speciesData?.getSpecies?.items ?? []
  const speciesNameValuePairs = speciesList.reduce(
    (acc, { name, id, origin_id }) => {
      acc[name] = {
        id,
        origin_id,
      }
      return acc
    },
    {} as {
      [key: string]: {
        id: string
        origin_id: string | null
      }
    },
  )
  const speciesOptions =
    speciesList.map(obj => ({
      value: obj.name,
      text: obj.name,
    })) ?? []

  const { handleSubmit, control, formState, watch, setValue, getValues } =
    useForm({
      mode: 'onChange',
      defaultValues: initialValues,
      resolver: yupResolver(validationSchema),
    })

  const speciesName = watch('speciesName')
  const speciesId = speciesNameValuePairs[speciesName]?.id ?? null
  const { data: breedsData, refetch: refetchBreeds } = useQuery<
    getBreedsBySpeciesId,
    getBreedsBySpeciesIdVariables
  >(GET_BREEDS_BY_SPECIES_ID, {
    variables: {
      speciesId: speciesId!,
      organisationId,
    },
    skip: !speciesId,
  })
  const breedsList = breedsData?.getBreedsBySpeciesId?.items ?? []
  const breedsOptions =
    breedsList.map(obj => ({
      value: obj.name!,
      text: obj.name!,
    })) ?? []

  const handleSubmitWithIds = (data: Values) => {
    const colorList = colorsData?.getColors?.items ?? []
    const colorId = colorList.find(obj => obj.hex === data.color)?.id ?? null
    const origin_species_id =
      speciesNameValuePairs[data.speciesName]?.origin_id ?? null
    const origin_breed_id =
      breedsList.find(obj => obj.name === data.breedName)?.origin_id ?? null
    const values = {
      ...data,
      origin_species_id,
      origin_breed_id,
      color_id: colorId,
    }
    onSubmit(values)
  }

  const { isValid, isDirty, errors, touchedFields } = formState
  const departments = watch('departments')

  useEffect(() => {
    if (!speciesId) return
    refetchBreeds()
    if (!isDirty) return
    setValue('breedName', '')
    // Don't need to watch isDirty's change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speciesId, refetchBreeds, setValue])

  const hasError = !isValid

  const handleAddMasterProblems = () => {
    setValue('master_problems', [
      ...(getValues('master_problems') ?? []),
      { ...defaultMasterProblems }, // Add undefined to end to represent appended empty value
    ])
  }

  const handleRemoveMasterProblems = (idx: number) => {
    setValue(
      'master_problems',
      getValues('master_problems')?.filter((_, index) => index !== idx) ?? [],
    )
  }

  const handleAddLocations = () => {
    setValue('locations', [
      ...(getValues('locations') ?? []),
      {
        display: '',
        wardId: '',
        roomId: '',
        enclosure: '',
      } as locationType,
    ])
  }

  const handleRemoveLocations = (idx: number) => {
    setValue(
      'locations',
      getValues('locations')?.filter((_, index) => index !== idx) ?? [],
    )
  }

  return (
    <KeyboardAwareScrollView>
      <FormBreak />
      {!!patient && (
        <Controller
          control={control}
          name="avatar_url"
          render={({ field: { onChange, value } }) => {
            const objectKeyPrefix = `${organisationId}/patient/${patient?.id}`
            const namePlaceHolder = `${patient?.name} ${contact?.last_name}`
            const onChangeImage = async (value: string) => {
              if (!onAvatarUpdate) return
              const newAvatar = await onAvatarUpdate(value)
              onChange(newAvatar)
            }
            const noImageText = t('patient:view:noImageText')
            return (
              <ImageUploader
                objectKey={value}
                objectKeyPrefix={objectKeyPrefix}
                onChange={onChangeImage}
                placeholder={namePlaceHolder}
                noImageText={noImageText}
              />
            )
          }}
        />
      )}
      <FormBreak />
      <Controller
        control={control}
        name="name"
        render={({ field: { onChange, value } }) => (
          <Status
            status={createErrorStatus(errors.name?.message, touchedFields.name)}
          >
            <TextInput
              accessibilityLabel="Patient Name Text Input"
              label={'Name'}
              onChangeText={onChange}
              value={value}
              required={true}
            />
          </Status>
        )}
      />

      <ControlledSelect
        dialog={false}
        control={control}
        name="sex"
        label={'Sex'}
        options={isEquine ? equineSexOptions : sexOptions}
        allowClear={true}
      />

      <ControlledSelect
        dialog={false}
        control={control}
        name="attitude"
        label={t('patient:view.demeanour')}
        options={attitudeOptions}
        allowClear={false}
      />

      <Controller
        control={control}
        name="color"
        render={({ field: { onChange, value } }) => (
          <ColorPicker
            label={t('patient:view.backgroundColor')}
            options={colorOptions}
            selected={value}
            onChange={onChange}
          />
        )}
      />
      {
        <Controller
          control={control}
          name="order"
          render={({ field: { onChange, value } }) => (
            <NumericInput
              label={t('patient:view.order')}
              onChange={onChange}
              value={value}
              shouldInteger={true}
              maxLength={9}
            />
          )}
        />
      }

      <FormBreak />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => {
          // Combine legacy array critical notes into a single value.
          const handleSaveCritNotes = (val: string) => {
            return onChange([val])
          }
          return (
            <TextArea
              label={t('patient:view.criticalNotes')}
              onChangeText={handleSaveCritNotes}
              value={value?.join('\n') ?? ''}
              visibleLines={4}
            />
          )
        }}
        name="critical_notes"
      />
      <FormBreak />
      <FormLabel text={t('patient:view.master')} />
      <Controller
        control={control}
        name="master_problems"
        render={({ field: { onChange, value } }) => (
          <>
            {(value ?? [])
              .sort((a: MasterProblemsT, b: MasterProblemsT) =>
                a.condition_active! > b.condition_active! ? -1 : 1,
              )
              .map((problem: MasterProblemsT, idx: number) => (
                <View key={idx}>
                  <TextInput
                    label={t('patient:view.condition')}
                    value={problem.condition ?? ''}
                    onChangeText={v => {
                      const newValue = [...value!]
                      newValue[idx].condition = v
                      onChange(newValue)
                    }}
                  />
                  <Select
                    dialog={false}
                    a11yLabel={t('patient:view.condStat')}
                    selected={problem.condition_active}
                    options={masterProblemStatusOptions}
                    onChange={v => {
                      const newValue = [...value!]
                      newValue[idx].condition_active = v
                      onChange(newValue)
                    }}
                  />
                  <TextInput
                    label={t('patient:view.description')}
                    value={problem.description ?? ''}
                    multiline={true}
                    numberOfLines={3}
                    onChangeText={v => {
                      const newValue = [...value!]
                      newValue[idx].description = v
                      onChange(newValue)
                    }}
                  />
                  <TextInput
                    label={t('patient:view.specifics')}
                    value={problem.condition_specifics ?? ''}
                    multiline={true}
                    numberOfLines={3}
                    onChangeText={v => {
                      const newValue = [...value!]
                      newValue[idx].condition_specifics = v
                      onChange(newValue)
                    }}
                  />
                  <TextLink
                    text={t('general.remove')}
                    a11yLabel={`${t('general.remove')} button`}
                    onPress={() => handleRemoveMasterProblems(idx)}
                    containerStyles={styles.removeMasterProblemButton}
                  />
                </View>
              ))}
          </>
        )}
      />
      <TextLink
        text={t('patient:edit.addMasterProblem')}
        a11yLabel={`${t('patient:edit.addMasterProblem')} button`}
        onPress={handleAddMasterProblems}
        containerStyles={styles.addButton}
      />
      <FormLabel text={t('patient:view.DOB')} />
      <Controller
        control={control}
        name="DOB"
        render={({ field: { onChange, value } }) => (
          <DateTimePicker
            defaultValue={defaultDOBWhenUnset}
            label={t('patient:view.DOB')}
            mode="date"
            onChange={onChange}
            maximumDateValue={maxDateOfBirth}
            value={value}
          />
        )}
      />

      <ControlledSwitchInput
        control={control}
        name="is_estimated_date_of_birth"
        label={t('patient:view.estimated')}
      />

      <FormBreak />
      <FormLabel text={t('patient:view.cprStatus')} />
      <ControlledSelect
        dialog={false}
        control={control}
        name="resuscitate"
        label={t('patient:view.cprStatus')}
        options={resuscitateOptions}
        allowClear={true}
      />

      <ControlledSwitchInput
        control={control}
        name="is_dead"
        label={t('patient:view.deceased')}
      />

      <FormLabel text={t('patient:view.appearance')} />
      <ControlledSingleSelect
        control={control}
        name="speciesName"
        label={t('patient:view.species')}
        options={speciesOptions}
        allowClear={true}
      />
      <ControlledSingleSelect
        disabled={!speciesName}
        control={control}
        name="breedName"
        key={speciesId}
        label={t('patient:view.breed')}
        options={breedsOptions}
        allowClear={true}
      />
      {/* <ControlledTextInput
        control={control}
        name="animal_colour"
        label={t('patient:view.color')}
      /> */}
      <Status
        status={createErrorStatus(errors.weight?.message, touchedFields.weight)}
      >
        <InputGroup>
          <Controller
            control={control}
            name="weight"
            render={({ field: { onChange, value } }) => (
              <NumericInput
                label={t('patient:view.weight')}
                onChange={onChange}
                style={styles.flex}
                value={value}
              />
            )}
          />
          <ControlledSelect
            dialog={false}
            control={control}
            name="weight_unit"
            a11yLabel="Weight Unit Select Input"
            options={PatientWeightUnitOptions}
          />
        </InputGroup>
      </Status>
      {!!patient?.weight_updated_at && (
        <TextInput
          accessibilityLabel="Weight last updated"
          disabled={true}
          label={t('patient:view.weight_last_updated')}
          value={
            getWeightLastUpdateTime(
              patient?.weight_updated_at,
              dateFormatPattern,
            ) ?? ''
          }
        />
      )}
      <FormBreak />

      <FormLabel text={t('patient:view.microchip')} />
      <ControlledTextInput
        control={control}
        name="microchip_number"
        label={t('patient:view.microchip')}
      />

      <FormBreak />
      <FormLabel text={t('patient:view.departments')} />
      {!!departments && (
        <TextInput
          accessibilityLabel={'Sheet Integration Departments'}
          disabled={true}
          label={t('patient:view.integrationDepartments')}
          value={departments.map(e => e.name).join(' | ')}
        />
      )}
      <View style={styles.locationTitleHolder}>
        <FormLabel text={t('patient:view.location')} />
        <TextLink
          text={t('patient:edit.addLocation')}
          a11yLabel={`${t('patient:edit.addLocation')} button`}
          onPress={handleAddLocations}
          containerStyles={styles.addLocationBtn}
        />
      </View>
      <Controller
        control={control}
        name="locations"
        render={({ field: { onChange, value } }) => (
          <>
            {(value ?? []).map((location: locationType, idx: number) => {
              return (
                <View key={idx}>
                  {idx === 1 && <FormLabel text={'Additional Locations'} />}
                  <LocationSelect
                    onChange={updateValue => {
                      const newValue = [...value!]
                      newValue[idx] = updateValue
                      onChange(newValue)
                    }}
                    location={location}
                    isEquine={isEquine}
                  />
                  {idx !== 0 && (
                    <TextLink
                      text={t('general.remove')}
                      a11yLabel={`${t('general.remove')} button`}
                      onPress={() => {
                        handleRemoveLocations(idx)
                      }}
                      containerStyles={styles.removeMasterProblemButton}
                    />
                  )}
                </View>
              )
            })}
          </>
        )}
      />

      <Controller
        control={control}
        name="notes"
        render={({ field: { onChange, value } }) => (
          <TextArea
            label={t('patient:view.notes')}
            value={value}
            onChangeText={onChange}
            visibleLines={6}
          />
        )}
      />

      <Button
        onPress={handleSubmit(handleSubmitWithIds)}
        title={submitTitle}
        loading={submitting}
        color={Colors.green}
        style={styles.button}
        disabled={hasError}
      />
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  addButton: {
    marginVertical: 8,
    width: 'auto',
    alignItems: 'center',
    flex: 1,
  },
  button: {
    marginVertical: 16,
  },
  flex: {
    flex: 1,
  },
  removeMasterProblemButton: {
    alignItems: 'flex-end',
    paddingRight: 8,
    paddingBottom: 16,
  },
  addLocationBtn: {
    lineHeight: 19,
    paddingRight: 8,
    paddingTop: 20,
    paddingBottom: 8,
  },
  locationTitleHolder: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
