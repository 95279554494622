import { gql } from '@apollo/client'

export const GET_TASK_PREVIEW = gql`
  query getTaskPreview($input: ListTaskPreviewItemsByPatientInput!) {
    listTaskPreviewItemsByPatient(input: $input) {
      treatment_groups {
        title
        order
        data {
          title
          data {
            id
            treatment_name
            start_at
            is_fluid
            status
            treatment_is_pending_approval
            medicine_dosage_info {
              route_of_administration
              total_dosage
              dosage_weight_unit
              dosage_patient_weight_unit
              dosage
              total_volume
              total_volume_unit
              concentration
              concentration_weight_unit
              concentration_volume_unit
              dose_rate
              dose_rate_time_unit
              previous_dose_rate
            }
            fluid_dosage_info {
              route_of_administration
              total_result
              total_result_unit
              patient_weight_unit
              fluid_action_type
              previous_total_result
            }
          }
        }
      }
      count
    }
  }
`
