import React, { useMemo } from 'react'
import { Modal } from 'components/common/Modal'
import { Pressable, StyleProp, ViewStyle } from 'react-native'
import { environment } from 'src/config'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { preventClickPropagation } from 'src/utils/preventClickPropagation'

type Props = {
  children: React.ReactElement
  visible: boolean
  onClose: () => void
  topPosition: number
}

const HISTORY_MODAL_CONTENT_WIDTH_BREAK_POINT = 550
const SMALL_SCREEN_HISTORY_MODAL_CONTENT_WIDTH = '80%'
const LARGE_SCREEN_HISTORY_MODAL_CONTENT_WIDTH = 440
const LARGE_WEB_SCREEN_LEFT_GUTTER_SPACE = 220
const LARGE_WEB_SCREEN_DEFAULT_LEFT_POSITION = 181

export const SheetActionsModalLayout: React.FC<Props> = ({
  children,
  visible,
  onClose,
  topPosition,
}) => {
  const { width, isLargeScreen } = useBreakpoint()

  const contentStyle: StyleProp<ViewStyle> = useMemo(() => {
    const contentWidth =
      width < HISTORY_MODAL_CONTENT_WIDTH_BREAK_POINT
        ? SMALL_SCREEN_HISTORY_MODAL_CONTENT_WIDTH
        : LARGE_SCREEN_HISTORY_MODAL_CONTENT_WIDTH

    if (environment.isWeb && isLargeScreen) {
      return {
        width: contentWidth,
        top: topPosition,
        left: LARGE_WEB_SCREEN_DEFAULT_LEFT_POSITION,
        transform: [
          {
            translateX: LARGE_WEB_SCREEN_LEFT_GUTTER_SPACE,
          },
        ],
        borderRadius: 8,
      }
    }
    return {
      width: contentWidth,
    }
  }, [isLargeScreen, width, topPosition])

  return (
    <Modal visible={visible} onCloseModal={onClose} contentStyle={contentStyle}>
      <Pressable onPress={preventClickPropagation}>{children}</Pressable>
    </Modal>
  )
}
