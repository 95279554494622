import { useTimeResolution } from 'src/hocs/timeContext'
import { useApolloClient, useMutation } from '@apollo/client'
import { UPDATE_TREATMENT_APPROVAL_STATUS } from 'components/Treatment/graphql'
import { ApprovalStatus } from 'types/globalTypes'
import {
  updateTreatmentApproval,
  updateTreatmentApprovalVariables,
} from 'types/updateTreatmentApproval'
import { TreatmentBaseWithTasks } from 'types/TreatmentBaseWithTasks'
import { buildTreatmentWithTasksFragmentVariable } from 'components/Treatment/utils/buildTreatmentWithTasksFragmentVariable'

export const useUpdateTreatmentApprovalStatus = () => {
  const client = useApolloClient()
  const { fromToQueryDate } = useTimeResolution()

  const [updateTreatmentApproval, { loading }] = useMutation<
    updateTreatmentApproval,
    updateTreatmentApprovalVariables
  >(UPDATE_TREATMENT_APPROVAL_STATUS)

  const updateTreatmentApprovalStatus = (
    treatmentId: string,
    patientId: string,
    updatedApprovalStatus: ApprovalStatus,
  ) =>
    updateTreatmentApproval({
      variables: {
        input: {
          id: treatmentId,
          patient_id: patientId,
          approval_status: updatedApprovalStatus,
        },
      },
      update: (_, { data }) => {
        const updateTreatmentApprovalResponse = data?.updateTreatmentApproval

        if (!updateTreatmentApprovalResponse) return

        const fragmentVariable = buildTreatmentWithTasksFragmentVariable(
          updateTreatmentApprovalResponse.id,
          fromToQueryDate,
        )

        const cachedTreatment =
          client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

        if (!cachedTreatment) return

        client.writeFragment({
          ...fragmentVariable,
          data: {
            ...cachedTreatment,
            approval_status: updateTreatmentApprovalResponse.approval_status,
          },
        })
      },
    })

  return {
    updateTreatmentApprovalStatus,
    loading,
  }
}
