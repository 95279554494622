import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import { HistoryModalLayout } from '../HistoryModalLayout'
import { SheetHistoryModalHeader } from 'components/UserHistory/SheetHistory/SheetHistoryModalHeader'
import { SheetHistoryBody } from 'components/UserHistory/SheetHistory/SheetHistoryBody'
import { useSheetHistory } from 'src/hooks/useSheetHistory'
import { environment } from 'src/config'
import { BottomSheetScrollView } from '@gorhom/bottom-sheet'
import { useUser } from 'src/context/user'
import { useOrganisation } from 'src/context/organisation'

type Props = {
  visible: boolean
  onClose?: () => void
}

export const SheetHistoryContainer: React.FC<Props> = ({
  visible,
  onClose = () => {},
}) => {
  const { user } = useUser()
  const [{ organisationId }] = useOrganisation()
  const { getSheetHistory } = useSheetHistory(visible, user?.id, organisationId)

  return (
    <RenderWrapper visible={visible} onClose={onClose}>
      {getSheetHistory ? (
        <SheetHistoryBody
          data={getSheetHistory.getSheetHistory}
          organisationId={organisationId}
          onClose={onClose}
        />
      ) : null}
    </RenderWrapper>
  )
}

const RenderWrapper: React.FC<{
  visible: boolean
  onClose: () => void
  children: React.ReactElement | null
}> = ({ visible, onClose, children }) => {
  return environment.isWeb ? (
    <HistoryModalLayout visible={visible} onClose={onClose}>
      <>
        <SheetHistoryModalHeader />
        <ScrollView style={styles.modalScrollView}>{children}</ScrollView>
      </>
    </HistoryModalLayout>
  ) : (
    <BottomSheetScrollView contentContainerStyle={styles.bottomSheetScrollView}>
      {children}
    </BottomSheetScrollView>
  )
}

const styles = StyleSheet.create({
  modalScrollView: {
    minHeight: 100,
  },
  bottomSheetScrollView: {
    backgroundColor: 'white',
  },
})
