import React from 'react'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { View, Text, TextStyle } from 'react-native'
import { PatientHeaderStyles as styles } from './PatientHeaderStyles'

type BreedTextProps = {
  speciesName?: string | null
  breedName?: string | null
  style?: TextStyle
}

export const BreedText = ({
  speciesName,
  breedName,
  style,
}: BreedTextProps) => {
  const species = speciesName ?? ''
  const breed = breedName ?? ''
  const separator = breedName ? ' • ' : ''
  return (
    <View style={styles.breedTextHolder}>
      <TextWithTooltip
        numberOfLines={5}
        title={`${species + separator + breed}`}
      >
        <Text style={[styles.infoText, styles.italicText, style]}>
          {`${species} ${separator} ${breed}`}
        </Text>
      </TextWithTooltip>
    </View>
  )
}
