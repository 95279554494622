import React, { useEffect } from 'react'
import { SvgArrow } from 'components/Icons'
import { View, Text, StyleSheet } from 'react-native'
import { useSheetContext } from 'src/context/sheet'
import { Colors, Typography } from 'src/design-system/theme'
import { useSeparationSites } from 'src/hooks/useSeparationSites'
import { Checkbox } from 'src/design-system/components/CheckBox/Checkbox'
import { useTranslation } from 'react-i18next'
import { BundleProduct } from 'src/types/BundleProduct'
import { Pill } from 'src/components/common/Pill'
import { BundleTreatmentFormInput } from './AddBundleForm'
import { TickForCubexBilling } from 'components/Icons/TickForCubexBilling'
import { ProductRow } from 'components/common/ProductRow/components'

type Props = {
  product: BundleProduct
  index: number
  update: (index: number, value: BundleTreatmentFormInput) => void
  value: BundleTreatmentFormInput
  isDuplicated?: boolean
  setProductSetup: (index: number) => void
}

export const BundleProductRow: React.FC<Props> = React.memo(
  ({ product, index, value, isDuplicated, update, setProductSetup }) => {
    const { t } = useTranslation()
    const { isWithinBusinessSeparation } = useSeparationSites(
      product?.sites ?? [],
    )
    const [sheetContext] = useSheetContext()

    const isValidSite = isWithinBusinessSeparation(
      sheetContext.sheetAttendingDepartment ?? '',
    )

    useEffect(() => {
      if (!isValidSite && value.shouldCreateTreatment) {
        update(index, {
          ...value,
          shouldCreateTreatment: false,
          isInvalidSite: true,
        })
      }
    }, [index, isValidSite, update, value])

    const toggleSelected = () =>
      update(index, {
        ...value,
        shouldCreateTreatment: !value.shouldCreateTreatment,
      })

    if (!isValidSite) {
      return (
        <ProductRow.Container disabled>
          <Checkbox checked={value.shouldCreateTreatment} disabled />
          <View style={styles.flex}>
            <Text style={[styles.heading, styles.disabled]} numberOfLines={1}>
              {product.name}
            </Text>
            <ProductRow.Warning>
              {t('addTreatment:businessUnitWarning')}
            </ProductRow.Warning>
          </View>
        </ProductRow.Container>
      )
    }

    if (product.is_bundle) {
      return (
        <ProductRow.Container onPress={toggleSelected}>
          <Checkbox
            checked={value.shouldCreateTreatment}
            onPress={toggleSelected}
          />
          <View style={styles.flex}>
            <Text style={styles.heading} numberOfLines={1}>
              {product.name}
            </Text>
            {isDuplicated ? (
              <ProductRow.Warning>
                {t('addTreatment:duplicateBundle')}
              </ProductRow.Warning>
            ) : null}
          </View>
        </ProductRow.Container>
      )
    }

    return (
      <ProductRow.Container onPress={() => setProductSetup(index)}>
        <Checkbox
          checked={value.shouldCreateTreatment}
          onPress={toggleSelected}
        />
        <View style={styles.flex}>
          <Text style={styles.heading} numberOfLines={1}>
            {product.name}
          </Text>
          {isDuplicated ? (
            <ProductRow.Warning>
              {t('addTreatment:duplicateTreatment')}
            </ProductRow.Warning>
          ) : null}
        </View>
        <View style={styles.rowEndSection}>
          {value.createTreatmentFromInput?.isSetup ? (
            <Pill
              icon={<TickForCubexBilling />}
              color={Colors.Backgrounds.positive}
              textStyle={{ color: Colors.Contents.positiveOnColor }}
            />
          ) : (
            <Pill
              size="small"
              text={t('template:treatment.setup')}
              color={Colors.Backgrounds.overlay}
            />
          )}
          <SvgArrow orient="right" color="grey" width={15} height={15} />
        </View>
      </ProductRow.Container>
    )
  },
)

const styles = StyleSheet.create({
  heading: {
    fontFamily: Typography.FontFamilies.base,
    fontSize: Typography.FontSizes.md,
  },
  flex: { flex: 1 },
  warning: {
    flexShrink: 1,
    color: Colors.Contents.warning,
    fontFamily: Typography.FontFamilies.italic,
    fontSize: Typography.FontSizes.base,
  },
  disabled: {
    color: Colors.Interactive.disabledPrimary,
  },
  rowEndSection: {
    marginLeft: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
})
