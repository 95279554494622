import { useCallback, useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import { OnScrollLayout } from './types'

const WIDE_SCROLL_AREA_PIXEL_DIFFERENCE = 1040

export const useGridLayout = () => {
  // fullGrid(Width|Height) are the sizes of grid *content*
  const [fullGridHeight, setFullGridHeight] = useState(
    // start with window on init, keeps vertical grid lines long
    Dimensions.get('window').height,
  )

  const [fullGridWidth, setFullGridWidth] = useState(1)
  const [gridScrollWidthDifference, setGridScrollWidthDifference] = useState(0)
  const [visibleGridHeight, setVisibleGridHeight] = useState(0)
  const [visibleGridWidth, setVisibleGridWidth] = useState(0)

  const scrollIndicatorSize =
    fullGridWidth > visibleGridWidth
      ? (visibleGridWidth * visibleGridWidth) / fullGridWidth
      : visibleGridWidth

  const scrollableWidth =
    visibleGridWidth > scrollIndicatorSize
      ? visibleGridWidth - scrollIndicatorSize
      : 1

  useEffect(() => {
    setGridScrollWidthDifference(fullGridWidth - visibleGridWidth)
  }, [visibleGridWidth, fullGridWidth])

  const onScrollLayout: OnScrollLayout = useCallback(event => {
    setVisibleGridWidth(event.nativeEvent.layout.width)
    setVisibleGridHeight(event.nativeEvent.layout.height)
  }, [])

  const onGridContentSizeChange = useCallback(
    (width: number, height: number) => {
      setFullGridWidth(width)
      setFullGridHeight(height)
    },
    [],
  )

  const hasScrollableContent =
    gridScrollWidthDifference > WIDE_SCROLL_AREA_PIXEL_DIFFERENCE

  const visibleToCompleteWidthRatio = visibleGridWidth / fullGridWidth

  return {
    fullGridHeight,
    fullGridWidth,
    hasScrollableContent,
    onGridContentSizeChange,
    onScrollLayout,
    scrollableWidth,
    scrollIndicatorSize,
    visibleGridHeight,
    visibleGridWidth,
    visibleToCompleteWidthRatio,
  }
}
