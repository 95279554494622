import React, { useState } from 'react'

import { Layout, toast } from 'components/common'
import { getProductIsHealthStatusWithField } from 'components/Task/utils/getProductInfo'
import { omit } from 'lodash'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import {
  perWeightUnitOptions,
  weightUnitOptions,
} from 'src/components/TreatmentForm/data'
import { Routes } from 'src/constants/Routes'
import { useOrganisation } from 'src/context/organisation'
import {
  deleteProduct as DeleteProduct,
  deleteProductVariables,
} from 'src/types/deleteProduct'
import { getProduct, getProductVariables } from 'types/getProduct'
import { ProductType, UpdateProductInput } from 'types/globalTypes'
import {
  updateProduct as UpdateProduct,
  updateProductVariables,
} from 'types/updateProduct'

import { SubHeader } from '../SubHeader/SubHeader'
import { CustomProductForm, CustomValueT, Inputs } from './CustomProductForm'
import {
  DELETE_CUSTOM_PRODUCT,
  GET_CUSTOM_PRODUCT,
  UPDATE_CUSTOM_PRODUCT,
} from './graphql'
import { SettingsStackParamList } from 'components/Settings/screens'

import { checkIsModifiableProduct } from 'src/utils/checkIsModifiableProduct'
import { cache } from 'src/apollo/cache'
import { generateProductPimsMapping } from 'components/Settings/utils/generateProductPimsMapping'

// TODO: remove once properly validate inputs
// Fix for missing validation check - #VR-4114
export const checkCustomValueHasKey = (
  customValue: CustomValueT,
): CustomValueT => ({
  key: customValue.key || 'custom_value',
  value: customValue.value,
})

type Props = StackScreenProps<SettingsStackParamList, Routes.EditCustomProduct>

export const EditCustomProductScreen: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { navigate } = navigation
  const [isModifiable, setIsModifiable] = useState<boolean>(false)
  const productId = route.params.productId

  const backButton = {
    title: 'settings:products.title',
    label: 'settings:products.returnTo',
    action: () => navigate(Routes.CustomProductList),
  }

  const queryVariables = {
    id: productId,
    organisation_id: organisationId,
  }
  const { data, loading } = useQuery<getProduct, getProductVariables>(
    GET_CUSTOM_PRODUCT,
    {
      variables: queryVariables,
      fetchPolicy: 'network-only',
      onError: err => {
        toast.error(err.message)
      },
      onCompleted: data =>
        setIsModifiable(checkIsModifiableProduct(data?.getProduct.origin_id)),
    },
  )
  const product = data?.getProduct
  const isProductHealthStatus = getProductIsHealthStatusWithField(product)
  const [deleteProduct] = useMutation<DeleteProduct, deleteProductVariables>(
    DELETE_CUSTOM_PRODUCT,
    {
      variables: queryVariables,
      onError: err => {
        toast.error(err.message)
      },
      onCompleted: () => {
        cache.evict({ id: `Product:${product?.id}` })
        cache.evict({ id: `ProductSearchHybridItem:${product?.id}` })
        toast.success(t('form.deleted', { itemName: product?.name }))
      },
    },
  )

  const [updateProduct, { loading: submitting }] = useMutation<
    UpdateProduct,
    updateProductVariables
  >(UPDATE_CUSTOM_PRODUCT, {
    onCompleted: () =>
      toast.success(t('form.updated', { itemName: product?.name })),
  })

  const updateProductAndNavBack = async (updatedProduct: Inputs) => {
    const newMedicine = updatedProduct.medicine
      ? {
          ...updatedProduct.medicine,
          dosage: updatedProduct.medicine.dosage,
          concentration: updatedProduct.medicine.concentration,
          diluted_concentration: updatedProduct.medicine.diluted_concentration,
          // These should be allowed to be empty
          concentration_volume_unit:
            updatedProduct.medicine.concentration_volume_unit || 'kg',
          concentration_weight_unit:
            updatedProduct.medicine.concentration_weight_unit || 'mg',
          diluted_concentration_volume_unit:
            updatedProduct.medicine.diluted_concentration_volume_unit || 'l',
          diluted_concentration_weight_unit:
            updatedProduct.medicine.diluted_concentration_weight_unit || 'mg',
        }
      : {}

    const input: UpdateProductInput = {
      id: productId,
      name: updatedProduct.name,
      type: updatedProduct.type as ProductType,
      organisation_id: organisationId,
      medicine_dosage_info:
        updatedProduct.type === ProductType.MEDICATION
          ? omit(newMedicine, ['dosageWeightUnit', 'dosagePerWeightUnit'])
          : {},
      description: updatedProduct.description,
      custom_values:
        updatedProduct.custom_values?.map(checkCustomValueHasKey) ?? null,
      parent_product_id: updatedProduct.parent_product_id ?? null,
      is_value_required: updatedProduct.isValueRequired,
      is_notes_required: updatedProduct.isNotesRequired,
      pims_mapping: generateProductPimsMapping(
        updatedProduct.customPimsMapping,
        product,
      ),
    }
    await updateProduct({ variables: { input } })
    navigate(Routes.CustomProductList)
  }

  const deleteProductAndNavBack = async () => {
    try {
      await deleteProduct()
      navigate(Routes.CustomProductList)
    } catch (err) {
      if (err instanceof Error) toast.error(err.message)
    }
  }

  const renderCustomProductForm = () => {
    if (!product) {
      return null
    }
    const productForm = {
      description: product.description || '',
      medicine: {
        is_diluted: !!product.medicine_dosage_info?.is_diluted,
        route_of_administration:
          product.medicine_dosage_info?.route_of_administration || '',
        calculate_base: product.medicine_dosage_info?.calculate_base || '',
        dosage: product.medicine_dosage_info?.dosage ?? null,
        dosage_weight_unit:
          product.medicine_dosage_info?.dosage_weight_unit || '',
        dosage_patient_weight_unit:
          product.medicine_dosage_info?.dosage_patient_weight_unit || '',
        concentration: product.medicine_dosage_info?.concentration ?? null,
        concentration_weight_unit:
          product.medicine_dosage_info?.concentration_weight_unit || '',
        concentration_volume_unit:
          product.medicine_dosage_info?.concentration_volume_unit || '',
        diluted_concentration:
          product.medicine_dosage_info?.diluted_concentration ?? null,
        diluted_concentration_weight_unit:
          product.medicine_dosage_info?.diluted_concentration_weight_unit || '',
        diluted_concentration_volume_unit:
          product.medicine_dosage_info?.diluted_concentration_volume_unit || '',
        dosageWeightUnit: weightUnitOptions[1].value,
        dosagePerWeightUnit: perWeightUnitOptions[0].value,
      },
      isValueRequired: product.is_value_required ?? false,
      isNotesRequired: product.is_notes_required ?? false,
      name: product.name,
      pimsMapping: product.pims_mapping,
      customPimsMapping: product?.pims_mapping?.[0].dest ?? null,
      type: (product.type?.toUpperCase() ||
        ProductType.STANDARD) as ProductType,
      custom_values:
        product.custom_values?.map(val => ({
          key: val.key,
          value: val.value,
        })) ?? [],
      parent_product_id: product.parent_product_id ?? null,
      origin_id: product.origin_id ?? null,
    }
    return (
      <CustomProductForm
        onSubmit={updateProductAndNavBack}
        onDelete={deleteProductAndNavBack}
        submitting={submitting}
        submitTitle={t('general.saveChanges')}
        product={productForm}
        isCustomProduct={!product.origin_id}
        isProductHealthStatus={isProductHealthStatus}
        isModifiable={isModifiable}
      />
    )
  }
  const getSubheaderLabel = () => {
    return loading
      ? `${t('settings:editProduct.title')}`
      : `${t('settings:editProduct.title')} ${product?.name}`
  }

  return (
    <>
      <SubHeader headline={getSubheaderLabel()} backButton={backButton} />
      <Layout mode="single-center">
        {loading ? (
          <ActivityIndicator size="large" style={styles.marginTop} />
        ) : (
          renderCustomProductForm()
        )}
      </Layout>
    </>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25,
  },
})
