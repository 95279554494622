import React, { useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'
import { Dialog } from 'components/common/Dialog/Dialog'
import { ListItem, OnPressLink } from 'components/common/ListItem'
import { SearchInput } from 'components/common/SearchInput'
import { searchInList } from 'src/utils/searchInList'
import { Colors } from 'src/design-system/theme'

type SearchableDialogProps = {
  title: string
  items: { text: string; value: string }[]
  searchField: string
  onSelect: (item: OnPressLink) => void
  toggleDialog: () => void
  visible: boolean
}

export const SearchableDialog: React.FC<SearchableDialogProps> = ({
  title,
  items,
  searchField,
  onSelect,
  toggleDialog,
  visible,
}) => {
  const [searchText, setSearchText] = useState('')

  const filteredItems = useMemo(
    () => searchInList(items, searchField, searchText),
    [items, searchText, searchField],
  )

  return (
    <Dialog
      dialogHeight="90%"
      showDone={false}
      title={title}
      toggleDialog={toggleDialog}
      onCancel={toggleDialog}
      visible={visible}
    >
      <SearchInput
        style={styles.searchStyle}
        onChangeText={setSearchText}
        showClearButton={false}
      />
      {filteredItems?.map(item => (
        <ListItem
          key={item.value}
          id={item.value}
          name={item.text}
          onPress={onSelect}
        />
      ))}
    </Dialog>
  )
}

const styles = StyleSheet.create({
  searchStyle: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.Borders.primary,
    height: 49,
    marginTop: 8,
  },
})
