import { SheetActionContainer } from 'components/SheetActions/SheetActionContainer'
import { SheetActionsModalLayout } from 'components/SheetActions/SheetActionModalLayout'
import React from 'react'
import { View, StyleSheet, LayoutChangeEvent } from 'react-native'
import { Patient } from '../PatientListItem'
import { Colors } from 'src/design-system/theme'

type SheetActionsProps = {
  isActionsVisible: boolean
  onActionsClose: () => void
  actionsPosition: number
  patient: Patient
  showDischargeModal: (value: boolean) => void
  showFinalisedSheets: (value: boolean) => void
  showImageUploader: (value: boolean) => void
  onActionsLayout: (event: LayoutChangeEvent) => void
}

export const SheetActions: React.FC<SheetActionsProps> = ({
  isActionsVisible,
  onActionsClose,
  actionsPosition: topPosition,
  patient,
  showDischargeModal: setShowDischargeModal,
  showFinalisedSheets: setShowFinalisedSheetsModal,
  showImageUploader: setShowImageUploader,
  onActionsLayout,
}) => {
  return (
    <SheetActionsModalLayout
      visible={isActionsVisible}
      onClose={onActionsClose}
      topPosition={topPosition}
    >
      <View style={styles.sheetActionsModal} onLayout={onActionsLayout}>
        <SheetActionContainer
          patient={patient}
          consultationId={patient.consultation_id}
          locations={patient.consultation_locations}
          currentColor={patient.consultation_color}
          currentCPRStatus={patient.resuscitate}
          onClose={onActionsClose}
          handleDischarge={() => setShowDischargeModal(true)}
          handleFinalisedSheet={() => setShowFinalisedSheetsModal(true)}
          handlePatientImage={() => setShowImageUploader(true)}
        />
      </View>
    </SheetActionsModalLayout>
  )
}

const styles = StyleSheet.create({
  sheetActionsModal: {
    backgroundColor: Colors.Backgrounds.UI,
  },
})
