import { ListItem } from 'components/common/ListItem'
import { RowButton } from 'components/SubHeader/SubHeader'
import React, { useMemo } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { Colors } from 'constants/Colors'
import { NestedOptionLevels, SubMenuProps } from './types'
import { orientToDegree, SvgArrow } from 'components/Icons'

export const PrimaryLevelMenu: React.FC<SubMenuProps> = ({
  menuOptions,
  actions,
  containerStyle,
  listItemStyle,
  state,
  selected,
  liftedFirstOption,
}) => {
  const containsSecondaryLevelOptions = menuOptions.some(
    option => option.nestedOptionLevel === NestedOptionLevels.CHILD,
  )

  const getSvgArrowIcon = (
    orient: keyof typeof orientToDegree,
    color: string,
  ) => <SvgArrow orient={orient} color={color} width={15} height={15} />

  const backIcon = useMemo(
    () => getSvgArrowIcon('left', Colors.buttons.blue),
    [],
  )

  return (
    <View style={containerStyle}>
      {containsSecondaryLevelOptions && !state.hasOnlyDepartments ? (
        <View style={styles.menuHeader}>
          <RowButton
            testID="BackButton"
            title=" Back"
            label="Go back to top level menu"
            onPress={() => {
              actions.setSecondaryMenuParentId(null)
            }}
            icon={backIcon}
            colour={Colors.buttons.blue}
          />
          <Text style={styles.menuHeaderText}>
            {state.secondaryMenuHeaderTitle}
          </Text>
          <View />
        </View>
      ) : null}
      {menuOptions.map((listItem, idx) => {
        const isSelected = containsSecondaryLevelOptions
          ? selected?.includes(listItem.value)
          : state.activeParentSelector?.value === listItem.value
        return (
          <React.Fragment key={idx + listItem.value}>
            <ListItem
              id={listItem.value}
              style={listItemStyle}
              name={listItem.text ?? ''}
              displayArrow={listItem.hasChildren}
              displayCheck={isSelected}
              onPress={() => actions.handlePrimaryMenuSelection(listItem)}
            />
            {liftedFirstOption && idx === 0 ? (
              <View style={styles.separator} />
            ) : null}
          </React.Fragment>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  menuHeaderText: {
    fontSize: 18,
    fontWeight: '600',
  },
  menuHeader: {
    // @ts-ignore
    boxSizing: 'border-box',
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 8,
    height: 36,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.borderGrey,
  },
})
