import { DischargePatient } from 'components/DischargePatient/DischargePatient'
import { Dialog } from 'components/common/Dialog/Dialog.web'
import React from 'react'

type DischargePatientDialogProps = {
  patientId: string
  showDischargeModal: boolean
  closeDischargeModal: () => void
  onDischarge: (patientName: string, dischargeNotes: string) => void
}

export const DischargePatientDialog: React.FC<DischargePatientDialogProps> = ({
  patientId,
  showDischargeModal,
  closeDischargeModal,
  onDischarge,
}) => {
  return (
    <Dialog
      title={'Discharge patient'}
      visible={showDischargeModal}
      toggleDialog={closeDischargeModal}
      showDone={false}
    >
      <DischargePatient
        showTitle={false}
        patientId={patientId}
        onDischarge={onDischarge}
      />
    </Dialog>
  )
}
