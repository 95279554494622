import { useQuery } from '@apollo/client'
import { GET_CALL_PARAMETER_TEMPLATES_WITH_DETAIL } from 'components/CallParameterTemplate/graphql'
import { LocationSelect } from 'components/Patient/LocationSelect'
import { locationType } from 'components/Patient/PatientForm'
import { resuscitateOptions } from 'components/Patient/data'
import { getPatientIsEquine } from 'components/PatientItem/utils/getPatientSex'
import { isTemplateCallParamsModified } from 'components/PatientPanel/Cards/CallParameters'
import { GET_COLORS } from 'components/Settings/Colors/graphql'
import { useGetDateFormatPattern } from 'components/Task/utils/useGetStatusLabelText'
import { getWeightLastUpdateTime } from 'components/TreatmentForm/utils/getWeightUpdatedAt'
import {
  Button,
  FormBreak,
  FormLabel,
  InputGroup,
  NumericInput,
  TextArea,
  TextInput,
  TextLink,
} from 'components/common'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'
import { ColorPicker } from 'components/shared/ColorPicker'
import { PatientWeightUnitOptions } from 'constants/PatientWeightOptions'
import { Routes } from 'constants/Routes'
import { isNil } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useOrganisation } from 'src/context/organisation'
import {
  getColors as GetColors,
  getColorsVariables as GetColorsVariables,
} from 'src/types/getColors'
import {
  getPatient_getPatient,
  getPatient_getPatient_call_parameters,
} from 'src/types/getPatient'
import { getTemplateCallParameter_getTemplateCallParameter as TemplateCallParameters } from 'src/types/getTemplateCallParameter'
import {
  getTemplateCallParametersWithDetail,
  getTemplateCallParametersWithDetailVariables,
} from 'src/types/getTemplateCallParametersWithDetail'
import { Resuscitation } from 'src/types/globalTypes'
import { CallParameterInput } from './CallParameterInput'
import { Colors } from 'src/design-system/theme/colors'
import { compareCallParamMinAndMax } from 'src/utils/compareCallParamMinAndMax'

type Props = {
  navigateTo: (route: string) => void
  patient: getPatient_getPatient | null | undefined
  onSubmit: (data: Values) => void
  onClose: () => void
}

export type CallParameter = {
  name: string
  min: number | null
  max: number | null
  short_name?: string | null
  product_id?: string | null
}

export type Inputs = {
  critical_notes: { value: string }[]
  additional_notes: string
  call_parameters: CallParameter[]
  location_display: string | null
  color: string | null
  order: number | null
  template_call_parameter_id?: string | null
  weight: number | null
  weight_unit: string | null
  weight_updated_at: string | null
  locations: locationType[]
  resuscitate: Resuscitation | null
}

export type Values = Inputs & {
  color_id: string | null
}

const textInputCheck = (input: string | null | undefined) => {
  if (!isNil(input)) return input
  return ''
}

export const defaultTemplate: CallParameter[] = [
  {
    name: 'Heart Rate',
    short_name: 'HR',
    min: null,
    max: null,
  },
  {
    name: 'Respiratory Rate',
    short_name: 'RR',
    min: null,
    max: null,
  },
  {
    name: 'Temperature',
    short_name: 'TEMP',
    min: null,
    max: null,
  },
  {
    name: 'Systolic Arterial Pressure',
    short_name: 'SAP',
    min: null,
    max: null,
  },
  {
    name: 'Mean Arterial Pressure',
    short_name: 'MAP',
    min: null,
    max: null,
  },
  {
    name: 'Diastolic Arterial Pressure',
    short_name: 'DAP',
    min: null,
    max: null,
  },
]

const MODIFIED_TEMPLATE_KEY = '-MODIFIED'

const oldDefaultTemplateNames = ['HR', 'RR', 'TEMP', 'PCV', 'TP', 'GLU']
// todo: unit test
// old callParameters which does not have product_id and use short name as name will be mapped to new default template
export const mapDefaultOldTemplateToNew = (
  callParameters: getPatient_getPatient_call_parameters[] | null,
) => {
  if (!callParameters) {
    return defaultTemplate
  }
  const isActiveOrNewCallParameters = callParameters.some((cp, idx) => {
    // new version
    if (cp.product_id) {
      return true
    }

    // name is not equal to old default name
    if (cp.name !== oldDefaultTemplateNames[idx]) {
      return true
    }
    return false
  })
  if (isActiveOrNewCallParameters) {
    return callParameters
  }
  return callParameters.map((cp, idx) => ({
    ...cp,
    name: defaultTemplate[idx].name,
    short_name: defaultTemplate[idx].short_name,
  }))
}

export const ParameterEntry: React.FC<Props> = ({
  onClose,
  navigateTo,
  patient,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const [isButtonDisabled] = useState(false)
  const { dateFormatPattern } = useGetDateFormatPattern()

  const defaultValues = useMemo(() => {
    const patientInputs: Inputs = {
      critical_notes: [],
      additional_notes: patient?.notes ?? '',
      call_parameters: [],
      location_display: null,
      color: null,
      order: null,
      template_call_parameter_id: null,
      weight: null,
      weight_unit: null,
      weight_updated_at: null,
      locations: [
        { display: null, wardId: null, roomId: null, enclosure: null },
      ],
      resuscitate: null as Resuscitation | null,
    }

    if (!patient) {
      return patientInputs
    }
    const locations =
      patient?.active_consultations?.items?.[0]?.locations?.map(item => {
        const locationKey = item.key.split(':')
        const wardId = locationKey[0] ?? ''
        const roomId = locationKey[1] ?? ''
        return {
          wardId,
          roomId,
          display: item.display,
          enclosure: item.enclosure,
        }
      }) ?? []

    patientInputs.color =
      patient.active_consultations?.items?.[0]?.color?.hex ?? null

    patientInputs.locations = locations

    patientInputs.critical_notes =
      patient.critical_notes?.map(note => ({
        value: note,
      })) ?? []

    patientInputs.template_call_parameter_id =
      patient.template_call_parameter_id

    const patientCallParameters =
      patient.template_call_parameter_id && patient.call_parameters
        ? patient.call_parameters
        : mapDefaultOldTemplateToNew(patient.call_parameters)
    patientInputs.call_parameters =
      patientCallParameters.map(obj => ({
        max: obj.max,
        min: obj.min,
        name: obj.name,
        short_name: obj.short_name,
        product_id: obj.product_id,
      })) ?? []

    patientInputs.order = patient.order
    patientInputs.weight = patient.weight ?? 0
    patientInputs.weight_unit = patient.weight_unit
    patientInputs.weight_updated_at = patient.weight_updated_at
    patientInputs.resuscitate = patient.resuscitate
    return patientInputs
  }, [patient])

  const { control, handleSubmit, setValue, getValues } = useForm<Inputs>({
    defaultValues,
  })

  const { fields: parameterFields, update } = useFieldArray({
    control,
    name: 'call_parameters',
  })

  const isEquine = getPatientIsEquine(patient?.species?.name)

  const [{ organisationId }] = useOrganisation()

  const { data: callParameterTemplates, loading: isLoadingTemplates } =
    useQuery<
      getTemplateCallParametersWithDetail,
      getTemplateCallParametersWithDetailVariables
    >(GET_CALL_PARAMETER_TEMPLATES_WITH_DETAIL, {
      variables: {
        organisation_id: organisationId,
      },
    })

  const { data: colorsData } = useQuery<GetColors, GetColorsVariables>(
    GET_COLORS,
    {
      variables: {
        getColorsOrganisationId: organisationId,
      },
    },
  )

  const colorOptions = colorsData?.getColors?.items?.map(obj => obj.hex) ?? []

  const handleSubmitWithModifiedValues = (data: Inputs) => {
    const isCallParamInRange = compareCallParamMinAndMax(data.call_parameters)
    if (!isCallParamInRange) return
    // Removed MODIFIED_TEMPLATE_KEY from custom option for modified templates
    const templateCallParameterId = data.template_call_parameter_id
      ? data.template_call_parameter_id?.replace(MODIFIED_TEMPLATE_KEY, '')
      : null

    const colorList = colorsData?.getColors?.items ?? []
    const colorId = colorList.find(obj => obj.hex === data.color)?.id ?? null
    const values = {
      ...data,
      color_id: colorId,
      template_call_parameter_id: templateCallParameterId,
    }
    onSubmit(values)
  }

  const templateOptions = useMemo(
    () =>
      callParameterTemplates?.getTemplateCallParameters?.items
        ?.filter(template => !template.disabled)
        .map(template => ({
          text: template.name,
          value: template.id,
        })) ?? [],
    [callParameterTemplates?.getTemplateCallParameters?.items],
  )

  useEffect(() => {
    if (patient?.template_call_parameter_id) {
      const templateCallParameters =
        callParameterTemplates?.getTemplateCallParameters?.items?.find(
          template => template.id === patient.template_call_parameter_id,
        )

      if (templateCallParameters === undefined) {
        return
      }

      const modifiedLabel = isTemplateCallParamsModified(
        patient.call_parameters,
        templateCallParameters as TemplateCallParameters,
      )

      if (!modifiedLabel) {
        return
      }

      const modifiedName = `${templateCallParameters.name} ${modifiedLabel}`

      // Add MODIFIED_TEMPLATE_KEY to custom option for modified templates, will be removed on submit
      const modifiedTemplateId = `${patient.template_call_parameter_id}${MODIFIED_TEMPLATE_KEY}`

      // Update the template options with the modified template and set as value
      templateOptions.unshift({
        text: modifiedName,
        value: modifiedTemplateId,
      })
      setValue('template_call_parameter_id', modifiedTemplateId)
    }
  }, [
    callParameterTemplates?.getTemplateCallParameters?.items,
    patient?.call_parameters,
    patient?.template_call_parameter_id,
    setValue,
    templateOptions,
  ])

  const applyTemplateToCallParameters = (templateId: string | null) => {
    if (!templateId) {
      defaultTemplate?.map((field, idx) => update(idx, field))
      setValue('call_parameters', defaultTemplate)
      return
    }

    // If templateId contains MODIFIED_TEMPLATE_KEY then we are applying the patient's current call parameters
    if (templateId.includes(MODIFIED_TEMPLATE_KEY)) {
      setValue(
        'call_parameters',
        (patient?.call_parameters ?? []).map(cp => ({
          max: cp.max,
          min: cp.min,
          name: cp.name,
          short_name: cp.short_name,
          product_id: cp.product_id,
        })) ?? [],
      )
      return
    }

    const selectedTemplate =
      callParameterTemplates?.getTemplateCallParameters?.items?.find(
        template => template.id === templateId,
      )

    const overwriteParameters =
      selectedTemplate?.call_parameter_items?.items?.map(obj => ({
        max: obj.max,
        min: obj.min,
        name: obj.name,
        short_name: obj.short_name,
        product_id: obj.product_id,
      }))

    if (!overwriteParameters) {
      defaultTemplate?.map((field, idx) => update(idx, field))
      setValue('call_parameters', defaultTemplate)
    } else {
      overwriteParameters?.map((field, idx) => update(idx, field))
      setValue('call_parameters', overwriteParameters)
    }
  }

  const handleAddLocations = () => {
    setValue('locations', [
      ...(getValues('locations') ?? []),
      {
        display: '',
        wardId: '',
        roomId: '',
        enclosure: '',
      } as locationType,
    ])
  }

  const handleRemoveLocations = (idx: number) => {
    setValue(
      'locations',
      getValues('locations')?.filter((_, index) => index !== idx) ?? [],
    )
  }

  return (
    <>
      <KeyboardAwareScrollView
        style={styles.scrollContainer}
        enableResetScrollToCoords={false}
        extraHeight={-64}
        keyboardOpeningTime={0}
      >
        {/* Nav */}
        <View style={styles.navigationButtonHolder}>
          <TouchableOpacity
            onPress={() => {
              navigateTo(Routes.SheetList)
              onClose()
            }}
          >
            <Text>{t('sheetHeader:dashboard')}</Text>
          </TouchableOpacity>
          <View style={styles.separatedView} />
          <TouchableOpacity
            onPress={() => {
              navigateTo(Routes.EditPatient)
              onClose()
            }}
            accessibilityLabel={t('patient:view.viewProfile')}
          >
            <Text>{t('sheetHeader:fullProfile')}</Text>
          </TouchableOpacity>
        </View>

        {/* PID */}
        <View style={styles.pidContainer}>
          <Text>{`PID ${patient?.code}`}</Text>
        </View>

        {/* Crit Notes */}
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => {
            // Combine legacy array critical notes into a single value.
            const handleSaveCritNotes = (val: string) => {
              return onChange([{ value: val }])
            }
            return (
              <TextArea
                label={t('patient:view.criticalNotes')}
                onChangeText={handleSaveCritNotes}
                value={textInputCheck(value?.map(n => n?.value).join('\n'))}
                visibleLines={4}
              />
            )
          }}
          name="critical_notes"
        />

        <FormBreak />

        {/* Color Picker */}
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <ColorPicker
              label={t('patient:view.backgroundColor')}
              options={colorOptions}
              selected={value}
              onChange={onChange}
            />
          )}
          name="color"
        />

        {/* Order */}
        <Controller
          control={control}
          name="order"
          render={({ field: { onChange, value } }) => (
            <NumericInput
              label={t('patient:view.order')}
              onChange={onChange}
              value={value}
              shouldInteger={true}
              maxLength={9}
            />
          )}
        />

        <FormBreak />

        {/* Weight */}
        <InputGroup>
          <Controller
            control={control}
            name="weight"
            render={({ field: { onChange, value } }) => (
              <NumericInput
                label={t('patient:view.weight')}
                onChange={onChange}
                style={styles.flex}
                value={value}
              />
            )}
          />
          <ControlledSelect
            dialog={false}
            control={control}
            name="weight_unit"
            a11yLabel="Weight Unit Select Input"
            options={PatientWeightUnitOptions}
          />
        </InputGroup>
        {!!patient?.weight_updated_at && (
          <TextInput
            accessibilityLabel="Weight last updated"
            disabled={true}
            label={t('patient:view.weight_last_updated')}
            value={
              getWeightLastUpdateTime(
                patient?.weight_updated_at,
                dateFormatPattern,
              ) ?? ''
            }
          />
        )}

        {/* Resuscitation Status */}
        <FormBreak />
        <FormLabel text={t('patient:view.cprStatus')} />
        <ControlledSelect
          dialog={false}
          control={control}
          name="resuscitate"
          label={t('patient:view.cprStatus')}
          options={resuscitateOptions}
          allowClear={true}
        />

        {/* Locations */}
        <View style={styles.locationTitleHolder}>
          <FormLabel text={t('patient:view.location')} />
          <TextLink
            text={t('patient:edit.addLocation')}
            a11yLabel={`${t('patient:edit.addLocation')} button`}
            onPress={handleAddLocations}
            containerStyles={styles.addLocationBtn}
            color={Colors.Contents.accent}
          />
        </View>
        <Controller
          control={control}
          name="locations"
          render={({ field: { onChange, value } }) => (
            <>
              {(value ?? []).map((location: locationType, idx: number) => {
                return (
                  <View key={idx}>
                    {idx === 1 && <FormLabel text={'Additional Locations'} />}
                    <LocationSelect
                      onChange={updateValue => {
                        const newValue = [...value!]
                        newValue[idx] = updateValue
                        onChange(newValue)
                      }}
                      location={location}
                      isEquine={isEquine}
                    />
                    {idx !== 0 && (
                      <TextLink
                        text={t('general.remove')}
                        a11yLabel={`${t('general.remove')} button`}
                        onPress={() => {
                          handleRemoveLocations(idx)
                        }}
                        containerStyles={styles.removeMasterProblemButton}
                      />
                    )}
                  </View>
                )
              })}
            </>
          )}
        />

        <FormLabel text={t('sheetHeader:drawer.callParameter')} />

        {/* Call Parameter Template */}
        <>
          <ControlledSelect
            dialog={false}
            control={control}
            a11yLabel={'Call Parameter Template'}
            allowClear={true}
            label={'Template'}
            loading={isLoadingTemplates}
            options={templateOptions ?? []}
            onChangeListener={applyTemplateToCallParameters}
            name="template_call_parameter_id"
          />
          <FormBreak />
        </>

        {parameterFields?.map((field, idx) => (
          <View key={field.id}>
            <Controller
              control={control}
              defaultValue={field}
              name={`call_parameters.${idx}`}
              render={({ field: { onChange, value } }) => (
                <CallParameterInput
                  label={value.name}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </View>
        ))}

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextArea
              label={t('sheetHeader:notes')}
              onChangeText={onChange}
              value={textInputCheck(value)}
              visibleLines={4}
            />
          )}
          name="additional_notes"
        />
      </KeyboardAwareScrollView>
      <View style={styles.footer}>
        <Button
          disabled={isButtonDisabled}
          onPress={handleSubmit(handleSubmitWithModifiedValues)}
          testID="SaveSheetButton"
          title={t('general.saveChanges')}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  navigationButtonHolder: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  pidContainer: {
    paddingLeft: 16,
  },
  scrollContainer: {
    height: 'auto',
  },
  separatedView: {
    marginHorizontal: 10,
    width: 1,
    backgroundColor: Colors.Contents.tertiary,
  },
  footer: {
    paddingVertical: 15,
  },
  removeMasterProblemButton: {
    alignItems: 'flex-end',
    paddingRight: 8,
    paddingBottom: 16,
  },
  addLocationBtn: {
    lineHeight: 19,
    paddingRight: 8,
    paddingTop: 20,
    paddingBottom: 8,
  },
  locationTitleHolder: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
