import { isNil } from 'lodash'
import { TreatmentSheet } from '../common/types'

const unitsNeedToConvert = ['l']

// avoid unreadable rate like 11l/hr, instead converting to 11L/hr
const convertToReadableUnitLabel = (unit: string | null | undefined) => {
  if (isNil(unit)) return
  return unitsNeedToConvert.includes(unit) ? unit.toUpperCase() : unit
}

export const getCRIDoseRate = (treatment: TreatmentSheet) => {
  const doseRate = treatment.medicine_dosage_info?.dose_rate

  if (isNil(doseRate)) return null

  const dosageWeightUnit = treatment.medicine_dosage_info?.dosage_weight_unit
  const perWeightUnit =
    treatment.medicine_dosage_info?.dosage_patient_weight_unit
  const timeUnit =
    treatment.medicine_dosage_info?.dose_rate_time_unit ??
    treatment.medicine_dosage_info?.infusion_rate_time_unit

  return `${doseRate}${dosageWeightUnit}/${perWeightUnit}/${getCRITimeUnit(
    timeUnit,
  )}`
}

export const getCRIInfusionRate = (treatment: TreatmentSheet) => {
  const infusionRate = treatment.medicine_dosage_info?.infusion_rate_total

  if (isNil(infusionRate)) {
    return null
  }

  const unit = convertToReadableUnitLabel(
    treatment.medicine_dosage_info?.infusion_rate_volume_unit,
  )
  const timeUnit = treatment.medicine_dosage_info?.infusion_rate_time_unit

  return `${infusionRate}${unit}/${getCRITimeUnit(timeUnit)}`
}

export const getTotalDosage = (treatment: TreatmentSheet) => {
  const totalDosage = treatment.medicine_dosage_info?.total_dosage
  if (isNil(totalDosage)) {
    return null
  }
  const dosageWeightUnit = treatment?.medicine_dosage_info?.dosage_weight_unit
  return `${totalDosage} ${dosageWeightUnit}`
}

export const getCRITotalDosage = (treatment: TreatmentSheet) => {
  const dosage = treatment.medicine_dosage_info?.dosage
  if (isNil(dosage)) return null
  const unit = treatment?.medicine_dosage_info?.dosage_weight_unit
  const timeUnit =
    treatment.medicine_dosage_info?.dose_rate_time_unit ??
    treatment.medicine_dosage_info?.infusion_rate_time_unit

  return `${dosage}${unit}/${getCRITimeUnit(timeUnit)}`
}

export const getCRITimeUnit = (timeUnit: string | null | undefined) => {
  if (!timeUnit) return null
  return timeUnit === 'hour' ? 'hr' : timeUnit
}

export const getCRIMedicationVolumeRate = (treatment: TreatmentSheet) => {
  const medicationVolume = treatment.medicine_dosage_info?.medication_volume
  if (!medicationVolume) return null
  const medicationVolumeUnit = convertToReadableUnitLabel(
    treatment.medicine_dosage_info?.medication_volume_unit,
  )

  return `${medicationVolume}${medicationVolumeUnit}`
}

export const getCRIFinalVolumeRate = (treatment: TreatmentSheet) => {
  const finalVolume = treatment.medicine_dosage_info?.iv_bag_size
  if (isNil(finalVolume)) return null
  const finalVolumeUnit = convertToReadableUnitLabel(
    treatment.medicine_dosage_info?.iv_bag_size_unit,
  )
  return `${finalVolume}${finalVolumeUnit}`
}
