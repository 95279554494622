import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Routes } from 'constants/Routes'
import { StackScreenProps } from '@react-navigation/stack'
import { useOrganisation } from 'src/context/organisation'

import { toast } from '../common'
import { SubHeader } from '../SubHeader/SubHeader'
import { CREATE_WORKFLOW_TEMPLATE_TEMPLATE } from './graphql'
import { Inputs, WorkflowTemplateForm } from './WorkflowTemplateForm'
import { SettingsStackParamList } from 'components/Settings/screens'
import { useTranslation } from 'react-i18next'
import { createTemplateWorkflow } from 'types/createTemplateWorkflow'

type Props = StackScreenProps<SettingsStackParamList>

const useCreateWorkflowTemplateMutation = (
  onComplete: (formData: createTemplateWorkflow) => void,
) => {
  const [createWorkflowTemplate] = useMutation(
    CREATE_WORKFLOW_TEMPLATE_TEMPLATE,
    {
      onError: err => {
        toast.error(err.message)
      },
      onCompleted: onComplete,
    },
  )
  return createWorkflowTemplate
}

export const AddWorkflowTemplateScreen: React.FC<Props> = ({ navigation }) => {
  const [{ organisationId }] = useOrganisation()
  const [submitLoading, setSubmitLoading] = useState(false)
  const { navigate } = navigation
  const { t } = useTranslation()

  const handleBack = () => navigate(Routes.WorkflowTemplateList)

  const backButton = {
    title: 'templates.workflowTitle',
    label: 'templates.returnHereLabel',
    action: handleBack,
  }

  const onComplete = (formData: createTemplateWorkflow): void => {
    toast.success(
      t('template:create.success', {
        name: formData.createTemplateWorkflow.name,
      }),
    )

    handleBack()
  }

  const createWorkflowTemplate = useCreateWorkflowTemplateMutation(onComplete)

  const onSubmit = async (formData: Inputs) => {
    setSubmitLoading(true)
    const workflowItems =
      formData.items
        ?.filter(item => item.name)
        .map((item, idx) => ({
          organisation_id: organisationId,
          order: idx,
          name: item.name,
        })) ?? []

    const input = {
      organisation_id: organisationId,
      name: formData.name,
      disabled: formData.disabled,
      sites: formData.sites,
      user_id: formData.user_id,
      workflow_items: workflowItems,
    }

    await createWorkflowTemplate({
      variables: { input },
    })
  }

  return (
    <>
      <SubHeader headline="Add Workflow Template" backButton={backButton} />
      <WorkflowTemplateForm
        handleSubmit={onSubmit}
        submitting={submitLoading}
      />
    </>
  )
}
