import { toast } from 'components/common'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PanResponder, View } from 'react-native'
import { usePermission } from 'src/hooks/usePermission'
import { PermissionAction } from 'src/types/globalTypes'

export const withPermission = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return (
    props: P & {
      disabled?: boolean
      permissionAction: PermissionAction | null
    },
  ) => {
    const { t } = useTranslation()
    const { disabled, permissionAction, ...restProps } = props
    const hasPermission = usePermission(permissionAction)

    const panResponse = useMemo(() => {
      return PanResponder.create({
        onStartShouldSetPanResponderCapture: () => {
          if (!hasPermission) {
            toast.noPermissionWarning(t(`permission:noPermissionWarning`))
          }
          return false
        },
      })
    }, [hasPermission, t])

    return (
      <View testID="permission-hoc" {...panResponse.panHandlers}>
        <WrappedComponent
          {...(restProps as P)} // Avoid adding new props to the wrapped component
          disabled={!!disabled || !hasPermission}
        />
      </View>
    )
  }
}
