import { gql } from '@apollo/client'

export const WORKFLOW_TEMPLATE_FIELDS = gql`
  fragment workflowTemplateFields on TemplateWorkflow {
    id
    name
    disabled
    user {
      id
      full_name
    }
    sites {
      id
      name
    }
  }
`

export const GET_WORKFLOW_TEMPLATES = gql`
  query getTemplateWorkflows($organisation_id: String!) {
    getTemplateWorkflows(organisation_id: $organisation_id) {
      items {
        ...workflowTemplateFields
      }
    }
  }
  ${WORKFLOW_TEMPLATE_FIELDS}
`

export const CREATE_WORKFLOW_TEMPLATE_TEMPLATE = gql`
  mutation createTemplateWorkflow($input: CreateTemplateWorkflowInput!) {
    createTemplateWorkflow(input: $input) {
      id
      name
      disabled
    }
  }
`

export const COPY_WORKFLOW_TEMPLATE = gql`
  mutation copyTemplateWorkflow($input: CopyTemplateWorkflowInput!) {
    copyTemplateWorkflow(input: $input) {
      id
      name
      user {
        id
        full_name
      }
      sites {
        id
        name
      }
      disabled
    }
  }
`

export const GET_WORKFLOW_TEMPLATE = gql`
  query getTemplateWorkflow($id: ID!, $organisationId: String!) {
    getTemplateWorkflow(id: $id, organisation_id: $organisationId) {
      ...workflowTemplateFields
      workflow_items {
        items {
          id
          name
          order
        }
      }
    }
  }
  ${WORKFLOW_TEMPLATE_FIELDS}
`

export const GET_TEMPLATES_BY_WORKFLOW = gql`
  query getTemplatesByTemplateWorkflow(
    $organisation_id: ID!
    $templateWorkflowId: ID!
    $pageInput: PageInput
  ) {
    getTemplatesByTemplateWorkflow(
      organisation_id: $organisation_id
      template_workflow_id: $templateWorkflowId
      pageInput: $pageInput
    ) {
      items {
        id
        name
        template_workflow_id
      }
    }
  }
`

export const UPDATE_WORKFLOW_TEMPLATE = gql`
  mutation updateTemplateWorkflow($input: UpdateTemplateWorkflowInput!) {
    updateTemplateWorkflow(input: $input) {
      ...workflowTemplateFields
    }
  }
  ${WORKFLOW_TEMPLATE_FIELDS}
`

export const DELETE_WORKFLOW_TEMPLATE = gql`
  mutation deleteTemplateWorkflow($input: DeleteTemplateWorkflowInput!) {
    deleteTemplateWorkflow(input: $input)
  }
`
