import { IconButtonWithText } from 'components/IconButtonWithText'
import { SvgArrow } from 'components/Icons'
import { Colors } from 'constants/Colors'
import React from 'react'
import { Animated, StyleSheet } from 'react-native'
import { Fonts } from 'constants/Fonts'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useTranslation } from 'react-i18next'
import { PATIENT_INFO_WIDTH_WEB } from 'components/PatientPanel/Drawer'
import { usePatientPanelDrawerContext } from 'src/context/patientPanelDrawer'

type Props = {
  showScrollToTop: boolean
  action: () => void
}

const icon = (
  <SvgArrow color={Colors.sheet.title.blue} width={16} orient={'up'} />
)

export const ScrollToTop: React.FC<Props> = ({ showScrollToTop, action }) => {
  const [{ shouldShowDrawer }] = usePatientPanelDrawerContext()
  const { t } = useTranslation()
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const smallScreen = isExSmallScreen || isSmallScreen
  return (
    <Animated.View
      style={[
        styles.container,
        shouldShowDrawer && styles.withPatientPanel,
        !showScrollToTop && styles.hidden,
      ]}
    >
      <IconButtonWithText
        action={action}
        label={t('sheet:scrollToTop.label')}
        buttonStyle={smallScreen ? styles.smallButtonStyle : styles.buttonStyle}
        textStyle={styles.textStyle}
        iconViewStyle={
          smallScreen ? styles.smallIconViewStyle : styles.iconViewStyle
        }
        icon={icon}
        hideText={smallScreen}
        title={t('sheet:scrollToTop.title')}
      />
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    backgroundColor: Colors.white,
  },
  withPatientPanel: {
    right: 20 + PATIENT_INFO_WIDTH_WEB,
  },
  hidden: {
    display: 'none',
  },
  buttonStyle: {
    width: 147,
    borderRadius: 4,
  },
  smallButtonStyle: {
    width: 50,
    paddingVertical: 4,
    paddingHorizontal: 18,
  },
  textStyle: {
    fontFamily: Fonts.bold,
    color: Colors.sheet.title.blue,
    fontSize: 14,
  },
  iconViewStyle: {
    marginRight: 12,
  },
  smallIconViewStyle: {
    marginRight: 0,
  },
})
