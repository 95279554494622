import React, { useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { Layout, toast } from 'components/common'
import { PatientStackParamList } from 'components/Patient/screens'
import { getPatientIsEquine } from 'components/PatientItem/utils/getPatientSex'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { equineSexToTagTitle } from 'components/Tag/utils/patientToTagTitle'
import { findWeightUnit } from 'constants/PatientWeightOptions'
import { Routes } from 'constants/Routes'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import { Attitude, UpdateConsultationInput } from 'src/types/globalTypes'
import {
  updatePatient as UpdatePatient,
  updatePatientVariables as UpdatePatientVariables,
} from 'src/types/updatePatient'
import { evictRootQuery } from 'src/utils/evictRootQuery'
import {
  getPatient,
  getPatient_getPatient_master_problems,
  getPatientVariables,
} from 'types/getPatient'

import { GET_PATIENT, UPDATE_PATIENT } from './graphql'
import {
  formatLocationKeys,
  patientDataToInput,
  PatientForm,
  Values,
} from './PatientForm'
import { getPatientDepartments } from './utils/getPatientDepartments'
import { useUpdateConsultation } from './useUpdateConsultation'

export const PATIENT_LIST_CACHE_REXP =
  /^(listWhiteboardPatients|listWhiteboardWorkflows)/

const returnNonNullProblems = (
  problems: Omit<getPatient_getPatient_master_problems, '__typename'>[] | null,
) => {
  if (!problems) {
    return null
  }
  return problems.map(problem => ({
    condition_active: problem.condition_active ?? false,
    condition_critical: problem.condition_critical ?? false,
    condition_specifics: problem.condition_specifics ?? '',
    condition: problem.condition ?? '',
    description: problem.description ?? '',
  }))
}

type EditPatientScreenRouteProp = RouteProp<
  PatientStackParamList,
  Routes.EditPatient
>

export const EditPatientScreen: React.FC = () => {
  const { t } = useTranslation()
  const [{ organisationId, organisationSites }] = useOrganisation()
  const { navigate } = useNavigation()
  const { params } = useRoute<EditPatientScreenRouteProp>()
  const { patientId, sheetId } = params
  const updateConsultation = useUpdateConsultation(patientId)

  const { data } = useQuery<getPatient, getPatientVariables>(GET_PATIENT, {
    variables: {
      organisationId,
      id: patientId,
    },
  })
  const patient = data?.getPatient
  const isEquine = getPatientIsEquine(patient?.species?.name)

  const patientDepartments = getPatientDepartments(
    patient?.active_consultations?.items,
    organisationSites,
  )
  const consultationId = patient?.active_consultations?.items?.[0]?.id
  const locations = useMemo(
    () =>
      patient?.active_consultations?.items?.[0]?.locations?.map(item => {
        const locationKey = item.key.split(':')
        const wardId = locationKey[0] ?? ''
        const roomId = locationKey[1] ?? ''
        return {
          wardId,
          roomId,
          display: item.display,
          enclosure: item.enclosure,
        }
      }) ?? [],
    [patient?.active_consultations?.items],
  )

  const consultationColor =
    patient?.active_consultations?.items?.[0]?.color?.hex
  const consultationColorId =
    patient?.active_consultations?.items?.[0]?.color?.id

  const [updatePatient, { loading: submitting, client }] = useMutation<
    UpdatePatient,
    UpdatePatientVariables
  >(UPDATE_PATIENT)

  const backLabel = sheetId
    ? 'patient:edit:backToSheet'
    : 'patient:edit:backToList'

  const handleAvatarUpdate = async (avatar: string) => {
    try {
      const response = await updatePatient({
        variables: {
          input: {
            id: patientId,
            organisation_id: organisationId,
            avatar_url: avatar,
          },
        },
      })
      toast.success(t('Profile photo updated'))
      return response.data?.updatePatient.avatar_url ?? null
    } catch (error) {
      if (error instanceof Error) toast.error(error.message)
      throw error
    }
  }

  const handleSubmit = async (values: Values) => {
    // Update actived consultation
    const newLocations = formatLocationKeys(values.locations)

    const input = {
      id: patient?.id!,
      organisation_id: organisationId,
      primary_location: {
        location_id: newLocations[0]?.id ?? null,
        location_display: newLocations[0]?.display ?? null,
        enclosure: newLocations[0]?.enclosure ?? null,
      },
      ...patientDataToInput(values),
    } as UpdatePatientVariables['input']

    const consultationInput = {
      id: consultationId,
      organisation_id: organisationId,
      patient_id: patient?.id!,
      locations: newLocations,
      color_id: values.color_id,
    } as UpdateConsultationInput

    if (
      patient?.weight === input.weight &&
      patient?.weight_unit === input.weight_unit
    ) {
      delete input.weight
      delete input.weight_unit
    }
    try {
      await updatePatient({ variables: { input } })
      await updateConsultation(consultationInput)
      evictRootQuery(client.cache, PATIENT_LIST_CACHE_REXP, { delay: 3000 })
      toast.success(t('form.updated', { itemName: values.name }))
    } catch (error) {
      if (error instanceof Error) toast.error(error.message)
    }

    !sheetId
      ? navigate(Routes.SheetList, {
          patientId,
        })
      : navigate(Routes.Sheet, {
          patientId,
          sheetId,
          initialDateInView: undefined,
        })
  }

  const backButton = {
    title: backLabel,
    label: backLabel,
    action: !sheetId
      ? () =>
          navigate(Routes.SheetList, {
            patientId,
          })
      : () =>
          navigate(Routes.Sheet, {
            patientId,
            sheetId,
            initialDateInView: undefined,
          }),
  }

  const renderPatientForm = () => {
    if (!patient) {
      return null
    }

    const defaultLocatons = [
      {
        display: '',
        wardId: '',
        roomId: '',
        enclosure: null,
      },
    ]

    const patientFormData = {
      color: consultationColor ?? patient.color,
      color_id: consultationColorId ?? null,

      locations: locations.length === 0 ? defaultLocatons : locations, // new locations list in here
      animal_colour: patient.animal_colour ?? '',
      attitude: patient.attitude ?? Attitude.UNKNOWN,
      avatar_url: patient.avatar_url ?? '',
      breedName: patient.breed?.name ?? '',
      code: patient.code ?? '',
      departments: patientDepartments,
      critical_notes: patient.critical_notes,
      DOB: patient.date_of_birth ? new Date(patient.date_of_birth) : null,
      id: patient.id,
      is_dead: patient.is_dead ?? false,
      is_estimated_date_of_birth: !!patient.is_estimated_date_of_birth,
      master_problems: returnNonNullProblems(patient.master_problems),
      microchip_number: patient.microchip_number ?? '',
      name: patient.name!,
      notes: patient.notes ?? '',
      resuscitate: patient.resuscitate ?? null,
      sex: isEquine
        ? equineSexToTagTitle(patient.sex, patient.date_of_birth)
        : patient.sex ?? '',
      speciesName: patient.species?.name ?? '',
      weight_unit: findWeightUnit(patient.weight_unit),
      weight: patient.weight,
      weight_updated_at: patient.weight_updated_at,
      order: patient.order,
    }

    return (
      <PatientForm
        contact={patient.contact}
        onSubmit={handleSubmit}
        onAvatarUpdate={handleAvatarUpdate}
        patient={patientFormData}
        submitting={submitting}
        submitTitle={t('general.saveChanges')}
      />
    )
  }

  return (
    <>
      <SubHeader headlineKey="patient:view.profile" backButton={backButton} />
      <Layout mode="single-center">{renderPatientForm()}</Layout>
    </>
  )
}
