/* eslint-disable no-continue */
import { utcToZonedTime } from 'date-fns-tz'
import { isEmpty, isNil, sortBy } from 'lodash'
import { Platform } from 'react-native'
import {
  getSettings_getOrganisation_staffed_hours,
  getSettings_getOrganisation_staffed_hours_MON,
  getSettings_getOrganisation_staffed_hours_MON_hours,
} from 'src/types/getSettings'
const isIOS = Platform.OS !== 'web'

const Weekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
const hourInMinutes = 60
const dayInMinutes = hourInMinutes * 24
const weekInMinutes = dayInMinutes * 7
const invalidateHourSpanInMinutes = 5

const getHourMinuteNumberFromDate = (dateString: string) => {
  const date = new Date(dateString)
  const dateHourNumber = date.getUTCHours()
  const dateMinuteNumber = date.getUTCMinutes()
  return dateHourNumber * hourInMinutes + dateMinuteNumber
}

type HourNumber = {
  startNumber: number
  endNumber: number
}

/*
   hours can have conflict
   1, get number from start and end for each staff hour, 1970-01-01T08:30:00.000Z is 8 hours and 30 minutes = 60 * 8 + 30
   2, sort hours
   3, filter staff hour, if start is bigger than end, or too close.
   4, merge hours 
      For example
      {
        start: '1970-01-01T08:00:00.000Z',
        end: '1970-01-01T12:00:00.000Z',
      },
      {
        start: '1970-01-01T12:30:00.000Z',
        end: '1970-01-01T18:00:00.000Z',
      },
      {
        start: '1970-01-01T09:30:00.000Z',
        end: '1970-01-01T16:30:00.000Z',
      }
      Will be merged into 8:00-18:00 
      And return start&end numbers.
*/
const mergeOverlappingAndGetHoursToNumber = (
  hours?: getSettings_getOrganisation_staffed_hours_MON_hours[],
) => {
  if (!hours) {
    return []
  }
  const sortedHours = sortBy(
    hours
      .map(hour => ({
        startNumber: getHourMinuteNumberFromDate(hour.start),
        endNumber: getHourMinuteNumberFromDate(hour.end),
      }))
      .filter(
        hourNumber =>
          hourNumber.endNumber - hourNumber.startNumber >=
          invalidateHourSpanInMinutes,
      ),
    ['startNumber'],
  )

  if (!sortedHours.length) {
    return []
  }

  const hourNumbers: HourNumber[] = []
  let currentHour = sortedHours[0]
  for (let i = 1; i < sortedHours.length; i = i + 1) {
    // if current end time is bigger or equal then next start time, merge conflict
    if (sortedHours[i].startNumber <= currentHour.endNumber) {
      currentHour = {
        startNumber: currentHour.startNumber,
        endNumber: Math.max(currentHour.endNumber, sortedHours[i].endNumber),
      }

      continue
    }
    // if no conflict, push current
    hourNumbers.push(currentHour)
    currentHour = sortedHours[i]
  }
  hourNumbers.push(currentHour)
  return hourNumbers
}

/*
  For each day's staffHours get the start number and end number as a pair, push them into a list.
  Therefore, each even index is start time number, odd index is end time number.
  For example
  Sunday is24H 
      ==> [0,1440] in the array 1440 = 60 *24
  Monday 
      {
        start: '1970-01-01T08:00:00.000Z',
        end: '1970-01-01T12:00:00.000Z',
      } 
      ==> [0, 1440, 1920, 2160] in the array 1920 = 60 * 24 +60 * 8 and 2160 = 60 * 24 +60 * 12

  At the end, the array will be duplicated by a map with + weekInMinutes to easily handle start date is Friday or Saturday.
*/
export const getStartEndTimeNumbersFromStaffHours = (
  staffHours?: getSettings_getOrganisation_staffed_hours,
) => {
  try {
    const startEndTimeNumbers: number[] = []
    if (!staffHours) {
      return
    }
    Weekdays.forEach((weekDay, index) => {
      const theDay = staffHours[
        weekDay as keyof getSettings_getOrganisation_staffed_hours
      ] as getSettings_getOrganisation_staffed_hours_MON
      if (!theDay.enabled) {
        return
      }
      const theDayInMinutesNumber = index * dayInMinutes

      if (theDay.is24H) {
        const startNumber = theDayInMinutesNumber
        const endNumber = startNumber + dayInMinutes
        startEndTimeNumbers.push(startNumber, endNumber)
        return
      }

      if (!isEmpty(theDay.hours)) {
        const hourNumbers = mergeOverlappingAndGetHoursToNumber(theDay.hours)
        hourNumbers.forEach(hourNumber => {
          startEndTimeNumbers.push(
            theDayInMinutesNumber + hourNumber.startNumber,
            theDayInMinutesNumber + hourNumber.endNumber,
          )
        })
      }
    })

    return [
      ...startEndTimeNumbers,
      ...startEndTimeNumbers.map(time => time + weekInMinutes),
    ]
  } catch (_) {
    return
  }
}

type GetTimesFromStaffedHourNumbersParams = {
  times: Date[]
  staffedHourNumbers?: number[]
  timePeriod: number
  isStaffedHoursEnabled: boolean
  startTimeNumber: number | null
}

/*
   Transfer sheet start time into time zone number
   For example 2022-11-27T11:00:00.000Z is Sunday 11:00 in UTC, it's Monday 00:00 in NZ.
   If the time zone is NZ, the outcome for 2022-11-27T11:00:00.000Z(NZ Monday 00:00) is 1440 = `60 * 24 * (Monday index)`
   Monday index is 1
   If the time zone is China, the outcome for 2022-11-27T11:00:00.000Z(CN Sunday 19:00) is 1140 = `60 * 24 * (Sunday index) + 60 * 19`
   Sunday index is 0.
*/
export const getStartNumberFromStartTime = (
  startTime: Date,
  timeZone: string,
) => {
  try {
    // utcToZonedTime get null after iOS build, a RN JS engine timezone problem, using backup solution
    if (isIOS) {
      return null
    }
    const zonedTime = utcToZonedTime(startTime, timeZone)

    const zonedTimeDay = zonedTime.getDay()
    const zonedTimeHour = zonedTime.getHours()
    const zonedTimeMinute = zonedTime.getMinutes()

    return (
      zonedTimeDay * dayInMinutes +
      zonedTimeHour * hourInMinutes +
      zonedTimeMinute
    )
  } catch {
    return null
  }
}

/*
 Calculate each time is open or close
*/
export const getTimesFromStaffedHourNumbers = ({
  times,
  staffedHourNumbers,
  timePeriod,
  isStaffedHoursEnabled,
  startTimeNumber,
}: GetTimesFromStaffedHourNumbersParams) => {
  try {
    if (
      !staffedHourNumbers ||
      !isStaffedHoursEnabled ||
      isNil(startTimeNumber)
    ) {
      return times.map(time => {
        const hour = time.getHours()
        return { time, isStaffedHour: hour >= 8 && hour < 20 }
      })
    }

    let timeIndex = 0
    let startEndIndex = 0
    const timesWithOffHour = []

    while (
      timeIndex < times.length &&
      startEndIndex < staffedHourNumbers.length
    ) {
      let currentStartNumber = startTimeNumber + timePeriod * timeIndex
      const isStartNumber = startEndIndex % 2 === 0 // even index means is a staff hour start

      const nextStaffEnd =
        staffedHourNumbers[isStartNumber ? startEndIndex + 1 : startEndIndex]
      const isTimeColumnAfterNextStaffEnd = currentStartNumber >= nextStaffEnd

      // if current time column start is after next staff hour end, increase staff hour index by 1
      if (isTimeColumnAfterNextStaffEnd) {
        startEndIndex += 1
        continue
      }

      if (isStartNumber) {
        const nowStaffStart = staffedHourNumbers[startEndIndex]
        let currentEndNumber = currentStartNumber + timePeriod

        // if current time column end before current staff hour start, no conflict, is close, then increase the time index by 1
        while (timeIndex < times.length && currentEndNumber <= nowStaffStart) {
          timesWithOffHour.push({
            time: times[timeIndex],
            isStaffedHour: false,
          })
          timeIndex += 1
          currentEndNumber = currentEndNumber + timePeriod
        }
        // if current time column end after current staff hour start, conflict,  increase staff hour index by 1, go to compare with staff hour end
        startEndIndex += 1
        continue
      }

      // isEndNumber
      const nowStaffEnd = staffedHourNumbers[startEndIndex]

      // if current time column start before current staff hour end, is inside the staff hour, is open, then increase the time index by 1
      while (timeIndex < times.length && currentStartNumber < nowStaffEnd) {
        timesWithOffHour.push({
          time: times[timeIndex],
          isStaffedHour: true,
        })
        timeIndex += 1
        currentStartNumber = currentStartNumber + timePeriod
      }

      // if current time column start after current staff hour end, go to compare with staff hour start
      startEndIndex += 1
    }
    return timesWithOffHour
  } catch (_) {
    return times.map(time => {
      const hour = time.getHours()
      return { time, isStaffedHour: hour >= 8 && hour < 20 }
    })
  }
}
