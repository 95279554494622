import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, View } from 'react-native'

import { SheetHeaderStyles as styles } from './styles'

type Props = {
  showMoreDetails: boolean
  onPressShowMore?: () => void
}

export const ShowMoreButton: React.FC<Props> = React.memo(
  ({ showMoreDetails, onPressShowMore }) => {
    const { t } = useTranslation()
    return (
      <View
        accessibilityLabel="Show More Button"
        style={styles.textButtonContainer}
      >
        <TouchableOpacity
          style={styles.textButtonTouchableField}
          onPress={onPressShowMore}
        >
          <Text style={styles.showMoreButton}>
            {showMoreDetails
              ? `- ${t('sheet:showLess')}`
              : `+ ${t('sheet:showMore')}`}
          </Text>
        </TouchableOpacity>
      </View>
    )
  },
)

ShowMoreButton.displayName = 'ShowMoreButton'
