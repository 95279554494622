import { isEmpty, orderBy, values } from 'lodash'
import { getTemplate_getTemplate_treatments_items_treatments_items_treatment_options_products } from 'src/types/getTemplate'
import {
  getTemplateWithProducts_getTemplate,
  getTemplateWithProducts_getTemplate_treatments_items_treatments_items as TemplateTreatment,
} from 'src/types/getTemplateWithProducts'

export type TemplateTreatmentsGroupHash = {
  [groupProductId: string]: TemplateTreatmentsInGroup
}
export type TemplateTreatmentWithIsAvailable = TemplateTreatment & {
  isAvailable: boolean
}

export type GetAllProductsAvailable = (
  products?:
    | null
    | getTemplate_getTemplate_treatments_items_treatments_items_treatment_options_products[],
) => boolean

export type TemplateTreatmentsInGroup = {
  order: null | number
  name: string
  id: string
  templateTreatments: TemplateTreatment[]
}
export const getTreatmentsFromTemplate = (
  template?: getTemplateWithProducts_getTemplate | null,
) => {
  if (!template) {
    return []
  }
  const firstTreatmentGroup = template.treatments?.items?.find(treatment => {
    if ('treatments' in treatment) {
      return treatment.treatments
    }
    return
  })
  return (
    (firstTreatmentGroup &&
      'treatments' in firstTreatmentGroup &&
      firstTreatmentGroup?.treatments?.items) ||
    []
  )
}

export const groupTemplateTreatments = (
  template: getTemplateWithProducts_getTemplate | null | undefined,
) => {
  if (!template) {
    return { groupedTemplateTreatments: [], templateTreatments: [] }
  }
  const templateTreatments = getTreatmentsFromTemplate(template)
  const productInGroup: TemplateTreatmentsGroupHash = {
    [template.id]: {
      order: null,
      name: template.name,
      id: template.id,
      templateTreatments: [],
    },
  }

  templateTreatments.forEach(templateTreatment => {
    const parentProduct = templateTreatment.product?.parent_product
    if (!parentProduct?.id) {
      productInGroup[template.id].templateTreatments.push(templateTreatment)
      return
    }

    if (!productInGroup[parentProduct.id]) {
      productInGroup[parentProduct.id] = {
        order: templateTreatment.product?.parent_product?.order ?? null,
        name: templateTreatment.product?.parent_product?.name ?? '',
        id: parentProduct.id,
        templateTreatments: [templateTreatment],
      }
      return
    }
    productInGroup[parentProduct.id].templateTreatments.push(templateTreatment)
  })

  const groupedTemplateTreatments = orderBy(
    values(productInGroup).filter(p => !isEmpty(p.templateTreatments)),
    ['order', 'name'],
  )

  return { groupedTemplateTreatments, templateTreatments }
}
export const extractGroupedTreatmentInOrderedList = (
  groupedTreatments: TemplateTreatmentsInGroup[],
) => {
  const templateTreatmentsInOrder: TemplateTreatment[] = []
  groupedTreatments.forEach(groupedTreatment => {
    templateTreatmentsInOrder.push(...groupedTreatment.templateTreatments)
  })
  return templateTreatmentsInOrder
}
