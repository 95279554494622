import React, { useState } from 'react'
import { findWeightUnit } from 'constants/PatientWeightOptions'
import { getOneProduct_getProduct as OneProduct } from 'src/types/getOneProduct'
import { getPatient_getPatient as Patient } from 'src/types/getPatient'
import { ApprovalStatus, ProductType } from 'types/globalTypes'
import { FluidDosageForm } from './FluidDosageForm'
import { Inputs as NonFluidFormData, FluidDosageFormData } from './types'
import { MedicineDosageForm } from './MedicineDosageForm'
import { getWeightLastUpdateTime } from './utils/getWeightUpdatedAt'
import { useGetDateFormatPattern } from 'components/Task/utils/useGetStatusLabelText'
import { EditableText, FormBreak } from 'components/common'
import { useSetShowTreatmentTabs } from 'src/hooks/useSetShowTreatmentTabs'
import { NameType, ProductName } from 'components/AddTreatment/ProductName'
import { environment } from 'src/config'
import { CubexTopBanner } from 'components/Banners/CubexTopBanner'
import { useCubexIntegration } from 'components/Banners/useCubexIntegration'

export type FormData = FluidDosageFormData | NonFluidFormData

export type Props = {
  defaultOverrides?: Partial<FormData>
  hasPIMSIntegration?: boolean
  isEdit?: boolean
  approvalStatus?: ApprovalStatus
  isNewSheet?: boolean // For treatment selector in add sheet screen, don't show schedule & instruction
  onSave: (data: FormData) => void
  patient: Patient
  product: OneProduct | Partial<OneProduct>
  showTreatmentName?: boolean
  submitting?: boolean
  submitTitle: string
  deleteTreatment?: () => void
  shouldDisableGeneralAutoCompleted?: boolean
  isIVInfusion?: boolean
  showEditableTreatmentName?: boolean
  treatmentName?: string
  handleCreateSheetModal?: (
    shouldBeVisible: boolean,
    newDosageInfo?: NonFluidFormData,
  ) => void
  shouldHideTabs?: boolean
  isAddTemplateToNewSheet?: boolean
}

export const TreatmentForm: React.FC<Props> = ({
  defaultOverrides,
  hasPIMSIntegration = false,
  isEdit,
  isNewSheet = false,
  onSave,
  patient,
  product,
  showTreatmentName,
  submitting,
  submitTitle,
  deleteTreatment,
  shouldDisableGeneralAutoCompleted,
  isIVInfusion = false,
  showEditableTreatmentName = true,
  treatmentName: initialTreatmentName,
  handleCreateSheetModal,
  approvalStatus,
  shouldHideTabs,
  isAddTemplateToNewSheet = false,
}) => {
  const [treatmentName, setTreatmentName] = useState(
    defaultOverrides?.treatmentName ?? (initialTreatmentName || product.name!),
  )
  const isFluids = product?.type === ProductType.IVFLUIDS
  const { dateFormatPattern } = useGetDateFormatPattern()
  const patientWeightUpdatedAtFormattedText = getWeightLastUpdateTime(
    patient?.weight_updated_at,
    dateFormatPattern,
  )
  const { isWeb } = environment
  const styleForWebOriOS = !isAddTemplateToNewSheet || isWeb
  useSetShowTreatmentTabs(!shouldHideTabs)

  const handleSave = (formData: FormData) => {
    const formDataWithName = {
      ...formData,
      ...(showEditableTreatmentName && { treatmentName }),
    }
    return onSave(formDataWithName)
  }
  const { cubexBannerStatus, isCubexProductBillable } =
    useCubexIntegration(product)

  return (
    <>
      {!showTreatmentName ? (
        showEditableTreatmentName && (
          <>
            <EditableText onChange={setTreatmentName} value={treatmentName} />
            <FormBreak />
          </>
        )
      ) : (
        <ProductName productName={treatmentName} />
      )}
      <CubexTopBanner cubexBannerStatus={cubexBannerStatus} />
      {!!product.name && treatmentName !== product.name && (
        <ProductName productName={product.name} type={NameType.SUBTITLE} />
      )}
      {isFluids ? (
        <FluidDosageForm
          defaultOverrides={defaultOverrides as FluidDosageFormData}
          isEdit={isEdit}
          isNewSheet={isNewSheet}
          onSave={handleSave as any}
          patientWeight={patient.weight ?? 0}
          patientWeightUnit={findWeightUnit(patient.weight_unit)}
          patientWeightUpdatedAt={patientWeightUpdatedAtFormattedText}
          product={product}
          species={patient.species?.name ?? 'Unknown'}
          submitting={submitting}
          deleteTreatment={deleteTreatment}
          approvalStatus={approvalStatus}
          submitTitle={submitTitle}
          styleForWebOriOS={styleForWebOriOS}
        />
      ) : (
        <MedicineDosageForm
          defaultOverrides={defaultOverrides as NonFluidFormData}
          hasPIMSIntegration={hasPIMSIntegration}
          isEdit={isEdit}
          isNewSheet={isNewSheet}
          onSave={handleSave}
          patientWeight={patient.weight ?? 0}
          patientWeightUnit={findWeightUnit(patient.weight_unit)}
          patientWeightUpdatedAt={patientWeightUpdatedAtFormattedText}
          product={product}
          submitting={submitting}
          submitTitle={submitTitle}
          deleteTreatment={deleteTreatment}
          shouldDisableGeneralAutoCompleted={shouldDisableGeneralAutoCompleted}
          isIVInfusion={isIVInfusion}
          handleCreateSheetModal={handleCreateSheetModal}
          approvalStatus={approvalStatus}
          styleForWebOriOS={styleForWebOriOS}
          isCubexProductBillable={isCubexProductBillable}
        />
      )}
    </>
  )
}
