import { differenceInMonths, differenceInDays } from 'date-fns'

// Convert a date iso to Year Month and Day
export const getBirthAgeYearMonths = (birthDateISO?: string | null) => {
  if (!birthDateISO) return ''

  const birthDate = new Date(birthDateISO)
  const now = new Date()
  const months = differenceInMonths(now, birthDate)
  if (months < 0) return ''

  if (months >= 12) {
    const year = Math.floor(months / 12)
    const month = months % 12
    return `${year}y ${month}m`
  }
  const days = differenceInDays(now, birthDate)
  if (months === 0) {
    return `${days}d`
  }

  let dateDiff = now.getDate() - birthDate.getDate()

  if (dateDiff < 0) {
    // Not 100% correct when the days of month is not 30, eg Feb. But not a big deal.
    dateDiff = 30 + dateDiff
  }

  return `${months}m ${dateDiff}d`
}
