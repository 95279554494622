import React from 'react'
import { CheckButton } from 'components/shared'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'

type Props = {
  onChange: () => void
  isAllSelected: boolean
  disabled?: boolean
}

export const SelectAll: React.FC<Props> = ({
  onChange,
  isAllSelected,
  disabled,
}) => {
  const { t } = useTranslation()

  return (
    <View style={styles.taskToCompleteContainer}>
      <View>
        <CheckButton
          onChange={onChange}
          checked={isAllSelected}
          disabled={disabled}
        />
      </View>
      <View style={styles.choiceWrapper}>
        <TouchableOpacity style={styles.allSelectText} onPress={onChange}>
          <Text>
            {isAllSelected
              ? t('bulkTask:drawer.deselect')
              : t('bulkTask:drawer.selectAll')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  taskToCompleteContainer: {
    marginVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  choiceWrapper: {
    flex: 1,
    width: '100%',
    marginLeft: 15,
    marginBottom: 5,
  },
  allSelectText: {
    color: Colors.contentSecondary,
    marginTop: 4,
    fontFamily: Fonts.semibold,
  },
})
