import React, { useCallback, useMemo } from 'react'
import { ActionableListItem, Actions } from 'components/common/MultiActions'
import { SvgArrow, SvgMeatballs } from 'components/Icons'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { Typography, Colors } from 'src/design-system/theme'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  GestureResponderEvent,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import {
  getTemplates_getTemplates_items_sites as SiteItems,
  getTemplates_getTemplates_items_user,
} from 'src/types/getTemplates'
import { isOptimisticId } from 'src/utils/optimisticId'
import { ColorCategory, stringToColor } from 'src/utils/stringToColor'

import { TemplateItem } from './types'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { Layout } from 'constants/Layout'
import { useOpenMenu } from 'src/hooks/useOpenMenu'
import { Pill } from 'components/common/Pill'

const Sites: React.FC<{
  sites: (SiteItems | null)[]
}> = ({ sites }) => {
  const { width } = useBreakpoint()
  const maxScreenWidth = Layout.xxl
  const maxSiteWidth = (width > maxScreenWidth ? maxScreenWidth : width) * 0.5

  const calculateSitesDisplaySize = useMemo(() => {
    const sitePadding = 10
    const widthForEachChar = 10
    let sitesWidth = 0
    let sitesSize = 0

    sites.forEach(site => {
      if (!site) return
      const siteNameLength = site.name.length
      sitesWidth += sitePadding * 3 + siteNameLength * widthForEachChar

      if (maxSiteWidth <= sitesWidth) return
      sitesSize = sitesSize + 1
    })
    return sitesSize
  }, [maxSiteWidth, sites])

  return (
    <View style={styles.siteContainer}>
      {sites.map((site, idx) => {
        if (idx > calculateSitesDisplaySize + 1) return
        if (idx > calculateSitesDisplaySize) {
          const tagNum = sites.length - idx
          return (
            <Pill
              key={idx}
              text={`+${tagNum}`}
              size="small"
              color={Colors.Backgrounds.overlay}
            />
          )
        }

        return (
          site && (
            <Pill
              text={site.name}
              key={site.id}
              size="small"
              color={stringToColor(
                site.name,
                ColorCategory.TEMPLATE_LIST_DEPARTMENT,
              )}
            />
          )
        )
      })}
    </View>
  )
}

type OnPressLink = {
  id: string
  name: string
}

type IMenuItemOption = {
  name: Actions
  onClick: () => unknown
  confirmationOption?: {
    title: string
    text: string
  }
  disabled?: boolean
}

export type Props = {
  template: TemplateItem
  onPress: (onPress: OnPressLink) => void
  menuItems: IMenuItemOption[]
}

export const TemplateListItem: React.FC<Props> = React.memo(
  ({ template, onPress, menuItems }) => {
    const { id, name, description, user, sites } = template
    const isOptimistic = isOptimisticId(id)
    const showMetaBall = Platform.OS === 'web' && !!menuItems

    const { anchorEl, actions, openMenu, handleCloseTemplateMenu } =
      useOpenMenu(menuItems)

    const handlePressTemplate = useCallback(
      () => onPress && onPress({ id, name }),
      [id, name, onPress],
    )

    return (
      <ActionableListItem
        actions={actions}
        anchorEl={anchorEl}
        closeMenu={handleCloseTemplateMenu}
        isActionable={true}
      >
        <TouchableOpacity
          accessibilityLabel={`Navigate to Template ${name}`}
          disabled={isOptimistic}
          onPress={handlePressTemplate}
          style={styles.container}
        >
          <TemplateItemDescription
            isOptimistic={isOptimistic}
            description={description}
            name={name}
            openMenu={openMenu}
            sites={sites}
            showMetaBall={showMetaBall}
            user={user}
          />
        </TouchableOpacity>
      </ActionableListItem>
    )
  },
)

TemplateListItem.displayName = 'TemplateListItem'

const TemplateItemDescription: React.FC<{
  description: string | null
  isOptimistic: boolean
  name: string
  openMenu: (event: GestureResponderEvent) => void
  showMetaBall: boolean
  sites: (SiteItems | null)[] | null
  user: getTemplates_getTemplates_items_user | null
}> = React.memo(
  ({
    description,
    isOptimistic,
    name,
    openMenu,
    sites,
    showMetaBall,
    user,
  }) => {
    const { t } = useTranslation()
    return (
      <>
        <View style={styles.main}>
          <View style={[styles.row, styles.templateTitleRow]}>
            <Text
              style={[
                styles.title,
                isOptimistic && { color: Colors.Contents.secondary },
              ]}
              numberOfLines={1}
              testID={name}
            >
              {name}
            </Text>
            {description ? (
              <>
                <Text style={styles.divider}> - </Text>
                <TextWithTooltip
                  style={[
                    styles.description,
                    isOptimistic && { color: Colors.Contents.secondary },
                  ]}
                  numberOfLines={1}
                  testID={description}
                  title={description}
                >
                  {description}
                </TextWithTooltip>
              </>
            ) : null}
          </View>
          <View style={styles.row}>
            {sites?.length ? <Sites sites={sites} /> : null}
            {user && sites?.length ? (
              <Text style={styles.divider}>|</Text>
            ) : null}
            {user ? (
              <Text
                style={[
                  styles.owner,
                  isOptimistic && { color: Colors.Contents.secondary },
                ]}
                numberOfLines={1}
                testID={name}
              >
                {user.full_name}
              </Text>
            ) : null}
          </View>
        </View>
        {isOptimistic ? (
          <ActivityIndicator size="small" style={styles.activitySpinner} />
        ) : (
          <>
            {showMetaBall ? (
              <TouchableOpacity
                accessibilityLabel={t('general.moreActions')}
                style={styles.metaBall}
                onPress={openMenu}
              >
                <SvgMeatballs color={Colors.Contents.tertiary} />
              </TouchableOpacity>
            ) : null}
            <View style={styles.icon}>
              <SvgArrow orient="right" color="grey" width={15} height={15} />
            </View>
          </>
        )}
      </>
    )
  },
)

TemplateItemDescription.displayName = 'TemplateItemDescription'

const styles = StyleSheet.create({
  icon: {
    padding: 16,
    maxWidth: '12%',
  },
  title: {
    fontFamily: Typography.FontFamilies.base,
    fontSize: 16,
    // @ts-ignore
    minWidth: 'fit-content',
  },
  description: {
    fontFamily: Typography.FontFamilies.base,
    fontSize: 14,
    color: Colors.Contents.tertiary,
  },
  owner: {
    fontFamily: Typography.FontFamilies.base,
    fontSize: 14,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    gap: 4,
  },
  templateTitleRow: {
    width: '100%',
  },
  activitySpinner: {
    paddingRight: 16,
  },
  container: {
    flexDirection: 'row',
    paddingLeft: 16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.Borders.primary,
    backgroundColor: Colors.Backgrounds.UI,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    width: '100%',
  },
  main: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 'auto',
    maxWidth: '88%',
  },
  metaBall: {
    paddingHorizontal: 16,
  },
  divider: {
    fontFamily: Typography.FontFamilies.base,
    color: Colors.Contents.tertiary,
    // @ts-expect-error (Web Only CSS rule)
    whiteSpace: 'nowrap', // Not sure why it's needed but fixes very long titles/descriptions wrapping weirdly
  },
  siteContainer: {
    flexDirection: 'row',
    gap: 4,
  },
})
