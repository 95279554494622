import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View, StyleSheet, Text } from 'react-native'

import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { TouchableArrow } from 'components/common/TouchableArrow'
import { useBreakpoint } from 'src/hocs/breakpoint'

type Props = {
  formatTime: string
  dayCountText: string | null
  onClickPrev: () => void
  onClickNext: () => void
  toggleDialog?: () => void
  placement: 'ABOVE_TIME_AREA' | 'DAY_NAVIGATION'
  isEmptySheet?: boolean
}

export const DateWithDay: React.FC<Props> = React.memo(
  ({
    formatTime,
    dayCountText,
    onClickPrev,
    onClickNext,
    toggleDialog,
    placement,
    isEmptySheet,
  }: Props) => {
    const { t } = useTranslation()
    const { isExSmallScreen, isLargeScreen, isSmallScreen } = useBreakpoint()
    const isMobileScreen = isExSmallScreen || isSmallScreen

    const centerPartTitleStyle = useMemo(() => {
      if (isMobileScreen) return styles.centerPartTitleMobile
      return styles.centerPartTitle
    }, [isMobileScreen])

    const touchableArrowStyles = useMemo(
      () => [
        styles.button,
        styles.rangeButtonRight,
        isExSmallScreen && styles.marginLeft,
        isEmptySheet && styles.disabledOpacity,
      ],
      [isEmptySheet, isExSmallScreen],
    )

    const ABOVE_TIME_AREA_TYPE = placement === 'ABOVE_TIME_AREA'
    if (!dayCountText) return <View style={centerPartTitleStyle} />
    return (
      <View style={centerPartTitleStyle}>
        {!ABOVE_TIME_AREA_TYPE && (
          <TouchableArrow
            a11yLabel={t('sheets.time.lastDay')}
            color={Colors.sheet.title.blue}
            style={touchableArrowStyles}
            onPress={onClickPrev}
            orient="left"
            height={13}
            width={13}
            disabled={isEmptySheet}
          />
        )}

        <TouchableOpacity
          onPress={toggleDialog}
          style={styles.button}
          disabled={ABOVE_TIME_AREA_TYPE || isEmptySheet}
        >
          <View
            style={[styles.textContainer, !isLargeScreen && styles.v2Padding]}
          >
            {ABOVE_TIME_AREA_TYPE ? (
              <Text style={styles.dateText}>{`${formatTime} -`} </Text>
            ) : null}
            <Text
              style={[
                styles.dayCountText,
                !ABOVE_TIME_AREA_TYPE && styles.title,
              ]}
            >
              {dayCountText}
            </Text>
          </View>
        </TouchableOpacity>

        {!ABOVE_TIME_AREA_TYPE && (
          <TouchableArrow
            a11yLabel={t('sheets.time.nextDay')}
            color={Colors.sheet.title.blue}
            style={touchableArrowStyles}
            onPress={onClickNext}
            orient="right"
            height={13}
            width={13}
            disabled={isEmptySheet}
          />
        )}
      </View>
    )
  },
)

DateWithDay.displayName = 'DateWithDay'

const styles = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  textContainer: {
    flexDirection: 'row',
    fontFamily: Fonts.bold,
    lineHeight: 21,
    fontSize: 16,
  },
  v2Padding: {
    paddingVertical: 10,
  },
  dateText: {
    color: Colors.task.text,
    fontFamily: Fonts.bold,
    fontSize: 12,
  },
  dayCountText: {
    color: Colors.task.text,
    fontFamily: Fonts.bold,
    fontSize: 12,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  disabledOpacity: {
    opacity: 0.5,
  },
  title: {
    fontSize: 16,
    color: Colors.contentPrimary,
  },
  rangeButtonRight: {
    padding: 4,
  },
  rangeButtonLeft: {
    padding: 4,
  },
  centerPartTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  centerPartTitleMobile: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  marginLeft: {
    marginLeft: 5,
  },
  marginRight: {
    marginRight: 5,
  },
})
