import { useCallback, useEffect } from 'react'
import { useOrganisation } from 'src/context/organisation'
import { makeVar, useReactiveVar } from '@apollo/client'

const curSheetId = makeVar<string | null>(null)

export const useSheetId = () => {
  const [{ organisationId }] = useOrganisation()

  useEffect(() => {}, [organisationId])

  const currentSheetId = useReactiveVar(curSheetId)

  const setSheetId = useCallback((v: string | null) => {
    curSheetId(v)
  }, [])

  return {
    currentSheetId,
    setSheetId,
  }
}
