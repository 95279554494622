import React, { useState } from 'react'
import { useAssociatedSiteIds } from 'src/hooks/useAssociatedSiteIds'
import { getTemplate_getTemplate_treatments_items_treatments_items } from 'src/types/getTemplate'
import {
  TemplatePickProductScreen,
  useGetProductGroups,
} from './ProductPicker/ProductPickerScreen'
import {
  TemplateTreatment,
  TemplateTreatmentForm,
  TreatmentOption,
  Values,
} from './TemplateTreatmentForm'

enum DrawerPages {
  TREATMENT,
  PICK_PRODUCT,
}

type Props = {
  currentTemplateTreatment?: getTemplate_getTemplate_treatments_items_treatments_items
  onSave: (
    templateTreatment: Values,
    treatmentOptions: TreatmentOption[],
  ) => void
  onDelete?: () => void
  createOrUpdateTreatmentLoading: boolean
  templateSiteIds?: string[] | null
}

export const TemplateTreatmentDrawer: React.FC<Props> = ({
  currentTemplateTreatment,
  onSave,
  onDelete,
  createOrUpdateTreatmentLoading,
  templateSiteIds,
}) => {
  const isEdit = !!onDelete
  const productGroups = useGetProductGroups()
  const { getIsProductAvailable } = useAssociatedSiteIds(templateSiteIds)
  const templateTreatment = React.useMemo(
    () =>
      ({
        name: currentTemplateTreatment?.name,
        instructions: currentTemplateTreatment?.instructions ?? '',
        is_billable: currentTemplateTreatment?.is_billable,
        is_auto_completed: currentTemplateTreatment?.is_auto_completed,
        schedule: currentTemplateTreatment?.schedule,
        conditional: currentTemplateTreatment?.conditional,
        medicine_dosage_info: currentTemplateTreatment?.medicine_dosage_info,
        type: currentTemplateTreatment?.type,
      } as TemplateTreatment),
    [currentTemplateTreatment],
  )

  const [treatmentOptions, setTreatmentOptions] = useState<TreatmentOption[]>(
    () =>
      currentTemplateTreatment?.treatment_options?.products?.map(product => {
        return {
          id: product.id,
          name: product.name,
          isBillable: product.is_billable,
          type: product.type,
          code: product.code,
          pims_mapping: product.pims_mapping,
          medicine_dosage_info: product.medicine_dosage_info,
          track_vital: product.track_vital,
          groupName:
            productGroups.find(
              productGroup => productGroup.id === product.parent_product_id,
            )?.name ?? '',
          isUnAvailableProduct: !getIsProductAvailable(product.sites),
          origin_id: product.origin_id,
        }
      }) ?? [],
  )

  const [curPage, setCurPage] = useState(
    isEdit ? DrawerPages.TREATMENT : DrawerPages.PICK_PRODUCT,
  )
  switch (curPage) {
    case DrawerPages.TREATMENT:
      return (
        <TemplateTreatmentForm
          templateTreatment={templateTreatment}
          treatmentOptions={treatmentOptions}
          onSave={data => {
            onSave(data, treatmentOptions)
          }}
          onDelete={onDelete}
          createOrUpdateTreatmentLoading={createOrUpdateTreatmentLoading}
          onClickTemplatePickProduct={() =>
            setCurPage(DrawerPages.PICK_PRODUCT)
          }
        />
      )
    case DrawerPages.PICK_PRODUCT:
      return (
        <TemplatePickProductScreen
          treatmentOptions={treatmentOptions}
          setTreatmentOptions={setTreatmentOptions}
          onClickTemplatePickProduct={() => setCurPage(DrawerPages.TREATMENT)}
          templateSiteIds={templateSiteIds}
        />
      )
    default:
      return null
  }
}
