import { useQuery } from '@apollo/client'
import { getOneProduct, getOneProductVariables } from 'types/getOneProduct'
import { GET_PRODUCT_DETAILS } from 'components/AddTreatment/graphql'
import {
  getProductByOriginId,
  getProductByOriginIdVariables,
} from 'types/getProductByOriginId'
import { GET_PRODUCT_BY_ORIGIN_ID } from 'components/IVInfusionForm/graphql'

const DefaultIVFluidsOriginId = 'VR-10056'

export const useDefaultIVFluid = (
  productId: string | undefined,
  organisationId: string,
) => {
  const { data: dataMedication, loading: loadingMedication } = useQuery<
    getOneProduct,
    getOneProductVariables
  >(GET_PRODUCT_DETAILS, {
    skip: !productId,
    variables: {
      id: productId!,
      organisation_id: organisationId,
    },
  })

  const { data: dataIVFluid, loading: loadingIVFluid } = useQuery<
    getProductByOriginId,
    getProductByOriginIdVariables
  >(GET_PRODUCT_BY_ORIGIN_ID, {
    skip: !!productId,
    variables: {
      origin_id: DefaultIVFluidsOriginId,
      organisation_id: organisationId,
    },
  })
  const productDetail = !productId
    ? dataIVFluid?.getProductByOriginId
    : dataMedication?.getProduct
  const loading = loadingIVFluid || loadingMedication

  return { productDetail, loading }
}
