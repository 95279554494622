import React from 'react'
import { format, parseISO } from 'date-fns'
import { TFunction } from 'i18next'
import { toLower, upperFirst } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskItem } from 'src/types/getSheet'
import { Status } from 'types/globalTypes'

import { TaskActionBox } from './TaskActionBox'
import { ActivityInfo } from './TaskActions'
import {
  getStatusLabelText,
  useGetDateFormatPattern,
} from './utils/useGetStatusLabelText'

enum FluidTaskStatus {
  START = 'START', // DUE MISSED START
  STOPPED = 'STOPPED', // DONE
  STARTED = 'STARTED', // IN_PROGRESS
  CHANGED = 'CHANGED', // IN_PROGRESS AND HAVE BEFORE FLUID TASK
  STOPPED_AND_CHANGED = 'STOPPED_AND_CHANGED', // DONE AND HAVE NEXT FLUID TASK
}

const getFluidTaskStatus = (clickedTask: TaskItem, allTasks: TaskItem[]) => {
  if (clickedTask.status === Status.DONE) {
    // deep coupled fluid tasks logic. The stopped task must have a after changed one.
    const hasWorkingFluidAfter = !!allTasks.find(
      task => task.given_start_at === clickedTask.given_stop_at,
    )
    if (hasWorkingFluidAfter) {
      return FluidTaskStatus.STOPPED_AND_CHANGED
    }

    return FluidTaskStatus.STOPPED
  }
  if (clickedTask.status !== Status.IN_PROGRESS) {
    return FluidTaskStatus.START
  }
  // deep coupled fluid tasks logic. The changed task must have a before stopped one.
  const hasStoppedFluidBefore = !!allTasks.find(
    task =>
      task.given_stop_at === clickedTask.given_start_at &&
      task.status === Status.DONE,
  )

  if (hasStoppedFluidBefore) {
    return FluidTaskStatus.CHANGED
  }

  return FluidTaskStatus.STARTED
}

const getFluidTaskTimeForText = (
  task: TaskItem,
  allTasks: TaskItem[],
  dateFormatPattern: string,
) => {
  switch (getFluidTaskStatus(task, allTasks)) {
    case FluidTaskStatus.STOPPED:
    case FluidTaskStatus.STOPPED_AND_CHANGED:
      return format(
        parseISO(task.given_stop_at ?? new Date().toISOString()),
        dateFormatPattern,
      )
    case FluidTaskStatus.STARTED:
      return format(
        parseISO(
          (task.given_start_at || task.start_at) ?? new Date().toISOString(),
        ),
        dateFormatPattern,
      )
    case FluidTaskStatus.CHANGED:
      return format(
        parseISO(
          (task.given_start_at || task.start_at) ?? new Date().toISOString(),
        ),
        dateFormatPattern,
      )

    default:
      return ''
  }
}

const getActionBoxText = (fluidTaskStatus: FluidTaskStatus) => {
  const status =
    fluidTaskStatus === FluidTaskStatus.STOPPED_AND_CHANGED
      ? FluidTaskStatus.CHANGED
      : fluidTaskStatus
  return upperFirst(toLower(status))
}

const getFluidTaskStatusLabelText = (
  task: TaskItem,
  allTasks: TaskItem[],
  t: TFunction,
  dateFormatPattern: string,
) => {
  // Show normal status label if the fluid task is not started yet
  if (task.status !== Status.DONE && task.status !== Status.IN_PROGRESS) {
    return getStatusLabelText(task, t, dateFormatPattern)
  }

  const time = getFluidTaskTimeForText(task, allTasks, dateFormatPattern)
  const fluidTaskStatus = getFluidTaskStatus(task, allTasks)
  const changedText = t('task:taskAddEdit.status.changed', { time })
  const statusLabelTexts = {
    [FluidTaskStatus.START]: t('task:taskAddEdit.status.startNow'),
    [FluidTaskStatus.STARTED]: t('task:taskAddEdit.status.started', { time }),
    [FluidTaskStatus.STOPPED]: t('task:taskAddEdit.status.stopped', { time }),
    [FluidTaskStatus.CHANGED]: changedText,
    [FluidTaskStatus.STOPPED_AND_CHANGED]: changedText,
  }

  return statusLabelTexts[fluidTaskStatus]
}

const taskBoxColors: Record<FluidTaskStatus, string> = {
  [FluidTaskStatus.STOPPED]: Colors.task.done,
  [FluidTaskStatus.STOPPED_AND_CHANGED]: Colors.task.done,
  [FluidTaskStatus.START]: Colors.task.pending,
  [FluidTaskStatus.STARTED]: Colors.task.due,
  [FluidTaskStatus.CHANGED]: Colors.task.due,
}

const getTaskBoxColor = (status: FluidTaskStatus) => {
  if (!status) return Colors.white
  return taskBoxColors[status] ?? Colors.white
}

type FluidTaskBoxAndTextProps = {
  taskToEdit: TaskItem
  allTasks: TaskItem[]
  sheetId?: string
}
export const FluidTaskBoxAndText = ({
  taskToEdit,
  allTasks,
  sheetId,
}: FluidTaskBoxAndTextProps) => {
  const fluidTaskStatus = getFluidTaskStatus(taskToEdit, allTasks)
  const { t } = useTranslation()
  const { dateFormatPattern } = useGetDateFormatPattern()
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()

  const isNativeStyle = isExSmallScreen || isSmallScreen

  return (
    <>
      <View style={styles.updateTaskTopHalf}>
        {/* Show normal action box if the fluid task is not started yet */}
        {taskToEdit.status !== Status.DONE &&
        taskToEdit.status !== Status.IN_PROGRESS ? (
          <TaskActionBox taskToEdit={taskToEdit} />
        ) : (
          <View
            style={[
              styles.taskStatus,
              { borderColor: getTaskBoxColor(fluidTaskStatus) },
              isNativeStyle && styles.smallTaskStatus,
            ]}
            testID="taskBox"
          >
            <>
              <View
                style={[
                  styles.taskStatusIndicator,
                  { backgroundColor: getTaskBoxColor(fluidTaskStatus) },
                ]}
              />
              <Text style={styles.taskStatusText}>
                {getActionBoxText(fluidTaskStatus)}
              </Text>
            </>
          </View>
        )}

        <View style={styles.activityInfoHolder}>
          <Text
            style={[
              styles.subLabelText,
              isNativeStyle && styles.smallSubLabelText,
            ]}
          >
            {getFluidTaskStatusLabelText(
              taskToEdit,
              allTasks,
              t,
              dateFormatPattern,
            )}
          </Text>
        </View>
      </View>
      {!!sheetId && <ActivityInfo task={taskToEdit} sheetId={sheetId} />}
    </>
  )
}

const styles = StyleSheet.create({
  taskStatus: {
    shadowColor: Colors.contentPrimary,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4.65,
    borderRadius: 4,
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    marginRight: 24,
    width: 64,
    elevation: 7,
    backgroundColor: Colors.white,
  },
  taskStatusIndicator: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 4,
  },
  taskStatusText: {
    fontSize: 12,
  },
  subLabelText: {
    color: Colors.contentPrimary,
    fontWeight: '500',
    fontSize: 16,
  },
  smallSubLabelText: {
    fontSize: 13,
  },
  updateTaskTopHalf: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  activityInfoHolder: {
    alignSelf: 'center',
  },
  smallTaskStatus: {
    marginRight: 10,
    width: 50,
  },
})
