import React, { useState } from 'react'
import { SwitchInput } from 'components/common'

import { CRIOffForm } from './CRIOffForm'
import { CRIOnForm } from './CRIOnForm'
import {
  CRIOffFormData,
  CRIOnFormData,
  Inputs,
  MedicationFormProps,
} from './types'

export const MedicineDosageForm: React.FC<MedicationFormProps> = props => {
  const {
    defaultOverrides,
    isEdit = false,
    onSave,
    isIVInfusion,
    handleCreateSheetModal,
    approvalStatus,
    styleForWebOriOS,
    isCubexProductBillable,
  } = props

  const [isCRI, setIsCRI] = useState(
    !!(isIVInfusion || defaultOverrides?.isCRI),
  )

  const renderCRISwitch = () => (
    <SwitchInput
      label={'CRI'}
      onChangeValue={setIsCRI}
      value={isCRI}
      disabled={isEdit || isIVInfusion}
    />
  )

  const handleOnSave = (data: Omit<Inputs, 'isCRI'>) => {
    onSave({
      ...data,
      isCRI,
    })
  }
  let copiedDefaultOverrides = defaultOverrides
  // setup treatment from template or multi-conditional treatment, default false when product is cubex billable
  if (defaultOverrides?.isBillable && isCubexProductBillable && !isEdit) {
    copiedDefaultOverrides = { ...defaultOverrides, isBillable: false }
  }

  if (!isCRI) {
    return (
      <CRIOffForm
        {...props}
        defaultOverrides={copiedDefaultOverrides as CRIOffFormData}
        onSave={handleOnSave}
        renderCRISwitch={renderCRISwitch}
        handleCreateSheetModal={handleCreateSheetModal}
        approvalStatus={approvalStatus}
        styleForWebOriOS={styleForWebOriOS}
      />
    )
  }

  return (
    <CRIOnForm
      {...props}
      defaultOverrides={copiedDefaultOverrides as CRIOnFormData}
      renderCRISwitch={renderCRISwitch}
      onSave={handleOnSave}
      approvalStatus={approvalStatus}
      styleForWebOriOS={styleForWebOriOS}
    />
  )
}
