import React from 'react'
import { taskItemMinWidth } from 'components/PatientItem/PatientListTasksItems'
import { usePatientTasksColumns } from 'components/PatientItem/usePatientTasksColumns'
import { format } from 'date-fns'
import { range } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { listWhiteboardPatients_listWhiteboardPatients_items_tasks as PatientTask } from 'src/types/listWhiteboardPatients'
import { CriticalNotesHeader } from 'components/Whiteboard/CriticalNotesHeader'
import { Colors } from 'constants/Colors'

type Props = {
  tasks: PatientTask[]
}

export const PatientListHeader: React.FC<Props> = ({ tasks }) => {
  const { settingsMap } = useOrgSettings()
  const { t } = useTranslation()

  let adminTimeFormat = settingsMap?.PREFERRED_TIME_FORMAT?.value ?? 'HH:00'
  adminTimeFormat = adminTimeFormat.replaceAll('m', '0')

  const columns = usePatientTasksColumns()
  if (!columns) {
    return null
  }

  const startTimes = tasks.map(task => {
    const startAt = Date.parse(task?.start_at)
    if (!startAt) return
    return format(startAt, adminTimeFormat)
  })

  return (
    <View style={styles.container}>
      <CriticalNotesHeader />
      <View style={styles.tasksContainer}>
        <View style={styles.tasks}>
          {
            <View style={styles.taskBoxContainer}>
              <Text testID={'MISSED'}>{t('patient:list.missed')}</Text>
            </View>
          }
          {
            <View style={styles.taskBoxContainer}>
              <Text testID={'DUE'}>{t('patient:list.due')}</Text>
            </View>
          }
          {range(columns).map((_, i) => {
            return (
              <View key={i} style={styles.taskBoxContainer}>
                <Text testID={startTimes[i]}>{startTimes[i]}</Text>
              </View>
            )
          })}
        </View>
        <View style={styles.headerUnderlineBorder} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    backgroundColor: 'white', // otherwise it's transparent??
  },
  tasksContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  tasks: {
    flexDirection: 'row',
    paddingBottom: 8,
    flex: 1,
    marginHorizontal: 8,
  },
  taskBoxContainer: {
    flex: 1,
    alignItems: 'center',
    minWidth: taskItemMinWidth,
  },
  headerUnderlineBorder: {
    width: '100%',
    borderBottomWidth: 1,
    borderColor: Colors.titleBorder,
    marginHorizontal: 4 + 4, // spacing + border radius
  },
})
