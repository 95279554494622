import React from 'react'
import { Text, TextStyle, StyleSheet, View } from 'react-native'
import { getBirthAgeYearMonths } from '../utils/getBirthAgeYearMonths'
import { Fonts } from 'constants/Fonts'
import { Colors } from 'constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { shorthandPatientBirth } from '../utils/shorthandPatientBirth'
import { getWeightText } from '../utils/getWeightText'

const isShowShorthandBirth = 0.6

type WeightAgeTextProps = {
  patientWeight: string
  patientWeightUnit: string | null
  dateOfBirth?: string | null
  sexTagTitle?: string | null
  textStyle?: TextStyle | null
  isWeightAgeWidthOversize?: boolean
  patientInfoWidthPercentage?: number
}

export const WeightAgeTextV2 = ({
  patientWeight,
  dateOfBirth,
  sexTagTitle,
  isWeightAgeWidthOversize,
  patientWeightUnit,
  patientInfoWidthPercentage = 0,
}: WeightAgeTextProps) => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isSmallScreens = isExSmallScreen || isSmallScreen
  const hasPatientWeight = !!patientWeight
  const hasDateOfBirth = !!dateOfBirth
  const hasSexTagTitle = !!sexTagTitle
  const birth = getBirthAgeYearMonths(dateOfBirth)
  const weight = getWeightText({
    isWeightAgeWidthOversize,
    patientWeight,
    patientWeightUnit,
  })

  return (
    <View style={styles.container}>
      <Text style={[styles.weightText, isSmallScreens && styles.mobileSize]}>
        {weight}
      </Text>
      {hasDateOfBirth && hasPatientWeight && (
        <Text style={styles.separator}>{'|'}</Text>
      )}
      <Text style={[styles.weightText, isSmallScreens && styles.mobileSize]}>
        {isWeightAgeWidthOversize &&
        patientInfoWidthPercentage > isShowShorthandBirth
          ? shorthandPatientBirth(birth)
          : birth}
      </Text>
      {hasSexTagTitle && !isWeightAgeWidthOversize && (
        <Text style={styles.separator}>{'|'}</Text>
      )}
      {!isWeightAgeWidthOversize && (
        <Text style={[styles.weightText, isSmallScreens && styles.mobileSize]}>
          {sexTagTitle}
        </Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginRight: 13,
  },
  weightText: {
    fontSize: 14,
    fontFamily: Fonts.semibold,
  },
  separator: {
    fontFamily: Fonts.semibold,
    color: Colors.infoGrey,
    marginHorizontal: 7,
  },
  mobileSize: {
    fontSize: 16,
  },
})
