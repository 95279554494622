import { IFlagsmithFeature } from 'react-native-flagsmith/types'

export enum FeatureFlagNames {
  AddRouteOfAdminToNotes = 'add_route_of_admin_to_notes', // Used by 2 segment overrides
  AutofillMedQtyBilledPerTask = 'autofill_medicine_quantity_billed_per_task', // Always on but turn off for demo eu and rood_and_riddle
  DefaultDayFluidRate = 'setting_default_day_fluid_rate', // Used by a single site in segment override
  DisplayWalkMe = 'display_walkme',
  OutageBlockUI = 'outage_block_ui', // Used for outage, disable login etc. for when VR is under maintenance
  ReadOnlyMode = 'read_only_mode', // Disable mutation when VR is under maintenance
  ShowDeletedOnPurpose = 'show_deleted_on_purpose',
  VRAdminControl = 'vet_radar_admin_controls',
  IS_SEARCH_PRODUCT_FROM_RDS = 'is_search_product_from_rds',
  TRAINING_SITE = 'training_site', // Used by single override, spoke to George about turning on for all users
  ROUTE_OF_ADMINISTRATION_SETTING = 'route_of_administration_setting',
  ENABLE_PROCEDURE_UI = 'enable_procedure_ui',
  ENABLE_BUNDLE_UI = 'enable_bundle_ui',
  GENERATE_SHEET_REPORT = 'generate_sheet_report',
  AUTHENTICATION_V2 = 'authentication_v2_enabled',
}

export type FlagSmithFlags = {
  [key in FeatureFlagNames]: IFlagsmithFeature
}

export const DEFAULT_FEATURE_FLAGS = {
  [FeatureFlagNames.AddRouteOfAdminToNotes]: { enabled: false },
  [FeatureFlagNames.AutofillMedQtyBilledPerTask]: { enabled: true },
  [FeatureFlagNames.DefaultDayFluidRate]: { enabled: false },
  [FeatureFlagNames.DisplayWalkMe]: { enabled: true },
  [FeatureFlagNames.OutageBlockUI]: { enabled: false },
  [FeatureFlagNames.ReadOnlyMode]: { enabled: false },
  [FeatureFlagNames.ShowDeletedOnPurpose]: { enabled: false },
  [FeatureFlagNames.VRAdminControl]: { enabled: false },
  [FeatureFlagNames.IS_SEARCH_PRODUCT_FROM_RDS]: { enabled: false },
  [FeatureFlagNames.TRAINING_SITE]: { enabled: false },
  [FeatureFlagNames.ROUTE_OF_ADMINISTRATION_SETTING]: { enabled: false },
  [FeatureFlagNames.ENABLE_PROCEDURE_UI]: { enabled: false },
  [FeatureFlagNames.ENABLE_BUNDLE_UI]: { enabled: false },
  [FeatureFlagNames.GENERATE_SHEET_REPORT]: { enabled: false },
  [FeatureFlagNames.AUTHENTICATION_V2]: { enabled: false },
}
