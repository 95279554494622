import React from 'react'
import { noop } from 'lodash'
import { StyleSheet, Text, TextInput, View, ViewStyle } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { Status } from '../Form/Status'
import { InputStatus } from '../Form/utils'

type Props = {
  children?: React.ReactElement | null
  disabled?: boolean
  error?: string
  label: string
  labelStyle?: ViewStyle
  onChangeText?: (text: string) => void
  value: string
  visibleLines?: number
  warning?: string
  required?: boolean
  status?: InputStatus | null
}

const lineHeight = 22
const paddingVertical = 10

export const TextArea: React.FC<Props> = ({
  disabled = false,
  error,
  label,
  labelStyle,
  onChangeText = noop,
  value,
  visibleLines = 2,
  warning,
  required = false,
  children,
  status,
}) => {
  const hasMsg = !!error || !!warning
  const height = visibleLines * lineHeight
  const textInputHeight = height - paddingVertical * 2

  return (
    <View>
      <View style={styles.headerContainer}>
        <View style={[styles.labelContainer, labelStyle]}>
          <Text style={styles.label}>{label}</Text>
          {required ? <Text style={styles.requiredLabel}>*</Text> : null}
        </View>
        {children}
      </View>
      <Status status={status}>
        <View
          style={[
            styles.container,
            hasMsg && styles.withMsg,
            !!warning && styles.withWarning,
            !!error && styles.withError,
            { height },
          ]}
        >
          <TextInput
            accessibilityLabel={`${label} Text Input`}
            multiline={true}
            numberOfLines={visibleLines}
            style={[
              styles.value,
              !!warning && styles.warning,
              !!error && styles.error,
              { height: textInputHeight },
            ]}
            onChangeText={onChangeText}
            value={value}
            editable={!disabled}
          />
        </View>
      </Status>
      {!!error && <Text style={[styles.msg, styles.error]}>{error}</Text>}
      {!!(!error && warning) && (
        <Text style={[styles.msg, styles.warning]}>{warning}</Text>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical,
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    paddingHorizontal: 16,
  },
  label: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 15,
    lineHeight: 19,
  },
  withMsg: {
    borderLeftWidth: 8,
    paddingLeft: 8,
  },
  withError: {
    borderLeftColor: Colors.error,
  },
  withWarning: {
    borderLeftColor: Colors.warning,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labelContainer: {
    paddingBottom: 10,
    paddingHorizontal: 16,
    paddingTop: 20,
  },
  value: {
    lineHeight,
    paddingHorizontal: 3,
    marginLeft: -3,
    fontFamily: Fonts.regular,
    fontSize: 16,
    width: '100%',
    textAlignVertical: 'top',
  },
  msg: {
    fontFamily: Fonts.regular,
    marginTop: 5,
    marginBottom: 9,
    fontSize: 13,
    lineHeight: 19,
    paddingHorizontal: 16,
  },
  error: {
    color: Colors.error,
  },
  warning: {
    color: Colors.warning,
  },
  requiredLabel: {
    color: 'red',
    marginTop: 0,
  },
})
