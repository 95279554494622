import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { Button } from './Button'
import { Colors, Variables } from 'src/design-system/theme'

type FooterProps = {
  onSubmit: () => void
  canSubmit: boolean
}

export const Footer: React.FC<FooterProps> = ({ onSubmit, canSubmit }) => {
  const { t } = useTranslation()

  return (
    <View style={styles.saveFooter}>
      <View style={styles.saveFooterWrapper}>
        <Button
          disabled={!canSubmit}
          title={t('general.saveChanges')}
          onPress={onSubmit}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  saveFooter: {
    borderTopColor: Colors.Borders.primary,
    borderTopWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveFooterWrapper: {
    marginVertical: Variables.GutterSpacing.md,
  },
})
