import { GestureResponderEvent } from 'react-native'

/**
 * Prevents click propagation to the parent element
 * @param e
 */
export const preventClickPropagation = (e: GestureResponderEvent) => {
  e.stopPropagation()
  e.preventDefault()
}
