import React from 'react'
import { Colors } from 'constants/Colors'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { Fonts } from 'constants/Fonts'
import { StyleSheet, Text, TextStyle, View } from 'react-native'
import flagsmith from 'react-native-flagsmith'
import { Status } from 'types/globalTypes'

import {
  SvgCheck,
  SvgClock,
  SvgComment,
  SvgExclamation,
  SvgInProgress,
  SvgMeatballs,
  SvgPendingClock,
  SvgSkipped,
} from '../Icons'
import { ParameterRange } from './Task'
import { checkValueIsOutsideOfRange } from './utils/checkValueIsOutsideOfRange'

export const TaskButtonContent: React.FC<{
  comment?: string | null
  isBigComment?: boolean
  isLoading?: boolean
  status: Status | null
  value?: string
  isDiscontinued?: boolean | null
  isBilled?: boolean | null
  range?: ParameterRange
}> = ({
  comment,
  isBigComment,
  isLoading,
  status,
  value,
  isDiscontinued,
  isBilled,
  range,
}) => {
  if (isLoading) {
    return <SvgMeatballs color={Colors.contentTertiary} size={24} />
  }

  return (
    <>
      <ButtonIconOrText
        status={status}
        value={value}
        range={range}
        isDiscontinued={isDiscontinued}
      />
      {isBilled ? (
        <View style={styles.billedIndicatorStyle} testID="TaskIsBilled">
          <SvgComment
            height={isBigComment ? 13 : 10}
            width={isBigComment ? 15 : 12}
            color={Colors.task.billed}
          />
        </View>
      ) : null}
      {!!comment && (
        <View style={styles.noteIndicatorStyle} testID="TaskHasNotes">
          <SvgComment
            height={isBigComment ? 13 : 10}
            width={isBigComment ? 15 : 12}
          />
        </View>
      )}
    </>
  )
}

const CompletedTaskText: React.FC<{ value: string; style?: TextStyle }> = ({
  value,
  style,
}) => (
  <Text numberOfLines={2} ellipsizeMode="tail" style={[styles.taskText, style]}>
    {value}
  </Text>
)

const ButtonIconOrText: React.FC<{
  status: Status | null
  value?: string
  isDiscontinued?: boolean | null
  range?: ParameterRange
}> = ({ status, value, isDiscontinued, range }) => {
  const renderOutRangeText = (value: string) => {
    return checkValueIsOutsideOfRange(value, range) ? (
      <CompletedTaskText value={value} style={styles.taskOutsideRangeText} />
    ) : (
      <CompletedTaskText value={value} />
    )
  }
  switch (status) {
    case Status.PENDING:
      return (
        <SvgPendingClock color={Colors.task.pending} width={18} height={18} />
      )
    case Status.DUE:
      return <SvgClock color={Colors.task.due} width={16} height={16} />
    case Status.MISSED:
      return isDiscontinued ? (
        <Text style={styles.discontinuedText}>{'D/C'}</Text>
      ) : (
        <SvgExclamation color={Colors.task.missed} />
      )
    case Status.MISSED_ON_PURPOSE:
      return <SvgSkipped color={Colors.task.missedOnPurpose} length={18} />
    case Status.DONE: // return icon if no done text
      return !!value ? (
        renderOutRangeText(value)
      ) : (
        <SvgCheck color={Colors.task.done} />
      )
    case Status.IN_PROGRESS: // return icon if no done text
      return !!value ? (
        renderOutRangeText(value)
      ) : (
        <SvgInProgress color={Colors.task.done} />
      )
    case Status.DELETED_ON_PURPOSE:
      return flagsmith.hasFeature(FeatureFlagNames.ShowDeletedOnPurpose) ? (
        <Text style={styles.taskText}>DOP</Text>
      ) : null
    default:
      return null
  }
}

const styles = StyleSheet.create({
  billedIndicatorStyle: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  noteIndicatorStyle: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    transform: [
      {
        scaleY: -1,
      },
    ],
  },
  taskText: {
    color: Colors.task.text,
    fontSize: 14,
    fontFamily: Fonts.regular,
    overflow: 'hidden',
    textAlign: 'center',
  },
  taskOutsideRangeText: {
    color: Colors.contentNegative,
    fontFamily: Fonts.bold,
  },
  discontinuedText: {
    color: Colors.task.missed,
    fontSize: 14,
    fontFamily: Fonts.regular,
  },
})
