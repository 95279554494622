import { useEffect } from 'react'
import { Dimensions } from 'react-native'

export const useResizeSubscription = (handleLayoutChange: () => void) => {
  useEffect(() => {
    const resizeSubscription = Dimensions.addEventListener(
      'change',
      handleLayoutChange,
    )
    return () => {
      resizeSubscription.remove()
    }
  }, [handleLayoutChange])
}
