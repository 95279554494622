import React, { useEffect, useRef, useState } from 'react'
import {
  Animated,
  Easing,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native'

import { Portal, PortalHost } from '@gorhom/portal'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { SvgArrow, SvgClose } from 'src/components/Icons'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { getDrawerWidth } from './getDrawerWidth'
import { ConfirmProvider } from 'src/context/confirm'
import { useTreatmentDrawerContext } from 'src/context/treatmentDrawer'

const SIDE_DRAWER_PORTAL_NAME = 'SIDE_DRAWER_PORTAL'

export type Props = {
  title?: string
  visible: boolean
  onClose: () => void
  children: React.ReactNode
  titleStyle?: TextStyle
  topOffset?: number
  name?: string
  back?: {
    text: string
    accessibilityLabel?: string
  }
}

const isTargetInAppContainer = (target: EventTarget | null) => {
  // All third-part modal are out side of app-container.
  return document.getElementById('app-container')?.contains(target as Node)
}

const EASING_IN_TIME = 150
const CLOSE_TIME = 120
const EASING_OUT_TIME = 80

export const SideDrawerPortal: React.FC = () => (
  <PortalHost name={SIDE_DRAWER_PORTAL_NAME} />
)

/**
 * Can't be nested or open inside a Modal.
 */
export const SideDrawer: React.FC<Props> = ({
  children,
  title,
  visible,
  onClose,
  titleStyle,
  topOffset,
  back,
}) => {
  const width = getDrawerWidth()

  const contentContainerRef = useRef<View>(null)
  const [isAnimationOpen, setIsAnimationOpen] = useState(false)

  const [animatedWidth] = useState(new Animated.Value(width))

  const [{ shouldShowTabs, handleBack }] = useTreatmentDrawerContext()

  // Close on Escape
  useEffect(() => {
    if (Platform.OS !== 'web') {
      return
    }
    const closeOnEscape = (e: KeyboardEvent) => {
      if (visible && e.key === 'Escape') {
        e.stopPropagation()
        if (onClose) {
          onClose()
        }
      }
    }
    document.addEventListener('keyup', closeOnEscape, false)
    return () => document.removeEventListener('keyup', closeOnEscape, false)
  }, [onClose, visible])

  useEffect(() => {
    const toValue = visible ? width : 0
    const animationProps = {
      toValue,
      useNativeDriver: false, // TODO: Animate translateY instead to support native
    }

    if (visible) {
      Animated.timing(animatedWidth, {
        ...animationProps,
        duration: EASING_IN_TIME,
        easing: Easing.out(Easing.exp),
      }).start()
    } else {
      Animated.timing(animatedWidth, {
        ...animationProps,
        duration: EASING_OUT_TIME,
        easing: Easing.in(Easing.exp),
      }).start()
    }

    const timer = setTimeout(() => {
      setIsAnimationOpen(visible)
    }, CLOSE_TIME) //  Close time should longer than animation out
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const insets = useSafeAreaInsets()

  useEffect(() => {
    if (Platform.OS !== 'web' || !isAnimationOpen) {
      return
    }
    const listener = (event: Event) => {
      if (!contentContainerRef.current) {
        return
      }
      if (isTargetInAppContainer(event.target)) {
        // close if click outside of this drawer content
        onClose()
        event.stopPropagation()
      }
    }
    // Click event might get fired programmatically inside {children}?
    // Using RAF to prevent it from being closed on open..
    requestAnimationFrame(() =>
      window.addEventListener('click', listener, true),
    )

    return () => window.removeEventListener('click', listener, true)
  }, [isAnimationOpen, onClose])

  return (
    <Portal hostName={SIDE_DRAWER_PORTAL_NAME}>
      {visible || isAnimationOpen ? (
        <>
          {/* For web, allow scrolling under the drawer */}
          {Platform.OS !== 'web' && (
            <TouchableOpacity style={styles.backdrop} onPress={onClose} />
          )}
          <Animated.View
            style={[
              styles.content,
              { width: animatedWidth },
              topOffset ? { top: topOffset } : {},
            ]}
          >
            <ConfirmProvider>
              <View
                style={[
                  styles.contentContainer,
                  {
                    paddingTop: insets.top,
                    paddingBottom: insets.bottom,
                  },
                ]}
                ref={contentContainerRef}
              >
                {visible && isAnimationOpen ? (
                  <>
                    {!!title && (
                      <View style={styles.header}>
                        <View
                          style={[
                            styles.headerNavigation,
                            back && handleBack
                              ? styles.multipleNavIcons
                              : styles.singleNavIcon,
                          ]}
                        >
                          {!!(back && handleBack) && (
                            <TouchableOpacity
                              onPress={handleBack}
                              style={styles.backButton}
                              accessibilityLabel={
                                back.accessibilityLabel ||
                                `back to ${back.text}`
                              }
                            >
                              <SvgArrow
                                orient="left"
                                color="black"
                                height={18}
                                width={18}
                              />
                            </TouchableOpacity>
                          )}
                          <TouchableOpacity onPress={onClose}>
                            <SvgClose color="black" length={18} />
                          </TouchableOpacity>
                        </View>
                        {shouldShowTabs ? (
                          <Text
                            numberOfLines={1}
                            style={[styles.titleText, titleStyle]}
                          >
                            {title}
                          </Text>
                        ) : null}
                      </View>
                    )}
                    {children}
                  </>
                ) : null}
              </View>
            </ConfirmProvider>
          </Animated.View>
        </>
      ) : null}
    </Portal>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    ...(Platform.OS === 'web' && { cursor: 'auto' }),
  },
  content: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#fff',
    // shadow generated from https://ethercreative.github.io/react-native-shadow-generator/
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
    elevation: 15,
    width: '100%',
  },
  contentContainer: {
    flex: 1,
    backgroundColor: Colors.backgroundGrey,
  },
  header: {
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  headerNavigation: {
    flexDirection: 'row',
  },
  backButton: {
    flexDirection: 'row',
  },
  titleText: {
    fontSize: 24,
    fontFamily: Fonts.regular,
    color: Colors.contentSecondary,
    textAlign: 'center',
  },
  singleNavIcon: {
    justifyContent: 'flex-end',
  },
  multipleNavIcons: {
    justifyContent: 'space-between',
  },
})
