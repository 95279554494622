import React, { useMemo } from 'react'
import { Modal } from 'components/common/Modal'
import { StyleProp, ViewStyle } from 'react-native/types'

import { useBreakpoint } from 'src/hocs/breakpoint'
import { IntroductionModalContent } from './IntroductionModalContent'

const MODAL_CONTENT_WIDTH_BREAK_POINT = 550
const SMALL_SCREEN_CONTENT_WIDTH = '90%'
const LARGE_SCREEN_CONTENT_WIDTH = 500

export const IntroductionModal: React.FC<{
  visible: boolean
  navigateToTrainingSite: () => void
  onClose: () => void
}> = ({ visible, navigateToTrainingSite, onClose }) => {
  // Stolen from Help Modal
  const { width } = useBreakpoint()
  const contentStyle: StyleProp<ViewStyle> = useMemo(() => {
    const contentWidth =
      width < MODAL_CONTENT_WIDTH_BREAK_POINT
        ? SMALL_SCREEN_CONTENT_WIDTH
        : LARGE_SCREEN_CONTENT_WIDTH
    return {
      width: contentWidth,
    }
  }, [width])
  return (
    <Modal visible={visible} onCloseModal={onClose} contentStyle={contentStyle}>
      <IntroductionModalContent
        navigateToTrainingSite={navigateToTrainingSite}
        onClose={onClose}
      />
    </Modal>
  )
}
