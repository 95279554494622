import React from 'react'
import { SvgIVFluidStart } from 'components/Icons/IVFluidStart'
import { SvgIVFluidScheduledStart } from 'components/Icons/IVFluidScheduledStart'
import { SvgIVFluidStop } from 'components/Icons/IVFluidStop'
import { SvgIVFluidScheduledStop } from 'components/Icons/IVFluidScheduledStop'
import { SvgFluidFluidRateChange } from 'components/Icons/IVFluidRateChange'
import { SvgFluidScheduledFluidRateChange } from 'components/Icons/IVFluidScheduledRateChange'
import { FluidActionType } from 'types/globalTypes'

type FluidStatusIconProps = { status: FluidActionType }

export const FluidStatusIcon: React.FC<FluidStatusIconProps> = ({ status }) => {
  switch (status) {
    case FluidActionType.START:
      return <SvgIVFluidStart />
    case FluidActionType.SCHEDULED_START:
      return <SvgIVFluidScheduledStart />
    case FluidActionType.STOP:
      return <SvgIVFluidStop />
    case FluidActionType.SCHEDULED_STOP:
      return <SvgIVFluidScheduledStop />
    case FluidActionType.RATE_CHANGE:
      return <SvgFluidFluidRateChange />
    case FluidActionType.SCHEDULED_RATE_CHANGE:
      return <SvgFluidScheduledFluidRateChange />

    default:
      return null
  }
}
