export enum local_toggle_type {
  SHOWMORE = 'SHOWMORE',
  SHOWWORKFLOW = 'SHOWWORKFLOW',
  SHOWDRAWER = 'SHOWDRAWER',
  SHOWPANEL = 'SHOWPANEL',
}

export enum local_toggle_key {
  IS_SHOW_MORE_DETAILS_KEY = 'IS_SHOW_MORE_DETAILS_KEY',
  IS_SHOW_WORKFLOW_KEY = 'IS_SHOW_WORKFLOW_KEY',
  SHOULD_SHOW_DRAWER = 'SHOULD_SHOW_DRAWER',
}

export enum card_status_type {
  SHOULD_SHOW_NOTES = 'SHOULD_SHOW_NOTES',
  SHOULD_SHOW_ANESTHESIA_NOTES = 'SHOULD_SHOW_ANESTHESIA_NOTES',
  SHOULD_SHOW_SHEET_DETAILS = 'SHOULD_SHOW_SHEET_DETAILS',
  SHOULD_SHOW_PRESENTING_PROBLEM = 'SHOULD_SHOW_PRESENTING_PROBLEM',
  SHOULD_SHOW_PATIENT_INFO = 'SHOULD_SHOW_PATIENT_INFO',
  SHOULD_SHOW_OWNER_DETAILS = 'SHOULD_SHOW_OWNER_DETAILS',
  SHOULD_SHOW_CALL_PARAMETERS = 'SHOULD_SHOW_CALL_PARAMETERS',
}

type StatusType = {
  SHOULD_SHOW_NOTES: boolean
  SHOULD_SHOW_ANESTHESIA_NOTES: boolean
  SHOULD_SHOW_SHEET_DETAILS: boolean
  SHOULD_SHOW_PRESENTING_PROBLEM: boolean
  SHOULD_SHOW_PATIENT_INFO: boolean
  SHOULD_SHOW_OWNER_DETAILS: boolean
  SHOULD_SHOW_CALL_PARAMETERS: boolean
}

export const card_status: StatusType = {
  SHOULD_SHOW_NOTES: false,
  SHOULD_SHOW_ANESTHESIA_NOTES: true,
  SHOULD_SHOW_SHEET_DETAILS: false,
  SHOULD_SHOW_PRESENTING_PROBLEM: false,
  SHOULD_SHOW_PATIENT_INFO: false,
  SHOULD_SHOW_OWNER_DETAILS: false,
  SHOULD_SHOW_CALL_PARAMETERS: false,
}
