import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, toast } from 'components/common'
import { Task } from 'components/Task/types'
import { useRescheduleTasks } from 'components/Task/utils/useRescheduleTasks'
import {
  BulkTasks,
  SubTreatmentAndFirstTask,
} from 'components/Treatment/utils/getSubTreatmentsNearestFirstTasks'
import { Colors } from 'constants/Colors'
import { compact, keyBy, omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { ApprovalStatus, Status, UpdateTaskInput } from 'src/types/globalTypes'

import { BulkTaskList } from './BulkTaskList'
import { TabName } from './common'
import { BulkTaskTabHeader } from './BulkTaskTabHeader'
import {
  Inputs,
  Ref as RescheduleRef,
  RescheduleDateTime,
} from './RescheduleDateTime'
import { useBulkUpdateTasks } from './utils/useBulkUpdateTasks'
import { useRescheduleTreatments } from './utils/useRescheduleTreatments'
import { useBulkDeleteTasks } from './utils/useBulkDeleteTasks'
import { useForm } from 'react-hook-form'
import { useCheckNotes } from './utils/useCheckNotes'
import { isCompleteOrSkipTab, isDeletedTab } from './utils/getTabStatus'
import { useShouldDisableComplete } from './utils/useShouldDisableComplete'
import { useCacheTreatment } from 'components/Treatment/utils/useCacheTreatment'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { usePatientCallParameters } from 'src/hooks/usePatientCallParameters'
import { checkValueIsOutsideOfRange } from 'components/Task/utils/checkValueIsOutsideOfRange'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as CheckTaskType } from 'src/types/getSheet'

type Props = {
  bulkTasks: BulkTasks | null
  children?: React.ReactElement | null
  patientId?: string
  sheetId: string
  toggleDrawer: () => void
}

export const DISPLAY_DURATION = 3000

export const generateTaskInput = (curTask: Task, status: Status) => {
  curTask.status = status
  const taskInput: UpdateTaskInput = omit(curTask, [
    '_pending',
    'send_to_adaptor',
    'start_at',
    'stop_at',
    'created_at',
    'billing_reference',
    'updated_at',
    'updated_by',
    'attending_vet',
    'attending_department',
    'medicine_dosage_info',
    'user_updated_at',
    'cubex_transaction_status',
  ])
  return taskInput
}

export type BulkTasksKeyedByTaskID = { [key: string]: SubTreatmentAndFirstTask }

export const bulkTasksA11yLabel = 'Bulk Task Completion Dialog'

const getCheckedTasks = (
  bulkTasks: BulkTasksKeyedByTaskID,
  action: TabName,
) => {
  const actionMap: { [action in TabName]: string } = {
    [TabName.DELETE]: 'deleteChecked',
    [TabName.COMPLETE]: 'completeChecked',
    [TabName.SKIP]: 'completeChecked',
    [TabName.RESCHEDULE]: 'checked',
  }

  return compact(
    Object.entries(bulkTasks).map(([_, bulkTask]) => {
      const isTaskChecked = (bulkTask as any)[actionMap[action]]
      return isTaskChecked ? bulkTask.nextTaskToComplete : null
    }),
  )
}

export const BulkTaskActions: React.FC<Props> = ({
  bulkTasks,
  patientId = '',
  sheetId,
  toggleDrawer,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const rescheduleRepeating = useRescheduleTreatments()
  const rescheduleTasks = useRescheduleTasks()
  const bulkUpdateTasks = useBulkUpdateTasks()
  const bulkDeleteTasks = useBulkDeleteTasks()

  const rescheduleRef = useRef<RescheduleRef>(null)

  const [currentTab, setCurrentTab] = useState(TabName.COMPLETE)
  const [dateOrRescheduleMinutes, setDateOrRescheduleMinutes] = useState<
    Date | number
  >(0)

  const rescheduleChangeHandler = (value: Date | number) => {
    setDateOrRescheduleMinutes(value)
  }
  const getPatientCallParameterRangeByProductID =
    usePatientCallParameters(patientId)

  // isSmallScreen is for small iPad which have same drawer width(300px) as iPhone.
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isXsOrSmScreen = isExSmallScreen || isSmallScreen

  const initialValues: BulkTasksKeyedByTaskID = useMemo(
    () =>
      keyBy(
        bulkTasks?.map(task => ({
          ...task,
          checked: false,
          completeChecked: false,
          deleteChecked: false,
          newStartDate: new Date(),
        })),
        'nextTaskToComplete.id',
      ),
    [bulkTasks],
  )

  const checkCreatedTaskValueRange = (
    allTasks: BulkTasksKeyedByTaskID,
    checkedTasks: CheckTaskType[],
  ) => {
    checkedTasks.map(task => {
      const taskId = task.id
      const callParamValueRange = getPatientCallParameterRangeByProductID(
        allTasks[taskId].treatment.product?.id,
      )
      const isOverflowed = checkValueIsOutsideOfRange(
        task.value,
        callParamValueRange,
      )
      if (isOverflowed) {
        setTimeout(() => {
          toast.notice(
            `${task.value} is not in the range of the ${allTasks[taskId].treatment.name} call parameter`,
            undefined,
            DISPLAY_DURATION,
          )
        }, 100)
      }
    })
  }

  const onComplete = (allTasks: BulkTasksKeyedByTaskID) => {
    const checkedTasks = getCheckedTasks(allTasks, TabName.COMPLETE)
    const taskAndInputArray = checkedTasks.map(task => {
      const taskInput = generateTaskInput(task, Status.DONE)
      return { taskInput, task }
    })
    bulkUpdateTasks(taskAndInputArray)
    checkCreatedTaskValueRange(allTasks, checkedTasks)
  }

  const onDelete = (allTasks: BulkTasksKeyedByTaskID) => {
    const checkedTasks = getCheckedTasks(allTasks, TabName.DELETE)
    const deleteTasksInput = checkedTasks.map(task => ({
      id: task.id,
      organisation_id: organisationId,
      sheet_id: sheetId,
      treatment_id: task.treatment_id,
    }))
    bulkDeleteTasks(deleteTasksInput)
  }

  const onReschedule = (allTasks: BulkTasksKeyedByTaskID) => {
    const checkedTasks = getCheckedTasks(allTasks, TabName.RESCHEDULE)
    rescheduleTasks(checkedTasks, dateOrRescheduleMinutes)
  }

  const onRescheduleRepeating = (
    allTasks: BulkTasksKeyedByTaskID,
    dataInputs: Inputs,
  ) => {
    rescheduleRepeating(allTasks, dateOrRescheduleMinutes, dataInputs)
  }

  const handleReschedule = (allTasks: BulkTasksKeyedByTaskID) => {
    return rescheduleRef.current?.handleSubmit((dataInputs: Inputs) => {
      try {
        if (dataInputs.isRepeating) {
          onRescheduleRepeating(allTasks, dataInputs)
        } else {
          onReschedule(allTasks)
        }
      } catch (err) {
        if (err instanceof Error) toast.error(err.message, null, err)
      }
    })()
  }

  const onSkip = (allTasks: BulkTasksKeyedByTaskID) => {
    const checkedTasks = getCheckedTasks(allTasks, TabName.SKIP)
    const taskAndInputArray = checkedTasks.map(task => {
      const taskInput = generateTaskInput(task, Status.MISSED_ON_PURPOSE)
      return { taskInput, task }
    })
    bulkUpdateTasks(taskAndInputArray)
  }

  const isCompleteOrSkip = useMemo(() => {
    return isCompleteOrSkipTab(currentTab)
  }, [currentTab])

  const { getCacheTreatmentById } = useCacheTreatment()

  const shouldDisableSubmitByApproval = useCallback(
    (allTasks: BulkTasksKeyedByTaskID) => {
      if (!isCompleteOrSkip) {
        return false
      }

      return Object.values(allTasks)
        .filter(value => value.completeChecked)
        .some(value => {
          const cacheTreatment =
            getCacheTreatmentById(value.treatment.id) ?? value.treatment

          return cacheTreatment.approval_status === ApprovalStatus.PENDING
        })
    },
    [getCacheTreatmentById, isCompleteOrSkip],
  )

  const onSubmit = (allTasks: BulkTasksKeyedByTaskID) => {
    if (shouldDisableSubmitByApproval(allTasks)) {
      toast.error(t('treatment:approval:failedToCompleteAction'))
      return
    }

    switch (currentTab) {
      case TabName.COMPLETE:
        onComplete(allTasks)
        break
      case TabName.DELETE:
        onDelete(allTasks)
        break
      case TabName.RESCHEDULE:
        handleReschedule(allTasks)
        break
      case TabName.SKIP:
        onSkip(allTasks)
        break
    }
    toggleDrawer()
  }

  const {
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<BulkTasksKeyedByTaskID>({
    defaultValues: initialValues,
    mode: 'onChange',
  })

  const bulkTaskValues = watch()

  const checkNotes = useCheckNotes({
    tasks: bulkTaskValues,
    setError,
    clearErrors,
    currentTab,
  })

  const handleChange = (field: string, value: any) => {
    setValue(field, value)
    if (isCompleteOrSkip) {
      checkNotes()
    }
  }

  const onPressTab = (tab: TabName) => {
    setCurrentTab(tab)
    reset(initialValues)
  }

  useEffect(() => {
    checkNotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasError = !!Object.keys(errors).length

  const isDelete = useMemo(() => {
    return isDeletedTab(currentTab)
  }, [currentTab])

  const numberOfTasksSelected = useMemo(() => {
    const valuesArray = Object.values(bulkTaskValues)
    return valuesArray.filter(value => {
      if (isDelete) {
        return value.deleteChecked
      }

      if (isCompleteOrSkip) {
        return value.completeChecked
      }

      return value.checked
    }).length
  }, [bulkTaskValues, isCompleteOrSkip, isDelete])

  const shouldDisableComplete = useShouldDisableComplete({
    hasError,
    hasTaskSelected: numberOfTasksSelected > 0,
    bulkTasks: bulkTaskValues,
    isBulkTaskActions: true,
    currentTab,
  })

  return (
    <>
      <KeyboardAwareScrollView
        style={styles.container}
        accessibilityLabel={bulkTasksA11yLabel}
      >
        <BulkTaskTabHeader
          currentTab={currentTab}
          isSmall={isXsOrSmScreen}
          onPress={onPressTab}
        >
          {currentTab === TabName.RESCHEDULE ? (
            <RescheduleDateTime
              onRescheduleChange={rescheduleChangeHandler}
              ref={rescheduleRef}
            />
          ) : null}
        </BulkTaskTabHeader>
        <View style={styles.taskList}>
          <BulkTaskList
            treatmentTasks={bulkTaskValues}
            handleChange={handleChange}
            dateOrRescheduleMinutes={dateOrRescheduleMinutes}
            currentTab={currentTab}
            patientId={patientId}
          />
        </View>
      </KeyboardAwareScrollView>
      <View style={styles.footer}>
        <Button
          a11yLabel={
            isDelete
              ? t('task:taskTransition.bulkDelete:a11y')
              : t('task:taskTransition.bulkSubmit:a11y')
          }
          disabled={shouldDisableComplete}
          onPress={() => onSubmit(bulkTaskValues)}
          title={`${t(`general.${currentTab}`)} ${t(`general.task`, {
            count: numberOfTasksSelected,
          })}`}
          color={isDelete ? Colors.contentNegative : undefined}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 'auto',
  },
  taskList: {
    paddingHorizontal: 30,
    width: '100%',
  },
  footer: {
    paddingVertical: 15,
  },
})
