import React, { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { toast } from 'components/common'
import { SelectOption } from 'components/common/Select/Select.types'
import { SYNC_WITH_CONSULT_PLACEHOLDER } from 'constants/ClinicalRecord'
import { isEmpty, sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { GET_USERS_LIST } from 'src/components/Settings/graphql'
import { useOrganisation } from 'src/context/organisation'
import { SingleSelect } from 'src/design-system/components/Selects'
import { useAssociatedSiteIds } from 'src/hooks/useAssociatedSiteIds'
import { getUsersList, getUsersListVariables } from 'src/types/getUsersList'
import { OriginTypes, veterinary_roles } from 'src/types/globalTypes'

type UserSelectOptions = SelectOption<string>

type Props = {
  disabled?: boolean
  onSelectedUserChange?: (selectedUser: string | null) => void
  selectedUser: string | null
  type: veterinary_roles
  isOwner?: boolean
  hasSyncWithConsultVetId?: boolean
  hideChevron?: boolean
  allowClear?: boolean
  sheetAttendingDepartmentId?: string | null
  consultVetId?: string | null
}

export const UserSection: React.FC<Props> = React.memo(
  ({
    onSelectedUserChange,
    type,
    selectedUser,
    disabled,
    isOwner,
    hasSyncWithConsultVetId = false,
    hideChevron = false,
    allowClear = true,
    sheetAttendingDepartmentId,
    consultVetId,
  }) => {
    const { t } = useTranslation()
    const [{ organisationId }] = useOrganisation()

    const { data: usersData, loading } = useQuery<
      getUsersList,
      getUsersListVariables
    >(GET_USERS_LIST, {
      fetchPolicy: 'cache-and-network', // see comment on GET_USERS_LIST
      variables: {
        organisation_id: organisationId,
        veterinary_role: type,
        origin_type: OriginTypes.EZYVET, // only getting ezyvetUsers
      },
    })

    const attendingDeptList = useMemo(
      () => (!!sheetAttendingDepartmentId ? [sheetAttendingDepartmentId] : []),
      [sheetAttendingDepartmentId],
    )

    const { associatedSiteIds } = useAssociatedSiteIds(attendingDeptList)

    const handleSelectedUserChange = (value: string | null) => {
      if (!onSelectedUserChange) return
      if (
        value === SYNC_WITH_CONSULT_PLACEHOLDER &&
        !isCaseOwnerInCurrentAttendingDepartment
      ) {
        toast.error(t('sheetForm.sameAsEzyvetDoctorError'))
        return
      }
      onSelectedUserChange(value)
    }

    useEffect(() => {
      const currentSelectedUser =
        selectedUser === SYNC_WITH_CONSULT_PLACEHOLDER
          ? consultVetId
          : selectedUser
      const selectedUserDetails = usersData?.getUsersList?.items.find(
        user => user.id === currentSelectedUser,
      )
      if (
        !sheetAttendingDepartmentId ||
        !selectedUserDetails ||
        isEmpty(selectedUserDetails.sites) ||
        isEmpty(associatedSiteIds)
      )
        return
      const isUserAvailableInNewDept = selectedUserDetails.sites?.some(
        id => associatedSiteIds![id],
      )
      if (!isUserAvailableInNewDept) {
        !!onSelectedUserChange && onSelectedUserChange(null)
      }
    }, [
      associatedSiteIds,
      consultVetId,
      onSelectedUserChange,
      selectedUser,
      sheetAttendingDepartmentId,
      usersData?.getUsersList?.items,
    ])

    // TODO: VR-4374 - sorting this on frontend as providing pageInput with sort
    // key does not sort as expected on backend
    const selectableUsers: UserSelectOptions[] = useMemo(() => {
      const rawUsers = usersData?.getUsersList?.items ?? []
      const filteredUsers = isEmpty(associatedSiteIds)
        ? rawUsers
        : rawUsers.filter(user =>
            user.sites?.some(id => associatedSiteIds![id]),
          )
      const users = sortBy(filteredUsers, 'full_name')
      const userSelectOptions = users.map(({ full_name, id }) => ({
        value: id,
        text: full_name,
      }))
      /**
       * 1. Only Add the sync_with_consult option when hasSyncWithConsultVetId is true
       * 2. Why didn't we set consult.attending_vet_id as a value here?
       * Because consult.attending_vet_id might be the value of the sibling options from existing code,
       * and the value needs to be unique here,
       * thus we just set a dumb SYNC_WITH_CONSULT_PLACEHOLDER as the new option value here.
       */
      if (hasSyncWithConsultVetId) {
        userSelectOptions.unshift({
          value: SYNC_WITH_CONSULT_PLACEHOLDER,
          text: t('sheetForm.sameAsCaseOwner'),
        })
      }
      return userSelectOptions
    }, [
      usersData?.getUsersList?.items,
      associatedSiteIds,
      hasSyncWithConsultVetId,
      t,
    ])

    const isCaseOwnerInCurrentAttendingDepartment = useMemo(() => {
      if (!consultVetId || isEmpty(selectableUsers)) return true
      return !!selectableUsers.find(vet => vet.value === consultVetId)
    }, [consultVetId, selectableUsers])

    const returnLabel = () => {
      if (isOwner) return t(`sheetForm.caseOwner`)
      return t(`sheetForm.${type.toLowerCase().split(' ').join('')}`)
    }
    return (
      <View>
        <SingleSelect
          label={returnLabel()}
          options={selectableUsers}
          selected={selectedUser}
          onChange={handleSelectedUserChange}
          loading={!usersData && loading}
          disabled={disabled}
          allowClear={allowClear}
          hideChevron={hideChevron}
        />
      </View>
    )
  },
)
