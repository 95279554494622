import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import { Platform } from 'react-native'
import {
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
} from '@react-navigation/native'
import { Routes } from 'src/constants/Routes'
import { Colors } from 'src/constants/Colors'
import { routingInstrumentation } from 'src/utils/errorTracking/sentry'
import { DdRumReactNavigationTracking } from '@datadog/mobile-react-navigation'
import { environment, ReleaseStage } from 'src/config/environment'
import { navigationRef } from 'components/Base/navigation/navigation'
import { AppScreensConfig } from 'components/Base/screens/AppScreensConfig'

const isWeb = Platform.OS === 'web'

const linking: LinkingOptions = {
  // Using Linking.makeUrl('/') for prefix will crash the App.
  // Since we don't need deeplink from web to native for now, just keep this empty.
  // Will try to figure out how it works when we need it.
  prefixes: [],
  config: {
    // @ts-ignore
    screens: AppScreensConfig,
  },
}

export const RootNavigationContainer: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const theme = useMemo(
    () => ({
      ...DefaultTheme,
      colors: {
        ...DefaultTheme.colors,
        background: Colors.backgroundGrey,
      },
    }),
    [],
  )

  const registerRouteTracking = useCallback(() => {
    // Load sentry and datadog (native only!) tracking if not running locally
    if (!isWeb && environment.releaseStage !== ReleaseStage.localDev) {
      routingInstrumentation?.registerNavigationContainer(navigationRef)
      DdRumReactNavigationTracking.startTrackingViews(navigationRef.current)
    }
  }, [])

  return (
    <NavigationContainer
      linking={linking}
      theme={theme}
      ref={navigationRef}
      onReady={registerRouteTracking}
      documentTitle={{
        formatter: (options, route) => {
          // Sheet screen title set in ReadySheet.tsx
          if (route?.name === Routes.Sheet && !!options?.title) {
            return options.title
          }
          return 'Vet Radar'
        },
      }}
    >
      {children}
    </NavigationContainer>
  )
}
