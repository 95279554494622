import React, { useMemo } from 'react'
import { FormField } from '../Form/FormField'
import { NestedListSelectMenu } from './NestedListSelectMenu'
import { useNestedListSelectMenu } from './useNestedListSelectMenu'
import { isEmpty } from 'lodash'
import { NestedListSelectProps, SubMenu } from './types'
import { PrimaryLevelMenu } from './PrimaryLevelMenu'
import {
  styles,
  primaryMenuHeightCalc,
  primaryMenuWithHeaderHeightCalc,
} from './styles'

export const SingleSelectNestedMenu: React.FC<NestedListSelectProps> = ({
  options,
  onChange,
  selected,
  label,
  loading,
  disabled,
}) => {
  const handleChange = (sites: string[]) => {
    onChange(sites)
    actions.setMenuOpen(false)
  }

  const firstOption = options.length === 1 ? [options[0].value] : null

  const { actions, state } = useNestedListSelectMenu(
    options,
    firstOption ?? selected,
    handleChange,
    loading,
  )

  const primaryMenu: SubMenu = (
    <PrimaryLevelMenu
      listItemStyle={styles.listItem}
      containerStyle={styles.menuCard}
      heightCalc={primaryMenuHeightCalc}
      menuOptions={state.primaryMenuOptions}
      state={state}
      actions={actions}
      liftedFirstOption={true}
    />
  )

  const secondaryMenu: SubMenu = (
    <PrimaryLevelMenu
      listItemStyle={styles.listItem}
      containerStyle={styles.menuCard}
      heightCalc={primaryMenuWithHeaderHeightCalc}
      menuOptions={state.secondaryMenuOptions}
      state={state}
      actions={actions}
      selected={selected}
    />
  )

  const valueText = useMemo(() => {
    if (options.length === 1) {
      return options[0].text
    }
    return !isEmpty(selected)
      ? options.find(option => option.value === selected[0])?.text
      : ''
  }, [options, selected])

  return (
    <>
      <FormField
        value={valueText ?? ''}
        label={label}
        onPress={() => actions.setMenuOpen(!state.isMenuOpen)}
        // Don't let the user remove options when the form is disabled.
        disabled={disabled}
        style={styles.formFieldStyle}
      />
      <NestedListSelectMenu
        label={label}
        state={state}
        actions={actions}
        selected={selected}
        primaryMenu={primaryMenu}
        secondaryMenu={secondaryMenu}
      />
    </>
  )
}
