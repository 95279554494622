import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { omit } from 'lodash'
import { Tag } from 'src/components/Tag/Tag'
import { Routes } from 'src/constants/Routes'

import { useOrganisation } from 'src/context/organisation'
import {
  updateTemplateCallParameter as updateTemplateCallParameterT,
  updateTemplateCallParameterVariables,
} from 'src/types/updateTemplateCallParameter'
import {
  getTemplateCallParameter,
  getTemplateCallParameterVariables,
  getTemplateCallParameter_getTemplateCallParameter_call_parameter_items_items,
} from 'src/types/getTemplateCallParameter'
import { TemplateCallParameterItemInput } from 'src/types/globalTypes'

import { SubHeader } from '../SubHeader/SubHeader'
import {
  UPDATE_CALL_PARAMETER_TEMPLATE,
  GET_CALL_PARAMETER_TEMPLATE,
} from './graphql'
import { Inputs, CallParameterTemplateForm } from './CallParameterTemplateForm'
import { SettingsStackParamList } from 'components/Settings/screens'
import { isOptimisticId } from 'src/utils/optimisticId'

type Props = StackScreenProps<
  SettingsStackParamList,
  Routes.EditCallParameterTemplate
>

export const getUpdatedTemplateCallParameters = (
  organisationId: string,
  originItems: getTemplateCallParameter_getTemplateCallParameter_call_parameter_items_items[],
  formItems: Inputs['items'],
) => {
  const deleteItems = originItems
    .filter(originItem => !formItems.find(item => item?.id === originItem?.id))
    .map(
      item =>
        ({
          ...omit(item, '__typename'),
          organisation_id: organisationId,
          deleted_at: new Date().toISOString(),
        } as TemplateCallParameterItemInput),
    )

  return [
    ...deleteItems,
    ...formItems.map(
      (item, idx) =>
        ({
          organisation_id: organisationId,
          product_id: item.product_id,
          order: idx,
          name: item.name,
          short_name: item.short_name,
          min: item.min,
          max: item.max,
          ...(!isOptimisticId(item.id) && { id: item.id }),
        } as TemplateCallParameterItemInput),
    ),
  ]
}

export const EditCallParameterTemplateScreen: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { navigate } = navigation
  const templateId = route.params.templateId
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { data, loading } = useQuery<
    getTemplateCallParameter,
    getTemplateCallParameterVariables
  >(GET_CALL_PARAMETER_TEMPLATE, {
    fetchPolicy: 'network-only',
    variables: {
      organisationId,
      id: templateId,
    },
  })

  const template = data?.getTemplateCallParameter

  const handleBack = () => navigate(Routes.CallParameterTemplateList)

  const onComplete = (formData: updateTemplateCallParameterT): void => {
    toast.success(
      t('template:update.success', {
        name: formData.updateTemplateCallParameter.name,
      }),
    )
    handleBack()
  }

  const [updateTemplate, { loading: submitting }] = useMutation<
    updateTemplateCallParameterT,
    updateTemplateCallParameterVariables
  >(UPDATE_CALL_PARAMETER_TEMPLATE, {
    onError: () => toast.error(t('template:update.err')),
    onCompleted: onComplete,
  })

  const backButton = {
    action: handleBack,
    title: 'templates.callParameterTitle',
    label: 'templates.returnHereLabel',
  }

  const handleSubmit = async (formData: Inputs) => {
    const callParameterItems = getUpdatedTemplateCallParameters(
      organisationId,
      template?.call_parameter_items?.items ?? [],
      formData.items,
    )
    await updateTemplate({
      variables: {
        input: {
          id: template?.id!,
          organisation_id: organisationId,
          name: formData.name,
          disabled: formData.disabled,
          sites: formData.sites,
          user_id: formData.user_id,
          call_parameter_items: callParameterItems,
        },
      },
    })
  }

  return (
    <>
      <SubHeader
        headline={template?.name}
        subHeadline={
          <View style={styles.tagContainer}>
            <Tag title={t('template:title')} />
            {!!template?.disabled && <Tag title={t('template:disabled')} />}
          </View>
        }
        backButton={backButton}
      />

      {loading ? (
        <ActivityIndicator size="large" style={styles.marginTop} />
      ) : (
        <CallParameterTemplateForm
          template={template!}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  tagContainer: {
    flexDirection: 'row',
  },
  marginTop: {
    marginTop: 25,
  },
})
