import { Platform } from 'react-native'
import {
  completeFileMultiPartUpload,
  getFileUploadPartSignedUrl,
  getFileUploadSignedUrl,
  startFileMultiPartUpload,
} from 'src/utils/file/file'

type KeyAndDataURI = {
  key: string
  dataURI: string
}

type Part = {
  eTag: string
  partNumber: number
}

const MAX_SINGLE_UPLOAD_SIZE = 5 * 1024 * 1024 // 5MB

const singleUpload = async (key: string, blob: Blob): Promise<void> => {
  const uploadSignedUrl = await getFileUploadSignedUrl(key)

  await fetch(uploadSignedUrl, {
    method: 'PUT',
    body: blob,
    headers: {
      'Content-Type': blob.type,
    },
  })
}

const multiPartUpload = async (key: string, blob: Blob): Promise<void> => {
  const chunkSize = MAX_SINGLE_UPLOAD_SIZE
  const chunkBatchSize = 4 // 4 upload request in the same batch window

  const uploadId = await startFileMultiPartUpload(key, blob.type)

  const chunkCount = Math.ceil(blob.size / chunkSize)
  const chunks = Array(chunkCount)
    .fill(0)
    .map((_, i) => ({
      partialBlob: blob.slice(i * chunkSize, (i + 1) * chunkSize),
      partNumber: i + 1,
    }))

  const chunkBatchCount = Math.ceil(chunks.length / chunkBatchSize)
  const chunkBatches = Array(chunkBatchCount)
    .fill(0)
    .map((_, i) => chunks.slice(i * chunkBatchSize, (i + 1) * chunkBatchSize))

  const parts: Part[] = []

  for (const chunkBatch of chunkBatches) {
    // eslint-disable-next-line no-await-in-loop
    await Promise.all(
      chunkBatch.map(async ({ partNumber, partialBlob }) => {
        const signedUrl = await getFileUploadPartSignedUrl(
          key,
          uploadId,
          partNumber,
        )

        const uploadResult = await fetch(signedUrl, {
          method: 'PUT',
          body: partialBlob,
        })

        const eTag = uploadResult.headers.get('ETag')

        if (!eTag) {
          throw new Error('Failed to upload')
        }

        parts.push({
          eTag,
          partNumber,
        })
      }),
    )
  }

  await completeFileMultiPartUpload(
    key,
    uploadId,
    parts
      .map(part => ({
        e_tag: part.eTag,
        part_number: part.partNumber,
      }))
      .sort((a, b) => a.part_number - b.part_number),
  )
}

export const upload = async ({ key, dataURI }: KeyAndDataURI) => {
  let blob: Blob

  if (Platform.OS === 'web') {
    blob = dataURItoBlob(dataURI)
  } else {
    const response = await fetch(dataURI)
    blob = await response.blob()
  }

  if (blob.size > MAX_SINGLE_UPLOAD_SIZE) {
    return multiPartUpload(key, blob)
  }

  return singleUpload(key, blob)
}

export const uploadMany = async (keyAndDataURLsArray: KeyAndDataURI[]) =>
  Promise.all(keyAndDataURLsArray.map(keyAndDataURI => upload(keyAndDataURI)))

// credit: https://gist.github.com/davoclavo/4424731
function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const _ia = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i = i + 1) {
    _ia[i] = byteString.charCodeAt(i)
  }

  const dataView = new DataView(arrayBuffer)
  const blob = new Blob([dataView], { type: mimeString })
  return blob
}
