import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormBreak,
  FormLabel,
  InputGroup,
  NumericInput,
  SecondaryButton,
  Select,
  SwitchInput,
  TextInput,
  toast,
} from 'components/common'
import { Status } from 'components/common/Form/Status'
import { InputStatusType } from 'components/common/Form/utils'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { Colors } from 'constants/Colors'
import { isNil, toPairs } from 'lodash'
import { Controller, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { WeightUnit } from 'src/constants/Weight'

import {
  allWeightUnitOptions,
  ConcentrationSetting,
  concentrationSettingOptions,
  concentrationVolumeUnitOptions,
  criPerTimeOptions,
  perWeightUnitOptions,
  RepeatScheduleValue,
  standardWeightUnitOptions,
  volumeUnitOptionsV2 as volumeUnitOptions,
} from './data'
import { CRIOnFormData, MedicationFormProps } from './types'
import { useCRIOnForm } from './useCRIOnForm'
import { getCRIOnFormSchema } from './utils/getTreatmentFormSchema'
import { isNonStandardWeightUnits } from './utils/isNonStandardWeightUnits'
import { DosageFormDurationInfo } from './components/DosageFormDurationInfo'
import { ScheduleTask } from 'components/common/Form/Sections'
import { useStartTimeRefresh } from 'src/hooks/useStartTimeRefresh'
import { PermissionSwitchInput } from 'components/common/Permission'
import { PermissionAction } from 'src/types/globalTypes'
import { SaveTreatmentButton } from './SaveTreatmentButton'
import { BillingSection } from 'components/Task/common/BillingSection'
import flagsmith from 'react-native-flagsmith'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { PatientWeight } from './components/PatientWeight'
import { Instructions } from './components/Instructions'
import { getDefaultCRIOnFormValues } from './utils/getDefaultCRIOnFormValues'

type Props = MedicationFormProps<CRIOnFormData> & {
  renderCRISwitch: () => JSX.Element
}

export const CRIOnForm: React.FC<Props> = ({
  defaultOverrides,
  isNewSheet = false,
  approvalStatus,
  onSave,
  patientWeight: defaultPatientWeight,
  patientWeightUnit: defaultPatientWeightUnit,
  patientWeightUpdatedAt,
  product,
  submitting = false,
  renderCRISwitch,
  isEdit,
  submitTitle,
  styleForWebOriOS,
  isCubexProductBillable,
}) => {
  const { t } = useTranslation()

  const [isUnitsBilledPerTaskModified, setIsUnitsBilledPerTaskModified] =
    useState<boolean>(
      !!defaultOverrides?.unitsBilledPerTask &&
        defaultOverrides?.unitsBilledPerTask !==
          defaultOverrides?.medicationVolume,
    )

  const startTime = useStartTimeRefresh()
  const footStyle = styleForWebOriOS
    ? styles.footer
    : styles.footerForCreatingSheet
  const footButtonStyle = styleForWebOriOS
    ? styles.footer
    : styles.footerButtonForCreatingSheet

  const productConcentration =
    product.medicine_dosage_info?.concentration ?? null
  const productConcentrationWeightUnit = (product.medicine_dosage_info
    ?.concentration_weight_unit ?? '') as WeightUnit
  const productConcentrationVolumeUnit =
    product.medicine_dosage_info?.concentration_volume_unit ?? ''

  const noConcentrationFromProductError =
    productConcentration &&
    productConcentrationWeightUnit &&
    productConcentrationVolumeUnit
      ? null
      : t('form.noValue')

  const validationSchema = getCRIOnFormSchema(t)

  const defaultValues = getDefaultCRIOnFormValues({
    product,
    defaultOverrides,
    defaultPatientWeight,
    defaultPatientWeightUnit,
    startTime,
    isCubexProductBillable,
  })

  const methods = useForm<CRIOnFormData>({
    defaultValues,
    criteriaMode: 'firstError',
    resolver: yupResolver(validationSchema),
  })

  const {
    operations: { clearCalculator, handleCalculate, getLock },
  } = useCRIOnForm(methods)

  const {
    setValue,
    getValues,
    control,
    trigger, // trigger for validation
    handleSubmit,
    watch,
    formState: { errors },
  } = methods

  const watchedDosageWeightUnit = watch('dosageWeightUnit')

  const watchedIsDiluted = watch('isDiluted')
  const watchedIsContinuous = watch('isContinuous', defaultValues.isContinuous)

  const watchedConcentrationSetting = watch(
    'concentrationSetting',
    defaultValues.concentrationSetting,
  )

  const shouldDisableWeightUnit =
    isNonStandardWeightUnits(productConcentrationWeightUnit) &&
    isNil(watchedConcentrationSetting)

  const concentrationWeightOptions = useMemo(() => {
    if (shouldDisableWeightUnit) {
      return [{ value: productConcentrationWeightUnit }]
    }
    return allWeightUnitOptions
  }, [shouldDisableWeightUnit, productConcentrationWeightUnit])

  const isConcentrationIgnore =
    watchedConcentrationSetting === ConcentrationSetting.IGNORE

  const dosageUnitOptions = useMemo(() => {
    if (shouldDisableWeightUnit) {
      return concentrationWeightOptions
    }
    const shouldRenderAllOptions = !isNil(watchedConcentrationSetting)

    if (shouldRenderAllOptions) {
      return allWeightUnitOptions
    }

    return standardWeightUnitOptions
  }, [
    shouldDisableWeightUnit,
    concentrationWeightOptions,
    watchedConcentrationSetting,
  ])

  const shouldAutofillUnitsBilledPerTask =
    flagsmith.hasFeature(FeatureFlagNames.AutofillMedQtyBilledPerTask) &&
    !isConcentrationIgnore &&
    !isUnitsBilledPerTaskModified

  const handleOnSave = (data: CRIOnFormData) => {
    onSave({
      ...defaultValues,
      ...data,
    })
  }

  const handleOnError: SubmitErrorHandler<CRIOnFormData> = submitErrors => {
    toPairs(submitErrors).forEach(errorPair => {
      const errorName = errorPair[0]
      const errorMessage =
        'message' in errorPair[1] ? errorPair[1].message : 'no message'
      toast.error(`${errorName}: ${errorMessage}`)
    })
  }

  const handleDosageConcentrationWeight = (
    changedFieldName: 'dosageWeightUnit' | 'concentrationWeightUnit',
    newValue: WeightUnit,
  ) => {
    setValue(changedFieldName, newValue)
    const syncedFieldName =
      changedFieldName === 'dosageWeightUnit'
        ? 'concentrationWeightUnit'
        : 'dosageWeightUnit'

    const syncedFieldValue = getValues(syncedFieldName)
    if (
      isNonStandardWeightUnits(newValue) ||
      isNonStandardWeightUnits(syncedFieldValue)
    ) {
      setValue(syncedFieldName, newValue)
    }
  }

  const handleMedicationVolumeChange = (
    newValue: number | null,
    onChange: (newValue: number | null) => void,
  ) => {
    onChange(newValue)
    if (shouldAutofillUnitsBilledPerTask) {
      setValue('unitsBilledPerTask', newValue)
    }

    handleCalculate('medicationVolume')
  }

  //  QTY to be billed not to be overwritten if it has already had an entry in it that is [NOT EQUAL TO]  Total Volume
  const onUnitsBilledPerTaskChange = () => {
    const [unitsBilledPerTask, totalVolume] = getValues([
      'unitsBilledPerTask',
      'medicationVolume',
    ])
    if (unitsBilledPerTask === totalVolume) {
      setIsUnitsBilledPerTaskModified(false)
    } else {
      setIsUnitsBilledPerTaskModified(true)
    }
    trigger()
  }

  useEffect(() => {
    trigger()
  }, [trigger])

  const isFirstRenderForStartAtDate = useRef(true)

  useEffect(() => {
    if (isEdit) {
      return
    }

    const defaultOverridesStartAtDate = defaultOverrides?.startAtDate
    // defaultOverridesStartAtDate should overwrite the startAtDate when first time render
    if (isFirstRenderForStartAtDate.current && defaultOverridesStartAtDate) {
      setValue('startAtDate', defaultOverridesStartAtDate)
      isFirstRenderForStartAtDate.current = false
      return
    }

    const { isDirty, isTouched } = control.getFieldState('startAtDate')
    // Only keep updating the refresh timer if the field has not been touched or modified.
    if (!isTouched && !isDirty) {
      setValue('startAtDate', startTime)
    }
  }, [startTime, control, setValue, isEdit, defaultOverrides?.startAtDate])
  const hasErrors = Object.keys(errors).length > 0

  const toggleConcentrationOverridden = useCallback(
    (overriddenSetting: ConcentrationSetting) => {
      const isOverridden = overriddenSetting === ConcentrationSetting.OVERRIDE
      const dosageWeightUnit = getValues('dosageWeightUnit')

      requestAnimationFrame(() => {
        if (isOverridden) {
          setValue('concentration', null)
          setValue(
            'concentrationVolumeUnit',
            concentrationVolumeUnitOptions[0].value,
          )
          setValue('concentrationWeightUnit', dosageWeightUnit)
        } else {
          setValue('concentration', defaultValues.concentration)
          setValue(
            'concentrationVolumeUnit',
            defaultValues.concentrationVolumeUnit,
          )
          setValue(
            'concentrationWeightUnit',
            defaultValues.concentrationWeightUnit,
          )
        }
        trigger()
      })
    },
    [
      defaultValues.concentration,
      defaultValues.concentrationWeightUnit,
      defaultValues.concentrationVolumeUnit,
      getValues,
      setValue,
      trigger,
    ],
  )

  const qtyBilledPermissionAction = isEdit
    ? PermissionAction.TREATMENT_QUANTITY_BILLED__CHANGE
    : PermissionAction.TREATMENT_QUANTITY_BILLED__ADD

  return (
    <>
      <KeyboardAwareScrollView>
        {renderCRISwitch()}
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label={t('addTreatment:diluteMedication')}
              onChangeValue={newValue => {
                onChange(newValue)
                clearCalculator()
              }}
              disabled={isEdit}
              value={value}
            />
          )}
          name="isDiluted"
        />
        <FormLabel text={t('addTreatment:patientInfo')} />
        <PatientWeight
          control={control}
          patientWeightUpdatedAt={patientWeightUpdatedAt}
          handleChange={() => handleCalculate('patientWeight')}
          disabled={getLock('patientWeight')}
        />
        <FormBreak />
        {/* Infusion Rate Group */}
        <InputGroup>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericInput
                label={t('addTreatment:infusionRate')}
                onChange={newValue => {
                  onChange(newValue)
                  handleCalculate('infusionRateTotal')
                }}
                required={!isConcentrationIgnore}
                style={{ flex: 9 }}
                value={value}
                disabled={getLock('infusionRateTotal')}
              />
            )}
            name="infusionRateTotal"
          />
          <ControlledSelect
            dialog={false}
            control={control}
            name="infusionRateVolumeUnit"
            options={volumeUnitOptions}
            onChangeListener={() => {
              handleCalculate('infusionRateTotal')
            }}
            disabled={getLock('infusionRateTotal')}
            dynamicallyHideOptions={getLock('infusionRateTotal')}
          />
          <ControlledSelect
            dialog={false}
            control={control}
            name="infusionRateTimeUnit"
            options={criPerTimeOptions}
            onChangeListener={() => {
              handleCalculate('infusionRateTotal')
            }}
            disabled={getLock('infusionRateTotal')}
            dynamicallyHideOptions={getLock('infusionRateTotal')}
          />
        </InputGroup>
        <FormLabel text={t('addTreatment:doseInformation')} />
        {/* Dose Rate Group */}
        <InputGroup>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericInput
                label={t('addTreatment:doseRate')}
                value={value}
                onChange={newValue => {
                  onChange(newValue)
                  handleCalculate('doseRate')
                }}
                style={styles.flex}
                required={!isConcentrationIgnore}
                disabled={getLock('doseRate')}
              />
            )}
            name="doseRate"
          />
          <Select
            dialog={false}
            selected={watchedDosageWeightUnit}
            options={dosageUnitOptions}
            onChange={newValue => {
              handleDosageConcentrationWeight('dosageWeightUnit', newValue)
              handleCalculate('doseRate')
            }}
            a11yLabel={t('addTreatment:dosageWeightUnit')}
            disabled={shouldDisableWeightUnit || getLock('doseRate')}
            dynamicallyHideOptions={getLock('doseRate')}
          />
          <ControlledSelect
            dialog={false}
            control={control}
            options={perWeightUnitOptions}
            a11yLabel={t('addTreatment:dosagePerWeightUnit')}
            name="dosagePerWeightUnit"
            onChangeListener={() => {
              handleCalculate('doseRate')
            }}
            disabled={getLock('doseRate')}
            dynamicallyHideOptions={getLock('doseRate')}
          />
          <ControlledSelect
            dialog={false}
            control={control}
            options={criPerTimeOptions}
            name="doseRateTimeUnit"
            onChangeListener={() => {
              handleCalculate('doseRate')
            }}
            disabled={getLock('doseRate')}
            dynamicallyHideOptions={getLock('doseRate')}
          />
        </InputGroup>

        {/* Dosage Rate Group */}
        <InputGroup>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericInput
                label={t('addTreatment:dosageRate')}
                value={value}
                onChange={newValue => {
                  onChange(newValue)
                  handleCalculate('dosageRate')
                }}
                style={styles.flex}
                required={!isConcentrationIgnore}
                disabled={getLock('dosageRate')}
              />
            )}
            name="dosageRate"
          />
          <Select
            dialog={false}
            selected={watchedDosageWeightUnit}
            options={dosageUnitOptions}
            onChange={newValue => {
              handleDosageConcentrationWeight('dosageWeightUnit', newValue)
              handleCalculate('doseRate')
            }}
            a11yLabel={t('addTreatment:dosageWeightUnit')}
            disabled={shouldDisableWeightUnit || getLock('dosageRate')}
            dynamicallyHideOptions={getLock('dosageRate')}
          />
          <ControlledSelect
            dialog={false}
            control={control}
            options={criPerTimeOptions}
            name="doseRateTimeUnit"
            onChangeListener={() => {
              handleCalculate('dosageRate')
            }}
            disabled={getLock('dosageRate')}
            dynamicallyHideOptions={getLock('dosageRate')}
          />
        </InputGroup>

        <InputGroup>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericInput
                label={t('addTreatment:medicationVolume')}
                onChange={newValue => {
                  handleMedicationVolumeChange(newValue, onChange)
                }}
                required={watchedIsDiluted}
                style={styles.flex}
                value={value}
                disabled={getLock('medicationVolume')}
              />
            )}
            name="medicationVolume"
          />
          <Controller
            control={control}
            name="medicationVolumeUnit"
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  dialog={false}
                  options={volumeUnitOptions}
                  selected={value}
                  onChange={newValue => {
                    onChange(newValue)
                    handleCalculate('medicationVolume')
                  }}
                  disabled={getLock('medicationVolume')}
                  dynamicallyHideOptions={getLock('medicationVolume')}
                />
              )
            }}
          />
        </InputGroup>

        <FormBreak />

        {product?.is_billable || defaultValues.isBillable ? (
          <>
            <FormLabel text={t('addTreatment:billing.title')} />

            <BillingSection
              control={control}
              handleChangeIsBillable={(val: boolean) => {
                setValue('isBillable', val)
                // Trigger to set if unitsBilledPerTask is required
                trigger()
              }}
              handleChangeQtyBilled={(val: number | null) => {
                setValue('unitsBilledPerTask', val)
                // Trigger to set if unitsBilledPerTask is required
                onUnitsBilledPerTaskChange()
              }}
              qtyBilledPermissionAction={qtyBilledPermissionAction}
              isCubexBillable={!!isCubexProductBillable}
              qtyBilledFieldName="unitsBilledPerTask"
              qtyBilledErrorMessage={errors.unitsBilledPerTask?.message}
            />
          </>
        ) : null}
        <FormBreak />
        <FormLabel text={t('addTreatment:concentration')} />
        {!noConcentrationFromProductError && (
          <>
            <Controller
              control={control}
              name="concentrationSetting"
              render={({ field: { onChange, value } }) => (
                <PermissionSwitchInput
                  permissionAction={
                    PermissionAction.TREATMENT_IGNORE_CONCENTRATION__CHANGE
                  }
                  label={t('addTreatment:ignoreConcentration')}
                  onChangeValue={newValue => {
                    const concentrationSettingValue = newValue
                      ? ConcentrationSetting.IGNORE
                      : null
                    if (isNil(concentrationSettingValue)) {
                      clearCalculator()
                    }
                    onChange(concentrationSettingValue)
                    setValue('isDiluted', false)
                    trigger()
                  }}
                  value={value === ConcentrationSetting.IGNORE}
                />
              )}
            />
          </>
        )}

        {!!noConcentrationFromProductError && (
          // Override concentration
          <Status
            status={createErrorStatus(
              noConcentrationFromProductError,
              true,
              InputStatusType.Warning,
            )}
          >
            <ControlledSelect
              dialog={false}
              a11yLabel="Concentration Setting"
              control={control}
              onChangeListener={newValue => {
                toggleConcentrationOverridden(newValue)
                clearCalculator()
              }}
              options={concentrationSettingOptions}
              name="concentrationSetting"
            />
          </Status>
        )}

        {/* Concentration */}
        {!isConcentrationIgnore && (
          <InputGroup>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumericInput
                  disabled={
                    !noConcentrationFromProductError || getLock('concentration')
                  }
                  label={t('addTreatment:concentration')}
                  onChange={newValue => {
                    onChange(newValue)
                    handleCalculate('concentration')
                  }}
                  required={true}
                  value={value}
                  style={styles.bigFlex}
                />
              )}
              name="concentration"
            />

            <Controller
              control={control}
              render={({ field: { value } }) => (
                <Select
                  dialog={false}
                  disabled={
                    !noConcentrationFromProductError || getLock('concentration')
                  }
                  dynamicallyHideOptions={
                    !noConcentrationFromProductError || getLock('concentration')
                  }
                  selected={value}
                  options={concentrationWeightOptions}
                  onChange={newValue => {
                    handleDosageConcentrationWeight(
                      'concentrationWeightUnit',
                      newValue,
                    )
                    handleCalculate('concentration')
                  }}
                />
              )}
              name="concentrationWeightUnit"
            />

            <Controller
              control={control}
              render={({ field: { value } }) => (
                <Select
                  dialog={false}
                  disabled={
                    !noConcentrationFromProductError || getLock('concentration')
                  }
                  dynamicallyHideOptions={
                    !noConcentrationFromProductError || getLock('concentration')
                  }
                  selected={value}
                  options={concentrationVolumeUnitOptions}
                  onChange={newValue => {
                    setValue('concentrationVolumeUnit', newValue)
                    handleCalculate('concentration')
                  }}
                />
              )}
              name="concentrationVolumeUnit"
            />
          </InputGroup>
        )}
        {!isConcentrationIgnore && watchedIsDiluted ? (
          <InputGroup>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumericInput
                  label={t('addTreatment:diluted')}
                  onChange={newValue => {
                    onChange(newValue)
                    handleCalculate('dilutedConcentration')
                  }}
                  required={watchedIsDiluted}
                  value={value}
                  style={styles.bigFlex}
                  disabled={getLock('dilutedConcentration')}
                />
              )}
              name="dilutedConcentration"
            />

            <Controller
              control={control}
              render={({ field: { value } }) => (
                <Select
                  dialog={false}
                  disabled={
                    !noConcentrationFromProductError ||
                    getLock('dilutedConcentration')
                  }
                  dynamicallyHideOptions={
                    !noConcentrationFromProductError ||
                    getLock('dilutedConcentration')
                  }
                  selected={value}
                  options={concentrationWeightOptions}
                  onChange={newValue => {
                    handleDosageConcentrationWeight(
                      'concentrationWeightUnit',
                      newValue,
                    )
                    handleCalculate('dilutedConcentration')
                  }}
                />
              )}
              name="concentrationWeightUnit"
            />

            <Controller
              control={control}
              render={({ field: { value } }) => (
                <Select
                  dialog={false}
                  disabled={
                    !noConcentrationFromProductError ||
                    getLock('dilutedConcentration')
                  }
                  dynamicallyHideOptions={
                    !noConcentrationFromProductError ||
                    getLock('dilutedConcentration')
                  }
                  selected={value}
                  options={concentrationVolumeUnitOptions}
                  onChange={newValue => {
                    setValue('concentrationVolumeUnit', newValue)
                    handleCalculate('dilutedConcentration')
                  }}
                />
              )}
              name="concentrationVolumeUnit"
            />
          </InputGroup>
        ) : null}
        {watchedIsDiluted ? (
          <>
            <FormBreak />
            <FormLabel text={t('addTreatment:diluentInformation')} />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label={t('addTreatment:diluentUsed')}
                  onChangeText={onChange}
                  value={value ?? ''}
                />
              )}
              name="diluentUsed"
            />
            <InputGroup>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericInput
                    label={t('addTreatment:finalVolume')}
                    onChange={newValue => {
                      onChange(newValue)
                      handleCalculate('ivBagSize')
                    }}
                    required={watchedIsDiluted}
                    style={styles.flex}
                    value={value}
                    disabled={getLock('ivBagSize')}
                  />
                )}
                name="ivBagSize"
              />
              <Controller
                control={control}
                name="ivBagSizeUnit"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Select
                      dialog={false}
                      options={volumeUnitOptions}
                      selected={value}
                      onChange={newValue => {
                        onChange(newValue)
                        handleCalculate('ivBagSize')
                      }}
                      disabled={getLock('ivBagSize')}
                      dynamicallyHideOptions={getLock('ivBagSize')}
                    />
                  )
                }}
              />
            </InputGroup>
          </>
        ) : null}

        <SecondaryButton
          title={t('addTreatment:clearCalculator')}
          onPress={clearCalculator}
          style={styles.clearCalculatorButton}
        />

        <DosageFormDurationInfo
          control={control}
          watchedIsContinuous={watchedIsContinuous}
        />
        {!isNewSheet && !isEdit && (
          <ScheduleTask
            control={control}
            isRepeating={RepeatScheduleValue.SINGLE}
            trigger={trigger}
            isFluidDosage={true}
            isEdit={isEdit}
          />
        )}

        {!isNewSheet && <Instructions control={control} />}
      </KeyboardAwareScrollView>
      <View style={footStyle}>
        <View style={footButtonStyle}>
          <SaveTreatmentButton
            disabled={hasErrors}
            loading={submitting}
            onPress={handleSubmit(handleOnSave, handleOnError)}
            isEdit={isEdit}
            approvalStatus={approvalStatus}
            submitTitle={submitTitle}
          />
        </View>
        {hasErrors ? (
          <Text style={{ color: Colors.errorBackground }}>
            {t('addTreatment:criErrorMessage')}
          </Text>
        ) : null}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  bigFlex: {
    flex: 9,
    marginRight: 'auto',
  },
  clearCalculatorButton: { alignSelf: 'center', paddingVertical: 8 },
  footer: {
    alignItems: 'center',
    paddingVertical: 12,
  },
  footerForCreatingSheet: {
    alignItems: 'center',
    marginTop: 10,
    paddingBottom: 50,
  },
  footerButtonForCreatingSheet: {
    marginBottom: 10,
    paddingBottom: 5,
  },
})
