import React, { useCallback, useMemo, useRef } from 'react'
import { useMutation } from '@apollo/client'
import {
  FlatList,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { SvgArrow } from 'components/Icons'
import { orderBy } from 'lodash'
import { EyeIconWithText, toast } from 'components/common'
import { UPDATE_WORKFLOW_ITEM } from 'components/Sheet/graphql'
import { getConsultation_getConsultation_workflow_workflow_items_items as WorkflowItem } from 'types/getConsultation'
import {
  updateWorkflowItem,
  updateWorkflowItemVariables,
} from 'types/updateWorkflowItem'
import { useOrganisation } from 'src/context/organisation'
import { Fonts } from 'constants/Fonts'
import { Colors } from 'constants/Colors'
import { format } from 'date-fns'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { PermissionCheckButton } from 'components/common/Permission'
import { PermissionAction } from 'types/globalTypes'

const ROW_HEIGHT = 78
const ButtonWidth = ROW_HEIGHT
const ITEM_WIDTH = 180
const SMALL_ITEM_WIDTH = 140
const ITEM_MARGIN = 8

type Props = {
  items: WorkflowItem[]
  openDrawer: () => void
  isDischarged: boolean
  isShowWorkflow: boolean
  onToggleWorkflow: () => void
}

type CarouselItemProps = {
  item: WorkflowItem
  toggleComplete: (item: WorkflowItem) => void
  isDischarged: boolean
  isEnd: boolean
}

enum Direction {
  FORWARD = 1,
  BACKWARD = -1,
}

export const CarouselItem: React.FC<CarouselItemProps> = React.memo(
  ({ item, toggleComplete, isDischarged, isEnd }) => {
    const { t } = useTranslation()
    const { adminDateFormat, adminTimeFormat } = useAdminTimeFormat()
    const { isExSmallScreen, isLargeScreen } = useBreakpoint()

    const renderWorkflowCheckButton = useMemo(() => {
      const permissionAction = item.completed_at
        ? PermissionAction.WORKFLOW_TASK__UNTICK
        : null

      return (
        <PermissionCheckButton
          permissionAction={permissionAction}
          disabled={isDischarged}
          onChange={() => toggleComplete(item)}
          checked={!!item.completed_at}
          style={styles.checkButton}
        />
      )
    }, [isDischarged, item, toggleComplete])

    if (!item.id) {
      return (
        <View
          style={[
            styles.workflowEmptyItem,
            isExSmallScreen && styles.exSmallWidth,
          ]}
        />
      )
    }

    return (
      <View
        style={[
          styles.workflowItemContainer,
          isExSmallScreen && styles.exSmallWidth,
          !item.completed_at && {
            backgroundColor: Colors.white,
          },
          isEnd &&
            (isLargeScreen
              ? { marginRight: ButtonWidth }
              : { marginRight: ButtonWidth - 30 }),
        ]}
      >
        {renderWorkflowCheckButton}
        <View style={styles.textContainer}>
          <Text
            style={styles.workflowTitleText}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item.name}
          </Text>

          <Text
            style={[
              styles.completedTimeText,
              !item.completed_at && {
                color: Colors.lightGrey2,
              },
            ]}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {item.completed_at
              ? t('datetime', {
                  date: format(
                    new Date(item.completed_at),
                    `${adminTimeFormat} ${adminDateFormat}`,
                  ),
                })
              : t('addTreatment:schedule.toBeComplete')}
          </Text>
        </View>
      </View>
    )
  },
)

CarouselItem.displayName = 'CarouselItem'

export const WorkflowCarousel: React.FC<Props> = React.memo(
  ({ items, openDrawer, isDischarged, onToggleWorkflow, isShowWorkflow }) => {
    const [{ organisationId }] = useOrganisation()
    const { isExSmallScreen, isLargeScreen } = useBreakpoint()
    const currentScrollOffsetX = useRef<number>(0)
    const carouselRef = useRef<any>()
    const [updateWorkFlowItem] = useMutation<
      updateWorkflowItem,
      updateWorkflowItemVariables
    >(UPDATE_WORKFLOW_ITEM, {
      onError: err => {
        toast.error(err.message)
      },
    })

    const toggleItemComplete = useCallback(
      (item: WorkflowItem) => {
        let newOrder = item.order
        let completedAt: string | null = new Date().toISOString()
        if (item.completed_at) {
          // insert it to the head of unfinished items
          newOrder = Math.min(...items.map(({ order }) => order)) - 1
          completedAt = null
        }
        updateWorkFlowItem({
          variables: {
            input: {
              id: item.id,
              organisation_id: organisationId,
              completed_at: completedAt,
              order: newOrder,
            },
          },
          optimisticResponse: {
            updateWorkflowItem: {
              id: item.id,
              completed_at: completedAt,
              order: newOrder,
              __typename: 'WorkflowItem',
            },
          },
        })
      },
      [items, organisationId, updateWorkFlowItem],
    )

    const itemWidth = !isExSmallScreen ? ITEM_WIDTH : SMALL_ITEM_WIDTH

    const sortedItems = useMemo(() => {
      return orderBy(
        items,
        [
          item =>
            item.completed_at
              ? new Date(item.completed_at).getTime()
              : Number.POSITIVE_INFINITY,
          'order',
        ],
        ['asc', 'asc'],
      )
    }, [items])

    const scrollToIndex = (direction: Direction) => {
      const curIdx = Math.floor(
        currentScrollOffsetX.current / (itemWidth + ITEM_MARGIN),
      )
      const nextIdx = curIdx + direction
      if (nextIdx < 0 || nextIdx >= items.length) {
        return
      }
      carouselRef.current?.scrollToIndex({
        index: nextIdx,
      })
    }

    const renderItem = ({
      item,
      index,
    }: {
      item: WorkflowItem
      index: number
    }) => {
      const isEnd = index === items.length - 1
      return (
        <CarouselItem
          item={item}
          toggleComplete={toggleItemComplete}
          key={item.id || index}
          isDischarged={isDischarged}
          isEnd={isEnd}
        />
      )
    }

    const onScrollList = useCallback(
      (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        const offsetX = e.nativeEvent.contentOffset.x
        currentScrollOffsetX.current = offsetX
      },
      [],
    )

    const containerStyle = useMemo(() => {
      if (isLargeScreen) {
        if (isShowWorkflow) {
          return [styles.flexRow, styles.heightOpen, styles.container]
        }
        return [styles.flexRow, styles.heightClose, styles.container]
      }
      if (isShowWorkflow) {
        return [styles.flexColumn, styles.container]
      }
      return [styles.flexColumn, styles.container, styles.mobileContainer]
    }, [isLargeScreen, isShowWorkflow])

    const rightButtonHolderStyle = useMemo(() => {
      if (isLargeScreen) return styles.rightButtonHolder
      return [styles.rightButtonHolder, styles.mbRightButtonHolder]
    }, [isLargeScreen])

    const carouselStyle = useMemo(() => {
      if (isLargeScreen) return [styles.flexRow, styles.heightOpen]
      return [styles.flexRow, styles.heightOpen, styles.mbTranslateY]
    }, [isLargeScreen])

    return (
      <View style={containerStyle}>
        <EyeIconWithText
          onPress={onToggleWorkflow}
          isLargeScreen={isLargeScreen}
          isClick={isShowWorkflow}
        />
        {isShowWorkflow ? (
          <View style={carouselStyle}>
            <TouchableOpacity
              style={styles.leftArrowBackground}
              onPress={() => scrollToIndex(Direction.BACKWARD)}
            >
              <SvgArrow
                orient="left"
                color={Colors.blue}
                width={15}
                height={15}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.addButtonBackground}
              onPress={openDrawer}
              disabled={isDischarged}
            >
              <View style={styles.addButtonHolder}>
                <Text style={styles.buttonText}>{'+'}</Text>
              </View>
            </TouchableOpacity>

            <View style={styles.flatListContainer}>
              <FlatList
                ref={carouselRef}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                data={sortedItems}
                renderItem={renderItem}
                onScroll={onScrollList}
                scrollEventThrottle={200}
              />
            </View>
            <View>
              <TouchableOpacity
                style={rightButtonHolderStyle}
                onPress={() => scrollToIndex(Direction.FORWARD)}
              >
                <SvgArrow
                  orient="right"
                  color={Colors.blue}
                  width={15}
                  height={15}
                />
              </TouchableOpacity>
            </View>
          </View>
        ) : null}
      </View>
    )
  },
)

WorkflowCarousel.displayName = 'WorkflowCarousel'

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    shadowOffset: {
      height: 5,
      width: 0,
    },
    shadowColor: Colors.contentPrimary,
    shadowOpacity: 0.9,
    shadowRadius: 10,
  },
  mobileContainer: {
    height: 48,
    marginHorizontal: 8,
    marginBottom: 8,
    width: 'auto',
    borderRadius: 6,
    shadowColor: Colors.contentPrimary,
    shadowOpacity: 0.1,
    shadowOffset: {
      height: 4,
      width: 0,
    },
    shadowRadius: 4,
  },
  flexRow: {
    width: '100%',
    flexDirection: 'row',
  },
  flexColumn: {
    width: '100%',
    flexDirection: 'column',
  },
  heightOpen: {
    height: ROW_HEIGHT,
  },
  heightClose: {
    height: 5,
  },
  leftArrowBackground: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
  },
  addButtonBackground: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    marginRight: 5,
  },
  rightButtonHolder: {
    position: 'absolute',
    right: 0,
    width: ButtonWidth,
    height: ROW_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    opacity: 0.9,
  },
  mbRightButtonHolder: {
    width: ButtonWidth - 30,
  },
  mbTranslateY: {
    transform: [
      {
        translateY: -5,
      },
    ],
  },
  addButtonHolder: {
    width: 40,
    borderRadius: 4,
    height: 54,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.blue,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 32,
    fontFamily: Fonts.regular,
    color: Colors.white,
  },
  flatListContainer: {
    flex: 1,
    paddingVertical: 10,
  },
  workflowEmptyItem: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 4,
    width: ITEM_WIDTH,
  },
  workflowItemContainer: {
    flex: 1,
    height: ROW_HEIGHT - 20,
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginHorizontal: ITEM_MARGIN / 2,
    backgroundColor: Colors.backgroundGrey,
    borderRadius: 6,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    width: ITEM_WIDTH,
  },
  exSmallWidth: {
    width: SMALL_ITEM_WIDTH,
  },
  textContainer: {
    flex: 1,
    height: '100%',
    paddingVertical: 9,
    paddingRight: 6,
    justifyContent: 'space-between',
  },
  workflowTitleText: {
    fontFamily: Fonts.regular,
  },
  completedTimeText: {
    fontFamily: Fonts.regular,
  },
  checkButton: {
    height: 16,
    width: 16,
    margin: 11,
  },
})
