export const formatFluidValueLabel = (
  label: string | null,
  round: (num: number) => number,
) => {
  if (!label) return null

  const labelValue = parseFloat(label)
  const roundedValue = round(labelValue)
  const units = label.match(/[^\d.]+/gm)

  return units && roundedValue ? `${roundedValue}${units[0]}` : label
}
