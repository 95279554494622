import * as React from 'react'
import { Text, View, SafeAreaView } from 'react-native'

export type Props = {
  error: Error
  logError?: boolean
}

export const ErrorView: React.FC<Props> = ({
  error,
  logError = true,
}: Props) => {
  // eslint-disable-next-line no-console
  if (logError) console.error(error)
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ margin: 20 }}>
        <Text>Error!</Text>
        <Text style={{ marginTop: 10 }}>
          The Vet Radar team has been notified!
        </Text>
        <Text style={{ marginVertical: 20 }}>Ignore anything below</Text>
        <Text>{error.message}</Text>
        <Text>{error.stack}</Text>
      </View>
    </SafeAreaView>
  )
}
