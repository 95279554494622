import React, { memo, useCallback } from 'react'
import { FlatList, StyleSheet, Text, View } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'
import { TaskPreviewTask } from '../../types'
import { TaskListTaskItem } from './TaskListTaskItem'

type Props = {
  sheetTitle: string
  timeFormat: string
  tasks: TaskPreviewTask[]
  sectionLength: number
}

export const TaskListSheetItem: React.FC<Props> = memo(
  ({ tasks, sectionLength, sheetTitle, timeFormat }) => {
    const renderSheetHeader =
      sectionLength > 1 ? (
        <View
          testID={`TaskListSheetHeader-${sheetTitle}`}
          style={styles.sheetHeader}
        >
          <Text style={styles.sheetHeaderText}>{sheetTitle}</Text>
        </View>
      ) : null

    const renderListItem = useCallback(
      (item: TaskPreviewTask) => (
        <TaskListTaskItem task={item} timeFormat={timeFormat} />
      ),
      [timeFormat],
    )

    return (
      <FlatList
        ListHeaderComponent={renderSheetHeader}
        data={tasks}
        renderItem={({ item }) => renderListItem(item)}
        removeClippedSubviews={true}
        windowSize={40} // TODO: This need to be further optimized to be device specific
        maxToRenderPerBatch={60}
      />
    )
  },
)

const styles = StyleSheet.create({
  sheetHeader: {
    width: '100%',
    height: 32,
    backgroundColor: Colors.Backgrounds.tertiary,
    paddingHorizontal: 16,
    paddingVertical: 4,
    borderBottomWidth: 1,
    borderColor: Colors.Borders.primary,
    justifyContent: 'center',
  },
  sheetHeaderText: {
    ...Typography.Label.M,
    fontWeight: Typography.FontWeights.bold,
  },
})
