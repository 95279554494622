import { SubHeader } from 'components/SubHeader/SubHeader'
import React, { useCallback, useState } from 'react'
import { StackScreenProps } from '@react-navigation/stack'
import { SettingsStackParamList } from 'components/Settings/screens'
import { Routes } from 'src/constants/Routes'
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import { Center } from 'components/common'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useTranslation } from 'react-i18next'
import { SearchInput } from 'components/common/SearchInput'
import { SvgArrow } from 'components/Icons'
import { Pill } from 'components/common/Pill'
import { searchInList } from 'src/utils/searchInList'
import { ColorCategory, stringToColor } from 'src/utils/stringToColor'
import { Typography, Colors } from 'src/design-system/theme'
import { useQuery } from '@apollo/client'
import { GET_PROCEDURES } from './graphql'
import {
  getProcedures,
  getProceduresVariables,
  getProcedures_getProcedures_items_sites as GetProcedureSites,
} from 'src/types/getProcedures'

type Props = StackScreenProps<SettingsStackParamList, Routes.ProceduresList>

type ProcedureSite = {
  id: string
  name: string
}

type Procedure = {
  id: string
  name: string
  use_case: string | null
  template_workflow_id: string | null
  sites: (GetProcedureSites | null)[] | null
}

export const ProceduresListScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation()
  const { navigate } = navigation
  const { isSmallishScreen } = useBreakpoint()
  const { organisation } = useOrganisationHook()
  const orgName = organisation?.name ?? ' '
  const [searchText, setSearchText] = useState('')

  const queryResult = useQuery<getProcedures, getProceduresVariables>(
    GET_PROCEDURES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        organisation_id: organisation?.id!,
      },
      skip: !organisation?.id,
    },
  )

  const { loading, data } = queryResult

  const navigateSettings = useCallback(
    () => navigate(Routes.Settings),
    [navigate],
  )

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: navigateSettings,
  }

  const filteredProceduresData: Procedure[] = searchInList(
    data?.getProcedures.items ?? [],
    'name',
    searchText,
  )

  const renderProcedureListItem = (procedure: Procedure) => (
    <Center>
      <TouchableOpacity
        onPress={() =>
          navigate(Routes.EditProcedure, {
            procedureId: procedure.id,
          })
        }
        style={styles.procedureContainer}
      >
        <View>
          <Text style={styles.procedureName}>{procedure.name}</Text>
          <View style={styles.departmentsContainer}>
            {procedure.sites?.map((site: ProcedureSite | null) => {
              if (!site) return null
              return (
                <Pill
                  key={site.id}
                  text={site.name}
                  size={'small'}
                  color={stringToColor(
                    site.name,
                    ColorCategory.TEMPLATE_LIST_DEPARTMENT,
                  )}
                />
              )
            })}
          </View>
        </View>
        <SvgArrow orient="right" color="grey" width={15} height={15} />
      </TouchableOpacity>
    </Center>
  )

  return (
    <>
      <SubHeader
        headlineKey={t('templates.proceduresTitle')}
        subHeadline={orgName}
        backButton={backButton}
      />

      {loading ? (
        <ActivityIndicator size={'large'} style={styles.activityIndicator} />
      ) : (
        <>
          <Center
            style={[
              styles.searchContainer,
              isSmallishScreen && styles.smallContainer,
            ]}
          >
            <SearchInput onChangeText={setSearchText} />

            <Text style={styles.headerText}>
              {t('templates.proceduresTitle')}
            </Text>
          </Center>
          <View
            testID="{ProceduresList}"
            style={styles.proceduresListContainer}
          >
            <FlatList
              data={filteredProceduresData}
              renderItem={procedures =>
                renderProcedureListItem(procedures.item)
              }
              contentContainerStyle={styles.listContainerStyle}
            />
          </View>
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  procedureContainer: {
    flexDirection: 'row',
    height: 60,
    borderBottomWidth: 1,
    borderBottomColor: Colors.Borders.primary,
    backgroundColor: Colors.Backgrounds.UI,
    padding: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  procedureName: {
    fontFamily: Typography.FontFamilies.base,
    fontSize: 16,
  },
  activityIndicator: {
    marginTop: 25,
  },
  departmentsContainer: {
    flexDirection: 'row',
    gap: 4,
    marginTop: 4,
  },
  listContainerStyle: {
    paddingBottom: 16,
  },
  proceduresListContainer: {
    flex: 1,
  },
  searchContainer: {
    paddingTop: 16,
    backgroundColor: Colors.Backgrounds.grey,
    gap: 20,
    marginBottom: 10,
  },
  smallContainer: {
    paddingHorizontal: 10,
  },
  headerText: {
    ...Typography.Heading.M,
  },
})
