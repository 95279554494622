import { useCallback, useRef, useState } from 'react'
import {
  Animated,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
} from 'react-native'

import {
  OnSheetScroll,
  PanResponderGestureEvent,
  UseCustomScrollParams,
} from './types'

const SHOW_SCROLL_TO_TOP_DIVIDER = 3

/* This is for setting up custom scrollbars on the web, to position absolute on
   bottom of visible screen. Could be used for web pan touch control in future
   nb: We ignore all of these handlers on native */
export const useCustomScroll = ({
  indicatorDragOffset,
  sheetRef,
}: UseCustomScrollParams) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false)
  const scrollValue = useRef(new Animated.Value(0)).current
  const gridVerticalScroll = useRef(new Animated.Value(0)).current
  const gridVerticalScrollHandler = useRef(
    Animated.event(
      [{ nativeEvent: { contentOffset: { y: gridVerticalScroll } } }],
      {
        useNativeDriver: Platform.OS !== 'web',
        listener: (event: NativeSyntheticEvent<NativeScrollEvent>) => {
          const threshold =
            event.nativeEvent.contentSize.height / SHOW_SCROLL_TO_TOP_DIVIDER
          if (event.nativeEvent.contentOffset.y > threshold) {
            setShowScrollToTop(true)
          } else {
            setShowScrollToTop(false)
          }
        },
      },
    ),
  ).current

  const onCustomXScroll: OnSheetScroll = useRef(
    Animated.event(
      [
        {
          nativeEvent: { contentOffset: { x: scrollValue } },
        },
      ],
      { useNativeDriver: Platform.OS !== 'web' },
    ),
  ).current

  // Capture the initial position within the element to keep the cursor in the
  // right place. This is likely the best 'fake' scrollbar behavior we'll have
  // until can use react-fiber to set scrolling as #1 priority
  const onCustomXPanResponderMove: PanResponderGestureEvent = useCallback(
    ({ nativeEvent: { locationX }, preventDefault }) => {
      // improves scroll perf by avoiding further event capture/bubbling phases
      preventDefault()

      sheetRef.current?.scrollTo({
        x:
          // @ts-ignore | bring indicator offset to position of press in indicator
          scrollValue._value + locationX - indicatorDragOffset.current,
        animated: false,
      })
    },
    // @ts-ignore
    [indicatorDragOffset, scrollValue._value, sheetRef],
  )

  // When user presses on an empty scroll bar space and wants to jump there
  const onScrollBarPress = useCallback(
    (scrollTo: number) => {
      sheetRef.current?.scrollTo({
        // @ts-ignore
        x: scrollValue._value + scrollTo,
        animated: false,
      })
    },
    // @ts-ignore
    [scrollValue._value, sheetRef],
  )

  return {
    showScrollToTop,
    gridVerticalScroll,
    gridVerticalScrollHandler,
    onCustomXScroll,
    onCustomXPanResponderMove,
    onScrollBarPress,
    scrollValue,
  }
}
