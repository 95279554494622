import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import {
  GestureResponderEvent,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import { SvgMeatballs } from 'components/Icons'
import {
  ActionableListItem,
  Actions,
  ActionT,
} from 'components/common/MultiActions'
import { Swipeable } from 'react-native-gesture-handler'
import { Identity } from 'components/Base/UtilityComponents'
import { PermissionSwipeableActions } from 'components/common/Swipeable/SwipeableActions'
import { PermissionAction } from 'src/types/globalTypes'

type IMenuItemOption = {
  name: Actions
  onClick: () => any
  permissionAction?: PermissionAction | null
}

interface Props extends Omit<TextInputProps, 'onChange'> {
  label?: string
  rightLabel?: string
  prefix?: React.ReactElement
  suffix?: string
  value: string
  accessibilityLabel?: string
  required?: boolean
  onChange: (value: string) => void
  iconRight?: React.ReactElement
  menuItems?: IMenuItemOption[]
  textInputDisabled?: boolean
  textInputDisabledGreyOut?: boolean
}

export const Input = forwardRef<TextInput, Props>(
  (
    {
      label,
      rightLabel,
      value,
      onChange,
      prefix,
      suffix,
      accessibilityLabel,
      required = false,
      iconRight,
      menuItems,
      autoFocus,
      textInputDisabled = false,
      textInputDisabledGreyOut = false,
      ...props
    },
    fRef,
  ) => {
    const ref: React.MutableRefObject<TextInput | null> = useRef(null)
    const focusInput = () =>
      requestAnimationFrame(() => {
        if (ref.current) ref.current.focus()
      })

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const openMenu = (event: GestureResponderEvent) => {
      event.preventDefault()
      const target = event.currentTarget
      requestAnimationFrame(() => setAnchorEl(target as any))
    }
    const actions = useMemo(() => {
      return (
        menuItems?.map(menuItem => {
          const onAction = () => {
            menuItem.onClick()
            setAnchorEl(null)
          }
          return {
            onAction,
            name: menuItem.name,
            permissionAction: menuItem?.permissionAction ?? null,
          }
        }) ?? []
      )
    }, [menuItems])

    const isSwipeable = Platform.OS !== 'web'
    const SwipeableNativeOnly = isSwipeable ? Swipeable : Identity
    const swipeableRef = useRef<Swipeable>(null)
    const closeSwipeable = useCallback(() => swipeableRef.current?.close(), [])

    const renderActions = useCallback(
      (actions: ActionT[]) => (
        <PermissionSwipeableActions actions={actions} close={closeSwipeable} />
      ),
      [closeSwipeable],
    )

    const renderRightActions = useCallback(
      () => renderActions(actions),
      [actions, renderActions],
    )

    return (
      <TouchableWithoutFeedback
        onPressIn={focusInput}
        // @ts-ignore
        focusable={false}
        accessible={false}
      >
        <View style={styles.flex}>
          <SwipeableNativeOnly
            ref={swipeableRef}
            renderRightActions={renderRightActions}
          >
            <>
              {!!label && (
                <View style={styles.labelContainer}>
                  <Text
                    numberOfLines={props.numberOfLines}
                    style={[
                      styles.labelText,
                      !!props.numberOfLines && styles.flexShrink,
                    ]}
                  >
                    {label}
                    {required ? (
                      <Text style={styles.requiredLabel}>*</Text>
                    ) : null}
                  </Text>
                  <Text
                    numberOfLines={props.numberOfLines}
                    style={styles.labelText}
                  >
                    {rightLabel}
                  </Text>
                </View>
              )}
              <View
                style={[
                  styles.inputContainer,
                  textInputDisabledGreyOut && textInputDisabled
                    ? styles.inputDisabled
                    : {},
                ]}
              >
                {prefix}
                <TextInput
                  editable={!textInputDisabled}
                  accessibilityLabel={accessibilityLabel || `${label} Input`}
                  ref={el => {
                    // fRef can be a function or an object
                    if (typeof fRef === 'function') {
                      fRef(el)
                    } else if (!!fRef) {
                      fRef.current = el
                    }
                    ref.current = el
                  }}
                  style={styles.textInput}
                  onChangeText={onChange}
                  autoFocus={autoFocus}
                  value={value ?? ''}
                  {...props}
                />
                {!!suffix && (
                  <View style={styles.suffixContainer}>
                    <Text
                      numberOfLines={1}
                      adjustsFontSizeToFit={true}
                      style={styles.suffix}
                    >
                      {suffix.toLowerCase()}
                    </Text>
                  </View>
                )}
                {iconRight}

                {!!menuItems?.length && Platform.OS === 'web' && (
                  <ActionableListItem
                    actions={actions}
                    anchorEl={anchorEl}
                    closeMenu={() => setAnchorEl(null)}
                    isActionable={true}
                  >
                    <TouchableOpacity
                      style={styles.metaBall}
                      onPress={openMenu}
                    >
                      <SvgMeatballs color={Colors.contentTertiary} />
                    </TouchableOpacity>
                  </ActionableListItem>
                )}
              </View>
            </>
          </SwipeableNativeOnly>
        </View>
      </TouchableWithoutFeedback>
    )
  },
)

Input.displayName = 'Input'

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  labelContainer: {
    flexDirection: 'row',
    paddingVertical: 2,
    paddingHorizontal: 8,
    justifyContent: 'space-between',
  },
  labelText: {
    fontFamily: Fonts.regular,
    color: Colors.contentSecondary,
    fontSize: 12,
  },
  flexShrink: {
    flexShrink: 1,
  },
  inputContainer: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.shared.borderGrey,
    height: 40,
    paddingVertical: 6,
    paddingHorizontal: 6,
    flexDirection: 'row',
  },
  textInput: {
    flex: 1,
    fontFamily: Fonts.regular,
    fontSize: 16,
    color: Colors.contentSecondary,
    paddingHorizontal: 2,
    width: '100%',
  },
  inputDisabled: {
    opacity: 0.3,
  },
  suffixContainer: {
    width: 90,
    marginLeft: 8,
    paddingHorizontal: 8,
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderLeftColor: Colors.titleBorder,
  },
  suffix: {
    fontFamily: Fonts.regular,
    color: Colors.contentSecondary,
  },
  requiredLabel: {
    color: 'red',
    marginTop: 0,
  },
  metaBall: {
    justifyContent: 'center',
    paddingHorizontal: 8,
  },
})
