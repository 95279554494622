import { PossibleSelectValues } from './Select.types'

export const createPlaceholderOption = <T extends PossibleSelectValues>(
  placeholder: string,
  value?: T,
  icon?: React.ReactElement,
) => ({
  icon,
  text: placeholder,
  value: value ?? '',
})
