import { useForm } from 'react-hook-form'
import { getColors_getColors_items as GetColorsItems } from 'src/types/getColors'

export type FormValues = {
  colors: GetColorsItems[]
}

type useWorkflowItemFormArgs = {
  initialColors: GetColorsItems[]
  onSubmit: (submitValues: FormValues) => void
}

export const useColorsConfigForm = ({
  initialColors,
  onSubmit,
}: useWorkflowItemFormArgs) => {
  const { handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: { colors: initialColors },
  })

  const colorList = watch('colors')

  const submitForm = handleSubmit(onSubmit)

  const setColorOrder = (newItems: GetColorsItems[]) => {
    setValue('colors', newItems)
  }

  return {
    colorList,
    setColorOrder,
    submitForm,
  }
}
