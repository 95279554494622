import React from 'react'
import { Colors } from 'constants/Colors'

import Svg, { G, Path } from 'react-native-svg'

export const SvgShare: React.FC<{ fillColor?: string }> = ({
  fillColor = Colors.vetRadarGrey,
}) => (
  <Svg width="17" height="17" viewBox="0 0 17 17" fill="none">
    <G fillRule="evenodd" clipRule="evenodd">
      <Path
        d="M2.27778 16.5C1.78889 16.5 1.37022 16.3261 1.02178 15.9782C0.673926 15.6298 0.5 15.2111 0.5 14.7222V2.27778C0.5 1.78889 0.673926 1.37022 1.02178 1.02178C1.37022 0.673926 1.78889 0.5 2.27778 0.5H7.61111C7.86296 0.5 8.07422 0.585037 8.24489 0.755111C8.41496 0.925778 8.5 1.13704 8.5 1.38889C8.5 1.64074 8.41496 1.8517 8.24489 2.02178C8.07422 2.19244 7.86296 2.27778 7.61111 2.27778H2.27778V14.7222H14.7222V9.38889C14.7222 9.13704 14.8076 8.92578 14.9782 8.75511C15.1483 8.58504 15.3593 8.5 15.6111 8.5C15.863 8.5 16.0739 8.58504 16.244 8.75511C16.4147 8.92578 16.5 9.13704 16.5 9.38889V14.7222C16.5 15.2111 16.3261 15.6298 15.9782 15.9782C15.6298 16.3261 15.2111 16.5 14.7222 16.5H2.27778ZM5.83333 11.1667C5.67037 11.0037 5.58889 10.7963 5.58889 10.5444C5.58889 10.2926 5.67037 10.0852 5.83333 9.92222L13.4778 2.27778H11.1667C10.9148 2.27778 10.7039 2.19244 10.5338 2.02178C10.3631 1.8517 10.2778 1.64074 10.2778 1.38889C10.2778 1.13704 10.3631 0.925778 10.5338 0.755111C10.7039 0.585037 10.9148 0.5 11.1667 0.5H15.6111C15.863 0.5 16.0739 0.585037 16.244 0.755111C16.4147 0.925778 16.5 1.13704 16.5 1.38889V5.83333C16.5 6.08519 16.4147 6.29615 16.244 6.46622C16.0739 6.63689 15.863 6.72222 15.6111 6.72222C15.3593 6.72222 15.1483 6.63689 14.9782 6.46622C14.8076 6.29615 14.7222 6.08519 14.7222 5.83333V3.52222L7.05556 11.1889C6.89259 11.3519 6.69259 11.4333 6.45556 11.4333C6.21852 11.4333 6.01111 11.3444 5.83333 11.1667Z"
        fill={fillColor}
      />
    </G>
  </Svg>
)
