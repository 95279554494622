import { StyleSheet } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { DialogHeights } from './common'

export const dialogStyles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    position: 'absolute',
    zIndex: -100,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  dialogOverlay: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  dialogBackground: {
    backgroundColor: Colors.white,
    borderRadius: 16,
    flex: 1,
    height: 'auto',
    minHeight: DialogHeights.SMALL,
    maxHeight: DialogHeights.BIG,
    overflow: 'scroll',
  },
  dialogContent: {
    flex: 1,
    overflow: 'scroll',
    height: '100%',
    width: '100%',
  },
})
