import { useApolloClient, useMutation } from '@apollo/client'
import { TREATMENT_FIELDS_BASE } from 'components/AddTreatment/graphql'
import { cloneFluidTreatmentUpdateValue } from 'components/EditTreatment/utils/updateFluidTreatmentUtils'
import { toast } from 'src/components/common/Toast/ToastArea'
import { useRoundingPrecision } from 'src/hooks/useRound'
import { Status } from 'src/types/globalTypes'
import { TaskFieldsFull } from 'src/types/TaskFieldsFull'
import { TreatmentFieldsBase } from 'src/types/TreatmentFieldsBase'
import { SentryExpo } from 'src/utils/errorTracking/sentry/common'

import { TASK_FIELDS_FULL, UPDATE_TASK_FULL } from '../graphql'
import { UpdateTask, UpdateTaskVariables } from '../types'
import { getIsFluidStyleTaskDisplay } from './getTreatmentInfo'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'

export const useUpdateTask = () => {
  const [updateTask] = useMutation<UpdateTask, UpdateTaskVariables>(
    UPDATE_TASK_FULL,
    {
      onCompleted: data => {
        const message = 'Completed Task'
        SentryExpo.addBreadcrumb({
          data,
          message,
          category: 'user_action',
          level: 'info',
        })
      },
    },
  )
  const client = useApolloClient()
  const roundingPrecision = useRoundingPrecision()
  const { dateFormatter } = useAdminTimeFormat()

  return (taskInput: UpdateTaskVariables['input']) => {
    const cachedTask = client.readFragment<TaskFieldsFull>({
      id: `Task:${taskInput.id}`,
      fragment: TASK_FIELDS_FULL,
      fragmentName: 'TaskFieldsFull',
    })

    if (!cachedTask) {
      return
    }

    const optimisticUpdatedTask = {
      ...cachedTask,
      updated_at: new Date().toISOString(),
      // We only need this _pending field for the ... effect on the updating task box
      _pending: true,
    }
    const fragmentVariable = {
      fragment: TREATMENT_FIELDS_BASE,
      fragmentName: 'TreatmentFieldsBase',
      id: `Treatment:${cachedTask.treatment_id}`,
    }

    const cachedTreatment =
      client.readFragment<TreatmentFieldsBase>(fragmentVariable)

    return updateTask({
      variables: {
        input: taskInput,
      },
      optimisticResponse: {
        updateTask: optimisticUpdatedTask,
      },
      update: () => {
        if (!cachedTreatment) {
          return
        }
        const updatedValue = taskInput.value ?? cachedTask.value

        if (!getIsFluidStyleTaskDisplay(cachedTreatment) || !updatedValue) {
          return
        }

        const isFluidValueChange = taskInput.status === Status.IN_PROGRESS

        if (!isFluidValueChange) {
          return
        }
        // update the fluid total or cri calculator when the task is IN_PROGRESS
        const clonedTreatment = cloneFluidTreatmentUpdateValue(
          cachedTreatment,
          updatedValue,
          roundingPrecision,
        )
        client.writeFragment({
          ...fragmentVariable,
          data: clonedTreatment,
        })
      },
    }).catch(err => {
      const curDate = dateFormatter(new Date(), 'monthdaytime')
      toast.error(
        `Error Updating Task ${curDate} - ${cachedTreatment?.name ?? ''}`,
      )
      // eslint-disable-next-line no-console
      console.error(err)
    })
  }
}
