import React, { Suspense, useEffect, useState } from 'react'
import { Amplify } from 'aws-amplify'
import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import { useFlags } from 'react-native-flagsmith/react'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import { RootNavigationContainer } from 'components/Base/screens/RootNavigationContainer'
import { LoginScreens } from 'components/common/Login/Authenticator'
import { ToastArea } from 'components/common/Toast/ToastArea'
import { ErrorView } from 'components/Error/ErrorView'
import { Outage } from 'components/Error/Outage'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { Fonts } from 'src/constants/Fonts'
import { ExpoUpdates } from 'src/context/ExpoUpdates'
import { FeatureFlagProvider, initFlagSmith } from 'src/context/featureFlags'
import { PatientPanelDrawerProvider } from 'src/context/patientPanelDrawer'
import { initDatadog } from 'src/utils/errorTracking/datadog'
import { initSentry } from 'src/utils/errorTracking/sentry'

import { AuthProvider } from './context/auth'
import { Appcues } from './utils/appcues'
import { WalkMe } from './utils/walkme'
import { SentryErrorBoundary } from './utils/errorTracking/sentry/common'
import { SyncStorage } from './utils/SyncStorage'
import vetradarmobile from './vetradar-exports'

import './i18n'
import './swEventHandlers'

// Lazy imports for code splitting
const AppScreen = React.lazy(
  () => import('./components/Base/screens/AppScreen'),
)

const Providers = React.lazy(() => import('./Providers'))

Amplify.configure(vetradarmobile)

export function loadFonts() {
  return Font.loadAsync({
    [Fonts.regular]: require('assets/fonts/NunitoSans-Regular.ttf'),
    [Fonts.semibold]: require('assets/fonts/NunitoSans-SemiBold.ttf'),
    [Fonts.bold]: require('assets/fonts/NunitoSans-Bold.ttf'),
    [Fonts.extrabold]: require('assets/fonts/NunitoSans-ExtraBold.ttf'),
    [Fonts.italic]: require('assets/fonts/NunitoSans-Italic.ttf'),
    [Fonts.textSecurityDisc]: require('text-security/text-security-disc-compat.ttf'),
  })
}

const OutageBlocker: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const flags = useFlags([FeatureFlagNames.OutageBlockUI])
  return flags.outage_block_ui.enabled ? <Outage /> : children
}

export const App = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-console
    SplashScreen.preventAutoHideAsync().catch(e => console.warn(e))
    initDatadog()
    initSentry()

    Promise.all([loadFonts(), SyncStorage.sync(), initFlagSmith()]).then(() => {
      Appcues.load()
      WalkMe.load()
      setLoadingComplete(true)
      SplashScreen.hideAsync()
    })
  }, [])

  if (!isLoadingComplete) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <SentryErrorBoundary
        fallback={({ error }) => <ErrorView error={error} />}
      >
        <ExpoUpdates>
          <SafeAreaProvider>
            <FeatureFlagProvider>
              <RootNavigationContainer>
                <AuthProvider>
                  <LoginScreens>
                    <Suspense fallback={null}>
                      <Providers>
                        <OutageBlocker>
                          <PatientPanelDrawerProvider>
                            <AppScreen />
                          </PatientPanelDrawerProvider>
                        </OutageBlocker>
                      </Providers>
                    </Suspense>
                  </LoginScreens>
                </AuthProvider>
                <ToastArea />
              </RootNavigationContainer>
            </FeatureFlagProvider>
          </SafeAreaProvider>
        </ExpoUpdates>
      </SentryErrorBoundary>
    </Suspense>
  )
}
