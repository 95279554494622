import { ReadFieldFunction } from '@apollo/client/cache/core/types/common'
import { Dictionary, flatten, uniqBy, values } from 'lodash'
import { listWhiteboardPatients_listWhiteboardPatients_items as WhiteboardPatientItems } from 'types/listWhiteboardPatients'

// Note: we only need to handle due and pending tasks. Missed tasks are automatically
// handled for multiple consultations.
// Sum all due tasks for a patient across multiple consultations
const aggregateDueTasks = (
  patient: WhiteboardPatientItems,
  item: WhiteboardPatientItems,
) => {
  const dueTasks = { ...patient.due_tasks }
  dueTasks.due_count += item.due_tasks.due_count

  const dueTaskSheetGroups = dueTasks?.top_sheet_groups?.concat(
    item.due_tasks.top_sheet_groups ?? [],
  )
  dueTasks.top_sheet_groups = uniqBy(dueTaskSheetGroups, 'sheet_group_name')
  return dueTasks
}

// Sum all pending tasks for a patient across multiple consultations
const aggregatePendingAndDoneTasks = (
  patient: WhiteboardPatientItems,
  item: WhiteboardPatientItems,
) => {
  const totalPendingTasks = []

  for (let i = 0; i < item.tasks.length; i += 1) {
    totalPendingTasks.push({ ...patient.tasks[i] })
    totalPendingTasks[i].pending_count += item.tasks[i].pending_count
    totalPendingTasks[i].done_count += item.tasks[i].done_count

    const totalSheetGroups = totalPendingTasks[i]?.top_sheet_groups?.concat(
      item.tasks[i].top_sheet_groups ?? [],
    )

    totalPendingTasks[i].top_sheet_groups = uniqBy(
      totalSheetGroups,
      'sheet_group_name',
    )
  }
  return totalPendingTasks
}

export const aggregatePatientItems = (
  existingItems: Dictionary<WhiteboardPatientItems[]>,
  incomingGroupedItems: Dictionary<WhiteboardPatientItems[]>,
  readField: ReadFieldFunction,
) => {
  const patientItemMap: { [patientId: string]: WhiteboardPatientItems } = {}
  const patientItemsFlatten = flatten(
    values({ ...existingItems, ...incomingGroupedItems }),
  )

  patientItemsFlatten.forEach((item: WhiteboardPatientItems) => {
    const currentPatientId =
      (readField('patient_id', item.patient as {}) as string) ?? ''

    if (!patientItemMap[currentPatientId]) {
      patientItemMap[currentPatientId] = { ...item }
    } else {
      patientItemMap[currentPatientId].due_tasks = aggregateDueTasks(
        patientItemMap[currentPatientId],
        item,
      )
      patientItemMap[currentPatientId].tasks = aggregatePendingAndDoneTasks(
        patientItemMap[currentPatientId],
        item,
      )
    }
  })

  return Object.values(patientItemMap)
}
