/* eslint-disable react-native/no-unused-styles */
import React, { useMemo } from 'react'
import { Colors } from 'constants/Colors'
import { StyleSheet, View } from 'react-native'

export const Indicator: React.FC<{
  size?: number
  color?: string
  strokeColor?: string
  accessibilityLabel?: string
}> = ({
  size = 16,
  color = Colors.approval.primary,
  strokeColor = Colors.backgroundGrey,
  accessibilityLabel,
}) => {
  /* eslint-disable react-native/no-unused-styles */
  const styles = useMemo(() => {
    return StyleSheet.create({
      indicator: {
        height: size,
        width: size,

        borderRadius: size / 2, // Formula for displaying as a circle
        borderColor: strokeColor,
        borderWidth: 2,

        backgroundColor: color,

        position: 'absolute',
        top: -(size / 2), // position center of circle top of parent
        right: -(size / 2), // position center of circle right of parent
      },
    })
  }, [color, size, strokeColor])

  return (
    <View style={styles.indicator} accessibilityLabel={accessibilityLabel} />
  )
}
