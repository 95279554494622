import { weightFactor, WeightUnit } from 'constants/Weight'

type DosageSafeRangeComparisonInformation = {
  lowestDosage: number | null
  highestDosage: number | null
  dosageWeightUnit: WeightUnit | null
  dosagePerWeightUnit: WeightUnit | null
  safeDosageWeightUnit: WeightUnit | null
  safeDosagePerWeightUnit: WeightUnit | null
  dosageRate: number | null
}

export const getIsDosageRateInSafeRange = (
  dosageSafeRangeComparisonInformation: DosageSafeRangeComparisonInformation,
) => {
  const {
    lowestDosage,
    highestDosage,
    dosageWeightUnit,
    dosagePerWeightUnit,
    safeDosageWeightUnit,
    safeDosagePerWeightUnit,
    dosageRate,
  } = dosageSafeRangeComparisonInformation

  // all of these values are needed to compare
  if (
    !dosageRate ||
    !dosagePerWeightUnit ||
    !dosageWeightUnit ||
    !safeDosagePerWeightUnit ||
    !safeDosageWeightUnit
  )
    return true

  const dosagePerWeightRatio =
    (dosageRate * weightFactor[dosageWeightUnit]) /
    weightFactor[dosagePerWeightUnit]
  let isDosageRateInSafeRange = true

  if (highestDosage) {
    const safeUpperDosagePerWeightRatio =
      (highestDosage * weightFactor[safeDosageWeightUnit]) /
      weightFactor[safeDosagePerWeightUnit]
    isDosageRateInSafeRange =
      safeUpperDosagePerWeightRatio >= dosagePerWeightRatio
  }
  if (!isDosageRateInSafeRange) return false

  if (lowestDosage) {
    const safeLowerDosagePerWeightRatio =
      (lowestDosage * weightFactor[safeDosageWeightUnit]) /
      weightFactor[safeDosagePerWeightUnit]
    isDosageRateInSafeRange =
      dosagePerWeightRatio >= safeLowerDosagePerWeightRatio
  }

  return isDosageRateInSafeRange
}
