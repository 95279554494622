import { Routes } from 'src/constants/Routes'

type GetHeaderConfigParams = {
  isAdding: boolean
  navigate: any // TODO: update to url param
}

// Only used in standalone mode
export const getPatientListActionButton = ({
  isAdding,
  navigate,
}: GetHeaderConfigParams) => ({
  title: 'general.add',
  label: 'patient:list.headlineAddLabel',
  action: () => navigate(Routes.AddPatient),
  disabled: isAdding,
})
