import React, { useMemo, useState } from 'react'
import { SvgClose } from 'components/Icons/Close'
import { Search } from 'components/Icons/Search'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import debounce from 'lodash/debounce'
import {
  StyleProp,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'

type Props = {
  placeholder?: string
  autoFocus?: boolean
  onChangeText: (text: string) => void
  style?: StyleProp<ViewStyle>
  testID?: string
  wait?: number
  showClearButton?: boolean
}

export const SearchInput: React.FC<Props> = ({
  placeholder = 'Search',
  autoFocus,
  onChangeText,
  style,
  testID,
  wait = 200,
  showClearButton = false,
}) => {
  const [value, setValue] = useState('')
  // TODO: use useDebounceCallback
  const debouncedOnChangeText = useMemo(
    () => debounce(onChangeText, wait),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeText],
  )
  const changeText = (text: string) => {
    setValue(text)
    debouncedOnChangeText(text)
  }
  const clearText = () => {
    setValue('')
    onChangeText('')
  }
  return (
    <View style={[styles.container, style]}>
      <View style={styles.flexContain}>
        <View style={styles.searchIcon}>
          <Search />
        </View>
        <TextInput
          testID={testID}
          autoFocus={autoFocus}
          style={styles.input}
          placeholder={placeholder}
          placeholderTextColor={Colors.placeholderGrey}
          value={value}
          onChangeText={changeText}
        />
        {showClearButton && !!value ? (
          <TouchableOpacity onPress={clearText} style={styles.clearIcon}>
            <SvgClose length={14} color={Colors.contentPrimary} />
          </TouchableOpacity>
        ) : null}
        {/* There was a requirement for using barcode to search.. Leave it here for future reference */}
        {/* <View style={styles.CameraBtn}>
          <Camera />
        </View> */}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  flexContain: {
    flex: 1,
    position: 'relative',
  },
  searchIcon: {
    left: 10,
    position: 'absolute',
    top: 12,
    zIndex: 1,
  },
  clearIcon: {
    right: 10,
    position: 'absolute',
    top: 12,
    zIndex: 1,
  },
  input: {
    fontFamily: Fonts.regular,
    borderRadius: 10,
    backgroundColor: Colors.white,
    color: Colors.contentPrimary,
    fontSize: 17,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 30,
  },
})
