import { Scope, SentryExpo } from 'src/utils/errorTracking/sentry/common'
import { ErrorResponse } from '@apollo/client/link/error'

import { reconnectWs } from '../wsLink'
import { Operation } from '@apollo/client/core'

export const networkErrorHandler = (
  networkError: NonNullable<ErrorResponse['networkError']>,
  operation: Operation,
) => {
  if (networkError.message.indexOf('jwt') !== -1) {
    // websocket token expired, reconnect'
    reconnectWs()
    return
  }
  SentryExpo.withScope((scope: Scope) => {
    scope.setContext('operation', {
      operationName: operation.operationName,
      query: JSON.stringify(operation.query),
      variables: JSON.stringify(operation.variables),
    })
    SentryExpo.captureException(networkError)
  })
  console.error(networkError) // eslint-disable-line no-console
}
