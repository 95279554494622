import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { getWidth } from 'src/utils/getWidth'
import { getPatient_getPatient as GetPatientData } from 'types/getPatient'
import { Fonts } from 'constants/Fonts'

const MAX_PATIENT_NAME_SIZE = 0.3

type Props = {
  patient: GetPatientData
  isPatientPanelSplitLayout: boolean | undefined
  patientNameWidth: number
  primaryInfoWidth: number
  setPatientNameWidth: (value: React.SetStateAction<number>) => void
}

export const GetPatientName = ({
  patient,
  isPatientPanelSplitLayout,
  patientNameWidth,
  primaryInfoWidth,
  setPatientNameWidth,
}: Props) => {
  const patientFullName = `"${patient.name}" ${
    patient.contact?.last_name ?? ''
  }`
  const isShorthandPatientName =
    isPatientPanelSplitLayout &&
    patientNameWidth > primaryInfoWidth * MAX_PATIENT_NAME_SIZE

  return isShorthandPatientName ? (
    <View style={styles.shorthandPatientName}>
      <Text
        style={[styles.patientName, styles.noMarginRight]}
        numberOfLines={1}
        ellipsizeMode={'tail'}
      >{`"${patient.name}`}</Text>
      <Text style={styles.patientName}>"</Text>
    </View>
  ) : (
    <Text
      style={styles.patientName}
      numberOfLines={1}
      ellipsizeMode={'tail'}
      onLayout={event => {
        if (patientNameWidth) return
        setPatientNameWidth(getWidth(event))
      }}
    >
      {patientFullName}
    </Text>
  )
}

const styles = StyleSheet.create({
  patientName: {
    fontFamily: Fonts.bold,
    marginRight: 8,
    flex: -1,
  },
  noMarginRight: {
    marginRight: 0,
  },
  shorthandPatientName: {
    flexDirection: 'row',
    flex: -1,
  },
})
