import { Storage } from '../utils/Storage'

const DEVICE_LOCK_STORAGE_NAME = 'device_lock'
const IS_DEVICE_LOCK_KEY_NAME = 'is_locked'

// TODO: Remove isLocked from AuthV2 in https://ezyvet.atlassian.net/browse/VR-10733
// We don't need this field to call loginWithPin for AuthV2, we use the authWeight instead
export class DeviceLockService {
  private storage: Storage

  constructor() {
    this.storage = new Storage(DEVICE_LOCK_STORAGE_NAME)
  }

  public lock(): void {
    this.storage.setItem(IS_DEVICE_LOCK_KEY_NAME, true)
  }

  public unlock(): void {
    this.storage.setItem(IS_DEVICE_LOCK_KEY_NAME, false)
  }

  public isLocked(): boolean {
    return this.storage.getItem(IS_DEVICE_LOCK_KEY_NAME) ?? false
  }

  public removeLock() {
    this.storage.removeItem(IS_DEVICE_LOCK_KEY_NAME)
  }
}

export const deviceLockService = new DeviceLockService()
