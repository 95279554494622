import { useNavigation } from '@react-navigation/native'
import { AppScreensConfig } from 'components/Base/screens/AppScreensConfig'
import { EmergencyDrugsButton } from 'components/EmergencyDrugsButton/EmergencyDrugsButton'
import { Routes } from 'constants/Routes'
import Constants from 'expo-constants'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'

import { Colors } from 'src/constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { HeaderNavButtons } from '../HeaderNavButtons'
import { AccountButton } from 'components/Account/AccountButton'
import { SvgHelp } from 'components/Icons'
import { useHelp } from 'src/context/help/help'
import { SvgClockRotateLeftSolid } from 'components/Icons/ClockRotateLeftSolid'
import { SheetHistoryContainer } from 'components/UserHistory/SheetHistory/SheetHistoryContainer'
import { useTranslation } from 'react-i18next'
import { useLazySheetHistory } from 'src/hooks/useSheetHistory'
import { useOrganisation } from 'src/context/organisation'
import { useUser } from 'src/context/user'
import { HEADER_HEIGHT, HEADER_PADDING } from 'constants/Header'

const VRLogo: React.FC = () => {
  const { navigate } = useNavigation()

  const navigateToPatientList = useCallback(() => {
    navigate(Routes.PatientsStack, {
      screen: AppScreensConfig[Routes.PatientsStack].rootStack,
    })
  }, [navigate])

  return (
    <TouchableOpacity
      onPress={navigateToPatientList}
      accessibilityLabel="Stethoscope button"
    >
      <Image
        style={styles.logo}
        resizeMode={'contain'}
        source={require('assets/images/vetradar-icon-white.png')}
      />
    </TouchableOpacity>
  )
}

const HelpButton: React.FC = () => {
  const { toggleHelpModal } = useHelp()

  return (
    <Pressable onPress={toggleHelpModal} style={styles.helpButtonContainer}>
      <SvgHelp fillColor={Colors.white} />
    </Pressable>
  )
}

const SheetHistoryButton = (props: { onPress: () => void }) => {
  const { t } = useTranslation()
  return (
    <Pressable
      onPress={props.onPress}
      accessibilityLabel={t('history:buttonLabel')}
    >
      <SvgClockRotateLeftSolid color={Colors.white} width={24} height={24} />
    </Pressable>
  )
}

export const Header = React.memo(() => {
  const breakpoint = useBreakpoint()
  const { user } = useUser()
  const [{ organisationId }] = useOrganisation()
  const navigation = useNavigation()

  const [showSheetHistory, setShowSheetHistory] = useState(false)
  const isSmallWebView = !breakpoint.isLargeScreen

  const onSheetHistoryButtonPress = useCallback(
    () => setShowSheetHistory(state => !state),
    [],
  )

  const onCloseSheetHistory = useCallback(() => setShowSheetHistory(false), [])

  const { loadSheetHistory } = useLazySheetHistory(
    user?.id as string,
    organisationId,
  )

  useEffect(() => {
    if (user) {
      loadSheetHistory()
    }
  }, [loadSheetHistory, user])

  if (Platform.OS !== 'web') {
    return <View testID="NavHeader" style={styles.statusBar} />
  }
  if (isSmallWebView) {
    return null
  }

  return (
    <>
      <View testID="NavHeader" style={styles.container}>
        <View style={styles.section}>
          <VRLogo />
          <View style={styles.emergencyDrugsButton}>
            <EmergencyDrugsButton />
          </View>
          <View style={styles.userHistoryButtonContainer}>
            <SheetHistoryButton onPress={onSheetHistoryButtonPress} />
          </View>
        </View>
        <View style={styles.section}>
          <HeaderNavButtons />
          <HelpButton />
          <AccountButton navigation={navigation} />
        </View>
      </View>

      <SheetHistoryContainer
        visible={showSheetHistory}
        onClose={onCloseSheetHistory}
      />
    </>
  )
})

Header.displayName = 'Header'

export const styles = StyleSheet.create({
  container: {
    paddingLeft: HEADER_PADDING,
    paddingRight: HEADER_PADDING,
    backgroundColor: Colors.contentSecondary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    height: HEADER_HEIGHT,
  },
  logo: {
    height: 38,
    width: 113.24,
    marginRight: 15,
  },
  statusBar: {
    height: Constants.statusBarHeight,
    backgroundColor: Colors.vetRadarBlue,
  },
  emergencyDrugsButton: {
    marginRight: 20,
  },
  userHistoryButtonContainer: {
    marginHorizontal: 20,
  },
  helpButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
    marginRight: 46,
  },
})
