import { getProduct_getProduct as Product } from 'types/getProduct'

const defaultCustomProductOriginIdPrefix = 'VR-'

export const generateProductPimsMapping = (
  selectedCustomPimsMapping: string | null,
  product?: Product,
) => {
  // if the product has same pims or product is from ezyvet
  // keep the existing pims
  if (
    !!product?.pims_mapping?.find(
      pims => pims.dest === selectedCustomPimsMapping,
    ) ||
    (product?.origin_id &&
      !product.origin_id.startsWith(defaultCustomProductOriginIdPrefix))
  ) {
    return product?.pims_mapping
  }

  if (selectedCustomPimsMapping) {
    return [{ dest: selectedCustomPimsMapping, field: null, type: 'EZYVET' }]
  }
  return null
}
