import React from 'react'
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native'
import { Colors } from 'src/constants/Colors'

type tabOption<T> = {
  text: string
  value: T
}

type tabsProps<T> = {
  options: tabOption<T>[]
  selected: T
  onPressHandler: Function
}

export function Tabs<T>({ options, selected, onPressHandler }: tabsProps<T>) {
  return (
    <View style={styles.tabsContainer}>
      {options.map((option: tabOption<T>) => {
        const isSelected = selected === option.value
        return (
          <View key={option.text} style={styles.tabContainer}>
            <TouchableOpacity onPress={() => onPressHandler(option.value)}>
              <View
                accessibilityLabel={`${option.text} tab option${
                  isSelected ? ' selected' : ''
                }`}
                style={[styles.tabButton, isSelected && styles.selectedTab]}
              >
                <Text
                  style={[styles.tabText, isSelected && styles.selectedText]}
                >
                  {option.text}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )
      })}
    </View>
  )
}

export const styles = StyleSheet.create({
  tabButton: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingTop: 8,
    paddingBottom: 2,
    borderBottomWidth: 0,
    borderBottomColor: 'transparent',
  },
  tabText: {
    fontSize: 18,
    marginRight: 'auto',
    paddingVertical: 2,
    textAlign: 'center',
    color: Colors.contentTertiary,
  },
  tabContainer: {
    paddingVertical: 8,
    paddingHorizontal: 4,
    marginHorizontal: '4%',
    alignItems: 'center',
  },
  tabsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 20,
  },
  selectedTab: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.tag.aggressivePink,
  },
  selectedText: {
    color: Colors.contentPrimary,
  },
})
