import { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { toast } from 'components/common'
import { useTranslation } from 'react-i18next'
import { environment } from 'src/config'
import { useClientQueryPromise } from 'src/utils/useClientQueryPromise'
import {
  GET_ALL_SHEET_LOGS_BY_ORGANISATION_ID,
  GET_SHEET_LOG_META_DATA,
} from 'components/Sheet/graphql'
import {
  getAllSheetLogsByOrganisationId,
  getAllSheetLogsByOrganisationIdVariables,
} from 'types/getAllSheetLogsByOrganisationId'

import { ContentType, download as downloadFile } from 'src/utils/download'

import {
  buildSheetLogCSV,
  buildSheetLogCSVFileName,
} from 'components/Sheet/utils/buildSheetLogCSV'
import {
  getSheetLogMetaData,
  getSheetLogMetaDataVariables,
} from 'types/getSheetLogMetaData'
import {
  getSettings as GetSettings,
  getSettingsVariables as GetSettingsVariables,
} from 'types/getSettings'
import { GET_SETTINGS } from 'components/Settings/graphql'
import { OrgSettingKeys } from 'src/hooks/useOrgSettings'

export const useSheetChangeLogDownloader = (
  sheetId: string,
  organisationId: string,
) => {
  const loadingToast = useRef<null | (() => void)>(null)

  const { t } = useTranslation()

  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const getSheetLogs = useClientQueryPromise<
    getAllSheetLogsByOrganisationId,
    getAllSheetLogsByOrganisationIdVariables
  >({
    query: GET_ALL_SHEET_LOGS_BY_ORGANISATION_ID,
    fetchPolicy: 'network-only',
  })

  const getSheetLogsMetaData = useClientQueryPromise<
    getSheetLogMetaData,
    getSheetLogMetaDataVariables
  >({
    query: GET_SHEET_LOG_META_DATA,
    fetchPolicy: 'network-only',
  })

  const getOrgSettings = useClientQueryPromise<
    GetSettings,
    GetSettingsVariables
  >({
    query: GET_SETTINGS,
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (isDownloading) {
      loadingToast.current = toast.disconnected(t('sheet:changeLogDownloading'))
      return
    }

    loadingToast.current?.()
    loadingToast.current = null
  }, [isDownloading, t])

  const canDownload = useMemo(() => {
    return environment.isWeb
  }, [])

  const download = useCallback(async () => {
    // stop to trigger download again if it is downloading state
    if (isDownloading || !canDownload) return

    setIsDownloading(true)

    try {
      const [
        {
          data: { getAllSheetLogsByOrganisationId: sheetLogs },
        },
        {
          data: { getSheet },
        },
        {
          data: { getOrganisation },
        },
      ] = await Promise.all([
        getSheetLogs({
          sheet_id: sheetId,
          organisation_id: organisationId,
        }),
        getSheetLogsMetaData({
          sheet_id: sheetId,
          organisation_id: organisationId,
        }),
        getOrgSettings({
          id: organisationId,
        }),
      ])

      const sheetLogMetaData = {
        patientCode: getSheet?.patient.code ?? '',
        patientName: `${getSheet?.patient.name ?? ''} ${
          getSheet?.patient?.contact?.last_name ?? ''
        }`.trim(),
        consultationId: getSheet?.consultation?.origin_reference ?? '',
        sheetName: getSheet?.name ?? '',
      }

      const timezoneSetting = getOrganisation?.settings?.find(
        setting => setting?.key === OrgSettingKeys.TIMEZONE,
      )

      const timezone = timezoneSetting?.value ?? ''

      const sheetLogsCSVFileName = buildSheetLogCSVFileName(
        sheetLogMetaData.patientName,
        sheetLogMetaData.sheetName,
        timezone,
      )

      const sheetLogsCSV = await buildSheetLogCSV(sheetLogs, sheetLogMetaData)

      downloadFile(sheetLogsCSV, sheetLogsCSVFileName, ContentType.CSV)
    } catch (_) {
      toast.error(t('sheet:downloadSheetChangeLogFailure'))
    }

    setIsDownloading(false)
  }, [
    isDownloading,
    canDownload,
    getSheetLogs,
    sheetId,
    organisationId,
    getSheetLogsMetaData,
    getOrgSettings,
    t,
  ])

  return { download, canDownload }
}
