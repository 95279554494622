import React, { useCallback, useMemo, useState } from 'react'
import MenuRaw from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Fonts } from 'constants/Fonts'
import { isEmpty, noop, take } from 'lodash'
import {
  ActivityIndicator,
  GestureResponderEvent,
  LayoutChangeEvent,
  StyleSheet,
  Text,
} from 'react-native'
import { withCreateElement } from 'src/hocs/withCreateElement'

import { FormField } from '../Form/FormField'
import { PossibleSelectValues, Props } from './index.types'

const Menu = withCreateElement(MenuRaw)
const MENU_ITEM_MIN_LENGTH = 150

export function MultiSelect<T extends PossibleSelectValues>({
  a11yLabel,
  disabled = false,
  label,
  onChange = noop,
  options,
  placeholder = ' ',
  selected,
  status,
  style,
  loading = false,
  hideChevron,
  iconLeft,
}: Props<T>) {
  const [inputWidth, setInputWidth] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const openMenu = useCallback((event: GestureResponderEvent) => {
    const target = event.currentTarget
    requestAnimationFrame(() => setAnchorEl(target as any))
  }, [])

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const onPress = (value: T) => {
    if (!selected) {
      return onChange([value])
    }
    if (selected.indexOf(value) === -1) {
      onChange([...selected, value])
    } else {
      onChange(selected.filter(s => s !== value))
    }
  }

  const onInputLayout = useCallback((event: LayoutChangeEvent) => {
    setInputWidth(event.nativeEvent.layout.width)
  }, [])

  const selectedTextToShow = useMemo(() => {
    if (!selected || isEmpty(selected)) {
      return placeholder
    }

    if (selected.length === 1) {
      const selectedOption = options.find(
        option => option.value === selected[0],
      )
      return selectedOption?.text ?? placeholder
    }

    const selectedText = take(selected, 4).map(value => {
      return options.find(option => option.value === value)?.text ?? ''
    })

    if (selected.length > 3) {
      return `${selectedText.join(', ')} ...`
    }
    return selectedText.join(', ')
  }, [options, placeholder, selected])

  return (
    <>
      <FormField
        a11yLabel={a11yLabel ? a11yLabel : `${label} Multi Select`}
        active={!!anchorEl}
        disabled={disabled}
        label={label}
        onLayout={onInputLayout}
        onPress={openMenu}
        status={status}
        style={[styles.formField, style]}
        value={selectedTextToShow}
        hideChevron={hideChevron}
        iconLeft={iconLeft}
        {...(loading && { icon: <ActivityIndicator /> })}
      />
      <Menu
        testID={a11yLabel}
        anchorEl={anchorEl}
        keepMounted={true}
        onClose={closeMenu}
        open={!!anchorEl}
        PaperProps={{
          style: {
            width: Math.max(inputWidth, MENU_ITEM_MIN_LENGTH),
          },
        }}
      >
        {!loading &&
          options.map((option, idx) => {
            const { text, value } = option
            const pickerLabel = text || `${value}`
            const isSelected =
              !!selected && selected.indexOf(option.value) !== -1
            return (
              <MenuItem
                key={idx}
                selected={isSelected}
                onClick={() => onPress(option.value)}
              >
                <Text
                  style={{
                    fontFamily: isSelected ? Fonts.bold : Fonts.regular,
                  }}
                >
                  {pickerLabel}
                </Text>
              </MenuItem>
            )
          })}
      </Menu>
    </>
  )
}

const styles = StyleSheet.create({
  formField: {
    minWidth: 96,
  },
})
