import React from 'react'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import {
  getCRIDoseRate,
  getCRIFinalVolumeRate,
  getCRIInfusionRate,
  getCRIMedicationVolumeRate,
} from 'components/Treatment/utils/getCRITreatmentInfo'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { Colors } from 'src/constants/Colors'

type Props = {
  treatment: TreatmentChildLevel | null
  isNativeStyle?: boolean
}

export const IVInfusionsHeader: React.FC<Props> = ({
  treatment,
  isNativeStyle = false,
}) => {
  const { t } = useTranslation()

  if (isNil(treatment)) {
    return <></>
  }

  const roa = treatment.medicine_dosage_info?.route_of_administration
  const diluentUsed = treatment.medicine_dosage_info?.diluent_used

  const infusionRate = getCRIInfusionRate(treatment)
  const doseRate = getCRIDoseRate(treatment)
  const medicationVolumeRate = getCRIMedicationVolumeRate(treatment)
  const finalVolumeRate = getCRIFinalVolumeRate(treatment)

  return (
    <>
      <View style={styles.taskValueContainer}>
        <View style={[styles.taskValue, isNativeStyle && styles.taskValueMob]}>
          <Text style={styles.subLabelText}>
            {t('task:taskAddEdit.infusionRate')}
          </Text>
          <Text
            style={[
              styles.labelText,
              styles.taskValueLabel,
              isNativeStyle && styles.labelTextMob,
            ]}
          >
            {infusionRate}
          </Text>
        </View>
        <View style={[styles.taskValue, isNativeStyle && styles.taskValueMob]}>
          <Text style={styles.subLabelText}>
            {t('task:taskAddEdit:doseRate')}
          </Text>
          <Text
            style={[
              styles.labelText,
              styles.taskValueLabel,
              isNativeStyle && styles.labelTextMob,
            ]}
            testID="doseRate"
          >
            {doseRate}
          </Text>
        </View>
      </View>

      <View style={styles.taskValueContainer}>
        <View style={[styles.taskValue, isNativeStyle && styles.taskValueMob]}>
          <Text style={styles.subLabelText}>
            {t('task:taskAddEdit.medicationVolume')}
          </Text>
          <Text
            style={[
              styles.labelText,
              styles.taskValueLabel,
              isNativeStyle && styles.labelTextMob,
            ]}
            testID="medicationVolume"
          >
            {medicationVolumeRate}
          </Text>
        </View>
        <View style={[styles.taskValue, isNativeStyle && styles.taskValueMob]}>
          <Text style={styles.subLabelText}>
            {t('task:taskAddEdit.finalVolume')}
          </Text>
          <Text
            style={[
              styles.labelText,
              styles.taskValueLabel,
              isNativeStyle && styles.labelTextMob,
            ]}
          >
            {finalVolumeRate}
          </Text>
        </View>
        <View style={[styles.taskValue, isNativeStyle && styles.taskValueMob]}>
          <Text style={styles.subLabelText}>
            {t('task:taskAddEdit.diluentUsed')}
          </Text>
          <Text
            style={[
              styles.labelText,
              styles.taskValueLabel,
              isNativeStyle && styles.labelTextMob,
            ]}
          >
            {diluentUsed}
          </Text>
        </View>
      </View>

      <View style={styles.roaContainer}>
        <Text>{`${t('task:taskAddEdit:routeOfAdmin')}:`}</Text>
        <Text style={styles.roaValue}>{roa}</Text>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  taskValueContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: 'auto',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    paddingBottom: 5,
  },
  taskValue: {
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
  taskValueMob: {
    maxWidth: '40%',
    padding: 12,
  },
  taskValueLabel: {
    paddingVertical: 4,
    textAlign: 'center',
  },
  labelText: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 28,
  },
  labelTextMob: {
    fontSize: 18,
    lineHeight: 20,
  },
  subLabelText: {
    color: Colors.contentTertiary,
    flexWrap: 'wrap',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 'auto',
  },
  roaContainer: {
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 8,
    width: '100%',
  },
  roaValue: {
    paddingLeft: 8,
  },
})
