import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { getBirthAgeYearMonths } from '../utils/getBirthAgeYearMonths'
import { Typography, Colors } from 'src/design-system/theme'
import { Paragraph } from 'src/design-system/components/Text'

type WeightSectionProps = {
  sexTagTitle: string
  weight: number | null
  weightUnit: string | null
  dateOfBirth: string | null
  contactName: string
}

export const WeightSection: React.FC<WeightSectionProps> = ({
  sexTagTitle,
  weight,
  weightUnit,
  dateOfBirth,
  contactName,
}) => {
  return (
    <View style={[styles.row, styles.weightSection]}>
      <Text style={styles.patientDetailsText}>
        <Text style={styles.weightText}>
          {weight ? `${weight}${weightUnit ?? ''}` : `-${weightUnit ?? ''}`}
        </Text>
        <Text style={styles.infoText}>{dateOfBirth ? '  |  ' : ''}</Text>
        <Text style={styles.weightText}>
          {getBirthAgeYearMonths(dateOfBirth)}
        </Text>
        <Text style={styles.infoText}>{sexTagTitle ? '  |  ' : ''}</Text>
        <Text style={styles.weightText}>{sexTagTitle}</Text>
      </Text>
      <Paragraph
        size={'M'}
        style={styles.flexShrinkContainer}
        numberOfLines={1}
      >
        {contactName}
      </Paragraph>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    maxWidth: '100%',
    alignItems: 'baseline',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  weightSection: {
    paddingBottom: 6,
    gap: 8,
  },
  weightText: {
    fontFamily: Typography.FontFamilies.semibold,
    fontSize: 14,
  },
  patientDetailsText: {
    flexShrink: 0,
  },
  infoText: {
    color: Colors.Contents.tertiary,
  },
  flexShrinkContainer: {
    flexShrink: 1,
  },
})
