import React, { useCallback, useContext, useState } from 'react'
import { noop } from 'lodash'

import { DrawerDataType } from './drawerDataType'

export const DrawerContext = React.createContext({
  close: noop as () => void,
  data: null as DrawerDataType,
  isOpen: false,
  open: noop as (data?: DrawerDataType) => void,
})

export const useDrawer = () => useContext(DrawerContext)

type Props = {
  children: React.ReactElement | React.ReactElement[]
}

export const DrawerProvider: React.FC<Props> = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<DrawerDataType>(null)

  const open = useCallback((_data: any) => {
    setIsOpen(true)
    setData(_data)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
    setData(null)
  }, [])

  const value = React.useMemo(
    () => ({
      close,
      data,
      isOpen,
      open,
    }),
    [close, data, isOpen, open],
  )

  return (
    <DrawerContext.Provider value={value}>
      {props.children}
    </DrawerContext.Provider>
  )
}
