import React from 'react'
import { StyleSheet, View, Platform } from 'react-native'
import { noop } from 'lodash'
import { Colors } from 'src/design-system/theme'

import { SvgArrow, SvgPlus } from '../Icons'
import { Pill } from 'src/components/common/Pill'
import { TagItem } from 'components/common/ProductRow/types'
import { ProductRow as BaseProductRow } from 'components/common/ProductRow/components'
import { ProductType } from 'src/types/globalTypes'

export type Product = {
  code: string | null
  id: string
  isBillable?: boolean
  isBundle?: boolean
  name: string
  tags?: TagItem[]
  type: ProductType
  group: {
    id: string
    name: string
    order: number | null
  }
  template_workflow_id?: string | null
}

type Props = {
  a11yLabel?: string
  product: Product
  tags?: TagItem[]
  searchWords: string[]
  onClick?: () => unknown
  isIVInfusion?: boolean
  isQuickAddIcon?: boolean
}

export const ProductRow: React.FC<Props> = ({
  a11yLabel,
  product,
  searchWords = [],
  tags,
  onClick,
  isIVInfusion = false,
  isQuickAddIcon = false,
}) => {
  const showGroupName =
    Platform.OS === 'web' && product.group.name !== 'Template' && !isIVInfusion

  return (
    <BaseProductRow.Container
      accessibilityLabel={a11yLabel}
      onPress={onClick || noop}
    >
      <View style={styles.flex}>
        <BaseProductRow.Heading text={product.name} searchWords={searchWords} />
        <View style={styles.subHeaderRow}>
          {!!tags &&
            tags.map((tag, i) => (
              <Pill
                key={i}
                text={tag.title}
                size="small"
                color={tag.color}
                textStyle={{ color: tag.textColor }}
              />
            ))}
          {!!product.code && (
            <BaseProductRow.SubHeading testID={product.code}>
              {product.code}
            </BaseProductRow.SubHeading>
          )}
        </View>
      </View>
      {showGroupName ? (
        <BaseProductRow.SubHeading testID={product.group.name}>
          {product.group.name}
        </BaseProductRow.SubHeading>
      ) : null}
      {isQuickAddIcon ? <QuickAddIcon /> : <ProductArrow />}
    </BaseProductRow.Container>
  )
}

const ProductArrow = () => (
  <View style={styles.arrowContainer}>
    <SvgArrow orient="right" color="grey" width={15} height={15} />
  </View>
)

const QuickAddIcon = () => (
  <View style={styles.plusContainer}>
    <SvgPlus />
  </View>
)

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  subHeaderRow: {
    flexDirection: 'row',
  },
  plusContainer: {
    backgroundColor: Colors.Backgrounds.accentPrimary,
    borderRadius: 17,
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowContainer: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
