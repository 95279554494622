const removeFirstDot = (str: string) =>
  str.startsWith('.') ? str.substring(1) : str

export const regexToNumber = (str: string) => {
  const numberAndDotRegex = /[^\d.]/g
  const numberString = removeFirstDot(str).replace(numberAndDotRegex, '')
  const splitByDotArray = numberString.split('.')
  if (splitByDotArray.length >= 3) {
    return `${splitByDotArray[0]}.${splitByDotArray[1]}${splitByDotArray[2]}`
  }
  return numberString
}
