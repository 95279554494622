import React, { useState } from 'react'
import { Platform, StyleSheet, SafeAreaView } from 'react-native'
import { Toast } from './Toast'
import { noop } from 'lodash'
import { SentryExpo } from 'src/utils/errorTracking/sentry/common'
import { isGraphQLError } from 'src/apollo/utils/graphQLLogging'
import { ToastType } from './type'

const MAX_SIZE = 12
const MAX_MESSAGE_LENGTH = 100
const SUCCESS_DURATION = 1000
const WARNING_DURATION = 5000
let seed = 0
function getUuid() {
  seed = seed + 1
  return `toast_${seed}`
}
let addToast: (t: Toast) => () => void = () => noop

// TODO: convert params to use object
export const toast = {
  success: (msg: string, onClick?: () => void) =>
    addToast({
      onClick,
      msg: msg.slice(0, MAX_MESSAGE_LENGTH),
      type: ToastType.SUCCESS,
      duration: SUCCESS_DURATION,
    }),
  error: (
    msg: string,
    onClick?: (() => void) | null,
    error?: unknown,
    processDuration?: number,
  ) => {
    if (error) {
      console.error(error) // eslint-disable-line no-console
      // Don't capture network/graphQlErrors, sentry captures in apollo link onError
      if (!isGraphQLError(error)) {
        SentryExpo.captureException(error, { extra: { toastMessge: msg } })
      }
    }
    return addToast({
      onClick,
      msg: msg.slice(0, MAX_MESSAGE_LENGTH),
      type: ToastType.ERROR,
      ...(processDuration && { duration: processDuration }),
    })
  },
  reload: (msg: string, onClick?: () => void) =>
    addToast({
      onClick,
      msg: msg.slice(0, MAX_MESSAGE_LENGTH),
      type: ToastType.RELOAD,
    }),
  process: (
    msg: string,
    onClick?: (() => void) | null,
    maxMessageLength = MAX_MESSAGE_LENGTH,
    processDuration?: number,
  ) =>
    addToast({
      onClick,
      msg: msg.slice(0, maxMessageLength),
      type: ToastType.PROCESS,
      ...(processDuration && { duration: processDuration }),
    }),
  notice: (msg: string, onClick?: () => void, processDuration?: number) =>
    addToast({
      onClick,
      msg: msg.slice(0, MAX_MESSAGE_LENGTH),
      type: ToastType.NOTICE,
      ...(processDuration && { duration: processDuration }),
    }),
  disconnected: (msg: string, onClick?: () => void) =>
    addToast({
      onClick,
      msg: msg.slice(0, MAX_MESSAGE_LENGTH),
      type: ToastType.DISCONNECTED,
    }),
  schedulerWarning: (msg: string, onClick?: () => void) =>
    addToast({
      onClick,
      msg: msg.slice(0, MAX_MESSAGE_LENGTH),
      type: ToastType.SCHEDULER_WARNING,
    }),
  noPermissionWarning: (msg: string, onClick?: () => void) =>
    addToast({
      onClick,
      msg: msg.slice(0, MAX_MESSAGE_LENGTH),
      type: ToastType.NO_PERMISSION_WARNING,
      duration: WARNING_DURATION,
    }),
}

export let clearToasts: () => void = noop

export const ToastArea: React.FC = () => {
  const [toastHeap, setToastStack] = useState<Toast[]>([])

  addToast = (toastItem: Toast) => {
    // Only allow one disconnect/schedulerWarning toast in the heap
    if (
      toastItem.type === 'disconnected' &&
      toastHeap.some(t => t.type === 'disconnected')
    ) {
      return () => remove(toastHeap.find(t => t.type === 'disconnected')!)
    }
    if (
      toastItem.type === 'schedulerWarning' &&
      toastHeap.some(t => t.type === 'schedulerWarning')
    ) {
      return () => remove(toastHeap.find(t => t.type === 'schedulerWarning')!)
    }
    toastItem.id = getUuid()
    setToastStack([toastItem, ...toastHeap].slice(0, MAX_SIZE))
    return () => remove(toastItem)
  }

  clearToasts = () => setToastStack([])

  const remove = (toastItem: Toast) => {
    toastItem.onClick?.()
    setToastStack(preToasts =>
      preToasts.filter(({ id }) => id !== toastItem.id),
    )
  }

  return (
    <SafeAreaView style={styles.container} pointerEvents="box-none">
      <>
        {toastHeap.map(toastItem => (
          <Toast key={toastItem.id} toast={toastItem} onClose={remove} />
        ))}
      </>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    alignItems: 'center',
    width: '100%',
    bottom: Platform.OS === 'web' ? 33 : 20,
  },
})
