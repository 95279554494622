import React from 'react'
import {
  FieldValues,
  FieldPath,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { TextInput, Props as TextInputProps } from './'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> &
  Omit<TextInputProps, 'value' | 'onChangeText'> & {
    onChangeListener?: (value: string) => void
  }

export const ControlledTextInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  defaultValue,
  onChangeListener,
  ...props
}: Props<TFieldValues, TName>) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
    ...(defaultValue !== undefined && { defaultValue }),
  })

  return (
    <TextInput
      {...props}
      value={value as string}
      onChangeText={newValue => {
        onChange(newValue)
        onChangeListener?.(newValue)
      }}
    />
  )
}
