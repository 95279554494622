import React from 'react'
import { ChartText } from 'components/common/Chart/ChartText'
import { ScaleLinear, ScaleTime } from 'd3-scale'
import { G, Line } from 'react-native-svg'
import {
  AnesthesiaChartSymbolShape,
  AnesthesiaChartValueData,
} from 'src/context/types/anesthesia'

import { ChartSVGIconPicker } from './ChartSVGIconPicker'

type Props = {
  currentPoint: AnesthesiaChartValueData
  isMobileScreen: boolean
  mapPointAndDapPoint: AnesthesiaChartValueData[] | null
  showText?: boolean
  symbolShape: AnesthesiaChartSymbolShape
  title: string
  xScale: ScaleTime<number, number>
  yScale: ScaleLinear<number, number>
}

export const PressureRowChartEntry: React.FC<Props> = ({
  currentPoint,
  isMobileScreen,
  mapPointAndDapPoint,
  showText,
  symbolShape,
  xScale,
  yScale,
}) => (
  <G x={xScale(currentPoint.time)} y={yScale(currentPoint.value)}>
    {!!showText && (
      <ChartText isSmall={isMobileScreen}>{currentPoint.value}</ChartText>
    )}
    <G transform="scale(1.6)">
      <ChartSVGIconPicker symbolShape={symbolShape} />
    </G>
    {!!mapPointAndDapPoint &&
      mapPointAndDapPoint.map(nextPoint => (
        <Line
          key={`${nextPoint.value}-${symbolShape}`}
          x1="0"
          x2="0"
          y1={
            (yScale(nextPoint.value) ?? 0) -
            (yScale(currentPoint.value) ?? 0) -
            6
          }
          y2="6"
          stroke="#FFC107"
          strokeWidth="1.5"
        />
      ))}
  </G>
)
