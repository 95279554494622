import { Colors } from 'src/design-system/theme'
import React from 'react'
import Svg, { Circle } from 'react-native-svg'

type Props = {
  size?: number
}

export const SvgKebab: React.FunctionComponent<Props> = ({ size = 30 }) => (
  <Svg
    fill={Colors.Contents.primary}
    width={size}
    height={size}
    viewBox="0 0 512 512"
  >
    <Circle cx="256" cy="256" r="48" />
    <Circle cx="256" cy="416" r="48" />
    <Circle cx="256" cy="96" r="48" />
  </Svg>
)
