import { useCallback, useMemo } from 'react'
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { GET_SHEETS } from 'components/Patient/graphql'
import { findIndex } from 'lodash'
import { useOrganisation } from 'src/context/organisation'
import { getSheets, getSheetsVariables } from 'src/types/getSheets'
import { sortSheetList } from 'components/SheetList/utils/sortSheetList'

import { RangeDirection } from './SheetTitle'

export const useGetPatientSheets = (
  patientId: string,
  sheetId?: string,
  fetchPolicy?: WatchQueryFetchPolicy,
) => {
  const [{ organisationId }] = useOrganisation()

  const { data } = useQuery<getSheets, getSheetsVariables>(GET_SHEETS, {
    variables: {
      organisationId,
      id: patientId,
    },
    fetchPolicy,
    skip: !patientId || !sheetId,
  })

  const patientInfo = data?.getPatient

  const sortedActive = useMemo(
    () =>
      sortSheetList(
        (patientInfo?.sheets?.items ?? []).filter(s => !s.closed_at),
      ),
    [patientInfo],
  )

  const listLength = sortedActive.length
  const hasOneSheet = listLength === 1
  const getCurrentSheetIdx = () => findIndex(sortedActive, ['id', sheetId])
  const idxCurrentSheet = getCurrentSheetIdx()

  const getPreviousOrNextSheet = useCallback(
    (direction: RangeDirection) => {
      if (!direction || hasOneSheet) return
      return sortedActive[
        (idxCurrentSheet + direction + listLength) % listLength
      ]
    },
    [hasOneSheet, sortedActive, idxCurrentSheet, listLength],
  )

  return {
    total: listLength,
    currentIndex: idxCurrentSheet,
    hasOneSheet,
    getPreviousOrNextSheet,
  }
}
