import React, { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { Button, Center, toast } from 'components/common'
import { DraggableList } from 'components/common/DraggableList'
import { SvgHamburger } from 'components/Icons'
import { Input } from 'components/shared'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { Colors } from 'constants/Colors'
import { Routes } from 'constants/Routes'
import { isEqual, noop, pick, sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import {
  BulkUpdateColorsMutation,
  BulkUpdateColorsMutationVariables,
} from 'src/types/BulkUpdateColorsMutation'
import {
  getColors as GetColors,
  getColors_getColors_items as GetColorsItems,
  getColorsVariables as GetColorsVariables,
} from 'src/types/getColors'
import { UpdateColorInput } from 'src/types/globalTypes'
import { evictRootQuery } from 'src/utils/evictRootQuery'
import { shade } from 'src/utils/shade'

import { BULK_UPDATE_COLORS, GET_COLORS } from './graphql'
import { FormValues, useColorsConfigForm } from './useColorsConfigForm'

const getBulkUpdateColorsInput = (
  updatedColors: GetColorsItems[],
  organisationId: string,
): UpdateColorInput[] =>
  updatedColors.map(color => ({
    ...pick(color, ['id', 'name', 'order', 'hex']),
    organisation_id: organisationId,
  }))

export const ColorsConfigScreen: React.FC = () => {
  const [{ organisationId }] = useOrganisation()
  const { navigate } = useNavigation()
  const { t } = useTranslation()

  const navigateSettings = useCallback(
    () => navigate(Routes.Settings),
    [navigate],
  )

  const { data, loading, client } = useQuery<GetColors, GetColorsVariables>(
    GET_COLORS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        getColorsOrganisationId: organisationId,
      },
    },
  )

  const [bulkUpdateColors] = useMutation<
    BulkUpdateColorsMutation,
    BulkUpdateColorsMutationVariables
  >(BULK_UPDATE_COLORS, {
    onCompleted: () => {
      evictRootQuery(
        client.cache,
        /^(listWhiteboardPatients|listWhiteboardWorkflows).*color.*/,
        { delay: 3000 },
      )
      toast.success(t('settings:colors.updateSuccess'))
    },
    onError: error =>
      toast.error(t('settings:colors.updateError'), null, error),
  })

  const initialColors = useMemo(
    () => sortBy(data?.getColors.items ?? [], 'order'),
    [data?.getColors],
  )

  const onSubmit = useCallback(
    (updatedColors: GetColorsItems[]) => {
      // skip mutation if no changes
      if (updatedColors.length) {
        const bulkUpdateColorsInput: UpdateColorInput[] =
          getBulkUpdateColorsInput(updatedColors, organisationId)
        bulkUpdateColors({ variables: { bulkUpdateColorsInput } })
      }
      navigateSettings()
    },
    [bulkUpdateColors, navigateSettings, organisationId],
  )

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: navigateSettings,
  }

  return (
    <>
      <SubHeader
        backButton={backButton}
        headlineKey={t('settings:colors.manageColorsTitle')}
      />
      {/* Not used in native currently but support future usage */}
      {/* <View style={[!environment.isWeb && styles.iosContainer]}> */}
      {loading ? (
        <ActivityIndicator size="large" style={styles.spinner} />
      ) : (
        <ColorsForm initialColors={initialColors} onSubmit={onSubmit} />
      )}
      {/* </View> */}
    </>
  )
}

const ColorItem = ({
  color,
  onPressIn,
}: {
  color: GetColorsItems
  onPressIn: (ev: GestureResponderEvent) => void
}) => {
  return (
    <View
      accessibilityLabel={`Color Sort Item ${color.name}`}
      style={styles.itemContainer}
      key={color.id}
    >
      <>
        <TouchableOpacity style={styles.dragBtn} onPressIn={onPressIn}>
          <SvgHamburger />
        </TouchableOpacity>
      </>
      <Input
        accessibilityLabel={color.name}
        autoFocus={false}
        editable={false} // Disabled until we are interested in CRUD'ing colors
        onChange={noop}
        prefix={
          <View
            style={[
              styles.option,
              {
                backgroundColor: color.hex,
                borderColor: shade(color.hex, -50),
              },
            ]}
          />
        }
        value={color.name}
      />
    </View>
  )
}

// Updates the order field to match the value in the manage colors list, then
// picks the colors that have changed since the initial update
const findReorderedColors = (
  colors: GetColorsItems[],
  initialColors: GetColorsItems[],
) =>
  colors
    .map((e, i) => ({
      ...e,
      // color sort often matches defaults when starts at 1 (though not required)
      order: i + 1,
    }))
    .filter((updatedColor, i) => {
      return !isEqual(updatedColor, initialColors[i])
    })

type ColorsFormProps = { initialColors: GetColorsItems[]; onSubmit: any }

const ColorsForm = ({ initialColors, onSubmit }: ColorsFormProps) => {
  const { t } = useTranslation()

  const handleSubmit = useCallback(
    (submitValues: FormValues) => {
      const updatedColors = findReorderedColors(
        submitValues.colors,
        initialColors,
      )
      onSubmit(updatedColors)
    },
    [initialColors, onSubmit],
  )

  const { setColorOrder, colorList, submitForm } = useColorsConfigForm({
    initialColors,
    onSubmit: handleSubmit,
  })

  const renderItem = useCallback(
    ({
      drag,
      item,
    }: {
      drag: (ev: GestureResponderEvent) => void
      item: GetColorsItems
    }) => {
      return (
        <Center>
          <ColorItem color={item} onPressIn={drag} />
        </Center>
      )
    },
    [],
  )
  const renderListHeader = () => <View style={styles.paddingTop} />
  const renderListFooter = () => (
    <Button
      a11yLabel="Update Colors Button"
      onPress={submitForm}
      style={styles.addButton}
      color={Colors.green}
      title={t('general.saveChanges')}
    />
  )
  return (
    <DraggableList
      items={colorList}
      onMoveEnd={setColorOrder}
      renderItem={renderItem}
      ListHeaderComponent={renderListHeader()}
      ListFooterComponent={renderListFooter()}
    />
  )
}

const styles = StyleSheet.create({
  addButton: {
    marginVertical: 24,
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 5,
  },
  dragBtn: {
    marginRight: 12,
    width: 18,
  },
  option: {
    marginRight: 4,
    marginTop: 4,
    width: 20,
    height: 20,
    borderWidth: 1,
  },
  spinner: {
    marginTop: 25,
  },
  paddingTop: {
    paddingTop: 16,
  },
})
