import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { BulkTaskCreate } from 'src/components/BulkTask/BulkTaskCreate'
import { Colors } from 'src/constants/Colors'
import { TimeSegment } from 'src/hocs/types/time'
import { getSheet_getSheet_treatments_items_treatments_items as SubTreatment } from 'src/types/getSheet'

type Props = {
  sheetId: string
  sheetName: string
  visible: boolean
  toggleBulkTaskCreateDrawer: () => void
  timeSegment: TimeSegment
  bulkTreatmentsToCreate: SubTreatment[]
  patientId?: string
  timePress: Date
  treatmentGroupName: string
}

export const BulkTaskCreateDrawer: React.FC<Props> = React.memo(
  ({
    sheetId,
    sheetName,
    toggleBulkTaskCreateDrawer,
    visible,
    timeSegment,
    patientId = '',
    bulkTreatmentsToCreate,
    timePress,
    treatmentGroupName,
  }) => {
    const { t } = useTranslation()

    return (
      <SheetAwareSideDrawer
        visible={visible}
        title={t('bulkTask:drawer.create')}
        onClose={toggleBulkTaskCreateDrawer}
        titleStyle={styles.bulkTitleStyle}
      >
        <BulkTaskCreate
          sheetId={sheetId}
          patientId={patientId}
          timeSegment={timeSegment}
          timePress={timePress}
          treatmentGroupName={treatmentGroupName}
          sheetName={sheetName}
          treatments={bulkTreatmentsToCreate}
          toggleDrawer={toggleBulkTaskCreateDrawer}
        />
      </SheetAwareSideDrawer>
    )
  },
)

BulkTaskCreateDrawer.displayName = 'BulkTaskCreateDrawer'

const styles = StyleSheet.create({
  bulkTitleStyle: {
    fontSize: 16,
    color: Colors.bulkTask.bulkGrey,
  },
})
