import { AlertLevel, Message } from 'components/common/Avatar/AvatarWithMessage'
import React from 'react'
import { View } from 'react-native'
import { PatientFieldsFull as GetPatientsItem } from 'src/types/PatientFieldsFull'
import { getAlertLevelAndAttitude } from '../utils/getAlertLevelAndMessage'
import { PatientHeaderStyles as styles } from './PatientHeaderStyles'

export const AlertLevelTag: React.FC<{
  patient: Pick<GetPatientsItem, 'attitude'>
}> = ({ patient }) => {
  const { alertLevel, attitude } = getAlertLevelAndAttitude(patient)
  return (
    <View style={styles.tagHolder}>
      <Message alertLevel={alertLevel ?? AlertLevel.NEUTRAL} title={attitude} />
    </View>
  )
}
