import { SvgArrow, SvgPencil } from 'components/Icons'
import { Colors } from 'constants/Colors'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/user'
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { IconButtonWithText } from 'components/IconButtonWithText'
import { Shadows } from 'constants/Shadows'
import { Fonts } from 'constants/Fonts'
import { DrawerAction } from 'components/Sheet/ReadySheet'
import {
  card_status,
  card_status_type,
  local_toggle_type,
} from 'constants/LocalToggleOptions'
import { useLocalToggle } from 'components/Sheet/useLocalToggle'

type StylingProps = {
  containerStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  contentTextStyle?: StyleProp<TextStyle>
  buttonStyle?: StyleProp<ViewStyle>
  buttonTextStyle?: StyleProp<TextStyle>
  buttonIconColor?: string
}

export type Props = {
  isCollapsible?: boolean
  isEditable?: boolean
  key: string
  header?: string
  showHeader?: boolean
  statusId?: card_status_type
  onPress?: (action: DrawerAction | null) => void // temp until we do inline editing
  hasValue?: boolean
  children?: React.ReactNode
  cardStyles?: StylingProps
}

export const Card = ({
  header,
  showHeader = true,
  isCollapsible = false,
  children,
  isEditable = true,
  statusId,
  onPress,
  hasValue = false,
  cardStyles,
}: Props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [isExpanded, setIsExpanded] = useLocalToggle({
    prefix: user?.id ? user.id : '',
    type: local_toggle_type.SHOWPANEL,
    key: statusId,
    initialState: statusId && card_status[statusId],
  })

  const handleExpand = () => {
    setIsExpanded(!isExpanded, statusId)
  }

  return (
    <View style={[styles.card, cardStyles?.containerStyle]}>
      {showHeader && (isCollapsible || header) && (
        <TouchableOpacity
          style={styles.headerContainer}
          disabled={!isCollapsible}
          onPress={handleExpand}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text style={[styles.headerText]}>{header}</Text>
          </View>
          {isCollapsible && (
            <SvgArrow
              width={9}
              height={9}
              color={Colors.contentPrimary}
              orient={isExpanded ? 'up' : 'down'}
            />
          )}
        </TouchableOpacity>
      )}
      {isExpanded && (
        <>
          <View style={[styles.content, cardStyles?.contentStyle]}>
            {!hasValue ? (
              <Text
                style={[styles.contentText, cardStyles?.contentTextStyle]}
              >{`No ${header?.toLowerCase()}`}</Text>
            ) : (
              children
            )}
          </View>
          {isEditable && (
            <IconButtonWithText
              action={onPress}
              label={t('patient:info.saveButton')}
              buttonStyle={[styles.editButton, cardStyles?.buttonStyle]}
              icon={
                <SvgPencil
                  scale={0.6}
                  color={cardStyles?.buttonIconColor || Colors.buttons.blue}
                />
              }
              textStyle={[styles.editText, cardStyles?.buttonTextStyle]}
              title={t('patient:info.editButton')}
              hideText={false}
            />
          )}
        </>
      )}
    </View>
  )
}

export const styles = StyleSheet.create({
  card: {
    backgroundColor: Colors.white,
    borderRadius: 6,
    padding: 8,
    marginVertical: 4,
    ...Shadows.cardShadow,
  },
  headerText: {
    color: Colors.contentPrimary,
    fontSize: 14,
    fontFamily: Fonts.bold,
  },
  content: {
    padding: 8,
    paddingTop: 0,
  },
  contentText: {
    fontSize: 14,
    fontFamily: Fonts.regular,
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    paddingBottom: 8,
  },
  editButton: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  editText: {
    color: Colors.buttons.blue,
    fontFamily: Fonts.extrabold,
    fontSize: 14,
  },
})
