import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { TemplateListScreen } from 'components/Template/TemplateListScreen'
import { EditTemplateScreen } from 'components/TreatmentTemplate/EditTemplateScreen'
import { AddWorkflowTemplateScreen } from 'components/WorkflowTemplate/AddWorkflowTemplateScreen'
import { EditWorkflowTemplateScreen } from 'components/WorkflowTemplate/EditWorkflowTemplateScreen'
import { AddCallParameterTemplateScreen } from 'components/CallParameterTemplate/AddCallParameterTemplateScreen'
import { EditCallParameterTemplateScreen } from 'components/CallParameterTemplate/EditCallParameterTemplateScreen'
import { Header } from 'src/components/Header/Header'
import { Manifest } from 'src/components/Manifest'
import { Settings } from 'src/components/Settings'
import { AddCustomProductScreen } from 'src/components/Settings/AddCustomProductScreen'
import { AddUserScreen } from 'src/components/Settings/AddUserScreen'
import { ColorsConfigScreen } from 'src/components/Settings/Colors/ColorsConfigScreen'
import { CustomProductListScreen } from 'src/components/Settings/CustomProductListScreen'
import { EditCustomProductScreen } from 'src/components/Settings/EditCustomProductScreen'
import { EditUserScreen } from 'src/components/Settings/EditUserScreen'
import { UserListScreen } from 'src/components/Settings/UserListScreen'
import { AddTemplateScreen } from 'src/components/TreatmentTemplate/AddTemplateScreen'
import { Routes } from 'src/constants/Routes'
import { withDestroyOnBlur } from 'src/hocs/withDestroyOnBlur'
import { SheetGroupOrderScreen } from '../SheetGroups/SheetGroupOrderScreen'
import { StaffedHoursScreen } from '../StaffedHours/StaffedHoursScreen'
import { TreatmentFrequenciesScreen } from 'components/Settings/TreatmentFrequencies/TreatmentFrequenciesScreen'
import { RouteOfAdministrationScreen } from 'components/Settings/RouteOfAdministration/RouteOfAdministrationScreen'
import { DataSyncScreen } from 'components/Settings/DataSync/DataSyncScreen'
import { ProceduresListScreen } from 'components/Procedures/ProceduresListScreen'
import { EditProcedureScreen } from 'components/Procedures/EditProcedureScreen'
import { OrganisationBanner } from 'components/Banners/OrganisationBanner'

export type SettingsStackParamList = {
  [Routes.Settings]: undefined
  [Routes.ColorsConfig]: undefined
  [Routes.SheetGroupOrderConfig]: undefined
  [Routes.StaffedHoursConfig]: undefined
  [Routes.TreatmentFrequenciesConfig]: undefined
  [Routes.RouteOfAdministrationConfig]: undefined
  [Routes.Manifest]: undefined
  [Routes.UserList]: undefined
  [Routes.AddUser]: undefined
  [Routes.EditUser]: {
    userId: string
  }
  [Routes.CustomProductList]: undefined
  [Routes.AddCustomProduct]: undefined
  [Routes.EditCustomProduct]: {
    productId: string
  }
  [Routes.TreatmentTemplateList]: undefined
  [Routes.ProceduresList]: undefined
  [Routes.AddTemplate]: undefined
  [Routes.EditTemplate]: { templateId: string }
  [Routes.EditProcedure]: { procedureId: string }

  [Routes.WorkflowTemplateList]: undefined
  [Routes.AddWorkflowTemplate]: undefined
  [Routes.EditWorkflowTemplate]: {
    templateId: string
  }
  [Routes.CallParameterTemplateList]: undefined
  [Routes.AddCallParameterTemplate]: undefined
  [Routes.EditCallParameterTemplate]: {
    templateId: string
  }
  [Routes.DataSync]: undefined
}

export const settingsStackScreensPath = {
  [Routes.Settings]: '',
  [Routes.Manifest]: 'manifest',
  [Routes.TreatmentTemplateList]: 'treatment-templates',
  [Routes.ProceduresList]: 'procedures',
  [Routes.AddTemplate]: 'treatment-templates/add',
  [Routes.EditTemplate]: 'treatment-templates/:templateId',
  [Routes.EditProcedure]: 'procedures/:procedureId',
  [Routes.CustomProductList]: 'custom-products',
  [Routes.AddCustomProduct]: 'custom-products/add',
  [Routes.EditCustomProduct]: 'custom-products/:productId/edit',
  [Routes.UserList]: 'users',
  [Routes.AddUser]: 'users/add',
  [Routes.EditUser]: 'users/:userId/edit',
  [Routes.ColorsConfig]: 'manage-colors',
  [Routes.SheetGroupOrderConfig]: 'manage-sheet-groups',
  [Routes.StaffedHoursConfig]: 'staffed-hours',
  [Routes.TreatmentFrequenciesConfig]: 'treatment-frequencies',
  [Routes.WorkflowTemplateList]: 'workflow-templates',
  [Routes.AddWorkflowTemplate]: 'workflow-templates/add',
  [Routes.EditWorkflowTemplate]: 'workflow-templates/:templateId',
  [Routes.CallParameterTemplateList]: 'call-parameter-templates',
  [Routes.AddCallParameterTemplate]: 'call-parameter-templates/add',
  [Routes.EditCallParameterTemplate]: 'call-parameter-templates/:templateId',
  [Routes.RouteOfAdministrationConfig]: 'route-of-administration',
  [Routes.DataSync]: 'data-sync',
}

const settingsStackScreens = {
  [Routes.Settings]: withDestroyOnBlur(Settings),
  [Routes.Manifest]: withDestroyOnBlur(Manifest),
  [Routes.TreatmentTemplateList]: withDestroyOnBlur(TemplateListScreen),
  [Routes.ProceduresList]: withDestroyOnBlur(ProceduresListScreen),
  [Routes.AddTemplate]: withDestroyOnBlur(AddTemplateScreen),
  [Routes.EditTemplate]: withDestroyOnBlur(EditTemplateScreen),
  [Routes.EditProcedure]: withDestroyOnBlur(EditProcedureScreen),

  [Routes.CustomProductList]: withDestroyOnBlur(CustomProductListScreen),
  [Routes.AddCustomProduct]: withDestroyOnBlur(AddCustomProductScreen),
  [Routes.EditCustomProduct]: withDestroyOnBlur(EditCustomProductScreen),
  [Routes.UserList]: withDestroyOnBlur(UserListScreen),
  [Routes.AddUser]: withDestroyOnBlur(AddUserScreen),
  [Routes.EditUser]: withDestroyOnBlur(EditUserScreen),
  [Routes.ColorsConfig]: withDestroyOnBlur(ColorsConfigScreen),
  [Routes.SheetGroupOrderConfig]: withDestroyOnBlur(SheetGroupOrderScreen),
  [Routes.StaffedHoursConfig]: withDestroyOnBlur(StaffedHoursScreen),
  [Routes.TreatmentFrequenciesConfig]: withDestroyOnBlur(
    TreatmentFrequenciesScreen,
  ),
  [Routes.RouteOfAdministrationConfig]: withDestroyOnBlur(
    RouteOfAdministrationScreen,
  ),
  [Routes.WorkflowTemplateList]: withDestroyOnBlur(TemplateListScreen),
  [Routes.AddWorkflowTemplate]: withDestroyOnBlur(AddWorkflowTemplateScreen),
  [Routes.EditWorkflowTemplate]: withDestroyOnBlur(EditWorkflowTemplateScreen),
  [Routes.CallParameterTemplateList]: withDestroyOnBlur(TemplateListScreen),
  [Routes.AddCallParameterTemplate]: withDestroyOnBlur(
    AddCallParameterTemplateScreen,
  ),
  [Routes.EditCallParameterTemplate]: withDestroyOnBlur(
    EditCallParameterTemplateScreen,
  ),
  [Routes.DataSync]: withDestroyOnBlur(DataSyncScreen),
}

export const SettingsStack = () => {
  const MyStack = createStackNavigator()
  return (
    <>
      <Header />
      <OrganisationBanner />
      <MyStack.Navigator screenOptions={{ headerShown: false }}>
        {Object.entries(settingsStackScreens).map(([name, component]) => (
          <MyStack.Screen name={name} component={component} key={name} />
        ))}
      </MyStack.Navigator>
    </>
  )
}
