import React from 'react'
import { DurationFormField } from 'components/common/Form/FormField/DurationFormField'
import { Select } from 'components/common/Select/Select'
import { Colors } from 'constants/Colors'
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'

type Props = {
  onChange: (v: number) => void
  value: number
  type: DurationType
  a11yLabel?: string
  label: string
  style?: StyleProp<ViewStyle>
  fieldTextWrapperStyle?: StyleProp<ViewStyle>
}

export enum DurationType {
  Days = 'Days',
  Hours = 'Hours',
  Minutes = 'Minutes',
}

const DAY_OPTION = Array(7)
  .fill(null)
  .map((_e, i) => ({
    value: i,
  }))

const HOUR_OPTION = Array(24)
  .fill(null)
  .map((_e, i) => ({
    value: i,
  }))

const MINUTES_OPTION = Array(12)
  .fill(null)
  .map((_e, i) => ({
    value: i * 5,
  }))

const DurationOptions = {
  [DurationType.Days]: DAY_OPTION,
  [DurationType.Hours]: HOUR_OPTION,
  [DurationType.Minutes]: MINUTES_OPTION,
}

export const DurationSelector: React.FC<Props> = ({
  onChange,
  value,
  type,
  a11yLabel,
  label,
  style,
  fieldTextWrapperStyle,
}) => {
  const options = DurationOptions[type]
  return (
    <View style={styles.spaceInnerContainer}>
      <Select
        a11yLabel={a11yLabel}
        options={options}
        selected={value}
        FieldComponent={DurationFormField}
        onChange={onChange}
        style={[styles.selectBox, style]}
        fieldTextWrapperStyle={fieldTextWrapperStyle}
      />

      <Text style={styles.label}>{label}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  selectBox: {
    display: 'flex',
    borderWidth: 0,
    marginTop: 15,
    borderBottomWidth: 2,
    borderBottomColor: Colors.shared.borderGrey,
    backgroundColor: Colors.backgroundGrey,
    borderRadius: 5,
    marginBottom: 5,
    width: 100,
    height: 50,
  },
  spaceInnerContainer: {
    marginHorizontal: 7,
  },
  label: {
    textTransform: 'capitalize',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '400',
    fontSize: 14,
    textAlign: 'center',
    color: Colors.contentSecondary,
    paddingTop: 3,
    marginBottom: 15,
  },
})
