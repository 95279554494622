import { FieldArrayWithId } from 'react-hook-form'
import { FormDataBundle } from '../AddBundleForm'
import { orderBy, values } from 'lodash'

type ItemType = {
  field: FieldArrayWithId<FormDataBundle, 'bundleTreatmentFormInputs', 'id'>
  index: number
}

export type BundlesInGroup = {
  name: string
  order: number | null
  items: ItemType[]
}

export type BundleGroupHash = {
  [parentProductId: string]: BundlesInGroup
}

export const groupBundleItems = (
  fields: FieldArrayWithId<FormDataBundle, 'bundleTreatmentFormInputs', 'id'>[],
) => {
  const bundleInGroup: BundleGroupHash = {}
  fields.forEach((field, index) => {
    const parentProductId = field.product.parent_product_id
    const name = field.product.parent_product?.name ?? ''
    const order = field.product.parent_product?.order ?? null

    if (!bundleInGroup[parentProductId]) {
      bundleInGroup[parentProductId] = {
        name,
        order,
        items: [{ field, index }],
      }
      return
    }

    bundleInGroup[parentProductId].items.push({ field, index })
  })
  return orderBy(values(bundleInGroup), ['order', 'name'])
}
