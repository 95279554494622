import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import React from 'react'
import { Image, Text, StyleSheet, View } from 'react-native'

type Props = {}

export const Outage: React.FC<Props> = () => {
  return (
    <View style={styles.container}>
      <Image
        style={styles.logoImage}
        source={require('assets/images/vetradar-logo-white.png')}
      />
      <Text style={styles.messageText}>
        The Vet Radar team are performing scheduled maintenance updates.
      </Text>
      <Text style={[styles.messageText, styles.extraMessageText]}>
        We'll be back soon!
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.vetRadarBlue,
    flex: 1,
    justifyContent: 'flex-start',
    paddingHorizontal: '20%',
    paddingVertical: '10%',
  },
  logoImage: {
    height: 100,
    marginBottom: 30,
    width: 180,
  },
  messageText: {
    fontFamily: Fonts.bold,
    color: Colors.white,
    fontSize: 32,
    textAlign: 'center',
  },
  extraMessageText: {
    marginTop: '2%',
  },
})
