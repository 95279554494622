import { maxBy, minBy } from 'lodash'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as Task } from 'src/types/getSheet'
import { isOptimisticId } from 'src/utils/optimisticId'
import { Status } from 'types/globalTypes'
import { getIsTaskDoneOrProgress } from './taskTransitionUtils'

export const getNextPendingFluidTask = (tasks: Task[], clickedTask: Task) => {
  if (tasks.length <= 1) {
    return null
  }
  const notStartTasks = tasks.filter(
    task =>
      task.given_start_at &&
      !getIsTaskDoneOrProgress(task.status) &&
      task.given_start_at !== clickedTask.given_start_at,
  )
  const nextNotStartFluidTask = minBy(
    notStartTasks,
    task => task.given_start_at,
  )

  return nextNotStartFluidTask ?? null
}

export const getNearestStoppedFluidTask = (tasks: Task[], task: Task) => {
  const stoppedTasks = tasks.filter(
    task => task.status === Status.DONE && task.given_stop_at,
  )
  // coupled logic for fluid tasks
  const nearestStoppedFluidTask = stoppedTasks.find(
    stoppedTask => stoppedTask.given_stop_at === task.given_start_at,
  )

  return nearestStoppedFluidTask ?? null
}

export const getLatestWorkingFluidTask = (tasks: Task[]) => {
  const workingTasks = tasks.filter(
    task =>
      getIsTaskDoneOrProgress(task.status) &&
      task.given_start_at &&
      !isOptimisticId(task.id),
  )

  const latestWorkingFluidTask = maxBy(
    workingTasks,
    task => task.given_start_at,
  )

  return latestWorkingFluidTask ?? null
}

// Find all the pending tasks that are earlier than the scheduled task
// task.given_start_at !== nextPendingFluidTask?.given_start_at is to exclude current schedule task itself.
export const getAllRemovableTasks = (
  allTasks: Task[],
  scheduleAt: string,
  nextPendingFluidTask: Task | null,
) => {
  return allTasks.filter(
    task =>
      task.given_start_at &&
      !getIsTaskDoneOrProgress(task.status) &&
      task.given_start_at !== nextPendingFluidTask?.given_start_at &&
      task.given_start_at <= scheduleAt,
  )
}
