import React, { useMemo } from 'react'
import { Colors, Typography } from 'src/design-system/theme'
import {
  ActivityIndicator,
  DimensionValue,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { usePatientById } from 'src/hooks/usePatient'
import { useKeyToUrl } from 'src/hooks/useKeyToUrl'
import { AvatarWithMessage } from '../Avatar/AvatarWithMessage'
import { getAlertLevelAndAttitude } from 'components/PatientItem/utils/getAlertLevelAndMessage'
import { getBirthAgeYearMonths } from 'components/PatientItem/utils/getBirthAgeYearMonths'
import { getPatientSexTagTitle } from 'components/PatientItem/utils/getPatientSex'
import { Tag } from 'components/Tag/Tag'
import { resuscitateToTagTitle } from 'components/Tag/utils/patientToTagTitle'
import { Indicator } from '../Indicator/indicator'

type PatientCardConfig = {
  avatarContainerMaxWidth?: DimensionValue
}

type Props = {
  patientId: string
  hasUnapprovedSheet?: boolean | null
  config?: PatientCardConfig
  hasPendingApprovalTasks?: boolean
}

export const CARD_HEIGHT_MEDIUM = 83
const isIOS = Platform.OS === 'ios'
export const joinValidStrings = (
  strings: (string | null | undefined)[],
  spacer?: string,
): string => {
  const spacerToUse = spacer ?? ' | '
  return strings.filter(str => !!str).join(spacerToUse)
}

export const PatientCard: React.FC<Props> = ({
  patientId,
  hasUnapprovedSheet,
  config,
}) => {
  const patient = usePatientById(patientId)
  const patientImage = useKeyToUrl(patient?.avatar_url, 160)
  const avatarContainerStyle = useMemo(
    () => ({
      ...styles.avatarContainer,
      backgroundColor: patient?.color ?? Colors.Backgrounds.UI,
      maxWidth: config?.avatarContainerMaxWidth,
    }),
    [config, patient?.color],
  )
  if (!patient)
    return <ActivityIndicator size="large" style={{ paddingVertical: 30 }} />

  const { alertLevel, attitude } = getAlertLevelAndAttitude(patient)
  const patientAge = getBirthAgeYearMonths(patient.date_of_birth)
  const sexTagTitle = getPatientSexTagTitle(patient)
  const parsedSpeciesDetails = joinValidStrings([
    patient.species?.name,
    patient.breed?.name,
  ])
  const parsedBasicDetails = joinValidStrings([
    !!patient.weight ? `${patient?.weight}${patient?.weight_unit}` : null,
    patientAge,
    sexTagTitle,
  ])
  const color = hasUnapprovedSheet ? patient.color : ''

  return (
    <View style={styles.container}>
      <View style={avatarContainerStyle}>
        <AvatarWithMessage
          alertLevel={alertLevel}
          message={attitude}
          name={`${patient.name} ${patient.contact?.last_name ?? ''}`}
          uri={patientImage}
          indicator={
            hasUnapprovedSheet ? (
              <Indicator
                accessibilityLabel={'Pending approvals indicator'}
                strokeColor={color ?? undefined}
              />
            ) : undefined
          }
        />
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.textContainer}>
          <View style={styles.largeDetailsTextContainer}>
            <Text numberOfLines={1} style={styles.largeDetailsText}>
              {`"${patient.name}" ${patient.contact?.last_name ?? ''}`}
            </Text>
          </View>
          <View
            style={[
              styles.smallDetailsTextContainer,
              isIOS && styles.maxWidth80Pct,
            ]}
          >
            <Text numberOfLines={1} style={styles.smallDetailsText}>
              {parsedSpeciesDetails}
            </Text>
            <Tag
              title={resuscitateToTagTitle(patient.resuscitate)}
              style={styles.tag}
            />
          </View>
          <View style={styles.smallDetailsTextContainer}>
            <Text numberOfLines={1} style={styles.smallDetailsText}>
              {parsedBasicDetails}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: CARD_HEIGHT_MEDIUM,
    overflow: 'hidden',
  },
  avatarContainer: {
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  detailsContainer: {
    flex: 4,
    backgroundColor: Colors.Backgrounds.UI,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  textContainer: { height: '100%', flex: 5 },
  largeDetailsTextContainer: { flex: 3 },
  smallDetailsTextContainer: {
    flex: 2,
    flexDirection: 'row',
  },
  smallDetailsText: { ...Typography.Label.M, color: Colors.Contents.secondary },
  largeDetailsText: { ...Typography.Heading.M },
  tag: { marginLeft: 5, justifyContent: 'center' },
  maxWidth80Pct: { maxWidth: '80%' },
})
