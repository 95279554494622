import { SvgExclamation } from 'components/Icons'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Colors } from 'src/design-system/theme/colors'

export const MissedTasksIndicator = () => (
  <View style={styles.missedTaskIconContainer} testID={'missed-indicator'}>
    <SvgExclamation color={Colors.Contents.onColor} />
  </View>
)

const styles = StyleSheet.create({
  missedTaskIconContainer: {
    position: 'absolute',
    zIndex: 100,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
  },
})
