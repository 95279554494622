import React from 'react'
import Svg, { Path } from 'react-native-svg'

export const TickForCubexBilling: React.FC = React.memo(() => (
  <Svg width="16" height="17" viewBox="0 0 16 17">
    <Path
      d="M8 16.375C10.0886 16.375 12.0916 15.5453 13.5685 14.0685C15.0453 12.5916 15.875 10.5886 15.875 8.5C15.875 6.41142 15.0453 4.40838 13.5685 2.93153C12.0916 1.45469 10.0886 0.625 8 0.625C5.91142 0.625 3.90838 1.45469 2.43153 2.93153C0.954685 4.40838 0.125 6.41142 0.125 8.5C0.125 10.5886 0.954685 12.5916 2.43153 14.0685C3.90838 15.5453 5.91142 16.375 8 16.375ZM11.4761 7.0542L7.53857 10.9917C7.24941 11.2809 6.78184 11.2809 6.49575 10.9917L4.527 9.02295C4.23784 8.73379 4.23784 8.26621 4.527 7.98013C4.81616 7.69404 5.28374 7.69097 5.56982 7.98013L7.01562 9.42593L10.4302 6.0083C10.7193 5.71914 11.1869 5.71914 11.473 6.0083C11.7591 6.29746 11.7622 6.76504 11.473 7.05112L11.4761 7.0542Z"
      fill="#1B5E20"
    />
  </Svg>
))
