import { gql } from '@apollo/client'

// GRAPHQL
export const GET_SHEET_GROUPS_ORDER = gql`
  query getSheetGroupsOrder($getOrganisationId: ID!) {
    getOrganisation(id: $getOrganisationId) {
      id
      products(masterProductType: EZYVET_SHEET_GROUPS) {
        items {
          id
          name
          order
        }
      }
    }
  }
`

export const UPDATE_SHEET_GROUP_ORDER = gql`
  mutation bulkUpdateProducts($input: [UpdateProductInput!]!) {
    bulkUpdateProducts(input: $input) {
      id
      name
      order
    }
  }
`
