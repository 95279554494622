import React, { useCallback, useMemo, useState } from 'react'
import {
  FetchResult,
  MutationFunctionOptions,
  useMutation,
  useQuery,
} from '@apollo/client'
import { StackScreenProps } from '@react-navigation/stack'
import { SecondaryButton, toast } from 'components/common'
import { Actions } from 'components/common/MultiActions'
import { MAGIC_ANESTHESIA_TEMPLATE_NAME } from 'components/CreateSheet/useGetAnesthesiaTemplates'
import { SettingsStackParamList } from 'components/Settings/screens'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { partition } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  ListRenderItem,
  SectionList,
  SectionListData,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { Center } from 'src/components/common/Center'
import { SearchInput } from 'src/components/common/SearchInput'
import { Colors } from 'src/constants/Colors'
import { Routes } from 'src/constants/Routes'
import { useOrganisation } from 'src/context/organisation'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import { getOptimisticId } from 'src/utils/optimisticId'
import { searchInList } from 'src/utils/searchInList'
import { sortList } from 'src/utils/sortList'
import {
  deleteTemplate as DeleteTemplate,
  deleteTemplateVariables as DeleteTemplateVariables,
} from 'types/deleteTemplate'
import { getTemplates_getTemplates_items as TemplateItem } from 'types/getTemplates'

import { LinkProps } from '../common/ListItem'
import { SubHeader } from '../SubHeader/SubHeader'
import {
  COPY_TREATMENT_TEMPLATE,
  DELETE_TREATMENT_TEMPLATE,
  GET_TREATMENT_TEMPLATES,
} from 'components/TreatmentTemplate/graphql'
import {
  COPY_WORKFLOW_TEMPLATE,
  DELETE_WORKFLOW_TEMPLATE,
  GET_WORKFLOW_TEMPLATES,
} from 'components/WorkflowTemplate/graphql'
import {
  COPY_CALL_PARAMETER_TEMPLATE,
  DELETE_CALL_PARAMETER_TEMPLATE,
  GET_CALL_PARAMETER_TEMPLATES,
} from 'components/CallParameterTemplate/graphql'
import {
  deleteTemplateUpdateHandler,
  getDeleteTemplateOptions,
  getDuplicateTemplateOptions,
} from './graphqlOptions'
import { TemplateListItem } from './TemplateListItem'
import { sortByName } from './utils/sortByName'
import {
  copyTemplate as CopyTemplate,
  copyTemplateVariables as CopyTemplateVariables,
} from 'src/types/copyTemplate'
import { DRUGS_TEMPLATE_NAME } from 'constants/Templates'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useFlags } from 'react-native-flagsmith/react'

type Props = StackScreenProps<
  SettingsStackParamList,
  | Routes.TreatmentTemplateList
  | Routes.WorkflowTemplateList
  | Routes.CallParameterTemplateList
>

type SectionHeaderInfo = {
  section: SectionListData<TemplateItem>
}

export enum TemplateType {
  TREATMENT = 'TREATMENT',
  WORKFLOW = 'WORKFLOW',
}

const routeDataDict = {
  [Routes.TreatmentTemplateList]: {
    get: GET_TREATMENT_TEMPLATES,
    delete: DELETE_TREATMENT_TEMPLATE,
    copy: COPY_TREATMENT_TEMPLATE,
    editRoute: Routes.EditTemplate as Routes.EditTemplate,
    addRoute: Routes.AddTemplate as Routes.AddTemplate,
    getDataKey: 'getTemplates',
    headlineKey: 'templates.treatmentTitle',
    createTemplateKey: 'template:createTreatmentTemplate',
  },
  [Routes.WorkflowTemplateList]: {
    get: GET_WORKFLOW_TEMPLATES,
    delete: DELETE_WORKFLOW_TEMPLATE,
    copy: COPY_WORKFLOW_TEMPLATE,
    editRoute: Routes.EditWorkflowTemplate as Routes.EditWorkflowTemplate,
    addRoute: Routes.AddWorkflowTemplate as Routes.AddWorkflowTemplate,
    getDataKey: 'getTemplateWorkflows',
    headlineKey: 'templates.workflowTitle',
    createTemplateKey: 'template:createWorkflowTemplate',
  },
  [Routes.CallParameterTemplateList]: {
    editRoute:
      Routes.EditCallParameterTemplate as Routes.EditCallParameterTemplate,
    addRoute:
      Routes.AddCallParameterTemplate as Routes.AddCallParameterTemplate,
    headlineKey: 'templates.callParameterTitle',
    createTemplateKey: 'template:createCallParameterTemplate',
    get: GET_CALL_PARAMETER_TEMPLATES,
    delete: DELETE_CALL_PARAMETER_TEMPLATE,
    copy: COPY_CALL_PARAMETER_TEMPLATE,
    getDataKey: 'getTemplateCallParameters',
  },
}

export const TemplateListScreen: React.FC<Props> = ({ navigation, route }) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { isSmallishScreen } = useBreakpoint()
  const [searchText, setSearchText] = useState('')
  const { navigate } = navigation

  const flags = useFlags([FeatureFlagNames.VRAdminControl])

  const routeData = routeDataDict[route.name]

  const queryResult = useQuery(routeData.get, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisation_id: organisationId,
    },
    onError: err => {
      toast.error(err.message)
    },
  })

  const [deleteTemplate] = useMutation<DeleteTemplate, DeleteTemplateVariables>(
    routeData.delete,
    getDeleteTemplateOptions(),
  )

  const [copyTemplate] = useMutation<CopyTemplate, CopyTemplateVariables>(
    routeData.copy,
    getDuplicateTemplateOptions(organisationId, routeData.get),
  )

  const { loading, data } = queryResult

  const templatesItems = data?.[routeData.getDataKey]?.items
  const templates = useMemo(() => {
    const filteredTemplateItems = templatesItems?.filter(
      (template: TemplateItem) =>
        flags.vet_radar_admin_controls.enabled
          ? true
          : !MAGIC_ANESTHESIA_TEMPLATE_NAME.test(template.name),
    )

    return searchInList(
      sortByName(filteredTemplateItems ?? []),
      'name',
      searchText,
    )
  }, [
    templatesItems,
    searchText,
    flags.vet_radar_admin_controls.enabled,
  ]) as TemplateItem[]

  const sectionTemplates = useMemo(() => {
    const partitionedTemplates = partition(
      templates,
      template => template.disabled,
    ).map(groupedTemplates => sortList(groupedTemplates))

    return [
      {
        title:
          partitionedTemplates[1].length > 0
            ? t('template:activeTemplates')
            : '',
        data: partitionedTemplates[1],
      },
      {
        title:
          partitionedTemplates[0].length > 0
            ? t('template:inactiveTemplates')
            : '',
        data: partitionedTemplates[0],
      },
    ]
  }, [templates, t])

  // TODO: fix subheader empty string handling
  const { organisation } = useOrganisationHook()
  const orgName = organisation?.name ?? ' '

  const navigateSettings = useCallback(
    () => navigate(Routes.Settings),
    [navigate],
  )
  const navigateAddTemplate = useCallback(
    () => navigate(routeData.addRoute),
    [navigate, routeData.addRoute],
  )

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: navigateSettings,
  }

  const handleDelete = useCallback(
    (id: string) => {
      deleteTemplate({
        update: deleteTemplateUpdateHandler(id, organisationId, routeData.get),
        variables: {
          input: {
            id,
            organisation_id: organisationId,
          },
        },
      })
    },
    [deleteTemplate, organisationId, routeData.get],
  )

  const onPress: LinkProps['onPress'] = useCallback(
    ({ id }: { id: string }) => {
      navigate(routeData.editRoute, {
        templateId: id,
      })
    },
    [navigate, routeData.editRoute],
  )

  const renderSectionListHeader = useCallback(
    ({ section: { title } }: SectionHeaderInfo) => {
      if (!title) return null
      return (
        <Center
          style={{
            backgroundColor: Colors.backgroundGrey,
          }}
        >
          <Text style={styles.listSeparatorText}>{title}</Text>
        </Center>
      )
    },
    [],
  )

  const renderSectionListFooter = useCallback(
    () => <View style={styles.sectionListFooter} />,
    [],
  )

  const renderListFooter = useCallback(() => {
    if (!loading) return null
    return <ActivityIndicator size="large" style={styles.activityIndicator} />
  }, [loading])

  const renderItem: ListRenderItem<TemplateItem> = useCallback(
    ({ item }) => (
      <TemplateListRow
        copyTemplate={copyTemplate}
        handleDelete={handleDelete}
        item={item}
        onPress={onPress}
        organisationId={organisationId}
      />
    ),
    [copyTemplate, handleDelete, onPress, organisationId],
  )

  const keyExtractor = useCallback((item: TemplateItem) => item.id, [])

  return (
    <>
      <SubHeader
        headlineKey={routeData.headlineKey}
        subHeadline={orgName}
        backButton={backButton}
      />
      <Center
        style={[
          styles.searchContainer,
          isSmallishScreen && styles.smallContainer,
        ]}
      >
        <SearchInput onChangeText={setSearchText} />
        <SecondaryButton
          onPress={navigateAddTemplate}
          title={t(routeData.createTemplateKey)}
          style={styles.addButton}
          textStyle={styles.addButtonText}
        />
      </Center>

      <View testID="TemplateList" style={styles.templateList}>
        <SectionList
          keyExtractor={keyExtractor}
          ListFooterComponent={renderListFooter}
          renderItem={renderItem}
          renderSectionHeader={renderSectionListHeader}
          renderSectionFooter={renderSectionListFooter}
          sections={sectionTemplates}
          style={styles.borderRadius}
          contentContainerStyle={styles.listContainerStyle}
          stickySectionHeadersEnabled={true}
        />
      </View>
    </>
  )
}

const TemplateListRow: React.FC<{
  copyTemplate: (
    options: MutationFunctionOptions<any, CopyTemplateVariables>,
  ) => Promise<FetchResult<CopyTemplate>>
  handleDelete: (id: string) => void
  item: TemplateItem
  onPress: NonNullable<LinkProps['onPress']>
  organisationId: string
}> = ({ copyTemplate, handleDelete, item, onPress, organisationId }) => {
  const { t } = useTranslation()

  const menuItems = useMemo(
    () => [
      {
        name: Actions.DELETE,
        onClick: () => handleDelete(item.id),
        confirmationOption: {
          title: t('general.deleteItem', { itemName: item.name }),
          text: t('general.confirm'),
        },
        // TODO: 12/07/21 VR-5797
        disabled: item.name === DRUGS_TEMPLATE_NAME,
      },
      {
        name: Actions.DUPLICATE,
        onClick: () =>
          copyTemplate({
            optimisticResponse: {
              copyTemplate: {
                ...item,
                id: getOptimisticId(),
                name: `${item.name} copy`,
              },
            },
            variables: {
              input: {
                id: item.id,
                organisation_id: organisationId,
              },
            },
          }),
      },
    ],
    [t, item, handleDelete, copyTemplate, organisationId],
  )

  return (
    <Center>
      <TemplateListItem
        onPress={onPress}
        template={item}
        menuItems={menuItems}
      />
    </Center>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    marginTop: 25,
  },
  listContainerStyle: {
    paddingBottom: 16,
  },
  templateList: {
    flex: 1,
  },
  searchContainer: {
    paddingTop: 16,
    backgroundColor: Colors.backgroundGrey,
  },
  listSeparatorText: {
    fontSize: 20,
    fontWeight: '500',
    paddingBottom: 12,
    paddingHorizontal: 16,
  },
  sectionListFooter: {
    marginBottom: 32,
  },
  borderRadius: {
    borderRadius: 12,
  },
  addButton: {
    paddingVertical: 10,
    alignSelf: 'flex-end',
    marginRight: 10,
  },
  addButtonText: {
    color: Colors.buttons.blue,
  },
  smallContainer: {
    paddingHorizontal: 10,
  },
})
