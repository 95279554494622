import { hasPIMSName } from 'components/common/HasPIMSBlocker/translation_helpers'
import {
  PermissionNumericInput,
  PermissionSwitchInput,
} from 'components/common/Permission'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { CubexTransactionStatus, PermissionAction } from 'src/types/globalTypes'
import { Colors } from 'constants/Colors'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { Status } from 'components/common'
import { Controller } from 'react-hook-form'
import { CubexBillToEzyVet } from 'components/CubexBilltoEzyvet'
import { useBreakpoint } from 'src/hocs/breakpoint'

type BillingSectionProps = {
  handleChangeIsBillable: (val: boolean) => void
  handleChangeQtyBilled: (val: number | null) => void
  qtyBilledPermissionAction: PermissionAction
  disabled?: boolean
  qtyBilledErrorMessage?: string
  control: any
  isCubexBillable: boolean
  cubexTransactionStatus?: CubexTransactionStatus | null
  isReadOnlyAction?: boolean
  qtyBilledFieldName?: string
}

export const BillingSection: React.FC<BillingSectionProps> = ({
  handleChangeIsBillable,
  handleChangeQtyBilled,
  qtyBilledPermissionAction,
  disabled = false,
  qtyBilledErrorMessage,
  control,
  isCubexBillable,
  cubexTransactionStatus = null,
  isReadOnlyAction = false,
  qtyBilledFieldName = 'qtyBilled',
}) => {
  const { t } = useTranslation()
  const {
    isExSmallScreen,
    isSmallScreen,
    isSmallishScreen,
    isMediumScreen,
    isLargeButNotThatLargeScreen,
  } = useBreakpoint()

  const useSmallScreenStyle =
    isExSmallScreen ||
    isSmallScreen ||
    isSmallishScreen ||
    isMediumScreen ||
    isLargeButNotThatLargeScreen

  const containerStyle = useMemo(() => {
    if (useSmallScreenStyle) return styles.billingSectionContainerColumn

    return isCubexBillable
      ? styles.billingSectionContainerColumn
      : styles.billingSectionContainerRow
  }, [useSmallScreenStyle, isCubexBillable])

  return (
    <View style={containerStyle}>
      <View style={styles.rowComponentLarge}>
        <Controller
          control={control}
          render={({ field: { value } }) => (
            <CubexBillToEzyVet
              isEzyVetBillingOn={value!}
              isCubexBillable={isCubexBillable}
              cubexTransactionStatus={cubexTransactionStatus}
            >
              <PermissionSwitchInput
                permissionAction={PermissionAction.TASK_BILLING__CHANGE}
                label={t('task:taskTransition:form:billing', hasPIMSName)}
                onChangeValue={val => handleChangeIsBillable(val)}
                value={value!}
                disabled={isReadOnlyAction || disabled}
              />
            </CubexBillToEzyVet>
          )}
          name="isBillable"
        />
      </View>
      <View style={styles.rowComponent}>
        <Controller
          control={control}
          render={({ field: { value } }) => (
            <View style={styles.rowComponent}>
              <Status status={createErrorStatus(qtyBilledErrorMessage, true)}>
                <PermissionNumericInput
                  style={styles.rowComponentLeftBorder}
                  permissionAction={qtyBilledPermissionAction}
                  label={t('task:taskTransition:form:qtyBilled')}
                  value={value}
                  onChange={(val: number | null) => {
                    return handleChangeQtyBilled(val)
                  }}
                  disabled={disabled}
                />
              </Status>
            </View>
          )}
          name={qtyBilledFieldName}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  billingSectionContainerRow: {
    flexDirection: 'row',
    width: '100%',
  },
  billingSectionContainerColumn: {
    flexDirection: 'column',
    width: '100%',
  },
  rowComponent: {
    flex: 1,
  },
  rowComponentLarge: {
    flexDirection: 'column',
  },
  rowComponentLeftBorder: {
    borderLeftWidth: 1,
    borderLeftColor: Colors.shared.borderGrey,
  },
})
