import React, { useCallback, useEffect, useState } from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'

import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { EmergencyDrugsDrawer } from 'components/EmergencyDrugs/EmergencyDrugsDrawer'
import { SvgHospital } from 'components/Icons/Hospital'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { GET_TREATMENT_TEMPLATES } from 'components/TreatmentTemplate/graphql'
import { useQuery } from '@apollo/client'
import { toast } from 'components/common/Toast/ToastArea'
import { useOrganisation } from 'src/context/organisation'
import { TemplateItem } from 'components/TreatmentTemplate/types'
import { DRUGS_TEMPLATE_NAME } from 'constants/Templates'
import { getActiveTemplates_getTemplates_items } from 'src/types/getActiveTemplates'

type Props = {
  textStyle?: StyleProp<ViewStyle>
  size?: number
  isTextButton?: boolean
}

export const EmergencyDrugsButton: React.FunctionComponent<Props> = React.memo(
  ({ textStyle, size = 36, isTextButton = false }) => {
    const { t } = useTranslation()
    const [{ organisationId }] = useOrganisation()
    const { isExSmallScreen, isSmallScreen } = useBreakpoint()
    const isMobileScreen = isExSmallScreen || isSmallScreen
    const [isDrugsDrawerVisible, setIsDrugsDrawerVisible] = useState(false)
    const [drugTemplate, setDrugTemplate] =
      useState<getActiveTemplates_getTemplates_items | null>(null)

    const toggleDrugsDrawer = useCallback(() => {
      setIsDrugsDrawerVisible(isVisible => !isVisible)
    }, [])

    const { data } = useQuery(GET_TREATMENT_TEMPLATES, {
      variables: {
        organisation_id: organisationId,
      },
      onError: err => {
        toast.error(err.message)
      },
    })

    useEffect(() => {
      const templatesItems = data?.getTemplates.items ?? null
      const drugTemp: getActiveTemplates_getTemplates_items =
        templatesItems?.find((item: TemplateItem) => {
          return item.name === DRUGS_TEMPLATE_NAME
        })
      setDrugTemplate(drugTemp)
    }, [data])

    return (
      <View testID={t('emergencyDrugs.title')}>
        <TouchableOpacity
          onPress={toggleDrugsDrawer}
          disabled={isDrugsDrawerVisible}
        >
          {isTextButton ? (
            <Text style={[styles.emergencyText, textStyle]}>
              {isMobileScreen
                ? t('title.emergencyShort')
                : t('title.emergency')}
            </Text>
          ) : (
            <SvgHospital size={size} />
          )}
        </TouchableOpacity>

        <SheetAwareSideDrawer
          visible={isDrugsDrawerVisible}
          title={t('emergencyDrugs.title')}
          onClose={toggleDrugsDrawer}
        >
          <EmergencyDrugsDrawer
            templateItem={drugTemplate}
            missingEmergTemplate={drugTemplate ? true : false}
          />
        </SheetAwareSideDrawer>
      </View>
    )
  },
)

EmergencyDrugsButton.displayName = 'EmergencyDrugsButton'

export const styles = StyleSheet.create({
  emergencyText: {
    fontFamily: Fonts.bold,
    fontSize: 20,
    marginHorizontal: 4,
    color: Colors.overDue,
  },
})
