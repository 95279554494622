import { TemplateTreatmentFormInput } from 'components/AddTreatment/templateUtilFns'
import { SYNC_WITH_CONSULT_PLACEHOLDER } from 'constants/ClinicalRecord'
import { startOfHour } from 'date-fns'

import { ProductSelected } from './ProductSection/ProductSelect'

export type TemplateToAddToSheet = {
  id: string
  startTime: 'now' | Date | null
  templateWorkflowId?: string | null
  applyWorkflow?: boolean
  name?: string
  sites?: string[]
}
// Selected template can have a null id in their 'unset' state
export type SelectedTemplate = Omit<TemplateToAddToSheet, 'id'> & {
  id: string | null
}

export enum SheetType {
  NORMAL = 'NORMAL',
  ANAESTHESIA = 'ANAESTHESIA',
}

export enum SheetTypeDisplayValueTranslationKey {
  NORMAL = 'hospital',
  ANAESTHESIA = 'anesthesia',
}

const initialSelectedTemplate: SelectedTemplate = {
  id: null,
  startTime: startOfHour(new Date()),
}
// start with one empty slot for templates
const initialSelectedTemplates = [initialSelectedTemplate]

export const initialTemplateFormInputs = [
  { selectedTemplate: initialSelectedTemplate },
]

const initialSelectedAnaesthesiaTemplate: SelectedTemplate[] = [
  {
    id: null,
    startTime: null,
  },
]

/**
 * These fields are for `attending doc|dept` dropdowns default values
 * and are not part of the `user editable` form inputs
 */
export type CreateSheetFormHiddenInputs = {
  consultVetId: string | null
  syncSelectedVetWithConsult: boolean
  consultSiteId: string | null
  syncSelectedDepartmentWithConsult: boolean
}

export type TemplateFormInput = {
  selectedTemplate: SelectedTemplate
  templateTreatmentFormInputs?: TemplateTreatmentFormInput[]
}

export type CreateSheetFormInputs = CreateSheetFormHiddenInputs & {
  name: string
  selectedTemplates: SelectedTemplate[]
  selectedVet: string | null
  selectedVetTech: string | null
  selectedDepartment: string | null
  caseOwner: string | null
  department: string | null
  additionalCareTeam: string[] | null
  selectedConsultId: string | null
  sheetType: SheetType | null
  ownerConsent: boolean | null
  asaCat: string | null
  emergency: boolean
  comments: string
  circuitCheck: boolean | null
  catSite: string[]
  templates: SelectedTemplate[]
  selectedPremedsProducts: (ProductSelected | null)[]
  selectedInductionProducts: (ProductSelected | null)[]
  selectedIntraoperativeFluidsProducts: (ProductSelected | null)[]
  templateFormInputs: TemplateFormInput[]
}

export const createSheetDefaults: CreateSheetFormInputs = {
  name: '',
  sheetType: SheetType.NORMAL,
  consultVetId: null,
  selectedVet: SYNC_WITH_CONSULT_PLACEHOLDER,
  syncSelectedVetWithConsult: true,
  selectedVetTech: null,
  selectedConsultId: null,
  consultSiteId: null,
  selectedDepartment: SYNC_WITH_CONSULT_PLACEHOLDER,
  syncSelectedDepartmentWithConsult: true,
  selectedTemplates: initialSelectedTemplates,
  caseOwner: null,
  department: null,
  additionalCareTeam: null,
  ownerConsent: false,
  asaCat: null,
  emergency: false,
  comments: '',
  circuitCheck: false,
  catSite: [''],
  templates: initialSelectedAnaesthesiaTemplate,
  selectedPremedsProducts: [null],
  selectedInductionProducts: [null],
  selectedIntraoperativeFluidsProducts: [null],
  templateFormInputs: initialTemplateFormInputs,
}
