import React from 'react'
import { Text, StyleSheet, View } from 'react-native'
import { Typography, Variables } from 'src/design-system/theme'
import { useTranslation } from 'react-i18next'

type Props = {
  onCloseModal: () => void
}

export const HelpModalHeader: React.FC<Props> = () => {
  const { t } = useTranslation()
  return (
    <View style={styles.headerContainer}>
      <Text style={styles.headerText}>{t('help:titleWeb')}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  headerText: {
    fontWeight: Typography.FontWeights.bold,
    lineHeight: 22,
    fontSize: Typography.FontSizes.md,
  },
  headerContainer: {
    display: 'flex',
    marginHorizontal: Variables.GutterSpacing.md,
    marginTop: Variables.GutterSpacing.md,
  },
})
