import React from 'react'
import { Platform } from 'react-native'
import { useIsFocused } from '@react-navigation/native'

export const withDestroyOnBlur = <P extends {}>(
  WrappedComponent: React.ComponentType<P>,
) => {
  if (Platform.OS !== 'web') {
    return WrappedComponent
  }
  return (props: any) => {
    // eslint-disable-next-line
    const isFocused = useIsFocused()
    if (!isFocused) return null
    return <WrappedComponent {...props} />
  }
}
