export type AuthProps = {
  children: React.ReactElement
}

export const PASSWORD_SETTINGS = {
  passwordPolicyMinLength: 10,
  passwordPolicyCharacters: [],
}

export const LOGIN_MECHANISM = ['email'] as 'email'[]

export const vocabularies = {
  en: {
    'Sign In': 'Sign in',
    'Verify Contact': 'Confirm account',
    'Back to Sign In': 'Back to sign in',
    'Reset Password': 'Reset password',
    'Forgot Password?': 'Forgot password?',

    // Below are added to Authenticator web custom components,
    // iOS @aws-amplify+ui-react-native are currently done with patch-package
    'Verify password': 'Select your email address',
    'Verification code password': 'Enter verification sent to your email',

    // Hacky layout tweak with newline for ios
    'Account recovery requires verified contact information':
      'Select your email address\n',

    // Translates validate error when selecting email, only works on web so far
    // TODO: would be better to handle specific validate when possible
    //  https://github.com/aws-amplify/amplify-ui/issues/3368
    "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null":
      'Pick the (circular) button next to your email before selecting verify.',
  },
}
