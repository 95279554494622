import {
  AnesthesiaChartEntry,
  AnesthesiaChartPressureTitles,
  AnesthesiaChartSymbolShape,
  AnesthesiaChartTitles,
} from '../types/anesthesia'

export type AnesthesiaPresetValue = Omit<
  AnesthesiaChartEntry,
  'data' | 'latest_value'
>

export type AnesthesiaPreset = AnesthesiaPresetValue[]

export const getAnesthesiaEntryInfo = (
  temperatureUnit: string,
): AnesthesiaPreset => {
  // Sort by order!
  return [
    {
      color: '#26A69A',
      full_title: AnesthesiaChartTitles.HeartRate,
      order: 0,
      symbol_shape: AnesthesiaChartSymbolShape.FilledCircle,
      title: 'HR',
      unit: 'bpm',
    },
    {
      color: '#689f38',
      full_title: AnesthesiaChartTitles.RespiratoryRate,
      order: 1,
      symbol_shape: AnesthesiaChartSymbolShape.EmptyCircle,
      title: 'RR',
      unit: 'bpm',
    },
    {
      color: '#F57C00',
      full_title: AnesthesiaChartPressureTitles.SystolicArterialPressure,
      group_order: 0,
      group_name: 'pressure',
      order: 2,
      symbol_shape: AnesthesiaChartSymbolShape.DownwardChevron,
      title: 'SAP',
      unit: 'mmHg',
    },
    {
      color: '#FFC107',
      full_title: AnesthesiaChartPressureTitles.MeanArterialPressure,
      group_order: 1,
      group_name: 'pressure',
      order: 3,
      symbol_shape: AnesthesiaChartSymbolShape.HorizontalLine,
      title: 'MAP',
      unit: 'mmHg',
    },
    {
      color: '#FF9800',
      full_title: AnesthesiaChartPressureTitles.DiastolicArterialPressure,
      group_order: 2,
      group_name: 'pressure',
      order: 4,
      symbol_shape: AnesthesiaChartSymbolShape.UpwardChevron,
      title: 'DAP',
      unit: 'mmHg',
    },
    {
      color: '#699FCC',
      full_title: AnesthesiaChartTitles.IntermittentPositivePressureVentilation,
      order: 5,
      symbol_shape: AnesthesiaChartSymbolShape.CrossedCircle,
      title: 'IPPV',
      unit: 'bpm',
    },
    {
      color: '#e766ca',
      full_title: AnesthesiaChartTitles.EndTidalCO2,
      order: 6,
      symbol_shape: AnesthesiaChartSymbolShape.Asterisk,
      title: 'EtCO2',
      unit: 'mmHg',
    },
    {
      color: '#7e57c2',
      full_title: AnesthesiaChartTitles.OxygenSaturation,
      order: 7,
      symbol_shape: AnesthesiaChartSymbolShape.EmptyTriangle,
      title: 'SpO2',
      unit: '%',
    },
    {
      color: '#7EB602',
      full_title: AnesthesiaChartTitles.TemperatureDegreesCelcius,
      order: 8,
      symbol_shape: AnesthesiaChartSymbolShape.Lozenge,
      title: 'Temp',
      unit: temperatureUnit ?? '',
    },
    {
      color: '#dc618d',
      full_title: AnesthesiaChartTitles.Doppler,
      order: 9,
      title: 'Doppler',
      symbol_shape: AnesthesiaChartSymbolShape.DownwardChevronCircle,
      unit: 'mmHg',
    },
  ]
}
