import { useMemo } from 'react'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { isEmpty } from 'lodash'
import flagsmith from 'react-native-flagsmith'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskItem } from 'src/types/getSheet'
import { Status } from 'types/globalTypes'

import { getTaskStartAtString, getTaskStopAtString } from './getTaskDate'

type TaskWithPosition = {
  task: TaskItem
  tasksInCell: number
  cellOrder: number
}

const showDeletedOnPurpose = flagsmith.hasFeature(
  FeatureFlagNames.ShowDeletedOnPurpose,
)

const getSortTasks = (tasks: TaskItem[]) => {
  return tasks
    .filter(
      task => showDeletedOnPurpose || task.status !== Status.DELETED_ON_PURPOSE,
    )
    .sort((a, b) => {
      if (getTaskStartAtString(a) < getTaskStartAtString(b)) {
        return -1
      }
      return 1
    })
}

type LayerTasks = { [key: string]: { lastStopTime: string; tasks: TaskItem[] } }

const getConflictTasksWithPosition = (conflictTasksChunk: TaskItem[]) => {
  const result: TaskWithPosition[] = []
  const layerTasks: LayerTasks = {}

  conflictTasksChunk.forEach(task => {
    // normally the layerCount won't more than 4, will be 0 at first loop
    const layerCount = Object.keys(layerTasks).length

    for (let i = 0; i < layerCount; i = i + 1) {
      // if current task do not have conflict in this layer push it, as early as we can, greedy
      if (layerTasks[i].lastStopTime <= getTaskStartAtString(task)) {
        layerTasks[i].tasks.push(task)
        // update this layer's lastStopTime
        layerTasks[i].lastStopTime = getTaskStopAtString(task)

        return
      }
    }

    // conflict with all layers, add a new layer
    layerTasks[layerCount] = {
      tasks: [task],
      lastStopTime: getTaskStopAtString(task),
    }
  })

  const finalLayerKeys = Object.keys(layerTasks)

  // finalLayerCount/tasksInCell in this chuck.
  const finalLayerCount = finalLayerKeys.length

  finalLayerKeys.forEach(key => {
    layerTasks[key].tasks.forEach(task => {
      result.push({
        task,
        tasksInCell: finalLayerCount,
        cellOrder: Number(key),
      })
    })
  })
  return result
}

const getLaterTime = (time1: string, time2: string) => {
  return time1 > time2 ? time1 : time2
}

const getTasksWithPosition = (tasks: TaskItem[]) => {
  if (isEmpty(tasks)) {
    return []
  }

  // sort tasks by start time
  const tasksSortedByStartAt = getSortTasks(tasks)

  let conflictTasksChunk: TaskItem[] = []
  let latestStopTime = ''
  let currentHasConflict = false

  // results of conflictTaskWithPosition
  const conflictTaskWithPosition: TaskWithPosition[] = []

  const noConflictTasks = tasksSortedByStartAt.filter(
    (currentTask, index, allTasks) => {
      const nextTask = allTasks[index + 1]
      // if latest check is true, this task should push into conflictTasksChunk
      if (currentHasConflict) {
        conflictTasksChunk.push(currentTask)

        // in this check, find nextTask should push into conflictTasksChunk
        if (!!nextTask && latestStopTime > getTaskStartAtString(nextTask)) {
          // latestStopTime = max of latestStopTime/next task stop time
          latestStopTime = getLaterTime(
            latestStopTime,
            getTaskStopAtString(nextTask),
          )
          return false
        }

        // nextTask has no conflict with current chunk, solve the current chunk into conflictTasks result
        conflictTaskWithPosition.push(
          ...getConflictTasksWithPosition(conflictTasksChunk),
        )
        //  reset chunk and next task does not have conflict
        conflictTasksChunk = []
        currentHasConflict = false

        return false
      }

      if (!nextTask) {
        return true
      }

      // the current task has conflict with next, push this
      if (getTaskStopAtString(currentTask) > getTaskStartAtString(nextTask)) {
        conflictTasksChunk.push(currentTask)
        // latestStopTime = max of this/next task stop time
        latestStopTime = getLaterTime(
          getTaskStopAtString(currentTask),
          getTaskStopAtString(nextTask),
        )
        // mark the next should be pushed
        currentHasConflict = true
        return false
      }

      return true
    },
  )

  const noConflictTaskWithPosition = noConflictTasks.map(task => ({
    task,
    tasksInCell: 1,
    cellOrder: 0,
  }))

  return [...noConflictTaskWithPosition, ...conflictTaskWithPosition]
}

export const useTasksWithPosition = (tasks: TaskItem[]) =>
  useMemo(() => getTasksWithPosition(tasks), [tasks])
