import React, { useEffect, useState } from 'react'
import { noop } from 'lodash'
import Modal from 'react-modal'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Colors } from 'src/constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'

import { DialogHeader } from './common'
import { DialogProps } from './Dialog.types'

export const Dialog: React.FC<DialogProps> = ({
  children,
  contentStyle = {},
  dialogHeight,
  disableCancel = false,
  onSave,
  showDone,
  title,
  toggleDialog,
  visible,
}) => {
  const [isDialogOpen, setDialogStatus] = useState(false)
  const breakpoint = useBreakpoint()

  useEffect(() => {
    setDialogStatus(visible)
  }, [visible])

  const dialogWebStyle = {
    overlay: {
      backgroundColor: 'rgba(45, 45, 45, 0.75)',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      padding: 0,
      backgroundColor: Colors.backgroundGrey,
      borderRadius: 8,
      flex: 1,
      height: 'auto',
      left: '50%',
      minHeight: dialogHeight
        ? dialogHeight
        : breakpoint.isLargeScreen
        ? '50%'
        : '80%',
      maxHeight: '80%',
      minWidth: breakpoint.isLargeButNotThatLargeScreen
        ? '60%'
        : breakpoint.isLargeScreen
        ? '40%'
        : '95%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      ...(contentStyle as object),
    },
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isDialogOpen}
      onRequestClose={disableCancel ? noop : toggleDialog}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      style={dialogWebStyle}
      testId="Web Dialog Modal Test ID"
    >
      <View style={styles.dialogContain}>
        <DialogHeader
          onSave={onSave}
          showDone={showDone}
          title={title}
          showCancel={!disableCancel}
          onCancel={disableCancel ? noop : toggleDialog}
        />
        <KeyboardAwareScrollView>{children}</KeyboardAwareScrollView>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  dialogContain: {
    height: '100%',
  },
})
