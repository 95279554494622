import { useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { GET_SHEET } from 'components/Sheet/graphql'
import { GetSheet, GetSheetVariables, Task } from 'components/Task/types'
import { getFlattenedTreatments } from 'components/Task/utils/getFlattenedTreatments'
import { cloneDeep, groupBy } from 'lodash'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import {
  bulkCreateTasks as BulkCreateTasks,
  bulkCreateTasksVariables as BulkCreateTasksVariables,
} from 'src/types/bulkCreateTasks'
import { ApprovalStatus, CreateTaskInput } from 'src/types/globalTypes'

import { BULK_CREATE_TASKS } from '../graphql'
import { useApprovals } from 'src/hooks/useApprovals'

export const useBulkCreateTasksMutation = () => {
  const [{ organisationId }] = useOrganisation()

  const { fromToQueryDate } = useTimeResolution()

  const [bulkCreateTasks] = useMutation<
    BulkCreateTasks,
    BulkCreateTasksVariables
  >(BULK_CREATE_TASKS, {
    onError: err => {
      toast.error('Error Bulk Create Tasks', null, err) // Send error to sentry
      console.error(err) // eslint-disable-line no-console
    },
  })

  const { shouldUnapproveTreatment } = useApprovals()

  return ({
    createTaskInputs,
    optimisticTasks,
    sheetId,
  }: {
    createTaskInputs: CreateTaskInput[]
    optimisticTasks: Task[]
    sheetId: string
  }) => {
    return bulkCreateTasks({
      variables: {
        input: createTaskInputs,
      },
      optimisticResponse: {
        bulkCreateTasks: optimisticTasks,
      },
      update: (cache, { data }) => {
        if (!data) return
        const variables = {
          id: sheetId,
          organisation_id: organisationId,
          ...fromToQueryDate,
        }
        const query = GET_SHEET
        const sheet = cache.readQuery<GetSheet, GetSheetVariables>({
          query,
          variables,
        })
        const clonedData = cloneDeep(sheet)

        const treatmentGroups = clonedData?.getSheet?.treatments?.items
        if (!treatmentGroups) return

        const flattenedTreatments = getFlattenedTreatments(treatmentGroups)

        const bulkCreatedTasks = data.bulkCreateTasks
        if (!flattenedTreatments || !bulkCreatedTasks) return

        const groupedByTreatmentIdTasks = groupBy(
          bulkCreatedTasks,
          task => task.treatment_id,
        )

        flattenedTreatments.forEach(treatment => {
          if (
            shouldUnapproveTreatment(treatment) &&
            groupedByTreatmentIdTasks[treatment.id]
          ) {
            treatment.approval_status = ApprovalStatus.PENDING
          }

          // wont have 2 task for each treatment
          const newTask = groupedByTreatmentIdTasks[treatment.id]?.[0]

          if (!newTask || !treatment.tasks?.items) return

          const hasSameTask = treatment.tasks.items?.some(
            oldTask => oldTask.id === newTask.id,
          )

          /*
           TODO We may want to better manage this by defining a merge fn for apollo cache 
           https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function .
          */
          if (hasSameTask) return // may hasSameTask from subscription

          treatment.tasks.items.push(newTask)
        })
        cache.writeQuery({ query, variables, data: clonedData })
      },
    })
  }
}
