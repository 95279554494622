import { gql } from '@apollo/client'

export const COLOR_FIELDS_SHORT = gql`
  fragment ColorFieldsShort on Color {
    hex
    id
  }
`

export const COLOR_FIELDS = gql`
  fragment ColorFields on Color {
    created_at
    hex
    id
    name
    order
    updated_at
  }
`

export const GET_COLORS = gql`
  query getColors($getColorsOrganisationId: ID!) {
    getColors(organisation_id: $getColorsOrganisationId) {
      items {
        ...ColorFields
      }
    }
  }
  ${COLOR_FIELDS}
`

export const BULK_UPDATE_COLORS = gql`
  mutation BulkUpdateColorsMutation(
    $bulkUpdateColorsInput: [UpdateColorInput!]!
  ) {
    bulkUpdateColors(input: $bulkUpdateColorsInput) {
      ...ColorFields
    }
  }
  ${COLOR_FIELDS}
`
