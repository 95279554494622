import { format, formatInTimeZone } from 'date-fns-tz'

export enum SECONDS {
  MINUTE = 60,
  HALF_HOUR = MINUTE * 30,
  HOUR = MINUTE * 60,
  DAY = HOUR * 24,
}

export enum ListPosition {
  ABOVE = 'ABOVE',
  BELOW = 'BELOW',
}

export enum TimeFormat {
  TWENTY_FOUR_HOUR = 'HH:mm',
  TWELVE_HOUR = 'hh:mm a',
}

export const startOfDayUTC = (value: Date): Date => {
  return new Date(
    Date.UTC(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate()),
  )
}

export const endOfDayUTC = (value: Date): Date => {
  return new Date(
    Date.UTC(
      value.getUTCFullYear(),
      value.getUTCMonth(),
      value.getUTCDate(),
      23, // hours
      59, // mins
      59, // seconds
      999, // milliseconds
    ),
  )
}

export const formatDateString = (value: string | null | undefined) => {
  let result = value
  if (!result || !result?.split(' ')) {
    return ''
  }

  const inputArray = result.split(' ')
  const time = inputArray[0]
  const ampm = inputArray[1]
  const match = /[aApP]/gm.exec(time)
  if (match) {
    const aOrP = match[0]
    result = [time.replace(aOrP, '_')].concat(ampm.replace('_', aOrP)).join(' ')
  }
  return result
}

export const processDateValue = (
  value: Date,
  timeList: RegExpExecArray,
  is24HOUR: boolean,
  useUTC: boolean,
) => {
  const result = new Date(value)
  let hours
  let minutes

  if (is24HOUR) {
    hours = +timeList[1]
    minutes = +timeList[2]
    setHours(result, hours, useUTC)
  } else {
    hours = +timeList[2]
    minutes = +timeList[3]
    const isPm = timeList[4].toLowerCase() === 'pm'
    const isAm = timeList[4].toLowerCase() === 'am'
    const is12Pm = hours === 12 && isPm
    const is12Am = hours === 12 && isAm

    const shouldAddHours = isPm && !is12Pm

    shouldAddHours
      ? setHours(result, hours + 12, useUTC)
      : is12Am
      ? setHours(result, hours - 12, useUTC)
      : setHours(result, hours, useUTC)
  }
  setMinutes(result, minutes, useUTC)

  return result
}

export const setHours = (value: Date, hours: number, useUTC: boolean) => {
  return useUTC ? value.setUTCHours(hours) : value.setHours(hours)
}

export const setMinutes = (value: Date, minutes: number, useUTC: boolean) => {
  return useUTC ? value.setUTCMinutes(minutes) : value.setMinutes(minutes)
}

export const isValidRegexDate = (value: string, is24HOUR: boolean) => {
  const matchRule = is24HOUR
    ? /^(0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/
    : /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
  return matchRule.exec(value)
}

export const formatToTimezone = (
  value: Date,
  useUTC: boolean,
  timeFormat: string,
) =>
  useUTC
    ? formatInTimeZone(value, 'UTC', timeFormat)
    : format(value, timeFormat)
