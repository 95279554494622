import React, { useState, useEffect, useCallback } from 'react'
import { ActivityIndicator, StyleSheet, View, Text } from 'react-native'
import { NavigationProp } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import {
  toast,
  SecondaryButton,
  ListItem,
  SwitchInput,
} from 'components/common'
import { useMutation, useQuery } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import { Fonts } from 'src/constants/Fonts'
import { Colors } from 'src/constants/Colors'
import { Routes } from 'constants/Routes'

import {
  getSheetDetail as GetSheetDetail,
  getSheetDetailVariables as GetSheetDetailVariables,
} from 'types/getSheetDetail'
import {
  getPatientWithSheets,
  getPatientWithSheetsVariables,
} from 'types/getPatientWithSheets'

import {
  GET_PATIENT_WITH_SHEETS,
  UPDATE_SHEETS_ORDER,
} from 'components/SheetList/graphql'
import { GET_SHEET_DETAIL } from 'components/Sheet/graphql'
import { SheetDetailForm } from './SheetDetailForm'
import { isEqual } from 'lodash'
import { sortSheetList } from 'components/SheetList/utils/sortSheetList'
import { SheetScreenRouteProp } from 'components/Sheet/Sheet'
import { DraggableList } from 'components/common/DraggableList'
import { useIsHideDiscontinuedTreatment } from 'src/hooks/useIsHideDiscontinuedTreatment'
import { useGetSheetType } from 'components/Sheet/useGetSheetType'
import { useIsAlertOn } from 'components/Sheet/useIsAlertOn'
import { ApprovalStatus, SheetType } from 'src/types/globalTypes'
import { PENDING_APPROVAL_TAG_NAME } from 'components/Tag/Tag'

type Props = {
  navigation: NavigationProp<any>
  route: SheetScreenRouteProp
  toggleDrawer: () => void
}

export const SheetInfo: React.FC<Props> = ({
  navigation,
  route,
  toggleDrawer,
}) => {
  const { t } = useTranslation()

  const { navigate } = navigation
  const { sheetId, patientId } = route.params

  const [{ organisationId }] = useOrganisation()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const { isHideDiscontinuedTreatment, setIsHideDiscontinuedTreatment } =
    useIsHideDiscontinuedTreatment()
  const [_isHideDiscontinuedTreatment, _setIsHideDiscontinuedTreatment] =
    useState(isHideDiscontinuedTreatment)

  const sheetType = useGetSheetType(sheetId)
  const isAnesthesia = sheetType === SheetType.ANAESTHESIA
  const [isAlertOn, setIsAlertOn] = useIsAlertOn(sheetId)
  const [_isAlertOn, _setIsAlertOn] = useState(isAlertOn)

  const { data: sheetsData } = useQuery<
    getPatientWithSheets,
    getPatientWithSheetsVariables
  >(GET_PATIENT_WITH_SHEETS, {
    variables: { id: patientId, organisation_id: organisationId },
    fetchPolicy: 'cache-and-network',
  })

  const getInitSheetList = useCallback(
    () =>
      sortSheetList(
        (sheetsData?.getPatient?.sheets?.items ?? []).filter(s => !s.closed_at),
      ),
    [sheetsData?.getPatient?.sheets?.items],
  )

  const [sheetList, reorderSheetList] = useState<any[]>(getInitSheetList)
  useEffect(() => {
    reorderSheetList(getInitSheetList())
  }, [getInitSheetList])

  const { data } = useQuery<GetSheetDetail, GetSheetDetailVariables>(
    GET_SHEET_DETAIL,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        organisationId,
        id: sheetId,
      },
    },
  )
  const sheet = data?.getSheet

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [doUpdateSheetsOrder] = useMutation(UPDATE_SHEETS_ORDER)
  const updateSheetsOrder = async () => {
    if (isEqual(getInitSheetList(), sheetList)) {
      return
    }
    const input = sheetList.map((s, index) => ({
      id: s.id,
      organisation_id: organisationId,
      order: index,
    }))
    return doUpdateSheetsOrder({
      variables: { input },
    })
  }

  const onSave = async (saveForm: () => Promise<void>) => {
    setIsSubmitting(true)
    try {
      await Promise.all([updateSheetsOrder(), saveForm()])
    } catch (err) {
      if (err instanceof Error) toast.error(err.message, null, err)
    } finally {
      setIsSubmitting(false)
      toggleDrawer()
    }
    setIsHideDiscontinuedTreatment(_isHideDiscontinuedTreatment)
    setIsAlertOn(_isAlertOn)
    toast.success(t('sheet:update.success'))
  }

  const onPressItem = (item: { id: string; name: string }) => {
    navigate(Routes.Sheet, {
      patientId,
      sheetId: item.id,
      sheetName: item.name,
      initialDateInView: undefined,
    })
    toggleDrawer()
  }

  const goCreateSheetScreen = () => {
    navigate(Routes.AddSheet, {
      patientId,
    })
    setIsButtonDisabled(true)
    setTimeout(() => {
      toggleDrawer()
    }, 1000)
  }

  const renderDraggableItem = ({ item, drag }: any) => (
    <ListItem
      draggable={true}
      id={item.id}
      name={item.name}
      onPress={onPressItem}
      onDragPress={drag}
      tag={
        item.approval_status === ApprovalStatus.PENDING
          ? PENDING_APPROVAL_TAG_NAME
          : undefined
      }
      {...(item.id === sheetId && { extraText: 'Current' })}
    />
  )

  if (!sheet) {
    return <ActivityIndicator size="large" style={styles.spinner} />
  }

  const renderListFooter = () => (
    <>
      <SecondaryButton
        title={`+ ${t('newSheet.title')}`}
        style={styles.createSheetBtn}
        textStyle={styles.createSheetBtnText}
        onPress={goCreateSheetScreen}
        disabled={isButtonDisabled}
        testID="SheetInfoCreateSheet"
        accessibilityLabel={'Create sheet'}
      />

      <View>
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>{t('editSheet.sheetSettings')}</Text>
        </View>
        <SwitchInput
          label={t('editSheet.hideDiscontinuedTreatment')}
          value={_isHideDiscontinuedTreatment}
          onChangeValue={v => _setIsHideDiscontinuedTreatment(v)}
        />
        {isAnesthesia ? (
          <SwitchInput
            label={'Alert'}
            value={_isAlertOn}
            onChangeValue={v => _setIsAlertOn(v)}
          />
        ) : null}
      </View>
    </>
  )

  return (
    <>
      <SheetDetailForm
        sheet={sheet}
        onSubmitForm={onSave}
        isSubmitting={isSubmitting}
      >
        <DraggableList
          items={sheetList}
          renderItem={renderDraggableItem}
          onMoveEnd={reorderSheetList}
          containerStyle={styles.flex}
        />
        {renderListFooter()}
      </SheetDetailForm>
    </>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  spinner: {
    marginTop: 25,
  },

  createSheetBtn: {
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  createSheetBtnText: {
    color: Colors.buttons.text,
  },
  titleContainer: {
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 16,
  },
  titleText: {
    fontFamily: Fonts.regular,
    fontSize: 20,
  },
})
