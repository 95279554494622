import React from 'react'
import {
  Text,
  StyleSheet,
  type TextProps as ReactNativeTextProps,
} from 'react-native'
import { Colors } from 'src/design-system/theme'
import {
  FontFamilies,
  FontWeights,
  FontSizes,
} from 'src/design-system/theme/typography/base'

type LabelProps = ReactNativeTextProps & {
  size: 'XL' | 'L' | 'M' | 'S'
  bold?: boolean
}

export const Label: React.FC<LabelProps> = ({
  children,
  style,
  size = 'M',
  bold,
  ...rest
}) => {
  return (
    <Text
      style={[styles[size], bold && { fontWeight: FontWeights.bold }, style]}
      {...rest}
    >
      {children}
    </Text>
  )
}

/* eslint-disable react-native/no-unused-styles */
// Accessed Dynamically
const styles = StyleSheet.create({
  XL: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.md,
    color: Colors.Contents.primary,
  },
  L: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.md,
    color: Colors.Contents.primary,
  },
  M: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.base,
    color: Colors.Contents.primary,
  },
  S: {
    fontFamily: FontFamilies.base,
    fontWeight: FontWeights.regular,
    fontSize: FontSizes.sm,
    color: Colors.Contents.primary,
  },
})
