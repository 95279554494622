export const getActionsPlacement = (
  kebabPosY: number,
  kebabHeight: number,
  actionsModalHeight: number,
  windowHeight: number,
) => {
  const kebabPlacement = kebabPosY + kebabHeight
  const margin = 4

  // Actions top and bottom position presets
  const modalPositionTop = kebabPosY - actionsModalHeight - margin
  const modalPositionBottom = kebabPlacement + margin

  let actionsPosition = modalPositionBottom

  // Space required by the actions at the bottom or top of the screen
  const screenSpaceBtm = windowHeight - kebabHeight - actionsModalHeight
  const screenSpaceTop = windowHeight - modalPositionTop

  // Edge case - not enough room for actions on top or bottom of the kebab
  if (kebabPlacement > screenSpaceBtm && windowHeight < screenSpaceTop) {
    const slack = actionsModalHeight / 2
    actionsPosition = modalPositionTop + slack
  } else if (kebabPlacement > screenSpaceBtm) {
    actionsPosition = modalPositionTop
  } else {
    actionsPosition = modalPositionBottom
  }

  return actionsPosition
}
