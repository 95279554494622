import React, { useEffect, useMemo, useRef } from 'react'
import {
  Platform,
  StyleSheet,
  TextInput as RnTextInput,
  View,
} from 'react-native'
import { usePatientById } from 'src/hooks/usePatient'
import { PermissionAction, Status } from 'src/types/globalTypes'

import { BulkTasksKeyedByTaskID } from './BulkTaskActions'
import { SelectAll } from './SelectAll'
import {
  areAllMissedTasks,
  areAllUnapprovedTreatmentTasks,
  useSelectAll,
} from './utils/useSelectAll'
import { usePrevious } from 'src/hooks/usePrevious'
import {
  isCompleteOrSkipTab,
  isDeletedTab,
  isSkipTab,
} from './utils/getTabStatus'
import { BulkTaskListItem } from './BulkTaskListItem'
import { TabName } from './common'
import { withPermission } from 'src/hocs'
import { useApprovals } from 'src/hooks/useApprovals'
import { getIsTaskSuceessOnCubex } from 'components/Task/utils/taskTransitionUtils'

export type HandleChange = (field: string, value: string | boolean) => void

type ListProps = {
  currentTab: TabName
  dateOrRescheduleMinutes: Date | number
  handleChange: HandleChange
  patientId?: string
  treatmentTasks: BulkTasksKeyedByTaskID
}

export const bulkTasksA11yLabel = 'Bulk Task Completion Dialog'

const PermissionSelectAll = withPermission(SelectAll)

export const BulkTaskList: React.FC<ListProps> = ({
  currentTab,
  dateOrRescheduleMinutes,
  handleChange,
  patientId = '',
  treatmentTasks,
}) => {
  const patient = usePatientById(patientId)
  const prevTab = usePrevious(currentTab)

  const isCompleteTabOrSkip = isCompleteOrSkipTab(currentTab)
  const isDelete = isDeletedTab(currentTab)
  const keyboardType =
    Platform.OS === 'web' ? 'default' : 'numbers-and-punctuation'

  const taskInputRefs = useRef<
    Record<
      number,
      { input: RnTextInput | HTMLElement; notes?: RnTextInput | HTMLElement }
    >
  >({})

  const treatmentTasksArr = useMemo(() => {
    const subTreatmentAndFirstTasks = Object.values(treatmentTasks).filter(
      subTreatmentAndFirstTask => {
        if (!subTreatmentAndFirstTask) return false

        const { nextTaskToComplete } = subTreatmentAndFirstTask
        const inProgressTask = nextTaskToComplete?.status === Status.IN_PROGRESS

        // in progress task can only be picked by complete action
        // If the task is successful in Cubex, it will be automatically removed during the delete action.
        return (
          !(inProgressTask && !isCompleteTabOrSkip) &&
          !(
            getIsTaskSuceessOnCubex(
              nextTaskToComplete?.cubex_transaction_status,
            ) && isDelete
          )
        )
      },
    )
    return subTreatmentAndFirstTasks
  }, [treatmentTasks, isCompleteTabOrSkip, isDelete])

  const { isAllSelected, handleAllSelect, clearAllSelect } = useSelectAll({
    valueArray: treatmentTasksArr,
    handleChange,
    isCompletingTask: isCompleteTabOrSkip,
    isSkippingTask: isSkipTab(currentTab),
    isBulkCreating: false,
    isDeletingTask: isDelete,
  })

  useEffect(() => {
    if (currentTab === prevTab) return
    clearAllSelect()
  }, [currentTab, clearAllSelect, prevTab])

  const { isTreatmentApproved } = useApprovals()

  const autoFocusIdx = useMemo(() => {
    if (isCompleteOrSkipTab(currentTab)) {
      const idx = treatmentTasksArr.findIndex(({ treatment }) =>
        isTreatmentApproved(treatment),
      )

      return idx ?? 0
    }

    return 0
  }, [currentTab, isTreatmentApproved, treatmentTasksArr])

  const shouldDisableSelectAll = useMemo(() => {
    if (isCompleteTabOrSkip) {
      return areAllUnapprovedTreatmentTasks(treatmentTasksArr)
    }

    return false
  }, [isCompleteTabOrSkip, treatmentTasksArr])

  return (
    <View style={styles.container} accessibilityLabel={bulkTasksA11yLabel}>
      {treatmentTasksArr.length > 0 && (
        <PermissionSelectAll
          permissionAction={
            isDelete && areAllMissedTasks(treatmentTasksArr)
              ? PermissionAction.TASK__DELETE_MISSED
              : null
          }
          isAllSelected={isAllSelected}
          onChange={handleAllSelect}
          disabled={shouldDisableSelectAll}
        />
      )}
      <View style={styles.taskList}>
        <View style={styles.inputs}>
          {treatmentTasksArr.map((subTreatmentAndFirstTask, idx) => (
            <BulkTaskListItem
              key={subTreatmentAndFirstTask.treatment.id}
              dateOrRescheduleMinutes={dateOrRescheduleMinutes}
              handleChange={handleChange}
              currentTab={currentTab}
              patientWeightUnit={patient?.weight_unit}
              subTreatmentAndFirstTask={subTreatmentAndFirstTask}
              idx={idx}
              autoFocus={autoFocusIdx === idx}
              taskInputRefs={taskInputRefs}
              keyboardType={keyboardType}
            />
          ))}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  taskList: {
    flexDirection: 'row',
    width: '100%',
  },
  inputs: {
    flex: 1,
  },
})
