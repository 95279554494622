import { differenceInYears } from 'date-fns'
import { Resuscitation } from 'src/types/globalTypes'
import {
  PatientFieldsFull as GetPatientsItem,
  PatientFieldsFull,
} from 'src/types/PatientFieldsFull'
import { TagTitle } from '../Tag'
import { EquineSex, PatientSex } from 'components/Patient/data'

type Sex = Extract<TagTitle, 'F' | 'FS' | 'M' | 'MN' | 'U'>
export const sexToTagTitle = (sex?: GetPatientsItem['sex']): Sex => {
  let title: Sex
  switch (sex) {
    case 'F':
      title = 'F'
      break
    case 'FS':
      title = 'FS'
      break
    case 'M':
      title = 'M'
      break
    case 'MN':
      title = 'MN'
      break
    default:
      title = 'U'
      break
  }
  return title
}

export const equineSexToTagTitle = (
  sex?: GetPatientsItem['sex'],
  birthDateISO?: string | null,
): EquineSex => {
  let title: EquineSex
  let isYoungerthanOneYear = false
  if (birthDateISO) {
    const years = differenceInYears(new Date(), new Date(birthDateISO))
    isYoungerthanOneYear = years < 1
  }
  switch (sex) {
    case PatientSex.M: // Case double ups like these handle the case where sex we get from ezyvet is just M | MN | F
      title = equineSexCheck(PatientSex.M, isYoungerthanOneYear)
      break
    case PatientSex.F:
      title = equineSexCheck(PatientSex.F, isYoungerthanOneYear)
      break
    case EquineSex.Stallion:
      title = equineSexCheck(PatientSex.M, isYoungerthanOneYear)
      break
    case EquineSex.Colt:
      if (!birthDateISO) {
        isYoungerthanOneYear = true
      }
      title = equineSexCheck(PatientSex.M, isYoungerthanOneYear)
      break
    case EquineSex.Mare:
      title = equineSexCheck(PatientSex.F, isYoungerthanOneYear)
      break
    case EquineSex.Filly:
      if (!birthDateISO) {
        isYoungerthanOneYear = true
      }
      title = equineSexCheck(PatientSex.F, isYoungerthanOneYear)
      break
    case PatientSex.MN:
    case EquineSex.Gelding:
      title = EquineSex.Gelding
      break
    default:
      title = EquineSex.Unknown
  }
  return title
}

export const equineSexCheck = (
  sex: string,
  isYoungerthanOneYear?: boolean,
): EquineSex => {
  switch (sex) {
    case PatientSex.M:
      if (isYoungerthanOneYear) {
        return EquineSex.Colt
      }
      return EquineSex.Stallion
    case PatientSex.F:
      if (isYoungerthanOneYear) {
        return EquineSex.Filly
      }
      return EquineSex.Mare
    default:
      return EquineSex.Unknown
  }
}

type Resuscitate = Extract<TagTitle, 'BLS' | 'ALS' | 'DNR'> | ''
export const resuscitateToTagTitle = (
  resuscitate?: PatientFieldsFull['resuscitate'],
): Resuscitate => {
  let title: Resuscitate
  switch (resuscitate) {
    case Resuscitation.NO:
      title = 'DNR'
      break
    case Resuscitation.YES:
      title = 'BLS'
      break
    case Resuscitation.RESUCITATE_PLUS:
      title = 'ALS'
      break
    default:
      title = ''
      break
  }
  return title
}
