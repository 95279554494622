import React from 'react'
import { ParameterEntry, Values } from 'components/SheetHeader/ParameterEntry'
import { getPatient_getPatient } from 'src/types/getPatient'

import { PatientStatus } from './PatientStatus'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'

export type Props = {
  toggleCallParameterDrawer: () => void
  drawerTitle: string
  isCallParameterDrawerVisible: boolean
  showPatientStatus: boolean
  isFinalized: boolean
  sheetId: string
  navigateTo: (route: string) => void
  patient?: getPatient_getPatient | null
  onSubmit: (values: Values) => void
}

export const CallParameterDrawer: React.FC<Props> = React.memo(
  ({
    toggleCallParameterDrawer,
    drawerTitle,
    isCallParameterDrawerVisible,
    showPatientStatus,
    isFinalized,
    sheetId,
    navigateTo,
    patient,
    onSubmit,
  }) => {
    return (
      <SheetAwareSideDrawer
        onClose={toggleCallParameterDrawer}
        title={drawerTitle}
        visible={isCallParameterDrawerVisible}
      >
        {showPatientStatus ? (
          <PatientStatus
            isFinalized={isFinalized}
            sheetId={sheetId}
            toggleDialog={toggleCallParameterDrawer}
          />
        ) : (
          <ParameterEntry
            onClose={toggleCallParameterDrawer}
            navigateTo={navigateTo}
            patient={patient}
            onSubmit={onSubmit}
          />
        )}
      </SheetAwareSideDrawer>
    )
  },
)

CallParameterDrawer.displayName = 'CallParameterDrawer'
