import { useSubscription } from '@apollo/client'

import { SUBSCRIBE_DATA_IMPORT_FROM_APP } from 'components/Settings/graphql'
import { useOrganisation } from 'src/context/organisation'
import { dataImportFromApp } from 'src/types/dataImportFromApp'
import { getDataSyncLogs_getDataSyncLogs_items } from 'src/types/getDataSyncLogs'
import { UpdateDataSyncLogs } from './UpdateDataSyncLogs'
import { useDataImportStatusToastSender } from './useDataImportToastSender'

export const useDataImportSubscription = () => {
  const [{ organisationId }] = useOrganisation()
  const sendDataImportToast = useDataImportStatusToastSender()

  useSubscription<dataImportFromApp>(SUBSCRIBE_DATA_IMPORT_FROM_APP, {
    variables: { organisationId },
    skip: !organisationId,
    onData: options => {
      const { status, error_info: errorMessage } =
        options.data?.data?.dataImportFromApp?.data ?? {}

      if (status) {
        sendDataImportToast(status, errorMessage)
      }

      const client = options.client
      const subscribeResult = options.data?.data?.dataImportFromApp

      if (!subscribeResult) {
        return
      }

      try {
        const newItem: getDataSyncLogs_getDataSyncLogs_items = {
          ...subscribeResult.data,
          __typename: 'DataSyncLog',
        }
        if (subscribeResult?.data) {
          UpdateDataSyncLogs(client, organisationId, newItem)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Data Import Subscription Cache update error', error)
      }
    },
  })
}
