import { environment } from 'src/config'
import { v4 as uuid } from 'uuid'

// Set the traceId once every time app loaded
// Added to apollo context header 'x-vr-traceid'
export const sessionTraceId = uuid()

// Useful session info for debugging
export const sessionHeaders = {
  'x-vr-traceid': sessionTraceId,
  'x-vr-client': `app:${environment.appVersion}`,
}
