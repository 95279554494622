import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { IVInfusionForm } from 'components/IVInfusionForm'
import {
  TreatmentSheet,
  TreatmentSheetSecondLevel,
} from 'components/Treatment/common/types'
import { getNearestTaskStartAfterNow } from 'components/Treatment/utils/getNearestTaskStartAfterNow'
import { getTreatmentDefault } from 'components/TreatmentForm/utils/getTreatmentDefault'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { toast } from 'src/components/common'
import { FormData } from 'src/components/TreatmentForm'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { TimeSegmentMinutes } from 'src/hocs/types/time'
import { getTreatment, getTreatmentVariables } from 'src/types/getTreatment'

import { GET_TREATMENT } from '../Treatment/graphql'
import { EditNonIVTreatment } from './EditNonIVTreatment'
import { useUpdateTreatment } from './utils/useUpdateTreatment'
import { UpdateTreatmentOptions } from 'src/types/globalTypes'

export type OnSave = (
  data: FormData,
  updateTreatmentOptions?: UpdateTreatmentOptions,
) => void

type Props = {
  sheetId: string
  patientId: string
  onSaveOverride?: OnSave
  treatment: TreatmentSheet
  onTreatmentEditDone: () => void
}

function isSecondLevelTreatment(
  treatment: TreatmentSheet,
): treatment is TreatmentSheetSecondLevel {
  return 'treatments' in treatment
}

export const EditTreatmentScreen: React.FC<Props> = ({
  sheetId,
  patientId,
  onSaveOverride,
  treatment,
  onTreatmentEditDone,
}) => {
  const [{ organisationId }] = useOrganisation()
  const { t } = useTranslation()
  const { timeSegment } = useTimeResolution()
  const defaultTimeWindow = TimeSegmentMinutes[timeSegment]

  const { data, loading } = useQuery<getTreatment, getTreatmentVariables>(
    GET_TREATMENT,
    {
      variables: {
        patient_id: patientId,
        treatment_id: treatment.id,
      },
      fetchPolicy: 'network-only',
    },
  )

  const treatmentDetail = data?.getTreatment

  const { updateTheTreatment, submitting } = useUpdateTreatment(
    patientId,
    sheetId,
    organisationId,
    treatment,
  )

  const onSave: OnSave = async (formData, updateTreatmentOptions) => {
    const dismiss = toast.process(t('treatment:editTreatment:updating'))
    onTreatmentEditDone()
    updateTheTreatment(formData, updateTreatmentOptions).finally(dismiss)
  }

  const defaultOverrides = useMemo(() => {
    if (!treatment || !treatmentDetail) {
      return null
    }
    const taskStart = getNearestTaskStartAfterNow(
      'tasks' in treatment && treatment.tasks?.items
        ? treatment.tasks.items
        : [],
    )
    return getTreatmentDefault(treatmentDetail, taskStart, defaultTimeWindow)
  }, [defaultTimeWindow, treatment, treatmentDetail])

  // isIVInfusion has childTreatments (Can be empty array)
  const isIVInfusion =
    isSecondLevelTreatment(treatment) && treatment.is_iv_infusion

  const productId = treatmentDetail?.product?.id

  if (loading) {
    return (
      <ActivityIndicator
        accessibilityLabel="Edit Treatment Loading Indicator"
        size="large"
        style={styles.marginTop}
      />
    )
  }
  if (!defaultOverrides) {
    return null
  }

  if (isIVInfusion) {
    return (
      <IVInfusionForm
        patientId={patientId}
        sheetId={sheetId}
        onDone={onTreatmentEditDone}
        onEditIVInfusion={onSave}
        treatment={treatment}
        defaultOverrides={defaultOverrides}
      />
    )
  }

  return (
    <EditNonIVTreatment
      patientId={patientId}
      onSave={onSaveOverride || onSave}
      productId={productId}
      submitting={submitting}
      defaultOverrides={defaultOverrides}
      treatmentName={treatment.name}
      approvalStatus={treatment?.approval_status || undefined}
    />
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25,
  },
})
