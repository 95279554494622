import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Pill } from '../Pill'
import { RadioButton } from 'src/design-system/components/RadioButton/RadioButton'
import { useBusinessUnitWarning } from './hooks/useBusinessUnitWarning'
import { Product, TagItem } from './types'
import { ProductRow } from './components'

type Props = {
  a11yLabel?: string
  onClick?: () => unknown
  product: Product
  searchWords: string[]
  selected?: boolean
  tags?: TagItem[]
  attendingDepartment?: string | null
}

export const RadioProductRow: React.FC<Props> = ({
  a11yLabel,
  onClick = () => {},
  product,
  searchWords = [],
  selected = false,
  tags,
  attendingDepartment,
}) => {
  const { isSiteValid, BusinessUnitWarning } = useBusinessUnitWarning(
    product,
    attendingDepartment,
  )
  return (
    <ProductRow.Container
      accessibilityLabel={a11yLabel}
      onPress={onClick}
      disabled={!isSiteValid}
    >
      <View style={styles.flex}>
        <ProductRow.Heading
          text={product.name}
          searchWords={searchWords}
          disabled={!isSiteValid}
        />
        <View style={styles.row}>
          {tags?.map((tag, i) => (
            <Pill
              key={i}
              text={tag.title}
              size="small"
              color={tag.color}
              textStyle={{ color: tag.textColor }}
            />
          ))}
          {BusinessUnitWarning ||
            (!!product.code && (
              <ProductRow.SubHeading
                disabled={!isSiteValid}
                testID={product.code}
              >
                {product.code}
              </ProductRow.SubHeading>
            ))}
        </View>
      </View>
      <RadioButton
        selected={selected}
        disabled={!isSiteValid}
        onChange={onClick}
      />
    </ProductRow.Container>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  row: { flexDirection: 'row' },
})
