import { SyncStorage } from 'src/utils/SyncStorage'

/**
 * @description
 * A json persistent storage with prefix.
 * Make sure SyncStorage.sync() is resolved before using this.
 */
export class Storage {
  prefix: string
  constructor(prefix: string = '') {
    this.prefix = `${prefix}:`
  }

  setItem(key: string, value: any) {
    SyncStorage.setItem(this.prefix + key, JSON.stringify(value))
  }

  removeItem(key: string) {
    SyncStorage.removeItem(this.prefix + key)
  }

  getItem(key: string) {
    try {
      return JSON.parse(SyncStorage.getItem(this.prefix + key))
    } catch {
      return undefined
    }
  }
}
