import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_PRODUCT_GROUPS } from 'components/AddTreatment/graphql'
import { useOrganisation } from 'src/context/organisation'
import { getProductGroups } from 'types/getProductGroups'

import { TreatmentOption } from '../TemplateTreatmentForm'
import { MultiProductPicker } from './MultiProductPicker'
import { useTreatmentOptions } from './useTreatmentOptions'

type Props = {
  treatmentOptions: TreatmentOption[]
  setTreatmentOptions: (treatmentOptions: TreatmentOption[]) => void
  onClickTemplatePickProduct: () => void
  templateSiteIds?: string[] | null
}

export const useGetProductGroups = () => {
  const [{ organisationId }] = useOrganisation()
  const query = GET_PRODUCT_GROUPS
  const { data } = useQuery<getProductGroups>(query, {
    variables: {
      id: organisationId,
    },
  })
  return data?.getOrganisation?.products?.items ?? []
}

export const TemplatePickProductScreen: React.FC<Props> = React.memo(
  ({
    treatmentOptions,
    setTreatmentOptions,
    onClickTemplatePickProduct,
    templateSiteIds,
  }) => {
    const groups = useGetProductGroups()

    const { setProductOptions, loading } = useTreatmentOptions({
      setTreatmentOptions,
    })

    return (
      <MultiProductPicker
        groups={groups}
        treatmentOptions={treatmentOptions}
        setTreatmentOptions={setProductOptions}
        onSelect={onClickTemplatePickProduct}
        loading={loading}
        siteIds={templateSiteIds ?? []}
      />
    )
  },
)

TemplatePickProductScreen.displayName = 'TemplatePickProductScreen'
