import { setMinutes, startOfMinute, getMinutes, startOfHour } from 'date-fns'
import { SheetState } from 'src/context/sheet'
import { SheetType } from 'src/types/globalTypes'

export const getStartTimeBySheetContext = (sheetContext: SheetState) => {
  const now = new Date()
  /**
   * If anaesthesia sheet we need to round down to the nearest 5 minutes
   * Examples: 54 => 50, 2 => 00, 38 => 35
   */
  return sheetContext.sheetType === SheetType.ANAESTHESIA
    ? setMinutes(startOfMinute(now), Math.floor(getMinutes(now) / 5) * 5)
    : startOfHour(now)
}
