const tintColor = '#2f95dc'

const vrColors = {
  vrWhite: '#FFFFFF',
  vrTeal: '#26A69A',
  vrGreen: '#689F38',
  vrRed: '#DD2C00',
  vrPeach: '#FF5252',
  vrOrange: '#F57C00',
  vrBlue: '#1565C0',
}

const commonColors = {
  contentSecondary: '#1A274C',
}

/**
 * @deprecated Use design-system/theme/colors for colors if possible.
 */
export const Colors = {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  noticeBackground: tintColor,
  noticeText: '#fff',

  primaryButtonBackground: '#ED6353',
  setupBackground: '#131639',
  overDue: '#ED6353',
  disabled: '#9b9b9b',
  done: '#B8E986',
  due: '#1ABECC',

  contentPrimary: '#212121',
  blue: '#007AFF',
  contentNegative: '#C62828', // For destructive/negative elements

  green: '#8FC05D',
  green2: '#7ED321',
  orange: '#f5a623',
  lightOrange: '#FFD083',
  lightGrey: '#E3E3E3',
  lightGrey2: '#8F8F8F',
  lightGrey3: '#767C91',
  lightPurple: '#C3D1FF',
  contentTertiary: '#616161',
  greyBlue: '#9FA5B7',
  darkGrey: '#424242',
  indigo: '#48007B',
  teal: '#00BCD4',
  purple: '#0E009F',
  white: '#FFFFFF',

  cancelRed: '#F00000',
  lightRed: '#ff5252',
  infoGrey: '#1a274c99',
  titleBorder: '#1a274c1a',
  problemGrey: '#f5f6f8',

  borderGrey: '#E1E1E1',
  borderSecondary: '#BDBDBD',
  placeholderGrey: '#8e8e93',

  vetRadarOrange: '#f46254',
  vetRadarBlue: '#1f284d',
  contentSecondary: commonColors.contentSecondary,
  vetRadarGrey: '#777',

  optimisticGrey: 'grey',
  backgroundGrey: '#f8f8f8',
  backgroundGrey2: '#f3f3f3',
  appleGrey: '#E7E7E7',

  error: '#ED6353',
  warning: '#F09703',

  patient: {
    borderBottom: '#9FA5B7',
    caution: '#DD1800',
    infoText: '#555D76',
    presentingProblems: '#F3F4F6',
    warning: vrColors.vrOrange,
    criticalNotes: '#F00000',
    transparentWhite: 'rgba(255, 255, 255, 0.75)',
  },

  tag: {
    aggressiveBackground: 'rgba(245, 76, 97, 0.2)',
    aggressivePink: '#ff0081',
    fBackground: '#ff00f3',
    peach: vrColors.vrPeach,
    fs: '#eb3b3b',
    m: vrColors.vrBlue,
    mn: '#0d47a1',
    dnr: '#e65100',
    bls: '#7eb602',
    als: '#13b891',
    template: '#542189',
    pendingApproval: '#8E44AD',
    messageLabelBackground: 'rgba(33, 33, 33, 0.1)',
    duplicateWarning: vrColors.vrOrange,
  },

  task: {
    background: vrColors.vrWhite,
    due: '#208C82',
    missed: vrColors.vrRed,
    missedOnPurpose: '#C46300',
    pending: vrColors.vrBlue,
    done: vrColors.vrGreen,
    text: '#616161',
    billed: vrColors.vrGreen,
    missedBorder: '#D3152C',
    dueBorder: '#B6F6FC',
    highlightColor: ['#F5FEC6', '#C4EBCB', '#FAD2E7', '#CCFCF8'],
  },

  grid: {
    scrollBackGround: '#C1C1C1',
    lightPurple: '#F1F4FE',
  },

  approval: {
    primary: '#8E44AD',
    background: '#8E44AD26', // 15% opacity of primary
  },

  treatment: {
    headerTimelineBackground: '#698BFF',
    gridBackground: '#FAFAFA',
    gridBorder: '#E8E9EE',
    conditionalBackground: '#FFF5E5',
    conditionalBorderColor: '#F09703',
    defaultBackground: '#FFFFFF',
    dueBorder: '#B6F6FC',
    finalisedBorderColor: '#7ED321',
    groupBackground: '#C3D1FF',
    missedBackground: '#FFC9CF',
    missedBorder: '#D3152C',
  },

  sheet: {
    title: {
      blue: '#0071ED',
      backgroundColor: '#F8F8F8',
    },
  },

  buttons: {
    text: '#F54C61',
    listFilters: commonColors.contentSecondary,
    whiteText: '#FFFFFF',
    blue: '#0071ED',
  },
  shared: {
    borderGrey: '#dadce2',
  },
  bulkTask: {
    selectedHeader: commonColors.contentSecondary,
    unSelectedHeader: '#767c91',
    bulkGrey: '#555d76',
  },
  history: {
    modal: {
      headerBackground: '#FAFAFA',
    },
    bottomSheet: {
      title: '#212121',
      headerBackground: '#FAFAFA',
      headerBorder: '#E0E0E0',
    },
  },
  sheetHistoryRow: {
    sheetName: '#757575',
    missedTasksBackground: '#FFE3E4',
    missedTasksPrimary: '#B71C1C',
    dueTasksBackground: '#afd6d2',
    dueTasksPrimary: '#1D5E58',
    missedOnPurposeTask: '#B76825',
  },

  sheetActions: {
    header: {
      background: '#FAFAFA',
    },
  },
}
