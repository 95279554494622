import { TFunction } from 'i18next'
import * as Yup from 'yup'

import { ConcentrationSetting, volumeFactor, VolumeUnit } from '../data'
import { RouteOfAdministration } from 'components/Settings/RouteOfAdministration/types'

const RequiredTranslation = 'form.required'
const NoZeroTranslation = 'form.nonZero'

const getNumberSchema = () => Yup.number().nullable()

const getRequiredNumberSchema = (t: TFunction) =>
  getNumberSchema().required(t(RequiredTranslation))

export const getRequiredPositiveSchema = (t: TFunction) =>
  getRequiredNumberSchema(t).positive(t(NoZeroTranslation))

const enforceRequiredNumberSchemaWhen = (
  t: TFunction,
  formEntryCondition: string,
) =>
  Yup.number().when(
    [formEntryCondition, 'concentrationSetting'],
    (formEntryConditionVar, concentrationSetting) => {
      if (
        formEntryConditionVar &&
        concentrationSetting !== ConcentrationSetting.IGNORE
      ) {
        return getRequiredPositiveSchema(t)
      }
      return getNumberSchema()
    },
  )

const getIsDilutedSchema = (t: TFunction) =>
  enforceRequiredNumberSchemaWhen(t, 'isDiluted')

const getRequiredWeightUnitSchema = (t: TFunction) =>
  Yup.string().nullable().required(t(RequiredTranslation))

const getNotIgnorePositiveSchema = (t: TFunction) =>
  Yup.number().when('concentrationSetting', {
    is: concentrationSetting =>
      concentrationSetting !== ConcentrationSetting.IGNORE,
    then: getRequiredPositiveSchema(t),
    otherwise: getNumberSchema(),
  })

export const getRoutesOfAdminSchema = (
  t: TFunction,
  routesOfAdministrations: RouteOfAdministration[],
) =>
  Yup.string()
    .required(t(RequiredTranslation))
    .test({
      message: t('addTreatment:invalidRouteOfAdministration') as string,
      test: (roa: string) => {
        return !!routesOfAdministrations.find(route => route.value === roa)
      },
    })

const getCRIOffMedicationVolumeSchema = (t: TFunction) => {
  return Yup.number().when(
    [
      'isDiluted',
      'salineVolume',
      'salineVolumeUnit',
      'medicationVolumeUnit',
      'totalVolume',
      'concentrationSetting',
    ],
    (
      isDiluted: boolean,
      salineVolume: number,
      salineVolumeUnit: VolumeUnit,
      medicationVolumeUnit: VolumeUnit,
      totalVolume: number,
      concentrationSetting: ConcentrationSetting,
    ) => {
      if (isDiluted && concentrationSetting !== ConcentrationSetting.IGNORE) {
        return getRequiredNumberSchema(t).test(
          'inSufficient',
          t('addTreatment:medicationVolumeInSufficientError'),
          // FOR VR-3799: medicationVolume + salineVolume should be no less than totalVolume
          medicationVolume =>
            medicationVolume * volumeFactor[medicationVolumeUnit] +
              salineVolume * volumeFactor[salineVolumeUnit] >=
            totalVolume,
        )
      }
      return getNumberSchema()
    },
  )
}

export const getUnitsBilledPerTaskSchema = (t: TFunction) => {
  return Yup.number().when('isBillable', {
    is: true,
    then: Yup.number()
      .test(
        'is-decimal',
        t('task:taskTransition:form:qtyBilledMaxDecimals'),
        (val: string) => {
          if (val !== undefined) {
            return /^(?!0\d|$)\d*(\.\d{1,4})?$/.test(val)
          }
          return true
        },
      )
      .required()
      .typeError(t('task:taskTransition:form:qtyBilledRequired')),
    otherwise: Yup.number().nullable(),
  })
}

export const getCRIOnFormSchema = (t: TFunction) =>
  Yup.object().shape({
    dosageRate: getNotIgnorePositiveSchema(t),
    doseRate: getNotIgnorePositiveSchema(t),
    infusionRateTotal: getNotIgnorePositiveSchema(t),
    patientWeight: getNotIgnorePositiveSchema(t),
    patientWeightUnit: getRequiredWeightUnitSchema(t),
    concentration: getNotIgnorePositiveSchema(t),
    dilutedConcentration: getIsDilutedSchema(t),
    medicationVolume: getIsDilutedSchema(t),
    ivBagSize: getIsDilutedSchema(t),
    unitsBilledPerTask: getUnitsBilledPerTaskSchema(t),
  })

export const getCRIOffFormSchema = (
  t: TFunction,
  routeOfAdministrations: RouteOfAdministration[],
  hasRouteOfAdministrationsSettingEnabled: boolean = false,
  isMedication: boolean,
) => {
  return Yup.object().shape({
    dilutedConcentration: getIsDilutedSchema(t),
    dosageRate: getNotIgnorePositiveSchema(t),
    infusionRate: getNumberSchema(),
    medicationVolume: getCRIOffMedicationVolumeSchema(t),
    patientWeight: getNotIgnorePositiveSchema(t),
    concentration: getNotIgnorePositiveSchema(t),
    unitsBilledPerTask: getUnitsBilledPerTaskSchema(t),
    ...(!isMedication && {
      dosageRate: Yup.number().nullable(),
      concentration: Yup.number().nullable(),
      patientWeight: Yup.number().nullable(),
      medicationVolume: Yup.number().nullable(),
    }),
    ...(hasRouteOfAdministrationsSettingEnabled
      ? { routesOfAdmin: getRoutesOfAdminSchema(t, routeOfAdministrations) }
      : {}),
  })
}
