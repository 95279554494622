import React from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'

type Props = {
  isLoading: boolean
  children: React.ReactNode
}
/**
 * Always renders children, but overlays a loading spinner when isLoading is true
 */
export const LoadingOverlay: React.FC<Props> = ({ isLoading, children }) => (
  <>
    {isLoading ? (
      <ActivityIndicator size="large" style={styles.loadingOverlay} />
    ) : null}
    {children}
  </>
)

/**
 * Only renders children when isLoading is false
 */
export const LoadingConditionalRender: React.FC<Props> = ({
  isLoading,
  children,
}) => (
  <>
    {isLoading ? (
      <ActivityIndicator size="large" style={styles.loadingOverlay} />
    ) : (
      children
    )}
  </>
)

const styles = StyleSheet.create({
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100000,
    backgroundColor: 'rgba(0,0,0,0.01)',
  },
})
