import { isNil } from 'lodash'
import { ParameterRange } from '../Task'

export const checkValueIsOutsideOfRange = (
  value?: string | number | null,
  range?: ParameterRange,
) => {
  if (isNil(value) || !range) return false

  const numericValue = Number(value)
  if (isNaN(numericValue)) return false

  const isBiggerThanMax = isNil(range.max) ? false : numericValue > range.max

  const isSmallerThanMin = isNil(range.min) ? false : numericValue < range.min

  return isBiggerThanMax || isSmallerThanMin
}
