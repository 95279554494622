import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { StyleSheet, TextInput, View, StyleProp, TextStyle } from 'react-native'
import { Fonts } from 'src/constants/Fonts'
import { Colors } from 'src/constants/Colors'
import { PIN_LENGTH } from 'components/common/Login/SetupPin'
import { useBreakpoint } from 'src/hocs/breakpoint'

type Props = {
  onChange: (string: string, reset: () => void) => void
  onConfirm?: () => void
  size?: number
  style?: StyleProp<TextStyle>
}

export type Ref = {
  reset: () => void
}

export const PinInput = forwardRef<Ref, Props>(
  ({ onChange, onConfirm, size = 4, style }, ref) => {
    const { isExSmallScreen } = useBreakpoint()
    const textInputRefs = useRef<TextInput[]>(Array(size).fill(null))
    const [digits, setDigits] = useState<string[]>(Array(size).fill(''))

    const reset = useCallback(() => {
      setDigits(Array(size).fill(''))
      textInputRefs.current[0].focus()
    }, [size])

    useImperativeHandle(ref, () => ({
      reset() {
        setDigits(Array(size).fill(''))
        textInputRefs.current[0].focus()
      },
    }))

    return (
      <View style={styles.inputs}>
        {textInputRefs.current.map((_, idx) => (
          <View key={idx}>
            <TextInput
              keyboardType="numeric"
              selectTextOnFocus={true}
              autoFocus={idx === 0}
              ref={ref => {
                if (ref) textInputRefs.current[idx] = ref
              }}
              value={digits[idx]}
              onChangeText={newValue => {
                if (newValue > '9' || newValue < '0') {
                  return
                }
                digits[idx] = newValue
                setDigits([...digits])
                if (newValue) textInputRefs.current[idx + 1]?.focus()
                onChange(digits.join(''), reset)
              }}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === 'Backspace') {
                  if (digits[idx]) {
                    digits[idx] = ''
                  } else if (idx > 0) {
                    digits[idx - 1] = ''
                    textInputRefs.current[idx - 1].focus()
                  }
                  setDigits([...digits])
                  onChange(digits.join(''), reset)
                }
                if (
                  nativeEvent.key === 'Enter' &&
                  onConfirm &&
                  idx === PIN_LENGTH - 1
                ) {
                  onConfirm()
                }
              }}
              textAlign={'center'}
              maxLength={1}
              style={[
                styles.input,
                style,
                {
                  width: isExSmallScreen ? 60 : 80,
                  height: isExSmallScreen ? 60 : 80,
                },
              ]}
            />
          </View>
        ))}
      </View>
    )
  },
)

PinInput.displayName = 'PinInput'

const styles = StyleSheet.create({
  inputs: {
    flexDirection: 'row',
    marginTop: 10,
  },
  input: {
    width: 80,
    height: 80,
    padding: 18,
    marginHorizontal: 10,
    fontSize: 55,
    fontFamily: Fonts.textSecurityDisc,
    textAlign: 'center',
    backgroundColor: Colors.white,
    borderRadius: 4,
    borderWidth: 2,
    borderBottomColor: Colors.contentSecondary,
  },
})
