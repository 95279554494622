import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import { getSheetIsAnesthesia } from 'components/Anesthesia/AnesthesiaChart/utils/getSheetIsAnesthesia'
import { VRBottomSheet } from 'components/BottomSheet'
import { toast } from 'components/common'
import { DialogStates } from 'components/common/Dialog/Dialog.types'
import { DischargePatient } from 'components/DischargePatient/DischargePatient'
import { useDischargePatient } from 'components/DischargePatient/useDischargePatient'
import { PATIENT_LIST_CACHE_REXP } from 'components/Patient/EditPatientScreen'
import { SUBSCRIBE_PATIENT, UPDATE_PATIENT } from 'components/Patient/graphql'
import { formatLocationKeys } from 'components/Patient/PatientForm'
import { useUpdateConsultation } from 'components/Patient/useUpdateConsultation'
import { useSheetChangeLogDownloader } from 'components/Sheet/useSheetChangeLogDownloader'
import { MobilePatientPanelV2 } from 'components/SheetHeader/MobilePatientPanelV2'
import { Values } from 'components/SheetHeader/ParameterEntry'
import { useSheetSubscription } from 'components/SheetList/useSheetSubscription'
import {
  ADD_USER_SHEET_HISTORY,
  GET_SHEET_HISTORY,
} from 'components/UserHistory/graphql'
import { WorkflowCarousel } from 'components/Workflow/WorkflowCarousel'
import { WorkflowItemsDrawer } from 'components/Workflow/WorkflowItemsDrawer'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { local_toggle_type } from 'constants/LocalToggleOptions'
import { Routes } from 'constants/Routes'
import { TFunction } from 'i18next'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'react-native-flagsmith/react'
import { cache } from 'src/apollo/cache'
import { environment } from 'src/config'
import { useOrganisation } from 'src/context/organisation'
import { PatientStartDateProvider } from 'src/context/patientStartDate'
import { SheetActionTypes, useSheetContext } from 'src/context/sheet'
import { useUser } from 'src/context/user'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useTimeResolution } from 'src/hocs/timeContext'
import { useApprovals } from 'src/hooks/useApprovals'
import { useOnReconnected } from 'src/hooks/useOnReconnected'
import { usePatientById } from 'src/hooks/usePatient'
import { usePatientId } from 'src/hooks/usePatientId'
import { useSheetId } from 'src/hooks/useSheetId'
import { useShouldSkipPolling } from 'src/hooks/useShouldSkipPolling'
import { useZoomMenu } from 'src/hooks/useZoomMenu'
import {
  AddUserHistory,
  AddUserHistoryVariables,
} from 'src/types/AddUserHistory'
import {
  generateSheetReport,
  generateSheetReportVariables,
} from 'src/types/generateSheetReport'
import {
  getConsultation,
  getConsultationVariables,
} from 'src/types/getConsultation'
import {
  SheetType,
  UpdateConsultationInput,
  UserHistoryType,
} from 'src/types/globalTypes'
import {
  updatePatient as UpdatePatient,
  updatePatientVariables,
} from 'src/types/updatePatient'
import { evictRootQuery } from 'src/utils/evictRootQuery'
import { useActionSheetHandler } from 'src/utils/useActionSheetHandler'
import { getPatient_getPatient_active_consultations_items } from 'types/getPatient'
import {
  getSheet as GetSheet,
  getSheet_getSheet,
  getSheetVariables as GetSheetVariables,
} from 'types/getSheet'

import { GridManager } from '../Grid/GridManager'
import { CallParameterDrawer } from './CallParameterDrawer'
import { GENERATE_SHEET_REPORT, GET_CONSULTATION, GET_SHEET } from './graphql'
import { SheetScreenRouteProp } from './Sheet'
import { useTreatmentSubscription } from './treatmentSubscription/useTreatmentSubscription'
import { useDeleteSheet } from './useDeleteSheet'
import { useFinalizeSheet } from './useFinalizeSheet'
import { useIsSheetDeletable } from './useIsSheetDeletable'
import { useLocalToggle } from './useLocalToggle'
import { useRestoreSheet } from './useRestoreSheet'
import { useSheetAlert } from './useSheetAlert'
import { useTaskSubscription } from './useTaskSubscription'
import { useWorkflowSubscription } from './useWorkflowSubscription'

import type { getSheetHistory_getSheetHistory } from 'src/types/getSheetHistory'
// Poll sheet every 30 seconds in case subscription lost
const POLL_INTERVAL = 30000
// Poll consultation every 2 minutes in case subscription lost
const CONSULTATION_POLL_INTERVAL = 2 * 60 * 1000

export type Props = {
  sheetId: string
  sheetType: SheetType | null | undefined
}

type GetFinalizeMessageParams = {
  sheet?: getSheet_getSheet | null
  sheetName?: string
  t: TFunction
}

export enum DrawerAction {
  STATUS = 'status',
  DETAILS = 'details',
}

const getFinalizeMessage = ({
  sheet,
  sheetName = '', // remove i18n warning
  t,
}: GetFinalizeMessageParams) => {
  const timers = sheet?.type_meta_data?.timers
  const result = t('sheet:finalize.confirm', {
    sheetName,
  })
  if (timers?.some(timer => timer.start_at)) {
    return t('sheet:finalize.running', { result })
  }
  return result
}

export const ReadySheet: React.FC<Props> = React.memo(
  ({ sheetId, sheetType }) => {
    const { t } = useTranslation()
    const { navigate, setOptions } = useNavigation()
    const shouldSkipPolling = useShouldSkipPolling(true)
    const { fromToQueryDate } = useTimeResolution()
    const { params } = useRoute<SheetScreenRouteProp>()
    const { patientId, sheetName: urlSheetName, isFromWhiteboard } = params
    const { user } = useUser()
    const userId = user?.id as string
    const DischargeBottomSheetRef = useRef<BottomSheetModal>(null)
    const flags = useFlags([FeatureFlagNames.GENERATE_SHEET_REPORT])
    const isSheetReportFlagEnabled = flags.generate_sheet_report.enabled

    const useDischargePatientCallback = () => {
      DischargeBottomSheetRef.current?.close()
    }

    const { dischargePatient } = useDischargePatient(
      patientId,
      useDischargePatientCallback,
    )

    const updateConsultation = useUpdateConsultation(patientId)
    const isAnesthesia = sheetType === SheetType.ANAESTHESIA

    useSheetAlert(sheetId, isAnesthesia)

    const { isLargeScreen } = useBreakpoint()

    useSheetSubscription()
    useTreatmentSubscription(sheetId)
    const [{ organisationId }] = useOrganisation()

    // isDeleting is set to true when the sheet is being deleted, it's to
    // differentiate between the device thats doing the deletion and others
    const [isDeletingDevice, setIsDeletingDevice] = useState(false)

    const [isCallParameterDrawerVisible, setIsCallParameterDrawerVisible] =
      useState(false)

    const [isShowMoreDetails, setIsShowDetails] = useLocalToggle({
      prefix: sheetId,
    })
    const [isShowWorkflow, setIsShowWorkflow] = useLocalToggle({
      prefix: sheetId,
      type: local_toggle_type.SHOWWORKFLOW,
    })

    const { setPatientId } = usePatientId()
    const { setSheetId } = useSheetId()

    const onPressShowMore = useCallback(() => {
      setIsShowDetails(!isShowMoreDetails)
    }, [isShowMoreDetails, setIsShowDetails])

    const onToggleWorkflow = useCallback(() => {
      setIsShowWorkflow(!isShowWorkflow)
    }, [isShowWorkflow, setIsShowWorkflow])

    const getSheetVariables = useMemo(
      () => ({
        id: sheetId,
        organisation_id: organisationId,
        ...fromToQueryDate,
      }),
      [sheetId, organisationId, fromToQueryDate],
    )

    const [addUserHistory] = useMutation<
      AddUserHistory,
      AddUserHistoryVariables
    >(ADD_USER_SHEET_HISTORY, {
      onError: err => {
        toast.error(err.message)
      },
      onCompleted: () => {
        const sheetHistoryCache = client.readQuery({
          variables: {
            organisation_id: organisationId,
            user_id: userId,
          },
          query: GET_SHEET_HISTORY,
        })

        if (!sheetHistoryCache || !data?.getSheet || !patient?.contact) {
          return
        }

        const clonedSheetHistory = cloneDeep(
          sheetHistoryCache.getSheetHistory,
        ) as getSheetHistory_getSheetHistory[]

        const updatedSheet = {
          __typename: 'Sheet',
          id: sheetId,
          name: `${data.getSheet.name}`,
          approval_status: data.getSheet.approval_status,
          patient: {
            __typename: 'Patient',
            id: patient.id,
            name: patient.name,
            avatar_url: patient.avatar_url,
            color: patient.color,
            contact: {
              __typename: 'Contact',
              last_name: patient.contact.last_name,
            },
          },
        } as getSheetHistory_getSheetHistory

        const index = clonedSheetHistory.findIndex(
          sheet => sheet.id === sheetId,
        )
        if (index !== -1) {
          clonedSheetHistory.splice(index, 1)
        }
        clonedSheetHistory.unshift(updatedSheet)

        client.writeQuery({
          query: GET_SHEET_HISTORY,
          variables: { organisation_id: organisationId, user_id: userId },
          data: { getSheetHistory: clonedSheetHistory },
        })
      },
    })

    const { data, refetch, loading } = useQuery<GetSheet, GetSheetVariables>(
      GET_SHEET,
      {
        variables: getSheetVariables,
        pollInterval: shouldSkipPolling ? 0 : POLL_INTERVAL,
        skip: !sheetId,
      },
    )

    const [generateSheetReport] = useMutation<
      generateSheetReport,
      generateSheetReportVariables
    >(GENERATE_SHEET_REPORT)

    const [sheetContext, setSheetContext] = useSheetContext()

    // evict sheet cache on destroyed, reset lastTaskScheduleStartTime
    useEffect(
      () => () => {
        cache.evict({ id: `Sheet:${sheetId}`, fieldName: 'treatments' })
        setSheetContext({
          type: SheetActionTypes.setSheet,
          sheetType: null,
          lastTaskScheduleStartTime: null,
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )

    useEffect(() => {
      if (sheetType !== sheetContext.sheetType) {
        setSheetContext({
          type: SheetActionTypes.setType,
          sheetType,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sheetType])

    useEffect(() => {
      if (data?.getSheet?.attending_department) {
        setSheetContext({
          type: SheetActionTypes.setAttendingDepartment,
          sheetAttendingDepartment: data.getSheet.attending_department,
        })
      }
    }, [data?.getSheet?.attending_department, setSheetContext])

    /* Keep the consultationId when navigate through days.
       Otherwise, consultationId will be undefined in the middle and trigger refetch. */
    const [consultationId, setConsultationId] = useState<string>()
    useEffect(() => {
      if (data?.getSheet?.consultation_id) {
        setConsultationId(data?.getSheet?.consultation_id)
      }
    }, [data?.getSheet?.consultation_id])

    useEffect(() => {
      // only show toast if the delete_at field has a value and
      // the device is not the device doing the deletion
      if (data?.getSheet?.deleted_at && !isDeletingDevice) {
        const sheetName = data.getSheet.name
        toast.notice(
          t('sheet:sheetDeletedNotice', {
            sheetName,
          }),
        )
      }
    }, [data?.getSheet?.deleted_at, data?.getSheet?.name, isDeletingDevice, t])

    useFocusEffect(
      useCallback(() => {
        if (!patientId) return
        setPatientId(patientId)
        setSheetId(sheetId)

        return () => {
          setPatientId(null)
          setSheetId(null)
        }
      }, [setPatientId, setSheetId, patientId, sheetId]),
    )

    const consultationQueryResult = useQuery<
      getConsultation,
      getConsultationVariables
    >(GET_CONSULTATION, {
      variables: {
        organisationId,
        id: consultationId ?? '',
      },
      skip: !consultationId,
      pollInterval: shouldSkipPolling ? 0 : CONSULTATION_POLL_INTERVAL,
    })

    const onReconnected = useCallback(() => {
      // BM: apollo seems to ignore the skip param on fetchMore?
      if (consultationId) {
        consultationQueryResult.refetch()
      }
      refetch()
    }, [consultationId, consultationQueryResult, refetch])

    useOnReconnected(onReconnected)

    const consultation = consultationQueryResult?.data?.getConsultation

    useEffect(() => {
      if (consultation) {
        setSheetContext({
          type: SheetActionTypes.setConsultationSiteId,
          consultationSiteId: consultation.site_id,
        })
      }
    }, [consultation, setSheetContext])

    const patient = usePatientById(patientId, 'cache-and-network')

    const patientStartDate = useMemo(() => {
      if (!consultationQueryResult.data) return new Date(0)
      return new Date(
        consultation?.admitted_at ?? patient?.created_at ?? Date.now(),
      )
    }, [
      consultation?.admitted_at,
      consultationQueryResult,
      patient?.created_at,
    ])

    const sheet = useMemo(() => {
      // queryResult.data might be null when navigating between day ranges
      if (!data?.getSheet) return null
      return data?.getSheet
    }, [data?.getSheet])

    const hasSheet = !!sheet
    const isFinalized = !!sheet?.closed_at

    const treatmentTree = useMemo(
      () => sheet?.treatments?.items ?? [],
      [sheet?.treatments?.items],
    )

    const isOptimisticUpdating = treatmentTree.some(treatmentGroup =>
      treatmentGroup?.treatments?.items?.some(
        treatment => treatment._optimistic,
      ),
    )

    useEffect(() => {
      if (!!userId && !!organisationId) {
        const userHistoryInput = {
          organisation_id: organisationId,
          user_id: userId,
          value: sheetId,
          type: UserHistoryType.SHEET,
        }
        addUserHistory({ variables: { input: userHistoryInput } })
      }
    }, [userId, organisationId, sheetId, addUserHistory])

    // set screen title
    useEffect(() => {
      if (!!patient?.name && !!sheet?.name) {
        setOptions({ title: `${patient.name} - ${sheet.name}` })
      }
    }, [patient?.name, setOptions, sheet?.name])

    // we check if active_consultations contains as object with id the same as the sheet.consultation_id
    // if it does then it's the correct sheet for the active consult
    const activeConsultCheck = (
      item: getPatient_getPatient_active_consultations_items | null,
    ) => item?.id === sheet?.consultation_id

    useWorkflowSubscription(consultationQueryResult.refetch, consultationId)

    const isAnActiveSheet =
      patient?.active_consultations?.items?.some(activeConsultCheck)

    // isDischarged will be the opposite value to isAnActiveSheet
    const isDischarged = !isAnActiveSheet

    const sheetName = sheet?.name ?? urlSheetName ?? ''

    const { finalizeSheet, loading: isFinalizePending } = useFinalizeSheet({
      sheetId,
      sheetName,
      consultId: sheet?.consultation_id!,
      message: getFinalizeMessage({ sheet, sheetName, t }),
    })

    const { restoreFinalizedSheet, loading: isRestorePending } =
      useRestoreSheet({
        sheetId,
        sheetName,
      })

    const handleFinalizeSheet = useCallback(async () => {
      if (!hasSheet) {
        toast.error(t('sheet:finalize:notReady'))
        return
      }
      await finalizeSheet()
        .then(() => {
          navigate(Routes.SheetList, {
            patientId,
          })
        })
        .catch(err => {
          // Handle the confirm dialog closed action
          if (!err) return
          throw err
        })
    }, [finalizeSheet, navigate, patientId, hasSheet, t])

    const handleRestoreSheet = useCallback(async () => {
      if (consultation?.discharged_at) {
        toast.error(t('sheet:restore.consultationDischarged'))
        return
      }

      await restoreFinalizedSheet().catch(err => {
        // Handle the confirm dialog closed action
        if (!err) return
        throw err
      })
    }, [consultation, restoreFinalizedSheet, t])

    const { deleteSheet, loading: isDeletePending } = useDeleteSheet({
      sheetId,
      patientId,
      sheetName,
    })

    const getIsSheetDeletable = useIsSheetDeletable(getSheetVariables)

    const handleDelete = useCallback(async () => {
      const isSheetDeletable = getIsSheetDeletable()
      if (!isSheetDeletable) {
        toast.error(t('sheet:delete.failed'))
        return
      }

      setIsDeletingDevice(true)

      await deleteSheet()
        .then(() => {
          navigate(Routes.SheetList, {
            patientId,
          })
        })
        .catch(err => {
          // Handle the confirm dialog closed action
          if (!err) return
          throw err
        })
    }, [deleteSheet, navigate, patientId, getIsSheetDeletable, t])

    useTaskSubscription(sheetId, refetch, fromToQueryDate, isOptimisticUpdating)

    useSubscription(SUBSCRIBE_PATIENT, { variables: { organisationId } })

    const [dialogOpen, setDialogOpen] = useState<DialogStates>(
      DialogStates.None,
    )
    const [isWorkflowDrawerVisible, setIsWorkflowDrawerVisible] =
      useState(false)

    const isOperationPending =
      isFinalizePending || isDeletePending || isRestorePending

    const isLoading = isOperationPending || (loading && !isOptimisticUpdating)

    const handlePressGeneratePdf = useCallback(async () => {
      try {
        await generateSheetReport({
          variables: {
            input: {
              organisation_id: organisationId,
              sheet_id: sheetId,
            },
          },
        })

        toast.success(t('sheet:report.generationSuccess'))
      } catch (error) {
        toast.error(t('sheet:report.generationFailed'))
      }
    }, [generateSheetReport, organisationId, sheetId, t])

    const handlePressDischarge = useCallback(() => {
      if (environment.isWeb) {
        setDialogOpen(DialogStates.Discharge)
      } else {
        DischargeBottomSheetRef.current?.present()
      }
    }, [])

    const toggleCallParameterDrawer = useCallback(() => {
      setIsCallParameterDrawerVisible(isVisible => !isVisible)
    }, [])

    const [drawerAction, setDrawerAction] = useState<DrawerAction | null>(null)

    const selectDrawerAction = useCallback(
      (action: DrawerAction | null) => {
        toggleCallParameterDrawer()
        setDrawerAction(action)
      },
      [toggleCallParameterDrawer],
    )

    const finalizeOrRestoreSheetButton = useMemo(() => {
      if (!hasSheet) {
        return []
      }
      if (!isFinalized) {
        return [
          {
            action: handleFinalizeSheet,
            name: t('sheetList:action.finalize'),
          },
        ]
      }
      if (!consultation) {
        return []
      }
      return [
        {
          action: handleRestoreSheet,
          name: t('sheetList:action.restore'),
        },
      ]
    }, [
      hasSheet,
      consultation,
      isFinalized,
      handleRestoreSheet,
      handleFinalizeSheet,
      t,
    ])

    const { buttonActionCb } = useZoomMenu(sheet)

    const sheetChangeLogDownloader = useSheetChangeLogDownloader(
      sheetId,
      organisationId,
    )

    const { showApproverUI, sheetHasUnapprovedTreatments, approveSheet } =
      useApprovals()

    const showApproval = useMemo(
      () => showApproverUI && sheetHasUnapprovedTreatments(data?.getSheet),
      [showApproverUI, sheetHasUnapprovedTreatments, data?.getSheet],
    )

    const [isApprovingSheetTreatments, setIsApprovingSheetTreatments] =
      useState<boolean>(false)

    const loadingToast = useRef<(() => void) | null>(null)

    useEffect(() => {
      if (isApprovingSheetTreatments) {
        loadingToast.current = toast.disconnected(
          t('treatment:approval:approvingSheetTreatments'),
        )
      } else {
        loadingToast.current?.()
        loadingToast.current = null
      }

      return () => {
        loadingToast.current?.()
        loadingToast.current = null
      }
    }, [isApprovingSheetTreatments, t])

    const approveTreatmentSheet = useCallback(async () => {
      setIsApprovingSheetTreatments(true)
      try {
        await approveSheet(sheetId, organisationId)
        toast.success(
          t('treatment:approval:successfulToApproveSheetTreatments'),
        )
      } catch (error) {
        toast.error(t('treatment:approval:failedToApproveSheetTreatments'))
      } finally {
        setIsApprovingSheetTreatments(false)
      }
    }, [approveSheet, organisationId, sheetId, t])

    const actionSheetButtons = useMemo(
      () => [
        ...(showApproval
          ? [
              {
                action: () => approveTreatmentSheet(),
                name: `${t('treatment:approval:approveTreatmentSheet')}`,
              },
            ]
          : []),
        {
          action: () => selectDrawerAction(DrawerAction.DETAILS),
          name: t('sheet:updatePatientDetails'),
        },
        ...(!isDischarged
          ? [
              {
                action: () => setIsWorkflowDrawerVisible(true),
                name: 'Workflow management',
              },
            ]
          : []),
        {
          // give timeout as opening and closing the same action sheet on web.
          action: () => setTimeout(buttonActionCb, 0),
          name: t('sheets.time.resolution.actionSheetTitle'),
        },
        {
          action: () => setDialogOpen(DialogStates.AllNotes),
          name: t('sheet:allNotes'),
        },
        ...finalizeOrRestoreSheetButton,
        ...(isSheetReportFlagEnabled
          ? [
              {
                action: handlePressGeneratePdf,
                name: t('sheet:report:generateReportAction'),
              },
            ]
          : []),
        ...(sheetChangeLogDownloader.canDownload
          ? [
              {
                action: () => sheetChangeLogDownloader.download(),
                name: t('sheet:downloadSheetChangeLog'),
              },
            ]
          : []),
        ...(getIsSheetDeletable()
          ? [
              {
                action: handleDelete,
                destructive: true,
                name: t('sheetList:action.delete'),
              },
            ]
          : []),
        ...(patient?.active_consultations?.items?.length
          ? [
              {
                action: handlePressDischarge,
                destructive: true,
                name: t('sheetList:action.discharge'),
              },
            ]
          : []),
      ],
      [
        showApproval,
        t,
        isDischarged,
        finalizeOrRestoreSheetButton,
        isSheetReportFlagEnabled,
        handlePressGeneratePdf,
        sheetChangeLogDownloader,
        getIsSheetDeletable,
        handleDelete,
        patient?.active_consultations?.items?.length,
        handlePressDischarge,
        approveTreatmentSheet,
        selectDrawerAction,
        buttonActionCb,
      ],
    )

    const anaesthesiaSheetButtons = useMemo(
      () => [
        {
          action: () => selectDrawerAction(DrawerAction.STATUS),
          name: t('sheet:updateStatus'),
        },
      ],
      [t, selectDrawerAction],
    )

    const actionButtons = useMemo(
      () =>
        sheetType === SheetType.ANAESTHESIA
          ? [...anaesthesiaSheetButtons, ...actionSheetButtons]
          : actionSheetButtons,
      [sheetType, actionSheetButtons, anaesthesiaSheetButtons],
    )

    const { showActionSheetWithOptions } = useActionSheet()

    const actionButtonHandler = useActionSheetHandler({
      showActionSheetWithOptions,
      buttons: actionButtons,
      cancelName: t('general.cancel'),
    })

    const actionButton = useMemo(() => {
      return {
        title: 'general.action',
        label: 'general.action',
        action: actionButtonHandler,
      }
    }, [actionButtonHandler])

    const backButton = useMemo(() => {
      const backToPatients = {
        title: 'title.patients',
        label: 'patient:list.returnHereLabel',
        action: () => navigate(Routes.Patients),
      }
      if (isFromWhiteboard === 'true' || isFromWhiteboard === true) {
        return {
          title: 'title.workflows',
          label: 'patient:list.returnHereLabel',
          action: () =>
            navigate(Routes.WhiteboardsStack, {
              screen: Routes.Whiteboard,
            }),
        }
      }
      return backToPatients
    }, [isFromWhiteboard, navigate])

    const navigateTo = useCallback(
      (route: string) => navigate(route, { patientId }),
      [navigate, patientId],
    )

    const [updatePatient, { client }] = useMutation<
      UpdatePatient,
      updatePatientVariables
    >(UPDATE_PATIENT)

    const onSubmit = useCallback(
      async (values: Values) => {
        toggleCallParameterDrawer()
        const newLocations = formatLocationKeys(values.locations)

        const consultationInput: UpdateConsultationInput = {
          color_id: values.color_id,
          id: consultationId!,
          locations: newLocations,
          organisation_id: organisationId,
          patient_id: patient?.id!,
        }

        const updatedCriticalNotes =
          values.critical_notes
            ?.map((note: { value: string }) => note.value)
            .filter(note => !!note) ?? []

        const updatedCallParameters = values.call_parameters?.map(parameter => {
          return {
            name: parameter.name,
            max: parameter.max,
            min: parameter.min,
            product_id: parameter.product_id ?? undefined,
            short_name: parameter.short_name ?? undefined,
          }
        })

        const input = {
          id: patientId,
          organisation_id: organisationId,
          critical_notes: updatedCriticalNotes,
          template_call_parameter_id: values.template_call_parameter_id,
          call_parameters: updatedCallParameters,
          notes: values.additional_notes,
          primary_location: {
            // Send Ward ID as location_id when no room is selected
            location_id: newLocations[0]?.id ?? null,
            location_display: newLocations[0]?.display ?? null,
            enclosure: newLocations[0]?.enclosure ?? null,
          },
          color: values.color,
          color_id: values.color_id,
          order: values.order,
          weight: values.weight,
          weight_unit: values.weight_unit,
          resuscitate: values.resuscitate,
        } as updatePatientVariables['input']

        if (
          patient?.weight === input.weight &&
          patient?.weight_unit === input.weight_unit
        ) {
          delete input.weight
          delete input.weight_unit
        }

        try {
          await updatePatient({ variables: { input } })
          await updateConsultation(consultationInput)

          // clear the patient list, saves on resetting fields
          evictRootQuery(client.cache, PATIENT_LIST_CACHE_REXP, { delay: 3000 })

          toast.success(
            t('form.updated', { itemName: t('sheet:patientDetail') }),
          )
        } catch (error) {
          if (error instanceof Error) toast.error(error.message)
        }
      },
      [
        client?.cache,
        consultationId,
        organisationId,
        patient?.id,
        patient?.weight,
        patient?.weight_unit,
        patientId,
        t,
        toggleCallParameterDrawer,
        updateConsultation,
        updatePatient,
      ],
    )

    const openWorkflowDrawer = useCallback(
      () => setIsWorkflowDrawerVisible(true),
      [],
    )

    // Patient Status as a drawer action is currently only available from the
    // Anaesthesia sheet screen. Persisting this behaviour until it's decided we
    // want this option to appear for other sheet types.
    const showPatientStatus =
      isAnesthesia && drawerAction === DrawerAction.STATUS

    const drawerTitle = showPatientStatus
      ? t('sheet:patientStatus')
      : t('sheet:patientDetail')

    const workflowItems = consultation?.workflow?.workflow_items?.items ?? []

    const shouldDisplayWorkflowCarousel =
      !!consultation?.id && !!workflowItems.length

    const onClickHeader = useCallback(
      () => selectDrawerAction(isAnesthesia ? DrawerAction.STATUS : null),
      [isAnesthesia, selectDrawerAction],
    )

    return (
      <>
        <PatientStartDateProvider patientStartDateValue={patientStartDate}>
          <GridManager
            sheet={sheet}
            changeDialogOpen={setDialogOpen}
            dialogOpen={dialogOpen}
            isLoading={isLoading}
            patientId={patientId}
            showAnesthesiaChart={getSheetIsAnesthesia(sheet)}
            showMoreDetails={isShowMoreDetails}
            onPressShowMore={onPressShowMore}
            onPressPatientInfo={onClickHeader}
            gridActionButton={actionButton}
            gridBackButton={backButton}
            consultationId={consultationId}
            selectDrawerAction={selectDrawerAction}
          />
        </PatientStartDateProvider>
        {!isLargeScreen ? (
          <MobilePatientPanelV2
            patientId={patientId}
            selectDrawerAction={selectDrawerAction}
          />
        ) : null}
        {shouldDisplayWorkflowCarousel ? (
          <WorkflowCarousel
            items={workflowItems}
            openDrawer={openWorkflowDrawer}
            isDischarged={isDischarged}
            onToggleWorkflow={onToggleWorkflow}
            isShowWorkflow={isShowWorkflow}
          />
        ) : null}
        <CallParameterDrawer
          toggleCallParameterDrawer={toggleCallParameterDrawer}
          drawerTitle={drawerTitle}
          isCallParameterDrawerVisible={isCallParameterDrawerVisible}
          isFinalized={isFinalized}
          sheetId={sheetId}
          navigateTo={navigateTo}
          patient={patient}
          onSubmit={onSubmit}
          showPatientStatus={showPatientStatus}
        />
        {!!consultation && (
          <WorkflowItemsDrawer
            isVisible={isWorkflowDrawerVisible}
            setIsVisible={setIsWorkflowDrawerVisible}
            workflow={consultation.workflow}
            consultationId={consultation.id}
            patientId={patientId}
            onCreate={consultationQueryResult.refetch}
          />
        )}
        {!environment.isWeb ? (
          <VRBottomSheet
            title={t('discharge:label')}
            ref={DischargeBottomSheetRef}
            handleClose={() => DischargeBottomSheetRef.current?.close()}
          >
            <DischargePatient
              showTitle={false}
              patientId={patientId}
              onDischarge={dischargePatient}
            />
          </VRBottomSheet>
        ) : null}
      </>
    )
  },
)

ReadySheet.displayName = 'ReadySheet'
