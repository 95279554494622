import React, { useState } from 'react'
import { View, Image as RawImage } from 'react-native'
import { LoadingOverlay } from 'components/common'

type Props = React.ComponentProps<typeof RawImage> & {
  isLoadingOverride?: boolean
}

export const Image: React.FC<Props> = props => {
  const [loading, setLoading] = useState(true)
  return (
    <View style={props.style} testID="Image Component">
      <LoadingOverlay isLoading={loading || !!props.isLoadingOverride}>
        <RawImage {...props} onLoadEnd={() => setLoading(false)} />
      </LoadingOverlay>
    </View>
  )
}
