import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color: string
}

export const SvgMessage: React.FunctionComponent<Props> = ({ color }) => (
  <Svg width={23} height={17}>
    <G fill={color} fillRule="nonzero">
      <Path d="M21.41 0H7.408c-.552.003-1 .447-1.008.998v2.313h7.066c1.149 0 2.084.953 2.084 2.102v5.801h1.679l2.333 2.788c.547.651.992.485.989-.366l-.01-2.422h.869c.546 0 .99-.417.99-.962V.998A.997.997 0 0 0 21.41 0z" />
      <Path d="M13.726 4H.673A.684.684 0 0 0 0 4.683v8.254c0 .175.073.341.2.464a.662.662 0 0 0 .473.184h1.1v2.468c0 .842.457.993 1.002.346l2.377-2.814h8.575a.662.662 0 0 0 .474-.184.649.649 0 0 0 .199-.464V4.683A.684.684 0 0 0 13.727 4z" />
    </G>
  </Svg>
)
