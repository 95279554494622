import React from 'react'
import { capitalize } from 'lodash'
import { StyleSheet, Text, View } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskItem } from 'src/types/getSheet'
import { Status } from 'types/globalTypes'

import { StatusHack } from './types'

type StatusT = Status | StatusHack | null | undefined

type TaskActionBoxProps = {
  taskToEdit: TaskItem
  isDiscontinued?: boolean | null
}

const getActionBoxText = (
  status: StatusT | null,
  isDiscontinued?: boolean | null,
) => {
  if (isDiscontinued) {
    return 'D/C'
  }

  switch (status) {
    case Status.DELETED_ON_PURPOSE:
      return 'DOP'
    case Status.IN_PROGRESS:
      return 'Started'
    case Status.MISSED_ON_PURPOSE:
      return capitalize(StatusHack.SKIPPED)
    default:
      return capitalize(status ?? '')
  }
}

export const TaskActionBox = ({
  taskToEdit,
  isDiscontinued = false,
}: TaskActionBoxProps) => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isNativeStyle = isExSmallScreen || isSmallScreen
  const status = taskToEdit?.status

  return (
    <View
      style={[
        styles.taskStatus,
        { borderColor: getTaskBoxColor(status) },
        isNativeStyle && styles.smallTaskStatus,
      ]}
      testID="taskBox"
    >
      <>
        <View
          style={[
            // VR-4850: Use an positioned view instead of border width to
            // fix ios render issue, as large radius bars blur on ios.
            styles.taskStatusIndicator,
            { backgroundColor: getTaskBoxColor(status) },
          ]}
        />
        <Text style={styles.taskStatusText}>
          {getActionBoxText(status, isDiscontinued)}
        </Text>
      </>
    </View>
  )
}

const taskBoxColors: Record<Status | StatusHack, string> = {
  [Status.DELETED_ON_PURPOSE]: Colors.contentTertiary,
  [Status.DONE]: Colors.task.done,
  [Status.DUE]: Colors.task.due,
  [Status.MISSED_ON_PURPOSE]: Colors.task.missedOnPurpose,
  [Status.MISSED]: Colors.task.missed,
  [Status.PENDING]: Colors.task.pending,
  [Status.IN_PROGRESS]: Colors.task.done,
  [StatusHack.SKIPPED]: Colors.task.missedOnPurpose,
}

export const getTaskBoxColor = (status: StatusT) => {
  if (!status) return Colors.white
  return taskBoxColors[status] ?? Colors.white
}

const styles = StyleSheet.create({
  taskStatus: {
    shadowColor: Colors.contentPrimary,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4.65,
    borderRadius: 4,
    alignItems: 'center',
    height: 40,
    justifyContent: 'center',
    marginRight: 24,
    width: 64,
    elevation: 7,
    backgroundColor: Colors.white,
  },
  smallTaskStatus: {
    marginRight: 10,
    width: 50,
  },
  taskStatusIndicator: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 4,
  },
  taskStatusText: {
    fontSize: 12,
  },
})
