import React from 'react'
import { TouchableOpacity } from 'react-native'

import { useMouseHover } from 'src/hooks/useMouseHover.web'
import { EyeIcon } from './EyeIcon'
import { ToggleButtonText } from './ToggleButtonText'
import { Props } from './EyeIconWithText'
import { styles } from './styles'

export const EyeIconWithText: React.FC<Props> = ({
  onPress,
  isLargeScreen,
  isClick,
}) => {
  const { hoverRef, isHovered } = useMouseHover()
  return (
    <TouchableOpacity
      ref={hoverRef}
      style={[
        isLargeScreen ? styles.toggleButton : styles.toggleButtonSmall,
        isHovered && isLargeScreen && styles.fullOpacity,
      ]}
      onPress={() => onPress()}
    >
      <EyeIcon isClose={isClick} />
      <ToggleButtonText isHideText={isClick} />
    </TouchableOpacity>
  )
}
