import React from 'react'
import { Colors } from 'constants/Colors'
import { EyeClose, EyeOpen } from 'components/Icons'

export const EyeIcon = React.memo(
  ({
    isClose,
    width,
    height,
  }: {
    isClose: boolean
    width?: number
    height?: number
  }) => {
    if (isClose)
      return (
        <EyeClose
          color={Colors.blue}
          width={width ?? 18}
          height={height ?? 18}
        />
      )

    return (
      <EyeOpen color={Colors.blue} width={width ?? 18} height={height ?? 18} />
    )
  },
)

EyeIcon.displayName = 'EyeIcon'
