import React, { useState } from 'react'
import {
  differenceInDays,
  startOfDay,
  startOfWeek,
  addDays,
  isToday,
  getDay,
} from 'date-fns'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { useTimeResolution, getDayRangeExtent } from 'src/hocs/timeContext'
import { range } from 'lodash'
import { SvgArrow, SvgCalendarWithDate } from 'components/Icons'
import { TouchableArrow } from 'components/common/TouchableArrow'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { getSheet_getSheet as Sheet } from 'types/getSheet'
import { Tag } from 'src/components/Tag/Tag'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from 'components/shared'
import { useDayCountDisplay } from 'src/context/patientStartDate'
import { environment } from 'src/config'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'

type Props = {
  sheet?: Sheet | null
  patientStartDate: Date
  scrollToDateIdx: (idx: number, animated?: boolean) => void
  scrollToNow: () => void
  done: () => void
}

type SingleDayProps = {
  startOfWeekDate: Date
  index: number
  patientStartDate: Date
  dayCount: number
  navToDay: (day: Date) => void
}

const DAYS_ONE_WEEK = 7
const { isWeb } = environment

export const GridDaySelector: React.FC<Props> = ({
  sheet,
  patientStartDate,
  scrollToDateIdx,
  scrollToNow,
  done,
}) => {
  const { t } = useTranslation()
  const { dateFormatter } = useAdminTimeFormat()
  const { dateRangeCount, setVisibleDayRange } = useTimeResolution()

  const now = new Date()
  const [startOfWeekDate, setStartOfWeekDate] = useState(
    startOfWeek(now, { weekStartsOn: 1 }),
  )
  const startDayCount =
    differenceInDays(startOfWeekDate, startOfDay(patientStartDate)) + 1
  const days = range(startDayCount, startDayCount + DAYS_ONE_WEEK)

  const navToDay = (day: Date) => {
    // Scroll to the first day if dateRangeCount is 1, to the second day the dateRangeCount is 3
    scrollToDateIdx(dateRangeCount === 1 ? 0 : 1, false)
    setVisibleDayRange(getDayRangeExtent(day, dateRangeCount))
    done()
  }

  const navToNow = () => {
    scrollToNow()
    done()
  }

  const nextWeek = () => {
    setStartOfWeekDate(currentWeek => addDays(currentWeek, DAYS_ONE_WEEK))
  }

  const prevWeek = () => {
    setStartOfWeekDate(currentWeek => addDays(currentWeek, -DAYS_ONE_WEEK))
  }

  return (
    <View style={styles.container}>
      <Text style={styles.secondaryText}>{t('sheet:startOn')}</Text>
      <Text style={styles.startDateText}>
        {dateFormatter(patientStartDate, 'weekdaymonthyear')}
      </Text>
      {!!sheet?.closed_at && (
        <View style={styles.finalized}>
          <Tag style={styles.tag} title={t('sheet:finalize:tag')} />
          <Text style={styles.secondaryText}>
            {dateFormatter(new Date(sheet.closed_at), 'timeweekdaymonthyear')}
          </Text>
        </View>
      )}

      <View style={styles.weekNavContainer}>
        <TouchableArrow
          style={styles.rangeButton}
          orient="left"
          onPress={prevWeek}
        />
        <Text style={styles.weekNavText}>
          {`${dateFormatter(startOfWeekDate, 'weekdaymonth')} - ${dateFormatter(
            addDays(startOfWeekDate, DAYS_ONE_WEEK - 1),
            'weekdaymonth',
          )}`}
        </Text>
        <TouchableArrow
          testID="nextWeek"
          style={styles.rangeButton}
          orient="right"
          onPress={nextWeek}
        />
      </View>

      <TouchableOpacity style={styles.todayBtn} onPress={navToNow}>
        <SvgCalendarWithDate dateNumber={getDay(now)} color={Colors.blue} />
        <Text style={styles.todayBtnText}>{`${t(
          'sheets.time.today',
        )} - ${dateFormatter(now, 'weekdaymonthyear')}`}</Text>
      </TouchableOpacity>

      <View style={styles.weekdays}>
        {days.map((dayCount, i) => (
          <SingleGridDaySelector
            startOfWeekDate={startOfWeekDate}
            index={i}
            patientStartDate={patientStartDate}
            dayCount={dayCount}
            navToDay={navToDay}
            key={dayCount}
          />
        ))}
      </View>

      <DateTimePicker mode="date" onChange={navToDay} />
    </View>
  )
}

export const SingleGridDaySelector: React.FC<SingleDayProps> = ({
  startOfWeekDate,
  index,
  patientStartDate,
  dayCount,
  navToDay,
}) => {
  const { dateFormatter } = useAdminTimeFormat()
  const curDay = addDays(startOfWeekDate, index)
  const dayCountText = useDayCountDisplay(
    addDays(patientStartDate, dayCount - 1),
  )
  const isDateToday = isToday(curDay)

  return (
    <TouchableOpacity
      style={[styles.dayRow, isDateToday && styles.blue]}
      onPress={() => navToDay(curDay)}
    >
      <View style={styles.flexRow}>
        <Text style={[styles.dayCount, isDateToday && styles.blue]}>
          {dayCountText}
        </Text>
        <Text style={[isDateToday && styles.blue]}> - </Text>
        <Text style={[styles.secondaryText, isDateToday && styles.blue]}>
          {dateFormatter(curDay, 'weekdaymonthyear')}
        </Text>
      </View>

      <View style={styles.flexRow}>
        <SvgArrow
          color={isDateToday ? Colors.blue : Colors.contentSecondary}
          height={11}
          orient="right"
          width={11}
        />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  blue: {
    color: Colors.blue,
    borderColor: Colors.blue,
  },
  container: {
    paddingHorizontal: 40,
    paddingBottom: 75,
  },
  secondaryText: {
    fontFamily: Fonts.regular,
    color: Colors.contentTertiary,
  },
  startDateText: {
    fontFamily: Fonts.semibold,
    fontSize: 20,
    marginTop: isWeb ? 6 : 0,
    marginBottom: isWeb ? 4 : 0,
  },
  tag: {
    marginRight: 12,
  },
  finalized: {
    flexDirection: 'row',
  },
  weekNavContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  weekNavText: {
    fontFamily: Fonts.semibold,
    fontSize: 16,
  },
  todayBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: isWeb ? 20 : 15,
  },
  todayBtnText: {
    marginTop: 2,
    fontFamily: Fonts.regular,
    color: Colors.blue,
    paddingLeft: 6,
  },
  rangeButton: {
    padding: 18,
  },
  dayRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.shared.borderGrey,
    height: 38,
    width: '100%',
    borderRadius: 4,
    paddingHorizontal: 9,
    backgroundColor: Colors.white,
  },
  dayCount: {
    fontFamily: Fonts.semibold,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  weekdays: {
    marginBottom: isWeb ? 25 : 5,
  },
})
