import * as React from 'react'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'

type Props = {
  title: string
  label?: string
  style?: StyleProp<ViewStyle>
  isActive?: boolean
  onPress: (ev: GestureResponderEvent) => void
}

export const HeaderNavButton: React.FC<Props> = ({
  title,
  isActive = false,
  label = title,
  style = {},
  onPress,
}) => {
  return (
    <TouchableOpacity
      accessibilityLabel={label}
      testID={`IconButton-${title}`}
      style={[styles.button, style, styles.topButton]}
      onPress={onPress}
    >
      <TextWithTooltip
        ellipsizeMode="middle"
        numberOfLines={1}
        style={[
          styles.buttonText,
          styles.topButtonText,
          { color: isActive ? Colors.overDue : Colors.white },
        ]}
        title={title}
      >
        {title}
      </TextWithTooltip>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  buttonText: {
    fontFamily: Fonts.regular,
    fontSize: 13.6,
    marginTop: 2,
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    justifyContent: 'flex-end',
    width: 90,
  },
  topButton: {
    alignItems: 'center',
    height: 50,
    justifyContent: 'center',
    marginRight: 60,
    width: 'auto',
  },
  topButtonText: {
    fontFamily: Fonts.regular,
    fontSize: 17.5,
    marginTop: 0,
  },
})
