import { useTranslation } from 'react-i18next'
import { PolicyRule } from 'src/design-system/components/TextInput/TextInput'

export const usePasswordPolicy = (): PolicyRule[] => {
  const { t } = useTranslation()
  return [
    {
      rule: pwd => pwd.length >= 10,
      message: t('login:policy.password.passwordLength'),
    },
    {
      rule: pwd => pwd.search(/[a-z]/) !== -1,
      message: t('login:policy.password.passwordLowercase'),
    },
    {
      rule: pwd => pwd.search(/[A-Z]/) !== -1,
      message: t('login:policy.password.passwordUppercase'),
    },
    {
      rule: pwd => pwd.search(/\d/) !== -1,
      message: t('login:policy.password.passwordNumber'),
    },
    {
      rule: pwd => pwd.search(/[!@#$%^&*()_+|~\-=`{}[\]:";'<>?,./]/) !== -1,
      message: t('login:policy.password.passwordSpecial'),
    },
  ]
}
