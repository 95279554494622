import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { Colors } from 'constants/Colors'
import { SvgInformation } from 'components/Icons'
import { Fonts } from 'constants/Fonts'
export const InformativeMessage: React.FunctionComponent<{
  messageText: string
}> = ({ messageText }) => {
  return (
    <View style={styles.messageContainer}>
      <View style={styles.informationIcon}>
        <SvgInformation color={Colors.white} />
      </View>
      <View style={styles.informationTextContainer}>
        <Text style={styles.informationText}>{messageText}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  messageContainer: {
    marginTop: 4,
    marginBottom: 8,
    marginHorizontal: 8,
    backgroundColor: Colors.white,
    borderColor: Colors.contentTertiary,
    borderWidth: 1,
    maxWidth: 380,
    minHeight: 30,
    width: 'auto',
    height: 'auto',
    borderRadius: 4,
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  informationIcon: {
    position: 'relative',
    backgroundColor: Colors.vetRadarBlue,
    width: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  informationTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    flexShrink: 1,
  },
  informationText: {
    color: Colors.contentPrimary,
    fontFamily: Fonts.regular,
    fontSize: 13,
    flexWrap: 'wrap',
  },
})
