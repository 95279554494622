import React from 'react'
import { StyleSheet, View } from 'react-native'

type NodeElementProps = {}

export const GridTreatmentNode: React.FC<NodeElementProps> = React.memo(() => {
  return <View style={styles.touchableTextContainer} />
})

GridTreatmentNode.displayName = 'GridTreatmentNode'

const styles = StyleSheet.create({
  touchableTextContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
