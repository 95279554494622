import React, { useCallback, useState } from 'react'
import { EditableProductName } from 'components/TreatmentTemplate/ProductPicker/EditableProductName'
import { SelectedProductRow } from 'components/TreatmentTemplate/ProductPicker/SelectedProductRow'
import { TreatmentOption } from 'components/TreatmentTemplate/TemplateTreatmentForm'
import { StyleSheet, View } from 'react-native'

type Props = {
  product: TreatmentOption
  onRemoveProduct: (id: string) => void
  setProductName?: (name: string, id: string) => void
}

export const EditableSelectedProductRow: React.FC<Props> = ({
  product,
  onRemoveProduct,
  setProductName,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const isEditable = !!setProductName

  const handleChangeProductName = useCallback(
    (name: string) => {
      setProductName?.(name, product.id)
    },
    [product.id, setProductName],
  )

  const removeEditing = useCallback(() => setIsEditing(false), [])
  const setEditing = useCallback(() => setIsEditing(true), [])

  return (
    <>
      {isEditing ? (
        <View style={styles.container}>
          <EditableProductName
            value={product.name}
            onChange={handleChangeProductName}
            removeEditing={removeEditing}
            style={styles.inputHeight}
          />
        </View>
      ) : (
        <View style={styles.container}>
          <SelectedProductRow
            product={product}
            removeTreatmentOption={onRemoveProduct}
            shouldHideDelete={product.isOldChild}
            {...(isEditable ? { setEditing } : {})}
          />
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 5,
  },
  inputHeight: {
    height: 45,
  },
})
