import { find } from 'lodash'
import { getDepartments_getSites_items as Sites } from 'src/types/getDepartments'
import { getSheetDetail_getSheet_consultation as Consult } from 'types/getSheetDetail'

export const getSheetDepartment = (
  consult?: Consult | null,
  organisationSites?: Sites[] | null,
) => {
  if (!consult || !organisationSites) return ''
  return find(organisationSites, ['id', consult.site_id])?.name ?? ''
}
