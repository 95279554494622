import { noop } from 'lodash'
import { ToastType, toast } from 'components/common'
import { DataSyncLogStatus } from 'src/types/globalTypes'

const dataImportToastSettingMap: {
  [key in DataSyncLogStatus]: {
    action: ToastType | null
    message: string | null
    processDuration?: number | null
  }
} = {
  [DataSyncLogStatus.PENDING]: {
    action: ToastType.PROCESS,
    message:
      'The data import process is currently underway and may take a few minutes.',
    processDuration: 3000,
  },

  [DataSyncLogStatus.SUCCESS]: {
    action: ToastType.SUCCESS,
    message: 'The data import process has been successfully completed!',
  },
  [DataSyncLogStatus.FAILED]: {
    action: ToastType.ERROR,
    message:
      'Data import has failed. Please contact our support team for assistance.',
  },
}

export const useDataImportStatusToastSender = () => {
  return (
    dataImportStatus: DataSyncLogStatus,
    serverErrorMessage?: string | null,
  ): (() => void) | null => {
    const toastSetting = dataImportToastSettingMap[dataImportStatus]
    const { action, message, processDuration } = toastSetting
    const finalMessage = serverErrorMessage ?? message
    if (action) {
      // TODO: will fix the type here
      // @ts-ignore
      return toast[action](finalMessage, null, 100, processDuration) as
        | (() => void)
        | null
    }
    return noop
  }
}
