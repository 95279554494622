type Listener = () => void
const listeners: Listener[] = []

export const addReconnectionListener = (listener: Listener) => {
  listeners.push(listener)
}

export const removeReconnectionListener = (listener: Listener) => {
  const index = listeners.indexOf(listener)
  if (index !== -1) {
    listeners.splice(index, 1)
  }
}

export const notifyReconnection = () => {
  // Without requestAnimationFrame react components won't get re-render. Don't really know why..
  listeners.forEach(listener => requestAnimationFrame(listener))
}
