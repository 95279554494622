import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { SvgArrow } from 'components/Icons/Arrow'
import { Colors } from 'src/design-system/theme'
import { Search } from 'components/Icons/Search'
import { Paragraph } from 'src/design-system/components/Text'
import { FontWeights } from 'src/design-system/theme/typography/base'

type SelectDropdownProps = {
  selectedItem: string
  onPress: () => void
  disabled?: boolean
  showArrow?: boolean
  showSearchIcon?: boolean
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  selectedItem,
  onPress,
  disabled = false,
  showArrow = true,
  showSearchIcon = false,
}) => {
  return (
    <TouchableOpacity
      disabled={disabled}
      style={styles.selectContainer}
      onPress={onPress}
    >
      {showSearchIcon ? (
        <View style={styles.icon}>
          <Search />
        </View>
      ) : null}

      <View style={styles.selectContent}>
        <Paragraph size="M" style={[styles.selectedItem]}>
          {selectedItem}
        </Paragraph>
        {showArrow ? (
          <SvgArrow orient="down" color="grey" width={15} height={15} />
        ) : null}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  selectContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
    height: 49,
  },
  selectContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    color: Colors.Contents.primary,
  },
  selectedItem: {
    color: Colors.Contents.primary,
    fontWeight: FontWeights.bold,
    flex: 1,
    paddingHorizontal: 10,
  },
  icon: {
    justifyContent: 'center',
  },
})
