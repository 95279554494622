import 'expo-dev-client'
import 'react-native-gesture-handler'

import * as serviceWorkerRegistration from './src/serviceWorkerRegistration'
import { App } from './src/App'
import { ignoreWarnings } from './jest/ignoreWarnings'
import { sentryWrapper } from './src/utils/errorTracking/sentry/common'

ignoreWarnings(App.name)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

export default sentryWrapper(App)
