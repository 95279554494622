import { Colors } from 'constants/Colors'
import React from 'react'
import {
  View,
  Pressable,
  StyleSheet,
  Text,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native'

type Props = {
  disabled?: boolean
  action?: (arg0?: any) => void
  label: string
  buttonStyle?: StyleProp<ViewStyle>
  iconViewStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  icon: React.ReactElement | false
  title: string
  hideText: boolean
}

export const IconButtonWithText: React.FC<Props> = React.memo(
  ({
    disabled,
    label,
    action,
    buttonStyle,
    iconViewStyle,
    textStyle,
    icon,
    title,
    hideText,
  }) => {
    return (
      <Pressable
        disabled={disabled}
        accessibilityLabel={label}
        onPress={action}
        style={({ pressed }) => {
          return [styles.button, pressed && styles.pressed, buttonStyle]
        }}
      >
        <View style={iconViewStyle}>{icon}</View>
        {!hideText && <Text style={textStyle}>{title}</Text>}
      </Pressable>
    )
  },
)

IconButtonWithText.displayName = 'IconButtonWithText'

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 13,
    paddingHorizontal: 22,
  },
  pressed: {
    opacity: 0.7,
    backgroundColor: Colors.white,
  },
})
