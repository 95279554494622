import { Status } from 'types/globalTypes'
import { Task } from '../types'

export const getTaskIsDeletable = (task?: Task | null) => {
  const status = task?.status
  if (!status) return false
  return (
    status === Status.MISSED ||
    status === Status.DUE ||
    status === Status.PENDING
  )
}
