import {
  FrequencyType,
  TemplateTreatmentScheduleInput,
} from 'src/types/globalTypes'
import { RepeatScheduleValue } from '../data'
import { FrequencyInput } from 'components/FrequencySelector/FrequencySelector'

interface FormTemplateTreatmentScheduleInput {
  repeat: RepeatScheduleValue
  start_at?: number | null
  frequency?: number | null
  repeat_until?: number | null
  repeat_count?: number | null
  duration?: number | null
  time_window?: number | null
  enable_staffed_hour?: boolean | null
  type?: FrequencyType | null
  treatment_frequency_id?: string | null
  frequencies?: number[] | null
}

type PartialFrequencyInput = Pick<
  FormTemplateTreatmentScheduleInput,
  'type' | 'treatment_frequency_id' | 'frequencies' | 'frequency'
>

export const getTemplateTreatmentSchedule: (
  schedule: FormTemplateTreatmentScheduleInput,
  frequencyInput?: FrequencyInput,
) => TemplateTreatmentScheduleInput | null = (
  schedule: FormTemplateTreatmentScheduleInput,
  frequencyInput?: FrequencyInput,
) => {
  const { repeat } = schedule

  switch (repeat) {
    case RepeatScheduleValue.NONE:
      return null
    case RepeatScheduleValue.SINGLE:
      return { ...schedule, type: FrequencyType.INTERVAL, repeat: false }
    case RepeatScheduleValue.REPEATING: {
      let frequencies: PartialFrequencyInput = {}
      if (frequencyInput) {
        frequencies = {
          type: frequencyInput.type as FrequencyType,
          treatment_frequency_id: frequencyInput.id,
        }
        if (frequencyInput.type === FrequencyType.INTERVAL) {
          frequencies = {
            ...frequencies,
            frequency: frequencyInput.frequencies?.at(0),
            frequencies: null,
          }
        } else {
          frequencies = {
            ...frequencies,
            frequencies: frequencyInput.frequencies,
            frequency: null,
          }
        }
      }
      return {
        ...schedule,
        ...frequencies,
        repeat: true,
      }
    }
    default:
      return null
  }
}
