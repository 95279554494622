import React from 'react'
import { Scrollbar as AnesthesiaChartScrollbar } from 'components/Anesthesia/AnesthesiaChart/Scrollbar'
import {
  TreatmentSheet,
  TreatmentTreeProps,
} from 'components/Treatment/common/types'
import { Colors } from 'constants/Colors'
import { StyleSheet, View } from 'react-native'

import { foldedTreatmentsDefault } from './common'
import { TreatmentGroup } from './TreatmentGroup'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { usePatientById } from 'src/hooks/usePatient'
import { useRoute } from '@react-navigation/native'
import { SheetScreenRouteProp } from 'components/Sheet/Sheet'
import { useRound } from 'src/hooks/useRound'

export enum DosageInfoType {
  DOSAGE_RATE = 'dosageRate',
  TOTAL_VOLUME = 'totalVolume',
  TOTAL_DOSAGE = 'totalDosage',
}

export const TreatmentTree: React.FC<TreatmentTreeProps> = React.memo(
  ({
    foldedTreatments = foldedTreatmentsDefault,
    isFinalized,
    onPressTreatment,
    onPressTreatmentGroupTitle,
    onToggleFolding = () => null,
    templateName,
    treatments,
    treatmentWidth,
    showAnesthesiaChart = false,
    timeInChart,
  }) => {
    const { params } = useRoute<SheetScreenRouteProp>()
    const { patientId } = params

    const { settingsMap } = useOrgSettings()
    const dosageInfoType = (settingsMap?.TREATMENT_LEAF_DOSAGE_INFO_TYPE
      ?.value ?? DosageInfoType.DOSAGE_RATE) as DosageInfoType
    const patient = usePatientById(patientId)
    const round = useRound()

    return (
      <View style={[styles.container, { width: treatmentWidth }]}>
        <View>
          {showAnesthesiaChart ? (
            <AnesthesiaChartScrollbar
              isFinalized={isFinalized}
              timeInChart={timeInChart}
            />
          ) : null}
          {treatments.map((treatment: TreatmentSheet) => (
            <TreatmentGroup
              dosageInfoType={dosageInfoType}
              foldedTreatments={foldedTreatments}
              isFinalized={isFinalized}
              isFolded={foldedTreatments.includes(treatment.id)}
              key={treatment.id}
              onPressTreatment={onPressTreatment}
              onPressTreatmentGroupTitle={onPressTreatmentGroupTitle}
              onToggleFolding={onToggleFolding}
              patient={patient}
              round={round}
              templateName={templateName}
              treatment={treatment}
            />
          ))}
        </View>
      </View>
    )
  },
)

TreatmentTree.displayName = 'TreatmentTree'

const styles = StyleSheet.create({
  container: {
    borderColor: Colors.borderGrey,
    borderRightWidth: 1,
    flex: 1,
  },
})
