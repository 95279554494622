import React, { useState } from 'react'
import { Text, TextInput, View, StyleSheet } from 'react-native'
import { Colors, Typography, Variables } from 'src/design-system/theme'
import { HelpDocumentationLink } from 'components/Help/HelpDocumentationLink'
import { useTranslation } from 'react-i18next'
import { environment } from 'src/config'

const HelpDescription = () => {
  const { t } = useTranslation()
  return (
    <View style={styles.helpDescriptionContainer}>
      <Text style={styles.helpDescriptionText}>{t('help:description')}</Text>
    </View>
  )
}

const Divider = () => {
  return <View style={styles.divider} />
}

type HelpFormInputProps = {
  feedback: string
  onFeedbackChange: (_: string) => void
}

const HelpFormInput: React.FC<HelpFormInputProps> = ({
  feedback,
  onFeedbackChange,
}) => {
  const { t } = useTranslation()

  const [isFocused, setIsFocused] = useState<boolean>(false)

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
  }

  return (
    <View>
      <Text style={styles.helpFormInputText}>{t('help:feedbackIntro')}</Text>
      <View style={styles.helpFormTextInputContainer}>
        <TextInput
          testID="test-feedback-input"
          onFocus={onFocus}
          onBlur={onBlur}
          value={feedback}
          onChangeText={onFeedbackChange}
          style={[
            styles.helpFormTextInput,
            isFocused && { borderColor: Colors.Borders.accent },
          ]}
          placeholder={t('help:feedbackInputPlaceholder')}
          placeholderTextColor={Colors.Contents.accessory}
          multiline={true}
        />
      </View>
    </View>
  )
}

type HelpModalBodyProps = {
  feedback: string
  onFeedbackChange: (_: string) => void
  onCloseModal: () => void
}

export const HelpModalBody: React.FC<HelpModalBodyProps> = ({
  onFeedbackChange,
  feedback,
  onCloseModal,
}) => {
  return (
    <View>
      <View style={styles.helpDocumentSection}>
        <HelpDescription />
        <HelpDocumentationLink onCloseModal={onCloseModal} />
      </View>

      <Divider />

      <View style={styles.helpFormSection}>
        <HelpFormInput
          feedback={feedback}
          onFeedbackChange={onFeedbackChange}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  helpDescriptionContainer: {
    marginVertical: environment.isWeb
      ? Variables.GutterSpacing.base
      : Variables.GutterSpacing.md,
  },
  helpDescriptionText: {
    fontWeight: Typography.FontWeights.regular,
    fontSize: Typography.FontSizes.base,
    lineHeight: 20,
  },
  divider: {
    width: '100%',
    borderTopWidth: Variables.BorderWidths.base,
    borderTopColor: Colors.Borders.primary,
  },
  helpFormInputText: {
    fontWeight: Typography.FontWeights.bold,
    fontSize: Typography.FontSizes.base,
    lineHeight: 16,
  },
  helpFormTextInputContainer: {
    marginTop: Variables.GutterSpacing.sm,
  },
  helpFormTextInput: {
    height: 124,
    padding: Variables.GutterSpacing.base,
    borderWidth: Variables.BorderWidths.base,
    borderColor: Colors.Borders.secondary,
    borderRadius: 4,
  },
  helpDocumentSection: {
    marginHorizontal: Variables.GutterSpacing.md,
    marginBottom: Variables.GutterSpacing.md,
  },
  helpFormSection: {
    marginHorizontal: Variables.GutterSpacing.md,
    marginVertical: 16,
  },
})
