import {
  format,
  parseISO,
  formatDistanceStrict,
  isWithinInterval,
  addMinutes,
  subMinutes,
} from 'date-fns'
import { Status } from '../../../types/globalTypes'
import { Task } from '../types'
import { TFunction } from 'i18next'
import { useOrgSettings } from 'src/hooks/useOrgSettings'

export const useGetDateFormatPattern = () => {
  const { settingsMap } = useOrgSettings()
  const adminTimeFormat = settingsMap?.PREFERRED_TIME_FORMAT?.value ?? 'HH:mm'
  const adminDateFormat = settingsMap?.PREFERRED_DATE_FORMAT?.value ?? 'PP'
  const dateFormatPattern = `${adminTimeFormat} ${adminDateFormat}`
  return { dateFormatPattern }
}

export const getTaskTimeTextInfo = (
  task: Task | null,
  dateFormatPattern: string,
) => {
  let time = ''
  let isOverdue = false

  const status = task?.status
  // Team consensus is use start at as time to measure from for all text. This
  // means for missed tasks will say, overdue since <start_at>. Given start at
  // should only be available for completed tasks
  const startAt =
    task?.given_start_at ?? task?.start_at ?? new Date().toISOString()
  if (status && task) {
    const now = new Date()
    switch (status) {
      case Status.IN_PROGRESS:
        break
      case Status.PENDING:
      case Status.MISSED:
        time = formatDistanceStrict(now, parseISO(startAt))
        break
      // TODO: #VR-5311 - Show exact time task was set to this state
      case Status.DELETED_ON_PURPOSE:
      case Status.MISSED_ON_PURPOSE:
      case Status.DONE: {
        time = format(parseISO(startAt), dateFormatPattern)
        break
      }
      case Status.DUE: {
        const stopAt = task?.given_stop_at ?? task?.stop_at
        isOverdue = !!task?.stop_at && new Date(task.stop_at) < new Date()
        // check if current time is within window of opportunity including buffer period. if true, return 'now'
        const taskStartTimeBeforeTransitioning = subMinutes(
          parseISO(startAt),
          task.buffer_due ?? 0,
        )
        const taskEndTimeBeforeTransitioning = addMinutes(
          parseISO(stopAt!),
          task.buffer_missed ?? 0,
        )
        const isCurrentlyDue = isWithinInterval(now, {
          start: taskStartTimeBeforeTransitioning,
          end: taskEndTimeBeforeTransitioning,
        })
        time = isCurrentlyDue
          ? 'now'
          : formatDistanceStrict(now, parseISO(startAt))
        break
      }
    }
  }
  const isDueNow = time === 'now'
  return { time, isDueNow, isOverdue }
}

export const useGetStatusLabelText = () => {
  const { dateFormatPattern } = useGetDateFormatPattern()

  return (task: Task | null, t: TFunction) =>
    getStatusLabelText(task, t, dateFormatPattern)
}

export const useGetLastEditLabelText = () => {
  const { dateFormatPattern } = useGetDateFormatPattern()

  return (task: Task | null, t: TFunction) =>
    getLastEditLabelText(task, t, dateFormatPattern)
}

export const useGetCreatedLabelText = () => {
  const { dateFormatPattern } = useGetDateFormatPattern()

  return (task: Task | null, t: TFunction) =>
    getCreatedLabelText(task, t, dateFormatPattern)
}

export const getStatusLabelText = (
  task: Task | null,
  t: TFunction,
  dateFormatPattern: string,
) => {
  const { isDueNow, isOverdue, time } = getTaskTimeTextInfo(
    task,
    dateFormatPattern,
  )

  const statusLabelTexts = {
    [Status.DELETED_ON_PURPOSE]: t('task:taskAddEdit.status.deletedOnPurpose', {
      time,
    }),
    [Status.DONE]: t('task:taskAddEdit.status.done', { time }),
    [Status.DUE]: isDueNow
      ? t('task:taskAddEdit.status.dueNow')
      : t('task:taskAddEdit.status.pending', { time }),
    [Status.MISSED_ON_PURPOSE]: t('task:taskAddEdit.status.skipped', { time }),
    [Status.MISSED]: t('task:taskAddEdit.status.missed', { time }),
    [Status.PENDING]: t('task:taskAddEdit.status.pending', { time }),
    [Status.IN_PROGRESS]: t('task:taskAddEdit.status.inProgressNow'),
  }
  // Return 'Missed' text regardless of status for case like basic tasks where
  // can be past now but still PENDING
  return statusLabelTexts[isOverdue ? Status.MISSED : task?.status!]
}

export const getLastEditLabelText = (
  task: Task | null,
  t: TFunction,
  dateFormatPattern: string,
) => {
  const taskLastEditDate = task?.updated_at
  if (!taskLastEditDate) return null
  const time = format(parseISO(taskLastEditDate), dateFormatPattern)

  return t('task:taskAddEdit.lastEditAt', { time })
}

export const getCreatedLabelText = (
  task: Task | null,
  t: TFunction,
  dateFormatPattern: string,
) => {
  const taskCreatedDate = task?.created_at
  if (!taskCreatedDate) return null
  const time = format(parseISO(taskCreatedDate), dateFormatPattern)

  return t('task:taskAddEdit.createdAt', { time })
}
