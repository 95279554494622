import { ApolloClient } from '@apollo/client'
import { cloneDeep } from 'lodash'

import {
  getDataSyncLogs,
  getDataSyncLogsVariables,
  getDataSyncLogs_getDataSyncLogs_items,
} from 'src/types/getDataSyncLogs'
import { GET_DATA_SYNC_LOGS } from '../graphql'

export function UpdateDataSyncLogs(
  client: ApolloClient<object>,
  organisationId: string,
  newData: getDataSyncLogs_getDataSyncLogs_items,
) {
  const query = GET_DATA_SYNC_LOGS
  const variables = { organisationId }
  const clonedDataSyncLogs = cloneDeep(
    client.readQuery<getDataSyncLogs, getDataSyncLogsVariables>({
      query,
      variables,
    }),
  )

  if (!clonedDataSyncLogs) {
    return
  }

  const indexOfResult = clonedDataSyncLogs.getDataSyncLogs.items?.findIndex(
    item => item.id === newData.id,
  )

  if (indexOfResult !== -1) {
    clonedDataSyncLogs.getDataSyncLogs.items[indexOfResult].status =
      newData.status
  }

  if (indexOfResult === -1 && !!newData) {
    clonedDataSyncLogs.getDataSyncLogs.items?.push(newData)
  }

  client.writeQuery<getDataSyncLogs, getDataSyncLogsVariables>({
    query,
    variables,
    data: clonedDataSyncLogs,
  })
}
