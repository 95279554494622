import React from 'react'
import Svg, { G, Path } from 'react-native-svg'
import { Colors } from 'src/design-system/theme'

type Props = {
  color?: string
  size: number
}

export const SvgDownload: React.FC<Props> = React.memo(
  ({ color = Colors.Contents.onColor, size = 16 }) => (
    <Svg width={size} height={size} viewBox="0 0 12 12">
      <G fill={color}>
        <Path d="M1.5 11C1.0875 11 0.734251 10.8652 0.440251 10.5957C0.146251 10.3262 -0.000498727 10.0027 1.27335e-06 9.625V7.5625H1.5V9.625H10.5V7.5625H12V9.625C12 10.0031 11.853 10.3269 11.559 10.5964C11.265 10.8659 10.912 11.0005 10.5 11H1.5ZM6 8.25L2.25 4.8125L3.3 3.81562L5.25 5.60312V0H6.75V5.60312L8.7 3.81562L9.75 4.8125L6 8.25Z" />
      </G>
    </Svg>
  ),
)

SvgDownload.displayName = 'SvgDownload'
