import React, { createContext, useCallback, useContext, useState } from 'react'
import { IdleTimer } from 'components/IdleTimer'
import { noop } from 'lodash'
import { useDeviceTimeout } from 'src/context/deviceLock/utils/useDeviceTimeout'
import { deviceLockService } from 'src/utils/deviceLockService'
import { pinSwitchService } from 'src/utils/pinSwitchService'
import {
  Auth,
  AUTH_STATE,
  AUTH_WEIGHT,
  authTypeService,
  useAuth,
  userSessionService,
} from 'src/context/auth'

type Context = {
  lock: () => void
  unlock: () => void
  onSelectUnlockedUser: (email: string) => void
  selectedUnlockedUserEmail: string
  isLocked: boolean
}

const DeviceLockContext = createContext<Context>({
  lock: noop,
  unlock: noop,
  onSelectUnlockedUser: noop,
  selectedUnlockedUserEmail: '',
  isLocked: false,
})
DeviceLockContext.displayName = 'DeviceLockContext'

export const useDeviceLock = (): Context => {
  const context = useContext<Context>(DeviceLockContext)

  if (!context) {
    throw new Error('useDeviceLock must be used within a DeviceLockProvider')
  }

  return context
}

type Props = {
  children: React.ReactElement
}

export const DeviceLockProvider: React.FC<Props> = ({ children }) => {
  const isAuthV2 = authTypeService.getIsAuthV2()
  const { toggleAuthState } = useAuth()
  const [isLocked, setIsLocked] = useState<boolean>(
    deviceLockService.isLocked(), // authState === AUTH_STATE.UNLOCKED_WITH_PIN,
  )

  const lock = useCallback(async () => {
    deviceLockService.lock()
    setIsLocked(true)
    if (isAuthV2) {
      await Auth.signOut(false) // Switch user needs to deactivate the session
      userSessionService.setAuthWeight(AUTH_WEIGHT.AUTHENTICATED)
      toggleAuthState(AUTH_STATE.UNLOCK_WITH_PIN, false)
    }
  }, [isAuthV2, toggleAuthState])

  const unlock = useCallback(() => {
    deviceLockService.unlock()
    setIsLocked(false)
    if (isAuthV2) {
      toggleAuthState(AUTH_STATE.UNLOCK_WITH_PIN, true)
    }
  }, [isAuthV2, toggleAuthState])

  const email = isAuthV2
    ? userSessionService.getUserSession()?.userAttributes?.email
    : pinSwitchService.currentUser?.attributes?.email
  const [selectedUnlockedUserEmail, setSelectedUnlockedUserEmail] =
    useState<string>(email ?? '')

  const deviceTimeout = useDeviceTimeout()

  return (
    <DeviceLockContext.Provider
      value={{
        lock,
        unlock,
        onSelectUnlockedUser: setSelectedUnlockedUserEmail,
        selectedUnlockedUserEmail,
        isLocked,
      }}
    >
      <IdleTimer timeout={deviceTimeout} onIdle={lock} isIdle={isLocked}>
        {children}
      </IdleTimer>
    </DeviceLockContext.Provider>
  )
}
