import Svg, { Circle, Line } from 'react-native-svg'
import React from 'react'

type Props = {
  color?: string
  height?: number
  width?: number
}

export const SvgIVFluidStop: React.FC<Props> = ({
  color = '#00bbd4',
  height = 20,
  width = 25,
}) => (
  <Svg viewBox="0 0 25 20" height={height} width={width} fill="none">
    <Circle
      cx="15"
      cy="10"
      r="10"
      fill={color}
      transform="matrix(1, 0, 0, 1, -1.1102230246251565e-16, 0)"
    />
    <Line
      x1="6"
      y1="10"
      x2="-1.74846e-07"
      y2="10"
      stroke={color}
      strokeWidth="4"
      transform="matrix(1, 0, 0, 1, -1.1102230246251565e-16, 0)"
    />
  </Svg>
)
