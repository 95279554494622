import { useApolloClient, useMutation } from '@apollo/client'
import { cloneDeep } from 'lodash'
import { useOrganisation } from 'src/context/organisation'
import { getUsersList, getUsersListVariables } from 'src/types/getUsersList'
import {
  createUser as CreateUser,
  createUserVariables as CreateUserVariables,
} from 'types/createUser'

import { CREATE_USER, GET_USERS_LIST } from './graphql'

export const useCreateUser = () => {
  const [{ organisationId }] = useOrganisation()
  const client = useApolloClient()
  return useMutation<CreateUser, CreateUserVariables>(CREATE_USER, {
    onCompleted: data => {
      try {
        const preData = client.readQuery<getUsersList, getUsersListVariables>({
          query: GET_USERS_LIST,
          variables: {
            organisation_id: organisationId,
          },
        })
        const { createUser } = data

        if (!preData || !createUser) {
          return
        }

        const userInList = {
          id: createUser.id,
          full_name: createUser.full_name,
          display_name: createUser.display_name,
          role: createUser.role,
          veterinary_role: createUser.veterinary_role,
        }

        const cloned = cloneDeep(preData)
        cloned.getUsersList.items.push({
          ...userInList,
          __typename: 'UserInList',
          sites: null,
        })
        client.writeQuery({
          query: GET_USERS_LIST,
          variables: {
            organisation_id: organisationId,
          },
          data: cloned,
        })
      } catch {
        // ignore if can't find GET_USERS_LIST in cache
      }
    },
  })
}
