import React from 'react'
import { View, Platform, StyleProp, ViewStyle } from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'

type Props = {
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
}

export const Center: React.FC<Props> = ({ children, style }) => {
  const breakpoint = useBreakpoint()
  const isFullWidth = Platform.OS !== 'web' || !breakpoint.isLargeScreen
  return (
    <View
      style={[
        {
          marginHorizontal: 'auto',
          width: isFullWidth ? '100%' : 1024,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}
