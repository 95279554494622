import React, { Fragment, useCallback, useMemo } from 'react'

import { FormLabel, SecondaryButton, Select, TextLink } from 'components/common'
import { noop } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { TemplateFormInput } from '../createSheetData'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { useFlags } from 'react-native-flagsmith/react'

type Props = {
  onPress: (index: number) => void
  onAddTemplate: () => void
  templateFormInputs: TemplateFormInput[]
  onSelectedTemplatesChange: (templateFormInputs: TemplateFormInput[]) => void
}

export const TemplateSection: React.FC<Props> = ({
  onAddTemplate,
  templateFormInputs,
  onSelectedTemplatesChange,
  onPress,
}) => {
  const { t } = useTranslation()
  const flags = useFlags([FeatureFlagNames.ENABLE_PROCEDURE_UI])
  const templateLabelVersion = flags.enable_procedure_ui.enabled
    ? 'addTemplateProcedure'
    : 'addTemplate'

  const onRemoveTemplate = useCallback(
    (idx: number) => {
      onSelectedTemplatesChange(
        templateFormInputs.filter((_, index) => index !== idx),
      )
    },
    [onSelectedTemplatesChange, templateFormInputs],
  )

  const selectedTemplates = useMemo(
    () =>
      templateFormInputs.map(
        templateFormInput => templateFormInput.selectedTemplate,
      ),
    [templateFormInputs],
  )

  return (
    <View>
      <FormLabel text={t(`${templateLabelVersion}.groupLabel`)} />
      {selectedTemplates.map((template, index) => {
        const removeTemplate = () => onRemoveTemplate(index)
        return (
          <Fragment key={index}>
            <TouchableOpacity
              onPress={() => {
                onPress(index)
              }}
            >
              <Select
                label={t(`${templateLabelVersion}.label`)}
                onChange={noop}
                options={[
                  {
                    value: template.id ?? '',
                    text: template.name ?? '',
                  },
                ]}
                placeholder={t(`${templateLabelVersion}.placeholder`)}
                selected={template === null ? template : template.id}
                isOpenMenuOverride={true}
              />
            </TouchableOpacity>
            <TextLink
              text={t('general.remove')}
              a11yLabel={`${t('general.remove')} template button`}
              onPress={removeTemplate}
              containerStyles={styles.removeButton}
            />
          </Fragment>
        )
      })}
      <SecondaryButton
        title={`+ ${t(`${templateLabelVersion}.action`)}`}
        style={styles.secondaryButton}
        onPress={onAddTemplate}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  secondaryButton: {
    alignSelf: 'flex-start',
    paddingLeft: 16,
    paddingBottom: 32,
  },
  removeButton: {
    alignItems: 'flex-end',
    paddingRight: 16,
    paddingBottom: 16,
  },
})
