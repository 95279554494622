import { useMemo } from 'react'
import { useSheetContext } from 'src/context/sheet'
import { useAssociatedSiteIds } from 'src/hooks/useAssociatedSiteIds'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { getOneProduct_getProduct as OneProduct } from 'src/types/getOneProduct'

import { CUBEX_BANNER_STATUS } from './CubexTopBanner'

export const useCubexIntegration = (
  product?: OneProduct | Partial<OneProduct>,
) => {
  const { settingsMap } = useOrgSettings()

  const [{ consultationSiteId }] = useSheetContext()

  const { getProductCubexIntegration } = useAssociatedSiteIds()

  const isCubexEnabled = settingsMap?.ENABLE_CUBEX_CABINET?.value === 'true'

  const cubexIntegration = useMemo(() => {
    return isCubexEnabled
      ? getProductCubexIntegration(
          consultationSiteId,
          product?.cubex_integrations,
        )
      : undefined
  }, [
    consultationSiteId,
    getProductCubexIntegration,
    product?.cubex_integrations,
    isCubexEnabled,
  ])

  const cubexBannerStatus = useMemo(() => {
    return cubexIntegration?.isProductInCubex
      ? CUBEX_BANNER_STATUS.WILL_SYNC
      : CUBEX_BANNER_STATUS.NOT_SHOW
  }, [cubexIntegration])

  return {
    cubexBannerStatus,
    isCubexProductBillable: cubexIntegration?.isCubexBillable ?? false,
  }
}
