type InitialBillingStateParams = {
  isEdit: boolean
  productsBillable: boolean
  treatmentBillable: boolean
}

type GetTemplateTreatmentBilling = (
  billingStateParams: InitialBillingStateParams,
) => boolean | null

// Gets initial state of template treatment billing entry (which can be a little
// complicated :-))
export const getTemplateTreatmentBilling: GetTemplateTreatmentBilling = ({
  isEdit,
  productsBillable,
  treatmentBillable,
}) => {
  if (!isEdit) {
    // Just use the product state when to determine
    return productsBillable ? true : null
  }
  // If 'Editing Treatment' we should show the treatmentIsBillable status only
  // when all products available would allow it. (This is because the
  // treatment can be set to billable: false, but you could be using products
  // that wouldn't allow this. This will allow for showing a false value when
  // you set billing to 'false' on a 'billable' set of products
  return productsBillable ? treatmentBillable : null
}
