import {
  equineSexToTagTitle,
  sexToTagTitle,
} from 'components/Tag/utils/patientToTagTitle'

export const getPatientIsEquine = (species?: string | null) => {
  if (!species) return false
  return species === 'Equine' || species === 'Equine (Horse)'
}

type PatientSpecies = {
  species: {
    name: string | null
  } | null
  date_of_birth: string | null
  sex: string | null
}

export const getPatientSexTagTitle = (patient: PatientSpecies) => {
  const isEquine = getPatientIsEquine(patient.species?.name)

  return isEquine
    ? equineSexToTagTitle(patient.sex, patient.date_of_birth)
    : sexToTagTitle(patient.sex)
}
