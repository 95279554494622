import React, { useCallback, useContext, useState } from 'react'
import {
  useNavigation,
  useRoute,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native'
import { noop } from 'lodash'
import { Platform } from 'react-native'

const FullScreenSwitchContext = React.createContext({
  isFullScreen: false,
  switchFullScreen: noop,
})
FullScreenSwitchContext.displayName = 'FullScreenSwitchContext'

export const getParam = (param: string) => {
  return (
    Platform.OS === 'web' &&
    new URLSearchParams(window.location.search).get(param)
  )
}

type Props = {
  children: React.ReactElement
}

const FullScreenSwitchProvider: React.FC<Props> = props => {
  const navigation = useNavigation()
  const { navigate } = navigation
  const route = useRoute()
  const [isFullScreen, setIsFullScreen] = useState(
    () => getParam('fullScreen') === 'true',
  )
  const switchFullScreen = useCallback(
    () =>
      setIsFullScreen(value => {
        const routeName = getFocusedRouteNameFromRoute(route)

        const nextValue = !value
        if (routeName) {
          navigate(routeName, { fullScreen: `${nextValue}` })
        }

        return nextValue
      }),
    [navigate, route],
  )

  return (
    <FullScreenSwitchContext.Provider
      value={{
        isFullScreen,
        switchFullScreen,
      }}
    >
      {props.children}
    </FullScreenSwitchContext.Provider>
  )
}

export const withFullScreenSwitchProvider = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return (props: P) => (
    // ApolloProvider can be removed after full on apollo hooks
    <FullScreenSwitchProvider>
      <WrappedComponent {...props} />
    </FullScreenSwitchProvider>
  )
}

export const useHeaderSwitch = () => useContext(FullScreenSwitchContext)
