import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color: string
  width?: number
  height?: number
  orient: Orient
}

export const orientToDegree = {
  left: 90,
  up: 180,
  right: 270,
  down: 0,
}

export type Orient = keyof typeof orientToDegree

export const SvgArrowWithStem: React.FC<Props> = React.memo(
  ({ color, width = 23, height = 23, orient }) => (
    <Svg width={width} height={height} viewBox="0 0 492 492">
      <G
        fill={color}
        fillRule="nonzero"
        transform={`rotate(${orientToDegree[orient]}, 246 246)`}
      >
        <Path d="M169.4,470.6c12.5,12.5,32.8,12.5,45.3,0l160-160c12.5-12.5,12.5-32.8,0-45.3s-32.8-12.5-45.3,0L224,370.8,224,64c0-17.7-14.3-32-32-32s-32,14.3-32,32l0,306.7L54.6,265.4c-12.5-12.5-32.8-12.5-45.3,0s-12.5,32.8,0,45.3l160,160z" />
      </G>
    </Svg>
  ),
)

SvgArrowWithStem.displayName = 'SvgArrowWithStem'
