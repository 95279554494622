export function createNewSheetBlurListener() {
  throw new Error(
    'createNewSheetBlurListener only to be used in *native* clients',
  )
}

export function webReplaceHistory(path: string) {
  if (window && window.history) {
    window.history.replaceState('', '', path)
  }
}

export function nativeCreateSheetDoneListener() {
  throw new Error(
    'nativeCreateSheetDoneListener only to be used in *native* clients',
  )
}
