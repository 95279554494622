import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_PATIENT } from 'components/Patient/graphql'
import { useUpdateConsultation } from 'components/Patient/useUpdateConsultation'
import { GET_COLORS } from 'components/Settings/Colors/graphql'
import { toast } from 'components/common'
import { ColorPicker } from 'components/shared/ColorPicker'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdatePatientListCache } from 'src/components/Patient/useUpdatePatientListCache'
import { useOrganisation } from 'src/context/organisation'
import { getColors, getColorsVariables } from 'src/types/getColors'
import { ConsultationLocationInput as ConsultationLocation } from 'src/types/globalTypes'
import {
  updatePatient as UpdatePatient,
  updatePatientVariables,
  updatePatient_updatePatient,
} from 'src/types/updatePatient'

type Props = {
  patientId: string
  consultationId: string
  locations: ConsultationLocation[]
  currentColor: string | null
}

export const SheetActionColorPicker: React.FC<Props> = ({
  patientId,
  consultationId,
  locations,
  currentColor,
}) => {
  const { t } = useTranslation()
  const [selectedColor, setSelectedColor] = useState<string | null>(
    currentColor,
  )
  const [{ organisationId }] = useOrganisation()
  const { data: colorsData } = useQuery<getColors, getColorsVariables>(
    GET_COLORS,
    { variables: { getColorsOrganisationId: organisationId } },
  )
  const [updatePatient] = useMutation<UpdatePatient, updatePatientVariables>(
    UPDATE_PATIENT,
  )
  const updateConsultation = useUpdateConsultation(patientId)
  const updatePatientListCache = useUpdatePatientListCache()

  const colorOptions = colorsData?.getColors?.items?.map(obj => obj.hex) ?? []

  const handleColorChange = async (updatedColor: string | null) => {
    setSelectedColor(updatedColor)
    const colourId =
      colorsData?.getColors?.items?.find(col => col.hex === updatedColor)?.id ??
      null

    try {
      await updatePatient({
        variables: {
          input: {
            id: patientId,
            organisation_id: organisationId,
            color: updatedColor,
            color_id: colourId,
          },
        },
        update: (_, { data: updateData }) => {
          if (!updateData?.updatePatient) return

          updatePatientListCache(
            updateData?.updatePatient as updatePatient_updatePatient,
            'UPDATE',
          )
        },
      })
      await updateConsultation({
        locations,
        id: consultationId,
        patient_id: patientId,
        organisation_id: organisationId,
        color_id: colourId,
      })

      toast.success(t('patient:action.color.toastMessage'))
    } catch (error) {
      if (error instanceof Error) toast.error(error.message)
    }
  }

  return (
    <ColorPicker
      options={colorOptions}
      label={t('patient:action.color.label')}
      selected={selectedColor}
      onChange={selected => handleColorChange(selected)}
    />
  )
}
