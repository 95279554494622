import React from 'react'
import { Routes } from 'constants/Routes'
import { useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { getBreakpoint, useBreakpoint } from 'src/hocs/breakpoint'
import {
  AppScreensConfig,
  ScreenName,
} from 'components/Base/screens/AppScreensConfig'
import { HeaderNavButton } from '../HeaderNavButton'

const { isExSmallScreen, isSmallScreen } = getBreakpoint()
const isSmallishInitialScreen = isExSmallScreen || isSmallScreen

export const routeNavs = [
  Routes.PatientsStack,
  ...(!isSmallishInitialScreen ? [Routes.WhiteboardsStack] : []),
  Routes.SettingsStack,
]

// only used for webview
export const HeaderNavButtons = React.memo(() => {
  const { t } = useTranslation()
  const route = useRoute()
  const { navigate } = useNavigation()
  const { isLargeScreen } = useBreakpoint()

  return (
    <>
      {routeNavs.map(routeName => {
        const routeNav = AppScreensConfig[routeName as ScreenName]

        const navigateToRoute = () =>
          navigate(routeName, { screen: routeNav!.rootStack })

        return (
          <HeaderNavButton
            key={routeName}
            // eslint-disable-next-line react/jsx-no-leaked-render
            style={isLargeScreen && styles.largeScreenMargin}
            title={t(routeNav!.titleKey)}
            isActive={route.name === routeName}
            onPress={navigateToRoute}
          />
        )
      })}
    </>
  )
})

HeaderNavButtons.displayName = 'HeaderNavButtons'

const styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  largeScreenMargin: {
    marginHorizontal: 19,
  },
})
