import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { Routes } from 'src/constants/Routes'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { SvgAddMedPlus } from 'src/components/Icons/AddMedPlus'

type Props = {
  title: string
  disabled?: boolean
  onPress?: () => void
  isInvertedStyle?: boolean
}

export const addTreatmentButtonHeight = 57

export const AddTreatmentButton: React.FC<Props> = React.memo(
  ({ disabled, onPress, isInvertedStyle, title }) => {
    const { t } = useTranslation()
    const { isExSmallScreen } = useBreakpoint()
    const route = useRoute()

    const showFullTitle = route.name === Routes.EditTemplate || !isExSmallScreen

    return (
      <Pressable
        style={({ pressed }) => [
          styles.container,
          isInvertedStyle && styles.reverseContainer,
          pressed && styles.lightTransparent,
        ]}
        onPress={onPress}
        testID="AddTreatmentButton"
        disabled={disabled}
      >
        <>
          <View style={styles.miniContainer}>
            <View style={styles.addMedPlus}>
              <SvgAddMedPlus
                color={
                  isInvertedStyle && !disabled
                    ? Colors.buttons.blue
                    : disabled && isInvertedStyle
                    ? Colors.lightGrey2
                    : Colors.white
                }
              />
            </View>
            <Text
              style={[
                styles.titleText,
                isInvertedStyle ? styles.reverseText : styles.text,
                disabled && styles.disabledText,
              ]}
            >
              {showFullTitle ? title : t('addTreatment:addBtnShortTitle')}
            </Text>
          </View>
        </>
      </Pressable>
    )
  },
)

AddTreatmentButton.displayName = 'AddTreatmentButton'

const styles = StyleSheet.create({
  container: {
    height: addTreatmentButtonHeight,
    backgroundColor: Colors.buttons.blue,
    width: '100%',
    justifyContent: 'center',
    borderRightWidth: 1,
    borderColor: Colors.shared.borderGrey,
    borderBottomWidth: 1,
  },
  lightTransparent: {
    opacity: 0.7,
  },
  disabledText: {
    color: Colors.lightGrey2,
  },
  titleText: {
    marginLeft: 8,
    fontFamily: Fonts.bold,
  },
  text: {
    color: Colors.white,
    fontFamily: Fonts.bold,
  },
  addMedPlus: {
    marginTop: 1,
  },
  miniContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  reverseContainer: {
    backgroundColor: Colors.white,
  },
  reverseText: {
    color: Colors.buttons.blue,
  },
})
