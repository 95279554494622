import React from 'react'
import Svg, { Defs, Path, G, Mask, Use } from 'react-native-svg'

type Props = {
  color: string
}

export const SvgDownChevron: React.FC<Props> = ({ color }) => (
  <Svg width={50} height={40}>
    <Defs>
      <Path
        d="M7.856 4.81a.689.689 0 0 0 .948 0 .635.635 0 0 0 0-.917L4.974.19a.689.689 0 0 0-.948 0L.196 3.893a.635.635 0 0 0 0 .918.689.689 0 0 0 .948 0l3.357-3.248 3.355 3.248z"
        id="down_chevron_svg__a"
      />
    </Defs>
    <G fill="none" fillRule="evenodd">
      <Mask id="down_chevron_svg__b" fill="#fff">
        <Use href="#down_chevron_svg__a" />
      </Mask>
      <Use
        fill={color}
        href="#down_chevron_svg__a"
        transform="rotate(-180 4.5 2.5) translate(-25 -20) scale(2)"
      />
    </G>
  </Svg>
)
