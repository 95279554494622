import { useCallback, useMemo, useRef, useState } from 'react'
import { LayoutChangeEvent, NativeScrollEvent } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export const usePatientPanelScroll = () => {
  const scrollRef = useRef<KeyboardAwareScrollView>(null)
  const [containerHeight, setContainerHeight] = useState<number | null>(null)
  const [scrollContentHeight, setScrollContentHeight] = useState<number | null>(
    null,
  )
  const [atBottom, setAtBottom] = useState(false)

  const showScrollButton = useMemo(() => {
    return (
      scrollContentHeight &&
      containerHeight &&
      scrollContentHeight > containerHeight
    )
  }, [scrollContentHeight, containerHeight])
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false)

  const handleContainerHeight = (event: LayoutChangeEvent) => {
    setContainerHeight(event.nativeEvent.layout.height)
  }

  const handleScrollContentHeight = (_: number, height: number) => {
    setScrollContentHeight(prevState => {
      if (prevState && height < prevState && atBottom) {
        setShouldScrollToTop(true)
      }
      if (!prevState || prevState <= height) {
        setShouldScrollToTop(false)
      }
      return height
    })
  }

  const handleScrollToTop = useCallback(() => {
    scrollRef?.current?.scrollToPosition(0, 0, true)
  }, [scrollRef])

  const handleScrollToEnd = useCallback(() => {
    scrollRef?.current?.scrollToEnd(true)
  }, [scrollRef])

  const handleScroll = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const scrollAtBottom =
      layoutMeasurement.height + contentOffset.y >= contentSize.height - 1
    setAtBottom(scrollAtBottom)
    setShouldScrollToTop(scrollAtBottom)
  }

  return {
    scrollRef,
    handleScrollContentHeight,
    handleContainerHeight,
    handleScrollToTop,
    handleScrollToEnd,
    showScrollButton,
    shouldScrollToTop,
    handleScroll,
  }
}
