import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { MultiSelect } from 'src/design-system/components/Selects'
import { GET_USERS_LIST } from 'components/Settings/graphql'
import { sortBy } from 'lodash'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { getUsersList, getUsersListVariables } from 'src/types/getUsersList'
import { OriginTypes } from 'src/types/globalTypes'
import { ezyVetSuffix } from 'constants/constants'

export type Props<T extends FieldValues> = {
  control: Control<T> | undefined
  name: string
}

export const filterUsersOut = (usersData: getUsersList | undefined) => {
  const users = sortBy(usersData?.getUsersList.items, 'display_name')

  const filteredUsers = users.filter(
    user => !user.display_name.includes(ezyVetSuffix),
  )

  return filteredUsers.map(user => ({
    value: user.id,
    text: user.display_name,
  }))
}

// OK to use any here, types handled in containing forms.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AdditionalCareTeam: React.FC<Props<any>> = ({ control, name }) => {
  const { t } = useTranslation()

  const [{ organisationId }] = useOrganisation()

  const { data: usersData, loading } = useQuery<
    getUsersList,
    getUsersListVariables
  >(GET_USERS_LIST, {
    fetchPolicy: 'cache-first',
    variables: {
      organisation_id: organisationId,
      origin_type: OriginTypes.EZYVET, // only getting ezyvetUsers
    },
  })

  const usersOptions = useMemo(() => filterUsersOut(usersData), [usersData])

  return (
    <View testID="additionalCareTeam">
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <MultiSelect
            options={usersOptions || []}
            selected={value}
            onChange={onChange}
            label={t('sheetForm.careTeam')}
            loading={loading}
            // @ts-expect-error No placeholder in web version.
            searchPlaceholder={t('sheetForm.careTeamSearch')}
          />
        )}
        name={name}
      />
    </View>
  )
}
