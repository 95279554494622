import { useState, useEffect } from 'react'
import { Audio } from 'expo-av'

const sounds = {
  alert: require('assets/alert.m4a'),
}

export const useSound = (soundType: keyof typeof sounds) => {
  const [sound, setSound] = useState<Audio.Sound | undefined>()
  useEffect(() => {
    const loadSound = async () => {
      const { sound } = await Audio.Sound.createAsync(sounds[soundType])
      setSound(sound)
    }
    loadSound()
  }, [soundType])

  return sound
}
