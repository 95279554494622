import { getFrequencyInputFromSchedule } from 'components/Treatment/utils/treatmentSchedule.utils'
import { FormData } from 'src/components/TreatmentForm'
import { Inputs } from 'src/components/TreatmentForm/types'
import {
  getTreatment_getTreatment as Treatment,
  getTreatment_getTreatment_fluid_dosage_info as FluidDosageInfo,
  getTreatment_getTreatment_medicine_dosage_info as MedicineDosageInfo,
} from 'src/types/getTreatment'

import { CRITimeUnit, RepeatScheduleValue } from '../data'

export const getTreatmentDefault = (
  treatment: Treatment,
  taskStartParam: string | undefined | null,
  defaultTimeWindow: number,
) => {
  const medicineDosageInfo = treatment.medicine_dosage_info
  const fluidDosageInfo = treatment.fluid_dosage_info
  const schedule = treatment.schedule
  const getStartAtDate = () => {
    if (schedule?.start_at) {
      return new Date(schedule.start_at)
    }
    if (taskStartParam) {
      return new Date(taskStartParam)
    }
    return null
  }

  const defaultOverrides = {
    ...getFrequencyInputFromSchedule(schedule),
    isRepeating: schedule?.repeat
      ? RepeatScheduleValue.REPEATING
      : RepeatScheduleValue.NONE,
    isBillable: treatment?.is_billable,
    startAtDate: getStartAtDate(), // 'now' as Exclude<SelectDateTimePickerValueType, 'discharge'>,
    repeatUntilDate:
      schedule?.repeat && schedule?.repeat_until
        ? new Date(schedule.repeat_until!)
        : 'discharge',
    timeWindow: schedule?.time_window ?? defaultTimeWindow,
    enableStaffedHour: !!schedule?.enable_staffed_hour,
    repeating: schedule?.frequency,
    instructions: treatment?.instructions ?? '',
    isInstructionsImportant: treatment?.is_instructions_important ?? false,
    isAutoCompleted: !!treatment?.is_auto_completed,
    isContinuous: !!treatment?.is_continuous,
    ...(medicineDosageInfo && getMedicineDosageDefault(medicineDosageInfo)),
    ...(fluidDosageInfo && getFluidDosageDefault(fluidDosageInfo)),
  } as FormData
  return defaultOverrides
}

const getFluidDosageDefault = (fluidDosageInfo: FluidDosageInfo) => {
  return {
    isHypovolemic: fluidDosageInfo.is_hypovolemic,
    isDehydrated: fluidDosageInfo.is_dehydrated,
    dehydration: fluidDosageInfo.dehydration,
    hoursToAdmin: fluidDosageInfo.hours_to_admin,
    dehydrationResult: fluidDosageInfo.dehydration_result,
    shockTotalResult: fluidDosageInfo.shock_total_result,
    physiologicReq: fluidDosageInfo.physiologic_req,
    ongoingLosses: fluidDosageInfo.ongoing_losses,
    totalResults: fluidDosageInfo.total_result,
    totalUnit: fluidDosageInfo.total_result_unit,
    IVSet: fluidDosageInfo.iv_set,
    dripRate: fluidDosageInfo.drip_rate,
    ...(fluidDosageInfo.patient_weight && {
      patientWeight: fluidDosageInfo.patient_weight,
      patientWeightUnit: fluidDosageInfo.patient_weight_unit,
    }),
    minutes: fluidDosageInfo.minutes,
    hours: fluidDosageInfo.hours,
    days: fluidDosageInfo.days,
    presetRateText: fluidDosageInfo.preset_rate_text,
  }
}

const getMedicineDosageDefault = (medicineDosageInfo: MedicineDosageInfo) => {
  return {
    isCRI: medicineDosageInfo.is_cri,
    administrationMethod: medicineDosageInfo.administration_method,
    fluidVolume: medicineDosageInfo.administration_volume,
    fluidVolumeUnit: medicineDosageInfo.infusion_rate_volume_unit ?? 'ml',
    infusionRate: medicineDosageInfo.infusion_rate,
    infusionRateVolumeUnit:
      medicineDosageInfo.infusion_rate_volume_unit ?? 'ml',
    infusionRateWeightUnit:
      medicineDosageInfo.infusion_rate_weight_unit ?? 'kg',
    infusionRateTimeUnit:
      medicineDosageInfo.infusion_rate_time_unit ?? CRITimeUnit.hour,
    doseRateTimeUnit:
      medicineDosageInfo.dose_rate_time_unit ??
      medicineDosageInfo.infusion_rate_time_unit ?? // old data using infusion_rate_time_unit
      CRITimeUnit.hour,
    infusionRateTotal: medicineDosageInfo.infusion_rate_total,
    isDiluted: medicineDosageInfo.is_diluted,
    routesOfAdmin: medicineDosageInfo.route_of_administration,
    // calculateBase: medicineDosageInfo.calculate_base,
    dosageRate: medicineDosageInfo.dosage,
    dosageWeightUnit: medicineDosageInfo.dosage_weight_unit,
    dosagePerWeightUnit: medicineDosageInfo.dosage_patient_weight_unit,
    concentration: medicineDosageInfo.concentration,
    concentrationWeightUnit: medicineDosageInfo.concentration_weight_unit,
    concentrationVolumeUnit: medicineDosageInfo.concentration_volume_unit,
    requiredRatioLHS: medicineDosageInfo.diluted_concentration_ratio_factor,
    requiredRatioRHS: medicineDosageInfo.diluted_concentration_ratio_volume,
    concentrationSetting: medicineDosageInfo.concentration_setting,
    unitsBilledPerTask: medicineDosageInfo.units_billed_per_task ?? 1,
    dilutedConcentration: medicineDosageInfo.diluted_concentration,
    dilutedConcentrationWeightUnit:
      medicineDosageInfo.concentration_weight_unit,
    dilutedConcentrationVolumeUnit:
      medicineDosageInfo.concentration_volume_unit,
    medicationVolume: medicineDosageInfo.medication_volume,
    medicationVolumeUnit: medicineDosageInfo.medication_volume_unit ?? 'ml',
    salineVolume: medicineDosageInfo.saline_volume,
    salineVolumeUnit: medicineDosageInfo.saline_volume_unit ?? 'ml',
    totalDosage: medicineDosageInfo.total_dosage,
    totalVolume: medicineDosageInfo.total_volume,
    totalVolumeUnit: medicineDosageInfo.total_volume_unit,
    ...(medicineDosageInfo.patient_weight && {
      patientWeight: medicineDosageInfo.patient_weight,
      patientWeightUnit: medicineDosageInfo.patient_weight_unit ?? 'kg',
    }),
    minutes: medicineDosageInfo.minutes,
    hours: medicineDosageInfo.hours,
    days: medicineDosageInfo.days,
    ivBagSize: medicineDosageInfo.iv_bag_size,
    ivBagSizeUnit: medicineDosageInfo.iv_bag_size_unit ?? 'ml',
    doseRate: medicineDosageInfo.dose_rate,
    diluentUsed: medicineDosageInfo.diluent_used,
  } as Inputs
}
