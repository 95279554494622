import React, { FunctionComponent } from 'react'
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import { Colors } from 'constants/Colors'
import { SvgHyperLinkOut } from 'components/Icons/HyperLinkOut'
import { navToHyperlink } from 'components/SheetInfo/SheetDetailForm'
import { HelpTooltip } from 'components/common/Tooltip/HelpTooltip'

type SheetDetailsProps = {
  department: string
  doctor: string
  vetTech: string
  careTeam?: Array<string | null>
  consultInfo: {
    id: string
    reference: string
  }
  sheetBundles?: Array<string | null>
}

export const SheetDetails: FunctionComponent<SheetDetailsProps> = ({
  department,
  doctor,
  vetTech,
  careTeam,
  consultInfo,
  sheetBundles,
}) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <ClinicalRecord consultInfo={consultInfo} />
      <DetailItem
        label={t('patientPanel:cards.sheetDetails.list.department')}
        value={department}
        testID="test-department"
      />
      <DetailItem
        label={t('patientPanel:cards.sheetDetails.list.doctor')}
        value={doctor}
        testID="test-doctor"
      />
      {!!vetTech && (
        <DetailItem
          label={t('patientPanel:cards.sheetDetails.list.vetTech')}
          value={vetTech}
          testID="test-vetTech"
        />
      )}
      {!!careTeam?.length && (
        <ListItem
          label={t('patientPanel:cards.sheetDetails.list.careTeam')}
          items={careTeam}
        />
      )}
      {!!sheetBundles?.length && (
        <ListItem
          label={t('patientPanel:cards.sheetDetails.list.bundles')}
          items={sheetBundles}
          tooltip={t('patientPanel:cards.sheetDetails.list.bundlesTooltip')}
        />
      )}
    </View>
  )
}

const ClinicalRecord: FunctionComponent<{
  consultInfo: SheetDetailsProps['consultInfo']
}> = ({ consultInfo }) => {
  const { t } = useTranslation()
  const isWeb = Platform.OS === 'web'
  const [{ PIMSIntegration }] = useOrganisation()
  const { instanceUrl } = useOrganisationHook()
  const integration = PIMSIntegration === 'EZYVET' ? 'ezyVet' : ''

  return (
    <View style={[styles.clinicalRecord, styles.spaceLine]}>
      <Text>
        {t(
          integration
            ? 'patientPanel:cards.sheetDetails.list.clinicalRecord'
            : 'patientPanel:cards.sheetDetails.list.clinicalRecordWithoutIntegration',
          {
            integration,
          },
        )}
      </Text>
      <View>
        <TouchableOpacity
          onPress={() => navToHyperlink(instanceUrl, consultInfo.id)}
          style={styles.clinicalRecordTextButton}
        >
          <Text
            style={[
              styles.text,
              styles.hyperLinkText,
              !isWeb && styles.hyperLinkTextMobile,
            ]}
            testID="test-clinical-record"
          >
            {consultInfo.reference}
          </Text>
          <SvgHyperLinkOut width={14} height={14} />
        </TouchableOpacity>
      </View>
    </View>
  )
}

const DetailItem: FunctionComponent<{
  label: string
  value: string
  testID?: string
}> = ({ label, value, testID }) => (
  <Text style={styles.spaceLine}>
    <Text style={styles.label}>{label}</Text>
    <Text style={styles.text} testID={testID}>
      {value}
    </Text>
  </Text>
)

const ListItem: FunctionComponent<{
  label: string
  items: Array<string | null>
  tooltip?: string
}> = ({ label, items, tooltip }) => (
  <View style={styles.listItem}>
    <View style={styles.listItemHeader}>
      <Text style={styles.label}>{label}</Text>
      {tooltip ? <HelpTooltip text={tooltip} size={16} /> : null}
    </View>
    {items.map(
      (item, index) =>
        !!item && (
          <Text
            style={[styles.text, styles.listItemText]}
            key={`${item}-${index}`}
          >
            {item}
          </Text>
        ),
    )}
  </View>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  spaceLine: {
    marginBottom: 8,
  },
  text: {
    fontSize: 14,
    fontFamily: Fonts.bold,
  },
  label: {
    fontSize: 14,
    fontFamily: Fonts.regular,
  },
  clinicalRecord: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  clinicalRecordTextButton: {
    flexDirection: 'row',
  },
  hyperLinkText: {
    color: Colors.blue,
    textDecorationLine: 'underline',
    marginRight: 3,
  },
  hyperLinkTextMobile: {
    marginLeft: 3,
  },
  listItem: {
    marginBottom: 8,
  },
  listItemHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  listItemText: {},
})
