import { SvgArrow, SvgHamburger } from 'components/Icons'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { Colors, Typography } from 'src/design-system/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, View, StyleSheet, Text } from 'react-native'
import { ProcedureProduct } from './utils/ProcedureUtil'

type Props = {
  item: ProcedureProduct
  onPress: () => void
}

export const ProcedureItem: React.FC<Props> = ({ item, onPress }) => {
  const { t } = useTranslation()

  const renderScheduleInfo = () => {
    return (
      // TODO - Add schedule data once GetProcedureTreatment is implemented
      <Text style={styles.noScheduleText}>
        {t('template:treatment.noTaskSchedule')}
      </Text>
    )
  }

  return (
    <TouchableOpacity onPress={onPress} style={styles.container}>
      <View style={styles.nameContainer}>
        <View style={styles.nameRow}>
          <SvgHamburger />

          <TextWithTooltip
            style={styles.title}
            numberOfLines={1}
            title={item.name}
          >
            {item.name}
          </TextWithTooltip>
        </View>
        <View style={styles.separatorRow}>
          <View style={styles.separator} />
        </View>

        <View style={styles.scheduleRow}>
          <View style={styles.scheduleInfo}>{renderScheduleInfo()}</View>
          <View style={styles.icon}>
            <SvgArrow orient="right" color="grey" width={15} height={15} />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 4,
    borderBottomWidth: 1,
    borderBottomColor: Colors.Borders.primary,
    backgroundColor: Colors.Backgrounds.UI,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nameContainer: { flexDirection: 'column' },
  icon: {
    padding: 16,
    maxWidth: '12%',
  },
  title: {
    ...Typography.Label.M,
    fontSize: 16,
    flex: 1.5,
  },
  nameRow: {
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
    paddingLeft: 10,
  },
  separatorRow: {
    paddingLeft: 35,
    paddingRight: 10,
  },
  separator: {
    borderTopWidth: 1,
    borderTopColor: '#F4F4F6',
  },
  scheduleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 46,
  },
  scheduleInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  noScheduleText: {
    ...Typography.Label.M,
    color: Colors.Interactive.disabledTertiary,
  },
})
