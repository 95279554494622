import React from 'react'
import {
  Pressable,
  StyleProp,
  Text,
  TextStyle,
  ViewStyle,
  StyleSheet,
} from 'react-native'
import { Colors, Typography, Variables } from 'src/design-system/theme'

type ButtonProps = {
  title: string
  disabled?: boolean
  onPress: () => void
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
}

export const Button: React.FC<ButtonProps> = ({
  title,
  disabled,
  onPress,
  style,
  textStyle,
}) => {
  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      style={[
        styles.button,
        style,
        disabled && {
          opacity: 0.36,
        },
      ]}
    >
      <Text style={[styles.buttonText, textStyle]}>{title}</Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 4,
    paddingVertical: Variables.Size.md,
    backgroundColor: Colors.Backgrounds.accentPrimary,
    width: 155,
  },
  buttonText: {
    textAlign: 'center',
    color: Colors.Contents.onColor,
    fontWeight: Typography.FontWeights.bold,
    fontSize: Typography.FontSizes.base,
    lineHeight: 16,
  },
})
