import React from 'react'
import MenuRaw from '@material-ui/core/Menu'
import MenuItemRaw from '@material-ui/core/MenuItem'
import { Colors } from 'constants/Colors'
import { Platform } from 'react-native'
import { withCreateElement } from 'src/hocs/withCreateElement'
import { withPermission } from 'src/hocs/withPermission'
import { PermissionAction } from 'src/types/globalTypes'

const Menu = withCreateElement(MenuRaw)
const PermissionMenuItem = withCreateElement(withPermission(MenuItemRaw))

export enum Actions {
  DELETE = 'Delete',
  CLEAR = 'Clear',
  EXCLUDE = 'Exclude',
  DUPLICATE = 'Duplicate',
  RENAME = 'Rename',
}

export type ActionT = {
  // We also name as index for colour, then it couldn't be i18n.  (Maybe we use a type instead of name to determine the colour)
  name: Actions
  onAction: () => void
  disabled?: boolean
  permissionAction?: PermissionAction | null
}

export type Props = {
  actions: ActionT[]
  anchorEl: null | HTMLElement
  children: React.ReactNode
  closeMenu: () => void
  isActionable: boolean
}

export const ActionableListItem: React.FC<Props> = ({
  actions,
  anchorEl,
  children,
  closeMenu,
  isActionable,
}) => {
  return (
    <>
      {children}
      {Platform.OS === 'web' && isActionable ? (
        <Menu
          anchorEl={anchorEl}
          keepMounted={true}
          onClose={closeMenu}
          open={!!anchorEl}
          testID={'More actions menu'}
          PaperProps={{
            style: {
              width: '300px',
            },
          }}
        >
          {actions.length > 0 &&
            actions.map((action, idx) => {
              return (
                <PermissionMenuItem
                  permissionAction={action.permissionAction ?? null}
                  // TODO: Decouple Permission from ActionableListItem after ActionableListItem is decoupled from src/components/shared/Input/index.tsx
                  key={idx}
                  onClick={action.onAction}
                  disabled={action.disabled}
                  style={{
                    backgroundColor:
                      action.name === Actions.DELETE ||
                      action.name === Actions.CLEAR
                        ? Colors.errorBackground
                        : '',
                    color:
                      action.name === Actions.DELETE ||
                      action.name === Actions.CLEAR
                        ? Colors.white
                        : '',
                  }}
                >
                  {action.name}
                </PermissionMenuItem>
              )
            })}
        </Menu>
      ) : null}
    </>
  )
}
