import { FieldMergeFunction, FieldReadFunction } from '@apollo/client'
import {
  listWhiteboardPatients_listWhiteboardPatients_items as WhiteboardPatientItems,
  listWhiteboardPatients_listWhiteboardPatients as WhiteboardPatients,
} from 'types/listWhiteboardPatients'
import {
  listWhiteboardWorkflows_listWhiteboardWorkflows_items as WorkflowPatientItems,
  listWhiteboardWorkflows_listWhiteboardWorkflows as WorkflowPatients,
} from 'types/listWhiteboardWorkflows'
import { flatten, groupBy, uniqBy, values } from 'lodash'
import { aggregatePatientItems } from '../utils/aggregatePatientItems'

type ResultCursorField = { read: FieldReadFunction<string | undefined> }

export const resultCursor: ResultCursorField = {
  read(existing) {
    return existing ?? ''
  },
}

const setWhiteboardPatientCursorField = (
  patientsWithWorkflowAndPageInfo: WhiteboardPatientItems[] | null,
  cursor = '',
) => {
  return (
    patientsWithWorkflowAndPageInfo?.map(patientInfo => ({
      ...patientInfo,
      resultCursor: cursor,
    })) ?? []
  )
}

const setWorkflowPatientCursorField = (
  patientsWithWorkflowAndPageInfo: WorkflowPatientItems[] | null,
  cursor = '',
) => {
  return (
    patientsWithWorkflowAndPageInfo?.map(patientInfo => ({
      ...patientInfo,
      resultCursor: cursor,
    })) ?? []
  )
}

// Common merge for both listWhiteboardPatients and  listWhiteboardWorkflows
// This merging handles how we both 'upsert' patient list items.
// So the next incoming section of patient items doesn't overwrite entire cache
export const whiteboardPatientItemsMerge: FieldMergeFunction<
  WhiteboardPatients
> =
  // TODO: improve FieldMergeFunction TS typing
  (existing, incoming, { readField, variables }) => {
    const resultCursor = variables?.input.pageInput.cursor

    const existingItems = groupBy(existing?.items ?? [], 'resultCursor')
    const incomingItems = incoming.items

    const incomingWithCursor = setWhiteboardPatientCursorField(
      incomingItems,
      resultCursor,
    )

    const incomingGroupedItems = groupBy(incomingWithCursor, 'resultCursor')

    /* Replace existing patient item hash pages with incoming ones, or append.
       This will capture page item change like a patient who is discharged.
       Flatten will conveniently maintain list order */

    const patientItems = aggregatePatientItems(
      existingItems,
      incomingGroupedItems,
      readField,
    )

    return {
      ...incoming,
      items: patientItems,
    }
  }

export const workflowPatientItemsMerge: FieldMergeFunction<WorkflowPatients> = (
  existing,
  incoming,
  { readField, variables },
) => {
  const resultCursor = variables?.input.pageInput.cursor

  const existingItems = groupBy(existing?.items, 'resultCursor')
  const incomingItems = incoming.items

  const incomingWithCursor = setWorkflowPatientCursorField(
    incomingItems,
    resultCursor,
  )

  const incomingGrouped = groupBy(incomingWithCursor, 'resultCursor')
  const patientItemsFlatten = flatten(
    values({ ...existingItems, ...incomingGrouped }),
  )

  // uniqBy will choose the first object as they appear in the array
  const patientItems = uniqBy(patientItemsFlatten, item =>
    readField('patient_id', item.patient as any),
  )

  return {
    ...incoming,
    items: patientItems,
  }
}
