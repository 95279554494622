import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import { useConfirm } from 'src/context/confirm'
import {
  updateSheet as UpdateSheet,
  updateSheetVariables as UpdateSheetVariables,
} from 'src/types/updateSheet'

import { UPDATE_SHEET } from './graphql'

type Props = {
  sheetId: string
  sheetName: string
  consultId: string
  message: string
}

export const useFinalizeSheet = ({
  sheetId,
  consultId,
  message,
  sheetName,
}: Props) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()

  const [updateSheet, { loading }] = useMutation<
    UpdateSheet,
    UpdateSheetVariables
  >(UPDATE_SHEET, {
    onCompleted: () => toast.success('Sheet Finalized'),
  })

  const confirm = useConfirm()

  const finalizeSheet = useCallback(
    () =>
      confirm({
        title: t('sheet:finalize.title', {
          sheetName,
        }),
        text: message,
        highlights: {
          content: [`${sheetName} sheet`],
        },
      })
        .then(() => {
          const input = {
            id: sheetId,
            organisation_id: organisationId,
            closed_at: new Date().toISOString(),
            consultation_id: consultId,
          }
          return updateSheet({ variables: { input } })
        })
        .catch(err => {
          if (err) {
            toast.error(err.message)
          }
          throw err
        }),
    [
      confirm,
      consultId,
      message,
      organisationId,
      sheetId,
      sheetName,
      t,
      updateSheet,
    ],
  )

  return {
    finalizeSheet,
    loading,
  }
}
