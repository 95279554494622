import React from 'react'
import { Field } from '../Field'
import { Select as CommonSelect } from 'components/common/Select/Select'
import {
  Props as CommonSelectProps,
  PossibleSelectValues,
} from 'components/common/Select/Select.types'

type Props<T extends PossibleSelectValues> = Omit<
  CommonSelectProps<T>,
  'FieldComponent'
>

export const Select = <T extends PossibleSelectValues>(props: Props<T>) => (
  <CommonSelect {...props} FieldComponent={Field} />
)
