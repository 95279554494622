import React from 'react'
import { Fonts } from 'constants/Fonts'
import { StyleSheet, Text, View } from 'react-native'

import {
  getStatusMessage,
  getStatusStyle,
  getStatusTextStyle,
  InputStatus,
} from '../utils'

type Props = {
  children: React.ReactNode
  status?: InputStatus | null
}

export const Status: React.FC<Props> = ({ children, status }) => {
  const statusStyle = getStatusStyle(status)
  const statusTextStyle = getStatusTextStyle(status)
  const statusMessage = getStatusMessage(status)

  return (
    <>
      <View>
        {children}
        {!!statusStyle && (
          <View
            accessibilityLabel={`Input Status Color for : ${statusMessage}`}
            style={[styles.statusBox, statusStyle]}
          />
        )}
      </View>
      {!!statusMessage && (
        <Text
          accessibilityLabel={`Input Status Message: ${statusMessage}`}
          style={[styles.msg, statusTextStyle]}
        >
          {statusMessage}
        </Text>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  msg: {
    fontFamily: Fonts.regular,
    marginTop: 5,
    marginBottom: 9,
    fontSize: 13,
    lineHeight: 19,
    paddingHorizontal: 16,
  },
  statusBox: {
    backgroundColor: 'transparent',
    left: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 8,
  },
})
