import React from 'react'
import { StyleSheet } from 'react-native'
import { Primitive } from 'src/design-system/theme/colors/base'
import {
  FontFamilies,
  FontSizes,
} from 'src/design-system/theme/typography/base'
import { Pill } from '../Pill'
import { DataSyncLogStatus } from 'src/types/globalTypes'

type StatusType = {
  status: string
}

enum STATUSSHOW {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAILED = 'Failed',
}

export const DataSyncStatus: React.FC<StatusType> = ({ status }) => {
  switch (status) {
    case DataSyncLogStatus.PENDING:
      return (
        <Pill
          size={'medium'}
          color={Primitive.orange100}
          style={[styles.backgroundStyle, styles.pendingSuccessStatus]}
          textStyle={[styles.textStyle, styles.pendingText]}
          text={STATUSSHOW.PENDING}
        />
      )
    case DataSyncLogStatus.SUCCESS:
      return (
        <Pill
          size={'medium'}
          color={Primitive.green100}
          style={[styles.backgroundStyle, styles.pendingSuccessStatus]}
          textStyle={[styles.textStyle, styles.successText]}
          text={STATUSSHOW.SUCCESS}
        />
      )
    case DataSyncLogStatus.FAILED:
      return (
        <Pill
          size={'medium'}
          color={Primitive.red100}
          style={[styles.backgroundStyle, styles.failedStatus]}
          textStyle={[styles.textStyle, styles.failedText]}
          text={STATUSSHOW.FAILED}
        />
      )
  }
  return null
}

const styles = StyleSheet.create({
  backgroundStyle: {
    height: 24,
    paddingTop: 2,
    paddingRight: 6,
    paddingBottom: 2,
    paddingLeft: 6,
    borderRadius: 180,
  },
  textStyle: {
    fontSize: FontSizes.base,
    fontFamily: FontFamilies.bold,
  },
  pendingSuccessStatus: {
    width: 70,
  },
  pendingText: {
    color: Primitive.orange900,
  },
  successText: {
    color: Primitive.green900,
  },
  failedStatus: {
    width: 60,
  },
  failedText: {
    color: Primitive.red900,
  },
})
