import { useApolloClient, useMutation } from '@apollo/client'
import { TASK_FIELDS_FULL } from 'components/Task/graphql'
import { toast } from 'src/components/common/Toast/ToastArea'
import {
  bulkDeleteTasks as BulkDeleteTasks,
  bulkDeleteTasksVariables as BulkDeleteTasksVariables,
} from 'src/types/bulkDeleteTasks'
import { ApprovalStatus, DeleteTaskInput } from 'src/types/globalTypes'
import { TaskFieldsFull } from 'src/types/TaskFieldsFull'

import { BULK_DELETE_TASKS } from '../graphql'
import { useApprovals } from 'src/hooks/useApprovals'
import { TreatmentBaseWithTasks } from 'types/TreatmentBaseWithTasks'
import { useTimeResolution } from 'src/hocs/timeContext'
import { buildTreatmentWithTasksFragmentVariable } from 'components/Treatment/utils/buildTreatmentWithTasksFragmentVariable'

export const useBulkDeleteTasks = () => {
  const [bulkDeleteTasks] = useMutation<
    BulkDeleteTasks,
    BulkDeleteTasksVariables
  >(BULK_DELETE_TASKS, {
    onError: err => {
      toast.error('Error Bulk Delete Tasks')
      console.error(err) // eslint-disable-line no-console
    },
  })
  const client = useApolloClient()
  const { shouldUnapproveTreatment } = useApprovals()
  const { fromToQueryDate } = useTimeResolution()

  const updateTreatmentCache = (
    deleteTasksInput: DeleteTaskInput[],
    isSuccessful: boolean,
  ): void => {
    if (!isSuccessful) return

    deleteTasksInput.forEach(task => {
      const fragmentVariable = buildTreatmentWithTasksFragmentVariable(
        task.treatment_id,
        fromToQueryDate,
      )

      const cachedTreatment =
        client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

      if (!cachedTreatment) return

      if (shouldUnapproveTreatment(cachedTreatment)) {
        client.writeFragment({
          ...fragmentVariable,
          data: {
            ...cachedTreatment,
            approval_status: ApprovalStatus.PENDING,
          },
        })
      }
    })
  }

  const updateCache = (
    deleteTasksInput: DeleteTaskInput[],
    clientData: { _pending: boolean },
  ) => {
    deleteTasksInput.forEach(task => {
      if (!clientData._pending) {
        client.cache.evict({ id: `Task:${task.id}` })
        return
      }
      // optimistic update
      const cachedTask = client.readFragment<TaskFieldsFull>({
        id: `Task:${task.id}`,
        fragment: TASK_FIELDS_FULL,
        fragmentName: 'TaskFieldsFull',
      })

      if (!cachedTask) return

      client.writeFragment({
        id: `Task:${task.id}`,
        fragment: TASK_FIELDS_FULL,
        fragmentName: 'TaskFieldsFull',
        data: {
          ...cachedTask,
          ...clientData,
        },
      })
    })
  }

  return (deleteTasksInput: DeleteTaskInput[]) => {
    bulkDeleteTasks({
      variables: {
        input: deleteTasksInput,
      },
      optimisticResponse: {
        bulkDeleteTasks: false,
      },

      update: (_, { data }) => {
        const isFromPlatform = !!data?.bulkDeleteTasks
        // false from optimisticResponse. will add ... to the tasks
        updateCache(deleteTasksInput, {
          _pending: !isFromPlatform,
        })

        updateTreatmentCache(deleteTasksInput, isFromPlatform)
      },
    })
  }
}
