import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { MAGIC_ANESTHESIA_TEMPLATE_NAME } from 'components/CreateSheet/useGetAnesthesiaTemplates'
import { sortByName } from 'components/Template/utils/sortByName'
import { TemplateUseCase } from 'components/TreatmentTemplate/TemplateForm'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { keyBy, noop, toLower } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { useFlags } from 'react-native-flagsmith/react'
import { GET_ACTIVE_TREATMENT_TEMPLATES } from 'src/components/TreatmentTemplate/graphql'
import { useOrganisation } from 'src/context/organisation'
import { Colors, Typography } from 'src/design-system/theme'
import {
  getActiveTemplates,
  getActiveTemplatesVariables,
} from 'src/types/getActiveTemplates'
import {
  getProductGroups as GetProductGroups,
  getProductGroups_getOrganisation_products_items as GroupType,
  getProductGroupsVariables,
} from 'src/types/getProductGroups'
import { ProductType } from 'src/types/globalTypes'
import {
  searchProducts,
  searchProductsVariables,
} from 'src/types/searchProducts'
import { searchInList } from 'src/utils/searchInList'

import { DuplicateTemplateHash } from './AddTemplateDrawerContent'
import { GET_PRODUCT_GROUPS, SEARCH_PRODUCTS } from './graphql'
import { Product, ProductRow } from './ProductRow'
import { BILLING_ITEMS, BUNDLE_FILTER, Group, SearchBar } from './SearchBar'
import { TagItem } from 'components/common/ProductRow/types'

// TODO: Everything related to templates are temporary solutions...
type Props = {
  onSelect: (product: Product) => void
  noTemplate?: boolean
  isQuickAdd?: boolean
  groupName?: ProductGroupName
  productType?: ProductType
  isIVInfusion?: boolean
  siteIds?: string[]
  isEditingTemplateEnabled?: boolean
  duplicateTemplateHashFilter?: DuplicateTemplateHash
  isAnesthesia?: boolean
  shouldShowBundleOverride?: boolean
}

export enum ProductGroupName {
  All = 'All',
  Medications = 'Medications',
  Fluids = 'Fluids',
  Template = 'Template',
}

const findProductGroupId = (
  groups: GroupType[],
  groupName: ProductGroupName,
) => {
  if (!groups) return ''
  return groups.find(group => group.name === groupName)?.id
}

type Filter = {
  key: string
  value: string
}

export const generateSearchFilters = (
  id: string | undefined,
  productType: string | undefined,
) => {
  const filters: Filter[] = []
  if (productType) {
    // find the product by type
    filters.push({ key: 'type', value: productType.toLowerCase() })
  }
  if (!id) return filters
  if (id === BILLING_ITEMS) {
    // BILLING_ITEMS is hard code to use id as a string to find billable products
    filters.push({ key: 'is_billable', value: 'true' })
    return filters
  }
  if (id === BUNDLE_FILTER) {
    // Bundles is hard coded to use id as a string to find bundle products
    filters.push({ key: 'is_bundle', value: 'true' })
    return filters
  }
  filters.push({ key: 'parent_product_id', value: id })
  return filters
}

export const SingleProductPicker: React.FC<Props> = ({
  onSelect,
  productType,
  noTemplate = false,
  isQuickAdd = false,
  groupName = ProductGroupName.All,
  isIVInfusion = false,
  siteIds,
  isEditingTemplateEnabled = false,
  duplicateTemplateHashFilter,
  isAnesthesia,
  shouldShowBundleOverride = false,
}) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [groupId, setGroupId] = useState<string | undefined>()
  const [{ organisationId }] = useOrganisation()

  const flags = useFlags([
    FeatureFlagNames.IS_SEARCH_PRODUCT_FROM_RDS,
    FeatureFlagNames.ENABLE_PROCEDURE_UI,
    FeatureFlagNames.ENABLE_BUNDLE_UI,
  ])

  const isProcedureFlagEnabled = flags.enable_procedure_ui.enabled
  const shouldShowBundle =
    shouldShowBundleOverride && flags.enable_bundle_ui.enabled

  const { loading: isGroupLoading, data: groupData } = useQuery<
    GetProductGroups,
    getProductGroupsVariables
  >(GET_PRODUCT_GROUPS, {
    variables: { id: organisationId },
  })

  const groups = useMemo(
    () => groupData?.getOrganisation?.products?.items ?? [],
    [groupData?.getOrganisation?.products?.items],
  )

  // TODO: Do cache eviction when leaving this screen? (API: https://github.com/apollographql/apollo-client/pull/5310)
  const [searchProduct, { loading: searchingProduct, data }] = useLazyQuery<
    searchProducts,
    searchProductsVariables
  >(SEARCH_PRODUCTS)

  const [loadTemplates, { loading: loadingTemplates, data: templatesData }] =
    useLazyQuery<getActiveTemplates, getActiveTemplatesVariables>(
      GET_ACTIVE_TREATMENT_TEMPLATES,
      {
        fetchPolicy: 'cache-and-network',
        variables: {
          organisation_id: organisationId,
          site_ids: siteIds ?? [],
        },
      },
    )

  // Run empty query on mount to get 'All' products
  useEffect(() => {
    if (groupName !== ProductGroupName.Template) {
      onChange('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName])

  // Run query to get 'medication' products if isOnlyMedication props
  useEffect(() => {
    if (groupName === ProductGroupName.All) {
      return
    }

    if (groupName === ProductGroupName.Template) {
      setGroupId(ProductGroupName.Template)
      onChange('', ProductGroupName.Template)
      return
    }

    const visibleGroupsId = findProductGroupId(groups, groupName)
    if (visibleGroupsId) {
      setGroupId(visibleGroupsId)
      onChange('', visibleGroupsId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, groupName])

  const templates = useMemo(
    () =>
      sortByName(templatesData?.getTemplates?.items ?? []).filter(template => {
        if (isAnesthesia && template.use_case !== TemplateUseCase.ANAESTHESIA)
          return false
        return (
          !duplicateTemplateHashFilter?.[template.id] &&
          !MAGIC_ANESTHESIA_TEMPLATE_NAME.test(template.name)
        )
      }),
    [
      duplicateTemplateHashFilter,
      isAnesthesia,
      templatesData?.getTemplates?.items,
    ],
  )

  const loading = searchingProduct || loadingTemplates

  const onChange = useCallback(
    // TODO: consider using https://github.com/helfer/apollo-link-debounce
    // Wait till input ended before sending to avoid a barrage of requests
    //
    (text: string, id?: string) => {
      setSearchText(text)
      setGroupId(id)

      if (id !== 'Template') {
        const filters = generateSearchFilters(id, productType)

        searchProduct({
          variables: {
            input: {
              organisation_id: organisationId,
              q: toLower(text),
              filters,
              is_from_rds: flags.is_search_product_from_rds.enabled,
              options: {
                site_ids: siteIds ?? [],
                should_show_bundle: shouldShowBundle,
              },
            },
          },
        })
        return
      }

      if (text === searchText) {
        // Only do front end search for template, so no need to load again here.
        loadTemplates()
      }
    },
    [
      searchText,
      productType,
      searchProduct,
      organisationId,
      flags.is_search_product_from_rds.enabled,
      siteIds,
      loadTemplates,
      shouldShowBundle,
    ],
  )
  const groupsById = useMemo(() => keyBy(groups, 'id'), [groups])

  const products = useMemo(() => {
    // TODO: use templates group section with ES
    if (groupId === 'Template') {
      return searchInList(templates, 'name', searchText).map(template => ({
        ...template,
        group: {
          name: t('addTreatment:template'),
          id: 'Template',
        },
      }))
    }
    // This will search ES in all other sub-sections
    const searchProductsData = data?.searchProducts ?? []
    // TODO: filter out Product Groups that appear in 'all' with ES
    return searchProductsData
      .filter(product => !noTemplate || product?.type !== 'template')
      .filter(product => product?.item?.parent_product_id !== '*')
      .map(product => {
        const {
          id,
          name,
          type,
          code,
          is_bundle: isBundle,
          is_billable: isBillable,
          parent_product_id: parentGroupId,
        } = product.item

        let group: Group
        let tags: TagItem[] | undefined
        let productCode: string | null = code

        if (product.type === 'template') {
          group = {
            id: 'Template',
            name: t('addTreatment:template'),
            order: null,
          }
          tags = [
            {
              title: t('addTreatment:template'),
              color: Colors.Backgrounds.overlay,
              textColor: Colors.Contents.primary,
            },
          ]
        } else {
          group =
            parentGroupId && groupsById[parentGroupId]
              ? groupsById[parentGroupId]
              : { id: '', name: '', order: null }

          if (shouldShowBundle && isBundle) {
            tags = [
              {
                title: t('addTreatment:bundle'),
                color: Colors.Backgrounds.accentSecondary,
                textColor: Colors.Contents.accentOnColor,
              },
            ]
            productCode = null
          }

          if (isProcedureFlagEnabled && product.type === 'procedure') {
            group.id = 'Procedure'
            tags = [
              {
                title: t('addTreatment:procedure'),
                color: Colors.Backgrounds.overlay,
                textColor: Colors.Contents.primary,
              },
            ]
            productCode = null
          }
        }

        return {
          group,
          id,
          isBillable,
          isBundle,
          name,
          tags,
          type,
          code: productCode,
        }
      })
  }, [
    groupId,
    data?.searchProducts,
    templates,
    searchText,
    t,
    noTemplate,
    groupsById,
    shouldShowBundle,
    isProcedureFlagEnabled,
  ])

  const hasNoSearchResult =
    searchText.length > 0 && products.length === 0 && !searchingProduct

  const selectedFilterGroupName =
    groups.find(group => group.id === groupId)?.name ??
    (groupId === 'Template' ? 'Template' : 'All')

  const renderProductRow = useCallback(
    (item: Product) => {
      const isMedicationOrFluids =
        item.type === ProductType.IVFLUIDS ||
        item.type === ProductType.MEDICATION

      const isTemplateSelectedAndEnabled =
        item.group.id === 'Template' && isEditingTemplateEnabled

      const isProcedure = item.group.id === 'Procedure'

      // if it is Anesthesia, normal treatment, not template
      // flag is on, keep the quick add button
      const isQuickAddIcon =
        isQuickAdd &&
        !isMedicationOrFluids &&
        !isTemplateSelectedAndEnabled &&
        !item.isBundle

      // noop for now, will be used for add procedure to sheet
      const onClick = isProcedure ? noop : () => onSelect(item)
      return (
        <ProductRow
          a11yLabel={t('addTreatment:selectProduct', {
            name: item.name,
          })}
          product={item}
          tags={item.tags}
          searchWords={searchText.split(/\s+/)}
          onClick={onClick}
          isIVInfusion={isIVInfusion}
          isQuickAddIcon={isQuickAddIcon}
        />
      )
    },
    [
      isEditingTemplateEnabled,
      isQuickAdd,
      isIVInfusion,
      searchText,
      t,
      onSelect,
    ],
  )

  return (
    <View style={styles.container}>
      {isGroupLoading ? (
        <ActivityIndicator
          accessibilityLabel="Product Picker Product Groups Loading Indicator"
          size="large"
          style={styles.spinner}
        />
      ) : (
        <View style={styles.container}>
          <SearchBar
            shouldShowGroupNames={
              // if have a type, do not show group names. Can open in the future
              groupName === ProductGroupName.All && !productType
            }
            groups={groups}
            searchText={searchText}
            groupId={groupId}
            onChange={onChange}
            noTemplate={noTemplate}
            isIVInfusion={isIVInfusion}
          />
          {loading ? (
            <ActivityIndicator
              accessibilityLabel="Product Picker Loading Indicator"
              size="large"
              style={styles.spinner}
            />
          ) : (
            <>
              {hasNoSearchResult ? (
                <Text style={styles.noResultText}>
                  {t('createTreatment.notFound', {
                    groupName: selectedFilterGroupName,
                  })}
                </Text>
              ) : null}
              {!!products.length && (
                <FlatList
                  data={products as Product[]}
                  style={styles.flatList}
                  showsHorizontalScrollIndicator={false}
                  keyExtractor={item => item.id}
                  testID="Products List"
                  renderItem={({ item }) => renderProductRow(item)}
                />
              )}
            </>
          )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  noResultText: {
    textAlign: 'center',
    marginHorizontal: 60,
    marginVertical: 20,
    fontFamily: Typography.FontFamilies.base,
    fontSize: Typography.FontSizes.md,
    color: Colors.Contents.secondary,
  },
  spinner: {
    marginTop: 25,
  },
  flatList: {
    overflow: 'scroll',
  },
})
