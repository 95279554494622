import React, { useCallback, useState } from 'react'
import { TextInput, TextInputProps } from 'react-native'
import {
  FieldValues,
  FieldPath,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import { EditableTextContainer, styles } from './'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> &
  Omit<TextInputProps, 'value' | 'onChangeText'> & {
    label?: string
    disabled?: boolean
    onChangeListener?: (value: string) => void
  }

export const ControlledEditableText = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  defaultValue,
  onChangeListener,
  ...restProps
}: Props<TFieldValues, TName>) => {
  const [isEditing, setIsEditing] = useState(false)
  const onTextInputBlur = useCallback(() => setIsEditing(false), [])
  const onTextPress = useCallback(() => setIsEditing(true), [])

  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
    ...(defaultValue !== undefined && { defaultValue }),
  })

  const editingTextInput = (
    <TextInput
      {...restProps}
      autoFocus={true}
      editable={isEditing}
      value={value}
      onBlur={onTextInputBlur}
      onChangeText={newValue => {
        onChange(newValue)
        onChangeListener?.(newValue)
      }}
      style={[styles.editingInput, styles.textShared]}
    />
  )

  return (
    <EditableTextContainer
      value={value}
      isEditing={isEditing}
      onTextPress={onTextPress}
      editingTextInput={editingTextInput}
    />
  )
}
