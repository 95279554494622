import React, { useCallback } from 'react'
import { SvgCheck } from 'components/Icons'
import { Colors } from 'constants/Colors'
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'

type Props = {
  onChange: (checked: boolean) => void
  checked: boolean
  disabled?: boolean
  disabledStyle?: ViewStyle
  style?: StyleProp<ViewStyle>
}

export const CheckButton: React.FC<Props> = React.memo(
  ({ onChange, checked, disabled = false, disabledStyle, style }) => {
    const onPress = useCallback(() => {
      onChange(!checked)
    }, [checked, onChange])
    return (
      <TouchableOpacity
        testID="CheckButton"
        disabled={disabled}
        style={styles.container}
        onPress={onPress}
      >
        <View
          style={[
            styles.textContainer,
            checked && styles.checked,
            disabled && (disabledStyle || styles.disabled),
            style,
          ]}
        >
          {checked ? (
            <SvgCheck color={Colors.contentSecondary} length={12} />
          ) : null}
        </View>
      </TouchableOpacity>
    )
  },
)

CheckButton.displayName = 'CheckButton'

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
  textContainer: {
    borderWidth: 1,
    borderColor: Colors.shared.borderGrey,
    height: 22,
    width: 22,
    borderRadius: 11,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
  },
  checked: { borderColor: Colors.contentSecondary },
  disabled: { backgroundColor: Colors.lightGrey },
})
