import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { PatientTag } from 'components/PatientItem/PatientItemComponents/PatientTag'
import { WeightAgeTextV2 } from 'components/PatientItem/PatientItemComponents/WeightAgeTextV2'
import { getPatientSexTagTitle } from 'components/PatientItem/utils/getPatientSex'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { getPatient_getPatient as GetPatientData } from 'types/getPatient'
import { Fonts } from 'constants/Fonts'
import { LINE_HEIGHT, MOBILE_LINE_HEIGHT } from './common'
import { getWidth } from 'src/utils/getWidth'

const MAX_WEIGHTAGE_WIDTH_PERCENTAGE_WITHOUT_TAG = 0.7
const MAX_WEIGHTAGE_WIDTH_PERCENTAGE_WITH_TAG = 0.5

type Props = {
  patient: GetPatientData
  primaryInfoWidth: number
  isPatientPanelSplitLayout?: boolean
}

export const PatientWeightAgeTag = ({
  patient,
  primaryInfoWidth,
  isPatientPanelSplitLayout,
}: Props) => {
  const { isLargeScreen } = useBreakpoint()
  const sexTagTitle = getPatientSexTagTitle(patient)
  const [patientInfoWidth, setPatientInfoWidth] = useState<number>(0)
  const maxWeightAgeWidthPercentage = !!patient?.resuscitate
    ? MAX_WEIGHTAGE_WIDTH_PERCENTAGE_WITH_TAG
    : MAX_WEIGHTAGE_WIDTH_PERCENTAGE_WITHOUT_TAG
  const patientInfoWidthPercentage = patientInfoWidth / primaryInfoWidth
  const isWeightAgeWidthOversize =
    isLargeScreen &&
    !!patient?.critical_notes?.length &&
    isPatientPanelSplitLayout &&
    patientInfoWidthPercentage > maxWeightAgeWidthPercentage

  return (
    <>
      <View
        onLayout={event => {
          if (patientInfoWidth) return
          setPatientInfoWidth(getWidth(event))
        }}
      >
        <WeightAgeTextV2
          patientWeight={`${patient.weight ?? '-'} `}
          patientWeightUnit={patient.weight_unit ?? ''}
          dateOfBirth={patient.date_of_birth}
          sexTagTitle={sexTagTitle}
          isWeightAgeWidthOversize={isWeightAgeWidthOversize}
          patientInfoWidthPercentage={patientInfoWidthPercentage}
        />
      </View>
      {patient.resuscitate ? (
        <PatientTag
          patient={patient}
          useV2={true}
          style={isLargeScreen ? styles.headerTag : styles.mobileHeaderTag}
          textStyle={
            isLargeScreen ? styles.headerTagText : styles.mobileHeaderTagText
          }
        />
      ) : null}
    </>
  )
}

const styles = StyleSheet.create({
  headerTag: {
    lineHeight: LINE_HEIGHT,
  },
  headerTagText: {
    fontFamily: Fonts.semibold,
    fontSize: 12,
  },
  mobileHeaderTag: {
    lineHeight: MOBILE_LINE_HEIGHT,
    height: 18,
  },
  mobileHeaderTagText: {
    fontSize: 12,
    lineHeight: MOBILE_LINE_HEIGHT,
  },
})
