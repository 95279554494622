import React from 'react'
import { useQuery } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import { usePatientById } from 'src/hooks/usePatient'
import { useTranslation } from 'react-i18next'
import { GET_PRODUCT_DETAILS } from 'components/AddTreatment/graphql'
import { Inputs as MedicineDosageInfo } from 'components/TreatmentForm/types'
import {
  getOneProduct as GetOneProduct,
  getOneProductVariables as GetOneProductVariables,
} from 'src/types/getOneProduct'

import { TreatmentForm, FormData } from 'src/components/TreatmentForm'

export type DosageInfo = FormData

type Props = {
  patientId: string
  productId: string
  dosageInfo?: DosageInfo
  onSetup: (dosageInfo: DosageInfo) => void
  handleCreateSheetModal?: (
    shouldBeVisible: boolean,
    newDosageInfo?: MedicineDosageInfo,
  ) => void
}

export const DosageSetup: React.FC<Props> = ({
  patientId,
  productId,
  onSetup,
  dosageInfo,
  handleCreateSheetModal,
}) => {
  const { t } = useTranslation()
  const [{ organisationId, hasPIMSIntegration }] = useOrganisation()
  const { data } = useQuery<GetOneProduct, GetOneProductVariables>(
    GET_PRODUCT_DETAILS,
    {
      variables: {
        id: productId,
        organisation_id: organisationId,
      },
    },
  )
  const patient = usePatientById(patientId)

  const product = data?.getProduct
  if (!product || !patient) {
    return null
  }

  return (
    <TreatmentForm
      defaultOverrides={dosageInfo}
      patient={patient}
      product={product}
      onSave={onSetup}
      submitTitle={t('addTreatment:addToSheet')}
      isNewSheet={true}
      showTreatmentName={true}
      handleCreateSheetModal={handleCreateSheetModal}
      hasPIMSIntegration={!!hasPIMSIntegration}
    />
  )
}
