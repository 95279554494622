const Size = {
  xs: 7,
  sm: 8,
  base: 10,
  md: 12,
}

const BorderWidths = {
  sm: 0.5,
  base: 1,
  md: 2,
}

// The space between modules or components
const GutterSpacing = {
  sm: Size.xs, // 7
  base: Size.sm, // 8
  md: Size.sm * 2, // 16
  lg: Size.sm * 3, // 24
  xl: Size.sm * 4, // 32
}

export const Variables = {
  Size,
  BorderWidths,
  GutterSpacing,
}
