import { useCallback, useMemo, useState } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useConfirm } from 'src/context/confirm'
import { Actions } from 'components/common/MultiActions'

type IMenuItemOption = {
  name: Actions
  onClick: () => void
  confirmationOption?: {
    title: string
    text: string
  }
  disabled?: boolean
}

export const useOpenMenu = (menuItems: IMenuItemOption[]) => {
  const confirm = useConfirm()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const openMenu = useCallback((event: GestureResponderEvent) => {
    event.preventDefault()
    const target = event.currentTarget
    requestAnimationFrame(() => setAnchorEl(target as any))
  }, [])

  const actions = useMemo(
    () =>
      menuItems.map(menuItem => {
        const onAction = async () => {
          setAnchorEl(null)
          if (menuItem.confirmationOption) {
            try {
              await confirm(menuItem.confirmationOption)
            } catch (err) {
              return
            }
          }
          menuItem.onClick()
        }
        return {
          onAction,
          name: menuItem.name,
          disabled: menuItem.disabled ?? false,
        }
      }),
    [confirm, menuItems],
  )
  const handleCloseTemplateMenu = useCallback(() => setAnchorEl(null), [])

  return { anchorEl, openMenu, actions, handleCloseTemplateMenu }
}
