import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  color: string
}

export const SvgWhiteboard: React.FC<Props> = ({ color }) => (
  <Svg height={18} width={20} viewBox="0 0 18 15">
    <Path
      fill={color}
      d="m16.3636364 0h-14.72727276c-.9 0-1.63636364.73636364-1.63636364 1.63636364v9.81818186c0 .9.73636364 1.6363636 1.63636364 1.6363636h4.09090909v1.6363636h6.54545457v-1.6363636h4.0909091c.9 0 1.6281818-.7363636 1.6281818-1.6363636l.0081818-9.81818186c0-.9-.7363636-1.63636364-1.6363636-1.63636364zm0 11.4545455h-14.72727276v-9.81818186h14.72727276z"
    />
  </Svg>
)
