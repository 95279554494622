import { find, isNil } from 'lodash'
import {
  getSheet_getSheet_treatments_items as TreatmentGroup,
  getSheet_getSheet_treatments_items_treatments_items as Treatment,
} from 'src/types/getSheet'

import { AnesthesiaState } from '../anesthesia'
import {
  AnesthesiaPreset,
  getAnesthesiaEntryInfo,
} from './anesthesiaChartEntryInfo'
import { mapTreatmentToChartData } from './mapTreatmentToChartData'
import { AnesthesiaChartData, AnesthesiaChartEntry } from '../types/anesthesia'
import { DayRange } from 'src/hocs/timeContext'
import { getChartHighestValue } from 'components/Anesthesia/AnesthesiaChart/utils/getHighestChartValue'
import { calcChartValuesRange } from 'components/Anesthesia/AnesthesiaChart/utils/calcChartValuesRange'

type EntryInfo = AnesthesiaPreset
export const mapPresetToAnesthesiaChartEntry = (
  entryInfo: EntryInfo,
  anesthesiaTreatments: Treatment[],
): AnesthesiaChartData => {
  return entryInfo
    .map(entry => {
      const matchingTreatment = find(anesthesiaTreatments, [
        'name',
        entry.full_title,
      ])
      const matchingTreatmentData = matchingTreatment
        ? mapTreatmentToChartData(matchingTreatment)
        : []

      const chartEntry: AnesthesiaChartEntry = {
        ...entry,
        data: matchingTreatmentData,
        order:
          matchingTreatment && !isNil(matchingTreatment.order)
            ? matchingTreatment.order
            : entry.order,
        matching_treatment_id: matchingTreatment?.id,
        latest_value: !!matchingTreatmentData.length
          ? matchingTreatmentData[matchingTreatmentData.length - 1].value
          : undefined,
      }
      return chartEntry
    })
    .sort((entryA, entryB) => entryA.order - entryB.order)
}

type CreateAnesthesiaInitialState = (
  anesthesiaTreatmentGroup: TreatmentGroup | undefined,
  temperatureUnit: string,
  anesthesiaChartRange: number[],
  visibleDayRange: DayRange,
  defaultMaxChartValue: number,
) => AnesthesiaState

export const createAnesthesiaState: CreateAnesthesiaInitialState = (
  anesthesiaTreatmentGroup,
  temperatureUnit,
  anesthesiaChartRange,
  visibleDayRange,
  defaultMaxChartValue,
) => {
  if (!anesthesiaTreatmentGroup?.treatments?.items)
    return { anesthesiaData: null, anesthesiaChartRange: [] }

  const anesthesiaTreatments = anesthesiaTreatmentGroup?.treatments?.items
  const anesthesiaData = mapPresetToAnesthesiaChartEntry(
    getAnesthesiaEntryInfo(temperatureUnit),
    anesthesiaTreatments,
  )
  const highestValue = getChartHighestValue(anesthesiaData, visibleDayRange)

  const chartValuesRange = calcChartValuesRange(
    highestValue,
    defaultMaxChartValue,
    anesthesiaChartRange,
  )

  const anesthesiaState: AnesthesiaState = {
    anesthesiaData,
    anesthesiaChartRange: chartValuesRange,
  }

  return anesthesiaState
}
