import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { SEARCH_PRODUCTS } from 'components/AddTreatment/graphql'
import { Button, TextLink } from 'components/common'
import { DraggableList } from 'components/common/DraggableList'
import { SearchInput } from 'components/common/SearchInput'
import { SvgHamburger } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { isNil, keyBy, toLower } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import {
  searchProducts,
  searchProducts_searchProducts,
  searchProductsVariables,
} from 'types/searchProducts'

import { TreatmentOption } from '../TemplateTreatmentForm'
import { ProductRow } from './ProductRow'
import { SelectedProductRow } from './SelectedProductRow'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { useFlags } from 'react-native-flagsmith/react'

const SELECTED_SHOW_MORE_LIMIT = 3

export type Group = {
  id: string
  name: string
}

type Props = {
  groups: Group[]
  setTreatmentOptions: (treatmentOptions: TreatmentOption[]) => void
  treatmentOptions: TreatmentOption[]
  onSelect: () => void
  loading?: boolean
  isIVInfusion?: boolean
  siteIds?: string[]
}

export const MultiProductPicker: React.FC<Props> = ({
  groups,
  setTreatmentOptions,
  treatmentOptions,
  onSelect,
  loading = false,
  isIVInfusion = false,
  siteIds,
}) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [{ organisationId }] = useOrganisation()

  const flags = useFlags([FeatureFlagNames.IS_SEARCH_PRODUCT_FROM_RDS])

  const addTreatmentOption = (option: TreatmentOption) =>
    setTreatmentOptions([...treatmentOptions, option])

  const removeTreatmentOption = (id: string) => {
    setTreatmentOptions(
      treatmentOptions.filter(treatmentOption => id !== treatmentOption.id),
    )
  }

  const treatmentOptionsKeyValue = useMemo(() => {
    const result: { [key: string]: boolean } = {}
    treatmentOptions.forEach(treatmentOption => {
      // true for iv infusion old children(disabled & added), false for new added products(added), undefined for other products
      result[treatmentOption.id] = treatmentOption.isOldChild ?? false
    })
    return result
  }, [treatmentOptions])

  // TODO: Do cache eviction when leaving this screen? (API: https://github.com/apollographql/apollo-client/pull/5310)
  const [searchProduct, { loading: searching, data }] = useLazyQuery<
    searchProducts,
    searchProductsVariables
  >(SEARCH_PRODUCTS)

  // Run empty query on mount to get 'All' products
  useEffect(() => {
    onChange('')
    setTreatmentOptions(treatmentOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const groupsById = keyBy(groups, 'id')
  // TODO: use templates group section with ES

  // This will search ES in all other sub-sections
  const searchProductsData = data?.searchProducts ?? []

  const filterProducts = (product: searchProducts_searchProducts) =>
    isIVInfusion
      ? product.type !== 'template' &&
        product.item.type !== 'GROUP' &&
        product.item.type !== 'MEDICATION' &&
        product.item.type !== 'IVFLUIDS'
      : product.type !== 'template' && product.item.type !== 'GROUP'

  const products: TreatmentOption[] = searchProductsData
    .filter(filterProducts)
    .map(product => {
      const {
        id,
        name,
        code,
        type,
        parent_product_id: parentGroupId,
        is_billable: isBillable,
      } = product.item
      const group = groupsById[parentGroupId!]

      const oldChildKeyValue = treatmentOptionsKeyValue[id]
        ? { isOldChild: treatmentOptionsKeyValue[id] }
        : {}

      return {
        id,
        name,
        type,
        code,
        groupName: group?.name ?? '',
        isBillable,
        ...oldChildKeyValue,
      }
    })

  const hasNoSearchResult = !!searchText && products.length === 0 && !searching

  const onChange = (text: string) => {
    setSearchText(text)
    searchProduct({
      variables: {
        input: {
          organisation_id: organisationId,
          q: toLower(text),
          is_from_rds: flags.is_search_product_from_rds.enabled,
          options: {
            site_ids: siteIds ?? [],
          },
        },
      },
    })
  }

  const [showMore, setShowMore] = useState(false)

  const renderListHeader = () => (
    <Text style={styles.selectedTitle}>
      {t('template:treatment.selectedTreatment')}
    </Text>
  )

  const renderShowMoreViewListFooter = () => {
    return (
      <>
        <TextLink
          text={`${t('general.showLess')} / Add treatment`}
          onPress={() => setShowMore(!showMore)}
          containerStyles={styles.textLink}
        />
        <Button
          style={styles.doneButton}
          title={t('general.saveChanges')}
          color={Colors.green}
          testID={'ProductPickDoneBtn'}
          onPress={onSelect}
        />
      </>
    )
  }

  const renderShowMoreView = () => {
    return (
      <>
        {renderListHeader()}
        <DraggableList
          ListFooterComponent={renderShowMoreViewListFooter()}
          items={treatmentOptions}
          renderItem={({ item, drag }) => (
            <View style={styles.dragItemContainer}>
              <TouchableOpacity style={styles.dragBtn} onPressIn={drag}>
                <SvgHamburger />
              </TouchableOpacity>
              <View style={{ flex: 1 }}>
                <SelectedProductRow
                  product={item}
                  key={item.id}
                  removeTreatmentOption={removeTreatmentOption}
                />
              </View>
            </View>
          )}
          onMoveEnd={setTreatmentOptions}
        />
      </>
    )
  }

  const renderShowLessView = () => {
    const extraTreatments = treatmentOptions.length - SELECTED_SHOW_MORE_LIMIT
    const showMoreText = `${t(
      'general.showMore',
    )} (${extraTreatments}) / Reorder`
    return (
      <View style={styles.container}>
        {renderListHeader()}

        {!isIVInfusion && (
          <>
            {treatmentOptions.slice(0, SELECTED_SHOW_MORE_LIMIT).map(item => (
              <SelectedProductRow
                product={item}
                key={item.id}
                removeTreatmentOption={removeTreatmentOption}
              />
            ))}

            {extraTreatments > 0 && (
              <TextLink
                text={showMoreText}
                onPress={() => setShowMore(!showMore)}
                containerStyles={styles.textLink}
              />
            )}
            <View
              testID="SelectedProductRowSeperator"
              style={styles.separator}
            />
          </>
        )}

        <SearchInput
          testID="SearchBarInput"
          style={styles.searchInput}
          onChangeText={onChange}
          wait={500}
        />

        {searching ? (
          <ActivityIndicator style={styles.spinner} size="large" />
        ) : (
          <>
            {hasNoSearchResult ? (
              <Text style={styles.noResultText}>
                {t('createTreatment.notFoundNoFilter')}
              </Text>
            ) : null}
            {!!products.length && (
              <FlatList
                data={products}
                keyExtractor={item => item.id}
                renderItem={({ item }) => (
                  <ProductRow
                    product={item}
                    a11yLabel={`Select Product ${item.name}`}
                    isIVInfusion={isIVInfusion}
                    searchWords={searchText.split(/\s+/)}
                    addTreatmentOption={addTreatmentOption}
                    removeTreatmentOption={removeTreatmentOption}
                    isAdded={!isNil(treatmentOptionsKeyValue[item.id])}
                  />
                )}
              />
            )}
          </>
        )}
        {!isIVInfusion && (
          <Button
            style={styles.doneButton}
            title={t('general.saveChanges')}
            color={Colors.green}
            testID={'ProductPickDoneBtn'}
            onPress={onSelect}
            loading={loading}
          />
        )}
      </View>
    )
  }

  return showMore ? renderShowMoreView() : renderShowLessView()
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  noResultText: {
    textAlign: 'center',
    marginHorizontal: 60,
    marginVertical: 20,
    fontFamily: Fonts.regular,
    fontSize: 15,
    color: '#3d3d3d',
  },
  spinner: {
    marginTop: 25,
  },
  doneButton: {
    marginVertical: 15,
  },
  searchInput: {
    marginBottom: 16,
    paddingHorizontal: 16,
  },
  selectedTitle: {
    paddingLeft: 16,
    paddingBottom: 8,
    fontFamily: Fonts.regular,
    fontSize: 20,
  },
  separator: {
    backgroundColor: Colors.shared.borderGrey,
    height: 1,
    marginVertical: 20,
  },
  textLink: {
    alignItems: 'flex-end',
    paddingHorizontal: 16,
    paddingTop: 5,
  },
  dragItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dragBtn: {
    paddingHorizontal: 12,
  },
})
