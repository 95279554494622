import Constants from 'expo-constants'
import { Platform } from 'react-native'

// Acceptable string values for app.config.json release stages
export enum ReleaseStage {
  production = 'production',
  staging = 'staging',
  master = 'master',
  localDev = 'local-dev',
  none = '',
}

export const nonLocalStages = [
  ReleaseStage.master,
  ReleaseStage.staging,
  ReleaseStage.production,
]

export type ManifestExtra = {
  appcuesURL?: string
  walkmeURL?: string
  buildTime?: string
  datadog?: {
    clientToken: string
    rumAppIos: string
    rumAppWeb: string
  }
  environment?: ReleaseStage
  expoUpdateChannel?: string
  flagsmith?: string
  gitHash?: string
  sentry?: {
    dsn: string
    dsnWeb: string
    releaseChannelWeb: string
  }
}

// We only get NODE_ENV from process.env with expo, everything else comes from app.config.json.
const nodeEnv = process.env.NODE_ENV ?? ''

const isDev = nodeEnv === 'development'
const isTest = nodeEnv === 'test'
const isProduction = !(isDev || isTest)
const isWeb = Platform.OS === 'web'

const manifest = Constants.expoConfig

// nb: Changing manifest.extra requires you to restart the Expo Packager `npm run start:expo`
const appVersion =
  // @ts-ignore
  manifest?.extra?.expoClient?.version ?? manifest?.version ?? ''

const manifestClientInfo =
  // @ts-ignore
  manifest?.extra?.expoClient ?? manifest ?? {}

const manifestExtra: ManifestExtra =
  // @ts-ignore
  manifest?.extra?.expoClient?.extra ?? manifest?.extra ?? {}

const slug =
  // @ts-ignore
  Constants.expoConfig?.extra?.expoClient?.slug ??
  Constants.expoConfig?.slug ??
  ''

const {
  datadog,
  expoUpdateChannel,
  flagsmith,
  gitHash,
  appcuesURL,
  walkmeURL,
  sentry,
} = manifestExtra

const releaseStage: ReleaseStage =
  isDev || isTest
    ? ReleaseStage.localDev
    : manifestExtra.environment && manifestExtra?.environment in ReleaseStage
    ? manifestExtra.environment
    : ReleaseStage.none

export const environment = Object.freeze({
  appVersion,
  datadog,
  expoUpdateChannel,
  flagsmith,
  isDev,
  isProduction,
  isTest,
  isWeb,
  manifest,
  manifestClientInfo,
  manifestExtra,
  nodeEnv,
  releaseStage,
  slug,
  gitHash: gitHash?.substring(0, 9) ?? '',
  appcuesURL: appcuesURL ?? '',
  walkmeURL: walkmeURL ?? '',
  sentryDsn: sentry?.dsn ?? '',
  sentryDsnWeb: sentry?.dsnWeb ?? '',
  sentryReleaseChannelWeb: sentry?.releaseChannelWeb ?? '',
})
