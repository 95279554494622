import { gql } from '@apollo/client'

export const GET_DEPARTMENTS = gql`
  query getDepartments($organisationId: ID!) {
    getSites(organisation_id: $organisationId) {
      items {
        id
        name
        type
        parent_site_id
        cubex_cabinet_id
      }
    }
  }
`

export const GET_LOCATION_TREE = gql`
  query getLocationTree($organisationId: ID!) {
    getLocations(organisation_id: $organisationId) {
      items {
        id
        name
        locations {
          items {
            id
            name
          }
        }
      }
    }
  }
`
