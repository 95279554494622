import { gql } from 'graphql-tag'

export const SHEET_META_DATA_FIELDS = gql`
  fragment SheetMetaDataFields on Sheet {
    type_meta_data {
      asa
      anaesthesia {
        circuit_check
        catheter_site
        owner_consent
        comments
        tube_size
        str_tube_size
        complications
      }
      timers {
        name
        elapsed
        start_at
      }
    }
  }
`

export const SHEET_HEADER_FIELDS = gql`
  fragment SheetHeaderFields on Sheet {
    attending_vet
    attending_vet_tech
    attending_department
    additional_care_team
    additional_care_team_names
    closed_at
    deleted_at
    consultation_id
    created_at
    approval_status
    id
    name
    type
    ...SheetMetaDataFields
  }
  ${SHEET_META_DATA_FIELDS}
`
