import React from 'react'
import { Text, StyleSheet, TextProps } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'

interface WarningProps extends React.PropsWithChildren<TextProps> {}

export const Warning: React.FC<WarningProps> = ({
  children,
  style,
  ...rest
}) => {
  if (!children) return null
  return (
    <Text style={[styles.warning, style]} {...rest}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  warning: {
    color: Colors.Contents.warning,
    fontFamily: Typography.FontFamilies.italic,
    fontSize: Typography.FontSizes.base,
  },
})
