import { useApolloClient, useMutation } from '@apollo/client'
import { TREATMENT_FIELDS_BASE } from 'components/AddTreatment/graphql'
import { cloneFluidTreatmentUpdateValue } from 'components/EditTreatment/utils/updateFluidTreatmentUtils'
import { pick } from 'lodash'
import { toast } from 'src/components/common/Toast/ToastArea'
import { useRoundingPrecision } from 'src/hooks/useRound'
import { Status } from 'src/types/globalTypes'
import {
  resetTask as ResetTask,
  resetTaskVariables as ResetTaskVariables,
} from 'src/types/resetTask'
import { TreatmentFieldsBase } from 'src/types/TreatmentFieldsBase'
import { SentryExpo } from 'src/utils/errorTracking/sentry/common'

import { RESET_TASK } from '../graphql'
import { Task } from '../types'

export const useResetTask = () => {
  const [resetTaskMutation, { loading }] = useMutation<
    ResetTask,
    ResetTaskVariables
  >(RESET_TASK, {
    onCompleted: data => {
      const message = 'Completed Task'
      SentryExpo.addBreadcrumb({
        data,
        message,
        category: 'user_action',
        level: 'info',
      })
    },
  })
  const client = useApolloClient()
  const roundingPrecision = useRoundingPrecision()

  const resetTask = async (curTask: Task, isFluidTask: boolean = false) => {
    return resetTaskMutation({
      variables: {
        input: {
          ...pick(curTask, [
            'id',
            'organisation_id',
            'patient_id',
            'sheet_id',
            'treatment_id',
          ]),
          isFluidTask,
        },
      },
      optimisticResponse: {
        resetTask: {
          ...curTask,
          _pending: true,
        },
      },
      update: (_, { data }) => {
        const resetTask = data?.resetTask
        if (!resetTask?.value || !isFluidTask) return

        const isTaskResetFromDoneToProgress =
          curTask.status === Status.DONE &&
          resetTask.status === Status.IN_PROGRESS
        // when reset fluid task to IN_PROGRESS, update the fluid total or cri calculator
        if (!isTaskResetFromDoneToProgress) return

        const fragmentVariable = {
          fragment: TREATMENT_FIELDS_BASE,
          fragmentName: 'TreatmentFieldsBase',
          id: `Treatment:${resetTask.treatment_id}`,
        }

        const cachedTreatment =
          client.readFragment<TreatmentFieldsBase>(fragmentVariable)

        if (!cachedTreatment) {
          return
        }

        const clonedTreatment = cloneFluidTreatmentUpdateValue(
          cachedTreatment,
          resetTask.value,
          roundingPrecision,
        )
        client.writeFragment({
          ...fragmentVariable,
          data: clonedTreatment,
        })
      },
    }).catch(_ => {
      toast.error(`Error Resetting Task`)
    })
  }

  return { resetTask, loading }
}
