import { NO_TASKS, SINGLE_TASK } from 'components/FrequencySelector/data'
import { FrequencyInput } from 'components/FrequencySelector/FrequencySelector'
import { getTemplate_getTemplate_treatments_items_treatments_items_schedule } from 'src/types/getTemplate'
import { FrequencyType } from 'types/globalTypes'
import { ScheduleFields_schedule } from 'types/ScheduleFields'

type TreatmentOrTemplateTreatmentSchedule =
  | ScheduleFields_schedule
  | getTemplate_getTemplate_treatments_items_treatments_items_schedule

const getType = (
  schedule: TreatmentOrTemplateTreatmentSchedule,
): FrequencyType => {
  if (schedule.type) return schedule.type
  return FrequencyType.INTERVAL
}

const getID = (schedule: TreatmentOrTemplateTreatmentSchedule) => {
  return schedule.treatment_frequency_id ?? null
}

const getFrequencies = (
  schedule: TreatmentOrTemplateTreatmentSchedule,
): number[] | undefined => {
  if (schedule.frequencies) return schedule.frequencies
  if (schedule.frequency) return [schedule.frequency]
  return
}

export const getFrequencyInputFromSchedule = (
  schedule?: TreatmentOrTemplateTreatmentSchedule | null,
): {
  frequencyInput: FrequencyInput | null
} => {
  if (!schedule) {
    return {
      frequencyInput: NO_TASKS,
    }
  }
  if (!schedule.repeat) {
    return { frequencyInput: SINGLE_TASK }
  }
  return {
    frequencyInput: {
      type: getType(schedule),
      id: getID(schedule),
      frequencies: getFrequencies(schedule),
    },
  }
}
