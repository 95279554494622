import { useCallback, useEffect, useMemo, useState } from 'react'
import { GET_PRODUCT_DETAILS } from 'components/AddTreatment/graphql'
import { useOrganisation } from 'src/context/organisation'
import {
  getOneProduct as GetOneProduct,
  getOneProduct_getProduct as OneProduct,
  getOneProductVariables as GetOneProductVariables,
} from 'src/types/getOneProduct'
import { useClientQueryPromise } from 'src/utils/useClientQueryPromise'

import { TreatmentOption } from '../TemplateTreatmentForm'

type UseTreatmentOptionsParams = {
  setTreatmentOptions: (treatmentOptions: TreatmentOption[]) => void
}

export const useTreatmentOptions = ({
  setTreatmentOptions,
}: UseTreatmentOptionsParams) => {
  const [{ organisationId }] = useOrganisation()
  // skip some actions if we're in 'Add Template' mode (no templateId yet)

  const [foundProductHash, setFoundProductHash] = useState<{
    [key: string]: {
      data: OneProduct | TreatmentOption
      dataLoading: boolean
    }
  }>({})

  const [duplicateTreatmentOptions, setDuplicateTreatmentOptions] = useState<
    TreatmentOption[]
  >([])

  const getOneProduct = useClientQueryPromise<
    GetOneProduct,
    GetOneProductVariables
  >({ query: GET_PRODUCT_DETAILS, fetchPolicy: 'cache-first' })

  const setProductOptions = useCallback(
    (treatmentOptions: TreatmentOption[]) => {
      treatmentOptions.forEach(async treatmentOption => {
        if (!foundProductHash[treatmentOption.id]) {
          // data to prevent the same query
          setFoundProductHash(previousFoundProductHash => ({
            ...previousFoundProductHash,
            [treatmentOption.id]: {
              data: treatmentOption,
              dataLoading: true,
            },
          }))

          try {
            const result = await getOneProduct({
              organisation_id: organisationId,
              id: treatmentOption.id,
            })

            // promise run for each query to get the product info
            setFoundProductHash(previousFoundProductHash => ({
              ...previousFoundProductHash,
              [treatmentOption.id]: {
                data: result.data.getProduct,
                dataLoading: false,
              },
            }))
          } catch (_) {
            setFoundProductHash(previousFoundProductHash => ({
              ...previousFoundProductHash,
              [treatmentOption.id]: {
                data: treatmentOption,
                dataLoading: false,
              },
            }))
          }
        }
      })
      setDuplicateTreatmentOptions(treatmentOptions)
    },
    [foundProductHash, getOneProduct, organisationId],
  )

  const loading = useMemo(() => {
    // make sure all promises return
    return Object.values(foundProductHash).some(
      value => value.dataLoading === true,
    )
  }, [foundProductHash])

  useEffect(() => {
    // when user click or promise back reset the treatmentOptions
    setTreatmentOptions(
      duplicateTreatmentOptions.map(treatmentOption => ({
        ...treatmentOption,
        track_vital:
          foundProductHash[treatmentOption.id]?.data.track_vital ?? null,
        pims_mapping:
          foundProductHash[treatmentOption.id]?.data.pims_mapping ?? null,
        medicine_dosage_info:
          foundProductHash[treatmentOption.id]?.data.medicine_dosage_info ??
          null,
      })),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, duplicateTreatmentOptions, setTreatmentOptions])

  return { setProductOptions, loading }
}
