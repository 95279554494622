import React, { useCallback, useMemo, useRef } from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Colors } from 'src/design-system/theme'
import { VRBottomSheet } from 'components/BottomSheet'
import { DischargePatient } from 'components/DischargePatient/DischargePatient'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { useDischargePatient } from 'components/DischargePatient/useDischargePatient'
import { environment } from 'src/config'
import { Patient } from 'components/PatientItem/PatientListItem'
import { useTranslation } from 'react-i18next'
import { navigate } from 'components/Base/navigation/navigation'
import { Routes } from 'constants/Routes'
import { listWhiteboardPatients_listWhiteboardPatients_items_patient_consultation_locations as ConsultationLocation } from 'src/types/listWhiteboardPatients'
import { SheetActionColorPicker } from './SheetActionColorPicker'
import { SheetActionsCPRStatus } from './SheetActionCPRStatus'
import { Resuscitation } from 'src/types/globalTypes'
import { SheetActionPatientOrder } from './SheetActionPatientOrder'
import { SheetActionFinalisedSheets } from './SheetActionFinalisedSheets'

const { isWeb } = environment

type Props = {
  patient: Patient
  consultationId: string
  locations: ConsultationLocation[]
  currentColor: string | null
  handleDischarge?: () => void
  handleFinalisedSheet?: () => void
  handlePatientImage: () => void
  currentCPRStatus?: Resuscitation | null
  onClose: () => void
}

export const SheetActionContainer: React.FC<Props> = ({
  patient,
  handleDischarge,
  handleFinalisedSheet,
  handlePatientImage,
  consultationId,
  locations,
  currentColor,
  currentCPRStatus,
  onClose,
}) => {
  const { t } = useTranslation()
  const DischargeBottomSheetRef = useRef<BottomSheetModal>(null)
  const viewFinalisedSheetsRef = useRef<BottomSheetModal>(null)

  const { dischargePatient } = useDischargePatient(patient.id, () => {
    DischargeBottomSheetRef.current?.dismiss()
    setTimeout(() => onClose(), 600)
  })

  // Remap function to get ConsultationLocation type to match ConsultationLocationInput
  const consultationLocation = locations.map(loc => ({
    id: loc.key,
    display: loc.display,
    enclosure: loc.enclosure,
  }))

  const handleDischargePatient = useCallback(() => {
    if (environment.isWeb) {
      if (onClose) {
        onClose()
      }
      if (handleDischarge) {
        handleDischarge()
      }
    } else {
      DischargeBottomSheetRef.current?.present()
    }
  }, [onClose, handleDischarge])

  const handleViewFinalisedSheets = useCallback(() => {
    if (isWeb) {
      onClose()
      if (handleFinalisedSheet) handleFinalisedSheet()
    } else {
      viewFinalisedSheetsRef.current?.present()
    }
  }, [handleFinalisedSheet, onClose])

  const handleDismissBottomSheet = useCallback(() => {
    onClose()
    DischargeBottomSheetRef.current?.dismiss()
    viewFinalisedSheetsRef.current?.dismiss()
  }, [onClose])

  const patientImageLabel = useMemo(
    () => () =>
      patient.avatar_url
        ? 'patient:action.patientImage.label'
        : 'patient:action.patientImage.noImageLabel',
    [patient.avatar_url],
  )

  return (
    <View>
      {patient.isActive ? (
        <SheetActionColorPicker
          patientId={patient.id}
          consultationId={consultationId}
          locations={consultationLocation}
          currentColor={currentColor}
        />
      ) : null}
      <SheetActionsCPRStatus
        patientId={patient.id}
        currentCPRStatus={currentCPRStatus}
      />
      <SheetActionPatientOrder
        patientId={patient.id}
        currentPatientOrder={patient.order}
      />

      <>
        <View style={styles.divider} />
        {patient.isActive ? (
          <Pressable
            accessibilityLabel="Create new sheet"
            style={styles.sheetActionButton}
            onPress={() => {
              onClose()
              navigate(Routes.AddSheet, { patientId: patient.id })
            }}
          >
            <Text>Create new sheet</Text>
          </Pressable>
        ) : null}
        <Pressable
          style={styles.sheetActionButton}
          accessibilityLabel={t(patientImageLabel())}
          onPress={() => {
            onClose()
            handlePatientImage()
          }}
        >
          <Text>{t(patientImageLabel())}</Text>
        </Pressable>
        <ViewFinalisedSheets onPress={handleViewFinalisedSheets} />
        {patient.isActive ? (
          <Pressable
            style={styles.sheetActionButton}
            accessibilityLabel={t('discharge:label')}
            onPress={handleDischargePatient}
          >
            <Text style={styles.dischargeText}>Discharge patient</Text>
          </Pressable>
        ) : null}
      </>
      <VRBottomSheet
        title={t('discharge:label')}
        ref={DischargeBottomSheetRef}
        handleClose={handleDismissBottomSheet}
      >
        <DischargePatient
          showTitle={false}
          patientId={patient.id}
          onDischarge={dischargePatient}
        />
      </VRBottomSheet>
      <VRBottomSheet
        title={t(`patient:action.finalisedSheet.label`)}
        ref={viewFinalisedSheetsRef}
        handleClose={handleDismissBottomSheet}
      >
        <SheetActionFinalisedSheets
          onClose={handleDismissBottomSheet}
          patientId={patient.id}
        />
      </VRBottomSheet>
    </View>
  )
}

const ViewFinalisedSheets: React.FC<{ onPress: () => void }> = ({
  onPress,
}) => {
  const { t } = useTranslation()
  return (
    <Pressable
      accessibilityLabel={t(`patient:action.finalisedSheet.label`)}
      style={styles.sheetActionButton}
      onPress={onPress}
    >
      <Text>{t(`patient:action.finalisedSheet.show`)}</Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  sheetActionButton: {
    padding: 16,
    color: Colors.Contents.primary,
    borderBottomColor: Colors.Borders.primary,
    borderBottomWidth: 1,
    height: 61,
    justifyContent: 'center',
  },
  dischargeText: {
    color: Colors.Contents.negative,
  },
  divider: {
    height: 10,
    borderWidth: 1,
    borderColor: Colors.Borders.primary,
    backgroundColor: Colors.Backgrounds.tertiary,
  },
})
