import React from 'react'
import {
  createElement as createElementWeb,
  unstable_createElement,
} from 'react-native-web'

const createElement = createElementWeb || unstable_createElement

export const withCreateElement = <T extends object>(
  BasicComponent: React.ComponentType<T>,
  overwriteProps = {} as object,
) => {
  class WithCreateElement extends React.Component<T & { testID?: string }> {
    render() {
      return createElement(BasicComponent, {
        ...this.props,
        ...overwriteProps,
      })
    }
  }
  return WithCreateElement
}
