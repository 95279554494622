import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { Ref, StopClock, StopClockData } from 'components/common/StopClock'
import { StyleSheet, View } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import {
  updateSheet as UpdateSheet,
  updateSheetVariables as UpdateSheetVariables,
} from 'src/types/updateSheet'
import { getSheet_getSheet_type_meta_data_timers as Timer } from 'types/getSheet'

import { SheetPatientInfoFields } from 'types/SheetPatientInfoFields'
import { UPDATE_SHEET } from 'components/Sheet/graphql'
import { Variables } from 'src/design-system/theme'

type Props = {
  a11yLabel?: string
  sheetPatientInfo: SheetPatientInfoFields
}
type ArrayRef = Ref | null

export const MAXIMUM_TIMER_ELAPSED = 216000 - 1

export const Timers: React.FC<Props> = ({ a11yLabel, sheetPatientInfo }) => {
  const [{ organisationId }] = useOrganisation()
  const clockRef = useRef<ArrayRef[]>([])
  const [updateSheet] = useMutation<UpdateSheet, UpdateSheetVariables>(
    UPDATE_SHEET,
  )

  const [isUpdating, setIsUpdating] = useState(false)
  const updateSheetWithTimer = useCallback(
    (timers: Timer[]) => {
      const input = {
        id: sheetPatientInfo.id,
        organisation_id: organisationId,
        consultation_id: sheetPatientInfo.consultation_id!,
        type_meta_data: {
          ...(sheetPatientInfo.type_meta_data?.asa && {
            asa: sheetPatientInfo.type_meta_data?.asa,
          }),
          timers,
          anaesthesia: sheetPatientInfo.type_meta_data?.anaesthesia,
        },
      }
      setIsUpdating(true)
      return updateSheet({ variables: { input } })
        .catch(error => {
          toast.error(error.message)
          throw error
        })
        .finally(() => {
          setIsUpdating(false)
        })
    },
    [
      organisationId,
      sheetPatientInfo.consultation_id,
      sheetPatientInfo.id,
      sheetPatientInfo.type_meta_data?.anaesthesia,
      sheetPatientInfo.type_meta_data?.asa,
      updateSheet,
    ],
  )

  const onToggle = (data: StopClockData, idx: number) => {
    const timers = [...(sheetPatientInfo?.type_meta_data?.timers ?? [])]
    timers[idx] = {
      ...timers[idx],
      elapsed: Math.min(data.elapsed, MAXIMUM_TIMER_ELAPSED),
      start_at: data.startAt?.toISOString() ?? null,
    }
    return updateSheetWithTimer(timers)
  }

  useEffect(() => {
    if (sheetPatientInfo.closed_at && !isUpdating) {
      const timers = [...(sheetPatientInfo?.type_meta_data?.timers ?? [])]

      // check any timer is running
      const shouldUpdateTimer = timers.reduce(
        (acc, timer) => !!timer.start_at || acc,
        false,
      )

      if (shouldUpdateTimer) {
        const newTimers = timers.map((timer, index) =>
          timer.start_at
            ? {
                ...timer,
                elapsed: Math.min(
                  clockRef.current[index]!.exportElapsedInternal,
                  MAXIMUM_TIMER_ELAPSED,
                ),
                start_at: null,
              }
            : timer,
        )
        updateSheetWithTimer(newTimers)
      }
    }
  }, [
    isUpdating,
    sheetPatientInfo.closed_at,
    sheetPatientInfo?.type_meta_data?.timers,
    updateSheetWithTimer,
  ])

  return (
    <View style={styles.container} accessibilityLabel={a11yLabel}>
      {sheetPatientInfo.type_meta_data?.timers?.map((timer, idx) => {
        const isFinalizedAndTimerStopped =
          !!sheetPatientInfo?.closed_at && !timer.start_at
        return (
          <StopClock
            ref={el => (clockRef.current[idx] = el)}
            key={`${idx}${timer.elapsed}${timer.start_at}`}
            title={timer.name}
            elapsed={timer.elapsed ?? 0}
            startAt={timer.start_at ? new Date(timer?.start_at) : null}
            onToggle={data => onToggle(data, idx)}
            disabled={isFinalizedAndTimerStopped || isUpdating}
          />
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: Variables.GutterSpacing.base,
  },
})
