import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color: string
  size: number
  orient: keyof typeof orientToDegree
}

export const orientToDegree = {
  left: 0,
  up: 90,
  right: 180,
  down: 270,
}

export const SvgChevron: React.FC<Props> = ({ color, size, orient }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 21" testID={'svg-chevron'}>
      <G fill={color} transform={`rotate(${orientToDegree[orient]}, 9 10.5)`}>
        <Path
          d="M5.36614 9.61748C4.87795 10.1056 4.87795 10.8983 5.36614 11.3864L12.8647 18.8839C13.3529 19.372 14.1457 19.372 14.6339 18.8839C15.122 18.3958 15.122 17.6031 14.6339 17.115L8.01797 10.5L14.63 3.88503C15.1181 3.39691 15.1181 2.60421 14.63 2.11609C14.1418 1.62797 13.349 1.62797 12.8608 2.11609L5.36223 9.61358L5.36614 9.61748Z"
          fill={color}
        />
      </G>
    </Svg>
  )
}
