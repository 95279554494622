import { getProduct_getProduct } from 'src/types/getProduct'
import { getSheet_getSheet_treatments_items_treatments_items_product as Product } from 'src/types/getSheet'
import { ProductType } from 'src/types/globalTypes'

export const getProductIsHealthStatusWeight = (product?: Product | null) =>
  !!product?.pims_mapping?.find(
    pims => pims.dest === 'healthstatus' && pims.field === 'weight',
  )

export const getProductIsHealthStatusWithField = (
  product?: getProduct_getProduct | Product | null,
) =>
  !!product?.pims_mapping?.find(
    pims => pims.dest === 'healthstatus' && !!pims.field,
  )

export const getProductIsGeneralType = (
  product?: Pick<Product, 'type'> | null,
) => {
  // nb: be sure to update this if we ever add more product types that have
  // complicated dosage forms
  return !(
    product?.type === ProductType.MEDICATION ||
    product?.type === ProductType.IVFLUIDS
  )
}
