import {
  getProcedure,
  getProcedure_getProcedure_sites,
  getProcedure_getProcedure_treatments as ProcedureTreatments,
} from 'src/types/getProcedure'

export type ProcedureProduct = {
  id: string
  name: string
  sheetGroup: string
  isBundle: boolean
  source?: string
  sites?: []
  treatments: Treatments
}

type Treatments = {
  items: ProcedureProduct[]
}

export type Procedure = {
  id?: string
  name?: string
  workflowId?: string | null
  useCase?: string | null
  products?: ProcedureProduct[]
  sites?: (getProcedure_getProcedure_sites | null)[] | null
}

// Recursively find all bottom most (currently up to 3 levels) products in a procedure
export const flattenTreatments = (
  currentLevelItem: ProcedureProduct,
  flattenedProcedureTreatments: ProcedureProduct[],
) => {
  if (currentLevelItem.isBundle) {
    currentLevelItem.treatments.items.map(item => {
      flattenTreatments(item, flattenedProcedureTreatments)
    })
  } else {
    flattenedProcedureTreatments.push(currentLevelItem)
  }
}

// Group procedure treatments by sheet group
export const groupProcedureTreatments = (product: ProcedureProduct) => {
  const flattenedTreatments: ProcedureProduct[] = []
  const groupedProductsMap: any = []

  product.treatments.items.map(item => {
    flattenTreatments(item, flattenedTreatments)
  })

  flattenedTreatments.map((item: ProcedureProduct) => {
    if (!groupedProductsMap[item.sheetGroup]) {
      groupedProductsMap[item.sheetGroup] = []
    }
    groupedProductsMap[item.sheetGroup].push({ ...item })
  })

  return groupedProductsMap
}

const addTreatments = (treatmentData: ProcedureTreatments) => {
  if (!treatmentData.items) return []

  const productTreatments: ProcedureProduct[] = []
  treatmentData.items?.map((item: any) =>
    productTreatments.push({
      id: item.id,
      name: item.product.name,
      sheetGroup: item.product.parent_product.name,
      isBundle: item.product.is_bundle,
      source: item.source,
      sites: item.product.sites,
      treatments: {
        items: addTreatments(!!item.treatments ? item.treatments : []),
      },
    }),
  )

  return productTreatments
}

export const formatProcedureData = (
  data: getProcedure | undefined,
): Procedure => {
  return {
    id: data?.getProcedure?.id,
    name: data?.getProcedure?.name,
    workflowId: data?.getProcedure?.template_workflow_id,
    useCase: data?.getProcedure?.use_case,
    sites: data?.getProcedure?.sites,
    products: addTreatments(data?.getProcedure?.treatments ?? ([] as any)),
  }
}
