import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { useCallback, useMemo } from 'react'
import { checkIsFluidMedication } from 'components/TreatmentForm/utils/getCalculations'
import { routesOfAdminOptions } from 'components/TreatmentForm/data'
import { useFlags } from 'react-native-flagsmith/react'
import { FeatureFlagNames } from 'constants/FeatureFlags'

export const getDefaultRouteOfAdminV1 = (
  productInfoRoute: string | null,
  productConcentrationVolumeUnit: string | null,
): string => {
  const isFluidMedication = checkIsFluidMedication(
    productConcentrationVolumeUnit,
  )
  const productRoute = routesOfAdminOptions.find(
    option => option.value === productInfoRoute,
  )?.value
  const defaultRoute = routesOfAdminOptions[isFluidMedication ? 2 : 0].value

  return productRoute ?? defaultRoute
}

export const useRouteOfAdministrations = () => {
  const { routeOfAdministrations, loading } = useOrgSettings()

  const { route_of_administration_setting } = useFlags([
    FeatureFlagNames.ROUTE_OF_ADMINISTRATION_SETTING,
  ])

  const shouldUseRouteOfAdministrationSetting =
    route_of_administration_setting.enabled

  const activeRouteOfAdministrations = useMemo(() => {
    return routeOfAdministrations?.filter(route => !!route.active) ?? []
  }, [routeOfAdministrations])

  const activeRouteOfAdministrationsSelectOptions = useMemo(() => {
    return activeRouteOfAdministrations.map(route => ({
      text: `${route.abbreviation} (${route.name})`,
      value: route.value,
    }))
  }, [activeRouteOfAdministrations])

  const getDefaultRouteOfAdmin = useCallback(
    (
      productInfoRoute: string | null,
      productConcentrationVolumeUnit: string | null,
    ) => {
      if (!shouldUseRouteOfAdministrationSetting) {
        return getDefaultRouteOfAdminV1(
          productInfoRoute,
          productConcentrationVolumeUnit,
        )
      }

      if (productInfoRoute) return productInfoRoute

      if (checkIsFluidMedication(productConcentrationVolumeUnit)) {
        return routesOfAdminOptions[2].value
      }

      return routesOfAdminOptions[0].value
    },
    [shouldUseRouteOfAdministrationSetting],
  )

  return {
    routeOfAdministrations,
    getDefaultRouteOfAdmin,
    activeRouteOfAdministrations,
    activeRouteOfAdministrationsSelectOptions,
    shouldUseRouteOfAdministrationSetting,
    loading,
  }
}
