import { useApolloClient, useMutation } from '@apollo/client'
import { TREATMENT_BASE_WITH_TASKS } from 'components/AddTreatment/graphql'
import { cloneFluidTreatmentUpdateValue } from 'components/EditTreatment/utils/updateFluidTreatmentUtils'
import { cloneDeep } from 'lodash'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { useRoundingPrecision } from 'src/hooks/useRound'
import { TreatmentBaseWithTasks } from 'src/types/TreatmentBaseWithTasks'
import { getOptimisticId } from 'src/utils/optimisticId'
import {
  ApprovalStatus,
  CreateTaskInput,
  Status,
  TreatmentTaskType,
} from 'types/globalTypes'

import { toast } from '../../common'
import { CREATE_TASK } from '../graphql'
import {
  CreatedTask,
  CreateTask,
  CreateTaskVariables,
  TaskFields,
} from '../types'
import { getIsFluidStyleTaskDisplay } from './getTreatmentInfo'
import { useApprovals } from 'src/hooks/useApprovals'

export type TaskOmitTypename = Omit<TaskFields, '__typename' | '_pending'>
export type NewSchedule = { start_at: string; stop_at: string }

export const generateTaskInput = (
  task: CreateTaskInput,
  organisationId: string,
  newSchedule?: NewSchedule,
) => ({
  buffer_due: task.buffer_due,
  buffer_missed: task.buffer_missed,
  organisation_id: organisationId,
  patient_id: task.patient_id,
  sheet_id: task.sheet_id,
  treatment_id: task.treatment_id,
  highlight_colour: task.highlight_colour,
  ...(task.qty_billed && { qty_billed: task.qty_billed }),
  ...(task.given_start_at && { given_start_at: task.given_start_at }),
  ...(task.value && { value: task.value }),
  ...(task.unit && { unit: task.unit }),
  ...(task.status && { status: task.status }),
  ...(task.assigned_user && { assigned_user: task.assigned_user }),
  ...(task.send_to_adaptor && { send_to_adaptor: task.send_to_adaptor }),
  ...(task.notes && { notes: task.notes }),
  ...(!!newSchedule
    ? newSchedule
    : { start_at: task.start_at, stop_at: task.stop_at }),
})

export const useCreateTask = () => {
  const [{ organisationId }] = useOrganisation()
  const { fromToQueryDate } = useTimeResolution()
  const [createTask] = useMutation<CreateTask, CreateTaskVariables>(
    CREATE_TASK,
    {
      onError: err => {
        console.error(err) // eslint-disable-line no-console
        toast.error(err.message)
      },
    },
  )
  const client = useApolloClient()
  const roundingPrecision = useRoundingPrecision()
  const { shouldUnapproveTreatment } = useApprovals()

  return (task: CreateTaskInput, newSchedule?: NewSchedule) => {
    const optimisticTask: CreatedTask = {
      id: getOptimisticId(),
      __typename: 'Task',
      _pending: null,
      value: null,
      unit: null,
      billing_reference: null,
      buffer_due: null,
      buffer_missed: null,
      given_start_at: task.given_start_at ?? '',
      given_stop_at: '',
      updated_at: '',
      user_updated_at: '',
      updated_by: '',
      created_at: '',
      attending_vet: '',
      attending_department: '',
      notes: '',
      assigned_user: '',

      medicine_dosage_info: null,
      highlight_colour: null,
      photo_urls: [],
      videos: null as any,
      send_to_adaptor: null as any,
      status: null as any,
      type: TreatmentTaskType.NORMAL,
      cubex_transaction_status: null,
      qty_billed: null,
      ...task,
    }
    const input = generateTaskInput(task, organisationId, newSchedule)
    return createTask({
      variables: {
        input,
      },
      optimisticResponse: {
        createTask: optimisticTask,
      },
      update: (_, { data }) => {
        if (!data) return

        const fragmentVariable = {
          fragment: TREATMENT_BASE_WITH_TASKS,
          fragmentName: 'TreatmentBaseWithTasks',
          id: `Treatment:${task.treatment_id}`,
          variables: fromToQueryDate,
        }

        const cachedTreatment =
          client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

        if (!cachedTreatment) {
          return
        }
        let clonedTreatment = cloneDeep(cachedTreatment)

        // update the fluid total or cri calculator when the task is creating and starting
        if (
          getIsFluidStyleTaskDisplay(cachedTreatment) &&
          task.status === Status.IN_PROGRESS &&
          task.value
        ) {
          clonedTreatment = cloneFluidTreatmentUpdateValue(
            cachedTreatment,
            task.value,
            roundingPrecision,
          )
        }

        if (clonedTreatment.tasks?.items) {
          // Ensure uniqueness as the same task might also be added from subscription
          clonedTreatment.tasks.items = clonedTreatment.tasks.items.filter(
            item => item.id !== data.createTask.id,
          )

          clonedTreatment.tasks.items.push(data.createTask)
        }

        if (shouldUnapproveTreatment(cachedTreatment)) {
          clonedTreatment.approval_status = ApprovalStatus.PENDING
        }

        client.writeFragment({
          ...fragmentVariable,
          data: clonedTreatment,
        })
      },
    })
  }
}
