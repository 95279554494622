import { Props, PossibleSelectValues, SelectOption } from './index.types'
import React from 'react'
import {
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
  Text,
  StyleSheet,
  View,
} from 'react-native'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { SvgCheck, SvgArrow } from 'src/components/Icons'
import { noop } from 'lodash'
import { getNextSelected } from './getNextSelected'

// eslint-disable-next-line
export function FilterTreeSelect<T extends PossibleSelectValues>({
  label,
  onChange = noop,
  options,
  selected,
  testID,
  loading = false,
  toggle,
  isOpen,
}: Props<T>) {
  const SvgArrowIcon = (
    <SvgArrow
      orient={isOpen ? 'up' : 'down'}
      color={Colors.contentSecondary}
      width={15}
      height={15}
    />
  )

  const onPress = (value: T) => {
    onChange(getNextSelected(value, selected, options))
  }

  const isSelected = (value: T) => selected.includes(value)

  type RenderItemProps = {
    item: SelectOption<T>
  }

  const renderItem = ({ item }: RenderItemProps) => {
    return (
      <>
        <TouchableOpacity
          onPress={() => onPress(item.value)}
          style={styles.itemContainer}
          accessibilityLabel={item.text}
        >
          <View style={styles.itemSelectedIcon}>
            {isSelected(item.value) && (
              <SvgCheck color={Colors.contentSecondary} />
            )}
          </View>
          <Text style={styles.itemText}>{item.text}</Text>
        </TouchableOpacity>
        {item.children?.map((child, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => onPress(child.value)}
            style={styles.childContainer}
            accessibilityLabel={child.text}
          >
            <View style={styles.childSelectedIcon}>
              {(isSelected(item.value) || isSelected(child.value)) && (
                <SvgCheck color={Colors.contentSecondary} />
              )}
            </View>
            <Text style={styles.childText}>{child.text}</Text>
          </TouchableOpacity>
        ))}
      </>
    )
  }

  return (
    <>
      <TouchableOpacity
        testID={testID}
        onPress={toggle}
        style={styles.labelContainer}
        accessibilityLabel={label}
      >
        <Text style={styles.labelText}>{label}</Text>
        {SvgArrowIcon}
      </TouchableOpacity>
      {isOpen && (
        <View style={styles.listContainer}>
          <FlatList
            extraData={selected}
            data={options}
            keyExtractor={item => `${item.value}-${item.text}`}
            renderItem={renderItem}
          />
          {loading && <ActivityIndicator />}
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  itemContainer: {
    paddingHorizontal: 16,
    paddingVertical: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemSelectedIcon: {
    width: 24,
  },
  itemText: {
    fontSize: 16,
  },
  childContainer: {
    backgroundColor: Colors.white,
    paddingLeft: 28,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  childSelectedIcon: {
    width: 20,
  },
  childText: {
    fontSize: 13,
  },
  labelContainer: {
    backgroundColor: Colors.white,
    paddingLeft: 24,
    paddingRight: 16,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  labelText: {
    fontFamily: Fonts.bold,
    color: Colors.contentSecondary,
    fontSize: 18,
    paddingVertical: 12,
  },
})
