import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import DateFnsUtils from '@date-io/date-fns'
import {
  DatePicker,
  DateTimePicker as DateTimePickerWeb,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { SvgCalendar } from 'src/components/Icons'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { withCreateElement } from 'src/hocs'
import { FormField } from '../Form/FormField'

import { Mode, Props } from './index.types'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { toast } from 'components/common'
import { CustomDateTimePicker } from './CustomPicker.web'
import { TimePicker } from '../TimePicker/TimePicker.web'
import { DatePickerWarning } from './DatePickerWarning'

const PickerMap: {
  [key in Mode]:
    | typeof TimePicker
    | typeof DatePicker
    | typeof DateTimePickerWeb
    | typeof CustomDateTimePicker
} = {
  time: TimePicker,
  date: DatePicker,
  datetime: DateTimePickerWeb,
  keyboardTime: CustomDateTimePicker,
}

const RawDateTimePicker: React.FC<Props> = ({
  minimumDateValue,
  maximumDateValue,
  disabled = false,
  label,
  value,
  onChange,
  FieldComponent = FormField,
  mode = 'keyboardTime',
  textStyle,
  labelStyle,
  warningInfo,
  title,
  setIsBeforeMinimum,
}) => {
  const { t } = useTranslation()
  const [showPicker, setShowPicker] = useState(false)
  const showDateTimePicker = () => setShowPicker(true)
  const hideDateTimePicker = () => setShowPicker(false)

  const { fullAdminDateFormat, adminTimeFormat } = useAdminTimeFormat()
  const dateFormat = useMemo(() => {
    if (mode === 'time') {
      return adminTimeFormat
    }
    return fullAdminDateFormat
  }, [mode, adminTimeFormat, fullAdminDateFormat])

  const onChangeAndClose = (data: MaterialUiPickersDate) => {
    if (minimumDateValue && data && data < minimumDateValue) {
      toast.notice(t('task:taskAddEdit:fluids:startFluidError'))
    }
    onChange(data as Date)
    hideDateTimePicker()
  }

  const InputContainer = () => {
    const displayValue = value
      ? format(new Date(value), dateFormat)
      : t(`common.dateTimePicker.placeholder.${mode}`)
    return (
      <>
        <FieldComponent
          disabled={disabled}
          label={label}
          value={displayValue}
          active={showPicker}
          onPress={showDateTimePicker}
          icon={<SvgCalendar />}
          textStyle={textStyle}
          labelStyle={labelStyle}
        />
        {!!warningInfo && (
          <DatePickerWarning
            warningInfo={{ ...warningInfo, pickedDate: value }}
          />
        )}
      </>
    )
  }

  const Picker = PickerMap[mode]

  useEffect(() => {
    if (!value || !warningInfo?.minDateTime || !setIsBeforeMinimum) return
    if (value <= warningInfo.minDateTime) {
      return setIsBeforeMinimum(true)
    }
    if (value > warningInfo.minDateTime) {
      return setIsBeforeMinimum(false)
    }
  }, [setIsBeforeMinimum, value, warningInfo])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <View style={{ zIndex: 1 }}>
        <Picker
          value={value}
          minDate={minimumDateValue}
          maxDate={maximumDateValue}
          open={showPicker}
          onChange={onChangeAndClose}
          onClose={hideDateTimePicker}
          TextFieldComponent={InputContainer}
          {...(mode === 'time' && ({ format: adminTimeFormat } as any))}
          {...(mode === 'datetime' && ({ openTo: 'hours' } as any))}
          {...(mode === 'keyboardTime' &&
            ({
              disabled,
              textStyle,
              labelStyle,
              warningInfo,
              active: showPicker,
              onPress: showDateTimePicker,
              title,
            } as any))}
        />
      </View>
    </MuiPickersUtilsProvider>
  )
}

export const DateTimePicker = withCreateElement<Props>(RawDateTimePicker)
