import { FluidActionType, Status } from 'src/types/globalTypes'

export enum TaskPreviewTab {
  DUE_MISSED = 'DUE_MISSED',
  UPCOMING = 'UPCOMING',
}

export interface TaskPreviewData {
  treatment_groups: TaskPreviewTreatmentGroup[]
}

export interface TaskPreviewTreatmentGroup {
  title: string
  order: number
  data: TaskPreviewSheet[]
}

export interface TaskPreviewSheet {
  title: string
  data: TaskPreviewTask[]
}

export interface TaskPreviewMedicineDosageInfo {
  route_of_administration: string | null
  total_dosage: number | null
  dosage_weight_unit: string | null
  dosage_patient_weight_unit: string | null
  dosage: number | null
  total_volume: number | null
  total_volume_unit: string | null
  concentration: number | null
  concentration_weight_unit: string | null
  dose_rate: number | null
  dose_rate_time_unit: string | null
  previous_dose_rate: number | null
  concentration_volume_unit: string | null
}

export interface TaskPreviewFluidDosageInfo {
  total_result: number | null
  total_result_unit: string | null
  patient_weight_unit: string | null
  fluid_action_type: FluidActionType | null
  previous_total_result: number | null
  route_of_administration: string | null
}

export interface TaskPreviewTask {
  id: string
  treatment_name: string
  start_at: string
  is_fluid: boolean
  status: Status
  medicine_dosage_info: TaskPreviewMedicineDosageInfo | null
  fluid_dosage_info: TaskPreviewFluidDosageInfo | null
  treatment_is_pending_approval?: boolean | null
}
