import React, { useMemo } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { Colors, Typography } from 'src/design-system/theme'
import {
  ProcedureProduct,
  groupProcedureTreatments,
} from './utils/ProcedureUtil'
import { useSeparationSites } from 'src/hooks/useSeparationSites'
import { useTranslation } from 'react-i18next'

interface Props {
  product: ProcedureProduct
  parentSites: any
}

export const ProcedureProductsScreen: React.FC<Props> = ({
  product,
  parentSites,
}) => {
  const { t } = useTranslation()
  const groupedProductsMap = useMemo(
    () => groupProcedureTreatments(product),
    [product],
  )

  // TODO - Cannot be tested currently as Procedures don't have assigned sites in ezyVet
  const sites = useSeparationSites(product.sites ?? [])
  const isValidProduct = parentSites
    ? parentSites.map((parentSite: any) =>
        sites.separationSites.find(siteId => siteId !== parentSite),
      )
    : null

  const renderProcedureProduct = (sheetGroup: string) => {
    return !!sheetGroup.length ? (
      <>
        <View style={styles.productListItemContainer}>
          <View style={styles.sheetGroupContainer}>
            <Text style={styles.sheetGroupLabel}>{sheetGroup}</Text>
          </View>
          {groupedProductsMap[sheetGroup].map((product: ProcedureProduct) => (
            <View style={styles.productContainer} key={product.id}>
              <Text style={styles.productName}>{product.name}</Text>
              {isValidProduct ? (
                <Text style={{ color: Colors.Contents.warning }}>
                  {t('procedure:businessSeparation:warning')}
                </Text>
              ) : null}
            </View>
          ))}
        </View>
        <View />
      </>
    ) : null
  }

  return (
    <FlatList
      data={Object.keys(groupedProductsMap)}
      renderItem={({ item }) => renderProcedureProduct(item)}
    />
  )
}

const styles = StyleSheet.create({
  productListItemContainer: {
    flex: 1,
  },
  sheetGroupContainer: {
    height: 40,
    justifyContent: 'center',
    paddingLeft: 12,
    backgroundColor: Colors.Backgrounds.accentSecondary,
  },
  sheetGroupLabel: {
    ...Typography.Label.L,
    fontWeight: Typography.FontWeights.bold,
  },
  productContainer: {
    height: 60,
    justifyContent: 'center',
    paddingLeft: 12,
    backgroundColor: Colors.Backgrounds.UI,
    borderBottomWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  productName: {
    ...Typography.Label.M,
  },
})
