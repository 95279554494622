import { getSettings_getOrganisation_staffed_hours } from 'src/types/getSettings'
import { StaffedHoursInput } from 'src/types/globalTypes'
import { StaffedHoursConfig } from './types'

export const deserializeStaffedHoursSettings = (
  data: getSettings_getOrganisation_staffed_hours,
): StaffedHoursConfig => {
  const { __typename, ...value } = data
  return Object.fromEntries(
    Object.entries(value).map(([key, value]) => {
      return [
        key,
        {
          ...value,
          hours: value.hours.map(v => {
            return { start: new Date(v.start), end: new Date(v.end) }
          }),
        },
      ]
    }),
  ) as unknown as StaffedHoursConfig
}

export const serializeStaffedHoursSettings = (
  data: StaffedHoursConfig,
): StaffedHoursInput => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      return [
        key,
        {
          ...value,
          hours: value.hours.map(v => {
            return { start: v.start.toISOString(), end: v.end.toISOString() }
          }),
        },
      ]
    }),
  ) as unknown as StaffedHoursInput
}
