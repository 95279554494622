import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import { getUsersList, getUsersListVariables } from 'src/types/getUsersList'
import { deleteUser, deleteUserVariables } from 'types/deleteUser'

import { DELETE_USER, GET_USERS_LIST } from './graphql'

export const useDeleteUser = (queryVariables: deleteUserVariables) => {
  const [{ organisationId }] = useOrganisation()
  const { t } = useTranslation()
  const client = useApolloClient()
  return useMutation<deleteUser, deleteUserVariables>(DELETE_USER, {
    variables: queryVariables,
    onError: err => {
      toast.error(err.message)
    },
    onCompleted: () => {
      toast.success(t('settings:deleteUser.success'))
      try {
        const preData = client.readQuery<getUsersList, getUsersListVariables>({
          query: GET_USERS_LIST,
          variables: {
            organisation_id: organisationId,
          },
        })

        if (!preData) {
          return
        }

        const cloned = cloneDeep(preData)
        cloned.getUsersList.items = cloned.getUsersList.items.filter(
          item => item.id !== queryVariables.id,
        )
        client.writeQuery({
          query: GET_USERS_LIST,
          variables: {
            organisation_id: organisationId,
          },
          data: cloned,
        })
      } catch {
        // ignore if can't find GET_USERS_LIST in cache
      }
    },
  })
}
