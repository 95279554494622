import { useApolloClient, useMutation } from '@apollo/client'
import { TREATMENT_BASE_WITH_TASKS } from 'components/AddTreatment/graphql'
import { toast } from 'components/common'
import { RESCHEDULE_TREATMENTS } from 'components/Treatment/graphql'
import { getRepeatUntil } from 'components/TreatmentForm/utils/getRepeatUntil'
import { cloneDeep, omit } from 'lodash'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import {
  bulkRescheduleTreatments,
  bulkRescheduleTreatments_bulkRescheduleTreatments_schedule as Schedule,
  bulkRescheduleTreatmentsVariables,
} from 'src/types/bulkRescheduleTreatments'
import {
  ApprovalStatus,
  FrequencyType,
  RescheduleTreatmentInput,
} from 'src/types/globalTypes'
import { TreatmentBaseWithTasks } from 'src/types/TreatmentBaseWithTasks'

import { BulkTasksKeyedByTaskID } from '../BulkTaskActions'
import { Inputs } from '../RescheduleDateTime'
import {
  createStartDateString,
  createTasksForCacheTreatment,
  getCheckedTreatmentWithNextTask,
} from './rescheduleTreatmentsUtils'
import { useApprovals } from 'src/hooks/useApprovals'
import { buildTreatmentWithTasksFragmentVariable } from 'components/Treatment/utils/buildTreatmentWithTasksFragmentVariable'

const FromOptimistic = 'FromOptimistic'

export const useRescheduleTreatments = () => {
  const [{ organisationId }] = useOrganisation()
  const client = useApolloClient()
  const { visibleDayRange, fromToQueryDate } = useTimeResolution()

  const { shouldUnapproveTreatment } = useApprovals()

  const [rescheduleTreatments] = useMutation<
    bulkRescheduleTreatments,
    bulkRescheduleTreatmentsVariables
  >(RESCHEDULE_TREATMENTS, {
    onError: err => {
      toast.error(err.message)
    },
  })

  return (
    allItems: BulkTasksKeyedByTaskID,
    newStartDateOrRescheduleMinutes: Date | number,
    dataInputs: Inputs,
  ) => {
    const checkedTreatmentWithNextTask =
      getCheckedTreatmentWithNextTask(allItems)
    const input: RescheduleTreatmentInput[] = []

    checkedTreatmentWithNextTask.forEach(treatmentWithNextTask => {
      const product = treatmentWithNextTask.product
      if (!product) {
        return
      }
      if (!treatmentWithNextTask.schedule) {
        return
      }
      if (!treatmentWithNextTask.start_at) {
        return
      }

      let schedule = {
        ...omit(treatmentWithNextTask.schedule, '__typename'),
        start_at: createStartDateString(
          treatmentWithNextTask.start_at,
          newStartDateOrRescheduleMinutes,
        ),
        repeat: dataInputs.isRepeating,
        repeat_until: getRepeatUntil(dataInputs.repeatUntilDate),
      }

      if (dataInputs.frequencyInput.type === FrequencyType.MINS_FROM_MIDNIGHT) {
        schedule = {
          ...schedule,
          type: FrequencyType.MINS_FROM_MIDNIGHT,
          frequencies: dataInputs.frequencyInput.frequencies!,
          frequency: null,
          treatment_frequency_id: dataInputs.frequencyInput.id,
        }
      } else {
        schedule = {
          ...schedule,
          type: FrequencyType.INTERVAL,
          frequency: dataInputs.frequencyInput.frequencies?.at(0)!,
          frequencies: null,
          treatment_frequency_id: null,
        }
      }

      const oneInput = {
        schedule,
        id: treatmentWithNextTask.treatment_id,
        organisation_id: treatmentWithNextTask.organisation_id,
        patient_id: treatmentWithNextTask.patient_id,
        product_id: product.id,
        sheet_id: treatmentWithNextTask.sheet_id,
        task_id: treatmentWithNextTask.task_id,
        reschedule_from: treatmentWithNextTask.start_at,
      }
      input.push(oneInput)
    })

    return rescheduleTreatments({
      variables: { input },
      optimisticResponse: {
        bulkRescheduleTreatments: input.map(treatment => ({
          discontinued_at: null,
          display_states: null,
          id: FromOptimistic,
          instructions: null,
          sheet_id: treatment.sheet_id,
          __typename: 'Treatment',
          schedule: treatment.schedule as Schedule,
        })),
      },
      update: (_, { data }) => {
        const responseTreatments = data?.bulkRescheduleTreatments
        if (!responseTreatments) {
          return
        }

        input.forEach(treatment => {
          const fragmentVariable = {
            fragment: TREATMENT_BASE_WITH_TASKS,
            fragmentName: 'TreatmentBaseWithTasks',
            id: `Treatment:${treatment.id}`,
            variables: fromToQueryDate,
          }

          const cachedTreatment =
            client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

          if (!cachedTreatment) return

          if (shouldUnapproveTreatment(treatment)) {
            client.writeFragment({
              ...fragmentVariable,
              data: {
                ...cachedTreatment,
                approval_status: ApprovalStatus.PENDING,
              },
            })
          }
        })

        const isFromPlatForm = responseTreatments.some(
          t => t.id !== FromOptimistic,
        )

        // task subscription is faster than the real platform response, the opt task will overwrite the real tasks
        if (isFromPlatForm) {
          return
        }

        input.forEach(rescheduleTreatment => {
          const fragmentVariable = buildTreatmentWithTasksFragmentVariable(
            rescheduleTreatment.id,
            fromToQueryDate,
          )

          const cachedTreatment =
            client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

          if (!cachedTreatment) {
            return
          }

          const oldTreatment = cloneDeep(cachedTreatment)

          if (!oldTreatment?.tasks?.items || !oldTreatment?.schedule) {
            return
          }
          const newTasks = createTasksForCacheTreatment(
            oldTreatment.tasks.items,
            rescheduleTreatment,
            oldTreatment.schedule,
            visibleDayRange,
            organisationId,
          )

          oldTreatment.tasks.items = newTasks

          client.writeFragment({
            ...fragmentVariable,
            data: oldTreatment,
          })
        })
      },
    })
  }
}
