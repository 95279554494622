import React, { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { Button, Center, toast } from 'components/common'
import { DraggableList } from 'components/common/DraggableList'
import { SvgHamburger } from 'components/Icons'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { Colors } from 'constants/Colors'
import { Routes } from 'constants/Routes'
import { isEqual, sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
} from 'react-native'
import { useOrganisation } from 'src/context/organisation'

import { useForm } from 'react-hook-form'
import { GET_SHEET_GROUPS_ORDER, UPDATE_SHEET_GROUP_ORDER } from './graphql'
import {
  bulkUpdateProducts,
  bulkUpdateProductsVariables,
} from 'src/types/bulkUpdateProducts'
import {
  getSheetGroupsOrder,
  getSheetGroupsOrderVariables,
  getSheetGroupsOrder_getOrganisation_products_items as SheetGroup,
} from 'src/types/getSheetGroupsOrder'
import { Fonts } from 'constants/Fonts'

// SHEET VIEW
export const SheetGroupOrderScreen: React.FC = () => {
  const [{ organisationId }] = useOrganisation()
  const { navigate } = useNavigation()
  const { t } = useTranslation()

  const navigateSettings = useCallback(
    () => navigate(Routes.Settings),
    [navigate],
  )

  const { data, loading } = useQuery<
    getSheetGroupsOrder,
    getSheetGroupsOrderVariables
  >(GET_SHEET_GROUPS_ORDER, {
    variables: {
      getOrganisationId: organisationId,
    },
  })

  const initialSheetGroups = useMemo(
    () => sortBy(data?.getOrganisation?.products.items ?? [], 'order'),
    [data],
  )

  const [updateOrder] = useMutation<
    bulkUpdateProducts,
    bulkUpdateProductsVariables
  >(UPDATE_SHEET_GROUP_ORDER, {
    onCompleted: () => {
      toast.success(t('settings:sheetGroup.updateSuccess'))
    },
    onError: error =>
      toast.error(t('settings:sheetGroup.updateError'), null, error),
  })

  const onSubmit = useCallback(
    async (updatedOrder: any) => {
      if (updatedOrder.length) {
        const inputs = updatedOrder.map((sheetGroup: SheetGroup) => {
          const { __typename, ...sheetGroupInput } = sheetGroup
          return {
            ...sheetGroupInput,
            organisation_id: organisationId,
          }
        })
        await updateOrder({ variables: { input: inputs } })
      }
      navigateSettings()
    },
    [updateOrder, organisationId, navigateSettings],
  )

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: navigateSettings,
  }

  return (
    <>
      <SubHeader
        backButton={backButton}
        headlineKey={t('settings:sheetGroup.manageOrderTitle')}
      />
      {loading ? (
        <ActivityIndicator size="large" style={styles.spinner} />
      ) : (
        <SheetGroupForm
          initialSheetGroups={initialSheetGroups}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}

// DRAGGABLE ITEM
const DraggableItem = ({
  sheetGroup,
  onPressIn,
}: {
  sheetGroup: SheetGroup
  onPressIn: (ev: GestureResponderEvent) => void
}) => {
  return (
    <View
      accessibilityLabel={`Sheet Group Sort Item ${sheetGroup.name}`}
      style={styles.itemContainer}
      key={sheetGroup.id}
    >
      <>
        <TouchableOpacity style={styles.dragBtn} onPressIn={onPressIn}>
          <SvgHamburger />
        </TouchableOpacity>
      </>
      <Text style={styles.textBox}>{sheetGroup.name}</Text>
    </View>
  )
}

// Updates the order field to match the updated values, then
// picks the items that have changed since the initial update
const findReorderedItems = (
  items: { order: number | null }[],
  initialItems: { order: number | null }[],
) =>
  items
    .map((e, i) => ({
      ...e,
      order: i + 1,
    }))
    .filter((updatedItem, i) => {
      return !isEqual(updatedItem, initialItems[i])
    })

type FormValues = {
  sheetGroups: SheetGroup[]
}

export const useSheetGroupConfigForm = ({
  initialSheetGroups,
  onSubmit,
}: {
  initialSheetGroups: SheetGroup[]
  onSubmit: any
}) => {
  const { handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: { sheetGroups: initialSheetGroups },
  })

  const sheetGroups = watch('sheetGroups')

  const submitForm = handleSubmit(onSubmit)

  const setGroupOrder = (newItems: SheetGroup[]) => {
    setValue('sheetGroups', newItems)
  }

  return {
    sheetGroups,
    setGroupOrder,
    submitForm,
  }
}

const SheetGroupForm = ({
  initialSheetGroups,
  onSubmit,
}: {
  initialSheetGroups: SheetGroup[]
  onSubmit: any
}) => {
  const { t } = useTranslation()

  const handleSubmit = useCallback(
    (submitValues: FormValues) => {
      const updatedOrder = findReorderedItems(
        submitValues.sheetGroups,
        initialSheetGroups,
      )
      onSubmit(updatedOrder)
    },
    [initialSheetGroups, onSubmit],
  )

  const { setGroupOrder, sheetGroups, submitForm } = useSheetGroupConfigForm({
    initialSheetGroups,
    onSubmit: handleSubmit,
  })

  const renderItem = useCallback(
    ({
      drag,
      item,
    }: {
      drag: (ev: GestureResponderEvent) => void
      item: SheetGroup
    }) => {
      return (
        <Center>
          <DraggableItem sheetGroup={item} onPressIn={drag} />
        </Center>
      )
    },
    [],
  )
  const renderListHeader = () => <View style={styles.paddingTop} />
  const renderListFooter = () => (
    <Button
      a11yLabel="Update Sheet Group Order Button"
      onPress={submitForm}
      style={styles.addButton}
      color={Colors.green}
      title={t('general.saveChanges')}
    />
  )
  return (
    <DraggableList
      items={sheetGroups}
      onMoveEnd={setGroupOrder}
      renderItem={renderItem}
      ListHeaderComponent={renderListHeader()}
      ListFooterComponent={renderListFooter()}
    />
  )
}

const styles = StyleSheet.create({
  addButton: {
    marginVertical: 24,
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 5,
  },
  textBox: {
    flex: 1,
    fontFamily: Fonts.regular,
    fontSize: 16,
    color: Colors.contentSecondary,
    width: '100%',
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.shared.borderGrey,
    paddingVertical: 6,
    paddingHorizontal: 6,
    flexDirection: 'row',
  },
  dragBtn: {
    marginRight: 12,
    width: 18,
  },
  spinner: {
    marginTop: 25,
  },
  paddingTop: {
    paddingTop: 16,
  },
})
