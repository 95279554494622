import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import Highlighter from 'react-native-highlight-words'
import { HospitalTaskProduct } from './HospitalTaskPickProductScreen'
import { Typography, Colors } from 'src/design-system/theme'

type Props = {
  a11yLabel?: string
  product: HospitalTaskProduct
  searchWords: string[]
  addProduct: (product: HospitalTaskProduct) => void
  removeProduct: (id: string) => void // TODO - make add and remove functions uniform?
  isAdded: boolean
}

export const HospitalTaskRow: React.FC<Props> = ({
  product,
  searchWords = [],
  addProduct,
  removeProduct,
  isAdded,
  a11yLabel,
}) => {
  const { t } = useTranslation()

  const formatTypeText = (productType: string) => {
    const lowerCaseText = productType.toLowerCase()
    return lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1)
  }

  return (
    <View style={styles.container} accessibilityLabel={a11yLabel}>
      <View style={{ flex: 1 }}>
        <Highlighter
          ellipsizeMode="middle"
          highlightStyle={{ fontFamily: Typography.FontFamilies.bold }}
          numberOfLines={1}
          searchWords={searchWords}
          autoEscape={true}
          style={styles.productName}
          testID={`TextHighlighter: ${product.name}`}
          textToHighlight={product.name}
        />
        {!!product.type ? (
          <Text style={styles.productTypeText}>
            {formatTypeText(product.type)}
          </Text>
        ) : null}
      </View>
      <TouchableOpacity
        accessibilityLabel={`${isAdded ? 'Remove' : 'Add'} ${product.name}`}
        style={[styles.toggleButton, isAdded && styles.removeBtn]}
        onPress={() => {
          isAdded ? removeProduct(product.id) : addProduct(product)
        }}
      >
        <Text style={styles.buttonText}>
          {isAdded
            ? t('template:treatment.added')
            : t('template:treatment.add')}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.Backgrounds.UI,
    borderBottomColor: Colors.Borders.primary,
    borderBottomWidth: 1,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
  },
  productName: {
    ...Typography.Paragraph.L,
    fontSize: Typography.FontSizes.md,
  },
  productTypeText: {
    fontSize: Typography.FontSizes.sm,
    color: Colors.Contents.tertiary,
  },
  toggleButton: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: Colors.Backgrounds.accentPrimary,
    alignItems: 'center',
    borderRadius: 3,
  },
  buttonText: {
    color: Colors.Contents.onColor,
    fontSize: Typography.FontSizes.base,
    fontWeight: Typography.FontWeights.bold,
  },
  removeBtn: {
    backgroundColor: Colors.Buttons.positive,
  },
})
