import { ApolloError, useQuery } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import {
  getOrganisation,
  getOrganisationVariables,
} from 'src/types/getOrganisation'

import { GET_ORGANISATION } from './graphql'

// This takes advantage of partial query caching.
// Ref: https://www.apollographql.com/docs/resources/graphql-glossary/#partial-query-caching
type UseOrganisationHookParams = { onError?: (error: ApolloError) => void }
export const useOrganisationHook = (options?: UseOrganisationHookParams) => {
  const [{ organisationId }] = useOrganisation()
  const { data, error, loading } = useQuery<
    getOrganisation,
    getOrganisationVariables
  >(GET_ORGANISATION, {
    onError: options?.onError,
    variables: {
      id: organisationId,
    },
  })

  const integrations = data?.getOrganisation?.integrations?.items
  const integrationId = integrations?.[0]?.id
  const rawInstanceUrl = integrations?.[0]?.adaptor?.instance_url
  const apiUrl = integrations?.[0]?.adaptor?.api_url ?? null

  const httpRegex = /^https?:\/\//

  // Make sure all instanceURL's are formatted correctly for linking to ezyVet
  let instanceUrl = null
  if (rawInstanceUrl) {
    const isCorrectUrl = httpRegex.test(rawInstanceUrl)
    instanceUrl = isCorrectUrl ? rawInstanceUrl : `https://${rawInstanceUrl}`
  }

  return {
    error,
    loading,
    instanceUrl,
    apiUrl,
    organisation: data?.getOrganisation ?? null,
    integrationId,
    organisationId,
  }
}
