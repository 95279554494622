type PatientBreedAndSpecies = {
  species: {
    name: string | null
  } | null
  breed: {
    name: string | null
  } | null
}

export const getPatientBreedAndSpecies = (patient: PatientBreedAndSpecies) => {
  const species = patient.species?.name ?? ''
  const breed = patient.breed?.name ?? ''
  const separator = patient.breed?.name ? ' - ' : ''

  return `${species} ${separator} ${breed}`
}
