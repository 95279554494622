import { useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { FormData } from 'components/TreatmentForm'
import { useTranslation } from 'react-i18next'
import { CREATE_TREATMENT } from 'src/components/AddTreatment/graphql'
import { getTreatmentInput } from 'src/components/TreatmentForm/utils/getTreatmentInput'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { useUpdateTreatments } from 'src/utils/useUpdateTreatments'
import {
  createTreatment as CreateTreatmentT,
  createTreatmentVariables,
} from 'types/createTreatment'
import { CreateTreatmentOptions, ProductType } from 'types/globalTypes'

export type ProductInfo = {
  productGroupName: string
  productGroupId: string
  productGroupOrder: number | null
  productId: string
  productName: string
  productType: ProductType
}

export const useAddEmergencyDrug = (sheetId: string, patientId: string) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { fromToQueryDate } = useTimeResolution()
  const updateTreatments = useUpdateTreatments(sheetId)

  const [createTreatment, { loading }] = useMutation<
    CreateTreatmentT,
    createTreatmentVariables
  >(CREATE_TREATMENT, {
    onCompleted: data => {
      updateTreatments(data.createTreatment)
      toast.success(t('addTreatment.success'))
    },
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
  })

  const addEmergencyDrug = (
    formData: FormData,
    product: any,
    createTreatmentOptions?: CreateTreatmentOptions,
  ) => {
    const { productId, productType } = product

    return createTreatment({
      variables: {
        input: {
          ...getTreatmentInput(formData, productType),
          organisation_id: organisationId,
          patient_id: patientId,
          product_id: productId,
          sheet_id: sheetId,
        },
        ...fromToQueryDate,
        options: createTreatmentOptions,
      },
      fetchPolicy: 'no-cache',
      update: (_, { data }) => {
        if (data) updateTreatments(data.createTreatment)
      },
    })
  }
  return {
    addEmergencyDrug,
    loading,
  }
}
