import React, { useCallback } from 'react'
import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { StyleSheet, View } from 'react-native'

import { AccountButton } from 'components/Account/AccountButton'
import {
  AppScreensConfig,
  ScreenName,
} from 'components/Base/screens/AppScreensConfig'
import { Routes } from 'constants/Routes'

import { FooterNavButton } from '../FooterNavButton'

// Must use navigation prop since not 'inside' navigator
type Props = BottomTabBarProps & {
  routeNavs: Routes[]
}

export const FooterNavItems: React.FC<Props> = ({
  state,
  descriptors,
  navigation,
  routeNavs,
}) => {
  const { navigate } = navigation

  const onPressNav = useCallback(
    (routeName: Routes) => {
      const routeNav = AppScreensConfig[routeName as ScreenName]

      /* VR-7466: Always navigate (web|ios) to 'root' screen when pressing tabs.
       Fixes issues with of user stuck on a sheet view in one tab with no tab
       navigation view available. Isn't typical iOS nav behavior  */
      navigate(routeName, { screen: routeNav!.rootStack })
    },
    [navigate],
  )

  return (
    <View style={styles.content}>
      {routeNavs.map(routeName => {
        const index = state.routes.findIndex(item => item.name === routeName)
        const isItemSelected = index === state.index
        const route = state.routes[index]
        const title = descriptors[route.key].options.title ?? ''

        return (
          <FooterItem
            isActive={isItemSelected}
            key={routeName}
            onPress={onPressNav}
            routeName={routeName}
            title={title}
          />
        )
      })}
      <AccountButton navigation={navigation} />
    </View>
  )
}

type FooterItemProps = {
  isActive: boolean
  onPress: (routeName: Routes) => void
  routeName: Routes
  title: string
}

const FooterItem: React.FC<FooterItemProps> = ({
  isActive,
  onPress,
  routeName,
  title,
}) => {
  const onPressNav = useCallback(() => {
    onPress(routeName)
  }, [onPress, routeName])

  const routeNavIcon = AppScreensConfig[routeName as ScreenName]!.icon

  return (
    <FooterNavButton
      icon={routeNavIcon}
      isActive={isActive}
      onPress={onPressNav}
      title={title}
    />
  )
}

export const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
})
