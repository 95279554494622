import React from 'react'
import { PatientInfo } from '../PatientItem'
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  StyleSheet,
  TouchableOpacity,
  Text,
} from 'react-native'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { environment } from 'src/config'

type PatientNameProps = {
  name: string
  onPressName?: PatientInfo['onPressName']
  textStyle: StyleProp<TextStyle>
  containerStyle: ViewStyle
  orderNum?: string
}

const { isWeb } = environment

export const PatientName: React.FC<PatientNameProps> = ({
  containerStyle,
  name,
  onPressName,
  textStyle,
  orderNum,
}) => {
  const getDisplayedOrderNumber = (orderNum: string) => {
    if (orderNum.length > 4) return ` (${orderNum.substring(0, 4)}...)`
    return ` (${orderNum})`
  }

  const Name = ({ name, textStyle, orderNum }: Partial<PatientNameProps>) => (
    <View style={styles.nameRowContainer}>
      <TextWithTooltip
        style={[textStyle, isWeb && styles.marginForWebOrderNum]}
        numberOfLines={1}
        title={name}
      >
        {name}
      </TextWithTooltip>
      {!!orderNum && (
        <Text style={[textStyle, styles.orderNumWidth]} numberOfLines={1}>
          {getDisplayedOrderNumber(orderNum)}
        </Text>
      )}
    </View>
  )

  return !onPressName ? (
    <View style={containerStyle}>
      <Name name={name} textStyle={textStyle} orderNum={orderNum} />
    </View>
  ) : (
    <TouchableOpacity style={containerStyle} onPress={onPressName}>
      <Name name={name} textStyle={textStyle} orderNum={orderNum} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  orderNumWidth: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
  },
  marginForWebOrderNum: {
    marginRight: 6,
  },
  nameRowContainer: {
    flexDirection: 'row',
  },
})
