import React from 'react'
import { TreatmentForm } from 'components/TreatmentForm'
import { FormData } from 'src/components/TreatmentForm'
import { useOrganisation } from 'src/context/organisation'
import { getPatient_getPatient as Patient } from 'src/types/getPatient'

import { CreateTreatmentFromInput } from './templateUtilFns'
import { useTranslation } from 'react-i18next'

type Props = {
  createTreatmentFromInput: CreateTreatmentFromInput
  onSave: (data: FormData) => void
  patient: Patient
  defaultOverrides?: Partial<FormData>
  isAddTemplateToNewSheet?: boolean
}

export const TemplateTreatmentFormContainer: React.FC<Props> = ({
  createTreatmentFromInput,
  patient,
  onSave,
  defaultOverrides,
  isAddTemplateToNewSheet = false,
}) => {
  const { t } = useTranslation()

  const [{ hasPIMSIntegration }] = useOrganisation()

  if (!createTreatmentFromInput.product) {
    return null
  }

  return (
    <TreatmentForm
      defaultOverrides={
        {
          ...createTreatmentFromInput.defaultOverrides,
          ...defaultOverrides,
        } as Partial<FormData>
      }
      onSave={onSave}
      patient={patient}
      product={createTreatmentFromInput.product}
      submitTitle={t('treatment:setup')}
      hasPIMSIntegration={!!hasPIMSIntegration}
      shouldHideTabs={true}
      isAddTemplateToNewSheet={isAddTemplateToNewSheet}
    />
  )
}
