import React from 'react'
import { PhoneLinking } from 'components/common/PhoneLinking'
import { Text, View } from 'react-native'
import { PatientFieldsFull as GetPatientsItem } from 'src/types/PatientFieldsFull'

import { PatientHeaderStyles as styles } from './PatientHeaderStyles'

export const PatientOwnerInfo: React.FC<{
  patient: Partial<GetPatientsItem>
}> = ({ patient }) => {
  return (
    <View style={[styles.row, styles.ownerRowPadding]}>
      <Text style={styles.regularText}>
        {`${patient?.contact?.first_name ?? ''} ${
          patient?.contact?.last_name ?? ''
        }`}
      </Text>
      <PhoneLinking phoneNumber={patient?.contact?.phone_number}>
        <Text style={[styles.regularText, styles.phoneText]}>
          {patient?.contact?.phone_number &&
          patient.contact.phone_number !== '0'
            ? `Ph: ${patient?.contact?.phone_number}`
            : ''}
        </Text>
      </PhoneLinking>
    </View>
  )
}
