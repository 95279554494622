import { useMutation } from '@apollo/client'
import { UPDATE_PATIENT } from 'components/Patient/graphql'
import { FormField, toast } from 'components/common'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import { resuscitateOptions } from 'components/Patient/data'
import {
  updatePatient as UpdatePatient,
  updatePatientVariables,
} from 'src/types/updatePatient'
import { Resuscitation } from 'src/types/globalTypes'
import { useUpdatePatientListCache } from 'src/components/Patient/useUpdatePatientListCache'
import { Picker } from '@react-native-community/picker'
import { SelectV2 } from 'components/common/SelectV2/SelectV2'
import { Platform, View, StyleSheet } from 'react-native'
import { FontSizes } from 'src/design-system/theme/typography/base'

type Props = {
  patientId: string
  currentCPRStatus?: Resuscitation | null
}

const isIOS = Platform.OS !== 'web'

const PICKER_ITEM_IOS_HEIGHT = 120

export const SheetActionsCPRStatus: React.FC<Props> = ({
  patientId,
  currentCPRStatus,
}) => {
  const { t } = useTranslation()
  const [selectedCPRStatus, setSelectedCPRStatus] = useState<
    Resuscitation | null | undefined
  >(currentCPRStatus)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [{ organisationId }] = useOrganisation()

  const [updatePatient] = useMutation<UpdatePatient, updatePatientVariables>(
    UPDATE_PATIENT,
  )
  const updatePatientListCache = useUpdatePatientListCache()
  const menuOptions = [
    { text: t('select.none'), value: null },
    ...resuscitateOptions,
  ]
  const handleUpdateCPRStatus = async (
    updatedCPRStatus?: Resuscitation | null,
  ) => {
    setSelectedCPRStatus(updatedCPRStatus)
    try {
      await updatePatient({
        variables: {
          input: {
            id: patientId,
            organisation_id: organisationId,
            resuscitate: updatedCPRStatus,
          },
        },
        update: (_, { data: updateData }) => {
          if (!updateData?.updatePatient) return
          updatePatientListCache(updateData?.updatePatient, 'UPDATE')
        },
      })

      toast.success(t('patient:action.resuscitation.toastMessage'))
    } catch (error) {
      if (error instanceof Error) toast.error(error.message)
    }
  }

  const getCPRStatusText = (CPRStatus?: Resuscitation | null): string => {
    if (!CPRStatus) return t('select.none')
    return (
      resuscitateOptions.find(option => option.value === CPRStatus)?.text ??
      t('select.none')
    )
  }

  const renderCPRStatusOptions = () => {
    return isIOS ? (
      <>
        <FormField
          label={t('patient:action.resuscitation.label')}
          textStyle={styles.selectMenuText}
          value={getCPRStatusText(selectedCPRStatus)}
          onPress={() => setIsMenuOpen(prev => !prev)}
        />
        {isMenuOpen ? (
          <Picker
            onValueChange={(val: Resuscitation) => handleUpdateCPRStatus(val)}
            selectedValue={selectedCPRStatus}
            accessibilityLabel="Select Picker Container"
            testID="Picker"
            itemStyle={styles.pickerItemStyle}
          >
            {menuOptions.map(option => (
              <Picker.Item
                key={option.value}
                label={option.text}
                value={option.value}
              />
            ))}
          </Picker>
        ) : null}
      </>
    ) : (
      <SelectV2
        options={menuOptions}
        label={t('patient:action.resuscitation.label')}
        selected={getCPRStatusText(selectedCPRStatus)}
        onPress={handleUpdateCPRStatus}
        textStyle={styles.selectMenuText}
      />
    )
  }

  return <View style={styles.container}>{renderCPRStatusOptions()}</View>
}

const styles = StyleSheet.create({
  container: { width: '100%', position: 'relative' },
  pickerItemStyle: {
    fontSize: FontSizes.base,
    height: PICKER_ITEM_IOS_HEIGHT,
    margin: 0,
  },
  selectMenuText: { fontSize: FontSizes.base },
})
