import React from 'react'

import { Modal } from 'components/common/Modal'

import { isIphone } from 'src/utils/isIPhone'
import { ResetModalContent } from './ResetModalContent'
import { Platform } from 'react-native'
import { RawDialog } from 'components/common/Dialog/RawDialog'

const IOS_MODAL_CONTENT_WIDTH = 580
const WEB_MODAL_CONTENT_WIDTH = 480

export const ResetModal: React.FC<{
  visible: boolean
  resetTrainingSite: () => void
  onClose: () => void
}> = ({ visible, resetTrainingSite, onClose }) => {
  // Stolen from Help Modal
  const modalWidth = {
    width:
      Platform.OS === 'ios' ? IOS_MODAL_CONTENT_WIDTH : WEB_MODAL_CONTENT_WIDTH,
  }

  if (isIphone) {
    return (
      <RawDialog visible={visible} onClose={onClose} dialogHeight={'40%'}>
        <ResetModalContent
          resetTrainingSite={resetTrainingSite}
          onClose={onClose}
        />
      </RawDialog>
    )
  }
  return (
    <Modal visible={visible} onCloseModal={onClose} contentStyle={modalWidth}>
      <ResetModalContent
        resetTrainingSite={resetTrainingSite}
        onClose={onClose}
      />
    </Modal>
  )
}
