import React from 'react'
import Svg, { G, Path } from 'react-native-svg'
import { Colors } from 'constants/Colors'

type Props = {
  color?: string
  width?: number
  height?: number
}

export const SvgAddMedPlus: React.FC<Props> = React.memo(
  ({ color = Colors.white, width = 16, height = 16 }) => (
    <Svg width={width} height={height}>
      <G fill={color}>
        <Path d="M7.987 16c.737 0 1.194-.496 1.194-1.266V9.558h5.511c.762 0 1.308-.422 1.308-1.13 0-.732-.508-1.167-1.308-1.167H9.181V.577c0-.782-2.35-.757-2.35 0v6.684H1.334C.533 7.261 0 7.696 0 8.428c0 .708.559 1.13 1.333 1.13h5.499v5.176c0 .745.419 1.266 1.155 1.266Z" />
      </G>
    </Svg>
  ),
)

SvgAddMedPlus.displayName = 'SvgAddMedPlus'
