import { Colors } from 'constants/Colors'
import { ApprovalStatus } from 'types/globalTypes'
import type { getSheetHistory_getSheetHistory as SheetHistory } from 'src/types/getSheetHistory'

type FormattedUserHistory = {
  sheetId: string
  patientId: string
  patientName: string
  contactLastName: string | null
  sheetName?: string
  patientImage: string
  studentApproval: boolean
  patientColor: string
}

export const formatUserHistory = (data: SheetHistory): FormattedUserHistory => {
  const studentApproval = data.approval_status === ApprovalStatus.PENDING
  return {
    sheetId: data.id,
    patientId: data.patient.id,
    patientName: data.patient?.name ?? '',
    contactLastName: data.patient?.contact?.last_name ?? '',
    sheetName: data.name,
    patientImage: data.patient?.avatar_url ?? '',
    studentApproval,
    patientColor: data.patient?.color ?? Colors.white,
  }
}
