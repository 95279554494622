import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  AddTreatmentButton,
  addTreatmentButtonHeight,
} from 'components/AddTreatment/AddTreatmentButton'
import { AddTreatmentDrawer } from 'components/AddTreatment/AddTreatmentDrawer'
import { toast } from 'components/common'
import { CustomHorizontalScroll } from 'components/CustomScrollBar/CustomHorizontalScroll'
import { OnSheetScroll } from 'components/CustomScrollBar/types'
import { SHEET_DAY_FORMAT } from 'constants/Date'
import { Fonts } from 'constants/Fonts'
import { SCROLL_VIEW_FIRST_INDEX_STICKY } from 'constants/Layout'
import { format, isWithinInterval } from 'date-fns'
import { differenceInHours } from 'date-fns/fp'
import { noop, orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  Animated,
  LayoutChangeEvent,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native'
import { Task } from 'src/components/Task/types'
import {
  TreatmentChildLevel,
  TreatmentSheetFirstLevel,
} from 'src/components/Treatment/common/types'
import { AnesthesiaProvider } from 'src/context/anesthesia'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useTimeResolution } from 'src/hocs/timeContext'
import { SmallTimeSegments, TimeSegment } from 'src/hocs/types/time'
import { useFoldedTreatments } from 'src/utils/useFoldedTreatments'
import { getSheet_getSheet as Sheet } from 'types/getSheet'

import { treatmentWidthLarge, treatmentWidthSmall } from '../common'
import { useCustomScroll } from '../CustomScrollBar/useCustomScroll'
import { useGridLayout } from '../CustomScrollBar/useGridLayout'
import { useScrollPosition } from '../CustomScrollBar/useScrollPosition'
import { TreatmentTree } from '../Treatment/TreatmentTree'
import {
  GridTimeline,
  treatmentColumnHeaderHeight,
} from './GridTimeLine/GridTimeline'
import { GridNavV2 } from 'components/Grid/GridNavV2'
import { useGetPatientSheets } from 'components/Grid/useGetPatientSheets'
import { Routes } from 'constants/Routes'
import { useNavigation } from '@react-navigation/native'
import { HeaderButton } from 'components/SubHeader/SubHeader'
import { ScrollToTop } from './ScrollToTop'
import { DateWithDay } from './DateWithDay'
import { useArrowButton } from 'src/hooks/useArrowButton'
import {
  PatientPanelDrawerActionTypes,
  usePatientPanelDrawerContext,
} from 'src/context/patientPanelDrawer'
import { PatientPanel } from 'components/PatientPanel'
import { DrawerAction } from 'components/Sheet/ReadySheet'
import { gridNavHeight } from './GridNavV2'
import { useUser } from 'src/context/user'
import { useLocalToggle } from 'components/Sheet/useLocalToggle'
import { local_toggle_type } from 'constants/LocalToggleOptions'
import { useDayCountDisplay } from 'src/context/patientStartDate'
import { EmptyGrid } from './EmptyGrid'
import { RangeDirection } from './SheetTitle'
import { useDraggableHorizontalScroll } from 'components/utils/useDraggableHorizontalScroll'

export type OnPressTreatmentGroup = (
  treatment: TreatmentSheetFirstLevel,
  timeOfPress: Date,
) => void

export type OnPressTask = (
  treatment: TreatmentChildLevel,
  task: Task,
  timeOfPress?: Date | undefined,
) => void

type Props = {
  onPressTask: OnPressTask
  onPressTreatment: (treatment: TreatmentChildLevel) => void
  onPressTreatmentGroup: OnPressTreatmentGroup
  onPressTreatmentGroupTitle: (treatment: TreatmentChildLevel) => void
  showAnesthesiaChart?: boolean
  sheet?: Sheet | null
  patientId: string
  initialDateInView?: string
  showMoreDetails?: boolean
  gridActionButton?: HeaderButton | null
  gridBackButton?: HeaderButton | null
  onPressShowMore?: () => void
  toggleSheetInfoDrawer?: () => void
  onPressPatientInfo?: () => void
  consultationId?: string
  selectDrawerAction: (action: DrawerAction | null) => void
}

const useTreatmentWidth = () => {
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  return isExSmallScreen || isSmallScreen
    ? treatmentWidthSmall
    : treatmentWidthLarge
}

const isWeb = Platform.OS === 'web'
const isTouchableWeb = isWeb && 'ontouchstart' in document.documentElement

export const GridFlatList = React.memo(
  ({
    showAnesthesiaChart = false,
    onPressTask,
    onPressTreatment,
    onPressTreatmentGroup,
    onPressTreatmentGroupTitle,
    sheet,
    patientId,
    initialDateInView,
    showMoreDetails = true,
    onPressShowMore,
    toggleSheetInfoDrawer,
    onPressPatientInfo,
    gridActionButton,
    gridBackButton,
    consultationId,
    selectDrawerAction,
  }: Props) => {
    const {
      dateRangeCount,
      timeNow,
      timeSegment,
      visibleDayRange,
      visibleDayList,
      getVisibleDayByIndex,
    } = useTimeResolution()
    const { t } = useTranslation()
    const { navigate } = useNavigation()
    const { user } = useUser()
    const { foldedTreatments, handleTreatmentFoldedToggle } =
      useFoldedTreatments()
    const treatmentWidth = useTreatmentWidth()
    const [timeInChart, setTimeInChart] = useState({ timeInChart: noop })
    const vertRef = useRef<ScrollView>(null)
    const sheetRef = useRef<ScrollView>(null)
    const [, setShowDrawer] = useLocalToggle({
      prefix: null,
      type: local_toggle_type.SHOWDRAWER,
    })
    const hasSheetTreatments = !!sheet && sheet?.treatments?.items?.length !== 0
    const {
      fullGridHeight,
      fullGridWidth,
      hasScrollableContent,
      onGridContentSizeChange,
      onScrollLayout,
      scrollableWidth,
      scrollIndicatorSize,
      visibleToCompleteWidthRatio,
      visibleGridWidth,
    } = useGridLayout()

    const { refs: horizontalScrollRefs } =
      useDraggableHorizontalScroll<ScrollView>({
        outerRef: sheetRef,
        retriggerKey: hasSheetTreatments,
      })

    const scrollIndicatorStyle = useMemo(
      () => ({ width: scrollIndicatorSize }),
      [scrollIndicatorSize],
    )

    const scrollContainerStyle = useMemo(
      () => ({ left: treatmentWidth }),
      [treatmentWidth],
    )

    const treatmentWidthStyle = useMemo(
      () => ({ width: treatmentWidth }),
      [treatmentWidth],
    )

    const [{ shouldShowDrawer }, dispatch] = usePatientPanelDrawerContext()
    const setShouldShowDrawer = (shouldShow: boolean) => {
      dispatch({
        type: PatientPanelDrawerActionTypes.setShouldShowDrawer,
        shouldShowDrawer: shouldShow,
      })
      setShowDrawer(shouldShow)
    }

    // TODO: try to remove
    const [headerNavHeight, setHeaderNavHeight] = useState<number>(0)
    const onGridNavLayout = useCallback((e: LayoutChangeEvent) => {
      setHeaderNavHeight(e.nativeEvent.layout.height)
    }, [])

    const { onSheetScroll, currentDateIdx, scrollToDateIdx, scrollToNow } =
      useScrollPosition({
        fullGridWidth,
        initialDateInView,
        sheetRef,
        visibleGridWidth,
        visibleDayListLength: visibleDayList.length - 1,
      })

    const { onClickPre, onClickNext } = useArrowButton({
      scrollToDateIdx,
      currentDateIdx,
    })

    const indicatorDragOffset = useRef(0)

    const {
      showScrollToTop,
      gridVerticalScroll,
      gridVerticalScrollHandler,
      onCustomXPanResponderMove,
      onCustomXScroll,
      onScrollBarPress,
      scrollValue,
    } = useCustomScroll({ indicatorDragOffset, sheetRef })

    const [isAddTreatmentDrawerVisible, setAddTreatmentDrawerVisible] =
      useState(false)

    const toggleDrawer = useCallback(() => {
      setAddTreatmentDrawerVisible(drawerVisible => !drawerVisible)
    }, [])

    const shouldShowFixedDateHeader =
      (sheet &&
        // always need fixed date header for anesthesia
        dateRangeCount === 1) ||
      ((Object.values(SmallTimeSegments) as TimeSegment[]).includes(
        timeSegment,
      ) &&
        hasScrollableContent &&
        hasSheetTreatments)

    const isFinalized = !!sheet?.closed_at
    const treatments = useMemo(
      () => sheet?.treatments?.items ?? [],
      [sheet?.treatments?.items],
    )
    const [startOfVisibleRange, endOfVisibleRange] = visibleDayRange

    // Also filter out by discontinued per settings
    const sortedTreatments = useMemo(
      () =>
        orderBy(
          treatments,
          ['order', 'node_type', 'conditional', 'name'],
          ['asc', 'desc', 'desc', 'asc'],
        ) as TreatmentChildLevel[],
      [treatments],
    )

    const { hasOneSheet, getPreviousOrNextSheet, total, currentIndex } =
      useGetPatientSheets(patientId, sheet?.id ?? '', 'cache-and-network')

    const navigateSheets = useCallback(
      (direction: RangeDirection) => {
        const nextSheet = getPreviousOrNextSheet(direction)
        if (!nextSheet) return
        return navigate(Routes.Sheet, {
          patientId,
          sheetId: nextSheet.id,
          sheetName: nextSheet.name,
        })
      },
      [getPreviousOrNextSheet, navigate, patientId],
    )

    const onXScroll: OnSheetScroll = useCallback(
      event => {
        onSheetScroll(event)
        if (isWeb) onCustomXScroll(event)
      },
      [onCustomXScroll, onSheetScroll],
    )

    const isTimeNowInSegment = useMemo(
      () =>
        isWithinInterval(new Date(), {
          start: startOfVisibleRange,
          end: endOfVisibleRange,
        }),
      [startOfVisibleRange, endOfVisibleRange],
    )

    const { isExSmallScreen, isSmallScreen } = useBreakpoint()

    // hide when it’s touchable and lower resolution
    const shouldShowHorizontalScrollbar =
      isWeb &&
      (!isTouchableWeb || !(isSmallScreen || isExSmallScreen)) &&
      !!scrollIndicatorSize &&
      hasSheetTreatments

    const currentlyVisibleDay = useMemo(
      () => getVisibleDayByIndex(currentDateIdx) || new Date(),
      [currentDateIdx, getVisibleDayByIndex],
    )

    useEffect(() => {
      const compareDay = differenceInHours(timeNow, currentlyVisibleDay) / 24
      // Type SchedulerWarning toast is singleton.
      const dismissSchedulerWarningToast = toast.schedulerWarning(
        t('general.aheadSchedule'),
      )
      if (compareDay < 2.5) {
        dismissSchedulerWarningToast()
      }
    }, [timeNow, visibleDayList, t, currentlyVisibleDay])

    const onScrollToTopAction = useCallback(() => {
      vertRef?.current?.scrollTo({ y: 0, animated: true })
    }, [])

    const sheetHeadline = useMemo(
      () => (sheet ? `${sheet.name} (${currentIndex + 1}/${total})` : null),
      [currentIndex, sheet, total],
    )

    const fixedDateHeaderStyle = useMemo(
      () => ({
        // gridNavHeight in iOS is larger for better offset
        height: isWeb ? gridNavHeight : gridNavHeight + 12,
        top: isWeb ? headerNavHeight : headerNavHeight - 7,
      }),
      [headerNavHeight],
    )

    const timeByScreenSize =
      isExSmallScreen || isSmallScreen
        ? format(currentlyVisibleDay, 'MM/dd')
        : format(currentlyVisibleDay, SHEET_DAY_FORMAT)

    const formatTime = currentlyVisibleDay ? timeByScreenSize : ''

    const dayCountText = useDayCountDisplay(currentlyVisibleDay)

    return (
      <AnesthesiaProvider treatments={treatments}>
        <>
          <>
            <GridNavV2
              hasLimitedScrollArea={!shouldShowFixedDateHeader}
              currentDateIdx={currentDateIdx}
              scrollToDateIdx={scrollToDateIdx}
              scrollToNow={scrollToNow}
              sheet={sheet}
              headline={sheetHeadline}
              isEmptySheet={!hasSheetTreatments}
              headlineOnPress={toggleSheetInfoDrawer}
              isFinalized={isFinalized}
              navigateSheets={navigateSheets}
              hasOneSheet={hasOneSheet}
              onPressShowMore={onPressShowMore}
              showMoreDetails={showMoreDetails}
              actionButton={gridActionButton}
              backButton={gridBackButton}
              onGridNavLayout={onGridNavLayout}
              selectDrawerAction={selectDrawerAction}
              patientId={patientId}
            />
            {shouldShowFixedDateHeader ? (
              <View style={[styles.dayTextContainer, fixedDateHeaderStyle]}>
                <DateWithDay
                  formatTime={formatTime}
                  placement="ABOVE_TIME_AREA"
                  dayCountText={dayCountText}
                  onClickPrev={onClickPre}
                  onClickNext={onClickNext}
                />
              </View>
            ) : null}
          </>
          {sheet?.treatments?.items?.length === 0 ? (
            <EmptyGrid isFinalized={isFinalized} />
          ) : (
            <Animated.ScrollView
              bounces={false}
              contentContainerStyle={styles.flexGrow}
              onScroll={gridVerticalScrollHandler}
              stickyHeaderIndices={SCROLL_VIEW_FIRST_INDEX_STICKY}
              // @ts-ignore
              webStickyHeaderStyle={treatmentWidthStyle}
              testID={'vertical-grid-scrollview'}
              ref={vertRef}
              // hacky way to make the component visibility hidden to prevent reference lost
              style={!!sheet ? styles.show : styles.hidden}
            >
              <View
                style={[
                  styles.treatmentColumnHeaderStyle,
                  styles.treatmentColumnHeaderStyleV2,
                  treatmentWidthStyle,
                ]}
              >
                <AddTreatmentButton
                  title={t('addTreatment:addBtnFullTitle')}
                  disabled={isFinalized}
                  onPress={toggleDrawer}
                  isInvertedStyle
                />
              </View>
              <View style={[styles.flexRow, styles.flexGrow]}>
                <View style={[treatmentWidthStyle, styles.flexGrow]}>
                  <TreatmentTree
                    foldedTreatments={foldedTreatments}
                    isFinalized={isFinalized}
                    onPressTreatment={onPressTreatment}
                    onPressTreatmentGroupTitle={onPressTreatmentGroupTitle}
                    onToggleFolding={handleTreatmentFoldedToggle}
                    showAnesthesiaChart={showAnesthesiaChart}
                    treatments={sortedTreatments}
                    treatmentWidth={treatmentWidth}
                    timeInChart={timeInChart}
                  />
                </View>
                <ScrollView
                  bounces={false}
                  contentContainerStyle={styles.xScrollContent}
                  horizontal={true}
                  scrollEventThrottle={16}
                  // Show custom scroll bar at fixed bottom if web
                  showsHorizontalScrollIndicator={!isWeb}
                  ref={horizontalScrollRefs}
                  /* Negative marginTop moves the scrollview up into space beside
                 sticky area. We then animate the nested timeline to keep it in
                 the 'header' position in <GridTimeline />.
                 nb: there are no 'stickyIndices' for horizontal scrollviews.
                 https://reactnative.dev/docs/scrollview#stickyheaderindices */
                  style={[styles.negativeMargin, styles.negativeMarginV2]}
                  testID={'horizontal-grid-scrollview'}
                  onContentSizeChange={onGridContentSizeChange}
                  onLayout={onScrollLayout}
                  onScroll={onXScroll}
                >
                  <GridTimeline
                    foldedTreatments={foldedTreatments}
                    gridVerticalScroll={gridVerticalScroll}
                    isFinalized={isFinalized}
                    shouldShowFixedDateHeader={shouldShowFixedDateHeader}
                    numberOfDays={dateRangeCount}
                    onLoadTimeInChartFunc={setTimeInChart}
                    onPressTask={onPressTask}
                    onPressTreatmentGroup={onPressTreatmentGroup}
                    patientId={patientId}
                    scrollHeight={fullGridHeight}
                    sheet={sheet}
                    showAnesthesiaChart={showAnesthesiaChart}
                    showTimeNow={isTimeNowInSegment ? timeNow : false}
                    timeEnd={endOfVisibleRange}
                    timeSegment={timeSegment}
                    timeStart={startOfVisibleRange}
                    treatments={sortedTreatments}
                  />
                </ScrollView>
              </View>
            </Animated.ScrollView>
          )}
          <ScrollToTop
            showScrollToTop={showScrollToTop}
            action={onScrollToTopAction}
          />
          {shouldShowHorizontalScrollbar ? (
            <CustomHorizontalScroll
              containerStyle={scrollContainerStyle}
              dragOffset={indicatorDragOffset}
              indicatorStyle={scrollIndicatorStyle}
              onPanResponderMove={onCustomXPanResponderMove}
              onScrollBarPress={onScrollBarPress}
              scrollValue={scrollValue}
              scrollableWidth={scrollableWidth}
              visibleToCompleteWidthRatio={visibleToCompleteWidthRatio}
            />
          ) : null}
        </>
        <AddTreatmentDrawer
          isQuickAdd={showAnesthesiaChart}
          isVisible={isAddTreatmentDrawerVisible}
          toggleDrawer={toggleDrawer}
        />
        <PatientPanel
          consultationId={consultationId}
          patientId={patientId}
          sheet={sheet}
          user={user}
          isExpanded={shouldShowDrawer}
          handleCollapse={() => setShouldShowDrawer(false)}
          onPressPatientInfo={onPressPatientInfo}
          selectDrawerAction={selectDrawerAction}
          toggleSheetInfoDrawer={toggleSheetInfoDrawer}
        />
      </AnesthesiaProvider>
    )
  },
)

GridFlatList.displayName = 'GridFlatList'

const styles = StyleSheet.create({
  dayText: {
    fontFamily: Fonts.bold,
    fontSize: 14,
  },
  dayTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    left: '50%',
    position: 'absolute',
    zIndex: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
  flexRow: {
    flexDirection: 'row',
  },
  negativeMargin: {
    marginTop: -treatmentColumnHeaderHeight,
  },
  negativeMarginV2: {
    marginTop: -addTreatmentButtonHeight,
  },
  xScrollContent: {
    paddingBottom: isWeb ? 20 : 0, // Add padding at bottom for custom bar if web
    overflow: 'hidden', // Hidden to ensure 'long' tasks don't extend scroll area
  },
  treatmentColumnHeaderStyle: {
    height: treatmentColumnHeaderHeight,
  },
  treatmentColumnHeaderStyleV2: {
    height: addTreatmentButtonHeight,
  },
  show: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
})
