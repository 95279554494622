import { useMutation, useApolloClient } from '@apollo/client'
import { TREATMENT_FIELDS } from 'components/AddTreatment/graphql'
import { toast } from 'components/common'
import { UPDATE_CONDITIONAL_TREATMENT } from 'components/Treatment/graphql'
import { FormData } from 'components/TreatmentForm'
import { RepeatScheduleValue } from 'components/TreatmentForm/data'
import {
  getIsFluidFormData,
  getTreatmentInput,
} from 'components/TreatmentForm/utils/getTreatmentInput'
import { useTranslation } from 'react-i18next'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { TreatmentTreeNodeType } from 'src/types/globalTypes'
import {
  updateConditionalTreatment as UpdateConditionalTreatment,
  updateConditionalTreatmentVariables as UpdateConditionalTreatmentVariables,
} from 'src/types/updateConditionalTreatment'

import { Product } from '../SelectProductsScreen'

type UseUpdateConditionalTreatmentParams = {
  sheetId: string
  patientId: string
  treatmentId: string
}

export const useUpdateConditionalTreatment = ({
  sheetId,
  patientId,
  treatmentId,
}: UseUpdateConditionalTreatmentParams) => {
  const [{ organisationId }] = useOrganisation()
  const { t } = useTranslation()
  const { fromToQueryDate } = useTimeResolution()
  const client = useApolloClient()

  const [updateConditionalTreatment] = useMutation<
    UpdateConditionalTreatment,
    UpdateConditionalTreatmentVariables
  >(UPDATE_CONDITIONAL_TREATMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      client.writeFragment({
        data: data.updateTreatment,
        id: `Treatment:${data.updateTreatment.id}`,
        fragment: TREATMENT_FIELDS,
        fragmentName: 'TreatmentFields',
        variables: fromToQueryDate,
      })
      toast.success(t('treatment:editTreatment:success'))
    },
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
  })

  return (formData: FormData, product: Product) => {
    const treatmentInput = getTreatmentInput(formData, product.type)

    const isFluid = getIsFluidFormData(formData, product.type) || formData.isCRI
    const optimisticResponse: UpdateConditionalTreatment = {
      updateTreatment: {
        _optimistic: true,
        conditional: false,
        discontinued_at: null,
        display_states: null,
        name: formData.treatmentName,
        node_type: TreatmentTreeNodeType.TREATMENT,
        parent_treatment_id: '',
        order: null,
        _hidden: null,
        schedule: {
          __typename: 'Schedule',
          repeat: treatmentInput.schedule.repeat,
          start_at: treatmentInput.schedule.start_at ?? null,
          time_window: treatmentInput.schedule.time_window ?? null,
          frequency: treatmentInput.schedule.frequency ?? null,
          repeat_until: treatmentInput.schedule.repeat_until ?? null,
          duration: isFluid ? 60 * 60 * 1000 : null,
          repeat_count: null,
          enable_staffed_hour:
            treatmentInput.schedule.enable_staffed_hour ?? null,
          type: null,
          frequencies: null,
          treatment_frequency_id: null,
        },
        created_at: new Date().toString(),
        is_instructions_important: formData.isInstructionsImportant,
        is_billable: null,
        is_iv_infusion: null,
        updated_at: null,
        value: null,
        treatments: null,
        id: treatmentId,
        instructions: null,
        product: null,
        is_auto_completed: null,
        is_continuous: null,
        medicine_dosage_info: null,
        fluid_dosage_info: null,
        sheet_id: sheetId,
        tasks: null,
        approval_status: null,
        approval_updated_by: null,
        cubex_integration: null,
        __typename: 'Treatment',
      },
    }

    const createTasks = formData.isRepeating !== RepeatScheduleValue.NONE

    updateConditionalTreatment({
      optimisticResponse,
      variables: {
        input: {
          ...treatmentInput,
          conditional: false,
          id: treatmentId,
          name: product?.name,
          organisation_id: organisationId,
          patient_id: patientId,
          product_id: product?.id,
          sheet_id: sheetId,
          option: {
            createTasks,
          },
        },
      },
    })
  }
}
