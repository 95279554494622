import { SvgKebab } from 'components/Icons/Kebab'
import React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'
import { Colors } from 'src/design-system/theme'

type Props = {
  kebabButtonRef?: React.Ref<View>
  onPress: () => void
  isKebabFocused?: boolean
}

export const KebabButton: React.FC<Props> = ({
  kebabButtonRef,
  onPress,
  isKebabFocused,
}) => {
  return (
    <Pressable
      ref={kebabButtonRef}
      style={[styles.kebabContainer, isKebabFocused && styles.kebabFocus]}
      onPress={onPress}
      accessibilityLabel="Sheet Actions"
    >
      <SvgKebab size={25} />
    </Pressable>
  )
}

const styles = StyleSheet.create({
  kebabContainer: {
    width: 38,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  kebabFocus: {
    backgroundColor: Colors.Interactive.clickedOverlay,
  },
})
