import React, { ReactNode, useMemo, useState } from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { ReactElementLike } from 'prop-types'
import {
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'

import { Select } from '../Select/Select'
import { TextInput } from '../TextInput'
import { ControlledSelect } from 'components/common/Select/ControlledSelect'

let idCounter = 0

export type InputGroupProps = {
  children: ReactNode[]
}

export const InputGroup: React.FC<InputGroupProps> = ({ children }) => {
  const inputGroupId = useMemo(() => {
    idCounter = idCounter + 1
    return idCounter
  }, [])

  const [showPicker, setShowPicker] = useState(false)
  const togglePicker = () => setShowPicker(!showPicker)

  const elementChildren = (
    children.filter(
      (child: ReactNode) => child && (child as ReactElementLike).type,
    ) as ReactElementLike[]
  ).map((element, i) => {
    let injectedProps = {}
    if (element.type === TextInput) {
      injectedProps = { inputGroupId }
    } else if (
      (Platform.OS === 'ios' && element.type === Select) ||
      element.type === ControlledSelect
    ) {
      // Make Select open/close together on ios
      injectedProps = {
        open: showPicker,
        onTogglePicker: togglePicker,
      }
    }
    return React.createElement(element.type, {
      ...element.props,
      ...injectedProps,
      key: i,
    })
  }) as ReactElementLike[]

  const childrenWithSeparator: ReactElementLike[] = [elementChildren[0]]
  for (let i = 1; i < elementChildren.length; i = i + 1) {
    childrenWithSeparator.push(
      <View style={styles.separator} key={`separator${i}`} />,
    )
    childrenWithSeparator.push(elementChildren[i])
  }

  return (
    <View>
      <View style={[showPicker && { borderBottomWidth: 1 }, styles.row]}>
        {childrenWithSeparator}
      </View>
    </View>
  )
}

export const InputGroupText: React.FC<{
  a11yLabel?: string
  text: string
  textStyle?: TextStyle
  containerStyle?: ViewStyle
}> = ({ a11yLabel, containerStyle, text, textStyle }) => {
  return (
    <View
      accessibilityLabel={a11yLabel}
      style={[styles.textContainer, containerStyle]}
    >
      <Text style={[styles.text, textStyle]}>{text}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: Colors.borderGrey,
    backgroundColor: Colors.white,
  },
  separator: {
    borderLeftColor: Colors.borderGrey,
    borderLeftWidth: 1,
    height: 60,
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    backgroundColor: Colors.white,
    height: 60,
    minWidth: 70,
  },
  text: {
    fontFamily: Fonts.regular,
    fontSize: 16,
    lineHeight: 22,
  },
})
