import { format, parseISO } from 'date-fns'

import { FluidAction } from '../types'

// only used for update fluid task value
export const getNewTaskNotes = (
  originalTaskValue: string | null,
  inputValue: string,
  inputNotes?: string | null,
) => {
  if (!originalTaskValue) {
    return inputValue
  }
  if (!inputNotes) {
    return ''
  }
  if (inputNotes.includes(originalTaskValue)) {
    return inputNotes.replace(originalTaskValue, inputValue)
  }
  return `${inputNotes}\nFrom ${originalTaskValue} to ${inputValue}.`
}

type TaskNotesInput = {
  notes?: string | null
  given_stop_at?: string | null
  stop_at?: string | null
  given_start_at?: string | null
  start_at?: string | null
}

// only used for start or stop fluid
export const modifyFluidTaskNotesByAction = (
  taskInput: TaskNotesInput,
  dateFormatPattern: string,
  action: FluidAction,
) => {
  let attachedNotes = ''
  const originNotes = taskInput.notes

  // start fluid
  if (action === FluidAction.START) {
    attachedNotes = `Started at ${format(
      parseISO(
        (taskInput.given_start_at || taskInput.start_at) ??
          new Date().toISOString(),
      ),
      dateFormatPattern,
    )}`
  }

  // stop fluid
  if (action === FluidAction.STOP) {
    attachedNotes = `Stopped at ${format(
      parseISO(
        (taskInput.given_stop_at || taskInput.stop_at) ??
          new Date().toISOString(),
      ),
      dateFormatPattern,
    )}`
  }

  return originNotes
    ? `${originNotes}
${attachedNotes}`
    : attachedNotes
}
