import React, { useMemo, useState } from 'react'
import { TextLink } from 'components/common'
import { SvgPlus } from 'components/Icons'
import { EditableSelectedProductRow } from 'components/TreatmentTemplate/ProductPicker/EditableSelectedProductRow'
import { TreatmentOption } from 'components/TreatmentTemplate/TemplateTreatmentForm'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'

export const ShowMoreLessA11Label = 'Show more/less text'
export const PlusButtonA11Label = 'PlusButtonA11Label'

const SELECTED_SHOW_MORE_LIMIT = 3

type Props = {
  title: string
  selectedProducts: TreatmentOption[]
  onPressAdd: () => void
  onRemoveProduct: (id: string) => void
  shouldDisabledAdd?: boolean
  setProductName?: (name: string, id: string) => void
}

export const AddedProducts: React.FC<Props> = ({
  title,
  selectedProducts,
  onPressAdd,
  onRemoveProduct,
  shouldDisabledAdd,
  setProductName,
}) => {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)

  // move new added products to the top
  const shownProducts = useMemo(() => {
    const orderedProducts = orderBy(selectedProducts, 'isOldChild', 'desc')
    if (showMore) {
      return orderedProducts
    }
    return orderedProducts.slice(0, SELECTED_SHOW_MORE_LIMIT)
  }, [selectedProducts, showMore])

  const isMoreProducts = selectedProducts.length > SELECTED_SHOW_MORE_LIMIT
  const hiddenItemsCount = selectedProducts.length - SELECTED_SHOW_MORE_LIMIT

  return (
    <View style={styles.container}>
      <View style={styles.wrapContainer}>
        <TouchableOpacity
          accessibilityLabel={`${PlusButtonA11Label}${title}`}
          onPress={onPressAdd}
          disabled={shouldDisabledAdd}
        >
          <View style={styles.titleContainer}>
            <Text style={styles.label}>{title}</Text>
            <SvgPlus
              color={shouldDisabledAdd ? Colors.disabled : Colors.blue}
              length={15}
            />
          </View>
        </TouchableOpacity>
      </View>

      {shownProducts.map(item => (
        <EditableSelectedProductRow
          key={item.id}
          product={item}
          onRemoveProduct={onRemoveProduct}
          setProductName={setProductName}
        />
      ))}
      {isMoreProducts ? (
        <TextLink
          a11yLabel={ShowMoreLessA11Label}
          text={
            showMore
              ? t('general.showLess')
              : `${t('general.showMore')} (${hiddenItemsCount})`
          }
          onPress={() => setShowMore(previousShowMore => !previousShowMore)}
          containerStyles={styles.textLink}
        />
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 5,
  },
  wrapContainer: {
    padding: 5,
    alignItems: 'flex-start',
  },
  titleContainer: {
    paddingHorizontal: 5,
    flexDirection: 'row',
  },
  textLink: {
    alignItems: 'flex-end',
    paddingHorizontal: 16,
  },
  label: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 13,
    lineHeight: 16,
  },
})
