import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { StyleSheet } from 'react-native'

export const SheetHeaderStyles = StyleSheet.create({
  commentsContainer: {
    maxWidth: '100%',
    borderRadius: 12,
    paddingHorizontal: 12,
    marginTop: 6,
    borderWidth: 2,
    borderColor: Colors.backgroundGrey,
  },
  complicationsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 8,
  },
  rolledUpComplicationsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  complicationsPill: {
    height: 24,
    borderRadius: 12,
    backgroundColor: Colors.blue,
    marginRight: 12,
    marginVertical: 4,
    justifyContent: 'center',
  },
  container: {
    height: 'auto',
    paddingHorizontal: 8,
    paddingTop: 8,
  },
  criticalNotesContainer: {
    backgroundColor: Colors.treatment.missedBackground,
    marginVertical: 3,
    marginHorizontal: 0,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 10,
  },
  detailBox: {
    marginVertical: 3,
    borderRadius: 10,
    alignItems: 'flex-start',
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  asaCatContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 12,
    maxWidth: '100%',
  },
  attendingVetContainer: {
    paddingHorizontal: 0,
    marginTop: 2,
  },
  collapsedAsaCatContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 12,
    width: '100%',
  },
  anesthesiaSidebar: {
    flex: 0,
    flexDirection: 'column',
    // paddingHorizontal: 16,
    justifyContent: 'center',
    height: 'auto',
  },
  sideBarDetailBox: {
    flex: 1,
    borderRadius: 12,
    paddingHorizontal: 12,
    paddingVertical: 10,
  },
  sideBarNotesBox: {
    borderRadius: 12,
    paddingHorizontal: 12,
  },
  sideBarProblemDetailBox: {
    borderRadius: 12,
    paddingHorizontal: 12,
    paddingVertical: 10,
  },
  presentingProblemsBox: {
    flex: 1,
    borderRadius: 12,
    maxWidth: '48%',
    alignItems: 'flex-start',
    paddingHorizontal: 12,
    height: '100%',
  },
  extraDetailsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  extraSmallDetailsRow: {
    paddingHorizontal: 4,
  },
  greyContainer: {
    backgroundColor: Colors.backgroundGrey,
  },
  patientAge: {
    fontFamily: Fonts.bold,
    fontSize: 16,
  },
  hideableRow: {
    paddingVertical: 8,
  },
  marginTop: {
    marginTop: 8,
  },
  selectedComplicationText: {
    color: Colors.white,
    paddingHorizontal: 12,
    justifyContent: 'center',
  },
  smallSizeComplicationText: {
    fontSize: 10,
  },
  textButtonContainer: {
    alignItems: 'flex-end',
    paddingHorizontal: 4,
    minHeight: 38,
  },
  textButtonTouchableField: {
    minHeight: 38,
    justifyContent: 'center',
  },
  textHeight: {
    paddingVertical: 2,
  },
  centerJustify: {
    justifyContent: 'center',
  },
  attendingVetHolder: {
    maxWidth: '70%',
    marginRight: 8,
    marginTop: 2,
    marginLeft: 2,
  },
  attendingVetText: {
    fontSize: 16,
  },
  complicationsSizeContainer: {
    borderRadius: 15,
    backgroundColor: Colors.blue,
    height: 28,
    justifyContent: 'center',
  },
  complicationsSizeText: {
    fontFamily: Fonts.bold,
    fontSize: 14,
    letterSpacing: 0.4,
    lineHeight: 32,
    paddingHorizontal: 15,
    color: 'white',
  },
  timerContainer: {
    justifyContent: 'center',
    height: 'auto',
  },
  timerGap: {
    height: 1,
    backgroundColor: 'lightgrey',
    marginVertical: 4,
  },
  showMoreButton: {
    alignItems: 'center',
    width: 'auto',
    fontFamily: Fonts.semibold,
    color: Colors.buttons.text,
  },
  regularText: {
    fontFamily: Fonts.regular,
    fontSize: 14,
  },
  rowPadding: {
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 4,
  },
  minRowHeight: {
    minHeight: 26,
  },
  mobileRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  row: {
    alignItems: 'baseline',
    flexDirection: 'row',
    flexShrink: 1,
  },
  leftLabelText: {
    flexShrink: 1,
  },
  rightLabelText: {
    flexShrink: 1,
    paddingLeft: 5,
  },
  info: {
    flexGrow: 1,
    height: 'auto',
    justifyContent: 'space-between',
    padding: 8,
  },
})
