import { InMemoryCache } from '@apollo/client'
import { MAGIC_ANESTHESIA_TEMPLATE_NAME } from 'components/CreateSheet/useGetAnesthesiaTemplates'
import { TreatmentNodeDisplayStates } from 'src/types/globalTypes'

import { listWhiteboardPatients } from './whiteboardPatients'
import { listWhiteboardWorkflows } from './whiteboardWorkflows'
import { resultCursor as patientListCursor } from 'src/apollo/cache/patientList'
import { readColor } from 'src/apollo/cache/Consultation/color'

// https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies
export const cache = new InMemoryCache({
  typePolicies: {
    // For VR-4354. https://www.apollographql.com/docs/react/caching/cache-field-behavior/#the-merge-function
    MedicineDosageInfo: {
      merge: true,
    },
    WhiteboardPatientList: {
      fields: {
        resultCursor: patientListCursor,
      },
    },
    WhiteboardWorkflow: {
      fields: {
        resultCursor: patientListCursor,
      },
    },
    Sheet: {
      merge: true,
      fields: {
        _zoom_level: {
          read(zoomLevel = null) {
            return zoomLevel
          },
        },
      },
    },
    Task: {
      fields: {
        _pending: existing => !!existing,
      },
    },
    Treatment: {
      fields: {
        _optimistic: existing => !!existing,
        _hidden: (existing, { readField }) => {
          if (existing !== undefined) {
            return existing
          }
          const name = readField('name') as string
          const displayStates = readField('display_states') as string
          return (
            MAGIC_ANESTHESIA_TEMPLATE_NAME.test(name) ||
            !!displayStates?.includes(TreatmentNodeDisplayStates.HIDDEN)
          )
        },
      },
    },
    WhiteboardPatient: {
      keyFields: ['patient_id', 'consultation_id'],
    },
    Consultation: {
      fields: {
        color: {
          read: readColor,
        },
      },
    },
    ConsultationLocation: {
      keyFields: ['display'],
    },
    Query: {
      fields: {
        listWhiteboardPatients,
        listWhiteboardWorkflows,
      },
    },
  },
})
