import { Task } from 'components/Task/types'
import { getIsCRI } from 'components/Task/utils/getTreatmentInfo'
import { TreatmentSheet } from 'components/Treatment/common/types'
import { startOfMinute } from 'date-fns'
import { cloneDeep, filter, first, isEmpty, isNil, sortBy } from 'lodash'
import { Status } from 'src/types/globalTypes'

import { nonZeroRound } from 'src/hooks/useRound'
import { getSheet_getSheet_treatments_items_medicine_dosage_info as MedicineDosageInfo } from 'src/types/getSheet'
import { TreatmentFieldsBase } from 'src/types/TreatmentFieldsBase'
import { getFluidTaskNumberValue } from './fluidTaskUtils'

export const getFluidTotalUnit = (treatment?: TreatmentSheet | null) => {
  const volumeUnit = getFluidStyleTreatmentVolumeUnit(treatment)
  const timeUnit = getFluidStyleTreatmentTimeUnit(treatment)
  return `${volumeUnit}/${timeUnit}`
}

export const getFluidStyleTreatmentVolumeUnit = (
  treatment?: TreatmentSheet | null,
) => {
  if (getIsCRI(treatment)) {
    return treatment?.medicine_dosage_info?.infusion_rate_volume_unit ?? 'ml'
  }
  return treatment?.fluid_dosage_info?.total_result_unit ?? 'ml'
}

export const getFluidStyleTreatmentTimeUnit = (
  treatment?: TreatmentSheet | null,
) => {
  const timeUnit = treatment?.medicine_dosage_info?.infusion_rate_time_unit
  if (!timeUnit || timeUnit === 'hour') {
    return 'hr'
  }

  return timeUnit
}

export enum TreatmentFluidType {
  Fluid = 'fluid',
  CRI = 'CRI',
  Normal = 'Normal',
}

export const getTreatmentFluidType = (
  treatment: TreatmentFieldsBase,
): TreatmentFluidType => {
  const isCRI = !!treatment.medicine_dosage_info?.is_cri
  if (isCRI) {
    return TreatmentFluidType.CRI
  }
  const isFluid = !isNil(treatment.fluid_dosage_info?.total_result)
  if (isFluid) {
    return TreatmentFluidType.Fluid
  }
  return TreatmentFluidType.Normal
}

export const getTreatmentIsFluidOrCRI = (
  treatment: TreatmentSheet,
): boolean => {
  return getTreatmentFluidType(treatment) !== TreatmentFluidType.Normal
}

export const getFluidValueIsUpdating = (
  oldTreatment: TreatmentSheet,
  updatedTreatment: TreatmentSheet,
): boolean => {
  if (getTreatmentFluidType(oldTreatment) === TreatmentFluidType.Normal) {
    return false
  }
  return (
    getFluidOrCRITreatmentValue(oldTreatment) !==
    getFluidOrCRITreatmentValue(updatedTreatment)
  )
}

export const cloneFluidTreatmentUpdateValue = <T extends TreatmentFieldsBase>(
  treatment: T,
  taskValue: string,
  roundingPrecision: number,
) => {
  const taskValueNumber = getFluidTaskNumberValue(taskValue)
  if (!taskValueNumber) {
    return treatment
  }

  const clonedTreatment = cloneDeep(treatment)

  updateFluidTreatmentTotalValue(
    clonedTreatment,
    taskValueNumber,
    roundingPrecision,
  )

  return clonedTreatment
}

// impure function for updating fluid treatment total value
export const updateFluidTreatmentTotalValue = (
  treatment: TreatmentFieldsBase,
  taskValueNumber: number,
  roundingPrecision: number,
) => {
  const treatmentFluidType = getTreatmentFluidType(treatment)
  if (
    treatmentFluidType === TreatmentFluidType.Fluid &&
    treatment.fluid_dosage_info
  ) {
    treatment.fluid_dosage_info = {
      ...treatment.fluid_dosage_info,
      total_result: taskValueNumber,
    }
  }
  if (
    treatmentFluidType === TreatmentFluidType.CRI &&
    treatment.medicine_dosage_info
  ) {
    treatment.medicine_dosage_info = getCRIMedicineDosageInfo(
      treatment.medicine_dosage_info,
      taskValueNumber,
      roundingPrecision,
    )
  }
}

const getCRIMedicineDosageInfo = (
  medicineDosageInfo: MedicineDosageInfo,
  taskValueNumber: number,
  roundingPrecision: number,
) => {
  // all new CRI treatments have dose_rate, if this is CRI v1
  if (!medicineDosageInfo.dose_rate) {
    return {
      ...medicineDosageInfo,
      infusion_rate_total: taskValueNumber,
    }
  }

  const { infusion_rate_total, dosage, dose_rate } = medicineDosageInfo
  // no calculator when 0 or null
  if (!infusion_rate_total || !dosage || !dose_rate || !taskValueNumber) {
    return {
      ...medicineDosageInfo,
      infusion_rate_total: taskValueNumber,
    }
  }
  //  dosage and dose_rate should be changed in both diluted and undiluted situation.
  const rate = taskValueNumber / infusion_rate_total
  const newDosage = nonZeroRound(dosage * rate, roundingPrecision)
  const newDoseRate = nonZeroRound(dose_rate * rate, roundingPrecision)

  return {
    ...medicineDosageInfo,
    infusion_rate_total: taskValueNumber,
    dosage: newDosage,
    dose_rate: newDoseRate,
  }
}

export const getFluidOrCRITreatmentValue = (
  treatment: TreatmentSheet | null,
): string => {
  if (!treatment) {
    return ''
  }
  const treatmentFluidType = getTreatmentFluidType(treatment)

  if (treatmentFluidType === TreatmentFluidType.CRI) {
    return `${
      treatment.medicine_dosage_info?.infusion_rate_total ?? 0
    }${getFluidTotalUnit(treatment)}`
  }

  if (treatmentFluidType === TreatmentFluidType.Fluid) {
    return `${
      treatment.fluid_dosage_info?.total_result ?? 0
    }${getFluidTotalUnit(treatment)}`
  }
  return ''
}

export const getStartingFluidTask = (tasks: Task[]): Task | undefined => {
  return tasks.find(task => task.status === Status.IN_PROGRESS)
}

export const getInitFluidTask = (tasks: Task[]): Task | undefined => {
  const filteredTasks = filter(
    tasks,
    task =>
      (task.status === Status.MISSED ||
        task.status === Status.DUE ||
        task.status === Status.PENDING) &&
      !task.given_stop_at,
  )

  // find and return init task
  const initFluidTask = filteredTasks.find(task => !task.given_start_at)
  if (initFluidTask) {
    return initFluidTask
  }

  if (isEmpty(filteredTasks)) {
    return
  }

  const sortedTasks = sortBy(filteredTasks, task => {
    if (!task.given_start_at) {
      return task.start_at ?? ''
    }
    return task.given_start_at
  })

  // the init task was reset or restarted a fluid task on the same line
  return first(sortedTasks)
}

// shallow clone and update task for cache
export const getUpdateTask = (
  updatedTreatment: TreatmentSheet,
  tasks: Task[],
): Task | null => {
  const startingTask = getStartingFluidTask(tasks)
  if (startingTask) {
    return {
      ...startingTask,
      given_stop_at: startOfMinute(new Date()).toISOString(),
      status: Status.DONE,
    }
  }

  const initTask = getInitFluidTask(tasks)

  if (initTask) {
    return { ...initTask, value: getFluidOrCRITreatmentValue(updatedTreatment) }
  }
  return null
}
