import { WeightUnit } from './Weight'

export const PatientWeightUnitOptions = [
  { text: `kg`, value: WeightUnit.KG },
  { text: `g`, value: WeightUnit.G },
  { text: `lb`, value: WeightUnit.LB },
  { text: `oz`, value: WeightUnit.OZ },
]
export const findWeightUnit: (
  weightUnit: string | null,
) => WeightUnit = weightUnit => {
  if (weightUnit === 'lbs') {
    // for VR-4214, conver "lbs" to "lb"
    return WeightUnit.LB
  }
  return (
    PatientWeightUnitOptions.find(option => option.value === weightUnit)
      ?.value ?? WeightUnit.KG
  )
}
