import React, { useCallback, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { useDeleteTreatment } from 'components/Treatment/utils/useDeleteTreatment'
import { SelectProductsScreen, Product } from './SelectProductsScreen'
import { SetupConditionalFieldsScreen } from './SetupConditionalFieldsScreen'
import { useConditionalTreatment } from './utils/useConditionalTreatment'

type Props = {
  onDone: () => void
  treatmentId: string
  patientId: string
  sheetId: string
}

export const TreatmentSetupDrawerContent: React.FC<Props> = ({
  onDone,
  treatmentId,
  patientId,
  sheetId,
}) => {
  const {
    loading,
    conditionalTreatment,
    products,
    productGroups,
    isSingleOption,
    isSingleSelect,
  } = useConditionalTreatment({
    patientId,
    treatmentId,
  })

  const deleteTreatment = useDeleteTreatment({
    patientId,
    sheetId,
    id: treatmentId,
    disableToast: true,
  })

  const [selectedProducts, setSelectedProducts] = useState<Product[]>()
  const [isDeleteTreatmentSelected, setIsDeleteTreatmentSelected] =
    useState<boolean>(false)

  const deleteConditionalTreatment = useCallback(() => {
    deleteTreatment()
    onDone()
  }, [deleteTreatment, onDone])

  if (loading) {
    return <ActivityIndicator size="large" style={{ marginTop: 25 }} />
  }

  if (!conditionalTreatment) {
    // should be an error page
    return null
  }

  if (isSingleOption || selectedProducts) {
    return (
      <SetupConditionalFieldsScreen
        sheetId={sheetId}
        patientId={patientId}
        conditionalTreatment={conditionalTreatment}
        isSingleOption={isSingleOption}
        selectedProducts={isSingleOption ? products : selectedProducts!}
        deleteTreatment={deleteConditionalTreatment}
        isDeleteTreatmentSelected={isDeleteTreatmentSelected}
        onDone={onDone}
      />
    )
  }

  return (
    <SelectProductsScreen
      treatmentName={conditionalTreatment.name}
      onSelect={setSelectedProducts}
      productGroups={productGroups}
      products={products}
      deleteTreatment={deleteConditionalTreatment}
      isSingleSelect={!!isSingleSelect}
      isDeleteTreatmentSelected={isDeleteTreatmentSelected}
      setIsDeleteTreatmentSelected={setIsDeleteTreatmentSelected}
    />
  )
}
