import { noop } from 'lodash'
import React, { useState } from 'react'
import { FlatList, Pressable, StyleSheet, Text } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'

type Props = {
  value: Date
  data: TimeOption[]
  showsVerticalScrollIndicator?: boolean
  showsHorizontalScrollIndicator?: boolean
  setIsOpen: (state: boolean) => void
  onChange: (date: Date) => void
  initialIndex?: number
}

type TimeOption = {
  text: string
  value: Date
}

// Formatting Constants
export const TIMEPICKER_ITEM_HEIGHT = 48
export const TIMEPICKER_HEIGHT = TIMEPICKER_ITEM_HEIGHT * 5 // 5 * item height
export const TIMEPICKER_HEIGHT_BUFFER = 80

export const TimePickerOptions: React.FC<Props> = ({
  value,
  data,
  showsHorizontalScrollIndicator = false,
  showsVerticalScrollIndicator = false,
  setIsOpen,
  onChange,
  initialIndex,
}) => {
  return (
    <FlatList
      style={[styles.flatList]}
      showsVerticalScrollIndicator={showsVerticalScrollIndicator}
      showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
      data={data}
      keyExtractor={item => `key-${item.value.getTime()}`}
      initialScrollIndex={initialIndex}
      renderItem={({ item }) => (
        <RenderOption
          value={item.value}
          label={item.text}
          selected={value.getTime() === item.value.getTime()}
          onPress={d => {
            setIsOpen(false)
            onChange(d)
          }}
        />
      )}
      getItemLayout={(_data, index) => ({
        length: TIMEPICKER_ITEM_HEIGHT,
        offset: TIMEPICKER_ITEM_HEIGHT * index,
        index,
      })}
    />
  )
}

type RenderOptionPickerProps = {
  value: Date
  label: string
  selected: boolean
  onPress: (date: Date) => void
}

const RenderOption: React.FC<RenderOptionPickerProps> = ({
  value,
  label,
  selected = false,
  onPress = noop,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Pressable
      style={[
        styles.button,
        isHovered && styles.itemHover,
        selected && styles.itemSelected,
      ]}
      onPress={() => onPress(value)}
      disabled={false}
      // @ts-ignore
      onHoverOut={() => setIsHovered(false)}
      onHoverIn={() => setIsHovered(true)}
    >
      <Text style={styles.itemText}>{label}</Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  flatList: {
    width: '100%',
    height: TIMEPICKER_HEIGHT,
    backgroundColor: 'white',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    shadowColor: Colors.Contents.primary,
    zIndex: 1,
  },
  itemHover: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  itemSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  itemText: {
    fontSize: 16,
    fontFamily: Typography.FontFamilies.base,
  },
  button: {
    paddingVertical: 13,
    paddingHorizontal: 22,
  },
})
