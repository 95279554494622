import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Pill } from '../Pill'
import { Product } from './types'
import { ProductRow } from './components'
import { useBusinessUnitWarning } from './hooks/useBusinessUnitWarning'
import { HelpTooltip } from '../Tooltip/HelpTooltip'

type Props = {
  product: Product
  searchWords: string[]
  attendingDepartment?: string | null
}

export const FixedQuantityProductRow: React.FC<Props> = ({
  product,
  searchWords = [],
  attendingDepartment,
}) => {
  const { t } = useTranslation()
  const { isSiteValid, BusinessUnitWarning } = useBusinessUnitWarning(
    product,
    attendingDepartment,
  )
  return (
    <ProductRow.Container>
      <View style={styles.start}>
        <View style={styles.headerContainer}>
          <ProductRow.Heading
            searchWords={searchWords}
            text={product.name}
            disabled={!isSiteValid}
          />
          {BusinessUnitWarning}
        </View>
        <HelpTooltip text={t('addTreatment:fixedQuantityTip')} />
      </View>
      <View style={styles.end}>
        <Pill text={`Qty ${product.quantity}`} size="small" />
      </View>
    </ProductRow.Container>
  )
}

export const styles = StyleSheet.create({
  start: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    minWidth: 0,
  },
  headerContainer: {
    display: 'flex',
    flexShrink: 1,
  },
  end: {},
})
