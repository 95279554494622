import { NextLink, Operation } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { sessionHeaders } from 'src/utils/errorTracking/sessionInfo'
import { promiseToZenObservable } from 'src/utils/promiseToZenObservable'

import { getAccessToken } from './getAccessToken'
import { sessionErrorToast } from './sessionErrorToast'
import { signOut } from 'src/utils/signOut'

// Apollo Server code when AuthenticationError thrown
// Note: Refresh tokens // cannot be refreshed - see:
// https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-the-refresh-token.html
const AUTH_ERROR_GRAPQHQL_CODE = 'UNAUTHENTICATED'

type RetryAuthGraphQLParams = {
  graphQLError: GraphQLError
  operation: Operation
  forward: NextLink
}

let isRefreshing = false
let pendingRequests: ((value: void | PromiseLike<void>) => void)[] = []

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback())
  pendingRequests = []
}

export const retryAuthGraphQL = ({
  graphQLError,
  operation,
  forward,
}: RetryAuthGraphQLParams) => {
  // When AuthError in resolver, try to refresh auth token
  if (graphQLError.extensions?.code !== AUTH_ERROR_GRAPQHQL_CODE) return

  // Avoids updating token many times at once
  if (isRefreshing) {
    // Will only emit once the Promise is resolved
    return promiseToZenObservable(
      new Promise<void>(resolve => {
        pendingRequests.push(resolve)
      }),
    ).flatMap(() => forward(operation))
  }

  isRefreshing = true
  return promiseToZenObservable(
    getAccessToken()
      .then(accessToken => {
        resolvePendingRequests()
        return accessToken
      })
      .catch(error => {
        // Updating token failed, clear pending, toast and sign out
        pendingRequests = []
        sessionErrorToast(error)
        return signOut()
      })
      .finally(() => {
        isRefreshing = false
      }),
  )
    .filter(value => !!value)
    .flatMap(newToken => {
      const prevHeaders = operation.getContext().headers
      operation.setContext({
        headers: {
          ...prevHeaders,
          ...sessionHeaders,
          authorization: newToken,
        },
      })
      // returning the new observable to retry the request
      return forward(operation)
    })
}
