import { addMinutes, startOfDay } from 'date-fns'
import { TimeSegment, TimeSegmentMinutes } from 'src/hocs/types/time'

export type GetGridTimesParams = {
  segmentsInDay: number
  timeStart: Date
  timeSegment: TimeSegment
}

type GetGridTimes = (args: GetGridTimesParams) => Date[]

export const getGridTimes: GetGridTimes = ({
  segmentsInDay,
  timeStart,
  timeSegment,
}) =>
  Array(segmentsInDay)
    .fill(null)
    .map(addMinutesFn(timeStart, TimeSegmentMinutes[timeSegment]))

const addMinutesFn =
  (timeStart: Date, minutes: number) => (_e: unknown, i: number) =>
    addMinutes(startOfDay(timeStart), i * minutes)
