import { SyncStorage } from 'src/utils/SyncStorage'

const ORGANISATION_ID = 'organisationId'

export const userOrganisationService = {
  setOrganisationId(organisationId: string): void {
    SyncStorage.setItem(ORGANISATION_ID, organisationId)
  },

  getOrganisationId(): string {
    return SyncStorage.getItem(ORGANISATION_ID) ?? ''
  },

  deleteOrganisationId(): void {
    SyncStorage.removeItem(ORGANISATION_ID)
  },
}
