import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  color?: string
}

export const StripedStatusBorder: React.FC<Props> = ({ color = '#75818D' }) => (
  <Svg>
    <Path
      d="M0.442708 0H6C7.10457 0 8 0.895432 8 2V3.77865L0.442708 0ZM8 8.02129V12.7786L0 8.77865V4.02129L8 8.02129ZM8 21.7786V17.0213L0 13.0213V17.7786L8 21.7786ZM8 26.0213V30.7786L0 26.7786V22.0213L8 26.0213ZM8 39.7786V35.0213L0 31.0213V35.7786L8 39.7786ZM8 44.0213V48.7786L0 44.7786V40.0213L8 44.0213ZM8 57.7786V53.0213L0 49.0213V53.7786L8 57.7786ZM3.95743 60H0V58.0213L3.95743 60Z"
      fill={color}
    />
  </Svg>
)
