import { Workbox } from 'workbox-window'
import { toast } from 'components/common/Toast/ToastArea'
import i18next from 'i18next'
import { environment } from './config'

if (environment.isProduction && 'serviceWorker' in navigator) {
  const wb = new Workbox('service-worker.js')
  wb.addEventListener('installed', event => {
    if (event.isUpdate) {
      toast.reload(i18next.t('general.updates'), () => window.location.reload())
    }
  })
  wb.register()
}
