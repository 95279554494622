import { cloneDeep, isNil, maxBy, uniqBy } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { GET_SHEET } from 'src/components/Sheet/graphql'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'
import { addTemplateToSheet_addTemplateToSheet } from 'src/types/addTemplateToSheet'
import { getSheet, getSheetVariables } from 'src/types/getSheet'
import { TreatmentGroupFields } from 'src/types/TreatmentGroupFields'
import { useSheetContext, SheetActionTypes } from 'src/context/sheet'
import { isValid } from 'date-fns'

const getNewTreatmentStartTime = (treatment: CreatedTreatment) => {
  // find the newest treatment, the created_at is the time the treatment be written into the DB. VR-6984
  const newTreatment = maxBy(treatment?.treatments?.items ?? [], 'created_at')
  const startTime = newTreatment?.schedule?.start_at
  if (!startTime) return null

  const startDate = new Date(startTime)
  if (!isValid(startDate)) return null

  return startDate
}

/**
 * Update Treatments in apollo cache
 * @param sheetId
 */

type CreatedTreatment =
  | addTemplateToSheet_addTemplateToSheet
  | TreatmentGroupFields
  | null

export const useUpdateTreatments = (sheetId: string) => {
  const [, setSheetContext] = useSheetContext()
  const [{ organisationId }] = useOrganisation()
  const { fromToQueryDate } = useTimeResolution()
  const client = useApolloClient()

  const query = GET_SHEET
  const variables = {
    id: sheetId,
    organisation_id: organisationId,
    ...fromToQueryDate,
  }

  return (createdTreatment: CreatedTreatment) => {
    if (!createdTreatment) {
      return
    }

    try {
      let data = client.readQuery<getSheet, getSheetVariables>({
        query,
        variables,
      })
      data = cloneDeep(data)
      const createdTreatments = createdTreatment?.treatments?.items ?? []
      const currentTreatmentGroup = data?.getSheet?.treatments?.items?.find(
        item =>
          isNil(item.product?.id)
            ? item.name === createdTreatment.name
            : item.product?.id === createdTreatment.product?.id,
      )
      const isTemplate = !createdTreatment.product
      if (!!currentTreatmentGroup) {
        createdTreatments.forEach(treatment => {
          if (currentTreatmentGroup?.treatments?.items) {
            currentTreatmentGroup.treatments.items.push(treatment)
            currentTreatmentGroup.treatments.items = uniqBy(
              currentTreatmentGroup.treatments.items,
              'id',
            )
          }
        })
      } else {
        // Has no group
        data!.getSheet!.treatments!.items?.push(createdTreatment)
        data!.getSheet!.treatments!.items = uniqBy(
          data!.getSheet!.treatments!.items,
          'id',
        )
      }

      /* Set last created task start, to start */
      if (!isTemplate) {
        const newTreatmentStartTime = getNewTreatmentStartTime(createdTreatment)
        if (newTreatmentStartTime) {
          setSheetContext({
            type: SheetActionTypes.setLastTaskStartTime,
            lastTaskScheduleStartTime: newTreatmentStartTime,
          })
        }
      }

      client.writeQuery({ query, variables, data })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error, optimistic updateTreatment failed', error)
    }
  }
}
