import { FontAwesome5 } from '@expo/vector-icons'
import { SvgDownload } from 'components/Icons/Download'
import { LinearGradient } from 'expo-linear-gradient'
import React, { useState } from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ImageBackground,
  ActivityIndicator,
} from 'react-native'
import { Colors } from 'src/design-system/theme'

type ThumbnailProps = {
  uri: string
  type?: 'image' | 'video'
  remove?: () => void
  download?: () => Promise<void>
}

export const ThumbNail: React.FC<ThumbnailProps> = ({
  uri,
  type,
  remove,
  download,
}) => {
  const [loading, setLoading] = useState(false)

  const handleDownload = async () => {
    if (download) {
      setLoading(true)
      await download()
      setLoading(false)
    }
  }

  return (
    <View style={styles.thumbnail}>
      <ImageBackground style={styles.thumbnailImage} source={{ uri }}>
        <LinearGradient
          colors={['#00000099', 'transparent']}
          style={styles.thumbnailGradient}
        >
          {!!type && (
            <FontAwesome5
              name={type === 'video' ? 'video' : 'camera'}
              style={{ opacity: 0.5 }}
              color={IconColor}
              size={IconSize}
            />
          )}
          <View style={styles.thumbnailButtons}>
            {!!download && !loading && (
              <TouchableOpacity onPress={handleDownload}>
                <SvgDownload color={IconColor} size={IconSize} />
              </TouchableOpacity>
            )}
            {!!loading && <ActivityIndicator size="small" color={IconColor} />}
            {!!remove && (
              <TouchableOpacity onPress={remove}>
                <FontAwesome5
                  name="trash"
                  color={Colors.Contents.negative}
                  size={IconSize}
                />
              </TouchableOpacity>
            )}
          </View>
        </LinearGradient>
      </ImageBackground>
    </View>
  )
}

const IconColor = Colors.Contents.onColor
const IconSize = 16

const styles = StyleSheet.create({
  thumbnail: {
    flexGrow: 1,
    flexShrink: 1,
    width: '30%',
    maxWidth: '33%',
    aspectRatio: 1,
    borderWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  thumbnailImage: {
    flex: 1,
    backgroundColor: Colors.Contents.primary,
  },
  thumbnailGradient: {
    padding: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  thumbnailButtons: {
    flexDirection: 'row',
    gap: 8,
  },
})
