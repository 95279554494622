import { DialogHeights } from '../Dialog/common'
import { InputStatus } from '../Form/utils'
import { FormField } from '../Form/FormField'
import { StyleProp, TextStyle } from 'react-native'

export type Mode = 'datetime' | 'time' | 'date' | 'keyboardTime'
export enum WarningType {
  TASK = 'TASK',
  RESCHEDULE = 'RESCHEDULE',
  TREATMENT = 'TREATMENT',
}

export type Props = {
  minimumDateValue?: Date
  maximumDateValue?: Date
  defaultValue?: Date
  disabled?: boolean
  dialog?: boolean
  label?: string
  mode?: Mode
  onChange: (date: Date) => void
  status?: InputStatus | null
  value?: Date | null
  dialogHeight?: DialogHeights
  FieldComponent?: typeof FormField
  textStyle?: StyleProp<TextStyle>
  labelStyle?: StyleProp<TextStyle>
  title?: string
  warningInfo?: {
    type: string
    minDateTime?: Date
  }
  setIsBeforeMinimum?: (status: boolean) => void
}
