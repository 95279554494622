import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Routes } from 'constants/Routes'
import { CommonActions } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { useOrganisation } from 'src/context/organisation'
import {
  createTemplate as CreateTemplate,
  createTemplateVariables as CreateTemplateVariables,
} from 'types/createTemplate'

import { toast } from '../common'
import { SubHeader } from '../SubHeader/SubHeader'
import { CREATE_TEMPLATE, GET_TREATMENT_TEMPLATES } from './graphql'
import { Inputs, TemplateForm } from './TemplateForm'
import { SettingsStackParamList } from 'components/Settings/screens'
import { client } from 'src/apollo/client'
import {
  getTemplates as GetTemplates,
  getTemplates_getTemplates_items as TemplateItem,
  getTemplatesVariables as GetTemplatesVariables,
} from 'types/getTemplates'
import { cloneDeep } from 'lodash'
import { useTranslation } from 'react-i18next'

type Props = StackScreenProps<SettingsStackParamList>

const useCreateTemplateMutation = () => {
  const [{ organisationId }] = useOrganisation()
  const { t } = useTranslation()
  const [createTemplate] = useMutation<CreateTemplate, CreateTemplateVariables>(
    CREATE_TEMPLATE,
    {
      onCompleted: data => {
        try {
          const newTemplate = data.createTemplate

          toast.success(
            t('template:create.success', {
              name: newTemplate.name,
            }),
          )

          const query = GET_TREATMENT_TEMPLATES
          const variables = { organisation_id: organisationId }
          const getTemplatesData = cloneDeep(
            client.readQuery<GetTemplates, GetTemplatesVariables>({
              query,
              variables,
            }),
          )

          if (
            !newTemplate ||
            !getTemplatesData ||
            !getTemplatesData.getTemplates.items
          ) {
            return
          }

          getTemplatesData.getTemplates.items?.push(newTemplate as TemplateItem)
          client.writeQuery({ query, variables, data: getTemplatesData })
        } catch {
          // ignore if can't find GET_TEMPLATES in cache
        }
      },
      onError: err => {
        toast.error(err.message)
      },
    },
  )
  return createTemplate
}

export const AddTemplateScreen: React.FC<Props> = ({ navigation }) => {
  const [{ organisationId }] = useOrganisation()
  const [submitLoading, setSubmitLoading] = useState(false)
  const { navigate } = navigation

  const backButton = {
    title: 'templates.treatmentTitle',
    label: 'templates.returnHereLabel',
    action: () => navigate(Routes.TreatmentTemplateList),
  }

  const createTemplate = useCreateTemplateMutation()

  const onSubmit = async (values: Inputs) => {
    setSubmitLoading(true)
    const input = {
      ...values,
      description: values.description.length ? values.description : null,
      organisation_id: organisationId,
    }

    const { data } = await createTemplate({
      variables: { input },
    })

    const templateId = data?.createTemplate.id

    if (templateId) {
      setSubmitLoading(false)
      // Move back to rewrite the stack nav history
      navigation.dispatch(CommonActions.goBack())
      navigate(Routes.EditTemplate, {
        templateId,
      })
    }
  }

  return (
    <>
      <SubHeader headline="Create template" backButton={backButton} />
      <TemplateForm handleSubmit={onSubmit} submitting={submitLoading} />
    </>
  )
}
