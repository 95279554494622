import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Label } from 'src/design-system/components/Text'
import { Colors } from 'src/design-system/theme/colors'

export const DividerWithText: React.FC<{
  text: string
}> = ({ text }) => {
  return (
    <View style={[styles.container]}>
      <View style={styles.line} />
      <Label size={'M'} bold={true} style={{ color: Colors.Contents.tertiary }}>
        {text}
      </Label>
      <View style={styles.line} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
  },
  line: {
    flex: 1,
    height: 1,
    backgroundColor: Colors.Borders.primary,
  },
})
