import React from 'react'
import { SvgArrow } from 'components/Icons'
import { ClipButton } from 'components/common/ClipButton'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { isEmpty, noop } from 'lodash'
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  Platform,
  ImageStyle,
} from 'react-native'
import { InputStatus } from '../utils'
import { Avatar } from '../../Avatar'
import { ClipButtonOption } from 'components/common/NestedListSelect/types'

export type FormFieldProps = {
  a11yLabel?: string
  active?: boolean
  activeOpacity?: number
  children?: React.ReactElement | false
  disabled?: boolean
  required?: boolean
  icon?: React.ReactElement
  iconLeft?: React.ReactElement
  label?: string
  onLayout?: (e: LayoutChangeEvent) => void
  onPress?: (e: GestureResponderEvent) => void
  status?: InputStatus | null
  style?: StyleProp<ViewStyle>
  value: string | React.ReactElement
  hideChevron?: boolean
  rightLabel?: string // only in shared/Field
  textStyle?: StyleProp<TextStyle>
  labelStyle?: StyleProp<TextStyle>
  iconRight?: React.ReactElement
  textWrapperStyle?: StyleProp<ViewStyle>
  avatarExist?: boolean
  avatarStyle?: StyleProp<ViewStyle & ImageStyle>
  selectedOptionButtons?: ClipButtonOption[]
  selectedOptionButtonAction?: (value: string) => void
  activeParentSelector?: ClipButtonOption | null
  clearAllSelectedOptions?: () => void
}

const SvgArrowIcon = (
  <SvgArrow orient="down" color="grey" width={15} height={15} />
)

export const FormField: React.FC<FormFieldProps> = ({
  a11yLabel,
  activeOpacity,
  children,
  active = false,
  disabled = false,
  required = false,
  icon = SvgArrowIcon,
  iconLeft,
  label,
  onLayout = noop,
  onPress = noop,
  style,
  value,
  hideChevron,
  textStyle,
  labelStyle,
  iconRight,
  textWrapperStyle,
  avatarExist = false,
  avatarStyle,
  selectedOptionButtons,
  selectedOptionButtonAction,
  activeParentSelector,
  clearAllSelectedOptions,
}) => {
  const containerStyle: StyleProp<ViewStyle> = [styles.container]
  if (style) {
    if (Array.isArray(style)) {
      containerStyle.push(...style)
    } else {
      containerStyle.push(style)
    }
  }

  // For VR-2924: Disabled TouchableOpacity's text is non-selectable
  const Container = disabled ? View : TouchableOpacity

  const shouldShowContentRow =
    !!activeParentSelector?.text || !isEmpty(selectedOptionButtons)

  return (
    // @ts-ignore
    <Container
      accessibilityLabel={a11yLabel ?? label}
      disabled={disabled}
      onLayout={onLayout}
      onPress={onPress}
      activeOpacity={activeOpacity}
      style={containerStyle}
    >
      {!!avatarExist && typeof value === 'string' && (
        <Avatar name={value ?? ''} style={avatarStyle} />
      )}
      <View style={styles.centerLabel}>
        <View style={styles.selectLabel}>
          {!!label && <Text style={[styles.label, labelStyle]}>{label}</Text>}
          {!!required && <Text style={styles.requiredLabel}>*</Text>}
        </View>
        <View style={[styles.selectDropDownArrow, textWrapperStyle]}>
          {iconLeft}
          <Text
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={[
              active && styles.active,
              disabled && styles.disabled,
              !!iconLeft && styles.valuePadding,
              !shouldShowContentRow && { minHeight: 22 },
              styles.value,
              textStyle,
            ]}
          >
            {value}
          </Text>
        </View>
        {shouldShowContentRow && (
          <View style={styles.contentRow}>
            {!!activeParentSelector?.value && (
              <Text>
                {!!activeParentSelector?.text &&
                  `${activeParentSelector.text}: `}
              </Text>
            )}
            {!isEmpty(selectedOptionButtons) && (
              <View style={styles.selectedOptionButtonContainer}>
                {selectedOptionButtons!.map((clipButtonOption, idx) => {
                  return (
                    <ClipButton
                      key={idx}
                      style={styles.selectedOptionButton}
                      title={clipButtonOption.text}
                      onPress={() => {
                        !!selectedOptionButtonAction &&
                          selectedOptionButtonAction(clipButtonOption.value)
                      }}
                    />
                  )
                })}
              </View>
            )}
          </View>
        )}
      </View>
      {children}
      {
        // the user shouldn't be able to use the Clear button when the formField is disabled
        !isEmpty(selectedOptionButtons) && !disabled && (
          <TouchableOpacity onPress={clearAllSelectedOptions ?? noop}>
            <View style={styles.textContainer}>
              <Text style={[styles.active, styles.bottomPadding]}>Clear</Text>
            </View>
          </TouchableOpacity>
        )
      }
      {!hideChevron && <View style={styles.icon}>{icon}</View>}
      {iconRight}
    </Container>
  )
}

const styles = StyleSheet.create({
  active: {
    color: Colors.blue,
  },
  centerLabel: {
    marginRight: 'auto',
  },
  selectLabel: {
    flexDirection: 'row',
  },
  selectDropDownArrow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    borderLeftColor: Colors.white,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    paddingLeft: 16,
  },
  disabled: {
    color: Colors.disabled,
  },
  icon: {
    padding: Platform.OS === 'web' ? 16 : 6,
    marginRight: Platform.OS === 'web' ? 8 : 4,
  },
  label: {
    fontFamily: Fonts.regular,
    fontSize: 13,
    lineHeight: 19,
    color: Colors.contentTertiary,
  },
  value: {
    fontFamily: Fonts.regular,
    fontSize: 16,
    lineHeight: 22,
  },
  valuePadding: {
    paddingHorizontal: 6,
  },
  requiredLabel: {
    marginTop: 0,
    color: 'red',
  },
  selectedOptionButton: {
    height: 20,
    padding: 8,
    margin: 4,
    maxWidth: 240,
  },
  contentRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  textContainer: {
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedOptionButtonContainer: {
    flexDirection: 'row',
    maxWidth: 700,
    overflow: 'hidden',
  },
  bottomPadding: {
    alignSelf: 'center',
    marginRight: 0,
    paddingBottom: 14,
  },
})
