import { SelectOption } from 'src/components/common/Select/Select.types'
import { ApprovalStatus, Attitude, Resuscitation } from 'src/types/globalTypes'

export enum PatientSex {
  M = 'M',
  MN = 'MN',
  F = 'F',
  FS = 'FS',
  UN = 'Unknown',
}

export enum EquineSex {
  Stallion = 'Stallion',
  Gelding = 'Gelding',
  Colt = 'Colt',
  Mare = 'Mare',
  Filly = 'Filly',
  Unknown = 'Unknown',
}

export const sexOptions: SelectOption<PatientSex | string>[] = [
  {
    value: PatientSex.M,
    text: 'M',
  },
  {
    value: PatientSex.MN,
    text: 'MN',
  },
  {
    value: PatientSex.F,
    text: 'F',
  },
  {
    value: PatientSex.FS,
    text: 'FS',
  },
  {
    value: PatientSex.UN,
    text: 'Unknown',
  },
]

export const equineSexOptions: SelectOption<PatientSex | string>[] = [
  {
    value: EquineSex.Mare,
    text: EquineSex.Mare,
  },
  {
    value: EquineSex.Filly,
    text: EquineSex.Filly,
  },
  {
    value: EquineSex.Stallion,
    text: EquineSex.Stallion,
  },
  {
    value: EquineSex.Colt,
    text: EquineSex.Colt,
  },
  {
    value: EquineSex.Gelding,
    text: EquineSex.Gelding,
  },
  {
    value: EquineSex.Unknown,
    text: EquineSex.Unknown,
  },
]

export const approvalStatusOptions = [
  { value: ApprovalStatus.APPROVED, text: 'Approved' },
  { value: ApprovalStatus.PENDING, text: 'Pending approval' },
]

export const resuscitateOptions = [
  { text: 'Do not resuscitate', value: Resuscitation.NO },
  { text: 'Resuscitation', value: Resuscitation.YES },
  {
    text: 'Resuscitation with advanced life support',
    value: Resuscitation.RESUCITATE_PLUS,
  },
]

export const attitudeOptions = [
  {
    text: 'Caution',
    value: Attitude.CAUTION,
  },
  {
    text: 'Friendly',
    value: Attitude.FRIENDLY,
  },
  {
    text: 'Unfriendly',
    value: Attitude.UNFRIENDLY,
  },
  {
    text: 'Unknown',
    value: Attitude.UNKNOWN,
  },
]

export const backgroundColorOptions = [
  '#FDE9B4',
  '#C4EBCB',
  '#FEC7C2',
  '#E0CDF8',
  '#BFEBFE',
  '#FED6BB',
]
