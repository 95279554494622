import { StyleProp, ViewStyle } from 'react-native'
import { RenderItemParams } from 'react-native-draggable-flatlist'
export type DraggableListProps<T extends ListItemT> = {
  a11yLabel?: string
  items: T[]
  isNestedDraggable?: boolean
  isDragDisabled?: boolean
  renderItem: (params: RenderItemParams<T>) => React.ReactNode
  onMoveEnd: (items: T[]) => any
  containerStyle?: StyleProp<ViewStyle>
  /**
   * Rendered at the very beginning of the list.
   */
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null
  /**
   * Rendered at the very end of the list.
   */
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null
}

export type ListItemT = {
  id: string
}

export const reorder = <T,>(
  list: T[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
