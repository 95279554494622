import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormField } from '../Form/FormField'
import {
  Animated,
  View,
  Modal as NativeModal,
  Dimensions,
  StyleSheet,
  Text,
  StyleProp,
  ViewStyle,
  Pressable,
  TextStyle,
} from 'react-native'
import { ListMeasurements } from '../NestedListSelect/types'
import { useWebOutsideClick } from 'src/hooks/useOutsideClick'
import { Colors } from 'constants/Colors'
import {
  FontFamilies,
  FontSizes,
} from 'src/design-system/theme/typography/base'

type MenuProps = {
  options: { value: any; text: string }[]
  selected: string
  label: string
  onPress: (value: any) => void
  textStyle?: TextStyle
  containerStyle?: ViewStyle
}

type MenuItemProps = {
  value: any
  text: string
  style: StyleProp<ViewStyle>
  onPress: (value: any) => void
  isSelected: boolean
}

const MENU_ITEM_HEIGHT = 36

const SelectV2MenuItem: React.FC<MenuItemProps> = ({
  value,
  text,
  style,
  onPress,
  isSelected,
}) => {
  const [isMouseInside, setIsMouseInside] = useState<boolean>(false)

  const selectedStyle = useMemo(() => {
    const backgroundColour = isSelected
      ? Colors.backgroundGrey2
      : isMouseInside
      ? Colors.backgroundGrey
      : Colors.white

    return {
      textStyle: {
        fontFamily: isSelected ? FontFamilies.bold : FontFamilies.base,
      },
      viewStyle: { backgroundColor: backgroundColour },
    }
  }, [isSelected, isMouseInside])

  return (
    <Pressable
      onHoverIn={() => setIsMouseInside(true)}
      onHoverOut={() => setIsMouseInside(false)}
      onPress={() => onPress(value)}
    >
      <View style={[style, selectedStyle.viewStyle]}>
        <Text style={selectedStyle.textStyle}>{text}</Text>
      </View>
    </Pressable>
  )
}

export const SelectV2: React.FC<MenuProps> = ({
  options,
  label,
  selected,
  onPress,
  containerStyle = {},
  textStyle = {},
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const containerRef = useRef<View>(null)
  const menuRef = useRef<View>(null)

  const [listMeasurements, setListMeasurements] = useState<ListMeasurements>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    px: 0,
    py: 0,
  })

  useWebOutsideClick({
    ref: menuRef,
    callback: () => setIsMenuOpen(false),
  })

  const handleSelectOption = (value: any) => {
    onPress(value)
    setIsMenuOpen(false)
  }

  const handleLayoutChange = () => {
    containerRef?.current?.measure((x, y, width, height, px, py) => {
      setListMeasurements({ x, y, width, height, px, py })
    })
  }

  useEffect(() => {
    if (!Dimensions.addEventListener) return
    const resizeSubscription = Dimensions.addEventListener(
      'change',
      handleLayoutChange,
    )
    return () => {
      resizeSubscription.remove()
    }
  }, [])

  const [fadeAnim] = useState(new Animated.Value(0))

  useEffect(() => {
    isMenuOpen ? fadeAnim.setValue(0) : fadeAnim.setValue(1)
    Animated.timing(fadeAnim, {
      toValue: isMenuOpen ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start()
  }, [isMenuOpen, fadeAnim])

  const getModalStyle = useCallback(() => {
    const openMenuHeight = (options.length ?? 1) * MENU_ITEM_HEIGHT + 16
    return {
      ...styles.menuContainer,
      ...containerStyle,
      width: listMeasurements.width,
      left: listMeasurements.px,
      top: listMeasurements.py,
      height: openMenuHeight,
      opacity: fadeAnim,
    }
  }, [
    options.length,
    containerStyle,
    listMeasurements.width,
    listMeasurements.px,
    listMeasurements.py,
    fadeAnim,
  ])

  const getMenuItemStyle = useCallback(() => {
    return {
      ...styles.menuItem,
      width: listMeasurements.width,
      height: MENU_ITEM_HEIGHT,
    }
  }, [listMeasurements.width])

  return (
    <>
      <FormField
        label={label}
        value={selected}
        onPress={() => setIsMenuOpen(prevValue => !prevValue)}
        textStyle={textStyle}
      />
      {isMenuOpen ? (
        <View ref={containerRef} onLayout={handleLayoutChange}>
          <NativeModal transparent={true} visible={isMenuOpen}>
            <Animated.View ref={menuRef} style={getModalStyle()}>
              {options.length
                ? options.map((option, idx) => {
                    const isSelected = option.text === selected
                    return (
                      <SelectV2MenuItem
                        key={idx}
                        style={getMenuItemStyle()}
                        text={option.text}
                        value={option.value}
                        onPress={handleSelectOption}
                        isSelected={isSelected}
                      />
                    )
                  })
                : null}
            </Animated.View>
          </NativeModal>
        </View>
      ) : null}
    </>
  )
}

const styles = StyleSheet.create({
  menuContainer: {
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: Colors.white,
    paddingVertical: 8,
    borderRadius: 4,
    shadowColor: Colors.vetRadarBlue,
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 12,
    shadowOpacity: 0.25,
    overflow: 'hidden',
  },
  menuItem: {
    fontSize: FontSizes.base,
    paddingVertical: 6,
    paddingHorizontal: 16,
    height: MENU_ITEM_HEIGHT - 6,
  },
})
