/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApprovalAction {
  STOP_FLUID = "STOP_FLUID",
}

export enum ApprovalRole {
  CAN_APPROVE = "CAN_APPROVE",
  NEEDS_APPROVAL = "NEEDS_APPROVAL",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}

export enum ApprovalStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
}

export enum Attitude {
  CAUTION = "CAUTION",
  FRIENDLY = "FRIENDLY",
  UNFRIENDLY = "UNFRIENDLY",
  UNKNOWN = "UNKNOWN",
}

export enum ConsultationStatus {
  admitted = "admitted",
  discharged = "discharged",
  unknown = "unknown",
}

export enum CubexTransactionStatus {
  CANCEL = "CANCEL",
  FAILED = "FAILED",
  SENT = "SENT",
  SUCCESS = "SUCCESS",
  SUCCESS_UN_BILLABLE = "SUCCESS_UN_BILLABLE",
  WAITING = "WAITING",
}

export enum DataSyncLogStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export enum FluidActionType {
  RATE_CHANGE = "RATE_CHANGE",
  SCHEDULED_RATE_CHANGE = "SCHEDULED_RATE_CHANGE",
  SCHEDULED_START = "SCHEDULED_START",
  SCHEDULED_STOP = "SCHEDULED_STOP",
  START = "START",
  STOP = "STOP",
}

export enum FrequencyType {
  INTERVAL = "INTERVAL",
  MINS_FROM_MIDNIGHT = "MINS_FROM_MIDNIGHT",
}

/**
 * The type of mutation that triggered the subscription
 */
export enum MutationType {
  CREATE = "CREATE",
  DELETE = "DELETE",
  UPDATE = "UPDATE",
}

/**
 * Indicate an organisation status.
 */
export enum OrganisationStatus {
  LIVE = "LIVE",
  PRE_LIVE = "PRE_LIVE",
}

export enum OriginTypes {
  EZYVET = "EZYVET",
  VETRADAR = "VETRADAR",
}

/**
 * The name follows is like BEM class naming pattern: https: // getbem.com/naming/
 * Pattern: ModuleName + ModuleFieldName + ActionNames
 * '__' in keys are the separator between 'ModuleName + ModuleFieldName' and 'ActionNames'
 */
export enum PermissionAction {
  TASK_BILLING__CHANGE = "TASK_BILLING__CHANGE",
  TASK_COMPLETED_LOCK_TIME__OVERRIDE = "TASK_COMPLETED_LOCK_TIME__OVERRIDE",
  TASK__DELETE_MISSED = "TASK__DELETE_MISSED",
  TASK__RESET = "TASK__RESET",
  TREATMENT_AUTO_COMPLETE__CHANGE = "TREATMENT_AUTO_COMPLETE__CHANGE",
  TREATMENT_BILLING__CHANGE = "TREATMENT_BILLING__CHANGE",
  TREATMENT_IGNORE_CONCENTRATION__CHANGE = "TREATMENT_IGNORE_CONCENTRATION__CHANGE",
  TREATMENT_QUANTITY_BILLED__ADD = "TREATMENT_QUANTITY_BILLED__ADD",
  TREATMENT_QUANTITY_BILLED__CHANGE = "TREATMENT_QUANTITY_BILLED__CHANGE",
  TREATMENT__DISCONTINUE_RESTART = "TREATMENT__DISCONTINUE_RESTART",
  WORKFLOW_TASK__DELETE = "WORKFLOW_TASK__DELETE",
  WORKFLOW_TASK__UNTICK = "WORKFLOW_TASK__UNTICK",
}

/**
 * The type of the input
 */
export enum ProductInputType {
  FLOAT = "FLOAT",
  NUMBER = "NUMBER",
  STRING = "STRING",
}

/**
 * The Treatment Catalog which can hold products from a PMS and
 */
export enum ProductType {
  IVFLUIDS = "IVFLUIDS",
  MEDICATION = "MEDICATION",
  OBSERVATION = "OBSERVATION",
  STANDARD = "STANDARD",
  VACCINATION = "VACCINATION",
}

export enum ResetStatus {
  FAIL = "FAIL",
  INITIAL = "INITIAL",
  STARTED = "STARTED",
  STARTED_ALREADY = "STARTED_ALREADY",
  SUCCESS = "SUCCESS",
}

export enum Resuscitation {
  NO = "NO",
  RESUCITATE_PLUS = "RESUCITATE_PLUS",
  YES = "YES",
}

export enum Roles {
  ADMIN = "ADMIN",
  BASIC = "BASIC",
  USER = "USER",
}

export enum SearchIndex {
  product = "product",
  template = "template",
}

export enum SheetType {
  ANAESTHESIA = "ANAESTHESIA",
  NORMAL = "NORMAL",
}

export enum SiteType {
  BUSINESS = "BUSINESS",
  DEPARTMENT = "DEPARTMENT",
  DIVISION = "DIVISION",
  LOCATION = "LOCATION",
  SITE = "SITE",
}

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}

export enum SourceTypes {
  HOSPITAL = "HOSPITAL",
  PROCEDURE = "PROCEDURE",
}

/**
 * The Status a given Task can be in over time.
 */
export enum Status {
  DELETED_ON_PURPOSE = "DELETED_ON_PURPOSE",
  DONE = "DONE",
  DUE = "DUE",
  IN_PROGRESS = "IN_PROGRESS",
  MISSED = "MISSED",
  MISSED_ON_PURPOSE = "MISSED_ON_PURPOSE",
  PENDING = "PENDING",
}

/**
 * Display states for treatments
 */
export enum TreatmentNodeDisplayStates {
  FOLDED = "FOLDED",
  HIDDEN = "HIDDEN",
}

export enum TreatmentTaskType {
  BASIC = "BASIC",
  NORMAL = "NORMAL",
}

export enum TreatmentTreeNodeType {
  GROUP = "GROUP",
  TREATMENT = "TREATMENT",
}

export enum TreatmentValueType {
  boolean = "boolean",
  float = "float",
  number = "number",
  string = "string",
}

export enum UserHistoryType {
  SHEET = "SHEET",
}

export enum veterinary_roles {
  USER = "USER",
  VET = "VET",
  VETERINARY_TECH = "VETERINARY_TECH",
}

export interface AddBundleToSheetInput {
  organisation_id: string;
  sheet_id: string;
  start_at?: string | null;
  bundle_id: string;
  ignore_duplication?: boolean | null;
}

export interface AddTemplateToSheetInput {
  organisation_id: string;
  sheet_id: string;
  start_at?: string | null;
  template_id: string;
  apply_template_workflow?: boolean | null;
  ignore_duplication?: boolean | null;
}

export interface AddTrimmedTemplateToSheetInput {
  template_id: string;
  start_at?: string | null;
  apply_template_workflow?: boolean | null;
}

export interface AddUserHistoryInput {
  organisation_id: string;
  user_id: string;
  value: string;
  type?: UserHistoryType | null;
}

export interface ApproveSheetInput {
  id: string;
  organisation_id: string;
}

export interface BreedInput {
  origin_id?: string | null;
  origin_type?: string | null;
  origin_species_id?: string | null;
  name?: string | null;
}

export interface BulkCreateTreatmentsInput {
  input: CreateTreatmentInput;
  options: CreateTreatmentOptions;
}

export interface BulkCreateTreatmentsOptions {
  conditional_treatment_id?: string | null;
  patient_id?: string | null;
}

export interface BundleTreatmentInput {
  product_id: string;
  should_create_treatment: boolean;
  schedule_overwrite?: ScheduleOverwrite | null;
  treatment_input?: TreatmentInput | null;
}

export interface CallParameterInput {
  name: string;
  short_name?: string | null;
  max?: number | null;
  min?: number | null;
  product_id?: string | null;
}

export interface ChildTreatments {
  product_id: string;
  name?: string | null;
}

export interface CompleteFileMultiPartUploadInput {
  key: string;
  upload_id: string;
  parts: PartInput[];
}

export interface ConsultationLocationInput {
  id?: string | null;
  display?: string | null;
  enclosure?: string | null;
}

export interface CopyTemplateCallParameterInput {
  id: string;
  organisation_id: string;
}

export interface CopyTemplateInput {
  id: string;
  organisation_id: string;
}

export interface CopyTemplateWorkflowInput {
  id: string;
  organisation_id: string;
}

export interface CreateBundleTreatmentOptions {
  bundle_treatments: BundleTreatmentInput[];
}

export interface CreatePatientInput {
  organisation_id: string;
  contact_id: string;
  avatar_url?: string | null;
  order?: number | null;
  code?: string | null;
  guid?: string | null;
  active?: boolean | null;
  primary_location?: PatientLocationInput | null;
  created_at?: string | null;
  modified_at?: string | null;
  name: string;
  sex?: string | null;
  species?: SpeciesInput | null;
  critical_notes?: string[] | null;
  breed?: BreedInput | null;
  is_dead?: boolean | null;
  attitude?: Attitude | null;
  resuscitate?: Resuscitation | null;
  is_hostile?: boolean | null;
  rabies_number?: string | null;
  date_of_rabies_vaccination?: string | null;
  microchip_number?: string | null;
  date_of_birth?: string | null;
  is_estimated_date_of_birth?: boolean | null;
  date_of_death?: string | null;
  master_problems?: MasterProblemInput[] | null;
  death_reason?: string | null;
  date_of_desex?: string | null;
  referring_clinic_id?: string | null;
  referring_vet_id?: string | null;
  residence_contact_id?: string | null;
  weight?: number | null;
  weight_unit?: string | null;
  notes?: string | null;
  notes_important?: boolean | null;
  template_call_parameter_id?: string | null;
  call_parameters?: CallParameterInput[] | null;
  color?: string | null;
  color_id?: string | null;
}

export interface CreateProductInput {
  parent_product_id: string;
  organisation_id: string;
  type: ProductType;
  name: string;
  input_type?: ProductInputType | null;
  medicine_dosage_info?: MedicineDosageInfoInput | null;
  description?: string | null;
  default_value?: string | null;
  default_unit?: string | null;
  code?: string | null;
  custom_values?: KeyValueInput[] | null;
  pims_mapping?: ProductPimsMappingInput[] | null;
  is_value_required?: boolean | null;
  is_notes_required?: boolean | null;
}

export interface CreateSheetInput {
  organisation_id: string;
  consultation_id: string;
  patient_id: string;
  type?: SheetType | null;
  name: string;
  attending_vet?: string | null;
  sync_attending_vet_with_consultation?: boolean | null;
  attending_vet_tech?: string | null;
  attending_department?: string | null;
  sync_attending_department_with_consultation?: boolean | null;
  additional_care_team?: string[] | null;
  type_meta_data?: SheetTypeMetaDataInput | null;
  pseudo_treatments?: SheetPseudoTreatmentInput[] | null;
  templates?: AddTrimmedTemplateToSheetInput[] | null;
  ignore_duplication?: boolean | null;
}

export interface CreateTaskInput {
  sheet_id: string;
  patient_id: string;
  treatment_id: string;
  organisation_id: string;
  photo_urls?: string[] | null;
  videos?: VideoInput[] | null;
  type?: TreatmentTaskType | null;
  status?: string | null;
  start_at: any;
  stop_at: any;
  buffer_due?: number | null;
  buffer_missed?: number | null;
  value?: string | null;
  unit?: string | null;
  date?: string | null;
  time?: string | null;
  notes?: string | null;
  assigned_user?: string | null;
  send_to_adaptor?: SendToAdaptorInput | null;
  given_start_at?: any | null;
  highlight_colour?: string | null;
  qty_billed?: number | null;
}

export interface CreateTemplateCallParameterInput {
  organisation_id: string;
  name: string;
  disabled?: boolean | null;
  call_parameter_items?: CreateTemplateCallParameterItemInput[] | null;
  user_id?: string | null;
  sites?: string[] | null;
  species_id?: string | null;
}

export interface CreateTemplateCallParameterItemInput {
  organisation_id: string;
  name: string;
  order: number;
  short_name: string;
  product_id: string;
  min?: number | null;
  max?: number | null;
  deleted_at?: string | null;
}

export interface CreateTemplateInput {
  organisation_id: string;
  parent_template_id?: string | null;
  template_workflow_id?: string | null;
  name: string;
  description?: string | null;
  disabled?: boolean | null;
  use_case?: string | null;
  user_id?: string | null;
  sites?: string[] | null;
}

export interface CreateTemplateTreatmentInput {
  organisation_id: string;
  template_id: string;
  name: string;
  type?: TreatmentTaskType | null;
  product_id?: string | null;
  schedule?: TemplateTreatmentScheduleInput | null;
  medicine_dosage_info?: MedicineDosageInfoInput | null;
  conditional?: boolean | null;
  is_billable?: boolean | null;
  treatment_options?: TemplateTreatmentOptionsInput | null;
  instructions?: string | null;
  value?: TreatmentValueInput | null;
  order?: number | null;
  is_auto_completed?: boolean | null;
}

export interface CreateTemplateTreatmentOptions {
  template_treatments: TemplateTreatmentInput[];
}

export interface CreateTemplateWorkflowInput {
  organisation_id: string;
  name: string;
  disabled?: boolean | null;
  workflow_items?: CreateTemplateWorkflowItemInput[] | null;
  user_id?: string | null;
  sites?: string[] | null;
}

export interface CreateTemplateWorkflowItemInput {
  organisation_id: string;
  name: string;
  order: number;
}

export interface CreateTreatmentInput {
  organisation_id: string;
  patient_id: string;
  product_id: string;
  sheet_id: string;
  type?: TreatmentTaskType | null;
  name?: string | null;
  schedule: ScheduleInput;
  medicine_dosage_info?: MedicineDosageInfoInput | null;
  fluid_dosage_info?: FluidDosageInfoInput | null;
  instructions?: string | null;
  is_instructions_important?: boolean | null;
  conditional?: boolean | null;
  optimistic_id?: string | null;
  display_states?: TreatmentNodeDisplayStates[] | null;
  is_billable?: boolean | null;
  is_auto_completed?: boolean | null;
  is_iv_infusion?: boolean | null;
  is_continuous?: boolean | null;
}

export interface CreateTreatmentOptions {
  createTasks?: boolean | null;
  child_treatments?: ChildTreatments[] | null;
}

export interface CreateWorkflowInput {
  organisation_id: string;
  name?: string | null;
  patient_id: string;
  consultation_id: string;
  workflow_items?: WorkflowItemInput[] | null;
}

export interface DayStaffedHoursInput {
  enabled: boolean;
  is24H: boolean;
  hours: StaffedHourBlockInput[];
}

export interface DeleteSheetInput {
  id: string;
  patient_id: string;
  organisation_id: string;
}

export interface DeleteTaskInput {
  id: string;
  treatment_id: string;
  sheet_id: string;
  organisation_id: string;
  approval_action?: ApprovalAction | null;
}

export interface DeleteTemplateCallParameterInput {
  id: string;
  organisation_id: string;
}

export interface DeleteTemplateInput {
  id: string;
  organisation_id: string;
}

export interface DeleteTemplateTreatmentInput {
  id: string;
  template_id: string;
}

export interface DeleteTemplateWorkflowInput {
  id: string;
  organisation_id: string;
}

export interface DeleteTreatmentInput {
  id: string;
  patient_id: string;
  sheet_id: string;
}

export interface EmailContactInput {
  support?: string[] | null;
  accounts?: string[] | null;
}

/**
 * Input for creating and running an ECS task for ezyvet data import
 */
export interface EzyVetDataImportTaskFromAppInput {
  organisation_id: string;
  integration_id: string;
  timestamp: string;
}

export interface FluidDosageInfoInput {
  is_hypovolemic?: boolean | null;
  is_dehydrated?: boolean | null;
  dehydration?: number | null;
  hours_to_admin?: number | null;
  dehydration_result?: number | null;
  shock_total_result?: number | null;
  physiologic_req?: number | null;
  ongoing_losses?: number | null;
  total_result?: number | null;
  total_result_unit?: string | null;
  iv_set?: number | null;
  drip_rate?: number | null;
  minutes?: number | null;
  hours?: number | null;
  days?: number | null;
  patient_weight?: number | null;
  patient_weight_unit?: string | null;
  preset_rate_text?: string | null;
}

export interface GenerateSheetReportInput {
  organisation_id: string;
  sheet_id: string;
}

export interface KeyValueInput {
  key: string;
  value?: string | null;
}

export interface ListTaskPreviewItemsByPatientInput {
  patient_id: string;
  start_time?: string | null;
  end_time?: string | null;
  task_statuses: Status[];
  limit?: number | null;
  organisation_id: string;
}

export interface ListWhiteboardInput {
  organisation_id: string;
  filterOptions?: WhiteboardFilterOptions | null;
  pageInput?: PageInput | null;
}

export interface MasterProblemInput {
  date?: string | null;
  condition_active?: boolean | null;
  condition_critical?: boolean | null;
  condition?: string | null;
  description?: string | null;
  condition_specifics?: string | null;
}

export interface MedicineCalculationInput {
  patient_weight?: number | null;
  patient_weight_unit?: string | null;
  total_value?: number | null;
  total_value_unit?: string | null;
}

export interface MedicineDosageInfoInput {
  calculate_base?: string | null;
  concentration_volume_unit?: string | null;
  concentration_weight_unit?: string | null;
  concentration?: number | null;
  diluent_used?: string | null;
  is_diluted?: boolean | null;
  diluted_concentration_volume_unit?: string | null;
  diluted_concentration_weight_unit?: string | null;
  diluted_concentration?: number | null;
  diluted_concentration_ratio_factor?: number | null;
  diluted_concentration_ratio_volume?: number | null;
  concentration_setting?: string | null;
  units_billed_per_task?: number | null;
  is_concentration_overridden?: boolean | null;
  iv_bag_size?: number | null;
  iv_bag_size_unit?: string | null;
  medication_volume?: number | null;
  medication_volume_unit?: string | null;
  saline_volume_unit?: string | null;
  saline_volume?: number | null;
  is_cri?: boolean | null;
  administration_method?: string | null;
  administration_volume?: number | null;
  administration_volume_unit?: string | null;
  infusion_rate?: number | null;
  infusion_rate_volume_unit?: string | null;
  infusion_rate_weight_unit?: string | null;
  infusion_rate_time_unit?: string | null;
  infusion_rate_total?: number | null;
  route_of_administration?: string | null;
  lowest_dispensable_unit?: string | null;
  dosage?: number | null;
  dosage_weight_unit?: string | null;
  dosage_patient_weight_unit?: string | null;
  dose_rate?: number | null;
  dose_rate_time_unit?: string | null;
  total_dosage?: number | null;
  total_volume?: number | null;
  total_volume_unit?: string | null;
  minutes?: number | null;
  hours?: number | null;
  days?: number | null;
  patient_weight?: number | null;
  patient_weight_unit?: string | null;
  lowest_dosage?: number | null;
  highest_dosage?: number | null;
}

export interface PageInput {
  limit?: number | null;
  cursor?: string | null;
  sortField?: string[] | null;
  sortDirection?: SortDirection[] | null;
  sortItems?: boolean | null;
  options?: PageInputOptions | null;
}

export interface PageInputOptions {
  includeDeleted?: boolean | null;
}

export interface PartInput {
  e_tag: string;
  part_number: number;
}

export interface PatientLocationInput {
  location_id?: string | null;
  location_display?: string | null;
  enclosure?: string | null;
}

export interface ProductPimsMappingInput {
  type: string;
  dest: string;
  field?: string | null;
}

export interface RescheduleTaskInput {
  id: string;
  sheet_id: string;
  patient_id: string;
  treatment_id: string;
  organisation_id: string;
  start_at: any;
  set_as_skipped: boolean;
  notes?: string | null;
  highlight_colour?: string | null;
}

export interface RescheduleTreatmentInput {
  id: string;
  organisation_id: string;
  patient_id: string;
  product_id: string;
  sheet_id: string;
  task_id: string;
  schedule: ScheduleInput;
  reschedule_from: any;
}

export interface ResetTaskInput {
  id: string;
  treatment_id: string;
  organisation_id: string;
  patient_id: string;
  sheet_id: string;
  isFluidTask?: boolean | null;
}

export interface RestoreSheetInput {
  id: string;
  organisation_id: string;
}

export interface RouteOfAdministrationsInput {
  abbreviation: string;
  name: string;
  value: string;
  default: boolean;
  active: boolean;
}

export interface ScheduleInput {
  treatment_frequency_id?: string | null;
  type?: FrequencyType | null;
  frequencies?: number[] | null;
  repeat: boolean;
  start_at?: string | null;
  frequency?: number | null;
  repeat_until?: string | null;
  repeat_count?: number | null;
  duration?: number | null;
  time_window?: number | null;
  disabled?: boolean | null;
  enable_staffed_hour?: boolean | null;
}

export interface ScheduleOverwrite {
  repeat: boolean;
  type?: FrequencyType | null;
  treatment_frequency_id?: string | null;
  frequencies?: number[] | null;
  frequency?: number | null;
  should_create_tasks: boolean;
}

export interface SearchFilter {
  key: string;
  value: string;
}

export interface SearchInput {
  q: string;
  organisation_id: string;
  indexes?: SearchIndex[] | null;
  filters?: SearchFilter[] | null;
  options?: SearchOptions | null;
  is_from_rds?: boolean | null;
}

export interface SearchOptions {
  site_ids?: string[] | null;
  should_show_bundle?: boolean | null;
}

export interface SendHelpFeedbackInput {
  organisation_id: string;
  feedback: string;
}

export interface SendToAdaptorInput {
  send_value?: boolean | null;
  send_notes?: boolean | null;
  is_billable?: boolean | null;
}

export interface SheetMetaTimerInput {
  name: string;
  elapsed: number;
  start_at?: string | null;
}

export interface SheetPseudoTreatmentInput {
  product_id: string;
  type?: TreatmentTaskType | null;
  name: string;
  medicine_dosage_info?: MedicineDosageInfoInput | null;
  fluid_dosage_info?: FluidDosageInfoInput | null;
  instructions?: string | null;
  conditional?: boolean | null;
  optimistic_id?: string | null;
  display_states?: TreatmentNodeDisplayStates[] | null;
  is_billable?: boolean | null;
  is_continuous?: boolean | null;
  is_instructions_important?: boolean | null;
  treatments?: SheetPseudoTreatmentInput[] | null;
}

export interface SheetTypeMetaDataInput {
  asa?: string | null;
  anaesthesia?: anaesthesiaMetaDataInput | null;
  timers?: SheetMetaTimerInput[] | null;
}

export interface SpeciesInput {
  origin_id?: string | null;
  origin_type?: string | null;
  name?: string | null;
}

export interface StaffedHourBlockInput {
  start: string;
  end: string;
}

export interface StaffedHoursInput {
  MON: DayStaffedHoursInput;
  TUE: DayStaffedHoursInput;
  WED: DayStaffedHoursInput;
  THU: DayStaffedHoursInput;
  FRI: DayStaffedHoursInput;
  SAT: DayStaffedHoursInput;
  SUN: DayStaffedHoursInput;
}

export interface StartFileMultiPartUploadInput {
  key: string;
  content_type: string;
}

export interface TemplateCallParameterItemInput {
  id?: string | null;
  organisation_id: string;
  name: string;
  short_name: string;
  product_id: string;
  order: number;
  min?: number | null;
  max?: number | null;
  deleted_at?: string | null;
}

export interface TemplateTreatmentInput {
  template_treatment_id: string;
  should_create_treatment: boolean;
  schedule_overwrite?: ScheduleOverwrite | null;
  treatment_input?: TreatmentInput | null;
}

export interface TemplateTreatmentOptionsInput {
  products?: string[] | null;
  product_groups?: string[] | null;
  expressions?: string[] | null;
}

export interface TemplateTreatmentScheduleInput {
  treatment_frequency_id?: string | null;
  type?: FrequencyType | null;
  repeat: boolean;
  start_at?: number | null;
  frequency?: number | null;
  frequencies?: number[] | null;
  repeat_until?: number | null;
  repeat_count?: number | null;
  duration?: number | null;
  time_window?: number | null;
  enable_staffed_hour?: boolean | null;
}

export interface TemplateWorkflowItemInput {
  id?: string | null;
  template_workflow_id?: string | null;
  organisation_id: string;
  name: string;
  order: number;
  deleted_at?: string | null;
}

export interface TreatmentFrequencyCreateInput {
  organisation_id: string;
  name: string;
  active: boolean;
  type: FrequencyType;
  frequencies: number[];
}

export interface TreatmentFrequencyUpdateInput {
  id: string;
  organisation_id: string;
  name: string;
  active: boolean;
  type: FrequencyType;
  frequencies: number[];
}

export interface TreatmentInput {
  input: CreateTreatmentInput;
  options?: CreateTreatmentOptions | null;
}

export interface TreatmentValueInput {
  value?: string | null;
  type?: TreatmentValueType | null;
  display_unit?: string | null;
}

export interface UpdateColorInput {
  id: string;
  organisation_id: string;
  name: string;
  order: number;
  hex: string;
}

export interface UpdateConsultationInput {
  id: string;
  organisation_id: string;
  patient_id: string;
  locations?: (ConsultationLocationInput | null)[] | null;
  color_id?: string | null;
}

export interface UpdateOrganisationInput {
  id: string;
  name: string;
  settings?: KeyValueInput[] | null;
  staffed_hours?: StaffedHoursInput | null;
  route_of_administrations?: RouteOfAdministrationsInput[] | null;
  email_contacts?: EmailContactInput | null;
  status?: OrganisationStatus | null;
  contracted_users?: string | null;
  xero_id?: string | null;
}

export interface UpdatePatientInput {
  id: string;
  organisation_id: string;
  order?: number | null;
  avatar_url?: string | null;
  contact_id?: string | null;
  code?: string | null;
  guid?: string | null;
  active?: boolean | null;
  primary_location?: PatientLocationInput | null;
  created_at?: string | null;
  modified_at?: string | null;
  master_problems?: MasterProblemInput[] | null;
  name?: string | null;
  sex?: string | null;
  species?: SpeciesInput | null;
  breed?: BreedInput | null;
  is_dead?: boolean | null;
  attitude?: Attitude | null;
  critical_notes?: string[] | null;
  resuscitate?: Resuscitation | null;
  is_hostile?: boolean | null;
  animalcolour_id?: string | null;
  rabies_number?: string | null;
  date_of_rabies_vaccination?: string | null;
  microchip_number?: string | null;
  date_of_birth?: string | null;
  is_estimated_date_of_birth?: boolean | null;
  date_of_death?: string | null;
  death_reason?: string | null;
  date_of_desex?: string | null;
  referring_clinic_id?: string | null;
  referring_vet_id?: string | null;
  residence_contact_id?: string | null;
  weight?: number | null;
  weight_unit?: string | null;
  notes?: string | null;
  notes_important?: boolean | null;
  template_call_parameter_id?: string | null;
  call_parameters?: CallParameterInput[] | null;
  color?: string | null;
  color_id?: string | null;
  is_changing_demeanours?: boolean | null;
}

export interface UpdateProcedureInput {
  id: string;
  organisation_id: string;
  template_workflow_id?: string | null;
  use_case?: string | null;
}

export interface UpdateProductInput {
  id: string;
  organisation_id: string;
  input_type?: ProductInputType | null;
  order?: number | null;
  parent_product_id?: string | null;
  medicine_dosage_info?: MedicineDosageInfoInput | null;
  type?: ProductType | null;
  name?: string | null;
  description?: string | null;
  default_value?: string | null;
  default_unit?: string | null;
  code?: string | null;
  custom_values?: KeyValueInput[] | null;
  pims_mapping?: ProductPimsMappingInput[] | null;
  is_value_required?: boolean | null;
  is_notes_required?: boolean | null;
}

export interface UpdateSheetInput {
  id: string;
  organisation_id: string;
  consultation_id: string;
  type?: string | null;
  name?: string | null;
  attending_vet?: string | null;
  sync_attending_vet_with_consultation?: boolean | null;
  attending_vet_tech?: string | null;
  attending_department?: string | null;
  sync_attending_department_with_consultation?: boolean | null;
  additional_care_team?: string[] | null;
  type_meta_data?: SheetTypeMetaDataInput | null;
  pseudo_treatments?: SheetPseudoTreatmentInput[] | null;
  closed_at?: string | null;
}

export interface UpdateSheetOrderInput {
  id: string;
  organisation_id: string;
  order: number;
}

export interface UpdateTaskInput {
  id: string;
  sheet_id: string;
  patient_id: string;
  treatment_id: string;
  organisation_id: string;
  photo_urls?: string[] | null;
  videos?: VideoInput[] | null;
  rescheduled_task_id?: string | null;
  type?: TreatmentTaskType | null;
  status?: string | null;
  start_at?: any | null;
  stop_at?: any | null;
  buffer_due?: number | null;
  buffer_missed?: number | null;
  given_start_at?: string | null;
  given_stop_at?: string | null;
  date?: string | null;
  time?: string | null;
  value?: string | null;
  unit?: string | null;
  medicine_calculation?: MedicineCalculationInput | null;
  notes?: string | null;
  assigned_user?: string | null;
  send_to_adaptor?: SendToAdaptorInput | null;
  highlight_colour?: string | null;
  qty_billed?: number | null;
}

export interface UpdateTemplateCallParameterInput {
  id: string;
  organisation_id: string;
  name?: string | null;
  disabled?: boolean | null;
  user_id?: string | null;
  sites?: string[] | null;
  species_id?: string | null;
  call_parameter_items?: TemplateCallParameterItemInput[] | null;
}

export interface UpdateTemplateInput {
  id: string;
  organisation_id: string;
  parent_template_id?: string | null;
  template_workflow_id?: string | null;
  name: string;
  description?: string | null;
  disabled?: boolean | null;
  use_case?: string | null;
  user_id?: string | null;
  sites?: string[] | null;
}

export interface UpdateTemplateTreatmentInput {
  id: string;
  organisation_id: string;
  template_id: string;
  name: string;
  type?: TreatmentTaskType | null;
  product_id?: string | null;
  schedule?: TemplateTreatmentScheduleInput | null;
  medicine_dosage_info?: MedicineDosageInfoInput | null;
  conditional?: boolean | null;
  is_billable?: boolean | null;
  treatment_options?: TemplateTreatmentOptionsInput | null;
  instructions?: string | null;
  value?: TreatmentValueInput | null;
  order?: number | null;
  is_auto_completed?: boolean | null;
}

export interface UpdateTemplateWorkflowInput {
  id: string;
  organisation_id: string;
  name?: string | null;
  disabled?: boolean | null;
  user_id?: string | null;
  sites?: string[] | null;
  workflow_items?: TemplateWorkflowItemInput[] | null;
}

export interface UpdateTreatmentApprovalInput {
  id: string;
  patient_id: string;
  approval_status: ApprovalStatus;
}

export interface UpdateTreatmentGroupInput {
  id: string;
  organisation_id: string;
  patient_id: string;
  sheet_id: string;
  name: string;
}

export interface UpdateTreatmentInput {
  id: string;
  organisation_id: string;
  patient_id: string;
  product_id: string;
  sheet_id: string;
  name: string;
  type?: TreatmentTaskType | null;
  order?: number | null;
  option?: CreateTreatmentOptions | null;
  due_tasks?: number | null;
  missed_tasks?: number | null;
  medicine_dosage_info?: MedicineDosageInfoInput | null;
  fluid_dosage_info?: FluidDosageInfoInput | null;
  schedule: ScheduleInput;
  instructions?: string | null;
  is_instructions_important?: boolean | null;
  conditional: boolean;
  discontinued_at?: any | null;
  display_states?: TreatmentNodeDisplayStates[] | null;
  is_billable?: boolean | null;
  is_auto_completed?: boolean | null;
  is_iv_infusion?: boolean | null;
  is_continuous?: boolean | null;
}

export interface UpdateTreatmentOptions {
  child_treatments?: ChildTreatments[] | null;
}

export interface UpdateTreatmentOrderInput {
  id: string;
  organisation_id: string;
  patient_id: string;
  sheet_id: string;
  order: number;
}

export interface UpdateWorkflowInput {
  id: string;
  organisation_id: string;
  name?: string | null;
  patient_id?: string | null;
  consultation_id?: string | null;
  workflow_items?: WorkflowItemInput[] | null;
}

export interface UpdateWorkflowItemInput {
  id: string;
  organisation_id: string;
  patient_id?: string | null;
  consultation_id?: string | null;
  completed_at?: string | null;
  name?: string | null;
  order?: number | null;
}

export interface UserCreateInput {
  full_name: string;
  email: string;
  organisation_id: string;
  role: Roles;
  veterinary_role: veterinary_roles;
  phone_number?: string | null;
  departments?: string[] | null;
  job_title?: string | null;
  display_name: string;
  allow_login?: boolean | null;
}

export interface UserUpdateInput {
  id: string;
  organisation_id: string;
  full_name?: string | null;
  display_name?: string | null;
  phone_number?: string | null;
  email?: string | null;
  old_email?: string | null;
  role?: Roles | null;
  approval_role?: ApprovalRole | null;
  active?: boolean | null;
  veterinary_role: veterinary_roles;
  job_title?: string | null;
  departments?: string[] | null;
  allow_login?: boolean | null;
  temporaryPassword?: string | null;
  auto_logout_timeout?: number | null;
  task_editing_timeout?: number | null;
}

export interface UserUpdateOptions {
  shouldValidateEmail?: boolean | null;
}

export interface VideoInput {
  url: string;
  thumbnail_url: string;
}

export interface WhiteboardFilterOptions {
  filters?: WhiteboardFilters | null;
  columns?: number | null;
  q?: string | null;
}

export interface WhiteboardFilters {
  sites?: string[] | null;
  attending_vet_user_ids?: string[] | null;
  attending_vet_tech_user_ids?: string[] | null;
  location_ids?: string[] | null;
  approval_statuses?: ApprovalStatus[] | null;
}

export interface WorkflowItemInput {
  organisation_id: string;
  patient_id: string;
  consultation_id: string;
  name: string;
  order: number;
  id?: string | null;
  completed_at?: string | null;
  deleted_at?: string | null;
}

export interface anaesthesiaMetaDataInput {
  owner_consent?: boolean | null;
  comments?: string | null;
  circuit_check?: boolean | null;
  catheter_site?: string[] | null;
  tube_size?: number | null;
  str_tube_size?: string | null;
  complications?: string[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
