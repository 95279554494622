import { useMemo } from 'react'
import { keyToUrl } from 'src/utils/keyToUrl'

export const useKeyToUrl = (key: string | null | undefined, size: number) => {
  return useMemo(
    () =>
      keyToUrl(key, {
        resize: {
          width: size,
          height: size,
        },
      }),
    [key, size],
  )
}
