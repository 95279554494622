import { useOrgSettings, DEFAULT_ROUNDING_PRECISION } from './useOrgSettings'
import { round } from 'lodash'
import { useCallback, useMemo } from 'react'

export const useRound = () => {
  const roundingPrecision = useRoundingPrecision()

  return useCallback(
    (number: number) => round(number, roundingPrecision),
    [roundingPrecision],
  )
}

export const useRoundingPrecision = () => {
  const { settingsMap } = useOrgSettings()

  const roundingPrecision =
    +settingsMap?.ROUNDING_PRECISION?.value ?? DEFAULT_ROUNDING_PRECISION

  return useMemo(() => {
    if (isNaN(roundingPrecision)) {
      return DEFAULT_ROUNDING_PRECISION
    }

    return roundingPrecision
  }, [roundingPrecision])
}

export const nonZeroRound = (value: number, roundingPrecision: number) => {
  let rounding = roundingPrecision
  if (value === 0) {
    return value
  }

  let result = round(value, rounding)

  if (result !== 0) {
    return result
  }

  // when input number is not 0, we want the result is not 0
  while (result === 0) {
    rounding = rounding + 1
    result = round(value, rounding)
  }

  return result
}

const PRECISION_RATE = 0.01

export const nonZeroPrecisionRound = (
  value: number,
  roundingPrecision: number,
) => {
  let rounding = roundingPrecision
  if (value === 0) {
    return value
  }

  let result = round(value, rounding)

  // when the round value is not accuracy to the input value, we want the result is more precise
  while (Math.abs(result - value) / Math.abs(value) >= PRECISION_RATE) {
    rounding = rounding + 1
    result = round(value, rounding)
  }

  return result
}

export const useNonZeroPrecisionRound = () => {
  const roundingPrecision = useRoundingPrecision()

  return (number: number) => nonZeroPrecisionRound(number, roundingPrecision)
}

export const useNonZeroRound = () => {
  const roundingPrecision = useRoundingPrecision()

  return (number: number) => nonZeroRound(number, roundingPrecision)
}
