export enum AnesthesiaChartGroupNames {
  Pressure = 'pressure',
}

export enum AnesthesiaChartPressureTitles {
  DiastolicArterialPressure = 'Diastolic Arterial Pressure',
  MeanArterialPressure = 'Mean Arterial Pressure',
  SystolicArterialPressure = 'Systolic Arterial Pressure',
}

export const anesthesiaGroupMemberTitles = [
  ...Object.values(AnesthesiaChartPressureTitles),
]

export type AnesthesiaGroupMemberTitles = typeof anesthesiaGroupMemberTitles

export enum AnesthesiaChartTitles {
  Doppler = 'Doppler',
  EndTidalCO2 = 'End-Tidal CO2',
  HeartRate = 'Heart Rate',
  IntermittentPositivePressureVentilation = 'Intermittent Positive-Pressure Ventilation',
  OxygenSaturation = 'Oxygen Saturation',
  RespiratoryRate = 'Respiratory Rate',
  TemperatureDegreesCelcius = 'Temperature',
}

export const anesthesiaCustomProductTitles: string[] = [
  ...Object.values(AnesthesiaChartTitles),
  ...Object.values(AnesthesiaChartPressureTitles),
]

export enum AnesthesiaChartSymbolShape {
  Asterisk = 'asterisk',
  CrossedCircle = 'crossed-circle',
  DownwardChevron = 'downward-cevron',
  DownwardChevronCircle = 'downward-cevron-circle',
  EmptyCircle = 'empty-circle',
  EmptyTriangle = 'empty-triangle',
  FilledCircle = 'filled-circle',
  HorizontalLine = 'horizontal-line',
  Lozenge = 'lozenge',
  UpwardChevron = 'upward-cevron',
}

export type AnesthesiaChartValueData = { time: Date; value: number }

export type AnesthesiaChartEntry = {
  color: string
  data: AnesthesiaChartValueData[]
  full_title: AnesthesiaChartTitles | AnesthesiaChartPressureTitles
  group_name?: string
  group_order?: number
  latest_value?: number
  matching_treatment_id?: string
  order: number
  show_text?: boolean
  symbol_shape: AnesthesiaChartSymbolShape
  title: string
  unit: string
}

export type AnesthesiaChartData = AnesthesiaChartEntry[]
