import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, toast } from 'components/common'
import { useGetDateFormatPattern } from 'components/Task/utils/useGetStatusLabelText'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { addMinutes, format } from 'date-fns'
import { keyBy } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { BulkTaskCreateList } from 'src/components/BulkTask/BulkTaskCreateList'
import { Task } from 'src/components/Task/Task'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { TimeSegment, TimeSegmentMinutes } from 'src/hocs/types/time'

import { getIsBillable } from './utils/getIsBillable'
import { getStatusAndTimeText } from './utils/getStatusAndTimeText'
import { useBulkCreateTasks } from './utils/useBulkCreateTasks'
import { useCheckNotes } from './utils/useCheckNotes'
import { useShouldDisableComplete } from './utils/useShouldDisableComplete'
import { useUser } from 'src/context/user'
import { ApprovalRole, ApprovalStatus } from 'types/globalTypes'
import { useCacheTreatment } from 'components/Treatment/utils/useCacheTreatment'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { usePatientCallParameters } from 'src/hooks/usePatientCallParameters'
import { checkValueIsOutsideOfRange } from 'components/Task/utils/checkValueIsOutsideOfRange'
import { DISPLAY_DURATION } from './BulkTaskActions'

export type SubTreatmentWithChecked = {
  checked: boolean
  value: string
  treatment: TreatmentChildLevel
  isBillable: boolean | null
  notes?: string
}

const ONE_MINUTES = 1000 * 60

export type BulkTreatmentsKeyedById = { [key: string]: SubTreatmentWithChecked }

type Props = {
  sheetId: string
  patientId: string
  treatments: TreatmentChildLevel[]
  toggleDrawer: () => void
  treatmentGroupName: string
  sheetName: string
  timePress: Date
  timeSegment: TimeSegment
}

export const BulkTaskCreate: React.FC<Props> = ({
  sheetId,
  patientId,
  treatments,
  toggleDrawer,
  treatmentGroupName,
  sheetName,
  timePress,
  timeSegment,
}) => {
  const { t } = useTranslation()

  const { isLargeScreen } = useBreakpoint()
  const { dateFormatPattern } = useGetDateFormatPattern()

  const initialValues: BulkTreatmentsKeyedById = keyBy(
    treatments?.map(treatment => ({
      treatment,
      checked: true,
      notes: '',
      isBillable: getIsBillable(treatment),
      value: '',
    })),
    'treatment.id',
  )

  const newTaskProps = useMemo(() => {
    const timeSegmentMinutes = TimeSegmentMinutes[timeSegment]
    const startAt = timePress.toISOString()
    const stopAt = addMinutes(timePress, timeSegmentMinutes).toISOString()
    return { startAt, stopAt, sheetId, patientId }
  }, [timeSegment, timePress, sheetId, patientId])

  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<BulkTreatmentsKeyedById>({
    defaultValues: initialValues,
    mode: 'onChange',
  })

  const bulkTasks = watch()
  const { createBulkTasks, createAndCompleteBulkTasks } =
    useBulkCreateTasks(bulkTasks)
  const getPatientCallParameterRangeByProductID =
    usePatientCallParameters(patientId)

  const checkBulkTasksValueRange = (bulkTasks: BulkTreatmentsKeyedById) => {
    const treatmentIds = Object.keys(bulkTasks)
    treatmentIds.map(treatmentId => {
      const taskValue = bulkTasks[treatmentId].value
      if (!taskValue) return
      const callParamValue = getPatientCallParameterRangeByProductID(
        bulkTasks[treatmentId].treatment.product?.id,
      )
      const isOverflowed = checkValueIsOutsideOfRange(taskValue, callParamValue)
      if (isOverflowed) {
        setTimeout(() => {
          toast.notice(
            `${taskValue} is not in the range of the ${bulkTasks[treatmentId].treatment.name} call parameter`,
            undefined,
            DISPLAY_DURATION,
          )
        }, 100)
      }
    })
  }

  const onCreateTasks = () => {
    createBulkTasks(newTaskProps)
    toggleDrawer()
  }

  const onCreateCompleteTasks = () => {
    if (shouldDisableCreateAndCompleteByApproval(bulkTasks)) {
      toast.error(t('treatment:approval:failedToCompleteAction'))
      return
    }
    checkBulkTasksValueRange(bulkTasks)
    createAndCompleteBulkTasks(newTaskProps)
    toggleDrawer()
  }

  const [statusAndTimeText, setStatusAndTimeText] = useState(() =>
    getStatusAndTimeText(timePress, TimeSegmentMinutes[timeSegment]),
  )

  useEffect(() => {
    const timer = setInterval(() => {
      setStatusAndTimeText(
        getStatusAndTimeText(timePress, TimeSegmentMinutes[timeSegment]),
      )
    }, ONE_MINUTES)
    return () => {
      clearInterval(timer)
    }
  }, [timeSegment, timePress])

  const hasTaskSelected = useMemo(() => {
    const valuesArray = Object.values(bulkTasks)
    return valuesArray.some(value => value.checked)
  }, [bulkTasks])

  const checkNotes = useCheckNotes({
    tasks: bulkTasks,
    setError,
    clearErrors,
  })

  const hasError = !!Object.keys(errors).length
  const formattedTimeInGrid = format(timePress, dateFormatPattern)

  const handleChange = (field: string, value: any) => {
    setValue(field, value)
    checkNotes()
  }

  useEffect(() => {
    checkNotes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shouldDisableComplete = useShouldDisableComplete({
    hasError,
    hasTaskSelected,
    bulkTasks,
  })

  const currentUser = useUser()

  const { getCacheTreatmentById } = useCacheTreatment()

  const hasUnapprovedTreatmentTaskSelected = useCallback(
    (bulkTasks: BulkTreatmentsKeyedById) => {
      const valuesArray = Object.values(bulkTasks)
      return valuesArray
        .filter(value => value.checked)
        .some(value => {
          const cacheTreatment =
            getCacheTreatmentById(value.treatment.id) ?? value.treatment

          return cacheTreatment.approval_status === ApprovalStatus.PENDING
        })
    },
    [getCacheTreatmentById],
  )

  const shouldDisableCreateAndCompleteByApproval = useCallback(
    (bulkTasks: BulkTreatmentsKeyedById) =>
      hasUnapprovedTreatmentTaskSelected(bulkTasks) ||
      currentUser.approvalRole === ApprovalRole.NEEDS_APPROVAL,
    [currentUser.approvalRole, hasUnapprovedTreatmentTaskSelected],
  )

  const shouldDisableCreateAndComplete = useMemo(() => {
    if (shouldDisableComplete) return true

    return shouldDisableCreateAndCompleteByApproval(bulkTasks)
  }, [
    bulkTasks,
    shouldDisableComplete,
    shouldDisableCreateAndCompleteByApproval,
  ])

  return (
    <>
      <KeyboardAwareScrollView style={styles.container}>
        <View style={styles.title}>
          <Text
            style={[
              styles.sheetTreatmentName,
              !isLargeScreen && smallStyles.smallFont18,
            ]}
          >{`${sheetName}/${treatmentGroupName} Group`}</Text>
        </View>

        <View
          style={[
            styles.actionContainer,
            !isLargeScreen && smallStyles.smallPadding11,
          ]}
        >
          <View style={styles.taskWrapper}>
            <View
              style={
                !isLargeScreen ? smallStyles.smallTaskIcon : styles.taskIcon
              }
            >
              <Task status={statusAndTimeText.status} disabled />
            </View>
            <View
              style={[
                styles.textWrapper,
                !isLargeScreen && smallStyles.smallPadding6,
              ]}
            >
              <Text
                style={[
                  styles.newTaskText,
                  !isLargeScreen && smallStyles.smallFont9,
                ]}
              >
                {t('bulkTask:drawer.newTask')}
              </Text>
              <Text
                style={[
                  styles.dateText,
                  !isLargeScreen && smallStyles.smallFont11,
                ]}
              >
                {formattedTimeInGrid}
              </Text>
              <Text
                style={[
                  styles.timeText,
                  !isLargeScreen && smallStyles.smallFont9,
                ]}
              >
                {statusAndTimeText.text}
              </Text>
            </View>
          </View>
          <View>
            <Button
              disabled={!hasTaskSelected}
              style={[
                styles.buttonStyle,
                !isLargeScreen && smallStyles.smallButton,
                !hasTaskSelected && styles.disabledBorderStyle,
              ]}
              textStyle={[
                styles.buttonTextStyle,
                !isLargeScreen && smallStyles.smallButtonText,
                !hasTaskSelected && styles.disabledTextStyle,
              ]}
              color={'transparent'}
              contentStyle={styles.buttonContentStyle}
              title={t('bulkTask:drawer.createTasks')}
              onPress={onCreateTasks}
              disabledBackgroundColor={Colors.white}
            />
          </View>
        </View>
        <View
          style={[
            styles.taskList,
            !isLargeScreen && smallStyles.smallPadding11,
          ]}
        >
          <BulkTaskCreateList
            patientId={patientId}
            values={bulkTasks}
            handleChange={handleChange}
          />
        </View>
      </KeyboardAwareScrollView>
      <View style={styles.footer}>
        <Button
          a11yLabel={t('task:taskTransition.bulkSubmit:a11y')}
          disabled={shouldDisableCreateAndComplete}
          onPress={onCreateCompleteTasks}
          title={t('bulkTask:drawer.createComplete')}
        />
      </View>
    </>
  )
}

const smallStyles = StyleSheet.create({
  smallTaskIcon: { width: 50, height: 38 },
  smallButton: {
    width: 100,
    height: 30,
    paddingHorizontal: 8,
    minWidth: 0,
  },
  smallButtonText: {
    fontSize: 10,
    lineHeight: 30,
  },
  smallPadding11: { paddingHorizontal: 11 },
  smallPadding6: { paddingHorizontal: 6 },
  smallFont9: { fontSize: 9 },
  smallFont11: { fontSize: 11 },
  smallFont18: { fontSize: 18 },
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 'auto',
  },
  title: { width: '100%', alignItems: 'center', paddingHorizontal: 10 },
  taskIcon: { width: 67, height: 51 },
  footer: {
    paddingVertical: 15,
  },
  sheetTreatmentName: {
    fontSize: 24,
    marginBottom: 8,
    fontFamily: Fonts.semibold,
  },
  buttonStyle: {
    backgroundColor: Colors.white,
    width: 129,
    height: 40,
    borderWidth: 1,
    borderColor: Colors.contentSecondary,
    borderRadius: 6,
  },
  buttonTextStyle: {
    color: Colors.contentSecondary,
    fontSize: 12,
    fontFamily: Fonts.semibold,
    lineHeight: 40,
  },
  disabledBorderStyle: {
    borderColor: Colors.lightGrey2,
  },
  disabledTextStyle: {
    color: Colors.lightGrey2,
  },
  buttonContentStyle: { paddingHorizontal: 8 },
  textWrapper: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
  },
  newTaskText: {
    fontSize: 12,
    color: Colors.bulkTask.bulkGrey,
    lineHeight: 18,
    marginTop: -3,
  },
  dateText: {
    fontSize: 15,
    color: Colors.contentPrimary,
  },
  timeText: {
    fontSize: 12,
    color: Colors.bulkTask.bulkGrey,
  },
  taskWrapper: {
    flexDirection: 'row',
  },
  actionContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 30,
    height: 57,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 16,
  },
  taskList: {
    paddingHorizontal: 30,
    width: '100%',
  },
})
