import { VitalOptions, VitalRanges } from 'constants/VitalOptions'
import { weightFactor } from 'constants/Weight'
import {
  getSheet_getSheet_treatments_items as parentSheetTreatment,
  getSheet_getSheet_treatments_items_treatments_items as SheetTreatment,
  getSheet_getSheet_treatments_items_treatments_items_product_pims_mapping as PimsMapping,
  getSheet_getSheet_treatments_items_treatments_items_treatments_items as ChildrenTreatment,
} from 'src/types/getSheet'
import { getTreatment_getTreatment as GetTreatment } from 'src/types/getTreatment'
import { ProductType } from 'src/types/globalTypes'
import { TreatmentFieldsBase } from 'src/types/TreatmentFieldsBase'

import { Action, FluidAction } from '../types'

type Treatment =
  | parentSheetTreatment
  | SheetTreatment
  | GetTreatment
  | ChildrenTreatment
type HasProductType = {
  product?: {
    name?: string
    pims_mapping?: PimsMapping[] | null
    track_vital?: boolean | null
    origin_id: string | null
  } | null
}
export const getProductMustHaveValue = (treatment?: HasProductType | null) => {
  return (
    getTreatmentTracksVital(treatment) ||
    getTreatmentIsHealthStatusProduct(treatment)
  )
}

export const getTreatmentHasVitalsOptions = (
  treatment?: HasProductType | null,
  vitalOptions = VitalOptions,
) => {
  if (!treatment) return false
  const treatmentOriginId = treatment.product?.origin_id
  if (!treatmentOriginId) return false
  return treatmentOriginId in vitalOptions
}

export const getTreatmentHasCustomValues = (treatment?: Treatment | null) => {
  return !!treatment?.product?.custom_values?.length ?? false
}

export const getTreatmentCustomValueOptions = (
  treatment?: Treatment | null,
) => {
  return (
    treatment?.product?.custom_values?.map(customVal => ({
      text: customVal.value ?? '',
      value: customVal.value ?? '',
    })) ?? []
  )
}

export const getTreatmentHasRange = (treatment?: Treatment | null) =>
  (treatment?.product && treatment?.product?.name in VitalRanges) ?? false

export const getTreatmentHasWeightUnit = (treatment?: Treatment | null) =>
  !!(
    treatment?.product?.default_unit &&
    treatment?.product?.default_unit in weightFactor
  )

export const getTreatmentIsHealthStatusProduct = (
  treatment?: HasProductType | null,
) =>
  !!treatment?.product?.pims_mapping?.find(
    pims => pims.dest === 'healthstatus',
  ) && getTreatmentHasVitalsOptions(treatment)

export const getTreatmentIsMedication = (treatment?: Treatment | null) =>
  treatment?.product?.type === ProductType.MEDICATION

export const getTreatmentIsObservation = (treatment?: Treatment | null) =>
  treatment?.product?.type === ProductType.OBSERVATION

export const getTreatmentIsValueRequired = (treatment?: Treatment | null) => {
  return treatment?.product?.is_value_required ?? false
}

export const getTreatmentIsNotesRequired = (
  action: Action | FluidAction,
  treatment?: Treatment | null,
) => {
  return (
    treatment?.product?.is_notes_required &&
    action !== Action.RESCHEDULE &&
    action !== FluidAction.SCHEDULE_FLUID
  )
}

export const getTreatmentTracksVital = (treatment?: HasProductType | null) =>
  !!treatment?.product?.track_vital

export const getTreatmentInfo = (treatment?: Treatment | null) => ({
  customValueOptions: getTreatmentCustomValueOptions(treatment),
  hasCustomValues: getTreatmentHasCustomValues(treatment),
  hasRange: getTreatmentHasRange(treatment),
  hasVital: getTreatmentTracksVital(treatment),
  hasWeightUnit: getTreatmentHasWeightUnit(treatment),
  isHealthStatusProduct: getTreatmentIsHealthStatusProduct(treatment),
  isMedication: getTreatmentIsMedication(treatment),
  isObservation: getTreatmentIsObservation(treatment),
  isValueRequired: getTreatmentIsValueRequired(treatment),
  isNotesRequired: treatment?.product?.is_notes_required ?? false,
})

export const getMustCompleteTaskWithValue = (
  action: Action | FluidAction,
  treatment?: Treatment | null,
) => {
  const { hasVital, isHealthStatusProduct } = getTreatmentInfo(treatment)
  return action === Action.COMPLETE && (hasVital || isHealthStatusProduct)
}

export const getMustCompleteTaskWithInitials = (
  action: Action | FluidAction,
  treatment?: Treatment | null,
) => {
  const isMedication = getTreatmentIsMedication(treatment)
  const isFluidStyleTreatment = getIsFluidStyleTaskDisplay(treatment)

  const mustShowMedicationInitials =
    isMedication && (action === Action.COMPLETE || action === Action.EDIT)

  const mustShowFluidInitials =
    (isFluidStyleTreatment &&
      (action === FluidAction.START ||
        action === FluidAction.VIEW ||
        action === FluidAction.STOP)) ||
    action === FluidAction.CREATE_FLUID

  return mustShowMedicationInitials || mustShowFluidInitials
}

export const getIsCRI = (treatment?: TreatmentFieldsBase | null) => {
  return treatment?.medicine_dosage_info?.is_cri ?? false
}

export const getIsFluid = (treatment?: TreatmentFieldsBase | null) => {
  return treatment?.product?.type === ProductType.IVFLUIDS ?? false
}

export const getIsFluidStyleTaskDisplay = (
  treatment?: TreatmentFieldsBase | null,
) => {
  if (!treatment) return false
  const isFluid = getIsFluid(treatment)
  const isCRI = getIsCRI(treatment)
  return isFluid || isCRI
}

export const getIsTreatmentFullHeight = (treatment?: Treatment | null) => {
  const isMedication = getTreatmentIsMedication(treatment)
  const isFluidCRI = getIsFluidStyleTaskDisplay(treatment)
  return isMedication || isFluidCRI || !!treatment?.conditional
}
