import React from 'react'
import {
  View,
  Text,
  StyleSheet,
  ViewStyle,
  TouchableOpacity,
  Platform,
} from 'react-native'
import { Colors, Typography, Variables } from 'src/design-system/theme'
import { HelpHeader } from './Header'
import { SvgGraduationCap } from 'components/Icons/GraduationCap'
import { useTrainingSwitch } from 'components/Training/useTrainingSwitch'
import { useTranslation } from 'react-i18next'
import { useOrganisationHook } from 'src/hooks/useOrganisation'
import { navigateToHelpSite } from '../HelpDocumentationLink'
import { SvgHyperLinkOut } from 'components/Icons/HyperLinkOut'
import { useUser } from 'src/context/user'
import { mailToSupport } from 'src/utils/mailTo'

type Props = { onClose: () => void; navigateToForm: () => void }
export const HelpScreen: React.FC<Props> = ({ onClose, navigateToForm }) => {
  const { t } = useTranslation()
  const { toggleTrainingMode, isTrainingOrganisation } = useTrainingSwitch()
  const { instanceUrl } = useOrganisationHook()
  const { username } = useUser()

  const handleNavigateToHelp = () => {
    onClose()
    navigateToHelpSite(instanceUrl)
  }

  const onContactSupport = async () => {
    onClose()

    await mailToSupport(instanceUrl ?? '', username)
  }

  return (
    <View>
      <HelpHeader
        title={t('help:helpScreen:title')}
        onPress={onClose}
        icon="close"
      />
      <View style={styles.section}>
        {!isTrainingOrganisation && (
          <Text>{t('help:helpScreen:trainingIntro')}</Text>
        )}
        <TrainingModeButton
          onPress={toggleTrainingMode}
          title={
            isTrainingOrganisation
              ? t('help:helpScreen:leaveTraining')
              : t('help:helpScreen:enterTraining')
          }
        />
      </View>
      {!isTrainingOrganisation && (
        <View style={styles.section}>
          <LinkOut
            onPress={handleNavigateToHelp}
            title={t('help:documentLinkTitle')}
          />
          <LinkOut
            onPress={onContactSupport}
            title={t('help:contactSupport')}
          />
          <TouchableOpacity onPress={navigateToForm}>
            <Text style={styles.link}>{t('help:helpScreen:feedbackLink')}</Text>
          </TouchableOpacity>
        </View>
      )}
      {Platform.OS === 'web' && (
        <View style={styles.footer}>
          <DoneButton title={t('help:doneButtonText')} onPress={onClose} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  footer: {
    backgroundColor: Colors.Backgrounds.primary,
    borderTopWidth: Variables.BorderWidths.base,
    borderColor: Colors.Borders.primary,
    padding: Variables.GutterSpacing.md,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  section: {
    gap: Variables.GutterSpacing.base,
    padding: Variables.GutterSpacing.md,
    borderColor: Colors.Borders.primary,
    borderTopWidth: 1,
  },
  link: {
    color: Colors.Contents.accent,
  },
})

const TrainingModeButton: React.FC<{
  onPress: () => void
  title: string
  style?: ViewStyle
}> = ({ title, onPress, style }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[buttonStyles.button, style]}>
      <SvgGraduationCap color={Colors.Contents.accent} />
      <Text style={[buttonStyles.buttonText]}>{title}</Text>
    </TouchableOpacity>
  )
}

const DoneButton: React.FC<{
  onPress: () => void
  title: string
  style?: ViewStyle
}> = ({ title, onPress, style }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[buttonStyles.button, buttonStyles.done, style]}
    >
      <Text style={[buttonStyles.doneText]}>{title}</Text>
    </TouchableOpacity>
  )
}

const LinkOut: React.FC<{
  onPress: () => void
  title: string
}> = ({ title, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={buttonStyles.linkContainer}>
        <Text style={buttonStyles.link}>{title}</Text>
        <SvgHyperLinkOut
          color={buttonStyles.link.color}
          width={12}
          height={12}
        />
      </View>
    </TouchableOpacity>
  )
}

const buttonStyles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 4,
    paddingVertical: Variables.Size.sm,
    borderColor: Colors.Contents.accent,
    borderWidth: 1,
    gap: 10,
  },
  done: {
    paddingHorizontal: Variables.GutterSpacing.md,
    paddingVertical: Variables.GutterSpacing.sm,
    marginLeft: Variables.GutterSpacing.base,
    backgroundColor: Colors.Backgrounds.accentPrimary,
    flexGrow: 0,
  },
  doneText: {
    color: Colors.Contents.onColor,
    fontWeight: Typography.FontWeights.bold,
    fontSize: Typography.FontSizes.base,
  },
  buttonText: {
    color: Colors.Contents.accent,
    fontWeight: Typography.FontWeights.bold,
    fontSize: Typography.FontSizes.base,
  },
  linkContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  link: {
    color: Colors.Contents.accent,
  },
})
