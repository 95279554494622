import React from 'react'
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from 'components/common'
import { SelectOption } from '../Select/Select.types'
import { DialogHeights } from '../Dialog/common'
import { startOfHour, startOfMinute } from 'date-fns'
import { WarningType } from '../DateTimePicker/index.types'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'

type PickerType = 'now' | 'discharge' | 'edit'
type Props<T extends PickerType> = {
  dialog?: boolean
  onChange: (date: Props<T>['value']) => void
  style?: StyleProp<ViewStyle>
  type: T
  value: T | Date
  lastUsedTime?: Date
  dialogHeight?: DialogHeights
  textStyle?: StyleProp<TextStyle>
  labelStyle?: StyleProp<TextStyle>
  defaultTimeToStart?: Date
  title?: string
}

export enum StartAtTypes {
  NOW = 'now',
  DT = 'dateTime',
  LAST = 'lastUsed',
}

export const SelectDateTimePicker = <T extends PickerType>({
  dialog = true,
  dialogHeight = DialogHeights.SMALL,
  lastUsedTime,
  onChange,
  style,
  type,
  value,
  textStyle,
  labelStyle,
  defaultTimeToStart,
  title,
}: Props<T>) => {
  const { t } = useTranslation()
  const isLastUsedTime = !!lastUsedTime

  const { dateFormatter } = useAdminTimeFormat()

  const options: SelectOption<StartAtTypes>[] = [
    {
      value: StartAtTypes.NOW,
      text: type === 'edit' ? 'now' : type,
    },
    {
      value: StartAtTypes.DT,
      text: t('addTreatment:schedule:dateTime'),
    },
    ...(isLastUsedTime
      ? [
          {
            value: StartAtTypes.LAST,
            text: `${t('createTreatment.matchLastTask')} ${dateFormatter(
              lastUsedTime as Date,
            )}`,
          },
        ]
      : []),
  ]

  const defaultTime = defaultTimeToStart ?? startOfHour(new Date())

  const getTimeToStart = (
    selectedVal: StartAtTypes,
    selectChanged: boolean = false,
  ) => {
    if (selectChanged) {
      return selectedVal === StartAtTypes.DT
        ? defaultTime
        : startOfMinute(new Date())
    }
    if (isLastUsedTime) return lastUsedTime as Date

    if (!(value instanceof Date)) {
      return startOfMinute(new Date())
    }
    return value
  }

  const selected =
    options[isLastUsedTime ? 2 : value instanceof Date ? 1 : 0].value
  const timeToStart = getTimeToStart(selected)

  const pickerLabel =
    type === 'discharge' ? 'Repeat until date/time' : 'Start at date/time'

  return (
    <View style={[style, styles.container_z_index]}>
      <DateTimePicker
        dialog={dialog}
        dialogHeight={dialogHeight}
        label={pickerLabel}
        onChange={onChange}
        value={timeToStart}
        textStyle={textStyle}
        labelStyle={labelStyle}
        title={title}
        warningInfo={{ type: WarningType.TREATMENT }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container_z_index: {
    zIndex: 1,
  },
})
