import React from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { processFontFamily } from 'expo-font'
import { Text as SVGText } from 'react-native-svg'

type ChartTextProps = {
  isSmall?: boolean
  children: string | number
  fontSizeSmall?: number
  fontSize?: number
  transform?: string
}
export const ChartText: React.FC<ChartTextProps> = ({
  children: value,
  fontSize = 12,
  fontSizeSmall = 10,
  isSmall = false,
  transform = 'translate(0 -8)',
}) => {
  return (
    <SVGText
      fontFamily={processFontFamily(Fonts.regular)!}
      fontSize={isSmall ? fontSizeSmall : fontSize}
      letterSpacing={0.6}
      stroke={Colors.placeholderGrey}
      textAnchor="middle"
      transform={transform}
    >
      {value}
    </SVGText>
  )
}
