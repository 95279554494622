import React from 'react'
import { View, Image, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { Heading, Label } from 'src/design-system/components/Text'
import { FontFamilies } from 'src/design-system/theme/typography/base'
import { useBreakpoint } from 'src/hocs/breakpoint'

type Props = {
  title: string
  subtitle?: string
  style?: StyleProp<ViewStyle>
}

export const AuthHeader: React.FC<Props> = ({ title, subtitle, style }) => {
  const { isExSmallScreen } = useBreakpoint()

  const showLogo = !isExSmallScreen

  return (
    <View style={[styles.container, style]}>
      {showLogo ? (
        <View style={styles.images}>
          <Image
            source={require('assets/images/idexx-logo.png')}
            resizeMode="contain"
            style={styles.logoImage}
          />
          <Image
            source={require('assets/images/vetradar-icon.png')}
            resizeMode="contain"
            style={styles.logoIcon}
          />
        </View>
      ) : null}
      <Heading size={'XL'} style={[styles.boldFont]}>
        {title}
      </Heading>
      <Label size="L">{subtitle}</Label>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 8,
  },
  logoIcon: {
    width: 50,
    height: 50,
    marginLeft: 8,
  },
  boldFont: {
    fontFamily: FontFamilies.bold,
  },
  logoImage: {
    width: 121,
    height: 23,
  },
  images: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
