import { useMemo } from 'react'
import { useIsHideDiscontinuedTreatment } from 'src/hooks/useIsHideDiscontinuedTreatment'

import { getSortedTreatmentDetails } from './common/treatmentSelectors'
import { TreatmentSheet } from './common/types'

export const useSortedTreatmentDetails = (treatment: TreatmentSheet) => {
  const { isHideDiscontinuedTreatment } = useIsHideDiscontinuedTreatment()

  return useMemo(
    () => getSortedTreatmentDetails(treatment, isHideDiscontinuedTreatment),
    [treatment, isHideDiscontinuedTreatment],
  )
}
