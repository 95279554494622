import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import {
  getLocationTree as GetLocationTree,
  getLocationTreeVariables as GetLocationTreeVariables,
} from 'types/getLocationTree'
import { GET_LOCATION_TREE } from 'components/Whiteboard/graphql'
import { useOrganisation } from 'src/context/organisation'

import { SelectOption } from 'components/common/TreeSelect/index.types'

const UnassignedValue = 'UNASSIGNED'

const UnassignedText = 'Unassigned'

export const UnassignedOption = { value: UnassignedValue, text: UnassignedText }

export const generateLocationOptions = (
  dataLocationTree: GetLocationTree | undefined,
) => {
  const locationTree = dataLocationTree?.getLocations.items ?? []
  return locationTree.map(location => {
    const locationOption: SelectOption<string> = {
      value: location.id,
      text: location.name,
    }
    const children = location.locations?.items
    if (!!children?.length) {
      locationOption.children = children.map(childLocation => ({
        value: childLocation.id,
        text: childLocation.name,
      }))
    }
    return locationOption
  })
}

export const useLocationTree = (
  hasUnassigned: boolean = false,
  skip = false,
) => {
  const [{ organisationId }] = useOrganisation()
  const { data: dataLocationTree, loading } = useQuery<
    GetLocationTree,
    GetLocationTreeVariables
  >(GET_LOCATION_TREE, {
    skip,
    variables: {
      organisationId,
    },
  })
  const locationOptions = useMemo(() => {
    let locationDataOptions = generateLocationOptions(dataLocationTree)
    if (hasUnassigned) {
      locationDataOptions = [UnassignedOption, ...locationDataOptions]
    }
    return locationDataOptions
  }, [dataLocationTree, hasUnassigned])

  return {
    locationOptions,
    loading,
  }
}
