import React from 'react'
import { FluidTaskActions } from 'components/Task/FluidTaskActions'
import { TaskActions } from 'components/Task/TaskActions'
import { Task } from 'components/Task/types'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { TimeSegment } from 'src/hocs/types/time'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { RequireApprovalBanner } from 'components/Banners/RequireApprovalBanner'
import { CubexTaskBanner } from 'components/Banners/CubexTaskBanner'

type Props = {
  sheetId: string
  isFluidStyleTaskDisplay: boolean
  visible: boolean
  toggleTaskActionsDrawer: () => void
  title?: string
  patientId?: string
  taskToEdit: Task | null
  isFinalized: boolean
  timeSegment: TimeSegment
  treatmentToEdit: TreatmentChildLevel | null
  timePress: Date
}

export const TaskActionsDrawer: React.FC<Props> = React.memo(
  ({
    sheetId,
    isFluidStyleTaskDisplay,
    patientId = '',
    visible,
    toggleTaskActionsDrawer,
    title,
    taskToEdit,
    isFinalized,
    timeSegment,
    treatmentToEdit,
    timePress,
  }) => {
    return (
      <SheetAwareSideDrawer
        visible={visible}
        onClose={toggleTaskActionsDrawer}
        title={title}
      >
        <CubexTaskBanner task={taskToEdit} />
        <RequireApprovalBanner treatment={treatmentToEdit} />
        {isFluidStyleTaskDisplay ? (
          <FluidTaskActions
            sheetId={sheetId}
            isFinalized={isFinalized}
            patientId={patientId}
            task={taskToEdit}
            timeSegment={timeSegment}
            toggleDialog={toggleTaskActionsDrawer}
            treatment={treatmentToEdit}
            timeInGrid={timePress}
          />
        ) : (
          <TaskActions
            sheetId={sheetId}
            task={taskToEdit}
            treatment={treatmentToEdit}
            toggleDialog={toggleTaskActionsDrawer}
            isFinalized={isFinalized}
            timeSegment={timeSegment}
            patientId={patientId}
          />
        )}
      </SheetAwareSideDrawer>
    )
  },
)

TaskActionsDrawer.displayName = 'TaskActionsDrawer'
