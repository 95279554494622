import { useTranslation } from 'react-i18next'
import { ActionableListItem, Center } from 'components/common'
import { Actions } from 'components/common/MultiActions'
import React, { useMemo } from 'react'
import { TreatmentFrequencyUpdateInput } from 'types/globalTypes'
import {
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { SvgArrow, SvgMeatballs } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { useOpenMenu } from 'src/hooks/useOpenMenu'
import { Fonts } from 'constants/Fonts'

export const TreatmentFrequencyListRow: React.FC<{
  handleDelete: (id: string) => void
  item: TreatmentFrequencyUpdateInput
  onPress: (frequency: TreatmentFrequencyUpdateInput) => void
  organisationId: string
  isSelected: boolean
}> = ({ handleDelete, item, onPress, isSelected }) => {
  const { name } = item
  const { t } = useTranslation()

  const menuItems = useMemo(
    () => [
      {
        name: Actions.DELETE,
        onClick: () => handleDelete(item.id),
        confirmationOption: {
          title: t('general.deleteItem', { itemName: item.name }),
          text: t('general.confirm'),
        },
      },
    ],
    [t, item, handleDelete],
  )
  const showMeatBalls = !!menuItems

  const { anchorEl, actions, openMenu, handleCloseTemplateMenu } =
    useOpenMenu(menuItems)

  const handPress = () => onPress && onPress(item)

  const textStyle = useMemo(
    () => [styles.title, isSelected && styles.selectedTitle],
    [isSelected],
  )
  return (
    <Center>
      <ActionableListItem
        actions={actions}
        anchorEl={anchorEl}
        closeMenu={handleCloseTemplateMenu}
        isActionable={true}
      >
        <Pressable
          accessibilityLabel={`Navigate to treatment frequency ${name}`}
          onPress={handPress}
          style={styles.container}
        >
          <View style={styles.rowWrapper}>
            <View style={styles.row}>
              <Text style={textStyle} numberOfLines={1} testID={name}>
                {name}
              </Text>
            </View>
          </View>
          {showMeatBalls ? (
            <TouchableOpacity
              accessibilityLabel={t('general.moreActions')}
              style={styles.meatBalls}
              onPress={openMenu}
            >
              <SvgMeatballs color={Colors.contentTertiary} />
            </TouchableOpacity>
          ) : null}
          <View style={styles.icon}>
            <SvgArrow orient="right" color="grey" width={15} height={15} />
          </View>
        </Pressable>
      </ActionableListItem>
    </Center>
  )
}

const styles = StyleSheet.create({
  icon: {
    padding: 16,
    maxWidth: '12%',
  },
  title: {
    fontFamily: Fonts.regular,
    fontSize: 16,
    // @ts-ignore
    minWidth: 'fit-content',
  },
  selectedTitle: {
    color: Colors.blue,
  },
  rowWrapper: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 'auto',
    maxWidth: '88%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    width: '100%',
  },
  container: {
    flexDirection: 'row',
    paddingLeft: 16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    width: '100%',
  },
  meatBalls: {
    paddingHorizontal: 16,
  },
})
