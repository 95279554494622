import React from 'react'
import { FontAwesome5 } from '@expo/vector-icons'
import { Colors } from 'constants/Colors'

type Props = {
  length?: number
  color?: string
}
export const SvgCreditCard: React.FC<Props> = ({
  color = Colors.contentSecondary,
  length = 20,
}) => <FontAwesome5 name="credit-card" size={length} color={color} />
