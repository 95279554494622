import React from 'react'
import { EditTreatmentScreen } from 'components/EditTreatment/EditTreatmentScreen'
import { StyleSheet } from 'react-native'
import { TreatmentActions } from 'components/Treatment/TreatmentActions'
import { useTranslation } from 'react-i18next'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'

type Props = {
  sheetId: string
  patientId: string
  isFinalized: boolean
  isEditTreatmentDrawerVisible: boolean
  isTreatmentActionsDrawerVisible: boolean
  treatmentToEdit: TreatmentChildLevel
  toggleTreatmentActionsDrawer: () => void
  toggleEditTreatmentDrawer: () => void
  onToggleEditTreatmentDrawer: () => void
}

enum TreatmentAction {
  EDIT = 'edit',
  ADD = 'add',
}

export const TreatmentActionDrawer: React.FC<Props> = React.memo(
  ({
    sheetId,
    patientId,
    isFinalized,
    isEditTreatmentDrawerVisible,
    isTreatmentActionsDrawerVisible,
    treatmentToEdit,
    toggleTreatmentActionsDrawer,
    toggleEditTreatmentDrawer,
    onToggleEditTreatmentDrawer,
  }) => {
    const { t } = useTranslation()
    const curTreatmentAction = isEditTreatmentDrawerVisible
      ? TreatmentAction.EDIT
      : TreatmentAction.ADD

    const treatmentDrawer = {
      [TreatmentAction.EDIT]: (
        <EditTreatmentScreen
          sheetId={sheetId}
          patientId={patientId}
          treatment={treatmentToEdit}
          onTreatmentEditDone={toggleEditTreatmentDrawer}
        />
      ),
      [TreatmentAction.ADD]: (
        <TreatmentActions
          sheetId={sheetId}
          patientId={patientId}
          toggleTreatmentActionsDrawer={toggleTreatmentActionsDrawer}
          treatment={treatmentToEdit}
          isFinalized={isFinalized}
          toggleEditTreatmentDrawer={onToggleEditTreatmentDrawer}
        />
      ),
    }

    const treatmentDrawerProps = {
      [TreatmentAction.EDIT]: {
        title: `${t('treatment:edit')} ${
          treatmentToEdit?.name ?? t('treatment:treatment')
        }`,
        visible: isEditTreatmentDrawerVisible,
        onClose: toggleEditTreatmentDrawer,
        titleStyle: styles.editTreatmentDrawer,
      },
      [TreatmentAction.ADD]: {
        title: treatmentToEdit?.name ?? '',
        visible: isTreatmentActionsDrawerVisible,
        onClose: toggleTreatmentActionsDrawer,
      },
    }

    return (
      <>
        <SheetAwareSideDrawer {...treatmentDrawerProps[curTreatmentAction]}>
          {treatmentDrawer[curTreatmentAction]}
        </SheetAwareSideDrawer>
      </>
    )
  },
)

TreatmentActionDrawer.displayName = 'TreatmentActionDrawer'

const styles = StyleSheet.create({
  editTreatmentDrawer: { paddingRight: 15 },
})
