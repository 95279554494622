import { getOneProduct_getProduct as OneProduct } from 'src/types/getOneProduct'

export function isCustomProduct(product: Partial<OneProduct>): Boolean {
  // TODO might be better to check against origin_type
  return !(product.origin_id && product.origin_id.slice(0, 2) !== 'VR')
}

export function getProductDefaultInstructions(
  product: Partial<OneProduct>,
): string {
  return isCustomProduct(product) ? product.description || '' : ''
}
