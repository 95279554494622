import { find } from 'lodash'
import { getDepartments_getSites_items as Sites } from 'src/types/getDepartments'
import { filterNull } from 'src/utils/notEmpty'
import { getPatient_getPatient_active_consultations_items as PatientConsultations } from 'types/getPatient'

export const getPatientDepartments = (
  patientConsultations?: PatientConsultations[] | null,
  organisationSites?: Sites[] | null,
) => {
  if (!patientConsultations || !organisationSites) return []

  return filterNull(
    patientConsultations.map(consult =>
      find(organisationSites, ['id', consult.site_id]),
    ),
  )
}
