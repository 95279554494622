import React from 'react'
import { StyleSheet, View } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'

import { Header } from 'src/components/Header/Header'
import { Routes } from 'src/constants/Routes'
import { WhiteboardPatientList } from 'src/components/Whiteboard/WhiteboardPatientList'
import {
  useHeaderSwitch,
  withFullScreenSwitchProvider,
} from 'src/context/fullScreenSwitch'
import { withDestroyOnBlur } from 'src/hocs/withDestroyOnBlur'
import { OrganisationBanner } from 'components/Banners/OrganisationBanner'

export const whiteboardsStackScreensPath = {
  [Routes.Whiteboard]: '',
}

const whiteboardsStackScreens = {
  [Routes.Whiteboard]: withDestroyOnBlur(WhiteboardPatientList),
}

export const WhiteboardsStack = withFullScreenSwitchProvider(() => {
  const MyStack = createStackNavigator()
  // eslint-disable-next-line
  const { isFullScreen } = useHeaderSwitch()

  return (
    <>
      {!isFullScreen ? <Header /> : <View style={styles.headerReplace} />}
      <OrganisationBanner />
      <MyStack.Navigator screenOptions={{ headerShown: false }}>
        {Object.entries(whiteboardsStackScreens).map(([name, component]) => (
          <MyStack.Screen name={name} component={component} key={name} />
        ))}
      </MyStack.Navigator>
    </>
  )
})

// without a height View, the patient list cannot scroll in full screen
const styles = StyleSheet.create({
  headerReplace: { height: 3 },
})
