import React from 'react'
import { SelectOption, SelectProps } from '..'
import { SelectBase } from '../base/base.web'
import { StylesConfig } from 'react-select'
import { Colors, Typography } from 'src/design-system/theme'

export const MultiSelect: React.FC<SelectProps> = props => {
  return <SelectBase {...props} styles={styleFunction} isMulti={true} />
}

const styleFunction: StylesConfig<SelectOption, boolean> = {
  multiValueLabel: base => ({
    ...base,
    fontFamily: Typography.FontFamilies.base,
    color: Colors.Contents.primary,
  }),
  multiValue: base => ({
    ...base,
    padding: 2,
    marginLeft: 0,
    backgroundColor: Colors.Backgrounds.overlay,
    height: 20,
    borderRadius: 10,
    alignItems: 'center',
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    display: state.isDisabled ? 'none' : 'flex',
    color: 'grey', // Using same as SVG
    '&:hover': {
      backgroundColor: 'transparent',
      color: Colors.Contents.primary,
    },
  }),
}
