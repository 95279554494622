import React from 'react'
import { TextLink } from 'components/common'
import { Colors } from 'constants/Colors'
import { noop } from 'lodash'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

import { styles } from './taskTransitionStyles'

type ResetButtonProps = {
  onReset: () => void
  buttonText: string
  disabled?: boolean
  a11yLabel?: string
}

export const ResetButton: React.FC<ResetButtonProps> = ({
  onReset,
  buttonText,
  disabled,
  a11yLabel,
}) => {
  const { t } = useTranslation()
  return (
    <View style={styles.linkBtnsContainer}>
      <TextLink
        a11yLabel={a11yLabel ?? t('task:taskTransition:reset:a11y')}
        text={buttonText}
        onPress={disabled ? noop : onReset}
        fontSize={14}
        containerStyles={styles.linkBtnStyle}
        color={disabled ? Colors.disabled : Colors.buttons.text}
      />
    </View>
  )
}
