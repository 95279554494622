import React, { useCallback } from 'react'
import { usePatientById } from 'src/hooks/usePatient'
import { View, Text, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'components/IconButton'
import { Colors } from 'constants/Colors'
import { SvgExpandArrows } from 'components/Icons/ExpandArrows'
import { Shadows } from 'constants/Shadows'
import {
  PatientPanelDrawerActionTypes,
  usePatientPanelDrawerContext,
} from 'src/context/patientPanelDrawer'
import { useLocalToggle } from 'components/Sheet/useLocalToggle'
import { local_toggle_type } from 'constants/LocalToggleOptions'
import { Fonts } from 'constants/constants'
import { DrawerAction } from 'components/Sheet/ReadySheet'
import { MinimisedCriticalNotesCard } from 'components/PatientPanel/Cards/MinimisedCriticalNotes'
import { MinimisedPrimaryInfo } from 'components/PatientPanel/Cards/MinimisedPrimaryInfo'
import { HEADER_HEIGHT } from 'constants/Header'

type Props = {
  patientId: string
  selectDrawerAction: (action: DrawerAction | null) => void
}

export const MobilePatientPanelV2: React.FC<Props> = ({
  patientId,
  selectDrawerAction,
}) => {
  const patient = usePatientById(patientId)
  const patientName = `"${patient?.name}" ${patient?.contact?.last_name ?? ''}`

  const [{ shouldShowDrawer }, dispatch] = usePatientPanelDrawerContext()
  const [, setShowDrawer] = useLocalToggle({
    prefix: null,
    type: local_toggle_type.SHOWDRAWER,
  })

  const onPressOpenPatientDetails = useCallback(() => {
    selectDrawerAction(DrawerAction.DETAILS)
  }, [selectDrawerAction])

  const { t } = useTranslation()
  const handleShowPatientDrawer = useCallback(() => {
    dispatch({
      type: PatientPanelDrawerActionTypes.setShouldShowDrawer,
      shouldShowDrawer: true,
    })
    setShowDrawer(true)
  }, [dispatch, setShowDrawer])

  if (shouldShowDrawer) return null
  return (
    <>
      <View style={styles.wrapper}>
        <View style={styles.title}>
          <View style={styles.patientName}>
            <Text
              ellipsizeMode={'tail'}
              numberOfLines={1}
              style={styles.headerText}
            >
              {patientName ?? ''}
            </Text>
          </View>
          <IconButton
            action={handleShowPatientDrawer}
            label={t('patientPanel:panel.collapse.label')}
            iconColor={Colors.white}
            icon={SvgExpandArrows}
            mobileHeaderToggle={true}
            iconHeight={17}
            iconWidth={17}
          />
        </View>
        <View>
          {!!patient && (
            <>
              <View style={styles.primaryInfo}>
                <MinimisedPrimaryInfo
                  patient={patient}
                  onPress={onPressOpenPatientDetails}
                />
              </View>
              {!!patient.critical_notes && (
                <View style={styles.criticalNotes}>
                  <MinimisedCriticalNotesCard notes={patient.critical_notes} />
                </View>
              )}
            </>
          )}
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    backgroundColor: Colors.backgroundGrey,
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
  },
  patientName: {
    height: 30,
    borderRadius: 4,
    backgroundColor: Colors.white,
    paddingVertical: 0,
    paddingHorizontal: 8,
    flexGrow: 0,
    flexShrink: 1,
    marginRight: 8,
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    ...Shadows.cardShadow,
  },
  primaryInfo: {
    marginBottom: 8,
  },
  criticalNotes: {
    height: HEADER_HEIGHT,
    marginHorizontal: 8,
    marginBottom: 8,
  },
  headerText: {
    fontWeight: '700',
    fontFamily: Fonts.bold,
    color: Colors.contentPrimary,
    fontSize: 14,
  },
})
