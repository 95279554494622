import React from 'react'
import { Tooltip } from '.'
import { SvgHelp } from 'components/Icons'

export const HelpTooltip = ({
  text,
  size,
}: {
  text: string
  size?: number
}) => {
  return (
    <Tooltip text={text}>
      <SvgHelp size={size} />
    </Tooltip>
  )
}
