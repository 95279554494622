import { Heading } from './Heading'
import { Container } from './Container'
import { SubHeading } from './SubHeading'
import { Warning } from './Warning'

/**
 * Exports composable components for building a product row.
 *
 * Example usage:
 * import { ProductRow } from 'components/common/ProductRow'
 * return (
 *  <ProductRow.Container>
 *   <ProductRow.Heading text="Hi">
 *    ... etc
 *  </ProductRow.Container>
 * )
 */
export const ProductRow = {
  Container,
  Heading,
  SubHeading,
  Warning,
}
