import { isEmpty } from 'lodash'
import {
  TaskPreviewFluidDosageInfo,
  TaskPreviewMedicineDosageInfo,
  TaskPreviewTask,
} from './types'
import { COMPLETED_STATUS } from './common'
import { getFluidTaskUnitTransferred } from 'components/Treatment/utils/getFluidTaskUnitTransferred'
import { FluidActionType } from 'types/globalTypes'

export const getRouteOfAdministration = (task: TaskPreviewTask) => {
  if (
    !task.medicine_dosage_info?.route_of_administration &&
    !task.fluid_dosage_info?.route_of_administration
  )
    return null
  return task.fluid_dosage_info?.route_of_administration
    ? `${task.fluid_dosage_info.route_of_administration}`
    : `${task.medicine_dosage_info?.route_of_administration}`
}

export const formatTaskPreviewMedicineDosageInfo = (
  dosageInfo: TaskPreviewMedicineDosageInfo | null,
  actionType: FluidActionType | null,
  isFluid: boolean,
): { [key: string]: string | null } | null => {
  if (!dosageInfo || isEmpty(dosageInfo)) return null
  const {
    previous_dose_rate,
    dose_rate,
    dose_rate_time_unit,
    dosage,
    dosage_weight_unit,
    dosage_patient_weight_unit,
    total_dosage,
    total_volume,
    total_volume_unit,
  } = dosageInfo

  const previousDoseRate =
    !actionType ||
    (previous_dose_rate &&
      [
        FluidActionType.RATE_CHANGE,
        FluidActionType.SCHEDULED_RATE_CHANGE,
      ].includes(actionType))
      ? `${previous_dose_rate}→`
      : ''

  const doseRate = formatMedicineDosageInfo([
    previousDoseRate,
    dose_rate,
    dosage_weight_unit,
    '/',
    dosage_patient_weight_unit,
    '/',
    getFluidTaskUnitTransferred(dose_rate_time_unit),
  ])

  const dosageRate = formatMedicineDosageInfo([
    dosage,
    dosage_weight_unit,
    '/',
    dosage_patient_weight_unit,
  ])

  const totalDosage = formatMedicineDosageInfo([
    total_dosage,
    dosage_weight_unit,
  ])
  const totalVolume = formatMedicineDosageInfo([
    total_volume,
    total_volume_unit,
  ])

  if (isFluid) {
    return {
      doseRate,
    }
  }
  return {
    dosageRate,
    totalDosage,
    totalVolume,
  }
}

const formatMedicineDosageInfo = (
  dosageInfo: (number | null | string)[],
): string | null => {
  const nullCheck = dosageInfo.some(k => k === null)
  if (nullCheck) return null
  return dosageInfo.filter(Boolean).join('')
}

export const formatTaskPreviewFluidDosageInfo = (
  dosageInfo?: TaskPreviewFluidDosageInfo | null,
): { [key: string]: string } | null => {
  if (!dosageInfo || isEmpty(dosageInfo)) return null
  const infusionRate =
    dosageInfo.previous_total_result &&
    [
      FluidActionType.RATE_CHANGE,
      FluidActionType.SCHEDULED_RATE_CHANGE,
    ].includes(dosageInfo.fluid_action_type!)
      ? `${dosageInfo.previous_total_result}→${dosageInfo.total_result}${dosageInfo?.total_result_unit}/hr`
      : `${dosageInfo?.total_result}${dosageInfo?.total_result_unit}/hr`
  return { infusionRate }
}

export const actionTypeToTextMapper = (action: FluidActionType) => {
  switch (action) {
    case FluidActionType.SCHEDULED_START:
      return 'Scheduled start'
    case FluidActionType.START:
      return 'Start'
    case FluidActionType.SCHEDULED_STOP:
      return 'Scheduled stop'
    case FluidActionType.STOP:
      return 'Stop'
    case FluidActionType.SCHEDULED_RATE_CHANGE:
      return 'Scheduled rate change'
    case FluidActionType.RATE_CHANGE:
      return 'Rate change'
    default:
      return null
  }
}

/**
 *
 * Filter and return the TaskPreview Tasks based on the showCompleted and is_fluid value
 *
 * @param tasks Array of TaskPreview Tasks
 * @param showCompleted Boolean value to determine to show completed tasks or not
 */
export const filterTaskPreviewTasks = (
  tasks: TaskPreviewTask[],
  showCompleted: boolean,
) => {
  return tasks.filter(task =>
    showCompleted ? task : !COMPLETED_STATUS.includes(task.status),
  )
}
