import React, { useCallback } from 'react'
import { Colors } from 'constants/Colors'
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  StyleProp,
  ViewStyle,
} from 'react-native'
import { Fonts } from 'src/constants/Fonts'

type Props = {
  options: [string, string]
  onChange: (value: boolean) => void
  disabled?: boolean
  value: boolean
  style?: StyleProp<ViewStyle>
  testID?: string
}

export const SwitchTab: React.FC<Props> = ({
  options,
  disabled = false,
  style,
  value,
  onChange,
  testID = 'SwitchButton',
}) => {
  const handleChange = useCallback(() => onChange(!value), [onChange, value])

  return (
    <View
      style={[
        styles.inputContainer,
        style,
        disabled && styles.disabledContainer,
      ]}
    >
      <TouchableOpacity
        testID={testID}
        disabled={disabled}
        onPress={handleChange}
        style={styles.buttonWrapper}
      >
        <View style={[styles.leftStyle, value && styles.activeColor]}>
          <Text style={styles.textStyle}>{options[0]}</Text>
        </View>
        <View style={[styles.rightStyle, !value && styles.activeColor]}>
          <Text style={styles.textStyle}>{options[1]}</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}
const styles = StyleSheet.create({
  inputContainer: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.shared.borderGrey,
    height: 26,
    width: 240,
    borderRadius: 8,
    backgroundColor: Colors.shared.borderGrey,
  },
  textStyle: { lineHeight: 24, fontFamily: Fonts.bold, fontSize: 12 },
  disabledContainer: {
    opacity: 0.3,
  },
  buttonWrapper: {
    height: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
    flex: 1,
  },
  activeColor: {
    backgroundColor: Colors.white,
  },
  rightStyle: {
    alignItems: 'center',
    flex: 1,
    backgroundColor: Colors.shared.borderGrey,
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
  },
  leftStyle: {
    alignItems: 'center',
    flex: 1,
    backgroundColor: Colors.shared.borderGrey,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
  },
})
