import { useOrgSettings } from './useOrgSettings'
import { Status, PermissionAction } from 'src/types/globalTypes'
import { getTime } from 'date-fns'
import { usePermission } from './usePermission'

export const useIsTaskPastEditingTimeout = (
  taskUserUpdatedAt: string | null,
  taskStatus?: Status | null,
): boolean => {
  const { settingsMap } = useOrgSettings()
  const userHasOverrideTaskTimeoutPermission = usePermission(
    PermissionAction.TASK_COMPLETED_LOCK_TIME__OVERRIDE,
  )
  const shouldSkip =
    !taskUserUpdatedAt ||
    taskStatus !== Status.DONE ||
    userHasOverrideTaskTimeoutPermission

  if (shouldSkip) {
    return false
  }

  const taskUserUpdatedAtMilliseconds = getTime(new Date(taskUserUpdatedAt))
  const currentTimeMilliseconds = getTime(new Date())
  const taskEditingTimeoutMilliseconds = !!settingsMap?.TASK_EDITING_TIMEOUT
    ?.value
    ? parseInt(settingsMap.TASK_EDITING_TIMEOUT?.value, 10)
    : 0

  if (!!taskEditingTimeoutMilliseconds) {
    return (
      currentTimeMilliseconds - taskUserUpdatedAtMilliseconds >
      taskEditingTimeoutMilliseconds
    )
  }

  return false
}
