import { getIsTaskDoneOrProgressOrSkipped } from 'components/Task/utils/taskTransitionUtils'
import { addMinutes } from 'date-fns'
import { compact, isNumber, uniqBy } from 'lodash'
import { DayRange } from 'src/hocs/timeContext'
import { TreatmentBaseWithTasks_schedule } from 'src/types/TreatmentBaseWithTasks'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskT } from 'src/types/getSheet'
import {
  FrequencyType,
  RescheduleTreatmentInput,
  ScheduleInput,
  Status,
  TreatmentTaskType,
} from 'src/types/globalTypes'
import { getOptimisticId } from 'src/utils/optimisticId'

import { BulkTasksKeyedByTaskID } from '../BulkTaskActions'

export type Schedule = {
  start_at: string
  repeat: boolean
  frequency: number
  repeat_until: string | null
  __typename: 'Schedule'
  repeat_count: number | null
  duration: number | null
  time_window: number | null
  enable_staffed_hour: boolean | null
  type: FrequencyType | null
  frequencies: number[] | null
  treatment_frequency_id: string | null
}

const optimisticTaskSeed: TaskT = {
  __typename: 'Task',
  id: 'id',
  organisation_id: 'organisation_id',
  patient_id: 'patient_id',
  sheet_id: 'sheet_id',
  treatment_id: 'treatment_id',
  status: Status.PENDING,
  start_at: null,
  stop_at: null,
  buffer_due: null,
  buffer_missed: null,
  given_start_at: null,
  given_stop_at: null,
  value: null,
  unit: null,
  notes: null,
  assigned_user: null,

  send_to_adaptor: null,
  _pending: null,
  type: TreatmentTaskType.NORMAL,
  photo_urls: null,
  videos: null,
  updated_at: null,
  user_updated_at: null,
  updated_by: null,
  created_at: '',
  attending_vet: null,
  attending_department: null,
  billing_reference: null,
  medicine_dosage_info: null,
  highlight_colour: null,
  cubex_transaction_status: null,
  qty_billed: null,
}

export const createScheduleDates = (
  schedule: ScheduleInput,
  visibleDayRange: DayRange,
) => {
  const { start_at, repeat, repeat_until, frequency } = schedule
  if (!start_at) {
    return []
  }
  if (!repeat || !frequency) {
    return [start_at]
  }

  const repeatUntil = repeat_until ? new Date(repeat_until) : visibleDayRange[1]

  let currentDate = new Date(start_at)

  const result = []
  while (currentDate <= repeatUntil) {
    result.push(currentDate.toISOString())
    currentDate = addMinutes(currentDate, frequency)
  }
  return result
}

export const createTasksForCacheTreatment = (
  oldTasks: TaskT[],
  rescheduleTreatment: RescheduleTreatmentInput,
  oldSchedule: TreatmentBaseWithTasks_schedule,
  visibleDayRange: DayRange,
  organisationId: string,
) => {
  const oldScheduleDates = createScheduleDates(oldSchedule, visibleDayRange)

  const oldScheduleHashMap: { [key: string]: Date } = oldScheduleDates.reduce(
    (result, startAt) => ({ ...result, [startAt]: new Date(startAt) }),
    {},
  )

  const reservedTasks = oldTasks.filter(task => {
    const taskStart = task.start_at
    // should delete old tasks
    if (!taskStart) {
      return false
    }

    const isBeforeReschedule =
      new Date(rescheduleTreatment.reschedule_from) > new Date(taskStart)

    const isNotInOldSchedule = !oldScheduleHashMap[taskStart]

    if (
      isBeforeReschedule ||
      getIsTaskDoneOrProgressOrSkipped(task.status) ||
      task.notes ||
      task.highlight_colour ||
      isNotInOldSchedule
    ) {
      return true
    }

    return false
  })

  const newTasksStartDates = createScheduleDates(
    rescheduleTreatment.schedule,
    visibleDayRange,
  )

  const newTasks = newTasksStartDates.map(startAt => ({
    ...optimisticTaskSeed,
    id: getOptimisticId(),
    organisation_id: organisationId,
    patient_id: rescheduleTreatment.patient_id,
    sheet_id: rescheduleTreatment.sheet_id,
    treatment_id: rescheduleTreatment.id,
    status: Status.PENDING,
    start_at: startAt,
    stop_at: addMinutes(
      new Date(startAt),
      rescheduleTreatment.schedule.time_window ?? 60,
    ).toISOString(),
  }))

  return uniqBy([...reservedTasks, ...newTasks], 'start_at')
}

export const getCheckedTreatmentWithNextTask = (
  allItems: BulkTasksKeyedByTaskID,
) => {
  const result = compact(
    Object.entries(allItems).map(
      ([_, { nextTaskToComplete, treatment, checked }]) =>
        checked
          ? {
              ...nextTaskToComplete,
              ...treatment,
              task_id: nextTaskToComplete.id,
            }
          : null,
    ),
  )

  return result
}

export const createStartDateString = (
  startAt: string,
  newStartDateOrRescheduleMinutes: Date | number,
) => {
  if (!isNumber(newStartDateOrRescheduleMinutes)) {
    return newStartDateOrRescheduleMinutes.toISOString()
  }

  const newDateString = addMinutes(
    new Date(startAt),
    newStartDateOrRescheduleMinutes,
  ).toISOString()

  return newDateString
}
