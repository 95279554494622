import React, { useMemo } from 'react'
import { StyleSheet, Text, TextProps, View, ViewProps } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'
import {
  FontFamilies,
  FontWeights,
} from 'src/design-system/theme/typography/base'

type Props = {
  size?: 'small' | 'medium' | 'large'
  color?: string
  iconPosition?: 'left' | 'right'
  icon?: React.ReactNode
  text?: string
  style?: ViewProps['style']
  textStyle?: TextProps['style']
}

export const Pill: React.FC<Props> = ({
  size = 'medium',
  color = Colors.Backgrounds.overlay,
  iconPosition = 'left',
  icon,
  text,
  style,
  textStyle,
}) => {
  const containerStyle = useMemo(() => {
    return [
      styles.container,
      styles[size],
      iconPosition === 'right' && styles.iconRight,
      { backgroundColor: color },
      style,
    ]
  }, [color, iconPosition, size, style])

  const textStyles = useMemo(() => {
    const styles = {
      small: {
        ...Typography.Label.S,
        fontFamily: FontFamilies.bold,
        color: Colors.Contents.primary,
      },
      medium: {
        ...Typography.Label.M,
        fontFamily: FontFamilies.bold,
        color: Colors.Contents.primary,
      },
      large: {
        ...Typography.Label.L,
        fontFamily: FontFamilies.bold,
        color: Colors.Contents.primary,
      },
    }
    return [styles[size], textStyle]
  }, [size, textStyle])

  return (
    <View style={containerStyle}>
      {icon ? <View accessibilityLabel={'pill-icon'}>{icon}</View> : null}
      {text ? (
        <Text style={textStyles} accessibilityLabel={'pill-text'}>
          {text}
        </Text>
      ) : null}
    </View>
  )
}

// TODO - remove eslint-disable once we have a better solution
// styles are accessed dynamically
/* eslint-disable react-native/no-unused-styles */
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 180,
    gap: 6,
  },
  small: {
    height: 24,
    paddingVertical: 2,
    paddingHorizontal: 6,
  },
  medium: {
    height: 32,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  large: {
    height: 40,
    paddingVertical: 5,
    paddingHorizontal: 12,
  },
  iconRight: {
    flexDirection: 'row-reverse',
  },
  textSmall: {
    ...Typography.Label.S,
    fontWeight: FontWeights.bold,
  },
  textMedium: {
    ...Typography.Label.M,
    fontWeight: FontWeights.bold,
  },
  textLarge: {
    ...Typography.Label.L,
    fontWeight: FontWeights.bold,
  },
})
