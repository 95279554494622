import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ThumbNail } from './Thumbnail'
import { VideoInput } from 'src/types/globalTypes'
import { toast } from 'components/common'
import { keyToUrl } from 'src/utils/keyToUrl'
import { UploadButton } from './UploadButton'
import { ImagePickerAsset, ImagePickerResult } from 'expo-image-picker'
import { PhotoAction, AttachmentInformation } from '../types'
import uuid from 'uuid'
import { settings } from 'src/vetradar-exports'
import { uploadMany } from 'src/utils/upload'
import { generateThumbnail } from 'src/utils/generateThumbnail/generateThumbnail'
import { downloadFromS3 } from 'src/utils/downloadFromS3'

type Props = {
  images: string[]
  videos: VideoInput[]
  objectKeyPrefix: string
  onChangePhotos: (
    photoUrls: string[] | string,
    photoAction?: PhotoAction,
  ) => void
  onChangeVideos: (
    videos: VideoInput[] | VideoInput,
    photoAction: PhotoAction,
  ) => void
  onUploading: (isUploading: boolean) => void
  isUploading: boolean
  shouldShowUploadBtn: boolean
  attachmentInfo: AttachmentInformation
}

const IMAGE_PREFIX = settings.s3PrefixForTtl
const VIDEO_PREFIX = settings.s3VideoPrefixForTtl

const MAX_TASK_FILES = 9

type KeyAndDataURI = {
  key: string
  dataURI: string
}

export const TaskFileAttachments: React.FC<Props> = ({
  images,
  videos,
  objectKeyPrefix,
  isUploading,
  onChangePhotos,
  onChangeVideos,
  onUploading,
  shouldShowUploadBtn,
  attachmentInfo,
}) => {
  const [tmpImages, setTempImages] = useState([] as KeyAndDataURI[])
  const [tmpVideos, setTempVideos] = useState([] as KeyAndDataURI[])

  const numFiles =
    videos.length + images.length + tmpImages.length + tmpVideos.length
  const canUpload =
    numFiles < MAX_TASK_FILES && shouldShowUploadBtn && !isUploading

  const handleSelect = async (files: ImagePickerResult) => {
    if (files.canceled) return
    onUploading(true)

    /* Warn and split if files go over limit */
    if (files.assets.length + numFiles >= MAX_TASK_FILES) {
      toast.error(`Maximum number of files exceeded (${MAX_TASK_FILES})`)
      files.assets = files.assets.slice(0, MAX_TASK_FILES - numFiles)
    }

    /* In one loop, split the videos and images, and validate filesize */
    let largeFileCount: number = 0
    const videos: ImagePickerAsset[] = []
    const images: ImagePickerAsset[] = []
    files.assets.forEach(asset => {
      /* EzyVet has 200mb upload limit, which we copy to avoid sync issues */
      if (asset.fileSize && asset.fileSize > 200 * 1024 * 1024) {
        largeFileCount += 1
      } else {
        if (asset.type === 'video') {
          videos.push(asset)
        }
        if (asset.type === 'image') {
          images.push(asset)
        }
      }
    })
    if (largeFileCount > 0) {
      toast.error('Some files exceed 200MB')
    }
    await Promise.all([handleAddVideos(videos), handleAddImages(images)])

    onUploading(false)
  }

  const handleAddVideos = async (assets: ImagePickerAsset[]): Promise<void> => {
    const videoPrefixedKeyAndURI: KeyAndDataURI[] = []
    const thumbnailPrefixedKeyAndURI: KeyAndDataURI[] = []
    const videoInput: VideoInput[] = []
    const videosForDisplay: KeyAndDataURI[] = []
    for (const video of assets) {
      const key = generateKey()
      try {
        // eslint-disable-next-line no-await-in-loop
        const thumbnail = (await generateThumbnail(video.uri)).uri
        videoPrefixedKeyAndURI.push({
          key: VIDEO_PREFIX + key,
          dataURI: video.uri,
        })
        thumbnailPrefixedKeyAndURI.push({
          key: `${IMAGE_PREFIX}video_thumbnails/${key}`,
          dataURI: thumbnail,
        })
        videoInput.push({
          url: key,
          thumbnail_url: `video_thumbnails/${key}`,
        })
        videosForDisplay.push({
          key,
          dataURI: thumbnail,
        })
      } catch (e) {
        toast.error('Unable to upload', null, e)
      }
    }
    await uploadMany(videoPrefixedKeyAndURI)
    await uploadMany(thumbnailPrefixedKeyAndURI)
    onChangeVideos(videoInput, PhotoAction.Add)
    setTempVideos(prev => [...prev, ...videosForDisplay])
  }

  const handleAddImages = async (assets: ImagePickerAsset[]): Promise<void> => {
    const keys: string[] = [] // Gather keys for onChangePhotos to avoid extra loop
    const keyAndURIs = assets.map(asset => {
      const key = generateKey()
      keys.push(key)
      return {
        key,
        dataURI: asset.uri,
      }
    })
    /* Prefixed key is used for TTL upload */
    const prefixedKeyAndURI = keyAndURIs.map(pair => {
      return {
        ...pair,
        key: IMAGE_PREFIX + pair.key,
      }
    })
    await uploadMany(prefixedKeyAndURI)
    onChangePhotos(keys, PhotoAction.Add)
    setTempImages(prev => [...prev, ...keyAndURIs])
  }

  const generateKey = (): string => {
    return `${objectKeyPrefix}/${uuid()}`
  }

  const handleRemoveImage = (key: string) => {
    setTempImages(prev => prev.filter(i => i.key !== key))
    onChangePhotos(key, PhotoAction.Remove)
  }

  const handleRemoveVideo = (key: string) => {
    setTempVideos(prev => prev.filter(i => i.key !== key))
    onChangeVideos(
      { url: key, thumbnail_url: `video_thumbnails/${key}` },
      PhotoAction.Remove,
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.thumbnailGroup}>
        {/* Images */}
        {images.map(key => (
          <ThumbNail
            key={key}
            type="image"
            uri={keyToUrl(key)}
            download={() => downloadFile(key, attachmentInfo)}
          />
        ))}
        {/* Videos */}
        {videos.map(({ thumbnail_url, url }) => (
          <ThumbNail
            key={url}
            type="video"
            uri={keyToUrl(thumbnail_url)}
            download={() => downloadFile(url, attachmentInfo)}
          />
        ))}
        {/* Uploading Images */}
        {tmpImages.map(({ key, dataURI }) => (
          <ThumbNail
            key={key}
            type="image"
            uri={dataURI}
            remove={() => handleRemoveImage(key)}
          />
        ))}
        {/* Uploading Videos */}
        {tmpVideos.map(({ key, dataURI }) => (
          <ThumbNail
            key={key}
            type="video"
            uri={dataURI}
            remove={() => handleRemoveVideo(key)}
          />
        ))}
      </View>
      {/* Upload Button */}
      {!!canUpload && (
        <View style={styles.buttonGroup}>
          <UploadButton
            options={{ allowsMultipleSelection: true }}
            onSelect={handleSelect}
          />
        </View>
      )}
    </View>
  )
}

const downloadFile = async (
  key: string,
  attachmentInfo: AttachmentInformation,
): Promise<void> => {
  try {
    await downloadFromS3(key, attachmentInfo)
  } catch (error) {
    toast.error('Error downloading file')
  }
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    gap: 8,
    marginBottom: 8,
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
  },
  thumbnailGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
  },
})
