import React, { ReactNode, useCallback } from 'react'
import { Pressable, View, StyleSheet, ViewStyle, StyleProp } from 'react-native'
import { Colors } from 'constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'

type IconButton = {
  disabled?: boolean
  containerStyle?: StyleProp<ViewStyle>
  action: () => void
  label: string
  icon?: React.FunctionComponent<{
    color: string
    width?: number
    height?: number
  }>
  iconColor: string
  iconWidth?: number
  iconHeight?: number
  mobileHeaderToggle?: boolean
  testID?: string
  children?: ReactNode
}

export const IconButton: React.FC<IconButton> = React.memo(
  ({
    disabled,
    containerStyle,
    action,
    label,
    icon,
    iconColor = Colors.white,
    iconWidth,
    iconHeight,
    mobileHeaderToggle,
    testID,
    children,
  }) => {
    const { isExSmallScreen, isSmallScreen } = useBreakpoint()

    const isSmallButtonHeight =
      isExSmallScreen || isSmallScreen || mobileHeaderToggle

    const pressableIconStyle = useCallback(
      ({ pressed }: { pressed: boolean }) => {
        const pressedStyle = pressed ? styles.pressableStyle : styles.empty
        return [
          styles.button,
          isSmallButtonHeight ? styles.smallButtonHeight : styles.buttonHeight,
          pressedStyle,
        ]
      },
      [isSmallButtonHeight],
    )

    return (
      <View
        style={[
          styles.container,
          containerStyle,
          (isExSmallScreen || mobileHeaderToggle) && styles.mobileContainer,
        ]}
      >
        <Pressable
          disabled={disabled}
          accessibilityLabel={label}
          onPress={action}
          testID={testID}
          style={pressableIconStyle}
        >
          {!!icon &&
            React.createElement(icon, {
              color: iconColor,
              width: iconWidth,
              height: iconHeight,
            })}
          {children}
        </Pressable>
      </View>
    )
  },
)

IconButton.displayName = 'IconButton'

const styles = StyleSheet.create({
  container: {
    width: 40,
    backgroundColor: Colors.buttons.blue,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  empty: {},
  mobileContainer: {
    width: 30,
  },
  smallButtonHeight: { height: 30 },
  buttonHeight: { height: 40 },
  pressableStyle: { opacity: 0.7, backgroundColor: Colors.white },
})
