import React from 'react'

import { useMutation } from '@apollo/client'
import { Layout, toast } from 'components/common'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { Routes } from 'constants/Routes'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { useOrganisation } from 'src/context/organisation'
import {
  createPatient as createPatientT,
  createPatientVariables,
} from 'src/types/createPatient'
import { CreatePatientInput } from 'src/types/globalTypes'

import { CREATE_PATIENT } from './graphql'
import { patientDataToInput, PatientForm, Values } from './PatientForm'
import { PatientStackParamList } from 'components/Patient/screens'
import { useUpdatePatientListCache } from 'src/components/Patient/useUpdatePatientListCache'

type Props = StackScreenProps<PatientStackParamList>

// TODO: Make contact_id a requred field in api
const DUMMY_CONTACT_ID = '***' // our system expects a link to a contact (you cant do this in the form yet)

export const AddPatientScreen: React.FC<Props> = ({ navigation }) => {
  const [{ organisationId }] = useOrganisation()
  const { t } = useTranslation()
  const updatePatientListCache = useUpdatePatientListCache()

  const { navigate } = navigation

  const [createPatient] = useMutation<createPatientT, createPatientVariables>(
    CREATE_PATIENT,
    {
      onError: err => {
        toast.error(err.message)
      },
      onCompleted: data => {
        updatePatientListCache(data?.createPatient, 'CREATE')
        toast.success(
          t('form.created', { itemName: data?.createPatient?.name }),
        )
        navigate(Routes.Patients)
      },
    },
  )

  const onSubmit = (values: Values) => {
    const input: CreatePatientInput = {
      organisation_id: organisationId,
      contact_id: DUMMY_CONTACT_ID,
      ...patientDataToInput(values),
    }
    createPatient({
      variables: {
        input,
      },
    })
  }
  const backButton = {
    title: 'title.patients',
    label: 'patient:list.returnHereLabel',
    action: () => navigate(Routes.Patients),
  }

  return (
    <>
      <SubHeader headlineKey="patient:new.title" backButton={backButton} />
      <Layout mode="single-center">
        <PatientForm submitTitle={t('patient:new.title')} onSubmit={onSubmit} />
      </Layout>
    </>
  )
}
