import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  color: string
}

export const SvgPatient: React.FunctionComponent<Props> = ({ color }) => (
  <Svg width={20} height={18}>
    <Path
      d="M9.6 17.6c18.247-12.023 5.95-22.106 0-15.513-5.95-6.593-18.247 3.49 0 15.513z"
      fill={color}
      fillRule="evenodd"
    />
  </Svg>
)
