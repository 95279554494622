import { getSafeDoseRange } from './getSafeDoseRange'
import { getIsDosageRateInSafeRange } from './getIsDosageRateInSafeRange'
import { getDosageSafeRangeInformativeText } from './getDosageSafeRangeInformativeText'
import { useTranslation } from 'react-i18next'
import { WeightUnit } from 'constants/Weight'
import { getTemplate_getTemplate_treatments_items_treatments_items_treatment_options_products_medicine_dosage_info as treatmentOptionMedicationDosageInfo } from 'src/types/getTemplate'
import { getOneProduct_getProduct_medicine_dosage_info as productMedicineDosageInfo } from 'src/types/getOneProduct'
import { useMemo } from 'react'

export const useSafeDosageRange = ({
  dosageInfo,
  defaultDosageValuesHasError,
  dosageRateValue,
  dosageWeightUnitValue,
  dosagePerWeightUnitValue,
  productName = '',
}: {
  dosageInfo?:
    | treatmentOptionMedicationDosageInfo
    | productMedicineDosageInfo
    | null
  defaultDosageValuesHasError: boolean
  dosageRateValue: number | null
  dosageWeightUnitValue: WeightUnit
  dosagePerWeightUnitValue: WeightUnit
  productName?: string
}) => {
  const { t } = useTranslation()

  const {
    lowestDosage = null,
    highestDosage = null,
    safeDoseRangeHasError,
  } = getSafeDoseRange(dosageInfo)

  const dosageRateOutOfSafeRangeWarningMessage = useMemo(() => {
    const dosageSafeRangeComparisonInfo = {
      lowestDosage,
      highestDosage,
      safeDosageWeightUnit: dosageInfo?.dosage_weight_unit as WeightUnit,
      safeDosagePerWeightUnit:
        dosageInfo?.dosage_patient_weight_unit as WeightUnit,
      dosageRate: dosageRateValue,
      dosageWeightUnit: dosageWeightUnitValue as WeightUnit,
      dosagePerWeightUnit: dosagePerWeightUnitValue as WeightUnit,
    }
    return getIsDosageRateInSafeRange(dosageSafeRangeComparisonInfo) ||
      safeDoseRangeHasError ||
      (defaultDosageValuesHasError && (lowestDosage || highestDosage))
      ? null
      : t('form.outsideSafeRangeWarning', {
          dosageRate: dosageRateValue,
          dosageWeightUnit: dosageWeightUnitValue,
          dosagePerWeightUnit: dosagePerWeightUnitValue,
        })
  }, [
    defaultDosageValuesHasError,
    dosageInfo?.dosage_patient_weight_unit,
    dosageInfo?.dosage_weight_unit,
    dosagePerWeightUnitValue,
    dosageRateValue,
    dosageWeightUnitValue,
    highestDosage,
    lowestDosage,
    safeDoseRangeHasError,
    t,
  ])

  const safeDoseRangeMessage = useMemo(() => {
    const dosageSafeRangeTextInput = {
      lowestDosage,
      highestDosage,
      productName,
      weightUnit: dosageInfo?.dosage_weight_unit as WeightUnit,
      perWeightUnit: dosageInfo?.dosage_patient_weight_unit as WeightUnit,
    }
    return getDosageSafeRangeInformativeText(dosageSafeRangeTextInput)
  }, [
    dosageInfo?.dosage_patient_weight_unit,
    dosageInfo?.dosage_weight_unit,
    highestDosage,
    lowestDosage,
    productName,
  ])

  const invalidSafeDoseRangeErrorMessage = useMemo(
    () =>
      safeDoseRangeHasError && !defaultDosageValuesHasError && !!dosageRateValue
        ? t('form.invalidSafeDoseRange', {
            dosageRate: dosageRateValue,
            dosageWeightUnit: dosageWeightUnitValue,
            dosagePerWeightUnit: dosagePerWeightUnitValue,
            productName,
          })
        : null,
    [
      defaultDosageValuesHasError,
      dosagePerWeightUnitValue,
      dosageRateValue,
      dosageWeightUnitValue,
      productName,
      safeDoseRangeHasError,
      t,
    ],
  )

  return {
    invalidSafeDoseRangeErrorMessage,
    safeDoseRangeMessage,
    dosageRateOutOfSafeRangeWarningMessage,
    safeDoseRangeHasError,
  }
}
