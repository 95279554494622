import { getSheet } from 'src/types/getSheet'
import { filterNull } from './notEmpty'

export const getHasProductDuplication = (
  sheetData: getSheet | null,
  productIds: (string | null | undefined)[] | undefined,
) => {
  return filterNull(
    sheetData?.getSheet?.treatments?.items?.flatMap(t =>
      t?.treatments?.items?.map(t => t.product?.id),
    ),
  ).some(id => productIds?.includes(id))
}

export const getProductDuplicates = (
  sheetData: getSheet | null,
  productIds: (string | null | undefined)[] | undefined,
) => {
  return filterNull(
    sheetData?.getSheet?.treatments?.items?.flatMap(t =>
      t?.treatments?.items?.map(t => ({
        id: t.product?.id,
        name: t.product?.name,
      })),
    ),
  ).filter(({ id }) => productIds?.includes(id))
}
