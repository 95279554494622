import React, { useCallback, useContext, useState } from 'react'
import { noop } from 'lodash'

import { Alert } from 'components/common'
import { Highlights } from 'components/common/Alert'

type OPTIONS = {
  text: string
  title: string
  highlights?: Highlights
  okText?: string
  cancelText?: string
}

type CONFIRM_FUN = (option: OPTIONS) => Promise<any>

const ConfirmContext = React.createContext<CONFIRM_FUN>(() =>
  Promise.resolve(null),
)
ConfirmContext.displayName = 'ConfirmContext'

type Props = {
  children: React.ReactElement
}

export const ConfirmProvider: React.FC<Props> = props => {
  const [isVisible, setIsVisible] = useState(false)
  const [[resolve, reject], setResolveReject] = useState([noop, noop])
  const [options, setOptions] = useState<OPTIONS>({
    text: '',
    title: '',
    highlights: {},
  })

  const confirm = useCallback((_options: OPTIONS) => {
    setOptions({ ..._options })
    setIsVisible(true)
    return new Promise((_resolve, _reject) => {
      return setResolveReject([_resolve, _reject]) as any
    })
  }, [])

  const onCancel = useCallback(() => {
    setIsVisible(false)
    reject()
  }, [reject])

  const onOK = useCallback(() => {
    setIsVisible(false)
    resolve()
  }, [resolve])

  return (
    <ConfirmContext.Provider value={confirm}>
      {props.children}
      <Alert
        onCancel={onCancel}
        onOK={onOK}
        visible={isVisible}
        title={options.title}
        text={options.text}
        highlights={options.highlights}
        okText={options.okText}
        cancelText={options.cancelText}
      />
    </ConfirmContext.Provider>
  )
}

export const useConfirm = () => {
  const context = useContext(ConfirmContext)
  if (context === undefined) {
    throw new Error('useConfirm must be used within a ConfirmContextProvider')
  }
  return context
}
