import React from 'react'
import { SvgArrow } from 'components/Icons'
import { components, type DropdownIndicatorProps } from 'react-select'
import { SelectOption } from '../..'

export const DropdownIndicator: React.FC<
  DropdownIndicatorProps<SelectOption, boolean>
> = props =>
  // @ts-ignore - Prop passed from parent for custom hideChevron behavior
  props.selectProps.hideChevron ? null : (
    <components.DropdownIndicator {...props}>
      <SvgArrow orient="down" color="grey" width={15} height={15} />
    </components.DropdownIndicator>
  )
