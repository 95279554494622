import { gql } from '@apollo/client'

export const GET_CUBEX_CABINETS = gql`
  query getCubexCabinetsCabinetIds($organisationId: ID!) {
    getCubexCabinets(organisation_id: $organisationId) {
      items {
        id
      }
    }
  }
`
