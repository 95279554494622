import React, { useCallback, useEffect, useState } from 'react'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { Routes } from 'constants/Routes'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { Layout, toast } from 'components/common'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  RouteOfAdministration,
  RouteOfAdministrationConfig,
} from 'components/Settings/RouteOfAdministration/types'
import { CreateRouteSection } from 'components/Settings/RouteOfAdministration/CreateRouteSection'
import { Footer } from 'components/Settings/RouteOfAdministration/Footer'
import { RouteSection } from 'components/Settings/RouteOfAdministration/RouteSection'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { Variables } from 'src/design-system/theme'
import { useConfirm } from 'src/context/confirm'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import * as _ from 'lodash'
import { useMutation } from '@apollo/client'
import {
  updateRouteOfAdministrations,
  updateRouteOfAdministrationsVariables,
} from 'types/updateRouteOfAdministrations'
import { SET_ROUTE_OF_ADMINISTRATIONS } from 'components/Settings/graphql'

export const RouteOfAdministrationScreen: React.FC = () => {
  const { routeOfAdministrations, organisation, loading } = useOrgSettings()

  const { navigate } = useNavigation()
  const { t } = useTranslation()
  const confirm = useConfirm()

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false)

  const navigateSettings = useCallback(async () => {
    if (!isFormDirty) return navigate(Routes.Settings)

    try {
      await confirm({
        title: t('settings:routeOfAdministration.confirm.title'),
        text: t('settings:routeOfAdministration.confirm.text'),
      })
      navigate(Routes.Settings)
    } catch (e) {
      // do nothing
    }
  }, [confirm, isFormDirty, navigate, t])

  const [updateRouteOfAdministrations, { loading: submitting }] = useMutation<
    updateRouteOfAdministrations,
    updateRouteOfAdministrationsVariables
  >(SET_ROUTE_OF_ADMINISTRATIONS, {
    onCompleted: _data => {
      toast.success(t('settings:update.success'))
    },
    onError: e => {
      console.error(e) // eslint-disable-line no-console
      toast.error(t('settings:update.err'))
    },
  })

  const setRouteOfAdministrations = async (data: RouteOfAdministration[]) => {
    if (!organisation) return

    await updateRouteOfAdministrations({
      variables: {
        input: {
          id: organisation.id,
          name: organisation.name,
          route_of_administrations: data,
        },
      },
    })
  }

  const backButton = {
    title: 'title.settings',
    label: 'returnTo.settings',
    action: navigateSettings,
  }

  const isLoading = loading || submitting

  return (
    <>
      <SubHeader
        backButton={backButton}
        headlineKey={t('settings:routeOfAdministration.header')}
      />

      {isLoading ? (
        <ActivityIndicator size="large" style={styles.spinner} />
      ) : null}

      {!loading && !!routeOfAdministrations ? (
        <RouteOfAdministrationForm
          routeOfAdministrations={routeOfAdministrations}
          setIsFormDirty={setIsFormDirty}
          setRouteOfAdministrations={setRouteOfAdministrations}
          isLoading={isLoading}
        />
      ) : null}
    </>
  )
}

type RouteOfAdministrationFormProps = {
  setIsFormDirty: (isDirty: boolean) => void
  routeOfAdministrations: RouteOfAdministration[]
  setRouteOfAdministrations: (data: RouteOfAdministration[]) => void
  isLoading: boolean
}

const RouteOfAdministrationForm: React.FC<RouteOfAdministrationFormProps> = ({
  setIsFormDirty,
  routeOfAdministrations,
  setRouteOfAdministrations,
  isLoading,
}) => {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { isValid, errors, isDirty, isSubmitSuccessful },
  } = useForm<RouteOfAdministrationConfig>({
    mode: 'onChange',
    defaultValues: {
      routes: _.orderBy(routeOfAdministrations, ['default'], ['desc']),
    },
  })

  useEffect(() => {
    setIsFormDirty(isDirty)
  }, [isDirty, setIsFormDirty])

  useEffect(() => {
    if (isSubmitSuccessful) {
      const routes = getValues('routes')
      reset({
        routes,
      })
    }
  }, [isSubmitSuccessful, reset, getValues])

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'routes',
  })

  const createNewRoute = () => {
    append({
      default: false,
      name: '',
      abbreviation: '',
      active: true,
      value: '',
    })
  }

  const removeRoute = (idx: number) => {
    remove(idx)
  }

  const onSave = async (data: RouteOfAdministrationConfig) => {
    await setRouteOfAdministrations(data.routes)
  }

  return (
    <>
      <Layout mode="single-center">
        <View style={styles.container}>
          <CreateRouteSection
            createDisabled={isLoading}
            createNewRoute={createNewRoute}
          />

          {fields.map((field, idx) => {
            return (
              <RouteSection
                removeRoute={removeRoute}
                idx={idx}
                key={field.id}
                control={control}
                watch={watch}
                setValue={setValue}
                errors={errors}
              />
            )
          })}
        </View>
      </Layout>
      <Footer
        canSubmit={!!isValid && !isLoading}
        onSubmit={handleSubmit(onSave)}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: Variables.GutterSpacing.md,
  },
  spinner: {
    marginTop: 25,
  },
})
