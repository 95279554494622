import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ValidPasswordSvg } from 'src/design-system/components/Icon/ValidPasswordIcon'
import { Paragraph } from 'src/design-system/components/Text/Paragraph'
import { Colors } from 'src/design-system/theme/colors'

export const ValidStatus: React.FC<{ status: string }> = ({ status }) => {
  return (
    <View style={styles.container}>
      <ValidPasswordSvg />
      <Paragraph size="S" style={styles.validPWStyle}>
        {status}
      </Paragraph>
    </View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center', gap: 8 },
  validPWStyle: { color: Colors.Contents.positive },
})
