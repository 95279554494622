import { useQuery, useApolloClient } from '@apollo/client'
import { useOrganisation } from 'src/context/organisation'
import {
  getSheetType as GetSheetType,
  getSheetTypeVariables as GetSheetTypeVariables,
} from 'src/types/getSheetType'
import { SheetType } from 'src/types/globalTypes'

import { GET_SHEET_TYPE, SHEET_TYPE } from './graphql'

export const useGetSheetType = (id: string) => {
  const client = useApolloClient()
  const [{ organisationId }] = useOrganisation()

  const cachedSheet = client.readFragment({
    id: `Sheet:${id}`,
    fragment: SHEET_TYPE,
  })

  const { data } = useQuery<GetSheetType, GetSheetTypeVariables>(
    GET_SHEET_TYPE,
    {
      skip: !!cachedSheet?.type || !id,
      variables: {
        organisationId,
        id,
      },
    },
  )

  return (
    (cachedSheet?.type || (data?.getSheet?.type as SheetType)) ??
    SheetType.NORMAL
  )
}
