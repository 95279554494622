import React, { useEffect } from 'react'
import { Button, FormBreak, TextInput, SwitchInput } from 'components/common'
import { Status } from 'components/common/Form/Status'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, FlatList, Text } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import * as Yup from 'yup'
import { Center } from 'src/components/common/Center'
import { OwnerSection } from 'components/Template/OwnerSection'
import {
  Controller,
  useForm,
  useFieldArray,
  useController,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getTemplateCallParameter_getTemplateCallParameter } from 'src/types/getTemplateCallParameter'
import { filterNull } from 'src/utils/notEmpty'
import { veterinary_roles } from 'src/types/globalTypes'
import { DepartmentsSection } from 'components/Template/DepartmentsSection'
import { ControlledTextInput } from 'components/common/TextInput/ControlledTextInput'

import { getOptimisticId } from 'src/utils/optimisticId'
import { SecondaryButton } from 'src/components/common'

import { ProductSelect } from './ProductSelect'
import { CallParameterInput } from 'components/SheetHeader/CallParameterInput'
import { templateValidName } from 'constants/Templates'
import { compareCallParamMinAndMax } from 'src/utils/compareCallParamMinAndMax'

const defaultValues = {
  name: '',
  user_id: null as null | string | undefined,
  sites: [] as string[],
  disabled: false as null | undefined | boolean,
  items: [] as CallParameterItem[],
}

export type Inputs = typeof defaultValues

export const validationSchema = Yup.object().shape({
  name: templateValidName,
  items: Yup.array().of(
    Yup.object().shape({
      product_id: Yup.string().required('Product required'),
      short_name: Yup.string().required('Short name required'),
    }),
  ),
})

const keyExtractor = (item: { id: string }) => item.id

export type CallParameterItem = {
  id: string
  name?: string
  short_name?: string
  product_id?: string
  min?: number | null
  max?: number | null
}

type Props = {
  handleSubmit: (data: Inputs) => void
  submitting: boolean
  template?: getTemplateCallParameter_getTemplateCallParameter
}

const getInitialValues = (
  template?: getTemplateCallParameter_getTemplateCallParameter,
): Inputs => {
  if (template?.id) {
    return {
      ...defaultValues,
      name: template?.name!,
      user_id: template?.user?.id,
      sites: filterNull(template?.sites?.map(i => i?.id) ?? []),
      disabled: template?.disabled,
      items: [
        ...defaultValues.items,
        ...(template?.call_parameter_items?.items ?? []),
      ],
    }
  }
  return defaultValues
}

const CallParameterItem: React.FC<any> = ({ index, control, remove }) => {
  const { t } = useTranslation()
  const {
    field: { value: shortName, onChange: shortNameOnChange },
  } = useController({
    control,
    name: `items.${index}.short_name`,
  })

  const {
    field: { value: name, onChange: nameOnChange },
  } = useController({
    control,
    name: `items.${index}.name`,
  })

  const {
    field: { onChange: productIdOnChange },
  } = useController({
    control,
    name: `items.${index}.product_id`,
  })

  const {
    field: { value: min, onChange: minOnChange },
  } = useController({
    control,
    name: `items.${index}.min`,
  })

  const {
    field: { value: max, onChange: maxOnChange },
  } = useController({
    control,
    name: `items.${index}.max`,
  })
  return (
    <Center>
      <View style={styles.itemContainer}>
        <ProductSelect
          name={name}
          onSelect={product => {
            nameOnChange(product.name)
            productIdOnChange(product.id)
          }}
        />

        <TextInput
          label={t('settings:callParameterTemplate.acronym')}
          required={true}
          value={shortName}
          onChangeText={value => {
            if (value.length > 4) {
              return
            }
            shortNameOnChange(value)
          }}
        />

        <CallParameterInput
          label={t('settings:callParameterTemplate.referenceRange')}
          value={{
            min,
            max,
          }}
          onChange={({ min, max }) => {
            minOnChange(min)
            maxOnChange(max)
          }}
        />

        <View style={styles.removeButtonContainer}>
          <SecondaryButton
            title={t('general.remove')}
            style={styles.linkButton}
            textStyle={styles.taskBtnTxt}
            onPress={() => remove(index)}
          />
        </View>
      </View>
    </Center>
  )
}

export const CallParameterTemplateForm: React.FC<Props> = ({
  handleSubmit,
  submitting,
  template,
}) => {
  const isNewTemplate = !template
  const { t } = useTranslation()

  const initialValues = getInitialValues(template)

  const {
    getValues,
    control,
    formState: { isValid, errors },
    trigger,
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  // Trigger an on-mount name validation, incase name changed outside form (eg. via duplicate)
  useEffect(() => {
    trigger('name')
  }, [trigger])

  const hasError = !isValid

  const {
    fields: callParameterItems,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'items',
  })

  const addEmptyItem = () => {
    append({
      id: getOptimisticId(),
      product_id: '',
      short_name: '',
      name: '',
      min: null,
      max: null,
    })
  }

  const onSubmit = () => {
    const data = getValues()
    const isCallParamInRange = compareCallParamMinAndMax(data.items)
    if (!isCallParamInRange) return
    handleSubmit(data)
  }

  const renderItem = ({ index }: { index: number }) => {
    return <CallParameterItem index={index} control={control} remove={remove} />
  }

  const renderListHeader = () => (
    <Center>
      <FormBreak />
      <Status status={createErrorStatus(errors.name?.message, true)}>
        <ControlledTextInput
          name="name"
          testID="Template Form Name Text Input"
          label={t('template:form.name')}
          control={control}
          required={true}
        />
      </Status>
      <Controller
        control={control}
        defaultValue={defaultValues.user_id}
        render={({ field: { onChange, value } }) => (
          <OwnerSection
            type={veterinary_roles.VET}
            onSelectedUserChange={onChange}
            selectedUser={value}
          />
        )}
        name="user_id"
      />
      <Controller
        control={control}
        defaultValue={defaultValues.sites}
        render={({ field: { onChange, value } }) => (
          <DepartmentsSection
            onSelectedDepartmentChange={newVal => onChange(newVal)}
            selectedDepartment={value ? value : []}
          />
        )}
        name="sites"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <SwitchInput
            label={t('template:form.active')}
            value={!value}
            onChangeValue={active => {
              onChange(!active)
            }}
          />
        )}
        name="disabled"
      />
    </Center>
  )

  const disableAddProduct = callParameterItems.length >= 12

  const renderListFooter = () => (
    <Center>
      <View style={styles.addButton}>
        <SecondaryButton
          title={t('settings:callParameterTemplate.addProduct')}
          style={styles.linkButton}
          textStyle={[
            styles.productButtonText,
            disableAddProduct && { color: Colors.disabled },
          ]}
          onPress={addEmptyItem}
          disabled={disableAddProduct}
        />
        {disableAddProduct ? (
          <Text style={styles.limitText}>
            (Can't add more than 12 products)
          </Text>
        ) : null}
      </View>
      <Button
        onPress={onSubmit}
        title={
          isNewTemplate
            ? t('settings:callParameterTemplate.create')
            : t('general.saveChanges')
        }
        loading={submitting}
        color={Colors.green}
        style={styles.button}
        disabled={hasError}
      />
    </Center>
  )

  return (
    <FlatList
      keyExtractor={keyExtractor}
      data={callParameterItems}
      renderItem={renderItem}
      ListHeaderComponent={renderListHeader()}
      ListFooterComponent={renderListFooter()}
    />
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    marginVertical: 10,
  },
  addButton: {
    flexDirection: 'row',
  },
  taskBtnTxt: {
    color: Colors.buttons.text,
  },
  productButtonText: {
    color: Colors.buttons.blue,
  },
  button: {
    marginVertical: 16,
  },
  linkButton: {
    paddingRight: 16,
  },
  removeButtonContainer: {
    alignItems: 'flex-end',
  },
  limitText: {
    fontFamily: Fonts.regular,
    color: Colors.disabled,
  },
})
