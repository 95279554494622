import React from 'react'
import { GET_PATIENT_VITALS } from 'components/Vitals/graphql'
import { VitalsArea } from 'components/Vitals/VitalsArea'
import { useQuery } from '@apollo/client'
import {
  getPatientVitals as GetPatientVitals,
  getPatientVitalsVariables as GetPatientVitalsVariables,
} from 'src/types/getPatientVitals'
import { useBreakpoint } from 'src/hocs/breakpoint'

type Props = {
  forceGraphLayout?: boolean
  patientId: string
  productId: string
  productName: string
  unit?: string | null
}

const VITAL_CHART_POLL_INTERVAL = 20 * 1000 // 20 seconds

export const VitalsChart: React.FC<Props> = ({
  forceGraphLayout,
  patientId,
  productId,
  productName,
  unit,
}) => {
  const breakpoint = useBreakpoint()
  const { data, loading } = useQuery<
    GetPatientVitals,
    GetPatientVitalsVariables
  >(GET_PATIENT_VITALS, {
    variables: {
      patientId,
      productId,
    },
    pollInterval: VITAL_CHART_POLL_INTERVAL,
  })

  const hourLimit = breakpoint.isLargeScreen ? 48 : 24
  const vitalsData = data?.getPatientVitals.items ?? []

  return (
    <VitalsArea
      data={vitalsData}
      forceGraphLayout={forceGraphLayout}
      loading={loading}
      hourLimit={hourLimit}
      title={productName}
      titleUnit={unit}
    />
  )
}
