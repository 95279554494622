import { GRID_TITLE_CONTAINER_HEIGHT } from 'components/Grid/GridNavV2'
import { HEADER_HEIGHT } from 'constants/Header'
import Constants from 'expo-constants'
import React from 'react'
import { environment } from 'src/config'
import { useTogglePatientHeaderDrawer } from 'src/context/patientPanelDrawer'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useSheetId } from 'src/hooks/useSheetId'
import { Props, SideDrawer } from './index'
import { VETRADAR_EMAILS } from 'components/Banners/OrganisationBanner'
import { useUser } from 'src/context/user'

export const SheetAwareSideDrawer: React.FC<Props> = ({ ...props }) => {
  const user = useUser()
  const { isSmallishScreen } = useBreakpoint()
  const headerHeight = environment.isWeb
    ? HEADER_HEIGHT
    : Constants.statusBarHeight

  const orgBannerHeight = 37.5

  const organisationBannerHeight = VETRADAR_EMAILS.includes(
    user?.user?.email ?? '',
  )
    ? orgBannerHeight
    : 0

  const topOffset = !isSmallishScreen
    ? GRID_TITLE_CONTAINER_HEIGHT + headerHeight + organisationBannerHeight
    : 0

  useTogglePatientHeaderDrawer(props.visible, false)

  const { currentSheetId } = useSheetId()
  const isOnSheetPage = !!currentSheetId

  if (isOnSheetPage) {
    return (
      <SideDrawer {...props} topOffset={topOffset}>
        {props.children}
      </SideDrawer>
    )
  }
  return <SideDrawer {...props}>{props.children}</SideDrawer>
}
