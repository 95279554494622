import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { FunctionComponent } from 'react'
import { Colors } from 'constants/Colors'

type Props = {
  width?: number
  height?: number
  scale?: number
  color?: string
}

export const SvgPencil: FunctionComponent<Props> = ({
  scale = 1,
  width = 19,
  height = 19,
  color = Colors.contentPrimary,
}) => (
  <Svg
    width={width}
    height={height}
    scale={scale}
    transform={[{ scale }] as any} // HACK: iOS being weird, not respecting scale - types are also broken?
    viewBox="0 0 19 19"
  >
    <Path
      d="M17.71 5.04c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83 3.75 3.75 1.84-1.83ZM0 15.25V19h3.75L14.81 7.93l-3.75-3.75L0 15.25Z"
      fill={color}
    />
  </Svg>
)
