import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { useTranslation } from 'react-i18next'
import { SvgArrow } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { checkIsFluidMedication } from 'components/TreatmentForm/utils/getCalculations'
import { volumeUnitOptions } from 'components/TreatmentForm/data'
import { DosageInfo } from './DosageSetup'
import { ProductType } from 'types/globalTypes'

type Props = {
  selectedDosageInfo: DosageInfo
  onPress: () => void
}

export const ProductDosageField: React.FC<Props> = ({
  selectedDosageInfo,
  onPress,
}) => {
  const { t } = useTranslation()
  const { isExSmallScreen } = useBreakpoint()
  return (
    <>
      <View style={styles.horizontalDivider} />
      <TouchableOpacity
        onPress={onPress}
        style={styles.container}
        accessibilityLabel={t('sheetForm.productDosage')}
      >
        <View
          style={[
            styles.dosageInfoContainer,
            isExSmallScreen && { flexWrap: 'wrap' },
          ]}
        >
          {selectedDosageInfo.productType === ProductType.IVFLUIDS && (
            <>
              {/* TODO: need design: */}
              <Text>
                {`${t('addTreatment:resultsTotal')}: `}{' '}
                <Text style={styles.boldText}>
                  {selectedDosageInfo.totalResults}
                  {'ml/hr'}
                </Text>
              </Text>

              {!!selectedDosageInfo.dripRate && (
                <>
                  <Text style={styles.verticalDivider}>|</Text>
                  <Text>
                    {`${t('addTreatment:dripRate')}: `}{' '}
                    <Text style={styles.boldText}>
                      {selectedDosageInfo.dripRate}
                      {'drops/min'}
                    </Text>
                  </Text>
                </>
              )}
            </>
          )}
          {selectedDosageInfo.productType === ProductType.MEDICATION &&
            (selectedDosageInfo.isCRI ? (
              <>
                <Text>
                  {`${t('addTreatment:infusionRate')}: `}{' '}
                  <Text style={styles.boldText}>
                    {selectedDosageInfo.infusionRateTotal}
                    {selectedDosageInfo.infusionRateVolumeUnit}/
                    {selectedDosageInfo.infusionRateTimeUnit}
                  </Text>
                </Text>

                <Text style={styles.verticalDivider}>|</Text>
                <Text>
                  {`${t('addTreatment:dosage')}: `}{' '}
                  <Text style={styles.boldText}>
                    {selectedDosageInfo.dosageRate}
                    {selectedDosageInfo.dosageWeightUnit}/
                    {selectedDosageInfo.doseRateTimeUnit}
                  </Text>
                </Text>

                <Text style={styles.verticalDivider}>|</Text>
                <Text>{`${t('addTreatment:routeOfAdministration')}: `}</Text>
                <Text style={styles.boldText}>
                  {selectedDosageInfo.routesOfAdmin}
                </Text>
              </>
            ) : (
              <>
                <Text>
                  {`${t('addTreatment:dosage')}: `}{' '}
                  <Text style={styles.boldText}>
                    {selectedDosageInfo.dosageRate}
                    {selectedDosageInfo.dosageWeightUnit}/
                    {selectedDosageInfo.dosagePerWeightUnit}
                  </Text>
                </Text>

                <Text style={styles.verticalDivider}>|</Text>
                <Text>
                  {`${t('addTreatment:totalDosagePerTask')}: `}{' '}
                  <Text style={styles.boldText}>
                    {selectedDosageInfo.totalDosage}
                    {selectedDosageInfo.dosageWeightUnit}
                  </Text>
                </Text>

                <Text style={styles.verticalDivider}>|</Text>
                <Text>
                  {checkIsFluidMedication(
                    selectedDosageInfo.concentrationVolumeUnit,
                  )
                    ? `${t('addTreatment:totalVolume')}: `
                    : `${t('addTreatment:totalAmount')}: `}{' '}
                  <Text style={styles.boldText}>
                    {selectedDosageInfo.totalVolume}
                    {volumeUnitOptions.filter(
                      option =>
                        option.value ===
                        selectedDosageInfo.concentrationVolumeUnit,
                    )?.[0]?.text ?? ''}
                  </Text>
                </Text>

                <Text style={styles.verticalDivider}>|</Text>
                <Text>{`${t('addTreatment:routeOfAdministration')}: `}</Text>
                <Text style={styles.boldText}>
                  {selectedDosageInfo.routesOfAdmin}
                </Text>
              </>
            ))}
        </View>
        <View style={styles.icon}>
          <SvgArrow orient="right" color="grey" width={15} height={15} />
        </View>
      </TouchableOpacity>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    borderLeftColor: Colors.white,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    paddingLeft: 16,
  },
  horizontalDivider: {
    height: 1,
    borderTopWidth: 1,
    borderTopColor: Colors.borderGrey,
    width: '94%',
    alignSelf: 'center',
  },
  dosageInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '95%',
    marginRight: 'auto',
  },
  boldText: {
    fontFamily: Fonts.bold,
  },
  verticalDivider: {
    color: Colors.contentTertiary,
    paddingHorizontal: 4,
  },
  icon: { padding: 16 },
})
