import { treatment_treatment_data } from 'types/treatment'
import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { TreatmentSubscriptionHandler } from 'components/Sheet/treatmentSubscription/handlers/types/treatment-handler.type'

export const useTreatmentDeletionHandler = (): TreatmentSubscriptionHandler => {
  const client = useApolloClient()

  const process = useCallback(
    (treatment: treatment_treatment_data) => {
      const treatmentId = treatment.id

      client.cache.evict({ id: `Treatment:${treatmentId}` })
    },
    [client],
  )

  return {
    process,
  }
}
