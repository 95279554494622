import React, { useCallback } from 'react'
import {
  OnPressTask,
  OnPressTreatmentGroup,
} from 'components/Grid/GridFlatList'
import { ScaleTimeFn } from 'components/Grid/GridTimeLine/GridTimeline'
import {
  getIsFluidStyleTaskDisplay,
  getIsTreatmentFullHeight,
} from 'components/Task/utils/getTreatmentInfo'
import { Colors } from 'constants/Colors'
import { isWithinInterval, parseISO } from 'date-fns'
import {
  GestureResponderEvent,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { SvgDiscontinuedBg } from 'src/components/Icons'
import { useTimeResolution } from 'src/hocs/timeContext'
import { Status, TreatmentTreeNodeType } from 'src/types/globalTypes'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items } from 'types/getSheet'
import { treatmentHeight, treatmentWidthLarge } from '../common'
import { getRowA11yLabel } from './common'
import {
  TreatmentChildLevel,
  TreatmentSheet,
  TreatmentSheetFirstLevel,
} from './common/types'
import { FluidGridTreatmentLeaf } from './FluidGridTreatmentLeaf'
import { GridFullRow } from './GridFullRow'
import { GridTreatmentLeaf } from './GridTreatmentLeaf'
import { GridTreatmentNode } from './GridTreatmentNode'
import { isTreatmentPending } from 'src/hooks/useApprovals'

type Task = getSheet_getSheet_treatments_items_treatments_items_tasks_items

// Treatment data from backend
export type TreatmentQuery = {
  name: string
  id: string
  created_at: string
}

// Treatment data from backend
export type NestedTreatment = TreatmentQuery & {
  tasks: {
    items: Task[]
  }
  treatments?: {
    items: NestedTreatment[]
  }
}

export type TreatmentProps = {
  hasNestedTreatmentGroups?: boolean
  id: string
  isFinalized: boolean
  isFolded?: boolean
  name: string
  nestingDepth?: number
  onPressTask: OnPressTask
  onPressTreatmentGroup?: OnPressTreatmentGroup
  positionInGridFn: ScaleTimeFn
  tasks: Task[]
  timeInGridFn: (x: number, t: TreatmentChildLevel) => void
  treatment: TreatmentChildLevel
}

// TODO: move type guard to more useful place
export function isFirstLevelTreatment( // aka isTreatmentGroup
  treatment: TreatmentSheet,
): treatment is TreatmentSheetFirstLevel {
  return 'treatments' in treatment
}

export const GridTreatment: React.FC<TreatmentProps> = React.memo(
  ({
    hasNestedTreatmentGroups,
    isFinalized,
    nestingDepth,
    onPressTask,
    onPressTreatmentGroup,
    positionInGridFn,
    tasks,
    timeInGridFn,
    treatment,
  }) => {
    const { visibleDayRange } = useTimeResolution()

    const a11yLabel = getRowA11yLabel('Grid', treatment.name, nestingDepth)
    const isFluidStyleTaskDisplay = getIsFluidStyleTaskDisplay(treatment)
    const isTreatmentGroup = treatment.node_type === TreatmentTreeNodeType.GROUP

    let groupStyle: ViewStyle = styles.empty
    if (isTreatmentGroup) {
      groupStyle = styles.rowWithChildren
    }

    if (isTreatmentPending(treatment)) {
      groupStyle = styles.rowPendingApproval
    }

    const getTouchCoordinates = useCallback(
      (event: any) => {
        if (treatment.conditional) {
          return
        }
        // evt.nativeEvent.locationX get undefined in expo sdk39 web
        let locationX: number
        if (Platform.OS === 'web') {
          locationX = (event.nativeEvent as MouseEvent).offsetX
        } else {
          locationX = (event as GestureResponderEvent).nativeEvent.locationX
        }
        if (locationX) {
          timeInGridFn(locationX, treatment)
        }
      },
      [timeInGridFn, treatment],
    )

    const isDiscontinued = !!treatment.discontinued_at
    const isReadOnly = isDiscontinued || isFinalized
    const isDiscontinuedinThisDateRange = treatment?.discontinued_at
      ? isWithinInterval(parseISO(treatment.discontinued_at), {
          start: visibleDayRange[0],
          end: visibleDayRange[1],
        })
      : false

    const discontinuedStartPosition =
      !isFinalized && isDiscontinuedinThisDateRange
        ? positionInGridFn(parseISO(treatment.discontinued_at!))
        : 0

    const onTreatmentRowPress = useCallback(
      (timeOfPress: Date) => {
        if (onPressTreatmentGroup && isFirstLevelTreatment(treatment)) {
          onPressTreatmentGroup(treatment, timeOfPress)
        }
      },
      [onPressTreatmentGroup, treatment],
    )

    return (
      /* Runs full width */
      <GridFullRowWrapper
        a11yLabel={a11yLabel}
        discontinuedStartPosition={discontinuedStartPosition}
        getTouchCoordinates={getTouchCoordinates}
        groupStyle={groupStyle}
        hasNestedTreatmentGroups={hasNestedTreatmentGroups}
        isFinalized={isFinalized}
        isFluidStyleTaskDisplay={isFluidStyleTaskDisplay}
        isReadOnly={isReadOnly}
        isTouchable={!!(isTreatmentGroup && !isReadOnly)}
        isTreatmentGroup={isTreatmentGroup}
        onPress={onTreatmentRowPress}
        onPressTask={onPressTask}
        positionInGridFn={positionInGridFn}
        tasks={tasks}
        treatment={treatment}
      />
    )
  },
)

GridTreatment.displayName = 'GridTreatment'

type GridFullRowWrapperProps = Omit<
  TreatmentProps,
  | 'id'
  | 'isFolded'
  | 'name'
  | 'nestingDepth'
  | 'onPressTreatmentGroup'
  | 'timeInGridFn'
> & {
  a11yLabel: string
  discontinuedStartPosition?: number
  getTouchCoordinates: (event: any) => void
  groupStyle: ViewStyle
  isFluidStyleTaskDisplay: boolean
  isReadOnly: boolean
  isTouchable?: boolean
  isTreatmentGroup?: boolean
  onPress: (timeOfPress: Date) => void
}

const GridFullRowWrapper: React.FC<GridFullRowWrapperProps> = React.memo(
  ({
    a11yLabel,
    discontinuedStartPosition,
    getTouchCoordinates,
    groupStyle,
    isFluidStyleTaskDisplay,
    isFinalized,
    isReadOnly,
    isTouchable,
    isTreatmentGroup,
    onPress,
    onPressTask,
    positionInGridFn,
    tasks,
    treatment,
  }) => {
    const isTreatmentFullHeight = getIsTreatmentFullHeight(treatment)

    return (
      <GridFullRow
        a11yLabel={a11yLabel}
        groupStyle={groupStyle}
        isTouchable={isTouchable}
        isTreatmentFullHeight={isTreatmentFullHeight}
        isTreatmentGroup={isTreatmentGroup}
        onPress={onPress}
        positionInGridFn={positionInGridFn}
      >
        {isTreatmentGroup ? (
          <GridTreatmentNode />
        ) : (
          <PressableGridRow
            discontinuedStartPosition={discontinuedStartPosition}
            isFluidStyleTaskDisplay={isFluidStyleTaskDisplay}
            isReadOnly={isReadOnly}
            isTreatmentFullHeight={isTreatmentFullHeight}
            onPress={getTouchCoordinates}
            onPressTask={onPressTask}
            tasks={tasks}
            treatment={treatment}
          >
            {isFluidStyleTaskDisplay ? (
              <FluidGridTreatmentLeaf
                isFinalized={isFinalized}
                onPressTask={onPressTask}
                positionInGridFn={positionInGridFn}
                tasks={tasks}
                treatment={treatment}
              />
            ) : (
              <GridTreatmentLeaf
                isFinalized={isFinalized}
                isTreatmentFullHeight={isTreatmentFullHeight}
                onPressTask={onPressTask}
                positionInGridFn={positionInGridFn}
                tasks={tasks}
                treatment={treatment}
              />
            )}
          </PressableGridRow>
        )}
      </GridFullRow>
    )
  },
)

GridFullRowWrapper.displayName = 'GridFullRowWrapper'

type PressableBackGroundProps = {
  children: React.ReactElement
  discontinuedStartPosition?: number
  isFluidStyleTaskDisplay: boolean
  isReadOnly: boolean
  isTreatmentFullHeight?: boolean
  onPress: (event: GestureResponderEvent) => void
  onPressTask: OnPressTask
  tasks: Task[]
  treatment: TreatmentChildLevel
}

const PressableGridRow: React.FC<PressableBackGroundProps> = React.memo(
  ({
    children,
    discontinuedStartPosition,
    isFluidStyleTaskDisplay,
    isReadOnly,
    isTreatmentFullHeight,
    onPress,
    onPressTask,
    tasks,
    treatment,
  }) => {
    const testID = `Grid treatment row ${treatment.name}`

    const onPressRow = useCallback(
      (event: GestureResponderEvent) => {
        const NOT_DONE_LIST = [
          Status.IN_PROGRESS,
          Status.MISSED,
          Status.PENDING,
          Status.DUE,
        ]
        if (isFluidStyleTaskDisplay) {
          // check if there are in progress task
          const inProgressTasks = tasks.filter(
            (i: Task) => i.status && NOT_DONE_LIST.includes(i.status),
          )
          const isInProgress = inProgressTasks.length > 0
          // if there is/are task/tasks in progress, we want to just show the edit drawer
          if (isInProgress) {
            return onPressTask(treatment, inProgressTasks[0])
          }
        }
        // otherwise, show create drawer
        return onPress(event)
      },
      [isFluidStyleTaskDisplay, onPress, onPressTask, tasks, treatment],
    )

    return (
      <TouchableOpacity
        activeOpacity={1}
        disabled={isReadOnly}
        onPress={onPressRow}
        style={[
          styles.touchableRow,
          !isTreatmentFullHeight && styles.smallHeight,
        ]}
        testID={testID}
      >
        {isReadOnly ? (
          <ReadOnlyGridTreatment
            discontinuedStartPosition={discontinuedStartPosition}
            isTreatmentFullHeight={isTreatmentFullHeight}
          />
        ) : null}
        {children}
      </TouchableOpacity>
    )
  },
)

PressableGridRow.displayName = 'PressableBackGround'

type ReadOnlyGridTreatmentProps = {
  discontinuedStartPosition?: number
  isTreatmentFullHeight?: boolean
}

const ReadOnlyGridTreatment: React.FC<ReadOnlyGridTreatmentProps> = ({
  discontinuedStartPosition,
  isTreatmentFullHeight,
}) => (
  <View
    style={[
      styles.textureBgContainer,
      !isTreatmentFullHeight && styles.smallHeight,
      {
        left: discontinuedStartPosition,
      },
    ]}
  >
    <SvgDiscontinuedBg opacity={0.1} />
  </View>
)

const styles = StyleSheet.create({
  empty: {},
  rowWithChildren: {
    backgroundColor: Colors.treatment.headerTimelineBackground,
    opacity: 0.4,
  },
  rowPendingApproval: {
    backgroundColor: Colors.approval.background,
  },
  textureBgContainer: {
    flexDirection: 'row',
    position: 'absolute',
    width: '100%',
    height: treatmentWidthLarge,
  },
  smallHeight: {
    height: treatmentHeight,
  },
  touchableRow: {
    height: '100%',
    width: '100%',
  },
})
