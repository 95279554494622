import i18n, { LanguageDetectorAsyncModule } from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as Localization from 'expo-localization'
import noop from 'lodash/noop'
import { format as formatDate } from 'date-fns'
import { SyncStorage } from 'src/utils/SyncStorage'

// TODO: Add the translations after init. This doesnt seem possible on
// react-native at today, 20/9/2019 , addResourceBuncle was undefined
// This way we can combine static bundled resources with ajax resources.
// Learn more: https://www.i18next.com/how-to/add-or-load-translations#add-after-init
// i18n.addResourceBundle('en', 'namespace1', {
//   key: 'hello from namespace 1'
// });
const resources = {
  'en-AU': {
    translation: require('./components/common/translation/en-AU/translation.json'),
  },
  'en-GB': {
    translation: require('./components/common/translation/en-GB/translation.json'),
    settings: require('./components/Settings/translation/settings.en-GB.json'),
    task: require('./components/Task/translation/task.en-GB.json'),
    procedure: require('./components/Procedures/translation/procedure.en-GB.json'),
  },
  'en-US': {
    account: require('./components/Account/translation/account.en-US.json'),
    addTreatment: require('./components/AddTreatment/translation/addTreatment.en-US.json'),
    bulkTask: require('./components/BulkTask/translation/bulkTask.en-US.json'),
    discharge: require('./components/DischargePatient/translation/discharge.en-US.json'),
    ivInfusionForm: require('./components/IVInfusionForm/translation/progressButton.en-US.json'),
    login: require('./components/common/Login/translation/login.en-US.json'),
    patient: require('./components/Patient/translation/patient.en-US.json'),
    patientPanel: require('./components/PatientPanel/translation/patientPanel.en-US.json'),
    permission: require('./components/common/Permission/translation/permission.en-US.json'),
    settings: require('./components/Settings/translation/settings.en-US.json'),
    sheet: require('./components/Sheet/translation/sheet.en-US.json'),
    sheetHeader: require('./components/SheetHeader/translation/header.en-US.json'),
    sheetList: require('./components/SheetList/translation/sheetList.en-US.json'),
    task: require('./components/Task/translation/task.en-US.json'),
    template: require('./components/Template/translation/template.en-US.json'),
    translation: require('./components/common/translation/en-US/translation.json'),
    treatment: require('./components/Treatment/translation/treatment.en-US.json'),
    vitals: require('./components/Vitals/translation/vitals.en-US.json'),
    whiteboard: require('./components/Whiteboard/translation/whiteboard.en-US.json'),
    help: require('./components/Help/translation/help.en-US.json'),
    history: require('components/UserHistory/translation/history.en-US.json'),
    training: require('./components/Training/translation/training.en-US.json'),
    procedure: require('./components/Procedures/translation/procedure.en-US.json'),
  },
  cn: {
    account: require('./components/Account/translation/account.cn.json'),
    addTreatment: require('./components/AddTreatment/translation/addTreatment.cn.json'),
    bulkTask: require('./components/BulkTask/translation/bulkTask.cn.json'),
    discharge: require('./components/DischargePatient/translation/discharge.cn.json'),
    ivInfusionForm: require('./components/IVInfusionForm/translation/progressButton.cn.json'),
    login: require('./components/common/Login/translation/login.cn.json'),
    patient: require('./components/Patient/translation/patient.cn.json'),
    patientPanel: require('./components/PatientPanel/translation/patientPanel.cn.json'),
    permission: require('./components/common/Permission/translation/permission.cn.json'),
    settings: require('./components/Settings/translation/settings.cn.json'),
    sheet: require('./components/Sheet/translation/sheet.cn.json'),
    sheetHeader: require('./components/SheetHeader/translation/header.cn.json'),
    sheetList: require('./components/SheetList/translation/sheetList.cn.json'),
    task: require('./components/Task/translation/task.cn.json'),
    template: require('./components/Template/translation/template.cn.json'),
    translation: require('./components/common/translation/cn/translation.json'),
    treatment: require('./components/Treatment/translation/treatment.cn.json'),
    vitals: require('./components/Vitals/translation/vitals.cn.json'),
    whiteboard: require('./components/Whiteboard/translation/whiteboard.cn.json'),
    help: require('./components/Help/translation/help.cn.json'),
    history: require('components/UserHistory/translation/history.cn.json'),
    procedure: require('./components/Procedures/translation/procedure.cn.json'),
  },
}

export const languageStorage = {
  get: () => SyncStorage.getItem('locale'),
  set: (locale: string) => SyncStorage.setItem('locale', locale),
}

const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  detect: async (cb: any) => {
    // Need to run sync before using SyncStorage.
    // This only need be done once in the App component, but languageDetector runs before App get rendered.
    await SyncStorage.sync()
    let locale = languageStorage.get()
    if (!locale) {
      const { locale: nativeLocal } = await Localization.getLocalizationAsync()
      locale = nativeLocal
    }
    return cb(locale)
  },
  init: noop,
  cacheUserLanguage: noop,
}

i18n
  // Detect user language
  .use(languageDetector)
  // Pass the i18n instance to the react-i18next components.
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en-US',
    debug: false, // Set to environment.isDev to debug translations locally
    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
      format: (value, format) => {
        if (value instanceof Date && !!format) {
          return formatDate(value, format)
        }
        return value
      },
    },
  })

export const i18nInstance = i18n
