import React, { useCallback, useMemo, useRef } from 'react'
import {
  getShouldShowTaskTreatmentHeader,
  TaskDialogHeader,
} from 'components/Task/TaskHeader/TaskDialogHeader'
import {
  TaskTransitionRef,
  TaskTransition,
} from 'components/Task/TaskTransition'
import {
  FluidTaskTransition,
  FluidTaskTransitionRef,
} from 'components/Task/FluidTaskTransition'
import { useCreateTask } from 'components/Task/utils/useCreateTask'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { addMinutes, format, subHours } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { useOrganisation } from 'src/context/organisation'
import { TimeSegment, TimeSegmentMinutes } from 'src/hocs/types/time'
import { CreateTaskInput, Status, TreatmentTaskType } from 'types/globalTypes'

import { getTimeInGridToNearestSegment } from './utils/getTimeInGridToNearestSegment'
import { getIsFluidStyleTaskDisplay } from '../../Task/utils/getTreatmentInfo'
import { FluidAction, Task } from 'components/Task/types'
import { getFluidOrCRITreatmentValue } from 'components/EditTreatment/utils/updateFluidTreatmentUtils'
import { maxBy } from 'lodash'
import { getOptimisticId } from 'src/utils/optimisticId'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { RequireApprovalBanner } from 'components/Banners/RequireApprovalBanner'
import { CubexTaskBanner } from 'components/Banners/CubexTaskBanner'

type Props = {
  sheetId: string
  patientId: string
  treatment: TreatmentChildLevel | null
  timeInGrid: Date
  toggleDialog: () => void
  timeSegment: TimeSegment
  dateFormatPattern?: string
}

export const TaskCreate: React.FC<Props> = ({
  sheetId,
  patientId,
  timeInGrid,
  timeSegment,
  toggleDialog,
  treatment,
  dateFormatPattern = 'p PP',
}) => {
  const restartFluidRef = useRef<FluidTaskTransitionRef | null>(null)
  const taskTransitionRef = useRef<TaskTransitionRef | null>(null)
  const [{ organisationId }] = useOrganisation()
  const { isPortrait, isLargeScreen } = useBreakpoint()

  const { t } = useTranslation()
  const createTask = useCreateTask()

  const extraLandscapeIpad = 240
  const extraPortraitIos = 250
  const extraLandscapeIphone = 50

  const timeInGridToNearestSegment = getTimeInGridToNearestSegment(
    timeSegment,
    timeInGrid,
  )

  const formattedTimeInGrid = timeInGridToNearestSegment
    ? format(timeInGridToNearestSegment, dateFormatPattern)
    : null

  const isFluidStyleTaskDisplay = getIsFluidStyleTaskDisplay(treatment)

  const createNewTask = (formData?: CreateTaskInput) => {
    if (isFluidStyleTaskDisplay) {
      toggleDialog()
      // use restartFluid from FluidTaskTransition
      return restartFluidRef.current?.restartFluid()
    }
    const startAt = getTimeInGridToNearestSegment(timeSegment, timeInGrid)
    const newWindow = TimeSegmentMinutes[timeSegment]
    const stopAt = addMinutes(startAt, newWindow)
    const newSchedule = {
      start_at: startAt.toISOString(),
      stop_at: stopAt.toISOString(),
    }
    const taskTransitionRefData = !!taskTransitionRef.current
      ? {
          notes: taskTransitionRef.current.getTaskNotes(),
          highlight_colour: taskTransitionRef.current.getTaskHighlightColor(),
          qty_billed: taskTransitionRef.current.getTaskQtyBilled(),
        }
      : {}
    const newTaskInput = {
      ...formData,
      organisation_id: organisationId,
      sheet_id: sheetId,
      patient_id: patientId,
      treatment_id: treatment!.id,
      start_at: startAt.toISOString(),
      stop_at: stopAt.toISOString(),
      type: TreatmentTaskType.NORMAL,
      status: formData?.status ?? Status.PENDING, // Complete a task if there are any formData
      ...taskTransitionRefData,
    }

    toggleDialog()
    return createTask(newTaskInput, newSchedule)
  }

  const shouldShowDialogHeader = getShouldShowTaskTreatmentHeader(treatment)

  const calculateExtraHeight = useMemo(() => {
    if (!isPortrait && isLargeScreen) {
      return extraLandscapeIpad
    }
    if (isPortrait) {
      return extraPortraitIos
    }
    return extraLandscapeIphone
  }, [isPortrait, isLargeScreen])

  const getLatestDoneTaskForRestart = useCallback(() => {
    const lastTaskDone = maxBy(treatment?.tasks?.items, 'given_stop_at')
    if (lastTaskDone) {
      // when restart task, clear the notes
      return { ...lastTaskDone, notes: '' }
    }

    // When restarting fluid, there must be a done status task, this won't happen, but for type fix and future extension
    const now = new Date()
    const oneHourBeforeISO = subHours(now, 1).toISOString()
    const nowISO = now.toISOString()
    const defaultTask = {
      id: getOptimisticId(),
      status: Status.DONE,
      value: getFluidOrCRITreatmentValue(treatment),
      given_start_at: oneHourBeforeISO,
      given_stop_at: nowISO,
      start_at: oneHourBeforeISO,
      stop_at: nowISO,
      organisation_id: organisationId,
      sheet_id: sheetId,
      patient_id: patientId,
      treatment_id: treatment!.id,
    } as Task

    return defaultTask
  }, [treatment, organisationId, patientId, sheetId])

  return (
    <>
      <CubexTaskBanner treatment={treatment} />
      <RequireApprovalBanner treatment={treatment} />
      <KeyboardAwareScrollView
        extraHeight={calculateExtraHeight}
        contentContainerStyle={[styles.container]}
        accessibilityLabel={t('task:taskTransition:a11y')}
      >
        <TaskDialogHeader
          patientId={patientId}
          showHeader={shouldShowDialogHeader}
          treatment={treatment}
        >
          <View style={styles.actionContainer}>
            <TouchableOpacity
              testID={'+Create'}
              onPress={() => createNewTask()}
            >
              <View style={styles.addActionButton}>
                <Text style={styles.addButtonText}>
                  + {t('task:taskAddEdit:addButton')}
                </Text>
                {!isFluidStyleTaskDisplay && (
                  <Text style={styles.addButtonSubtext}>
                    {formattedTimeInGrid}
                  </Text>
                )}
              </View>
            </TouchableOpacity>
          </View>
        </TaskDialogHeader>
        {isFluidStyleTaskDisplay ? (
          <FluidTaskTransition
            ref={restartFluidRef}
            task={getLatestDoneTaskForRestart()}
            sheetId={sheetId}
            action={FluidAction.CREATE_FLUID}
            patientId={patientId}
            toggleDialog={toggleDialog}
            treatment={treatment}
            timeInGrid={timeInGrid}
          />
        ) : (
          <TaskTransition
            ref={taskTransitionRef}
            patientId={patientId}
            treatment={treatment}
            toggleDialog={toggleDialog}
            createAndCompleteTask={createNewTask}
            sheetId={sheetId}
          />
        )}
      </KeyboardAwareScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    width: '100%',
  },
  actionContainer: {
    flex: 1,
    padding: 8,
    paddingHorizontal: 6,
    alignItems: 'center',
  },
  addActionButton: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingBottom: 8,
  },

  addButtonText: {
    color: Colors.blue,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  addButtonSubtext: {
    color: Colors.contentTertiary,
    fontSize: 14,
    paddingLeft: 12,
    textAlign: 'center',
  },
})
