import React from 'react'
import ReactSelect, {
  createFilter,
  mergeStyles,
  type CSSObjectWithLabel,
  type GroupBase,
  type MultiValue,
  type OptionsOrGroups,
  type Props as LibraryProps,
  type SingleValue,
  type StylesConfig,
  type ActionMeta,
} from 'react-select'
import { Colors, Typography } from 'src/design-system/theme'
import { Shadows } from 'constants/Shadows'
import { ClearIndicator } from './components/ClearIndicator.web'
import { Control } from './components/Control.web'
import { DropdownIndicator } from './components/DropdownIndicator.web'
import { LoadingIndicator } from './components/LoadingIndicator'
import { PossibleSelectValues, SelectOption, SelectProps } from '..'

// Overwrite the Library Types if they are explicitly defined in our interface.
export type BaseSelectProps = Omit<
  LibraryProps<SelectOption, boolean, GroupBase<SelectOption>>,
  keyof SelectProps
> & {
  onChange: (value: any, action?: ActionMeta<SelectOption>) => void
} & SelectProps

/* Helper Functions for single/Multi Selection */
function findOptionsByValue(
  options: OptionsOrGroups<SelectOption, GroupBase<SelectOption>>,
  values: PossibleSelectValues | PossibleSelectValues[],
): MultiValue<SelectOption> | SingleValue<SelectOption> {
  // @ts-ignore - Type confusion from typescript
  const flattenedOptions = options.flatMap(group => group.options || group)
  if (Array.isArray(values) && flattenedOptions.length) {
    return values.map(value =>
      flattenedOptions.find(option => option.value === value),
    )
  }
  return flattenedOptions.find(option => option.value === values)
}

const getValuesFromOptions = (
  options: MultiValue<SelectOption> | SingleValue<SelectOption>,
) => {
  if (Array.isArray(options)) {
    return options.map(v => v.value)
  }
  return (options as SingleValue<SelectOption>)?.value ?? null
}

export const SelectBase: React.FC<BaseSelectProps> = props => {
  const selected = props.options
    ? findOptionsByValue(props.options, props.selected)
    : null

  return (
    <ReactSelect
      {...props}
      components={{
        Control,
        DropdownIndicator,
        LoadingIndicator,
        ClearIndicator,
        ...props.components,
      }}
      onChange={(values, action) =>
        props?.onChange(
          getValuesFromOptions(values) as PossibleSelectValues,
          action,
        )
      }
      // Map our prop names to library prop names
      value={selected}
      isDisabled={props.disabled}
      placeholder={props.placeholder || ''}
      isLoading={props.loading}
      isClearable={props.allowClear}
      // Modify default library behavior to match ours
      getOptionLabel={option => option.text || String(option.value)}
      filterOption={createFilter({ stringify: option => `${option.label}` })}
      closeMenuOnSelect={props.isMulti ? false : true}
      hideSelectedOptions={false}
      // Styling
      styles={mergeStyles(baseStyles, props.styles)}
      // Always show on top
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
    />
  )
}

/* Styling */
const baseStyles: StylesConfig<SelectOption, boolean> = {
  container: base => ({
    ...base,
    backgroundColor: Colors.Backgrounds.UI,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: Colors.Borders.primary,
    borderStyle: 'solid',
    flexDirection: 'row',
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999, // Needed to render over our Modals
  }),
  control: base => ({
    ...base,
    color: Colors.Contents.primary,
    cursor: 'pointer',
    minHeight: 24,
    borderWidth: 0,
    backgroundColor: Colors.Backgrounds.UI,
    boxShadow: 'none',
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
  }),
  valueContainer: base => ({
    ...base,
    paddingLeft: 4,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  input: base => ({
    ...base,
    fontFamily: Typography.FontFamilies.base,
    color: Colors.Contents.primary,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
  }),
  singleValue: (base, state) => ({
    ...base,
    fontFamily: Typography.FontFamilies.base,
    margin: 0,
    color: state.isDisabled
      ? Colors.Contents.accessory
      : Colors.Contents.primary,
  }),
  option: (base, state) => ({
    ...base,
    ...(Typography.Label.M as CSSObjectWithLabel),
    color: Colors.Contents.primary,
    fontFamily: state.isSelected
      ? Typography.FontFamilies.bold
      : Typography.FontFamilies.base,
    backgroundColor: state.isDisabled
      ? undefined
      : state.isSelected
      ? Colors.Interactive.clickedOverlay
      : state.isFocused
      ? Colors.Interactive.hoverOverlayPrimary
      : undefined,
    ':active': {
      backgroundColor: Colors.Interactive.clickedHighlight,
    },
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  group: base => ({
    ...base,
    borderWidth: 0,
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    marginTop: -1, // This collapses the borders between groups
    borderColor: Colors.Borders.primary,
    marginBottom: 0,
    padding: 0,
  }),
  groupHeading: base => ({
    ...base,
    ...(Typography.Label.M as CSSObjectWithLabel),
    color: Colors.Contents.tertiary,
    textTransform: 'none',
  }),
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  menu: base => ({
    ...base,
    width: '84%',
    marginTop: -4,
    marginLeft: 10,
    borderRadius: 4,
    shadow: Shadows.cardShadow,
    overflow: 'hidden',
  }),
  clearIndicator: base => ({
    ...base,
    padding: 0,
    color: Colors.Contents.accent,
    '&:hover': {
      backgroundColor: 'transparent',
      color: Colors.Interactive.hoverAccent,
    },
    ':active': {
      backgroundColor: Colors.Interactive.clickedHighlight,
    },
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 0,
  }),
  indicatorsContainer: base => ({
    ...base,
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: 81, // Sum of indicators' widths
    padding: 8,
    gap: 8,
  }),
}
