import React from 'react'
import { useQuery } from '@apollo/client'
import { RepeatScheduleValue } from 'components/TreatmentForm/data'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator } from 'react-native'
import { FormData, TreatmentForm } from 'src/components/TreatmentForm'
import { useOrganisation } from 'src/context/organisation'
import { useSheetContext } from 'src/context/sheet'
import { usePatientById } from 'src/hooks/usePatient'
import { useSetShowTreatmentTabs } from 'src/hooks/useSetShowTreatmentTabs'
import {
  getOneProduct as GetOneProduct,
  getOneProductVariables as GetOneProductVariables,
} from 'src/types/getOneProduct'
import { useCreateTreatment } from 'src/utils/useCreateTreatment'

import { GET_PRODUCT_DETAILS } from './graphql'
import { Product } from './ProductRow'

type Props = {
  sheetId: string
  patientId: string
  product: Product
  onDone: () => void
}

export const AddTreatmentScreen: React.FC<Props> = ({
  sheetId,
  patientId,
  product,
  onDone,
}) => {
  const [{ organisationId, hasPIMSIntegration }] = useOrganisation()
  const [{ lastTaskStartTime }] = useSheetContext()
  const { t } = useTranslation()

  const patient = usePatientById(patientId)

  const { data, loading } = useQuery<GetOneProduct, GetOneProductVariables>(
    GET_PRODUCT_DETAILS,
    {
      variables: {
        id: product.id,
        organisation_id: organisationId,
      },
    },
  )
  const productDetail = data?.getProduct

  const defaultOverrides = {
    ...(lastTaskStartTime && { startAtDate: lastTaskStartTime }),
  }
  useSetShowTreatmentTabs()
  const createTreatment = useCreateTreatment(sheetId, patientId)
  const onSave = async (formData: FormData) => {
    const productInfo = {
      productId: product.id,
      productName: product.name,
      productType: product.type,
      productGroupId: product.group.id,
      productGroupName: product.group.name,
      productGroupOrder: product.group.order,
    }

    const shouldCreateTasks = formData.isRepeating !== RepeatScheduleValue.NONE

    await createTreatment(formData, productInfo, {
      createTasks: shouldCreateTasks,
    })
    onDone()
  }

  if (loading) {
    return <ActivityIndicator size="large" />
  }

  if (!productDetail || !patient) {
    return null
  }

  return (
    <TreatmentForm
      defaultOverrides={defaultOverrides}
      patient={patient}
      product={productDetail}
      onSave={onSave}
      submitTitle={t('addTreatment:addToSheet')}
      hasPIMSIntegration={!!hasPIMSIntegration}
    />
  )
}
