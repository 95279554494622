import React from 'react'

import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form'
import { SingleSelect, PossibleSelectValues, SelectProps } from '.'

type ControlledProps<
  T extends PossibleSelectValues,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> &
  Omit<SelectProps, 'selected' | 'onChange'> & {
    onChangeListener?: (value: T) => void
  }

export const ControlledSingleSelect = <
  T extends PossibleSelectValues,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  onChangeListener,
  defaultValue,
  ...props
}: ControlledProps<T, TFieldValues, TName>) => {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
    ...(defaultValue !== undefined && { defaultValue }),
  })

  return (
    <SingleSelect
      {...props}
      selected={value as T}
      onChange={newValue => {
        onChange(newValue)
        onChangeListener?.(newValue)
      }}
    />
  )
}
