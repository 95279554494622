import { useOrgSettings } from 'src/hooks/useOrgSettings'
import { useUser } from 'src/context/user'

export const useDeviceTimeout = () => {
  const { settingsMap } = useOrgSettings()

  const { user } = useUser()

  if (
    user?.auto_logout_timeout === null ||
    user?.auto_logout_timeout === undefined
  ) {
    return Number(settingsMap?.AUTO_LOGOUT_TIMEOUT?.value ?? 0)
  }

  return user.auto_logout_timeout
}
