import { Colors } from 'constants/Colors'
import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const SvgExpandArrows = ({
  width = 21,
  height = 21,
  color = Colors.blue,
}: Props) => (
  <Svg width={width} height={height} viewBox="0 0 21 21" fill={color}>
    <Path
      d="M20.1673 2.04183C20.1673 1.72136 20.04 1.41402 19.8134 1.18741C19.5868 0.960803 19.2795 0.833496 18.959 0.833496H12.9173C12.5968 0.833496 12.2895 0.960803 12.0629 1.18741C11.8363 1.41402 11.709 1.72136 11.709 2.04183C11.709 2.3623 11.8363 2.66964 12.0629 2.89625C12.2895 3.12286 12.5968 3.25016 12.9173 3.25016H16.0227L12.0594 7.22558C11.9461 7.33791 11.8563 7.47155 11.7949 7.6188C11.7336 7.76605 11.702 7.92398 11.702 8.0835C11.702 8.24301 11.7336 8.40095 11.7949 8.54819C11.8563 8.69544 11.9461 8.82908 12.0594 8.94141C12.1717 9.05467 12.3054 9.14456 12.4526 9.20591C12.5999 9.26725 12.7578 9.29883 12.9173 9.29883C13.0768 9.29883 13.2348 9.26725 13.382 9.20591C13.5293 9.14456 13.6629 9.05467 13.7752 8.94141L17.7507 4.966V8.0835C17.7507 8.40397 17.878 8.71131 18.1046 8.93792C18.3312 9.16452 18.6385 9.29183 18.959 9.29183C19.2795 9.29183 19.5868 9.16452 19.8134 8.93792C20.04 8.71131 20.1673 8.40397 20.1673 8.0835V2.04183ZM8.9419 12.0589C8.82957 11.9457 8.69593 11.8558 8.54868 11.7944C8.40143 11.7331 8.2435 11.7015 8.08398 11.7015C7.92447 11.7015 7.76653 11.7331 7.61929 11.7944C7.47204 11.8558 7.3384 11.9457 7.22607 12.0589L3.25065 16.0222V12.9168C3.25065 12.5964 3.12334 12.289 2.89674 12.0624C2.67013 11.8358 2.36279 11.7085 2.04232 11.7085C1.72185 11.7085 1.4145 11.8358 1.1879 12.0624C0.961291 12.289 0.833984 12.5964 0.833984 12.9168V18.9585C0.833984 19.279 0.961291 19.5863 1.1879 19.8129C1.4145 20.0395 1.72185 20.1668 2.04232 20.1668H8.08398C8.40445 20.1668 8.7118 20.0395 8.9384 19.8129C9.16501 19.5863 9.29232 19.279 9.29232 18.9585C9.29232 18.638 9.16501 18.3307 8.9384 18.1041C8.7118 17.8775 8.40445 17.7502 8.08398 17.7502H4.96648L8.9419 13.7747C9.05516 13.6624 9.14505 13.5288 9.20639 13.3815C9.26774 13.2343 9.29932 13.0763 9.29932 12.9168C9.29932 12.7573 9.26774 12.5994 9.20639 12.4521C9.14505 12.3049 9.05516 12.1712 8.9419 12.0589Z"
      fill="white"
    />
  </Svg>
)
