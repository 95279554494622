import React, { useCallback } from 'react'
import {
  FrequencyInput,
  FrequencySelector,
} from 'components/FrequencySelector/FrequencySelector'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { TemplateTreatmentFormInput } from './templateUtilFns'
import { noop } from 'lodash'
import { messageBoxColors } from 'components/common/Avatar/AvatarWithMessage'
import { SvgArrow } from 'components/Icons'
import { Pill } from 'components/common/Pill'
import { TickForCubexBilling } from 'components/Icons/TickForCubexBilling'
import { Colors, Typography } from 'src/design-system/theme'
import { Checkbox } from 'src/design-system/components/CheckBox/Checkbox'

type Props = {
  treatment: TemplateTreatmentFormInput
  onChange: (templateTreatmentFormInputs: TemplateTreatmentFormInput[]) => void
  templateTreatmentFormInputs: TemplateTreatmentFormInput[]
  index: number
  onSetup: (templateTreatmentFormInput: TemplateTreatmentFormInput) => void
}

const SvgArrowIcon = (
  <SvgArrow orient="right" color="grey" width={15} height={15} />
)

export const SelectableTreatmentRow: React.FC<Props> = ({
  treatment,
  onChange,
  templateTreatmentFormInputs,
  index,
  onSetup,
}) => {
  const {
    name,
    frequencyInput,
    shouldCreateTreatment,
    createTreatmentFromInput,
    templateTreatmentId,
    isDuplicated,
  } = treatment

  const { t } = useTranslation()
  const { isExSmallScreen } = useBreakpoint()
  const handleSelectedTreatment = useCallback(
    (value: boolean) => {
      const newValue = [...templateTreatmentFormInputs]
      newValue[index].shouldCreateTreatment = value
      onChange(newValue)
    },
    [index, onChange, templateTreatmentFormInputs],
  )

  const hasSetup = !!createTreatmentFromInput
  const isSetup = createTreatmentFromInput?.isSetup

  const handleFrequencyInput = useCallback(
    (value?: FrequencyInput) => {
      const newValue = [...templateTreatmentFormInputs]
      newValue[index].frequencyInput = value
      onChange(newValue)
    },
    [index, onChange, templateTreatmentFormInputs],
  )

  const renderFrequencyInput = () => {
    return (
      <FrequencySelector
        label={t('addTreatment:schedule.taskScheduleV2')}
        selected={frequencyInput}
        allowNoTasks={true}
        onChange={handleFrequencyInput}
      />
    )
  }

  return (
    <>
      <View style={styles.flexContainer}>
        <TouchableOpacity
          accessibilityLabel={`Select Treatment ${name}`}
          onPress={() => {
            hasSetup ? onSetup(treatment) : noop
          }}
          style={styles.container}
          key={templateTreatmentId}
        >
          <View style={styles.flexContainer}>
            <View style={{ alignSelf: 'center' }}>
              <Checkbox
                a11yLabel={`Checkbox ${name}`}
                checked={shouldCreateTreatment}
                onPress={() => {
                  handleSelectedTreatment(!shouldCreateTreatment)
                }}
              />
            </View>
            <View style={styles.treatmentContainer}>
              <View
                style={[
                  hasSetup ? styles.halfWidth : styles.fullWidth,
                  isExSmallScreen && styles.mobileWidth,
                ]}
              >
                <Text
                  style={[
                    styles.treatmentName,
                    isSetup && isExSmallScreen
                      ? styles.treatmentNameAfterSetup
                      : null,
                  ]}
                  numberOfLines={1}
                  ellipsizeMode={'tail'}
                >
                  {name}
                </Text>
              </View>
              <View style={[styles.setupContainer]}>
                {hasSetup ? (
                  <>
                    {isSetup ? (
                      <Pill
                        icon={<TickForCubexBilling />}
                        color={Colors.Backgrounds.positive}
                      />
                    ) : (
                      !isExSmallScreen && (
                        <Text
                          style={[
                            styles.messageContainer,
                            messageBoxColorsStyle.TEXTLABEL,
                          ]}
                        >
                          {t('template:treatment:setup')}
                        </Text>
                      )
                    )}
                    <View style={styles.padding}>{SvgArrowIcon}</View>
                  </>
                ) : null}
              </View>
            </View>
          </View>
        </TouchableOpacity>
        {!isExSmallScreen && !!frequencyInput && (
          <View style={[styles.flex, styles.boardLeft]}>
            {renderFrequencyInput()}
          </View>
        )}
      </View>
      {!!isExSmallScreen && !!frequencyInput && (
        <View style={styles.flex}>{renderFrequencyInput()}</View>
      )}
      {!!isDuplicated && (
        <Text style={styles.duplicateContainer}>
          {t('addTreatment:duplicateTreatment')}
        </Text>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  duplicateContainer: {
    color: Colors.Contents.warning,
    paddingLeft: 12,
    paddingBottom: 8,
  },
  flexContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.Backgrounds.UI,
    borderBottomColor: Colors.Borders.primary,
    borderBottomWidth: 1,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    paddingLeft: 6,
    paddingRight: 6,
    flex: 1,
  },
  setupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  boardLeft: {
    borderLeftWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  padding: {
    paddingRight: 18,
    paddingLeft: 8,
  },
  treatmentName: {
    fontFamily: Typography.FontFamilies.base,
    fontSize: 16,
  },
  treatmentNameAfterSetup: {
    paddingRight: 35,
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    fontWeight: '700',
    fontSize: 14,
    paddingHorizontal: 8,
    paddingVertical: 6,
    lineHeight: 16,
    marginRight: 8,
    overflow: 'hidden',
    color: Colors.Contents.primary,
  },
  treatmentContainer: {
    justifyContent: 'space-between',
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 6,
  },
  halfWidth: {
    justifyContent: 'center',
    width: '68%',
  },
  fullWidth: {
    justifyContent: 'center',
    width: '100%',
  },
  mobileWidth: {
    width: '85%',
  },
})

const messageBoxColorsStyle = StyleSheet.create(messageBoxColors)
