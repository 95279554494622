import * as React from 'react'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Primitive } from 'src/design-system/theme/colors/base'
import { Typography } from 'src/design-system/theme'

type Props = {
  title: string
  extraHeadStyle?: React.CSSProperties
}

const useStyles = makeStyles({
  head: {
    border: '1px solid',
    borderColor: Primitive.grey300,
    backgroundColor: Primitive.grey100,
  },
})

const headTextStyle = {
  fontWeight: Typography.SubHeading.M.fontWeight,
}

export const DataTableHead: React.FC<Props> = ({ title, extraHeadStyle }) => {
  const classes = useStyles()
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell
            className={classes.head}
            style={{ ...headTextStyle, ...extraHeadStyle }}
          >
            {title}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
