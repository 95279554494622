import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Paragraph } from 'src/design-system/components/Text'

type ContactDetailSectionProps = {
  consultLocation: string | null
  primaryLocation?: string | null
  phoneNumber?: string | null
}

export const ContactDetailSection: React.FC<ContactDetailSectionProps> = ({
  consultLocation,
  primaryLocation,
  phoneNumber,
}) => {
  const locationDisplay = consultLocation ?? primaryLocation ?? ' '

  return (
    <View style={[styles.row, styles.rowPadding, styles.locationPhoneRow]}>
      <Paragraph size={'M'} style={styles.locationText} numberOfLines={1}>
        {locationDisplay}
      </Paragraph>
      <Paragraph
        size={'M'}
        style={styles.flexShrinkContainer}
        numberOfLines={1}
      >
        {phoneNumber && phoneNumber !== '0' ? `Ph: ${phoneNumber}` : ''}
      </Paragraph>
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    maxWidth: '100%',
    alignItems: 'baseline',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  rowPadding: {
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
  locationPhoneRow: {
    justifyContent: 'space-between',
    columnGap: 4,
  },
  locationText: {
    flexShrink: 0,
  },
  flexShrinkContainer: {
    flexShrink: 1,
  },
})
