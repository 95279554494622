import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { UpdateTaskInput } from 'types/globalTypes'

import { Inputs, Task } from '../types'
import { getTaskGivenTimes } from './getTaskGivenTimes'
import { getTaskUnit } from './getTaskUnit'

type Props = {
  inputData: Inputs
  newTaskValue: any
  organisationId: string
  originalTask: Task
  patientWeightUnit: string
  shouldShowBillableToggle: boolean | null | undefined
  treatment: TreatmentChildLevel | null
  shouldShowBackDate?: boolean
}

type GetUpdateTaskInputProps = (param: Props) => {
  updatedTask: UpdateTaskInput
}

export const getUpdateTaskInput: GetUpdateTaskInputProps = ({
  inputData,
  newTaskValue,
  organisationId,
  originalTask,
  patientWeightUnit,
  shouldShowBillableToggle,
  treatment,
  shouldShowBackDate = true,
}) => {
  const { givenStartAt, givenStopAt } = getTaskGivenTimes({
    originalTask,
    isBackdate: inputData.backdate,
    formTimeGiven: inputData.datetimeGiven,
  })

  const givenTimes = shouldShowBackDate
    ? {
        given_start_at: givenStartAt,
        given_stop_at: givenStopAt,
      }
    : {}
  return {
    updatedTask: {
      assigned_user: inputData.assigned_user,
      id: originalTask.id,
      notes: inputData.notes,
      organisation_id: organisationId,
      patient_id: originalTask.patient_id,
      photo_urls: inputData.photo_urls ? inputData.photo_urls : [],
      videos: inputData.videos ?? originalTask.videos,
      sheet_id: originalTask.sheet_id,
      treatment_id: originalTask.treatment_id,
      highlight_colour: inputData.highlightColor,
      value: newTaskValue,
      qty_billed: inputData.qtyBilled,
      unit: getTaskUnit({
        patientWeightUnit,
        dosageInfo: treatment?.medicine_dosage_info,
        product: treatment?.product,
        task: inputData,
      }),
      ...(shouldShowBillableToggle && {
        send_to_adaptor: { is_billable: inputData.isBillable },
      }),
      ...givenTimes,
    },
  }
}
