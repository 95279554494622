import { environment } from 'src/config'
import { Auth } from 'aws-amplify'
import { Auth as VAuth, authTypeService } from '../context/auth'

export const signOut = async () => {
  const isAuthV2 = authTypeService.getIsAuthV2()
  if (isAuthV2) {
    if (environment.isWeb) window.history.pushState(null, '', '/login')
    return VAuth.signOut()
  }
  if (environment.isWeb) window.history.pushState(null, '', '/')
  return Auth.signOut()
}
