import React from 'react'
import { FormField } from 'components/common'
import { SvgArrow } from 'components/Icons'

import { SingleProductPicker } from 'components/AddTreatment/SingleProductPicker'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'

type Props = {
  name?: string
  onSelect: (item: { id: string; name: string }) => void
}

export const ProductSelect: React.FC<Props> = ({ name, onSelect }) => {
  const [isDrawerVisible, setDrawerVisible] = React.useState(false)
  const toggleDrawer = () => setDrawerVisible(!isDrawerVisible)

  const onProductSelect = (item: { id: string; name: string }) => {
    onSelect(item)
    toggleDrawer()
  }

  return (
    <>
      <FormField
        active={false}
        required={true}
        label={'Product'}
        onPress={toggleDrawer}
        value={!!name ? name : 'please select product'}
        icon={<SvgArrow orient="right" color="grey" width={15} height={15} />}
      />
      <SheetAwareSideDrawer
        visible={isDrawerVisible}
        onClose={toggleDrawer}
        title={'Select a Product'}
      >
        <SingleProductPicker onSelect={onProductSelect} noTemplate={true} />
      </SheetAwareSideDrawer>
    </>
  )
}
