import React, { useMemo, useState } from 'react'
import { messageBoxColors } from 'components/common/Avatar/AvatarWithMessage'
import { HEADER_HEIGHT } from 'constants/Header'
import { getAlertLevelAndAttitude } from 'components/PatientItem/utils/getAlertLevelAndMessage'
import { getPatientBreedAndSpecies } from 'components/PatientItem/utils/getPatientBreedAndSpecies'
import { LINE_HEIGHT } from 'components/PatientPanel/Cards/common'
import { GetPatientName } from 'components/PatientPanel/Cards/GetPatientName'
import { PatientWeightAgeTag } from 'components/PatientPanel/Cards/PatientWeightAgeTag'
import { Fonts } from 'constants/Fonts'
import { Shadows } from 'constants/Shadows'
import {
  Animated,
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
} from 'react-native'
import { getPatient_getPatient as GetPatientData } from 'types/getPatient'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { getWidth } from 'src/utils/getWidth'
import { environment } from 'src/config'
import { Colors } from 'constants/Colors'

type Props = {
  patient: GetPatientData
  animatedValue?: Animated.Value
  isPatientPanelSplitLayout?: boolean
  onPress?: () => void
}

const { isWeb } = environment

export const MinimisedPrimaryInfo: React.FC<Props> = ({
  patient,
  animatedValue,
  onPress,
  isPatientPanelSplitLayout,
}) => {
  const { alertLevel, attitude } = getAlertLevelAndAttitude(patient)
  const breedAndSpecies = getPatientBreedAndSpecies(patient)
  const patientColor =
    patient.active_consultations?.items?.[0]?.color?.hex || Colors.white
  const { isLargeScreen, isVeryLargeScreen } = useBreakpoint()

  const isCritical_notes = !!patient.critical_notes?.length
  const [primaryInfoWidth, setPrimaryInfoWidth] = useState(0)
  const [patientNameWidth, setPatientNameWidth] = useState(0)

  const minimisedPatientInfoCardPressableStyle = useMemo(
    () => [
      { backgroundColor: patientColor },
      styles.minimisedPatientInfoCard,
      isPatientPanelSplitLayout && styles.autoWidth,
      !isLargeScreen && styles.autoHeight,
    ],
    [isLargeScreen, isPatientPanelSplitLayout, patientColor],
  )

  return (
    <Animated.View
      style={[
        {
          flex: animatedValue,
          opacity: animatedValue,
        },
        styles.container,
        isLargeScreen && !isWeb && isCritical_notes && styles.IOSContainer,
        isVeryLargeScreen && isWeb && styles.largeContainer,
      ]}
    >
      <TouchableOpacity
        style={minimisedPatientInfoCardPressableStyle}
        onPress={onPress}
      >
        <View
          style={styles.headerWeight}
          onLayout={event => {
            setPrimaryInfoWidth(getWidth(event))
          }}
        >
          {isLargeScreen ? (
            <GetPatientName
              patient={patient}
              isPatientPanelSplitLayout={isPatientPanelSplitLayout}
              patientNameWidth={patientNameWidth}
              primaryInfoWidth={primaryInfoWidth}
              setPatientNameWidth={setPatientNameWidth}
            />
          ) : (
            <Text
              style={styles.headerBreedText}
              numberOfLines={1}
              ellipsizeMode={'tail'}
            >
              {breedAndSpecies}
            </Text>
          )}
          {isLargeScreen ? (
            <PatientWeightAgeTag
              patient={patient}
              primaryInfoWidth={primaryInfoWidth}
              isPatientPanelSplitLayout={isPatientPanelSplitLayout}
            />
          ) : null}
        </View>
        <View
          style={[
            styles.headerBreedContainer,
            !isLargeScreen && styles.mobileHeaderInfo,
          ]}
        >
          <Text
            style={[
              styles.headerMessageContainer,
              messageBoxColorsStyle[alertLevel],
            ]}
          >
            {attitude}
          </Text>
          {isLargeScreen ? (
            <Text
              style={styles.headerBreedText}
              numberOfLines={1}
              ellipsizeMode={'tail'}
            >
              {breedAndSpecies}
            </Text>
          ) : (
            <PatientWeightAgeTag
              patient={patient}
              primaryInfoWidth={primaryInfoWidth}
              isPatientPanelSplitLayout={isPatientPanelSplitLayout}
            />
          )}
        </View>
      </TouchableOpacity>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: -1,
  },
  largeContainer: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: 'auto',
  },
  IOSContainer: {
    flex: -1,
  },
  minimisedPatientInfoCard: {
    height: HEADER_HEIGHT,
    borderRadius: 6,
    padding: 8,
    flexDirection: 'column',
    ...Shadows.cardShadow,
    marginHorizontal: 8,
    overflow: 'hidden',
  },
  autoWidth: { width: 'auto' },
  autoHeight: { height: 'auto' },
  headerBreedText: {
    fontFamily: Fonts.italic,
    lineHeight: LINE_HEIGHT,
    fontSize: 14,
    flex: -1,
  },
  headerMessageContainer: {
    display: 'flex',
    height: LINE_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 9,
    color: Colors.white,
    fontSize: 12,
    paddingHorizontal: 8,
    paddingVertical: 1,
    lineHeight: 16,
    marginRight: 8,
    overflow: 'hidden',
  },
  headerBreedContainer: {
    flexDirection: 'row',
  },
  headerWeight: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileHeaderInfo: {
    alignItems: 'center',
    marginTop: 3,
    flexWrap: 'wrap',
  },
})

const messageBoxColorsStyle = StyleSheet.create(messageBoxColors)
