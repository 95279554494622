import React, { useState } from 'react'
import { Button, Status, TextInput, FormBreak } from 'components/common'
import { orderBy } from 'lodash'
import { StyleSheet, View } from 'react-native'
import { Colors } from 'src/constants/Colors'

import { DraggableList } from 'components/common/DraggableList'
import { GroupTreatmentItem } from './GroupTreatmentItem'
import {
  TreatmentSheet,
  TreatmentSheetFirstLevel,
} from 'components/Treatment/common/types'

import { getSheet_getSheet_treatments_items_treatments_items as Treatment } from 'src/types/getSheet'
import { TFunction } from 'i18next'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { createErrorStatus } from 'components/common/TextInput/utils'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

type TemplateFormProps = {
  handleSubmit: (treatmentList: Treatment[], groupName: string) => void
  submitting: boolean
  treatmentToEdit: TreatmentSheet | null
}

type FormValues = {
  name: string
}

const getValidationSchema = (t: TFunction) => {
  const requiredMsg = t('form.required')
  return Yup.object().shape({
    name: Yup.string().max(30).required(requiredMsg),
  })
}

const emptyTreatments: Treatment[] = []

export const EditTreatmentGroup: React.FC<TemplateFormProps> = ({
  handleSubmit,
  submitting,
  treatmentToEdit,
}) => {
  const { t } = useTranslation()

  const getTemplateTreatments = () => {
    const newTreatmentToEdit = treatmentToEdit as TreatmentSheetFirstLevel

    const treatmentsList =
      newTreatmentToEdit?.treatments?.items ?? emptyTreatments

    const groupTreatments = treatmentsList as Treatment[]

    if (!groupTreatments.length) {
      return emptyTreatments
    }
    return orderBy(
      groupTreatments,
      ['discontinued_at', 'order'],
      ['desc', 'asc'],
    )
  }

  const defaultName = treatmentToEdit?.name ?? ''
  const initialValues = {
    name: treatmentToEdit?.name ?? '',
  }

  const [treatmentList, reorderTreatmentList] = useState<Treatment[]>(
    getTemplateTreatments(),
  )
  const showDiscontinue = false

  const validationSchema = getValidationSchema(t)
  const {
    control,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = () => {
    const newName = getValues('name')
    let submitName = ''
    if (defaultName !== newName && newName !== '') {
      submitName = newName
    }

    handleSubmit(treatmentList, submitName)
  }

  const renderDraggableItem = ({ item, drag, isActive }: any) => (
    <GroupTreatmentItem
      groupTreatment={item}
      onDragPress={drag}
      displayHidden={showDiscontinue}
      isActive={isActive}
    />
  )

  const renderListHeader = () => (
    <View style={styles.headerHolder}>
      <Status status={createErrorStatus(errors.name?.message, true)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              onChangeText={newVal => {
                onChange(newVal)
              }}
              value={value}
              disabled={false}
              label={'Name'}
              required={true}
            />
          )}
          name="name"
          rules={{ required: true }}
        />
      </Status>
      <FormBreak />
    </View>
  )

  const renderListFooter = () => (
    <Button
      onPress={onSubmit}
      title={t('general.saveChanges')}
      loading={submitting}
      color={Colors.green}
      style={styles.button}
    />
  )

  // Have issuse to add unit test for the draggable list.
  return (
    <View style={styles.dragContainer}>
      <DraggableList
        items={treatmentList}
        renderItem={renderDraggableItem}
        onMoveEnd={reorderTreatmentList}
        ListHeaderComponent={renderListHeader()}
        ListFooterComponent={renderListFooter()}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  dragContainer: {
    flex: 1,
  },
  headerHolder: {
    marginTop: 20,
    marginBottom: 30,
    marginHorizontal: 15,
  },
  button: {
    marginVertical: 16,
  },
})
