import { useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { useTranslation } from 'react-i18next'
import {
  deleteTreatment as DeleteTreatment,
  deleteTreatmentVariables as DeleteTreatmentVariables,
} from 'src/types/deleteTreatment'

import { DELETE_TREATMENT } from '../graphql'

export const useDeleteTreatment = ({
  id,
  sheetId,
  patientId,
  treatmentName = 'treatment',
  disableToast = false,
}: {
  sheetId: string
  patientId: string
  id: string
  treatmentName?: string
  disableToast?: boolean
}) => {
  const { t } = useTranslation()
  const [deleteTreatment] = useMutation<
    DeleteTreatment,
    DeleteTreatmentVariables
  >(DELETE_TREATMENT, {
    variables: {
      input: { id, patient_id: patientId, sheet_id: sheetId },
    },
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
    onCompleted: () =>
      !disableToast &&
      toast.success(
        t('treatment:deleted', {
          treatmentName,
        }),
      ),
  })

  return () => {
    const dismiss = !disableToast
      ? toast.process(
          t('treatment:deleting', {
            treatmentName,
          }),
        )
      : undefined

    deleteTreatment({
      optimisticResponse: { deleteTreatment: false },
      update: (cache, result) => {
        cache.modify({
          id: `Treatment:${id}`,
          fields: {
            _hidden: () => !!result.data?.deleteTreatment,
          },
        })
      },
    }).finally(dismiss)
  }
}
