import { CallParameter } from 'components/SheetHeader/ParameterEntry'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

type ParametersProps = {
  callParameters?: CallParameter[] | null
  a11yLabel?: string
}

const defaultParameterOrder: { [param: string]: number } = {
  HR: 1,
  TP: 2,
  TEMP: 3,
  PCV: 4,
  RR: 5,
  GLU: 6,
}

const callParametersSorter = (firstName: string, secondName: string) => {
  const firstDefault = defaultParameterOrder[firstName]
  const secondDefault = defaultParameterOrder[secondName]
  if (!firstDefault || !secondDefault) return 0
  return firstDefault < secondDefault ? -1 : 1
}

export const CallParameterBox: React.FC<ParametersProps> = ({
  callParameters,
  a11yLabel,
}) => {
  const displayCallParameters =
    callParameters &&
    // copy to avoid arr mutation
    [...callParameters].sort((first, second) => {
      return callParametersSorter(first.name, second.name)
    })

  return (
    <View style={styles.callParametersContainer} accessibilityLabel={a11yLabel}>
      {displayCallParameters?.map((parameterValue, idx) => (
        <CallParameterRow parameter={parameterValue} key={idx} />
      ))}
    </View>
  )
}

type RowProps = {
  parameter: CallParameter
}

const CallParameterRow: React.FC<RowProps> = ({ parameter }) => {
  const displayName = parameter.short_name || parameter.name
  return (
    <View style={styles.callParameterContainer}>
      <View style={styles.callParameterName}>
        <Text>{displayName}</Text>
      </View>
      <View style={styles.callParameterValueContainer}>
        <Text>{parameter.min}</Text>
        <Text style={styles.separator}>{'< >'}</Text>
        <Text>{parameter.max}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  callParametersContainer: {
    flexDirection: 'column',
  },
  callParameterContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 2,
  },
  callParameterName: {
    flex: 1,
  },
  callParameterValueContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  separator: {
    marginHorizontal: 4,
  },
})
