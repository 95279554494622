import React, { useCallback } from 'react'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { SvgClose, SvgPencil } from 'src/components/Icons'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { useBreakpoint } from 'src/hocs/breakpoint'

export type Product = {
  id: string
  name: string
  code?: string | null
  isUnAvailableProduct?: boolean
}

type Props = {
  product: Product
  removeTreatmentOption: (id: string) => void
  shouldHideDelete?: boolean
  setEditing?: () => void
}

const hitSlop = { top: 10, left: 10, bottom: 10, right: 10 }

export const SelectedProductRow: React.FC<Props> = ({
  product,
  removeTreatmentOption,
  shouldHideDelete,
  setEditing,
}) => {
  const { t } = useTranslation()

  const shouldShowEdit = !!setEditing && !shouldHideDelete
  const deleteItem = useCallback(() => {
    removeTreatmentOption(product.id)
  }, [product.id, removeTreatmentOption])
  const { isVeryLargeScreen } = useBreakpoint()
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.textContainer,
          product.isUnAvailableProduct && styles.unavailableTextContainer,
        ]}
      >
        <TouchableOpacity
          onPress={() => {
            setEditing?.()
          }}
          disabled={!shouldShowEdit}
          style={styles.inputContainer}
          testID={`Editable Product Name Touchable - ${product.name}`}
        >
          <View style={styles.productDetails}>
            <TextWithTooltip
              numberOfLines={1}
              style={styles.productName}
              title={product.name}
            >
              {product.name}
            </TextWithTooltip>
            {shouldShowEdit ? (
              <View style={styles.icon}>
                <SvgPencil scale={0.8} />
              </View>
            ) : null}
            {!!product.code && isVeryLargeScreen ? (
              <Text style={styles.subText}>{`- ${product.code}`}</Text>
            ) : null}
          </View>

          {product.isUnAvailableProduct ? (
            <TextWithTooltip
              title={t('template:form.productUnavailable')}
              numberOfLines={1}
              style={styles.unavailableText}
            >
              {t('template:form.productUnavailable')}
            </TextWithTooltip>
          ) : null}
        </TouchableOpacity>
      </View>

      {!shouldHideDelete ? (
        <TouchableOpacity
          onPress={deleteItem}
          testID="DeleteItem"
          style={styles.deleteBtn}
          hitSlop={hitSlop}
        >
          <SvgClose color={Colors.vetRadarBlue} length={15} />
        </TouchableOpacity>
      ) : null}
    </View>
  )
}

const height = 45

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    minHeight: height,
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
    width: '100%',
  },
  textContainer: {
    flexDirection: 'row',
    width: '80%',
    minHeight: height,
    alignItems: 'center',
  },
  productName: {
    fontFamily: Fonts.regular,
    fontSize: 16,
  },
  subText: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 13,
    paddingLeft: 4,
    minWidth: 100,
  },
  deleteBtn: {
    marginLeft: 15,
  },
  icon: {
    marginLeft: 12,
  },
  inputContainer: {
    minHeight: height,
    maxWidth: '100%',
    padding: 10,
  },
  unavailableTextContainer: {
    width: '60%',
  },
  unavailableText: { color: Colors.error, fontSize: 13 },
  productDetails: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
