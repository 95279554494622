import React, { ReactElement } from 'react'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { Primitive } from 'src/design-system/theme/colors/base'
import { FontFamilies } from 'src/design-system/theme/typography/base'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  content: string | ReactElement | null
  extraBodyStyle?: React.CSSProperties
}

const useStyles = makeStyles({
  customTableCell: {
    fontFamily: FontFamilies.base,
    backgroundColor: Primitive.white,
    wordBreak: 'break-all',
  },
})

export const DataTableBody: React.FC<Props> = ({ content, extraBodyStyle }) => {
  const tableBodyUseStyles = useStyles()
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell
            className={`${tableBodyUseStyles.customTableCell} ${extraBodyStyle}`}
          >
            {content}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
