import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { Colors } from 'constants/Colors'
import { useTranslation } from 'react-i18next'

export const ApprovalRequiredTextMessage = () => {
  const { t } = useTranslation()
  return (
    <Text style={styles.text}>{t('bulkTask:drawer.approvalRequired')}</Text>
  )
}

const styles = StyleSheet.create({
  text: {
    color: Colors.approval.primary,
    fontSize: 12,
    paddingHorizontal: 10,
  },
})
