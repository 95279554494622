import { format } from 'date-fns'
import { AttachmentInformation } from 'components/Task/types'
import { getFileDownloadSignedUrl } from 'src/utils/file/file'

export const downloadFromS3 = async (
  key: string,
  attachmentInfo: AttachmentInformation,
  filename = 'download',
): Promise<void> => {
  if (attachmentInfo.name && attachmentInfo.startedAt) {
    const formattedStartAt = format(
      new Date(attachmentInfo.startedAt),
      'dd_MM_yyyy_HH:mm',
    )
    filename = `Vet Radar - ${attachmentInfo.name} - ${formattedStartAt}`
  }

  const sourceURL = await getFileDownloadSignedUrl(key, filename, true)

  const a = document.createElement('a')
  a.href = sourceURL
  a.download = filename
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(sourceURL)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
}
