import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { TemplateFormInput } from 'components/CreateSheet/createSheetData'
import { GET_TEMPLATE } from 'components/TreatmentTemplate/graphql'
import { compact, isDate } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { useBackButton } from 'src/hooks/useBackButton'
import {
  getTemplate as GetTemplate,
  getTemplateVariables as GetTemplateVariables,
} from 'src/types/getTemplate'

import { AddTemplateToSheetForm } from './AddTemplateToSheetForm'
import { Product } from './ProductRow'
import { ProductGroupName, SingleProductPicker } from './SingleProductPicker'
import { FormDataTemplate, FormDataTemplateWithId } from './templateUtilFns'
import { TemplateDuplicationIdHash } from './useTemplateDuplication'

type Props = {
  patientId: string
  onDone: (data: FormDataTemplateWithId) => void
  sheetAttendingDepartment: string | null
  currentTemplateIndex: number
  templateFormInputs: TemplateFormInput[]
  isAnesthesia: boolean
}

export type DuplicateTemplateHash = {
  [template_id: string]: true
}

type SelectedProduct = Product | { id: string } | null

const getTemplateDuplicationIdHash = (
  templateFormInputs: TemplateFormInput[],
  currentTemplateIndex: number,
) => {
  if (currentTemplateIndex > templateFormInputs.length) {
    return {}
  }

  // only compare with previous templates, for example, product_1 in template 2(index) and template 3(index)
  // when product_1 display in template 2 should not be duplicated, because template 1 does not contain product1, even template 3 has.
  // while in template 3, shows `is duplicated`
  const templateFormInputsBeforeIndex = templateFormInputs.slice(
    0,
    currentTemplateIndex,
  )

  const templateDuplicationIdHash: TemplateDuplicationIdHash = {}

  templateFormInputsBeforeIndex.forEach(templateFormInput => {
    templateFormInput.templateTreatmentFormInputs?.forEach(
      templateTreatmentFormInput => {
        const productId = templateTreatmentFormInput.productId
        if (productId) {
          templateDuplicationIdHash[productId] = true
        }
      },
    )
  })

  return templateDuplicationIdHash
}

export const AddTemplateDrawerContent: React.FC<Props> = ({
  patientId,
  onDone,
  sheetAttendingDepartment,
  currentTemplateIndex,
  templateFormInputs,
  isAnesthesia,
}) => {
  const { t } = useTranslation()

  const templateOverrides = templateFormInputs[currentTemplateIndex]
  const templateDuplicationIdHash = getTemplateDuplicationIdHash(
    templateFormInputs,
    currentTemplateIndex,
  )

  const duplicateTemplateHash: DuplicateTemplateHash = useMemo(
    () =>
      templateFormInputs.reduce((duplicateTemplateHash, templateFormInput) => {
        const templateId = templateFormInput.selectedTemplate.id
        const thisTemplateId = templateOverrides.selectedTemplate.id
        if (templateId && templateId !== thisTemplateId) {
          duplicateTemplateHash[templateId] = true
          return duplicateTemplateHash
        }
        return duplicateTemplateHash
      }, {} as DuplicateTemplateHash) ?? {},
    [templateFormInputs, templateOverrides],
  )

  const currentTemplateId = templateOverrides?.selectedTemplate.id
  const defaultSelect = currentTemplateId
    ? {
        id: currentTemplateId,
      }
    : null

  const [selectedProduct, setSelectedProduct] =
    useState<SelectedProduct>(defaultSelect)

  const [{ organisationId }] = useOrganisation()

  const handleBackOverrides = useBackButton<SelectedProduct>(
    selectedProduct,
    setSelectedProduct,
  )

  const { data: template } = useQuery<GetTemplate, GetTemplateVariables>(
    GET_TEMPLATE,
    {
      variables: {
        id: selectedProduct?.id ?? '',
        organisation_id: organisationId,
      },
      skip: !selectedProduct?.id,
    },
  )
  const getTemplate = template?.getTemplate

  const defaultOverrides = useMemo(() => {
    const selectedTemplateInForm = templateOverrides?.selectedTemplate
    const isDifferentProduct =
      selectedProduct?.id && selectedProduct.id !== selectedTemplateInForm?.id

    if (isDifferentProduct) {
      return {}
    }

    return {
      startTime: isDate(selectedTemplateInForm?.startTime)
        ? selectedTemplateInForm?.startTime
        : undefined,
      applyWorkflow: templateOverrides?.selectedTemplate.applyWorkflow,
      templateTreatmentFormInputs:
        templateOverrides?.templateTreatmentFormInputs,
    }
  }, [templateOverrides, selectedProduct?.id])

  const onSave = useCallback(
    (data: FormDataTemplate) => {
      if (!selectedProduct || !getTemplate) {
        return
      }
      const sites = compact(getTemplate.sites?.map(site => site?.id)) ?? []
      onDone({
        ...data,
        sites,
        templateId: selectedProduct.id,
        index: currentTemplateIndex,
        name: getTemplate.name,
      })
    },
    [currentTemplateIndex, getTemplate, onDone, selectedProduct],
  )

  if (!selectedProduct) {
    return (
      <SingleProductPicker
        onSelect={setSelectedProduct}
        siteIds={sheetAttendingDepartment ? [sheetAttendingDepartment] : []}
        groupName={ProductGroupName.Template}
        duplicateTemplateHashFilter={duplicateTemplateHash}
        isAnesthesia={isAnesthesia}
      />
    )
  }

  if (!getTemplate) {
    return <ActivityIndicator size="large" style={styles.spinner} />
  }

  return (
    <AddTemplateToSheetForm
      onSave={onSave}
      submitting={false}
      submitTitle={t('addTreatment:addTemplate')}
      templateWorkflowId={getTemplate.template_workflow_id}
      template={getTemplate}
      handleBackOverrides={handleBackOverrides}
      patientId={patientId}
      defaultOverrides={defaultOverrides}
      templateDuplicationIdHash={templateDuplicationIdHash}
      isAddTemplateToNewSheet={true}
    />
  )
}
const styles = StyleSheet.create({
  spinner: {
    marginTop: 25,
  },
})
