import { useCallback, useEffect } from 'react'
import {
  TreatmentDrawerActionTypes,
  useTreatmentDrawerContext,
} from 'src/context/treatmentDrawer'

export const useSetShowTreatmentTabs = (shouldShowTabsWhenUnmount = true) => {
  const [_, treatmentDrawerDispatch] = useTreatmentDrawerContext()

  const setShouldShowTabs = useCallback(
    (newValue: boolean = true) => {
      if (shouldShowTabsWhenUnmount) {
        treatmentDrawerDispatch({
          type: TreatmentDrawerActionTypes.setShouldShowTabs,
          setShouldShowTabs: newValue,
        })
      }
    },
    [treatmentDrawerDispatch, shouldShowTabsWhenUnmount],
  )

  useEffect(() => {
    setShouldShowTabs(false)

    return setShouldShowTabs
  }, [setShouldShowTabs])
}
