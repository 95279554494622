import React, { useId } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { ControlProps, components } from 'react-select'
import { Colors, Typography } from 'src/design-system/theme'
import { SelectOption } from '../..'

export const Control: React.FC<ControlProps<SelectOption, boolean>> = ({
  children,
  ...props
}) => {
  const labelId = useId()
  // @ts-ignore Custom Prop passed to parent.
  const label = props.selectProps?.label

  /* 
    We need to change the layout of the components, in order to get the label inside the control. 
    So we extract each child and put them in manually. 
  */
  const getSpecificChild = (type: React.ElementType) =>
    React.Children.toArray(children).filter(
      child => React.isValidElement(child) && child.type === type,
    )

  const valueContainerBorderStyle = {
    borderWidth: 2,
    borderColor: props.menuIsOpen ? Colors.Borders.accent : 'transparent',
    borderRadius: 4,
    marginLeft: -6,
  }

  return (
    <components.Control
      {...props}
      aria-labelledby={label ? labelId : undefined}
    >
      <View style={styles.labelContainer}>
        {!!label && (
          <Text nativeID={labelId} style={styles.label}>
            {label}
          </Text>
        )}
        <View style={valueContainerBorderStyle}>
          {getSpecificChild(components.ValueContainer)}
        </View>
      </View>
      {getSpecificChild(components.IndicatorsContainer)}
    </components.Control>
  )
}

const styles = StyleSheet.create({
  labelContainer: {
    flex: 1,
  },
  label: {
    ...Typography.Label.M,
    color: Colors.Contents.tertiary,
  },
})
