import React, { useCallback, useEffect } from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { PatientStackParamList } from 'components/Patient/screens'
import { ActivityIndicator, Platform, StyleSheet, View } from 'react-native'
import { Routes } from 'src/constants/Routes'
import { useOrganisation } from 'src/context/organisation'
import {
  getDayRangeExtent,
  useResetTimeResolution,
  useTimeResolution,
} from 'src/hocs/timeContext'
import { TimeSegment } from 'src/hocs/types/time'
import { SheetType } from 'src/types/globalTypes'

import { webReplaceHistory } from '../SheetList/utils/navigation'
import { ReadySheet } from './ReadySheet'
import { useGetSheetType } from './useGetSheetType'
import { cache } from 'src/apollo/cache'
import { ZOOM_LEVEL_FIELD } from 'components/Sheet/graphql'
import { getSheet_getSheet } from 'src/types/getSheet'

// use the params from routes.sheet in patientstacklist
export type SheetScreenRouteProp = RouteProp<
  PatientStackParamList,
  Routes.Sheet
>

export const Sheet: React.FC = () => {
  const { setParams } = useNavigation()
  const { params } = useRoute<SheetScreenRouteProp>()

  const { initialDateInView, patientId, sheetId: urlSheetId } = params

  const [{ lastCreatedSheetId }] = useOrganisation()
  useResetTimeResolution(initialDateInView)

  const sheetType = useGetSheetType(urlSheetId)

  const {
    toggleTimeSegment,
    timeSegment,
    setDateRangeCount,
    setVisibleDayRange,
  } = useTimeResolution()

  const sheetId =
    urlSheetId === 'optimisticSheetId' ? lastCreatedSheetId || '' : urlSheetId

  const isSheetCreated =
    (urlSheetId !== 'optimisticSheetId' && urlSheetId) || !!lastCreatedSheetId

  const getZoomLevel = useCallback((sheetId: String) => {
    if (!sheetId) return null

    return cache.readFragment<getSheet_getSheet>({
      id: `Sheet:${sheetId}`,
      fragment: ZOOM_LEVEL_FIELD,
    })
  }, [])

  const getTimeSegment = useCallback(
    (sheetType: SheetType) => {
      const zoomLevelFragment = getZoomLevel(sheetId)

      if (zoomLevelFragment?._zoom_level)
        return zoomLevelFragment?._zoom_level as TimeSegment

      if (sheetType === SheetType.ANAESTHESIA) {
        return TimeSegment.fiveMinute
      }

      return TimeSegment.hourly
    },
    [getZoomLevel, sheetId],
  )

  /*  if current sheet is optimistic, change once real id exists */
  useEffect(() => {
    if (
      isSheetCreated &&
      urlSheetId === 'optimisticSheetId' &&
      !!lastCreatedSheetId
    ) {
      if (Platform.OS === 'web') {
        webReplaceHistory(`/patients/${patientId}/sheets`)
      }
      setParams({ sheetId: lastCreatedSheetId })
    }
  }, [isSheetCreated, urlSheetId, lastCreatedSheetId, setParams, patientId])

  useEffect(() => {
    const timeSegment = getTimeSegment(sheetType)
    const middleDate = initialDateInView
      ? new Date(initialDateInView)
      : new Date()
    if (sheetType === SheetType.ANAESTHESIA) {
      setDateRangeCount(1)
      setVisibleDayRange(getDayRangeExtent(middleDate, 1))
    } else {
      setDateRangeCount(3)
      setVisibleDayRange(getDayRangeExtent(middleDate, 3))
    }
    toggleTimeSegment(timeSegment)
  }, [
    sheetType,
    sheetId,
    initialDateInView,
    setDateRangeCount,
    setVisibleDayRange,
    timeSegment,
    toggleTimeSegment,
    getTimeSegment,
  ])

  return (
    <View style={styles.container}>
      {!isSheetCreated ? (
        <ActivityIndicator size="large" style={styles.activityIndicator} />
      ) : (
        // Use key={sheetId} to trigger reload and construct sheet after optimistic
        <>
          <ReadySheet key={sheetId} sheetId={sheetId} sheetType={sheetType} />
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    marginTop: 25,
  },
  container: {
    flex: 1,
  },
})
