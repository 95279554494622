import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import Highlighter from 'react-native-highlight-words'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'

import { TreatmentOption } from '../TemplateTreatmentForm'

type Props = {
  a11yLabel?: string
  product: TreatmentOption
  searchWords: string[]
  addTreatmentOption: (product: TreatmentOption) => void
  removeTreatmentOption: (id: string) => void
  isAdded: boolean
  isIVInfusion?: boolean
}

export const ProductRow: React.FC<Props> = ({
  product,
  searchWords = [],
  addTreatmentOption,
  removeTreatmentOption,
  isAdded,
  isIVInfusion = false,
  a11yLabel,
}) => {
  const { t } = useTranslation()
  return (
    <View style={styles.container} accessibilityLabel={a11yLabel}>
      <View style={{ flex: 1 }}>
        <Highlighter
          ellipsizeMode="middle"
          highlightStyle={{ fontFamily: Fonts.bold }}
          numberOfLines={1}
          searchWords={searchWords}
          autoEscape={true}
          style={styles.productName}
          testID={`TextHighlighter: ${product.name}`}
          textToHighlight={product.name}
        />
        {!!product.code && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.subText} testID={product.code}>
              {product.code}
            </Text>
          </View>
        )}
      </View>
      {Platform.OS === 'web' && !isIVInfusion && (
        <Text style={styles.groupName} testID={`Group: ${product.groupName}`}>
          {product.groupName}
        </Text>
      )}
      <TouchableOpacity
        accessibilityLabel={`${isAdded ? 'Remove' : 'Add'} ${product.name}`}
        style={[
          styles.toggleButton,
          isAdded && styles.removeBtn,
          product.isOldChild && styles.disableBtn,
        ]}
        disabled={product.isOldChild}
        onPress={() => {
          isAdded
            ? removeTreatmentOption(product.id)
            : addTreatmentOption(product)
        }}
      >
        <Text style={styles.buttonText}>
          {isAdded
            ? t('template:treatment.added')
            : t('template:treatment.add')}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
  },
  productName: {
    fontFamily: Fonts.regular,
    fontSize: 16,
  },
  subText: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 13,
  },
  groupName: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 13,
    paddingRight: 20,
  },
  toggleButton: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: Colors.blue,
    alignItems: 'center',
    borderRadius: 3,
  },
  buttonText: {
    color: Colors.white,
    fontFamily: Fonts.regular,
    fontSize: 12,
  },
  removeBtn: {
    backgroundColor: Colors.green,
  },
  disableBtn: {
    backgroundColor: Colors.disabled,
  },
})
