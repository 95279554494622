import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { AddTreatmentButton } from 'components/AddTreatment/AddTreatmentButton'
import {
  Button,
  FormBreak,
  FormLabel,
  Select,
  SwitchInput,
  TextInput,
  toast,
  treatmentHeight,
} from 'components/common'
import { DraggableList } from 'components/common/DraggableList'
import { Status } from 'components/common/Form/Status'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { DepartmentsSection } from 'components/Template/DepartmentsSection'
import { OwnerSection } from 'components/Template/OwnerSection'
import { WorkflowTemplateSection } from 'components/Template/WorkflowTemplateSection'
import { Fonts } from 'constants/Fonts'
import {
  DRUGS_TEMPLATE_NAME,
  templateValidName,
  treatmentTemplateValidName,
} from 'constants/Templates'
import { cloneDeep, isEqual, isNil, noop, pick, set } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { Center } from 'src/components/common/Center'
import { Colors } from 'src/constants/Colors'
import { useOrganisation } from 'src/context/organisation'
import { useAssociatedSiteIds } from 'src/hooks/useAssociatedSiteIds'
import { useTreatmentFrequencies } from 'src/hooks/useTreatmentFrequencies'
import {
  getActiveTemplates,
  getActiveTemplatesVariables,
} from 'src/types/getActiveTemplates'
import {
  getTemplateWithProducts_getTemplate,
  getTemplateWithProducts_getTemplate_treatments_items_treatments_items as TemplateTreatment,
  getTemplateWithProducts_getTemplate_treatments_items_treatments_items_treatment_options_products as OptionsProducts,
} from 'src/types/getTemplateWithProducts'
import { veterinary_roles } from 'src/types/globalTypes'
import { filterNull } from 'src/utils/notEmpty'
import * as Yup from 'yup'

import { AddTemplateTreatmentScreen } from './AddTemplateTreatmentScreen'
import { EditTemplateTreatmentScreen } from './EditTemplateTreatmentScreen'
import { GET_ACTIVE_TREATMENT_TEMPLATES } from './graphql'
import { TemplateTreatmentItem } from './TemplateTreatmentItem'
import { TreatmentTemplate } from './types'
import {
  extractGroupedTreatmentInOrderedList,
  groupTemplateTreatments,
  TemplateTreatmentsInGroup,
} from './utils/templateTreatmentsUtils'

// TODO (Aug 2022) - After no users are using the old values, run a conversion on the Db then clean up here.
export enum TemplateUseCase {
  // Legacy Values
  EMERGENCY = 'EMERGENCY',
  BILLING = 'BILLING',
  NORMAL = 'NORMAL',
  // Updated Values
  ANAESTHESIA = 'ANAESTHESIA',
  HOSPITAL = 'HOSPITAL',
}

export type Inputs = {
  name: string
  description: string
  user_id?: string | null
  sites?: string[] | null
  disabled: boolean
  use_case: string
  template_workflow_id?: string | null
}

type TemplateFormProps = {
  handleSubmit: (
    data: Inputs,
    treatmentList?: TreatmentTemplate[],
  ) => Promise<void>
  submitting: boolean
  template?: getTemplateWithProducts_getTemplate | null
}

const defaultValues = {
  name: '',
  description: '',
  user_id: null,
  sites: null,
  disabled: false,
  use_case: TemplateUseCase.NORMAL,
  template_workflow_id: null,
}

export type Values = typeof defaultValues

export type TemplateTreatmentWithAvailable = TemplateTreatment & {
  isAvailable: boolean
}

export const TemplateForm: React.FC<TemplateFormProps> = ({
  handleSubmit,
  submitting,
  template,
}) => {
  const isNewTemplate = !template
  const templateId = template?.id
  const { t } = useTranslation()
  const [isAddDrawerVisible, setIsAddDrawerVisible] = useState(false)
  const toggleAddDrawer = () => setIsAddDrawerVisible(isVisible => !isVisible)
  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false)
  const toggleEditDrawer = () => setIsEditDrawerVisible(isVisible => !isVisible)
  const [curTemplateTreatmentId, setCurTemplateTreatmentId] = useState('')
  const [hasUnavailableProducts, setHasUnavailableProducts] = useState(false)

  const [{ organisationId }] = useOrganisation()
  const { data: templatesData } = useQuery<
    getActiveTemplates,
    getActiveTemplatesVariables
  >(GET_ACTIVE_TREATMENT_TEMPLATES, {
    variables: { organisation_id: organisationId },
    onError: err => {
      toast.error(err.message)
    },
  })
  const { queryResult } = useTreatmentFrequencies()
  const { data } = queryResult
  const treatmentFrequencies = data?.getTreatmentFrequencies.items

  const emergencyDrugsTemplate = (
    templatesData?.getTemplates?.items ?? []
  ).find(({ name }) => name === DRUGS_TEMPLATE_NAME)

  const isEditingEmergencyDrugTemplate =
    template?.id === emergencyDrugsTemplate?.id || isNil(emergencyDrugsTemplate)

  const useCaseOptions = [
    {
      text: t('template:form.anaesthesia'),
      value: TemplateUseCase.ANAESTHESIA,
    },
    {
      text: t('template:form.hospital'),
      value: TemplateUseCase.HOSPITAL,
    },
  ]

  const initialValues = useMemo((): Inputs => {
    if (templateId) {
      const oldTemplateFields = pick(template, [
        'description',
        'disabled',
        'id',
        'name',
        'organisation_id',
        'sites',
        'use_case',
        'user_id',
        'template_workflow_id',
      ])

      const convertLegacyUseCase = (
        useCase?: TemplateUseCase | null,
      ): TemplateUseCase => {
        switch (useCase) {
          case TemplateUseCase.BILLING:
          case TemplateUseCase.EMERGENCY:
            return TemplateUseCase.ANAESTHESIA
          case TemplateUseCase.NORMAL:
            return TemplateUseCase.HOSPITAL
          default:
            return useCase ?? defaultValues.use_case
        }
      }

      return {
        ...defaultValues,
        ...(oldTemplateFields as Inputs),
        use_case: convertLegacyUseCase(
          oldTemplateFields.use_case as TemplateUseCase,
        ),
        user_id: template?.user?.id,
        sites: filterNull(template?.sites?.map(i => i?.id) ?? []),
      }
    }
    return defaultValues
  }, [templateId, template])

  const validationSchema = useMemo(
    () =>
      yupResolver(
        Yup.object().shape({
          name:
            // Allow the name “Emergency Drugs” when editing that template
            isEditingEmergencyDrugTemplate
              ? templateValidName
              : treatmentTemplateValidName,
        }),
      ),
    [isEditingEmergencyDrugTemplate],
  )

  const {
    getValues,
    watch,
    control,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: validationSchema,
  })

  const templateSiteIds = watch('sites')

  const { getIsProductAvailable } = useAssociatedSiteIds(templateSiteIds)

  const getAllProductsAvailable = useCallback(
    (products?: null | OptionsProducts[]) => {
      if (!products) {
        return true
      }
      return products?.every(p => getIsProductAvailable(p.sites))
    },
    [getIsProductAvailable],
  )
  const {
    groupedTemplateTreatments: initGroupedTreatments,
    templateTreatments,
  } = useMemo(() => groupTemplateTreatments(template), [template])

  const [groupedTreatments, setGroupedTreatments] = useState<
    TemplateTreatmentsInGroup[]
  >([])

  useEffect(() => {
    setGroupedTreatments(initGroupedTreatments)
  }, [initGroupedTreatments])

  const reorderTemplateTreatments = useCallback(
    (reorderedTemplateTreatments: TemplateTreatment[]) => {
      setGroupedTreatments(previousGroupedTreatments =>
        previousGroupedTreatments.map(newGroupedTreatment => {
          const isChangingTheOrder =
            !!newGroupedTreatment.templateTreatments.find(
              treatment =>
                treatment.id ===
                reorderedTemplateTreatments.find(
                  templateTreatment => templateTreatment.id === treatment.id,
                )?.id,
            )
          if (isChangingTheOrder) {
            return {
              ...newGroupedTreatment,
              templateTreatments: reorderedTemplateTreatments,
            }
          }
          return newGroupedTreatment
        }),
      )
    },
    [],
  )

  useEffect(() => {
    if (hasUnavailableProducts) {
      setValue('disabled', true)
      //  changing hasUnavailableProducts make template active, when it was active,
    } else if (!template?.disabled) {
      setValue('disabled', false)
    }
  }, [hasUnavailableProducts, setValue, template?.disabled])

  // Trigger an on-mount name validation, incase name changed outside form (eg. via duplicate)
  useEffect(() => {
    trigger('name')
  }, [trigger])

  const hasError = !isValid
  const goToEditTreatment = useCallback((id: string) => {
    setCurTemplateTreatmentId(id)
    toggleEditDrawer()
  }, [])

  const renderDraggableItem = useCallback(
    ({ item }: { item: TemplateTreatment }) => {
      const treatmentFrequency = treatmentFrequencies?.find(
        treatmentFrequency =>
          treatmentFrequency.id === item.schedule?.treatment_frequency_id,
      )

      // check if the product outside business unit
      const isAvailable = getAllProductsAvailable(
        item.treatment_options?.products,
      )

      if (!isAvailable) {
        setHasUnavailableProducts(true)
      }

      return (
        <Center>
          <TemplateTreatmentItem
            templateTreatment={{ ...item, isAvailable }}
            templateId={templateId}
            onPress={() => goToEditTreatment(item.id)}
            treatmentFrequency={treatmentFrequency}
          />
        </Center>
      )
    },
    [
      templateId,
      treatmentFrequencies,
      goToEditTreatment,
      getAllProductsAvailable,
    ],
  )

  const onSubmit = useCallback(async () => {
    const data = getValues()
    const groupedTreatmentsInOrder =
      extractGroupedTreatmentInOrderedList(groupedTreatments)
    const initGroupedTreatmentsInOrder = extractGroupedTreatmentInOrderedList(
      initGroupedTreatments,
    )

    // if no changes on the order, just submit the template
    if (isEqual(groupedTreatmentsInOrder, initGroupedTreatmentsInOrder)) {
      await handleSubmit(data)
      return
    }

    await handleSubmit(data, groupedTreatmentsInOrder)
  }, [getValues, groupedTreatments, handleSubmit, initGroupedTreatments])

  const handleChange = (field: keyof Inputs) => (value: any) => {
    const updatedTemplateForm = cloneDeep(initialValues)
    set(updatedTemplateForm, field, value)
  }

  const renderListHeader = () => (
    <Center>
      <FormBreak />
      <Status status={createErrorStatus(errors.name?.message, true)}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              testID="Template Form Name Text Input"
              label={t('template:form.name')}
              value={value}
              onChangeText={newVal => {
                onChange(newVal)
                handleChange('name')(newVal)
              }}
            />
          )}
          name="name"
          rules={{ required: true }}
        />
      </Status>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextInput
            label={t('template:form.desc')}
            value={value}
            onChangeText={newVal => {
              onChange(newVal)
              handleChange('description')(newVal)
            }}
          />
        )}
        name="description"
      />
      <Controller
        control={control}
        defaultValue={defaultValues.user_id}
        render={({ field: { onChange, value } }) => (
          <OwnerSection
            type={veterinary_roles.VET}
            onSelectedUserChange={onChange}
            selectedUser={value}
          />
        )}
        name="user_id"
      />
      <Controller
        control={control}
        defaultValue={defaultValues.sites}
        render={({ field: { onChange, value } }) => (
          <DepartmentsSection
            onSelectedDepartmentChange={newVal => onChange(newVal)}
            selectedDepartment={value ? value : []}
          />
        )}
        name="sites"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <SwitchInput
            label={t('template:form.active')}
            value={!value}
            onChangeValue={active => {
              onChange(!active)
              handleChange('disabled')(active)
            }}
            disabled={hasUnavailableProducts}
          />
        )}
        name="disabled"
      />
      {hasUnavailableProducts ? (
        <View style={styles.unavailableContainer}>
          <Text style={styles.unavailableText}>
            {t('template:form.templateUnavailable')}
          </Text>
        </View>
      ) : null}
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            label={t('template:form.useCase')}
            options={useCaseOptions}
            selected={value}
            onChange={newVal => {
              onChange(newVal)
              handleChange('use_case')(newVal)
            }}
          />
        )}
        name="use_case"
      />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <WorkflowTemplateSection
            selected={value}
            onChange={newVal => {
              onChange(newVal)
              handleChange('template_workflow_id')(newVal)
            }}
          />
        )}
        name="template_workflow_id"
      />
      {!isNewTemplate && (
        <>
          <FormLabel text="Template configuration" />
          <AddTreatmentButton
            title={t('addTreatment:addBtnFullTitle')}
            onPress={toggleAddDrawer}
          />
          <SheetAwareSideDrawer
            visible={isAddDrawerVisible}
            onClose={toggleAddDrawer}
            title={t('addTreatment.title')}
          >
            <AddTemplateTreatmentScreen
              templateId={templateId!}
              treatmentListLength={templateTreatments.length}
              toggleDrawer={toggleAddDrawer}
              templateSiteIds={templateSiteIds}
            />
          </SheetAwareSideDrawer>
          <SheetAwareSideDrawer
            visible={isEditDrawerVisible}
            onClose={toggleEditDrawer}
            title={t('treatment:editTreatment.title')}
          >
            <EditTemplateTreatmentScreen
              templateId={templateId!}
              templateTreatmentId={curTemplateTreatmentId}
              toggleDrawer={toggleEditDrawer}
              templateSiteIds={templateSiteIds}
            />
          </SheetAwareSideDrawer>
        </>
      )}
    </Center>
  )

  const renderListFooter = () => (
    <Button
      onPress={onSubmit}
      title={
        isNewTemplate
          ? t('general.makeTreatmentTemplate')
          : t('general.saveChanges')
      }
      loading={submitting}
      color={Colors.green}
      style={styles.button}
      disabled={hasError}
    />
  )

  const renderGroups = useCallback(
    ({ item }: { item: TemplateTreatmentsInGroup }) => (
      <DraggableList
        key={item.id}
        items={item.templateTreatments}
        renderItem={renderDraggableItem}
        onMoveEnd={reorderTemplateTreatments}
        // items in each group is draggable for reordering. but the group itself is not draggable
        isNestedDraggable={true}
        ListHeaderComponent={
          <Center>
            <View style={styles.centerGroup}>
              <Text style={styles.centerGroupName}>{item.name}</Text>
            </View>
          </Center>
        }
      />
    ),
    [renderDraggableItem, reorderTemplateTreatments],
  )

  return (
    <DraggableList
      items={groupedTreatments}
      renderItem={renderGroups}
      // cannot reorder the group, but need the draggable list style for children items reordering
      onMoveEnd={noop}
      isDragDisabled={true}
      ListHeaderComponent={renderListHeader()}
      ListFooterComponent={renderListFooter()}
    />
  )
}

const styles = StyleSheet.create({
  button: {
    marginVertical: 16,
  },
  unavailableContainer: {
    paddingBottom: 14,
    paddingTop: 7,
    paddingHorizontal: 3,
  },
  unavailableText: { color: Colors.error },
  centerGroup: {
    backgroundColor: Colors.lightPurple,
    height: treatmentHeight,
    width: 'auto',
    justifyContent: 'center',
  },
  centerGroupName: {
    fontSize: 16,
    paddingLeft: 16,
    color: Colors.contentPrimary,
    fontFamily: Fonts.bold,
  },
})
