import React from 'react'
import { SheetPatientInfoFields } from 'types/SheetPatientInfoFields'
import { StyleSheet, Text, View } from 'react-native'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'

type Props = {
  sheetPatientInfo: SheetPatientInfoFields
}

export const AnesthesiaNotes: React.FunctionComponent<Props> = ({
  sheetPatientInfo,
}) => {
  const sheetMetaData = sheetPatientInfo?.type_meta_data
  const anaesthesiaDetails = sheetMetaData?.anaesthesia
  const complications = anaesthesiaDetails?.complications

  return (
    <>
      {complications && (
        <View style={styles.complicationsContainer}>
          {complications.map((complication, idx) => {
            const isLast = complications.length === idx + 1
            return (
              <View style={[styles.pill]} key={complication}>
                <Text
                  style={[
                    styles.pillText,
                    isLast && styles.lastComplicationText,
                  ]}
                >
                  {complication}
                </Text>
              </View>
            )
          })}
        </View>
      )}
      <View style={styles.comments}>
        <Text style={styles.text}>{anaesthesiaDetails?.comments}</Text>
      </View>
      {sheetMetaData?.asa && (
        <View style={styles.asaContainer}>
          <Text style={[styles.text, styles.asaTitle]}>ASA</Text>
          <View style={[styles.pill, styles.asaPill]}>
            <Text style={styles.pillText}>{sheetMetaData?.asa}</Text>
          </View>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  complicationsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  pill: {
    height: 27,
    borderRadius: 14,
    backgroundColor: Colors.lightRed,
    justifyContent: 'center',
    marginTop: 4,
  },
  pillText: {
    fontFamily: Fonts.bold,
    color: Colors.white,
    paddingVertical: 4,
    paddingHorizontal: 8,
    justifyContent: 'center',
  },
  text: {
    fontFamily: Fonts.regular,
  },
  comments: {
    marginVertical: 4,
  },
  lastComplicationText: {
    paddingBottom: 0,
  },
  asaContainer: {
    flexDirection: 'row',
    marginTop: 4,
    alignItems: 'center',
  },
  asaTitle: {
    marginRight: 8,
  },
  asaPill: { marginTop: 0 },
})
