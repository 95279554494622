import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  color?: string
  width?: number
  height?: number
}

export const SvgCheckMark: React.FC<Props> = React.memo(
  ({ color = '#1B5E20', width = 32, height = 32 }) => (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 448 512"
      fill="none"
      testID="SvgCheckMark"
    >
      <Path
        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
        fill={color}
      />
    </Svg>
  ),
)
