import React from 'react'
import { Colors } from 'constants/Colors'
import {
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'

type Props = {
  isFirstTask?: boolean
  isLastTask?: boolean
  isFluidStarted?: boolean
  isFluidEnded?: boolean
  onPress?: (event: GestureResponderEvent) => void
  overlapTask?: boolean
}

const EmptyTriangle: React.FC = React.memo(() => {
  return (
    <View style={styles.triangleWrapper}>
      <View accessibilityLabel="End of fluid task" style={styles.triangle} />
      <View
        accessibilityLabel="End of fluid task empty"
        style={styles.whiteSmallTriangle}
      />
    </View>
  )
})

const FluidStartShape = React.memo(
  ({
    isFirstTask,
    isFluidStarted,
    overlapTask,
  }: {
    isFirstTask: boolean
    isFluidStarted: boolean
    overlapTask: boolean
  }) => {
    if (isFirstTask) {
      return (
        <View
          accessibilityLabel="Start of fluid task"
          style={[styles.oval, isFluidStarted && styles.ovalDone]}
        />
      )
    }

    if (isFluidStarted) {
      return (
        <View style={styles.triangleWrapper}>
          <View
            accessibilityLabel="End of fluid task"
            style={[styles.triangle, overlapTask && styles.overlapTriangle]}
          />
        </View>
      )
    }

    return <EmptyTriangle />
  },
)
FluidStartShape.displayName = 'FluidStartShape'

export const FluidTask: React.FC<Props> = ({
  isFluidStarted,
  isFluidEnded,
  onPress,
  isLastTask = true,
  isFirstTask = true,
  overlapTask = false,
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.fluidLineContainer}>
      <View pointerEvents="none" style={styles.spaceAroundRow}>
        <FluidStartShape
          isFluidStarted={!!isFluidStarted}
          isFirstTask={isFirstTask}
          overlapTask={overlapTask}
        />
        <View style={styles.fluidLine} />
        <View
          accessibilityLabel="End of fluid task"
          // eslint-disable-next-line react/jsx-no-leaked-render
          style={isLastTask && [styles.oval, isFluidEnded && styles.ovalDone]}
        />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  fluidLine: {
    marginHorizontal: 0.1,
    flex: 1,
    height: 3,
    backgroundColor: Colors.teal,
  },
  fluidLineContainer: {
    flex: 1,
    overflow: 'visible',
  },
  oval: {
    width: 20,
    height: 20,
    borderColor: Colors.teal,
    borderWidth: 3,
    borderRadius: 12,
  },
  triangleWrapper: {
    width: 24,
    height: 32,
    marginRight: -12,
    marginTop: -12,
    zIndex: 2,
    position: 'relative',
  },
  triangle: {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    borderColor: 'transparent',
    borderWidth: 12,
    borderBottomColor: Colors.orange,
    borderBottomWidth: 20,
    zIndex: 10,
    position: 'absolute',
  },
  overlapTriangle: {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    borderColor: 'transparent',
    borderWidth: 11,
    borderBottomColor: Colors.lightOrange,
    borderBottomWidth: 18,
    zIndex: 10,
    position: 'absolute',
  },
  whiteSmallTriangle: {
    left: 4,
    top: 8,
    width: 0,
    height: 0,
    borderColor: 'transparent',
    borderWidth: 8,
    borderBottomColor: Colors.white,
    borderBottomWidth: 14,
    zIndex: 11,
    position: 'absolute',
  },
  ovalDone: {
    backgroundColor: Colors.teal,
  },
  spaceAroundRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'visible',
  },
})
