import React, { useMemo } from 'react'
import { CheckButton, ChoiceRow, Input, Select } from 'components/shared'
import { getTaskUnit } from 'components/Task/utils/getTaskUnit'
import { getTreatmentInfo } from 'components/Task/utils/getTreatmentInfo'
import { addMinutes, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { KeyboardTypeOptions, StyleSheet, TextInput, View } from 'react-native'

import { SubTreatmentAndFirstTask } from 'components/Treatment/utils/getSubTreatmentsNearestFirstTasks'
import {
  isCompleteOrSkipTab,
  isDeletedTab,
  isRescheduleTab,
  isSkipTab,
} from './utils/getTabStatus'
import { HandleChange } from './BulkTaskList'
import { TabName } from './common'
import { isNumber } from 'lodash'
import { PermissionCheckButton } from 'components/common/Permission'
import { PermissionAction, Status } from 'types/globalTypes'
import { getProductIsHealthStatusWeight } from 'components/Task/utils/getProductInfo'
import { regexToNumber } from 'components/Treatment/utils/allowNumberAndOnlyOneDot'
import { useApprovals } from 'src/hooks/useApprovals'
import { ApprovalRequiredTextMessage } from 'components/BulkTask/ApprovalRequiredTextMessage'
import { retrieveVitalProductOptions } from 'src/utils/retrieveVitalProductOptions'

const showRescheduleToTime = (
  startAt: string | null,
  dateOrRescheduleMinutes: Date | number,
) => {
  if (!startAt) return ''
  let newStartDate: Date
  if (isNumber(dateOrRescheduleMinutes)) {
    newStartDate = addMinutes(new Date(startAt), dateOrRescheduleMinutes)
  } else {
    newStartDate = dateOrRescheduleMinutes
  }
  return format(newStartDate, 'MMM dd HH:mm')
}

type InputProps = {
  subTreatmentAndFirstTask: SubTreatmentAndFirstTask
  idx: number
  autoFocus: boolean
  keyboardType: KeyboardTypeOptions
  currentTab: TabName
  dateOrRescheduleMinutes: Date | number
  patientWeightUnit?: string | null
  taskInputRefs: React.MutableRefObject<
    Record<
      number,
      {
        input: TextInput | HTMLElement
        notes?: TextInput | HTMLElement | undefined
      }
    >
  >
  handleChange: HandleChange
}

export const BulkTaskListItem: React.FC<InputProps> = ({
  subTreatmentAndFirstTask,
  idx,
  autoFocus,
  currentTab,
  taskInputRefs,
  patientWeightUnit,
  dateOrRescheduleMinutes,
  keyboardType,
  handleChange,
}) => {
  const {
    checked,
    nextTaskToComplete,
    treatment,
    completeChecked,
    deleteChecked,
  } = subTreatmentAndFirstTask

  const { t } = useTranslation()
  const isSkip = isSkipTab(currentTab)
  const isReschedule = isRescheduleTab(currentTab)
  const isCompleteOrSkip = isCompleteOrSkipTab(currentTab)
  const isDelete = isDeletedTab(currentTab)
  const isCheckedAndReschedule = checked && isReschedule

  const { isTreatmentPending } = useApprovals()

  const shouldShowRequiredApproval =
    !!treatment && isTreatmentPending(treatment)

  // rescheduleToTime should show user when isCheckedAndReschedule
  const rescheduleToTime = isCheckedAndReschedule
    ? showRescheduleToTime(nextTaskToComplete.start_at, dateOrRescheduleMinutes)
    : null

  const {
    customValueOptions,
    hasCustomValues,
    isHealthStatusProduct,
    isObservation,
    isNotesRequired,
    isValueRequired,
  } = getTreatmentInfo(treatment)

  const treatmentProduct = treatment?.product

  const customProductOptions = retrieveVitalProductOptions(
    customValueOptions,
    isHealthStatusProduct,
    treatmentProduct?.origin_id,
  )

  // change the value to non-empty string to check the checkbox remove value to un-check.
  const handleChangeRow = (updatedValue: string) => {
    handleChange(`${nextTaskToComplete.id}.completeChecked`, !!updatedValue)
    if (getProductIsHealthStatusWeight(treatment?.product)) {
      const revertedValue = regexToNumber(updatedValue)
      handleChange(
        `${nextTaskToComplete.id}.nextTaskToComplete.value`,
        revertedValue,
      )
      return
    }
    handleChange(
      `${nextTaskToComplete.id}.nextTaskToComplete.value`,
      updatedValue,
    )
  }

  const handleBulkWeightValueChange = (val: string) => {
    if (val.endsWith('.')) {
      const splitVal = val.split('.')
      if (!splitVal[1])
        handleChange(
          `${nextTaskToComplete.id}.nextTaskToComplete.value`,
          splitVal[0],
        )
    }
  }

  const handleNotes = (note: string) => {
    handleChange(`${nextTaskToComplete.id}.completeChecked`, !!note)
    handleChange(`${nextTaskToComplete.id}.nextTaskToComplete.notes`, note)
  }

  const handleChecked = (checked: boolean) => {
    let fieldChange: string = `${nextTaskToComplete.id}.checked`

    if (isDelete) {
      fieldChange = `${nextTaskToComplete.id}.deleteChecked`
    }

    if (isCompleteOrSkip) {
      fieldChange = `${nextTaskToComplete.id}.completeChecked`
    }

    handleChange(fieldChange, checked)
  }

  const shouldShowChecked = useMemo(() => {
    if (isDelete) {
      return deleteChecked ?? false
    }

    if (isCompleteOrSkip) {
      return completeChecked ?? false
    }

    return checked
  }, [checked, completeChecked, deleteChecked, isCompleteOrSkip, isDelete])

  const handleNextFocus = (type: 'notes' | 'input') => () => {
    const nextNotes = taskInputRefs.current[idx]?.notes
    const nextInput = taskInputRefs.current[idx + 1]?.input

    if (nextNotes && isNotesRequired && type !== 'notes') {
      nextNotes.focus()
    } else if (nextInput) {
      nextInput.focus()
    }
  }

  const taskUnit = getTaskUnit({
    patientWeightUnit,
    dosageInfo: treatment?.medicine_dosage_info,
    product: treatment.product,
    task: nextTaskToComplete,
  })

  if (!!isCompleteOrSkip || !!isSkip) {
    return (
      <View
        key={treatment.id}
        style={styles.choices}
        accessibilityLabel={`Complete: ${treatment.name}`}
      >
        <CheckButton
          onChange={handleChecked}
          checked={shouldShowChecked}
          style={styles.checkButtonComplete}
          disabled={shouldShowRequiredApproval}
        />
        <View style={styles.completeContainer}>
          {isHealthStatusProduct || hasCustomValues ? (
            <Select
              label={treatment.name}
              {...(nextTaskToComplete.start_at && {
                rightLabel: format(
                  new Date(nextTaskToComplete.start_at),
                  'MMM dd HH:mm',
                ),
              })}
              required={isValueRequired}
              a11yLabel={`Options: ${treatment.name}`}
              allowClear={true}
              onChange={handleChangeRow}
              options={customProductOptions}
              placeholder={t('task:taskTransition.form.valueLabel')}
              selected={nextTaskToComplete.value}
              disabled={shouldShowRequiredApproval}
              fRef={el => {
                if (el)
                  taskInputRefs.current[idx] = {
                    ...taskInputRefs.current[idx],
                    input: el,
                  }
              }}
            />
          ) : (
            <Input
              accessibilityLabel={`Value: ${treatment.name}`}
              keyboardType={keyboardType}
              label={treatment.name}
              {...(nextTaskToComplete.start_at && {
                rightLabel: format(
                  new Date(nextTaskToComplete.start_at),
                  'MMM dd HH:mm',
                ),
              })}
              {...(taskUnit && {
                suffix: taskUnit,
              })}
              onChange={handleChangeRow}
              ref={el => {
                if (el)
                  taskInputRefs.current[idx] = {
                    ...taskInputRefs.current[idx],
                    input: el,
                  }
              }}
              onSubmitEditing={handleNextFocus('input')}
              returnKeyType="next"
              value={nextTaskToComplete.value ?? ''}
              autoFocus={autoFocus}
              numberOfLines={1}
              required={isValueRequired}
              onBlur={
                getProductIsHealthStatusWeight(treatment?.product)
                  ? () =>
                      handleBulkWeightValueChange(
                        nextTaskToComplete.value ?? '',
                      )
                  : undefined
              }
              textInputDisabledGreyOut={shouldShowRequiredApproval}
              textInputDisabled={shouldShowRequiredApproval}
            />
          )}
          {isNotesRequired && !isObservation ? (
            <Input
              accessibilityLabel={`Notes: ${treatment.name}`}
              keyboardType={keyboardType}
              label={t('task:taskTransition:form:notes')}
              onChange={handleNotes}
              onSubmitEditing={handleNextFocus('notes')}
              ref={el => {
                if (el)
                  taskInputRefs.current[idx] = {
                    ...taskInputRefs.current[idx],
                    notes: el,
                  }
              }}
              returnKeyType="next"
              value={nextTaskToComplete.notes ?? ''}
              numberOfLines={2}
              textInputDisabledGreyOut={shouldShowRequiredApproval}
              textInputDisabled={shouldShowRequiredApproval}
              required
            />
          ) : null}
          {shouldShowRequiredApproval ? <ApprovalRequiredTextMessage /> : null}
        </View>
      </View>
    )
  }

  return (
    <View
      key={treatment.id}
      style={styles.choices}
      accessibilityLabel={`Complete: ${treatment.name}`}
    >
      <PermissionCheckButton
        permissionAction={
          isDelete &&
          subTreatmentAndFirstTask?.nextTaskToComplete?.status === Status.MISSED
            ? PermissionAction.TASK__DELETE_MISSED
            : null
        }
        onChange={handleChecked}
        checked={shouldShowChecked}
        style={styles.checkButton}
      />
      <ChoiceRow
        treatmentName={treatment.name}
        time={
          !!nextTaskToComplete.start_at
            ? format(new Date(nextTaskToComplete.start_at), 'MMM dd HH:mm')
            : null
        }
        rescheduleToTime={rescheduleToTime}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  checkButtonComplete: {
    marginTop: 28,
    marginRight: 15,
  },
  checkButton: {
    marginTop: 8,
    marginRight: 15,
  },
  completeContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
  },
  choices: {
    flexDirection: 'row',
    flex: 0,
    flexBasis: 'auto',
    marginVertical: 4,
    alignItems: 'flex-start',
  },
})
