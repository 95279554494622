import { noop } from 'lodash'
import { ToastType, toast } from 'components/common'
import { ResetStatus } from 'src/types/globalTypes'

const resetStatusToastSettingMap: {
  [key in ResetStatus]: {
    action: ToastType | null
    message: string | null
  }
} = {
  [ResetStatus.STARTED]: {
    action: ToastType.PROCESS,
    message:
      'Training mode data reset is in progress, this can take a few minutes…',
  },
  [ResetStatus.STARTED_ALREADY]: {
    action: ToastType.ERROR,
    message:
      'Training mode data reset has already been started by another user',
  },
  [ResetStatus.SUCCESS]: {
    action: ToastType.SUCCESS,
    message: 'Training mode data reset complete!',
  },
  [ResetStatus.FAIL]: {
    action: ToastType.ERROR,
    message: '',
  },
  [ResetStatus.INITIAL]: {
    action: null, // when resetStatus is INITIAL, no toast is sent
    message: null,
  },
}

export const useResetStatusToastSender = () => {
  return (
    resetStatus: ResetStatus,
    serverErrorMessage: string,
  ): (() => void) | null => {
    const toastSetting = resetStatusToastSettingMap[resetStatus]
    const { action, message } = toastSetting
    // when resetStatus is FAIL then message is empty, but serverErrorMessage is not empty
    const finalMessage = message || serverErrorMessage
    if (action) {
      return toast[action](finalMessage) as (() => void) | null
    }
    return noop
  }
}
