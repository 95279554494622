import { SyncStorage } from 'src/utils/SyncStorage'
import { userOrganisationService } from './userOrganisationService'

export interface CheckAuthType {
  isAuthV2: boolean
}

export interface AuthType extends CheckAuthType {
  organisationId: string
}

const LOCAL_STORAGE_KEY = 'apc_auth_type'

export const authTypeService = {
  // Sets the authType in SyncStorage with organisationId as the key.
  setAuthType(authType: AuthType): void {
    const storedData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    const data = storedData ? JSON.parse(storedData) : {}
    data[authType.organisationId] = authType
    SyncStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
  },

  // Retrieves the authType.isAuthV2 from SyncStorage using organisationId as the key.
  getIsAuthV2(): boolean {
    const organisationId = userOrganisationService.getOrganisationId()
    const storedData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    if (storedData) {
      const data = JSON.parse(storedData)
      return !!data[organisationId]?.isAuthV2
    }
    return false
  },

  // Deletes the authType from SyncStorage using organisationId as the key.
  deleteAuthType(organisationId: string): void {
    const storedData = SyncStorage.getItem(LOCAL_STORAGE_KEY)
    if (storedData) {
      const data = JSON.parse(storedData)
      delete data[organisationId]
      SyncStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
    }
  },
}
