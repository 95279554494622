import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ProductName } from 'components/AddTreatment/ProductName'
import { useGetSheetType } from 'components/Sheet/useGetSheetType'
import {
  FormData,
  Props as AddTreatmentFormProps,
} from 'components/TreatmentForm'
import { RepeatScheduleValue } from 'components/TreatmentForm/data'
import { groupBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { usePatientById } from 'src/hooks/usePatient'
import { getConditionalTreatment_getConditionalTreatment } from 'src/types/getConditionalTreatment'
import { ProductType, SheetType } from 'src/types/globalTypes'
import {
  BulkCreateTreatmentsRawInput,
  useBulkCreateTreatments,
} from 'src/utils/useBulkCreateTreatments'

import { Product } from '../TreatmentSetup/SelectProductsScreen'
import { FormContainer } from './FormContainer'
import { getConditionalScheduleFormData } from './utils/getConditionalScheduleFormData'
import { useUpdateConditionalTreatment } from './utils/useUpdateConditionalTreatment'
import { WeightUnit } from 'constants/Weight'

type Props = {
  patientId: string
  sheetId: string
  selectedProducts: Product[]
  conditionalTreatment: getConditionalTreatment_getConditionalTreatment
  isSingleOption: boolean
  onDone: () => void
  deleteTreatment: () => void
  isDeleteTreatmentSelected: boolean
}

const convertProductToProductInfo = (productToCreate: Product) => ({
  productGroupName: productToCreate.group.name,
  productGroupId: productToCreate.group.id,
  productGroupOrder: productToCreate.group.order,
  productId: productToCreate.id,
  productName: productToCreate.name,
  productType: productToCreate.type as ProductType,
})

export const SetupConditionalFieldsScreen: React.FC<Props> = ({
  patientId,
  sheetId,
  selectedProducts,
  conditionalTreatment,
  isSingleOption,
  onDone,
  deleteTreatment,
  isDeleteTreatmentSelected,
}) => {
  const { t } = useTranslation()

  const patient = usePatientById(patientId)
  const bulkCreateTreatments = useBulkCreateTreatments(
    sheetId,
    patientId,
    conditionalTreatment.id,
  )

  const updateConditionalTreatment = useUpdateConditionalTreatment({
    patientId,
    sheetId,
    treatmentId: conditionalTreatment.id,
  })

  const productsGroup: {
    general?: Product[]
    individual?: Product[]
  } = useMemo(
    () =>
      groupBy(selectedProducts, productToCreate => {
        const isGeneral =
          productToCreate.type !== ProductType.MEDICATION &&
          productToCreate.type !== ProductType.IVFLUIDS
        return isGeneral ? 'general' : 'individual'
      }),
    [selectedProducts],
  )
  // Array of products in a sequence of forms to complete
  const formsProduct: Product[] = useMemo(
    () => [
      // General products to be grouped together under one product
      ...(productsGroup.general?.[0] ? [productsGroup.general[0]] : []),
      // Medicine or fluid products to have individual dosage&schedule form
      ...(productsGroup.individual ?? []),
    ],
    [productsGroup],
  )
  const formsLength = formsProduct.length
  const formsDataRef = useRef([] as FormData[])

  const [curFormIdx, setCurFormIdx] = useState(0)

  const sheetType = useGetSheetType(sheetId)

  const defaultOverrides = useMemo(() => {
    const {
      schedule,
      created_at,
      instructions,
      medicine_dosage_info,
      is_billable,
      is_auto_completed,
    } = conditionalTreatment
    const scheduleFormData = getConditionalScheduleFormData(
      schedule,
      created_at,
      isSingleOption,
    )
    const dosageInfoDefaults = isSingleOption
      ? {
          dosageRate: medicine_dosage_info?.dosage,
          dosageWeightUnit:
            medicine_dosage_info?.dosage_weight_unit ?? WeightUnit.MG,
          dosagePerWeightUnit:
            medicine_dosage_info?.dosage_patient_weight_unit ?? WeightUnit.KG,
        }
      : {}

    return {
      ...scheduleFormData,
      ...dosageInfoDefaults,
      sheetType,
      instructions: instructions ?? '',

      isBillable: is_billable,
      isAutoCompleted: !!is_auto_completed,
    } as AddTreatmentFormProps['defaultOverrides']
  }, [conditionalTreatment, isSingleOption, sheetType])

  // If the current sheet type is anaesthesia and selected production is a multi-selected treatment.
  // Then check the current treatment type, if the type is not medication or iv fluids,
  // push the empty data input to formData for skipping creating edit page.
  useEffect(() => {
    if (sheetType === SheetType.ANAESTHESIA && !isSingleOption) {
      let newIdx = curFormIdx

      formsProduct.forEach(item => {
        const type = item?.type
        const isGeneral =
          type !== ProductType.MEDICATION && type !== ProductType.IVFLUIDS
        if (isGeneral) {
          const generalInputs = {
            ...(defaultOverrides as FormData),
            isRepeating: RepeatScheduleValue.NONE,
          }
          formsDataRef.current.push(generalInputs)
          newIdx = newIdx + 1
        }
      })

      setCurFormIdx(newIdx)
      if (newIdx <= formsLength - 1) return

      saveAndCreateTreatment().then(onDone)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetType, isSingleOption])

  const onSave = async (formData: FormData) => {
    formsDataRef.current.push(formData)

    // only call save on the last form
    if (curFormIdx < formsLength - 1) {
      return setCurFormIdx(curFormIdx + 1)
    }

    if (isSingleOption) {
      saveAndUpdateTreatment()
    } else {
      await saveAndCreateTreatment()
    }
    onDone()
  }

  const saveAndUpdateTreatment = () => {
    const formData = formsDataRef.current.shift()
    const formProduct = selectedProducts[0]
    if (!formData || !formProduct) return
    updateConditionalTreatment(formData, formProduct)
  }

  const saveAndCreateTreatment = async () => {
    const bulkCreateTreatmentsRawInputs: BulkCreateTreatmentsRawInput[] = []
    const { general, individual } = productsGroup
    if (general) {
      const formData = formsDataRef.current.shift()
      if (!formData) {
        return
      }
      general.forEach(productToCreate => {
        bulkCreateTreatmentsRawInputs.push({
          formData,
          product: convertProductToProductInfo(productToCreate),
          options: {
            createTasks: formData.isRepeating !== RepeatScheduleValue.NONE,
          },
        })
      })
    }

    if (individual) {
      individual.forEach(productToCreate => {
        const formData = formsDataRef.current.shift()
        if (!formData) {
          return
        }
        bulkCreateTreatmentsRawInputs.push({
          formData,
          product: convertProductToProductInfo(productToCreate),
          options: {
            createTasks: formData.isRepeating !== RepeatScheduleValue.NONE,
          },
        })
      })
    }
    await bulkCreateTreatments(bulkCreateTreatmentsRawInputs)
    if (isDeleteTreatmentSelected) {
      deleteTreatment()
      onDone()
    }
  }

  let treatmentName = formsProduct[curFormIdx]?.name ?? ''
  let shouldDisableGeneralAutoCompleted = false

  if (curFormIdx === 0 && productsGroup.general) {
    treatmentName = productsGroup.general.map(e => e.name).join(' - ') ?? ''
    // disable auto_complete when the conditional template treatment is not auto_completed
    shouldDisableGeneralAutoCompleted = !conditionalTreatment.is_auto_completed
  }

  const product = formsProduct[curFormIdx]

  if (!patient || !product) {
    return null
  }

  return (
    <>
      <ProductName productName={treatmentName} />
      <FormContainer
        // Add a key here to make sure it get a newly created form when clicking next
        key={curFormIdx}
        defaultOverrides={defaultOverrides}
        onSave={onSave}
        patient={patient}
        product={product}
        submitTitle={
          formsLength === curFormIdx + 1
            ? t('sheets.setup.finish')
            : t('sheets.setup.next')
        }
        deleteTreatment={isSingleOption ? deleteTreatment : undefined}
        shouldDisableGeneralAutoCompleted={shouldDisableGeneralAutoCompleted}
      />
    </>
  )
}
