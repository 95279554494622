import { encode as btoa } from 'base-64'

import { settings } from '../vetradar-exports'

const { imageBucketUrl, fileBucketUrl: bucket } = settings

export const keyToUrl = (key: string | null | undefined, edits = {}) => {
  if (!key) {
    return ''
  }
  const imageRequest = JSON.stringify({
    bucket,
    key,
    edits,
  })
  const url = `https://${imageBucketUrl}/${btoa(imageRequest)}`

  return url
}
