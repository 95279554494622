import {
  local_toggle_type,
  local_toggle_key,
} from 'constants/LocalToggleOptions'
import { isNil } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Storage } from 'src/utils/Storage'

type Props = {
  prefix: string | null
  type?: string
  key?: string
  initialState?: boolean
}

export const useLocalToggle = ({
  prefix,
  type = local_toggle_type.SHOWMORE,
  key,
  initialState = true,
}: Props) => {
  const storageId = `${type}${isNil(prefix) ? '' : `:${prefix}`}`
  const storage = useMemo(() => new Storage(storageId), [storageId])
  const [isTrue, _setIsTrue] = useState(initialState)
  const [localKey, _setLocalKey] = useState(key ?? '')

  useEffect(() => {
    switch (type) {
      case local_toggle_type.SHOWMORE:
        _setLocalKey(local_toggle_key.IS_SHOW_MORE_DETAILS_KEY)
        break
      case local_toggle_type.SHOWWORKFLOW:
        _setLocalKey(local_toggle_key.IS_SHOW_WORKFLOW_KEY)
        break
      case local_toggle_type.SHOWDRAWER:
        _setLocalKey(local_toggle_key.SHOULD_SHOW_DRAWER)
        break
      case local_toggle_type.SHOWPANEL:
        break
      default:
        _setLocalKey('')
        break
    }
  }, [type])

  useEffect(() => {
    if (!isNil(storage.getItem(localKey)))
      return _setIsTrue(storage.getItem(localKey))
    if (!isNil(initialState)) {
      return _setIsTrue(initialState)
    }
    return _setIsTrue(true)
  }, [initialState, localKey, storage])

  const setIsTrue = useCallback(
    (value: boolean, key?: string) => {
      if (key) {
        _setLocalKey(key)
      }
      storage.setItem(localKey, value)
      _setIsTrue(value)
    },
    [localKey, storage],
  )

  return [isTrue, setIsTrue] as const
}
