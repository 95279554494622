import React, { useMemo } from 'react'
import { SvgArrow } from 'components/Icons/Arrow'
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native'
import { Colors } from 'constants/Colors'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { Fonts } from 'constants/Fonts'

export const BackButton: React.FC<{
  label: string
  onPress: () => unknown
}> = React.memo(({ label, onPress }) => {
  const { t } = useTranslation()
  const { isExSmallScreen } = useBreakpoint()

  // Change styles depending on breakpoint
  const styles = useMemo(() => {
    if (isExSmallScreen) return stylesExtraSmall
    return stylesDefault
  }, [isExSmallScreen])

  return (
    <View style={styles.container}>
      <TouchableOpacity
        accessibilityLabel={label}
        style={styles.touchable}
        onPress={onPress}
        testID={'BackButton'}
        hitSlop={HIT_SLOP}
      >
        <SvgArrow orient="left" color={COLOR} width={13} height={13} />
        <Text style={styles.text}>{` ${t('general.back')}`}</Text>
      </TouchableOpacity>
    </View>
  )
})

const COLOR = Colors.sheet.title.blue
const HIT_SLOP = { top: 5, bottom: 5, left: 15, right: 15 }

const stylesDefault = StyleSheet.create({
  container: {
    marginRight: 5,
    flexDirection: 'row',
    alignSelf: 'stretch',
  },
  touchable: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'stretch',
    alignItems: 'center',
    paddingRight: 10,
    paddingVertical: 5,
  },
  text: {
    color: COLOR,
    fontSize: 16,
    paddingTop: 2,
    fontFamily: Fonts.bold,
  },
})

const stylesExtraSmall = StyleSheet.create({
  ...stylesDefault,
  container: { ...stylesDefault.container, marginBottom: 3 },
  text: { ...stylesDefault.text, fontFamily: Fonts.semibold },
})
