import React, { useCallback } from 'react'
import {
  StyleSheet,
  View,
  SafeAreaView,
  TouchableOpacity,
  Text,
} from 'react-native'
import { IVInfusionStep } from './common'
import { Colors } from 'constants/Colors'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'src/hocs/breakpoint'

type Props = {
  step: IVInfusionStep
  onStepChange: (step: IVInfusionStep) => void
  isEdit?: boolean
}

export const IVInfusionProgressButton = ({
  step,
  onStepChange,
  isEdit,
}: Props) => {
  const { t } = useTranslation()
  const { isExSmallScreen } = useBreakpoint()

  const renderButtons = useCallback(() => {
    const CalculatorButton = () => (
      <View style={styles.actionContainer}>
        <TouchableOpacity
          accessibilityLabel={IVInfusionStep.CalculatorForm}
          onPress={() => onStepChange(IVInfusionStep.CalculatorForm)}
        >
          <View
            style={[
              styles.actionButton,
              styles.actionButtonCalc,
              isExSmallScreen && styles.buttonSmallScreen,
            ]}
          >
            <Text style={styles.buttonCalculatorText}>
              {t('ivInfusionForm:goToCalculator')}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    )

    switch (step) {
      case IVInfusionStep.AddProducts:
        return (
          <View style={styles.actionsContainer}>
            <CalculatorButton />
            {!isEdit && (
              <View style={styles.actionContainer}>
                <TouchableOpacity
                  accessibilityLabel={IVInfusionStep.AddMedication}
                  onPress={() => onStepChange(IVInfusionStep.AddMedication)}
                >
                  <View
                    style={[
                      styles.actionButton,
                      isExSmallScreen && styles.buttonSmallScreen,
                    ]}
                  >
                    <Text numberOfLines={1} style={styles.buttonMedicationText}>
                      {t('ivInfusionForm:addProducts.addMedication')}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            )}
          </View>
        )
      case IVInfusionStep.AddMedication:
        return <CalculatorButton />
      default:
        return null
    }
  }, [onStepChange, step, t, isExSmallScreen, isEdit])

  return (
    <SafeAreaView>
      <View>{renderButtons()}</View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  actionButton: {
    justifyContent: 'center',
    minWidth: 100,
    elevation: 8,
    backgroundColor: Colors.white,
    borderRadius: 7,
    borderColor: Colors.blue,
    paddingVertical: 12,
    paddingHorizontal: 30,
    borderWidth: 1,
  },
  actionButtonCalc: {
    backgroundColor: Colors.blue,
  },
  buttonCalculatorText: {
    color: Colors.white,
  },
  buttonMedicationText: {
    color: Colors.blue,
  },
  buttonSmallScreen: {
    minWidth: 70,
    paddingHorizontal: 10,
  },
  actionContainer: {
    alignItems: 'center',
    paddingBottom: 36,
    paddingTop: 28,
  },
  actionsContainer: {
    borderBottomColor: Colors.lightGrey,
    borderBottomWidth: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-evenly',
  },
})
