import gql from 'graphql-tag'

export const GET_SHEET_HISTORY = gql`
  query getSheetHistory($organisation_id: ID!, $user_id: ID!) {
    getSheetHistory(organisation_id: $organisation_id, user_id: $user_id) {
      id
      name
      approval_status
      patient {
        id
        name
        avatar_url
        color
        contact {
          last_name
        }
      }
    }
  }
`

export const ADD_USER_SHEET_HISTORY = gql`
  mutation AddUserHistory($input: AddUserHistoryInput!) {
    addUserHistory(input: $input) {
      organisation_id
      user_id
      type
      value
    }
  }
`

export const GET_BULK_TASK_COUNT_BY_SHEET = gql`
  query getBulkTaskCountBySheets($organisation_id: ID!, $sheet_ids: [ID!]!) {
    getBulkTaskCountBySheets(
      organisation_id: $organisation_id
      sheet_ids: $sheet_ids
    ) {
      items {
        sheet_id
        count {
          MISSED
          DUE
        }
      }
    }
  }
`
