import { Dimensions } from 'react-native'
import { environment } from 'src/config'

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

export const Layout = {
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,
  // Define the dimensions breakpoints, copy from Bootstrap
  // Tiny devices (portrait phones, less than 576px)
  sm: 576,
  // Small devices (landscape phones, 576px and up)
  md: 768,
  // Large devices (desktops, 992px and up)
  lg: 992,
  // Extra large devices (large desktops, 1200px and up)
  xl: 1200,
  // Super extra large devices (TVs and such)
  xxl: 1800,
  // A special breakpoint
  xLarge: 1600,
}

export const SCROLL_LIST_END_REACHED_THRESHOLD = environment.isWeb ? 0.125 : 0.2

// used when you want the first item in a scroll view fixed
// https://reactnative.dev/docs/scrollview#stickyheaderindices
export const SCROLL_VIEW_FIRST_INDEX_STICKY = [0]
