import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useBulkDeleteTasks } from 'components/BulkTask/utils/useBulkDeleteTasks'
import {
  DateTimePicker,
  FormBreak,
  InputGroup,
  InputGroupText,
  NumericInput,
  Status,
  SwitchInput,
  TextArea,
  TextInput,
} from 'components/common'
import { WarningType } from 'components/common/DateTimePicker/index.types'
import { createErrorStatus } from 'components/common/TextInput/utils'
import {
  getFluidOrCRITreatmentValue,
  getFluidStyleTreatmentTimeUnit,
  getFluidStyleTreatmentVolumeUnit,
  getFluidTotalUnit,
} from 'components/EditTreatment/utils/updateFluidTreatmentUtils'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import {
  getTaskStartAtString,
  getTaskStopAtString,
} from 'components/Treatment/utils/getTaskDate'
import { filter, omit } from 'lodash'

import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { toast } from 'src/components/common/Toast/ToastArea'
import { useConfirm } from 'src/context/confirm'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { usePatientById } from 'src/hooks/usePatient'
import { useRound } from 'src/hooks/useRound'
import { getOptimisticId, isOptimisticId } from 'src/utils/optimisticId'
import {
  ApprovalAction,
  ApprovalRole,
  ApprovalStatus,
  PermissionAction,
  Status as StatusEnum,
} from 'types/globalTypes'

import { SubmitButton } from './common/SubmitButton'
import { styles } from './common/taskTransitionStyles'
import { TaskFileAttachments } from './TaskFileAttachments'
import {
  FluidAction,
  FluidTaskTransitionProps,
  GetChangeFluidStoppedAndStartTaskInput,
  GetCompleteTaskInput,
  GetEditTaskInput,
  GetStopFluidTaskInput,
  Inputs,
  Task,
} from './types'
import {
  getAllRemovableTasks,
  getLatestWorkingFluidTask,
  getNearestStoppedFluidTask,
  getNextPendingFluidTask,
} from './utils/getFluidTaskFunctions'
import {
  getNewTaskNotes,
  modifyFluidTaskNotesByAction,
} from './utils/getFluidTaskNotes'
import { getFluidTaskGivenTimes } from './utils/getTaskGivenTimes'
import { getFormValueOrTotalDosage } from './utils/getTotalPrescribedDosage'
import { getUpdateTaskInput } from './utils/getUpdateTaskInput'
import { useDeleteTask } from './utils/useDeleteTask'
import { useGetDateFormatPattern } from './utils/useGetStatusLabelText'
import { useRescheduleTask } from './utils/useRescheduleTask'
import { useResetTask } from './utils/useResetTask'
import { useScheduleFluidTask } from './utils/useScheduleFluidTask'
import { useTaskTransition } from './utils/useTaskTransition'
import { useUpdateTask } from './utils/useUpdateTask'
import { PermissionResetButton } from 'components/common/Permission'
import { useUser } from 'src/context/user'
import { useApprovals } from 'src/hooks/useApprovals'
import { useCacheTreatment } from 'components/Treatment/utils/useCacheTreatment'
import { BillingSection } from './common/BillingSection'
import { getIsTaskDoneOrProgress } from 'components/Task/utils/taskTransitionUtils'
import { attachmentInfo } from './TaskTransition'

export const FluidTaskTransitionWithoutRef: ForwardRefRenderFunction<
  FluidTaskTransitionRef,
  FluidTaskTransitionProps
> = (
  {
    action,
    isFinalized = false,
    patientId,
    task,
    toggleDialog,
    treatment,
    sheetId,
    activityLog,
    timeInGrid,
  },
  ref,
) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const patient = usePatientById(patientId)
  const deleteTask = useDeleteTask()
  const bulkDeleteTasks = useBulkDeleteTasks()
  const scheduleOrCreateFluidTask = useScheduleFluidTask()
  const { resetTask, loading } = useResetTask()

  const allTasks = useMemo(
    () =>
      filter(
        treatment?.tasks?.items ?? [],
        t => t.status !== StatusEnum.DELETED_ON_PURPOSE,
      ),
    [treatment?.tasks?.items],
  )

  const nextPendingFluidTask = useMemo(
    () => getNextPendingFluidTask(allTasks, task),
    [allTasks, task],
  )
  const round = useRound()

  const updateTask = useUpdateTask()
  const rescheduleTask = useRescheduleTask()
  const { dateFormatPattern } = useGetDateFormatPattern()
  const [isBeforeMinimum, setIsBeforeMinimum] = useState(false)

  const isSmallToLargeDisplay =
    breakpoint.isExSmallScreen ||
    breakpoint.isSmallScreen ||
    breakpoint.isMediumScreen ||
    breakpoint.isLargeButNotThatLargeScreen

  const {
    control,
    errors,
    getValues,
    handleIsBillable,
    handleQtyBilled,
    handlePhotoChange,
    handleVideoChange,
    isBackdated,
    isMedication,
    isReadOnlyAction,
    isUploadingMedia,
    isWeb,
    mustCompleteWithInitials,
    objectKeyPrefix,
    organisationId,
    patientWeightUnit,
    setIsUploadingMedia,
    shouldDisableSubmit,
    shouldShowBackDate,
    shouldShowBillableToggle,
    shouldShowUploadBtn,
  } = useTaskTransition({
    action,
    isFinalized,
    patientId,
    task,
    toggleDialog,
    treatment,
    sheetId,
    activityLog,
    timeInGrid,
    nextPendingFluidTask,
  })

  const isCreateFluid = action === FluidAction.CREATE_FLUID
  const isScheduleFluidAction = action === FluidAction.SCHEDULE_FLUID
  const isChangeRateFluid = action === FluidAction.CHANGE_FLUID_RATE
  const isEditFluid = action === FluidAction.EDIT_FLUID
  const isStartFluid = action === FluidAction.START
  const isStopFluid = action === FluidAction.STOP

  const shouldEditRate =
    isEditFluid || isScheduleFluidAction || isChangeRateFluid || isCreateFluid

  const getStartFluidInput = useCallback(
    ({ originalTask, updatedTask, datetimeGiven }: GetCompleteTaskInput) => {
      const taskInput = omit(
        {
          ...updatedTask,
          ...getFluidTaskGivenTimes({
            action,
            originalTask,
            backdateTimeGiven: datetimeGiven,
          }),
          status: StatusEnum.IN_PROGRESS,
        },
        ['value', 'unit'],
      )
      taskInput.notes = modifyFluidTaskNotesByAction(
        taskInput,
        dateFormatPattern,
        action,
      )
      return taskInput
    },
    [action, dateFormatPattern],
  )

  const getStopFluidInput = useCallback(
    ({ updatedTask, originalTask, datetimeGiven }: GetStopFluidTaskInput) => {
      const taskInput = omit(
        {
          ...updatedTask,
          ...getFluidTaskGivenTimes({
            action,
            originalTask,
            backdateTimeGiven: isBackdated ? datetimeGiven : undefined,
          }),
          status: StatusEnum.DONE,
        },
        ['value', 'unit'],
      )

      taskInput.notes = modifyFluidTaskNotesByAction(
        taskInput,
        dateFormatPattern,
        action,
      )

      return taskInput
    },
    [action, dateFormatPattern, isBackdated],
  )

  const getEditTaskInput = useCallback(
    ({ updatedTask, originalTask, value, notes }: GetEditTaskInput) => {
      const taskInput = {
        ...updatedTask,
        status: originalTask.status,
        value: originalTask.value,
      }
      const inputValue = value + getFluidTotalUnit(treatment)
      const isFluidValueChange = inputValue !== originalTask.value
      // changing fluid task value
      if (isFluidValueChange) {
        taskInput.value = inputValue
        taskInput.notes = getNewTaskNotes(originalTask.value, inputValue, notes)
      }

      return taskInput
    },
    [treatment],
  )

  const getChangeFluidStoppedAndStartTaskInput = useCallback(
    ({
      originalTask,
      notes,
      value,
      nextPendingFluidTask,
      scheduleAt,
    }: GetChangeFluidStoppedAndStartTaskInput) => {
      const stoppedTaskInput = {
        notes,
        id: originalTask.id,
        sheet_id: originalTask.sheet_id,
        patient_id: originalTask.patient_id,
        treatment_id: originalTask.treatment_id,
        organisation_id: organisationId,
        status: StatusEnum.DONE,
        given_stop_at: scheduleAt,
      }

      const startTaskInput = {
        id: nextPendingFluidTask?.id ?? getOptimisticId(),
        sheet_id: originalTask.sheet_id,
        patient_id: originalTask.patient_id,
        treatment_id: originalTask.treatment_id,
        organisation_id: organisationId,
        assigned_user: originalTask.assigned_user ?? '',
        given_start_at: scheduleAt,
        status: StatusEnum.IN_PROGRESS,
        value: value.toString(),
        notes: `Change from ${originalTask.value} to ${value}.`,
      }

      return { stoppedTaskInput, startTaskInput }
    },
    [organisationId],
  )

  const confirm = useConfirm()

  const shouldShowRescheduleWhenEditing =
    action === FluidAction.EDIT_FLUID && !nextPendingFluidTask

  const {
    isTreatmentPending,
    shouldUnapproveTreatment,
    updateTreatmentApprovalStatus,
  } = useApprovals()

  useImperativeHandle(
    ref,
    () => ({
      restartFluid: () => {
        const inputData = getValues() as Inputs
        if (!inputData.assigned_user) return

        const originalTask = task
        // create a fluid task with value and start the Fluid
        const inputValue = inputData.value
          ? inputData.value + getFluidTotalUnit(treatment)
          : getFluidOrCRITreatmentValue(treatment)

        scheduleOrCreateFluidTask({
          originalTask,
          inputValue,
          scheduleAt: inputData.newTaskDate,
          statusAndNotesOverwrite: {
            notes: inputData.notes,
            status: StatusEnum.PENDING,
            assigned_user: inputData.assigned_user,
          },
          isRestart: true,
        })
      },
    }),
    [getValues, scheduleOrCreateFluidTask, task, treatment],
  )

  const onReset = useCallback(() => {
    confirm({
      text: t('sheet:undoTask.fluidConfirm'),
      title: t('sheet:undoTask.title'),
    })
      .then(async () => {
        const resettingTask = getLatestWorkingFluidTask(allTasks)
        if (!resettingTask) {
          return
        }
        const resetLatestWorkingFluidTask = () => resetTask(resettingTask, true)

        const latestStoppedFluidTask = getNearestStoppedFluidTask(
          allTasks,
          resettingTask,
        )
        if (
          !latestStoppedFluidTask ||
          resettingTask.status !== StatusEnum.IN_PROGRESS
        ) {
          return resetLatestWorkingFluidTask()
        }

        return Promise.all([
          resetLatestWorkingFluidTask(),
          resetTask(latestStoppedFluidTask, true),
        ])
      })
      .finally(() => {
        toggleDialog()
      })
  }, [allTasks, confirm, resetTask, t, toggleDialog])

  const hasScheduleWhenStarting =
    action === FluidAction.START && !!nextPendingFluidTask

  // Cannot backdate when the fluid contains schedule rate changes
  const shouldBackDate = shouldShowBackDate && !hasScheduleWhenStarting

  useEffect(() => {
    if (!isBackdated) {
      setIsBeforeMinimum(false)
    }
  }, [isBackdated])

  const currentUser = useUser()

  const doesUserNeedApproval =
    currentUser?.approvalRole === ApprovalRole.NEEDS_APPROVAL

  const shouldDisableSubmitButtonByApproval = useCallback(
    (treatment: TreatmentChildLevel | null) => {
      const isTreatmentUnapproved = !!treatment && isTreatmentPending(treatment)

      switch (action) {
        case FluidAction.START:
        case FluidAction.STOP:
          return isTreatmentUnapproved
        case FluidAction.CREATE_FLUID:
          return doesUserNeedApproval
        case FluidAction.CHANGE_FLUID_RATE:
          if (isTreatmentUnapproved) return true

          return !nextPendingFluidTask && doesUserNeedApproval
        default:
          return false
      }
    },
    [action, doesUserNeedApproval, isTreatmentPending, nextPendingFluidTask],
  )
  const { getCacheTreatmentById } = useCacheTreatment()

  const onSubmitTask = useCallback(async () => {
    const cacheTreatment = treatment?.id
      ? getCacheTreatmentById(treatment.id)
      : treatment

    if (shouldDisableSubmitButtonByApproval(cacheTreatment)) {
      toast.error(t('treatment:approval:failedToCompleteAction'))
      return
    }

    const inputData = getValues() as Inputs
    const newTaskValue = getFormValueOrTotalDosage({
      isMedication,
      patient,
      treatment,
      formValue: inputData.value,
      round,
    })

    const originalTask = task
    const { updatedTask } = getUpdateTaskInput({
      originalTask,
      inputData,
      newTaskValue,
      organisationId,
      patientWeightUnit,
      shouldShowBillableToggle,
      treatment,
      shouldShowBackDate,
    })

    switch (action) {
      case FluidAction.EDIT_FLUID: {
        const taskInput = getEditTaskInput({
          updatedTask,
          originalTask,
          value: inputData.value,
          notes: inputData.notes,
        })
        await updateTask(taskInput)

        // When student edits fluid, unapprove the treatment.
        if (shouldUnapproveTreatment(treatment)) {
          await updateTreatmentApprovalStatus(
            task.treatment_id,
            task.patient_id,
            ApprovalStatus.PENDING,
          )
        }

        // when we have multi-tasks, we do not allow reschedule/backdate the task time
        if (shouldShowRescheduleWhenEditing) {
          rescheduleTask(
            {
              ...originalTask,
              notes: updatedTask.notes ?? '',
            },
            inputData.newTaskDate,
          )
        }

        break
      }
      case FluidAction.CREATE_FLUID: {
        // create a scheduled fluid task with value and start the Fluid
        const inputValue = inputData.value + getFluidTotalUnit(treatment)

        // create and start fluid
        scheduleOrCreateFluidTask({
          originalTask,
          inputValue,
          scheduleAt: inputData.newTaskDate,
          statusAndNotesOverwrite: {
            notes: modifyFluidTaskNotesByAction(
              {
                given_start_at: inputData.newTaskDate.toISOString(),
                notes: inputData.notes,
              },
              dateFormatPattern,
              FluidAction.START,
            ),
            status: StatusEnum.IN_PROGRESS,
            assigned_user: inputData.assigned_user as string,
          },
        })
        break
      }
      case FluidAction.SCHEDULE_FLUID: {
        // create a scheduled fluid task with value

        const inputValue = inputData.value + getFluidTotalUnit(treatment)
        scheduleOrCreateFluidTask({
          originalTask,
          inputValue,
          scheduleAt: inputData.newTaskDate,
        })
        break
      }
      case FluidAction.START: {
        // start a fluid task means we change status from pending =>in progress
        const taskInput = getStartFluidInput({
          originalTask,
          updatedTask,
          datetimeGiven: inputData.backdate ? inputData.datetimeGiven : null,
        })
        updateTask(taskInput)
        break
      }

      case FluidAction.STOP: {
        const taskInput = getStopFluidInput({
          updatedTask,
          originalTask,
          datetimeGiven: inputData.datetimeGiven,
        })

        const notDoneAndInProgressTasks = allTasks.filter(
          task =>
            task.status !== StatusEnum.DONE &&
            task.status !== StatusEnum.IN_PROGRESS,
        )
        // we will delete all pending fluid tasks, and stop the latest working one.
        notDoneAndInProgressTasks.forEach(task =>
          deleteTask(task, true, ApprovalAction.STOP_FLUID),
        )

        updateTask(taskInput)
        break
      }
      case FluidAction.CHANGE_FLUID_RATE: {
        if (!inputData.value) {
          return
        }
        const inputValue = inputData.value + getFluidTotalUnit(treatment)

        if (new Date(originalTask.given_start_at!) >= inputData.newTaskDate) {
          toast.error(t('task:taskAddEdit:fluids:RescheduleError'))
          return
        }

        if (isOptimisticId(nextPendingFluidTask?.id)) {
          return
        }

        const scheduleAt = inputData.newTaskDate.toISOString()

        const removableTasks = getAllRemovableTasks(
          allTasks,
          scheduleAt,
          nextPendingFluidTask,
        )
        const shouldProceedDelete = removableTasks.length > 0
        // Delete all pending tasks that are earlier than rescheduled task
        if (shouldProceedDelete) {
          try {
            await confirm({
              title: t('task:taskAddEdit:fluids:confirmBackDateTitle'),
              text: t('task:taskAddEdit:fluids:confirmBackDateText'),
              okText: 'Yes',
              cancelText: 'Cancel',
            })
          } catch (e) {
            // Should stop the process if the user rejects to delete pending tasks
            return
          }
        }

        const { stoppedTaskInput, startTaskInput } =
          getChangeFluidStoppedAndStartTaskInput({
            originalTask,
            nextPendingFluidTask,
            notes: inputData.notes,
            value: inputValue,
            scheduleAt,
          })

        // stop the click fluid
        updateTask(stoppedTaskInput)

        if (nextPendingFluidTask) {
          updateTask(startTaskInput)
        } else {
          // If there is no scheduled rate change(nextPendingFluidTask), create a in_progress task
          scheduleOrCreateFluidTask({
            originalTask,
            inputValue,
            scheduleAt: inputData.newTaskDate,
            statusAndNotesOverwrite: {
              notes: startTaskInput.notes,
              status: startTaskInput.status,
              assigned_user: startTaskInput.assigned_user,
            },
          })
        }

        // Bulk delete empty triangles
        if (shouldProceedDelete) {
          const deleteTasksInput = removableTasks.map(task => ({
            id: task.id,
            organisation_id: organisationId,
            sheet_id: sheetId,
            treatment_id: task.treatment_id,
          }))
          bulkDeleteTasks(deleteTasksInput)
        }
        break
      }
      default:
        break
    }
    toggleDialog()
  }, [
    treatment,
    getCacheTreatmentById,
    shouldDisableSubmitButtonByApproval,
    getValues,
    isMedication,
    patient,
    round,
    task,
    organisationId,
    patientWeightUnit,
    shouldShowBillableToggle,
    shouldShowBackDate,
    action,
    toggleDialog,
    getEditTaskInput,
    updateTask,
    shouldUnapproveTreatment,
    shouldShowRescheduleWhenEditing,
    updateTreatmentApprovalStatus,
    rescheduleTask,
    scheduleOrCreateFluidTask,
    dateFormatPattern,
    getStartFluidInput,
    getStopFluidInput,
    allTasks,
    deleteTask,
    nextPendingFluidTask,
    getChangeFluidStoppedAndStartTaskInput,
    t,
    confirm,
    bulkDeleteTasks,
    sheetId,
  ])

  const shouldDisableSubmitButton = useMemo(() => {
    // existing disable logic
    if (shouldDisableSubmit || isBeforeMinimum) return true

    return shouldDisableSubmitButtonByApproval(treatment)
  }, [
    isBeforeMinimum,
    shouldDisableSubmit,
    shouldDisableSubmitButtonByApproval,
    treatment,
  ])

  return (
    <>
      <View
        style={[
          styles.container,
          {
            paddingBottom: isWeb && breakpoint.isLargeScreen ? 24 : 36,
          },
        ]}
      >
        {mustCompleteWithInitials ? (
          <Controller
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <Status
                status={createErrorStatus(errors.assigned_user?.message, true)}
              >
                <TextInput
                  accessibilityLabel="Initials Input"
                  autoFocus={true}
                  disabled={isReadOnlyAction}
                  label={t('task:taskTransition:form:initials')}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  required={mustCompleteWithInitials}
                  style={styles.flex}
                  value={value!}
                />
              </Status>
            )}
            name="assigned_user"
          />
        ) : null}
        {!isStartFluid && !isStopFluid && (
          <DataTimePickerWrapper
            action={action}
            nextPendingFluidTask={nextPendingFluidTask}
            control={control}
            treatment={treatment}
            task={task}
            setIsBeforeMinimum={setIsBeforeMinimum}
            name={'newTaskDate'}
          />
        )}
        {shouldEditRate ? (
          <Status status={createErrorStatus(errors.value?.message, true)}>
            <InputGroup>
              <Controller
                control={control}
                render={({ field: { onChange, value } }) => (
                  <NumericInput
                    label={
                      isCreateFluid
                        ? t('task:taskAddEdit:fluids:rateTotal')
                        : t('task:taskAddEdit:fluids:newRateTotal')
                    }
                    style={styles.bigFlex}
                    testID={t('task:taskAddEdit:fluids:newRateTotal')}
                    // TODO: fix type mismatch
                    value={value as any}
                    onChange={newVal => {
                      onChange(newVal)
                    }}
                    required={true}
                  />
                )}
                name="value"
                rules={{
                  required: true,
                }}
              />
              <InputGroupText
                text={getFluidStyleTreatmentVolumeUnit(treatment)}
              />
              <InputGroupText
                text={getFluidStyleTreatmentTimeUnit(treatment)}
              />
            </InputGroup>
          </Status>
        ) : null}
        {shouldBackDate ? (
          <>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <SwitchInput
                  label={t('task:taskTransition:form:backdate')}
                  onChangeValue={onChange}
                  value={value}
                  disabled={isReadOnlyAction}
                />
              )}
              name="backdate"
            />
            {isBackdated ? (
              <DataTimePickerWrapper
                action={action}
                nextPendingFluidTask={nextPendingFluidTask}
                control={control}
                treatment={treatment}
                task={task}
                setIsBeforeMinimum={setIsBeforeMinimum}
                name={'datetimeGiven'}
              />
            ) : null}
          </>
        ) : null}

        {!!shouldShowBillableToggle && (
          <BillingSection
            control={control}
            handleChangeIsBillable={handleIsBillable}
            handleChangeQtyBilled={handleQtyBilled}
            qtyBilledPermissionAction={PermissionAction.TASK_BILLING__CHANGE}
            isCubexBillable={!!treatment?.cubex_integration?.is_cubex_billable}
            cubexTransactionStatus={task?.cubex_transaction_status}
            isReadOnlyAction={isReadOnlyAction}
            qtyBilledErrorMessage={errors.qtyBilled?.message}
            disabled={getIsTaskDoneOrProgress(task.status)}
          />
        )}
        {!isScheduleFluidAction && (
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextArea
                label={t('task:taskTransition:form:notes')}
                onChangeText={onChange}
                value={value}
                visibleLines={6}
                disabled={isReadOnlyAction}
                status={createErrorStatus(errors.notes?.message, true)}
              />
            )}
            name="notes"
          />
        )}
        <FormBreak />
        <TaskFileAttachments
          images={task?.photo_urls ?? []}
          videos={task?.videos ?? []}
          objectKeyPrefix={objectKeyPrefix}
          onChangePhotos={handlePhotoChange}
          onChangeVideos={handleVideoChange}
          onUploading={setIsUploadingMedia}
          isUploading={isUploadingMedia}
          shouldShowUploadBtn={shouldShowUploadBtn}
          attachmentInfo={attachmentInfo(treatment, task)}
        />
        {!isReadOnlyAction && (
          <>
            <View
              style={[
                styles.buttonLayout,
                isSmallToLargeDisplay && styles.buttonLayoutSmall,
              ]}
            >
              <SubmitButton
                action={action}
                task={task}
                loading={isUploadingMedia || loading}
                onSubmit={onSubmitTask}
                disabled={shouldDisableSubmitButton}
              />
            </View>
          </>
        )}
        {!isCreateFluid && (
          <PermissionResetButton
            onReset={onReset}
            disabled={loading || isFinalized}
            buttonText={t('task:taskTransition:reset:fluidTitle')}
            permissionAction={PermissionAction.TASK__RESET}
          />
        )}
        {!!activityLog && (
          <View style={styles.activityLogContainer}>{activityLog}</View>
        )}
      </View>
    </>
  )
}

export type FluidTaskTransitionRef = {
  restartFluid: () => void
}

export const FluidTaskTransition = forwardRef(FluidTaskTransitionWithoutRef)

FluidTaskTransition.displayName = 'FluidTaskTransition'

type DataTimePickerWrapperProps = {
  nextPendingFluidTask: Task | null
  control: Control<Inputs, any>
  task: Task
  treatment: TreatmentChildLevel | null
  action: FluidAction
  setIsBeforeMinimum: (input: boolean) => void
  name: 'newTaskDate' | 'datetimeGiven'
}

const DataTimePickerWrapper: React.FC<DataTimePickerWrapperProps> = ({
  action,
  nextPendingFluidTask,
  control,
  treatment,
  task,
  setIsBeforeMinimum,
  name,
}) => {
  const { t } = useTranslation()
  const isScheduleFluidAction = action === FluidAction.SCHEDULE_FLUID
  const isChangeRateFluid = action === FluidAction.CHANGE_FLUID_RATE
  const isEditFluid = action === FluidAction.EDIT_FLUID
  const isCreateFluid = action === FluidAction.CREATE_FLUID
  const isOnlyFluidTask =
    treatment?.tasks?.items && treatment.tasks.items.length === 1

  const minimumDateValue = useMemo(() => {
    if (isCreateFluid) {
      return new Date(getTaskStopAtString(task))
    }
    if (isOnlyFluidTask) {
      return undefined
    }
    return new Date(getTaskStartAtString(task))
  }, [isCreateFluid, isOnlyFluidTask, task])

  const label = useMemo(() => {
    if (isScheduleFluidAction) {
      return t('task:taskAddEdit:fluids:scheduleFluid')
    }
    if (isCreateFluid) {
      return t('task:taskAddEdit:fluids:startAt')
    }

    if (isChangeRateFluid) {
      return t('task:taskAddEdit:fluids:changeRateAt')
    }

    if (isEditFluid && !nextPendingFluidTask) {
      return t('task:taskTransition:form:rescheduleTo')
    }

    return undefined
  }, [
    isChangeRateFluid,
    isCreateFluid,
    isEditFluid,
    isScheduleFluidAction,
    nextPendingFluidTask,
    t,
  ])

  const warningInfo = useMemo(() => {
    if (getTaskStopAtString(task) && isCreateFluid) {
      return {
        type: WarningType.RESCHEDULE,
        minDateTime: new Date(getTaskStopAtString(task)),
      }
    }
    if (isOnlyFluidTask) {
      return undefined
    }
    if (getTaskStartAtString(task) && !isCreateFluid) {
      return {
        type: WarningType.RESCHEDULE,
        minDateTime: new Date(getTaskStartAtString(task)),
      }
    }
    if (isChangeRateFluid || (isEditFluid && !nextPendingFluidTask)) {
      return {
        type: WarningType.TASK,
      }
    }

    return undefined
  }, [
    isChangeRateFluid,
    isCreateFluid,
    isEditFluid,
    isOnlyFluidTask,
    nextPendingFluidTask,
    task,
  ])

  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <DateTimePicker
          dialog={false}
          label={label}
          onChange={onChange}
          value={value}
          warningInfo={warningInfo}
          minimumDateValue={minimumDateValue}
          setIsBeforeMinimum={setIsBeforeMinimum}
        />
      )}
      name={name}
    />
  )
}
