import { Colors } from 'constants/Colors'
import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

export const SvgHyperLinkOut = ({
  width = 21,
  height = 21,
  color = Colors.blue,
}: Props) => (
  <Svg width={width} height={height} viewBox="0 0 21 21" fill={color}>
    <Path
      id="hyperLinkOutIcon"
      d="M 13.125 0 C 12.398438 0 11.8125 0.585938 11.8125 1.3125 C 11.8125 2.039062 12.398438 2.625 13.125 2.625 L 16.515625 2.625 L 8.261719 10.886719 C 7.746094 11.398438 7.746094 12.230469 8.261719 12.742188 C 8.773438 13.257812 9.605469 13.257812 10.117188 12.742188 L 18.375 4.484375 L 18.375 7.875 C 18.375 8.601562 18.960938 9.1875 19.6875 9.1875 C 20.414062 9.1875 21 8.601562 21 7.875 L 21 1.3125 C 21 0.585938 20.414062 0 19.6875 0 Z M 3.28125 1.3125 C 1.46875 1.3125 0 2.78125 0 4.59375 L 0 17.71875 C 0 19.53125 1.46875 21 3.28125 21 L 16.40625 21 C 18.21875 21 19.6875 19.53125 19.6875 17.71875 L 19.6875 13.125 C 19.6875 12.398438 19.101562 11.8125 18.375 11.8125 C 17.648438 11.8125 17.0625 12.398438 17.0625 13.125 L 17.0625 17.71875 C 17.0625 18.078125 16.765625 18.375 16.40625 18.375 L 3.28125 18.375 C 2.921875 18.375 2.625 18.078125 2.625 17.71875 L 2.625 4.59375 C 2.625 4.234375 2.921875 3.9375 3.28125 3.9375 L 7.875 3.9375 C 8.601562 3.9375 9.1875 3.351562 9.1875 2.625 C 9.1875 1.898438 8.601562 1.3125 7.875 1.3125 Z M 3.28125 1.3125"
    />
  </Svg>
)
