import { TabName } from '../common'

export const isCompletedTab = (tabName: TabName) => tabName === TabName.COMPLETE

export const isSkipTab = (currentTab: TabName) => currentTab === TabName.SKIP

export const isRescheduleTab = (currentTab: TabName) =>
  currentTab === TabName.RESCHEDULE

export const isDeletedTab = (currentTab: TabName) =>
  currentTab === TabName.DELETE

export const isCompleteOrSkipTab = (currentTab: TabName) =>
  currentTab === TabName.COMPLETE || currentTab === TabName.SKIP
