import { Colors } from 'constants/Colors'
import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { Fonts } from 'src/constants/Fonts'

export enum NameType {
  TITLE = 'TITLE',
  SUBTITLE = 'SUBTITLE',
}

type Props = {
  productName: string
  type?: NameType
}

export const ProductName: React.FC<Props> = ({
  productName,
  type = NameType.TITLE,
}) => {
  return (
    <Text
      style={[
        type === NameType.TITLE ? styles.title : styles.subtitle,
        styles.baseStyles,
      ]}
    >
      {productName}
    </Text>
  )
}

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    color: Colors.contentSecondary,
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 16,
  },
  baseStyles: {
    fontFamily: Fonts.regular,
    textAlign: 'center',
  },
})
