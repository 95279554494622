import { volumeFactor, VolumeUnit } from 'components/TreatmentForm/data'
import { getFormattedNumber } from 'components/TreatmentForm/utils/getCalculations'
import { weightFactor, WeightUnit } from 'constants/Weight'
import { isNil } from 'lodash'
import { getOneProduct_getProduct_medicine_dosage_info as ProductMedicineInfo } from 'src/types/getOneProduct'
import { getSheet_getSheet_treatments_items_treatments_items_medicine_dosage_info as MedicineDosageInfo } from 'src/types/getSheet'

export const getTotalPrescribedVolume = (
  productInfo: ProductMedicineInfo | null | undefined,
  totalDosage: number | null,
  treatmentInfo: MedicineDosageInfo | null | undefined,
  round: (n: number) => number,
) => {
  if (!isNil(treatmentInfo?.total_volume)) {
    return treatmentInfo!.total_volume.toString()
  }
  if (!productInfo || !totalDosage || !treatmentInfo) return null
  const isDiluted = treatmentInfo.is_diluted
  const concentrationNumber = isDiluted
    ? treatmentInfo.diluted_concentration
    : productInfo.concentration
  const concentrationWeightUnit = (
    isDiluted
      ? treatmentInfo?.diluted_concentration_weight_unit
      : productInfo.concentration_weight_unit
  ) as WeightUnit
  const concentrationVolumeUnit = (
    isDiluted
      ? treatmentInfo?.diluted_concentration_volume_unit
      : productInfo.concentration_volume_unit
  ) as VolumeUnit
  if (
    !concentrationNumber ||
    !concentrationWeightUnit ||
    !(concentrationWeightUnit in weightFactor) ||
    !concentrationVolumeUnit ||
    !(concentrationVolumeUnit in volumeFactor)
  ) {
    return null
  }

  const normedConcentration =
    (concentrationNumber * weightFactor[concentrationWeightUnit]) /
    volumeFactor[concentrationVolumeUnit]

  return getFormattedNumber(
    (totalDosage * weightFactor.mg) / normedConcentration,
    round,
  )
}

export const getTotalVolumeUnit = (
  volume: string | null,
  productInfo: { concentration_volume_unit: string | null } | null | undefined,
  treatmentInfo: MedicineDosageInfo | null | undefined,
  taskInfo?: { total_volume_unit: string | null } | null,
) => {
  let unit: string
  if (taskInfo?.total_volume_unit) {
    unit = taskInfo.total_volume_unit
  } else if (treatmentInfo?.total_volume_unit) {
    unit = treatmentInfo.total_volume_unit
  } else {
    unit =
      (treatmentInfo?.is_diluted
        ? treatmentInfo?.diluted_concentration_volume_unit
        : productInfo?.concentration_volume_unit) ?? 'ml'
  }

  if (unit === VolumeUnit.TABLE) {
    if (volume === '0' || volume === '1') {
      unit = 'tab'
    } else {
      unit = 'tabs'
    }
  }

  return unit
}
