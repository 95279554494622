import { gql } from '@apollo/client'
export const GET_FILE_UPLOAD_SIGNED_URL = gql`
  query getFileUploadSignedUrl($key: String!) {
    getFileUploadSignedUrl(key: $key) {
      url
    }
  }
`

export const START_FILE_MULTI_PART_UPLOAD = gql`
  mutation startFileMultiPartUpload($input: StartFileMultiPartUploadInput!) {
    startFileMultiPartUpload(input: $input) {
      upload_id
    }
  }
`

export const COMPLETE_FILE_MULTI_PART_UPLOAD = gql`
  mutation completeFileMultiPartUpload(
    $input: CompleteFileMultiPartUploadInput!
  ) {
    completeFileMultiPartUpload(input: $input)
  }
`

export const GET_FILE_UPLOAD_PART_SIGNED_URL = gql`
  query getFileUploadPartSignedUrl(
    $key: String!
    $upload_id: String!
    $part_number: Int!
  ) {
    getFileUploadPartSignedUrl(
      key: $key
      upload_id: $upload_id
      part_number: $part_number
    ) {
      url
    }
  }
`

export const GET_FILE_DOWNLOAD_SIGNED_URL = gql`
  query getFileDownloadSignedUrl(
    $key: String!
    $file_name: String!
    $is_attachment: Boolean!
  ) {
    getFileDownloadSignedUrl(
      key: $key
      file_name: $file_name
      is_attachment: $is_attachment
    ) {
      url
    }
  }
`
