import { SvgArrow } from 'components/Icons'
import React from 'react'
import { Text, StyleSheet, View, TouchableOpacity } from 'react-native'
import { Colors, Typography, Variables } from 'src/design-system/theme'

type Props = {
  title: string
  icon: 'close' | 'back'
  onPress: () => void
}

export const HelpHeader: React.FC<Props> = ({ title, icon, onPress }) => {
  const hasBack = icon === 'back'

  const Backbutton = (
    <TouchableOpacity onPress={onPress}>
      <SvgArrow
        color={Colors.Contents.primary}
        orient="left"
        height={styles.headerText.fontSize}
        width={styles.headerText.fontSize}
      />
    </TouchableOpacity>
  )

  return (
    <View style={styles.headerContainer}>
      {!!hasBack && Backbutton}
      <Text style={styles.headerText}>{title}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  headerText: {
    fontWeight: Typography.FontWeights.bold,
    lineHeight: 22,
    fontSize: Typography.FontSizes.md,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: Variables.GutterSpacing.md,
    gap: Variables.GutterSpacing.sm,
  },
})
