export const getFluidTaskNumberValue = (taskValue: string | null) => {
  if (!taskValue) {
    return null
  }

  const scientificNotationMatch = taskValue.match(
    /[+-]?(\d+)(\.\d*)?([eE][+-]?\d+)?/,
  )
  if (!scientificNotationMatch) {
    return null
  }
  const scientificValue = scientificNotationMatch[0]
  const scientificNumber = Number(scientificValue)
  if (isNaN(scientificNumber)) {
    return null
  }
  return scientificNumber
}

export const getFluidTaskUnit = (taskValue: string | null) => {
  if (!taskValue) {
    return null
  }

  const taskUnits = taskValue.replace(/[\d.]+/gi, '')

  return taskUnits
}
