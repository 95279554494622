import React, { useState } from 'react'
import { SingleProductPicker } from './SingleProductPicker'
import { Product } from './ProductRow'
import { AddTreatmentScreen } from './AddTreatmentScreen'
import { AddTemplateToSheetScreen } from './AddTemplateToSheetScreen'
import { useBackButton } from 'src/hooks/useBackButton'
import { useSheetContext } from 'src/context/sheet'
import { AddBundleScreen } from './Bundles/AddBundleScreen'
import { useFlags } from 'react-native-flagsmith/react'
import { FeatureFlagNames } from 'constants/FeatureFlags'

type Props = {
  patientId: string
  sheetId: string
  onDone: () => void
}

export const AddTreatmentDrawerContent: React.FC<Props> = ({
  patientId,
  sheetId,
  onDone,
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)

  const handleBackOverrides = useBackButton<Product | null>(
    selectedProduct,
    setSelectedProduct,
  )

  const addTreatmentOrTemplate = (product: Product) => {
    setSelectedProduct(product)
  }

  const [sheetContext] = useSheetContext()

  const bundleFlags = useFlags([FeatureFlagNames.ENABLE_BUNDLE_UI])
  const isBundleFlagEnabled = bundleFlags.enable_bundle_ui.enabled

  if (selectedProduct) {
    if (selectedProduct?.group.id === 'Template') {
      return (
        <AddTemplateToSheetScreen
          sheetId={sheetId}
          templateId={selectedProduct.id!}
          onDone={onDone}
          patientId={patientId}
          handleBackOverrides={handleBackOverrides}
        />
      )
    }

    if (selectedProduct.isBundle && isBundleFlagEnabled) {
      return (
        <AddBundleScreen
          product={selectedProduct}
          onDone={onDone}
          sheetId={sheetId}
          patientId={patientId}
          handleBackOverrides={handleBackOverrides}
        />
      )
    }

    return (
      <AddTreatmentScreen
        sheetId={sheetId}
        patientId={patientId}
        product={selectedProduct}
        onDone={onDone}
      />
    )
  }

  return (
    <SingleProductPicker
      onSelect={addTreatmentOrTemplate}
      siteIds={
        sheetContext.sheetAttendingDepartment
          ? [sheetContext.sheetAttendingDepartment]
          : []
      }
      shouldShowBundleOverride={true}
    />
  )
}
