export enum SmallTimeSegments {
  fiveMinute = 'five-minute',
  tenMinute = 'ten-minute',
  fifteenMinute = 'fifteen-minute',
  thirtyMinute = 'thirty-minute',
  hourly = 'hourly',
}

export enum LargeTimeSegments {
  twoHours = '2-hours',
  fourHours = '4-hours',
  sixHours = '6-hours',
  eightHours = '8-hours',
  twelveHours = '12-hours',
  twentyFourHours = '24-hours',
}

// nb: Very Important, the string values used in TimeSegment MUST match the keys of
// components/common/translation/*/translation.json ->
// 'sheets.time.resolution.options'. You can't typecheck json files so be sure
// never to break this contract. We assume that the translation file resolution
// options entry uses Record<TimeSegment, string> format in a few places
export const TimeSegment = {
  ...SmallTimeSegments,
  ...LargeTimeSegments,
}

export type TimeSegment = SmallTimeSegments | LargeTimeSegments

export const TimeSegmentMinutes: Record<TimeSegment, number> = {
  'five-minute': 5,
  'ten-minute': 10,
  'fifteen-minute': 15,
  'thirty-minute': 30,
  hourly: 60,
  '2-hours': 120,
  '4-hours': 240,
  '6-hours': 360,
  '8-hours': 480,
  '12-hours': 720,
  '24-hours': 1440,
}

export const TimeSegmentFuzzIntervalMinutes: Record<TimeSegment, number> = {
  [TimeSegment.fiveMinute]: 1,
  [TimeSegment.tenMinute]: 2,
  [TimeSegment.fifteenMinute]: 3,
  [TimeSegment.thirtyMinute]: 4,
  [TimeSegment.hourly]: 8,
  [TimeSegment.twoHours]: 16,
  [TimeSegment.fourHours]: 30,
  [TimeSegment.sixHours]: 30,
  [TimeSegment.eightHours]: 30,
  [TimeSegment.twelveHours]: 60,
  [TimeSegment.twentyFourHours]: 60,
}

export const DEFAULT_DATEBOX_WIDTH = 45

/**
 * Get the number of segments (columns) for the timeline grid
 * The calculation gets the number of minutes for the number of days
 * and divides by the zoomLevel chosen (which is in minutes)
 *
 * example: (60 *(3*24)): 4320 / 60:hourly = 72 segments
 * example: (60 *(3*24)): 4320 / 360:6-hours = 12 segments
 * @param timeSegment
 * @param days
 */
export const getSegmentsPerOneOrThreeDays = (
  timeSegment: TimeSegment,
  days: number,
) => (60 * (days * 24)) / TimeSegmentMinutes[timeSegment]

// The number of days we render on each screen before you have to paginate
// 1 for Anaesthesia sheet, 3 for Normal sheet
export type DateRangeCount = 1 | 3
export const DEFAULT_DAYS_RENDERED: DateRangeCount = 3

// How many scrollable screen widths of grid coverage we expect for the rendered
// grid. Determins how 'scrollable' a grid is.
export const EXPECTED_GRID_SCREEN_COVERAGE = 3
// The factor by which to scale up a datebox when the grid is too small,
// relative to the difference in rendered grid coverage from EXPECTED_GRID_SCREEN_COVERAGE
export const SMALL_GRID_DATEBOX_MULTIPLY = 3.5
