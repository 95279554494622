type Props = {
  isWeightAgeWidthOversize?: boolean
  patientWeight: string
  patientWeightUnit: string | null
}

const MAX_WEIGHT_WIDTH = 4

export const getWeightText = ({
  isWeightAgeWidthOversize,
  patientWeight,
  patientWeightUnit,
}: Props) => {
  const weight =
    isWeightAgeWidthOversize && patientWeight.length > MAX_WEIGHT_WIDTH + 1
      ? `${patientWeight.slice(0, MAX_WEIGHT_WIDTH)}..`
      : patientWeight
  return `${weight}${patientWeightUnit}`
}
