import { StyleSheet } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { EditableCard } from '../EditableCard'
import { environment } from 'src/config/environment'

type CriticalNotesProps = {
  notes: string[] | null
  onPress?: (val?: string) => void
}

export const CriticalNotesCard: React.FC<CriticalNotesProps> = ({
  notes,
  onPress,
}) => {
  const { t } = useTranslation()
  const joinedNotes = notes?.join('\n')
  const isWeb = environment.isWeb
  const cardStyles = {
    containerStyle: styles.container,
    contentStyle: styles.content,
    contentTextStyle: styles.contentText,
    buttonStyle: styles.button,
    buttonIconColor: Colors.white,
    buttonTextStyle: styles.buttonText,
    editingStyle: isWeb
      ? { ...styles.criticalNotesEditing, outlineWidth: 0 }
      : styles.criticalNotesEditing,
  }
  return (
    <EditableCard
      key={t('patientPanel:cards.criticalNotes.key')}
      header={t('patientPanel:cards.criticalNotes.header')}
      showHeader={false}
      onPress={onPress}
      cardStyles={cardStyles}
      value={joinedNotes}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.patient.criticalNotes,
  },
  content: {
    paddingTop: 8,
  },
  contentText: {
    fontFamily: Fonts.bold,
    color: Colors.white,
  },
  button: {
    backgroundColor: Colors.patient.criticalNotes,
  },
  buttonText: {
    color: Colors.white,
  },
  criticalNotesEditing: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
  },
})
