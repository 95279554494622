import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { TASK_FIELDS_FULL } from 'components/Task/graphql'
import { TreatmentSheet } from 'components/Treatment/common/types'
import { UPDATE_TREATMENT } from 'components/Treatment/graphql'
import { getProductType } from 'components/TreatmentForm/utils/getProductType'
import { getTreatmentInput } from 'components/TreatmentForm/utils/getTreatmentInput'
import { useTranslation } from 'react-i18next'
import { GET_PRODUCT_ID } from 'src/components/AddTreatment/graphql'
import { FormData } from 'src/components/TreatmentForm'
import { UpdateTreatmentOptions } from 'src/types/globalTypes'
import { ProductId } from 'src/types/ProductId'
import {
  updateTreatment as updateTreatmentType,
  updateTreatmentVariables,
} from 'types/updateTreatment'

import {
  getFluidValueIsUpdating,
  getUpdateTask,
} from './updateFluidTreatmentUtils'

export const useUpdateTreatment = (
  patientId: string,
  sheetId: string,
  organisationId: string,
  treatment: TreatmentSheet,
) => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const getProductId = client.readFragment<ProductId>({
    id: `Treatment:${treatment.id}`,
    fragment: GET_PRODUCT_ID,
  })

  const productId = getProductId?.product?.id
  const productType = getProductType(treatment.product?.type)

  const [updateTreatment, { loading: submitting }] = useMutation<
    updateTreatmentType,
    updateTreatmentVariables
  >(UPDATE_TREATMENT, {
    onError: err => {
      console.error(err) // eslint-disable-line no-console
      toast.error(err.message)
    },
  })

  const updateTheTreatment = async (
    formData: FormData,
    updateTreatmentOptions?: UpdateTreatmentOptions,
  ) => {
    const treatmentInput = getTreatmentInput(formData, productType)
    await updateTreatment({
      variables: {
        input: {
          name: treatment.name,
          ...treatmentInput,
          conditional: treatment.conditional ?? false,
          id: treatment.id,
          organisation_id: organisationId,
          patient_id: patientId,
          product_id: productId!,
          sheet_id: sheetId,
        },
        options: updateTreatmentOptions,
      },
      update: () => {
        const newTreatment = {
          ...treatment,
          ...treatmentInput,
        } as TreatmentSheet

        if (getFluidValueIsUpdating(treatment, newTreatment)) {
          if (!('tasks' in newTreatment && newTreatment.tasks?.items)) {
            return
          }

          const newTask = getUpdateTask(newTreatment, newTreatment.tasks.items)

          if (newTask) {
            client.writeFragment({
              id: `Task:${newTask.id}`,
              fragment: TASK_FIELDS_FULL,
              fragmentName: 'TaskFieldsFull',
              data: newTask,
            })
          }
        }
        toast.success(t('treatment:editTreatment:success'))
      },
    })
  }
  return { updateTheTreatment, submitting }
}
