import React from 'react'

import { DateTimePicker as CommonDateTimePicker } from 'components/common/DateTimePicker/DateTimePicker'

import { Field } from '../Field'

type Props = Omit<
  React.ComponentProps<typeof CommonDateTimePicker>,
  'FieldComponent'
>

export const DateTimePicker: React.FC<Props> = props => {
  return <CommonDateTimePicker {...props} FieldComponent={Field} />
}
