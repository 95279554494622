import { useCallback } from 'react'
import { useTimeResolution } from 'src/hocs/timeContext'
import { buildTreatmentWithTasksFragmentVariable } from 'components/Treatment/utils/buildTreatmentWithTasksFragmentVariable'
import { TreatmentBaseWithTasks } from 'types/TreatmentBaseWithTasks'
import { useApolloClient } from '@apollo/client'

export const useCacheTreatment = () => {
  const { fromToQueryDate } = useTimeResolution()
  const client = useApolloClient()

  const getCacheTreatmentById = useCallback(
    (treatmentId: string) => {
      const fragmentVariable = buildTreatmentWithTasksFragmentVariable(
        treatmentId,
        fromToQueryDate,
      )

      return client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)
    },
    [client, fromToQueryDate],
  )

  return {
    getCacheTreatmentById,
  }
}
