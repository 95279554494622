import Svg, { Path } from 'react-native-svg'
import React from 'react'

type Props = {
  color?: string
  height?: number
  width?: number
}

export const SvgFluidScheduledFluidRateChange: React.FC<Props> = ({
  color = '#FFA339',
  height = 21,
  width = 24,
}) => (
  <Svg viewBox="0 0 24 21" height={height} width={width} fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0L0 21H24L12 0ZM12 4.14815L3.5556 18.9259H20.4444L12 4.14815Z"
      fill={color}
    />
  </Svg>
)
