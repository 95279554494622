import React, { useEffect, useMemo, useState } from 'react'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  Easing,
} from 'react-native-reanimated'

const DEFAULT_MAX_HEIGHT = 100
const DEFAULT_MIN_HEIGHT = 0

type Props = {
  scrollOffset: number
  children?: React.ReactElement | null
  interpolationRange?: number
  maxHeight?: number
  minHeight?: number
}

export const AnimatedFadingHeader: React.FC<Props> = ({
  children,
  scrollOffset,
  interpolationRange = 200,
  maxHeight = DEFAULT_MAX_HEIGHT,
  minHeight = DEFAULT_MIN_HEIGHT,
}) => {
  const anim = useSharedValue(maxHeight)
  const [isHeaderOpen, setIsHeaderOpen] = useState<boolean>(true)

  const offsetValue = useMemo(() => {
    if (isHeaderOpen) return scrollOffset
    return scrollOffset + maxHeight
  }, [isHeaderOpen, maxHeight, scrollOffset])

  useEffect(() => {
    if (isHeaderOpen && offsetValue >= interpolationRange) {
      setIsHeaderOpen(false)
      anim.value = minHeight
    }
    if (!isHeaderOpen && offsetValue < interpolationRange) {
      setIsHeaderOpen(true)
      anim.value = maxHeight
    }
  }, [
    anim,
    interpolationRange,
    isHeaderOpen,
    maxHeight,
    minHeight,
    offsetValue,
  ])

  const animatedStyle = useAnimatedStyle(() => {
    return {
      height: withTiming(anim.value, {
        duration: 300,
        easing: Easing.linear,
      }),
    }
  })

  return <Animated.View style={animatedStyle}>{children}</Animated.View>
}
