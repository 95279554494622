import { Observable } from '@apollo/client/utilities'

export const promiseToZenObservable = <T extends unknown>(
  promise: Promise<T>,
) => {
  return new Observable<T>(subscriber => {
    promise.then(
      value => {
        if (subscriber.closed) {
          return
        }
        subscriber.next(value)
        subscriber.complete()
      },
      err => {
        subscriber.error(err)
      },
    )
  })
}
