export const shorthandPatientBirth = (birth: string) => {
  const findYear = birth.indexOf('y')
  const findMonth = birth.indexOf('m')
  if (findYear > 0) {
    return birth.slice(0, findYear + 1)
  }
  if (findMonth > 0) {
    return birth.slice(0, findMonth + 1)
  }
  return birth
}
