import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { Fonts } from 'constants/Fonts'
import { Colors, Typography, Variables } from 'src/design-system/theme'
import {
  LeaveTrainingModeButton,
  ResetTrainingModeButton,
} from './zTEMP-buttons'
import { useTranslation } from 'react-i18next'
import { ResetModal } from './ResetModal'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useResetTrainingOrganisationData } from './useResetTrainingOrganisationData'
import { useTrainingOrganisationDataResetSubscription } from './useTrainingOrganisationDataResetSubscription'

export const TrainingBanner: React.FC<{
  navigateToRealSite: () => void
}> = ({ navigateToRealSite }) => {
  const { t } = useTranslation()
  const [showResetModal, setShowResetModal] = useState(false)
  const [isResetting, setIsResetting] = useState(false)
  const closeModal = () => setShowResetModal(false)

  useTrainingOrganisationDataResetSubscription({ setIsResetting })
  const { resetTrainingOrganisation, loadingToast } =
    useResetTrainingOrganisationData()

  const { isSmallishScreen } = useBreakpoint()

  useEffect(() => {
    if (isResetting) return
    loadingToast.current?.()
    loadingToast.current = null
  }, [isResetting, loadingToast])

  /*
   This layout hack is used to make the middle element (title) Wrap downwards on small screens or resized browser windows
  */
  const layoutHack = isSmallishScreen && { minWidth: 100 }
  const resetTitle = isSmallishScreen
    ? t('training:trainingBanner:resetButtonShort')
    : t('training:trainingBanner:resetButton')

  const resetTrainingSite = () => {
    closeModal()
    resetTrainingOrganisation()
    setIsResetting(true)
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.column}>
          <LeaveTrainingModeButton
            title={t('training:trainingBanner:leaveButton')}
            onPress={navigateToRealSite}
          />
        </View>

        <View style={styles.titleLayoutHack}>
          <View style={styles.column}>
            <Text style={styles.title}>
              {t('training:trainingBanner:title')}
            </Text>
          </View>
          <View style={layoutHack} />
        </View>

        <View style={styles.column} />

        <View style={styles.resetContainer}>
          <ResetTrainingModeButton
            disabled={isResetting}
            title={resetTitle}
            onPress={() => setShowResetModal(true)}
          />
        </View>

        {/* TODO Cards */}
      </View>
      <ResetModal
        visible={showResetModal}
        onClose={closeModal}
        resetTrainingSite={resetTrainingSite}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: Variables.GutterSpacing.md,
    backgroundColor: Colors.Backgrounds.accentSecondary,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  titleLayoutHack: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    height: 40, // Size of bar (72px) minus paddings (32px), needed so IPad layout isn't double height for some reason
  },
  resetContainer: {
    position: 'absolute',
    top: Variables.GutterSpacing.md,
    right: Variables.GutterSpacing.md,
  },
  column: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: Colors.Contents.primary,
    fontSize: 28, // custom size?
    fontWeight: Typography.FontWeights.bold,
    fontFamily: Fonts.bold,
  },
})
