import React from 'react'
import { SvgArrow } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { getCustomProducts_getOrganisation_products_items as ProductItemT } from 'types/getCustomProducts'
import { Center } from 'src/components/common/Center'

export type CustomProductItemProps = {
  a11yLabel?: string
  disabled?: boolean | null
  product: ProductItemT
  onPress: () => void
}

export const CustomProductItem = ({
  a11yLabel,
  disabled = false,
  product,
  onPress,
}: CustomProductItemProps) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const getProductType = (type: ProductItemT['type']) => {
    if (type === 'IVFLUIDS') {
      return 'IV FLUIDS'
    }
    return type
  }
  return (
    <Center>
      <TouchableOpacity
        accessibilityLabel={
          a11yLabel || `${t('settings:users.navTo')} ${product.name}`
        }
        disabled={disabled ?? false}
        onPress={onPress}
        style={[styles.container, disabled && { opacity: 0.5 }]}
      >
        <View style={styles.main}>
          <View
            style={[
              styles.nameWrapper,
              {
                flex: breakpoint.isLargeScreen ? 5 : 3,
              },
            ]}
          >
            <Text
              style={[styles.name]}
              testID={product.name}
              numberOfLines={3}
              ellipsizeMode={'middle'}
            >
              {product.name}
            </Text>
          </View>
          <View style={styles.typeWrapper}>
            <View
              style={[
                styles.typeTag,
                {
                  borderColor: Colors.lightGrey2,
                },
              ]}
            >
              <Text
                style={[
                  styles.typeText,
                  {
                    color: Colors.lightGrey2,
                  },
                ]}
              >
                {product.track_vital
                  ? t('settings:products.healthStatus')
                  : getProductType(product.type)}
              </Text>
            </View>
          </View>
          <View style={styles.icon}>
            {!disabled ? (
              <SvgArrow orient="right" color="grey" width={15} height={15} />
            ) : (
              <View style={{ width: 15 }} />
            )}
          </View>
        </View>
      </TouchableOpacity>
    </Center>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 150,
    paddingLeft: 16,
    paddingVertical: 16,
    width: '100%',
  },
  main: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  nameWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 'auto',
    paddingRight: 8,
  },
  icon: {
    padding: 16,
  },
  name: {
    fontFamily: Fonts.regular,
    fontSize: 16,
    marginLeft: 16,
  },
  typeWrapper: {
    alignItems: 'flex-start',
    flex: 2,
    paddingHorizontal: 8,
  },
  typeTag: {
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 4,
  },
  typeText: {
    fontFamily: Fonts.regular,
    textTransform: 'uppercase',
  },
})
