import { toast } from 'components/common/Toast/ToastArea'
import i18next from 'i18next'
import debounce from 'lodash/debounce'

// There can be multiple session timeout requests in a short period, debounce the error toast.
export const sessionErrorToast = debounce(
  (error?: Error) =>
    toast.error(i18next.t('general.sessionExpired'), null, error),
  500,
)
