import React from 'react'
import Svg, { G, Path } from 'react-native-svg'

type Props = {
  color?: string
  length?: number
}

export const SvgInProgress: React.FC<Props> = ({
  color = '#fff',
  length = 30,
}) => (
  <Svg
    width={length}
    height={length}
    viewBox="0 0 300 228"
    testID="SvgInProgress"
  >
    <G
      transform="translate(0,228) scale(0.05,-0.05)"
      fill={color}
      stroke="none"
    >
      <Path
        d="M2689 3973 c-6 -14 -1 -51 10 -81 12 -30 21 -98 21 -150 0 -127 41
   -167 147 -143 201 46 537 -44 850 -227 328 -192 625 -766 612 -1182 -4 -132
   -35 -381 -49 -400 -8 -11 -21 -42 -29 -70 -142 -530 -795 -987 -1364 -957
   -155 8 -150 15 -175 -237 -18 -178 -20 -176 304 -147 1286 114 2048 1364 1546
   2535 -196 456 -667 881 -1122 1012 -245 71 -731 101 -751 47z"
      />
      <Path
        d="M2120 3817 c-209 -111 -216 -124 -125 -228 41 -46 91 -108 111 -137
   40 -58 115 -69 154 -22 14 16 60 39 102 51 87 23 96 53 48 146 -17 32 -34 79
   -39 105 -31 172 -65 184 -251 85z"
      />
      <Path
        d="M1524 3335 c-43 -58 -93 -117 -111 -132 -63 -53 -36 -91 127 -178
   193 -103 209 -104 250 -17 18 38 50 84 71 103 58 52 50 71 -84 206 -150 149
   -156 150 -253 18z"
      />
      <Path
        d="M3032 2439 c-280 -280 -446 -433 -462 -423 -13 7 -76 70 -139 139
   -64 69 -126 125 -138 124 -30 0 -128 -101 -135 -139 -5 -25 407 -460 436 -460
   5 0 218 207 474 461 255 253 493 480 528 505 81 56 80 79 -5 165 -97 97 -75
   111 -559 -372z"
      />
      <Path
        d="M1192 2735 c-59 -207 -67 -375 -17 -376 19 -1 76 -5 125 -10 245 -25
   227 -31 247 81 37 211 36 213 -91 259 -62 23 -120 48 -128 56 -24 25 -129 17
   -136 -10z"
      />
      <Path
        d="M1161 2003 c-26 -8 -27 -124 -2 -221 11 -39 24 -96 30 -126 16 -78
   73 -86 201 -27 61 28 128 51 148 51 51 0 64 45 32 108 -15 28 -30 90 -34 137
   l-6 85 -174 0 c-95 0 -183 -3 -195 -7z"
      />
      <Path
        d="M1643 1393 c-12 -14 -71 -50 -131 -80 -148 -72 -148 -68 -13 -239
   158 -200 147 -198 282 -51 122 133 144 196 83 241 -20 14 -56 55 -81 91 -48
   68 -103 83 -140 38z"
      />
      <Path
        d="M2100 901 c-22 -44 -68 -111 -102 -150 -76 -87 -72 -94 132 -194 169
   -84 230 -89 230 -21 0 21 18 91 40 155 50 144 44 160 -67 208 -49 21 -95 47
   -102 59 -29 48 -91 21 -131 -57z"
      />
    </G>
  </Svg>
)
