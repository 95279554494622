import React, { useEffect, useRef } from 'react'
import { Platform, Text } from 'react-native'

type Props = React.ComponentProps<typeof Text> & {
  title?: string | null
}

export const TextWithTooltip: React.FC<Props> = ({
  title,
  children,
  ...props
}) => {
  const ref = useRef<Text>(null)
  useEffect(() => {
    if (Platform.OS !== 'web' || !ref.current) {
      return
    }
    ref.current.setNativeProps({ title })
  }, [title])
  return (
    <Text ref={ref} {...props}>
      {children}
    </Text>
  )
}
