import {
  addMinutes,
  differenceInMinutes,
  format,
  getMinutes,
  startOfDay,
} from 'date-fns'

/**
 * generates the name of the treatment frequency based on the frequencies added
 *
 * @param frequencies
 * @param timeFormat
 */
export const getDynamicName = (frequencies: Date[], timeFormat: string) => {
  let formatWithMins: string
  let formatOnHour: string

  // checks if the time format include a indicating the format being used is am/pm
  if (timeFormat.includes('a')) {
    formatWithMins = 'h:mmaaa'
    formatOnHour = 'haaa'
  } else {
    formatWithMins = 'HH:mm'
    formatOnHour = 'HH:mm'
  }
  const time = frequencies.slice(0, 5).map((frequency: Date) => {
    const formatString =
      getMinutes(frequency) > 0 ? formatWithMins : formatOnHour
    return format(frequency, formatString)
  })
  return time.join(', ')
}

/**
 * take an array of numbers (mins from midnight) and returns an array of dates with time set from midnight
 *
 * @param frequencies
 */
export const getTimeOfDayArray = (frequencies: number[]) => {
  return frequencies.map(frequency =>
    addMinutes(startOfDay(new Date()), frequency),
  )
}

/**
 * takes an array of dates and returns an array of numbers which are minutes from midnight
 *
 * @param frequencies
 */
export const getMinFromMidnightArray = (frequencies: Date[]) => {
  return frequencies.map(frequency =>
    differenceInMinutes(frequency, startOfDay(frequency)),
  )
}

/**
 * checks if the array has unique values
 *
 * @param array
 */
export const isArrayUnique = (array: number[]) =>
  new Set(array).size !== array.length
