import React from 'react'
import Svg, { G, Path, Polyline } from 'react-native-svg'

export const SvgEnterFull: React.FC = () => (
  <Svg width="24" height="24" viewBox="0 0 24 24">
    <G fill="none" fillRule="evenodd">
      <Path
        d="M4,9 L4,4 L8,4 C8.33333333,4 8.66666667,4 9,4"
        stroke="#1A274C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15,4 L20,4 L20,8 C20,8 20,8.33333333 20,9"
        stroke="#1A274C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Polyline
        stroke="#1A274C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="20 15 20 20 15 20"
      />
      <Polyline
        stroke="#1A274C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="9 20 4 20 4 15"
      />
    </G>
  </Svg>
)
