import { VideoThumbnail } from './types'

export const generateThumbnail = async (
  videoURI: string,
): Promise<VideoThumbnail> => {
  return getVideoThumbnailAsync(videoURI)
}

// Modified from @rajesh896/video-thumbnails-generator
export const getVideoThumbnailAsync = async (
  urlOfFIle: string,
  videoTimeInSeconds: number = 0,
): Promise<VideoThumbnail> => {
  // Quicktime videos can be processed as mp4 to avoid errors
  urlOfFIle = urlOfFIle.replace('video/quicktime', 'video/mp4')

  return new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video')
      const timeupdate = () => {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate)
          video.pause()
        }
      }
      video.addEventListener('loadeddata', () => {
        try {
          if (snapImage()) {
            video.removeEventListener('timeupdate', timeupdate)
          }
        } catch (error) {
          reject(error)
        }
      })
      const snapImage = () => {
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        canvas
          .getContext('2d')!
          .drawImage(video, 0, 0, canvas.width, canvas.height)
        const image: string | undefined = canvas.toBlob(
          blob => {
            const reader = new FileReader()
            reader.readAsDataURL(blob as Blob)
            reader.onloadend = () => {
              const base64data = reader.result
              resolve({
                uri: base64data as string,
                width: video.videoWidth,
                height: video.videoHeight,
              })
            }
          },
          'image/jpeg',
          1 /* quality */,
        ) as unknown as string
        const success = image?.length > 100000
        if (success) {
          URL.revokeObjectURL(urlOfFIle)
          resolve({
            uri: image as unknown as string,
            width: video.videoWidth,
            height: video.videoHeight,
          })
        }
        return success
      }
      video.addEventListener('timeupdate', timeupdate)
      video.preload = 'metadata'
      video.src = urlOfFIle
      // Load video in Safari / IE11
      video.muted = true
      video.playsInline = true
      video.crossOrigin = 'Anonymous'
      video.currentTime = videoTimeInSeconds
      video
        .play()
        .then()
        .catch(err => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            status: 500,
            reason: `Access to video at ${urlOfFIle} from origin ${window.location.hostname} has been blocked by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource.`,
            message: err,
          })
        })
    } catch (error) {
      reject(error)
    }
  })
}
