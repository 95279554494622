import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Checkbox } from 'src/design-system/components/CheckBox/Checkbox'
import { Pill } from '../Pill'
import { useBusinessUnitWarning } from './hooks/useBusinessUnitWarning'
import { Product, TagItem } from './types'
import { ProductRow } from './components'

type Props = {
  a11yLabel?: string
  onClick?: () => unknown
  product: Product
  searchWords: string[]
  selected?: boolean
  tags?: TagItem[]
  attendingDepartment?: string | null
}

export const SelectableProductRow: React.FC<Props> = ({
  a11yLabel,
  onClick = () => {},
  product,
  searchWords = [],
  selected = false,
  tags,
  attendingDepartment,
}) => {
  const { isSiteValid, BusinessUnitWarning } = useBusinessUnitWarning(
    product,
    attendingDepartment,
  )

  return (
    <ProductRow.Container
      accessibilityLabel={a11yLabel}
      onPress={onClick}
      disabled={!isSiteValid}
    >
      <View style={styles.flex}>
        <ProductRow.Heading
          text={product.name}
          searchWords={searchWords}
          disabled={!isSiteValid}
        />
        <View style={styles.row}>
          {tags?.map((tag, i) => (
            <Pill
              key={i}
              text={tag.title}
              size="small"
              color={tag.color}
              textStyle={{ color: tag.textColor }}
            />
          ))}
          {BusinessUnitWarning ||
            (!!product.code && (
              <ProductRow.SubHeading
                disabled={!isSiteValid}
                testID={product.code}
              >
                {product.code}
              </ProductRow.SubHeading>
            ))}
        </View>
      </View>
      {Platform.OS === 'web' && isSiteValid && product.group ? (
        <ProductRow.SubHeading
          style={{ flexShrink: 1 }}
          disabled={!isSiteValid}
          testID={product.group.name}
        >
          {product.group.name}
        </ProductRow.SubHeading>
      ) : null}
      <Checkbox
        a11yLabel={`Checkbox ${product.name}`}
        checked={selected}
        disabled={!isSiteValid}
        onPress={onClick}
      />
    </ProductRow.Container>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  row: { flexDirection: 'row' },
})
