import { getFlattenedTreatments } from 'components/Task/utils/getFlattenedTreatments'
import { differenceInMinutes } from 'date-fns'
import { minBy } from 'lodash'
import { TreatmentSheet } from 'src/components/Treatment/common/types'
import { AnesthesiaChartValueData } from 'src/context/types/anesthesia'
import { TimeSegment, TimeSegmentMinutes } from 'src/hocs/types/time'

const ONE_FIFTH_FUZZINESS = 5

const getMinFuzzinessDifference = (
  dataDate: Date,
  selectedDate: Date,
  timeSegment: TimeSegment,
) => {
  const absoluteDifferenceMinutes = Math.abs(
    differenceInMinutes(dataDate, selectedDate),
  )
  const timeFuzziness = Math.round(
    TimeSegmentMinutes[timeSegment] / ONE_FIFTH_FUZZINESS,
  )

  if (absoluteDifferenceMinutes > timeFuzziness) {
    return
  }
  return absoluteDifferenceMinutes
}

export const getTaskValue = (
  entryData: AnesthesiaChartValueData[],
  selectedDate: Date | undefined,
  timeSegment: TimeSegment,
) => {
  if (!selectedDate) {
    return ''
  }
  const nearestTimeData = minBy(entryData, data =>
    getMinFuzzinessDifference(data.time, selectedDate, timeSegment),
  )
  return nearestTimeData?.value?.toString() ?? ''
}

export const getNearestTimeTask = (
  treatments: TreatmentSheet[],
  treatmentId: string,
  selectedDate: Date,
  timeSegment: TimeSegment,
) => {
  const subTreatments = getFlattenedTreatments(treatments)

  const treatment = subTreatments.find(t => t.id === treatmentId)
  if (!treatment) {
    return
  }
  const tasks = treatment.tasks?.items
  if (!tasks) {
    return
  }
  const nearestTimeTask = minBy(tasks, task => {
    if (!task.start_at) {
      return
    }

    return getMinFuzzinessDifference(
      new Date(task.start_at),
      selectedDate,
      timeSegment,
    )
  })
  return nearestTimeTask
}
