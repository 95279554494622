import { useQuery } from '@apollo/client'
import {
  getDepartments,
  getDepartments_getSites_items,
  getDepartmentsVariables,
} from 'types/getDepartments'
import { GET_DEPARTMENTS } from 'components/Whiteboard/graphql'
import { useOrganisation } from 'src/context/organisation'
import { useMemo } from 'react'
import { SiteType } from 'types/globalTypes'
import { uniqBy } from 'lodash'

export const useSeparationSites = (siteIds: string[]) => {
  const [{ organisationId }] = useOrganisation()

  const { data, loading } = useQuery<getDepartments, getDepartmentsVariables>(
    GET_DEPARTMENTS,
    {
      variables: {
        organisationId,
      },
    },
  )

  const allSites = useMemo(() => {
    return data?.getSites?.items ?? []
  }, [data?.getSites?.items])

  const separationSites = useMemo(() => {
    return getSeparationSites(siteIds, allSites)
  }, [siteIds, allSites])

  const isWithinBusinessSeparation = (siteId: string): boolean => {
    const site = getAllSitesMap(allSites)[siteId]

    if (!site) return false

    if (site.type === SiteType.SITE) return true

    return separationSites.find(site => site.id === siteId) !== undefined
  }

  return {
    loading,
    separationSites,
    isWithinBusinessSeparation,
  }
}

const getAllSitesMap = (
  allSites: getDepartments_getSites_items[],
): Record<string, getDepartments_getSites_items> => {
  return allSites.reduce(
    (acc: Record<string, getDepartments_getSites_items>, site) => {
      acc[site.id] = site
      return acc
    },
    {},
  )
}

const getBusinessSeparationSites = (
  currentSite: getDepartments_getSites_items,
  allSites: getDepartments_getSites_items[],
): getDepartments_getSites_items[] => {
  return allSites.filter(
    site =>
      site.parent_site_id === currentSite.id || site.id === currentSite.id,
  )
}

const getDivisionSeparationSites = (
  currentSite: getDepartments_getSites_items,
  allSites: getDepartments_getSites_items[],
): getDepartments_getSites_items[] => {
  return allSites.filter(
    site =>
      site.parent_site_id === currentSite.parent_site_id ||
      site.id === currentSite.parent_site_id,
  )
}

const getSeparationSites = (
  siteIds: string[],
  allSites: getDepartments_getSites_items[],
): getDepartments_getSites_items[] => {
  // handle edge case that siteIds is empty
  if (siteIds.length === 0) return allSites

  const allSitesMap = getAllSitesMap(allSites)

  return uniqBy(
    siteIds.reduce((acc: getDepartments_getSites_items[], siteId) => {
      const currentSite = allSitesMap[siteId]

      if (!currentSite) return acc

      switch (currentSite.type) {
        case SiteType.DIVISION:
          return acc.concat(getDivisionSeparationSites(currentSite, allSites))
        case SiteType.BUSINESS:
          return acc.concat(getBusinessSeparationSites(currentSite, allSites))
        case SiteType.SITE:
          return acc.concat(allSites)
        default:
          return acc
      }
    }, []),
    'id',
  )
}
