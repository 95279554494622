import { useQuery } from '@apollo/client'
import { toast } from 'components/common'
import { useOrganisation } from 'src/context/organisation'
import {
  getDataSyncLogs,
  getDataSyncLogsVariables,
} from 'src/types/getDataSyncLogs'
import { GET_DATA_SYNC_LOGS } from '../graphql'

export const useGetDataSyncLogs = () => {
  const [{ organisationId }] = useOrganisation()

  const { data, loading, refetch } = useQuery<
    getDataSyncLogs,
    getDataSyncLogsVariables
  >(GET_DATA_SYNC_LOGS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisationId,
    },
    onError: err => {
      toast.error(err.toString())
    },
  })
  return { data, loading, refetch }
}
