import { AnesthesiaChartEntry } from 'src/context/types/anesthesia'
import { DayRange } from 'src/hocs/timeContext'

export const MAX_CHART_VALUE = 400

const isBetweenDates = (date: Date, start: Date, end: Date) => {
  return date >= start && date <= end
}

export const getChartHighestValue = (
  data: AnesthesiaChartEntry[],
  visibleDays: DayRange,
): number => {
  return data.reduce((acc: number, { data }) => {
    const highest = data.reduce((highestAcc: number, { value, time }) => {
      if (visibleDays && isBetweenDates(time, visibleDays[0], visibleDays[1])) {
        return Math.max(value, highestAcc)
      }
      return highestAcc
    }, 0)
    return Math.max(highest, acc)
  }, 0)
}
