import React from 'react'
import { StyleProp, Text, View, ViewStyle } from 'react-native'
import { stringToColor } from 'src/utils/stringToColor'

type AvatarInitialsProps = {
  name?: string
  size?: number
  style?: StyleProp<ViewStyle>
}

export const AvatarInitials: React.FC<AvatarInitialsProps> = ({
  name,
  size = 52,
  style,
}) => {
  const initials = name
    ?.split(' ')
    .filter(s => !!s)
    .map(s => s[0].toLocaleUpperCase())
    .slice(0, 3)
    .join('')

  const backgroundColor = stringToColor(name ?? '')

  return (
    <View
      testID="avatarInitial"
      style={[
        { backgroundColor, alignItems: 'center', justifyContent: 'center' },
        style,
      ]}
    >
      <Text style={{ fontSize: size / 2, color: 'white' }}>{initials}</Text>
    </View>
  )
}
