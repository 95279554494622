import { MinWidth } from 'components/common/Button'
import { CubexTransactionStatus, Status } from 'src/types/globalTypes'
import { Status as StatusEnum } from 'types/globalTypes'

import { Action, FluidAction, GetButtonTitleParams } from '../types'

export const buttonMinWidth: MinWidth = {
  small: '90%',
  large: '90%',
}

export const getIsTaskDoneOrProgress = (status?: Status | null) => {
  return status === Status.DONE || status === Status.IN_PROGRESS
}

export const getIsTaskDoneOrProgressOrSkipped = (status?: Status | null) => {
  return getIsTaskDoneOrProgress(status) || status === Status.MISSED_ON_PURPOSE
}

export const getIsTaskDoneOrSkipped = (status?: Status | null) => {
  return status === Status.DONE || status === Status.MISSED_ON_PURPOSE
}

export const getIsTaskSuceessOnCubex = (
  status?: CubexTransactionStatus | null | undefined,
) => {
  return status === CubexTransactionStatus.SUCCESS
}

export const getIsTaskMissedOrPendingOrDue = (status?: Status | null) => {
  return (
    status === Status.MISSED ||
    status === Status.PENDING ||
    status === Status.DUE
  )
}

export const getIsTaskSkipDisabledWithMandatoryNotes = (
  message: string | undefined,
) => {
  return !!message
}

export const getButtonTitle = ({ action, t, task }: GetButtonTitleParams) => {
  if (!task) {
    return t('task:taskAddEdit:addComplete')
  }
  const isInProgressTask = task.status === StatusEnum.IN_PROGRESS
  switch (action) {
    case Action.EDIT:
      return t(
        isInProgressTask
          ? 'task:taskTransition:submit:done'
          : 'task:taskTransition:submit:changes',
      )
    case Action.COMPLETE:
      return t('task:taskTransition:submit:done')
    case Action.RESCHEDULE:
      return t('task:taskAddEdit:rescheduleTask')
    case FluidAction.EDIT_FLUID:
      return t('task:taskAddEdit:fluids:editFluid')
    case FluidAction.STOP:
      return t('task:taskAddEdit:fluids:stopFluid')
    case FluidAction.SCHEDULE_FLUID:
      return t('task:taskAddEdit:fluids:scheduleFluidButton')
    case FluidAction.CHANGE_FLUID_RATE:
      return t('task:taskAddEdit:fluids:changeFluidRate')
    case FluidAction.START:
      return t('task:taskAddEdit:fluids:startFluid')
    case FluidAction.CREATE_FLUID:
      return t('task:taskAddEdit:fluids:createAndStartFluid')
    default:
      return ''
  }
}
