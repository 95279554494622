import React, { useCallback, useMemo, useState } from 'react'
import { useRoute } from '@react-navigation/native'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { OnPressTask } from 'components/Grid/GridFlatList'
import { ScaleTimeFn } from 'components/Grid/GridTimeLine/GridTimeline'
import { SheetScreenRouteProp } from 'components/Sheet/Sheet'
import { getTreatmentIsMedication } from 'components/Task/utils/getTreatmentInfo'
import { TreatmentSetupDrawerContent } from 'components/TreatmentSetup/TreatmentSetupDrawerContent'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { differenceInHours } from 'date-fns'
import { isNil, maxBy, noop } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import { useFlags } from 'react-native-flagsmith/react'
import { isTreatmentPending } from 'src/hooks/useApprovals'
import { usePatientCallParameters } from 'src/hooks/usePatientCallParameters'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskItem } from 'src/types/getSheet'
import { Status } from 'types/globalTypes'

import {
  treatmentHeight as smallTreatmentHeight,
  treatmentHeightLarge,
  treatmentHeight,
} from '../common'
import { ParameterRange, Task } from '../Task/Task'
import { TreatmentSheet } from './common/types'
import { getTaskStartAt, getTaskStopAt } from './utils/getTaskDate'
import { useTasksWithPosition } from './utils/useTasksWithPosition'

// Discontinued task will be the closet task around discontinued date, the temporary range is 2 hours.
const discontinuedTaskRange = 2

export const returnDiscontinuedTask = (
  tasks: TaskItem[],
  isDiscontinued: boolean,
  treatment: TreatmentSheet,
) => {
  const missedTasks = tasks.filter(task => task.status === Status.MISSED)
  const missedTaskSize = missedTasks.length
  if (missedTaskSize < 1 || !isDiscontinued) return null

  const lastTask = maxBy(missedTasks, 'start_at')!
  const startAtDate = new Date(lastTask.start_at ?? '')
  const endAtDate = new Date(lastTask.stop_at ?? '')
  const discontinuedDate = new Date(treatment.discontinued_at)

  const isTaskInRange =
    discontinuedTaskRange >=
    Math.abs(differenceInHours(endAtDate, discontinuedDate))

  const isRunningTask =
    discontinuedDate < endAtDate && startAtDate < discontinuedDate
  if (isTaskInRange || isRunningTask) return lastTask
  return null
}

// TODO: validate the task date string is an ISO date string and parsable
export type TreatmentLeaf = {
  onPressTask?: OnPressTask
  positionInGridFn: ScaleTimeFn
  tasks: TaskItem[]
  treatment: TreatmentSheet
  isFinalized: boolean
  isTreatmentFullHeight?: boolean
}

export const GridTreatmentLeaf: React.FC<TreatmentLeaf> = React.memo(
  ({
    positionInGridFn,
    tasks,
    treatment,
    onPressTask = noop,
    isFinalized,
    isTreatmentFullHeight = false,
  }) => {
    const hasConditionalFields = !!treatment.conditional
    const { params } = useRoute<SheetScreenRouteProp>()
    const { patientId, sheetId } = params
    const getPatientCallParameterRangeByProductID =
      usePatientCallParameters(patientId)

    const range = getPatientCallParameterRangeByProductID(treatment.product?.id)

    const { t } = useTranslation()

    const { show_deleted_on_purpose } = useFlags([
      FeatureFlagNames.ShowDeletedOnPurpose,
    ])

    const [isDrawerVisible, setIsDrawerVisible] = useState(false)
    const toggleDrawer = useCallback(
      () => setIsDrawerVisible(isVisible => !isVisible),
      [],
    )

    const isDiscontinued = !!treatment.discontinued_at
    const isMedication = getTreatmentIsMedication(treatment)
    const isNeedFinishSetup = hasConditionalFields && !isDiscontinued
    const isFinalizedOrNotNeedSetup = !isNeedFinishSetup || isFinalized
    const isTreatmentInactive = isDiscontinued || isFinalized

    const isWebAndIsFinalizedOrNotNeedSetup =
      Platform.OS === 'web' && isFinalizedOrNotNeedSetup

    const handlePressTask = useCallback(
      (task: TaskItem) => {
        onPressTask(treatment, task)
      },
      [onPressTask, treatment],
    )

    const discontinuedTask = useMemo(() => {
      return returnDiscontinuedTask(tasks, isDiscontinued, treatment)
    }, [tasks, isDiscontinued, treatment])

    // Populate tasks with vertical position info to handle overlapping scenario. Currently only show 2 overlapping tasks.
    const tasksWithPosition = useTasksWithPosition(tasks)

    const isPendingApproval = isTreatmentPending(treatment)

    return (
      /* With pointerEvents="none" TouchableOpacity below disabled, stops the
    click event in GridTreatment which call getTouchCoordinates. Without isWeb
    check stop click on task. https://reactnative.dev/docs/view#pointerevents  */
      <View pointerEvents={isWebAndIsFinalizedOrNotNeedSetup ? 'none' : 'auto'}>
        <TouchableOpacity
          disabled={isFinalizedOrNotNeedSetup}
          onPress={toggleDrawer}
          style={[
            isTreatmentFullHeight
              ? styles.medicationContainer
              : styles.container,
            isNeedFinishSetup && styles.warningBackground,
          ]}
          activeOpacity={0.6}
        >
          {tasksWithPosition.map(({ task, tasksInCell, cellOrder }) => {
            // skip if task deleted
            if (
              !show_deleted_on_purpose.enabled &&
              task.status === Status.DELETED_ON_PURPOSE
            ) {
              return null
            }
            // skip if treatmentInactive and pending
            if (isTreatmentInactive && task.status === Status.PENDING) {
              return null
            }

            const isTaskDiscontinued = discontinuedTask?.id === task.id

            return (
              <TaskButton
                isMedication={isMedication}
                // In any IOS devices, isPendingApproval change will not trigger re-render
                // Add isPendingApproval to the key is to force update the Task Button once the isPendingApproval changes
                key={`${task.id}-${isPendingApproval}`}
                onPress={handlePressTask}
                positionInGridFn={positionInGridFn}
                task={task}
                range={range}
                isDiscontinued={isTaskDiscontinued}
                tasksInCell={tasksInCell}
                cellOrder={cellOrder}
                isPendingApproval={isPendingApproval}
              />
            )
          })}
        </TouchableOpacity>

        <SheetAwareSideDrawer
          title={t('treatment:setup')}
          visible={isDrawerVisible}
          onClose={toggleDrawer}
        >
          <TreatmentSetupDrawerContent
            onDone={toggleDrawer}
            treatmentId={treatment.id}
            patientId={patientId}
            sheetId={sheetId}
          />
        </SheetAwareSideDrawer>
      </View>
    )
  },
)

GridTreatmentLeaf.displayName = 'GridTreatmentLeaf'

type TaskButtonProps = {
  positionInGridFn: ScaleTimeFn
  isMedication: boolean
  onPress: (task: TaskItem) => void
  task: TaskItem
  isDiscontinued?: boolean | null
  isPendingApproval?: boolean | null
  tasksInCell: number
  cellOrder: number
  range?: ParameterRange
}

// when task time conflicts, at most show 4 layer of tasks
const MAX_TASK_CELL = 4

const TaskButton = React.memo(
  ({
    positionInGridFn,
    isMedication,
    onPress,
    task,
    isDiscontinued,
    tasksInCell,
    cellOrder,
    isPendingApproval,
    range,
  }: TaskButtonProps) => {
    const handlePress = useCallback(() => {
      onPress(task)
    }, [task, onPress])

    const startAt = getTaskStartAt(task)
    const stopAt = getTaskStopAt(task)
    const renderTasksInCell = Math.min(tasksInCell, MAX_TASK_CELL)
    const left = positionInGridFn(startAt)
    const width = (positionInGridFn(stopAt) ?? 0) - (left ?? 0)
    // Occupy the whole cell when there is only one in it.

    const treatmentHeight = isMedication
      ? treatmentHeightLarge
      : smallTreatmentHeight

    const height = treatmentHeight / renderTasksInCell
    // Put the first one on top, the rest all start from the second haft. For now.
    const top =
      (treatmentHeight * Math.min(cellOrder, MAX_TASK_CELL - 1)) /
      renderTasksInCell

    const taskValue =
      isMedication && task.assigned_user ? task.assigned_user : task.value ?? ''

    const isBilled = !isNil(task.billing_reference?.remote_id)

    return (
      <View
        pointerEvents={'auto'} // Re-enable touchable events
        style={[
          styles.taskItem,
          {
            left,
            width,
            height,
            top,
          },
        ]}
      >
        <Task
          comment={task.notes}
          id={task.id}
          isPending={task._pending}
          onPress={handlePress}
          status={task.status}
          value={taskValue}
          width={width}
          isDiscontinued={isDiscontinued}
          isBilled={isBilled}
          highlightColor={task.highlight_colour}
          isPendingApproval={isPendingApproval}
          range={isMedication ? undefined : range}
        />
      </View>
    )
  },
)

TaskButton.displayName = 'TaskButton'

const styles = StyleSheet.create({
  container: {
    height: treatmentHeight,
  },
  medicationContainer: {
    height: treatmentHeightLarge,
  },
  taskItem: {
    overflow: 'hidden',
    position: 'absolute',
  },
  warningBackground: {
    backgroundColor: '#fceed7',
    opacity: 0.6,
  },
})
