import { useRef } from 'react'
import { useMutation } from '@apollo/client'
import { RESET_TRAINING_ORGANISATION_DATA } from 'components/Settings/graphql'
import { useOrganisation } from 'src/context/organisation'
import {
  resetTrainingOrganisationData,
  resetTrainingOrganisationDataVariables,
} from 'types/resetTrainingOrganisationData'
import { useResetStatusToastSender } from './useResetStatusToastSender'

export const useResetTrainingOrganisationData = () => {
  const [{ organisationId }] = useOrganisation()
  const sendResetStatusToast = useResetStatusToastSender()
  const loadingToast = useRef<(() => void) | null>(null)

  const [resetTrainingOrganisationData, { loading }] = useMutation<
    resetTrainingOrganisationData,
    resetTrainingOrganisationDataVariables
  >(RESET_TRAINING_ORGANISATION_DATA)

  const resetTrainingOrganisation = () =>
    resetTrainingOrganisationData({
      variables: {
        id: organisationId,
      },
      update: (_, { data }) => {
        const { resetStatus, message: errorMessage } =
          data?.resetTrainingOrganisationData ?? {}

        if (resetStatus) {
          loadingToast.current = sendResetStatusToast(
            resetStatus,
            errorMessage!,
          )
        }
      },
    })

  return {
    resetTrainingOrganisation,
    loading,
    loadingToast,
  }
}
