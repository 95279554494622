import React from 'react'
import Svg, { Path, G, Polygon } from 'react-native-svg'

export const SvgCalendar: React.FC = () => (
  <Svg width={18} height={20}>
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G transform="translate(-376.000000, -21.000000)">
        <G>
          <G transform="translate(373.000000, 20.000000)">
            <G>
              <Polygon points="0 0 24 0 24 24 0 24" />
              <Path
                d="M19,3 L18,3 L18,1 L16,1 L16,3 L8,3 L8,1 L6,1 L6,3 L5,3 C3.89,3 3.01,3.9 3.01,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 L19,3 Z M19,19 L5,19 L5,8 L19,8 L19,19 L19,19 Z M7,10 L12,10 L12,15 L7,15 L7,10 Z"
                fill="#000000"
              />
            </G>
          </G>
        </G>
      </G>
    </G>
  </Svg>
)
