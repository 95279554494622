import React, { useCallback, useMemo, useRef, useState } from 'react'
import { CustomHorizontalScrollProps } from 'components/CustomScrollBar/types'
import { Colors } from 'constants/Colors'
import {
  Animated,
  GestureResponderEvent,
  PanResponder,
  Pressable,
  StyleSheet,
  View,
} from 'react-native'

import { scrollBarA11yLabel, scrollIndicatorA11yLabel } from './constants'

export const CustomHorizontalScroll: React.FC<CustomHorizontalScrollProps> =
  React.memo(
    ({
      containerStyle,
      dragOffset,
      indicatorStyle,
      indicatorRefOverride,
      onPanResponderMove,
      onScrollBarPress,
      scrollableWidth,
      scrollValue,
      visibleToCompleteWidthRatio,
    }) => {
      const [dragActive, setDragActive] = useState(false)

      const scrollIndicatorPosition = useMemo(
        () =>
          Animated.multiply(
            scrollValue,
            visibleToCompleteWidthRatio,
          ).interpolate({
            inputRange: [0, scrollableWidth],
            outputRange: [0, scrollableWidth],
            extrapolate: 'clamp',
          }),
        [scrollableWidth, scrollValue, visibleToCompleteWidthRatio],
      )

      const scrollControlRef = useRef<View>(null)

      const onPressScrollJump = useCallback(
        (e: GestureResponderEvent) => {
          ;(indicatorRefOverride || scrollControlRef).current?.measure(
            (x, _y, w) => {
              onScrollBarPress(
                (e.nativeEvent.locationX - w / 2 - x) /
                  visibleToCompleteWidthRatio,
              )
            },
          )
        },
        [
          scrollControlRef,
          onScrollBarPress,
          visibleToCompleteWidthRatio,
          indicatorRefOverride,
        ],
      )

      const panResponder = useRef(
        PanResponder.create({
          onPanResponderMove,
          onPanResponderGrant: ({ nativeEvent: { locationX } }) => {
            setDragActive(true)
            dragOffset.current = locationX
          },
          onPanResponderRelease: () => {
            setDragActive(false)
            dragOffset.current = 0
          },
          // start (and preventDefault) event during capture phase may have slight
          // perf advantage: see useCustomScroll->onCustomXPanResponderMove
          onMoveShouldSetPanResponderCapture: () => true,
          onStartShouldSetPanResponderCapture: () => true,
        }),
      ).current

      const [hover, setHover] = useState(false)
      const onMouseEnter = useCallback(() => setHover(true), [])
      const onMouseLeave = useCallback(() => setHover(false), [])
      const isIndicatorActive = hover || dragActive

      const pressableScrollbarStyle = useMemo(
        () => [styles.scrollBarContainer, containerStyle],
        [containerStyle],
      )

      return (
        <Pressable
          accessibilityLabel={scrollBarA11yLabel}
          onPressIn={onPressScrollJump}
          style={pressableScrollbarStyle}
        >
          <Animated.View
            accessibilityLabel={scrollIndicatorA11yLabel}
            // @ts-ignore
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={scrollControlRef}
            {...panResponder.panHandlers}
            style={[
              styles.scrollBar,
              isIndicatorActive && styles.scrollBarHover,
              indicatorStyle,
              {
                transform: [
                  {
                    translateX: scrollIndicatorPosition,
                  },
                ],
              },
            ]}
          />
        </Pressable>
      )
    },
  )

CustomHorizontalScroll.displayName = 'CustomHorizontalScroll'

const styles = StyleSheet.create({
  scrollBar: {
    backgroundColor: Colors.grid.scrollBackGround,
    borderRadius: 8,
    // @ts-ignore
    cursor: 'default',
    height: '100%',
  },
  scrollBarHover: {
    backgroundColor: Colors.contentTertiary,
  },
  scrollTouch: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  scrollBarContainer: {
    backgroundColor: Colors.treatment.gridBackground,
    cursor: 'default',
    bottom: 0,
    height: 16,
    paddingVertical: 1,
    position: 'absolute',
    right: 14,
  },
})
