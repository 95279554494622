import { useApolloClient, useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { useOrganisation } from 'src/context/organisation'
import { useTimeResolution } from 'src/hocs/timeContext'

import { RESCHEDULE_TASKS } from '../graphql'
import { RescheduleTasks, RescheduleTasksVariables, TaskFields } from '../types'
import { updateRescheduleTask, createDateString } from './useRescheduleTask'
import { TreatmentBaseWithTasks } from 'types/TreatmentBaseWithTasks'
import { ApprovalStatus } from 'types/globalTypes'
import { useApprovals } from 'src/hooks/useApprovals'
import { buildTreatmentWithTasksFragmentVariable } from 'components/Treatment/utils/buildTreatmentWithTasksFragmentVariable'

export const useRescheduleTasks = () => {
  const [{ organisationId }] = useOrganisation()
  const client = useApolloClient()
  const { fromToQueryDate } = useTimeResolution()

  const [rescheduleTasks] = useMutation<
    RescheduleTasks,
    RescheduleTasksVariables
  >(RESCHEDULE_TASKS, {
    onError: err => {
      toast.error(err.message)
    },
  })

  const { shouldUnapproveTreatment } = useApprovals()

  return (
    tasks: TaskFields[],
    newStartDateOrRescheduleMinutes: Date | number,
  ) => {
    // when the user set hours and minutes =0, nothing happens on the current task but reschedule repeating works.
    if (newStartDateOrRescheduleMinutes === 0) {
      return
    }
    const input = tasks.map(task => {
      const treatmentId = task.treatment_id

      const { startAt } = createDateString(
        task,
        newStartDateOrRescheduleMinutes,
      )

      return {
        id: task.id,
        notes: task.notes,
        organisation_id: organisationId,
        patient_id: task.patient_id,
        set_as_skipped: false,
        sheet_id: task.sheet_id,
        start_at: startAt,
        treatment_id: treatmentId,
      }
    })

    return rescheduleTasks({
      variables: { input },

      optimisticResponse: {
        rescheduleTasks: tasks.map(task => {
          const { startAt, stopAt } = createDateString(
            task,
            newStartDateOrRescheduleMinutes,
          )
          return {
            ...task,
            start_at: startAt,
            stop_at: stopAt,
          }
        }),
      },
      update: (_, { data }) => {
        data?.rescheduleTasks.forEach(rescheduleTask => {
          const fragmentVariable = buildTreatmentWithTasksFragmentVariable(
            rescheduleTask.treatment_id,
            fromToQueryDate,
          )

          const cachedTreatment =
            client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

          if (!cachedTreatment) return

          if (shouldUnapproveTreatment(cachedTreatment)) {
            client.writeFragment({
              ...fragmentVariable,
              data: {
                ...cachedTreatment,
                approval_status: ApprovalStatus.PENDING,
              },
            })
          }

          const newTask = rescheduleTask
          if (!newTask) {
            return
          }
          const oldTask = tasks.find(
            task => task.treatment_id === newTask.treatment_id,
          )
          if (!oldTask) {
            return
          }
          updateRescheduleTask(newTask, oldTask, client, fromToQueryDate)
        })
      },
    })
  }
}
