export enum OrganisationTimeout {
  ONE_MIN = '60000',
  TWO_MIN = '120000',
  THREE_MIN = '180000',
  FOUR_MIN = '240000',
  FIVE_MIN = '300000',
  TEN_MIN = '600000',
  FIFTEEN_MIN = '900000',
  THIRTY_MIN = '1800000',
  ONE_HOUR = '3600000',
  TWELVE_HOURS = '43200000',
  ONE_DAY = '86400000',
  NONE = '0',
}

export enum UserTimeout {
  ONE_MIN = 60000,
  TWO_MIN = 120000,
  THREE_MIN = 180000,
  FOUR_MIN = 240000,
  FIVE_MIN = 300000,
  TEN_MIN = 600000,
  FIFTEEN_MIN = 900000,
  THIRTY_MIN = 1800000,
  ONE_HOUR = 3600000,
  ONE_DAY = 86400000,
  NO_LIMIT = 0,
}
