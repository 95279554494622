import { useCallback } from 'react'
import { OperationVariables, QueryOptions } from '@apollo/client'
import { client } from 'src/apollo/client'

export const useClientQueryPromise = <T, TVariables extends OperationVariables>(
  options: QueryOptions<TVariables, T>,
) => {
  return useCallback(
    (variables: TVariables) =>
      client.query<T, TVariables>({ ...options, variables }),
    [options],
  )
}
