import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'

import { Select, TextInput } from 'components/common'
import { Status } from 'components/common/Form/Status'
import { getLocations, getLocationsVariables } from 'src/types/getLocations'
import { GET_LOCATIONS } from './graphql'
import { useOrganisation } from 'src/context/organisation'
import { SelectOption } from 'components/common/Select/Select.types'
import { getRoodRiddleThirdLevelLocations as getCageOptions } from './utils/roodRiddleThirdLevelLocations'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { useTranslation } from 'react-i18next'

type Props = {
  onChange: (newValue: any) => void
  location: any
  isEquine: boolean
}

export const LocationSelect: React.FC<Props> = ({
  onChange,
  location,
  isEquine,
}: Props) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()

  const [cageOptions, setCageOptions] = useState<SelectOption<string>[] | null>(
    null,
  )
  const [wardIsSet, setWardIsSet] = useState<boolean>(false)
  const [showCageError, setShowCageError] = useState<boolean>(false)

  const wardId = location.wardId
  const roomId = location.roomId
  const cage = location.enclosure

  const { data: topLevelLocations, loading: loadingWardOptions } = useQuery<
    getLocations,
    getLocationsVariables
  >(GET_LOCATIONS, {
    variables: {
      organisationId,
    },
  })

  const wardOptions = useMemo(
    () =>
      topLevelLocations?.getLocations?.items?.map(location => ({
        text: location.name,
        value: location.id,
      })) ?? [],
    [topLevelLocations?.getLocations?.items],
  )

  const [
    getRoomOptions,
    { data: secLevelLocations, loading: loadingRoomOptions },
  ] = useLazyQuery<getLocations, getLocationsVariables>(GET_LOCATIONS)

  const roomOptions = useMemo(
    () =>
      secLevelLocations?.getLocations?.items?.map(location => ({
        text: location.name,
        value: location.id,
      })) ?? [],
    [secLevelLocations?.getLocations?.items],
  )

  const getDisplayLocation = (
    type: 'ward' | 'room' | 'cage',
    value: string,
  ) => {
    let newWardId = location.wardId
    let newRoomId = location.roomId
    let newCage = location.enclosure
    switch (type) {
      case 'ward':
        // No room is selected when ward id change
        newWardId = value
        newRoomId = ''
        break
      case 'room':
        newRoomId = value
        break
      default:
        newCage = value
        break
    }

    const wardName = wardOptions.find(
      option => option.value === newWardId,
    )?.text
    const roomName = roomOptions.find(
      option => option.value === newRoomId,
    )?.text

    const locationDisplayLabel = [wardName, roomName, newCage]
      .filter(s => !!s)
      .join(', ')

    return locationDisplayLabel
  }

  useEffect(() => {
    if (!wardId) return
    getRoomOptions({
      variables: {
        organisationId,
        locationParentId: wardId,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wardId])

  useEffect(() => {
    const wardName = wardOptions.find(option => option.value === wardId)?.text
    const roomName = roomOptions.find(option => option.value === roomId)?.text
    setWardIsSet(!(wardId === null || wardId === ''))
    // TODO: see comment on roodRiddleThirdLevelLocations.ts, remove setCageOptions asap
    setCageOptions(getCageOptions(wardName, roomName))
  }, [
    wardId,
    roomId,
    cage,
    secLevelLocations,
    wardOptions,
    roomOptions,
    onChange,
  ])

  return (
    <>
      <Select
        dialog={false}
        label={isEquine ? 'Location' : 'Ward'}
        selected={location.wardId}
        options={wardOptions ?? []}
        loading={loadingWardOptions}
        allowClear={true}
        onChange={v => {
          const display = getDisplayLocation('ward', v)
          const newItem = {
            ...location,
            display,
            wardId: v,
            roomId: null,
            enclosure: null,
          }
          onChange(newItem)
        }}
      />

      <Select
        dialog={false}
        label={isEquine ? 'Barn' : 'Room'}
        loading={loadingRoomOptions}
        options={wardIsSet ? roomOptions : []}
        allowClear={true}
        onChange={v => {
          const display = getDisplayLocation('room', v)
          const newItem = {
            ...location,
            display,
            roomId: v,
          }
          onChange(newItem)
        }}
        selected={location.roomId}
      />

      {cageOptions ? (
        <Select
          dialog={false}
          a11yLabel={`${isEquine ? 'Stall' : 'Cage'} Select Input`}
          label={isEquine ? 'Stall' : 'Cage'}
          allowClear={true}
          options={cageOptions}
          selected={cage}
          onChange={v => {
            const display = getDisplayLocation('cage', v)
            const newItem = {
              ...location,
              display,
              enclosure: v,
            }
            onChange(newItem)
          }}
        />
      ) : (
        <Status
          status={createErrorStatus(
            t('patient:form.fieldRequired', {
              required: isEquine ? 'Location' : 'Ward',
            }),
            !wardIsSet && showCageError,
          )}
        >
          <TextInput
            label={isEquine ? 'Stall' : 'Cage'}
            value={cage}
            onFocus={() => setShowCageError(true)}
            onBlur={() => setShowCageError(false)}
            onChangeText={newValue => {
              const display = getDisplayLocation('cage', newValue)
              const newItem = {
                ...location,
                display,
                enclosure: newValue,
              }
              onChange(newItem)
            }}
            disabled={!wardIsSet && showCageError}
          />
        </Status>
      )}
    </>
  )
}
