import { useQuery } from '@apollo/client'
import { toast } from 'components/common'
import { GET_ACTIVE_TREATMENT_TEMPLATES } from 'src/components/TreatmentTemplate/graphql'
import { useOrganisation } from 'src/context/organisation'
import {
  getActiveTemplates as GetActiveTemplates,
  getActiveTemplatesVariables as GetActiveTemplatesVariables,
} from 'types/getActiveTemplates'
import { SelectedTemplate } from './createSheetData'

// extra *a* character optional for non-US spelling
export const MAGIC_ANESTHESIA_TEMPLATE_NAME = /Ana?esthesia \(Hidden\)/
export const MAGIC_ANESTHESIA_MONITORING_NAME = /Ana?esthesia Monitoring/

export function useGetAnesthesiaTemplates() {
  const [{ organisationId }] = useOrganisation()
  const { data: templatesData, loading } = useQuery<
    GetActiveTemplates,
    GetActiveTemplatesVariables
  >(GET_ACTIVE_TREATMENT_TEMPLATES, {
    variables: { organisation_id: organisationId },
    onError: err => {
      toast.error(err.message)
    },
  })

  const anesthesiaTemplates: SelectedTemplate[] | undefined =
    templatesData?.getTemplates.items
      ?.filter(
        template =>
          MAGIC_ANESTHESIA_TEMPLATE_NAME.test(template.name) ||
          MAGIC_ANESTHESIA_MONITORING_NAME.test(template.name),
      )
      .map(template => ({
        id: template.id,
        startTime: 'now',
        templateWorkflowId: null,
        applyWorkflow: false,
      }))

  return {
    loading,
    anesthesiaTemplates: anesthesiaTemplates ?? [],
  }
}
