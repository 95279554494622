import React from 'react'
import { StyleSheet, View } from 'react-native'
import { DefaultPasswordSvg } from 'src/design-system/components/Icon/DefaultPasswordIcon'
import { Paragraph } from 'src/design-system/components/Text/Paragraph'
import { Colors } from 'src/design-system/theme/colors'

export const DefaultStatus: React.FC<{ status: string }> = ({ status }) => {
  return (
    <View style={styles.container}>
      <DefaultPasswordSvg />
      <Paragraph size="S" style={styles.validPWStyle}>
        {status}
      </Paragraph>
    </View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center', gap: 8 },
  validPWStyle: { color: Colors.Contents.tertiary },
})
