import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { criticalNotesWidth } from 'components/PatientItem/PatientListTasksItems'
import {
  PATIENT_CARD_WIDTH,
  PATIENT_CARD_WIDTH_TABLET,
} from '../PatientItem/PatientListItem'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useTranslation } from 'react-i18next'

export const CriticalNotesHeader: React.FC = () => {
  const { t } = useTranslation()
  const { isMediumScreen } = useBreakpoint()
  return (
    <View
      style={[
        styles.criticalNotesContainer,
        isMediumScreen && styles.tableCriticalNotesContainer,
      ]}
    >
      <Text>{t('whiteboard:header.criticalNotes')}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  criticalNotesContainer: {
    marginLeft: 15 + PATIENT_CARD_WIDTH, // patient card width
    paddingBottom: 8,
    alignItems: 'center',
    width: criticalNotesWidth,
  },
  tableCriticalNotesContainer: {
    marginLeft: 17 + PATIENT_CARD_WIDTH_TABLET,
  },
})
