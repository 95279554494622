import React, { useRef, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { Avatar } from 'components/common/Avatar'
import { useKeyToUrl } from 'src/hooks/useKeyToUrl'
import { ImageUploaderDialog } from 'components/common/ImageUploader/ImageUploaderDialog'

type Props = {
  objectKey: string
  objectKeyPrefix: string
  onChange: (objectKey: string) => void
  placeholder?: string
  noImageText?: string
}

export const ImageUploader: React.FC<Props> = ({
  objectKey,
  objectKeyPrefix,
  onChange,
  placeholder,
  noImageText,
}) => {
  const newObjectKeyRef = useRef<string | null>(null)
  const [visible, setVisible] = useState(false)

  const toggleDialog = () => setVisible(!visible)
  const openDialog = () => {
    newObjectKeyRef.current = null
    setVisible(true)
  }

  const thumbnail = useKeyToUrl(objectKey, 100)

  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity onPress={openDialog} testID="PatientAvatar">
          <Avatar uri={thumbnail} size={50} name={placeholder} />
        </TouchableOpacity>
      </View>

      <ImageUploaderDialog
        visible={visible}
        objectKey={objectKey}
        objectKeyPrefix={objectKeyPrefix}
        onChange={onChange}
        noImageText={noImageText}
        toggleDialog={toggleDialog}
        newObjectKeyRef={newObjectKeyRef}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
