import React, { useMemo, useRef, useState } from 'react'
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native'
import { useTranslation } from 'react-i18next'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { SvgArrow, orientToDegree } from 'components/Icons'
import { EmergencyDrugsButton } from 'components/EmergencyDrugsButton/EmergencyDrugsButton'
import { IconButton } from 'components/IconButton'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { SvgClockRotateLeftSolid } from 'components/Icons/ClockRotateLeftSolid'
import { VRBottomSheet } from 'components/BottomSheet'
import { SheetHistoryContainer } from 'components/UserHistory/SheetHistory/SheetHistoryContainer'
import { USER_SHEET_HISTORY_ICON_SIZE } from 'components/Grid/NavIconLayout'
import { environment } from 'src/config'
import { SvgHyperLinkOut } from 'components/Icons/HyperLinkOut'
import {
  Colors as DesignSystemColors,
  Typography,
} from 'src/design-system/theme'

const isIOS = !environment.isWeb

export type HeaderButton = {
  action: () => void
  disabled?: boolean
  headerIcon?: React.ReactElement
  label: string
  subHeaderIcon?: React.ReactElement
  title: string
}

type RowButtonComponentProps = {
  disabled?: boolean
  icon?: React.ReactElement
  label: string
  onPress: (event: GestureResponderEvent) => void
  testID: string
  title: string
  colour?: string
  font?: string
}

const getSvgArrowIcon = (
  orient: keyof typeof orientToDegree,
  color: string,
) => <SvgArrow orient={orient} color={color} width={15} height={15} />

export const RowButton = ({
  icon,
  label,
  title,
  testID,
  onPress,
  disabled,
  colour = Colors.buttons.text,
  font = Fonts.semibold,
}: RowButtonComponentProps) => {
  const customStyle = useMemo(
    () => ({
      color: colour,
      fontFamily: font,
    }),
    [colour, font],
  )
  return (
    <TouchableOpacity
      accessibilityLabel={label}
      disabled={disabled}
      onPress={onPress}
      style={styles.rowButton}
      testID={testID}
    >
      {icon}
      <Text
        style={[
          styles.actionTitle,
          customStyle,
          disabled && styles.disabledText,
        ]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  )
}

export type SubHeaderProps = {
  actionButton?: HeaderButton | null
  backButton?: HeaderButton | null
  headlineKey?: string
  headline?: string | null
  subHeadlineKey?: string
  subHeadline?: string | JSX.Element | null
  hyperlinkHeadline?: string
  hyperlinkOnClick?: () => void
  headlineParams?: object
  icon?: JSX.Element
  isBorderVisible?: boolean
  showEmergButton?: boolean
}

export const SubHeader: React.FC<SubHeaderProps> = ({
  actionButton,
  backButton,
  headline,
  headlineKey,
  headlineParams,
  icon,
  subHeadline,
  subHeadlineKey,
  hyperlinkHeadline,
  hyperlinkOnClick,
  isBorderVisible = true,
  showEmergButton = false,
}) => {
  const { t } = useTranslation()
  const backIcon = useMemo(
    () => getSvgArrowIcon('left', Colors.buttons.blue),
    [],
  )
  const isEmergBtnSheet = !headlineKey && showEmergButton
  const isPatientsList = headlineKey && showEmergButton
  const bottomSheetRef = useRef<BottomSheetModal>(null)
  const [isSheetHistoryOpen, setIsSheetHistoryOpen] = useState(false)

  const openSheetHistory = () => {
    if (isIOS) bottomSheetRef?.current?.present()
    setIsSheetHistoryOpen(true)
  }

  const dismissSheetHistory = () => {
    if (isIOS) bottomSheetRef?.current?.dismiss()
    setIsSheetHistoryOpen(false)
  }

  return (
    <View style={[styles.container, !isBorderVisible && styles.noBorderBottom]}>
      <View style={styles.actionRow}>
        {backButton ? (
          <RowButton
            testID="BackButton"
            title={` ${t(backButton.title)}`}
            label={t(backButton.label)}
            onPress={backButton?.action}
            icon={backIcon}
            colour={Colors.buttons.blue}
          />
        ) : (
          <View />
        )}

        {isEmergBtnSheet ? (
          <EmergencyDrugsButton
            isTextButton={true}
            textStyle={styles.emergencyText}
          />
        ) : null}

        {actionButton ? (
          <RowButton
            disabled={actionButton.disabled}
            icon={actionButton.subHeaderIcon}
            label={t(actionButton.label)}
            onPress={actionButton.action}
            testID="ActionButton"
            title={t(actionButton.title)}
          />
        ) : null}
      </View>

      {headline || headlineKey ? (
        <View style={styles.headlineWrapper}>
          <Text testID="HeaderHeadline" style={styles.headline}>
            {headlineKey ? t(headlineKey, headlineParams) : headline}
          </Text>
          {isPatientsList ? (
            <View style={styles.headlineIcons}>
              <IconButton
                action={openSheetHistory}
                containerStyle={styles.sheetHistoryButton}
                iconColor={Colors.buttons.blue}
                icon={SvgClockRotateLeftSolid}
                label={t('history:buttonLabel')}
                iconHeight={USER_SHEET_HISTORY_ICON_SIZE}
                iconWidth={USER_SHEET_HISTORY_ICON_SIZE}
              />
              {isIOS ? (
                <VRBottomSheet
                  title={t('history:sheet.title')}
                  ref={bottomSheetRef}
                  handleClose={dismissSheetHistory}
                >
                  <SheetHistoryContainer
                    visible={isSheetHistoryOpen}
                    onClose={dismissSheetHistory}
                  />
                </VRBottomSheet>
              ) : (
                <SheetHistoryContainer
                  visible={isSheetHistoryOpen}
                  onClose={dismissSheetHistory}
                />
              )}
              <EmergencyDrugsButton isTextButton={false} />
            </View>
          ) : null}
        </View>
      ) : null}

      <View style={styles.headers}>
        {icon ? <View style={styles.icon}>{icon}</View> : null}
        <View style={styles.flex}>
          {subHeadlineKey || subHeadline ? (
            typeof subHeadline === 'string' ? (
              <Text style={styles.subHeadline}>
                {subHeadlineKey ? t(subHeadlineKey) : subHeadline}
              </Text>
            ) : (
              /* TODO: subHeadline is better change to a render function renderSubHeadline: () => JSX.Element | null */
              subHeadline
            )
          ) : null}

          {hyperlinkHeadline ? (
            <TouchableOpacity
              onPress={hyperlinkOnClick}
              style={styles.hyperlinkTextButton}
            >
              <Text style={[styles.hyperLinkText]}>{hyperlinkHeadline}</Text>
              <SvgHyperLinkOut width={14} height={14} />
            </TouchableOpacity>
          ) : null}
        </View>
      </View>
    </View>
  )
}

export const styles = StyleSheet.create({
  actionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionTitle: {
    fontSize: 16,
    paddingTop: 2,
  },
  container: {
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    paddingVertical: 4,
    paddingHorizontal: 15,
  },
  disabledText: {
    opacity: 0.5,
  },
  flex: {
    flex: 1,
  },
  headers: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headline: {
    fontFamily: Fonts.bold,
    fontSize: 20,
  },
  emergencyText: {
    fontFamily: Fonts.semibold,
    fontSize: 16,
    marginHorizontal: 4,
    color: Colors.error,
  },
  headlineWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    justifyContent: 'space-between',
  },
  icon: {
    paddingRight: 14,
  },
  noBorderBottom: {
    borderBottomWidth: 0,
  },
  rowButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
  },
  subHeadline: {
    fontFamily: Fonts.regular,
    fontSize: 16,
  },
  sheetHistoryButton: {
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  headlineIcons: { flexDirection: 'row' },
  hyperLinkText: {
    ...Typography.Label.M,
    color: DesignSystemColors.Contents.accent,
    marginRight: 3,
  },
  hyperlinkTextButton: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
})
