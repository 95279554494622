import React from 'react'
import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { routeNavs } from 'components/HeaderNavButtons'
import { StyleSheet, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Colors } from 'src/constants/Colors'

import { FooterNavItems } from './FooterNavItems'

export const FOOTER_HEIGHT = 53

type FooterProps = BottomTabBarProps & {
  isSheetViewActive: boolean
}

const MinHeightFooter = () => (
  <View testID="Minimal height nav footer" style={styles.minHeight} />
)

export const Footer: React.FC<FooterProps> = React.memo(
  // Pass navigation and nav-state info from tab navigator
  ({ descriptors, isSheetViewActive, navigation, state }) => {
    const insets = useSafeAreaInsets()
    if (isSheetViewActive) {
      // A (1px) view needed to preserve layout on small-width web layouts.
      // Be sure to test small web view before removing
      return <MinHeightFooter />
    }
    return (
      <View
        style={[
          styles.container,
          {
            height: FOOTER_HEIGHT + insets.bottom,
            paddingBottom: insets.bottom,
          },
        ]}
      >
        <View style={styles.content}>
          <FooterNavItems
            descriptors={descriptors}
            navigation={navigation}
            routeNavs={routeNavs}
            state={state}
          />
        </View>
      </View>
    )
  },
)

Footer.displayName = 'Footer'

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.backgroundGrey2,
    borderColor: Colors.borderGrey,
    borderTopWidth: 1,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  minHeight: {
    height: 1,
  },
})
