import { DatePicker } from '@material-ui/pickers'
import { StyleProp, StyleSheet, Text, TextStyle, View } from 'react-native'
import { addHours, addMinutes, format, subHours } from 'date-fns'
import React from 'react'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { useTranslation } from 'react-i18next'
import { FormField } from '../Form/FormField'
import { SvgCalendar } from 'components/Icons'
import { useSheetContext } from 'src/context/sheet'
import { SheetType } from 'src/types/globalTypes'
import { TimePicker } from '../TimePicker/TimePicker.web'
import { Colors } from 'src/design-system/theme'
import { DatePickerWarning } from './DatePickerWarning'
import { useBreakpoint } from 'src/hocs/breakpoint'

type Props = {
  value?: Date | null
  disabled: boolean
  minDate: Date | undefined
  open: boolean
  active: boolean
  onPress: () => void
  onChange: () => void
  onClose: () => void
  TextFieldComponent: () => JSX.Element
  textStyle?: StyleProp<TextStyle>
  labelStyle?: StyleProp<TextStyle>
  title?: string
  warningInfo: {
    type: string
    pickedDate?: Date | null
    minDateTime?: Date
  }
}

export const CustomDateTimePicker: React.FC<Props> = ({
  value = new Date(),
  disabled,
  minDate,
  open,
  active,
  onPress,
  onChange,
  onClose,
  textStyle,
  labelStyle,
  warningInfo,
  title,
}) => {
  const { adminDateFormat, adminTimeFormat } = useAdminTimeFormat()
  const [sheetContext] = useSheetContext()
  const isAnaesthesiaSheet = sheetContext.sheetType === SheetType.ANAESTHESIA // TODO get business logic out of Common Component
  const timeOptionsInterval = isAnaesthesiaSheet ? 5 : 30
  const { t } = useTranslation()

  const { isSmallishScreen } = useBreakpoint()

  const InputContainer = () => {
    const displayValue = value
      ? format(new Date(value), adminDateFormat)
      : t(`common.dateTimePicker.placeholder.date`)
    return (
      <>
        <FormField
          disabled={disabled}
          value={displayValue}
          active={active}
          onPress={onPress}
          textStyle={textStyle}
          labelStyle={labelStyle}
          icon={isSmallishScreen ? <></> : <SvgCalendar />}
          style={styles.input_container}
        />
      </>
    )
  }

  return (
    <View>
      <View style={styles.picker_container}>
        <View
          style={[
            styles.date_container,
            isSmallishScreen ? styles.resetDateContainer : null,
          ]}
        >
          {title ? (
            <View style={styles.title_container}>
              <Text>{title}</Text>
            </View>
          ) : null}
          <DatePicker
            value={value}
            minDate={minDate}
            open={open}
            onChange={onChange}
            onClose={onClose}
            TextFieldComponent={InputContainer}
          />
        </View>
        <View
          style={[
            styles.time_container,
            isSmallishScreen ? styles.resetTimeContainer : null,
          ]}
        >
          <TimePicker
            value={value ?? undefined}
            intervalSeconds={timeOptionsInterval * 60}
            minTime={
              isAnaesthesiaSheet && value ? subHours(value, 2) : undefined
            } // TODO no business logic
            maxTime={
              isAnaesthesiaSheet && value
                ? addMinutes(addHours(value, 2), timeOptionsInterval)
                : undefined
            }
            allowCustomTime
            format={adminTimeFormat}
            onChange={onChange}
            isResetWidth={isSmallishScreen}
          />
        </View>
      </View>
      <View>
        {!!warningInfo && (
          <DatePickerWarning
            warningInfo={{ ...warningInfo, pickedDate: value }}
          />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  picker_container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  date_container: {
    flexDirection: 'row',
    flex: 1,
    borderRightWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  time_container: {
    flex: 1,
  },
  title_container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRightWidth: 1,
    borderRightColor: Colors.Borders.primary,
    borderBottomWidth: 1,
    borderBottomColor: Colors.Borders.primary,
    width: 64,
  },
  input_container: {
    flex: 1,
  },
  resetDateContainer: {
    flex: 0.6,
    paddingRight: 0,
    borderRightWidth: 1,
    borderRightColor: Colors.Borders.primary,
  },
  resetTimeContainer: {
    flex: 0.4,
  },
})
