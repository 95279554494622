import React, { createContext, useContext, useReducer } from 'react'
import { TemplateToAddToSheet } from 'components/CreateSheet/createSheetData'
import { getDepartments_getSites_items } from 'src/types/getDepartments'
import { UnreachableCaseError } from 'src/utils/unreachableCaseError'
import { OrgChoice } from 'components/common/ChooseOrganisation/useFetchUserOrganisationChoices'

export enum OrganisationActionTypes {
  setHasPIMSIntegration = 'setHasPIMSIntegration',
  setPIMSIntegration = 'setPIMSIntegration',
  setLastCreatedSheetId = 'setLastCreatedSheetId',
  setOrganisationId = 'setOrganisationId',
  setOrganisationSites = 'setOrganisationSites',
  setOrganisationChoices = 'setOrganisationChoices',
  setTemplatesToAddToSheet = 'setTemplatesToAddToSheet',
}

type OrganisationSite = getDepartments_getSites_items

export type OrganisationAction =
  | {
      type: OrganisationActionTypes.setLastCreatedSheetId
      lastCreatedSheetId: string | null
    }
  | {
      type: OrganisationActionTypes.setTemplatesToAddToSheet
      templatesToAddToSheet: TemplateToAddToSheet[] | null
    }
  | {
      type: OrganisationActionTypes.setHasPIMSIntegration
      hasPIMSIntegration: boolean
    }
  | {
      type: OrganisationActionTypes.setPIMSIntegration
      PIMSIntegration: string | null
    }
  | {
      type: OrganisationActionTypes.setOrganisationId
      organisationId: string
    }
  | {
      type: OrganisationActionTypes.setOrganisationChoices
      organisationChoices: OrgChoice[]
    }
  | {
      type: OrganisationActionTypes.setOrganisationSites
      organisationSites: OrganisationSite[] | null
    }

export type OrganisationDispatch = (action: OrganisationAction) => void

export type OrganisationState = {
  hasPIMSIntegration: boolean | null
  PIMSIntegration: string | null
  lastCreatedSheetId: string | null
  organisationId: string
  organisationChoices: OrgChoice[] | null
  organisationSites: OrganisationSite[] | null
  templatesToAddToSheet: TemplateToAddToSheet[] | null
}

export const getInitialState = (
  overrides?: Partial<OrganisationState>,
): OrganisationState => ({
  hasPIMSIntegration: null,
  PIMSIntegration: null,
  lastCreatedSheetId: null,
  organisationId: '',
  organisationChoices: null,
  organisationSites: null,
  templatesToAddToSheet: null,
  ...overrides,
})

const OrganisationStateContext = createContext<OrganisationState | undefined>(
  undefined,
)
OrganisationStateContext.displayName = 'OrganisationStateContext'

const OrganisationDispatchContext = createContext<
  OrganisationDispatch | undefined
>(undefined)
OrganisationDispatchContext.displayName = 'OrganisationDispatchContext'

function organisationReducer(
  state: OrganisationState,
  action: OrganisationAction,
): OrganisationState {
  switch (action.type) {
    case OrganisationActionTypes.setLastCreatedSheetId: {
      return { ...state, lastCreatedSheetId: action.lastCreatedSheetId }
    }
    case OrganisationActionTypes.setTemplatesToAddToSheet: {
      return { ...state, templatesToAddToSheet: action.templatesToAddToSheet }
    }
    case OrganisationActionTypes.setHasPIMSIntegration: {
      return { ...state, hasPIMSIntegration: action.hasPIMSIntegration }
    }
    case OrganisationActionTypes.setPIMSIntegration: {
      return { ...state, PIMSIntegration: action.PIMSIntegration }
    }
    case OrganisationActionTypes.setOrganisationId: {
      return { ...state, organisationId: action.organisationId }
    }
    case OrganisationActionTypes.setOrganisationChoices: {
      return { ...state, organisationChoices: action.organisationChoices }
    }
    case OrganisationActionTypes.setOrganisationSites: {
      return { ...state, organisationSites: action.organisationSites }
    }
    default: {
      throw new UnreachableCaseError(action)
    }
  }
}

function useOrganisationState() {
  const context = useContext(OrganisationStateContext)
  if (context === undefined) {
    throw new Error(
      'useOrganisationState must be used within a OrganisationProvider',
    )
  }
  return context
}

function useOrganisationDispatch() {
  const context = useContext(OrganisationDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useOrganisationDispatch must be used within a OrganisationProvider',
    )
  }
  return context
}

type ProviderProps = {
  initialState?: Partial<OrganisationState>
  children: React.ReactElement
}

export const OrganisationProvider: React.FC<ProviderProps> = ({
  children,
  initialState,
}) => {
  const [state, dispatch] = useReducer(
    organisationReducer,
    getInitialState(initialState),
  )

  return (
    <OrganisationStateContext.Provider value={state}>
      <OrganisationDispatchContext.Provider value={dispatch}>
        {children}
      </OrganisationDispatchContext.Provider>
    </OrganisationStateContext.Provider>
  )
}

type UseOrganisation = () => [OrganisationState, OrganisationDispatch]
export const useOrganisation: UseOrganisation = () => {
  return [useOrganisationState(), useOrganisationDispatch()]
}
