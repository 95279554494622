import React, { useCallback, useState, useRef } from 'react'
import MenuRaw from '@material-ui/core/Menu'
import MenuItemRaw from '@material-ui/core/MenuItem'
import { Fonts } from 'constants/Fonts'
import { find, isArray, noop } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  LayoutChangeEvent,
  StyleSheet,
  Text,
} from 'react-native'
import { withCreateElement } from 'src/hocs/withCreateElement'

import { FormField } from '../Form/FormField'
import { createPlaceholderOption } from './common'
import { PossibleSelectValues, Props, SelectOption } from './Select.types'
import {
  getPickerIconName,
  SortOptionsEnum,
} from 'components/Patient/PatientListSort'
import { Avatar } from '../Avatar'
import { View } from 'react-native-web'
import { Colors } from 'constants/Colors'

const Menu = withCreateElement(MenuRaw)
const MenuItem = withCreateElement(MenuItemRaw)
const MENU_ITEM_MIN_LENGTH = 100

// eslint-disable-next-line
export function Select<T extends PossibleSelectValues>({
  a11yLabel,
  clearLabel,
  disabled = false,
  label,
  onChange = noop,
  options,
  placeholder = ' ',
  selected,
  status,
  style,
  required = false,
  allowClear = false,
  loading = false,
  hideChevron,
  iconLeft,
  rightLabel,
  FieldComponent = FormField,
  textStyle,
  labelStyle,
  iconRight,
  fieldTextWrapperStyle,
  fRef,
  renderSelectItem,
  renderSelectTitle,
  selectItemStyle,
  hasAvatar = false,
  avatarStyle,
  searchInput,
  isOpenMenuOverride,
}: Props<T>) {
  const { t } = useTranslation()
  const fieldEleRef = useRef<null | HTMLElement>(null)

  const [inputWidth, setInputWidth] = useState(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const flatOptions = options.flat()

  const selectedOptionOrPlaceHolder =
    find(flatOptions, ['value', selected]) ||
    createPlaceholderOption(placeholder)

  const openMenu = useCallback(() => {
    requestAnimationFrame(() => setAnchorEl(fieldEleRef.current))
  }, [])

  const closeMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onPress = useCallback(
    (value: T) => {
      onChange(value)
      setAnchorEl(null)
    },
    [onChange],
  )

  const onInputLayout = useCallback(
    (event: LayoutChangeEvent) => {
      fieldEleRef.current = (event.nativeEvent as any).target
      if (typeof fRef === 'function') {
        fRef(fieldEleRef.current)
      } else if (!!fRef) {
        fRef.current = fieldEleRef.current
      }
      setInputWidth(event.nativeEvent.layout.width)
    },
    [fRef],
  )

  const getPickerShowText = (pickerLabelName: string) => {
    const selectedTextToShow = pickerLabelName.split(' ')[0]
    return selectedTextToShow
  }

  const {
    text: selectedText,
    value: selectedValue,
    icon: selectedIcon,
  } = selectedOptionOrPlaceHolder

  let selectedTextToShow: string | React.ReactElement =
    selectedText || `${selectedValue}`
  const pickerIconName = getPickerIconName(selectedValue as SortOptionsEnum)

  if (renderSelectTitle) {
    selectedTextToShow = renderSelectTitle(selectedTextToShow)
  } else if (!!pickerIconName) {
    selectedTextToShow = getPickerShowText(selectedTextToShow)
  }

  const renderMenuItem = useCallback(
    (option: SelectOption<T>, idx: number) => {
      const { text, value, icon } = option
      let pickerItem: string | React.ReactElement = text || `${value}`
      const isSelected = option.value === selected && !searchInput
      const idSuffix = pickerItem.replace(' ', '_')
      const testID = `MenuItem_${idSuffix}`
      const iconName = getPickerIconName(value as SortOptionsEnum)

      if (renderSelectItem) {
        pickerItem = renderSelectItem(pickerItem)
      } else if (!!iconName) {
        pickerItem = getPickerShowText(pickerItem)
      }

      return (
        <MenuItem
          key={idx}
          selected={isSelected}
          onClick={() => onPress(option.value)}
          testID={testID}
        >
          {hasAvatar ? <Avatar name={option.text ?? ''} /> : null}
          {
            <Text
              style={[
                {
                  fontFamily: isSelected ? Fonts.bold : Fonts.regular,
                  marginRight: 10,
                },
                selectItemStyle,
              ]}
            >
              {pickerItem}
            </Text>
          }
          {icon}
        </MenuItem>
      )
    },
    [
      hasAvatar,
      onPress,
      renderSelectItem,
      searchInput,
      selectItemStyle,
      selected,
    ],
  )

  const mapOptionGroups = useCallback(() => {
    // If options is groups of options
    if (isArray(options[0])) {
      return (options as SelectOption<T>[][]).map((group, groupIdx) => (
        // Render Each Group in a view with a divider, except for the last group
        <View
          key={groupIdx}
          style={groupIdx < options.length - 1 && styles.groupDivider}
        >
          {group.map((option, idx) => {
            return renderMenuItem(option, idx)
          })}
        </View>
      ))
    }
    // Otherwise, Render just the options
    return (options as SelectOption<T>[]).map((option, idx) => {
      return renderMenuItem(option, idx)
    })
  }, [options, renderMenuItem])

  return (
    <>
      <FieldComponent
        a11yLabel={a11yLabel ? a11yLabel : `${label} Select Input`}
        active={!!anchorEl}
        disabled={disabled}
        required={required}
        label={label}
        onLayout={onInputLayout}
        onPress={isOpenMenuOverride ? noop : openMenu}
        status={status}
        style={[styles.formField, style]}
        value={selectedTextToShow}
        hideChevron={hideChevron}
        iconLeft={iconLeft}
        rightLabel={rightLabel}
        textStyle={textStyle}
        labelStyle={labelStyle}
        iconRight={iconRight}
        textWrapperStyle={fieldTextWrapperStyle}
        {...(loading && { icon: <ActivityIndicator /> })}
        avatarExist={hasAvatar}
        avatarStyle={avatarStyle}
      >
        {selectedIcon}
      </FieldComponent>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        onClose={closeMenu}
        open={!!anchorEl}
        testID={a11yLabel ?? `${label} Menu`}
        PaperProps={{
          style: {
            width: Math.max(inputWidth, MENU_ITEM_MIN_LENGTH),
          },
        }}
      >
        {searchInput}
        {allowClear ? (
          <MenuItem
            selected={selected === null}
            onClick={() => onPress(null as any)}
          >
            <Text style={{ fontFamily: Fonts.italic }}>
              {clearLabel ?? t('select.none')}
            </Text>
          </MenuItem>
        ) : null}
        {loading ? (
          <ActivityIndicator size="large" style={{ paddingVertical: 30 }} />
        ) : null}

        {!loading && mapOptionGroups()}
      </Menu>
    </>
  )
}

const styles = StyleSheet.create({
  formField: {
    minWidth: 96,
  },
  groupDivider: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderSecondary,
  },
})
