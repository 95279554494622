import { AvatarWithMessage } from 'components/common/Avatar/AvatarWithMessage'
import { Indicator } from 'components/common/Indicator/indicator'
import React from 'react'
import { useKeyToUrl } from 'src/hooks/useKeyToUrl'
import { Attitude } from 'src/types/globalTypes'
import { getAlertLevelAndAttitude } from '../utils/getAlertLevelAndMessage'

type PatientAvatarProps = {
  attitude: Attitude | null
  name: string | null
  lastName?: string | null
  hasUnapprovedSheet: boolean | null
  color?: string
  avatarUrl: string | null
}

export const PatientAvatar: React.FC<PatientAvatarProps> = ({
  name,
  lastName = '',
  hasUnapprovedSheet,
  color = undefined,
  avatarUrl,
  attitude,
}) => {
  const patientImage = useKeyToUrl(avatarUrl, 160)
  const { alertLevel, attitude: VrAttitude } = getAlertLevelAndAttitude({
    attitude,
  })

  return (
    <AvatarWithMessage
      alertLevel={alertLevel}
      message={VrAttitude}
      name={`${name} ${lastName}`}
      uri={patientImage}
      indicator={
        hasUnapprovedSheet ? (
          <Indicator
            accessibilityLabel={'Pending approvals indicator'}
            strokeColor={color}
          />
        ) : undefined
      }
    />
  )
}
