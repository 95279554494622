import { isEqual, isValid, parseISO } from 'date-fns'
import { sortBy, toNumber } from 'lodash'

import { GraphData } from 'components/common/Chart/types'

export type VitalsGraphInput = {
  date: Date | string
  examination_date?: Date | string
  value: number | string
}

const parseVitalsGraphData = ({
  date,
  examination_date,
  value,
}: VitalsGraphInput): GraphData => {
  // prefer to use exam_date (Represent start of time window) to date (update_time) when available
  const dateToChart = examination_date ?? date

  return {
    // _recorded_at is used to to keep update time when check for duplicates
    _recorded_at: typeof date === 'string' ? parseISO(date) : date,
    date: typeof dateToChart === 'string' ? parseISO(dateToChart) : dateToChart,
    value: typeof value === 'string' ? toNumber(value) : value,
  }
}

const filterInvalidValues = ({ date, value }: GraphData): boolean =>
  isValid(date) && isFinite(value)

// TODO: Remove after #VR-3990 fixed
const useLatestWhenUpdatedValue = (
  element: GraphData,
  i: number,
  arr: GraphData[],
): GraphData => {
  const nextElement: GraphData | undefined = arr[i + 1]
  const equalDates = nextElement && isEqual(element.date, nextElement.date)
  if (!equalDates) return element

  // Until our `getPatientsVitals` call handles updates (#VR-3990), when two
  // values have the same date of entry (ie. its an update), we use the latest
  // value for both. The chart won't care about the duplication of entry and
  // this is temporary so not going to worry about filtering duplicated return
  const latestValue =
    nextElement._recorded_at > element._recorded_at
      ? nextElement.value
      : element.value
  return { ...element, value: latestValue }
}

export const parseAndFilterGraphData = (
  vitalsData: VitalsGraphInput[],
): GraphData[] => {
  return sortBy(
    vitalsData.map(parseVitalsGraphData).filter(filterInvalidValues),
    'date',
  ).map(useLatestWhenUpdatedValue)
}
