import React from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { RawNumericInput } from 'components/common/RawNumericInput'

const getCallParameterTitle = (title: string, t: TFunction) => {
  switch (title) {
    case 'HR':
      return t('sheetHeader:drawer.HR')
    case 'RR':
      return t('sheetHeader:drawer.RR')
    case 'TEMP':
      return t('sheetHeader:drawer.TEMP')
    case 'GLU':
      return t('sheetHeader:drawer.GLUCOSE')
    default:
      return title
  }
}

type CallParameter = {
  min: number | null
  max: number | null
}

type Props = {
  label: string
  value: CallParameter
  onChange: (value: CallParameter) => void
}

export const CallParameterInput: React.FC<Props> = ({
  label,
  onChange,
  value,
}) => {
  const { t } = useTranslation()
  return (
    <View style={styles.parameterContainer}>
      <View style={styles.parameterTitleHolder}>
        <Text style={styles.label}>{getCallParameterTitle(label, t)}</Text>
      </View>
      <View style={styles.parameterTextfield}>
        <RawNumericInput
          label={`${label} Min`}
          value={value.min}
          style={styles.input}
          onChange={newValue => {
            onChange({ ...value, min: newValue })
          }}
        />
        <View style={styles.gapBetweenParameter}>
          <Text numberOfLines={1} style={styles.textLabel}>
            {'< >'}
          </Text>
        </View>
        <RawNumericInput
          label={`${label} Max`}
          value={value.max}
          style={styles.input}
          onChange={newValue => {
            onChange({ ...value, max: newValue })
          }}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  label: {
    color: Colors.contentTertiary,
    fontFamily: Fonts.regular,
    fontSize: 13,
    lineHeight: 19,
  },
  parameterContainer: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    height: 65,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  parameterTitleHolder: {
    width: '100%',
    paddingTop: 8,
  },
  parameterTextfield: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 8,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gapBetweenParameter: {
    minWidth: 10,
  },
  input: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: Colors.contentPrimary,
    fontFamily: Fonts.regular,
    fontSize: 16,
    lineHeight: 22,
    paddingHorizontal: 3,
  },
  textLabel: {
    paddingHorizontal: 8,
    fontSize: 16,
    color: 'gray',
  },
})
