import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { useUser } from 'src/context/user'
import { Typography, Colors } from 'src/design-system/theme'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useOrgSettings } from 'src/hooks/useOrgSettings'

export const VETRADAR_EMAILS = [
  'qa@vetradar.com',
  'team@vetradar.com',
  'feedback@ezyvet.com',
]

export const OrganisationBanner = React.memo(() => {
  const user = useUser()
  const orgSettings = useOrgSettings()
  const { isMediumScreen } = useBreakpoint()

  if (!user || !user.user?.email) {
    return null
  }

  if (!VETRADAR_EMAILS.includes(user.user?.email)) {
    return null
  }

  const textStyles = isMediumScreen
    ? Typography.Heading.M
    : Typography.Heading.S

  return (
    <View style={styles.banner} testID="organisation-banner">
      <Text style={{ ...textStyles, ...styles.header }}>
        {orgSettings.name}
      </Text>
    </View>
  )
})

const styles = StyleSheet.create({
  banner: {
    backgroundColor: Colors.Backgrounds.accentSecondary,
  },
  header: {
    fontWeight: Typography.FontWeights.bold,
    textAlign: 'center',
    padding: 8,
  },
})
