import React, { useCallback } from 'react'
import { createQuickAnesthesiaChartTask } from 'components/Anesthesia/AnesthesiaChart/utils/createQuickAnesthesiaChartTask'
import {
  BulkValueCompletionEntry,
  ValueEntry,
} from 'components/Anesthesia/ValueEntry'
import { Task } from 'components/Task/types'
import { TaskOmitTypename } from 'components/Task/utils/useCreateTask'
import { useUpdateTask } from 'components/Task/utils/useUpdateTask'
import { addMinutes } from 'date-fns'
import { isNil, pick } from 'lodash'
import { View } from 'react-native'
import { getNearestTimeTask } from 'src/components/Grid/GridTimeLine/utils/getNearestTimeTask'
import { TreatmentSheet } from 'src/components/Treatment/common/types'
import { useAnesthesiaContext } from 'src/context/anesthesia'
import { TimeSegment } from 'src/hocs/types/time'
import { UpdateTaskInput } from 'src/types/globalTypes'

import { useAnesthesiaBulkCreate } from './utils/useAnesthesiaBulkCreate'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'

type InnerDrawerProps = {
  anesthesiaData: any
  visible: boolean
  onClose: () => void
  onSave?: (values: BulkValueCompletionEntry[]) => void
  selectedDate: Date
  timeSegment?: TimeSegment
}

type AnesthesiaDrawerProps = {
  visible: boolean
  onClose: () => void
  selectedDate: Date
  treatments: TreatmentSheet[]
  timeSegment?: TimeSegment
  sheetId: string
  patientId: string
}

const InnerDrawer: React.FC<InnerDrawerProps> = ({
  anesthesiaData,
  visible,
  onClose,
  onSave,
  selectedDate,
  timeSegment = TimeSegment.fiveMinute,
}) => {
  return (
    <View testID={'InnerDrawer'}>
      <SheetAwareSideDrawer
        visible={visible}
        onClose={onClose}
        title={'Anesthesia'}
      >
        <ValueEntry
          anesthesiaData={anesthesiaData}
          onSave={onSave}
          selectedDate={selectedDate}
          timeSegment={timeSegment}
        />
      </SheetAwareSideDrawer>
    </View>
  )
}

export const AnesthesiaDrawer: React.FC<AnesthesiaDrawerProps> = ({
  visible,
  onClose,
  selectedDate,
  treatments,
  timeSegment = TimeSegment.fiveMinute,
  sheetId,
  patientId,
}) => {
  const anesthesiaBulkCreate = useAnesthesiaBulkCreate()

  const updateTask = useUpdateTask()
  const updateTaskValue = useCallback(
    (curTask: Task, value: string) => {
      const taskInput: UpdateTaskInput = pick(curTask, [
        'id',
        'sheet_id',
        'patient_id',
        'treatment_id',
        'organisation_id',
      ])
      taskInput.value = value
      return updateTask(taskInput)
    },
    [updateTask],
  )

  const [{ anesthesiaData }] = useAnesthesiaContext()

  const onSaveValue = useCallback(
    (updatedEntries: BulkValueCompletionEntry[]) => {
      const anesthesiaQuickTasks: TaskOmitTypename[] = []
      const newSchedule = {
        start_at: selectedDate.toISOString(),
        stop_at: addMinutes(selectedDate, 5).toISOString(),
      }
      updatedEntries.forEach(entry => {
        if (isNil(entry.value_to_submit)) return
        const sameTimeTask = getNearestTimeTask(
          treatments,
          entry.matching_treatment_id,
          selectedDate,
          timeSegment,
        )
        if (!sameTimeTask) {
          const task = createQuickAnesthesiaChartTask({
            patientId,
            sheetId,
            selectedDate,
            value: entry.value_to_submit,
            treatmentId: entry.matching_treatment_id,
            unit: entry.unit,
          })
          anesthesiaQuickTasks.push(task)
          return
        }
        if (sameTimeTask.value !== entry.value_to_submit) {
          updateTaskValue(sameTimeTask, entry.value_to_submit)
        }
      })
      if (anesthesiaQuickTasks.length > 0) {
        anesthesiaBulkCreate({
          tasks: anesthesiaQuickTasks,
          newSchedule,
          sheetId,
        })
      }
      onClose()
    },
    [
      anesthesiaBulkCreate,
      onClose,
      patientId,
      selectedDate,
      sheetId,
      timeSegment,
      treatments,
      updateTaskValue,
    ],
  )

  return (
    <InnerDrawer
      anesthesiaData={anesthesiaData}
      visible={visible}
      onClose={onClose}
      onSave={onSaveValue}
      selectedDate={selectedDate}
    />
  )
}
