import React, { Dispatch, SetStateAction, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_TEMPLATE } from 'components/TreatmentTemplate/graphql'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { useSetShowTreatmentTabs } from 'src/hooks/useSetShowTreatmentTabs'
import { getTemplate, getTemplateVariables } from 'src/types/getTemplate'
import { useAddTemplateToSheet } from 'src/utils/useAddTemplateToSheet'

import { AddTemplateToSheetForm } from './AddTemplateToSheetForm'
import {
  FormDataTemplate,
  generateTemplateTreatmentInputs,
} from './templateUtilFns'
import { useTemplateDuplication } from './useTemplateDuplication'

type Props = {
  templateId: string
  onDone: () => void
  sheetId: string
  patientId: string
  handleBackOverrides: Dispatch<SetStateAction<(() => void) | null>>
}

type OnSave = (data: FormDataTemplate) => void

export const AddTemplateToSheetScreen: React.FC<Props> = ({
  sheetId,
  templateId,
  onDone,
  patientId,
  handleBackOverrides,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()

  const addTemplateToSheet = useAddTemplateToSheet({ sheetId, patientId })

  useSetShowTreatmentTabs()

  const { data: template } = useQuery<getTemplate, getTemplateVariables>(
    GET_TEMPLATE,
    {
      variables: {
        id: templateId,
        organisation_id: organisationId,
      },
    },
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSave: OnSave = async ({
    startTime,
    applyWorkflow,
    templateTreatmentFormInputs,
  }) => {
    const setupTemplateTreatmentIds = {
      organisation_id: organisationId,
      patient_id: patientId,
      sheet_id: sheetId,
    }

    const templateTreatmentInput = generateTemplateTreatmentInputs(
      setupTemplateTreatmentIds,
      templateTreatmentFormInputs,
    )

    setIsSubmitting(true)

    try {
      await addTemplateToSheet({
        templateId,
        startTime,
        applyWorkflow,
        templateTreatmentInput,
        shouldSkipIgnore: true,
      })
    } finally {
      setIsSubmitting(false)
    }
    onDone()
    return
  }

  const getTemplate = template?.getTemplate

  const templateDuplicationIdHash = useTemplateDuplication({
    patientId,
    sheetId,
  })

  if (!getTemplate) {
    return <ActivityIndicator size="large" style={styles.spinner} />
  }

  return (
    <AddTemplateToSheetForm
      onSave={onSave}
      submitting={isSubmitting}
      submitTitle={t('addTreatment:addTemplateToSheet.title')}
      templateWorkflowId={getTemplate.template_workflow_id}
      template={getTemplate}
      handleBackOverrides={handleBackOverrides}
      patientId={patientId}
      templateDuplicationIdHash={templateDuplicationIdHash}
    />
  )
}

const styles = StyleSheet.create({
  spinner: {
    marginTop: 25,
  },
})
