import React from 'react'
import { Colors } from 'constants/Colors'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size: number
  color?: string
}

export const SvgHospital: React.FC<Props> = ({
  size = 24,
  color = Colors.overDue,
}) => (
  <Svg
    aria-hidden={true}
    focusable={false}
    width={size}
    height={size}
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <Path
      d="M18 14h-4v4h-4v-4H6v-4h4V6h4v4h4m2-8H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m0 18H4V4h16v16z"
      fill={color}
    />
  </Svg>
)
