import { Primitive, Record } from './base'

/**
 * PLEASE
 * Keep in sync with vet radar design system, do not add extra styles here unless in design system.
 * Special cases should be overwritten in the special case component
 */

const Backgrounds = {
  UI: Primitive.white,
  primary: Primitive.grey100,
  secondary: Primitive.grey50,
  tertiary: Primitive.grey200,
  accentPrimary: Primitive.blue600,
  accentSecondary: Primitive.blue200,
  positive: Primitive.green100,
  warning: Primitive.orange100,
  negative: Primitive.red100,
  transparent: Primitive.white, // opacity:0%
  overlay: 'rgba(33, 33, 33, 0.1)', // Primitive.grey900 opacity:10%
  transparentWhite: 'rgba(255, 255, 255, 0.75)',
  grey: '#f8f8f8', // TODO deprecated but required till we move other backgrounds away from this value
}

const Borders = {
  primary: Primitive.grey300,
  secondary: Primitive.grey400,
  tertiary: Primitive.grey600,
  accent: Primitive.blue600,
  negative: Primitive.red800,
}

const Contents = {
  primary: Primitive.grey900,
  secondary: Primitive.grey800,
  tertiary: Primitive.grey700,
  accessory: Primitive.grey500,
  onColor: Primitive.white,
  accent: Primitive.blue600,
  accentOnColor: Primitive.blue800,
  positive: Primitive.green800,
  positiveOnColor: Primitive.green900,
  warning: Primitive.orange800,
  negative: Primitive.red800,
  negativeOnColor: Primitive.red900,
}

const Interactive = {
  hoverAccent: Primitive.blue700,
  clickedAccent: Primitive.blue800,
  hoverOverlayPrimary: 'rgba(33, 33, 33, 0.05)', // Primitive.grey900 opacity: 5%
  hoverOverlaySecondary: 'rgba(33, 33, 33, 0.1)', // Primitive.grey900 opacity: 10%
  clickedOverlay: 'rgba(33, 33, 33, 0.15)', // Primitive.grey900 opacity:15%
  hoverHighlight: Primitive.blue50,
  clickedHighlight: Primitive.blue100,
  disabledPrimary: Primitive.grey300,
  disabledSecondary: Primitive.grey400,
  disabledTertiary: Primitive.grey500,
}

const Records = {
  dashPrimary: Record.dash300,
  dashSecondary: Record.dash50,
  contactPrimary: Record.contact400,
  contactSecondary: Record.contact50,
  patientPrimary: Record.patient300,
  patientSecondary: Record.patient50,
  clinicalPrimary: Record.clinical500,
  clinicalSecondary: Record.clinical50,
  financialPrimary: Record.financial700,
  financialSecondary: Record.financial50,
  reportPrimary: Record.report300,
  reportSecondary: Record.report50,
  adminPrimary: Record.admin300,
  adminSecondary: Record.admin50,
  helpPrimary: Record.help600,
  helpSecondary: Record.help50,
}

const Buttons = {
  positive: Record.clinical600,
}

export const Colors = {
  Backgrounds,
  Borders,
  Contents,
  Interactive,
  Records,
  Buttons,
}
