import { useCallback, useEffect, useMemo } from 'react'
import { useOrganisation } from 'src/context/organisation'

import { Storage } from 'src/utils/Storage'
import { makeVar, useReactiveVar } from '@apollo/client'

const IS_HIDED_CONTINUED_TREATMENT_KEY = 'IS_HIDED_CONTINUED_TREATMENT_KEY'
const isHideDiscontinuedTreatmentVar = makeVar<boolean>(false)

export const useIsHideDiscontinuedTreatment = () => {
  const [{ organisationId }] = useOrganisation()
  const storage = useMemo(() => new Storage(organisationId), [organisationId])

  useEffect(() => {
    isHideDiscontinuedTreatmentVar(
      storage.getItem(IS_HIDED_CONTINUED_TREATMENT_KEY),
    )
  }, [organisationId, storage])

  const isHideDiscontinuedTreatment = !!useReactiveVar(
    isHideDiscontinuedTreatmentVar,
  )

  const setIsHideDiscontinuedTreatment = useCallback(
    (v: boolean) => {
      storage.setItem(IS_HIDED_CONTINUED_TREATMENT_KEY, v)
      isHideDiscontinuedTreatmentVar(v)
    },
    [storage],
  )

  return {
    isHideDiscontinuedTreatment,
    setIsHideDiscontinuedTreatment,
  }
}
