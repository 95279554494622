import React from 'react'

import { Layout, toast } from 'components/common'
import { omit } from 'lodash'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { StackScreenProps } from '@react-navigation/stack'
import { Routes } from 'src/constants/Routes'
import { useOrganisation } from 'src/context/organisation'
import {
  createProduct as CreateProduct,
  createProductVariables as CreateProductVariables,
} from 'types/createProduct'
import { ProductType } from 'types/globalTypes'

import { SubHeader } from '../SubHeader/SubHeader'
import { CustomProductForm, Inputs } from './CustomProductForm'
import { CREATE_CUSTOM_PRODUCT } from './graphql'
import { checkCustomValueHasKey } from './EditCustomProductScreen'
import { SettingsStackParamList } from 'components/Settings/screens'
import { generateProductPimsMapping } from 'components/Settings/utils/generateProductPimsMapping'
import { useRouteOfAdministrations } from 'components/RouteOfAdministration/useRouteOfAdministrations'
import { ActivityIndicator, StyleSheet } from 'react-native'

type Props = StackScreenProps<SettingsStackParamList>

export const AddCustomProductScreen: React.FC<Props> = props => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { loading } = useRouteOfAdministrations()

  const [createProduct, { loading: submitting }] = useMutation<
    CreateProduct,
    CreateProductVariables
  >(CREATE_CUSTOM_PRODUCT, {
    onError: err => {
      toast.error(err.message)
    },
  })

  const {
    navigation: { navigate },
  } = props

  const backButton = {
    title: 'settings:products.title',
    label: 'settings:products.returnTo',
    action: () => navigate(Routes.CustomProductList),
  }

  const createAndNavBack = async (data: Inputs) => {
    const newMedicine = data.medicine
      ? {
          ...data.medicine,
          dosage: data.medicine.dosage,
          concentration: data.medicine.concentration,
          diluted_concentration: data.medicine.diluted_concentration,
          concentration_volume_unit:
            data.medicine.concentration_volume_unit || 'kg',
          concentration_weight_unit:
            data.medicine.concentration_weight_unit || 'mg',
          diluted_concentration_volume_unit:
            data.medicine.diluted_concentration_volume_unit || 'l',
          diluted_concentration_weight_unit:
            data.medicine.diluted_concentration_weight_unit || 'mg',
        }
      : {}

    const input = {
      name: data.name,
      type: data.type as ProductType,
      parent_product_id: data.parent_product_id ?? '',
      organisation_id: organisationId,
      medicine_dosage_info:
        data.type === ProductType.MEDICATION
          ? omit(newMedicine, ['dosageWeightUnit', 'dosagePerWeightUnit'])
          : {},
      description: data.description.length ? data.description : null,
      custom_values: data.custom_values?.map(checkCustomValueHasKey),
      pims_mapping: generateProductPimsMapping(data.customPimsMapping),
      is_value_required: data.isValueRequired,
      is_notes_required: data.isNotesRequired,
    }

    try {
      await createProduct({
        variables: { input },
      })
      toast.success(t('form.created', { itemName: data.name }))
      navigate(Routes.CustomProductList)
    } catch (err) {
      if (err instanceof Error) toast.error(err.message)
    }
  }

  return (
    <>
      <SubHeader
        headlineKey="settings:addProduct.title"
        backButton={backButton}
      />
      <Layout mode="single-center">
        {loading ? (
          <ActivityIndicator size="large" style={styles.marginTop} />
        ) : (
          <CustomProductForm
            onSubmit={createAndNavBack}
            submitting={submitting}
            submitTitle={t('settings:addProduct.submit')}
          />
        )}
      </Layout>
    </>
  )
}

const styles = StyleSheet.create({
  marginTop: {
    marginTop: 25,
  },
})
