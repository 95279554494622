export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined
}

export function filterNull<T>(
  arr: (T | null | undefined)[] | null | undefined,
): T[] {
  if (!arr) return []
  return arr.filter(notEmpty)
}
