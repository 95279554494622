import React, { useMemo } from 'react'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useTimeResolution } from 'src/hocs/timeContext'
import { getSheet_getSheet as Sheet } from 'types/getSheet'
import { Dialog } from 'components/common/Dialog/Dialog'
import { GridDaySelector } from './GridDaySelector'
import { Colors } from 'constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { SHEET_DAY_FORMAT } from 'constants/Date'
import { RangeDirection, SheetTitle } from 'components/Grid/SheetTitle'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { HeaderButton } from 'components/SubHeader/SubHeader'
import { NavIconLayout } from './NavIconLayout'
import { View, StyleSheet, LayoutChangeEvent, Platform } from 'react-native'
import { useToggleVisible } from './useToggleVisible'
import { useArrowButton } from 'src/hooks/useArrowButton'
import { usePatientById } from 'src/hooks/usePatient'
import {
  useDayCountDisplay,
  usePatientStartDate,
} from 'src/context/patientStartDate'
import { isNil } from 'lodash'
import { DrawerAction } from 'components/Sheet/ReadySheet'
import { useApprovals } from 'src/hooks/useApprovals'
import { BackButton } from './GridNav/BackButton'
import { MinimisedPatientInfo } from './GridNav/MinimisedPatientInfo'
import { usePatientPanelDrawerContext } from 'src/context/patientPanelDrawer'

type Props = {
  hasLimitedScrollArea?: boolean
  sheet?: Sheet | null
  currentDateIdx: number
  scrollToDateIdx: (idx: number, animated?: boolean) => void
  scrollToNow: () => void
  headline?: string | null
  headlineOnPress?: () => void
  isFinalized: boolean
  navigateSheets: (direction: RangeDirection) => any
  hasOneSheet: boolean
  onPressShowMore?: () => void
  showMoreDetails: boolean
  actionButton?: HeaderButton | null
  backButton?: HeaderButton | null
  onGridNavLayout: (e: LayoutChangeEvent) => void
  selectDrawerAction: (action: DrawerAction | null) => void
  patientId: string
  isEmptySheet?: boolean
}

// TODO: discussed that we could make it responsive instead of in pixel
export const GRID_TITLE_CONTAINER_HEIGHT = 68
const GRID_TITLE_CONTAINER_HEIGHT_MOBILE = 84
const DRAWER_WIDTH = 212

const isIOS = Platform.OS !== 'web'

export const gridNavHeight = 24

export const GridNavV2: React.FC<Props> = React.memo(
  ({
    currentDateIdx,
    hasLimitedScrollArea,
    scrollToDateIdx,
    scrollToNow,
    sheet,
    headline,
    headlineOnPress,
    isFinalized,
    navigateSheets,
    hasOneSheet,
    actionButton,
    backButton,
    onGridNavLayout,
    patientId,
    isEmptySheet,
    selectDrawerAction,
  }) => {
    const { t } = useTranslation()
    const { isExSmallScreen, isSmallScreen, isLargeScreen } = useBreakpoint()
    const isSmallerScreen = isExSmallScreen || isSmallScreen

    // Change styles depending on breakpoint
    const styles = useMemo(() => {
      if (isExSmallScreen) return stylesExSmall
      if (isSmallScreen) return stylesSmall
      return stylesDefault
    }, [isExSmallScreen, isSmallScreen])

    const { visible, switchVisible } = useToggleVisible()
    const [{ shouldShowDrawer }, dispatch] = usePatientPanelDrawerContext()

    const patient = usePatientById(patientId)

    const { sheetHasUnapprovedTreatments } = useApprovals()

    const shouldShowInfoHeader = isLargeScreen && !!patient

    const { getVisibleDayByIndex } = useTimeResolution()
    const patientStartDate = usePatientStartDate()

    const curVisibleDay = useMemo(
      () => getVisibleDayByIndex(currentDateIdx) || new Date(),
      [currentDateIdx, getVisibleDayByIndex],
    )

    const dayCountText = useDayCountDisplay(curVisibleDay)
    const { adminDateFormat } = useAdminTimeFormat()
    const adminDateFormatShortHand = adminDateFormat.replace(/\/[^/]*$/gm, '')

    const formatTime = isSmallerScreen
      ? format(curVisibleDay, adminDateFormatShortHand)
      : format(curVisibleDay, SHEET_DAY_FORMAT)

    const { onClickPre, onClickNext } = useArrowButton({
      scrollToDateIdx,
      currentDateIdx,
      hasLimitedScrollArea,
    })

    return (
      <>
        <View style={styles.container} onLayout={onGridNavLayout}>
          <View style={styles.titleRow}>
            <View
              style={[
                styles.headerLeftContainer,
                shouldShowDrawer && {
                  paddingRight: DRAWER_WIDTH,
                },
              ]}
            >
              <View style={styles.leftPartTitle}>
                <View style={styles.leftButtonContainer}>
                  {!!backButton && (
                    <BackButton
                      label={t(backButton.label)}
                      onPress={backButton?.action}
                    />
                  )}
                  <SheetTitle
                    headline={headline}
                    headlineOnPress={headlineOnPress}
                    isFinalized={isFinalized}
                    navigateSheets={navigateSheets}
                    hasOneSheet={hasOneSheet}
                    shouldShowDrawer={shouldShowDrawer}
                  />
                </View>
              </View>
              <NavIconLayout
                isMobileScreen={isSmallerScreen}
                isEmptySheet={isEmptySheet}
                formatTime={formatTime}
                dayCountText={dayCountText}
                actionButton={actionButton}
                onClickPrev={onClickPre}
                toggleDialog={switchVisible}
                onClickNext={onClickNext}
                needsApproval={sheetHasUnapprovedTreatments(sheet)}
              />
            </View>
            {!!shouldShowInfoHeader && (
              <MinimisedPatientInfo
                patient={patient}
                shouldShowDrawer={shouldShowDrawer}
                dispatch={dispatch}
                selectDrawerAction={selectDrawerAction}
              />
            )}
          </View>
        </View>
        <Dialog
          onCancel={switchVisible}
          showDone={false}
          toggleDialog={switchVisible}
          visible={visible}
          contentStyle={styles.dialogContent}
        >
          {!isNil(patientStartDate) && (
            <GridDaySelector
              sheet={sheet}
              patientStartDate={patientStartDate}
              scrollToDateIdx={scrollToDateIdx}
              scrollToNow={scrollToNow}
              done={switchVisible}
            />
          )}
        </Dialog>
      </>
    )
  },
)

GridNavV2.displayName = 'GridNavV2'

const stylesDefault = StyleSheet.create({
  container: {
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    paddingLeft: 16,
    paddingRight: 8,
    height: GRID_TITLE_CONTAINER_HEIGHT,
    backgroundColor: Colors.backgroundGrey,
    fontSize: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerLeftContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
    minWidth: '44%',
    flexShrink: 1,
    flexBasis: 'auto',
  },
  dialogContent: {
    bottom: 'auto',
  },
  leftButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  leftPartTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    width: isIOS ? '40%' : 'auto',
  },
})

const stylesSmall = StyleSheet.create({
  ...stylesDefault,
  container: {
    ...stylesDefault.container,
    height: GRID_TITLE_CONTAINER_HEIGHT_MOBILE,
  },
  titleRow: {
    ...stylesDefault.titleRow,
    alignItems: 'flex-end',
  },
  leftButtonContainer: {
    ...stylesDefault.leftButtonContainer,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
})

const stylesExSmall = StyleSheet.create({
  ...stylesSmall,
  container: {
    ...stylesSmall.container,
    paddingHorizontal: 10,
  },
  leftPartTitle: {
    ...stylesSmall.leftPartTitle,
    maxWidth: '75%',
  },
})
