import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { pinSwitchService } from 'src/utils/pinSwitchService'
import { useDeviceLock } from 'src/context/deviceLock/deviceLock'
import { signOut as signOutHelper } from 'src/utils/signOut'

// TODO: This hook will be deleted in https://ezyvet.atlassian.net/browse/VR-10733
export const useSignOut = () => {
  const client = useApolloClient()
  const { unlock } = useDeviceLock()

  const signOut = useCallback(async () => {
    await Promise.all([client.clearStore(), signOutHelper()])
    unlock()
    pinSwitchService.clearStorage()
  }, [client, unlock])

  const resetPinAndSignOut = useCallback(
    async (email: string) => {
      pinSwitchService.resetPin(email)
      await signOut()
    },
    [signOut],
  )

  return {
    signOut,
    resetPinAndSignOut,
  }
}
