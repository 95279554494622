import {
  getSheet_getSheet_treatments_items as TreatmentSheetFirstLevel,
  getSheet_getSheet_treatments_items_treatments_items as SubTreatment,
} from 'src/types/getSheet'
import { ProductType, TreatmentNodeDisplayStates } from 'src/types/globalTypes'

export const isGeneralTreatment = (treatment: SubTreatment) => {
  return (
    treatment.product?.type !== ProductType.MEDICATION &&
    treatment.product?.type !== ProductType.IVFLUIDS
  )
}

export const isNotConditionalTreatment = (treatment: SubTreatment) => {
  return !treatment.conditional ?? true
}

export const isNotHiddenTreatment = (treatment: SubTreatment) => {
  return (
    !treatment.display_states?.includes(TreatmentNodeDisplayStates.HIDDEN) ??
    true
  )
}

export const getAllBulkActionableSubTreatments = (
  treatment?: TreatmentSheetFirstLevel,
) => {
  return (
    treatment?.treatments?.items
      ?.filter(isGeneralTreatment)
      .filter(isNotHiddenTreatment)
      .filter(isNotConditionalTreatment) ?? []
  )
}
