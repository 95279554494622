import { WeightUnit } from 'constants/Weight'
import { getOneProduct_getProduct_medicine_dosage_info } from 'src/types/getOneProduct'

export const extractDosageInfo = (
  dosageInfo: getOneProduct_getProduct_medicine_dosage_info | null | undefined,
) => {
  return {
    productConcentration: dosageInfo?.concentration ?? null,
    productConcentrationWeightUnit: (dosageInfo?.concentration_weight_unit ??
      '') as WeightUnit,
    productConcentrationVolumeUnit: dosageInfo?.concentration_volume_unit ?? '',
    productAdministrationRoute: dosageInfo?.route_of_administration ?? '',

    productWeightUnit: dosageInfo?.dosage_weight_unit ?? '',
    productPatientWeightUnit: dosageInfo?.dosage_patient_weight_unit ?? '',

    dilutedConcentration: dosageInfo?.diluted_concentration ?? null,
    dilutedConcentrationVolumeUnit:
      dosageInfo?.diluted_concentration_volume_unit ?? '',
    dilutedConcentrationWeightUnit:
      dosageInfo?.diluted_concentration_weight_unit ?? '',
    dilutedRatioFactor: dosageInfo?.diluted_concentration_ratio_factor ?? null,
    dilutedRatioVolume: dosageInfo?.diluted_concentration_ratio_volume ?? null,
  }
}
