import { WeightUnit } from 'src/constants/Weight'
import { getOneProduct_getProduct as OneProduct } from 'src/types/getOneProduct'
import {
  ConcentrationSetting,
  criPerTimeOptions,
  perWeightUnitOptions,
  RepeatScheduleValue,
  RouteOfAdminCRI,
  VolumeUnit,
  volumeUnitOptionsV2 as volumeUnitOptions,
} from '../data'
import { CRIOnFormData } from '../types'
import { getDefaultDosageWeightUnit } from './getDefaultDosageWeightUnit'
import { getProductDefaultInstructions } from 'components/TreatmentForm/utils/isCustomProduct'
import { ProductType } from 'src/types/globalTypes'

export const getDefaultCRIOnFormValues = ({
  product,
  defaultOverrides,
  defaultPatientWeight,
  defaultPatientWeightUnit,
  startTime,
  isCubexProductBillable,
}: {
  product: OneProduct | Partial<OneProduct>
  defaultOverrides?: CRIOnFormData
  defaultPatientWeight?: number
  defaultPatientWeightUnit?: WeightUnit
  startTime: Date
  isCubexProductBillable?: boolean
}) => {
  const productConcentration =
    product.medicine_dosage_info?.concentration ?? null
  const productConcentrationWeightUnit = (product.medicine_dosage_info
    ?.concentration_weight_unit ?? '') as WeightUnit
  const productConcentrationVolumeUnit = (product.medicine_dosage_info
    ?.concentration_volume_unit ?? volumeUnitOptions[0].value) as VolumeUnit
  const hasConcentrationFromProduct = !!(
    productConcentration &&
    productConcentrationWeightUnit &&
    productConcentrationVolumeUnit
  )

  return {
    patientWeight: defaultPatientWeight,
    patientWeightUnit: defaultPatientWeightUnit,
    doseRate: null,
    dosageWeightUnit: getDefaultDosageWeightUnit(
      productConcentrationWeightUnit,
    ),
    dosagePerWeightUnit: perWeightUnitOptions[0].value,
    dosageRate: null,
    infusionRateTotal: null,
    infusionRateVolumeUnit: volumeUnitOptions[0].value,
    infusionRateTimeUnit: criPerTimeOptions[0].value,
    doseRateTimeUnit: criPerTimeOptions[0].value,
    medicationVolume: null,
    medicationVolumeUnit: volumeUnitOptions[0].value,
    ivBagSize: null,
    ivBagSizeUnit: volumeUnitOptions[0].value,
    isDiluted: false,
    isContinuous: true,
    concentration: productConcentration,
    concentrationWeightUnit: getDefaultDosageWeightUnit(
      productConcentrationWeightUnit,
    ),
    concentrationVolumeUnit: productConcentrationVolumeUnit,
    dilutedConcentration: null,
    dilutedConcentrationWeightUnit: productConcentrationWeightUnit,
    dilutedConcentrationVolumeUnit: productConcentrationVolumeUnit,
    concentrationSetting: hasConcentrationFromProduct
      ? null
      : ConcentrationSetting.IGNORE,

    startAtDate: startTime,

    minutes: 0,
    hours: 0,
    days: 0,

    unitsBilledPerTask: 1,
    instructions: getProductDefaultInstructions(product), // Use description for custom products.
    isInstructionsImportant: false,

    enableStaffedHour: true,

    diluentUsed: null,
    isBillable: !!product.is_billable && !isCubexProductBillable,
    routesOfAdmin: RouteOfAdminCRI,
    ...defaultOverrides,
    isRepeating: RepeatScheduleValue.SINGLE,
    productType: ProductType.MEDICATION,
  } as CRIOnFormData
}
