import { ApolloCache } from '@apollo/client'

type optionalParams = {
  broadcast?: boolean
  delay?: number
}

export const evictRootQuery = (
  cache: ApolloCache<any>,
  queryRe: RegExp,
  { broadcast = true, delay = 250 }: optionalParams = {},
) => {
  const cacheContents = cache.extract()
  const queriesToEvict = Object.keys(cacheContents.ROOT_QUERY).filter(key =>
    queryRe.test(key),
  )

  setTimeout(() => {
    queriesToEvict.forEach(query => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: query,
        broadcast,
      })
    })
    // garbage collect cache items not reachable via root query
    cache.gc()
  }, delay)
}
