import React from 'react'
import { Fonts } from 'constants/Fonts'
import {
  StyleSheet,
  Text,
  View,
  TextStyle,
  ViewStyle,
  StyleProp,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { messageBoxColors } from 'components/common/Avatar/AvatarWithMessage'
import { hexToRGB } from 'src/utils/hexToRGB'

export type TagTitle = keyof typeof styleMap
type Props = {
  testID?: string
  title: TagTitle | ''
  style?: ViewStyle
  size?: 'big' | 'small'
  textStyle?: StyleProp<TextStyle>
}

const defaultTagStyles = StyleSheet.create({
  tag: {
    backgroundColor: Colors.contentPrimary,
  },
})

const defaultTextStyles = StyleSheet.create({
  tag: {
    color: Colors.white,
  },
})

const sexColors = {
  male: {
    backgroundColor: Colors.tag.m,
  },
  maleNeutered: {
    backgroundColor: Colors.tag.mn,
  },
  female: {
    backgroundColor: Colors.tag.peach,
  },
  femaleSpayed: {
    backgroundColor: Colors.tag.peach,
  },
  unknown: {
    backgroundColor: Colors.darkGrey,
  },
}

const equineSexColors = {
  Colt: sexColors.male,
  Filly: sexColors.female,
  Gelding: sexColors.maleNeutered,
  Mare: sexColors.female,
  Stallion: sexColors.male,
  Unknown: sexColors.unknown,
}

const patientSexColors = {
  F: sexColors.female,
  FS: sexColors.femaleSpayed,
  M: sexColors.male,
  MN: sexColors.maleNeutered,
  U: sexColors.unknown,
}

export const PENDING_APPROVAL_TAG_NAME = 'Pending approval'

/* eslint-disable react-native/no-unused-styles */
const styleMap = StyleSheet.create({
  // Sex Styles
  ...equineSexColors,
  ...patientSexColors,

  // Resuscitate Styles
  DNR: {
    backgroundColor: Colors.tag.dnr,
  },
  BLS: {
    backgroundColor: Colors.tag.bls,
  },
  ALS: {
    backgroundColor: Colors.tag.als,
  },
  DECEASED: {
    backgroundColor: Colors.contentPrimary,
  },

  // Attitude Styles
  CAUTION: messageBoxColors.BAD,
  FRIENDLY: messageBoxColors.GOOD,
  UNFRIENDLY: messageBoxColors.WARNING,
  UNKNOWN: messageBoxColors.UNKNOWN,

  // Status Styles
  ACTIVE: {
    backgroundColor: Colors.blue,
  },
  INACTIVE: {
    backgroundColor: Colors.contentTertiary,
  },
  Inactive: {
    // Repeat but should make this a uniform approach like lowercase everything
    backgroundColor: Colors.contentTertiary,
  },
  Template: {
    backgroundColor: Colors.tag.template,
  },
  [PENDING_APPROVAL_TAG_NAME]: {
    backgroundColor: hexToRGB(Colors.tag.pendingApproval, '0.15'),
  },
  Finalized: {
    backgroundColor: Colors.green2,
  },
  complication: {
    backgroundColor: Colors.blue,
  },
})

const textStyleMap: { [key in string]: TextStyle } = StyleSheet.create({
  [PENDING_APPROVAL_TAG_NAME]: {
    color: Colors.tag.pendingApproval,
  },
})

const bigStyles = StyleSheet.create({
  container: {
    borderRadius: 12,
  },
  text: {
    fontFamily: Fonts.bold,
    fontSize: 14,
    letterSpacing: 0.4,
    lineHeight: 32,
    paddingHorizontal: 15,
  },
})

const smallStyles = StyleSheet.create({
  container: {
    borderRadius: 7,
  },
  text: {
    fontFamily: Fonts.bold,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: 18,
    paddingHorizontal: 8,
  },
})

export const Tag: React.FC<Props> = ({
  testID,
  title,
  style,
  size = 'small',
  textStyle,
}) => {
  if (!title) return null
  const styles = size === 'big' ? bigStyles : smallStyles
  return (
    <View
      testID={testID}
      style={[styles.container, defaultTagStyles.tag, styleMap[title], style]}
    >
      <Text
        selectable={false}
        style={[
          styles.text,
          defaultTextStyles.tag,
          textStyleMap[title],
          textStyle,
        ]}
      >
        {title}
      </Text>
    </View>
  )
}
