import { useEffect } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import {
  generateTemplateTreatmentInputs,
  TemplateTreatmentFormInput,
} from 'components/AddTreatment/templateUtilFns'
import { getSheetIsAnesthesia } from 'components/Anesthesia/AnesthesiaChart/utils/getSheetIsAnesthesia'
import { toast } from 'components/common'
import { ProductSelected } from 'components/CreateSheet/ProductSection/ProductSelect'
import { GET_SHEETS } from 'components/Patient/graphql'
import {
  nativeCreateSheetDoneListener,
  webReplaceHistory,
} from 'components/SheetList/utils/navigation'
import {
  Inputs as MedicineDosageInfo,
  FluidDosageFormData,
} from 'components/TreatmentForm/types'
import { Routes } from 'constants/Routes'
import { compact } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import {
  OrganisationActionTypes,
  useOrganisation,
} from 'src/context/organisation'
import {
  createSheet as CreateSheetQuery,
  createSheetVariables as CreateSheetVariables,
} from 'src/types/createSheet'
import { evictRootQuery } from 'src/utils/evictRootQuery'
import { ProductType, SheetPseudoTreatmentInput } from 'types/globalTypes'

import {
  CreateSheetFormInputs,
  SelectedTemplate,
  TemplateToAddToSheet,
} from './createSheetData'
import { CREATE_SHEET } from './graphql'
import { useGetAnesthesiaTemplates } from './useGetAnesthesiaTemplates'

export const isTemplateToAddToSheet = (
  template: TemplateToAddToSheet | SelectedTemplate | null,
): template is TemplateToAddToSheet => !!template && template.id !== null

const getStartTime = (startTime: 'now' | Date | null) => {
  if (!startTime) return null
  if (startTime === 'now') return null
  return startTime.toISOString()
}

const getTemplateIdAndTime = (template: TemplateToAddToSheet) => {
  return {
    template_id: template.id,
    start_at: getStartTime(template.startTime),
    apply_template_workflow: template.applyWorkflow,
  }
}

enum PseudoTemplateGroupNames {
  Premeds = 'Premeds',
  Induction = 'Induction',
  'Intraoperative Fluids' = 'Intraoperative Fluids',
}

export type SubmitFormActionParams = {
  values: CreateSheetFormInputs
}

const getMedicineDosageInfo = (medicineDosageInfo: MedicineDosageInfo) => ({
  route_of_administration: medicineDosageInfo.routesOfAdmin,
  dosage: medicineDosageInfo.dosageRate,
  dosage_weight_unit: medicineDosageInfo.dosageWeightUnit,
  dosage_patient_weight_unit: medicineDosageInfo.dosagePerWeightUnit,
  diluted_concentration: medicineDosageInfo.dilutedConcentration,
  is_cri: medicineDosageInfo.isCRI,
  patient_weight: medicineDosageInfo.patientWeight,
  patient_weight_unit: medicineDosageInfo.patientWeightUnit,
  is_diluted: medicineDosageInfo.isDiluted,
  diluent_used: medicineDosageInfo.diluentUsed,
  iv_bag_size: medicineDosageInfo.ivBagSize,
  iv_bag_size_unit: medicineDosageInfo.ivBagSizeUnit,
  days: medicineDosageInfo.days,
  hours: medicineDosageInfo.hours,
  minutes: medicineDosageInfo.minutes,
  concentration: medicineDosageInfo.concentration,
  concentration_volume_unit: medicineDosageInfo.concentrationVolumeUnit,
  concentration_weight_unit: medicineDosageInfo.concentrationWeightUnit,
  total_volume: medicineDosageInfo.totalVolume,
  // If there is no volume unit provided from the product, it should be set to ml
  // Follows the logic provided on the CRIOffForm.tsx [271-276]
  total_volume_unit: medicineDosageInfo.totalVolumeUnit ?? 'ml',
  total_dosage: medicineDosageInfo.totalDosage,
  units_billed_per_task: medicineDosageInfo.unitsBilledPerTask,
  infusion_rate_total: medicineDosageInfo.infusionRateTotal,
  infusion_rate_volume_unit: medicineDosageInfo.infusionRateVolumeUnit,
  infusion_rate_time_unit: medicineDosageInfo.infusionRateTimeUnit,
  dose_rate: medicineDosageInfo.doseRate,
  dose_rate_time_unit: medicineDosageInfo.doseRateTimeUnit,
  medication_volume: Number(medicineDosageInfo.medicationVolume),
  medication_volume_unit: medicineDosageInfo.medicationVolumeUnit,
})

const getIsBillable = (medicineDosageInfo: MedicineDosageInfo) =>
  medicineDosageInfo.isBillable

const getFluidMedicineDosageInfo = (
  fluidMedicineDosage: FluidDosageFormData,
) => ({
  is_hypovolemic: fluidMedicineDosage.isHypovolemic,
  is_dehydrated: fluidMedicineDosage.isDehydrated,
  dehydration: fluidMedicineDosage.dehydration,
  hours_to_admin: fluidMedicineDosage.hoursToAdmin,
  dehydration_result: fluidMedicineDosage.dehydrationResult,
  shock_total_result: fluidMedicineDosage.shockTotalResult,
  physiologic_req: fluidMedicineDosage.physiologicReq,
  ongoing_losses: fluidMedicineDosage.ongoingLosses,
  total_result: fluidMedicineDosage.totalResults,
  iv_set: fluidMedicineDosage.IVSet,
  drip_rate: fluidMedicineDosage.dripRate,
  minutes: fluidMedicineDosage.minutes,
  hours: fluidMedicineDosage.hours,
  days: fluidMedicineDosage.days,
  patient_weight: fluidMedicineDosage.patientWeight,
  patient_weight_unit: fluidMedicineDosage.patientWeightUnit,
})

const getPseudoTemplate = (
  selectedProducts: (ProductSelected | null)[],
  groupName: string,
): SheetPseudoTreatmentInput | null => {
  const selectedProductsFiltered = compact(selectedProducts)
  if (!selectedProductsFiltered.length) return null

  const treatments: SheetPseudoTreatmentInput[] = selectedProductsFiltered.map(
    product => ({
      is_billable: product.isBillable,
      name: product.productName,
      product_id: product.productId,
      is_continuous: product.dosageInfo?.isContinuous,
      is_instructions_important: product.dosageInfo?.isInstructionsImportant,
      ...(product.dosageInfo?.productType === ProductType.MEDICATION && {
        medicine_dosage_info: getMedicineDosageInfo(product.dosageInfo),
        is_billable: getIsBillable(product.dosageInfo) ?? product.isBillable,
      }),
      ...(product.dosageInfo?.productType === ProductType.IVFLUIDS && {
        fluid_dosage_info: getFluidMedicineDosageInfo(product.dosageInfo),
      }),
    }),
  )

  return {
    treatments,
    name: groupName,
    // TODO: remove item from api input
    product_id: '', // no id since is a treatment group
  }
}

type UseCreateSheetParams = { patientId: string }
export const useCreateSheet = ({ patientId }: UseCreateSheetParams) => {
  const [{ organisationId }, setOrganisation] = useOrganisation()
  const navigation = useNavigation()
  const { navigate } = navigation
  const client = useApolloClient()

  const { t } = useTranslation()

  const { anesthesiaTemplates, loading: isTemplateListLoading } =
    useGetAnesthesiaTemplates()
  const [createSheet] = useMutation<CreateSheetQuery, CreateSheetVariables>(
    CREATE_SHEET,
    {
      onError: err => {
        toast.error(err.message)
      },
      onCompleted: data => {
        setOrganisation({
          type: OrganisationActionTypes.setLastCreatedSheetId,
          lastCreatedSheetId: data.createSheet.id,
        })
        // Update workflow template items
        evictRootQuery(client.cache, /^(getConsultation)/)
      },
      refetchQueries: [
        {
          query: GET_SHEETS,
          variables: {
            organisationId,
            id: patientId,
          },
        },
      ],
    },
  )

  useEffect(() => {
    // null lastCreatedSheet whenever visit this page
    setOrganisation({
      type: OrganisationActionTypes.setLastCreatedSheetId,
      lastCreatedSheetId: null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitCreateSheet = async (
    { values }: SubmitFormActionParams,
    ignoreDuplication: boolean,
  ) => {
    const { name: sheetName, templateFormInputs } = values

    const isSheetAnesthesia = getSheetIsAnesthesia({ type: values.sheetType })

    const getAnesthesiaPseudoTreatments = ():
      | SheetPseudoTreatmentInput[]
      | null => {
      if (!isSheetAnesthesia) return null

      const premedsTemplate = getPseudoTemplate(
        values.selectedPremedsProducts,
        PseudoTemplateGroupNames.Premeds,
      )
      const inductionTemplate = getPseudoTemplate(
        values.selectedInductionProducts,
        PseudoTemplateGroupNames.Induction,
      )
      const intraopTemplate = getPseudoTemplate(
        values.selectedIntraoperativeFluidsProducts,
        PseudoTemplateGroupNames['Intraoperative Fluids'],
      )

      const pseudoTemplate = compact([
        premedsTemplate,
        inductionTemplate,
        intraopTemplate,
      ])
      return pseudoTemplate
    }

    const asaCategory = values.asaCat?.concat(values.emergency ? 'E' : '')
    const templateTreatmentFormInputs: TemplateTreatmentFormInput[] = []

    const templatesV2 = templateFormInputs.map(templateFormInput => {
      templateTreatmentFormInputs.push(
        ...(templateFormInput.templateTreatmentFormInputs ?? []),
      )
      return { ...templateFormInput.selectedTemplate }
    })

    const additionalTemplates = isSheetAnesthesia ? anesthesiaTemplates : []

    const templatesShouldApply = [...additionalTemplates, ...templatesV2]

    const templatesToAddToSheet = templatesShouldApply.filter(
      isTemplateToAddToSheet,
    )

    const templates = templatesToAddToSheet.map(getTemplateIdAndTime)

    // BM: IMHO should be called pseudoTemplates
    const pseudoTemplates = isSheetAnesthesia
      ? getAnesthesiaPseudoTreatments()
      : null
    /**
     * Why didn't we change the formData via (reactHookForm.setValue) in createSheet form directly before submitting it?
     * Because that will change the displaying text in createSheet form before createSheet form disappear
     * (This will last about 1 sec which might confuse user I think)
     */
    const hasSyncWithConsultVetId = values.syncSelectedVetWithConsult
    const hasSyncWithConsultSiteId = values.syncSelectedDepartmentWithConsult
    const attending_vet = hasSyncWithConsultVetId
      ? values.consultVetId
      : values.selectedVet
    const attending_department = hasSyncWithConsultSiteId
      ? values.consultSiteId
      : values.selectedDepartment

    const setupTemplateTreatmentIds = {
      organisation_id: organisationId,
      patient_id: patientId,
      sheet_id: patientId,
      // When creating sheet pages, it is necessary to have a sheet ID
      // so that it can be passed back to the API and easily traced.
    }

    const templateTreatmentInput = generateTemplateTreatmentInputs(
      setupTemplateTreatmentIds,
      templateTreatmentFormInputs,
    )

    createSheet({
      variables: {
        input: {
          ignore_duplication: ignoreDuplication,
          templates,
          attending_vet,
          attending_department,
          sync_attending_vet_with_consultation: hasSyncWithConsultVetId,
          sync_attending_department_with_consultation: hasSyncWithConsultSiteId,
          name: sheetName,
          type: values.sheetType,
          consultation_id: values.selectedConsultId ?? '',
          attending_vet_tech: values.selectedVetTech,
          additional_care_team: values.additionalCareTeam,
          organisation_id: organisationId,
          patient_id: patientId,
          ...(isSheetAnesthesia && {
            type_meta_data: {
              ...(asaCategory && { asa: asaCategory }),
              anaesthesia: {
                owner_consent: values.ownerConsent,
                circuit_check: values.circuitCheck,
                catheter_site: compact(values.catSite),
                comments: values.comments,
              },
              timers: [
                {
                  name: t('sheet:timers:anesthetic'),
                  elapsed: 0,
                  start_at: null,
                },
                {
                  name: t('sheet:timers:surgery'),
                  elapsed: 0,
                  start_at: null,
                },
              ],
            },
            pseudo_treatments: pseudoTemplates,
          }),
        },
        options: { template_treatments: templateTreatmentInput },
      },
    })

    // sets back url back to be to SheetList on web
    if (Platform.OS === 'web') {
      webReplaceHistory(`/patients/${patientId}/sheets`)
    } else {
      // re writes navigation history on native
      nativeCreateSheetDoneListener({
        navigation,
        patientId,
        sheetName,
        // TODO: delay this history rewrite until server sheetId returns (oncomplete)
        sheetId: 'optimisticSheetId',
      })
    }

    navigate(Routes.Sheet, {
      patientId,
      sheetName,
      sheetId: 'optimisticSheetId',
    })
  }
  return {
    isTemplateListLoading,
    submitCreateSheet,
  }
}
