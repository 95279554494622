import React, { useCallback, useLayoutEffect, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { PinInput } from 'components/shared/PinInput'
import { pinSwitchService } from 'src/utils/pinSwitchService'
import { Colors } from 'constants/Colors'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Fonts } from 'constants/Fonts'
import { signOut } from 'src/utils/signOut'

export const PIN_LENGTH = 4

type Props = {
  children?: React.ReactElement | null
  onPressSkip?: () => void
}

export const SetupPin: React.FC<Props> = ({ children, onPressSkip }) => {
  // set has completed pin form - user can skip setting the pin as well
  const [hasMadePinDecision, setHasMadePinDecision] = useState(false)

  const { t } = useTranslation()
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isMobile = isExSmallScreen || isSmallScreen

  const [pin, setPin] = useState('')
  const confirmButtonDisabled = pin.length < PIN_LENGTH

  const backToSignIn = useCallback(() => {
    signOut()
  }, [])

  const onSkip = useCallback(() => {
    if (onPressSkip) onPressSkip()
    pinSwitchService.skipPin()
    setHasMadePinDecision(true)
  }, [onPressSkip])

  const onConfirm = useCallback(() => {
    pinSwitchService.setPin(pin)
    setHasMadePinDecision(true)
  }, [pin])

  const onChangePIN = useCallback((pin: string) => {
    setPin(pin)
  }, [])

  useLayoutEffect(() => {
    const initPINSwitch = async () => {
      await pinSwitchService.init()
      if (pinSwitchService.hasPin() || pinSwitchService.hasSkippedPin()) {
        setHasMadePinDecision(true)
      }
    }
    initPINSwitch()
  }, [])

  if (hasMadePinDecision) {
    return <>{children}</>
  }

  return (
    <KeyboardAwareScrollView contentContainerStyle={styles.scrollViewContainer}>
      <View style={styles.container} accessibilityLabel="Setup PIN Switch">
        <View
          style={[
            styles.setupContainer,
            isMobile && styles.mobileSetupContainer,
          ]}
        >
          <Text style={styles.label}>{t('login:pin:setup')}</Text>
          <View style={styles.labelInfoContainer}>
            <Text style={[styles.label, styles.labelInfo]}>
              {t('login:pin:description')}
            </Text>
          </View>
          <View style={styles.pinGroupContainer}>
            <PinInput
              onChange={onChangePIN}
              onConfirm={onConfirm}
              style={styles.input}
            />
          </View>

          <TouchableOpacity
            disabled={confirmButtonDisabled}
            style={[
              styles.button,
              isMobile && styles.smallScreenBtnWidth,
              confirmButtonDisabled && styles.buttonDisabled,
            ]}
            onPress={onConfirm}
          >
            <Text style={styles.buttonText}>{t('login:pin:confirm')}</Text>
          </TouchableOpacity>

          <View
            style={[styles.backButton, isMobile && styles.smallScreenBtnWidth]}
          >
            <TouchableOpacity onPress={backToSignIn}>
              <Text style={styles.backText}>{t('login:pin:backToSign')}</Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={onSkip}
              accessibilityLabel="Skip PIN Setup"
            >
              <Text style={styles.backText}>{t('login:pin:skip')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  scrollViewContainer: {
    height: '100%',
    width: '100%',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  setupContainer: {
    backgroundColor: '#131639',
    width: 500,
    maxHeight: 400,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    paddingVertical: 30,
  },
  mobileSetupContainer: {
    backgroundColor: '#131639',
    width: '90%',
    maxHeight: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: Colors.white,
    fontFamily: Fonts.regular,
    fontSize: 20,
  },
  labelInfoContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  labelInfo: {
    fontSize: 16,
    marginLeft: 2,
  },
  pinGroupContainer: {
    flexDirection: 'row',
    width: '80%',
    justifyContent: 'center',
    marginVertical: 20,
  },
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: Colors.white,
    borderWidth: 0,
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#ED6353',
    borderRadius: 5,
    borderWidth: 0,
    color: Colors.white,
    fontFamily: Fonts.regular,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: 0.8,
    marginBottom: 4,
    padding: 16,
    width: 400,
  },
  buttonDisabled: {
    backgroundColor: Colors.contentTertiary,
    color: Colors.contentPrimary,
    fontFamily: Fonts.regular,
    fontWeight: '600',
    fontSize: 14,
    alignItems: 'center',
    padding: 16,
    borderRadius: 5,
  },
  buttonText: {
    color: Colors.white,
    fontFamily: Fonts.regular,
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: 0.8,
  },
  backButton: {
    width: 400,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  backText: {
    color: Colors.vetRadarOrange,
    fontFamily: Fonts.regular,
    fontSize: 15,
  },
  smallScreenBtnWidth: {
    width: '80%',
  },
})
