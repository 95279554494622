import React from 'react'
import { Text, TextStyle } from 'react-native'
import { getBirthAgeYearMonths } from '../utils/getBirthAgeYearMonths'
import { PatientHeaderStyles as styles } from './PatientHeaderStyles'

type WeightAgeTextProps = {
  patientWeight: string
  dateOfBirth: string | null | undefined
  sexTagTitle: string | null | undefined
  textStyle?: TextStyle | null
}
// TODO Delete once new patient info is deployed
export const WeightAgeText = ({
  patientWeight,
  dateOfBirth,
  sexTagTitle,
  textStyle,
}: WeightAgeTextProps) => {
  return (
    <>
      <Text>
        <Text style={[styles.weightText, textStyle]}>{patientWeight}</Text>
        <Text style={styles.infoText}>
          {dateOfBirth && patientWeight ? '  |  ' : ''}
        </Text>
        <Text style={[styles.weightText, textStyle]}>
          {getBirthAgeYearMonths(dateOfBirth)}
        </Text>
        <Text style={styles.infoText}>{sexTagTitle ? '  |  ' : ''}</Text>
        <Text style={[styles.weightText, textStyle]}>{sexTagTitle}</Text>
      </Text>
    </>
  )
}
