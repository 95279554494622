import { useEffect } from 'react'
import {
  addReconnectionListener,
  removeReconnectionListener,
} from 'src/apollo/reconnectSubscription'

export const useOnReconnected = (cb: () => any) => {
  useEffect(() => {
    addReconnectionListener(cb)
    return () => removeReconnectionListener(cb)
  }, [cb])
}
