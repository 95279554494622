import { useQuery } from '@apollo/client'
import { GET_PATIENT_WITH_SHEETS } from 'components/SheetList/graphql'
import { Routes } from 'constants/Routes'
import { parseISO } from 'date-fns'
import React, { useMemo, useRef } from 'react'
import {
  Text,
  View,
  StyleSheet,
  Pressable,
  ActivityIndicator,
  Image,
  Modal,
  FlatList,
  ScrollView,
} from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { Colors, Typography } from 'src/design-system/theme'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import {
  getPatientWithSheets,
  getPatientWithSheetsVariables,
  getPatientWithSheets_getPatient_sheets_items as FinalisedSheetItem,
} from 'src/types/getPatientWithSheets'
import { navigate } from 'components/Base/navigation/navigation'
import { useWebOutsideClick } from 'src/hooks/useOutsideClick'
import { useTranslation } from 'react-i18next'
import { SvgCross } from 'components/Icons'
import { Shadows } from 'constants/Shadows'
import { isEmpty } from 'lodash'
import { environment } from 'src/config'

type Props = {
  patientId: string
  onClose: () => void
}

export const SheetActionFinalisedSheets: React.FC<Props> = ({
  patientId,
  onClose,
}) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { dateFormatter } = useAdminTimeFormat()
  const { data: patientSheets, loading: isLoading } = useQuery<
    getPatientWithSheets,
    getPatientWithSheetsVariables
  >(GET_PATIENT_WITH_SHEETS, {
    variables: { id: patientId, organisation_id: organisationId },
  })

  const isWeb = environment.isWeb

  const navigateToFinalisedSheet = (sheet: FinalisedSheetItem) => {
    onClose()
    navigate(Routes.Sheet, { patientId, sheetId: sheet.id })
  }

  const finalisedSheets = useMemo(() => {
    const sheets = patientSheets?.getPatient?.sheets?.items
    return sheets?.filter(sheet => !!sheet?.closed_at)
  }, [patientSheets?.getPatient?.sheets?.items])

  const hasFinalisedSheet = !isEmpty(finalisedSheets)

  const containerStyle = useMemo(() => {
    return hasFinalisedSheet
      ? isWeb && styles.finalisedSheetsContainer
      : styles.imageContainer
  }, [hasFinalisedSheet, isWeb])

  const FinalisedSheetRow = (sheet: FinalisedSheetItem) =>
    useMemo(() => {
      return (
        <Pressable
          style={styles.finalisedSheetsRow}
          onPress={() => navigateToFinalisedSheet(sheet)}
          accessibilityRole="button"
          accessibilityLabel={`Navigate to ${sheet.name}`}
        >
          <Text style={styles.finalisedSheetName} numberOfLines={1}>
            {sheet.name}
          </Text>
          <Text style={styles.finalisedSheetDate}>
            {dateFormatter(parseISO(sheet.closed_at!))}
          </Text>
        </Pressable>
      )
    }, [sheet])

  return (
    <View style={containerStyle}>
      {isLoading ? (
        <ActivityIndicator style={styles.loadingOverlay} />
      ) : hasFinalisedSheet ? (
        <ScrollView showsVerticalScrollIndicator={true}>
          <FlatList
            data={finalisedSheets}
            keyExtractor={sheet => sheet.id}
            renderItem={({ item: sheet }) => <FinalisedSheetRow {...sheet} />}
            accessibilityLabel="Finalised sheets list"
          />
        </ScrollView>
      ) : (
        <>
          <Image
            style={styles.emptySheetsImage}
            source={require('assets/images/finalised-sheets.png')}
            accessibilityLabel="Empty sheets image"
          />
          <Text style={styles.emptySheetsText}>
            {`${t('patient:action.finalisedSheet.empty', {
              name: patientSheets?.getPatient?.name,
            })}`}
          </Text>
        </>
      )}
    </View>
  )
}

type ModalProps = {
  visible: boolean
  patientId: string
  onClose: () => void
}

export const SheetActionFinalisedSheetsModal: React.FC<ModalProps> = ({
  visible,
  patientId,
  onClose,
}) => {
  const { t } = useTranslation()
  const modalContentRef = useRef<View>(null)
  useWebOutsideClick({ ref: modalContentRef, callback: () => onClose() })

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="fade"
      onRequestClose={onClose}
      accessibilityLabel={t(`patient:action.finalisedSheet.label`)}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent} ref={modalContentRef}>
          <View style={styles.header}>
            <Text style={styles.headerText}>
              {t(`patient:action.finalisedSheet.label`)}
            </Text>
            <Pressable onPress={onClose}>
              <SvgCross color={Colors.Contents.secondary} />
            </Pressable>
          </View>
          <SheetActionFinalisedSheets onClose={onClose} patientId={patientId} />
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: 480,
    backgroundColor: 'white',
    borderRadius: 8,
    overflow: 'hidden',
    ...Shadows.cardShadow,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    backgroundColor: Colors.Backgrounds.primary,
  },
  headerText: {
    color: Colors.Contents.primary,
    ...Typography.Heading.M,
  },
  finalisedSheetsContainer: {
    maxHeight: 600,
  },
  loadingOverlay: {
    top: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  finalisedSheetsRow: {
    flexDirection: 'row',
    height: 60,
    paddingVertical: 19,
    paddingHorizontal: 16,
    borderTopWidth: 1,
    borderColor: Colors.Borders.primary,
    justifyContent: 'space-between',
  },
  finalisedSheetName: {
    flex: 2,
    maxWidth: 260,
    color: Colors.Contents.primary,
    ...Typography.Label.L,
  },
  finalisedSheetDate: {
    color: Colors.Contents.tertiary,
    ...Typography.Label.L,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 40,
  },
  emptySheetsImage: {
    height: 234,
    width: 234,
    marginBottom: 10,
    flexShrink: 0,
  },
  emptySheetsText: {
    color: Colors.Contents.secondary,
    ...Typography.Label.bold,
  },
})
