import React, { useMemo } from 'react'
import { ChartText } from 'components/common/Chart/ChartText'
import { ScaleLinear, ScaleTime } from 'd3-scale'
import { G, Line } from 'react-native-svg'
import {
  AnesthesiaChartSymbolShape,
  AnesthesiaChartValueData,
} from 'src/context/types/anesthesia'

import { ChartSVGIconPicker } from './ChartSVGIconPicker'

type Props = {
  color: string
  data: AnesthesiaChartValueData[]
  isMobileScreen: boolean
  showText?: boolean
  symbolShape: AnesthesiaChartSymbolShape
  title: string
  xScale: ScaleTime<number, number>
  yScale: ScaleLinear<number, number>
}

export const RowChartEntry: React.FC<Props> = ({
  color,
  data,
  isMobileScreen,
  showText,
  symbolShape,
  title,
  xScale,
  yScale,
}) => {
  const [lineElements, textIconElements] = useMemo(() => {
    const lineElements = []
    const textIconElements = []

    for (let i = 0; i < data.length; i += 1) {
      const { time, value } = data[i]
      const x1 = xScale(time) ?? 0
      const y1 = yScale(value) ?? 0

      /* We draw a line between each coordinate, but don't touch the actual coordinate, to allow transparency behind the icons */
      if (i + 1 < data.length) {
        const { time: nextTime, value: nextValue } = data[i + 1]
        const x2 = xScale(nextTime) ?? 0
        const y2 = yScale(nextValue) ?? 0

        const dx = x2 - x1
        const dy = y2 - y1
        const length = Math.sqrt(dx * dx + dy * dy) || 1 // ensure no divide by 0

        const paddingBetweenLines = 3 // px from actual data point the line will end
        const ratio = (length - 2 * paddingBetweenLines) / length

        lineElements.push(
          <Line
            x1={x1 + dx * ratio}
            x2={x2 - dx * ratio}
            y1={y1 + dy * ratio}
            y2={y2 - dy * ratio}
            stroke={color}
            strokeWidth={1.5}
            key={`${title}Line${i}`}
          />,
        )
      }

      /* Draw the Icon on each point, with optional text */
      textIconElements.push(
        <G key={`${title}Text${i}`} x={x1} y={y1}>
          {!!showText && (
            <ChartText isSmall={isMobileScreen}>{value}</ChartText>
          )}
          <G transform="scale(1.6)">
            <ChartSVGIconPicker symbolShape={symbolShape} color={color} />
          </G>
        </G>,
      )
    }
    return [lineElements, textIconElements]
  }, [
    data,
    xScale,
    yScale,
    color,
    title,
    showText,
    isMobileScreen,
    symbolShape,
  ])

  return (
    <G>
      {lineElements}
      {textIconElements}
    </G>
  )
}
