import React from 'react'
import { useQuery } from '@apollo/client'
import {
  Props as AddTreatmentFormProps,
  TreatmentForm,
} from 'components/TreatmentForm'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { GET_PRODUCT_DETAILS } from 'src/components/AddTreatment/graphql'
import { useOrganisation } from 'src/context/organisation'
import {
  getOneProduct as GetOneProduct,
  getOneProductVariables as GetOneProductVariables,
} from 'src/types/getOneProduct'

type Props = AddTreatmentFormProps

export const FormContainer: React.FC<Props> = ({
  product,
  defaultOverrides,
  patient,
  onSave,
  submitTitle,
  deleteTreatment,
  shouldDisableGeneralAutoCompleted,
}) => {
  const [{ organisationId, hasPIMSIntegration }] = useOrganisation()
  const productId = product.id!
  const { data: productData, loading } = useQuery<
    GetOneProduct,
    GetOneProductVariables
  >(GET_PRODUCT_DETAILS, {
    skip: !productId,
    variables: {
      id: productId,
      organisation_id: organisationId,
    },
  })
  const productDetail = productData?.getProduct

  if (loading) {
    return <ActivityIndicator size="large" style={styles.activityIndicator} />
  }
  if (!productDetail) {
    return null
  }
  return (
    <TreatmentForm
      defaultOverrides={defaultOverrides}
      onSave={onSave}
      patient={patient}
      product={productDetail}
      submitTitle={submitTitle}
      hasPIMSIntegration={!!hasPIMSIntegration}
      deleteTreatment={deleteTreatment}
      shouldDisableGeneralAutoCompleted={shouldDisableGeneralAutoCompleted}
      showEditableTreatmentName={false}
      showTreatmentName={false}
    />
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    marginTop: 25,
  },
})
