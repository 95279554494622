import React, { useCallback, useMemo } from 'react'
import { SelectOption, SelectProps, Group, PossibleSelectValues } from '..'
import { SelectBase } from '../base/base.web'
import { ActionMeta, Options, StylesConfig } from 'react-select'
import { Colors, Typography } from 'src/design-system/theme'

const isGroup = (item: SelectOption | Group | undefined): item is Group => {
  return !!item && 'options' in item
}

type ExtendedOption = SelectOption & {
  group?: PossibleSelectValues
  options?: SelectOption[]
}

/** An item will appear as selected if its parent is selected */
const isOptionSelected = (
  option: SelectOption,
  valuesSelected: Options<ExtendedOption>,
) =>
  valuesSelected?.some(item => {
    if (item.value === option.value) {
      return true
    }
    if (isGroup(item)) {
      return item.options.some(
        nestedOption => nestedOption.value === option.value,
      )
    }
    return false
  })

export const NestedMultiSelect: React.FC<SelectProps> = ({
  options,
  ...props
}) => {
  /** Enrich each Group with it's top level as a selectable option, and add the id/value of parents to sub-options. This makes entire groups selectable, and helps processing changes. */
  const selectableGroups: Array<ExtendedOption | SelectOption | Group> =
    useMemo(
      () =>
        options.map(option => {
          if (isGroup(option) && option.value) {
            return {
              ...option,
              options: [
                {
                  text: option.label,
                  value: option.value,
                  options: option.options,
                },
                ...option.options.map(opt => ({
                  value: opt.value,
                  text: opt.text,
                  group: option.value,
                })),
              ],
            }
          }
          return option
        }),
      [options],
    )

  const handleGroupChange = useCallback(
    (
      newSelected: PossibleSelectValues[],
      event?: ActionMeta<ExtendedOption>,
    ) => {
      const selectedOption = event?.option
      if (!selectedOption) return props.onChange(newSelected)

      /** Selecting a group */
      if (isGroup(selectedOption) && event.action === 'select-option') {
        const optionValuesInGroup = selectedOption.options.map(opt => opt.value)
        const filteredGroups = newSelected.filter(value => {
          return !optionValuesInGroup.includes(value)
        })
        return props.onChange(filteredGroups)
      }
      /** De-selecting an option in a selected group */
      if (
        event.action === 'deselect-option' &&
        !newSelected.includes(selectedOption.value)
      ) {
        const withoutGroup = newSelected.filter(
          v => v !== selectedOption?.group,
        )
        return props.onChange([...withoutGroup])
      }
      /** Selecting an sub-option in a Group */
      if (
        event.action === 'select-option' &&
        !isGroup(selectedOption) &&
        selectedOption.group
      ) {
        const parentGroup = options.find(
          option => option.value === selectedOption.group,
        ) as Group
        const valuesWithinParent = parentGroup.options.map(
          option => option.value,
        )
        if (
          valuesWithinParent.every((val: PossibleSelectValues) =>
            newSelected.includes(val),
          )
        ) {
          const withoutChildren =
            newSelected.filter(val => !valuesWithinParent.includes(val)) || []
          return props.onChange([parentGroup.value!, ...withoutChildren])
        }
      }
      /** Default */
      props.onChange(newSelected)
    },
    [options, props],
  )

  return (
    <SelectBase
      {...props}
      options={selectableGroups}
      onChange={handleGroupChange}
      isOptionSelected={isOptionSelected}
      styles={styleFunction}
      isMulti={true}
    />
  )
}

const styleFunction: StylesConfig<any, boolean, any> = {
  group: base => ({
    ...base,
  }),
  groupHeading: (base, { data }) => ({
    ...base,
    display: data.value ? 'none' : 'inital', // Hide the heading if the top level is selectable
  }),
  option: (base, { data }) => ({
    ...base,
    fontFamily: data?.options
      ? Typography.FontFamilies.semibold
      : base.fontFamily,
    paddingLeft: data?.group ? 24 : base.paddingLeft,
    fontSize: data?.group ? 12 : base.fontSize,
  }),
  multiValueLabel: base => ({
    ...base,
    fontFamily: Typography.FontFamilies.base,
    color: Colors.Contents.primary,
  }),
  multiValue: base => ({
    ...base,
    padding: 2,
    marginLeft: 0,
    backgroundColor: Colors.Backgrounds.overlay,
    height: 20,
    borderRadius: 10,
    alignItems: 'center',
  }),
  multiValueRemove: (base, state) => ({
    ...base,
    display: state.isDisabled ? 'none' : 'flex',
    color: 'grey', // Using same as SVG
    '&:hover': {
      backgroundColor: 'transparent',
      color: Colors.Contents.primary,
    },
  }),
}
