import React from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'

import { useTranslation } from 'react-i18next'
import { CancelButton, ContinueButton } from '../zTEMP-buttons'

export const IntroductionModalContent: React.FC<{
  navigateToTrainingSite: () => void
  onClose: () => void
}> = ({ navigateToTrainingSite, onClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <View style={styles.header}>
        <Text style={styles.title}>
          {t('training:introductionModal:titleLong')}
        </Text>
        <Text>{t('training:introductionModal:description')}</Text>
      </View>

      <Image
        style={styles.image}
        source={require('assets/images/training.png')}
      />

      <View style={styles.footer}>
        {/* TODO temp buttons */}
        <ContinueButton
          title={t('training:continue')}
          onPress={() => {
            navigateToTrainingSite()
            onClose()
          }}
        />
        <CancelButton title={t('training:cancel')} onPress={onClose} />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  header: {
    padding: 16,
    borderBottomWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  title: {
    fontSize: Typography.FontSizes.md,
    fontWeight: Typography.FontWeights.bold,
    marginBottom: 8,
  },
  image: {
    alignSelf: 'center',
    margin: 16,
    width: 280,
    height: 160,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.Backgrounds.primary,
    padding: 16,
    borderTopWidth: 1,
    borderColor: Colors.Borders.primary,
  },
})
