import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number
  color?: string
}

export const InvalidPasswordSvg = ({ size = 14, color = '#EA2839' }: Props) => {
  return (
    <Svg width={size} height={(size * 12) / 14} viewBox="0 0 14 12" fill="none">
      <Path
        d="M6.99949 0C7.3878 0 7.74603 0.200893 7.94292 0.530357L13.8496 10.3875C14.0492 10.7196 14.0492 11.1295 13.8551 11.4616C13.6609 11.7937 13.2972 12 12.9062 12H1.09282C0.701775 12 0.338077 11.7937 0.143923 11.4616C-0.0502316 11.1295 -0.047497 10.717 0.149392 10.3875L6.05607 0.530357C6.25295 0.200893 6.61118 0 6.99949 0ZM6.99949 3.42857C6.63579 3.42857 6.34319 3.71518 6.34319 4.07143V7.07143C6.34319 7.42768 6.63579 7.71429 6.99949 7.71429C7.36319 7.71429 7.65579 7.42768 7.65579 7.07143V4.07143C7.65579 3.71518 7.36319 3.42857 6.99949 3.42857ZM7.87455 9.42857C7.87455 9.20124 7.78236 8.98323 7.61825 8.82248C7.45415 8.66173 7.23157 8.57143 6.99949 8.57143C6.76741 8.57143 6.54484 8.66173 6.38073 8.82248C6.21662 8.98323 6.12443 9.20124 6.12443 9.42857C6.12443 9.6559 6.21662 9.87392 6.38073 10.0347C6.54484 10.1954 6.76741 10.2857 6.99949 10.2857C7.23157 10.2857 7.45415 10.1954 7.61825 10.0347C7.78236 9.87392 7.87455 9.6559 7.87455 9.42857Z"
        fill={color}
      />
    </Svg>
  )
}
