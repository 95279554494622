import Svg, { Circle, Line } from 'react-native-svg'
import React from 'react'

type Props = {
  color?: string
  height?: number
  width?: number
}

export const SvgIVFluidScheduledStart: React.FC<Props> = ({
  color = '#00bbd4',
  height = 20,
  width = 25,
}) => (
  <Svg viewBox="0 0 25 20" height={height} width={width} fill="none">
    <Circle
      cx="10"
      cy="10"
      r="8.5"
      transform="matrix(-1, 0, 0, 1, 19.999999999999996, 0)"
      stroke={color}
      strokeWidth="3"
      fill="rgba(255, 255, 255, 0)"
    />
    <Line
      y1="-2"
      x2="6"
      y2="-2"
      transform="matrix(1, -8.742279788975793e-8, -8.742279788975793e-8, -1, 18.999999999999996, 7.999999999999999)"
      stroke={color}
      strokeWidth="4"
    />
  </Svg>
)
