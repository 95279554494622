/* eslint-disable no-bitwise */
// BM: Taken from https://github.com/GabiGrin/hex-to-rgb-string/blob/master/index.js
export function hexToRGB(hex: string, opacity = '1.00') {
  if (!hex || !hex.match(/^#(?:[0-9a-f]{6})$/i)) {
    throw new Error(`Invalid hex color string ${hex}`)
  }
  hex = hex.replace('#', '')

  const decimalValue = parseInt(hex, 16)
  const r = (decimalValue >> 16) & 255
  const g = (decimalValue >> 8) & 255
  const b = decimalValue & 255

  return `rgba(${r},${g},${b},${opacity})`
}
