import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  FormBreak,
  FormLabel,
  InformativeMessage,
  InputGroup,
  MultiSelectFormField,
  NumericInput,
  Select,
  SelectTimePicker,
  SwitchInput,
  TextArea,
  TextInput,
} from 'components/common'
import { Status } from 'components/common/Form/Status'
import { InputStatusType } from 'components/common/Form/utils'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { SINGLE_TASK } from 'components/FrequencySelector/data'
import { FrequencySelector } from 'components/FrequencySelector/FrequencySelector'
import { getProductMustHaveValue } from 'components/Task/utils/getTreatmentInfo'
import { getFrequencyInputFromSchedule } from 'components/Treatment/utils/treatmentSchedule.utils'
import {
  perWeightUnitOptions,
  RepeatScheduleValue,
  repeatUntilOptions,
  standardWeightUnitOptions,
  timeWindowOptions,
  weightUnitOptions,
} from 'components/TreatmentForm/data'
import { getDefaultDosageValues } from 'components/TreatmentForm/utils/getDefaultDosageValues'
import {
  isNonStandardWeightUnits,
  isStandardWeightUnits,
} from 'components/TreatmentForm/utils/isNonStandardWeightUnits'
import { useSafeDosageRange } from 'components/TreatmentForm/utils/useSafeDosageRange'
import { WeightUnit } from 'constants/Weight'
import { isNil, isNull, noop } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useConfirm } from 'src/context/confirm'
import { useOrganisation } from 'src/context/organisation'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import {
  FrequencyType,
  ProductType,
  TreatmentTaskType,
} from 'types/globalTypes'
import {
  getTemplate_getTemplate_treatments_items_treatments_items_treatment_options_products_medicine_dosage_info as medicationDosageInfo,
  getTemplate_getTemplate_treatments_items_treatments_items_treatment_options_products_pims_mapping as Pims_mapping,
} from 'src/types/getTemplate'
import { TemplateTreatment } from 'components/TreatmentTemplate/TemplateTreatmentForm'
import { Colors } from 'src/design-system/theme'
import { getTemplateTreatmentBilling } from 'components/TreatmentTemplate/utils/getTemplateTreatmentBilling'
import { HelpTooltip } from 'components/common/Tooltip/HelpTooltip'

export interface TreatmentOption {
  id: string
  name: string
  isBillable: boolean | null
  groupName: string
  code: string | null
  type: string | null
  pims_mapping?: Pims_mapping[] | null
  medicine_dosage_info?: medicationDosageInfo | null
  track_vital?: boolean | null
  isOldChild?: boolean
  isUnAvailableProduct?: boolean
  origin_id?: string | null
}

const keyboardType = Platform.OS === 'web' ? 'default' : 'numeric'

// nb: Just for form typings. Will get default from GET_TREATMENT_FORM cache
const defaultValues = {
  name: '',
  instructions: '',
  selectedTreatmentOptions: [] as string[],
  conditional: false,
  dosageRate: null as number | null,
  dosageWeightUnit: '' as string | null,
  dosagePerWeightUnit: '' as string | null,
  /* We set billing to default false instead of null to fix when editing
     products, display billing control goes null | false, but billing
     unintentionally set to true. Should be fixed by always displaying control
     and removing TemplateTreatmentForm->showBillingControl logic */
  isBillable: false as boolean | null,
  isAutoCompleted: false as boolean | null,
  schedule: {
    repeat: RepeatScheduleValue.SINGLE as RepeatScheduleValue,
    start_at: null,
    time_window: timeWindowOptions[4].value,
    frequency: 0,
    repeat_until: 0,
    enable_staffed_hour: true as boolean | null,
    type: FrequencyType.INTERVAL,
    treatment_frequency_id: '',
  },
  frequencyInput: SINGLE_TASK,
  type: TreatmentTaskType.NORMAL,
}

export type Values = typeof defaultValues

export type Props = {
  procedureTreatment?: TemplateTreatment
  treatmentOptions?: TreatmentOption[]
  onSave: (data: Values) => void
  onClickTemplatePickProduct: () => void
}

const dosageRateUnitOptions = [
  ...weightUnitOptions,
  {
    value: WeightUnit.ML,
    text: 'mL',
  },
]

export const ProcedureTreatmentSettingScreen: React.FC<Props> = ({
  procedureTreatment: templateTreatment,
  treatmentOptions = [],
  onSave,
  onClickTemplatePickProduct,
}) => {
  const { t } = useTranslation()
  const [{ hasPIMSIntegration }] = useOrganisation()

  const { settingsMap } = useOrgSettings()
  const isStaffedHoursEnabled =
    settingsMap.ENABLE_OPERATING_HOURS.value === 'true'
  const [isDosageInfoModified, setIsDosageInfoModified] = useState<boolean>(
    !!templateTreatment?.medicine_dosage_info,
  )

  // VR-2063: Force finish setup on template treatment of single medication product
  const isSingleMedicationProduct =
    treatmentOptions.length === 1 &&
    treatmentOptions[0].type === ProductType.MEDICATION

  // single fluid does not have `no task` and `repeating tasks` option
  const isSingleFluidProduct =
    treatmentOptions.length === 1 &&
    treatmentOptions[0].type === ProductType.IVFLUIDS

  const isSingleMedicationOrFluid =
    isSingleMedicationProduct || isSingleFluidProduct

  const treatmentFormOptions = treatmentOptions?.map(
    treatmentOption => treatmentOption.name,
  )

  // VR-4911: Show the billing (default) option if *any* treatment is billable
  const treatmentOptionsSomeBillable = treatmentOptions.some(
    treatment => treatment.isBillable,
  )

  const treatmentIsBillable = templateTreatment?.is_billable ?? false

  const initialBillableState = getTemplateTreatmentBilling({
    isEdit: false, // TODO - Review once procedure treatment is linked
    productsBillable: treatmentOptionsSomeBillable,
    treatmentBillable: treatmentIsBillable,
  })

  // Show if initally possible (ie. is_billable on treatmentOption)
  const showBillingControl = hasPIMSIntegration && !isNull(initialBillableState) // TODO

  const initName =
    treatmentFormOptions.length === 1
      ? treatmentFormOptions[0]
      : templateTreatment?.name

  const dosageInfo = treatmentOptions[0]?.medicine_dosage_info

  const shouldDisableDosageWeightUnit = isNonStandardWeightUnits(
    dosageInfo?.concentration_weight_unit as WeightUnit,
  )
  const shouldConcentrationWeightUnitOverrideDefault =
    isNonStandardWeightUnits(
      dosageInfo?.concentration_weight_unit as WeightUnit,
    ) &&
    dosageInfo?.concentration_weight_unit !== dosageInfo?.dosage_weight_unit

  const defaultDosageValues = {
    dosageRate: null,
    dosageWeightUnit: shouldConcentrationWeightUnitOverrideDefault
      ? dosageInfo?.concentration_weight_unit
      : null,
    dosagePerWeightUnit: null,
  }

  const { dosageValues = defaultDosageValues, defaultDosageValuesHasError } =
    useMemo(
      () =>
        getDefaultDosageValues(
          shouldConcentrationWeightUnitOverrideDefault,
          dosageInfo,
        ),
      [dosageInfo, shouldConcentrationWeightUnitOverrideDefault],
    )

  const savedDosageValues =
    templateTreatment?.medicine_dosage_info &&
    templateTreatment?.name === treatmentOptions[0]?.name
      ? {
          dosageRate: templateTreatment.medicine_dosage_info.dosage,
          dosageWeightUnit:
            templateTreatment.medicine_dosage_info.dosage_weight_unit,
          dosagePerWeightUnit:
            templateTreatment.medicine_dosage_info.dosage_patient_weight_unit,
        }
      : null

  const newScheduleFromTreatmentForm = useMemo(() => {
    const staffedHoursOverride = isStaffedHoursEnabled
      ? {}
      : { enable_staffed_hour: false }
    if (isNil(templateTreatment?.schedule)) {
      return {
        ...defaultValues.schedule,
        ...staffedHoursOverride,
        frequency: -1,
        repeat: RepeatScheduleValue.NONE,
      }
    }
    if (templateTreatment?.schedule.repeat) {
      return {
        ...templateTreatment.schedule,
        ...staffedHoursOverride,
        repeat: RepeatScheduleValue.REPEATING,
      }
    }
    return {
      ...templateTreatment?.schedule,
      ...staffedHoursOverride,
      repeat: RepeatScheduleValue.SINGLE,
    }
  }, [isStaffedHoursEnabled, templateTreatment?.schedule])

  const initialValues = {
    ...defaultValues,
    ...templateTreatment,
    ...getFrequencyInputFromSchedule(templateTreatment?.schedule),
    schedule: newScheduleFromTreatmentForm,
    selectedTreatmentOptions: treatmentFormOptions,
    isAutoCompleted: !!templateTreatment?.is_auto_completed,
    ...(showBillingControl && {
      isBillable: false,
    }),
    ...(isSingleMedicationOrFluid && {
      conditional: true,
    }),
    ...(isSingleMedicationProduct && {
      ...dosageValues,
      ...savedDosageValues,
    }),
    name: initName,
  } as Values

  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
  })

  // Watching all fields lose the benefit of regional rendering react-hook-form,
  // but easier and safer to refactor from formik in this case.
  const values = watch()
  const watchedFrequencyInput = watch('frequencyInput')
  const safeDosageRangeInput = {
    dosageInfo,
    defaultDosageValuesHasError,
    dosageRateValue: values.dosageRate,
    dosageWeightUnitValue: values.dosageWeightUnit as WeightUnit,
    dosagePerWeightUnitValue: values.dosagePerWeightUnit as WeightUnit,
    productName: treatmentOptions[0]?.name,
  }
  const {
    invalidSafeDoseRangeErrorMessage,
    safeDoseRangeMessage,
    dosageRateOutOfSafeRangeWarningMessage,
    safeDoseRangeHasError,
  } = useSafeDosageRange(safeDosageRangeInput)

  const confirm = useConfirm()
  const isMultiTreatmentOptions = values.selectedTreatmentOptions.length > 1

  const handleChange = useCallback(
    (field: any) => (value: any) => {
      setValue(field, value, { shouldDirty: true })
      trigger()
    },
    [setValue, trigger],
  )

  // useEffect for when the new frequency selector is used
  useEffect(() => {
    if (isSingleFluidProduct) return
    if (watchedFrequencyInput.type === 'NO_TASKS' && !isMultiTreatmentOptions) {
      setValue('schedule.repeat', RepeatScheduleValue.NONE)
    } else if (watchedFrequencyInput.type === 'SINGLE_TASK') {
      setValue('schedule.repeat', RepeatScheduleValue.SINGLE)
    } else {
      setValue('schedule.repeat', RepeatScheduleValue.REPEATING)
    }
    if (watchedFrequencyInput?.type === FrequencyType.MINS_FROM_MIDNIGHT)
      setValue('schedule.enable_staffed_hour', false)
  }, [
    isSingleFluidProduct,
    isMultiTreatmentOptions,
    watchedFrequencyInput,
    setValue,
  ])

  const isSingleFluidOrMultiWithNoTasks =
    isSingleFluidProduct ||
    (isMultiTreatmentOptions && watchedFrequencyInput.type === 'NO_TASKS')

  useEffect(() => {
    if (isSingleFluidOrMultiWithNoTasks) {
      handleChange('schedule.repeat')(RepeatScheduleValue.SINGLE)
      setValue('frequencyInput', SINGLE_TASK)
    }
  }, [handleChange, isSingleFluidOrMultiWithNoTasks, setValue])

  const shouldShowAutoCompleted = useMemo(() => {
    return !treatmentOptions.some(
      treatmentOption =>
        treatmentOption.type === ProductType.IVFLUIDS ||
        getProductMustHaveValue({
          product: {
            ...treatmentOption,
            origin_id: treatmentOption.origin_id ?? null,
          },
        }),
    )
  }, [treatmentOptions])

  const hasUnAvailableProduct = useMemo(() => {
    return treatmentOptions.some(
      treatmentOption => treatmentOption.isUnAvailableProduct,
    )
  }, [treatmentOptions])

  const handleSave = useCallback(async () => {
    // VR-7773: if the user edits treatment and doesn't alter a value which is out of the safe dosage range, don't warn them again
    const isDosageFieldsDirty =
      dirtyFields.dosageRate ||
      dirtyFields.dosageWeightUnit ||
      dirtyFields.dosagePerWeightUnit
    const isValueOutOfSafeDoseRange = !!dosageRateOutOfSafeRangeWarningMessage
    const savedDataExists = !!templateTreatment?.medicine_dosage_info
    if (
      !isValueOutOfSafeDoseRange ||
      (savedDataExists && !isDosageFieldsDirty)
    ) {
      handleSubmit(onSave)()
      return
    }
    await confirm({
      title: t('form.confirmDosageOutsideSafeRange.title'),
      text: t('form.confirmDosageOutsideSafeRange.text', {
        dosageRate: values.dosageRate,
        weightUnit: values.dosageWeightUnit,
        perWeightUnit: values.dosagePerWeightUnit,
        dosageSafeRangeText: safeDoseRangeMessage.split(': ')[1],
      }),
      okText: t('form.confirmDosageOutsideSafeRange.ok'),
      cancelText: t('form.confirmDosageOutsideSafeRange.cancel'),
    })
      .then(handleSubmit(onSave))
      .catch(noop)
  }, [
    confirm,
    dirtyFields.dosagePerWeightUnit,
    dirtyFields.dosageRate,
    dirtyFields.dosageWeightUnit,
    dosageRateOutOfSafeRangeWarningMessage,
    handleSubmit,
    onSave,
    safeDoseRangeMessage,
    t,
    templateTreatment?.medicine_dosage_info,
    values.dosagePerWeightUnit,
    values.dosageRate,
    values.dosageWeightUnit,
  ])

  return (
    <>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        extraHeight={-64} // set to tabBarBottom height const
        keyboardOpeningTime={0}
      >
        {!!treatmentOptions.length ? (
          <Status
            status={
              hasUnAvailableProduct
                ? { type: InputStatusType.Error }
                : createErrorStatus(
                    errors.selectedTreatmentOptions?.message,
                    true,
                  )
            }
          >
            <MultiSelectFormField
              label={t('template:form.treatmentOptions')}
              onClick={onClickTemplatePickProduct}
              placeholder={t('template:form.selectPlaceholder')}
              selected={values.selectedTreatmentOptions}
            />
          </Status>
        ) : null}
        {isMultiTreatmentOptions ? (
          <Status status={createErrorStatus(errors.name?.message, true)}>
            <TextInput
              label={t('template:form.name')}
              onChangeText={handleChange('name')}
              value={values.name}
            />
          </Status>
        ) : null}
        <FormBreak />
        <SwitchInput
          disabled={isSingleMedicationOrFluid || isMultiTreatmentOptions}
          label={t('template:treatment.finishSetup')}
          value={isMultiTreatmentOptions || values.conditional}
          onChangeValue={handleChange('conditional')}
        />
        {isSingleMedicationProduct ? (
          <>
            <FormBreak />
            <Status
              status={createErrorStatus(
                t('form.invalidDefaultDosageValues'),
                defaultDosageValuesHasError && !isDosageInfoModified,
                InputStatusType.Warning,
              )}
            >
              <Status
                status={createErrorStatus(
                  dosageRateOutOfSafeRangeWarningMessage,
                  !!dosageRateOutOfSafeRangeWarningMessage,
                  InputStatusType.Warning,
                )}
              >
                <Status
                  status={createErrorStatus(
                    invalidSafeDoseRangeErrorMessage,
                    !!invalidSafeDoseRangeErrorMessage,
                    InputStatusType.Error,
                  )}
                >
                  <InputGroup>
                    <NumericInput
                      label={t('template:treatment:defaultDosage')}
                      keyboardType={keyboardType}
                      value={values.dosageRate ?? null}
                      onChange={v => {
                        handleChange('dosageRate')(v)
                        !isDosageInfoModified && setIsDosageInfoModified(true)
                      }}
                      style={styles.flex}
                    />
                    <Select
                      selected={values.dosageWeightUnit}
                      options={
                        isStandardWeightUnits(
                          dosageInfo?.concentration_weight_unit as WeightUnit,
                        )
                          ? standardWeightUnitOptions
                          : dosageRateUnitOptions
                      }
                      onChange={v => {
                        handleChange('dosageWeightUnit')(v)
                        !isDosageInfoModified && setIsDosageInfoModified(true)
                      }}
                      a11yLabel={t('addTreatment:dosageWeightUnit')}
                      disabled={shouldDisableDosageWeightUnit}
                    />
                    <Select
                      selected={values.dosagePerWeightUnit}
                      options={perWeightUnitOptions}
                      onChange={v => {
                        handleChange('dosagePerWeightUnit')(v)
                        !isDosageInfoModified && setIsDosageInfoModified(true)
                      }}
                      a11yLabel={t('addTreatment:dosagePerWeightUnit')}
                    />
                  </InputGroup>
                </Status>
              </Status>
            </Status>
            {!defaultDosageValuesHasError &&
              !safeDoseRangeHasError &&
              !!safeDoseRangeMessage && (
                <InformativeMessage messageText={safeDoseRangeMessage} />
              )}
          </>
        ) : null}
        <FormLabel text={t('template:treatment.defaultSchedule')} />
        {!isSingleFluidProduct ? (
          <FrequencySelector
            selected={values.frequencyInput}
            onChange={handleChange('frequencyInput')}
            allowNoTasks={!isMultiTreatmentOptions}
            showEditedText={false}
          />
        ) : null}

        {values.schedule.repeat !== RepeatScheduleValue.NONE && (
          <>
            <SelectTimePicker
              onChange={handleChange('schedule.start_at')}
              value={values.schedule.start_at}
            />
            {!isSingleFluidProduct && (
              <Select
                label={t('template:treatment.defaultTimeWindow')}
                options={timeWindowOptions}
                selected={values.schedule.time_window}
                onChange={handleChange('schedule.time_window')}
                dialog={true}
              />
            )}
          </>
        )}

        {values.schedule.repeat === RepeatScheduleValue.REPEATING && (
          <>
            {/* label terminology TBD */}
            <Select
              label={t('template:treatment.defaultRepeatUntil')}
              onChange={handleChange('schedule.repeat_until')}
              options={repeatUntilOptions}
              selected={values.schedule.repeat_until}
            />
            {isStaffedHoursEnabled &&
            watchedFrequencyInput?.type !== FrequencyType.MINS_FROM_MIDNIGHT ? (
              <SwitchInput
                label={t('template:treatment.staffedHoursOnly')}
                tooltip={
                  <HelpTooltip
                    text={t('addTreatment:staffedHoursOnlyTooltip')}
                  />
                }
                onChangeValue={handleChange('schedule.enable_staffed_hour')}
                value={values.schedule.enable_staffed_hour}
              />
            ) : null}
          </>
        )}
        {showBillingControl ? (
          <>
            <FormLabel text={t('template:treatment.integration.title')} />
            <SwitchInput
              label={t('template:treatment.billing.toggle', {
                integration: 'ezyVet',
              })}
              onChangeValue={handleChange('isBillable')}
              style={styles.padTop}
              value={values.isBillable ?? false}
            />
            <FormBreak />
          </>
        ) : null}
        {shouldShowAutoCompleted ? (
          <>
            <FormLabel text={t('template:treatment.defaultIsAutoCompleted')} />
            <SwitchInput
              label={t('template:treatment.defaultIsAutoCompleted')}
              onChangeValue={handleChange('isAutoCompleted')}
              style={styles.padTop}
              value={values.isAutoCompleted ?? false}
            />
            <FormBreak />
          </>
        ) : null}
        <TextArea
          label={t('template:treatment.defaultInstructions')}
          onChangeText={handleChange('instructions')}
          value={values.instructions}
          visibleLines={6}
        />
      </KeyboardAwareScrollView>
      <View style={styles.footer}>
        <Button
          disabled={!isValid}
          // Set all as touched and validate?
          loading={false} // TODO
          onPress={handleSave}
          color={Colors.Buttons.positive}
          testID="SaveTreatmentButton"
          title={t('general.saveChanges')}
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  padTop: {
    paddingTop: 13,
  },
  flex: {
    flex: 1,
  },
  footer: {
    marginVertical: 15,
  },
})
