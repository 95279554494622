import React, { useCallback } from 'react'
import downChevron from 'assets/images/png/icons/chevron_down/downChevron.png'
import upChevron from 'assets/images/png/icons/chevron_up/upChevron.png'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'

import { TreatmentSheet } from './common/types'

const foldButtonHitSlop = { top: 15, bottom: 15 }

type TouchableElementProps = {
  isFolded: boolean
  isRootLevel?: boolean
  onPress: (id: string) => void
  onPressTreatmentGroupTitle: (treatment: TreatmentSheet) => void
  treatment: TreatmentSheet
}

export const TreatmentNode: React.FC<TouchableElementProps> = React.memo(
  ({
    isFolded,
    isRootLevel = true,
    onPress,
    onPressTreatmentGroupTitle,
    treatment,
  }) => {
    const handlePressTreatmentGroupTitle = useCallback(() => {
      onPressTreatmentGroupTitle(treatment)
    }, [onPressTreatmentGroupTitle, treatment])

    const handlePressFoldTitle = useCallback(() => {
      onPress(treatment.id)
    }, [onPress, treatment])

    return (
      <TouchableOpacity
        accessibilityLabel={`Treatment: ${treatment.name}`}
        style={styles.touchableOpacity}
        onPress={
          Platform.OS === 'web' ? handlePressTreatmentGroupTitle : undefined
        }
        onLongPress={
          Platform.OS !== 'web' ? handlePressTreatmentGroupTitle : undefined
        }
      >
        <View style={styles.textContainer}>
          <Text
            numberOfLines={2}
            ellipsizeMode={'tail'}
            style={[
              styles.treatmentText,
              isRootLevel && styles.treatmentTextGroup,
            ]}
          >
            {treatment.name}
          </Text>
        </View>
        <TouchableOpacity
          hitSlop={foldButtonHitSlop}
          onPress={handlePressFoldTitle}
          style={styles.buttonContainer}
        >
          <Image
            source={isFolded ? upChevron : downChevron}
            style={styles.chevronStyle}
          />
        </TouchableOpacity>
      </TouchableOpacity>
    )
  },
)

TreatmentNode.displayName = 'TreatmentNode'

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    maxWidth: 40,
  },
  chevronStyle: {
    height: 24,
    width: 24,
  },
  textContainer: {
    flex: 1,
  },
  touchableOpacity: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  treatmentText: {
    fontFamily: Fonts.regular,
    fontSize: 13,
    fontWeight: 'normal',
    marginLeft: 8,
  },
  treatmentTextGroup: {
    color: Colors.contentPrimary,
    fontFamily: Fonts.bold,
  },
})
