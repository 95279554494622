import React, { useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { StyleSheet, Text, View } from 'react-native'
import { Fonts } from 'src/constants/Fonts'
import { rafInterval } from 'src/utils/rafTimeout'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'

type TimeAndDateFormat = {
  timeFormat: string
  dateFormat: string
}

const getTimeAndDate = ({ timeFormat, dateFormat }: TimeAndDateFormat) => {
  const now = new Date()
  return {
    time: format(now, timeFormat),
    date: format(now, dateFormat),
  }
}

const useClock = () => {
  const { adminTimeFormat, adminDateFormat } = useAdminTimeFormat()

  const dateTimeFormats: TimeAndDateFormat = useMemo(() => {
    let adminReTimeFormat
    if (adminTimeFormat === 'h:mmaaa') {
      adminReTimeFormat = 'h:mm:ssaaa'
    } else if (adminTimeFormat === 'HH:mm') {
      adminReTimeFormat = 'HH:mm:ss'
    }
    return {
      timeFormat: adminReTimeFormat ?? 'HH:mm:ss',
      dateFormat: adminDateFormat ?? 'E dd, MMM',
    }
  }, [adminTimeFormat, adminDateFormat])

  const [timeAndDate, setTimeAndDate] = useState(
    getTimeAndDate(dateTimeFormats),
  )
  useEffect(() => {
    const cancel = rafInterval(() => {
      const curTimeAndDate = getTimeAndDate(dateTimeFormats)
      setTimeAndDate(curTimeAndDate)
    }, 1000)
    return () => cancel()
  }, [dateTimeFormats])

  return timeAndDate
}

export const Clock: React.FC = React.memo(() => {
  const { time, date } = useClock()
  const { isExSmallScreen, isSmallScreen } = useBreakpoint()
  const isMobileScreen = isExSmallScreen || isSmallScreen
  return (
    <View style={styles.section}>
      <View style={styles.clock}>
        <Text style={[styles.timeText, isMobileScreen && styles.smallTimeText]}>
          {time}
        </Text>
        <Text style={[styles.dateText, isMobileScreen && styles.smallDateText]}>
          {date}
        </Text>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  section: {
    alignItems: 'baseline',
    flexDirection: 'row',
  },
  clock: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  timeText: {
    fontFamily: Fonts.regular,
    fontSize: 48,
    fontWeight: 'bold',
  },
  dateText: {
    fontFamily: Fonts.regular,
    fontSize: 22,
    marginLeft: 10,
  },
  smallTimeText: { fontSize: 26, marginLeft: 5 },
  smallDateText: { fontSize: 13, marginLeft: 5 },
})
