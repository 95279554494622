import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { SecondaryButton } from 'components/common'
import { Dialog } from 'components/common/Dialog/Dialog'
import { DialogStates } from 'components/common/Dialog/Dialog.types'
import { ListItem } from 'components/common/ListItem'
import { DischargePatient } from 'components/DischargePatient/DischargePatient'
import { PatientItem } from 'components/PatientItem/PatientItem'
import { SubHeader } from 'components/SubHeader/SubHeader'
import { Routes } from 'constants/Routes'
import { parseISO } from 'date-fns'
import { omit, orderBy, partition } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  SectionList,
  SectionListData,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Center } from 'src/components/common/Center'
import { Colors } from 'src/constants/Colors'
import { useOrganisation } from 'src/context/organisation'
import { useActionSheetHandler } from 'src/utils/useActionSheetHandler'
import {
  getPatientWithSheets,
  getPatientWithSheets_getPatient_sheets_items as SheetItems,
  getPatientWithSheetsVariables,
} from 'types/getPatientWithSheets'
import { GET_PATIENT_WITH_SHEETS } from './graphql'
import { useSheetSubscription } from './useSheetSubscription'
import { sortSheetList } from 'components/SheetList/utils/sortSheetList'
import { useDischargePatient } from 'src/components/DischargePatient/useDischargePatient'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { ApprovalStatus } from 'types/globalTypes'
import { PENDING_APPROVAL_TAG_NAME } from 'components/Tag/Tag'
import { VRBottomSheet } from 'components/BottomSheet'
import { environment } from 'src/config'
import { BottomSheetModal } from '@gorhom/bottom-sheet'

type Props = { onPressOverride?: (params?: SheetItems) => void }

type SectionHeaderInfo = {
  section: SectionListData<SheetItems>
}

type SectionItem = { item: SheetItems }
type SheetListItemProps = SectionItem & {
  onPress: (item: SheetItems) => void
  extraText?: string
  studentApprovalEnabled: boolean
}

const REFERRER_EZYVET = 'ezyvet'

const SheetListItem: React.FC<SheetListItemProps> = ({
  item,
  onPress,
  extraText,
  studentApprovalEnabled,
}) => {
  const onPressSheetListItem = useCallback(() => onPress(item), [item, onPress])

  const tag = useMemo(() => {
    if (item.closed_at) return 'Finalized'

    if (
      studentApprovalEnabled &&
      item.approval_status === ApprovalStatus.PENDING
    )
      return PENDING_APPROVAL_TAG_NAME

    return null
  }, [item.closed_at, item.approval_status, studentApprovalEnabled])

  return (
    <Center>
      <ListItem
        id={item.id}
        name={item.name}
        onPress={onPressSheetListItem}
        tag={tag}
        extraText={extraText}
      />
    </Center>
  )
}

SheetListItem.displayName = 'SheetListItem'

export const SheetList: React.FC<Props> = ({ onPressOverride }) => {
  const { navigate, setParams } = useNavigation()
  const route = useRoute()
  const params = route.params as { patientId: string; referrer?: string }

  const { patientId, referrer } = params

  const { showActionSheetWithOptions } = useActionSheet()
  const [{ organisationId }] = useOrganisation()
  const [visibleDialog, setVisibleDialog] = useState(DialogStates.None)

  const { dischargePatient } = useDischargePatient(patientId)
  const DischargeBottomSheetRef = useRef<BottomSheetModal>(null)

  const { t } = useTranslation()
  const { dateFormatter } = useAdminTimeFormat()

  const queryResult = useQuery<
    getPatientWithSheets,
    getPatientWithSheetsVariables
  >(GET_PATIENT_WITH_SHEETS, {
    variables: { id: patientId, organisation_id: organisationId },
    fetchPolicy: 'cache-and-network',
  })

  useSheetSubscription()

  const goCreateSheetScreen = useCallback(
    () =>
      navigate(Routes.AddSheet, {
        patientId,
      }),
    [navigate, patientId],
  )

  const onPress = useCallback(
    (item: SheetItems) => {
      navigate(Routes.Sheet, {
        patientId,
        sheetId: item.id,
        sheetName: item.name,
        initialDateInView: item.closed_at ? item.closed_at : undefined,
      })
    },
    [navigate, patientId],
  )

  const onPressItem = onPressOverride ?? onPress

  const patient = queryResult?.data?.getPatient
  const headerPatient = omit(patient, ['active_consultations'])
  const fullHeaderColor = useMemo(
    () => patient?.active_consultations?.items?.[0]?.color?.hex,
    [patient?.active_consultations?.items],
  )

  const isDischargedPatient = !patient?.active_consultations?.items?.length

  const sheets = useMemo(
    () => patient?.sheets?.items ?? [],
    [patient?.sheets?.items],
  )

  const data = useMemo(() => {
    const partitionedSheets = partition(sheets, sheet => sheet.closed_at).map(
      groupedSheets => sortSheetList(groupedSheets),
    )

    return [
      {
        title: t('sheets.title'),
        data: partitionedSheets[1],
      },
      {
        title:
          partitionedSheets[0].length > 0 ? t('sheetList:list:finalised') : '',
        data: orderBy(partitionedSheets[0], ['closed_at'], ['desc']),
      },
    ]
  }, [sheets, t])

  const hasActiveSheets = useMemo(
    () => !!data?.length && data[0].data?.length > 0,
    [data],
  )

  useEffect(() => {
    if (referrer !== REFERRER_EZYVET || !hasActiveSheets) {
      return
    }

    const openSheets = data[0].data
    navigate(Routes.Sheet, {
      patientId,
      sheetId: openSheets[0].id,
    })
    return () => {
      setParams({ referrer: undefined })
    }
  }, [data, hasActiveSheets, navigate, patientId, referrer, setParams])

  const handlePressDischarge = useCallback(() => {
    if (environment.isWeb) {
      setVisibleDialog(DialogStates.Discharge)
    } else {
      DischargeBottomSheetRef.current?.present()
    }
  }, [])

  const actionSheetButtons = [
    {
      name: t('newSheet.title'),
      action: goCreateSheetScreen,
    },
    {
      action: handlePressDischarge,
      destructive: true,
      name: t('sheetList:action.discharge'),
    },
  ]

  const actionSheetHandler = useActionSheetHandler({
    showActionSheetWithOptions,
    cancelName: t('general.cancel'),
    buttons: actionSheetButtons,
  })

  const actionButton = {
    title: 'general.action',
    label: 'general.action',
    action: actionSheetHandler,
  }

  const backButton = useMemo(() => {
    return {
      title: 'title.patients',
      label: 'patient:list.returnHereLabel',
      action: () => navigate(Routes.Patients),
    }
  }, [navigate])

  const removeDialog = useCallback(() => {
    setVisibleDialog(DialogStates.None)
  }, [])

  const handlePendingDischargePatient = useCallback(
    async (name: string, dischargeNotes: string) => {
      removeDialog()
      await dischargePatient(name, dischargeNotes)
    },
    [dischargePatient, removeDialog],
  )

  // To remove the bottom border at the end of list
  const renderListFooter = useCallback(
    () => <View style={styles.listFooter} />,
    [],
  )

  const renderListHeader = useCallback(() => {
    return (
      <Center style={styles.paddingTop}>
        {!!patient && (
          <PatientItem
            patient={headerPatient}
            headerColor={fullHeaderColor}
            mode="full"
          />
        )}
      </Center>
    )
  }, [patient, headerPatient, fullHeaderColor])

  const keyExtractor = useCallback((item: SheetItems) => item.id, [])

  const renderSectionListHeader = useCallback(
    ({ section: { title } }: SectionHeaderInfo) => {
      if (!title) return null
      return (
        <Center>
          <Text style={styles.listSeparatorText}>{title}</Text>
        </Center>
      )
    },
    [],
  )

  const renderSectionListFooter = useCallback(
    ({ section: { title } }: SectionHeaderInfo) => {
      if (title !== t('sheetList:list:sheets')) return null
      if (isDischargedPatient) return null
      return (
        <Center>
          <SecondaryButton
            accessibilityLabel={'Create sheet'}
            onPress={goCreateSheetScreen}
            style={styles.createSheetBtn}
            textStyle={styles.createSheetBtnText}
            title={`+ ${t('newSheet.title')}`}
          />
        </Center>
      )
    },
    [goCreateSheetScreen, t, isDischargedPatient],
  )

  const renderSectionItem = useCallback(
    ({ item }: SectionItem) => (
      <SheetListItem
        item={item}
        onPress={onPressItem}
        studentApprovalEnabled={true}
        extraText={
          item.closed_at
            ? `${dateFormatter(parseISO(item.closed_at))}`
            : undefined
        }
      />
    ),
    [onPressItem, dateFormatter],
  )

  return (
    <>
      <SubHeader
        actionButton={isDischargedPatient ? null : actionButton}
        backButton={backButton}
        headline={patient?.name}
      />
      {queryResult.loading ? (
        <ActivityIndicator
          accessibilityLabel="SheetList Loading Indicator"
          size="large"
          style={styles.marginTop}
        />
      ) : (
        <SectionList
          keyExtractor={keyExtractor}
          ListFooterComponent={renderListFooter}
          ListHeaderComponent={renderListHeader}
          renderItem={renderSectionItem}
          renderSectionHeader={renderSectionListHeader}
          renderSectionFooter={renderSectionListFooter}
          stickySectionHeadersEnabled={false}
          sections={data}
          style={styles.borderRadius}
          testID="SheetList"
        />
      )}
      {!environment.isWeb ? (
        <VRBottomSheet
          title={t('discharge:label')}
          ref={DischargeBottomSheetRef}
          handleClose={() => DischargeBottomSheetRef.current?.close()}
        >
          <DischargePatient
            showTitle={false}
            patientId={patientId}
            onDischarge={handlePendingDischargePatient}
          />
        </VRBottomSheet>
      ) : (
        <Dialog
          onCancel={removeDialog}
          onSave={removeDialog}
          showDone={false}
          toggleDialog={removeDialog}
          visible={visibleDialog !== DialogStates.None}
        >
          {visibleDialog === DialogStates.Discharge && (
            <DischargePatient
              patientId={patientId}
              onDischarge={handlePendingDischargePatient}
            />
          )}
        </Dialog>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  borderRadius: {
    borderRadius: 12,
  },
  createSheetBtn: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    marginBottom: 16,
  },
  createSheetBtnText: {
    color: Colors.buttons.text,
  },
  listFooter: {
    height: 16,
  },
  listSeparatorText: {
    color: Colors.contentTertiary,
    fontSize: 18,
    fontWeight: '500',
    paddingTop: 32,
    paddingBottom: 12,
    paddingHorizontal: 16,
  },
  marginTop: {
    marginTop: 25,
  },
  paddingTop: {
    paddingTop: 16,
  },
})
