import { TreatmentSubscriptionHandler } from 'components/Sheet/treatmentSubscription/handlers/types/treatment-handler.type'
import { treatment_treatment_data } from 'types/treatment'

export const useTreatmentCreationHandler = (): TreatmentSubscriptionHandler => {
  // TODO: In the future, we should handle the treatment creation event
  const process = (_treatment: treatment_treatment_data) => {}

  return {
    process,
  }
}
