import { Variables } from '../variables'

export const FontFamilies = {
  base: 'nunitosans-regular',
  semibold: 'nunitosans-semibold',
  bold: 'nunitosans-bold',
  italic: 'nunitosans-italic',
}

export const FontSizes = {
  sm: Variables.Size.md, // 12
  base: Variables.Size.xs * 2, // 14
  md: Variables.Size.sm * 2, // 16
  lg: Variables.Size.base * 2, // 20
  xl: Variables.Size.md * 2, // 24
  xxl: Variables.Size.sm * 4, // 32
}

export const FontWeights: {
  [key in string]:
    | 'normal'
    | 'bold'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
} = {
  regular: '400',
  medium: '500',
  bold: '700',
}

// TODO this formula is wrong
export const LineHeightMultiplier = {
  lg: 1.4, // 140%
}
