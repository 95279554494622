import { resuscitateToTagTitle } from 'components/Tag/utils/patientToTagTitle'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Typography, Colors } from 'src/design-system/theme'
import { Tag } from 'components/Tag/Tag'
import { Resuscitation } from 'src/types/globalTypes'
import { Paragraph } from 'src/design-system/components/Text'

type PatientBreedSectionProps = {
  breed: { name: string | null } | null
  species: { name: string | null } | null
  resuscitate: Resuscitation | null
}

export const PatientBreedSection: React.FC<PatientBreedSectionProps> = ({
  species,
  breed,
  resuscitate,
}) => {
  return (
    <View style={[styles.row, styles.breedContainer]}>
      <TextWithTooltip
        style={styles.flexShrinkContainer}
        numberOfLines={1}
        title={`${species?.name ?? ''} ${breed?.name ? ' • ' : ''} ${
          breed?.name ?? ''
        }`}
      >
        <Paragraph size={'M'} style={[styles.infoText, styles.italicText]}>
          {species?.name ?? ''}
        </Paragraph>
        <Paragraph size={'M'} style={styles.infoText}>
          {breed?.name ? ' • ' : ''}
        </Paragraph>
        <Paragraph size={'M'} style={[styles.infoText, styles.italicText]}>
          {breed?.name ?? ''}
        </Paragraph>
      </TextWithTooltip>
      <Tag
        title={resuscitateToTagTitle(resuscitate)}
        style={styles.tag}
        textStyle={styles.tagText}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    maxWidth: '100%',
    alignItems: 'baseline',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  flexShrinkContainer: {
    flexShrink: 1,
  },
  breedContainer: {
    justifyContent: 'flex-start',
  },
  infoText: {
    color: Colors.Contents.tertiary,
  },
  italicText: {
    fontFamily: Typography.FontFamilies.italic,
  },
  tag: {
    flexShrink: 0,
    marginLeft: 5,
  },
  tagText: {
    fontFamily: Typography.FontFamilies.bold,
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: 20,
    paddingHorizontal: 8,
  },
})
