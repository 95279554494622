import { getAllSheetLogsByOrganisationId_getAllSheetLogsByOrganisationId as SheetLog } from 'types/getAllSheetLogsByOrganisationId'
import { FieldInfo, parseAsync } from 'json2csv'
import { orderBy } from 'lodash'
import { formatInTimeZone, format } from 'date-fns-tz'

type SheetLogMetaData = {
  patientName: string
  patientCode: string
  consultationId: string
  sheetName: string
}

const sheetLogContentCSVFieldMappings: FieldInfo<unknown>[] = [
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Time',
    value: 'time',
  },
  {
    label: 'Account',
    value: 'user_account',
  },
  {
    label: 'Veterinary Role',
    value: 'veterinary_role',
  },
  {
    label: 'Location',
    value: 'entity',
  },
  {
    label: 'Change Type',
    value: 'change_type',
  },
  {
    label: 'Sheet Group',
    value: 'sheet_group',
  },
  {
    label: 'Product',
    value: 'product_name',
  },
  {
    label: 'Schedule',
    value: 'schedule',
  },
  {
    label: 'Changed From',
    value: 'changed_from',
  },
  {
    label: 'Changed To',
    value: 'changed_to',
  },
  {
    label: 'Notes',
    value: 'notes',
  },
]

const buildSheetLogHeaderCSV = ({
  patientCode,
  patientName,
  consultationId,
  sheetName,
}: SheetLogMetaData): Promise<string> => {
  const sheetLogHeader = [
    ['Patient Name', patientName],
    ['Patient Code', patientCode],
    ['Consultation ID', consultationId],
    ['Sheet Name', sheetName],
  ]

  return parseAsync(sheetLogHeader, { header: false })
}

const buildSheetContentCSV = (sheetLogs: SheetLog[]): Promise<string> => {
  const sortedSheetLogs = orderBy(sheetLogs, 'created_at', 'desc')

  return parseAsync(sortedSheetLogs, {
    fields: sheetLogContentCSVFieldMappings,
    header: true,
  })
}

export const buildSheetLogCSV = async (
  sheetLogs: SheetLog[],
  { patientCode, patientName, consultationId, sheetName }: SheetLogMetaData,
): Promise<string> => {
  const sheetLogsHeaderCSV = await buildSheetLogHeaderCSV({
    patientCode,
    patientName,
    consultationId,
    sheetName,
  })

  const sheetLogContentCSV = await buildSheetContentCSV(sheetLogs)

  return `${sheetLogsHeaderCSV}\r\n\r\n${sheetLogContentCSV}`
}

export const buildSheetLogCSVFileName = (
  patientName: string,
  sheetName: string,
  timezone: string,
): string => {
  const currentDateTime = new Date()

  const dateString = timezone
    ? formatInTimeZone(currentDateTime, timezone, 'dd-MMM-yyyy')
    : format(currentDateTime, 'dd-MMM-yyyy')

  return `${patientName}-${sheetName}-${dateString}.csv`
}
