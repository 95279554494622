import {
  MutationUpdaterFn,
  MutationHookOptions,
  DocumentNode,
} from '@apollo/client'
import { toast } from 'components/common'
import { cloneDeep } from 'lodash'
import {
  copyTemplate as CopyTemplate,
  copyTemplateVariables as CopyTemplateVariables,
} from 'types/copyTemplate'
import {
  deleteTemplate as DeleteTemplate,
  deleteTemplateVariables as DeleteTemplateVariables,
} from 'types/deleteTemplate'

export const getDeleteTemplateOptions: () => MutationHookOptions<
  DeleteTemplate,
  DeleteTemplateVariables
> = () => ({
  fetchPolicy: 'no-cache',
  onCompleted: () => {
    toast.success('Deleted Template')
  },
  onError: err => {
    toast.error('Error Deleting Template')
    console.error(err) // eslint-disable-line no-console
  },
  optimisticResponse: {
    deleteTemplate: true,
  },
})

export const deleteTemplateUpdateHandler =
  (
    templateId: string,
    organisationId: string,
    query: DocumentNode,
  ): MutationUpdaterFn<any> =>
  (dataProxy, { data: updateData }) => {
    const updateDataKey = updateData ? Object.keys(updateData)[0] : null
    const deletedTemplateSuccess = updateDataKey
      ? updateData?.[updateDataKey]
      : false
    const variables = { organisation_id: organisationId }
    const getTemplatesData = cloneDeep(
      dataProxy.readQuery<any>({
        query,
        variables,
      }),
    )

    const getTemplatesDataKey = getTemplatesData
      ? Object.keys(getTemplatesData)[0]
      : null

    if (
      !deletedTemplateSuccess ||
      !getTemplatesDataKey ||
      !getTemplatesData?.[getTemplatesDataKey]?.items
    ) {
      return
    }

    getTemplatesData[getTemplatesDataKey].items = getTemplatesData[
      getTemplatesDataKey
    ].items.filter((e: any) => e.id !== templateId)
    dataProxy.writeQuery({ query, variables, data: getTemplatesData })
  }

export const getDuplicateTemplateOptions = (
  organisationId: string,
  query: DocumentNode,
): MutationHookOptions<CopyTemplate, CopyTemplateVariables> => ({
  fetchPolicy: 'no-cache',
  onCompleted: () => {
    toast.success('Duplicated Template')
  },
  onError: err => {
    toast.error('Error Duplicating Template')
    console.error(err) // eslint-disable-line no-console
  },
  update: duplicateTemplateUpdateHandler(organisationId, query),
})

export const duplicateTemplateUpdateHandler =
  (organisationId: string, query: DocumentNode): MutationUpdaterFn<any> =>
  (dataProxy, { data: updateData }) => {
    const updateDataKey = updateData ? Object.keys(updateData)[0] : null

    // new template copied from template with same name / contents
    const newTemplate = updateDataKey && updateData?.[updateDataKey]

    const variables = { organisation_id: organisationId }

    let getTemplatesData = dataProxy.readQuery<any>({
      query,
      variables,
    })
    getTemplatesData = cloneDeep(getTemplatesData)
    const getTemplatesDataKey = getTemplatesData
      ? Object.keys(getTemplatesData)[0]
      : null

    if (
      !newTemplate ||
      !getTemplatesDataKey ||
      !getTemplatesData ||
      !getTemplatesData?.[getTemplatesDataKey]?.items
    ) {
      return
    }

    const updatedTemplateList = [
      ...getTemplatesData[getTemplatesDataKey].items,
      newTemplate, // append to end, matches network mutation behavior
    ]
    getTemplatesData[getTemplatesDataKey].items = updatedTemplateList
    dataProxy.writeQuery({ query, variables, data: getTemplatesData })
  }
