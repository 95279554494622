export interface UserPreferences {
  [userId: string]: {
    [key: string]: any
  }
}

// Generic, so we can switch to a Backend Datastore if needed.
interface DataStore {
  getItem(key: string): any
  setItem(key: string, value: any): void
  removeItem(key: string): any
}

/**
 * @description
 * A locally persistent storage for user preferences, not for personal information or private information.
 */
export class PreferencesService {
  private preferences: UserPreferences = {}
  private dataStore: DataStore
  private userId: string

  constructor(dataStore: DataStore, userId: string) {
    this.dataStore = dataStore
    this.userId = userId
    this.preferences = this.parsePreferences() || {}
  }

  private parsePreferences(): UserPreferences {
    try {
      return JSON.parse(this.dataStore.getItem('preferences'))
    } catch (error) {
      return {}
    }
  }

  public getPreferences(): UserPreferences {
    return this.preferences[this.userId] || {}
  }

  public setPreference(key: string, value: any): void {
    if (!this.preferences[this.userId]) {
      this.preferences[this.userId] = {}
    }
    this.preferences[this.userId][key] = value
    this.dataStore.setItem('preferences', JSON.stringify(this.preferences))
  }

  public getPreference(key: string): any {
    return this.preferences[this.userId]?.[key]
  }

  public wipeAllDevicePreferences(): void {
    return this.dataStore.removeItem('preferences')
  }
}
