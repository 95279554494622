import { useEffect, useMemo, useState } from 'react'
import { GET_TIMEZONE_IN_MINUTES_FROM_UTC_TIME } from 'components/Sheet/graphql'
import { isNil } from 'lodash'
import { TimeSegmentMinutes } from 'src/hocs/types/time'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import {
  getTimeZoneInMinutesFromUTCTime,
  getTimeZoneInMinutesFromUTCTimeVariables,
} from 'src/types/getTimeZoneInMinutesFromUTCTime'
import { useClientQueryPromise } from 'src/utils/useClientQueryPromise'

import { getGridTimes, GetGridTimesParams } from './getGridTimes'
import {
  getStartEndTimeNumbersFromStaffHours,
  getStartNumberFromStartTime,
  getTimesFromStaffedHourNumbers,
} from './staffedHoursUtils'

export type TimesWithIsStaffedHour = {
  time: Date
  isStaffedHour: boolean
}[]

export const useGetStaffedHourTimes = ({
  segmentsInDay,
  timeStart,
  timeSegment,
}: GetGridTimesParams) => {
  // getGridTimes to get times
  const times = useMemo(
    () => getGridTimes({ segmentsInDay, timeStart, timeSegment }),
    [segmentsInDay, timeStart, timeSegment],
  )
  const [startTimeNumber, setStartTimeNumber] = useState<null | number>(null)
  const { staffed_hours, settingsMap, organisation } = useOrgSettings()

  const timePeriod = TimeSegmentMinutes[timeSegment]

  const timeZone = settingsMap?.TIMEZONE?.value ?? ''

  const isStaffedHoursEnabled =
    settingsMap?.ENABLE_OPERATING_HOURS?.value === 'true'

  // transfer staffed_hours to a list of numbers
  const staffedHourNumbers = useMemo(
    () => getStartEndTimeNumbersFromStaffHours(staffed_hours),
    [staffed_hours],
  )

  const getStartNumberFromStartTimeFromPlatform = useClientQueryPromise<
    getTimeZoneInMinutesFromUTCTime,
    getTimeZoneInMinutesFromUTCTimeVariables
  >({
    query: GET_TIMEZONE_IN_MINUTES_FROM_UTC_TIME,
  })

  useEffect(() => {
    async function getStartTime() {
      let startNumber = getStartNumberFromStartTime(times[0], timeZone)

      if (isNil(startNumber) && isStaffedHoursEnabled) {
        const { data } = await getStartNumberFromStartTimeFromPlatform({
          id: organisation?.id ?? '',
          date: times[0].toISOString(),
          time_zone: timeZone,
        })
        startNumber = data.getTimeZoneInMinutesFromUTCTime
      }

      setStartTimeNumber(startNumber)
    }
    getStartTime()
  }, [
    getStartNumberFromStartTimeFromPlatform,
    organisation?.id,
    timeZone,
    times,
    isStaffedHoursEnabled,
  ])

  // compare numbers to get each time is open or close
  return useMemo(
    () =>
      getTimesFromStaffedHourNumbers({
        times,
        timePeriod,
        isStaffedHoursEnabled,
        staffedHourNumbers,
        startTimeNumber,
      }),
    [
      staffedHourNumbers,
      timePeriod,
      times,
      isStaffedHoursEnabled,
      startTimeNumber,
    ],
  )
}
