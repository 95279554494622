import React, { useCallback } from 'react'
import { Colors } from 'constants/Colors'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, StyleSheet, View } from 'react-native'
import {
  SvgCheck,
  SvgClose,
  SvgReschedule,
  SvgSkipped,
} from 'src/components/Icons'

import { TabName } from './common'

export const tabIconMap = {
  [TabName.COMPLETE]: SvgCheck,
  [TabName.RESCHEDULE]: SvgReschedule,
  [TabName.DELETE]: SvgClose,
  [TabName.SKIP]: SvgSkipped,
}

export const tabs = [
  TabName.COMPLETE,
  TabName.RESCHEDULE,
  TabName.DELETE,
  TabName.SKIP,
]

type TabHeaderProps = {
  children?: React.ReactElement | null
  currentTab: TabName
  isSmall: boolean
  onPress: (tab: TabName) => void
}

const BulkTaskTab: React.FC<{
  currentTab: TabName
  isSmall: boolean
  onPress: (tab: TabName) => void
  tab: TabName
}> = ({ currentTab, isSmall, onPress, tab }) => {
  const { t } = useTranslation()
  const handlePress = useCallback(() => onPress(tab), [tab, onPress])

  const isActive = tab === currentTab
  const Icon = tabIconMap[tab]

  const selectedStateColor = isActive
    ? Colors.bulkTask.selectedHeader
    : Colors.bulkTask.unSelectedHeader

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={[styles.actionContainer, isSmall && styles.exSmallActionContainer]}
      key={tab}
    >
      <Icon color={selectedStateColor} length={25} />
      <Text
        style={[
          styles.actionButtonText,
          isSmall && styles.exSmallScreenFontSize,
          { color: selectedStateColor },
        ]}
      >
        {t(`general.${tab}`)}
      </Text>
    </TouchableOpacity>
  )
}

export const BulkTaskTabHeader: React.FC<TabHeaderProps> = ({
  children,
  currentTab,
  isSmall,
  onPress,
}) => {
  return (
    <View style={styles.tabHeader}>
      <View style={styles.actionsContainer}>
        {tabs.map(tab => (
          <BulkTaskTab
            currentTab={currentTab}
            key={tab}
            isSmall={isSmall}
            onPress={onPress}
            tab={tab}
          />
        ))}
      </View>
      {children}
      <View style={styles.separator} />
    </View>
  )
}

const styles = StyleSheet.create({
  actionButtonText: {
    paddingTop: 4,
    fontSize: 16,
  },
  actionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionContainer: {
    paddingHorizontal: 6,
    marginHorizontal: 5,
    alignItems: 'center',
    minWidth: 60,
  },
  exSmallActionContainer: {
    paddingHorizontal: 0,
    minWidth: 45,
  },
  exSmallScreenFontSize: {
    fontSize: 12,
  },
  separator: {
    zIndex: -1,
    height: 1,
    width: '100%',
    marginVertical: 16,
    backgroundColor: Colors.borderGrey,
  },
  tabHeader: {
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: 24,
    zIndex: 1,
  },
})
