import React, { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { Button, TextLink, toast } from 'src/components/common'
import { DraggableList } from 'components/common/DraggableList'
import { Input } from 'components/shared/Input'
import {
  PermissionCheckButton,
  PermissionTextLink,
} from 'components/common/Permission'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { SvgHamburger } from 'components/Icons'
import { Actions } from 'components/common/MultiActions'
import { Colors } from 'src/constants/Colors'
import { isOptimisticId } from 'src/utils/optimisticId'
import { CREATE_WORKFLOW, UPDATE_WORKFLOW } from 'components/Sheet/graphql'
import { useOrganisation } from 'src/context/organisation'
import { createWorkflow, createWorkflowVariables } from 'types/createWorkflow'
import { updateWorkflow, updateWorkflowVariables } from 'types/updateWorkflow'
import { getConsultation_getConsultation_workflow as Workflow } from 'types/getConsultation'
import { orderBy, sortBy } from 'lodash'
import {
  GET_WORKFLOW_TEMPLATES,
  GET_WORKFLOW_TEMPLATE,
} from 'components/WorkflowTemplate/graphql'
import {
  getTemplateWorkflow,
  getTemplateWorkflowVariables,
} from 'src/types/getTemplateWorkflow'
import {
  getTemplateWorkflows,
  getTemplateWorkflowsVariables,
} from 'src/types/getTemplateWorkflows'
import { SearchInput } from 'components/common/SearchInput'
import { searchInList } from 'src/utils/searchInList'
import { WorkflowTemplateList } from './WorkflowTemplateList'
import {
  useWorkflowItemForm,
  FormValues,
  WorkflowItem,
} from './useWorkflowItemForm'

import { useTranslation } from 'react-i18next'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { PermissionAction } from 'types/globalTypes'

const SELECTED_SHOW_MORE_LIMIT = 5
const DELETE_ALL_BTN_HEIGHT = 30
const WORKFLOW_ITEM_HEIGHT = 45
const WORKFLOW_ITEMS_MAX_HEIGHT =
  WORKFLOW_ITEM_HEIGHT * SELECTED_SHOW_MORE_LIMIT + DELETE_ALL_BTN_HEIGHT

type Props = {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  workflow: Workflow | null
  patientId: string
  consultationId: string
  onCreate: () => void
}
export const WorkflowItemsDrawer: React.FC<Props> = React.memo(
  ({
    isVisible,
    setIsVisible,
    workflow,
    patientId,
    consultationId,
    onCreate,
  }) => {
    const setInvisible = useCallback(() => setIsVisible(false), [setIsVisible])

    return (
      <SheetAwareSideDrawer
        visible={isVisible}
        onClose={setInvisible}
        title={'Workflow management'}
      >
        <WorkflowItemsDrawerContent
          onDone={setInvisible}
          onCreate={onCreate}
          workflow={workflow}
          patientId={patientId}
          consultationId={consultationId}
        />
      </SheetAwareSideDrawer>
    )
  },
)

WorkflowItemsDrawer.displayName = 'WorkflowItemsDrawer'

type WorkflowItemsDrawerContentProps = {
  workflow: Workflow | null
  patientId: string
  consultationId: string
  onDone: () => void
  onCreate: () => void
}

export const WorkflowItemsDrawerContent: React.FC<
  WorkflowItemsDrawerContentProps
> = ({ workflow, patientId, consultationId, onDone, onCreate }) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()

  const [isShowMore, showMore] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [currentLoadingTemplateId, setCurrentLoadingTemplateId] =
    useState<string>()

  const { loading: isLoadingTemplates, data: templatesData } = useQuery<
    getTemplateWorkflows,
    getTemplateWorkflowsVariables
  >(GET_WORKFLOW_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisation_id: organisationId,
    },
    onError: err => {
      toast.error(err.message)
    },
  })

  // TODO: is this code here necessary or should be refactored?
  const [createWorkFlow, { loading: isCreating }] = useMutation<
    createWorkflow,
    createWorkflowVariables
  >(CREATE_WORKFLOW, {
    onError: err => {
      toast.error(err.message)
    },
    onCompleted: () => {
      onDone()
      onCreate()
      toast.success(t('sheet:workflow:created'))
    },
  })

  const [updateWorkFlow, { loading: isUpdating }] = useMutation<
    updateWorkflow,
    updateWorkflowVariables
  >(UPDATE_WORKFLOW, {
    onError: err => {
      toast.error(err.message)
    },
    onCompleted: () => {
      onDone()
      toast.success(t('sheet:workflow:changed'))
    },
  })

  const [getWorkflowTemplate] = useLazyQuery<
    getTemplateWorkflow,
    getTemplateWorkflowVariables
  >(GET_WORKFLOW_TEMPLATE, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const items = data?.getTemplateWorkflow?.workflow_items?.items ?? []
      addItems(items)
      setCurrentLoadingTemplateId(undefined)
    },
  })

  const templates = useMemo(() => {
    const templateItems = (
      templatesData?.getTemplateWorkflows?.items ?? []
    ).filter(({ disabled }) => !disabled)
    return searchInList(sortBy(templateItems, 'name'), 'name', searchText)
  }, [templatesData, searchText])

  const onSubmit = (submitValues: FormValues) => {
    const items = submitValues.items
      .filter(item => !!item.name)
      .map((item, index) => {
        return {
          ...(!isOptimisticId(item.id) && { id: item.id }),
          organisation_id: organisationId,
          patient_id: patientId,
          consultation_id: consultationId,
          name: item.name,
          completed_at: item.completedAt,
          order: index,
          ...(item.deletedAt && { deleted_at: new Date().toISOString() }),
        }
      })

    if (workflow) {
      updateWorkFlow({
        variables: {
          input: {
            id: workflow.id,
            organisation_id: organisationId,
            patient_id: patientId,
            consultation_id: consultationId,
            workflow_items: items,
          },
        },
      })
      return
    }
    createWorkFlow({
      variables: {
        input: {
          organisation_id: organisationId,
          patient_id: patientId,
          consultation_id: consultationId,
          workflow_items: items,
        },
      },
    })
  }

  const {
    finishedItems,
    unfinishedItems,
    itemsCount,
    addEmptyItem,
    addItems,
    deleteItem,
    deleteAllItems,
    toggleFinishItem,
    submitForm,
    setUnfinishedItems,
    setItemName,
  } = useWorkflowItemForm({
    workflow,
    onSubmit,
  })

  const addTemplate = (id: string) => {
    getWorkflowTemplate({
      variables: {
        organisationId,
        id,
      },
    })
    setCurrentLoadingTemplateId(id)
  }

  const renderItem = ({ item, drag }: { item: WorkflowItem; drag: any }) => {
    if (item.deletedAt) {
      return null
    }
    const menuItems = [
      {
        name: Actions.DELETE,
        onClick: () => deleteItem(item.id),
        permissionAction: PermissionAction.WORKFLOW_TASK__DELETE,
      },
    ]
    return (
      <View style={styles.itemContainer} key={item.id}>
        {/* Don't allow reordering in search mode */}
        {isShowMore ? (
          <>
            {item.completedAt ? (
              <View style={styles.dragBtn} />
            ) : (
              <TouchableOpacity style={styles.dragBtn} onPressIn={drag}>
                <SvgHamburger />
              </TouchableOpacity>
            )}
          </>
        ) : null}

        <Input
          prefix={
            <PermissionCheckButton
              style={styles.completeBtn}
              checked={!!item.completedAt}
              onChange={() => toggleFinishItem(item)}
              permissionAction={
                item.completedAt ? PermissionAction.WORKFLOW_TASK__UNTICK : null
              }
            />
          }
          value={item.name}
          onChange={value => setItemName(item, value)}
          menuItems={menuItems}
          autoFocus={isShowMore && isOptimisticId(item.id)}
          textInputDisabled={!!item.completedAt}
        />
      </View>
    )
  }

  const renderListHeader = () => (
    <>
      {!!itemsCount && (
        <View style={styles.deleteBtnContainer}>
          <PermissionTextLink
            permissionAction={PermissionAction.WORKFLOW_TASK__DELETE}
            text={`Delete all tasks`}
            containerStyles={styles.textLink}
            onPress={() => {
              deleteAllItems()
              showMore(false)
            }}
          />
        </View>
      )}

      {orderBy(finishedItems, ({ completedAt }) => new Date(completedAt!)).map(
        item => renderItem({ item, drag: undefined }),
      )}
    </>
  )

  const addEmptyItemAndShowMore = () => {
    addEmptyItem()
    showMore(true)
  }

  let hiddenItemsCount = 0
  if (itemsCount > SELECTED_SHOW_MORE_LIMIT) {
    hiddenItemsCount = itemsCount - SELECTED_SHOW_MORE_LIMIT
  }

  const renderListFooter = () => {
    return (
      <>
        <View style={[styles.addBtnContainer, styles.marginForMediumScreen]}>
          <TextLink
            text={`+ Task`}
            containerStyles={styles.textLink}
            onPress={addEmptyItemAndShowMore}
          />
          <TextLink
            text={
              isShowMore
                ? `${t('general.showLess')} / Add templates`
                : `${t('general.showMore')} (${hiddenItemsCount}) / Reorder`
            }
            containerStyles={styles.textLink}
            onPress={() => showMore(!isShowMore)}
          />
        </View>
        {!isShowMore && renderTemplates()}
      </>
    )
  }

  const renderTemplates = () => {
    return (
      <>
        <View style={styles.separator} />
        <SearchInput
          style={styles.searchInput}
          onChangeText={setSearchText}
          placeholder={t('sheet:workflow:searchTemplate')}
        />
        <WorkflowTemplateList
          templates={templates}
          searchText={searchText}
          onAddClick={addTemplate}
          isListLoading={isLoadingTemplates}
          currentAddingTemplateId={currentLoadingTemplateId}
        />
      </>
    )
  }

  const renderShowMoreView = () => {
    return (
      <View style={styles.dragList}>
        <DraggableList
          ListHeaderComponent={renderListHeader()}
          ListFooterComponent={renderListFooter()}
          items={unfinishedItems}
          renderItem={renderItem}
          onMoveEnd={setUnfinishedItems}
        />
      </View>
    )
  }

  const renderShowLessView = () => {
    return (
      <>
        <View style={styles.showLessViewItemsContainer}>
          {renderListHeader()}
          {unfinishedItems
            .filter(item => !item.deletedAt)
            .map(item => renderItem({ item, drag: undefined }))}
        </View>
        {renderListFooter()}
      </>
    )
  }
  return (
    <>
      {isShowMore ? renderShowMoreView() : renderShowLessView()}
      <Button
        testID="SaveButton"
        onPress={submitForm}
        title={t('general.saveChanges')}
        color={Colors.green}
        style={styles.button}
        loading={isUpdating || isCreating}
      />
    </>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingBottom: 5,
  },
  deleteBtnContainer: {
    flexDirection: 'row-reverse',
    paddingHorizontal: 12,
  },
  addBtnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
  },
  dragBtn: {
    marginRight: 12,
    width: 18,
  },
  completeBtn: {
    marginHorizontal: 6,
  },
  textLink: {
    paddingBottom: 8,
  },
  button: {
    marginVertical: 15,
  },
  searchInput: {
    marginBottom: 16,
    paddingHorizontal: 16,
  },
  separator: {
    backgroundColor: Colors.shared.borderGrey,
    height: 1,
    marginTop: 8,
    marginBottom: 20,
  },
  showLessViewItemsContainer: {
    maxHeight: WORKFLOW_ITEMS_MAX_HEIGHT,
    overflow: 'hidden',
  },
  marginForMediumScreen: {
    marginTop: 12,
  },
  dragList: {
    flex: 1,
  },
})
