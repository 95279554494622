import { environment } from 'src/config'

export enum ContentType {
  CSV = 'text/csv;charset=utf-8;',
}

/**
 * The method is only used for web not ios
 */
export const download = (
  content: string,
  filename: string,
  contentType: ContentType,
): void => {
  if (environment.isWeb) {
    const blob = new Blob([content], { type: contentType })
    const url = URL.createObjectURL(blob)

    const pom = document.createElement('a')
    pom.href = url
    pom.setAttribute('download', filename)
    pom.click()

    // clean up
    URL.revokeObjectURL(url)
  }
}
