import { Portal } from '@gorhom/portal'
import React, { useMemo } from 'react'
import { Animated, SafeAreaView, StyleSheet, Text, View } from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { TouchableArrow } from 'components/common/TouchableArrow'
import { usePatientPanelScroll } from 'src/hooks/usePatientPanelScroll'
import { IconButton } from 'components/IconButton'
import { SvgCollapseArrows } from 'components/Icons/CollapseArrows'
import { Shadows } from 'constants/Shadows'
import { useTranslation } from 'react-i18next'
import { PATIENT_INFO_PORTAL_NAME } from 'components/PatientPanel/DrawerPortal'
import { SvgCross } from 'components/Icons/Cross'
import { environment } from 'src/config'

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Colors, Typography } from 'src/design-system/theme'
import { Primitive } from 'src/design-system/theme/colors/base'

const AnimatedKeyboardAwareScrollView = Animated.createAnimatedComponent(
  KeyboardAwareScrollView,
)

export const PATIENT_INFO_WIDTH_WEB = 227

type Props = {
  isExpanded?: boolean
  children: React.ReactNode
  handleCollapse: () => void
  patientName: string | null
}

export const Drawer = ({
  isExpanded = false,
  children,
  handleCollapse,
  patientName,
}: Props) => {
  const { t } = useTranslation()
  const { isSmallishScreen } = useBreakpoint()
  const {
    scrollRef,
    handleScrollContentHeight,
    handleContainerHeight,
    handleScrollToTop,
    handleScrollToEnd,
    showScrollButton,
    shouldScrollToTop,
    handleScroll,
  } = usePatientPanelScroll()
  const isWeb = environment.isWeb
  const { isLargeScreen } = useBreakpoint()

  const drawerStyle = useMemo(() => {
    return isSmallishScreen ? styles.drawerMobile : styles.drawerWeb
  }, [isSmallishScreen])

  return (
    <Portal hostName={PATIENT_INFO_PORTAL_NAME}>
      {isExpanded ? (
        <View style={[styles.drawerContainer, drawerStyle]}>
          <View style={styles.drawerHeader}>
            <View
              style={[
                styles.drawerTitle,
                isSmallishScreen && styles.mobileTitle,
              ]}
            >
              <Text
                style={styles.headerText}
                ellipsizeMode={'tail'}
                numberOfLines={isLargeScreen ? 2 : 1}
              >
                {patientName ?? ''}
              </Text>
            </View>
            <IconButton
              action={handleCollapse}
              label={t('patientPanel:panel.collapse.label')}
              iconColor={Colors.Contents.onColor}
              mobileHeaderToggle={isSmallishScreen}
              icon={
                isSmallishScreen
                  ? () => SvgCross({ color: Colors.Contents.onColor })
                  : SvgCollapseArrows
              }
            />
          </View>
          <View style={styles.scrollContainer} onLayout={handleContainerHeight}>
            <AnimatedKeyboardAwareScrollView
              extraScrollHeight={67}
              bounces
              onContentSizeChange={handleScrollContentHeight}
              onScroll={e => handleScroll(e.nativeEvent)}
              ref={scrollRef}
              style={styles.drawerContent}
              scrollEventThrottle={100}
              showsHorizontalScrollIndicator={false}
            >
              <SafeAreaView>{children}</SafeAreaView>
              {showScrollButton ? (
                <View
                  style={
                    isWeb ? styles.scrollViewSpacer : styles.scrollViewSpacerIos
                  }
                />
              ) : null}
            </AnimatedKeyboardAwareScrollView>
            {showScrollButton ? (
              <SafeAreaView
                style={styles.scrollButtonContainer}
                accessibilityLabel={t(
                  'patientPanel:scrollToPosition.a11yLabel',
                )}
              >
                <TouchableArrow
                  onPress={
                    shouldScrollToTop ? handleScrollToTop : handleScrollToEnd
                  }
                  orient={shouldScrollToTop ? 'up' : 'down'}
                  height={16}
                  width={16}
                  color={Primitive.blue600}
                  style={styles.scrollButton}
                />
              </SafeAreaView>
            ) : null}
          </View>
        </View>
      ) : null}
    </Portal>
  )
}

const styles = StyleSheet.create({
  scrollContainer: {
    position: 'relative',
    flex: 1,
    flexDirection: 'column',
  },
  drawerWeb: {
    width: PATIENT_INFO_WIDTH_WEB,
    maxHeight: '100%',
    height: '100%',
    paddingHorizontal: 8,
    paddingTop: 14,
    paddingBottom: 8,
  },
  drawerMobile: {
    height: '100%',
    width: '100%',
  },
  drawerContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: Colors.Backgrounds.grey,
    paddingHorizontal: 8,
    paddingTop: 8,
    zIndex: 2,
  },
  drawerContent: {
    position: 'relative',
  },
  scrollViewSpacer: {
    backgroundColor: Colors.Backgrounds.grey,
    height: 60,
  },
  scrollViewSpacerIos: {
    backgroundColor: Colors.Backgrounds.grey,
    height: 80,
  },
  drawerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  drawerTitle: {
    height: 40,
    borderRadius: 4,
    backgroundColor: Colors.Contents.onColor,
    paddingVertical: 0,
    paddingHorizontal: 8,
    flexGrow: 0,
    flexShrink: 1,
    marginRight: 8,
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    ...Shadows.cardShadow,
  },
  headerText: {
    fontWeight: '700',
    fontFamily: Typography.FontFamilies.bold,
    color: Colors.Contents.primary,
    fontSize: 14,
  },
  scrollButtonContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  scrollButton: {
    flexGrow: 1,
    paddingVertical: 18,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: Colors.Backgrounds.transparentWhite,
  },
  mobileTitle: {
    height: 30,
  },
})
