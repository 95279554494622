import { FromToQueryDate } from 'src/hocs/timeContext'
import { TREATMENT_BASE_WITH_TASKS } from 'components/AddTreatment/graphql'

export const buildTreatmentWithTasksFragmentVariable = (
  treatmentId: string,
  fromToQueryDate: FromToQueryDate,
) => ({
  fragment: TREATMENT_BASE_WITH_TASKS,
  fragmentName: 'TreatmentBaseWithTasks',
  id: `Treatment:${treatmentId}`,
  variables: fromToQueryDate,
})
