import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  size?: number
  color?: string
}

export const ValidPasswordSvg = ({ size = 16, color = '#43A047' }: Props) => {
  return (
    <Svg width={size} height={(size * 16) / 16} viewBox="0 0 16 16" fill="none">
      <Path
        d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM11.0898 6.71484L7.58984 10.2148C7.33281 10.4719 6.91719 10.4719 6.66289 10.2148L4.91289 8.46484C4.65586 8.20781 4.65586 7.79219 4.91289 7.53789C5.16992 7.28359 5.58555 7.28086 5.83984 7.53789L7.125 8.82305L10.1602 5.78516C10.4172 5.52812 10.8328 5.52812 11.0871 5.78516C11.3414 6.04219 11.3441 6.45781 11.0871 6.71211L11.0898 6.71484Z"
        fill={color}
      />
    </Svg>
  )
}
