import React, { useCallback, useMemo } from 'react'
import { OnPressTask } from 'components/Grid/GridFlatList'
import { ScaleTimeFn } from 'components/Grid/GridTimeLine/GridTimeline'
import { FluidTask } from 'components/Task/FluidTask'
import { getIsTaskDoneOrProgress } from 'components/Task/utils/taskTransitionUtils'
import { Colors } from 'constants/Colors'
import { addDays, addHours, addMinutes, parseISO } from 'date-fns'
import { noop } from 'lodash'
import {
  GestureResponderEvent,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { DEFAULT_DAYS_RENDERED } from 'src/hocs/types/time'
import { useRound } from 'src/hooks/useRound'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as TaskItem } from 'src/types/getSheet'
import { Status } from 'src/types/globalTypes'

import { treatmentHeightLarge } from '../common'
import { TreatmentSheet } from './common/types'
import {
  convertClickPositionToTime,
  findWorkingFluidTask,
} from './utils/fluidLeafFns'
import { formatFluidValueLabel } from './utils/formatFluidValueLabel'
import { getFluidTaskUnitTransferred } from './utils/getFluidTaskUnitTransferred'

const CIRCLE_PIXEL_BUFFER = 10 // shift to get 'center' of 20px circle in position
const MIN_FLUID_TASK_WIDTH = 40 // so that 2*20px circles fit at small fluid sizes
const TASK_WIDTH = 20
const TASK_LABEL_WIDTH = 90

type Props = {
  sortedTasks: TaskItem[]
  treatment: TreatmentSheet
  positionInGridFn: ScaleTimeFn
  onPressTask?: OnPressTask
}
export const FluidTaskGroup: React.FC<Props> = React.memo(
  ({ sortedTasks, treatment, positionInGridFn, onPressTask }) => {
    return (
      <>
        {sortedTasks.map((currentTask, index) => (
          <SingleFluidTask
            key={currentTask.id}
            task={currentTask}
            index={index}
            sortedTasks={sortedTasks}
            treatment={treatment}
            positionInGridFn={positionInGridFn}
            onPressTask={onPressTask}
          />
        ))}
      </>
    )
  },
)

FluidTaskGroup.displayName = 'FluidTaskGroup'

type SingleFluidTaskProps = {
  task: TaskItem
  index: number
  sortedTasks: TaskItem[]
  treatment: TreatmentSheet
  positionInGridFn: ScaleTimeFn
  onPressTask?: OnPressTask
}

const SingleFluidTask: React.FC<SingleFluidTaskProps> = ({
  task,
  index,
  sortedTasks,
  treatment,
  positionInGridFn,
  onPressTask = noop,
}) => {
  const round = useRound()
  // start_at = planned start at, given_start_at = real start after fluid commences
  const { start_at: startAt, given_start_at: givenStartAt, value } = task

  const { minutes, hours, days } =
    treatment.fluid_dosage_info ?? treatment.medicine_dosage_info ?? {}

  const isFirstTask = index === 0
  const isContinuous = !!treatment.is_continuous

  const firstStartAt = sortedTasks[0].given_start_at ?? sortedTasks[0].start_at

  const isLastTask = index === sortedTasks.length - 1
  const isOnlyTask = isFirstTask && isLastTask

  // fall back to start at if fluid task hasn't commenced
  const startAtDate = useMemo(
    () => parseISO(givenStartAt ?? startAt ?? ''),
    [givenStartAt, startAt],
  )

  const left = (positionInGridFn(startAtDate) ?? 0) - CIRCLE_PIXEL_BUFFER

  const endOfTaskDate = useMemo(() => {
    // fluid is stopped
    if (task.given_stop_at) {
      return parseISO(task.given_stop_at)
    }
    // reach the next task
    const nextTask = isLastTask ? null : sortedTasks[index + 1]
    if (nextTask && nextTask.given_start_at) {
      return parseISO(nextTask.given_start_at ?? nextTask.start_at)
    }
    // if isContinuous, endOfTaskDate will be extended to DEFAULT_DAYS_RENDERED days
    if (isContinuous) {
      return addDays(new Date(), DEFAULT_DAYS_RENDERED)
    }
    const startOfTaskDate = firstStartAt ? parseISO(firstStartAt) : startAtDate
    // by treatment day/hour/minute
    return addDays(
      addHours(addMinutes(startOfTaskDate, minutes ?? 0), hours ?? 0),
      days ?? 0,
    )
  }, [
    days,
    isContinuous,
    firstStartAt,
    hours,
    index,
    isLastTask,
    minutes,
    sortedTasks,
    startAtDate,
    task.given_stop_at,
  ])

  const width = (positionInGridFn(endOfTaskDate) ?? 0) - left

  const givenWidth =
    width < MIN_FLUID_TASK_WIDTH && isOnlyTask ? MIN_FLUID_TASK_WIDTH : width

  const showHideLabel = !isLastTask && givenWidth < TASK_LABEL_WIDTH

  const handlePressTask = useCallback(
    (event: any) => {
      let locationX: number
      if (Platform.OS === 'web') {
        locationX = (event.nativeEvent as MouseEvent).offsetX
      } else {
        locationX = (event as GestureResponderEvent).nativeEvent.locationX
      }

      const workingFluidTask = findWorkingFluidTask(sortedTasks, task)

      if (locationX) {
        const timeOfPress = convertClickPositionToTime(
          locationX,
          givenWidth,
          startAtDate,
          endOfTaskDate,
        )

        onPressTask(treatment, workingFluidTask, timeOfPress)
        return
      }

      onPressTask(treatment, workingFluidTask)
    },
    [
      endOfTaskDate,
      givenWidth,
      onPressTask,
      startAtDate,
      treatment,
      sortedTasks,
      task,
    ],
  )

  const zIndexLayer = sortedTasks.length + index

  return (
    <View
      key={task.id}
      style={[
        styles.taskItem,
        {
          left,
          width: givenWidth,
          zIndex: zIndexLayer,
          overflow: 'visible',
        },
      ]}
    >
      <FluidTask
        isFluidStarted={getIsTaskDoneOrProgress(task.status)}
        isFluidEnded={task.status === Status.DONE}
        isFirstTask={isFirstTask}
        isLastTask={isLastTask}
        onPress={handlePressTask}
        overlapTask={showHideLabel}
      />
      {!showHideLabel && (
        <View style={styles.taskLabel}>
          <Text
            style={isFirstTask ? styles.firstFluidText : styles.fluidText}
            numberOfLines={1}
          >
            {formatFluidValueLabel(getFluidTaskUnitTransferred(value), round)}
          </Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  firstFluidText: {
    textAlign: 'center',
    fontSize: 12,
    color: Colors.contentPrimary,
  },
  fluidText: {
    textAlign: 'center',
    fontSize: 12,
    color: Colors.darkGrey,
  },

  taskItem: {
    height: treatmentHeightLarge,
    overflow: 'hidden',
    position: 'absolute',
  },
  taskLabel: {
    position: 'absolute',
    marginLeft: (TASK_WIDTH - TASK_LABEL_WIDTH) / 2,
    bottom: 2,
    width: TASK_LABEL_WIDTH,
  },
})
