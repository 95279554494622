import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Fonts } from 'src/constants/Fonts'
import { useTranslation } from 'react-i18next'
import { Colors } from 'constants/Colors'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'

type Props = {
  treatmentName: string
  rescheduleToTime?: string | null
  time?: string | null
}

export const ChoiceRow: React.FC<Props> = ({
  treatmentName,
  time = '',
  rescheduleToTime,
}) => {
  const { t } = useTranslation()
  return (
    <View
      style={[styles.container, !!rescheduleToTime && styles.rescheduleHeight]}
    >
      <View style={styles.subContainer}>
        <Text numberOfLines={1} style={styles.name}>
          {treatmentName}
        </Text>
        <Text numberOfLines={1} style={styles.time}>
          {time}
        </Text>
      </View>
      {!!rescheduleToTime && (
        <>
          <View style={styles.separator} />
          <View style={styles.rescheduleContainer}>
            <TextWithTooltip
              numberOfLines={1}
              style={styles.reschedule}
              title={t('task:taskTransition:form:rescheduleTo')}
            >
              {t('task:taskTransition:form:rescheduleTo')}:
            </TextWithTooltip>
            <TextWithTooltip
              numberOfLines={1}
              style={styles.time}
              title={rescheduleToTime}
            >
              {rescheduleToTime}
            </TextWithTooltip>
          </View>
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.shared.borderGrey,
    height: 38,
    width: '100%',
    borderRadius: 4,
    paddingHorizontal: 9,
    backgroundColor: Colors.white,
  },
  subContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: 38,
    alignItems: 'center',
  },
  rescheduleContainer: {
    flexDirection: 'row',
    height: 35,
    alignItems: 'center',
  },
  name: {
    flexGrow: 1,
    flexShrink: 1,
    fontFamily: Fonts.semibold,
    fontSize: 14,
    color: Colors.contentSecondary,
  },
  reschedule: {
    fontSize: 12,
    color: Colors.patient.infoText,
    marginRight: 8,
  },
  rescheduleHeight: {
    height: 74,
  },
  time: {
    fontFamily: Fonts.regular,
    fontSize: 12,
    color: Colors.contentSecondary,
  },
  separator: {
    backgroundColor: Colors.shared.borderGrey,
    height: 1,
  },
})
