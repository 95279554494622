import React, { useCallback, useMemo } from 'react'
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { Direction } from 'src/constants/Orientation'
import { SvgArrow, orientToDegree } from 'components/Icons'
import { useBreakpoint } from 'src/hocs/breakpoint'

export enum RangeDirection {
  Previous = -1,
  Current = 0,
  Next = 1,
}

const getSvgArrowIcon = (
  orient: keyof typeof orientToDegree,
  color: string,
) => <SvgArrow orient={orient} color={color} width={13} height={13} />

const HIT_SLOP = { top: 5, bottom: 5, left: 15, right: 15 }
const ARROW_COLOR = Colors.sheet.title.blue

const Headline: React.FC<{ headline: string }> = ({ headline }) => {
  const { isExSmallScreen } = useBreakpoint()

  const styles = useMemo(() => {
    if (isExSmallScreen) return stylesExtraSmall
    return stylesDefault
  }, [isExSmallScreen])

  //  Intentionally empty space fallback, keeps position
  return (
    <Text
      testID="HeaderHeadline"
      style={[styles.headline]}
      numberOfLines={1}
      ellipsizeMode={'middle'}
    >
      {headline}
    </Text>
  )
}

export type SheetTitle = {
  headline?: string | null
  headlineOnPress?: () => any
  isFinalized?: boolean
  navigateSheets?: (direction: RangeDirection) => any
  hasOneSheet?: boolean
  shouldShowDrawer?: boolean
}

export const SheetTitle: React.FC<SheetTitle> = React.memo(
  ({ headline, headlineOnPress, isFinalized, navigateSheets, hasOneSheet }) => {
    const { isExSmallScreen } = useBreakpoint()

    const styles = useMemo(() => {
      if (isExSmallScreen) return stylesExtraSmall
      return stylesDefault
    }, [isExSmallScreen])

    const shouldDisableNav = isFinalized || hasOneSheet

    const onPressPrevious = useCallback(() => {
      if (navigateSheets) navigateSheets(RangeDirection.Previous)
    }, [navigateSheets])

    const onPressNext = useCallback(() => {
      if (navigateSheets) navigateSheets(RangeDirection.Next)
    }, [navigateSheets])

    if (!headline) return <View style={styles.headlineWrapper} />

    if (!headlineOnPress || !navigateSheets)
      return (
        <View style={styles.headlineWrapper}>
          <Headline headline={headline} />
        </View>
      )

    return (
      <View style={styles.headlineWrapper}>
        <TouchableOpacity
          onPress={onPressPrevious}
          disabled={shouldDisableNav}
          style={[styles.button, shouldDisableNav && styles.disabledText]}
          hitSlop={HIT_SLOP}
        >
          {getSvgArrowIcon(Direction.LEFT, ARROW_COLOR)}
        </TouchableOpacity>

        <TouchableOpacity style={styles.button} onPress={headlineOnPress}>
          <Headline headline={headline} />
        </TouchableOpacity>

        <TouchableOpacity
          onPress={onPressNext}
          disabled={shouldDisableNav}
          style={[styles.button, shouldDisableNav && styles.disabledText]}
          hitSlop={HIT_SLOP}
        >
          {getSvgArrowIcon(Direction.RIGHT, ARROW_COLOR)}
        </TouchableOpacity>
      </View>
    )
  },
)

SheetTitle.displayName = 'SheetTitle'

/* eslint-disable react-native/no-unused-styles */
export const stylesDefault = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  disabledText: {
    opacity: 0.5,
  },
  headlineWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: 40,
    flexShrink: 1,
    gap: 4,
  },
  headline: {
    fontFamily: Fonts.bold,
    fontSize: 16,
    marginHorizontal: 4,
    color: Colors.sheet.title.blue,
  },
})

export const stylesExtraSmall = StyleSheet.create({
  ...stylesDefault,
  headline: {
    ...stylesDefault.headline,
    fontFamily: Fonts.semibold,
    marginHorizontal: 0,
  },
  headlineWrapper: {
    ...stylesDefault.headlineWrapper,
    paddingVertical: 0,
  },
})
