import { Storage } from '../utils/Storage'

const IDLE_TIMER_STORAGE = 'idle_timer'
const IDLE_TIMER_IDLE_AT = 'idle_at'

export class IdleTimerService {
  private storage: Storage

  constructor() {
    this.storage = new Storage(IDLE_TIMER_STORAGE)
  }

  public setIdleAt(idleAt: string) {
    this.storage.setItem(IDLE_TIMER_IDLE_AT, idleAt)
  }

  public unsetIdleAt() {
    this.storage.removeItem(IDLE_TIMER_IDLE_AT)
  }

  public getIdleAt() {
    return this.storage.getItem(IDLE_TIMER_IDLE_AT)
  }
}

export const idleTimerService = new IdleTimerService()
