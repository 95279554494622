import { gql } from '@apollo/client'

export const GET_ORGANISATION = gql`
  query getOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      is_training
      training_organisation_id
      integrations {
        items {
          id
          name
          type
          adaptor {
            instance_url
            api_url
          }
        }
      }
      name
    }
  }
`
