import React from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { Tag } from 'src/components/Tag/Tag'
import { Routes } from 'src/constants/Routes'

import { useOrganisation } from 'src/context/organisation'
import {
  updateTemplateTreatment as updateTemplateTreatmentT,
  updateTemplateTreatmentVariables,
} from 'src/types/updateTemplateTreatment'

import { SubHeader } from '../SubHeader/SubHeader'
import { UPDATE_TEMPLATE, UPDATE_TEMPLATE_TREATMENT } from './graphql'
import { Inputs, TemplateForm } from './TemplateForm'
import { TreatmentTemplate } from './types'
import { useTemplate } from './useTemplate'
import { SettingsStackParamList } from 'components/Settings/screens'
import { updateTemplate } from 'types/updateTemplate'

type Props = StackScreenProps<SettingsStackParamList, Routes.EditTemplate>

export type UpdateTemplateTreatmentProps = Pick<
  updateTemplateTreatmentVariables['input'],
  'id' | 'product_id' | 'name' | 'order'
>

export const EditTemplateScreen: React.FC<Props> = ({ navigation, route }) => {
  const { navigate } = navigation
  const templateId = route.params.templateId
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { loading, template } = useTemplate({ templateId })

  const [updateTemplateTreatment] = useMutation<
    updateTemplateTreatmentT,
    updateTemplateTreatmentVariables
  >(UPDATE_TEMPLATE_TREATMENT, {
    onError: err => {
      toast.error(err.message)
    },
  })

  const handleBack = () => {
    navigate(Routes.TreatmentTemplateList)
  }

  const onComplete = (formData: updateTemplate): void => {
    toast.success(
      t('template:update.success', {
        name: formData.updateTemplate.name,
      }),
    )
    handleBack()
  }

  const [updateTemplate, { loading: submitting }] = useMutation(
    UPDATE_TEMPLATE,
    {
      onError: () => toast.error(t('template:update.err')),
      onCompleted: onComplete,
    },
  )

  const backButton = {
    action: handleBack,
    label: 'templates.returnHereLabel',
    title: 'templates.treatmentTitle',
  }

  const handleSubmit = async (
    data: Inputs,
    treatmentList?: TreatmentTemplate[],
  ) => {
    // only update treatments order if there is a change
    if (treatmentList) {
      try {
        await Promise.all(
          treatmentList.map((newTreatment, idx) => {
            if (newTreatment.order !== idx) {
              const newerNewTreatment = {
                id: newTreatment.id,
                organisation_id: organisationId,
                template_id: templateId,
                name: newTreatment.name,
                order: idx,
              }
              return updateTemplateTreatment({
                variables: {
                  input: newerNewTreatment,
                },
              })
            }
            return
          }),
        )
      } catch (err) {
        if (err instanceof Error) toast.error(err.message, null, err)
      }
    }
    // update template after updating treatments order, wait for cache update
    await updateTemplate({
      variables: {
        input: {
          id: template?.id,
          organisation_id: organisationId,
          ...data,
        },
      },
    })
  }

  return (
    <>
      <SubHeader
        headline={template?.name}
        subHeadline={
          <View style={styles.tagContainer}>
            <Tag title={t('template:title')} />
            {!!template?.disabled && <Tag title={t('template:disabled')} />}
          </View>
        }
        backButton={backButton}
      />

      {loading ? (
        <ActivityIndicator size="large" style={styles.marginTop} />
      ) : (
        <TemplateForm
          template={template}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  tagContainer: {
    flexDirection: 'row',
  },
  marginTop: {
    marginTop: 25,
  },
})
