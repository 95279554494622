import React, { useCallback, useState } from 'react'
import { Product } from 'components/AddTreatment/ProductRow'
import { SingleProductPicker } from 'components/AddTreatment/SingleProductPicker'
import { ProgressBar } from 'components/common'
import { OnSave } from 'components/EditTreatment/EditTreatmentScreen'
import { TreatmentSheetSecondLevel } from 'components/Treatment/common/types'
import { FormData } from 'components/TreatmentForm'
import { MultiProductPicker } from 'components/TreatmentTemplate/ProductPicker/MultiProductPicker'
import { useGetProductGroups } from 'components/TreatmentTemplate/ProductPicker/ProductPickerScreen'
import { TreatmentOption } from 'components/TreatmentTemplate/TemplateTreatmentForm'
import { compact, noop } from 'lodash'
import { getSheet_getSheet_treatments_items_treatments_items_treatments_items_product as TreatmentProduct } from 'src/types/getSheet'
import { ProductType } from 'src/types/globalTypes'

import { CalculatorForm } from './CalculatorForm'
import { IVInfusionProgressButton } from './IVInfusionProgressButton'
import { IVInfusionStep } from './common'
import { useSheetContext } from 'src/context/sheet'

const convertCurrentProductToTreatmentOption = (
  product?: TreatmentProduct | null,
) => {
  if (!product) {
    return null
  }
  return {
    isOldChild: true,
    id: product.id,
    name: product.name,
    isBillable: product.is_billable,
    type: product.type,
    groupName: '',
    code: '',
  }
}
export enum IVInfusionProgressStep {
  AddProducts = 'Add products',
  AddMedication = 'Add medication',
}

type Props = {
  defaultOverrides?: Partial<FormData>
  patientId: string
  sheetId: string
  onDone: () => void
  onEditIVInfusion?: OnSave
  treatment?: TreatmentSheetSecondLevel
}

export const IVInfusionForm: React.FC<Props> = ({
  patientId,
  sheetId,
  onDone,
  onEditIVInfusion,
  treatment,
  defaultOverrides,
}) => {
  const isEdit = !!treatment
  const [step, setStep] = useState(() =>
    isEdit ? IVInfusionStep.CalculatorForm : IVInfusionStep.AddProducts,
  )

  const [treatmentOptions, setTreatmentOptions] = useState<TreatmentOption[]>(
    () =>
      compact(
        treatment?.treatments?.items?.map(childTreatment => {
          const treatmentOption = convertCurrentProductToTreatmentOption(
            childTreatment.product,
          )

          if (!treatmentOption) return null

          return {
            ...treatmentOption,
            name: childTreatment?.name ?? treatmentOption.name,
          }
        }),
      ),
  )

  const [medicationOption, setMedicationOption] =
    useState<TreatmentOption | null>(() => {
      const editMedicationOption = convertCurrentProductToTreatmentOption(
        treatment?.product,
      )
      if (!editMedicationOption) {
        return null
      }
      return {
        ...editMedicationOption,
        name: treatment?.name ?? editMedicationOption.name,
      }
    })

  const onRemoveTreatmentOptions = useCallback((id: string) => {
    setTreatmentOptions(previousTreatments =>
      previousTreatments.filter(
        previousTreatment => previousTreatment.id !== id,
      ),
    )
  }, [])

  const onRemoveMedicationOption = useCallback(() => {
    setMedicationOption(null)
  }, [])

  const handleChangeTreatmentName = (name: string, id: string) => {
    setTreatmentOptions(
      treatmentOptions?.map(option => {
        if (id === option.id) return { ...option, name }

        return option
      }),
    )
  }

  const onSelect = useCallback((product: Product) => {
    setMedicationOption({
      id: product.id,
      name: product.name,
      isBillable: product.isBillable ?? null,
      groupName: product.group.name,
      code: product.code,
      type: product.type,
    })
    setStep(IVInfusionStep.CalculatorForm)
  }, [])

  const groups = useGetProductGroups()

  const [sheetContext] = useSheetContext()

  // site ids for product picker to use
  const siteIds = sheetContext.sheetAttendingDepartment
    ? [sheetContext.sheetAttendingDepartment]
    : []

  const renderIVInfusionStep = () => {
    switch (step) {
      case IVInfusionStep.AddProducts:
        return (
          <MultiProductPicker
            isIVInfusion={true}
            groups={groups}
            setTreatmentOptions={setTreatmentOptions}
            treatmentOptions={treatmentOptions}
            onSelect={noop}
            siteIds={siteIds}
          />
        )
      case IVInfusionStep.AddMedication:
        return (
          <SingleProductPicker
            productType={ProductType.MEDICATION}
            onSelect={onSelect}
            isIVInfusion={true}
            siteIds={siteIds}
          />
        )
      default:
        return (
          <CalculatorForm
            treatmentOptions={treatmentOptions}
            handleChangeTreatmentName={handleChangeTreatmentName}
            onRemoveTreatmentOptions={onRemoveTreatmentOptions}
            medicationOption={medicationOption}
            onRemoveMedicationOption={onRemoveMedicationOption}
            onPressAddProductGroup={() => setStep(IVInfusionStep.AddProducts)}
            onPressAddMedicationOption={() =>
              setStep(IVInfusionStep.AddMedication)
            }
            onDone={onDone}
            sheetId={sheetId}
            onEditIVInfusion={onEditIVInfusion}
            patientId={patientId}
            defaultOverrides={defaultOverrides}
            isEdit={isEdit}
          />
        )
    }
  }

  return (
    <>
      {step !== IVInfusionStep.CalculatorForm && !isEdit && (
        <ProgressBar steps={IVInfusionProgressStep} selectedStepName={step} />
      )}
      {renderIVInfusionStep()}
      <IVInfusionProgressButton
        step={step}
        onStepChange={setStep}
        isEdit={isEdit}
      />
    </>
  )
}
