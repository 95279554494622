import { reloadApp } from 'src/utils/reloadApp'
import { deviceLockService } from 'src/utils/deviceLockService'
import { idleTimerService } from 'src/utils/idleTimerService'
import { authTypeService, CheckAuthType } from './authTypeService'
import { userOrganisationService } from './userOrganisationService'
import { userPinSessionService, UserPinSession } from './userPinService'
import { userSessionService } from './userSession'
import { AuthAPI } from './api'
import {
  AuthError,
  ErrorCode,
  NewAuthChallenge,
  skipAuthMap,
  SkipAuthType,
  UserInfo,
  UserSession,
} from './type'

export const ONE_MIN = 60000
const pinErrors = [
  ErrorCode.PIN_NOT_FOUND,
  ErrorCode.PIN_EXPIRED,
  ErrorCode.PIN_MAX_ATTEMPT,
]
const userErrors = [ErrorCode.USER_NOT_FOUND, ErrorCode.USER_DISABLED]
const pinAndUserErrors = [...pinErrors, ...userErrors]

export class Auth {
  static async checkAuthVersion(): Promise<void> {
    const organisationId = userOrganisationService.getOrganisationId()
    const authType = {
      organisationId,
      isAuthV2: false,
    }
    if (!organisationId) return
    const result: CheckAuthType = await AuthAPI.get(
      `/check-auth-version/${organisationId}`,
    )

    if (result?.isAuthV2) {
      authType.isAuthV2 = result.isAuthV2
    }

    return authTypeService.setAuthType(authType)
  }

  static async currentSession(): Promise<UserSession | null> {
    const sessionData = userSessionService.getUserSession()
    if (!sessionData) return null
    const expiredAt = sessionData?.authenticationResult?.expiredAt
    if (!expiredAt) return null
    const buffer = ONE_MIN * 1
    const expiredTime = new Date(expiredAt)
    const beforeExpiration = new Date(expiredTime.getTime() - buffer)
    if (new Date() > beforeExpiration) {
      return this.refreshToken()
    }
    return sessionData
  }

  static async getUserInfo(): Promise<AuthError | void> {
    const result = await AuthAPI.get('/user-info')
    if (result?.errorCode) return result
    userSessionService.setUserSession({
      userInfo: result,
    })
  }

  static async setUpPin(pin: string): Promise<AuthError | void> {
    const session = userSessionService.getUserSession()
    if (!session) return
    const result = await AuthAPI.post('/setup-pin', {
      headers: {
        token: session?.pinInfo?.token,
      },
      payload: {
        pin,
      },
    })
    if (result?.errorCode) return result
    userPinSessionService.setUserPinSession(session, result.devicePinId)
  }

  static async signInWithPin(
    selectedUser: UserPinSession,
    pin: string,
  ): Promise<AuthError | void> {
    const { cognitoUserId, pinId, userId } = selectedUser
    const result = await AuthAPI.post('/sign-in-with-pin', {
      payload: {
        userId: cognitoUserId,
        devicePinId: pinId,
        pin,
      },
    })
    if (result?.errorCode) {
      if (pinAndUserErrors.includes(result.errorCode)) {
        userPinSessionService.deleteUserPinSession(userId)
        userSessionService.deleteUserSession()
      }
      return result
    }
    userSessionService.setUserSession(result)
    await userSessionService.checkUserCanAccessLocalOrg()
    idleTimerService.unsetIdleAt()
    deviceLockService.removeLock()
  }

  static async signIn(
    email: string,
    password: string,
  ): Promise<AuthError | NewAuthChallenge> {
    const result = await AuthAPI.post('/sign-in', {
      payload: {
        email,
        password,
      },
    })
    if (result?.errorCode) {
      if (result.errorCode === ErrorCode.PASSWORD_OR_EMAIL_MAX_ATTEMPT) {
        userSessionService.setAuthFailures(email)
      }
      return result
    }
    // Each signIn will reset the user PIN
    userPinSessionService.deleteCurrentUserPinSession()
    userSessionService.resetAuthFailures(email)
    idleTimerService.unsetIdleAt()
    deviceLockService.removeLock()
    if (!result?.ChallengeName) {
      userSessionService.setUserSession(result)
      await userSessionService.checkUserCanAccessLocalOrg()
    }
    return result
  }

  static async skipAuth(action: SkipAuthType): Promise<AuthError | void> {
    const result = await AuthAPI.post('/skip-auth-step', {
      payload: {
        action,
      },
    })
    if (result?.errorCode) return result
    const userActionField = skipAuthMap[action]
    if (!result[userActionField]) return
    const session = userSessionService.getUserSession()
    userSessionService.setUserSession({
      userInfo: {
        ...(session?.userAttributes || {}),
        [userActionField]: result[userActionField],
      } as UserInfo,
    })
  }

  static async respondNewPasswordChallenge(
    email: string,
    newPassword: string,
    session: string,
  ): Promise<AuthError | void> {
    const result = await AuthAPI.post(
      '/respond-new-password-required-challenge',
      {
        payload: {
          userId: email,
          newPassword,
          session,
        },
      },
    )
    if (result?.errorCode) return result
    userSessionService.setUserSession(result)
    await userSessionService.checkUserCanAccessLocalOrg()
    // Change password will reset the user PIN
    userPinSessionService.deleteCurrentUserPinSession()
  }

  static async sendEmailVerification(): Promise<AuthError | void> {
    return AuthAPI.post('/send-email-verification')
  }

  static async verifyEmailVerificationCode(
    code: string,
  ): Promise<AuthError | void> {
    return AuthAPI.post('/verify-email-verification-code', {
      payload: {
        code,
      },
    })
  }

  // if deleteDevicePin is false, then it's user switch request
  // if deleteDevicePin is true, then it's sign-out request
  // for sign-out request, we don't need to apply retries logic
  static async signOut(
    deleteDevicePin = true,
    retries = 0,
  ): Promise<AuthError | void> {
    const session = userSessionService.getUserSession()
    const cognitoUserId = session?.userAttributes?.cognito_user_id
    const userId = session?.userAttributes?.id || ''
    userSessionService.deleteUserSession()
    const devicePinId = deleteDevicePin
      ? userPinSessionService.getUserPinSession(userId)?.pinId
      : null
    if (devicePinId) {
      userPinSessionService.deleteUserPinSession(userId)
    }
    if (!cognitoUserId) return
    const result = await AuthAPI.post(
      '/sign-out',
      {
        headers: {
          token: session?.authenticationResult?.accessToken,
        },
        payload: {
          userId: cognitoUserId,
          ...(devicePinId && { devicePinId }),
        },
      },
      retries,
    )
    if (result?.errorCode) return result
  }

  static async changePassword(
    previousPassword: string,
    proposedPassword: string,
    userId: string,
  ): Promise<AuthError | void> {
    const result = await AuthAPI.post('/change-password', {
      payload: {
        previousPassword,
        proposedPassword,
        userId,
      },
    })
    // Change password will reset the user PIN
    if (!result?.errorCode) userPinSessionService.deleteCurrentUserPinSession()
    return result
  }

  static async confirmForgetPassword(
    email: string,
    code: string,
    password: string,
  ): Promise<AuthError | void> {
    return AuthAPI.post('/confirm-forgot-password', {
      payload: {
        email,
        code,
        password,
      },
    })
  }

  static async forgetPassword(email: string): Promise<AuthError | void> {
    return AuthAPI.post('/forgot-password', {
      payload: {
        email,
      },
    })
  }

  static async refreshToken(
    refreshToken?: string,
    retries = 2,
  ): Promise<UserSession | null> {
    if (!refreshToken && !userSessionService.hasV2Session()) return null
    const session = userSessionService.getUserSession()
    const result = await AuthAPI.post(
      '/refresh-token',
      {
        headers: {
          'refresh-token':
            refreshToken ?? session?.authenticationResult.refreshToken,
        },
      },
      retries,
    )
    if (result?.errorCode) {
      await this.signOut() // when refresh token expired, sign out the user and reload the app
      userSessionService.setErrorCode(result?.errorCode)
      reloadApp()
      return null
    }
    userSessionService.setUserSession({
      authenticationResult: {
        ...session?.authenticationResult,
        ...result,
      },
    })
    return userSessionService.getUserSession()
  }
}
