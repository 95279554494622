import React, { memo } from 'react'
import { StyleSheet, Text, ViewProps } from 'react-native'
import { BottomSheetHandle, BottomSheetHandleProps } from '@gorhom/bottom-sheet'
import { Colors } from 'constants/Colors'
import { AnimateProps } from 'react-native-reanimated'

export interface HeaderHandleProps extends BottomSheetHandleProps {
  children?: string | React.ReactNode | React.ReactNode[]
  style?: AnimateProps<ViewProps>['style']
  indicatorStyle?: AnimateProps<ViewProps>['style']
}

const HeaderHandleComponent = ({ children, ...rest }: HeaderHandleProps) => {
  return (
    <BottomSheetHandle style={styles.handleContainer} {...rest}>
      {typeof children === 'string' ? (
        <Text style={styles.title}>{children}</Text>
      ) : (
        children
      )}
    </BottomSheetHandle>
  )
}

const styles = StyleSheet.create({
  handleContainer: {
    paddingTop: 10,
  },
  title: {
    marginTop: 16,
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'center',
    fontWeight: 'bold',
    color: Colors.history.bottomSheet.title,
  },
})

export const HeaderHandle = memo(HeaderHandleComponent)
