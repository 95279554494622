import { gql } from '@apollo/client'

export const GET_PATIENT_WITH_SHEETS = gql`
  query getPatientWithSheets($id: ID!, $organisation_id: ID!) {
    getPatient(id: $id, organisation_id: $organisation_id) {
      id
      name
      avatar_url
      code
      color
      resuscitate
      sex
      species {
        name
        origin_id
      }
      breed {
        name
        origin_id
        origin_species_id
      }
      weight
      weight_unit
      attitude
      date_of_birth
      master_problems {
        condition_active
        condition_critical
        condition_specifics
        condition
        description
      }
      contact {
        id
        first_name
        last_name
        phone_number
      }
      sheets {
        items {
          id
          order
          closed_at
          name
          attending_vet
          attending_vet_tech
          approval_status
        }
      }
      active_consultations {
        items {
          id
          color {
            id
            hex
          }
        }
      }
    }
  }
`

export const UPDATE_SHEETS_ORDER = gql`
  mutation updateSheetsOrder($input: [UpdateSheetOrderInput!]!) {
    updateSheetsOrder(input: $input) {
      id
      organisation_id
      order
      name
    }
  }
`

export const SUBSCRIBE_SHEET = gql`
  subscription sheet($patientId: ID!) {
    sheet(patient_id: $patientId) {
      mutation
      data {
        id
        order
        closed_at
        deleted_at
        name
        attending_vet
        attending_vet_tech
        additional_care_team_names
        type_meta_data {
          asa
          anaesthesia {
            catheter_site
            owner_consent
            circuit_check
            comments
            tube_size
            str_tube_size
            complications
          }
          timers {
            name
            elapsed
            start_at
          }
        }
      }
    }
  }
`
