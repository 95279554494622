import React from 'react'
import { SvgArrow } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { Avatar } from 'components/common/Avatar'
import { Center } from 'src/components/common/Center'

export type UserItemProps = {
  a11yLabel?: string
  disabled?: boolean
  name: string
  role?: string
  onPress: () => void
}

export const UserItem = ({
  a11yLabel,
  disabled = false,
  name,
  role,
  onPress,
}: UserItemProps) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  return (
    <Center>
      <TouchableOpacity
        style={[styles.container, disabled && { opacity: 0.5 }]}
        onPress={onPress}
        accessibilityLabel={a11yLabel || `${t('settings:users:navTo')} ${name}`}
        disabled={disabled}
      >
        <View style={styles.main}>
          <View
            style={[
              styles.nameAvatarWrapper,
              {
                flex: breakpoint.isLargeScreen ? 5 : 3,
              },
            ]}
          >
            <Avatar name={name} />
            <Text
              style={[styles.name]}
              testID={name}
              numberOfLines={3}
              ellipsizeMode={'middle'}
            >
              {name}
            </Text>
          </View>
          {!!role && (
            <View style={styles.userRoleWrapper}>
              <Text style={[styles.name, styles.userRoleText]}>{role}</Text>
            </View>
          )}
          {!disabled && (
            <View style={styles.icon}>
              <SvgArrow orient="right" color="grey" width={15} height={15} />
            </View>
          )}
        </View>
      </TouchableOpacity>
    </Center>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxHeight: 150,
    paddingLeft: 16,
    paddingVertical: 16,
    width: '100%',
  },
  main: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  nameAvatarWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 'auto',
    paddingRight: 8,
  },
  userRoleWrapper: {
    alignItems: 'flex-start',
    flex: 1,
    paddingLeft: 12,
  },
  userRoleText: {
    textAlign: 'center',
  },
  icon: {
    padding: 16,
  },
  name: {
    fontFamily: Fonts.regular,
    fontSize: 16,
    marginLeft: 16,
  },
})
