import { SvgExclamation, SvgPendingClock } from 'components/Icons'
import { Pill } from 'components/common/Pill'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Colors as _Colors } from 'src/constants/constants'
import { Colors, Typography } from 'src/design-system/theme'
import { TaskPreviewTab } from '../types'

type Props = {
  activeTab: TaskPreviewTab
  dueTaskCount: number
  missedTaskCount: number
  handleDueMissed: () => void
  handleUpcoming: () => void
}

export const TaskPreviewTabs: React.FC<Props> = ({
  activeTab,
  dueTaskCount,
  missedTaskCount,
  handleDueMissed,
  handleUpcoming,
}) => {
  const { t } = useTranslation()

  const isActiveTab = useCallback(
    (tab: TaskPreviewTab) => (activeTab === tab ? styles.selectedTab : null),
    [activeTab],
  )

  const isActiveHeading = useCallback(
    (tab: TaskPreviewTab) =>
      activeTab === tab ? styles.selectedHeading : null,
    [activeTab],
  )

  return (
    <View
      style={styles.tabContainer}
      accessibilityLabel="TaskPreview_TabSelector"
    >
      <Pressable
        onPress={handleDueMissed}
        accessibilityLabel="TaskPreview_DueMissedTab"
        style={[
          styles.tab,
          isActiveTab(TaskPreviewTab.DUE_MISSED),
          styles.dueMissedTab,
        ]}
      >
        <Text
          style={[
            styles.tabHeading,
            isActiveHeading(TaskPreviewTab.DUE_MISSED),
          ]}
        >
          {t('task:taskPreview:dueMissedTab')}
        </Text>
        <View
          accessibilityLabel="TaskPreview_DueMissedCount"
          style={styles.pill}
        >
          {missedTaskCount > 0 ? (
            <Pill
              size={'small'}
              color={_Colors.sheetHistoryRow.missedTasksBackground}
              icon={
                <SvgExclamation
                  height={14}
                  color={_Colors.sheetHistoryRow.missedTasksPrimary}
                />
              }
              text={missedTaskCount > 9 ? '9+' : missedTaskCount.toString()}
              textStyle={styles.missedTaskText}
            />
          ) : null}
          {dueTaskCount > 0 ? (
            <Pill
              size={'small'}
              color={_Colors.sheetHistoryRow.dueTasksBackground}
              style={{ marginLeft: 8 }}
              icon={
                <SvgPendingClock
                  height={16}
                  color={_Colors.sheetHistoryRow.dueTasksPrimary}
                />
              }
              text={dueTaskCount > 9 ? '9+' : dueTaskCount.toString()}
              textStyle={styles.dueTaskText}
            />
          ) : null}
        </View>
      </Pressable>
      <Pressable
        onPress={handleUpcoming}
        accessibilityLabel="TaskPreview_UpcomingTab"
        style={[styles.tab, isActiveTab(TaskPreviewTab.UPCOMING)]}
      >
        <Text
          style={[styles.tabHeading, isActiveHeading(TaskPreviewTab.UPCOMING)]}
        >
          {t('task:taskPreview:upcomingTab')}
        </Text>
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  tab: {
    minWidth: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.Backgrounds.UI,
    borderBottomWidth: 2,
    borderBottomColor: Colors.Borders.primary,
  },
  tabContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 52,
    borderTopWidth: 1,
    borderTopColor: Colors.Borders.primary,
  },
  selectedTab: {
    borderBottomWidth: 2,
    borderBottomColor: Colors.Borders.accent,
    backgroundColor: Colors.Backgrounds.accentSecondary,
  },
  tabHeading: {
    ...Typography.Heading.S,
    textAlign: 'center',
  },
  selectedHeading: {
    color: Colors.Interactive.clickedAccent,
  },
  dueMissedTab: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  pill: {
    marginLeft: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  missedTaskText: {
    color: _Colors.sheetHistoryRow.missedTasksPrimary,
  },
  dueTaskText: {
    color: _Colors.sheetHistoryRow.dueTasksPrimary,
  },
})
