import { getTreatmentIsObservation } from 'components/Task/utils/getTreatmentInfo'
import { isEmpty, isNil } from 'lodash'
import { useCallback } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BulkTasksKeyedByTaskID } from '../BulkTaskActions'
import { BulkTreatmentsKeyedById } from '../BulkTaskCreate'
import { toast } from 'components/common'
import { TabName } from '../common'

type Props = {
  tasks: BulkTasksKeyedByTaskID | BulkTreatmentsKeyedById
  setError: (name: string, error: FieldError) => void
  clearErrors: (name?: string | string[]) => void
  currentTab?: TabName
}

export const useCheckNotes = ({
  tasks,
  setError,
  clearErrors,
  currentTab = TabName.COMPLETE,
}: Props) => {
  const { t } = useTranslation()

  const handleSetError = useCallback(setError, [setError])
  const handleClearErrors = useCallback(clearErrors, [clearErrors])

  return useCallback(() => {
    Object.values(tasks).forEach(task => {
      const isNotesRequired = task.treatment?.product?.is_notes_required
      if (!isNotesRequired) return

      const isObservation = getTreatmentIsObservation(task.treatment)

      const notesInput = task?.nextTaskToComplete
        ? `${task?.nextTaskToComplete.id}.nextTaskToComplete.notes`
        : `${task?.treatment.id}.notes`
      const valueInput = task?.task?.nextTaskToComplete
        ? `${task?.nextTaskToComplete.id}.nextTaskToComplete.value`
        : `${task?.treatment.id}.value`
      const isSelected = task?.nextTaskToComplete
        ? task?.completeChecked
        : task.checked
      const notes = task?.nextTaskToComplete
        ? task?.nextTaskToComplete.notes
        : task.notes
      const value = task?.nextTaskToComplete
        ? task?.nextTaskToComplete.value
        : task.value

      const requiredInput = isObservation ? valueInput : notesInput
      const requiredValue = isObservation ? value : notes

      if ((isEmpty(requiredValue) || isNil(requiredValue)) && isSelected) {
        handleSetError(requiredInput, {
          type: 'custom',
          message: t('task:taskTransition:form:mandatoryNotes'),
        })
        if (currentTab === TabName.SKIP) {
          toast.notice(t('task:taskTransition:form:mandatoryNotesMessage'))
        }
      } else {
        handleClearErrors(requiredInput)
      }
    })
  }, [tasks, handleSetError, t, currentTab, handleClearErrors])
}
