import { useCallback, useEffect, useMemo } from 'react'
import { useOrganisation } from 'src/context/organisation'

import { Storage } from 'src/utils/Storage'
import { makeVar, useReactiveVar } from '@apollo/client'

const CURRENT_PATIENT_KEY = 'CURRENT_PATIENT_KEY'
const curPatientId = makeVar<string | null>(null)

export const usePatientId = () => {
  const [{ organisationId }] = useOrganisation()
  const storage = useMemo(() => new Storage(organisationId), [organisationId])

  useEffect(() => {
    curPatientId(storage.getItem(CURRENT_PATIENT_KEY))
  }, [organisationId, storage])

  const currentPatientId = useReactiveVar(curPatientId)

  const setPatientId = useCallback(
    (v: string | null) => {
      storage.setItem(CURRENT_PATIENT_KEY, v)
      curPatientId(v)
    },
    [storage],
  )

  return {
    currentPatientId,
    setPatientId,
  }
}
