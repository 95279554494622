import React, { useState } from 'react'
import { noop, toInteger } from 'lodash'
import {
  StyleProp,
  TextInput as RnTextInput,
  TextStyle,
  TextInputProps,
} from 'react-native'

const numericRe = /^(0|[1-9]\d*)(\.\d+)?$/
const integerRe = /^\d+$/
export const isNumeric = (str: string) => {
  if (!str || str.length === 0) {
    return true
  }
  if (str.length >= 2 && str.slice(-1) === '.') {
    return integerRe.test(str.slice(0, -1))
  }
  return numericRe.test(str)
}

type Props = Omit<TextInputProps, 'value' | 'onChange'> & {
  autoFocus?: boolean
  disabled?: boolean
  label?: string
  value: number | null
  onChange?: (value: number | null) => void
  style?: StyleProp<TextStyle>
  shouldInteger?: boolean
}

export const RawNumericInput = React.forwardRef<RnTextInput, Props>(
  (
    {
      autoFocus = false,
      disabled = false,
      label,
      onChange = noop,
      value,
      shouldInteger = false,
      style,
      ...props
    },
    fRef,
  ) => {
    const [text, setText] = useState(value?.toString() ?? '')

    React.useEffect(() => {
      if (value === parseFloat(text)) {
        return
      }
      setText(value?.toString() ?? '')
    }, [value, text])

    const onChangeText = (t: string) => {
      let result = t
      if (!result) {
        setText('')
        onChange(null)
        return
      }

      if (result === '.') {
        setText('0.')
        onChange('0.')
      }

      if (!isNumeric(result)) {
        return
      }

      if (shouldInteger) {
        result = toInteger(result).toString()
      }

      setText(result)
      onChange(parseFloat(result))
    }

    const onBlur = () => {
      setText(value?.toString() ?? '')
    }

    return (
      <RnTextInput
        autoFocus={autoFocus}
        ref={fRef}
        accessibilityLabel={props.accessibilityLabel || `${label} Number Input`}
        keyboardType="numeric"
        style={style}
        onChangeText={onChangeText}
        onBlur={onBlur}
        value={text}
        editable={!disabled}
        {...props}
      />
    )
  },
)

RawNumericInput.displayName = 'RawNumericInput'
