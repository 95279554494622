import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  InputGroup,
  InputGroupText,
  NumericInput,
} from 'components/common'
import { getTaskValue } from 'components/Grid/GridTimeLine/utils/getNearestTimeTask'
import { useGetDateFormatPattern } from 'components/Task/utils/useGetStatusLabelText'
import { Colors, Typography } from 'src/design-system/theme'
import { format, startOfMinute } from 'date-fns'
import { capitalize, clone, map, pick } from 'lodash'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TextInput as RnTextInput, View } from 'react-native'
import { AnesthesiaChartEntry } from 'src/context/types/anesthesia'
import { TimeSegment } from 'src/hocs/types/time'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

export type BulkValueCompletionEntry = Required<
  Pick<
    AnesthesiaChartEntry,
    'matching_treatment_id' | 'full_title' | 'title' | 'unit'
  >
> & { value_to_submit?: string }

export type BulkValueCompletionLookup = {
  [treatmentID: string]: BulkValueCompletionEntry
}

type Props = {
  anesthesiaData: AnesthesiaChartEntry[]
  onSave?: (values: BulkValueCompletionEntry[]) => void
  selectedDate?: Date
  timeSegment?: TimeSegment
}

const capitalizeList = ['celsius', 'fahrenheit']

export const ValueEntry: React.FC<Props> = ({
  anesthesiaData,
  onSave,
  selectedDate = startOfMinute(new Date()),
  timeSegment = TimeSegment.fiveMinute,
}) => {
  const { t } = useTranslation()

  const { dateFormatPattern } = useGetDateFormatPattern()
  const initialValues = anesthesiaData.reduce<BulkValueCompletionLookup>(
    (acc, entry) => {
      if (!entry.matching_treatment_id) return acc

      const formattedEntry = pick(entry, [
        'matching_treatment_id',
        'full_title',
        'title',
        'unit',
      ]) as BulkValueCompletionEntry
      formattedEntry.value_to_submit = getTaskValue(
        entry.data,
        selectedDate,
        timeSegment,
      )
      return { ...acc, [formattedEntry.matching_treatment_id]: formattedEntry }
    },
    {},
  )

  const [anesthesiaChartEntries, setAnesthesiaChartEntry] =
    useState(initialValues)
  const textInputRefs = useRef<RnTextInput[]>([])
  useEffect(() => {
    textInputRefs.current = Array(anesthesiaData.length)
  }, [anesthesiaData.length])

  const handleSave = useCallback(() => {
    if (onSave) onSave(map(anesthesiaChartEntries))
    setAnesthesiaChartEntry(initialValues)
  }, [anesthesiaChartEntries, initialValues, onSave])

  const handleChangeEntry = (
    entry: BulkValueCompletionEntry,
    newText: string,
  ) => {
    setAnesthesiaChartEntry(entries => {
      const updatedEntries = clone(entries)
      updatedEntries[entry.matching_treatment_id].value_to_submit = newText
      return updatedEntries
    })
  }

  return (
    <KeyboardAwareScrollView
      style={styles.scrollContainer}
      enableResetScrollToCoords={false}
      extraHeight={220}
      keyboardOpeningTime={0}
    >
      <View style={styles.container}>
        {!!selectedDate && (
          <View style={styles.timeLabelHolder}>
            <Text style={styles.timeLabelText}>
              {format(selectedDate, dateFormatPattern)}
            </Text>
          </View>
        )}
        {Object.values(anesthesiaChartEntries).map((chartEntry, idx) => {
          if (capitalizeList.includes(chartEntry.unit)) {
            chartEntry.unit = capitalize(chartEntry.unit)
          }
          const submitEditing = () => {
            const nextTextInput = textInputRefs.current[idx + 1]
            if (nextTextInput) {
              nextTextInput.focus()
            }
          }

          return (
            <View
              key={chartEntry.matching_treatment_id}
              style={styles.taskToCompleteContainer}
              accessibilityLabel={`Complete: ${chartEntry.full_title}`}
            >
              <InputGroup>
                <View style={[styles.treatmentGroupInput]}>
                  <Text style={[styles.text, styles.treatmentNameText]}>
                    {chartEntry.title}
                  </Text>
                </View>
                <>
                  <NumericInput
                    label={t('task:taskTransition:form:valueLabel')}
                    ref={el => {
                      if (el) textInputRefs.current[idx] = el
                    }}
                    onSubmitEditing={submitEditing}
                    style={styles.valueInput}
                    onChange={v =>
                      handleChangeEntry(chartEntry, v?.toString() ?? '')
                    }
                    value={
                      chartEntry.value_to_submit === ''
                        ? null
                        : Number(chartEntry.value_to_submit)
                    }
                    testID={`Value_${chartEntry.title}`}
                  />
                </>
                <InputGroupText
                  a11yLabel="Completion Value Unit"
                  containerStyle={styles.unitContainer}
                  textStyle={styles.unitText}
                  text={chartEntry.unit}
                />
              </InputGroup>
            </View>
          )
        })}
        <Button
          a11yLabel={t('task:taskTransition.bulkSubmit:a11y')}
          color={Colors.Buttons.positive}
          onPress={handleSave}
          style={styles.submitBtn}
          title={t('general.complete')}
          testID="ButtonComplete"
        />
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  scrollContainer: {
    height: 'auto',
  },
  container: {
    alignItems: 'center',
    height: 'auto',
    marginHorizontal: 20,
  },
  submitBtn: {
    marginTop: 12,
  },
  treatmentGroupInput: {
    alignContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 8,
    height: 60,
    justifyContent: 'center',
    flexGrow: 1,
    borderBottomWidth: 1,
    borderColor: Colors.Borders.primary,
  },
  taskToCompleteContainer: {
    width: '100%',
    marginVertical: 4,
  },
  text: {
    ...Typography.Paragraph.S,
  },
  valueInput: {
    flexShrink: 0,
    flexBasis: '50%',
  },
  treatmentNameText: {
    fontSize: 14,
  },
  unitContainer: {
    width: 60,
  },
  unitText: {
    fontSize: 12,
  },
  timeLabelHolder: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 15,
  },
  timeLabelText: {
    color: Colors.Contents.tertiary,
    fontSize: 14,
    textAlign: 'center',
  },
})
