import { isEqual } from 'lodash'
import { useCallback, useState } from 'react'

import {
  getPatientListVisibleItems,
  VisiblePatientsToken,
} from './getPatientListVisibleItems'
import { useDebounceCallback } from 'src/hooks/useDebounceCallback'

type IsVisibleMap = { [id: string]: boolean }

export type PatientListView = {
  fetchedCursors: string[]
  fetchMoreExhausted: boolean
  isVisibleMap: IsVisibleMap
  visiblePageCursors: string[]
}

const initListView = {
  fetchedCursors: [],
  fetchMoreExhausted: false,
  isVisibleMap: {},
  visiblePageCursors: [],
}

export const usePatientListCursor = () => {
  const [listView, setListView] = useState<PatientListView>(initListView)

  const resetListView = useCallback(() => {
    setListView(previousListView => ({
      ...previousListView,
      fetchMoreExhausted: false,
      fetchedCursors: [''],
    }))
  }, [])

  const handleViewableItemsChanged = useDebounceCallback(
    ({ viewableItems }: { viewableItems: VisiblePatientsToken[] }) => {
      const { updatedIsVisibleMap, pageCursorsInView } =
        getPatientListVisibleItems(viewableItems)

      setListView(previousListView => {
        const isViewableItemsChanged =
          !isEqual(previousListView.visiblePageCursors, pageCursorsInView) ||
          !isEqual(previousListView.isVisibleMap, updatedIsVisibleMap)
        if (isViewableItemsChanged) {
          return {
            ...previousListView,
            visiblePageCursors: pageCursorsInView,
            isVisibleMap: updatedIsVisibleMap,
          }
        }
        return previousListView
      })
    },
    [],
    200,
  )

  const addCursorBeforeFetch = useCallback((cursor: string) => {
    setListView(previousListView => ({
      ...previousListView,
      fetchedCursors: [...previousListView.fetchedCursors, cursor],
    }))
  }, [])

  const checkExhaustedAfterFetch = useCallback((hasPatientLength: boolean) => {
    setListView(previousListView => ({
      ...previousListView,
      fetchMoreExhausted: !hasPatientLength,
    }))
  }, [])

  return {
    listView,
    resetListView,
    handleViewableItemsChanged,
    addCursorBeforeFetch,
    checkExhaustedAfterFetch,
  }
}
