import { KeyboardTypeOptions } from 'react-native'

export enum PinInputType {
  ALPHANUMERIC = 'ALPHANUMERIC',
  NUMERIC = 'NUMERIC',
  ALPHA = 'ALPHA',
}

export const validateInput = (type: PinInputType, value: string): boolean => {
  let regexPattern: RegExp

  switch (type) {
    case PinInputType.NUMERIC:
      regexPattern = /^[0-9]+$/
      break
    case PinInputType.ALPHA:
      regexPattern = /^[a-zA-Z]+$/
      break
    case PinInputType.ALPHANUMERIC:
      regexPattern = /^[a-zA-Z0-9]+$/
      break
    default:
      return false
  }

  return !!value.match(regexPattern)
}

export const generateCommonPins = (length: number): string[] => {
  const pinsSet: Set<string> = new Set()

  if (length === 0) {
    return []
  }

  for (let i = 0; i <= 9; i += 1) {
    pinsSet.add(`${i}`.repeat(length))
  }

  for (let i = 0; i <= 10 - length; i += 1) {
    const pinArr = Array.from({ length }, (_, index) => (i + index).toString())
    pinsSet.add(pinArr.join(''))
    pinsSet.add(pinArr.reverse().join(''))
  }

  return Array.from(pinsSet)
}

export const isRepeatingPin = (pin: string): boolean => {
  return pin === pin[0].repeat(pin.length)
}

export const isSequentialPin = (pin: string): boolean => {
  let sequential = true
  let reverseSequential = true

  // Check sequential order (e.g., 12345)
  for (let i = 1; i < pin.length; i += 1) {
    if (parseInt(pin[i], 10) !== parseInt(pin[i - 1], 10) + 1) {
      sequential = false
      break
    }
  }

  // Check reverse sequential order (e.g., 54321)
  for (let i = 1; i < pin.length; i += 1) {
    if (parseInt(pin[i], 10) !== parseInt(pin[i - 1], 10) - 1) {
      reverseSequential = false
      break
    }
  }

  return sequential || reverseSequential
}

export const determineKeyboardType = (
  type: PinInputType,
): KeyboardTypeOptions => {
  switch (type) {
    case PinInputType.NUMERIC:
      return 'number-pad'
    case PinInputType.ALPHA:
      return 'default'
    case PinInputType.ALPHANUMERIC:
      return 'default'
    default:
      return 'default'
  }
}
