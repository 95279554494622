import React, { PropsWithChildren } from 'react'
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  View,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'

export type SwitchBodyProps = {
  label?: string
  tooltip?: JSX.Element
  loading?: boolean
  style?: StyleProp<ViewStyle>
  autoFocus?: boolean
  wrapStyle?: StyleProp<ViewStyle>
  childrenStyle?: StyleProp<ViewStyle>
  subLabel?: string
  textStyle?: StyleProp<TextStyle>
  subTag?: React.ReactElement | null
  subTagStyle?: StyleProp<ViewStyle>
}

export const SwitchInputBody: React.FC<PropsWithChildren<SwitchBodyProps>> = ({
  label,
  tooltip,
  loading,
  style,
  wrapStyle,
  childrenStyle,
  subLabel,
  textStyle,
  subTag,
  subTagStyle,
  children,
}) => {
  return (
    <View style={[styles.container, style]}>
      {!!label && (
        <View style={styles.row}>
          <View
            style={[
              styles.label,
              wrapStyle,
              !!subLabel && styles.extraTextPadding,
            ]}
          >
            <Text style={[styles.value, textStyle]}>
              {label}
              <View style={subTagStyle}>{subTag}</View>
            </Text>
            {!!subLabel && <Text>{subLabel}</Text>}
          </View>
          <View>{tooltip}</View>
        </View>
      )}
      {!!loading && (
        <ActivityIndicator size="small" style={styles.activityIndicator} />
      )}
      <View style={childrenStyle}>{children}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  activityIndicator: {
    marginRight: 8,
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 60,
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  extraTextPadding: {
    paddingBottom: 5,
  },
  label: {
    marginRight: 'auto',
    flexDirection: 'column',
  },
  value: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontFamily: Fonts.regular,
    fontSize: 16,
    lineHeight: 22,
    alignItems: 'center',
  },
  row: { flexDirection: 'row', gap: 4 },
})
