import React from 'react'
import { Platform } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { useTranslation } from 'react-i18next'
import { SecondaryButton, toast } from 'components/common'

type Props = {
  options?: ImagePicker.ImagePickerOptions
  onSelect: (result: ImagePicker.ImagePickerResult) => Promise<void>
}

const defaultOptions: ImagePicker.ImagePickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.All,
  videoQuality: 1, // Medium
  quality: 0.5, // Half way between maximum compression and max quality
  allowsMultipleSelection: false,
  selectionLimit: 9, // 3x3 grid
  base64: false,
}

export const UploadButton: React.FC<Props> = ({ options, onSelect }) => {
  const { t } = useTranslation()
  const { showActionSheetWithOptions } = useActionSheet()

  const launchOptions = {
    ...defaultOptions,
    allowsEditing: !options?.allowsMultipleSelection, // Shouldn't be active if multiselect is true, this line avoids warnings.
    ...options,
  }

  const selectFromImageLibraryWeb = async () => {
    return onSelect(
      webFix(await ImagePicker.launchImageLibraryAsync(launchOptions)),
    )
  }

  const selectFromImageLibrary = async () => {
    if (Platform.OS === 'ios') {
      const mediaPermission =
        await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (mediaPermission.status !== 'granted') {
        toast.error(t('permissions.failed', { permission: 'camera roll' }))
      }
    }
    return onSelect(await ImagePicker.launchImageLibraryAsync(launchOptions))
  }

  const selectFromCamera = async () => {
    if (Platform.OS === 'ios') {
      const cameraPermission = await ImagePicker.requestCameraPermissionsAsync()
      if (cameraPermission.status !== 'granted') {
        toast.error(t('permissions.failed', { permission: 'camera' }))
      }
    }
    return onSelect(await ImagePicker.launchCameraAsync(launchOptions))
  }

  const onOpenActionSheet = () => {
    if (Platform.OS === 'web') {
      return selectFromImageLibraryWeb()
    }
    const options = [
      t('task:taskTransition:fileAttachments.openCamera'),
      t('task:taskTransition:fileAttachments.openLibrary'),
      t('general.cancel'),
    ]
    const cancelButtonIndex = 2

    return showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      buttonIndex => {
        if (buttonIndex === 0) return selectFromCamera()
        if (buttonIndex === 1) return selectFromImageLibrary()

        // Cancel button or cancel by outside click
        if (buttonIndex === 2) return

        // Handle the case when buttonIndex is invalid
        throw new Error('Invalid button index')
      },
    )
  }

  return (
    <SecondaryButton
      accessibilityLabel={t('task:taskTransition:fileAttachments.addMedia')}
      title={t('task:taskTransition:fileAttachments.addMedia')}
      onPress={onOpenActionSheet}
    />
  )
}

/**
 * This makes the picker return type and fileSize on WEB
 * which allows us to check upload size and show thumbnail correctly
 *  */
const webFix = (
  result: ImagePicker.ImagePickerResult,
): ImagePicker.ImagePickerResult => {
  if (result.canceled) return result
  const fixedAssets = result.assets.map(
    (asset): ImagePicker.ImagePickerAsset => {
      let type: 'image' | 'video' | undefined
      let fileSize: number | undefined
      const parts = asset.uri.split(';')
      if (parts.length > 0) {
        fileSize = atob(asset.uri.split(',')[1]).length
        const mimeType = parts[0].split(':')[1]
        if (mimeType) {
          const mediaType = mimeType.split('/')[0].toLowerCase()
          if (mediaType === 'image' || mediaType === 'video') {
            type = mediaType
          }
        }
      }
      return {
        ...asset,
        type,
        fileSize,
      }
    },
  )
  return { ...result, assets: fixedAssets }
}
