import { addMinutes, isWithinInterval, parseISO, subMinutes } from 'date-fns'
import { find } from 'lodash'
import {
  TimeSegment,
  TimeSegmentFuzzIntervalMinutes,
} from 'src/hocs/types/time'
import {
  getSheet_getSheet_treatments_items as TreatmentSheetFirstLevel,
  getSheet_getSheet_treatments_items_treatments_items_tasks_items as SubTreatmentTask,
} from 'src/types/getSheet'
import { Status } from 'src/types/globalTypes'
import { isOptimisticId } from 'src/utils/optimisticId'

import { TreatmentSheetThirdLevel } from '../common/types'
import { getAllBulkActionableSubTreatments } from './getAllBulkActionableSubTreatments'

export type BulkTaskWithoutTreatment = {
  checked: boolean
  completeChecked?: boolean
  deleteChecked?: boolean
  nextTaskToComplete: SubTreatmentTask
}

export type SubTreatmentAndFirstTask = {
  checked: boolean
  completeChecked?: boolean
  deleteChecked?: boolean
  treatment: TreatmentSheetThirdLevel
  nextTaskToComplete: SubTreatmentTask
}

export type BulkTasks = SubTreatmentAndFirstTask[]

export function isValidSubTreatmentAndFirstTask(
  subTreatmentAndFirstTask: SubTreatmentAndFirstTask | undefined,
): subTreatmentAndFirstTask is SubTreatmentAndFirstTask {
  return !!subTreatmentAndFirstTask?.treatment ?? false
}

type GetSubTreatmentsNearestFirstTasks = (
  treatment: TreatmentSheetFirstLevel,
  timeOfPress: Date,
  timeSegment: TimeSegment,
) => BulkTasks

/* Gets the closest due|pending task underneath the row, if they are
   close(ish) to the position of the TreatmentRow's press */
export const getSubTreatmentNearestFirstTasks: GetSubTreatmentsNearestFirstTasks =
  (treatment, timeOfPress, timeSegment) => {
    const allSubTreatments = getAllBulkActionableSubTreatments(treatment)

    // This is so that when you press around a time area, it grabs task up to
    // <interval> before/after the press
    const timeFuzzIntervalMinutes = TimeSegmentFuzzIntervalMinutes[timeSegment]

    const fuzzyTimeRange = (startISO: string, endISO: string) => ({
      start: subMinutes(parseISO(startISO), timeFuzzIntervalMinutes),
      end: addMinutes(parseISO(endISO), timeFuzzIntervalMinutes),
    })

    const subTreatmentsFirstDueOrPendingTask = allSubTreatments.map(
      subTreatment => {
        const allSubTreatmentTasks = subTreatment.tasks?.items ?? []
        const firstPendingOrDueSubTreatmentTask = find<SubTreatmentTask>(
          allSubTreatmentTasks,
          task => {
            if (!task.start_at || !task.stop_at) return false
            const isNotBulkPickerTask =
              task.status === Status.DELETED_ON_PURPOSE ||
              task.status === Status.DONE ||
              task.status === Status.MISSED_ON_PURPOSE

            const isOptimisticTask = isOptimisticId(task.id)

            if (isNotBulkPickerTask || isOptimisticTask) {
              return false
            }
            const range = fuzzyTimeRange(task.start_at, task.stop_at)
            return isWithinInterval(timeOfPress, range)
          },
        )
        const subTreatmentAndFirstTask: SubTreatmentAndFirstTask | undefined =
          firstPendingOrDueSubTreatmentTask && {
            checked: false,
            treatment: subTreatment,
            nextTaskToComplete: firstPendingOrDueSubTreatmentTask,
          }
        return subTreatmentAndFirstTask
      },
    )
    return subTreatmentsFirstDueOrPendingTask.filter<SubTreatmentAndFirstTask>(
      isValidSubTreatmentAndFirstTask,
    )
  }

export const getTreatmentsNoSameTimeTask = (
  treatmentGroup: TreatmentSheetFirstLevel,
  timeOfPress: Date,
) => {
  const allSubTreatments = getAllBulkActionableSubTreatments(treatmentGroup)
  return allSubTreatments.filter(treatment => {
    if (isOptimisticId(treatment.id)) return false
    const hasNoSameTimeTask = !treatment.tasks?.items?.some(task => {
      if (!task.start_at || !task.stop_at) return false
      if (task.status === Status.DELETED_ON_PURPOSE) {
        return false
      }

      return isWithinInterval(timeOfPress, {
        start: parseISO(task.start_at),
        // Use stop_at will find the task when you click the next column.
        end: subMinutes(parseISO(task.stop_at), 1),
      })
    })
    const isNotDisContinued = !treatment.discontinued_at
    return hasNoSameTimeTask && isNotDisContinued
  })
}
