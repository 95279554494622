import { orderBy } from 'lodash'

export const sortSheetList = <T extends { name: string; order: number | null }>(
  sheets: T[],
) => {
  return orderBy(
    sheets,
    ['order', item => item.name.toLowerCase()],
    ['asc', 'asc'],
  )
}
