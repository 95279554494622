import React, { useMemo, useRef } from 'react'
import {
  Platform,
  StyleSheet,
  TextInput as RnTextInput,
  View,
} from 'react-native'
import { usePatientById } from 'src/hooks/usePatient'
import { BulkTreatmentsKeyedById } from './BulkTaskCreate'
import { SelectAll } from './SelectAll'
import { useSelectAll } from './utils/useSelectAll'
import { HandleChange } from './BulkTaskList'
import { BulkTaskCreateListItem } from './BulkTaskCreateListItem'
import { useApprovals } from 'src/hooks/useApprovals'

type Props = {
  handleChange: HandleChange
  patientId?: string
  values: BulkTreatmentsKeyedById
}

export const bulkTasksA11yLabel = 'Bulk Task Completion Dialog'

export const BulkTaskCreateList: React.FC<Props> = ({
  handleChange,
  patientId = '',
  values,
}) => {
  const patient = usePatientById(patientId)
  const valueArray = Object.values(values)
  const keyboardType =
    Platform.OS === 'web' ? 'default' : 'numbers-and-punctuation'

  const taskInputRefs = useRef<
    Record<
      number,
      { input: RnTextInput | HTMLElement; notes?: RnTextInput | HTMLElement }
    >
  >({})

  const { isAllSelected, handleAllSelect } = useSelectAll({
    handleChange,
    valueArray,
    isBulkCreating: true,
    isCompletingTask: false,
  })

  const { isTreatmentApproved } = useApprovals()

  const autoFocusIdx = useMemo(
    () =>
      valueArray.findIndex(({ treatment }) => isTreatmentApproved(treatment)) ??
      0,

    [isTreatmentApproved, valueArray],
  )

  return (
    <View style={styles.container} accessibilityLabel={bulkTasksA11yLabel}>
      <SelectAll isAllSelected={isAllSelected} onChange={handleAllSelect} />
      <View style={styles.taskList}>
        <View style={styles.inputs}>
          {valueArray?.map((treatmentWithCheck, idx) => (
            <BulkTaskCreateListItem
              key={treatmentWithCheck.treatment.id}
              treatmentTask={treatmentWithCheck}
              idx={idx}
              autoFocus={idx === autoFocusIdx}
              keyboardType={keyboardType}
              patient={patient}
              handleChange={handleChange}
              taskInputRefs={taskInputRefs}
            />
          ))}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: 'auto',
  },
  taskList: {
    flexDirection: 'row',
    width: '100%',
  },
  inputs: {
    flex: 1,
  },
})
