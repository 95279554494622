import { StyleSheet } from 'react-native'
import { Colors } from 'constants/Colors'

export const styles = StyleSheet.create({
  toggleButton: {
    position: 'absolute',
    top: -35,
    left: '50%',
    width: 160,
    flexDirection: 'row',
    transform: [{ translateX: -80 }],
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    shadowOffset: {
      height: -3,
      width: 0,
    },
    shadowColor: Colors.contentPrimary,
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  toggleButtonSmall: {
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingVertical: 10,
    backgroundColor: Colors.white,
    borderRadius: 6,
    height: 48,
    alignItems: 'center',
  },
  fullOpacity: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
})
