import React from 'react'
import {
  repeatingIntervalOptions,
  timeWindowOptions,
} from 'components/TreatmentForm/data'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { dateFormatter } from 'src/utils/formatter'

import { TreatmentChildLevel } from './common/types'

export const formatStartAt = (startAt: string | null, timeFormat: string) => {
  if (startAt === null) {
    return ''
  }
  return format(new Date(startAt), timeFormat)
}

export const formatWindow = (timeWindow: number) => {
  return timeWindowOptions.find(option => option.value === timeWindow)?.text
}

export const formatRepeat = (frequency: number, repeatUntil: string) => {
  const frequencyText = repeatingIntervalOptions.find(
    option => option.value === frequency,
  )?.text

  let untilString = 'discharge'
  if (repeatUntil) {
    untilString = dateFormatter(new Date(repeatUntil), 'date')
  }

  return [...(frequencyText ? [frequencyText] : []), untilString].join(' - ')
}

export type Props = {
  treatment: Partial<TreatmentChildLevel> // TODO: check type really is partial?
  timeFormat?: string
}

const defaultFluidDosageInfo = {
  days: 0,
  hours: 0,
  minutes: 0,
}

export const TreatmentSchedule: React.FC<Props> = ({
  treatment,
  timeFormat = 'MMM do yyyy HH:mm',
}) => {
  const { t } = useTranslation()
  const {
    isExSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeButNotThatLargeScreen,
  } = useBreakpoint()

  const isSmallToLargeDisplay =
    isExSmallScreen ||
    isSmallScreen ||
    isMediumScreen ||
    isLargeButNotThatLargeScreen

  const defaultTreatment = {
    schedule: {
      start_at: '',
      repeat: false,
      frequency: 0,
      repeat_until: '',
      time_window: 0,
    },
    ...treatment,
  }

  const schedule = defaultTreatment.schedule
  const fluidDosageInfo =
    defaultTreatment.medicine_dosage_info ?? defaultTreatment.fluid_dosage_info
  const { days, hours, minutes } = fluidDosageInfo ?? defaultFluidDosageInfo

  const returnFluidDurationTitle = () => {
    if (!!days || !!hours || !!minutes) {
      return `${t('addTreatment:duration.label')}: `
    }
    return ''
  }

  if (!schedule) return null

  const hasScheduleFieldSet = !!(
    schedule.start_at ||
    schedule.time_window ||
    schedule.repeat
  )
  const hasTimeFieldSet =
    !!(days || hours || minutes) && !treatment.is_continuous

  return (
    <>
      {hasScheduleFieldSet ? (
        <View
          style={[
            styles.container,
            isSmallToLargeDisplay && styles.exSmallContainer,
          ]}
          testID="Treatment Schedule"
        >
          {!!schedule.start_at && (
            <View style={styles.rowContainer}>
              <Text style={styles.scheduleText}>{`${t(
                'addTreatment:schedule.startAt',
              )}: `}</Text>
              <Text style={styles.scheduleBoldText}>
                {formatStartAt(schedule.start_at, timeFormat)}
              </Text>
            </View>
          )}

          {!!schedule.time_window && (
            <View style={styles.rowContainer}>
              {!isSmallToLargeDisplay && (
                <Text style={styles.verticalDivider}>|</Text>
              )}
              <Text style={styles.scheduleText}>{`${t(
                'template:treatment.window',
              )}: `}</Text>
              <Text style={styles.scheduleBoldText}>
                {formatWindow(schedule.time_window)}
              </Text>
            </View>
          )}

          {!!schedule.repeat && (
            <View style={styles.rowContainer}>
              {!isSmallToLargeDisplay && (
                <Text style={styles.verticalDivider}>|</Text>
              )}
              <Text style={styles.scheduleText}>{`${t(
                'addTreatment:repeating',
              )}: `}</Text>
              <Text style={styles.scheduleBoldText}>
                {formatRepeat(schedule.frequency!, schedule.repeat_until!)}
              </Text>
            </View>
          )}
        </View>
      ) : null}

      {hasTimeFieldSet ? (
        <View style={styles.container}>
          <Text style={styles.scheduleText}>{returnFluidDurationTitle()}</Text>

          {!!days && (
            <View style={styles.rowContainer}>
              <Text style={styles.scheduleText}>{`${t(
                'addTreatment:duration.days',
              )}: `}</Text>
              <Text style={styles.scheduleBoldText}>{days}</Text>
            </View>
          )}

          {!!hours && (
            <View style={styles.rowContainer}>
              <Text style={styles.verticalDivider}>|</Text>
              <Text style={styles.scheduleText}>{`${t(
                'addTreatment:duration.hrs',
              )}: `}</Text>
              <Text style={styles.scheduleBoldText}>{hours}</Text>
            </View>
          )}

          {!!minutes && (
            <View style={styles.rowContainer}>
              <Text style={styles.verticalDivider}>|</Text>
              <Text style={styles.scheduleText}>{`${t(
                'addTreatment:duration.mins',
              )}: `}</Text>
              <Text style={styles.scheduleBoldText}>{minutes}</Text>
            </View>
          )}
        </View>
      ) : null}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  exSmallContainer: {
    marginBottom: 10,
    marginHorizontal: 0,
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-evenly',
    width: '100%',
  },
  rowContainer: {
    flexDirection: 'row',
  },
  verticalDivider: {
    fontFamily: Fonts.regular,
    color: Colors.contentTertiary,
    paddingHorizontal: 8,
  },
  scheduleText: {
    fontFamily: Fonts.regular,
  },
  scheduleBoldText: {
    fontFamily: Fonts.bold,
  },
})
