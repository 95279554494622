import { WeightUnit } from 'constants/Weight'

type DosageSafeRangeTextInput = {
  lowestDosage: number | null
  highestDosage: number | null
  productName: string
  weightUnit: WeightUnit
  perWeightUnit: WeightUnit
}

export const getDosageSafeRangeInformativeText = (
  dosageSafeRangeTextInput: DosageSafeRangeTextInput,
) => {
  const {
    lowestDosage,
    highestDosage,
    productName,
    weightUnit,
    perWeightUnit,
  } = dosageSafeRangeTextInput
  const labelText = `${productName}'s safe range:`
  const unitsText = `${weightUnit}/${perWeightUnit}`
  if (!!lowestDosage && !!highestDosage) {
    return `${labelText} ${lowestDosage} ${unitsText} to ${highestDosage} ${unitsText}.`
  }
  if (!!highestDosage) {
    return `${labelText} below ${highestDosage} ${unitsText}.`
  }
  if (!!lowestDosage) {
    return `${labelText} above ${lowestDosage} ${unitsText}.`
  }
  return ''
}
