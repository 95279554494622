import { gql } from '@apollo/client'
import {
  MEDICINE_FIELDS,
  MEDICINE_FIELDS_FULL,
  PRODUCT_FIELDS,
  SCHEDULE_FIELDS,
  TREATMENT_FIELDS_BASE,
  TREATMENT_GROUP_FIELDS,
} from 'components/AddTreatment/graphql'
import { TASK_FIELDS_FULL } from 'components/Task/graphql'

export const UPDATE_TREATMENT = gql`
  mutation updateTreatment(
    $input: UpdateTreatmentInput!
    $options: UpdateTreatmentOptions
  ) {
    updateTreatment(input: $input, options: $options) {
      ...TreatmentFieldsBase
      treatments {
        items {
          ...TreatmentFieldsBase
        }
      }
    }
  }

  ${TREATMENT_FIELDS_BASE}
`

export const UPDATE_TREATMENT_APPROVAL_STATUS = gql`
  mutation updateTreatmentApproval($input: UpdateTreatmentApprovalInput!) {
    updateTreatmentApproval(input: $input) {
      id
      approval_status
    }
  }
`

export const APPROVE_SHEET_TREATMENTS = gql`
  mutation approveSheetTreatments($input: ApproveSheetInput!) {
    approveSheet(input: $input)
  }
`

export const RESCHEDULE_TREATMENTS = gql`
  mutation bulkRescheduleTreatments($input: [RescheduleTreatmentInput!]!) {
    bulkRescheduleTreatments(input: $input) {
      discontinued_at
      display_states
      id
      instructions
      sheet_id
      ...ScheduleFields
    }
  }
  ${SCHEDULE_FIELDS}
`

export const BULK_CREATE_TREATMENTS = gql`
  mutation bulkCreateTreatments(
    $input: [BulkCreateTreatmentsInput!]!
    $fromISODate: String!
    $toISODate: String!
    $options: BulkCreateTreatmentsOptions
  ) {
    bulkCreateTreatments(input: $input, options: $options) {
      ...TreatmentGroupFields
    }
  }
  ${TREATMENT_GROUP_FIELDS}
`

export const UPDATE_CONDITIONAL_TREATMENT = gql`
  mutation updateConditionalTreatment($input: UpdateTreatmentInput!) {
    updateTreatment(input: $input) {
      sheet_id
      updated_at
      tasks {
        items {
          ...TaskFieldsFull
        }
      }
      treatments {
        items {
          id
          name
        }
      }
      ...TreatmentFieldsBase
    }
  }
  ${TREATMENT_FIELDS_BASE}
  ${TASK_FIELDS_FULL}
`

export const UPDATE_TREATMENT_GROUP = gql`
  mutation updateTreatmentGroup($input: UpdateTreatmentGroupInput!) {
    updateTreatmentGroup(input: $input) {
      id
      name
    }
  }
`
export const UPDATE_TREATMENTS_ORDER = gql`
  mutation updateTreatmentsOrder($input: [UpdateTreatmentOrderInput!]!) {
    updateTreatmentsOrder(input: $input) {
      id
      organisation_id
      order
      name
    }
  }
`

export const TASK_NOTE = gql`
  fragment TaskNotes on Task {
    id
    start_at
    given_start_at
    notes
    updated_at
    treatment_id
    status
  }
`

const TASK_NOTES_BY_TREATMENT = gql`
  fragment TaskNotesByTreatment on Treatment {
    tasks {
      items {
        ...TaskNotes
      }
    }
  }
  ${TASK_NOTE}
`

export const GET_TREATMENT_NOTES = gql`
  query getTreatmentNotes($patient_id: ID!, $treatment_id: ID!) {
    getTreatment(patient_id: $patient_id, treatment_id: $treatment_id) {
      ...TaskNotesByTreatment
    }
  }
  ${TASK_NOTES_BY_TREATMENT}
`

export const GET_TREATMENT = gql`
  query getTreatment($patient_id: ID!, $treatment_id: ID!) {
    getTreatment(patient_id: $patient_id, treatment_id: $treatment_id) {
      product {
        type
      }
      ...TreatmentFieldsBase
      ...MedicineFieldsFull
    }
  }
  ${TREATMENT_FIELDS_BASE}
  ${MEDICINE_FIELDS_FULL}
`

export const SUBSCRIBE_TREATMENT = gql`
  subscription treatment($sheetId: ID!) {
    treatment(sheet_id: $sheetId) {
      mutation
      data {
        approval_status
        approval_updated_by
        conditional
        discontinued_at
        display_states
        id
        instructions
        is_continuous
        is_instructions_important
        name
        schedule {
          enable_staffed_hour
          frequencies
          frequency
          repeat
          repeat_until
          start_at
          time_window
          treatment_frequency_id
          type
        }
        sheet_id
        tasks {
          items {
            id
            status
          }
        }
        treatmentTasksStatus {
          hasDue
          hasMissed
        }
        ...MedicineFields
        ...ProductFields
      }
    }
  }
  ${PRODUCT_FIELDS}
  ${MEDICINE_FIELDS}
`

export const DELETE_TREATMENT = gql`
  mutation deleteTreatment($input: DeleteTreatmentInput!) {
    deleteTreatment(input: $input)
  }
`
