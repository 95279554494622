import React from 'react'
import { Colors } from 'constants/constants'
import { StyleProp, StyleSheet, View, ViewStyle, TextInput } from 'react-native'
import { Fonts } from 'src/constants/Fonts'

type Props = {
  style?: StyleProp<ViewStyle>
  value: string
  onChange: (text: string) => void
  removeEditing: () => void
}

export const EditableProductName = ({
  value,
  onChange,
  style,
  removeEditing,
}: Props) => {
  return (
    <View style={[styles.inputContainer, style]}>
      <TextInput
        autoFocus={true}
        onChangeText={onChange}
        onBlur={removeEditing}
        value={value}
        style={styles.text}
        testID={`Editable Product Name Input - ${value}`}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    fontFamily: Fonts.regular,
    fontSize: 16,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.borderGrey,
    padding: 5,
    height: '100%',
  },
  inputContainer: {
    marginHorizontal: 5,
  },
})
