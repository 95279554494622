import React from 'react'
import Svg, { Circle, G, Path } from 'react-native-svg'

type Props = {
  color?: string
  width?: number
  height?: number
}

export const SvgExclamation: React.FC<Props> = ({
  color = '#DD2C00',
  width = 6,
  height = 20,
}) => (
  <Svg height={height} width={width} viewBox="0 0 6 20" testID="SvgExclamation">
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G transform="translate(-25.000000, -17.000000)">
        <G transform="translate(18.000000, 15.000000)">
          <G transform="translate(0.000000, 2.000000)">
            <Circle
              fill={color}
              fillRule="nonzero"
              cx="10"
              cy="17.5"
              r="1.66666667"
            />
            <Path
              d="M10,0.833333333 C11.0416667,0.833333333 12.0833333,0.833333333 12.0833333,2.8843601 C12.0833333,6.72571056 11.0416667,13.4260588 11.0416667,13.9387873 C11.0416667,14.4515159 10.839269,13.9387873 10,13.9387873 C9.16073102,13.9387873 8.95833333,14.4515159 8.95833333,13.9387873 C8.95833333,13.4260588 7.91666667,6.57621019 7.91666667,2.8843601 C7.91666667,0.822895051 8.95833333,0.833333333 10,0.833333333 Z"
              fill={color}
              fillRule="evenodd"
            />
          </G>
        </G>
      </G>
    </G>
  </Svg>
)
