import React from 'react'
import { useQuery } from '@apollo/client'
import { SvgFlag } from 'components/Icons/Flag'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { weightFactor } from 'constants/Weight'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { GET_PRODUCT_DETAILS } from 'src/components/AddTreatment/graphql'
import { getFormattedNumber } from 'src/components/TreatmentForm/utils/getCalculations'
import { Colors } from 'src/constants/Colors'
import { useOrganisation } from 'src/context/organisation'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { usePatientById } from 'src/hooks/usePatient'
import { useRound } from 'src/hooks/useRound'
import {
  getOneProduct as GetOneProduct,
  getOneProductVariables as GetOneProductVariables,
} from 'src/types/getOneProduct'
import { ProductType, Status } from 'types/globalTypes'

import { Task } from '../types'
import { getTotalPrescribedDosage } from '../utils/getTotalPrescribedDosage'
import {
  getTotalPrescribedVolume,
  getTotalVolumeUnit,
} from '../utils/getTotalPrescribedVolume'
import {
  getIsCRI,
  getIsFluid,
  getIsFluidStyleTaskDisplay,
} from '../utils/getTreatmentInfo'
import { DilutionHeader } from './DilutionHeader'
import { TruncatedText } from './TruncatedText'
import { getFluidOrCRITreatmentValue } from 'components/EditTreatment/utils/updateFluidTreatmentUtils'

type Props = {
  children?: React.ReactElement | null
  showHeader?: boolean
  treatment: TreatmentChildLevel | null
  patientId: string
  task?: Task | null
  headStyleForCubex?: ViewStyle | null
}

// If not a medication type or no treatment instructions skip header
// If task is complete don't show header (adding new 'recorded value' header in VR-2806)
export const getShouldShowTaskTreatmentHeader = (
  treatment: TreatmentChildLevel | null,
  task?: Task | null,
) => {
  const isMedication = treatment?.product?.type === ProductType.MEDICATION
  const isFluidStyleTaskDisplay = getIsFluidStyleTaskDisplay(treatment)
  if (!isMedication && !treatment?.instructions && !isFluidStyleTaskDisplay) {
    return false
  }
  if (task?.status === Status.DONE) {
    return false
  }
  return true
}

const InfoHeader: React.FC<Props> = ({
  treatment,
  patientId,
  task,
  headStyleForCubex,
}) => {
  const { t } = useTranslation()
  const round = useRound()
  const patient = usePatientById(patientId)
  const [{ organisationId }] = useOrganisation()
  const { isMediumScreen, isLargeScreen } = useBreakpoint()
  const isNativeStyle = !isMediumScreen && !isLargeScreen
  const isMedication = treatment?.product?.type === ProductType.MEDICATION
  const isFluidStyleTaskDisplay = getIsFluidStyleTaskDisplay(treatment)
  const isCRI = getIsCRI(treatment)
  const isFluid = getIsFluid(treatment)
  const isDoneTask = task?.status === Status.DONE
  const taskMedicineInfo = task?.medicine_dosage_info ?? null

  const medicineDosageInfo =
    isDoneTask && taskMedicineInfo
      ? taskMedicineInfo
      : treatment?.medicine_dosage_info

  const infusionRateTotal = medicineDosageInfo?.infusion_rate_total
  const prescribedDosage = medicineDosageInfo?.dosage || 0
  const dosageWeightUnit = medicineDosageInfo?.dosage_weight_unit
  const totalDosageWeightUnit = medicineDosageInfo?.dosage_weight_unit

  const dosagePerWeightUnit = medicineDosageInfo?.dosage_patient_weight_unit
  const patientWeightAtTimeOfTreatmentCreation =
    medicineDosageInfo?.patient_weight ?? patient?.weight

  const patientWeightUnit =
    medicineDosageInfo?.patient_weight_unit ?? patient?.weight_unit

  const prescribedDosageUnitPerWeight = `${dosageWeightUnit}/${dosagePerWeightUnit}`

  const totalPrescribedDosage = getTotalPrescribedDosage({
    patientWeight: {
      weight: patientWeightAtTimeOfTreatmentCreation,
      weight_unit: patientWeightUnit,
    },
    treatmentMedicineDosage: medicineDosageInfo,
    round,
  })

  const { data } = useQuery<GetOneProduct, GetOneProductVariables>(
    GET_PRODUCT_DETAILS,
    {
      variables: {
        id: treatment?.product?.id!,
        organisation_id: organisationId,
      },
    },
  )

  const totalPrescribedVolume = () => {
    const product = data?.getProduct
    const vol = getTotalPrescribedVolume(
      product?.medicine_dosage_info,
      parseFloat(totalPrescribedDosage as string),
      medicineDosageInfo,
      round,
    )

    const unit = getTotalVolumeUnit(
      vol,
      product?.medicine_dosage_info,
      medicineDosageInfo,
      task?.medicine_dosage_info,
    )

    return {
      totalVolume: vol,
      totalVolumeUnit: unit,
    }
  }

  const { totalVolume, totalVolumeUnit } = totalPrescribedVolume()
  const totalVolumeWithUnit = `${totalVolume} ${totalVolumeUnit}`

  const convertedWeightToKilos =
    patientWeightAtTimeOfTreatmentCreation && patientWeightUnit === 'lb'
      ? round(
          (patientWeightAtTimeOfTreatmentCreation * weightFactor.lb) /
            weightFactor.kg,
        )
      : null

  const roa = isCRI ? 'CRI' : medicineDosageInfo?.route_of_administration

  const isEmpty =
    !isMedication &&
    !roa &&
    !treatment?.instructions &&
    !isFluidStyleTaskDisplay
  if (isEmpty) {
    return null
  }

  return (
    <View
      style={[
        styles.treatmentDetailsContainer,
        {
          padding: isNativeStyle ? 8 : 12,
          width: isFluidStyleTaskDisplay ? 'auto' : '100%',
          marginTop: Platform.OS === 'web' ? 8 : 0,
          minWidth: '60%',
        },
        headStyleForCubex,
      ]}
    >
      <DilutionHeader medicineDosageInfo={medicineDosageInfo} />
      {isMedication ? (
        <View style={styles.treatmentValueContainer}>
          <View
            style={[
              styles.treatmentValue,
              isNativeStyle && styles.treatmentValueMob,
            ]}
          >
            <Text style={styles.subLabelText}>
              {t('task:taskAddEdit:value')}
            </Text>
            <Text
              style={[
                styles.labelText,
                styles.treatmentValueLabel,
                isNativeStyle && styles.labelTextMob,
              ]}
              testID="Dose Rate"
            >
              {getFormattedNumber(prescribedDosage, round)}{' '}
              {prescribedDosageUnitPerWeight}
            </Text>
            {!isNil(infusionRateTotal) && (
              <Text style={styles.secondaryText}>
                {`${infusionRateTotal} ml/hr`}
              </Text>
            )}
          </View>
          <View
            style={[
              styles.treatmentValue,
              isNativeStyle && styles.treatmentValueMob,
            ]}
          >
            <Text style={[styles.subLabelText]}>
              {t('task:taskAddEdit:patientWeight')}
            </Text>
            <Text
              style={[
                styles.labelText,
                styles.treatmentValueLabel,
                isNativeStyle && styles.labelTextMob,
              ]}
            >
              {`${getFormattedNumber(
                patientWeightAtTimeOfTreatmentCreation,
                round,
              )} ${patientWeightUnit}`}
            </Text>
            {!isNil(convertedWeightToKilos) && (
              <Text style={styles.secondaryText}>
                {convertedWeightToKilos}kg
              </Text>
            )}
          </View>
          <View
            style={[
              styles.treatmentValue,
              isNativeStyle && styles.treatmentValueMob,
            ]}
          >
            <Text style={styles.subLabelText}>
              {t(
                isCRI
                  ? 'task:taskAddEdit:totalVolume'
                  : 'task:taskAddEdit:totalDosage',
              )}
            </Text>
            <Text
              style={[
                styles.labelText,
                styles.treatmentValueLabel,
                isNativeStyle && styles.labelTextMob,
              ]}
            >
              {isCRI
                ? `${totalVolumeWithUnit}`
                : `${totalPrescribedDosage} ${totalDosageWeightUnit}`}
            </Text>
          </View>
          {!isCRI && !isNil(totalVolume) && (
            <View
              style={[
                styles.treatmentValue,
                isNativeStyle && styles.treatmentValueMob,
              ]}
            >
              <Text style={styles.subLabelText}>
                {t('task:taskAddEdit:totalVolume')}
              </Text>
              <Text
                style={[
                  styles.labelText,
                  styles.treatmentValueLabel,
                  isNativeStyle && styles.labelTextMob,
                ]}
              >
                {totalVolumeWithUnit}
              </Text>
            </View>
          )}
        </View>
      ) : null}
      {isFluid ? (
        <View style={styles.treatmentValue}>
          <Text style={styles.subLabelText}>
            {t('task:taskAddEdit.infusionRateTotal')}
          </Text>
          <Text
            style={[
              styles.labelText,
              styles.treatmentValueLabel,
              isNativeStyle && styles.labelTextMob,
            ]}
          >
            {getFluidOrCRITreatmentValue(treatment)}
          </Text>
        </View>
      ) : null}
      {!!roa && (
        <View
          style={[
            styles.roaContainer,
            !!treatment?.instructions && styles.roaBorder,
          ]}
        >
          <Text>{`${t('addTreatment:routeOfAdministration')}:`}</Text>
          <Text style={styles.roaValue}>{roa}</Text>
        </View>
      )}

      {!!treatment?.instructions && (
        <View style={styles.instructionsContainer}>
          {!!treatment?.is_instructions_important && (
            <View style={styles.flag}>
              <SvgFlag size={13} />
            </View>
          )}
          <TruncatedText
            textToTruncate={treatment.instructions}
            style={styles.instructionsText}
          />
        </View>
      )}
    </View>
  )
}

export const TaskDialogHeader: React.FC<Props> = ({
  treatment,
  children,
  showHeader = true,
  patientId,
  task,
  headStyleForCubex,
}) => {
  const hasConditionalFields = !!treatment?.conditional
  return (
    <View style={styles.treatmentNameContainer} testID="TaskDialogHeader">
      {children}
      {showHeader && !hasConditionalFields ? (
        <InfoHeader
          treatment={treatment}
          patientId={patientId}
          task={task}
          headStyleForCubex={headStyleForCubex}
        />
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  treatmentNameContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: 8,
    paddingTop: Platform.OS === 'web' ? 0 : 24,
    width: 'auto',
    height: 'auto',
  },
  treatmentDetailsContainer: {
    backgroundColor: Colors.white,
    borderRadius: 8,
  },
  treatmentValueContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: 'auto',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    paddingBottom: 5,
  },
  treatmentValue: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  treatmentValueMob: {
    maxWidth: '33%',
    padding: 12,
  },
  treatmentValueLabel: {
    paddingVertical: 4,
    textAlign: 'center',
  },
  labelText: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 28,
  },
  labelTextMob: {
    fontSize: 18,
    lineHeight: 20,
  },
  subLabelText: {
    color: Colors.contentTertiary,
    flexWrap: 'wrap',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 'auto',
  },
  secondaryText: {
    fontSize: 13,
  },
  roaContainer: {
    flexDirection: 'row',
    paddingVertical: 8,
  },
  roaValue: {
    paddingLeft: 8,
  },
  roaBorder: {
    borderBottomColor: Colors.borderGrey,
    borderBottomWidth: 1,
  },
  instructionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '95%',
  },
  instructionsText: {
    fontWeight: 'bold',
    paddingTop: 16,
  },
  flag: {
    marginRight: 4,
  },
})
