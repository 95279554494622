import { useLocalToggle } from 'components/Sheet/useLocalToggle'
import { local_toggle_type } from 'constants/LocalToggleOptions'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { UnreachableCaseError } from 'src/utils/unreachableCaseError'

export enum PatientPanelDrawerActionTypes {
  setShouldShowDrawer = 'setShouldShowDrawer',
}

export type PatientPanelDrawerContext = {
  shouldShowDrawer: boolean
}

export type PatientPanelDrawerAction = {
  type: PatientPanelDrawerActionTypes.setShouldShowDrawer
  shouldShowDrawer: PatientPanelDrawerContext['shouldShowDrawer']
}

export const getInitialState = (
  overrides?: Partial<PatientPanelDrawerContext>,
): PatientPanelDrawerContext => ({
  shouldShowDrawer: true,
  ...overrides,
})

export type PatientPanelDrawerDispatch = (
  action: PatientPanelDrawerAction,
) => void

const PatientPanelDrawerContext = createContext<
  PatientPanelDrawerContext | undefined
>(undefined)
PatientPanelDrawerContext.displayName = 'PatientPanelDrawerContext'

const PatientPanelDrawerDispatchContext = createContext<
  PatientPanelDrawerDispatch | undefined
>(undefined)
PatientPanelDrawerDispatchContext.displayName =
  'PatientPanelDrawerDispatchContext'

const patientPanelDrawerReducer = (
  state: PatientPanelDrawerContext,
  action: PatientPanelDrawerAction,
): PatientPanelDrawerContext => {
  switch (action.type) {
    case PatientPanelDrawerActionTypes.setShouldShowDrawer: {
      return { ...state, shouldShowDrawer: action.shouldShowDrawer }
    }
    default: {
      throw new UnreachableCaseError(action as never)
    }
  }
}

export const usePatientPanelDrawerState = () => {
  const context = useContext(PatientPanelDrawerContext)
  if (context === undefined) {
    throw new Error(
      'usePatientPanelDrawerState must be used within a PatientPanelDrawerProvider',
    )
  }
  return context
}

export const usePatientPanelDrawerDispatch = () => {
  const context = useContext(PatientPanelDrawerDispatchContext)
  if (context === undefined) {
    throw new Error(
      'usePatientPanelDrawerDispatch must be used within a PatientPanelDrawerProvider',
    )
  }
  return context
}

/* Temporarily toggle the state, returning it to previously set value afterwards. */
export const useTogglePatientHeaderDrawer = (
  toggle: boolean,
  shouldShowDrawerDefault?: boolean, // Change this to the state you want to toggle to, or leave blank to always pick the opposite of current state.
) => {
  const [{ shouldShowDrawer }, dispatch] = usePatientPanelDrawerContext()
  useEffect(() => {
    if (toggle) {
      dispatch({
        type: PatientPanelDrawerActionTypes.setShouldShowDrawer,
        shouldShowDrawer: shouldShowDrawerDefault ?? !shouldShowDrawer,
      })
      return () =>
        dispatch({
          type: PatientPanelDrawerActionTypes.setShouldShowDrawer,
          shouldShowDrawer,
        })
    }
    return
    // Intentionally don't update on Drawer state updating
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggle])
}

type ProviderProps = {
  initialState?: Partial<PatientPanelDrawerContext>
  children: React.ReactElement
}
export const PatientPanelDrawerProvider: React.FC<ProviderProps> = ({
  children,
  initialState,
}) => {
  const [isShowDrawer] = useLocalToggle({
    prefix: null,
    type: local_toggle_type.SHOWDRAWER,
  })
  const [state, dispatch] = useReducer(
    patientPanelDrawerReducer,
    getInitialState(initialState),
  )

  useEffect(() => {
    dispatch({
      type: PatientPanelDrawerActionTypes.setShouldShowDrawer,
      shouldShowDrawer: isShowDrawer,
    })
  }, [isShowDrawer])

  return (
    <PatientPanelDrawerContext.Provider value={state}>
      <PatientPanelDrawerDispatchContext.Provider value={dispatch}>
        {children}
      </PatientPanelDrawerDispatchContext.Provider>
    </PatientPanelDrawerContext.Provider>
  )
}

type UsePatientPanelDrawer = () => [
  PatientPanelDrawerContext,
  PatientPanelDrawerDispatch,
]
export const usePatientPanelDrawerContext: UsePatientPanelDrawer = () => {
  return [usePatientPanelDrawerState(), usePatientPanelDrawerDispatch()]
}
