import React from 'react'
import {
  StyleSheet,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'src/constants/Fonts'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'

type Props = {
  icon: React.FunctionComponent<{ color: string }>
  isActive?: boolean
  onPress?: (ev: GestureResponderEvent) => void
  title: string
}

export const FooterNavButton: React.FunctionComponent<Props> = ({
  icon,
  isActive = false,
  onPress,
  title,
}) => {
  const color = isActive ? Colors.overDue : Colors.optimisticGrey

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      onPress={onPress}
      style={styles.button}
      testID={`IconButton-${title}`}
    >
      {React.createElement(icon, { color })}
      <TextWithTooltip
        ellipsizeMode="middle"
        numberOfLines={1}
        style={[styles.buttonText, { color }]}
        title={title}
      >
        {title}
      </TextWithTooltip>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  buttonText: {
    fontFamily: Fonts.regular,
    fontSize: 13.6,
    marginTop: 2,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 4,
  },
})
