import { Task } from 'components/Task/types'
import { groupBy, isNil } from 'lodash'
import { useOrganisation } from 'src/context/organisation'
import {
  CreateTaskInput,
  Status,
  TreatmentTaskType,
} from 'src/types/globalTypes'
import { getOptimisticId } from 'src/utils/optimisticId'

import { generateTaskInput } from '../BulkTaskActions'
import { BulkTreatmentsKeyedById } from '../BulkTaskCreate'
import { getCheckedTreatments } from './getCheckedTreatments'
import { useBulkCreateTasksMutation } from './useBulkCreateTasksMutation'
import { TaskAndInput, useBulkUpdateTasks } from './useBulkUpdateTasks'

export type NewTaskProps = {
  startAt: string
  stopAt: string
  sheetId: string
  patientId: string
}

export const useBulkCreateTasks = (values: BulkTreatmentsKeyedById) => {
  const [{ organisationId }] = useOrganisation()

  const checkedTreatments = getCheckedTreatments(values)

  const bulkCreateTasks = useBulkCreateTasksMutation()

  const bulkUpdateTasks = useBulkUpdateTasks()

  const createBulkTasks = (newTaskProps: NewTaskProps) => {
    if (checkedTreatments.length === 0) {
      return
    }
    const { startAt, stopAt, sheetId, patientId } = newTaskProps

    const createTaskInputs: CreateTaskInput[] = []
    const optimisticTasks: Task[] = []

    checkedTreatments.forEach(treatment => {
      const optimisticTask = {
        _pending: true,
        value: null,
        unit: null,
        id: getOptimisticId(),
        organisation_id: organisationId,
        sheet_id: sheetId,
        patient_id: patientId,
        treatment_id: treatment.id,
        status: Status.PENDING,
        start_at: startAt,
        stop_at: stopAt,
        send_to_adaptor: null,
        qty_billed: treatment.unitsBilledPerTask ?? 1,
        highlight_colour: null,
        buffer_due: null,
        buffer_missed: null,
        given_start_at: '',
        given_stop_at: '',
        notes: treatment.notes,
        assigned_user: '',

        updated_at: null,
        user_updated_at: null,
        updated_by: '',
        created_at: '',
        attending_vet: null,
        attending_department: null,
        medicine_dosage_info: null,
        billing_reference: null,
        type: TreatmentTaskType.NORMAL,
        __typename: 'Task',
        photo_urls: null,
      } as Task

      optimisticTasks.push(optimisticTask)
      createTaskInputs.push({
        organisation_id: organisationId,
        patient_id: patientId,
        sheet_id: sheetId,
        treatment_id: treatment.id,
        start_at: startAt,
        stop_at: stopAt,
      })
    })

    return bulkCreateTasks({ createTaskInputs, optimisticTasks, sheetId })
  }

  const createAndCompleteBulkTasks = async (newTaskProps: NewTaskProps) => {
    if (checkedTreatments.length === 0) {
      return
    }
    const createBulkTasksResponse = await createBulkTasks(newTaskProps)

    const responseTasks = createBulkTasksResponse?.data?.bulkCreateTasks

    if (!responseTasks) {
      return
    }

    const groupedTreatmentsAndValues = groupBy(
      checkedTreatments,
      checkedTreatment => checkedTreatment.id,
    )

    const taskAndInputArray: TaskAndInput[] = []

    responseTasks.forEach(task => {
      // wont have 2 task for each treatment
      const taskValue = groupedTreatmentsAndValues[task.treatment_id]?.[0]
      if (!taskValue) {
        return
      }
      const taskInput = generateTaskInput(
        {
          ...task,
          ...(!isNil(taskValue.isBillable) && {
            send_to_adaptor: {
              is_billable: taskValue.isBillable,
              __typename: 'SendToAdaptor',
            },
          }),
          ...(taskValue.value && {
            value: taskValue.value,
          }),
          ...(taskValue.notes && {
            notes: taskValue.notes,
          }),
        },
        Status.DONE,
      )
      taskAndInputArray.push({ taskInput, task })
    })

    bulkUpdateTasks(taskAndInputArray)
  }
  return { createBulkTasks, createAndCompleteBulkTasks }
}
