import { isEqual } from 'date-fns'
import { useState, useEffect } from 'react'
import { useSheetContext } from 'src/context/sheet'
import { getStartTimeBySheetContext } from './util/getStartTimeBySheetContext'

const THIRTY_SECONDS = 1000 * 30

export const useStartTimeRefresh = () => {
  const [sheetContext] = useSheetContext()
  const [currentTime, setCurrentTime] = useState(
    getStartTimeBySheetContext(sheetContext),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedCurrentTime = getStartTimeBySheetContext(sheetContext)
      if (!isEqual(currentTime, updatedCurrentTime)) {
        setCurrentTime(updatedCurrentTime)
      }
    }, THIRTY_SECONDS)

    return () => clearInterval(interval)
  }, [currentTime, sheetContext])

  return currentTime
}
