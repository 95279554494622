import React, { createContext, useContext, useReducer } from 'react'

export enum TreatmentDrawerActionTypes {
  setShouldShowTabs = 'setShouldShowTabs',
  setHandleBack = 'setHandleBack',
}

export type TreatmentDrawerContext = {
  shouldShowTabs: boolean
  handleBack: (() => void) | null
}

export type TreatmentDrawerAction =
  | {
      type: TreatmentDrawerActionTypes.setShouldShowTabs
      setShouldShowTabs: TreatmentDrawerContext['shouldShowTabs']
    }
  | {
      type: TreatmentDrawerActionTypes.setHandleBack
      setHandleBack: TreatmentDrawerContext['handleBack']
    }

export const getInitialState = (
  overrides?: Partial<TreatmentDrawerContext>,
): TreatmentDrawerContext => ({
  shouldShowTabs: true,
  handleBack: null,
  ...overrides,
})

export type TreatmentDrawerDispatch = (action: TreatmentDrawerAction) => void

const TreatmentDrawerContext = createContext<
  TreatmentDrawerContext | undefined
>(undefined)
TreatmentDrawerContext.displayName = 'TreatmentDrawerContext'

const TreatmentDrawerDispatchContext = createContext<
  TreatmentDrawerDispatch | undefined
>(undefined)
TreatmentDrawerDispatchContext.displayName = 'TreatmentDrawerDispatchContext'

const treatmentDrawerReducer = (
  state: TreatmentDrawerContext,
  action: TreatmentDrawerAction,
): TreatmentDrawerContext => {
  switch (action.type) {
    case TreatmentDrawerActionTypes.setShouldShowTabs: {
      return { ...state, shouldShowTabs: action.setShouldShowTabs }
    }
    case TreatmentDrawerActionTypes.setHandleBack: {
      return { ...state, handleBack: action.setHandleBack }
    }
    default: {
      // @ts-expect-error
      throw new UnreachableCaseError(action)
    }
  }
}

export const useTreatmentDrawerState = () => {
  const context = useContext(TreatmentDrawerContext)
  if (context === undefined) {
    throw new Error(
      'useTreatmentDrawerState must be used within a TreatmentDrawerProvider',
    )
  }
  return context
}

export const useTreatmentDrawerDispatch = () => {
  const context = useContext(TreatmentDrawerDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useTreatmentDrawerDispatch must be used within a TreatmentDrawerProvider',
    )
  }
  return context
}

type ProviderProps = {
  initialState?: Partial<TreatmentDrawerContext>
  children: React.ReactElement
}

export const TreatmentDrawerProvider: React.FC<ProviderProps> = ({
  children,
  initialState,
}) => {
  const [state, dispatch] = useReducer(
    treatmentDrawerReducer,
    getInitialState(initialState),
  )

  return (
    <TreatmentDrawerContext.Provider value={state}>
      <TreatmentDrawerDispatchContext.Provider value={dispatch}>
        {children}
      </TreatmentDrawerDispatchContext.Provider>
    </TreatmentDrawerContext.Provider>
  )
}

type UseTreatmentDrawer = () => [
  TreatmentDrawerContext,
  TreatmentDrawerDispatch,
]
export const useTreatmentDrawerContext: UseTreatmentDrawer = () => {
  return [useTreatmentDrawerState(), useTreatmentDrawerDispatch()]
}
