import { gql } from '@apollo/client'

export const GET_SHEETS = gql`
  query getSheets($id: ID!, $organisationId: ID!) {
    getPatient(id: $id, organisation_id: $organisationId) {
      id
      sheets {
        items {
          id
          name
          type
          order
          closed_at
          attending_vet
        }
      }
    }
  }
`

export const PATIENT_FIELDS_SUMMARY = gql`
  fragment PatientFieldsSummary on Patient {
    id
    color
    critical_notes
    organisation_id
    name
    avatar_url
    code
    sex
    resuscitate
    weight
    weight_unit
    weight_updated_at
    created_at
    attitude
    order
    contact {
      id
      first_name
      last_name
      phone_number
    }
    species {
      name
      origin_id
    }
    primary_location {
      location_display
      enclosure
      location_id
    }
    active_consultations {
      items {
        attending_vet_id
        admitted_at
        description
        id
        presenting_problems {
          id
          name
        }
        site_id
        status
        locations {
          key
          display
          enclosure
        }
        color {
          id
          hex
        }
      }
    }
    breed {
      name
      origin_id
      origin_species_id
    }
    date_of_birth
  }
`

export const PATIENT_FIELDS_FULL = gql`
  fragment PatientFieldsFull on Patient {
    ...PatientFieldsSummary
    microchip_number
    breed {
      name
      origin_id
      origin_species_id
    }
    weight
    weight_unit
    weight_updated_at
    created_at
    notes
    is_dead
    is_hostile
    is_estimated_date_of_birth
    date_of_birth
    animal_colour
    attitude
    order
    master_problems {
      condition_active
      condition_critical
      condition_specifics
      condition
      description
    }
    template_call_parameter_id
    call_parameters {
      name
      min
      max
      short_name
      product_id
    }
  }
  ${PATIENT_FIELDS_SUMMARY}
`

export const WHITEBOARD_PATIENT_FIELDS = gql`
  fragment WhiteboardPatientFields on WhiteboardPatient {
    consultation_id
    patient_id
    consultation_status
    consultation_description
    site_id
    site_location_name
    has_unapproved_sheet
    latest_active_sheet_id
    patient_name
    patient_weight
    patient_weight_unit
    patient_attitude
    patient_sex
    patient_resuscitate
    patient_color
    patient_location_current_key
    patient_location_current_display
    patient_location_primary_key
    patient_location_primary_display
    patient_species {
      name
    }
    patient_breed {
      name
    }
    missed_tasks
    patient_critical_notes
    patient_avatar_url
    patient_date_of_birth
    patient_enclosure
    contact_first_name
    contact_last_name
    contact_phone_number
    patient_order
    attending_vet_id
    attending_vet_display_name
    attending_vet_tech_id
    presenting_problems {
      id
      name
    }
    consultation_locations {
      key
      display
      enclosure
    }
    consultation_color
  }
`

export const LIST_WHITEBOARD_PATIENT = gql`
  query listWhiteboardPatients($input: ListWhiteboardInput!) {
    listWhiteboardPatients(input: $input) {
      pageInfo {
        nextCursor
      }
      items {
        resultCursor @client
        patient {
          ...WhiteboardPatientFields
        }
        due_tasks {
          due_count
          top_sheet_groups {
            sheet_group_name
          }
        }
        missed_tasks {
          missed_count
          top_sheet_groups {
            sheet_group_name
          }
        }
        tasks {
          start_at
          stop_at
          done_count
          pending_count
          top_sheet_groups {
            sheet_group_name
          }
        }
      }
    }
  }
  ${WHITEBOARD_PATIENT_FIELDS}
`

export const GET_PATIENT = gql`
  query getPatient($id: ID!, $organisationId: ID!) {
    getPatient(id: $id, organisation_id: $organisationId) {
      ...PatientFieldsFull
    }
  }
  ${PATIENT_FIELDS_FULL}
`

export const CREATE_PATIENT = gql`
  mutation createPatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      ...PatientFieldsSummary
    }
  }
  ${PATIENT_FIELDS_SUMMARY}
`

export const UPDATE_PATIENT = gql`
  mutation updatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      ...PatientFieldsFull
    }
  }
  ${PATIENT_FIELDS_FULL}
`

export const DISCHARGE_PATIENT = gql`
  mutation dischargePatient(
    $id: String!
    $organisation_id: String!
    $discharge_notes: String
    $locale: String
  ) {
    dischargePatient(
      id: $id
      organisation_id: $organisation_id
      discharge_notes: $discharge_notes
      locale: $locale
    ) {
      id
    }
  }
`

export const SUBSCRIBE_PATIENT = gql`
  subscription patient($organisationId: ID!) {
    patient(organisation_id: $organisationId) {
      mutation
      data {
        ...PatientFieldsFull
      }
    }
  }
  ${PATIENT_FIELDS_FULL}
`

export const SUBSCRIBE_PATIENT_SUMMARY = gql`
  subscription patientSummary($organisationId: ID!) {
    patient(organisation_id: $organisationId) {
      mutation
      data {
        ...PatientFieldsSummary
      }
    }
  }
  ${PATIENT_FIELDS_SUMMARY}
`

export const GET_SPECIES = gql`
  query getSpecies($organisationId: ID!) {
    getSpecies(organisation_id: $organisationId) {
      items {
        id
        name
        origin_id
      }
    }
  }
`

export const GET_BREEDS_BY_SPECIES_ID = gql`
  query getBreedsBySpeciesId($speciesId: ID!, $organisationId: ID!) {
    getBreedsBySpeciesId(
      species_id: $speciesId
      organisation_id: $organisationId
    ) {
      items {
        id
        name
        origin_id
        species_id
        origin_species_id
      }
    }
  }
`

export const GET_LOCATIONS = gql`
  query getLocations($organisationId: ID!, $locationParentId: ID) {
    getLocations(
      organisation_id: $organisationId
      location_parent_id: $locationParentId
    ) {
      items {
        id
        name
      }
    }
  }
`

export const GET_LOCATION = gql`
  query getLocation($id: ID!, $organisationId: ID!) {
    getLocation(id: $id, organisation_id: $organisationId) {
      id
      parent_location_id
    }
  }
`

export const GET_USER_DISPLAY_NAME = gql`
  query getUserDisplayName($id: ID!, $organisationId: String!) {
    getUserDisplayName(id: $id, organisation_id: $organisationId) {
      id
      display_name
    }
  }
`

export const LIST_WHITEBOARD_WORKFLOWS = gql`
  query listWhiteboardWorkflows($input: ListWhiteboardInput!) {
    listWhiteboardWorkflows(input: $input) {
      items {
        resultCursor @client
        patient {
          ...WhiteboardPatientFields
        }
        workflow_items {
          id
          name
          order
          completed_at
        }
      }
      pageInfo {
        nextCursor
      }
    }
  }
  ${WHITEBOARD_PATIENT_FIELDS}
`
