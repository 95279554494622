import React, { useCallback, useEffect, useState } from 'react'
import { addTreatmentButtonHeight } from 'components/AddTreatment/AddTreatmentButton'
import { toast } from 'components/common'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { getIsFluidStyleTaskDisplay } from 'components/Task/utils/getTreatmentInfo'
import { useGetDateFormatPattern } from 'components/Task/utils/useGetStatusLabelText'
import { GridTreatmentTree } from 'components/Treatment/GridTreatmentTree'
import { Colors } from 'constants/Colors'
import { ScaleTime, scaleTime } from 'd3-scale'
import { startOfMinute } from 'date-fns'
import { isEmpty, maxBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Animated, Pressable, StyleSheet, View } from 'react-native'
import { TreatmentChildLevel } from 'src/components/Treatment/common/types'
import { getSegmentsPerOneOrThreeDays, TimeSegment } from 'src/hocs/types/time'
import { useZoomMenu } from 'src/hooks/useZoomMenu'
import { Status } from 'src/types/globalTypes'
import { isOptimisticId } from 'src/utils/optimisticId'
import { getSheet_getSheet } from 'types/getSheet'

import { OnPressTask, OnPressTreatmentGroup } from '../GridFlatList'
import { gridNavHeight } from '../GridNavV2'
import { AnesthesiaDrawer } from './AnesthesiaDrawer'
import { DateArea } from './DateArea'
import { GridBackgroundLines } from './GridBackgroundLine'
import { TaskCreate } from './TaskCreate'
import { TimeArea } from './TimeBox'
import { getDateBoxWidth } from './utils/getDateBoxWidth'
import { useGetStaffedHourTimes } from './utils/useGetStaffedHourTimes'

type Props = {
  sheet?: getSheet_getSheet | null
  gridVerticalScroll?: Animated.Value
  patientId: string
  foldedTreatments: string[]
  isFinalized: boolean
  shouldShowFixedDateHeader?: boolean
  onPressTask: OnPressTask
  onPressTreatmentGroup: OnPressTreatmentGroup
  scrollHeight?: number
  showAnesthesiaChart?: boolean
  showTimeNow: Date | false
  timeEnd: Date
  timeSegment: TimeSegment
  timeStart: Date
  treatments: TreatmentChildLevel[]
  numberOfDays: number
  onLoadTimeInChartFunc?: (timeInChart: {
    timeInChart: (time: Date) => void
  }) => void
}

export const treatmentColumnHeaderHeight =
  addTreatmentButtonHeight + gridNavHeight
export type ScaleTimeFn = ScaleTime<number, number>

const TimeNowLine = React.memo(({ position }: { position: number }) => (
  <View style={[styles.timeNowLine, { left: position }]} />
))

const TimeNowDownArrow = React.memo(({ position }: { position: number }) => {
  const ARROW_OFFSET = -9
  return (
    <>
      <View
        style={[styles.timeNowLine, styles.timeNowMiniLine, { left: position }]}
      />
      <View
        style={[styles.timeNowDownArrow, { left: position + ARROW_OFFSET }]}
      />
    </>
  )
})

export const isAllowedToOpenDrawer = (
  time: Date,
  treatment: TreatmentChildLevel,
) => {
  const isNoTask = isEmpty(treatment.tasks?.items)
  const isFluidStyleTaskDisplay = getIsFluidStyleTaskDisplay(treatment)
  if (isNoTask || !isFluidStyleTaskDisplay) {
    return true
  }

  const isAllDoneOrDeleted =
    treatment.tasks?.items &&
    treatment.tasks.items.every(
      task =>
        task.status === Status.DONE ||
        task.status === Status.DELETED_ON_PURPOSE,
    )

  // to enable click when fluid task not done
  if (!isAllDoneOrDeleted) {
    return true
  }

  const nearestTask = maxBy(treatment?.tasks?.items, task => task.given_stop_at)

  const isGivenTimeBeforeNearestTask =
    nearestTask?.given_stop_at && time < new Date(nearestTask.given_stop_at)

  // if given time is before nearest task, then don't allow to open drawer, vise versa.
  return !isGivenTimeBeforeNearestTask
}

export const GridTimeline: React.FC<Props> = React.memo(
  ({
    foldedTreatments,
    gridVerticalScroll,
    isFinalized,
    shouldShowFixedDateHeader,
    numberOfDays,
    onLoadTimeInChartFunc,
    onPressTask,
    onPressTreatmentGroup,
    patientId,
    scrollHeight,
    sheet,
    showAnesthesiaChart = false,
    showTimeNow,
    timeEnd,
    timeSegment = TimeSegment.hourly,
    timeStart,
    treatments,
  }) => {
    const { t } = useTranslation()
    const { dateFormatPattern } = useGetDateFormatPattern()
    const { buttonActionCb } = useZoomMenu(sheet)
    const [isTaskDrawerVisible, setTaskDrawerVisible] = useState(false)
    const [isAnesthesiaDrawerVisible, setAnesthesiaDrawerVisible] =
      useState(false)
    const [taskDialogTime, setTaskDialogTime] = useState<Date | null>(null)
    const [taskDialogTreatment, setTaskDialogTreatment] =
      useState<TreatmentChildLevel | null>(null)

    useEffect(() => {
      if (onLoadTimeInChartFunc) {
        onLoadTimeInChartFunc({ timeInChart: updateTimeInChart })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const drawerTitle = t('task:taskAddEdit:createTask', {
      taskName: taskDialogTreatment?.name ?? '',
    })

    const segmentsInDay = getSegmentsPerOneOrThreeDays(
      timeSegment,
      numberOfDays,
    )
    const segmentsPerDay = segmentsInDay / numberOfDays
    const timeBoxWidth = getDateBoxWidth(timeSegment, numberOfDays)
    const fullGridWidth = segmentsInDay * timeBoxWidth

    const timesWithIsStaffedHour = useGetStaffedHourTimes({
      segmentsInDay,
      timeStart,
      timeSegment,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const positionInGridFn: ScaleTimeFn = useCallback(
      scaleTime().domain([timeStart, timeEnd]).range([0, fullGridWidth]),
      [timeStart, timeEnd, fullGridWidth],
    )

    const toggleTaskDrawer = useCallback(() => {
      setTaskDrawerVisible(value => !value)
    }, [])

    const toggleAnesthesiaDrawer = useCallback(() => {
      setAnesthesiaDrawerVisible(value => !value)
    }, [])

    const getIsAllowedToOpenDrawer = useCallback(
      (time: Date, treatment: TreatmentChildLevel) =>
        isAllowedToOpenDrawer(time, treatment),
      [],
    )

    const timeInGridFn = useCallback(
      (x: number, treatment: TreatmentChildLevel) => {
        if (isOptimisticId(treatment.id)) return
        const time = positionInGridFn.invert(x)
        // When clicking empty grid on Fluids Row, toss error
        if (!getIsAllowedToOpenDrawer(time, treatment)) {
          toast.notice(t('task:taskAddEdit:fluids:startFluidError'))
          return
        }
        setTaskDialogTime(startOfMinute(time))
        setTaskDialogTreatment(treatment)
        toggleTaskDrawer()
      },
      [getIsAllowedToOpenDrawer, positionInGridFn, t, toggleTaskDrawer],
    )

    const updateTimeInChart = useCallback(
      (time: Date) => {
        if (isFinalized) {
          toast.error('The sheet has been finalized')
          return
        }
        setTaskDialogTime(startOfMinute(time))
        toggleAnesthesiaDrawer()
      },
      [isFinalized, toggleAnesthesiaDrawer],
    )

    const timeInChartFn = useCallback(
      (x: number) => {
        updateTimeInChart(positionInGridFn.invert(x))
      },
      [positionInGridFn, updateTimeInChart],
    )

    return (
      <>
        <View
          style={[styles.wrapper, { width: fullGridWidth }]}
          accessibilityLabel="Grid Timeline"
        >
          <GridBackgroundLines
            timesWithIsStaffedHour={timesWithIsStaffedHour}
            positionInGridFn={positionInGridFn}
            timeBoxWidth={timeBoxWidth}
            scrollHeight={scrollHeight}
          />
          <Animated.View
            testID={'grid-timeline-header'}
            style={[
              styles.timelineHeader,
              {
                // For unknown reason height must be in object for value to persist
                height: addTreatmentButtonHeight,
                transform: [{ translateY: gridVerticalScroll ?? 0 }],
              },
            ]}
          >
            <>
              {showTimeNow ? (
                <TimeNowDownArrow
                  position={positionInGridFn(showTimeNow) ?? 0}
                />
              ) : null}
              <DateArea
                numberOfDays={numberOfDays}
                segmentsPerDay={segmentsPerDay}
                shouldShowFixedDateHeader={shouldShowFixedDateHeader}
                timeBoxWidth={timeBoxWidth}
                timeStart={timeStart}
              />
              <Pressable onPress={buttonActionCb}>
                <TimeArea
                  positionInGridFn={positionInGridFn}
                  segmentsInDay={segmentsInDay}
                  timeBoxWidth={timeBoxWidth}
                  timesWithIsStaffedHour={timesWithIsStaffedHour}
                />
              </Pressable>
            </>
          </Animated.View>
          <GridTreatmentTree
            foldedTreatments={foldedTreatments}
            isFinalized={isFinalized}
            onPressTask={onPressTask}
            onPressTreatmentGroup={onPressTreatmentGroup}
            positionInGridFn={positionInGridFn}
            showAnesthesiaChart={showAnesthesiaChart}
            timeInGridFn={timeInGridFn}
            treatments={treatments}
            timeInChart={timeInChartFn}
          />
          {showTimeNow ? (
            <TimeNowLine position={positionInGridFn(showTimeNow) ?? 0} />
          ) : null}
        </View>
        {!!taskDialogTime && (
          <AnesthesiaDrawer
            visible={isAnesthesiaDrawerVisible}
            onClose={toggleAnesthesiaDrawer}
            selectedDate={taskDialogTime}
            treatments={treatments}
            timeSegment={timeSegment}
            sheetId={sheet?.id ?? ''}
            patientId={patientId}
          />
        )}
        {!!taskDialogTime && (
          <SheetAwareSideDrawer
            visible={isTaskDrawerVisible}
            onClose={toggleTaskDrawer}
            title={drawerTitle}
          >
            <TaskCreate
              patientId={patientId}
              sheetId={sheet?.id ?? ''}
              treatment={taskDialogTreatment}
              timeInGrid={taskDialogTime}
              toggleDialog={toggleTaskDrawer}
              timeSegment={timeSegment}
              dateFormatPattern={dateFormatPattern}
            />
          </SheetAwareSideDrawer>
        )}
      </>
    )
  },
)

GridTimeline.displayName = 'GridTimeline'

export const styles = StyleSheet.create({
  timelineHeader: {
    zIndex: 1,
  },
  timeNowDownArrow: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderTopColor: Colors.blue,
    borderTopWidth: 10,
    height: 0,
    position: 'absolute',
    top: 23,
    width: 0,
    zIndex: 2,
  },
  timeNowLine: {
    borderColor: Colors.blue,
    borderWidth: 1,
    height: '100%',
    position: 'absolute',
    top: 23,
    width: 1,
  },
  timeNowMiniLine: {
    zIndex: 2,
  },
  wrapper: {
    flex: 1,
    backgroundColor: Colors.white,
  },
})
