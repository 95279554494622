import { isAfter, parseISO } from 'date-fns'
import { minBy } from 'lodash'
import { getSheet_getSheet_treatments_items_treatments_items_tasks_items as Task } from 'src/types/getSheet'
import { Status } from 'src/types/globalTypes'

export const getNearestTaskStartAfterNow = (allTasks: Task[]) => {
  const now = new Date()
  const afterTasks = allTasks.filter(item => {
    if (!item.stop_at) return false
    if (item.status !== Status.DUE && item.status !== Status.PENDING) {
      return false
    }
    const startAt = parseISO(item?.stop_at)
    return isAfter(startAt, now)
  })

  return minBy(afterTasks, task => {
    if (!task.start_at) return
    return parseISO(task.start_at)
  })?.start_at
}
