import { useQuery } from '@apollo/client'
import { toast } from 'src/components/common'
import { useOrganisation } from 'src/context/organisation'

import {
  getTemplateWorkflows,
  getTemplateWorkflowsVariables,
} from 'src/types/getTemplateWorkflows'
import { GET_WORKFLOW_TEMPLATES } from 'components/WorkflowTemplate/graphql'

export const useGetWorkflowTemplates = () => {
  const [{ organisationId }] = useOrganisation()

  const { loading: isLoadingTemplates, data: templatesData } = useQuery<
    getTemplateWorkflows,
    getTemplateWorkflowsVariables
  >(GET_WORKFLOW_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisation_id: organisationId,
    },
    onError: err => {
      toast.error(err.message)
    },
  })
  const workflowTemplatesList =
    templatesData?.getTemplateWorkflows?.items?.filter(
      item => !item.disabled,
    ) ?? []

  return {
    workflowTemplatesList,
    isLoadingTemplates,
  }
}
