import { isWithinInterval, parseISO } from 'date-fns'
import { Status as StatusEnum } from 'types/globalTypes'

import { Action, FluidAction, Task } from '../types'

/*
  No flag to indicate whether a task has been backdated so checking
  whether the actual time of administration falls within the intended
  window of opportunity
  We also pass through our startTime which is determined by SheetType
  TODO: Flag whether the task has been backdated in platform Task type
*/
export const getTaskIsBackdated = (
  startTime: Date,
  task?: Task,
  action?: Action | FluidAction,
) => {
  const noBackdate = {
    backdate: false,
    datetimeGiven: startTime,
  }
  if (!task) return noBackdate
  if (action === FluidAction.STOP) return noBackdate
  if (task.status === StatusEnum.MISSED_ON_PURPOSE) return noBackdate
  if (!task.given_start_at || !task.start_at || !task.stop_at) return noBackdate

  if (
    !isWithinInterval(parseISO(task.given_start_at), {
      start: parseISO(task.start_at),
      end: parseISO(task.stop_at),
    })
  ) {
    return {
      backdate: true,
      datetimeGiven: task.given_start_at
        ? parseISO(task.given_start_at)
        : startTime,
    }
  }

  return noBackdate
}
