/* eslint-disable no-console */
import { ApolloQueryResult, useSubscription } from '@apollo/client'
import {
  getConsultation,
  getConsultationVariables,
} from 'src/types/getConsultation'

import { SUBSCRIBE_WORKFLOW } from './graphql'

type Refetch = (
  variables?: Partial<getConsultationVariables> | undefined,
) => Promise<ApolloQueryResult<getConsultation>>

export const useWorkflowSubscription = (
  refetch: Refetch,
  consultationId?: string,
) => {
  // start workflow subscription if there is a consult id
  // this has moved from the carousel because of VR-7450
  useSubscription(SUBSCRIBE_WORKFLOW, {
    variables: { consultationId },
    skip: !consultationId,
    onData: ({ data }) => {
      const workflowSubscription = data?.data?.workflow

      if (workflowSubscription?.mutation === 'CREATE') {
        refetch()
        return
      }
    },
  })
}
