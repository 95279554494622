import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { Colors } from 'constants/Colors'
import SheetHistoryModalRow from 'components/UserHistory/SheetHistory/SheetHistoryModalRow'
import { formatUserHistory } from 'components/UserHistory/utils'
import type { getSheetHistory_getSheetHistory as SheetHistory } from 'src/types/getSheetHistory'
import { useQuery } from '@apollo/client'
import { GET_BULK_TASK_COUNT_BY_SHEET } from '../graphql'
import {
  getBulkTaskCountBySheets,
  getBulkTaskCountBySheetsVariables,
  getBulkTaskCountBySheets_getBulkTaskCountBySheets_items_count as StatusCount,
} from 'src/types/getBulkTaskCountBySheets'

const MAX_VISIBLE_ROWS = 10
const ROW_HEIGHT = 60

type SheetHistoryBodyProps = {
  data: SheetHistory[] | null
  organisationId: string
  onClose: () => void
}

type TaskCountAccumulator = {
  [sheetId: string]: StatusCount
}

export const SheetHistoryBody: React.FC<SheetHistoryBodyProps> = ({
  data,
  organisationId,
  onClose,
}) => {
  const { data: taskCountQuery } = useQuery<
    getBulkTaskCountBySheets,
    getBulkTaskCountBySheetsVariables
  >(GET_BULK_TASK_COUNT_BY_SHEET, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisation_id: organisationId,
      sheet_ids: data?.map(sheet => sheet.id) ?? [],
    },
  })

  const taskCountMap = useMemo(() => {
    const tasksCounts = taskCountQuery?.getBulkTaskCountBySheets?.items ?? []
    return tasksCounts.reduce((acc, taskCount) => {
      if (taskCount) {
        acc[taskCount.sheet_id] = taskCount.count
      }
      return acc
    }, {} as TaskCountAccumulator)
  }, [taskCountQuery?.getBulkTaskCountBySheets?.items])

  return (
    <View testID={'sheet-history-body'} style={styles.bodyContainer}>
      {data
        ? data.map(sheet => {
            const {
              sheetId,
              patientId,
              patientName,
              contactLastName,
              sheetName,
              patientImage,
              studentApproval,
              patientColor,
            } = formatUserHistory(sheet)
            return (
              <SheetHistoryModalRow
                key={sheetId}
                sheetId={sheetId}
                patientId={patientId}
                patientName={patientName}
                contactLastName={contactLastName}
                sheetName={sheetName}
                patientImage={patientImage}
                studentApproval={studentApproval}
                patientColor={patientColor}
                dueTasks={taskCountMap[sheetId]?.DUE ?? 0}
                missedTasks={taskCountMap[sheetId]?.MISSED ?? 0}
                onClose={onClose}
              />
            )
          })
        : null}
    </View>
  )
}

const styles = StyleSheet.create({
  bodyContainer: {
    minHeight: ROW_HEIGHT,
    maxHeight: ROW_HEIGHT * MAX_VISIBLE_ROWS,
    backgroundColor: Colors.white,
  },
})
