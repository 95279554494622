import React from 'react'
import { Chart as AnesthesiaChartGraph } from 'components/Anesthesia/AnesthesiaChart/Chart'

import { foldedTreatmentsDefault } from './common'
import { GridTreatmentTreeProps, TreatmentChildLevel } from './common/types'
import { GridTreatmentGroup } from './GridTreatmentGroup'

export const GridTreatmentTree: React.FC<GridTreatmentTreeProps> = React.memo(
  ({
    foldedTreatments = foldedTreatmentsDefault,
    isFinalized,
    onPressTask,
    onPressTreatmentGroup,
    positionInGridFn,
    showAnesthesiaChart = false,
    timeInGridFn,
    treatments,
    timeInChart,
  }) => {
    return (
      <>
        {showAnesthesiaChart ? (
          <AnesthesiaChartGraph timeInChart={timeInChart} />
        ) : null}
        {treatments.map((treatment: TreatmentChildLevel) => (
          <GridTreatmentGroup
            foldedTreatments={foldedTreatments}
            isFinalized={isFinalized}
            isFolded={foldedTreatments.includes(treatment.id)}
            key={treatment.id}
            onPressTask={onPressTask}
            onPressTreatmentGroup={onPressTreatmentGroup}
            positionInGridFn={positionInGridFn}
            timeInGridFn={timeInGridFn}
            treatment={treatment}
          />
        ))}
      </>
    )
  },
)

GridTreatmentTree.displayName = 'GridTreatmentTree'
