import { format, isValid } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useOrgSettings } from 'src/hooks/useOrgSettings'

export const useAdminTimeFormat = () => {
  const { settingsMap } = useOrgSettings()
  const adminTimeFormat = settingsMap?.PREFERRED_TIME_FORMAT?.value ?? 'HH:mm'
  const adminDateFormat =
    settingsMap?.PREFERRED_DATE_FORMAT?.value ?? 'MMM do yyyy'
  const fullAdminDateFormat = `${adminDateFormat} ${adminTimeFormat}`

  const defaultFormatMap = useMemo(
    () => ({
      daymonth: `d MMM`, // 29 Jan
      weekdaymonth: `EEEE d, MMM`, // Friday 29, Jan
      weekdaymonthyear: `EEEE d, MMMM yyyy`, // Friday 29, January 2021
      timeweekdaymonthyear: `${adminTimeFormat} EEEE d, MMMM yyyy`, // {1:55 PM | 13:55 } Friday 29, January 2021
      datetime: fullAdminDateFormat,
      date: adminDateFormat,
      time: adminTimeFormat,
      monthdaytime: `MMM do ${adminTimeFormat}`,
    }),
    [adminDateFormat, adminTimeFormat, fullAdminDateFormat],
  )

  const dateFormatter = useCallback(
    (date: Date, mode: keyof typeof defaultFormatMap = 'datetime') => {
      if (isValid(date)) {
        return format(date, defaultFormatMap[mode])
      }
      return ''
    },
    [defaultFormatMap],
  )

  return {
    adminTimeFormat,
    adminDateFormat,
    fullAdminDateFormat,
    dateFormatter,
  }
}
