import { Task } from 'components/Task/types'
import { TreatmentChildLevel } from 'components/Treatment/common/types'
import React, { useEffect, useMemo, useState } from 'react'
import { CubexTransactionStatus } from 'src/types/globalTypes'

import { CUBEX_BANNER_STATUS, CubexTopBanner } from './CubexTopBanner'
import { useSheetContext } from 'src/context/sheet'
import { useSeparationSites } from 'src/hooks/useSeparationSites'
import { useQuery } from '@apollo/client'
import { GET_CUBEX_CABINETS } from './graphql'
import {
  getCubexCabinetsCabinetIds,
  getCubexCabinetsCabinetIdsVariables,
} from 'src/types/getCubexCabinetsCabinetIds'

export const CubexTaskBanner: React.FC<{
  task?: Task | null
  treatment?: TreatmentChildLevel | null
}> = ({ task, treatment }) => {
  const [{ consultationSiteId }] = useSheetContext()
  const { separationSites } = useSeparationSites([consultationSiteId ?? ''])
  const [cubexMachineName, setCubexMachineName] = useState('')
  const { data } = useQuery<
    getCubexCabinetsCabinetIds,
    getCubexCabinetsCabinetIdsVariables
  >(GET_CUBEX_CABINETS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisationId: task?.organisation_id ?? '',
    },
    skip: !task?.organisation_id,
  })

  useEffect(() => {
    const cubeCabinetIds =
      data?.getCubexCabinets?.items.map(item => item.id) ?? []
    if (cubeCabinetIds?.length > 1) {
      setCubexMachineName(
        separationSites.find(site => site.id === consultationSiteId)?.name ??
          '',
      )
    }
  }, [data?.getCubexCabinets?.items, consultationSiteId, separationSites])

  const cubexBannerStatus = useMemo(() => {
    if (!task) {
      return treatment?.cubex_integration
        ? CUBEX_BANNER_STATUS.WILL_SYNC
        : CUBEX_BANNER_STATUS.NOT_SHOW
    }
    if (
      !task?.cubex_transaction_status ||
      task?.cubex_transaction_status === CubexTransactionStatus.FAILED ||
      task?.cubex_transaction_status === CubexTransactionStatus.CANCEL
    ) {
      return CUBEX_BANNER_STATUS.NOT_SHOW
    }
    if (task.cubex_transaction_status === CubexTransactionStatus.WAITING) {
      return CUBEX_BANNER_STATUS.WILL_SYNC
    }
    return CUBEX_BANNER_STATUS.ARRIVED
  }, [task, treatment])

  return (
    <CubexTopBanner
      cubexBannerStatus={cubexBannerStatus}
      cubexMachineName={cubexMachineName}
    />
  )
}
