import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { FormLabel, SwitchInput } from 'components/common'
import { DaysHoursMinutesSelector } from '../../common/DurationSelector/DaysHoursMinutesSelector'
import { noop } from 'lodash'

type Props = {
  isCRI?: boolean
  control: any // use any as FluidDosageForm.Inputs conflicts with MedicineDosageForm.Inputs
  watchedIsContinuous: boolean
  onDurationChange?: () => void
}

export const DosageFormDurationInfo: React.FC<Props> = ({
  isCRI = true,
  control,
  watchedIsContinuous,
  onDurationChange = noop,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <FormLabel text={t('addTreatment:duration.label')} />
      {isCRI ? (
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label={t('addTreatment:duration.isContinuous')}
              onChangeValue={onChange}
              value={value}
            />
          )}
          name="isContinuous"
        />
      ) : null}
      {!watchedIsContinuous && (
        <DaysHoursMinutesSelector
          onDurationChange={onDurationChange}
          control={control}
        />
      )}
    </>
  )
}
