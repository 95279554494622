// TODO: i18n 🤔
const rowNames = ['Root Level', 'First Level']
export const getRowA11yLabel = (
  type: 'Grid' | 'Treatment',
  name: string,
  nestingDepth?: number,
) =>
  `${name} ${
    nestingDepth !== undefined ? `${rowNames[nestingDepth]} ` : ''
  }${type} Row`

export const foldedTreatmentsDefault: string[] = []
