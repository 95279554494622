export { Fonts } from './Fonts'
export { Colors } from './Colors'
export { Layout } from './Layout'
export { Routes } from './Routes'

export const E2ETestOrganisationID = 'aa741523-c5f6-47ec-bf9b-d4b05c7c1a75'

export const contactSupportEmail = 'feedback@ezyvet.com'

export const SuperUserEmails = ['team@vetradar.com', contactSupportEmail]

export const ezyVetSuffix = '(ezyVet)'
