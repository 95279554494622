export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  PROCESS = 'process',
  NOTICE = 'notice',
  DISCONNECTED = 'disconnected',
  RELOAD = 'reload',
  SCHEDULER_WARNING = 'schedulerWarning',
  NO_PERMISSION_WARNING = 'noPermissionWarning',
}
