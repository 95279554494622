import { TreatmentChildLevel } from 'components/Treatment/common/types'
import { TFunction } from 'i18next'
import { TimeSegment } from 'src/hocs/types/time'
import {
  rescheduleTasks,
  rescheduleTasks_rescheduleTasks,
  rescheduleTasksVariables,
} from 'src/types/rescheduleTasks'
import {
  createTask,
  createTask_createTask,
  createTaskVariables,
} from 'types/createTask'
import {
  getSheet,
  getSheet_getSheet_treatments_items_treatments_items_tasks_items,
  getSheetVariables,
} from 'types/getSheet'
import {
  getTreatment,
  getTreatment_getTreatment_schedule,
  getTreatmentVariables,
} from 'types/getTreatment'
import { UpdateTaskInput, VideoInput } from 'types/globalTypes'
import {
  rescheduleTask,
  rescheduleTask_rescheduleTask,
  rescheduleTaskVariables,
} from 'types/rescheduleTask'
import { TaskFieldsFull } from 'types/TaskFieldsFull'
import {
  updateTaskFull,
  updateTaskFull_updateTask,
  updateTaskFullVariables,
} from 'types/updateTaskFull'

export type CreateTask = createTask
export type CreatedTask = createTask_createTask
export type CreateTaskVariables = createTaskVariables
export type TaskFields = TaskFieldsFull
export type Task =
  getSheet_getSheet_treatments_items_treatments_items_tasks_items

export type UpdateTask = updateTaskFull
export type UpdatedTask = updateTaskFull_updateTask
export type UpdateTaskVariables = updateTaskFullVariables

export type RescheduleTask = rescheduleTask
export type RescheduledTask = rescheduleTask_rescheduleTask
export type RescheduleTaskVariables = rescheduleTaskVariables

export type RescheduleTasks = rescheduleTasks
export type RescheduledTasks = rescheduleTasks_rescheduleTasks
export type RescheduleTasksVariables = rescheduleTasksVariables

export type GetSheet = getSheet
export type GetSheetVariables = getSheetVariables

export type GetTreatment = getTreatment
export type GetTreatmentVariables = getTreatmentVariables
export type TreatmentSchedule = getTreatment_getTreatment_schedule

export type TaskActionsProps = {
  treatment: TreatmentChildLevel | null
  task?: Task | null
  timeInGrid?: Date
  toggleDialog: () => void
  isFinalized: boolean
  timeSegment: TimeSegment
  patientId: string
  sheetId: string
}

type TransitionProps = {
  treatment: TreatmentChildLevel | null
  toggleDialog: () => void

  createAndCompleteTask?: (input: any) => any // Promise<CreatedTask | undefined>
  isFinalized?: boolean
  isTaskPastEditingTimeout?: boolean
  patientId: string
  sheetId: string
  activityLog?: React.ReactElement | null
  timeInGrid?: Date
}

export type TaskTransitionProps = TransitionProps & {
  action?: Action
  task?: Task
}

export type UseTaskTransitionProps = TransitionProps & {
  action?: Action | FluidAction
  task?: Task
  nextPendingFluidTask?: Task | null
}

export type FluidTaskTransitionProps = TransitionProps & {
  action: FluidAction
  task: Task
  prevTask?: Task
}

export type AttachmentInformation = {
  name: string | undefined
  startedAt: string
}

export enum PhotoAction {
  Remove = 'Remove',
  Add = 'Add',
}

export enum Action {
  COMPLETE = 'Complete',
  EDIT = 'Edit',
  RESCHEDULE = 'Reschedule',
  VIEW = 'View',
}

export enum FluidAction {
  START = 'Start_fluid',
  STOP = 'Stop_fluid',
  VIEW = 'View_fluid',
  CREATE_FLUID = 'Create_fluid',
  EDIT_FLUID = 'Edit_fluid',
  SCHEDULE_FLUID = 'Schedule_fluid',
  CHANGE_FLUID_RATE = 'Change_fluid_rate',
}

export type GetDoneOrProgressTaskInput = {
  newValue: string | null
  inputs: Inputs
  isInProgress: boolean
}

export type GetCompleteTaskInput = {
  originalTask: Task
  updatedTask: UpdateTaskInput
  isInProgress?: boolean
  datetimeGiven: Date | null
}

export type GetEditTaskInput = {
  updatedTask: UpdateTaskInput
  originalTask: Task
  value?: string
  notes?: string
}

export type GetEditFluidTaskInput = {
  updatedTask: UpdateTaskInput
  originalTask: Task
  value: string
  notes: string
}

export type GetStopFluidTaskInput = {
  updatedTask: UpdateTaskInput
  originalTask: Task
  datetimeGiven: Date
}

export type GetChangeFluidStoppedAndStartTaskInput = {
  originalTask: Task
  notes: string
  value: string
  nextPendingFluidTask: Task | null
  scheduleAt: string
}

export enum StatusHack {
  SKIPPED = 'SKIPPED',
}

export type Inputs = {
  assigned_user: string | null
  backdate: boolean
  datetimeGiven: Date
  newTaskDate: Date
  isBillable: boolean | null | undefined
  notes: string
  reschedule: boolean
  showValue: boolean
  unit: string
  value: string
  photo_urls: string[]
  videos: VideoInput[]
  highlightColor: string | null
  qtyBilled: number | null
}

export type GetButtonTitleParams = {
  action: Action | FluidAction
  t: TFunction
  task?: Task
}
