import React, { useState } from 'react'
import { SvgCheck, SvgClock, SvgExclamation } from 'components/Icons'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import { getTaskColor } from 'components/Whiteboard/getTaskColor'
import { Colors } from 'constants/Colors'
import { range } from 'lodash'
import { Pressable, PressableProps, StyleSheet, Text, View } from 'react-native'
import { Fonts } from 'src/constants/Fonts'
import {
  listWhiteboardPatients_listWhiteboardPatients_items_due_tasks as DueTasks,
  listWhiteboardPatients_listWhiteboardPatients_items_missed_tasks as MissedTasks,
  listWhiteboardPatients_listWhiteboardPatients_items_tasks as PatientTask,
} from 'src/types/listWhiteboardPatients'

import { usePatientTasksColumns } from './usePatientTasksColumns'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { TaskPreviewContainer } from 'components/Task/TaskPreview/TaskPreviewContainer'
import { useTranslation } from 'react-i18next'
import { TaskPreviewTab } from 'components/Task/TaskPreview/types'

type PatientInfo = {
  patientId: string
  hasUnapprovedSheet?: boolean | null
  onPressName?: () => void
  tasks: PatientTask[]
  dueTasks?: DueTasks
  missedTasks?: MissedTasks
  criticalNotes?: string[] | null
}

export const taskItemMinWidth = 112
export const criticalNotesWidth = 120

export const PatientListTasksItems: React.FC<PatientInfo> = React.memo(
  ({
    patientId,
    hasUnapprovedSheet,
    tasks,
    criticalNotes,
    dueTasks,
    missedTasks,
  }) => {
    const { t } = useTranslation()
    const [showTaskPreviewDrawer, setShowTaskPreviewDrawer] = useState(false)
    const [selectedTaskPreviewTab, setSelectedTaskPreviewTab] =
      useState<TaskPreviewTab>(TaskPreviewTab.UPCOMING)
    const [selectedTime, setSelectedTime] = useState<string>(
      new Date().toISOString(),
    )
    const columns = usePatientTasksColumns()
    const dueCount = dueTasks?.due_count ?? 0
    const missedCount = missedTasks?.missed_count ?? 0
    const dueTopSheetGroups = dueTasks?.top_sheet_groups ?? []
    const missedTopSheetGroups = missedTasks?.top_sheet_groups ?? []

    const handleTaskPreviewOnPress = (
      tab: TaskPreviewTab,
      start_at?: string,
    ) => {
      setShowTaskPreviewDrawer(true)
      setSelectedTaskPreviewTab(tab)
      if (start_at) {
        setSelectedTime(start_at)
      }
    }

    return (
      <>
        <View style={styles.tasksContainer}>
          <View style={styles.tasks}>
            <View
              accessibilityLabel={'Critical Notes'}
              style={styles.notesHolder}
            >
              <TextWithTooltip
                style={styles.noteText}
                numberOfLines={7}
                title={criticalNotes?.join('\n') ?? ''}
              >
                {criticalNotes?.join('\n')}
              </TextWithTooltip>
            </View>
            {!!columns && (
              <>
                <TaskPreviewPressable
                  onPress={() => {
                    handleTaskPreviewOnPress(TaskPreviewTab.DUE_MISSED)
                  }}
                >
                  <TaskColumn
                    count={missedCount}
                    topSheetGroups={missedTopSheetGroups}
                    isAllDone={false}
                    isMissed={true}
                  />
                </TaskPreviewPressable>
                <TaskPreviewPressable
                  onPress={() => {
                    handleTaskPreviewOnPress(TaskPreviewTab.DUE_MISSED)
                  }}
                >
                  <TaskColumn
                    count={dueCount}
                    topSheetGroups={dueTopSheetGroups}
                    isAllDone={false}
                    isDueColumn={true}
                  />
                </TaskPreviewPressable>
              </>
            )}
            {range(columns).map((_, i) => {
              const task = tasks[i]
              const topSheetGroups = task?.top_sheet_groups ?? []
              const taskCount = task?.pending_count ?? 0
              const isAllDone = task?.done_count > 0 && !task?.pending_count
              const key = task?.start_at ?? i

              return (
                <Pressable
                  style={styles.taskPreviewButton}
                  onPress={() => {
                    handleTaskPreviewOnPress(
                      TaskPreviewTab.UPCOMING,
                      task.start_at,
                    )
                  }}
                  key={key}
                >
                  <TaskColumn
                    count={taskCount}
                    topSheetGroups={topSheetGroups}
                    isAllDone={isAllDone}
                    isDueColumn={false}
                  />
                </Pressable>
              )
            })}
          </View>
          <View style={styles.columnBorderSeperator} />
        </View>
        <SheetAwareSideDrawer
          visible={showTaskPreviewDrawer}
          title={t('task:taskPreview.title')}
          onClose={() => setShowTaskPreviewDrawer(false)}
        >
          <TaskPreviewContainer
            patientId={patientId}
            hasUnapprovedSheet={hasUnapprovedSheet}
            fromISODate={selectedTime}
            dueTaskCount={dueCount}
            missedTaskCount={missedCount}
            tab={selectedTaskPreviewTab}
          />
        </SheetAwareSideDrawer>
      </>
    )
  },
)

const TaskPreviewPressable = ({
  children,
  onPress,
}: {
  children: React.ReactNode
  onPress?: PressableProps['onPress']
}) => {
  return (
    <Pressable style={styles.taskPreviewButton} onPress={onPress}>
      {children}
    </Pressable>
  )
}

type TaskColumnProps = {
  count: number
  topSheetGroups: { sheet_group_name: string }[]
  isAllDone: boolean
  isDueColumn?: boolean
  isMissed?: boolean
}

const TaskColumn: React.FC<TaskColumnProps> = ({
  count,
  topSheetGroups,
  isAllDone,
  isDueColumn = false,
  isMissed = false,
}) => {
  const treatmentLength = topSheetGroups.length
  const colour = getTaskColor(isDueColumn, isAllDone, isMissed)
  const allDoneIconRender = () => (
    <SvgCheck length={32} color={Colors.task.done} />
  )
  const dueIconRender = () => {
    return (
      <>
        <SvgClock width={24} height={24} color={colour} />
        <Text style={styles.taskCountText}>{count}</Text>
      </>
    )
  }

  const missedIconRender = () => {
    return (
      <>
        <SvgExclamation height={24} color={colour} />
        <Text style={[styles.taskCountText, { color: colour }]}>{count}</Text>
      </>
    )
  }

  return (
    <View style={[styles.taskBoxContainer]}>
      {!!count || isAllDone ? (
        <View style={[styles.taskBox, { borderColor: colour }]}>
          <>
            <View
              style={[
                // VR-4850: Use an positioned view instead of border width to
                // fix ios render issue, as large radius bars blur on ios.
                styles.taskBoxIndicatorBar,
                {
                  backgroundColor: colour,
                },
              ]}
            />
            {isMissed ? (
              <View style={styles.taskCountContainer}>
                {missedIconRender()}
              </View>
            ) : (
              <View style={styles.taskCountContainer}>
                {isAllDone ? allDoneIconRender() : dueIconRender()}
              </View>
            )}
          </>
          {!isAllDone && (
            <>
              {topSheetGroups
                .slice(0, treatmentLength > 3 ? 2 : treatmentLength)
                .map(({ sheet_group_name }) => (
                  <TextWithTooltip
                    testID={sheet_group_name}
                    numberOfLines={1}
                    style={styles.taskNameText}
                    key={sheet_group_name}
                    title={sheet_group_name}
                  >
                    {sheet_group_name}
                  </TextWithTooltip>
                ))}
              {treatmentLength > 3 && <Text>{`+${treatmentLength - 2}`}</Text>}
            </>
          )}
        </View>
      ) : null}
    </View>
  )
}
PatientListTasksItems.displayName = 'PatientListTasksItems'

const styles = StyleSheet.create({
  firstColumnContainer: {
    borderLeftWidth: 1,
  },
  tasksContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  tasks: {
    flexDirection: 'row',
    marginLeft: 15,
    flex: 1,
  },
  taskBoxContainer: {
    flex: 1,
    borderRightWidth: 1,
    borderColor: Colors.titleBorder,
    minWidth: taskItemMinWidth,
  },
  taskBox: {
    flex: 1,
    margin: 2,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 4,
    overflow: 'hidden',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingTop: 45,
  },
  taskBoxIndicatorBar: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0,
    width: 6,
  },
  taskCountContainer: {
    flexDirection: 'row',
    paddingVertical: 8,
  },
  taskCountText: {
    fontFamily: Fonts.regular,
    fontSize: 18,
    marginLeft: 5,
  },
  taskNameText: {
    fontFamily: Fonts.regular,
    fontSize: 14,
  },
  notesHolder: {
    width: criticalNotesWidth,
    borderRadius: 8,
    backgroundColor: '#ff525229',
    marginVertical: 2,
    marginRight: 2,
  },
  noteText: {
    fontFamily: Fonts.regular,
    fontSize: 14,
    margin: 5,
    marginVertical: 10,
  },
  columnBorderSeperator: {
    width: '100%',
    borderBottomWidth: 1,
    borderColor: Colors.titleBorder,
    marginHorizontal: criticalNotesWidth + 15 + 8, // critical notes width + spacing + border radius
  },
  taskPreviewButton: {
    minWidth: taskItemMinWidth,
    flex: 1,
  },
})
