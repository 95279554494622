import { StyleSheet, View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo, useState } from 'react'
import { Control, Controller, useForm } from 'react-hook-form'
import {
  Center,
  FormBreak,
  FormLabel,
  Select,
  Button,
  treatmentHeight,
} from 'components/common'
import { AddTreatmentButton } from 'components/AddTreatment/AddTreatmentButton'
import { WorkflowTemplateSection } from 'components/Template/WorkflowTemplateSection'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'
import { ProcedureProductsScreen } from './ProcedureProductsScreen'
import { Tabs } from 'components/common/Tabs/Tabs'
import { useTreatmentDrawerContext } from 'src/context/treatmentDrawer'
import { HospitalTaskPickProductScreen } from './HospitalTaskPickProductScreen'
import { Colors, Typography } from 'src/design-system/theme'
import { ProcedureItem } from './ProcedureItem'
import { Procedure, ProcedureProduct } from './utils/ProcedureUtil'
import { ProcedureTreatmentSettingScreen } from './ProcedureTreatmentSettingScreen'
import { TemplateTreatment } from 'components/TreatmentTemplate/TemplateTreatmentForm'
import { DraggableList } from 'components/common/DraggableList'
import { noop } from 'lodash'

enum ProcedureUseCase {
  ANAESTHESIA = 'ANAESTHESIA',
  HOSPITAL = 'HOSPITAL',
}

enum ProcedureAction {
  Products = 'Products',
  Settings = 'Settings',
}

export type Input = {
  use_case?: string | null
  template_workflow_id?: string | null
}

type Props = {
  procedure: Procedure
  onSave: (data: Input) => {}
  saving: boolean
}

export const ProcedureForm: React.FC<Props> = ({
  procedure,
  onSave,
  saving,
}) => {
  const { t } = useTranslation()

  const [groupedProductList, setGroupedProductList] = useState([])
  const [isAddDrawerVisible, setIsAddDrawerVisible] = useState(false)
  const [currentProduct, setCurrentProduct] = useState<ProcedureProduct | null>(
    null,
  )

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<Input>({
    defaultValues: {
      use_case: procedure.useCase,
      template_workflow_id: procedure.workflowId,
    },
    mode: 'onChange',
    // TODO - add schema validation
  })

  const mapToSheetGroup = (product: ProcedureProduct, map: any) => {
    if (!map[product.sheetGroup]) {
      map[product.sheetGroup] = []
    }
    map[product.sheetGroup].push(product)
  }

  useMemo(() => {
    const productSheetGroupMap: any = []

    procedure.products?.map(levelOneItem => {
      if (levelOneItem.isBundle && !!levelOneItem.treatments.items) {
        levelOneItem.treatments.items.map(levelTwoItem => {
          mapToSheetGroup(levelTwoItem, productSheetGroupMap)
        })
      } else if (!levelOneItem.isBundle) {
        mapToSheetGroup(levelOneItem, productSheetGroupMap)
      }
    })
    setGroupedProductList(productSheetGroupMap)
  }, [procedure.products])

  const renderListHeader = () => (
    <Center>
      <FormBreak />
      <SelectUseCase control={control} />
      <SelectTemplateWorkflow control={control} />
      <FormLabel text="Procedure Configuration" />
    </Center>
  )

  const renderListFooter = () => (
    <>
      <Center style={{ marginVertical: 30 }}>
        <AddTreatmentButton
          title={t('procedure:addBtnFullTitle')}
          onPress={() => setIsAddDrawerVisible(true)}
        />
      </Center>
      <View style={styles.footer}>
        <Button
          disabled={!isValid}
          loading={saving}
          onPress={handleSubmit(onSave)}
          color={Colors.Buttons.positive}
          testID="SaveTreatmentButton"
          title={t('general.saveChanges')}
        />
      </View>
    </>
  )

  const renderDraggableItems = useCallback(
    (sheetGroupKey: any) => (
      <DraggableList
        items={groupedProductList[sheetGroupKey]}
        renderItem={({ item }) => (
          <Center>
            <ProcedureItem
              item={item as ProcedureProduct}
              onPress={() => {
                setCurrentProduct(item as ProcedureProduct)
              }}
            />
          </Center>
        )}
        onMoveEnd={currentList => {
          // TODO add order updating functionality when saving
          groupedProductList[sheetGroupKey] = currentList as never
          setGroupedProductList({ ...groupedProductList })
        }}
        ListHeaderComponent={
          <Center>
            <View style={styles.centerGroup}>
              <Text style={styles.centerGroupName}>{sheetGroupKey}</Text>
            </View>
          </Center>
        }
      />
    ),
    [groupedProductList],
  )

  return (
    <>
      <DraggableList
        // not re-arrangeable but needed for children draggable lists reordering UI
        items={Object.keys(groupedProductList) as any}
        renderItem={({ item }) => renderDraggableItems(item)}
        ListHeaderComponent={renderListHeader()}
        ListFooterComponent={renderListFooter()}
        onMoveEnd={noop}
      />
      <ProductSettingsDrawer
        visible={currentProduct !== null}
        product={currentProduct}
        onClose={() => setCurrentProduct(null)}
        onSave={() => {}}
        sites={procedure.sites}
      />
      <AddProductDrawer
        visible={isAddDrawerVisible}
        onClose={() => setIsAddDrawerVisible(false)}
        onSave={() => {}}
      />
    </>
  )
}

const SelectUseCase: React.FC<{
  control: Control<Input>
}> = ({ control }) => {
  const { t } = useTranslation()
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <Select
          label={t('template:form.useCase')}
          options={[
            {
              text: t('template:form.anaesthesia'),
              value: ProcedureUseCase.ANAESTHESIA,
            },
            {
              text: t('template:form.hospital'),
              value: ProcedureUseCase.HOSPITAL,
            },
          ]}
          selected={value}
          onChange={onChange}
        />
      )}
      name="use_case"
    />
  )
}

const SelectTemplateWorkflow: React.FC<{
  control: Control<Input>
}> = ({ control }) => {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <WorkflowTemplateSection selected={value} onChange={onChange} />
      )}
      name="template_workflow_id"
    />
  )
}

type AddProductDrawerProps = {
  visible: boolean
  onClose: () => void
  onSave: () => void
}
const AddProductDrawer: React.FC<AddProductDrawerProps> = ({
  visible,
  onClose,
}) => {
  const { t } = useTranslation()
  return (
    <SheetAwareSideDrawer
      visible={visible}
      onClose={onClose}
      title={t('procedure:addHospitalTasks')}
    >
      <HospitalTaskPickProductScreen />
    </SheetAwareSideDrawer>
  )
}

type ProductSettingsDrawerProps = {
  visible: boolean
  product: ProcedureProduct | null
  onClose: () => void
  onSave: () => void
  sites?: Array<{ id: string; name: string } | null> | null // TODO can we make this type better?
}

const ProductSettingsDrawer: React.FC<ProductSettingsDrawerProps> = ({
  visible,
  product,
  onClose,
  onSave,
  sites,
}) => {
  const [{ shouldShowTabs }] = useTreatmentDrawerContext()
  const [currentTab, setCurrentTab] = useState<ProcedureAction>(
    ProcedureAction.Products,
  )
  const tabs = [
    {
      value: ProcedureAction.Products,
      text: 'Products',
    },
    {
      value: ProcedureAction.Settings,
      text: 'Settings',
    },
  ]
  return (
    <SheetAwareSideDrawer
      visible={visible}
      onClose={() => {
        setCurrentTab(ProcedureAction.Products)
        onClose()
      }}
      title={product?.isBundle ? product.name : `Edit "${product?.name}"`}
    >
      {product?.isBundle && shouldShowTabs ? ( // TODO should we split the Bundle/NotBundle paths into separate components? Are they Different Enough?
        <Tabs
          options={tabs}
          onPressHandler={(tab: ProcedureAction) => {
            setCurrentTab(tab)
          }}
          selected={currentTab}
        />
      ) : (
        <ProcedureTreatmentSettingScreen
          procedureTreatment={{} as TemplateTreatment} // TODO - Add once procedure treatment query is ready
          onSave={onSave}
          onClickTemplatePickProduct={() => {}}
        />
      )}

      {currentTab === ProcedureAction.Products ? (
        <ProcedureProductsScreen product={product!} parentSites={sites} />
      ) : (
        <ProcedureTreatmentSettingScreen
          onSave={onSave} // TODO - Add once procedure treatment query is ready
          onClickTemplatePickProduct={() => {}}
        />
      )}
    </SheetAwareSideDrawer>
  )
}

const styles = StyleSheet.create({
  centerGroup: {
    backgroundColor: '#C3D1FF', // TODO - Stephen needs to add to design system
    height: treatmentHeight,
    width: 'auto',
    justifyContent: 'center',
  },
  centerGroupName: {
    fontSize: 16,
    paddingLeft: 16,
    color: Colors.Contents.primary,
    fontFamily: Typography.FontFamilies.bold,
  },
  footer: {
    marginVertical: 15,
  },
})
