import React, { useCallback, useState } from 'react'
import { RawDialog } from 'components/common/Dialog/RawDialog'
import { isIphone } from 'src/utils/isIPhone'
import { FeedbackScreen } from './FeedbackScreen'
import { HelpModalLayout } from '../HelpModalLayout'
import { HelpScreen } from './HelpScreen'

type Props = {
  visible: boolean
  feedback: string
  onClose: () => void
  onSendFeedback: () => void
  onFeedbackChange: (_: string) => void
  shouldDisableSendFeedbackButton?: boolean
}

export const HelpModal: React.FC<Props> = ({
  visible,
  onClose,
  feedback,
  onSendFeedback,
  onFeedbackChange,
  shouldDisableSendFeedbackButton,
}) => {
  const [showForm, setShowForm] = useState(false)

  const handleClose = useCallback(() => {
    onClose()
    setShowForm(false)
  }, [onClose])

  const screen = showForm ? (
    <FeedbackScreen
      feedback={feedback}
      onSendFeedback={onSendFeedback}
      onFeedbackChange={onFeedbackChange}
      shouldDisableSendFeedbackButton={shouldDisableSendFeedbackButton}
      onClose={handleClose}
      onBack={() => setShowForm(false)}
    />
  ) : (
    <HelpScreen
      onClose={handleClose}
      navigateToForm={() => setShowForm(true)}
    />
  )

  if (isIphone) {
    return (
      <RawDialog
        visible={visible}
        onClose={onClose}
        dialogHeight={showForm ? '80%' : '50%'}
      >
        {screen}
      </RawDialog>
    )
  }

  return (
    <HelpModalLayout visible={visible} onClose={handleClose}>
      {screen}
    </HelpModalLayout>
  )
}
