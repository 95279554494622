import React from 'react'
import { StyleProp, TextStyle, StyleSheet } from 'react-native'
import Highlighter from 'react-native-highlight-words'
import { Colors } from 'src/design-system/theme'
import { Typography } from 'src/design-system/theme/typography'

type HighlightableTextProps = {
  text: string
  searchWords: string[]
  disabled?: boolean
  style?: StyleProp<TextStyle>
  highlightStyle?: StyleProp<TextStyle>
}

export const Heading: React.FC<HighlightableTextProps> = ({
  text,
  searchWords,
  disabled = false,
  style,
  highlightStyle,
}) => {
  return (
    <Highlighter
      ellipsizeMode="middle"
      highlightStyle={StyleSheet.flatten([styles.highlight, highlightStyle])}
      numberOfLines={1}
      searchWords={searchWords}
      autoEscape={true}
      style={StyleSheet.flatten([
        styles.text,
        disabled ? styles.disabled : undefined,
        style,
      ])}
      testID={`TextHighlighter: ${text}`}
      textToHighlight={text}
    />
  )
}

const styles = StyleSheet.create({
  text: {
    fontFamily: Typography.FontFamilies.base,
    fontSize: Typography.FontSizes.md,
    color: Colors.Contents.primary,
  },
  disabled: {
    color: Colors.Interactive.disabledTertiary,
  },
  highlight: { fontFamily: Typography.FontFamilies.bold },
})
