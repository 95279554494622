import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { Tag } from 'src/components/Tag/Tag'
import { Routes } from 'src/constants/Routes'

import { useOrganisation } from 'src/context/organisation'
import {
  updateTemplateWorkflow as updateTemplateWorkflowT,
  updateTemplateWorkflowVariables,
} from 'src/types/updateTemplateWorkflow'
import {
  getTemplateWorkflow,
  getTemplateWorkflowVariables,
} from 'src/types/getTemplateWorkflow'
import { TemplateWorkflowItemInput } from 'src/types/globalTypes'

import { SubHeader } from '../SubHeader/SubHeader'
import {
  UPDATE_WORKFLOW_TEMPLATE,
  GET_WORKFLOW_TEMPLATE,
  GET_TEMPLATES_BY_WORKFLOW,
} from './graphql'
import { Inputs, WorkflowTemplateForm } from './WorkflowTemplateForm'
import { SettingsStackParamList } from 'components/Settings/screens'
import { isOptimisticId } from 'src/utils/optimisticId'
import { getTemplatesByTemplateWorkflow as GetTemplatesByTemplateWorkflow } from 'src/types/getTemplatesByTemplateWorkflow'

type Props = StackScreenProps<
  SettingsStackParamList,
  Routes.EditWorkflowTemplate
>

export const EditWorkflowTemplateScreen: React.FC<Props> = ({
  navigation,
  route,
}) => {
  const { navigate } = navigation
  const templateId = route.params.templateId
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const { data, loading } = useQuery<
    getTemplateWorkflow,
    getTemplateWorkflowVariables
  >(GET_WORKFLOW_TEMPLATE, {
    fetchPolicy: 'network-only',
    variables: {
      organisationId,
      id: templateId,
    },
  })

  const template = data?.getTemplateWorkflow

  const { data: treatmentTemplatesData } =
    useQuery<GetTemplatesByTemplateWorkflow>(GET_TEMPLATES_BY_WORKFLOW, {
      fetchPolicy: 'cache-and-network',
      variables: {
        organisation_id: organisationId,
        templateWorkflowId: templateId,
      },
    })

  const treatmentTemplates =
    treatmentTemplatesData?.getTemplatesByTemplateWorkflow

  const onComplete = (formData: updateTemplateWorkflowT): void => {
    const templateName = formData.updateTemplateWorkflow.name
    toast.success(
      t('template:update.success', {
        name: templateName,
      }),
    )
    handleBack()
  }

  const handleBack = () => navigate(Routes.WorkflowTemplateList)

  const [updateTemplate, { loading: submitting }] = useMutation<
    updateTemplateWorkflowT,
    updateTemplateWorkflowVariables
  >(UPDATE_WORKFLOW_TEMPLATE, {
    onError: () => toast.error(t('template:update.err')),
    onCompleted: onComplete,
  })

  const backButton = {
    action: handleBack,
    label: 'templates.returnHereLabel',
    title: 'templates.workflowTitle',
  }

  const handleSubmit = async (formData: Inputs) => {
    const workflowItems =
      formData.items
        ?.filter(item => item.name)
        .map(
          (item, idx) =>
            ({
              organisation_id: organisationId,
              order: idx,
              name: item.name,
              deleted_at: item.deletedAt,
              ...(!isOptimisticId(item.id) && { id: item.id }),
            } as TemplateWorkflowItemInput),
        ) ?? []

    template?.workflow_items?.items?.forEach(oldItem => {
      if (!workflowItems.find(item => item.id === oldItem.id)) {
        workflowItems.push({
          organisation_id: organisationId,
          id: oldItem.id,
          name: oldItem.name,
          order: oldItem.order,
          deleted_at: new Date().toISOString(),
        })
      }
    })

    await updateTemplate({
      variables: {
        input: {
          id: template?.id!,
          organisation_id: organisationId,
          name: formData.name,
          disabled: formData.disabled,
          sites: formData.sites,
          user_id: formData.user_id,
          workflow_items: workflowItems,
        },
      },
    })
  }

  return (
    <>
      <SubHeader
        headline={template?.name}
        subHeadline={
          <View style={styles.tagContainer}>
            <Tag title={t('template:title')} />
            {!!template?.disabled && <Tag title={t('template:disabled')} />}
          </View>
        }
        backButton={backButton}
      />

      {loading ? (
        <ActivityIndicator size="large" style={styles.marginTop} />
      ) : (
        <WorkflowTemplateForm
          template={template!}
          handleSubmit={handleSubmit}
          submitting={submitting}
          treatmentTemplates={treatmentTemplates}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  tagContainer: {
    flexDirection: 'row',
  },
  marginTop: {
    marginTop: 25,
  },
})
