import { Colors } from 'constants/Colors'

export const getTaskColor = (
  isDue: boolean,
  isAllDone: boolean = false,
  isMissed: boolean = false,
) => {
  const { done, due, pending } = Colors.task
  if (isMissed) {
    return Colors.contentNegative
  }
  if (isAllDone) {
    return done
  }
  if (isDue) {
    return due
  }
  return pending
}
