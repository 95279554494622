import { LogBox } from 'react-native'

export const ignoreWarnings = appName => {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  LogBox.ignoreLogs([
    // Ignore warnings from these modules about require cycles
    'Require cycle: node_modules/aws-appsync',
  ])

  // Ignore storybook warnings
  if (appName === 'StorybookRoot') {
    LogBox.ignoreLogs([
      'Failed prop type',
      'Each child in a list should have a unique "key" prop.',
    ])
  }
}
