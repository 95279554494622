import { weightFactor, WeightUnit } from 'src/constants/Weight'

import { timeFactor, volumeFactor, VolumeUnit } from '../data'
import {
  isSpecialDosageWeightUnits,
  isStandardWeightUnits,
} from './isNonStandardWeightUnits'

export enum FluidMedication {
  ML = 'ml',
  L = 'l',
  FLOZ = 'fl oz',
}

export const checkIsFluidMedication = (volumeUnit: string | null): boolean => {
  if (volumeUnit === '') {
    return true
  }
  if (!volumeUnit) {
    return false
  }
  return (
    volumeUnit === FluidMedication.ML ||
    volumeUnit === FluidMedication.L ||
    volumeUnit === FluidMedication.FLOZ
  )
}

export const getNormedWeight = (
  weight: number,
  weightUnit: WeightUnit,
  normUnit: WeightUnit = WeightUnit.UG,
): number => {
  if (!(weightUnit in weightFactor)) {
    return weight
  }
  return (weight * weightFactor[weightUnit]) / weightFactor[normUnit]
}

export const getNormedDosage = (
  dosage: number,
  dosageWeightUnit: WeightUnit,
  dosagePerWeightUnit: WeightUnit,
): number => {
  if (
    !(dosageWeightUnit in weightFactor) ||
    !(dosagePerWeightUnit in weightFactor)
  ) {
    return dosage
  }
  return (
    (dosage * weightFactor[dosageWeightUnit]) /
    weightFactor[dosagePerWeightUnit]
  )
}

export const getNormedTotalDosage = (
  normedDosage: number,
  normedWeight: number,
  weightUnit?: WeightUnit | null,
): number => {
  return (
    (normedDosage * normedWeight) /
    weightFactor[
      !!weightUnit && weightUnit in weightFactor ? weightUnit : WeightUnit.MG
    ]
  )
}

export const getNormedTotalVolume = (
  normedDosage: number,
  normedWeight: number,
  normedConcentration: number,
  normedTotalVolumeUnit: number = volumeFactor.ml,
) => {
  if (normedConcentration === 0 || normedTotalVolumeUnit === 0) {
    return null
  }
  return (
    (normedDosage * normedWeight) / normedConcentration / normedTotalVolumeUnit
  )
}

export const getFormattedNumber = (
  rawNumber: number | null | undefined,
  round: (n: number) => number,
): string => {
  // We cannot coerce with !! incase rawNumber is 0
  if (rawNumber === undefined || rawNumber === null) {
    return ''
  }
  const strRawNumber = rawNumber.toString()

  const scientificNotationPattern = /^[+-]?\d+(\.\d*)?[eE][+-]?\d+$/
  if (scientificNotationPattern.test(strRawNumber)) {
    return strRawNumber
  }

  // If it is a decimal, integer is 0, the decimal zero places are greater than 2, like 0.0005
  const regex = /^0\.00\d*$/
  if (regex.test(strRawNumber)) return strRawNumber

  return round(rawNumber).toString()
}

export const getNormedConcentration = (
  concentration: number | null,
  concentrationWeightUnit: WeightUnit,
  concentrationVolumeUnit: VolumeUnit,
) => {
  if (!concentrationWeightUnit || !concentrationVolumeUnit || !concentration) {
    return 0
  }
  return (
    (concentration * weightFactor[concentrationWeightUnit]) /
    volumeFactor[concentrationVolumeUnit]
  )
}

export const getNormedVol = (vol: number, unit: VolumeUnit) => {
  return vol * volumeFactor[unit]
}

export const getNormedInfusionRate = (
  vol: number,
  volumeUnit: VolumeUnit,
  perWeightUnit: WeightUnit,
  infusionRateTimeUnit: string,
) => {
  return (
    (vol * volumeFactor[volumeUnit]) /
    weightFactor[perWeightUnit] /
    timeFactor[infusionRateTimeUnit]
  )
}

type CalculateTotalDosageParams = {
  patientWeight: number | null
  patientWeightUnit: string
  dosageRate?: number | null
  dosageWeightUnit: WeightUnit | null
  dosagePerWeightUnit: WeightUnit | null
  concentrationWeightUnit?: WeightUnit | null
  round: (n: number) => number
}

export const calculateDosageML = ({
  patientWeight,
  patientWeightUnit,
  dosageRate,
  dosageWeightUnit,
  dosagePerWeightUnit,
  round,
}: CalculateTotalDosageParams) => {
  if (dosageWeightUnit !== WeightUnit.ML) {
    return null
  }

  if (
    !dosagePerWeightUnit ||
    !patientWeight ||
    !patientWeightUnit ||
    !dosageRate
  ) {
    return null
  }

  const normedWeight = getNormedWeight(
    patientWeight,
    patientWeightUnit as WeightUnit,
  )
  const normedDosage = getNormedDosage(
    dosageRate,
    dosageWeightUnit,
    dosagePerWeightUnit,
  )

  let totalVolume: number | null = round(normedWeight * normedDosage)

  if (!totalVolume) {
    totalVolume = null
  }

  return totalVolume
}

export const calculateTotalDosage = ({
  patientWeight,
  patientWeightUnit,
  dosageRate,
  dosageWeightUnit,
  dosagePerWeightUnit,
  concentrationWeightUnit,
  round,
}: CalculateTotalDosageParams) => {
  if (
    !patientWeight ||
    !patientWeightUnit ||
    !dosageRate ||
    !dosageWeightUnit ||
    !dosagePerWeightUnit ||
    !concentrationWeightUnit
  ) {
    return null
  }

  if (isSpecialDosageWeightUnits(dosageWeightUnit)) {
    return null
  }
  // same unit or both standard can do the calculate
  if (
    dosageWeightUnit === concentrationWeightUnit ||
    (isStandardWeightUnits(dosageWeightUnit) &&
      isStandardWeightUnits(concentrationWeightUnit))
  ) {
    const normedPatientWeight = getNormedWeight(
      patientWeight,
      patientWeightUnit as WeightUnit,
    )

    const normedDosage = getNormedDosage(
      dosageRate,
      dosageWeightUnit,
      dosagePerWeightUnit,
    )
    const normedTotalDosage = getNormedTotalDosage(
      normedDosage,
      normedPatientWeight,
      dosageWeightUnit,
    )

    return round(normedTotalDosage)
  }

  return null
}
