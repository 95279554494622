import React from 'react'
import { View, Text, StyleSheet, Pressable } from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'

interface RadioButtonProps {
  selected: boolean
  label?: string
  disabled?: boolean
  onChange?: () => void
  size?: number
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  selected,
  disabled,
  onChange,
  size = 24,
}) => {
  const radioStyle = StyleSheet.flatten([
    styles.radio,
    { width: size, height: size },
    { padding: selected ? size / 3 : 0 },
    {
      borderColor: disabled
        ? Colors.Interactive.disabledPrimary
        : Colors.Borders.tertiary,
    },
    { borderWidth: selected ? 0 : 2 },
    {
      backgroundColor: selected
        ? Colors.Contents.accent
        : Colors.Backgrounds.UI,
    },
  ])

  return (
    <Pressable
      disabled={disabled}
      onPress={onChange}
      hitSlop={16}
      role="radio"
      accessibilityRole="radio"
      accessibilityState={{
        disabled,
        checked: selected,
      }}
      style={({ pressed }) => [styles.container, pressed && { opacity: 0.5 }]}
    >
      <View style={styles.container}>
        <View style={radioStyle}>
          <View style={styles.radioDot} />
        </View>
        {!!label && <Text style={styles.label}>{label}</Text>}
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  radio: {
    borderRadius: 32,
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  radioDot: {
    backgroundColor: 'white',
    borderRadius: 32,
    flexGrow: 1,
  },
  label: {
    ...Typography.Label.M,
    marginLeft: 8,
  },
})
