import React, { useCallback, useMemo } from 'react'
import { FormField } from '../Form/FormField'
import { NestedListSelectMenu } from './NestedListSelectMenu'
import { useNestedListSelectMenu } from './useNestedListSelectMenu'
import { reject } from 'lodash'
import { NestedListSelectProps, ClipButtonOption, SubMenu } from './types'
import { PrimaryLevelMenu } from './PrimaryLevelMenu'
import { SecondaryLevelMenu } from './SecondaryLevelMenu'
import {
  styles,
  secondaryMenuHeightCalc,
  primaryMenuHeightCalc,
} from './styles'

export const MultiSelectNestedMenu: React.FC<NestedListSelectProps> = ({
  options,
  onChange,
  selected,
  label,
  loading,
  disabled,
}) => {
  const { actions, state } = useNestedListSelectMenu(
    options,
    selected,
    onChange,
    loading,
  )
  // this section handles placing, sizing and resizing the menu on the screen
  const clipButtons = useMemo(
    () =>
      options.reduce((acc, option) => {
        if (selected.includes(option.value)) {
          return acc.concat([{ value: option.value, text: option.text ?? '' }])
        }
        return acc
      }, [] as ClipButtonOption[]),
    [selected, options],
  )

  const handleClipButtonRemoval = useCallback(
    (selectedId: string) => {
      if (disabled) return
      const newSelected = reject(selected, option => option === selectedId)
      onChange(newSelected)
    },

    [disabled, onChange, selected],
  )

  const primaryMenu: SubMenu = (
    <PrimaryLevelMenu
      listItemStyle={styles.listItem}
      containerStyle={styles.menuCard}
      heightCalc={primaryMenuHeightCalc}
      menuOptions={state.primaryMenuOptions}
      state={state}
      actions={actions}
    />
  )

  const secondaryMenu: SubMenu = (
    <SecondaryLevelMenu
      containerStyle={styles.menuCard}
      heightCalc={secondaryMenuHeightCalc}
      menuOptions={state.secondaryMenuOptions}
      state={state}
      actions={actions}
      selected={selected}
    />
  )

  return (
    <>
      <FormField
        value={''}
        label={label}
        onPress={() => actions.setMenuOpen(!state.isMenuOpen)}
        selectedOptionButtons={clipButtons}
        activeParentSelector={state.activeParentSelector}
        // Don't let the user remove options when the form is disabled.
        selectedOptionButtonAction={handleClipButtonRemoval}
        clearAllSelectedOptions={() => onChange([])}
        disabled={disabled}
        style={styles.formFieldStyle}
      />

      <NestedListSelectMenu
        label={label}
        state={state}
        actions={actions}
        selected={selected}
        primaryMenu={primaryMenu}
        secondaryMenu={secondaryMenu}
        multiSelectMenu={true}
      />
    </>
  )
}
