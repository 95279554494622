import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useQuery } from '@apollo/client'
import { GET_CALL_PARAMETER_TEMPLATE } from 'components/CallParameterTemplate/graphql'
import { CallParameterBox } from 'components/PatientItem/PatientItemComponents/CallParameterBox'
import { mapDefaultOldTemplateToNew } from 'components/SheetHeader/ParameterEntry'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import {
  getTemplateCallParameter,
  getTemplateCallParameterVariables,
  getTemplateCallParameter_getTemplateCallParameter as TemplateCallParameters,
} from 'src/types/getTemplateCallParameter'
import {
  PatientFieldsFull,
  PatientFieldsFull_call_parameters as PatientCallParameters,
} from 'types/PatientFieldsFull'

type PatientCallParametersContentProps = {
  patient: PatientFieldsFull
  onPress?: () => void
}

export const PatientCallParametersContent: FunctionComponent<
  PatientCallParametersContentProps
> = ({ patient }) => {
  const [modifiedLabel, setModifiedLabel] = useState<string>('')
  const [templateName, setTemplateName] = useState<string>('')
  const { data, loading } = useQuery<
    getTemplateCallParameter,
    getTemplateCallParameterVariables
  >(GET_CALL_PARAMETER_TEMPLATE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organisationId: patient.organisation_id,
      id: patient.template_call_parameter_id ?? '',
    },
    skip: !patient.template_call_parameter_id,
  })

  useEffect(() => {
    if (!loading) {
      setTemplateName(data?.getTemplateCallParameter?.name ?? 'STANDARD')
      setModifiedLabel(
        isTemplateCallParamsModified(
          patient.call_parameters,
          data?.getTemplateCallParameter,
        ),
      )
    }
  }, [data, loading, patient.call_parameters])

  return (
    <>
      <CallParameterBox
        callParameters={mapDefaultOldTemplateToNew(patient.call_parameters)}
      />
      <View style={styles.templateContainer}>
        <Text style={styles.heading}>Template</Text>
        <Text style={styles.templateText}>
          {templateName} {modifiedLabel}
        </Text>
      </View>
    </>
  )
}

export enum TemplateCallParamsModifierLabel {
  EDITED = '(Edited)',
  DELETED = '(Deleted)',
  INACTIVE = '(Inactive)',
  NONE = '',
}

export const isTemplateCallParamsModified = (
  currentCallParameters: PatientCallParameters[] | null,
  templateCallParameters: TemplateCallParameters | undefined,
): TemplateCallParamsModifierLabel => {
  if (!templateCallParameters || !currentCallParameters)
    return TemplateCallParamsModifierLabel.NONE

  if (templateCallParameters.deleted_at)
    return TemplateCallParamsModifierLabel.DELETED

  if (templateCallParameters.disabled)
    return TemplateCallParamsModifierLabel.INACTIVE

  const templateCallParamsArr =
    templateCallParameters.call_parameter_items?.items ?? []

  if (currentCallParameters.length !== templateCallParamsArr.length)
    return TemplateCallParamsModifierLabel.EDITED

  for (let idx = 0; idx < currentCallParameters.length; idx += 1) {
    if (
      currentCallParameters[idx].max !== templateCallParamsArr[idx].max ||
      currentCallParameters[idx].min !== templateCallParamsArr[idx].min
    ) {
      return TemplateCallParamsModifierLabel.EDITED
    }
  }

  return TemplateCallParamsModifierLabel.NONE
}

const styles = StyleSheet.create({
  templateContainer: {
    flexDirection: 'column',
    marginTop: 4,
  },
  heading: {
    fontFamily: Fonts.regular,
    fontSize: 12,
    color: Colors.greyBlue,
  },
  templateText: {
    fontFamily: Fonts.bold,
    fontSize: 12,
    color: Colors.darkGrey,
  },
})
