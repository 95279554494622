import { useCallback, useMemo } from 'react'
import { ParameterRange } from 'src/components/Task/Task'
import {
  getPatient_getPatient,
  getPatient_getPatient_call_parameters,
} from 'src/types/getPatient'

import { usePatientById } from './usePatient'

export type PatientById = getPatient_getPatient

const getSmallerMax = (value1: number | null, value2: number | null) => {
  if (value1 === null) return value2
  if (value2 === null) return value1
  return value1 < value2 ? value1 : value2
}

const getBiggerMin = (value1: number | null, value2: number | null) => {
  if (value1 === null) return value2
  if (value2 === null) return value1
  return value1 > value2 ? value1 : value2
}

const getPatientCallParameterRange = (
  callParametersHashByProductId: Record<string, ParameterRange>,
  productId?: string | null,
) => {
  if (!productId) {
    return
  }
  if (!callParametersHashByProductId[productId]) {
    return
  }
  return callParametersHashByProductId[productId]
}

const buildCallParametersHashByProductId = (
  callParameters?: getPatient_getPatient_call_parameters[] | null,
) => {
  if (!callParameters) {
    return {}
  }
  return callParameters.reduce((result, cp) => {
    if (!cp.product_id) {
      return result
    }

    // when duplicate product_id, compare the min and max values, edge case but more patient safe
    if (result[cp.product_id]) {
      result[cp.product_id] = {
        min: getBiggerMin(result[cp.product_id].min, cp.min),
        max: getSmallerMax(result[cp.product_id].max, cp.max),
      }
    } else {
      result[cp.product_id] = { min: cp.min, max: cp.max }
    }

    return result
  }, {} as Record<string, ParameterRange>)
}

export const usePatientCallParameters = (patientId: string) => {
  const patient = usePatientById(patientId)

  const callParametersHashByProductId = useMemo(
    () => buildCallParametersHashByProductId(patient?.call_parameters),
    [patient],
  )

  const getPatientCallParameterRangeByProductID = useCallback(
    (productId?: string | null) =>
      getPatientCallParameterRange(callParametersHashByProductId, productId),
    [callParametersHashByProductId],
  )

  return getPatientCallParameterRangeByProductID
}
