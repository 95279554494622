import { noop } from 'lodash'
import React from 'react'
import {
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
  ActivityIndicator,
} from 'react-native'
import { Colors, Typography } from 'src/design-system/theme'

type OnPressType = (event: GestureResponderEvent) => any | (() => any)

type Props = {
  accessibilityLabel?: string | undefined
  title: string
  testID?: string
  onPress?: OnPressType
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  disabled?: boolean
  loading?: boolean
}

/* Secondary Button, used for add treatment, add template etc */
export const SecondaryButton = React.memo(
  ({
    accessibilityLabel,
    onPress = noop,
    style = {},
    testID,
    textStyle = {},
    title,
    disabled = false,
    loading = false,
  }: Props) => {
    const optionalProps = { testID }
    return (
      <TouchableOpacity
        accessibilityLabel={accessibilityLabel}
        style={[styles.container, style]}
        onPress={onPress}
        activeOpacity={0.3}
        disabled={disabled || loading}
        {...optionalProps}
      >
        <Text style={[styles.text, textStyle]}>{title}</Text>
        {loading ? (
          <ActivityIndicator size="small" style={styles.activityIndicator} />
        ) : null}
      </TouchableOpacity>
    )
  },
)

SecondaryButton.displayName = 'SecondaryButton'

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activityIndicator: {
    paddingLeft: 10,
  },
  text: {
    fontFamily: Typography.FontFamilies.base,
    color: Colors.Contents.accent,
  },
})
