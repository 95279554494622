import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Paragraph } from 'src/design-system/components/Text'

type AttendingVetSectionProps = {
  attendingVet: string | null
  attendingVetTech?: string | null
}

export const AttendingVetSection: React.FC<AttendingVetSectionProps> = ({
  attendingVet,
  attendingVetTech,
}) => (
  <View style={[styles.row, styles.rowPadding]}>
    <Paragraph size={'M'}>{attendingVet ?? ' '}</Paragraph>
    <Paragraph size={'M'}>{attendingVetTech ?? ' '}</Paragraph>
  </View>
)

const styles = StyleSheet.create({
  row: {
    maxWidth: '100%',
    alignItems: 'baseline',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  rowPadding: {
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
})
