import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'components/common'
import { SvgArrow, SvgHamburger } from 'components/Icons'
import { TextWithTooltip } from 'components/shared/TextWithTooltip'
import {
  repeatingIntervalOptions,
  repeatUntilOptions,
  timeWindowOptions,
} from 'components/TreatmentForm/data'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { addMinutes, startOfDay } from 'date-fns'
import { cloneDeep, noop } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { useOrganisation } from 'src/context/organisation'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import {
  deleteTemplateTreatment,
  deleteTemplateTreatmentVariables,
} from 'src/types/deleteTemplateTreatment'
import { getTemplate, getTemplateVariables } from 'src/types/getTemplate'
import { FrequencyType } from 'src/types/globalTypes'
import { useTreatmentFrequencies } from 'src/hooks/useTreatmentFrequencies'

import { DELETE_TEMPLATE_TREATMENT, GET_TEMPLATE } from './graphql'
import { TemplateTreatmentWithAvailable } from './TemplateForm'
import { getTreatmentFrequencies_getTreatmentFrequencies_items } from 'src/types/getTreatmentFrequencies'

const formatWindow = (timeWindow: number) => {
  return timeWindowOptions.find(option => option.value === timeWindow)?.text
}

const formatRepeat = (frequency: number, repeatUntil: number) => {
  const frequencyText = repeatingIntervalOptions.find(
    option => option.value === frequency,
  )?.text
  return [
    ...(frequencyText ? [frequencyText] : []),
    repeatUntilOptions.find(option => option.value === repeatUntil)?.text,
  ].join(' - ')
}

const formatTreatmentFrequency = (
  name: string,
  repeatUntil: number,
  isDeleted: boolean,
) => {
  const repeatUntilLabel = repeatUntilOptions.find(
    option => option.value === repeatUntil,
  )?.text
  return `${name} ${isDeleted ? '(Deleted) ' : ''}- ${repeatUntilLabel}`
}

type OnPressLink = {
  id: string
  name: string
}

export type Props = {
  templateId?: string
  templateTreatment: TemplateTreatmentWithAvailable
  // Don't need this on web. Maybe useful on native.
  onDragPress?: () => void
  onPress: (onPress: OnPressLink) => void
  treatmentFrequency?: getTreatmentFrequencies_getTreatmentFrequencies_items | null
}

export const TemplateTreatmentItem: React.FC<Props> = ({
  templateTreatment,
  onDragPress = noop,
  onPress,
  templateId,
  treatmentFrequency,
}) => {
  const { t } = useTranslation()
  const { dateFormatter } = useAdminTimeFormat()
  const { id, name, treatment_options } = templateTreatment
  const [{ organisationId }] = useOrganisation()
  const { singleFrequency } = useTreatmentFrequencies()
  const [frequencyLabel, setFrequencyLabel] = useState('')

  const formatStartAt = (startAt: number | null) => {
    if (startAt === null) {
      return 'Now'
    }
    return dateFormatter(addMinutes(startOfDay(new Date()), startAt), 'time')
  }

  const isMinsFromMidnightType =
    templateTreatment?.schedule?.type === FrequencyType.MINS_FROM_MIDNIGHT

  const getPlaceholderForDeleted = useCallback(async () => {
    const frequency = await singleFrequency({
      organisationId,
      id: templateTreatment.schedule?.treatment_frequency_id!,
    })
    return frequency.data.getTreatmentFrequency?.name
  }, [
    organisationId,
    singleFrequency,
    templateTreatment.schedule?.treatment_frequency_id,
  ])

  useEffect(() => {
    setFrequencyLabel(
      isMinsFromMidnightType ? treatmentFrequency?.name ?? '' : '',
    )
  }, [isMinsFromMidnightType, treatmentFrequency])

  useEffect(() => {
    if (isMinsFromMidnightType && !treatmentFrequency) {
      getPlaceholderForDeleted().then(name => setFrequencyLabel(name!))
    }
  }, [getPlaceholderForDeleted, isMinsFromMidnightType, treatmentFrequency])

  const [deleteTemplateTreatment, { loading: removing }] = useMutation<
    deleteTemplateTreatment,
    deleteTemplateTreatmentVariables
  >(DELETE_TEMPLATE_TREATMENT, {
    variables: {
      input: {
        id,
        template_id: templateId!,
      },
    },
    onError: err => {
      toast.error(err.message)
    },
    onCompleted: () => {
      toast.success(t('form.deleted', { itemName: name }))
    },
    update: (proxy, { data }) => {
      // update cache
      if (!data?.deleteTemplateTreatment || !templateId) return

      const result = proxy.readQuery<getTemplate, getTemplateVariables>({
        query: GET_TEMPLATE,
        variables: {
          id: templateId,
          organisation_id: organisationId,
        },
      })

      if (!result?.getTemplate?.treatments?.items) {
        return
      }

      const newCacheResult = cloneDeep(result)
      if (!newCacheResult?.getTemplate?.treatments?.items) {
        return
      }

      newCacheResult.getTemplate.treatments.items =
        newCacheResult.getTemplate.treatments.items.map(treatmentGroup => {
          if (treatmentGroup.treatments?.items) {
            treatmentGroup.treatments.items =
              treatmentGroup.treatments.items.filter(t => t.id !== id)
          }
          return treatmentGroup
        })

      proxy.writeQuery({
        query: GET_TEMPLATE,
        variables: {
          id: templateId,
          organisation_id: organisationId,
        },
        data: newCacheResult,
      })
    },
  })

  const isMultiProducts = (treatment_options?.products?.length ?? 0) > 1

  const renderScheduleInfo = () => {
    const schedule = templateTreatment.schedule
    if (!schedule) {
      return (
        <Text style={styles.NoScheduleText}>
          {t('template:treatment.noTaskSchedule')}
        </Text>
      )
    }

    return (
      <>
        <Text style={styles.scheduleText}>{`${t(
          'addTreatment:schedule.startAt',
        )}: `}</Text>
        <Text style={styles.scheduleBoldText}>
          {formatStartAt(schedule.start_at)}
        </Text>

        {!!schedule.time_window && (
          <>
            <Text style={styles.verticalDivider}>|</Text>
            <Text style={styles.scheduleText}>{`${t(
              'template:treatment.treatmentWindow',
            )}: `}</Text>
            <Text style={styles.scheduleBoldText}>
              {formatWindow(schedule.time_window)}
            </Text>
          </>
        )}

        {!!schedule.repeat && (
          <>
            <Text style={styles.verticalDivider}>|</Text>
            <Text style={styles.scheduleText}>{`${t(
              'addTreatment:repeating',
            )}: `}</Text>
            <Text style={styles.scheduleBoldText}>
              {isMinsFromMidnightType
                ? formatTreatmentFrequency(
                    frequencyLabel!,
                    schedule.repeat_until!,
                    !treatmentFrequency,
                  )
                : formatRepeat(schedule.frequency!, schedule.repeat_until!)}
            </Text>
          </>
        )}
      </>
    )
  }

  const onPressItem = useCallback(() => {
    if (removing) {
      return
    }
    onPress({ id, name })
  }, [id, name, onPress, removing])

  return (
    <TouchableOpacity
      onPress={onPressItem}
      style={styles.container}
      testID={name}
    >
      <View style={styles.nameContainer}>
        <View style={styles.nameRow}>
          <TouchableOpacity style={styles.draggable} onPressIn={onDragPress}>
            <SvgHamburger />
          </TouchableOpacity>

          <TextWithTooltip style={styles.title} numberOfLines={1} title={name}>
            {name}
          </TextWithTooltip>
        </View>
        {templateTreatment.isAvailable ? (
          <>
            <View style={styles.separatorRow}>
              <View style={styles.separator} />
            </View>

            <View style={styles.scheduleRow}>
              <View style={styles.scheduleInfo}>{renderScheduleInfo()}</View>

              <View style={styles.icon}>
                <SvgArrow orient="right" color="grey" width={15} height={15} />
              </View>
            </View>
          </>
        ) : (
          <View style={styles.availableText}>
            <Text style={styles.unavailableText}>
              {isMultiProducts
                ? t('template:form.multiProductsUnavailable')
                : t('template:form.productUnavailable')}
            </Text>
          </View>
        )}
      </View>
      {templateTreatment.isAvailable ? null : (
        <View style={styles.removeButtonContainer}>
          <TouchableOpacity
            onPress={() => deleteTemplateTreatment()}
            disabled={removing}
          >
            <View style={styles.removeButton}>
              {removing ? (
                <ActivityIndicator size="small" />
              ) : (
                <Text numberOfLines={1} style={styles.buttonRemoveText}>
                  {t('general.remove')}
                </Text>
              )}
            </View>
          </TouchableOpacity>
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 4,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderGrey,
    backgroundColor: Colors.white,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nameContainer: { flexDirection: 'column' },
  draggable: {
    padding: 12,
  },
  icon: {
    padding: 16,
    maxWidth: '12%',
  },
  title: {
    fontFamily: Fonts.regular,
    fontSize: 16,
    flex: 1.5,
  },
  nameRow: {
    flexDirection: 'row',
    height: 60,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  separatorRow: {
    paddingLeft: 35,
    paddingRight: 10,
  },
  separator: {
    borderTopWidth: 1,
    borderTopColor: '#F4F4F6',
  },
  scheduleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 46,
  },
  availableText: {
    paddingLeft: 42.5,
    marginTop: -18,
    paddingBottom: 18,
  },
  scheduleInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  verticalDivider: {
    fontFamily: Fonts.regular,
    color: Colors.contentTertiary,
    paddingHorizontal: 8,
  },
  NoScheduleText: {
    fontFamily: Fonts.regular,
    color: Colors.disabled,
  },
  scheduleText: {
    fontFamily: Fonts.regular,
  },
  scheduleBoldText: {
    fontFamily: Fonts.bold,
  },
  removeButton: {
    justifyContent: 'center',
    minWidth: 80,
    backgroundColor: Colors.white,
    borderRadius: 5,
    borderColor: Colors.blue,
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderWidth: 1,
    alignItems: 'center',
  },

  buttonRemoveText: {
    color: Colors.blue,
    fontFamily: Fonts.bold,
  },

  removeButtonContainer: {
    alignItems: 'center',
    marginRight: 30,
  },
  unavailableText: { color: Colors.error },
})
