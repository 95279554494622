import React, { useMemo } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { ClipButton } from 'components/common/ClipButton'
import { ApprovalStatus } from 'types/globalTypes'

type SetFilterStateType = {
  attendingVets: (vets: string[]) => void
  attendingVetTechs: (vetTechs: string[]) => void
  approvalStatuses: (approvalStatuses: ApprovalStatus[]) => void
  locations: (locations: string[]) => void
  sites: (departments: string[]) => void
  searchQuery: React.Dispatch<React.SetStateAction<string>>
}

type FilterStateType = {
  searchQuery: string
  attendingVets: string[]
  attendingVetTechs: string[]
  approvalStatuses: ApprovalStatus[]
  approvalStatusName: string
  attendingVetName: string
  attendingVetTechName: string
  locations: string[]
  locationName: string
  sites: string[]
  siteName: string
}

type ClearButtonType = {
  setFilterState: SetFilterStateType
  filterState: FilterStateType
}

export const FilterClearButtons: React.FC<ClearButtonType> = ({
  setFilterState,
  filterState,
}) => {
  const { t } = useTranslation()

  const attendingVetTitle = useMemo(() => {
    if (
      filterState.attendingVets.length === 1 &&
      filterState.attendingVetName
    ) {
      return filterState.attendingVetName
    }
    return t('patient:filter:doctorWithCount', {
      count: filterState.attendingVets.length,
    })
  }, [filterState.attendingVetName, filterState.attendingVets.length, t])

  const attendingVetTechTitle = useMemo(() => {
    if (
      filterState.attendingVetTechs.length === 1 &&
      filterState.attendingVetTechName
    ) {
      return filterState.attendingVetTechName
    }
    return t('patient:filter:vetTechWithCount', {
      count: filterState.attendingVetTechs.length,
    })
  }, [
    filterState.attendingVetTechName,
    filterState.attendingVetTechs.length,
    t,
  ])

  const approvalStatusTitle = useMemo(() => {
    if (
      filterState.approvalStatuses.length === 1 &&
      filterState.approvalStatusName
    ) {
      return filterState.approvalStatusName
    }
    return t('patient:filter:approvalStatusWithCount', {
      count: filterState.approvalStatuses.length,
    })
  }, [filterState.approvalStatusName, filterState.approvalStatuses.length, t])

  const departmentTitle = useMemo(() => {
    if (filterState.sites.length === 1 && filterState.siteName) {
      return filterState.siteName
    }
    return t('patient:filter:departmentWithCount', {
      count: filterState.sites.length,
    })
  }, [filterState.siteName, filterState.sites.length, t])

  const locationTitle = useMemo(() => {
    if (filterState.locations.length === 1 && filterState.locationName) {
      return filterState.locationName
    }
    return t('patient:filter:locationWithCount', {
      count: filterState.locations.length,
    })
  }, [filterState.locationName, filterState.locations.length, t])

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={styles.scrollContainer}
    >
      <View style={styles.clearButtonsContainer}>
        {filterState.attendingVets?.length ? (
          <ClipButton
            style={styles.clearButton}
            onPress={() => setFilterState.attendingVets([])}
            title={attendingVetTitle}
          />
        ) : null}
        {filterState.attendingVetTechs?.length ? (
          <ClipButton
            style={styles.clearButton}
            onPress={() => setFilterState.attendingVetTechs([])}
            title={attendingVetTechTitle}
          />
        ) : null}
        {filterState.approvalStatuses?.length ? (
          <ClipButton
            style={styles.clearButton}
            onPress={() => setFilterState.approvalStatuses([])}
            title={approvalStatusTitle}
          />
        ) : null}
        {filterState.sites?.length ? (
          <ClipButton
            style={styles.clearButton}
            onPress={() => setFilterState.sites([])}
            title={departmentTitle}
          />
        ) : null}
        {filterState.locations?.length ? (
          <ClipButton
            style={styles.clearButton}
            onPress={() => setFilterState.locations([])}
            title={locationTitle}
          />
        ) : null}
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  scrollContainer: {
    overflow: 'hidden',
  },
  clearButtonsContainer: {
    flexDirection: 'row',
  },
  clearButton: {
    marginLeft: 10,
  },
})
