import React from 'react'
import { noop } from 'lodash'
import { Linking, TouchableOpacity } from 'react-native'

type Props = {
  children: React.ReactNode
  phoneNumber: string | null | undefined
}

export const PhoneLinking: React.FC<Props> = ({ children, phoneNumber }) => {
  const pressPhoneNumber = () => {
    if (phoneNumber) {
      const url = `tel:${phoneNumber}`
      Linking.openURL(url).catch(noop)
    }
  }
  return (
    <TouchableOpacity onPress={pressPhoneNumber}>{children}</TouchableOpacity>
  )
}
