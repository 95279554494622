import { BundleTreatmentFormInput } from 'components/AddTreatment/Bundles/AddBundleForm'
import { RepeatScheduleValue } from 'components/TreatmentForm/data'
import { getTreatmentInput } from 'components/TreatmentForm/utils/getTreatmentInput'

export const formInputToTreatmentInput = (
  form: BundleTreatmentFormInput,
  organisationId: string,
  patientId: string,
  sheetId: string,
) => {
  if (!form.productId) throw new Error('No product id')

  const defaultInput = {
    product_id: form.productId,
    should_create_treatment: form.shouldCreateTreatment,
  }

  if (!form.shouldCreateTreatment || !form?.createTreatmentFromInput?.isSetup) {
    return defaultInput
  }

  const treatmentInput = {
    input: {
      ...getTreatmentInput(
        // @ts-ignore
        form.createTreatmentFromInput.defaultOverrides,
        form.createTreatmentFromInput.product.type ?? undefined,
      ),
      product_id: form.createTreatmentFromInput.product.id,
      organisation_id: organisationId,
      patient_id: patientId,
      sheet_id: sheetId,
    },
    options: {
      createTasks:
        form.createTreatmentFromInput.defaultOverrides.isRepeating !==
        RepeatScheduleValue.NONE,
    },
  }

  return {
    ...defaultInput,
    treatment_input: treatmentInput,
  }
}
