import { SelectOption } from 'src/components/common/Select/Select.types'

export interface VitalOptionsT {
  [key: string]: SelectOption<string>[]
}
/*
  The keys below are the origin IDs assigned to the standard products by the seed_products script 
  in packages/core/__seed_data__/seed_products.json of Platform repo. If these are changed, VitalOptions
  will need to be updated.
*/
export const VitalOptions: VitalOptionsT = {
  // Attitude
  'VR-10007': [
    {
      value: 'BAR',
      text: 'BAR',
    },
    {
      value: 'QAR',
      text: 'QAR',
    },
    {
      value: 'Comatose',
      text: 'Comatose',
    },
    {
      value: 'Sleeping',
      text: 'Sleeping',
    },
    {
      value: 'Vocalizing',
      text: 'Vocalizing',
    },
    {
      value: 'Sedate',
      text: 'Sedate',
    },
  ],
  // Mucous Membranes
  'VR-10009': [
    {
      value: 'Pink',
      text: 'Pink',
    },
    {
      value: 'Pale Pink',
      text: 'Pale Pink',
    },
    {
      value: 'Pigmented',
      text: 'Pigmented',
    },
    {
      value: 'Tacky',
      text: 'Tacky',
    },
    {
      value: 'Blue',
      text: 'Blue',
    },
    {
      value: 'Grey',
      text: 'Grey',
    },
    {
      value: 'White',
      text: 'White',
    },
    {
      value: 'Pale',
      text: 'Pale',
    },
    {
      value: 'Yellow',
      text: 'Yellow',
    },
    {
      value: 'Injected',
      text: 'Injected',
    },
    {
      value: 'Red',
      text: 'Red',
    },
  ],
  // CRT
  'VR-10008': [
    {
      value: '<1 sec',
      text: '<1 sec',
    },
    {
      value: '1-2 sec',
      text: '1-2 sec',
    },
    {
      value: '>2 sec',
      text: '>2 sec',
    },
  ],
  // Pulse Quality
  'VR-10003': [
    {
      value: 'Normal',
      text: 'Normal',
    },
    {
      value: 'Bounding',
      text: 'Bounding',
    },
    {
      value: 'Fair',
      text: 'Fair',
    },
    {
      value: 'Difficult to Palpate',
      text: 'Difficult to Palpate',
    },
    {
      value: 'Weak',
      text: 'Weak',
    },
    {
      value: 'No Pulse Detected',
      text: 'No Pulse Detected',
    },
    {
      value: 'Dropped Pulse',
      text: 'Dropped Pulse',
    },
  ],
  // Respiratory Effort
  'VR-10006': [
    {
      value: 'Normal',
      text: 'Normal',
    },
    {
      value: 'Shallow',
      text: 'Shallow',
    },
    {
      value: 'Laboured Breathing',
      text: 'Laboured Breathing',
    },
  ],
}

export const VitalRanges: { [key: string]: string } = {
  'Pain Score': '/4',
  'Dental Score': '/4',
  BCS: '/5',
  'Body Condition Score': '/5',
}
