import { Task } from 'components/Task/types'
import { isValid } from 'date-fns'
import { getSheet_getSheet_treatments_items_treatments_items as Treatment } from 'src/types/getSheet'
import { notEmpty } from 'src/utils/notEmpty'
import { AnesthesiaChartValueData } from '../types/anesthesia'
import { Status } from 'src/types/globalTypes'
import { sortBy } from 'lodash'

export const mapTreatmentToChartData = (
  treatment: Treatment,
): AnesthesiaChartValueData[] => {
  return sortBy(
    treatment.tasks?.items
      ?.map((task: Task) => {
        if (task.status !== Status.DONE) return
        if (!task.start_at || !task.value) return
        if (!isFinite(+task.value)) return

        const parsedDate = new Date(task.start_at)
        if (!isValid(parsedDate)) return
        return {
          time: parsedDate,
          value: +task.value,
        }
      })
      .filter(notEmpty) ?? [],
    'time',
  )
}
