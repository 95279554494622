import React from 'react'
import { Dialog } from 'components/common/Dialog/Dialog'
import { HelpModalBody } from 'components/Help/HelpModalBody'
import { HelpModalFooter } from 'components/Help/HelpModalFooter'
import { useTranslation } from 'react-i18next'

type Props = {
  toggleDialog: () => void
  visible: boolean
  feedback: string
  onFeedbackChange: (_: string) => void
  onSendFeedback: () => void
  shouldDisableSendFeedbackButton?: boolean
}

export const HelpDialog: React.FC<Props> = ({
  toggleDialog,
  visible,
  feedback,
  onSendFeedback,
  onFeedbackChange,
  shouldDisableSendFeedbackButton,
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      title={t('help:titleIOS')}
      showDone={false}
      showCancel={true}
      toggleDialog={toggleDialog}
      visible={visible}
      onCancel={toggleDialog}
    >
      <HelpModalBody
        onCloseModal={toggleDialog}
        feedback={feedback}
        onFeedbackChange={onFeedbackChange}
      />
      <HelpModalFooter
        onCloseModal={toggleDialog}
        onSendFeedback={onSendFeedback}
        sendFeedbackButtonDisabled={shouldDisableSendFeedbackButton}
      />
    </Dialog>
  )
}
