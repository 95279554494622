import { useUpdatePatientListCache } from 'components/Patient/useUpdatePatientListCache'
import { useMutation } from '@apollo/client'
import {
  updatePatient as UpdatePatient,
  updatePatientVariables as UpdatePatientVariables,
} from 'types/updatePatient'
import { UPDATE_PATIENT } from 'components/Patient/graphql'
import { toast } from 'components/common'
import { Patient } from 'components/PatientItem/PatientListItem'
import { useOrganisation } from 'src/context/organisation'
import { useTranslation } from 'react-i18next'

export const useUpdatePatientImage = (patient?: Patient) => {
  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()
  const updatePatientListCache = useUpdatePatientListCache()

  const [updatePatient] = useMutation<UpdatePatient, UpdatePatientVariables>(
    UPDATE_PATIENT,
  )

  const handleAvatarUpdate = async (avatar: string) => {
    if (!patient) return
    try {
      const response = await updatePatient({
        variables: {
          input: {
            id: patient.id,
            organisation_id: organisationId,
            avatar_url: avatar,
          },
        },
      })
      toast.success(t('patient:imageUpload.success'))
      return response.data?.updatePatient ?? null
    } catch (error) {
      if (error instanceof Error) toast.error(error.message)
      throw error
    }
  }

  const handlePatientImageUpdate = async (
    value: string,
    callback?: () => void,
  ) => {
    if (!handleAvatarUpdate) return
    const updatePatient = await handleAvatarUpdate(value)
    if (updatePatient) updatePatientListCache(updatePatient, 'UPDATE')
    if (callback) callback()
  }

  const objectKeyPrefix = `${organisationId}/patient/${patient?.id}`

  return {
    handlePatientImageUpdate,
    objectKeyPrefix,
  }
}
