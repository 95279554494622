import { useApolloClient } from '@apollo/client'
import { SHEET_HEADER_FIELDS } from './graphql'
import { SheetHeaderFields } from 'types/SheetHeaderFields'

export const useSheetHeader = (sheetId: string) => {
  const client = useApolloClient()

  return client.readFragment<SheetHeaderFields>({
    id: `Sheet:${sheetId}`,
    fragment: SHEET_HEADER_FIELDS,
    fragmentName: 'SheetHeaderFields',
  })
}
