import React, { useCallback, useMemo } from 'react'
import {
  Patient,
  PatientListItem,
} from 'components/PatientItem/PatientListItem'
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'
import {
  Resuscitation,
  Attitude,
  ConsultationStatus,
} from 'src/types/globalTypes'
import { useOrganisation } from 'src/context/organisation'
import { WhiteboardListWorkflowItems } from '../Patient/WhiteboardListWorkflowItems'
import { PATIENT_CARD_HEIGHT } from 'components/Patient/PatientListCard'
import { isEqual } from 'lodash'
import type { ListWhiteboardWorkflowsItem } from './useGetWhiteboardPatientList'

export type OnPressPatientListCardParams = {
  isAnesthesia?: boolean
  patientId: string
  sheetId?: string
  sheetName?: string
}

type Props = {
  item: ListWhiteboardWorkflowsItem
  onPress: (params: OnPressPatientListCardParams) => void
  isSortByPatientOrder?: boolean
  isListLoading: boolean
  setTimestamp: (timestamp: number) => void
  onPressKebab: (patient: Patient) => void
}

export const WhiteboardPatientListItem: React.FC<Props> = React.memo(
  ({
    item,
    onPress,
    isSortByPatientOrder = false,
    isListLoading,
    setTimestamp,
    onPressKebab,
  }) => {
    const [{ organisationId }] = useOrganisation()

    const whiteboardPatient = item.patient
    const workflowItems = item.workflow_items
    const patient: Patient = useMemo(
      () => ({
        id: whiteboardPatient.patient_id,
        name: whiteboardPatient.patient_name,
        sex: whiteboardPatient.patient_sex,
        species: whiteboardPatient.patient_species,
        breed: whiteboardPatient.patient_breed,
        attitude: whiteboardPatient.patient_attitude as Attitude,
        avatar_url: whiteboardPatient.patient_avatar_url,
        order: whiteboardPatient.patient_order,
        color: whiteboardPatient.patient_color,
        primary_location: {
          location_display: whiteboardPatient.patient_location_primary_display,
        },
        contact: {
          first_name: whiteboardPatient.contact_first_name,
          last_name: whiteboardPatient.contact_last_name,
          phone_number: whiteboardPatient.contact_phone_number,
        },
        weight: whiteboardPatient.patient_weight,
        weight_unit: whiteboardPatient.patient_weight_unit,
        date_of_birth: whiteboardPatient.patient_date_of_birth, // toIOS?
        resuscitate: whiteboardPatient.patient_resuscitate as Resuscitation,
        latest_active_sheet_id: whiteboardPatient.latest_active_sheet_id,
        presentingProblems: [
          ...whiteboardPatient.presenting_problems,
          { name: whiteboardPatient.consultation_description },
        ]
          .filter(p => p.name)
          .map(p => p.name)
          .join(' . '),
        critical_notes: whiteboardPatient.patient_critical_notes,
        consultation_id: whiteboardPatient.consultation_id,
        isActive:
          whiteboardPatient.consultation_status === ConsultationStatus.admitted,
        attending_vet_id: whiteboardPatient.attending_vet_id,
        attending_vet_display_name:
          whiteboardPatient.attending_vet_display_name,
        attending_vet_tech_id: whiteboardPatient.attending_vet_tech_id,
        attending_vet_tech_display_name:
          whiteboardPatient.attending_vet_tech_display_name,
        consultation_locations: whiteboardPatient.consultation_locations,
        consultation_color: whiteboardPatient.consultation_color,
        has_unapproved_sheet: whiteboardPatient.has_unapproved_sheet,
      }),
      [whiteboardPatient],
    )

    const patientId = patient.id
    const { isExSmallScreen, isSmallScreen } = useBreakpoint()

    const onPressHandler = useCallback(async () => {
      const sheetId = whiteboardPatient.latest_active_sheet_id

      if (sheetId) {
        onPress({
          patientId,
          sheetId,
        })
      } else {
        onPress({ patientId })
      }
    }, [whiteboardPatient.latest_active_sheet_id, onPress, patientId])

    const touchStyles = useMemo(
      () => [
        styles.touchableContainer,
        (isExSmallScreen || isSmallScreen) && styles.flex,
      ],
      [isExSmallScreen, isSmallScreen],
    )

    return (
      <View style={styles.rowContainer}>
        <PatientListItemPressable
          onPressHandler={onPressHandler}
          touchStyles={touchStyles}
          isSortByPatientOrder={isSortByPatientOrder}
          missedTasksCount={whiteboardPatient.missed_tasks ?? 0}
          patient={patient}
          onPressKebab={onPressKebab}
        />

        {!isExSmallScreen && !isSmallScreen && (
          <WhiteboardListWorkflowItems
            criticalNotes={whiteboardPatient.patient_critical_notes}
            organisationId={organisationId}
            items={workflowItems}
            isListLoading={isListLoading}
            isActive={patient.isActive}
            setTimestamp={setTimestamp}
          />
        )}
      </View>
    )
  },
  isEqual, // Warning, risky but worthwhile here. see: https://react.dev/reference/react/memo#specifying-a-custom-comparison-function
)

WhiteboardPatientListItem.displayName = 'WhiteboardListItem'

type PatientListItemPressableProps = {
  onPressHandler: () => Promise<void>
  touchStyles: StyleProp<ViewStyle>
  isSortByPatientOrder: boolean
  patient: Patient
  missedTasksCount: number
  onPressKebab: (patient: Patient) => void
}

const PatientListItemPressable: React.FC<PatientListItemPressableProps> =
  React.memo(
    ({
      onPressHandler,
      touchStyles,
      isSortByPatientOrder,
      patient,
      missedTasksCount,
      onPressKebab,
    }) => {
      return (
        <TouchableOpacity
          delayLongPress={1500}
          onPress={onPressHandler}
          style={touchStyles}
        >
          <PatientListItem
            isSortByPatientOrder={isSortByPatientOrder}
            missedTasksCount={missedTasksCount}
            patient={patient}
            onPressKebab={onPressKebab} // TODO fix this - left empty for PR
          />
        </TouchableOpacity>
      )
    },
  )

PatientListItemPressable.displayName = 'PatientListItemPressable'

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    height: PATIENT_CARD_HEIGHT,
  },
  touchableContainer: {
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
})
