import React, { useState, useCallback, useMemo } from 'react'
import { StyleSheet, Text, FlatList } from 'react-native'
import { useTranslation } from 'react-i18next'

import { FormBreak } from 'components/common'
import { Center } from 'src/components/common/Center'
import { SearchInput } from 'src/components/common/SearchInput'
import { Colors } from 'src/constants/Colors'
import { Fonts } from 'constants/Fonts'
import { SCROLL_VIEW_FIRST_INDEX_STICKY } from 'constants/Layout'
import { UserItem } from 'components/Settings/UserItem'
import { sortList } from 'src/utils/sortList'
import { searchInList } from 'src/utils/searchInList'
import { SwitchableUser } from 'src/utils/pinSwitchService'

type Props = {
  users: SwitchableUser[]
  onSelect: (user: SwitchableUser) => void
}

const userListKeyExtractor = (item: SwitchableUser) => item.email

export const PinSwitchUserList: React.FC<Props> = ({ users, onSelect }) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')

  const filteredUsers = useMemo(
    () => searchInList(sortList(users, 'username'), 'username', searchText),
    [users, searchText],
  )

  const renderListHeader = useCallback(
    () => (
      <Center style={styles.searchContainer}>
        <SearchInput onChangeText={setSearchText} />
      </Center>
    ),
    [],
  )

  const renderListFooter = useCallback(() => {
    const noSearchResult = filteredUsers.length === 0
    if (!noSearchResult) return null
    return (
      <Text style={styles.noSearchResult}>
        {t('account:general.noSearchResult')}
      </Text>
    )
  }, [filteredUsers.length, t])

  const renderItem = useCallback(
    ({ item }: { item: SwitchableUser }) => {
      return <UserItem name={item.username} onPress={() => onSelect(item)} />
    },
    [onSelect],
  )

  return (
    <FlatList
      data={filteredUsers}
      ItemSeparatorComponent={FormBreak}
      keyExtractor={userListKeyExtractor}
      ListHeaderComponent={renderListHeader}
      ListFooterComponent={renderListFooter}
      renderItem={renderItem}
      stickyHeaderIndices={SCROLL_VIEW_FIRST_INDEX_STICKY}
    />
  )
}

const styles = StyleSheet.create({
  searchContainer: {
    paddingVertical: 16,
    backgroundColor: Colors.backgroundGrey,
  },
  noSearchResult: {
    textAlign: 'center',
    marginVertical: 60,
    fontFamily: Fonts.regular,
    fontSize: 15,
    color: '#3d3d3d',
  },
})
