import React from 'react'
import {
  TouchableOpacity,
  GestureResponderEvent,
  ViewStyle,
  StyleProp,
} from 'react-native'
import { SvgArrow } from 'components/Icons'
import { Colors } from 'constants/Colors'
import { Orient } from 'components/Icons/Arrow'

type Props = {
  a11yLabel?: string
  onPress: (event: GestureResponderEvent) => void
  orient: Orient
  color?: string
  style?: StyleProp<ViewStyle>
  testID?: string
  height?: number
  width?: number
  disabled?: boolean
}

const HIT_SLOP = { top: 5, bottom: 5, left: 15, right: 15 }

export const TouchableArrow: React.FC<Props> = React.memo(
  ({
    a11yLabel,
    onPress,
    orient,
    color,
    style,
    testID,
    height = 11,
    width = 11,
    disabled = false,
  }) => {
    const arrowColor = color || Colors.contentSecondary
    return (
      <TouchableOpacity
        testID={testID}
        accessibilityLabel={a11yLabel}
        onPress={onPress}
        style={style}
        disabled={disabled}
        hitSlop={HIT_SLOP}
      >
        <SvgArrow
          color={arrowColor}
          height={height}
          orient={orient}
          width={width}
        />
      </TouchableOpacity>
    )
  },
)

TouchableArrow.displayName = 'TouchableArrow'
