import { ApolloQueryResult, useSubscription } from '@apollo/client'
import { TREATMENT_BASE_WITH_TASKS } from 'components/AddTreatment/graphql'
import { Task } from 'components/Task/types'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { isWithinInterval } from 'date-fns'
import { cloneDeep } from 'lodash'
import flagsmith from 'react-native-flagsmith'
import { FromToQueryDate } from 'src/hocs/timeContext'
import { MutationType, Status } from 'src/types/globalTypes'
import {
  task as TaskSub,
  task_task as TaskSubResult,
  task_task_data,
} from 'src/types/task'
import {
  TreatmentBaseWithTasks,
  TreatmentBaseWithTasks_tasks_items,
} from 'src/types/TreatmentBaseWithTasks'
import {
  getSheet as GetSheet,
  getSheetVariables as GetSheetVariables,
} from 'types/getSheet'

import { SUBSCRIBE_TASK } from '../Task/graphql'

const shouldTaskUpdateBySubscription = (
  mutation: MutationType,
  subscriptionTask: task_task_data,
  tasks: TreatmentBaseWithTasks_tasks_items[],
) => {
  // MutationType is not UPDATE,
  if (mutation !== MutationType.UPDATE) {
    return true
  }

  const oldTask = tasks.find(item => item.id === subscriptionTask.id)
  // cannot find the task
  if (!oldTask) {
    return true
  }

  // not a task transition
  if (
    subscriptionTask.status !== Status.DUE &&
    subscriptionTask.status !== Status.MISSED
  ) {
    return true
  }

  // the old task can do a transition
  if (
    oldTask.status !== Status.DONE &&
    oldTask.status !== Status.IN_PROGRESS &&
    oldTask.status !== Status.MISSED_ON_PURPOSE
  ) {
    return true
  }

  // two tasks have updated_at fields to compare
  if (!oldTask?.updated_at || !subscriptionTask?.updated_at) {
    return true
  }

  // old task is higher status and later updated
  if (oldTask.updated_at > subscriptionTask.updated_at) {
    return false
  }

  return true
}

const getIsTaskInQueryTime = (task: Task, fromToQueryDate: FromToQueryDate) => {
  if (task?.start_at) {
    const { fromISODate, toISODate } = fromToQueryDate
    const taskStart = new Date(task.start_at)

    return isWithinInterval(taskStart, {
      start: new Date(fromISODate),
      end: new Date(toISODate),
    })
  }
  return false
}
type Refetch = (
  variables?: Partial<GetSheetVariables> | undefined,
) => Promise<ApolloQueryResult<GetSheet>>

type GetUpdateCacheOrRefetchProps = {
  cachedTreatment: TreatmentBaseWithTasks | null
  subscribeResult: TaskSubResult
  refetch: Refetch
}

export const getUpdateCacheOrRefetch = (data: GetUpdateCacheOrRefetchProps) => {
  const { cachedTreatment, subscribeResult, refetch } = data
  const { data: onCreateTask, mutation } = subscribeResult

  if (!cachedTreatment) {
    refetch()
    return
  }

  const clonedTreatment = cloneDeep(cachedTreatment)

  if (!clonedTreatment.tasks?.items) {
    return
  }
  const shouldUpdateTask = shouldTaskUpdateBySubscription(
    mutation,
    onCreateTask,
    clonedTreatment.tasks.items,
  )

  if (!shouldUpdateTask) {
    return clonedTreatment
  }

  clonedTreatment.tasks.items = clonedTreatment.tasks.items.filter(
    item => item.id !== onCreateTask.id,
  )

  if (mutation !== 'DELETE') {
    // Only push back to array if updating
    clonedTreatment.tasks.items.push({
      ...onCreateTask,
      qty_billed: onCreateTask.billing_reference?.billed_qty ?? null,
    })
  }

  if (flagsmith.hasFeature(FeatureFlagNames.ShowDeletedOnPurpose)) {
    // For VR-3986, delete rescheduled task
    clonedTreatment.tasks.items = clonedTreatment.tasks.items.filter(
      item => item.status !== Status.DELETED_ON_PURPOSE,
    )
  }

  return clonedTreatment
}

export const useTaskSubscription = (
  sheetId: string,
  refetch: Refetch,
  fromToQueryDate: FromToQueryDate,
  isOptimisticUpdating: boolean,
) => {
  useSubscription<TaskSub>(SUBSCRIBE_TASK, {
    variables: { sheetId },
    fetchPolicy: 'no-cache', // without this, apollo will auto update a task by subscription
    onData: options => {
      const client = options.client
      if (isOptimisticUpdating) {
        // Subscription may come back earlier than addTemplateToSheet mutation,
        // resulting sheet refreshed before the creation is completed. To fix
        // VR-2364, ignore self triggered subscription.
        return
      }
      const subscribeResult = options.data?.data?.task
      if (!subscribeResult) {
        return
      }

      if (!getIsTaskInQueryTime(subscribeResult.data, fromToQueryDate)) {
        return
      }

      try {
        const treatmentId = subscribeResult.data.treatment_id
        const fragmentVariable = {
          fragment: TREATMENT_BASE_WITH_TASKS,
          fragmentName: 'TreatmentBaseWithTasks',
          id: `Treatment:${treatmentId}`,
          variables: fromToQueryDate,
        }

        const cachedTreatment =
          client.readFragment<TreatmentBaseWithTasks>(fragmentVariable)

        const clonedTreatment = getUpdateCacheOrRefetch({
          cachedTreatment,
          subscribeResult,
          refetch,
        })

        if (clonedTreatment) {
          client.writeFragment({ data: clonedTreatment, ...fragmentVariable })
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Task Subscription Cache update error', error)
      }
    },
  })
}
