import { FormBreak, SelectDateTimePicker, SwitchInput } from 'components/common'
import { WarningType } from 'components/common/DateTimePicker/index.types'
import {
  PickerType,
  SwitchInputDateTimePicker,
} from 'components/common/SwitchInput/SwitchInputDateTimePicker'
import { DEFAULT_INTERVAL_OPTION } from 'components/FrequencySelector/data'
import {
  FrequencyInput,
  FrequencySelector,
} from 'components/FrequencySelector/FrequencySelector'
import { DateTimePicker, NumberInput, SwitchTab } from 'components/shared'
import { DEFAULT_REPEATING_INTERVAL_4_HOURS } from 'components/TreatmentForm/data'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { startOfMinute } from 'date-fns'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { useStartTime } from 'src/hooks/useStartTime'

type SelectDateTimePickerValueType = React.ComponentProps<
  typeof SelectDateTimePicker
>['value']

export type Inputs = {
  isRepeating: boolean
  repeating: number
  frequencyInput: FrequencyInput
  repeatUntilDate: Exclude<SelectDateTimePickerValueType, 'now' | 'edit'>
}

type Props = {
  onRescheduleChange: (value: Date | number) => void
}

export type Ref = {
  handleSubmit: (fun: (data: Inputs) => void) => () => void
}

export const RescheduleDateTime = forwardRef<Ref, Props>(
  ({ onRescheduleChange }, ref) => {
    const { isExSmallScreen } = useBreakpoint()
    const { t } = useTranslation()
    const startTime = useStartTime()

    const defaultValues = {
      isRepeating: false,
      repeating: DEFAULT_REPEATING_INTERVAL_4_HOURS.value,
      frequencyInput: DEFAULT_INTERVAL_OPTION,
      repeatUntilDate: 'discharge',
    } as Inputs

    const { control, watch, handleSubmit } = useForm<Inputs>({
      defaultValues,
    })
    const isRepeating = watch('isRepeating', defaultValues.isRepeating)

    useImperativeHandle(ref, () => ({ handleSubmit }), [handleSubmit])

    const [selectedDate, setSelectedDate] = useState(startTime)
    const [shouldRescheduleWithTimeOff, setShouldRescheduleWithTimeOff] =
      useState(false)
    const [minutes, setMinutes] = useState(0)
    const [hours, setHours] = useState(0)

    useEffect(() => {
      if (shouldRescheduleWithTimeOff) {
        onRescheduleChange(hours * 60 + minutes)
      } else {
        onRescheduleChange(selectedDate)
      }
    }, [
      minutes,
      hours,
      shouldRescheduleWithTimeOff,
      selectedDate,
      onRescheduleChange,
    ])

    return (
      <View style={styles.container}>
        <View style={styles.separator} />
        <View>
          <Text style={styles.rescheduleText}>
            {t('task:taskTransition:form:rescheduleTask')}
          </Text>
        </View>
        <SwitchTab
          testID={'switch'}
          style={styles.switchTab}
          options={[
            t('task:taskTransition:form:offset'),
            t('task:taskTransition:form:date'),
          ]}
          value={shouldRescheduleWithTimeOff}
          onChange={setShouldRescheduleWithTimeOff}
        />
        {shouldRescheduleWithTimeOff ? (
          <View style={styles.timeWrapper}>
            <View
              style={isExSmallScreen ? styles.smallFlex : styles.regularFlex}
            >
              <NumberInput
                style={
                  isExSmallScreen
                    ? styles.smallNumberInput
                    : styles.regularNumberInput
                }
                label={'Hours'}
                value={hours}
                onChange={setHours}
                max={24}
              />
              <NumberInput
                label={'Minutes'}
                value={minutes}
                onChange={setMinutes}
                max={59}
              />
            </View>
          </View>
        ) : (
          <View style={styles.dateWrapper}>
            <View
              style={isExSmallScreen ? styles.smallWidth : styles.regularWidth}
            >
              <DateTimePicker
                onChange={date => {
                  setSelectedDate(startOfMinute(date))
                }}
                value={selectedDate}
                warningInfo={{ type: WarningType.TASK }}
              />
            </View>
          </View>
        )}

        <View style={styles.separator} />
        <View style={styles.repeatingWrapper}>
          <Text style={[styles.rescheduleText]}>
            {t('task:taskTransition:form:rescheduleRepeating')}
          </Text>
        </View>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              textStyle={[
                styles.formTextStyle,
                isExSmallScreen && styles.smallFormText,
              ]}
              style={styles.repeatingStyle}
              label={t('task:taskTransition:form:rescheduleRepeating')}
              onChangeValue={onChange}
              value={value}
            />
          )}
          name="isRepeating"
        />
        {isRepeating ? (
          <>
            <FormBreak />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <FrequencySelector
                  label={t('addTreatment:repeating')}
                  selected={value}
                  onChange={onChange}
                  onlyScheduleTasks={false}
                  style={styles.repeatingStyle}
                  labelStyle={styles.labelTextStyle}
                  textStyle={styles.formTextStyle}
                />
              )}
              name="frequencyInput"
            />
            <FormBreak />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <SwitchInputDateTimePicker
                  labelStyle={styles.labelTextStyle}
                  textStyle={styles.formTextStyle}
                  type={PickerType.DISCHARGE}
                  value={value as PickerType}
                  style={styles.repeatingStyle}
                  onChange={onChange}
                  switchLabel={t('addTreatment:schedule:repeatUntil')}
                  defaultTimeToStart={startTime}
                />
              )}
              name="repeatUntilDate"
            />
          </>
        ) : null}
      </View>
    )
  },
)

RescheduleDateTime.displayName = 'RescheduleDateTime'

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  rescheduleText: {
    color: Colors.vetRadarBlue,
    fontFamily: Fonts.semibold,
    fontSize: 16,
  },
  separator: {
    backgroundColor: Colors.shared.borderGrey,
    height: 1,
    marginVertical: 16,
  },
  switchTab: {
    marginVertical: 15,
  },
  dateWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
  },
  timeWrapper: {
    marginVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  repeatingStyle: {
    borderRadius: 4,
    borderColor: Colors.shared.borderGrey,
    borderWidth: 1,
    height: 50,
    borderLeftColor: Colors.shared.borderGrey,
  },
  repeatingWrapper: {
    marginBottom: 12,
  },
  formTextStyle: {
    color: Colors.contentSecondary,
  },
  smallFormText: {
    fontSize: 13,
  },
  labelTextStyle: {
    textTransform: 'uppercase',
    fontSize: 11,
    color: Colors.bulkTask.bulkGrey,
  },
  smallNumberInput: {
    marginBottom: 10,
  },
  regularNumberInput: {
    marginRight: 10,
  },
  smallFlex: {
    flexDirection: 'column',
  },
  regularFlex: {
    flexDirection: 'row',
  },
  smallWidth: {
    width: 230,
  },
  regularWidth: {
    width: 300,
  },
})
