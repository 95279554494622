import React from 'react'
import { BulkTaskActions } from 'components/BulkTask/BulkTaskActions'
import { BulkTasks } from 'components/Treatment/utils/getSubTreatmentsNearestFirstTasks'
import { useTranslation } from 'react-i18next'
import { SheetAwareSideDrawer } from 'components/common/SideDrawer/SheetAwareSideDrawer'

type Props = {
  sheetId: string
  visible: boolean
  toggleBulkTaskDrawer: () => void
  bulkTasksToComplete: BulkTasks | null
  patientId?: string
}

export const BulkTaskActionsDrawer: React.FC<Props> = React.memo(
  ({
    sheetId,
    toggleBulkTaskDrawer,
    visible,
    patientId = '',
    bulkTasksToComplete,
  }) => {
    const { t } = useTranslation()

    return (
      <SheetAwareSideDrawer
        visible={visible}
        title={t('bulkTask:drawer.title')}
        onClose={toggleBulkTaskDrawer}
      >
        <BulkTaskActions
          bulkTasks={bulkTasksToComplete}
          patientId={patientId}
          sheetId={sheetId}
          toggleDrawer={toggleBulkTaskDrawer}
        />
      </SheetAwareSideDrawer>
    )
  },
)

BulkTaskActionsDrawer.displayName = 'BulkTaskActionsDrawer'
