import { isEmpty, isObject } from 'lodash'

export const getUniqueKeyArgsFunction = (queryName: string) => {
  const result: string[] = []
  return function recursionUniqueKeyArgsString(
    variables: {
      [key: string]: any
    },
    parentKey?: string,
  ) {
    Object.keys(variables).forEach(key => {
      // remove cursor from the combined string
      if (key === 'cursor') {
        return
      }
      if (isEmpty(variables[key])) {
        return
      }

      if (isObject(variables[key])) {
        recursionUniqueKeyArgsString(variables[key], key)
        return
      }

      // result of site_id:["a","b"] and site_id:["b","a"] will be the same string
      let uniqueString = variables[key].toString()
      if (isNaN(+key)) {
        uniqueString = key + variables[key].toString()
      }
      if (parentKey) {
        uniqueString = parentKey + uniqueString
      }

      result.push(uniqueString)
    })

    // make sure the combined string is the same, when object sequence is different
    return queryName + result.sort().join('')
  }
}
