import { Animated, StyleSheet, Text } from 'react-native'
import React from 'react'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'
import { Shadows } from 'constants/Shadows'

type Props = {
  notes: string[]
  animatedValue?: Animated.Value
}

export const MinimisedCriticalNotesCard: React.FC<Props> = ({
  notes,
  animatedValue,
}: Props) => {
  // For legacy critical notes, if there are multiple then join the first two and show ellipses? Can probably remove.
  const joinedNotes = notes.slice(0, 2).join('\n').trim()
  const displayText = notes.length > 2 ? `${joinedNotes}...` : joinedNotes

  return (
    <>
      {!!displayText && (
        <Animated.View style={[styles.container, { opacity: animatedValue }]}>
          <Text style={styles.text} numberOfLines={2} ellipsizeMode={'tail'}>
            {displayText}
          </Text>
        </Animated.View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    ...Shadows.cardShadow,
    flex: 1,
    backgroundColor: Colors.patient.criticalNotes,
    paddingHorizontal: 11,
    paddingVertical: 8,
    borderRadius: 6,
    justifyContent: 'center',
  },
  text: {
    fontSize: 14,
    fontWeight: '700',
    fontFamily: Fonts.regular,
    color: Colors.white,
  },
})
