import React, { useCallback, useState } from 'react'
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { Colors } from 'constants/constants'
import { Fonts } from 'constants/Fonts'
import { SvgPencil } from 'src/components/Icons'

export const defaultTextAccessibilityLabel = 'Press to edit treatment name'
export const defaultInputAccessibilityLabel = 'Edit treatment name text input'

type Props = {
  onChange: (text: string) => void
  value: string
  textAccessibilityLabel?: string
  inputAccessibilityLabel?: string
}

type EditableTextContainerProps = {
  value: string
  isEditing: boolean
  onTextPress: () => void
  editingTextInput: React.ReactNode
  textAccessibilityLabel?: string
}

export const EditableTextContainer = ({
  value,
  isEditing,
  onTextPress,
  editingTextInput,
  textAccessibilityLabel = 'text name',
}: EditableTextContainerProps) => {
  return (
    <View style={styles.container}>
      {isEditing ? (
        <>{editingTextInput}</>
      ) : (
        <TouchableOpacity
          accessibilityLabel={textAccessibilityLabel}
          onPress={onTextPress}
          style={styles.textContainer}
        >
          <Text
            ellipsizeMode={'tail'}
            numberOfLines={1}
            style={[styles.text, styles.textShared]}
          >
            {value}
          </Text>
          <View style={styles.icon}>
            <SvgPencil />
          </View>
        </TouchableOpacity>
      )}
    </View>
  )
}

export const EditableText = ({
  value,
  onChange,
  textAccessibilityLabel = defaultTextAccessibilityLabel,
  inputAccessibilityLabel = defaultInputAccessibilityLabel,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false)

  const onTextInputBlur = useCallback(() => setIsEditing(false), [])
  const onTextPress = useCallback(() => setIsEditing(true), [])
  const editingTextInput = (
    <TextInput
      accessibilityLabel={inputAccessibilityLabel}
      autoFocus={true}
      editable={isEditing}
      onBlur={onTextInputBlur}
      onChangeText={onChange}
      style={[styles.editingInput, styles.textShared]}
      value={value}
    />
  )

  return (
    <EditableTextContainer
      value={value}
      textAccessibilityLabel={textAccessibilityLabel}
      isEditing={isEditing}
      onTextPress={onTextPress}
      editingTextInput={editingTextInput}
    />
  )
}

export const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    justifyContent: 'center',
    marginHorizontal: 10,
  },
  textShared: {
    color: Colors.contentSecondary,
    fontFamily: Fonts.regular,
    fontSize: 24,
    height: 40,
    paddingHorizontal: 12,
    paddingVertical: 6,
    textAlign: 'center',
  },
  editingInput: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.blue,
    width: '100%',
  },
  text: {
    maxWidth: '55%',
  },
  icon: {
    alignSelf: 'center',
    marginRight: 8,
  },
})
