import React from 'react'
import {
  FormLabel,
  Select,
  SelectDateTimePicker,
  Status,
} from 'src/components/common'
import {
  Control,
  Controller,
  FieldValues,
  UseFormTrigger,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  RepeatScheduleValue,
  timeWindowOptions,
} from 'components/TreatmentForm/data'
import { createErrorStatus } from 'components/common/TextInput/utils'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { useStartTime } from 'src/hooks/useStartTime'
import { SwitchInput } from 'components/common'
import {
  PickerType,
  SwitchInputDateTimePicker,
} from 'components/common/SwitchInput/SwitchInputDateTimePicker'
import { useOrgSettings } from 'src/hooks/useOrgSettings'
import {
  FrequencyInput,
  FrequencySelector,
} from 'components/FrequencySelector/FrequencySelector'
import { FrequencyType } from 'types/globalTypes'
import { HelpTooltip } from 'components/common/Tooltip/HelpTooltip'

type Props<ControlInput extends FieldValues> = {
  control: Control<ControlInput, any> // TODO: add better typings for Control
  errors?: FieldErrors
  isEdit?: boolean
  isFluidDosage?: boolean
  isMedication?: boolean
  isRepeating: RepeatScheduleValue
  frequencyInput?: FrequencyInput
  trigger: UseFormTrigger<any>
}

export function ScheduleTask<T extends FieldValues>({
  control,
  isRepeating,
  frequencyInput,
  isEdit,
  isFluidDosage = false,
  trigger,
  errors,
}: Props<T>) {
  const { t } = useTranslation()
  const defaultTimeToStart = useStartTime()
  const { settingsMap } = useOrgSettings()

  const showStaffedHoursToggle =
    settingsMap?.ENABLE_OPERATING_HOURS?.value === 'true' &&
    isRepeating === RepeatScheduleValue.REPEATING

  return (
    <>
      <FormLabel text={t('addTreatment:schedule:label')} />
      <Status
        status={createErrorStatus(
          // @ts-ignore TODO: investigate error typing and conversion with translation
          t(errors?.repeating?.message),
        )}
      >
        {!isFluidDosage && (
          <Controller
            control={control}
            name={'frequencyInput' as any}
            render={({ field: { onChange, value } }) => (
              <FrequencySelector
                label={t('addTreatment:schedule.taskScheduleV2')}
                selected={value}
                allowNoTasks={!isEdit}
                onChange={v => {
                  onChange(v)
                  trigger()
                }}
              />
            )}
          />
        )}
      </Status>
      {isRepeating !== RepeatScheduleValue.NONE && (
        <Controller
          control={control}
          name={'startAtDate' as any}
          render={({ field: { onChange, value } }) => (
            <SelectDateTimePicker
              type={isEdit ? 'edit' : 'now'}
              value={value}
              onChange={onChange}
              defaultTimeToStart={defaultTimeToStart}
              title={t('addTreatment:schedule:startsLabel')}
            />
          )}
        />
      )}
      {isRepeating === RepeatScheduleValue.REPEATING && !isFluidDosage && (
        <Controller
          control={control}
          name={'repeatUntilDate' as any}
          render={({ field: { onChange, value } }) => (
            <SwitchInputDateTimePicker
              type={PickerType.DISCHARGE}
              value={value}
              onChange={onChange}
              switchLabel={t('addTreatment:schedule:repeatUntil')}
              pickerLabel={t('addTreatment:schedule:repeatUntilDateTime')}
              defaultTimeToStart={defaultTimeToStart}
              title={t('addTreatment:schedule:endsLabel')}
            />
          )}
        />
      )}
      {isRepeating !== RepeatScheduleValue.NONE && !isFluidDosage && (
        <Controller
          control={control}
          name={'timeWindow' as any}
          render={({ field: { onChange, value } }) => (
            <Select
              label={t('addTreatment:timeWindow')}
              dialog={false}
              options={timeWindowOptions}
              selected={value}
              onChange={onChange}
            />
          )}
        />
      )}
      {showStaffedHoursToggle &&
      frequencyInput?.type !== FrequencyType.MINS_FROM_MIDNIGHT ? (
        <Controller
          control={control}
          name={'enableStaffedHour' as any}
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              label={t('addTreatment:staffedHoursOnly')}
              tooltip={
                <HelpTooltip text={t('addTreatment:staffedHoursOnlyTooltip')} />
              }
              value={!!value}
              onChangeValue={active => {
                onChange(active)
              }}
            />
          )}
        />
      ) : null}
    </>
  )
}
