import React from 'react'
import { Colors } from '../../constants/Colors'
import Svg, { G, Path } from 'react-native-svg'

export const SvgInformation: React.FC<{ color?: string }> = ({
  color = Colors.vetRadarGrey,
}) => (
  <Svg width="16" height="16" viewBox="0 0 16 16">
    <G fillRule="evenodd" clipRule="evenodd" fill={color}>
      <Path
        id="informationIcon"
        d="M8 0C3.58203 0 0 3.58332 0 8C0 12.4193 3.58203 16 8 16C12.418 16 16 12.4193 16 8C16 3.58332 12.418 0 8 0ZM8.00002 3.54839C8.74829 3.54839 9.35486 4.15497 9.35486 4.90323C9.35486 5.6515 8.74829 6.25807 8.00002 6.25807C7.25175 6.25807 6.64518 5.6515 6.64518 4.90323C6.64518 4.15497 7.25175 3.54839 8.00002 3.54839ZM9.41933 12.129C9.6331 12.129 9.80642 11.9557 9.80642 11.7419V10.9677C9.80642 10.7539 9.6331 10.5806 9.41933 10.5806H9.03223V7.35482C9.03223 7.14104 8.85891 6.96772 8.64513 6.96772H6.58062C6.36685 6.96772 6.19352 7.14104 6.19352 7.35482V8.12901C6.19352 8.34278 6.36685 8.51611 6.58062 8.51611H6.96772V10.5806H6.58062C6.36685 10.5806 6.19352 10.7539 6.19352 10.9677V11.7419C6.19352 11.9557 6.36685 12.129 6.58062 12.129H9.41933Z"
      />
    </G>
  </Svg>
)
