import { useEffect, useCallback, useState } from 'react'
import {
  TreatmentDrawerActionTypes,
  useTreatmentDrawerContext,
} from 'src/context/treatmentDrawer'

export function useBackButton<T>(
  selected: T,
  setSelected: (newSelected: T | null) => void,
) {
  const [_, treatmentDrawerDispatch] = useTreatmentDrawerContext()
  const [overrideCallback, handleBackOverrides] = useState<(() => void) | null>(
    null,
  )

  const dispatch = useCallback(treatmentDrawerDispatch, [
    treatmentDrawerDispatch,
  ])

  const handleBack = useCallback(() => {
    setSelected(null)
  }, [setSelected])

  useEffect(() => {
    const callback = overrideCallback ?? handleBack

    dispatch({
      type: TreatmentDrawerActionTypes.setHandleBack,
      setHandleBack: selected ? callback : null,
    })
  }, [selected, dispatch, handleBack, overrideCallback])

  // using handleBackOverrides to reuse the back button for different drawer navigation
  return handleBackOverrides
}
