import React from 'react'
import { SvgArrow } from 'components/Icons'

import { FormField } from '../Form/FormField'

export type Props = {
  label?: string
  onClick: () => any
  placeholder?: string
  selected: string[]
}

export const MultiSelectFormField: React.FC<Props> = ({
  label,
  onClick,
  placeholder = ' ',
  selected,
}) => {
  let formValue = placeholder
  if (selected && selected.length === 1) {
    formValue = selected[0]
  }
  if (selected && selected.length > 1) {
    formValue = `${selected.length} options`
  }
  return (
    <FormField
      active={false}
      label={label}
      onPress={onClick}
      value={formValue}
      icon={<SvgArrow orient="right" color="grey" width={15} height={15} />}
    />
  )
}
