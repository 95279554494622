import React, { useCallback } from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useAdminTimeFormat } from 'src/hooks/useAdminTimeFormat'
import { addHours, addMinutes, format } from 'date-fns'
import { SvgChevron } from 'components/Icons/Chevron'
import { Colors, Typography } from 'src/design-system/theme'
import { FontFamilies } from 'src/design-system/theme/typography/base'

type Props = {
  selectedTime: string
  updateSelectedTime: (time: string) => void
  disableNext?: boolean
  disablePrevious?: boolean
  incrementBy?: IncrementBy
}

export enum IncrementBy {
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
}

export enum UpdateTimeAction {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
}

export const TimeNavigator: React.FC<Props> = ({
  selectedTime,
  updateSelectedTime,
  disableNext = false,
  disablePrevious = false,
  incrementBy = IncrementBy.HOUR,
}) => {
  const { adminTimeFormat } = useAdminTimeFormat()

  const formatTime = useCallback(
    (time: string) => {
      return format(new Date(time), adminTimeFormat)
    },
    [adminTimeFormat],
  )

  const updateTime = (action: UpdateTimeAction) => {
    const amount = action === UpdateTimeAction.INCREMENT ? 1 : -1
    if (incrementBy === IncrementBy.HOUR) {
      updateSelectedTime(addHours(new Date(selectedTime), amount).toISOString())
    } else {
      updateSelectedTime(
        addMinutes(new Date(selectedTime), amount).toISOString(),
      )
    }
  }

  return (
    <View style={styles.container} testID={'time-navigator'}>
      <View style={styles.pressableContainer}>
        <Pressable
          accessibilityLabel={'Previous'}
          style={disablePrevious ? styles.disabled : null}
          onPress={() => updateTime(UpdateTimeAction.DECREMENT)}
          disabled={disablePrevious}
        >
          <SvgChevron
            color={Colors.Contents.primary}
            size={20}
            orient={'left'}
          />
        </Pressable>
      </View>

      <View style={styles.pressableContainer}>
        <Pressable
          accessibilityLabel={'Next'}
          style={disableNext ? styles.disabled : null}
          onPress={() => updateTime(UpdateTimeAction.INCREMENT)}
          disabled={disableNext}
        >
          <SvgChevron
            color={Colors.Contents.primary}
            size={20}
            orient={'right'}
          />
        </Pressable>
      </View>
      <Text testID={'startAtFormatted'} style={styles.timeText}>
        {formatTime(selectedTime)}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row' },
  pressableContainer: { margin: 11 },
  timeText: {
    ...Typography.Heading.XL,
    fontFamily: FontFamilies.bold,
    marginLeft: 4,
  },
  disabled: {
    opacity: 0.5,
  },
})
