import { TaskOmitTypename } from 'components/Task/utils/useCreateTask'
import { addMinutes, startOfMinute } from 'date-fns'
import { Status, TreatmentTaskType } from 'src/types/globalTypes'
import { getOptimisticId } from 'src/utils/optimisticId'

type CreateQuickAnesthesiaChartTask = (params: {
  patientId: string
  sheetId: string
  treatmentId: string
  selectedDate: Date
  unit: string
  value: string
}) => TaskOmitTypename

export const createQuickAnesthesiaChartTask: CreateQuickAnesthesiaChartTask = ({
  patientId,
  sheetId,
  selectedDate,
  treatmentId,
  unit,
  value,
}) => {
  const startAt = startOfMinute(selectedDate ?? new Date())
  const inFiveMins = addMinutes(startAt, 5)

  const quickAnesthesiaChartTask: TaskOmitTypename = {
    value,
    unit,
    assigned_user: null,
    buffer_due: null,
    buffer_missed: null,
    given_start_at: startAt.toISOString(),
    given_stop_at: inFiveMins.toISOString(),
    updated_at: '',
    user_updated_at: '',
    updated_by: '',
    created_at: '',
    attending_vet: '',
    attending_department: '',
    id: getOptimisticId(),

    medicine_dosage_info: null,
    notes: null,
    organisation_id: 'Test123',
    patient_id: patientId,
    send_to_adaptor: null,
    sheet_id: sheetId,
    start_at: startAt.toISOString(),
    status: Status.DONE,
    stop_at: inFiveMins.toISOString(),
    treatment_id: treatmentId,
    type: TreatmentTaskType.BASIC,
    photo_urls: null,
    videos: null,
    billing_reference: null,
    highlight_colour: null,
    cubex_transaction_status: null,
    qty_billed: null,
  }
  return quickAnesthesiaChartTask
}
