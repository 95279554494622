import React from 'react'
import { View, StyleSheet } from 'react-native'

type Props = {}

export const FormBreak: React.FC<Props> = () => {
  return <View style={styles.container} />
}

const styles = StyleSheet.create({
  container: {
    height: 16,
  },
})
