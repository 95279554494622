import React from 'react'
import {
  Pressable,
  Text,
  View,
  StyleSheet,
  Platform,
  Linking,
} from 'react-native'
import { SvgShare } from 'components/Icons/Share'
import { Colors, Typography, Variables } from 'src/design-system/theme'
import { useTranslation } from 'react-i18next'
import { useOrganisationHook } from 'src/hooks/useOrganisation'

type Props = {
  onCloseModal: () => void
}

export const navigateToHelpSite = (instanceUrl?: string | null) => {
  if (!instanceUrl) return

  const ezyVetHelpQueryParams = 'recordclass=HelpDoc&recordid=401368'
  const ezyVetUrl = `${instanceUrl}?${ezyVetHelpQueryParams}`

  if (Platform.OS === 'web') {
    window.open(ezyVetUrl, '_blank')
    return
  }
  return Linking.openURL(ezyVetUrl)
}

export const HelpDocumentationLink: React.FC<Props> = ({ onCloseModal }) => {
  const { t } = useTranslation()

  const { instanceUrl } = useOrganisationHook()

  const handleNavigate = () => {
    onCloseModal()
    navigateToHelpSite(instanceUrl)
  }

  return (
    <Pressable
      testID="test-help-documentation-link"
      onPress={handleNavigate}
      style={({ pressed }) => [
        styles.helpDocumentLink,
        pressed && {
          backgroundColor: Colors.Interactive.clickedHighlight,
          borderColor: Colors.Interactive.clickedAccent,
        },
      ]}
    >
      {({ pressed }) => (
        <View style={styles.helpDocumentLinkContainer}>
          <Text
            style={[
              styles.helpDocumentLinkText,
              pressed && { color: Colors.Interactive.clickedAccent },
            ]}
          >
            {t('help:documentLinkTitle')}
          </Text>
          <SvgShare
            fillColor={
              pressed
                ? Colors.Interactive.clickedAccent
                : Colors.Contents.accent
            }
          />
        </View>
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  helpDocumentLink: {
    width: '100%',
    borderWidth: Variables.BorderWidths.base,
    borderColor: Colors.Borders.accent,
    borderRadius: 4,
  },
  helpDocumentLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helpDocumentLinkText: {
    fontSize: Typography.FontSizes.base,
    lineHeight: 16,
    color: Colors.Contents.accent,
    fontWeight: Typography.FontWeights.bold,
    paddingVertical: Variables.GutterSpacing.base,
    marginRight: Variables.GutterSpacing.base,
  },
})
