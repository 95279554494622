import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, StyleSheet, TextInput } from 'react-native'
import { Colors, Variables } from 'src/design-system/theme'
import { HelpHeader } from './Header'
import { HelpModalFooter } from '../HelpModalFooter'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

type Props = {
  feedback: string
  onBack: () => void
  onClose: () => void
  onSendFeedback: () => void
  onFeedbackChange: (_: string) => void
  shouldDisableSendFeedbackButton?: boolean
}
export const FeedbackScreen: React.FC<Props> = ({
  feedback,
  onBack,
  onClose,
  onSendFeedback,
  onFeedbackChange,
  shouldDisableSendFeedbackButton,
}) => {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = () => {
    setIsFocused(false)
  }

  return (
    <View>
      <HelpHeader
        title={t('help:feedbackScreen:title')}
        onPress={onBack}
        icon="back"
      />
      <KeyboardAwareScrollView
        scrollEnabled={true}
        enableAutomaticScroll={true}
        viewIsInsideTabBar={true}
        extraScrollHeight={130}
      >
        <View style={styles.section}>
          <Text>{t('help:feedbackIntro')}</Text>
          <View style={styles.helpFormTextInputContainer}>
            <TextInput
              testID="test-feedback-input"
              onFocus={onFocus}
              onBlur={onBlur}
              value={feedback}
              onChangeText={onFeedbackChange}
              style={[
                styles.helpFormTextInput,
                isFocused && { borderColor: Colors.Borders.accent },
              ]}
              placeholder={t('help:feedbackInputPlaceholder')}
              placeholderTextColor={Colors.Contents.accessory}
              multiline={true}
            />
          </View>
        </View>
        <HelpModalFooter
          onCloseModal={onClose}
          onSendFeedback={onSendFeedback}
          sendFeedbackButtonDisabled={shouldDisableSendFeedbackButton}
        />
      </KeyboardAwareScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  section: {
    padding: Variables.GutterSpacing.md,
    borderColor: Colors.Borders.primary,
    gap: Variables.GutterSpacing.base,
    borderTopWidth: 1,
  },
  helpFormTextInputContainer: {
    marginTop: Variables.GutterSpacing.sm,
  },
  helpFormTextInput: {
    height: 124,
    padding: Variables.GutterSpacing.base,
    borderWidth: Variables.BorderWidths.base,
    borderColor: Colors.Borders.secondary,
    borderRadius: 4,
  },
})
