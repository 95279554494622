import React from 'react'
import { StyleSheet, View, ScrollView, Platform } from 'react-native'
import { useBreakpoint } from 'src/hocs/breakpoint'

type Props = {
  children: React.ReactNode
  // TODO: Add more modes when needed
  mode: 'single-center'
  testID?: string
}

export const Layout: React.FC<Props> = ({ children, testID }) => {
  const breakpoint = useBreakpoint()
  if (Platform.OS !== 'web' || !breakpoint.isLargeScreen) {
    return (
      <ScrollView testID={testID}>
        {/* Use View instead of padding in ScrollView to fix VR-2003. Don't know why!? */}
        <View style={styles.padding} />
        {children}
        <View style={styles.padding} />
      </ScrollView>
    )
  }
  return (
    <ScrollView contentContainerStyle={styles.centerContainer} testID={testID}>
      <View style={styles.center}>{children}</View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  padding: {
    height: 16,
  },
  centerContainer: {
    alignItems: 'center',
    paddingVertical: 25,
  },
  center: {
    // TODO: Parameter this when needed
    width: 1024,
    maxWidth: '100%',
  },
})
