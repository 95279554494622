import { PossibleSelectValues, SelectOption } from './index.types'

import { find } from 'lodash'

export function getNextSelected<T extends PossibleSelectValues>(
  value: T,
  selected: T[],
  options: SelectOption<T>[],
) {
  let newSelected = [...selected]
  const option = find(options, ['value', value])
  if (option) {
    // click on root option
    if (newSelected.includes(value)) {
      newSelected = newSelected.filter(s => s !== value)
      return newSelected
    }

    newSelected.push(value)
    // clear selected children
    if (option.children) {
      newSelected = newSelected.filter(e =>
        option.children?.every(childOption => childOption.value !== e),
      )
    }
    return newSelected
  }

  // click on child option
  const rootOption = find(options, o => !!find(o.children, ['value', value]))!
  if (!rootOption) {
    return newSelected
  }

  if (newSelected.includes(rootOption.value)) {
    // click chlid when rootOption is selected
    const childrenValues = rootOption.children!.map(
      childOption => childOption.value,
    )
    newSelected = [...newSelected, ...childrenValues].filter(
      s => s !== value && s !== rootOption.value,
    )
    return newSelected
  }

  if (newSelected.includes(value)) {
    // click chlid when it is selected
    newSelected = newSelected.filter(s => s !== value)
    return newSelected
  }

  // click chlid when it is not selected
  newSelected.push(value)
  const allSelected = rootOption.children!.every(child =>
    newSelected.includes(child.value),
  )
  if (allSelected) {
    // All chlid are selected
    newSelected.push(rootOption.value)
    // clear selected children
    newSelected = newSelected.filter(e =>
      rootOption.children?.every(childOption => childOption.value !== e),
    )
  }
  return newSelected
}
