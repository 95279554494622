import { StyleSheet } from 'react-native'
import { Colors } from 'constants/Colors'
import { Fonts } from 'constants/Fonts'

export const PatientHeaderStyles = StyleSheet.create({
  columnLeft: {
    flex: 1,
  },
  columnRight: {
    flex: 1,
    alignItems: 'flex-end',
  },
  fullProfileButtonText: {
    fontFamily: Fonts.regular,
    fontSize: 14,
  },
  fullProfileButton: {
    backgroundColor: Colors.white,
    borderColor: Colors.contentPrimary,
    borderRadius: 6,
    borderWidth: 1,
    height: 40,
    justifyContent: 'center',
    paddingHorizontal: 12,
    textAlign: 'center',
  },
  tagHolder: {
    marginRight: 5,
  },
  infoText: {
    fontFamily: Fonts.regular,
    color: Colors.infoGrey,
  },
  row: {
    flexDirection: 'row',
  },
  rowDivider: {
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: Colors.lightGrey,
    marginTop: 5,
  },
  rowTopDivider: {
    borderTopWidth: 1,
    borderColor: Colors.lightGrey,
    marginTop: 10,
    paddingTop: 5,
  },
  tags: {
    flexDirection: 'row',
  },
  regularText: {
    fontFamily: Fonts.regular,
  },
  italicText: {
    fontFamily: Fonts.italic,
  },
  tag: {
    marginLeft: 5,
  },
  weightText: {
    fontFamily: Fonts.semibold,
    fontSize: 14,
  },
  phoneText: {
    letterSpacing: 0.45,
  },
  rowPadding: {
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingLeft: 6,
    paddingVertical: 2,
  },
  ownerRowPadding: {
    justifyContent: 'space-between',
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingLeft: 6,
    paddingVertical: 2,
  },
  breedTextHolder: {
    maxWidth: 270,
  },
  contact: {
    paddingLeft: 8,
    paddingVertical: 12,
  },
  buttonContainer: {
    justifyContent: 'center',
    paddingVertical: 12,
  },
  condition: {
    fontFamily: Fonts.regular,
    fontSize: 18,
    paddingBottom: 5,
  },
  contactName: {
    fontFamily: Fonts.regular,
    fontSize: 18,
  },
  otherInfoText: {
    lineHeight: 20,
    textTransform: 'uppercase',
  },
  owner: {
    fontFamily: Fonts.regular,
    fontSize: 14,
  },
  phoneNumber: {
    fontFamily: Fonts.regular,
    fontSize: 14,
    color: Colors.lightRed,
  },
  problemsContainer: {
    paddingTop: 5,
  },
  problemTitle: {
    fontFamily: Fonts.regular,
    fontSize: 20,
    paddingLeft: 8,
  },
  inactiveProblemTitle: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  masterProblemsText: {
    fontFamily: Fonts.regular,
  },
  problemContainer: {
    paddingHorizontal: 8,
    paddingVertical: 10,
    marginVertical: 4,
    borderRadius: 8,
    backgroundColor: Colors.problemGrey,
  },
  problemTag: {
    width: 'auto',
    backgroundColor: Colors.blue,
    paddingHorizontal: 4,
    marginBottom: 4,
  },
  cprTagText: {
    fontFamily: Fonts.bold,
    fontSize: 13,
    letterSpacing: 0.5,
    lineHeight: 20,
    paddingHorizontal: 8,
  },
})
