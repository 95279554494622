import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, StyleSheet } from 'react-native'
import { Button } from './Button'
import { Variables } from 'src/design-system/theme'

type CreateRouteSectionProps = {
  createNewRoute: () => void
  createDisabled: boolean
}

export const CreateRouteSection: React.FC<CreateRouteSectionProps> = ({
  createNewRoute,
  createDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <View style={styles.createRouteSectionContainer}>
      <Button
        title={t('settings:routeOfAdministration.createNewRouteButtonText')}
        onPress={createNewRoute}
        disabled={createDisabled}
        style={styles.createRouteButton}
        textStyle={styles.createRouteButtonText}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  createRouteSectionContainer: {
    marginBottom: Variables.GutterSpacing.md,
  },
  createRouteButton: {
    paddingVertical: Variables.Size.base,
    width: 107,
  },
  createRouteButtonText: {
    fontSize: 14,
  },
})
