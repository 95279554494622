import React, { useMemo } from 'react'
import { FeatureFlagNames } from 'constants/FeatureFlags'
import { useTranslation } from 'react-i18next'
import { useFlags } from 'react-native-flagsmith/react'
import { useSeparationSites } from 'src/hooks/useSeparationSites'
import { Warning } from '../components/Warning'
import { Product } from '../types'

export const useBusinessUnitWarning = (
  product: Product,
  attendingDepartment: string | undefined | null,
) => {
  const { t } = useTranslation()
  const { isWithinBusinessSeparation } = useSeparationSites(
    product?.sites ?? [],
  )

  const { enable_bundle_ui } = useFlags([FeatureFlagNames.ENABLE_BUNDLE_UI])
  const isBundleFlagEnabled = enable_bundle_ui.enabled

  const isSiteValid = useMemo(() => {
    if (!isBundleFlagEnabled) return true
    return isWithinBusinessSeparation(attendingDepartment ?? '')
  }, [isBundleFlagEnabled, isWithinBusinessSeparation, attendingDepartment])

  const BusinessUnitWarning = isSiteValid ? null : (
    <Warning>{t('addTreatment:businessUnitWarning')}</Warning>
  )

  return { isSiteValid, BusinessUnitWarning }
}
