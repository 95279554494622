import { ReactElement } from 'react'
import { SelectOption } from '../Select/Select.types'
import {
  NestedListMenuActions,
  NestedListMenuState,
} from './useNestedListSelectMenu'

export type ClipButtonOption = {
  value: string
  text: string
}

export type ListMeasurements = {
  x: number
  y: number
  width: number
  height: number
  px: number
  py: number
}

export type NestedListSelectOption = SelectOption<string> & {
  nestedOptionLevel: NestedOptionLevels
  parentId: string | null
  hasChildren?: boolean
}

export type NestedListSelectProps = {
  options: NestedListSelectOption[]
  disabled?: boolean
  onChange: (sites: string[]) => void
  selected: string[]
  label: string
  loading: boolean
}

export enum NestedOptionLevels {
  PARENT = 1,
  CHILD = 2,
  TOP = 3,
}

export type NestedListSelectOptionsMenuProps = {
  state: NestedListMenuState
  actions: NestedListMenuActions
  label: string
  selected: string[]
  primaryMenu: SubMenu
  secondaryMenu: SubMenu
  multiSelectMenu?: boolean
}

export type SubMenuProps = {
  menuOptions: NestedListSelectOption[]
  state: NestedListMenuState
  actions: NestedListMenuActions
  heightCalc: (options: number) => number
  containerStyle: any
  listItemStyle?: any
  selected?: string[]
  liftedFirstOption?: boolean
}

export type SubMenu = ReactElement<SubMenuProps>
