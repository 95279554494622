import { isNil } from 'lodash'

export function getRepeatUntil(date: Date | 'discharge' | number) {
  if (typeof date === 'number') {
    // TODO: What if repeatUntilDate is number selected from ContinuePicker?
    return date.toString() // Converts to string for now but it will soon accept an integer
  }
  const finishTime =
    date === 'discharge' || isNil(date) ? null : date.toISOString()

  return finishTime
}
