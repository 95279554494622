import { useCallback } from 'react'
import { useTimeResolution } from 'src/hocs/timeContext'

type Props = {
  scrollToDateIdx: (idx: number, animated?: boolean) => void
  currentDateIdx: number
  hasLimitedScrollArea?: boolean
}

export const useArrowButton = ({
  scrollToDateIdx,
  currentDateIdx,
  hasLimitedScrollArea,
}: Props) => {
  const { dateRangeCount, setToNextRange, setToPrevRange } = useTimeResolution()

  const onClickPre = useCallback(() => {
    if (dateRangeCount === 1) {
      setToPrevRange()
      scrollToDateIdx(0, false)
      return
    }
    if (currentDateIdx === 0 || hasLimitedScrollArea) {
      setToPrevRange()
      scrollToDateIdx(1, false)
      return
    }
    scrollToDateIdx(currentDateIdx - 1)
  }, [
    dateRangeCount,
    currentDateIdx,
    hasLimitedScrollArea,
    scrollToDateIdx,
    setToPrevRange,
  ])

  const onClickNext = useCallback(() => {
    if (dateRangeCount === 1) {
      setToNextRange()
      scrollToDateIdx(0, false)
      return
    }

    if (currentDateIdx === dateRangeCount - 1 || hasLimitedScrollArea) {
      setToNextRange()
      scrollToDateIdx(1, false)
      return
    }

    scrollToDateIdx(currentDateIdx + 1)
  }, [
    dateRangeCount,
    currentDateIdx,
    hasLimitedScrollArea,
    scrollToDateIdx,
    setToNextRange,
  ])
  return { onClickPre, onClickNext }
}
